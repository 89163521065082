import { FC } from "react";

import { useAuth } from "@/auth/context/jwt";
import { useQueryBrandsUnderFamilyBrand } from "@/company/onboarding/api";

import { AffiliateCompanyAvatar } from "./AffiliateCompanyAvatar";

interface AffiliateCompaniesProps {
  familyOfBrandsId: number;
}

export const AffiliateCompanies: FC<AffiliateCompaniesProps> = ({
  familyOfBrandsId,
}) => {
  const { session } = useAuth();
  const brand = session?.brand;
  const { data: { logoUrls = [], brands = [] } = {} } =
    useQueryBrandsUnderFamilyBrand(
      {
        familyBrandId: familyOfBrandsId,
        brandId: brand?.id,
      },
      !!familyOfBrandsId
    );
  return (
    (
      <>
        {logoUrls.map((url: string, index: number) => {
          const name = brands[index];
          return <AffiliateCompanyAvatar key={url} logo={url} name={name} />;
        })}
      </>
    ) ?? null
  );
};
