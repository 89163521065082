import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";
import { CompanyCard } from "@/company/types";

export const USE_GET_COMPANY_CARD_URI =
  "/internal-admin/companies/:companyId/cards";

export function useGetCompanyCardById(
  companyId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<CompanyCard | null> {
  return useQuery({
    queryKey: [
      USE_GET_COMPANY_CARD_URI.replace(":companyId", companyId.toString()),
    ],
    queryFn: async () => {
      const response = await API.get(
        USE_GET_COMPANY_CARD_URI.replace(":companyId", companyId.toString())
      );
      if (response.data.data) {
        return response.data.data;
      }
      return null;
    },
    ...options,
  });
}
