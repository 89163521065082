import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { DateTime } from "luxon";

import { isDefined } from "@/utils/isDefined";

import { ReferralCampaign } from "../types";

const mockReferralCampaigns: ReferralCampaign[] = [
  {
    geographies: ["Northeast", "South"],
    name: "Trades Partner Development Challenge (Electrical, Plumbing, HVAC)",
    startDate: "2024-11-08T17:00:17.000+05:30",
    endDate: "2024-12-31T17:00:17.000+05:30",
    description:
      "<ul><li><p>Grow our referral network by establishing five new partnerships in the electrical, plumbing, and HVAC sectors</p></li><li><p>Incentives for each new partnership and for every 10 jobs referred through these new channels</p></li><li><p>Quarterly “Top Trades Partner” awards to celebrate locations with the strongest referral partnerships</p></li></ul>",
    goal: "I want to establish a referral network for a new franchisee to accelerate sales",
    otherGoal: "",
    prizes: [
      {
        prizeName: "$100",
        prizeType: "CASH",
      },
    ],
    brands: ["All"],
    specificBrands: [],
    notificationChannel: "EMAIL",
    sendUpdatesFrequency: "WEEKLY",
    targetParticipants: "ADMIN_AND_OWNERS",
    successCriteria: [
      {
        criteria: "First to send # of referrals within # timeframe",
        numberOfReferrals: 10,
        numberOfDays: 10,
      },
    ],
    isDraft: false,
    id: 1,
    status: "INACTIVE",
    createdAt: "2024-11-08T12:09:23.503Z",
    updatedAt: "2024-11-08T12:09:23.503Z",
  },
  {
    geographies: ["West"],
    name: "Technician Expansion & Referral Boost Program",
    startDate: "2024-11-08T00:00:45.024+05:30",
    endDate: "2025-02-21T17:00:45.000+05:30",
    description:
      "<ul><li><p>Onboard 5+ new technicians, who each refer 10+ jobs to local Rollins affiliates</p></li><li><p>Incentives for technicians exceeding the referral target within the first quarter</p></li></ul>",
    goal: "I want to establish a referral network for a new franchisee to accelerate sales",
    otherGoal: "",
    prizes: [
      {
        prizeName: "$2,000 Visa Gift Card",
        prizeType: "GIFTCARD",
      },
    ],
    brands: ["All"],
    specificBrands: [],
    notificationChannel: "EMAIL",
    sendUpdatesFrequency: "WEEKLY",
    targetParticipants: "ADMIN_AND_OWNERS",
    successCriteria: [
      {
        criteria: "First to send # of referrals within # timeframe",
        numberOfReferrals: 10,
        numberOfDays: 15,
      },
    ],
    currentStep: "CAMPAIGN_REWARDS",
    isDraft: true,
    id: 2,
    status: "INACTIVE",
    createdAt: "2024-11-08T12:11:54.006Z",
    updatedAt: "2024-11-08T12:11:54.006Z",
  },
  {
    geographies: ["Midwest"],
    name: "Garage & Pest Solutions in the SW with A1 Garage",
    startDate: "2024-11-08T17:00:08.000+05:30",
    endDate: "2024-11-10T17:00:08.000+05:30",
    description:
      "<ul><li><p>In Southwest markets, partner with A1 Garage Door Services to cross-refer garage and pest control needs</p></li><li><p>Receive rewards for every 10+ jobs referred to/from A1 Garage</p></li></ul>",
    goal: "I want to drive adoption and participation of our network on TradeEngage",
    otherGoal: "",
    prizes: [
      {
        prizeName: "$1,000",
        prizeType: "CASH",
      },
    ],
    brands: ["All"],
    specificBrands: [],
    notificationChannel: "EMAIL",
    sendUpdatesFrequency: "WEEKLY",
    targetParticipants: "ADMIN_AND_OWNERS",
    successCriteria: [
      {
        criteria: "First to send # of referrals within # timeframe",
        numberOfReferrals: 10,
        numberOfDays: 5,
      },
    ],
    isDraft: false,
    id: 3,
    status: "INACTIVE",
    createdAt: "2024-11-08T12:13:13.208Z",
    updatedAt: "2024-11-08T12:13:13.208Z",
  },
  {
    geographies: ["Midwest"],
    name: "Poop 911 & Northwest Exterminating Co-Op Campaign",
    startDate: "2024-11-08T17:00:35.297+05:30",
    endDate: "2025-02-28T17:43:35.000+05:30",
    description:
      "<ul><li><p>Launch successful co-op campaigns with Poop 911 to promote flea, tick services for homeowners</p></li><li><p>Bonus incentives for teams reaching specific co-op referral milestones</p></li></ul>",
    goal: "I want to drive adoption and participation of our network on TradeEngage",
    otherGoal: "",
    prizes: [
      {
        prizeName: "$1,000",
        prizeType: "CASH",
      },
    ],
    brands: ["All", "Specific Brands"],
    specificBrands: ["294"],
    notificationChannel: "EMAIL",
    sendUpdatesFrequency: "WEEKLY",
    targetParticipants: "ADMIN_AND_OWNERS",
    successCriteria: [
      {
        criteria: "First to send # of referrals within # timeframe",
        numberOfReferrals: 20,
        numberOfDays: 10,
      },
    ],
    isDraft: false,
    id: 4,
    status: "ACTIVE",
    createdAt: "2024-11-08T12:14:32.064Z",
    updatedAt: "2024-11-08T12:14:32.064Z",
  },
  {
    geographies: ["Midwest"],
    name: "Northwest Exterminating -Orkin Commercial Referral Exchange",
    startDate: "2024-10-31T17:00:50.000+05:30",
    endDate: "2024-11-01T17:44:50.000+05:30",
    description:
      "<ul><li><p>Connect with your local commercial Orkin branch</p></li><li><p>Refer 5+ commercial pest control jobs</p></li><li><p>Bonus for jobs converting to long-term commercial contracts</p></li></ul>",
    goal: "My franchisee is a seasonal business and I want to re-engage referral partners from prior years",
    otherGoal: "",
    prizes: [
      {
        prizeName: "$500 Team Lunch",
        prizeType: "CASH",
      },
    ],
    brands: ["All"],
    specificBrands: [],
    notificationChannel: "EMAIL",
    sendUpdatesFrequency: "WEEKLY",
    targetParticipants: "ADMIN_AND_OWNERS",
    successCriteria: [
      {
        criteria: "First to send # of referrals within # timeframe",
        numberOfReferrals: 5,
        numberOfDays: 10,
      },
    ],
    isDraft: false,
    id: 5,
    status: "INACTIVE",
    createdAt: "2024-11-08T12:15:39.462Z",
    updatedAt: "2024-11-08T12:15:39.462Z",
  },
  {
    geographies: ["Midwest"],
    name: "Mosquito Control Kickoff in SE Markets",
    startDate: "2024-11-08T17:00:07.038+05:30",
    endDate: "2024-11-30T17:05:07.000+05:30",
    description:
      "<ul><li><p>Refer mosquito control services by Missquito to ramp up new franchisees</p></li><li><p>Refer 5+ jobs to Nashville, Atlanta and Savannah markets</p></li></ul>",
    goal: "I want to encourage more outbound sales initiatives with local service providers",
    otherGoal: "",
    prizes: [
      {
        prizeName: "$1,000 Amazon Giftcard",
        prizeType: "GIFTCARD",
      },
    ],
    brands: ["All"],
    specificBrands: [],
    notificationChannel: "EMAIL",
    sendUpdatesFrequency: "WEEKLY",
    targetParticipants: "ADMIN_AND_OWNERS",
    successCriteria: [
      {
        criteria: "First to send # of referrals within # timeframe",
        numberOfReferrals: 5,
        numberOfDays: 5,
      },
    ],
    isDraft: false,
    id: 6,
    status: "INACTIVE",
    createdAt: "2024-11-08T12:18:07.641Z",
    updatedAt: "2024-11-08T12:18:07.641Z",
  },
  {
    geographies: ["Midwest"],
    name: "SERVPRO Partnership Power Program",
    startDate: "2024-11-08T17:00:21.891+05:30",
    endDate: "2024-11-30T17:00:21.000+05:30",
    description:
      "<ul><li><p>Partner with local SERVPRO franchises for referrals involving pest-related clean-ups or water damage prevention services.</p></li><li><p>Incentives for every 10+ jobs referred to and from SERVPRO</p></li><li><p>Quarterly “Top SERVPRO Partner” awards for locations achieving the most partnerships and referrals</p></li></ul>",
    goal: "I want to establish a referral network for a new franchisee to accelerate sales",
    otherGoal: "",
    prizes: [
      {
        prizeName: "",
        prizeType: "CASH",
      },
    ],
    brands: ["All"],
    specificBrands: [],
    notificationChannel: "EMAIL",
    sendUpdatesFrequency: "WEEKLY",
    targetParticipants: "ADMIN_AND_OWNERS",
    successCriteria: [
      {
        criteria: "First to send # of referrals within # timeframe",
        numberOfReferrals: 10,
        numberOfDays: 5,
      },
    ],
    currentStep: "CAMPAIGN_BASIC_INFO",
    isDraft: true,
    id: 7,
    status: "INACTIVE",
    createdAt: "2024-11-08T12:19:44.136Z",
    updatedAt: "2024-11-08T12:19:44.136Z",
  },
  {
    geographies: ["Northeast", "Midwest", "South", "West"],
    name: "Refer 10+ Bat Removal Jobs to Critter Stop",
    startDate: "2024-11-08T17:00:50.962+05:30",
    endDate: "2025-01-31T17:00:50.000+05:30",
    description:
      "<ul><li><p>Refer all bat removal jobs to Critter Control to ensure safe, humane handling of bats and adherence to wildlife regulations</p></li><li><p>Incentives for technicians who refer the most bat removal jobs each month</p></li><li><p>Monthly spotlight on top “Wildlife Referral Champions” to recognize top contributors</p></li></ul>",
    goal: "I want to encourage more outbound sales initiatives with local service providers",
    otherGoal: "",
    prizes: [
      {
        prizeName: "$2,000 Visa Gift Card",
        prizeType: "CASH",
      },
      {
        prizeType: "OTHERS",
        prizeName:
          "DeWalt ToughSystem Tool Box – Durable, stackable storage with waterproof design, perfect for organizing essential tools.",
      },
      {
        prizeType: "OTHERS",
        prizeName:
          "Carhartt Insulated Hooded Jacket – Rugged, weather-resistant gear to keep technicians comfortable during outdoor jobs",
      },
    ],
    brands: ["All"],
    specificBrands: [],
    notificationChannel: "EMAIL",
    sendUpdatesFrequency: "WEEKLY",
    targetParticipants: "ADMIN_AND_OWNERS",
    successCriteria: [
      {
        criteria: "First to send # of referrals within # timeframe",
        numberOfReferrals: 20,
        numberOfDays: 15,
      },
    ],
    isDraft: false,
    id: 8,
    status: "ACTIVE",
    createdAt: "2024-11-08T12:21:35.839Z",
    updatedAt: "2024-11-08T12:21:35.839Z",
  },
  {
    geographies: ["Southeast"],
    name: "Premier Pool & Spa - Mosquito Co Op (Northwest Exterminating)",
    startDate: "2024-11-08T17:55:02.163+05:30",
    endDate: "2025-03-31T17:55:02.000+05:30",
    description:
      "<ul><li><p>Receive 30+ mosquito control referrals (closed jobs only)</p></li><li><p>Refer 20+ pool cleaning jobs</p></li><li><p>Weekly “Mosquito-Free Pool Partner” recognition for the location with the most referrals</p></li></ul>",
    goal: "I want to encourage more outbound sales initiatives with local service providers",
    otherGoal: "",
    prizes: [
      {
        prizeName: "Win 5+ Tickets to MLB Spring Training!",
        prizeType: "TICKETS",
      },
      {
        prizeType: "CASH",
        prizeName: "$1,000 Team Dinner",
      },
      {
        prizeType: "CASH",
        prizeName: "$500 Team Lunch",
      },
    ],
    brands: ["Specific Brands"],
    specificBrands: ["3623"],
    notificationChannel: "EMAIL",
    sendUpdatesFrequency: "WEEKLY",
    targetParticipants: "ADMIN_AND_OWNERS",
    successCriteria: [
      {
        criteria: "First to send # of referrals within # timeframe",
        numberOfReferrals: 30,
        numberOfDays: 20,
      },
    ],
    isDraft: false,
    id: 9,
    status: "ACTIVE",
    createdAt: "2024-11-08T12:27:26.004Z",
    updatedAt: "2024-11-08T12:27:26.004Z",
  },
] as ReferralCampaign[];

export function useGetReferralCampaigns(
  params: {
    searchWord?: string;
  },
  options: UseQueryOptions<ReferralCampaign[]> = {}
): UseQueryResult<ReferralCampaign[]> {
  const storageKey = "referral-campaigns";
  const { searchWord } = params;

  return useQuery({
    queryKey: [storageKey],
    queryFn: async (): Promise<ReferralCampaign[]> => {
      let parsedReferralCampaigns: ReferralCampaign[] = [];
      const referralCampaigns = localStorage.getItem(storageKey);
      if (isDefined(referralCampaigns)) {
        parsedReferralCampaigns = JSON.parse(referralCampaigns);
      } else {
        parsedReferralCampaigns = mockReferralCampaigns as ReferralCampaign[];
        localStorage.setItem(storageKey, JSON.stringify(mockReferralCampaigns));
      }

      if (isDefined(searchWord)) {
        parsedReferralCampaigns = parsedReferralCampaigns.filter(
          referralCampaign =>
            referralCampaign.name
              .toLowerCase()
              .includes(searchWord.toLowerCase()) ||
            referralCampaign.description
              .toLowerCase()
              .includes(searchWord.toLowerCase())
        );
      }
      return parsedReferralCampaigns.sort((a, b) =>
        DateTime.fromISO(b.createdAt)
          .diff(DateTime.fromISO(a.createdAt))
          .toMillis()
      );
    },
    ...options,
  });
}
