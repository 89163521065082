import {
  CampaignOutlined as CampaignOutlinedIcon,
  EventAvailableOutlined as EventAvailableOutlinedIcon,
  FormatListBulleted as FormatListBulletedIcon,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useField } from "formik";
import { DateTime } from "luxon";
import * as yup from "yup";

import { BulletListInputField } from "@/lib/form-fields/BulletListInputField";
import { DateTimePickerField } from "@/lib/form-fields/DateTimePickerField";
import { TextInputField } from "@/lib/form-fields/TextInputField";

import { AddReferralCampaignFormInputField } from "./AddReferralCampaignFormInputField";

export const referralCampaignBasicInfoFormSchema = yup.object().shape({
  name: yup.string().required("Campaign Name is required"),
  startDate: yup.mixed<DateTime>().required("Campaign Start Date is required"),
  endDate: yup.mixed<DateTime>().required("Campaign End Date is required"),
  description: yup
    .string()
    .required("Campaign Description is required")
    .min(26, "Campaign Description is required"),
});

export type ReferralCampaignBasicInfoFormFields = yup.InferType<
  typeof referralCampaignBasicInfoFormSchema
>;

interface ReferralCampaignBasicInfoFormProps {
  inEditMode: boolean;
}

export function ReferralCampaignBasicInfoForm(
  props: ReferralCampaignBasicInfoFormProps
) {
  const { inEditMode } = props;
  const [startDateField] = useField<DateTime>("startDate");

  return (
    <Stack spacing={4}>
      <AddReferralCampaignFormInputField
        title="Campaign Details"
        icon={<CampaignOutlinedIcon />}
      >
        <TextInputField
          fullWidth
          required
          label="Campaign Name"
          name="name"
          placeholder="Untitled Campaign"
          InputLabelProps={{ shrink: true }}
        />
      </AddReferralCampaignFormInputField>
      <AddReferralCampaignFormInputField
        title="Time Period"
        icon={<EventAvailableOutlinedIcon />}
      >
        <Stack direction="row" justifyContent="space-between">
          <DateTimePickerField
            label="Campaign Start Date"
            name="startDate"
            minDate={inEditMode ? undefined : DateTime.now()}
          />
          <DateTimePickerField
            label="Campaign End Date"
            name="endDate"
            minDate={startDateField.value}
          />
        </Stack>
      </AddReferralCampaignFormInputField>
      <AddReferralCampaignFormInputField
        includeConnector={false}
        title="Campaign Description"
        icon={<FormatListBulletedIcon />}
      >
        <BulletListInputField
          name="description"
          label="Description*"
          placeholder="Decscribe what is included here"
          maxRows={5}
        />
      </AddReferralCampaignFormInputField>
    </Stack>
  );
}
