import constate from "constate";
import { useState } from "react";

function useShowToolTip() {
  const [showToolTip, setShowToolTip] = useState(true);

  return { showToolTip, setShowToolTip };
}

export const [ShowToolTipProvider, useShowToolTipContext] =
  constate(useShowToolTip);
