import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { CreateHomeownerJobDialog } from "@/common/CreateJobDialog/CreateHomeownerJobDialog";
import BarChartSquare02 from "@/common/icons/untitled-ui/duocolor/bar-chart-square-02";
import { DashboardLayout, NavLink } from "@/common/layouts/DashboardLayout";
import ProtectedRoute from "@/common/ProtectedRoutes";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useGetMyJobs } from "@/homeowner/api/useGetMyJobs";
import { HomeownerRoutes } from "@/homeowner/constants";
import { HomesProvider } from "@/homeowner/contexts/home-context";
import { PublicRoutes } from "@/open/constants";

import { HomeownerSideNavHeader } from "./HomeownerSideNavHeader";

const HomeownerNavLinks: NavLink[] = [
  {
    label: "HomeHub Concierge",
    link: getNavigationPathFromRoutePaths([
      HomeownerRoutes.BASE,
      HomeownerRoutes.JOBS_LIST,
    ]),
    icon: <BarChartSquare02 />,
  },
  {
    label: "My Service Providers",
    link: getNavigationPathFromRoutePaths([
      HomeownerRoutes.BASE,
      HomeownerRoutes.SERVICE_PROVIDERS,
    ]),
    icon: <ContactPhoneIcon />,
  },
  {
    label: "Profile",
    link: getNavigationPathFromRoutePaths([
      HomeownerRoutes.BASE,
      HomeownerRoutes.PERSONAL_SETTINGS,
    ]),
    icon: <ManageAccountsIcon />,
  },
  {
    label: "Contact Support",
    link: getNavigationPathFromRoutePaths([
      PublicRoutes.BASE,
      PublicRoutes.CONTACT_SUPPORT,
    ]),
    icon: <SupportAgentOutlinedIcon />,
  },
];

const HomeownerAccountButtons = [
  {
    label: "Profile",
    link: getNavigationPathFromRoutePaths([
      HomeownerRoutes.BASE,
      HomeownerRoutes.PERSONAL_SETTINGS,
    ]),
    icon: <ManageAccountsIcon />,
  },
];

const homeownerBottomNavLinks: [NavLink, NavLink, NavLink, NavLink] = [
  {
    label: "HomeHub",
    link: getNavigationPathFromRoutePaths([
      HomeownerRoutes.BASE,
      HomeownerRoutes.JOBS_LIST,
    ]),
    icon: <BarChartSquare02 color="inherit" />,
  },
  {
    label: "Providers",
    link: getNavigationPathFromRoutePaths([
      HomeownerRoutes.BASE,
      HomeownerRoutes.SERVICE_PROVIDERS,
    ]),
    icon: <ContactPhoneIcon color="inherit" />,
  },
  {
    label: "Profile",
    link: getNavigationPathFromRoutePaths([
      HomeownerRoutes.BASE,
      HomeownerRoutes.PERSONAL_SETTINGS,
    ]),
    icon: <ManageAccountsIcon color="inherit" />,
  },
  {
    label: "Support",
    link: getNavigationPathFromRoutePaths([
      PublicRoutes.BASE,
      PublicRoutes.CONTACT_SUPPORT,
    ]),
    icon: <SupportAgentOutlinedIcon color="inherit" />,
  },
];

export function HomeownerDashboardLayout() {
  const { session, isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);
  const { refetch: refreshJobs } = useGetMyJobs({
    refetchOnWindowFocus: false,
    enabled:
      !!(session?.user?.type === USER_TYPE.HOMEOWNER) && !!isAuthenticated,
  });

  return (
    <>
      <DashboardLayout
        primaryNavLinks={HomeownerNavLinks}
        sectionedNavLinks={[]}
        sideNavHeader={
          <HomeownerSideNavHeader
            name={
              session?.user
                ? `${session?.user?.firstName} ${session?.user?.lastName}`
                : ""
            }
          />
        }
        accountButtons={HomeownerAccountButtons}
        bottomNavBarProps={{
          navLinks: homeownerBottomNavLinks,
          onClickAction: () => setIsJobDialogOpen(true),
          actionIcon: (
            <img src="/mobile-action-icon.svg" alt="Submit a new project" />
          ),
          enabled: true,
        }}
      >
        <HomesProvider>
          <ProtectedRoute userRole={USER_TYPE.HOMEOWNER} />
        </HomesProvider>
      </DashboardLayout>
      <CreateHomeownerJobDialog
        isOpen={isJobDialogOpen}
        jobsListUrl={getNavigationPathFromRoutePaths([
          HomeownerRoutes.BASE,
          HomeownerRoutes.JOBS_LIST,
        ])}
        refreshJobs={refreshJobs}
        onClose={() => setIsJobDialogOpen(false)}
      />
    </>
  );
}
