import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PercentIcon from "@mui/icons-material/Percent";
import TungstenOutlinedIcon from "@mui/icons-material/TungstenOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  Slider,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { isCompanyActive } from "@/common/utils/accessControl";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useUpdateBrandLocationProfile } from "@/company/onboarding/api";
import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { CompanyRoutes } from "@/company/types";
import { tradeEngageColors } from "@/Theme/colors";

import { OnboardingContainer } from "../../container/OnboardingContainer";
import { OnboardingSteps } from "../../utils";

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 10,
    label: "10%",
  },
  {
    value: 20,
    label: "20%",
  },
  {
    value: 30,
    label: "30%",
  },
  {
    value: 40,
    label: "40%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 60,
    label: "60%",
  },
  {
    value: 70,
    label: "70%",
  },
  {
    value: 80,
    label: "80%",
  },
  {
    value: 90,
    label: "90%",
  },
  {
    value: 100,
    label: "100%",
  },
];

function valueLabelFormat(value: number) {
  return `${value}%`;
}

export function TechnicianSplit() {
  const navigate = useNavigate();
  const { session, refreshSession } = useAuth();
  const brandLocationProfile = session?.brandLocationProfile;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [technicianSplit, setTechnicianSplit] = useState(0);
  const [numberOfTechnician, setNumberOfTechnician] = useState(3);

  const { isLoading: updatingCompany, mutateAsync: updateCompany } =
    useUpdateBrandLocationProfile(session?.brandLocationProfile?.id, {
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating company details.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        trackEvent(AnalyticsEvents.COMPANY_ONBOARDING_TECHNICIAN_SPLIT_ADDED, {
          technicianSplit,
          companyId: session?.company?.id,
          source: "NEXT_BUTTON",
        });
        refreshSession();
        navigate(
          isCompanyActive(session?.company?.status)
            ? OnboardingSteps[ONBOARDING_STAGES.TECHNICIAN_SPLIT].nextStepRoute
            : OnboardingSteps[ONBOARDING_STAGES.RECOMMENDED_PARTNERS]
                .nextStepRoute
        );
      },
    });

  useEffect(() => {
    if (brandLocationProfile?.defaultTechnicianSplit) {
      setTechnicianSplit(brandLocationProfile?.defaultTechnicianSplit);
    }
  }, [brandLocationProfile]);

  const onNext = async () => {
    await updateCompany({
      defaultTechnicianSplit: technicianSplit,
    });
  };

  const companyEarnSplit = technicianSplit
    ? Math.round((100 - 100 * (technicianSplit / 100)) * 100) / 100
    : 0;
  const techEarnSplit = technicianSplit
    ? Math.round(100 * (technicianSplit / 100) * 100) / 100
    : 0;
  const techWeeklyEarning = 5 * techEarnSplit;
  const techMonthlyEarning = 4 * techWeeklyEarning;
  const companyMonthlyEarning = numberOfTechnician
    ? companyEarnSplit * 4 * 5 * numberOfTechnician
    : 0;

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const mobileScreen = (
    <Box
      display="flex"
      sx={{
        flexGrow: 1,
      }}
      flexDirection={"column"}
      gap={1}
    >
      <Accordion
        expanded={expanded === "panel1"}
        sx={{
          borderRadius: "8px !important",
          width: "100%",
          p: "8px 16px",
          my: "0 !important",
          mx: "0 !important",
          overflow: "auto !important",
          "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
            height: "40px",
            minHeight: "40px",
          },
          "& .MuiAccordionSummary-root": {
            px: 0,
          },
          "& .MuiAccordionDetails-root": {
            overflow: "auto",
            p: "8px 24px",
            flexGrow: 1,
            pt: 0,
            pb: 2,
            px: 0,
          },
          "& .MuiAccordionDetails-root.Mui-expanded": {
            overflow: "auto",
          },
          "&:before": {
            content: "none",
          },
        }}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                cursor: "pointer",
              }}
            />
          }
          sx={{
            "& .MuiAccordionSummary-content.Mui-expanded": {
              my: 1,
              mx: 0,
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#111927",
              fontSize: "15px",
              lineHeight: "26px",
              fontWeight: "600",
              wordWrap: "break-word",
            }}
          >
            Technician Split
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .MuiAccordionDetails-root": {
              p: 0,
            },
            overflow: "auto",
          }}
        >
          <Box width={"100%"} pb={2} px={isMobile ? 0 : 1}>
            <Typography variant="overline" color={"text.secondary"}>
              Recommended: 50%
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#212B36",
                  fontSize: "32px",
                  fontWeight: "700",
                  lineHeight: "48px",
                  wordWrap: "break-word",
                  mr: 0.5,
                }}
              >
                {technicianSplit}{" "}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#212B36",
                  fontSize: "20px",
                  fontWeight: "700",
                  lineHeight: "30px",
                  wordWrap: "break-word",
                }}
              >
                %
              </Typography>
            </Box>
            <Box pr={isMobile ? 0 : 2} pl={isMobile ? 0 : 1}>
              <Slider
                aria-label="technician split slider"
                sx={{ width: "100%" }}
                defaultValue={technicianSplit}
                valueLabelDisplay="auto"
                step={10}
                value={technicianSplit}
                {...(isMobile ? {} : { marks })}
                min={0}
                max={100}
                valueLabelFormat={valueLabelFormat}
                onChange={(event: Event, newValue: number | number[]) =>
                  setTechnicianSplit(newValue as number)
                }
              />
            </Box>
          </Box>
          <Card
            sx={{
              display: "flex",
              px: 3,
              mt: 1,
              py: 2,
              bgcolor: tradeEngageColors.darkBlue,
              color: "white",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              alignSelf: "stretch",
              gap: 1,
            }}
          >
            <IconButton
              sx={{
                bgcolor: "#16DB65",
                color: "white",
                borderRadius: "20px",
                width: "40px",
                height: "40px",
                mt: 1,
              }}
            >
              <TungstenOutlinedIcon />
            </IconButton>
            <Typography
              sx={{
                color: "white",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "25.12px",
                wordWrap: "break-word",
              }}
            >
              What is Technician Split?
            </Typography>
            <Typography
              sx={{
                opacity: 0.7,
                color: "white",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "21.98px",
                wordWrap: "break-word",
              }}
            >
              The portion of the referral commission paid to the technician who
              sourced the referral. Most companies allocate 100% of the earnings
              to the technicians. This approach ensures that your team remains
              highly incentivized and aligned with company goals. Earnings are
              deposited directly into the technician’s virtual wallet.
            </Typography>
          </Card>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        sx={{
          borderRadius: "8px !important",
          width: "100%",
          p: "8px 16px",
          my: "0 !important",
          mx: "0 !important",
          bgcolor: tradeEngageColors.darkBlue,
          "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
            height: "40px",
            minHeight: "40px",
          },
          "& .MuiAccordionSummary-root": {
            px: 0,
          },
          "& .MuiAccordionDetails-root": {
            pt: 0,
            pb: 2,
            px: 0,
          },
          "&:before": {
            content: "none",
          },
        }}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                cursor: "pointer",
              }}
            />
          }
          sx={{
            "& .MuiAccordionSummary-content.Mui-expanded": {
              my: 1,
              mx: 0,
            },
            bgcolor: tradeEngageColors.darkBlue,
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            {...(expanded === "panel2" ? { my: 3 } : {})}
          >
            <IconButton
              sx={{
                bgcolor: "#16DB65",
                color: "white",
                borderRadius: "20px",
                width: "32px",
                height: "32px",
                mr: 1,
              }}
            >
              <CalculateOutlinedIcon />
            </IconButton>
            <Typography
              sx={{
                color: "white",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "25.12px",
                wordWrap: "break-word",
              }}
            >
              TradeEngage Calculator
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .MuiAccordionDetails-root": {
              p: 0,
            },
            bgcolor: tradeEngageColors.darkBlue,
          }}
        >
          <Box
            sx={{
              display: "flex",
              rowGap: "8px",
              columnGap: "4px",
              flexWrap: "wrap",
              alignItems: "center",
              overflow: "auto",
              bgcolor: tradeEngageColors.darkBlue,
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={1}
              pt={isMobile ? 2 : 1.5}
              pb={isMobile ? 1 : 1.5}
              width={"100%"}
              alignItems={isMobile ? "start" : "center"}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  pt: 0.3,
                  lineHeight: "19.92px",
                  wordWrap: "break-word",
                }}
              >
                Estimated Referral Commission Received per Job
              </Typography>
              <Typography
                sx={{
                  textAlign: "right",
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "26px",
                  wordWrap: "break-word",
                }}
              >
                +$100
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={1}
              py={isMobile ? 0.5 : 1.5}
              width={"100%"}
              alignItems={"center"}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "19.92px",
                  wordWrap: "break-word",
                }}
              >
                Technician Split
              </Typography>
              <TextField
                hiddenLabel
                sx={{
                  width: "84px",
                  color: "white",
                  height: "36px",
                  "& .MuiFilledInput-input::-webkit-inner-spin-button, & .MuiFilledInput-input::-webkit-outer-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentIcon
                        fontSize="small"
                        sx={{ color: "white", height: "24px" }}
                      />
                    </InputAdornment>
                  ),
                  style: { color: "white" },
                }}
                size="small"
                InputLabelProps={{ shrink: false }}
                name="link"
                type="number"
                value={technicianSplit}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 1,
                }}
                onChange={event =>
                  setTechnicianSplit(parseInt(event.target.value))
                }
              />
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderColor: "rgba(255, 255, 255, 0.30)",
              }}
            />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={1}
              py={isMobile ? 1 : 1.5}
              width={"100%"}
              alignItems={"center"}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "19.92px",
                  wordWrap: "break-word",
                }}
              >
                Your Company Gets
              </Typography>
              <Typography
                sx={{
                  color: "#16DB65",
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "26px",
                  wordWrap: "break-word",
                }}
              >
                ${companyEarnSplit.toLocaleString("en-US")}
              </Typography>
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderColor: "rgba(255, 255, 255, 0.30)",
              }}
            />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={1}
              py={isMobile ? 1 : 1.5}
              width={"100%"}
              alignItems={"center"}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "19.92px",
                  wordWrap: "break-word",
                }}
              >
                Your Technician Gets
              </Typography>
              <Typography
                sx={{
                  color: "#16DB65",
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "26px",
                  wordWrap: "break-word",
                }}
              >
                ${techEarnSplit}
              </Typography>
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderColor: "rgba(255, 255, 255, 0.30)",
              }}
            />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={1}
              py={isMobile ? 1 : 1.5}
              width={"100%"}
              alignItems={isMobile ? "start" : "center"}
            >
              <Typography
                sx={{
                  color: "#16DB65",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "19.92px",
                  wordWrap: "break-word",
                }}
              >
                Technician{" "}
                <Typography
                  display="inline"
                  sx={{
                    color: "#16DB65",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "19.92px",
                    wordWrap: "break-word",
                    textDecoration: "underline",
                  }}
                >
                  Weekly
                </Typography>{" "}
                Income (1 referral per day)
              </Typography>
              <Typography
                sx={{
                  textAlign: "right",
                  color: "#16DB65",
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "21.6px",
                  wordWrap: "break-word",
                }}
              >
                ${techWeeklyEarning.toLocaleString("en-US")}
              </Typography>
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderColor: "rgba(255, 255, 255, 0.30)",
              }}
            />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={1}
              py={isMobile ? 1 : 1.5}
              width={"100%"}
              alignItems={isMobile ? "start" : "center"}
            >
              <Typography
                sx={{
                  color: "#16DB65",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "19.92px",
                  wordWrap: "break-word",
                }}
              >
                Technician{" "}
                <Typography
                  display="inline"
                  sx={{
                    color: "#16DB65",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "19.92px",
                    wordWrap: "break-word",
                    textDecoration: "underline",
                  }}
                >
                  Monthly
                </Typography>{" "}
                Income (1 referral per day)
              </Typography>
              <Typography
                sx={{
                  textAlign: "right",
                  color: "#16DB65",
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "21.6px",
                  wordWrap: "break-word",
                }}
              >
                ${techMonthlyEarning.toLocaleString("en-US")}
              </Typography>
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderColor: "rgba(255, 255, 255, 0.30)",
              }}
            />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={1}
              py={isMobile ? 1 : 1.5}
              width={"100%"}
              alignItems={"center"}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "19.92px",
                  wordWrap: "break-word",
                }}
              >
                Number of Technicians
              </Typography>
              <TextField
                hiddenLabel
                sx={{
                  width: "84px",
                  color: "white",
                  height: "36px",
                  "& .MuiFilledInput-input::-webkit-inner-spin-button, & .MuiFilledInput-input::-webkit-outer-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                }}
                InputProps={{
                  style: { color: "white" },
                }}
                value={numberOfTechnician}
                size="small"
                InputLabelProps={{ shrink: false }}
                name="link"
                type="number"
                onChange={event =>
                  setNumberOfTechnician(parseInt(event.target.value))
                }
              />
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderColor: "rgba(255, 255, 255, 0.30)",
              }}
            />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={1}
              py={isMobile ? 1 : 1.5}
              width={"100%"}
              alignItems={isMobile ? "start" : "center"}
            >
              <Typography
                sx={{
                  color: "#16DB65",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "19.92px",
                  wordWrap: "break-word",
                }}
              >
                Your{" "}
                <Typography
                  display="inline"
                  sx={{
                    color: "#16DB65",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "19.92px",
                    wordWrap: "break-word",
                    textDecoration: "underline",
                  }}
                >
                  Monthly
                </Typography>{" "}
                Savings in Customer Acquisition Costs
              </Typography>
              <Typography
                sx={{
                  textAlign: "right",
                  color: "#16DB65",
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "21.6px",
                  wordWrap: "break-word",
                }}
              >
                ${companyMonthlyEarning.toLocaleString("en-US")}
              </Typography>
            </Box>
            <Typography
              sx={{
                opacity: 0.7,
                color: "white",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "400",
                lineHeight: "18.84px",
                wordWrap: "break-word",
              }}
            >
              TradeEngage users boost earnings for their technicians, their
              companies and referral partners, while gaining access to exclusive
              jobs at half the cost!
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  const desktopScreen = (
    <>
      <Card
        sx={{
          display: "flex",
          px: 2,
          py: 3,
          flexDirection: "column",
          alignItems: "flex-start",
          width: isMobile ? "100%" : "50%",
          alignSelf: "stretch",
          overflow: "auto",
          height: "fit-content",
        }}
      >
        <Box width={"100%"} pb={2} px={1}>
          <Typography variant="overline" color={"text.secondary"}>
            Recommended: 50%
          </Typography>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#212B36",
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "48px",
                wordWrap: "break-word",
                mr: 0.5,
              }}
            >
              {technicianSplit}{" "}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                color: "#212B36",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "30px",
                wordWrap: "break-word",
              }}
            >
              %
            </Typography>
          </Box>
          <Box pr={2} pl={1}>
            <Slider
              aria-label="technician split slider"
              sx={{ width: "100%" }}
              defaultValue={technicianSplit}
              valueLabelDisplay="auto"
              step={10}
              value={technicianSplit}
              {...(isMobile ? {} : { marks })}
              min={0}
              max={100}
              valueLabelFormat={valueLabelFormat}
              onChange={(event: Event, newValue: number | number[]) =>
                setTechnicianSplit(newValue as number)
              }
            />
          </Box>
        </Box>
        <Card
          sx={{
            display: "flex",
            px: 3,
            mt: 1,
            py: 2,
            bgcolor: tradeEngageColors.darkBlue,
            color: "white",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            alignSelf: "stretch",
            gap: 1,
          }}
        >
          <IconButton
            sx={{
              bgcolor: "#16DB65",
              color: "white",
              borderRadius: "20px",
              width: "40px",
              height: "40px",
              mt: 1,
            }}
          >
            <TungstenOutlinedIcon />
          </IconButton>
          <Typography
            sx={{
              color: "white",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "25.12px",
              wordWrap: "break-word",
            }}
          >
            What is Technician Split?
          </Typography>
          <Typography
            sx={{
              opacity: 0.7,
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "21.98px",
              wordWrap: "break-word",
            }}
          >
            The portion of the referral commission paid to the technician who
            sourced the referral. Most companies allocate 100% of the earnings
            to the technicians. This approach ensures that your team remains
            highly incentivized and aligned with company goals. Earnings are
            deposited directly into the technician’s virtual wallet.
          </Typography>
        </Card>
      </Card>
      <Card
        sx={{
          display: "flex",
          px: 3,
          py: 2,
          bgcolor: tradeEngageColors.darkBlue,
          color: "white",
          flexDirection: "column",
          alignItems: "flex-start",
          width: isMobile ? "100%" : "50%",
          alignSelf: "stretch",
          overflow: "auto",
          height: "fit-content",
        }}
      >
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <IconButton
            sx={{
              bgcolor: "#16DB65",
              color: "white",
              borderRadius: "20px",
              width: "40px",
              height: "40px",
              my: 1,
            }}
          >
            <CalculateOutlinedIcon />
          </IconButton>
          <Typography
            sx={{
              color: "white",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "25.12px",
              wordWrap: "break-word",
            }}
          >
            TradeEngage Calculator
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          py={1.5}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "19.92px",
              wordWrap: "break-word",
            }}
          >
            Estimated Referral Commission Received per Job
          </Typography>
          <Typography
            sx={{
              textAlign: "right",
              color: "white",
              fontSize: "15px",
              fontWeight: "600",
              lineHeight: "26px",
              wordWrap: "break-word",
            }}
          >
            +$100
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          py={1.5}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "19.92px",
              wordWrap: "break-word",
            }}
          >
            Technician Split
          </Typography>
          <TextField
            hiddenLabel
            sx={{
              width: "84px",
              color: "white",
              height: "36px",
              "& .MuiFilledInput-input::-webkit-inner-spin-button, & .MuiFilledInput-input::-webkit-outer-spin-button":
                {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentIcon
                    fontSize="small"
                    sx={{ color: "white", height: "24px" }}
                  />
                </InputAdornment>
              ),
              style: { color: "white" },
            }}
            size="small"
            InputLabelProps={{ shrink: false }}
            name="link"
            type="number"
            value={technicianSplit}
            inputProps={{
              min: 0,
              max: 100,
              step: 1,
            }}
            onChange={event => setTechnicianSplit(parseInt(event.target.value))}
          />
        </Box>
        <Divider
          sx={{ width: "100%", borderColor: "rgba(255, 255, 255, 0.30)" }}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          py={1.5}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "19.92px",
              wordWrap: "break-word",
            }}
          >
            Your Company Gets
          </Typography>
          <Typography
            sx={{
              color: "#16DB65",
              fontSize: "15px",
              fontWeight: "600",
              lineHeight: "26px",
              wordWrap: "break-word",
            }}
          >
            ${companyEarnSplit.toLocaleString("en-US")}
          </Typography>
        </Box>
        <Divider
          sx={{ width: "100%", borderColor: "rgba(255, 255, 255, 0.30)" }}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          py={1.5}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "19.92px",
              wordWrap: "break-word",
            }}
          >
            Your Technician Gets
          </Typography>
          <Typography
            sx={{
              color: "#16DB65",
              fontSize: "15px",
              fontWeight: "600",
              lineHeight: "26px",
              wordWrap: "break-word",
            }}
          >
            ${techEarnSplit}
          </Typography>
        </Box>
        <Divider
          sx={{ width: "100%", borderColor: "rgba(255, 255, 255, 0.30)" }}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          py={1.5}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "#16DB65",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "19.92px",
              wordWrap: "break-word",
            }}
          >
            Technician{" "}
            <Typography
              display="inline"
              sx={{
                color: "#16DB65",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "19.92px",
                wordWrap: "break-word",
                textDecoration: "underline",
              }}
            >
              Weekly
            </Typography>{" "}
            Income (1 referral per day)
          </Typography>
          <Typography
            sx={{
              textAlign: "right",
              color: "#16DB65",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "21.6px",
              wordWrap: "break-word",
            }}
          >
            ${techWeeklyEarning.toLocaleString("en-US")}
          </Typography>
        </Box>
        <Divider
          sx={{ width: "100%", borderColor: "rgba(255, 255, 255, 0.30)" }}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          py={1.5}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "#16DB65",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "19.92px",
              wordWrap: "break-word",
            }}
          >
            Technician{" "}
            <Typography
              display="inline"
              sx={{
                color: "#16DB65",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "19.92px",
                wordWrap: "break-word",
                textDecoration: "underline",
              }}
            >
              Monthly
            </Typography>{" "}
            Income (1 referral per day)
          </Typography>
          <Typography
            sx={{
              textAlign: "right",
              color: "#16DB65",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "21.6px",
              wordWrap: "break-word",
            }}
          >
            ${techMonthlyEarning.toLocaleString("en-US")}
          </Typography>
        </Box>
        <Divider
          sx={{ width: "100%", borderColor: "rgba(255, 255, 255, 0.30)" }}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          py={1.5}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "19.92px",
              wordWrap: "break-word",
            }}
          >
            Number of Technicians
          </Typography>
          <TextField
            hiddenLabel
            sx={{
              width: "84px",
              color: "white",
              height: "36px",
              "& .MuiFilledInput-input::-webkit-inner-spin-button, & .MuiFilledInput-input::-webkit-outer-spin-button":
                {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
            }}
            InputProps={{
              style: { color: "white" },
            }}
            value={numberOfTechnician}
            size="small"
            InputLabelProps={{ shrink: false }}
            name="link"
            type="number"
            onChange={event =>
              setNumberOfTechnician(parseInt(event.target.value))
            }
          />
        </Box>
        <Divider
          sx={{ width: "100%", borderColor: "rgba(255, 255, 255, 0.30)" }}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          py={1.5}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "#16DB65",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "19.92px",
              wordWrap: "break-word",
            }}
          >
            Your Company&apos;s{" "}
            <Typography
              display="inline"
              sx={{
                color: "#16DB65",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "19.92px",
                wordWrap: "break-word",
                textDecoration: "underline",
              }}
            >
              Monthly
            </Typography>{" "}
            Earnings from Referrals
          </Typography>
          <Typography
            sx={{
              textAlign: "right",
              color: "#16DB65",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "21.6px",
              wordWrap: "break-word",
            }}
          >
            ${companyMonthlyEarning.toLocaleString("en-US")}
          </Typography>
        </Box>
        <Typography
          sx={{
            opacity: 0.7,
            color: "white",
            fontSize: "12px",
            fontStyle: "italic",
            fontWeight: "400",
            lineHeight: "18.84px",
            wordWrap: "break-word",
          }}
        >
          Service Providers earn referral revenue without any administrative
          burden and use these funds to offset their customer acquisition costs
          to $0, all while enabling their technicians to earn more.
        </Typography>
      </Card>
    </>
  );

  return (
    <OnboardingContainer
      heading="Set Your Technician Split"
      subheading="Drag the slider below. In the TradeEngage calculator, enter your technician headcount to estimate your customer acquisition cost savings!"
    >
      <Box
        display={"flex"}
        sx={{
          overflow: "auto",
          flexGrow: 1,
          gap: 2,
          ...(isMobile ? { padding: "8px 8px" } : { padding: "8px 24px" }),
          background: "#F3F4F6",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {isMobile ? mobileScreen : desktopScreen}
      </Box>
      <Stack
        spacing={2}
        mt={3}
        mb={2}
        mx={2}
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "end",
          alignSelf: "stretch",
        }}
      >
        <Button
          variant="text"
          sx={{
            px: 0,
          }}
          onClick={() => {
            trackEvent(
              AnalyticsEvents.SKIPPED_COMPANY_ONBOARDING_ADDING_TECHNICIAN_SPLIT_PRICE_DATA,
              {
                companyId: session?.company?.id,
                source: "SKIP_BUTTON",
              }
            );
            navigate(
              isCompanyActive(session?.company?.status)
                ? OnboardingSteps[ONBOARDING_STAGES.TECHNICIAN_SPLIT]
                    .nextStepRoute
                : OnboardingSteps[ONBOARDING_STAGES.RECOMMENDED_PARTNERS]
                    .nextStepRoute
            );
          }}
        >
          Skip for Now
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            navigate(
              `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.OUTBOUND_PREFERENCES}`,
              { replace: true }
            )
          }
        >
          Back
        </Button>
        <LoadingButton
          loading={updatingCompany}
          variant="contained"
          onClick={onNext}
        >
          Continue
        </LoadingButton>
      </Stack>
    </OnboardingContainer>
  );
}
