import { CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import {
  GridRenderEditCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import { useState } from "react";

import { useGetAllBrands } from "@/company/api/useGetAllBrands";

export function EditBrandArea(
  props: GridRenderEditCellParams<
    GridValidRowModel,
    GridValidRowModel,
    GridValidRowModel,
    GridTreeNodeWithRender
  >
) {
  const { colDef } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();

  const { data: allbrands, isLoading: isLoadingAllBrands } = useGetAllBrands();

  const handleRef = (
    el: React.SetStateAction<HTMLDivElement | null | undefined>
  ) => {
    setAnchorEl(el);
  };

  if (isLoadingAllBrands) {
    return null;
  }

  return (
    <div>
      <div
        ref={handleRef}
        style={{
          maxHeight: "80px",
          width: colDef.computedWidth,
          display: "block",
          // position: "absolute",
          marginTop: "48px",
          top: 48,
          overflow: "auto",
          zIndex: 100,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="top-start">
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <Autocomplete
              sx={{ maxWidth: "200px" }}
              value={props.value ?? ""}
              options={allbrands ?? []}
              // value={formik.values.sectors}
              getOptionLabel={option => option.name}
              renderInput={params => {
                return (
                  <TextField {...params} variant="standard" margin="normal" />
                );
              }}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} key={option.id} value={option}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    {<Typography>{option.name}</Typography>}
                    {selected ? (
                      <CheckCircleOutlineIcon color="primary" />
                    ) : null}
                  </Box>
                </MenuItem>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    size="small"
                    label={option.name}
                    {...getTagProps({ index })}
                    variant="outlined"
                  />
                ))
              }
              onChange={(e, nv) => {
                props.api.setEditCellValue({ ...props, value: nv });
              }}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}
