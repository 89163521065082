import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { Box, Divider, Link, Typography } from "@mui/material";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import { FC } from "react";
import zipcodes from "zipcodes";

import { formatUSPhoneNumber } from "@/utils";

import { useFetchHomeownerDetails } from "../api/useFetchHomeownerDetails";
import { JobStatus } from "../types";
import { isJobHomeownerAccepted } from "../types/job";
import { JobHistory } from "./JobHistory";
import { JobHistoryByCompanyId } from "./JobHistorySuperAdmin";

interface HomeownerTabProps {
  jobId: number;
  jobStatus: JobStatus;
  companyId?: number;
}

export const HomeownerTab: FC<HomeownerTabProps> = ({
  jobId,
  jobStatus,
  companyId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useFetchHomeownerDetails(jobId, {
    refetchOnWindowFocus: false,
  });

  const { homeowner, home, notes } = data ?? {};
  const addressFields = home?.zipCode
    ? zipcodes.lookup(home?.zipCode)
    : {
        city: home?.city ?? "",
        state: home?.state ?? "",
        country: home?.country ?? "",
      };

  return (
    <Box>
      <Box>
        <Box pb={3}>
          <Typography variant="overline">Project Details</Typography>
        </Box>
        <Box>
          <Box display={"flex"} py={1.5}>
            <Typography width={"50%"} variant="subtitle2">
              Homeowner
            </Typography>
            <Box width={"50%"} display={"flex"} gap={1}>
              <Typography variant="body2" color="text.secondary">
                {`${homeowner?.firstName} ${homeowner?.lastName}`}
              </Typography>
              <ContentCopyOutlinedIcon
                sx={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                  color: "#6C737F",
                }}
                onClick={() => {
                  copy(`${homeowner?.firstName} ${homeowner?.lastName}`);
                  enqueueSnackbar({
                    message: "Homeowner Name copied to clipboard!",
                    variant: "success",
                  });
                }}
              />
            </Box>
          </Box>
          <Divider />
          {notes ? (
            <>
              <Box display={"flex"} py={1.5} alignItems={"start"}>
                <Typography width={"50%"} variant="subtitle2">
                  Notes
                </Typography>
                <Typography
                  width={"50%"}
                  variant="body2"
                  color="text.secondary"
                >
                  {notes}
                </Typography>
              </Box>
              <Divider />
            </>
          ) : null}
          {isJobHomeownerAccepted(jobStatus) &&
          (home?.streetAddress || home?.zipCode) ? (
            <Box display={"flex"} alignItems={"start"} width={"100%"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"start"}
                width={"100%"}
                py={1.5}
              >
                {home?.streetAddress ? (
                  <>
                    <Box
                      display={"flex"}
                      width={"100%"}
                      py={1.5}
                      alignItems={"start"}
                    >
                      <Typography width={"50%"} variant="subtitle2">
                        Street Address
                      </Typography>
                      <Box width={"50%"} display={"flex"} gap={1}>
                        <Typography variant="body2" color="text.secondary">
                          {home.streetAddress}
                        </Typography>
                        <ContentCopyOutlinedIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                            color: "#6C737F",
                          }}
                          onClick={() => {
                            copy(`${home?.streetAddress}, ${home?.zipCode}`);
                            enqueueSnackbar({
                              message: "Homeowner Address copied to clipboard!",
                              variant: "success",
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
                {home?.zipCode ? (
                  <>
                    <Box
                      display={"flex"}
                      py={1.5}
                      pb={2}
                      width={"100%"}
                      alignItems={"start"}
                    >
                      <Typography width={"50%"} variant="subtitle2">
                        Zip Code
                      </Typography>
                      <Box width={"50%"} display={"flex"} gap={1}>
                        <Typography variant="body2" color="text.secondary">
                          {home.zipCode}
                        </Typography>
                        <ContentCopyOutlinedIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                            color: "#6C737F",
                          }}
                          onClick={() => {
                            copy(`${home?.zipCode}`);
                            enqueueSnackbar({
                              message:
                                "Homeowner zip code copied to clipboard!",
                              variant: "success",
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
                <Divider sx={{ width: "100%" }} />
              </Box>
            </Box>
          ) : null}
          {homeowner?.cellPhone ? (
            <>
              <Box display={"flex"} py={1.5} alignItems={"start"}>
                <Typography width={"50%"} variant="subtitle2">
                  Mobile Number
                </Typography>
                <Box width={"50%"} display={"flex"} gap={1}>
                  <Link
                    href={`tel:${homeowner?.cellPhone}`}
                    variant="body2"
                    color="text.secondary"
                    sx={{ "&:hover": { color: "#16B364" } }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      color="text.secondary"
                      sx={{ "&:hover": { color: "#16B364" } }}
                    >
                      {formatUSPhoneNumber(homeowner?.cellPhone)}
                    </Typography>
                  </Link>
                  <ContentCopyOutlinedIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      color: "#6C737F",
                    }}
                    onClick={() => {
                      copy(homeowner?.cellPhone);
                      enqueueSnackbar({
                        message: "Homeowner Mobile Number copied to clipboard!",
                        variant: "success",
                      });
                    }}
                  />
                </Box>
              </Box>
              <Divider />
            </>
          ) : null}
        </Box>
      </Box>
      {home?.homeownerId ? (
        companyId ? (
          <JobHistoryByCompanyId
            homeOwnerId={home?.homeownerId}
            companyId={companyId}
          />
        ) : (
          <JobHistory homeOwnerId={home?.homeownerId} />
        )
      ) : null}
    </Box>
  );
};
