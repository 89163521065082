import { Close as CloseIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { SyntheticEvent, useEffect } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { CallRecording } from "@/company/api/useFetchCallRecordings";
import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";

export interface AddJobDetailsDialogProps {
  isOpen: boolean;
  job: Job;
  recording: CallRecording;
  isLoading?: boolean;
  editJob: ({
    summary,
    serviceCategory,
    jobType,
  }: {
    summary: string;
    serviceCategory: JobSectors;
    jobType: string;
  }) => void;
  onClose: () => void;
}

export function AddJobDetailsDialog(props: AddJobDetailsDialogProps) {
  const { job, editJob, onClose, isOpen, recording, isLoading } = props;
  const { session } = useAuth();

  const validationSchema = yup.object().shape({
    jobType: yup.string().required("Job Title is required"),
    summary: yup.string().required("Job Summary is required"),
    serviceCategory: yup.string().required("Job Service Category is required"),
  });

  const formik = useFormik({
    initialValues: {
      jobType: "",
      summary: "",
      serviceCategory: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ jobType, serviceCategory, summary }) => {
      if (jobType && serviceCategory && summary) {
        editJob({
          summary,
          serviceCategory: reverseSnakeCaseJobSectors[serviceCategory],
          jobType,
        });
      }
    },
  });

  useEffect(() => {
    if (recording?.jobSummary) {
      formik.setFieldValue("summary", recording.jobSummary, true);
    }
    if (recording?.jobType) {
      formik.setFieldValue("jobType", recording.jobType, true);
    }
    if (recording?.jobSector) {
      formik.setFieldValue(
        "serviceCategory",
        snakeCaseJobSectors[recording.jobSector as JobSectors],
        true
      );
    }
    if (job?.summary) {
      formik.setFieldValue("summary", job.summary, true);
    }
    if (job?.jobType) {
      formik.setFieldValue("jobType", job.jobType, true);
    }
    if (job?.sector) {
      formik.setFieldValue(
        "serviceCategory",
        snakeCaseJobSectors[job.sector as JobSectors],
        true
      );
    }
  }, [job, recording]);

  const jobSectorOptions =
    job.createdForCompanyId === recording.company?.id
      ? session?.companySectors?.map(sector => sector.sector) ?? []
      : Object.keys(JobSectors);

  return (
    <Dialog
      fullWidth
      sx={{ m: { xs: -3, md: 0 } }}
      open={props.isOpen}
      maxWidth="md"
      onClose={() => props.onClose()}
    >
      <DialogTitle component="div" width="95%" sx={{ px: 2 }}>
        <Typography gutterBottom variant="h5">
          Add Job Details
        </Typography>
        <Typography gutterBottom variant="body2" color={"text.secondary"}>
          Upload a job to refer to your network. It’s easy!
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => props.onClose()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: { xs: 0 }, pt: 0 }}>
        <Box width="100%" pt={0}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems={"center"}
            width={"100%"}
            flexDirection={"column"}
            gap={2}
            px={2}
          >
            <Box display={"flex"} width={"100%"}>
              <TextField
                fullWidth
                required
                id="jobType"
                name="jobType"
                label="Job Title"
                sx={{ my: 0 }}
                value={formik.values.jobType}
                error={formik.touched.jobType && Boolean(formik.errors.jobType)}
                helperText={formik.touched.jobType && formik.errors.jobType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            <Box display={"flex"} width={"100%"}>
              <Autocomplete
                sx={{ width: "100%" }}
                options={jobSectorOptions.map(sector => {
                  if (sector === JobSectors.HVAC) {
                    return sector.toString();
                  }
                  return snakeCaseJobSectors[sector.toString() as JobSectors];
                })}
                value={formik.values.serviceCategory}
                getOptionLabel={option => option}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    error={
                      formik.touched.serviceCategory &&
                      Boolean(formik.errors.serviceCategory)
                    }
                    helperText={
                      formik.touched.serviceCategory &&
                      formik.errors.serviceCategory
                    }
                    value={formik.values.serviceCategory ?? []}
                    margin="normal"
                    InputLabelProps={{
                      style: { marginBottom: "8px" },
                    }}
                    label="Select Job Service Category"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      {<Typography>{option}</Typography>}
                      {selected ? (
                        <CheckCircleOutlineIcon color="primary" />
                      ) : null}
                    </Box>
                  </MenuItem>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                      size="small"
                      label={option}
                      {...getTagProps({ index })}
                      variant="outlined"
                    />
                  ))
                }
                onChange={(_: SyntheticEvent, newValue: string | null) =>
                  formik.setFieldValue(
                    "serviceCategory",
                    newValue as JobSectors
                  )
                }
                onBlur={formik.handleBlur}
              />
            </Box>
            <Box display={"flex"} width={"100%"} flexDirection={"column"}>
              <TextField
                multiline
                fullWidth
                id="summary"
                name="summary"
                label="Job Summary"
                InputProps={{
                  inputProps: {
                    maxLength: 500,
                  },
                }}
                rows={4}
                maxRows={4}
                value={formik.values.summary}
                error={formik.touched.summary && Boolean(formik.errors.summary)}
                helperText={
                  formik.touched.summary && formik.errors.summary
                    ? formik.errors.summary
                    : null
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"end"}
                mt={0.5}
              >
                {" "}
                <Typography
                  fontSize={"12px"}
                  color="text.secondary"
                  fontWeight={400}
                  mr={1}
                >
                  {formik.values.summary?.length ?? 0}/500 Characters
                </Typography>
              </Box>
            </Box>
          </Box>
          <Stack
            direction="row"
            gap={2}
            mt={2}
            sx={{
              display: "flex",
              alignItems: { xs: "flex-center", lg: "flex-start" },
              justifyContent: "start",
              alignSelf: "stretch",
              width: "100%",
            }}
            px={2}
          >
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="primary"
              disabled={
                !!(
                  (!formik.dirty && !Object.keys(formik.touched).length) ||
                  isLoading
                )
              }
              onClick={() => formik.handleSubmit()}
            >
              Add Job Details
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              variant="outlined"
              color="primary"
              disabled={
                !!(
                  (!formik.dirty && !Object.keys(formik.touched).length) ||
                  isLoading
                )
              }
              onClick={() => onClose()}
            >
              Cancel
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
