import { Close as CloseIcon } from "@mui/icons-material";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { DateTime } from "luxon";
import numeral from "numeral";

import { CompanyCard } from "@/company/types";
import { neutral } from "@/Theme/colors";

export interface ConfirmPurchaseDialogProps {
  open: boolean;
  creditsToBuy: number;
  onClickConfirm: () => void;
  onClickCancel: () => void;
  onClickClose: () => void;
  isBuyingCredits: boolean;
  card?: CompanyCard | null;
  onClickChangeCard: () => void;
}

export function ConfirmPurchaseDialog(props: ConfirmPurchaseDialogProps) {
  const {
    open,
    creditsToBuy,
    card,
    isBuyingCredits,
    onClickCancel,
    onClickChangeCard,
    onClickClose,
    onClickConfirm,
  } = props;

  const specificDate = DateTime.fromObject({
    year: card?.expirationYear,
    month: card?.expirationMonth,
  });
  const formattedDate = specificDate.toFormat("MM/yy");
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      sx={{ p: 2 }}
      onClose={() => !isBuyingCredits && onClickClose()}
    >
      <Box width="100%" p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Box>
            <Typography gutterBottom variant="h5">
              Review Your Purchase
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary">
              Please review and confirm your purchase. Credits will be available
              in your account for immediate use.
            </Typography>
          </Box>
          <IconButton disabled={isBuyingCredits} onClick={() => onClickClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          mt={3}
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-start"}
          border={1}
          borderRadius={"20px"}
          p={3}
          borderColor={neutral[200]}
          width="100%"
        >
          <MonetizationOnOutlinedIcon
            sx={{
              height: "40px",
              width: "40px",
              mr: 3,
              p: 1,
              color: "primary.main",
              backgroundColor: "primary.light",
              borderRadius: "50%",
            }}
          />
          <Typography variant="h4" fontWeight={"bold"}>
            {numeral(creditsToBuy).format("$0,0.00")}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          borderRadius={"20px"}
          p={3}
          width="100%"
        >
          <Typography variant="body2" color="textSecondary" width="70%">
            We will charge your card on file. Click to add or update your card
            details.
          </Typography>
          <Button
            color="inherit"
            variant="outlined"
            onClick={onClickChangeCard}
          >
            Change Card
          </Button>
        </Box>
        <Box
          mb={3}
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-start"}
          border={1}
          borderRadius={"20px"}
          p={3}
          width="100%"
          borderColor={neutral[200]}
        >
          <WalletOutlinedIcon
            sx={{
              height: "40px",
              width: "40px",
              mr: 3,
              p: 1,
              color: "primary.main",
              backgroundColor: "primary.light",
              borderRadius: "50%",
            }}
          />
          <Box display="flex" alignItems={"center"} flexDirection={"column"}>
            <Typography variant="body2" color="textSecondary">
              **** **** **** {card?.lastFourDigits}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {card?.cardholderName} | {formattedDate}
            </Typography>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={3}
        >
          <Button
            color="inherit"
            variant="outlined"
            disabled={isBuyingCredits}
            onClick={onClickCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isBuyingCredits}
            variant="contained"
            color="primary"
            onClick={onClickConfirm}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
}
