/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Add as AddIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "lodash";
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import { FC, KeyboardEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

import { useJobShare } from "../api/useJobShare";

interface Column {
  id: "email";
  label: string;
  minWidth?: number;
  align?: "left";
}

const columns: readonly Column[] = [
  { id: "email", label: "Email Address", minWidth: 300 },
];

export const JobContactInvite: FC<{ jobId: number }> = ({ jobId }) => {
  const [email, setEmail] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const [emails, setEmails] = useState<Array<string>>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleDelete = (deletedIndex: number) => {
    let newEmailsList = [...emails];
    newEmailsList = newEmailsList.filter(
      (email, index) => index !== deletedIndex
    );
    setEmails(newEmailsList);
  };

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const navigate = useNavigate();

  const action = (snackbarId: SnackbarKey, onClick: () => void) => (
    <>
      <Typography
        sx={{
          color: "#16DB65",
          fontSize: "14px",
          fontWeight: 600,
          pr: 2,
          cursor: "pointer",
        }}
        onClick={() => {
          onClick();
          closeSnackbar(snackbarId);
        }}
      >
        Contact Us
      </Typography>
      <CloseIcon
        sx={{ color: "white", cursor: "pointer", mr: 1 }}
        fontSize="small"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      />
    </>
  );

  const { mutateAsync: createInvitation } = useJobShare({
    onSuccess: () => {
      enqueueSnackbar({
        message: "Your request has been submitted.",
        variant: "success",
        subText: (
          <>
            Need help?{" "}
            <a
              role="button"
              style={{
                textDecorationLine: "underline",
                color: "white",
                cursor: "pointer",
              }}
              onClick={event => {
                event.preventDefault();
                setContactSupportModalOpen(true);
              }}
            >
              Contact support
            </a>
          </>
        ),
      });
    },
    onError: () => {
      enqueueSnackbar(
        "There was an error while trying to share the job details.",
        {
          variant: "error",
        }
      );
    },
  });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSendInviteClick = async () => {
    const contacts = _.uniq(emails).map(email => ({
      email,
    }));
    await createInvitation({ jobId, contacts });
    setEmails([]);
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === "Enter") {
      setEmails(emails => [...emails, email]);
      setEmail("");
    }
  };

  return (
    <>
      <Box py={3} display={"flex"} flexDirection={"column"} gap={3}>
        <Box display={"flex"} gap={2}>
          <TextField
            fullWidth
            label="Enter Recipient's Email Address"
            name="email"
            type="email"
            value={email}
            onKeyUp={handleKeyUp}
            onChange={event => setEmail(event?.target.value)}
          />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            disabled={!email}
            onClick={() => {
              setEmails(emails => [...emails, email]);
              setEmail("");
            }}
          >
            Add
          </Button>
        </Box>
      </Box>
      <Box>
        <TableContainer sx={{ height: 110 }} component={Paper}>
          <Table stickyHeader size="small" aria-label="email invite table">
            <TableHead>
              <TableRow sx={{ height: "2px" }}>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: isDesktop ? "300" : "80" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  key={"action"}
                  style={{ minWidth: isDesktop ? "170" : "80" }}
                  align="right"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.uniq(emails)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                      {columns.map(column => {
                        const value = row;
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Box display={"flex"} gap={1} alignItems={"center"}>
                              <Avatar />
                              {value}
                            </Box>
                          </TableCell>
                        );
                      })}
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          aria-label="delete"
                          onClick={() => handleDelete(index)}
                        >
                          <PersonRemoveOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={_.uniq(emails).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          py={3}
        >
          <Button
            variant="contained"
            startIcon={<ForwardToInboxOutlinedIcon />}
            onClick={handleSendInviteClick}
          >
            Share Project{" "}
            {_.uniq(emails).length ? `(${_.uniq(emails).length})` : ""}
          </Button>
        </Stack>
      </Box>
    </>
  );
};
