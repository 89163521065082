/* eslint-disable jsx-a11y/media-has-caption */
import { HelpOutline } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import numeral from "numeral";
import { FC, useEffect, useState } from "react";

import { ModifyJobDialog } from "@/common/ModifyJobDialog";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { snakeCaseJobSectors } from "@/company/utils";
import { TechnicianJobShareDialog } from "@/homeowner/components/job-share-dialog/TechnicianJobShareDialog";
import { getTechnicianJobCardStatusChipProps } from "@/technician/components/TechnicianJobCard/utils";
import { neutral } from "@/Theme/colors";
import { isDefined } from "@/utils/isDefined";

import { JobUploadTypes } from "../api/useCreateJob";
import { useGetLatestRoutingByJobId } from "../api/useGetJobRoutingsByJobId";
import { useUpdateJobSector } from "../api/useUpdateJobSector";
import { JobSectors } from "../constants";
import { Job, JobStatus } from "../types";
import { RoutedJobStatus, Source } from "../types/job";
import {
  getRecommendedProviderDetails,
  ProjectConsultantJobStatusChipProps,
  ProjectJobStatusChipProps,
} from "../utils";
import { CompanyProfileDrawer } from "./CompanyProfileDrawer";
import { JobDrawerTabsEnum } from "./JobDrawer";
import { JobShareDialog } from "./JobShareDialog";

interface JobProjectProps {
  job?: Job;
  jobStatus?: JobStatus | RoutedJobStatus;
  lastActivity?: string;
  source?: Source;
  inactiveReason?: string;
  isRoutedJob?: boolean;
  changeTab?: (tab: JobDrawerTabsEnum) => void;
  onClose?: () => void;
  refreshJobs?: () => void;
}

export const JobProjectTab: FC<JobProjectProps> = ({
  job,
  jobStatus,
  lastActivity,
  source,
  isRoutedJob,
  inactiveReason,
  changeTab,
  refreshJobs,
}) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >();

  const [jobSummary, setJobSummary] = useState("");
  const [jobType, setJobType] = useState("");

  const { isLoading: updatingJobSector, mutateAsync: updateJobSector } =
    useUpdateJobSector({
      onSuccess: () => {
        enqueueSnackbar({
          message: "Job updated successfully.",
          variant: "success",
        });
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "There was an error while updating job  details.";
        enqueueSnackbar({
          message,
          variant: "error",
        });
      },
    });

  const [modifyJobSectorDialogOpen, setModifyJobSectorDialogOpen] =
    useState(false);

  useEffect(() => {
    if (job?.jobType) {
      setJobType(job.jobType);
    }
    if (job?.summary) {
      setJobSummary(job.summary);
    }
  }, [job]);

  const [newSector, setNewJobSector] = useState<JobSectors | null>(null);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const { data: latestJobRouting, isLoading: fetchingLatestJobRouting } =
    useGetLatestRoutingByJobId(job?.id, {
      enabled: Boolean(job?.id) && !isRoutedJob,
    });

  const isJobDeclined =
    !isRoutedJob &&
    job?.status !== JobStatus.CLOSED &&
    (latestJobRouting?.status === RoutedJobStatus.INACTIVE ||
      latestJobRouting?.status === RoutedJobStatus.DECLINED);

  if (!job) return null;

  const recommendedProvider =
    isRoutedJob ||
    job?.listingExtraInfo?.buyingCompanyId ||
    job?.listingExtraInfo?.routedToCompanyId
      ? null
      : getRecommendedProviderDetails(job);

  const getStatusChip = () => {
    if (source === Source.CONSULTANT && jobStatus) {
      return (
        <Chip
          label={ProjectConsultantJobStatusChipProps[jobStatus].label}
          sx={{
            bgcolor: ProjectConsultantJobStatusChipProps[jobStatus].bgcolor,
            color: ProjectConsultantJobStatusChipProps[jobStatus].color,
            fontSize: "12px",
            fontWeight: 600,
            height: "24px",
          }}
        />
      );
    }
    if (isJobDeclined) {
      return (
        <Chip
          label={"JOB DECLINED"}
          sx={{
            bgcolor: "rgba(240, 68, 56, 0.12)",
            color: "#B42318",
            fontSize: "12px",
            fontWeight: 600,
            height: "24px",
          }}
        />
      );
    }
    if (jobStatus && isRoutedJob && source !== Source.TECHNICIAN_DASHBOARD) {
      return (
        <Chip
          label={ProjectJobStatusChipProps[jobStatus].label}
          sx={{
            bgcolor: ProjectJobStatusChipProps[jobStatus].bgcolor,
            color: ProjectJobStatusChipProps[jobStatus].color,
            fontSize: "12px",
            fontWeight: 600,
            height: "24px",
          }}
        />
      );
    }
    if (job.status && !isRoutedJob && source !== Source.TECHNICIAN_DASHBOARD) {
      return (
        <Chip
          label={ProjectJobStatusChipProps[job.status].label}
          sx={{
            bgcolor: ProjectJobStatusChipProps[job.status].bgcolor,
            color: ProjectJobStatusChipProps[job.status].color,
            fontSize: "12px",
            fontWeight: 600,
            height: "24px",
          }}
        />
      );
    }
    if (!isJobDeclined && source === Source.TECHNICIAN_DASHBOARD) {
      return <Chip {...getTechnicianJobCardStatusChipProps(job, true)} />;
    }
    return null;
  };

  return (
    <>
      {!isRoutedJob && fetchingLatestJobRouting ? (
        <Box my={5} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box flexGrow={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
            rowGap={1}
            flexWrap={"wrap"}
          >
            {getStatusChip()}
            {source === Source.TECHNICIAN_DASHBOARD ? (
              <Button
                startIcon={<ShareIcon />}
                variant="contained"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setShareDialogOpen(true);
                }}
              >
                Share
              </Button>
            ) : (
              <Button
                startIcon={<ShareIcon />}
                variant="text"
                sx={{ color: "black", cursor: "pointer" }}
                onClick={() => {
                  setShareDialogOpen(true);
                }}
              >
                Share
              </Button>
            )}
          </Box>
          <Box py={2}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            {job?.type === JobUploadTypes.AUDIO ? (
              <audio
                controls
                playsInline
                controlsList="nodownload"
                style={{
                  width: "100%",
                  borderRadius: 3,
                  background: "white",
                }}
              >
                <source src={`${job.videoUrl}`} type="audio/mp4" />
              </audio>
            ) : job?.type === JobUploadTypes.VIDEO ? (
              <video
                controls
                playsInline
                controlsList="nodownload"
                style={{
                  width: "100%",
                  height: "220px",
                  borderRadius: 3,
                  background: "white",
                }}
              >
                <source src={`${job.videoUrl}#t=0.1`} type="video/mp4" />
              </video>
            ) : null}
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            {job?.sector ? (
              <Box display={"flex"} alignItems={"center"}>
                <Typography variant="subtitle2" width={"50%"}>
                  Service Category
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  width={"50%"}
                >
                  {
                    snakeCaseJobSectors[
                      (newSector ?? job?.sector) as JobSectors
                    ]
                  }
                </Typography>
                {(job.status === JobStatus.OPPORTUNITY ||
                  job.status === JobStatus.SUBMISSION) &&
                source === Source.TECHNICIAN_DASHBOARD ? (
                  <Button
                    sx={{
                      mr: 1,
                    }}
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setModifyJobSectorDialogOpen(true);
                    }}
                  >
                    Modify
                  </Button>
                ) : null}
              </Box>
            ) : null}
            {source === Source.TECHNICIAN_DASHBOARD &&
            !job?.listingExtraInfo?.buyingCompanyId &&
            (job.status === JobStatus.PENDING_REFERRAL_PAYMENT ||
              job.status === JobStatus.ROUTED) ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                my={1}
                py={2}
                px={3}
                borderRadius="20px"
                bgcolor={neutral[100]}
              >
                <Typography variant="caption">
                  🎉 Great referral! Your commission will be on its way soon! 🚀
                </Typography>
                {/* <img
                src="/potential-income.svg"
                height="40px"
                width="42px"
                alt="commission"
              />
              <Typography variant="caption">
                Your Instant Commission Potential
              </Typography>
              <Typography variant="h5">
                $
                {getTechnicianSplit(
                  job,
                  session?.companySectors ?? [],
                  brandLocationProfile?.defaultTechnicianSplit ?? 0.5
                )}
              </Typography> */}
              </Box>
            ) : (
              <Divider />
            )}
            {jobStatus === RoutedJobStatus.EXPIRED ||
            jobStatus === RoutedJobStatus.INACTIVE ||
            jobStatus === RoutedJobStatus.DECLINED ||
            !recommendedProvider ? null : (
              <>
                <Box display={"flex"} alignItems={"start"}>
                  <Typography variant="subtitle2" width={"50%"}>
                    Recommended Provider
                  </Typography>
                  {recommendedProvider ? (
                    <Box
                      width={"50%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Box
                        gap="4px"
                        display="flex"
                        alignItems="flex-start"
                        flexDirection="column"
                        mb={1}
                      >
                        <CompanyAvatar
                          name={recommendedProvider.companyName ?? ""}
                          height="40px"
                          width="40px"
                          logo={recommendedProvider.logoUrl}
                        />
                        <Typography
                          variant="button"
                          sx={{
                            color: "#16B364",
                            textTransform: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedCompanyId(recommendedProvider.id);
                          }}
                        >
                          {recommendedProvider.companyName}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      width={"50%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Typography variant="body2" color="text.secondary">
                        Processing
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Divider />
              </>
            )}
            {source !== Source.TECHNICIAN_DASHBOARD &&
              job?.listingExtraInfo?.createdByCompanyId &&
              job?.listingExtraInfo?.createdByCompanyName && (
                <>
                  <Box display={"flex"} alignItems={"start"}>
                    <Typography variant="subtitle2" width={"50%"}>
                      Recommended by
                    </Typography>
                    <Box
                      width={"50%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      {isRoutedJob || source === Source.CALL_CENTER ? (
                        <Box
                          gap="4px"
                          display="flex"
                          alignItems="flex-start"
                          flexDirection="column"
                          mb={1}
                        >
                          <CompanyAvatar
                            name={
                              job?.listingExtraInfo?.createdByCompanyName ?? ""
                            }
                            height="40px"
                            width="40px"
                            logo={
                              job?.listingExtraInfo?.createdByCompanyLogoUrl
                            }
                          />
                          <Typography
                            variant="button"
                            sx={{
                              color: "#16B364",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedCompanyId(job?.createdByCompanyId);
                            }}
                          >
                            {job?.listingExtraInfo?.createdByCompanyName}
                          </Typography>
                        </Box>
                      ) : null}
                      <Typography variant="body2" color="text.secondary">
                        {job?.listingExtraInfo?.technicianName}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />{" "}
                </>
              )}
            {source !== Source.TECHNICIAN_DASHBOARD &&
              !job?.listingExtraInfo?.createdByCompanyId &&
              job?.listingExtraInfo?.homeownerId && (
                <>
                  <Box display={"flex"} alignItems={"start"}>
                    <Typography variant="subtitle2" width={"50%"}>
                      Recommended by
                    </Typography>
                    <Box
                      width={"50%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      {Object.values(RoutedJobStatus).includes(
                        jobStatus as RoutedJobStatus
                      ) ? (
                        <Box
                          gap="4px"
                          display="flex"
                          alignItems="flex-start"
                          flexDirection="column"
                          mb={1}
                        >
                          <Typography
                            variant="button"
                            sx={{
                              color: "#16B364",
                              textTransform: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              changeTab?.(JobDrawerTabsEnum.HOMEOWNER);
                            }}
                          >
                            {job?.listingExtraInfo?.homeownerName}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  <Divider />{" "}
                </>
              )}
            {!isRoutedJob &&
            (latestJobRouting?.status === RoutedJobStatus.INACTIVE ||
              latestJobRouting?.status === RoutedJobStatus.DECLINED) ? (
              <>
                <Box
                  display={"flex"}
                  alignItems={"start"}
                  gap={1}
                  flexDirection={"column"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"start"}
                    gap={1}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <Typography variant="subtitle2">Declined by</Typography>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Box
                        gap={1}
                        display="flex"
                        alignItems="center"
                        justifyContent={"start"}
                        mb={1}
                      >
                        <CompanyAvatar
                          name={latestJobRouting?.companyName ?? ""}
                          height="40px"
                          width="40px"
                          logo={latestJobRouting?.companyLogoUrl}
                        />
                        <Typography
                          variant="button"
                          sx={{
                            color: "#16B364",
                            textTransform: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedCompanyId(latestJobRouting?.companyId);
                          }}
                        >
                          {latestJobRouting?.companyName}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"start"}
                    flexDirection={"column"}
                    gap={1}
                  >
                    <Typography variant="subtitle2">
                      Reason Job Didn’t Move Forward
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {(latestJobRouting?.status === RoutedJobStatus.INACTIVE
                        ? latestJobRouting.inactiveReason
                        : latestJobRouting.declinedReason) || "No reason found"}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </>
            ) : inactiveReason ? (
              <>
                {" "}
                <Box>
                  <Typography variant="subtitle2">
                    Reason Job Didn’t Move Forward
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {inactiveReason}
                  </Typography>
                </Box>
                <Divider />
              </>
            ) : null}
            {job?.leadPrice ? (
              <>
                <Box display={"flex"} alignItems={"center"}>
                  <Typography variant="subtitle2" width={"50%"}>
                    Paid for the Referral
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    width={"50%"}
                  >
                    {numeral(job?.leadPrice).format("$0,0.00")}
                  </Typography>
                </Box>
                <Divider />
              </>
            ) : null}
            {isRoutedJob &&
            (jobStatus === RoutedJobStatus.PENDING_PAYMENT ||
              job.jobBuyingCost) ? (
              <>
                <Box display={"flex"} alignItems={"center"}>
                  <Typography variant="subtitle2" width={"50%"}>
                    Job Completion Payment
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    width={"50%"}
                  >
                    {jobStatus === RoutedJobStatus.PENDING_PAYMENT
                      ? "Pending"
                      : ""}
                    {job.jobBuyingCost
                      ? `${numeral(
                          job.jobBuyingCost -
                            (job?.leadPrice ? job?.leadPrice : 0)
                        ).format("$0,0.00")}`
                      : ""}
                  </Typography>
                </Box>
                <Divider />
              </>
            ) : null}
            {job?.status === JobStatus.OPPORTUNITY ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={1}
                width={"100%"}
              >
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Box display={"flex"} gap={0.5} alignItems={"center"}>
                    <Typography variant="subtitle2" sx={{ color: "#111927" }}>
                      Job Type
                    </Typography>
                    <ToolTip
                      placement="bottom"
                      message={`A brief title based on the job you uploaded.
                           After analyzing the content, we summarize it
                           into a one-liner. Example: "Faucet
                           Repair" for a plumbing video.`}
                    >
                      <HelpOutline
                        sx={{
                          background: "none",
                          color: "#6C737F",
                          width: "20px",
                          height: "17px",
                        }}
                      />
                    </ToolTip>
                  </Box>
                  <TextField
                    required
                    fullWidth
                    multiline
                    hiddenLabel
                    maxRows={8}
                    id="jobType"
                    name="jobType"
                    margin="normal"
                    InputLabelProps={{ shrink: false }}
                    inputProps={{ minLength: 3, maxLendth: 500 }}
                    helperText={
                      jobType.length < 3
                        ? `Minimum length is 3 characters`
                        : jobType.length > 500
                        ? `Maximum 500 characters`
                        : ""
                    }
                    error={jobType?.length < 3 || jobType?.length > 500}
                    sx={{ my: 0, mx: 0 }}
                    value={jobType}
                    onChange={e => setJobType(e.target.value)}
                  />
                  <Box
                    display={"flex"}
                    width={"100%"}
                    justifyContent={"end"}
                    mt={0}
                  >
                    <Typography
                      fontSize={"12px"}
                      color="text.secondary"
                      fontWeight={400}
                      mr={1}
                    >
                      {jobType?.length ?? 0}/500 Characters
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    sx={{ color: "#111927" }}
                  >
                    Summary
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    multiline
                    hiddenLabel
                    id="summary"
                    name="summary"
                    margin="normal"
                    InputLabelProps={{ shrink: false }}
                    minRows={4}
                    maxRows={8}
                    inputProps={{ minLength: 3, maxLendth: 500 }}
                    helperText={
                      jobSummary.length < 3
                        ? `Minimum length is 3 characters`
                        : jobSummary.length > 500
                        ? `Maximum 500 characters`
                        : ""
                    }
                    error={jobSummary?.length < 3 || jobSummary?.length > 500}
                    sx={{ my: 0, mx: 0, p: 0 }}
                    value={jobSummary}
                    onChange={e => setJobSummary(e.target.value)}
                  />
                  <Box
                    display={"flex"}
                    width={"100%"}
                    justifyContent={"end"}
                    mt={0.5}
                  >
                    <Typography
                      fontSize={"12px"}
                      color="text.secondary"
                      fontWeight={400}
                      mr={1}
                    >
                      {jobSummary?.length ?? 0}/500 Characters
                    </Typography>
                  </Box>
                </Box>
                <Box width={"100%"} display={"flex"} justifyContent={"end"}>
                  <Button
                    variant="contained"
                    disabled={
                      updatingJobSector ||
                      jobType?.length < 3 ||
                      jobSummary?.length < 3
                    }
                    onClick={() => {
                      updateJobSector({
                        jobId: job?.id,
                        summary: jobSummary,
                        jobType,
                      });
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography variant="subtitle2">Summary</Typography>
                <Typography variant="body2" color="text.secondary">
                  {job?.summary}
                </Typography>
              </Box>
            )}
            <Divider />
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="subtitle2" width={"50%"}>
                Date & Time Created
              </Typography>
              <Typography variant="body2" color="text.secondary" width={"50%"}>
                {DateTime.fromFormat(
                  job.createdAt.toString().split(".")[0].split("T").join(" "),
                  "yyyy-MM-dd HH:mm:ss",
                  { zone: "utc" }
                )
                  .toLocal()
                  .toFormat("MM/dd/yy hh:mm a")}
              </Typography>
            </Box>

            <Divider />
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="subtitle2" width={"50%"}>
                Last Activity
              </Typography>
              <Typography variant="body2" color="text.secondary" width={"50%"}>
                {lastActivity}
              </Typography>
            </Box>
            {job.listingExtraInfo?.homeownerDelayedReason ? (
              <>
                <Divider />
                <Box display={"flex"} alignItems={"start"}>
                  <Typography variant="subtitle2" width={"50%"}>
                    Reason for Delay
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    width={"50%"}
                    fontStyle={"italic"}
                    fontSize={"14px"}
                  >
                    {`“${job.listingExtraInfo?.homeownerDelayedReason}”`}
                  </Typography>
                </Box>
              </>
            ) : null}
            {job.listingExtraInfo?.homeownerDeclinedReason ? (
              <>
                <Divider />
                <Box display={"flex"} alignItems={"start"}>
                  <Typography variant="subtitle2" width={"50%"}>
                    Reason for Declining
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    width={"50%"}
                    fontStyle={"italic"}
                    fontSize={"14px"}
                  >
                    {`“${job.listingExtraInfo?.homeownerDeclinedReason}”`}
                  </Typography>
                </Box>
              </>
            ) : null}
            {isDefined(job.jobPromotion) && (
              <>
                <Divider />
                <Box display={"flex"} alignItems={"center"}>
                  <Typography variant="subtitle2" width={"50%"}>
                    Job Promotion
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    width={"50%"}
                  >
                    {job.jobPromotion.title}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          {selectedCompanyId ? (
            <CompanyProfileDrawer
              open={!!selectedCompanyId}
              companyId={selectedCompanyId}
              onClose={() => {
                setSelectedCompanyId(undefined);
              }}
            />
          ) : null}
          {shareDialogOpen ? (
            source === Source.TECHNICIAN_DASHBOARD ? (
              <TechnicianJobShareDialog
                job={job}
                isOpen={shareDialogOpen}
                onClose={() => {
                  setShareDialogOpen(false);
                }}
              />
            ) : (
              <JobShareDialog
                job={job}
                isOpen={shareDialogOpen}
                onClose={() => {
                  setShareDialogOpen(false);
                }}
              />
            )
          ) : null}
          {modifyJobSectorDialogOpen ? (
            <ModifyJobDialog
              job={job}
              isOpen={modifyJobSectorDialogOpen}
              onClose={sector => {
                if (sector) {
                  setNewJobSector(sector);
                  refreshJobs?.();
                }
                setModifyJobSectorDialogOpen(false);
              }}
            />
          ) : null}
        </Box>
      )}
    </>
  );
};
