/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { GoogleAutocomplete } from "@/common/GoogleAutocomplete";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { Job } from "@/company/jobs/types";
import { HomeownerAction } from "@/homeowner/api/useHomeOwnerActOnJob";
import { useHomesContext } from "@/homeowner/contexts/home-context";
import { Home } from "@/homeowner/types";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import {
  UpdateOwnUserPayload,
  useUpdateOwnUser,
} from "@/user/api/useUpdateOwnUser";
import { NAME_REGEX } from "@/utils";

export interface HomeownerJobApproveDialogProps {
  job: Job;
  open: boolean;
  isUpdatingJob?: boolean;
  onClose: () => void;
  actOnJob: (data: HomeownerAction) => Promise<Job>;
}

const validationSchema = yup.object({
  firstName: yup
    .string()
    .matches(NAME_REGEX, "Enter a valid name")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(NAME_REGEX, "Enter a valid name")
    .required("Last name is required"),
  address: yup.string().required("Address is required"),
  allowAutoRouting: yup.boolean().optional().oneOf([true, false]),
  comment: yup.string().optional(),
  acceptedTermsAndConditions: yup
    .boolean()
    .oneOf([true], "You must accept the Terms and Conditions"),
});

export function HomeownerJobApproveDialog(
  props: HomeownerJobApproveDialogProps
) {
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isUpdatingUser, mutateAsync: updateOwnUser } =
    useUpdateOwnUser();

  const [zipCode, setZipCode] = useState("");
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const { session, refreshSession } = useAuth();
  const { open, onClose, actOnJob, job, isUpdatingJob } = props;
  const {
    homes,
    createHome,
    linkHomeToJob,
    refreshHomes,
    isLinkingHomeToJob,
    isFetching: isFetchingHome,
    isCreating: isCreatingHome,
  } = useHomesContext();

  const formik = useFormik({
    initialValues: {
      firstName: session?.user?.firstName
        ? session?.user?.firstName == "first_name"
          ? ""
          : session?.user?.firstName
        : "",
      lastName: session?.user?.lastName
        ? session?.user?.lastName === "last_name"
          ? ""
          : session?.user?.lastName
        : "",
      address: homes?.at(-1)?.streetAddress
        ? homes?.at(-1)?.streetAddress === "street_address"
          ? ""
          : homes?.at(-1)?.streetAddress
        : "",
      allowAutoRouting: true,
      comment: "",
      acceptedTermsAndConditions:
        session?.user?.acceptedTermsAndConditions ?? false,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const { firstName, lastName, address, allowAutoRouting, comment } =
        values;
      try {
        const userUpdateData: UpdateOwnUserPayload = {};
        if (!session?.user?.acceptedTermsAndConditions) {
          userUpdateData.acceptedTermsAndConditions = true;
        }
        if (
          firstName !== session?.user?.firstName ||
          lastName !== session?.user?.lastName
        ) {
          userUpdateData.firstName = firstName;
          userUpdateData.lastName = lastName;
        }
        if (Object.keys(userUpdateData).length > 0) {
          await updateOwnUser(userUpdateData);
        }
        let newHome: Home | null = null;
        if (
          !(homes?.map(home => home.streetAddress) ?? []).includes(
            address as string
          )
        ) {
          // TODO: get data from google api
          newHome = await createHome({
            label: `Home ${(homes?.length ?? 0) + 1}`,
            streetAddress: address as string,
            homeownerId: session?.user?.id ?? 0,
            country: "US",
            ...(zipCode ? { zipCode } : {}),
          });
          refreshHomes();
        }
        const homeId = newHome
          ? newHome.id
          : homes?.filter(home => home.streetAddress === address)[0].id;
        await linkHomeToJob({ jobId: job.id, homeId: homeId ?? 0 });
        try {
          await actOnJob({ action: "APPROVE", comment, allowAutoRouting });
        } catch (error) {
          return;
        }
        refreshSession();
        onClose();
      } catch (error) {
        if (error?.code == "unable_to_retrieve_coordinates_from_address") {
          enqueueSnackbar("Address Not Found", {
            subText: (
              <>
                Please enter a valid address. Questions?{" "}
                <a
                  role="button"
                  style={{
                    textDecorationLine: "underline",
                    color: "#7A271A",
                    cursor: "pointer",
                  }}
                  onClick={event => {
                    event.preventDefault();
                    setContactSupportModalOpen(true);
                  }}
                >
                  Contact support
                </a>
                .
              </>
            ),
            variant: "error",
            autoHideDuration: 10000,
          });
        } else {
          const message = doesErrorHaveMessage(error)
            ? error.message
            : "Unable to update job.";
          enqueueSnackbar({
            message,
            variant: "error",
          });
        }
      }
    },
  });

  const isLoading =
    isUpdatingJob ||
    isUpdatingUser ||
    isCreatingHome ||
    isFetchingHome ||
    isLinkingHomeToJob;

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={() => {
        formik.handleReset(null);
        onClose();
      }}
    >
      <DialogTitle component="div">
        <Typography gutterBottom variant="h5">
          Please confirm your details for this service
        </Typography>
        <Typography variant="body2" color="textSecondary">
          By providing your preferences we can fast track scheduling.
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => {
          formik.handleReset(null);
          onClose();
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ mt: 3, pt: 0, px: 3 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="First Name*"
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              error={formik.touched.comment && Boolean(formik.errors.comment)}
              helperText={formik.touched.comment && formik.errors.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name*"
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <GoogleAutocomplete
              defaultOptions={[
                ...(homes?.map(home => home.streetAddress) ?? []),
              ]}
              selectedValue={formik.values.address ?? ""}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              label="Home Address*"
              onChange={(address: string) => {
                formik.setFieldValue("address", address);
              }}
              onSelect={({ selection, zipCode }) => {
                if (zipCode) {
                  setZipCode(zipCode);
                }
                formik.setFieldValue("address", selection);
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" my={3}>
          <Grid item xs={10} md={11}>
            <Typography gutterBottom variant="overline">
              Contact Trusted Partner
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`If ${
                job.listingExtraInfo?.potentialReferralForCompanyName ??
                "the service provider"
              } can't take on this job to meet your timeline, do you want us to coordinate another trusted partner?`}
            </Typography>
          </Grid>
          <Grid
            xs={2}
            md={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Switch
              defaultChecked
              color="primary"
              value={formik.values.allowAutoRouting}
              onChange={async event => {
                await formik.setFieldValue(
                  "allowAutoRouting",
                  event.target.checked,
                  true
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={1}>
          <TextareaAutosize
            id="comment"
            name="comment"
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "8px",
              borderColor: "#E5E7EB",
              fontSize: "0.875rem",
              fontWeight: 400,
              lineHeight: 1.57,
              fontFamily:
                '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
              color: " #6C737F",
            }}
            color="primary"
            value={formik.values.comment}
            minRows={5}
            placeholder="Share more details with the recommended service provider here (optional)"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        {!session?.user?.acceptedTermsAndConditions ? (
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <FormControlLabel
                value={formik.values.acceptedTermsAndConditions}
                control={
                  <Checkbox
                    name="acceptedTermsAndConditions"
                    checked={Boolean(formik.values.acceptedTermsAndConditions)}
                    onChange={formik.handleChange}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      I have read and accepted the{" "}
                      <Link
                        color="#16B364"
                        href={"/open/terms-of-service"}
                        rel="noreferrer"
                      >
                        <Typography
                          py={4}
                          display="inline"
                          variant="subtitle2"
                          color="#16B364"
                        >
                          Terms and Conditions
                        </Typography>
                      </Link>
                      .
                    </Typography>
                  </Box>
                }
              />
              {formik.touched.acceptedTermsAndConditions &&
                formik.errors.acceptedTermsAndConditions && (
                  <FormHelperText error>
                    {formik.errors.acceptedTermsAndConditions}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions>
        {!isUpdatingJob && (
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={() => {
              formik.handleReset(null);
              onClose();
            }}
          >
            No, Cancel
          </Button>
        )}
        <LoadingButton
          loading={isLoading}
          size="small"
          variant="contained"
          color="primary"
          disabled={!formik.isValid}
          onClick={() => formik.handleSubmit()}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
