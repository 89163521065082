import { useQueryClient } from "@tanstack/react-query";
import constate from "constate";
import { useCallback, useEffect, useState } from "react";

import { Chat, useFetchChats } from "../api/useFetchChats";
import { USE_GET_UNREAD_CHATS_COUNT_URI } from "../api/useFetchUnreadMessagesCount";
import { useMarkChatsWithCompanyAsRead } from "../api/useMarkChatsWithCompanyAsRead";
import { useUpdateChat } from "../api/useUpdateChat";

function useChatDetails({ companyId }: { companyId: number }) {
  const queryClient = useQueryClient();
  const [chats, setChats] = useState<Array<Chat>>([]);

  const {
    data: chatsResponse,
    isLoading,
    // refetch: refetchChats,
  } = useFetchChats(companyId);

  const { mutateAsync: updateChatMutateFn } = useUpdateChat();
  const {
    isLoading: isMarkingChatsAsRead,
    mutateAsync: markChatsWithCompanyAsReadMutateFn,
  } = useMarkChatsWithCompanyAsRead();

  const markChatsWithCompanyAsRead = useCallback(
    async (chatsResponse: Chat[]) => {
      const unreadChats = chatsResponse.filter(
        chat => chat.fromCompanyId == companyId && !chat.toCompanyReadAt
      );
      if (unreadChats.length > 0) {
        await markChatsWithCompanyAsReadMutateFn(companyId);
        queryClient.invalidateQueries({
          queryKey: [USE_GET_UNREAD_CHATS_COUNT_URI],
        });
        // refetchChats();
      }
    },
    [companyId, queryClient]
  );

  useEffect(() => {
    if (chatsResponse && chatsResponse.length > 0) {
      setChats(chatsResponse);

      queryClient.invalidateQueries({
        queryKey: [USE_GET_UNREAD_CHATS_COUNT_URI],
      });
      markChatsWithCompanyAsRead(chatsResponse);
    }
  }, [chatsResponse]);

  const updateChat = async (message: string) => {
    const chat = {
      toCompanyId: companyId,
      message,
    };
    const chatResult = await updateChatMutateFn(chat);
    const newChats = [...chats];
    newChats.push(chatResult);
    setChats(newChats);
  };

  return {
    chats,
    isLoading: isLoading || isMarkingChatsAsRead,
    updateChat,
    markChatsWithCompanyAsRead,
  };
}

export const [ChatProvider, useChatContext] = constate(useChatDetails);
