import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import _ from "lodash";
import * as React from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export interface GoogleAutocompleteProps {
  onChange: (address: string) => void;
  onSelect: ({
    selection,
    zipCode,
    state,
    city,
    latitude,
    longitude,
  }: {
    selection: string | PlaceType;
    zipCode?: string;
    city?: string;
    state?: string;
    latitude?: number;
    longitude?: number;
  }) => void;
  selectedValue: string;
  defaultOptions?: Array<string | PlaceType>;
  disabled?: boolean;
  label?: string;
  helperText?: string | false;
  error?: boolean;
  onBlur?: (event: any) => void;
  fullWidth?: boolean;
}

export const GoogleAutocomplete: React.FC<GoogleAutocompleteProps> = ({
  onSelect,
  onChange,
  selectedValue,
  defaultOptions = [],
  disabled,
  onBlur,
  label,
  helperText,
  error,
  fullWidth,
}) => {
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const getPostalCode = (place: google.maps.places.PlaceResult | null) => {
    const addressComponents = place?.address_components || [];
    const postalCodeComponent = addressComponents.find(component =>
      component.types.includes("postal_code")
    );
    const posCode = postalCodeComponent ? postalCodeComponent.short_name : null;
    return postalCodeComponent ? postalCodeComponent.short_name : null;
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    // apiKey: AppConstants.googleApiKey,
  });

  React.useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length) {
      setOptions(placePredictions);
    }
  }, [placePredictions]);

  return (
    <Autocomplete
      autoComplete
      freeSolo
      includeInputInList
      filterSelectedOptions
      id="google-map-autocomplete"
      sx={{ minWidth: 200 }}
      getOptionLabel={option =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={x => x}
      options={[..._.compact(defaultOptions), ...options]}
      disabled={disabled}
      value={selectedValue}
      noOptionsText="No locations"
      renderInput={params => (
        <TextField
          {...params}
          // {...(onBlur ? { onBlur } : {})}
          label={label ?? "Add a location"}
          onBlur={event => {
            if (onBlur) {
              onBlur(event);
            }
          }}
          {...(fullWidth ? { fullWidth: !!fullWidth } : {})}
          {...(helperText ? { helperText } : {})}
          {...(error ? { error } : {})}
          onChange={event => onChange(event.target.value)}
        />
      )}
      renderOption={(props, option) => {
        if (typeof option === "string")
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 40 }}>
                  <FmdGoodOutlinedIcon
                    sx={{
                      color: "text.secondary",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {option}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 40 }}>
                <FmdGoodOutlinedIcon
                  sx={{
                    color: "text.secondary",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Typography
                    key={index}
                    variant="subtitle2"
                    display={"inline"}
                    color="text.primary"
                  >
                    {part.text}
                  </Typography>
                  // <Box
                  //   key={index}
                  //   component="span"
                  //   sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  // >

                  // </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      onChange={(
        event: any,
        newValue: google.maps.places.AutocompletePrediction | null,
        reason
      ) => {
        if (reason === "clear" || newValue == "") {
          onSelect({
            selection: "",
            zipCode: undefined,
            latitude: undefined,
            longitude: undefined,
          });
        }
        if (newValue?.place_id) {
          placesService?.getDetails(
            {
              placeId: newValue.place_id,
            },
            placeDetails => {
              onSelect({
                selection: newValue?.description ?? newValue ?? "",
                zipCode: getPostalCode(placeDetails) ?? undefined,
                latitude: placeDetails?.geometry?.location?.lat(),
                longitude: placeDetails?.geometry?.location?.lng(),
              });
            }
          );
        } else if (newValue) {
          onSelect({
            selection: newValue ?? "",
          });
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue?.length > 3) {
          getPlacePredictions({
            input: newInputValue,
          });
          return;
        }
        setOptions([]);
      }}
    />
  );
};
