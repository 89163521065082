import { CircularProgress, Theme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyRoutes } from "@/company/types";

import { useGetJobsStatistics } from "../api/useGetJobsStatistics";
import { AnalyticsStats } from "../components/AnalyticsStats";
import { ReferralStatistics } from "../components/ReferralStatistics";
import { calculateBusinessDays, formatNumber } from "./utils";

const AnalyticsPage = () => {
  const {
    data: jobStatistics,
    refetch: refetchStatistics,
    isLoading,
  } = useGetJobsStatistics({
    cacheTime: 0, // Do not keep cache
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const { session } = useAuth();
  const navigate = useNavigate();

  const familyOfBrands = session?.familyOfBrands;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Container disableGutters maxWidth="100%" sx={{ bgcolor: "white" }}>
      <Grid container spacing={1} bgcolor={"white"}>
        {isMobile ? null : (
          <Grid
            item
            xs={12}
            sx={{
              pt: 2,
              pb: 2,
              top: "56px",
              zIndex: 1000,
            }}
            position={"sticky"}
            bgcolor={"white"}
          >
            <Typography variant="h5">Reporting & Analytics</Typography>
            {isLoading ? (
              <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
                Fetching Details
              </Typography>
            ) : familyOfBrands?.name ? (
              <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
                You’ve earned{" "}
                <b>
                  ${formatNumber(jobStatistics?.totalJobsEarnings ?? 0)}{" "}
                  {jobStatistics?.firstJobPaidAt
                    ? ` in less
                than 
                ${calculateBusinessDays(jobStatistics?.firstJobPaidAt ?? "")} 
                business days `
                    : " "}
                </b>
                on our platform! Earn more from other local partners! Click{" "}
                <Typography
                  display="inline"
                  variant="body2"
                  color="#16B364"
                  fontWeight={600}
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `${getNavigationPathFromRoutePaths([
                        CompanyRoutes.BASE,
                        CompanyRoutes.EXPLORE_PARTNERS,
                      ])}?exploreTab=affiliate-companies`
                    )
                  }
                >
                  here
                </Typography>{" "}
                to connect with your{" "}
                <Typography variant="body2" display={"inline"} fontWeight={600}>
                  {familyOfBrands.name}
                </Typography>{" "}
                affiliates and other relevant companies.
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
                You’ve earned $
                {formatNumber(jobStatistics?.totalJobsEarnings ?? 0)}
                {jobStatistics?.firstJobPaidAt
                  ? ` in less
                than 
                ${calculateBusinessDays(jobStatistics?.firstJobPaidAt ?? "")} 
                days `
                  : " "}
                on our platform! Earn more from other local partners!
              </Typography>
            )}
          </Grid>
        )}
        {
          <Container maxWidth={"xl"} sx={{ mt: 4 }}>
            <Grid
              container
              spacing={{
                xs: 3,
                lg: 4,
              }}
            >
              {isLoading ? (
                <Box
                  my={5}
                  textAlign="center"
                  width={"100%"}
                  justifyContent={"center"}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Grid xs={12} md={4}>
                    <AnalyticsStats
                      action={
                        <Typography color="text.secondary" variant="body2">
                          Info Text
                        </Typography>
                      }
                      title="Total Revenue from Referrals"
                      value={`$${formatNumber(
                        jobStatistics?.totalJobsEarnings ?? 0
                      )}`}
                    />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <AnalyticsStats
                      action={
                        <Typography color="text.secondary" variant="body2">
                          Info Text
                        </Typography>
                      }
                      title="Cost Per Acquisition (CPA)"
                      value={`${formatNumber(
                        jobStatistics?.costPerAcquisition ?? 0,
                        true
                      )}%`}
                    />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <AnalyticsStats
                      action={
                        <Typography color="text.secondary" variant="body2">
                          Info Text
                        </Typography>
                      }
                      title="Inbound Referral Conversion"
                      value={`${formatNumber(
                        jobStatistics?.conversionRatio ?? 0,
                        true
                      )}%`}
                    />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <AnalyticsStats
                      action={
                        <Typography color="text.secondary" variant="body2">
                          Info Text
                        </Typography>
                      }
                      toolTip="Time savings for dispatchers, CSRs & 
                  managers by eliminating patch-workflows such as text messaging, 
                  spreadsheet tracking/calculations, check/cash delivery, and 
                  homeowner coordination. This conservatively assumes 45 minute 
                  savings per job and $50 per hour labor costs."
                      title="Efficiency Cost Savings"
                      value={`$${formatNumber(
                        jobStatistics?.efficiencyCostSavings ?? 0,
                        false
                      )}`}
                    />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <AnalyticsStats
                      action={
                        <Typography color="text.secondary" variant="body2">
                          Info Text
                        </Typography>
                      }
                      title="Number of Referrals Received"
                      value={`${formatNumber(
                        jobStatistics?.jobsNotExpired ?? 0
                      )}`}
                    />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <AnalyticsStats
                      action={
                        <Typography color="text.secondary" variant="body2">
                          Info Text
                        </Typography>
                      }
                      title="Fees Paid to Technicians"
                      value={`$${formatNumber(
                        jobStatistics?.techniciansFees ?? 0
                      )}`}
                    />
                  </Grid>
                </>
              )}

              <Grid xs={12} lg={12}>
                <ReferralStatistics onJobPaid={refetchStatistics} />
              </Grid>
            </Grid>
          </Container>
        }
      </Grid>
    </Container>
  );
};

export default AnalyticsPage;
