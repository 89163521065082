import { Home } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { getDefaultLoginRedirectTo } from "@/auth/utils";
import { Footer } from "@/common/footer/Footer";
import { TopNav } from "@/common/top-nav";

export function OpenLayout({ children }) {
  const { session } = useAuth();

  const location = useLocation();

  // Check if the current path is the landing path
  const landingPath = "/";
  const isLandingPath = location.pathname === landingPath;

  const accountButtons = [
    {
      label: "Home",
      link: getDefaultLoginRedirectTo(session),
      icon: <Home />,
    },
  ];

  return (
    <Box
      sx={{
        position: { xs: "relative" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopNav
        showTradeengageLogo
        accountButtons={accountButtons}
        isMarketingPage={isLandingPath}
      />
      <Box flexGrow={1}>{children}</Box>
      <Footer />
    </Box>
  );
}
