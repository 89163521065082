import { Close as CloseIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  debounce,
  Dialog,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useMemo, useState } from "react";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useCreateReferralInvitations } from "@/company/api/useCreateReferralInvitations";
import { useGetAllBrandLocations } from "@/company/api/useGetAllBrandLocations";
import { BasicCompanySchema } from "@/company/api/useGetAllBrands";
import { neutral } from "@/Theme/colors";

import { SEARCH_MINIMUM_CHAR_COUNT } from "./constants";

export interface CreateReferralInvitationDialogProps {
  open: boolean;
  referrerCompanyId: number;
  onClickConfirm: () => void;
  onClickCancel: () => void;
  onClickClose: () => void;
  refetch: () => void;
}

export function CreateReferralInvitationByCompanyIdDialog(
  props: CreateReferralInvitationDialogProps
) {
  const { open, onClickCancel, onClickClose, refetch, referrerCompanyId } =
    props;

  const { enqueueSnackbar } = useSnackbar();

  const [referredKeyword, setReferredKeyword] = useState("");

  const debouncedReferredSetter = useMemo(
    () =>
      debounce(
        (referredKeyword: string) => setReferredKeyword(referredKeyword),
        500
      ),
    []
  );

  const { mutateAsync: createReferralInvitation, isLoading } =
    useCreateReferralInvitations({
      onSuccess: () => {
        enqueueSnackbar({
          message: "Created referral invitation successfully.",
          variant: "success",
        });
        onClickClose();
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while creating referral invitation.";
        enqueueSnackbar({
          message,
          includeContactSupportText: false,
          variant: "error",
        });
      },
    });

  const [selectedReferred, setSelectedReferred] =
    useState<BasicCompanySchema>();

  const {
    data: allReferredbrandLocations,
    isLoading: isLoadingReferredBrandLocations,
  } = useGetAllBrandLocations(referredKeyword, { enabled: !!referredKeyword });

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      sx={{ p: 2 }}
      onClose={() => onClickClose()}
    >
      <Box width="100%" p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Box>
            <Typography gutterBottom variant="h5">
              Create Referral Invitations
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary">
              Select the referred companies from dropdown.
            </Typography>
          </Box>
          <IconButton onClick={() => onClickClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          mt={3}
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-start"}
          border={1}
          borderRadius={"20px"}
          p={3}
          borderColor={neutral[200]}
          width="100%"
        >
          <Autocomplete
            sx={{ width: "100%" }}
            id="referred"
            options={allReferredbrandLocations ?? []}
            value={selectedReferred}
            getOptionLabel={option => `id=${option.id}, name=${option.name}`}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                value={selectedReferred}
                margin="normal"
                InputLabelProps={{
                  style: { marginBottom: "8px" },
                }}
                label="Select Referred Company"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} key={option} value={option}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  {
                    <Typography>{`id=${option.id}, name=${option.name}`}</Typography>
                  }
                  {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
                </Box>
              </MenuItem>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  size="small"
                  label={`id=${option.id}, name=${option.name}`}
                  {...getTagProps({ index })}
                  variant="outlined"
                />
              ))
            }
            onInputChange={(_: unknown, newInputValue: string) => {
              if (newInputValue.length >= SEARCH_MINIMUM_CHAR_COUNT) {
                debouncedReferredSetter(newInputValue);
              }
            }}
            onChange={(_: SyntheticEvent, newValue) =>
              setSelectedReferred(newValue)
            }
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={3}
          mt={2}
        >
          <Button color="inherit" variant="outlined" onClick={onClickCancel}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={!selectedReferred}
            onClick={() => {
              createReferralInvitation({
                referrerId: referrerCompanyId,
                referredId: selectedReferred?.id,
              });
            }}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
}
