import "./styles.css"; // Import your custom styles

import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhonelinkLockOutlinedIcon from "@mui/icons-material/PhonelinkLockOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import {
  PLATFORM_PRESENCE,
  ReferralInvitation,
  ReferralInvitationActions,
} from "@/company/types";

import { CompanyAvatar } from "../company-avatar/CompanyAvatar";
import { TabsEnum } from "../company-drawer/CompanyDrawer";
import { MoreMenuMessaging } from "./MoreMenuMessaging";
import { getCompanySectorsChip } from "./utils";

export interface CompanyInvitationProps {
  referralInvitation: ReferralInvitation;
  onOpen?: (tab?: TabsEnum) => void;
  isFirstOffPlatformCompany?: boolean;
  onMessageMenuClick?: () => void;
  showTooltip?: boolean;
}

export function CompanyInvitationCard(props: CompanyInvitationProps) {
  const {
    referralInvitation,
    onOpen,
    onMessageMenuClick,
    isFirstOffPlatformCompany,
    showTooltip,
  } = props;

  const [invitationAccepted, setInvitationAccepted] = useState(false);

  const { session } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id, {
      refetchOnWindowFocus: false,
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];

  const { updateReferralInvitation } = useReferralInvitationContext();

  let companyProfile = referralInvitation?.otherCompanyProfile;

  const { data: companyProfileV2, isLoading } = useFetchCompanyProfile(
    companyProfile.id,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (!isLoading && companyProfileV2) {
    companyProfile = companyProfileV2;
  }

  if (!companyProfile) return null;

  return (
    <>
      <Card
        sx={{
          cursor: "pointer",
          boxShadow: "0px 0px 0px 1px #F2F4F7",
          "&:active": {
            border: "1px solid #071D2F",
          },
          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
          "&:focus": {
            border: "1px solid #071D2F",
          },
        }}
        onClick={event => {
          if (companyProfile) {
            onOpen?.(TabsEnum.OVERVIEW);
            return;
          }
          onOpen?.();
        }}
      >
        <Box
          sx={{ display: "flex", px: 2, pt: 2 }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <CompanyAvatar
            height={"40px"}
            logo={companyProfile.logoUrl}
            name={companyProfile.name ?? ""}
          />
        </Box>
        <CardContent sx={{ p: 0, px: 2, pb: 2 }}>
          <Box display={"flex"} gap={1} py={1} flexWrap={"wrap"}>
            {getCompanySectorsChip(companyProfile?.sectors)}
          </Box>
          {/* </Grid> */}
          <Grid container>
            <Grid item xs={12} display={"flex"} alignItems={"end"}>
              <Typography display="inline" variant="h6">
                {" "}
                {companyProfile.name}
                {companyProfile.platformPresence ===
                PLATFORM_PRESENCE.ON_PLATFORM ? (
                  <ToolTip
                    placement="bottom"
                    title="TradeEngage Participant"
                    message="This is a verified active business on TradeEngage."
                  >
                    <VerifiedOutlinedIcon
                      color="primary"
                      fontSize="small"
                      sx={{ ml: 1, verticalAlign: "bottom", color: "#16DB65" }}
                    />
                  </ToolTip>
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          <Box display={"flex"} flexDirection={"column"} py={1}>
            <Box display={"flex"} gap={1}>
              {companyProfile.sectors.some(
                sector =>
                  relevanceOrderedJobSectorsList?.includes(sector.sector) ||
                  recommendedJobSectorsList?.includes(sector.sector)
              ) ? (
                <Box display={"flex"} alignItems={"center"}>
                  <img
                    src="/top-referral.svg"
                    alt="top referral category"
                    style={{ marginRight: "8px" }}
                  />
                  <Typography
                    variant="caption"
                    color="primary"
                    alignItems={"center"}
                  >
                    Top Referral Category
                  </Typography>
                </Box>
              ) : null}
              <Box display={"flex"} alignItems={"center"}>
                {companyProfile.owners?.[0]?.firstName ? (
                  <>
                    <PersonOutlineOutlinedIcon
                      color="primary"
                      sx={{ width: "16px", mr: "2px" }}
                    />{" "}
                    <Typography
                      variant="caption"
                      color="primary"
                      alignItems={"center"}
                    >
                      {`${companyProfile.owners?.[0]?.firstName ?? ""} ${
                        companyProfile.owners?.[0]?.lastName ?? ""
                      }`}
                    </Typography>
                  </>
                ) : null}
                {companyProfile.owners?.[0]?.hasPhone ? (
                  <ToolTip placement="bottom" message="Mobile Number Verified">
                    <PhonelinkLockOutlinedIcon
                      color="primary"
                      fontSize="small"
                      sx={{ width: "16px" }}
                    />
                  </ToolTip>
                ) : null}
                {companyProfile.owners?.[0]?.hasEmail ? (
                  <ToolTip placement="bottom" message="Email Address Verified">
                    <MarkEmailReadOutlinedIcon
                      color="primary"
                      fontSize="small"
                      sx={{ width: "16px" }}
                    />
                  </ToolTip>
                ) : null}
              </Box>
            </Box>
          </Box>
          {referralInvitation.chat ? (
            <Card
              sx={{
                bgcolor: "#F8F9FA",
                borderRadius: "10px",
                boxShadow: "none",
              }}
            >
              <Box
                display={"flex"}
                p={2}
                justifyContent={"space-between"}
                onClick={event => {
                  event.stopPropagation();
                  onOpen?.(TabsEnum.MESSAGE);
                }}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    textOverflow: "ellipsis",
                    fontStyle: "italic",
                    fontSize: "12px",
                  }}
                >
                  {company?.id === referralInvitation.chat?.toCompanyId
                    ? companyProfile?.owners?.[0]?.firstName ??
                      companyProfile.name
                    : "You"}{" "}
                  sent{" "}
                  {company?.id === referralInvitation.chat?.fromCompanyId
                    ? companyProfile?.owners?.[0]?.firstName ??
                      companyProfile.name
                    : "you"}{" "}
                  a message...
                </Typography>
                <Typography
                  color="primary"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  See More
                </Typography>
              </Box>
            </Card>
          ) : null}
        </CardContent>
        <Divider sx={{ width: "100%", height: "2px" }} />
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          sx={{ p: 2, width: "100%" }}
        >
          {company?.id === referralInvitation.invitingCompanyId ? (
            referralInvitation.otherCompanyProfile.platformPresence ===
            PLATFORM_PRESENCE.OFF_PLATFORM ? (
              <Box>
                {isFirstOffPlatformCompany ? (
                  <MoreMenuMessaging
                    showToolTip={showTooltip}
                    companyProfile={referralInvitation.otherCompanyProfile}
                    onToolTipClose={() => {
                      onMessageMenuClick?.();
                    }}
                    onClick={() => {
                      onMessageMenuClick?.();
                    }}
                    onMessageClick={() => {
                      onOpen?.(TabsEnum.MESSAGE);
                    }}
                  />
                ) : (
                  <MoreMenuMessaging
                    showToolTip={false}
                    companyProfile={referralInvitation.otherCompanyProfile}
                    onToolTipClose={() => {
                      onMessageMenuClick?.();
                    }}
                    onClick={() => {
                      onMessageMenuClick?.();
                    }}
                    onMessageClick={() => {
                      onOpen?.(TabsEnum.MESSAGE);
                    }}
                  />
                )}
              </Box>
            ) : (
              <Button
                size="small"
                variant="outlined"
                startIcon={<ChatBubbleOutlineOutlinedIcon />}
                onClick={event => {
                  event.stopPropagation();
                  onOpen?.(TabsEnum.MESSAGE);
                }}
              >
                Send a Message
              </Button>
            )
          ) : null}
          {invitationAccepted ? (
            <Button
              size="small"
              variant="outlined"
              sx={{ borderRadius: "8px" }}
              startIcon={
                referralInvitation.chat &&
                company?.id === referralInvitation.chat?.toCompanyId ? (
                  <MarkChatUnreadOutlinedIcon fontSize="small" />
                ) : (
                  <ChatBubbleOutlineOutlinedIcon />
                )
              }
              onClick={event => {
                event.stopPropagation();
                onOpen?.(TabsEnum.MESSAGE);
              }}
            >
              {referralInvitation.chat &&
              company?.id === referralInvitation.chat?.toCompanyId
                ? "Reply Now"
                : "Send a Message"}
            </Button>
          ) : company?.id === referralInvitation.invitedCompanyId ? (
            <>
              <Button
                sx={{ borderRadius: "8px" }}
                size="small"
                variant="contained"
                onClick={async event => {
                  event.stopPropagation();
                  await updateReferralInvitation({
                    invitationId: referralInvitation.id,
                    action: ReferralInvitationActions.ACCEPT,
                    shouldRefresh: false,
                  });
                  setInvitationAccepted(true);
                  enqueueSnackbar({
                    message: `${referralInvitation.otherCompanyProfile.name} is now a
                    Referral Partner.`,
                    variant: "success",
                    subText: (
                      <Typography
                        sx={{
                          color: "#16DB65",
                          fontSize: "14px",
                          fontWeight: 600,
                          pr: 2,
                          cursor: "pointer",
                        }}
                        onClick={event => {
                          event.stopPropagation();
                          onOpen?.(TabsEnum.MESSAGE);
                        }}
                      >
                        {referralInvitation.chat &&
                        company?.id === referralInvitation.chat?.toCompanyId
                          ? "Reply Now"
                          : "Send a Message"}
                      </Typography>
                    ),
                  });
                }}
              >
                Accept
              </Button>
              <Button
                size="small"
                sx={{ borderRadius: "8px" }}
                variant="outlined"
                onClick={async event => {
                  event.stopPropagation();
                  await updateReferralInvitation({
                    invitationId: referralInvitation.id,
                    action: ReferralInvitationActions.IGNORE,
                  });
                  enqueueSnackbar({
                    message: `You have ignored the invitation from 
                    ${referralInvitation.otherCompanyProfile.name}.`,
                    variant: "success",
                  });
                }}
              >
                Ignore
              </Button>
            </>
          ) : null}
        </Box>
      </Card>
    </>
  );
}
