import constate from "constate";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export const LEAD_DATA_STORAGE_KEY = "leads";

function useOnboardingReferralSkipped() {
  const location = useLocation();
  const [outboundSkipped, setOutboundSkipped] = useState(false);
  const [inboundSkipped, setInboundSkipped] = useState(false);

  return {
    outboundSkipped,
    inboundSkipped,
    setInboundSkipped,
    setOutboundSkipped,
  };
}

export const [
  OnboardingReferralSkippedProvider,
  useOnboardingReferralSkippedContext,
] = constate(useOnboardingReferralSkipped);
