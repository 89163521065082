import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputBaseComponentProps,
  Link,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { ElementType } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { getDefaultLoginRedirectTo } from "@/auth/utils";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { Seo } from "@/common/Seo/Seo";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { PLATFORM_PRESENCE } from "@/company/types";
import { useUpdateOwnUser } from "@/user/api/useUpdateOwnUser";

const validationSchema = yup.object({
  acceptedTermsAndConditions: yup
    .boolean()
    .oneOf([true], "You must accept the Terms and Conditions"),
  // acceptedWhatsappMessagingTerms: yup
  //   .boolean()
  //   .oneOf(
  //     [true],
  //     "You must agree to receive messages on WhatsApp to submit job videos"
  //   ),
});

export function CompanyTeamMemberPersonalOnboardingPage() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { session, refreshSession } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { isLoading: isUpdating, mutateAsync: updateOwnUser } =
    useUpdateOwnUser();

  const formik = useFormik({
    initialValues: {
      // acceptedWhatsappMessagingTerms: true,
      acceptedTermsAndConditions: false,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      updateUser(values);
    },
  });

  const updateUser = async (values: {
    acceptedWhatsappMessagingTerms: boolean;
    acceptedTermsAndConditions: boolean;
  }) => {
    try {
      await updateOwnUser(values);
      refreshSession();
      navigate(getDefaultLoginRedirectTo(session));
    } catch (error: unknown) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while saving your data.";
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  if (
    session?.user?.acceptedTermsAndConditions &&
    session.user?.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM
  ) {
    navigate(getDefaultLoginRedirectTo(session));
  }

  return (
    <>
      <Seo title="Account Setup" />
      <Box p={2}>
        {isMobile ? (
          <Box textAlign={"center"} my={2}>
            <TradeEngageLogo logotype={TradeEngageLogoTypes.LOGO_DARK} />
          </Box>
        ) : null}
        <Box mb={3}>
          <Typography variant="h5">Set up your account</Typography>
          <Typography variant="body2" color="text.secondary">
            Please review the details provided by the {session?.company?.name}{" "}
            team
          </Typography>
        </Box>
        <Box>
          <TextField
            fullWidth
            disabled
            aria-readonly
            label="First Name"
            name="firstName"
            value={session?.user?.firstName}
            sx={{ my: 1 }}
          />
          <TextField
            fullWidth
            disabled
            aria-readonly
            label="Last Name"
            name="lastName"
            value={session?.user?.lastName}
            helperText="Not you? Please contact your admin to update"
            sx={{ my: 1 }}
          />
          <TextField
            fullWidth
            disabled
            aria-readonly
            label="Mobile Number"
            name="cellPhone"
            value={session?.user?.cellPhone}
            sx={{ my: 1 }}
            InputProps={{
              inputComponent: MaskedPhoneInput as unknown as
                | ElementType<InputBaseComponentProps>
                | undefined,
            }}
          />
          <FormControl sx={{ my: 1, width: "100%" }}>
            <FormControlLabel
              value={formik.values.acceptedTermsAndConditions}
              control={
                <Checkbox
                  name="acceptedTermsAndConditions"
                  checked={Boolean(formik.values.acceptedTermsAndConditions)}
                  onChange={formik.handleChange}
                />
              }
              label={
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    I have read and accepted the{" "}
                    <Link
                      color="#16B364"
                      href={"/open/terms-of-service"}
                      rel="noreferrer"
                    >
                      <Typography
                        py={4}
                        display="inline"
                        variant="subtitle2"
                        color="#16B364"
                      >
                        Terms and Conditions
                      </Typography>
                    </Link>
                    .
                  </Typography>
                </Box>
              }
            />
            {formik.errors.acceptedTermsAndConditions && (
              <FormHelperText error>
                {formik.errors.acceptedTermsAndConditions}
              </FormHelperText>
            )}
          </FormControl>
          {/* <FormControl sx={{ my: 1, width: "100%" }}>
            <FormControlLabel
              value={formik.values.acceptedWhatsappMessagingTerms}
              control={
                <Checkbox
                  name="acceptedWhatsappMessagingTerms"
                  checked={Boolean(
                    formik.values.acceptedWhatsappMessagingTerms
                  )}
                  onChange={formik.handleChange}
                />
              }
              label={
                <Box>
                  <Typography variant="body2">
                    {
                      "I agree to receive messages on WhatsApp. Don't have WhatsApp? No problem"
                    }
                  </Typography>
                </Box>
              }
            />
            {formik.errors.acceptedWhatsappMessagingTerms && (
              <FormHelperText error>
                {formik.errors.acceptedWhatsappMessagingTerms}
              </FormHelperText>
            )}
          </FormControl> */}
        </Box>
        <LoadingButton
          fullWidth
          variant="contained"
          disabled={
            !formik.isValid ||
            (!formik.dirty && !Object.keys(formik.touched).length)
          }
          loading={isUpdating}
          sx={{ mt: 3, mb: 2 }}
          onClick={() => formik.handleSubmit()}
        >
          Continue
        </LoadingButton>
      </Box>
    </>
  );
}
