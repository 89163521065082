import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import MobileFriendlyOutlinedIcon from "@mui/icons-material/MobileFriendlyOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { Box, Chip, Divider, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FC } from "react";

import { useAuth } from "@/auth/context/jwt";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { JobSectors } from "@/company/jobs/constants";
import {
  BRAND_CATEGORY,
  CompanyPublicProfile,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { AffiliateCompanies } from "./AffiliateCompanies";

interface OverviewProps {
  companyProfile: CompanyPublicProfile;
}

export const Overview: FC<OverviewProps> = ({ companyProfile }) => {
  const { session } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id ?? 0, {
      refetchOnWindowFocus: false,
      enabled: Boolean(company?.id),
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];

  return (
    <>
      <Box display={"flex"} py={1} flexDirection={"column"}>
        <Typography variant="h5">
          {companyProfile.name}
          {companyProfile.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM ? (
            <ToolTip
              placement="bottom"
              title="TradeEngage Participant"
              message="This is a verified active business on TradeEngage."
            >
              <VerifiedOutlinedIcon
                sx={{ verticalAlign: "center", color: "#16DB65", ml: 1 }}
              />
            </ToolTip>
          ) : null}
        </Typography>
        {companyProfile.description ? (
          <Typography variant="body2" color="text.secondary" pt={2}>
            {companyProfile.description}
          </Typography>
        ) : null}
      </Box>
      <Box>
        <Typography variant="overline" color="rgba(17, 25, 39, 0.38)" py={1}>
          SERVICES
        </Typography>
        {companyProfile.sectors?.length ? (
          <Box display={"flex"} gap={1} flexWrap={"wrap"}>
            {companyProfile.sectors.map(sector => (
              <Chip
                key={sector.sector}
                label={
                  snakeCaseJobSectors[sector.sector] ??
                  snakeCaseJobSectors[JobSectors.OTHER]
                }
                color="default"
                sx={{ mr: 1 }}
              />
            ))}
          </Box>
        ) : null}
      </Box>
      <Divider sx={{ my: 1.5 }} />{" "}
      {companyProfile.profile.brandCategory === BRAND_CATEGORY.FRANCHISE ||
      companyProfile.sectors.some(
        sector =>
          relevanceOrderedJobSectorsList?.includes(sector.sector) ||
          recommendedJobSectorsList?.includes(sector.sector)
      ) ||
      companyProfile?.yearEstablished ||
      companyProfile?.numEmployees ? (
        <Box>
          <Typography
            variant="overline"
            color="rgba(17, 25, 39, 0.38)
"
          >
            OVERVIEW
          </Typography>
          {companyProfile.profile.brandCategory === BRAND_CATEGORY.FRANCHISE ? (
            <Box>
              <Chip label={"Franchise"} color="primary" size="small" />
            </Box>
          ) : null}
          <Box display={"flex"} flexDirection={"column"} py={1} gap={1}>
            {companyProfile.sectors.some(
              sector =>
                relevanceOrderedJobSectorsList?.includes(sector.sector) ||
                recommendedJobSectorsList?.includes(sector.sector)
            ) ? (
              <Box display={"flex"}>
                <img
                  src="/top-referral.svg"
                  alt="top referral category"
                  style={{ marginRight: "8px" }}
                />
                <Typography variant="body2" color="primary">
                  Top Referral Category
                </Typography>
              </Box>
            ) : null}
            <>
              {companyProfile?.yearEstablished ? (
                <Box display={"flex"}>
                  <VerifiedUserOutlinedIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#B5BCC4" }}
                  />
                  <Typography variant="body2">
                    {DateTime.now().year -
                    (companyProfile?.yearEstablished ?? DateTime.now().year)
                      ? DateTime.now().year -
                        (companyProfile?.yearEstablished ?? DateTime.now().year)
                      : "< 1"}{" "}
                    Years in Business
                  </Typography>
                </Box>
              ) : null}
              {companyProfile?.numEmployees ? (
                <Box display={"flex"}>
                  <EngineeringOutlinedIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#B5BCC4" }}
                  />
                  <Typography variant="body2">
                    {companyProfile?.numEmployees ?? 0} Employees
                  </Typography>
                </Box>
              ) : null}
            </>
          </Box>
        </Box>
      ) : null}
      {!(
        companyProfile.owners?.[0]?.firstName ||
        companyProfile.owners?.[0]?.lastName
      ) &&
      !companyProfile.owners?.[0]?.hasPhone &&
      !companyProfile.owners?.[0]?.hasEmail &&
      !companyProfile.familyOfBrands &&
      !companyProfile.competitorSectors?.length ? null : (
        <>
          <Divider sx={{ my: 1.5 }} />
          <Box>
            <Typography
              variant="overline"
              color="rgba(17, 25, 39, 0.38)
"
            >
              OWNER
            </Typography>
            <Box display={"flex"} flexDirection={"column"} py={1} gap={1}>
              {companyProfile.owners?.[0]?.firstName ||
              companyProfile.owners?.[0]?.lastName ? (
                <Box display={"flex"}>
                  <PersonOutlineIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#B5BCC4" }}
                  />
                  <Typography variant="body2">
                    {`${companyProfile.owners?.[0]?.firstName ?? ""} ${
                      companyProfile.owners?.[0]?.lastName ?? ""
                    }`}{" "}
                    (Owner)
                  </Typography>
                </Box>
              ) : null}
              {companyProfile.owners?.[0]?.hasPhone ? (
                <Box display={"flex"}>
                  <MobileFriendlyOutlinedIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#B5BCC4" }}
                  />
                  <Typography variant="body2">
                    Owner Mobile Phone Verified
                  </Typography>
                </Box>
              ) : null}
              {companyProfile.owners?.[0]?.hasEmail ? (
                <Box display={"flex"}>
                  <MarkEmailReadOutlinedIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#B5BCC4" }}
                  />
                  <Typography variant="body2">Owner Email Verified</Typography>
                </Box>
              ) : null}
              {companyProfile.familyOfBrands ? (
                <Box display={"flex"}>
                  <BusinessOutlinedIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#B5BCC4" }}
                  />
                  <Typography variant="body2">
                    {companyProfile.familyOfBrands.name}
                  </Typography>
                </Box>
              ) : null}
              {companyProfile.competitorSectors?.length ? (
                <Box display={"flex"}>
                  <HandymanOutlinedIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#B5BCC4" }}
                  />
                  <Typography variant="body2">
                    Brand Affiliate is a{" "}
                    {companyProfile.competitorSectors.reduce(
                      (acc, sector, index) => {
                        if (index === 0) {
                          return snakeCaseJobSectors[sector];
                        } else if (
                          index ===
                          (companyProfile?.competitorSectors?.length ?? 1) - 1
                        ) {
                          return acc + " and " + snakeCaseJobSectors[sector];
                        } else {
                          return acc + ", " + snakeCaseJobSectors[sector];
                        }
                      },
                      ""
                    )}{" "}
                    Competitor
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
        </>
      )}
      {companyProfile.familyOfBrands ? (
        <>
          <Divider sx={{ my: 1.5 }} />
          <Box>
            <Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  gutterBottom
                  variant="overline"
                  color="rgba(17, 25, 39, 0.38)"
                >
                  BRAND AFFILIATES
                </Typography>
                <ToolTip message="This company is part of a family of brands and these are the company's affiliates.">
                  <HelpOutlineIcon
                    sx={{
                      background: "none",
                      color: "#6C737F",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </ToolTip>
              </Box>
              <Box
                display={"flex"}
                sx={{ flexWrap: "wrap", alignItems: "center" }}
                py={1}
              >
                <AffiliateCompanies
                  familyOfBrandsId={companyProfile.familyOfBrands.id}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};
