import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { CompanyPublicProfile } from "../types";

export const USE_GET_ALL_CHATS__GROUPED_URI = "/chats/grouped";

export interface Chat {
  id: number;
  fromCompanyId: number;
  toCompanyId: number;
  message: string;
  createdAt: string;
  toCompanyReadAt?: string;
}

export function useFetchCompanyMessagesList(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<
  Array<{ latestChat: Chat; companyProfile: CompanyPublicProfile }>
> {
  return useQuery({
    queryKey: [USE_GET_ALL_CHATS__GROUPED_URI],
    queryFn: async () => {
      const response = await API.get(`${USE_GET_ALL_CHATS__GROUPED_URI}`);
      return response.data.data;
    },
    ...options,
  });
}
