import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { isDefined } from "@/utils/isDefined";

interface ExternalConnection {
  id: number;
  name: string;
  logoUrl: string;
  users: { firstName: string; lastName: string; id: number }[];
}

const mockExternalConnections: ExternalConnection[] = [
  {
    id: 5684,
    name: "Right at Home of Nashville",
    logoUrl:
      "https://app-tradeengage.s3.amazonaws.com/Brand/Right+At+Home_Logo.png",
    users: [
      {
        id: 3993,
        firstName: "Bradford",
        lastName: "Meythaler",
      },
    ],
  },
  {
    id: 5690,
    name: "A1 Garage Door Service of Nashville",
    logoUrl: "https://tradeengage-app.s3.amazonaws.com/companies/2599/logo.png",
    users: [
      {
        id: 3985,
        firstName: "Tommy",
        lastName: "Mello",
      },
    ],
  },
  {
    id: 7226,
    name: "Pulte Homes of Nashville",
    logoUrl: "https://tradeengage-app.s3.amazonaws.com/Brand/pultehomes.png",
    users: [
      {
        id: 3997,
        firstName: "Libby",
        lastName: "Perry",
      },
    ],
  },
  // {
  //   id: 7288,
  //   name: "Toll Brothers of Tennessee",
  //   logoUrl: "https://tradeengage-app.s3.amazonaws.com/Brand/tollbrothers.png",
  //   users: [],
  // },
  {
    id: 8164,
    name: "D.R. Horton Nashville Division Office",
    logoUrl:
      "https://tradeengage-videos.s3.amazonaws.com/companies/8164/logos/639a80df-52d1-4bbd-acf9-5743aebea04e.png",
    users: [
      {
        id: 3995,
        firstName: "Sydney",
        lastName: "Saghi",
      },
    ],
  },
  {
    id: 8165,
    name: "Meritage Homes - Nashville",
    logoUrl: "https://tradeengage-app.s3.amazonaws.com/Brand/meritagehomes.png",
    users: [
      {
        id: 3999,
        firstName: "Chad",
        lastName: "Nicols",
      },
    ],
  },
  {
    id: 8166,
    name: "David Weekley Homes Nashville Division Office",
    logoUrl: "https://tradeengage-app.s3.amazonaws.com/companies/2989/logo.png",
    users: [
      {
        id: 3998,
        firstName: "Jess",
        lastName: "McKinney",
      },
    ],
  },
  {
    id: 8167,
    name: "Century Communities",
    logoUrl:
      "https://tradeengage-videos.s3.amazonaws.com/companies/8167/logos/9b8ca982-2c61-4513-8a38-323183869958.png",
    users: [
      {
        id: 3996,
        firstName: "Zack",
        lastName: "Adler",
      },
    ],
  },
  {
    id: 8168,
    name: "Visiting Angels Senior Home Care Nashville",
    logoUrl:
      "https://app-tradeengage.s3.amazonaws.com/Brand/Visiting+Angels_Logo.png",
    users: [
      {
        id: 3992,
        firstName: "Andrew",
        lastName: "Dilwicius",
      },
    ],
  },
  {
    id: 8169,
    name: "SERVPRO of Belle Meade/West Nashville/Downtown Nashville",
    logoUrl: "https://app-tradeengage.s3.amazonaws.com/Brand/ServPro_Logo.png",
    users: [
      {
        id: 4015,
        firstName: "Robert",
        lastName: "Dixon",
      },
    ],
  },
  {
    id: 8170,
    name: "SERVPRO of Southeast Nashville",
    logoUrl: "https://app-tradeengage.s3.amazonaws.com/Brand/ServPro_Logo.png",
    users: [
      {
        id: 4016,
        firstName: "Justin",
        lastName: "Bisig",
      },
    ],
  },
  {
    id: 8172,
    name: "SERVPRO of Hermitage/Donelson",
    logoUrl: "https://app-tradeengage.s3.amazonaws.com/Brand/ServPro_Logo.png",
    users: [
      {
        id: 4015,
        firstName: "Robert",
        lastName: "Dixon",
      },
    ],
  },
  // {
  //   id: 8174,
  //   name: "SERVPRO of East Nashville, Madison, Goodlettsville",
  //   logoUrl: "https://app-tradeengage.s3.amazonaws.com/Brand/ServPro_Logo.png",
  //   users: [],
  // },
  {
    id: 8177,
    name: "SERVPRO of Downtown Nashville/Team Isaacson",
    logoUrl: "https://app-tradeengage.s3.amazonaws.com/Brand/ServPro_Logo.png",
    users: [
      {
        id: 4017,
        firstName: "Mike",
        lastName: "Isaacson",
      },
    ],
  },
  {
    id: 8178,
    name: "SERVPRO of Downtown Nashville/Team Bisig",
    logoUrl: "https://app-tradeengage.s3.amazonaws.com/Brand/ServPro_Logo.png",
    users: [
      {
        id: 4016,
        firstName: "Justin",
        lastName: "Bisig",
      },
    ],
  },
  {
    id: 8179,
    name: "PuroClean of North Nashville",
    logoUrl:
      "https://app-tradeengage.s3.amazonaws.com/Brand/PuroClean_Logo.png",
    users: [
      {
        id: 3990,
        firstName: "James",
        lastName: "Scalf",
      },
    ],
  },
  {
    id: 8181,
    name: "Comfort Keepers Home Care of Nashville",
    logoUrl:
      "https://tradeengage-videos.s3.amazonaws.com/companies/8181/logos/3e842f77-d086-42bd-b139-9a7f8f2c377b.png",
    users: [
      {
        id: 3994,
        firstName: "Christopher",
        lastName: "McClintock",
      },
    ],
  },
];

export function useGetExternalConnections(
  params: {
    searchWord?: string;
  },
  options: UseQueryOptions<ExternalConnection[]> = {}
): UseQueryResult<ExternalConnection[]> {
  const { searchWord } = params;

  return useQuery({
    queryKey: ["external-connections"],
    queryFn: async (): Promise<ExternalConnection[]> => {
      let externalConnections: ExternalConnection[] = mockExternalConnections;

      if (isDefined(searchWord)) {
        externalConnections = externalConnections.filter(
          (connection: ExternalConnection) =>
            connection.name.toLowerCase().includes(searchWord.toLowerCase())
        );
      }

      return externalConnections;
    },
    ...options,
  });
}
