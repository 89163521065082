import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";

import { UserTransaction, UserTransactionConcepts } from "@/user/types";

export interface TechnicianTransactionCardProps {
  transaction: UserTransaction;
}

export function TechnicianTransactionCard(
  props: TechnicianTransactionCardProps
) {
  const { transaction } = props;

  const isPositiveTransaction =
    transaction.concept == UserTransactionConcepts.REFERRAL_FEE_EARNED
      ? true
      : false;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {isPositiveTransaction ? (
          <NorthEastOutlinedIcon
            fontSize="large"
            color="info"
            sx={{
              bgcolor: theme => theme.palette.primary.light,
              color: theme => theme.palette.primary.main,
              borderRadius: 30,
              p: 1,
            }}
          />
        ) : (
          <SouthEastOutlinedIcon
            fontSize="large"
            sx={{
              bgcolor: "#FEF3F2",
              color: theme => theme.palette.error.main,
              borderRadius: 30,
              p: 1,
            }}
          />
        )}
        <Box ml={2}>
          <Typography gutterBottom variant="body1">
            {_.startCase(transaction.concept.toString().toLowerCase())}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {DateTime.fromISO(transaction.createdAt.toString(), {
              zone: "utc",
            })
              .toLocal()
              .toFormat("MM/dd/yy")}
            {transaction.job && transaction.job.jobType
              ? ` | ${transaction.job.jobType}`
              : null}
          </Typography>
        </Box>
      </Box>
      <Box textAlign="right">
        <Typography
          gutterBottom
          variant="body2"
          sx={{
            color: theme =>
              isPositiveTransaction
                ? theme.palette.primary.main
                : theme.palette.error.main,
          }}
        >
          {`${isPositiveTransaction ? "+" : "-"} $${Math.abs(
            transaction.amount
          ).toFixed(2)}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          ${transaction.newBalance.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
}
