export enum JobSectors {
  ACCESSIBILITY_SOLUTIONS = "ACCESSIBILITY_SOLUTIONS",
  AIR_QUALITY_AND_PURIFICATION = "AIR_QUALITY_AND_PURIFICATION",
  AIR_DUCT_CLEANING = "AIR_DUCT_CLEANING",
  APPLIANCES = "APPLIANCES",
  APPLIANCE_SALES = "APPLIANCE_SALES",
  ASPHALT_AND_PAVING = "ASPHALT_AND_PAVING",
  AUTO_GLASS = "AUTO_GLASS",
  BATHROOM_REMODELING = "BATHROOM_REMODELING",
  BAT_REMOVAL = "BAT_REMOVAL",
  BLINDS = "BLINDS",
  CARPENTRY = "CARPENTRY",
  CARPET_CLEANING = "CARPET_CLEANING",
  CHIMNEY_SWEEP = "CHIMNEY_SWEEP",
  CHRISTMAS_LIGHTING = "CHRISTMAS_LIGHTING",
  CLOSETS_SHELVING_AND_ORGANIZATION = "CLOSETS_SHELVING_AND_ORGANIZATION",
  CONCRETE_AND_MASONRY = "CONCRETE_AND_MASONRY",
  CRAWLSPACES = "CRAWLSPACES",
  CUSTOM_BUILDS_AND_REMODELING = "CUSTOM_BUILDS_AND_REMODELING",
  DECKS_AND_PATIOS = "DECKS_AND_PATIOS",
  DRAIN_AND_SEWER = "DRAIN_AND_SEWER",
  DRYER_VENT_CLEANING = "DRYER_VENT_CLEANING",
  DRYWALL = "DRYWALL",
  ELECTRICAL = "ELECTRICAL",
  EXTERIOR_CLEANING_AND_PRESSURE_WASHING = "EXTERIOR_CLEANING_AND_PRESSURE_WASHING",
  FENCING = "FENCING",
  FLOORING = "FLOORING",
  FOUNDATION_AND_WATERPROOFING = "FOUNDATION_AND_WATERPROOFING",
  FURNITURE_REPAIR = "FURNITURE_REPAIR",
  GARAGE_DOOR_REPAIR_AND_INSTALLATION = "GARAGE_DOOR_REPAIR_AND_INSTALLATION",
  GENERAL_CONTRACTING_AND_HANDYMAN = "GENERAL_CONTRACTING_AND_HANDYMAN",
  GENERATORS = "GENERATORS",
  GLASS_SERVICES_RESIDENTIAL = "GLASS_SERVICES_RESIDENTIAL",
  GUTTER_CLEANING = "GUTTER_CLEANING",
  HOME_INSPECTIONS_AND_APPRAISALS = "HOME_INSPECTIONS_AND_APPRAISALS",
  HVAC = "HVAC",
  INSULATION = "INSULATION",
  INTERIOR_CLEANING = "INTERIOR_CLEANING",
  INTERIOR_DESIGN = "INTERIOR_DESIGN",
  JUNK_REMOVAL_AND_HAULING = "JUNK_REMOVAL_AND_HAULING",
  KITCHEN_REMODELING = "KITCHEN_REMODELING",
  LANDSCAPING = "LANDSCAPING",
  LOCKSMITH = "LOCKSMITH",
  MOVING_AND_STORAGE = "MOVING_AND_STORAGE",
  MOSQUITO_CONTROL = "MOSQUITO_CONTROL",
  PACKOUT_AND_STORAGE = "PACKOUT_AND_STORAGE",
  PAINTING = "PAINTING",
  PEST_CONTROL = "PEST_CONTROL",
  PEST_PROTECTION = "PEST_PROTECTION",
  PET_SERVICES = "PET_SERVICES",
  PLUMBING = "PLUMBING",
  POOL_AND_SPA = "POOL_AND_SPA",
  PROPERTY_MANAGEMENT = "PROPERTY_MANAGEMENT",
  REAL_ESTATE = "REAL_ESTATE",
  RESTORATION_AND_REMEDIATION = "RESTORATION_AND_REMEDIATION",
  ROOFING = "ROOFING",
  SENIOR_CARE = "SENIOR_CARE",
  SIDING = "SIDING",
  SOLAR_AND_ENERGY = "SOLAR_AND_ENERGY",
  TERMITE_INSPECTION = "TERMITE_INSPECTION",
  TILE_AND_STONE = "TILE_AND_STONE",
  TREE_REMOVAL_AND_TRIMMING = "TREE_REMOVAL_AND_TRIMMING",
  WINDOWS_DOORS_AND_SIDING = "WINDOWS_DOORS_AND_SIDING",
  OTHER = "OTHER",
  WATER_TREATMENT = "WATER_TREATMENT",
  WINDOW_TINTING = "WINDOW_TINTING",
  WILDLIFE_CONTROL_AND_REMOVAL = "WILDLIFE_CONTROL_AND_REMOVAL",
  WOOD_ROT_REPAIR = "WOOD_ROT_REPAIR",
}

// TODO:  Replace with actual values once service types are fixed
export function getServiceTypesByJobSector(jobSector: JobSectors) {
  const jobSectorServiceTypeObj = {
    [JobSectors.ACCESSIBILITY_SOLUTIONS]:
      "Wheelchair Ramp Installation, Stairlift Installation, Bathroom Accessibility Modifications, Grab Bar Installation, Door Widening for Wheelchair Access, Accessible Shower Installation, Kitchen Accessibility Upgrades, Automated Home Systems for Accessibility, Non-Slip Flooring Installation, Visual Alert Systems, Voice-Activated Home Systems, Accessible Pathway Installation, Elevator Installation, Accessible Lighting Solutions, Custom Accessibility Modifications, Emergency Response System Installation, Adaptive Equipment Installation, Therapeutic Equipment Installation, Safety Alarm Installation, Mobility Aids Installation",

    [JobSectors.AIR_QUALITY_AND_PURIFICATION]:
      "Air Purifier Installation, HVAC Air Filtration Upgrades, Duct Cleaning and Sanitization, Humidity Control Solutions, Indoor Air Quality Testing, Radon Testing and Mitigation, Carbon Monoxide Detector Installation, Ventilation System Upgrades, Mold Testing and Remediation, Allergen Reduction Solutions, UV Light Air Sanitization, HEPA Filter Installation, Air Quality Monitoring Systems, Eco-Friendly Air Solutions, Energy-Efficient Air Systems, Air Dehumidifier Installation, Air Ionizer Installation, Air Vent Cleaning, Whole House Air Purification, Ozone Air Treatment",

    [JobSectors.AIR_DUCT_CLEANING]:
      "Air Filter Replacement, Air Quality Testing, Air Vent Cleaning, Duct Cleaning and Sanitization, Dryer Vent Cleaning, Duct Inspection, Duct Replacement, Duct Sealing, Mold Removal from Ducts",

    [JobSectors.APPLIANCES]:
      "Appliance Repair, Appliance Installation, Refrigerator Maintenance, Washer and Dryer Repair, Dishwasher Repair, Oven and Range Repair, Microwave Repair, Small Appliance Repair, Smart Appliance Setup, Appliance Parts Replacement, Energy-Efficient Appliance Upgrades, Integrated Appliance Installation, Commercial Appliance Services, Appliance Maintenance Programs, Appliance Recycling, Gas Appliance Installation, Electric Appliance Installation, Refrigeration System Repair, Custom Appliance Fittings, Appliance Warranty Services",

    [JobSectors.APPLIANCE_SALES]:
      "Retail Appliance Sales, Online Appliance Sales, Commercial Appliance Sales, Smart Home Appliance Sales, Energy-Efficient Appliance Sales, Home Appliance Showrooms, Used Appliance Sales, Appliance Accessories Sales, Bulk Appliance Orders, Luxury Appliance Sales, Kitchen Appliance Packages, Appliance Leasing Options, Wholesale Appliance Distribution, Discount Appliance Sales, High-End Appliance Retail, Home Appliance Financing, Appliance Trade-In Programs, Appliance Delivery Services, Appliance Installation with Purchase, Extended Warranty Appliance Sales",

    [JobSectors.ASPHALT_AND_PAVING]:
      "Driveway Paving, Asphalt Repair, Parking Lot Paving, Driveway Resurfacing, Sidewalk Installation, Asphalt Sealing, Pothole Repair, Line Striping and Marking, Asphalt Maintenance, Concrete Paving, Paver Installation, Retaining Wall Construction, Decorative Asphalt, Sports Court Paving, Pathway Paving, Commercial Paving Services, Paving Consultation and Design, Grading and Excavation, Paving  ",

    [JobSectors.AUTO_GLASS]:
      "Auto Glass Chip Repair, Auto Glass Crack Repair, Automotive Glass Services, Glass Leak Repair, Glass Scratch Removal, Power Window Repair, Rear Window Replacement, Side Window Replacement, Window Regulator Replacement, Windshield Repair, Windshield Replacement, Window Tinting",

    [JobSectors.BATHROOM_REMODELING]:
      "Full Bathroom Renovation, Shower Installation, Bathtub Replacement, Toilet Installation, Vanity and Sink Upgrade, Bathroom Flooring Installation, Bathroom Tile Work, Lighting and Electrical Upgrades, Plumbing Overhaul, Accessibility Features, Cabinet Installation, Mirror Installation, Painting and Finishing, Exhaust Fan Installation, Heated Floors Installation, Custom Shower Doors, Bathroom Expansion, Waterproofing Services, Window Replacement, Countertop Installation",

    [JobSectors.BAT_REMOVAL]:
      "Bat Inspection, Bat Exclusion Service Bat Guano Cleanu Bat Habitat Remova Bat Proofing and Sealin Emergency Bat Remova Bat Colony Relocation",

    [JobSectors.BLINDS]:
      "Blind Installation, Blind Repair, Motorized Blinds Setup, Custom Blind Design, Blind Cleaning, Window Measurements, Cordless Blinds Installation, Vertical Blinds Installation, Roller Blinds Fitting, Venetian Blinds Installation, Smart Blinds Integration, Blind Color Customization, Energy-Efficient Blinds Installation, Blackout Blinds Installation, Child Safety Blinds Installation, Remote Controlled Blinds Setup, Blind Replacement, Window Treatment Consultation, Light Filtering Blinds Installation, Office Blinds Installation",

    [JobSectors.CARPENTRY]:
      "Custom Furniture Building, Cabinet Making, Wooden Frame Construction, Deck Building, Door Installation and Repair, Window Frame Construction, Shelving Installation, Trim and Molding Work, Staircase Building and Repair, Fence and Gate Making, Wooden Flooring Installation, Pergola and Gazebo Construction, Wooden Partition Building, Roof Framing, Custom Woodworking Projects, Playhouse and Treehouse Building, Closet Building, Wood Rot Repair, Handrail and Banister Installation, Wood Paneling",

    [JobSectors.CARPET_CLEANING]:
      "Steam Cleaning, Stain Removal, Odor Removal, Eco-Friendly Cleaning, Carpet Deodorizing, Pet Stain and Odor Removal, Area Rug Cleaning, Carpet Repair and Stretching, Upholstery Cleaning, Commercial Carpet Cleaning, Carpet Protection Treatment, Dry Cleaning, Hot Water Extraction, Deep Carpet Cleaning, Carpet Inspection, Spot Cleaning, Carpet Sanitization, Allergy Relief Treatment, Wool Carpet Care",

    [JobSectors.CHIMNEY_SWEEP]:
      "Chimney Cleaning, Chimney Inspection, Chimney Repair, Creosote Removal, Chimney Lining, Fireplace Cleaning, Chimney Cap Installation, Chimney Masonry Work, Chimney Waterproofing, Smoke Chamber Repair, Chimney Crown Repair, Flue Repair, Chimney Rebuilding, Firebox Repair, Chimney Damper Installation, Animal Removal, Chimney Leak Repair, Chimney Flashing, Chimney Tuckpointing, Chimney Draft Improvement",

    [JobSectors.CHRISTMAS_LIGHTING]:
      "Holiday Light Installation, Christmas Light Design, Light Hanging Service, Commercial Holiday Lighting, Residential Christmas Lighting, LED Christmas Lights Installation, Light Removal and Storage, Roofline Light Installation, Tree Lighting, Wreath and Garland Installation, Custom Light Displays, Outdoor Holiday Decorations, Timer Setup for Lights, Energy-Efficient Lighting, Repair of Christmas Lights, Window Decoration, Icicle Lights Installation, Animated Light Display Setup, Festive Yard Art Setup, Christmas Light Maintenance",

    [JobSectors.CLOSETS_SHELVING_AND_ORGANIZATION]:
      "Custom Closet Design, Shelving Installation, Closet Organizing Systems, Walk-in Closet Build, Garage Storage Solutions, Pantry Organization, Office Shelving, Kids' Closet Organization, Space Maximization, Closet Remodeling, Closet Accessory Installation, Shoe Storage Solutions, Wardrobe Design, Mudroom Storage, Laundry Room Organization, Linen Closet Organization, Closet Lighting, Custom Cabinetry, Jewelry Storage Solutions, Adjustable Shelving Setup",

    [JobSectors.CONCRETE_AND_MASONRY]:
      "Concrete Driveway Installation, Masonry Repair, Stamped Concrete, Brickwork, Retaining Wall Building, Concrete Patio Installation, Stone Veneer Installation, Foundation Repair, Concrete Resurfacing, Sidewalk and Walkway Installation, Decorative Concrete Work, Concrete Sealing, Chimney Masonry, Fire Pit Construction, Concrete Staining, Block Wall Construction, Concrete Demolition and Removal, Stone Masonry, Concrete Countertop Installation, Brick Paver Installation",

    [JobSectors.CRAWLSPACES]:
      "Encapsulation, Insulation, Waterproofing, Ventilation, Mold Remediation, Sump Pump Installation, Dehumidification, Structural Repair, Cleaning and Maintenance, Vapor Barrier Installation, Drainage Solutions, Access Door Installation, Floor Repair, Odor Removal, Inspections, Moisture Control, Radon Mitigation, Air Quality Improvement, Foundation Repair, Thermal Imaging Inspection",

    [JobSectors.CUSTOM_BUILDS_AND_REMODELING]:
      "Custom Home Building, Kitchen Remodeling, Bathroom Remodeling, Basement Finishing, Room Additions, Home Extensions, Historical Renovation, Attic Conversion, Custom Carpentry, Window Installation, Door Installation, Custom Cabinetry, Custom Countertops, Home Office Remodeling, Sunroom Addition, Second Story Addition, Eco-Friendly Remodeling, Aging-In-Place Modifications, Open Floor Plan Conversion, Exterior Remodeling, Interior Design Services, Custom Flooring, Smart Home Upgrades, Energy Efficiency Upgrades, Home Theater Installation, Custom Lighting Solutions, Roof Remodeling, Custom Staircase Building, Home Gym Installation, Wine Cellar Construction",

    [JobSectors.DECKS_AND_PATIOS]:
      "Deck Building, Patio Installation, Deck Repair, Patio Repair, Composite Decking Installation, Patio Paver Installation, Deck Staining and Sealing, Patio Enclosures, Custom Deck Design, Patio Lighting Installation, Deck Expansion, Screened Porch Installation, Outdoor Kitchen Construction, Patio Landscaping, Deck Railing Installation, Fire Pit Installation, Gazebo Building, Outdoor Fireplace Construction, Deck Waterproofing, Patio Cover Installation, Pergola Installation, Deck Resurfacing, Outdoor Entertainment Area Design, ADA Compliant Deck Construction, Solar Panel Decking, Hot Tub Installation on Deck, Deck Lighting System, Patio Drainage Solutions",

    [JobSectors.DRAIN_AND_SEWER]:
      "Drain Cleaning, Sewer Line Repair, Clogged Drain Repair, Sewer Line Inspection, Drainage System Installation, Septic Tank Services, Hydro Jetting, Sewer Line Replacement, Video Camera Pipe Inspection, Trenchless Sewer Repair, Sump Pump Installation, Sewer Ejector Pump Installation, Grease Trap Cleaning, Storm Drain Cleaning, Sewer Line Maintenance, French Drain Installation, Backwater Valve Installation, Sewer Odor Detection, Drainage Repair, Basement Waterproofing, Sewer Line Relining, Septic System Repair, Drain Vent Cleaning, Sewer Pipe Lining, Rain Garden Installation, Sewer Backup Prevention, Sewage Grinder Pump Installation, Sewer Cleanout Installation",

    [JobSectors.DRYER_VENT_CLEANING]:
      "Dryer Vent Inspection, Lint Removal, Vent Rerouting, Vent Repair, Booster Fan Installation, Transition Hose Replacement, Bird Guard Installation, Vent Cover Replacement, Dryer Vent Upgrades, Mold Removal in Vents, Energy Efficiency Assessment, Fire Safety Inspection, Multi-Unit Dryer Vent Cleaning, Airflow Testing, Dryer Vent System Restoration, Dryer Interior Cleaning, Rooftop Vent Cleaning, Dryer Vent Alarm Installation, Custom Vent Solutions, Dryer Vent Code Compliance, Pest Prevention in Vents, Magnetic Vent Closure Installation, Dryer Ventilation Optimization, Dryer Vent Relocation, Lint Sensor Installation, Exterior Vent Cleaning",

    [JobSectors.DRYWALL]:
      "Drywall Installation, Drywall Repair, Plaster Repair, Ceiling Drywall Repair, Soundproofing, Water Damage Repair, Texture Matching, Mold Remediation in Drywall, Drywall Finishing, Fire-Resistant Drywall Installation, Drywall Sanding, Popcorn Ceiling Removal, Custom Drywall Shapes, Acoustic Ceiling Installation, Insulation Installation, Drywall Art and Texturing, Drywall Patching, Vapor Barrier Installation, Wall Skimming, Drywall Demolition, LED Light Strip Installation in Drywall, Wallpaper Removal, Framing for Drywall, Green Board Installation, Drywall for Basements, Metal Stud Framing, Bullnose Corners Installation, Arched Drywall Construction",

    [JobSectors.ELECTRICAL]:
      "Electrical Panel Upgrade, Lighting Installation, Outlet Installation, Electrical Wiring, Generator Installation, Circuit Breaker Replacement, Electrical Inspection, Smart Home Automation, Surge Protection, Emergency Electrical Repairs, Electrical Troubleshooting, EV Charger Installation, Ceiling Fan Installation, Landscape Lighting, Pool and Hot Tub Wiring, Security System Installation, Recessed Lighting, Home Theater Wiring, Data Cabling, Smoke Detector Installation, Solar Panel Installation, Electrical Maintenance, GFCI Outlet Installation, Energy Efficient Lighting, Knob and Tube Replacement, Motion Sensor Lighting, LED Retrofit, Childproofing Electrical Outlets",

    [JobSectors.EXTERIOR_CLEANING_AND_PRESSURE_WASHING]:
      "House Washing, Driveway Cleaning, Deck Cleaning, Gutter Cleaning, Roof Cleaning, Sidewalk Cleaning, Graffiti Removal, Fence Cleaning, Pool Area Cleaning, Garage Floor Cleaning, Commercial Pressure Washing, Solar Panel Cleaning, Brick Cleaning, Stucco Cleaning, Window Washing, Moss and Algae Removal, Patio Cleaning, Awning Cleaning, Chimney Cleaning, Oil Stain Removal, Paint Preparation, Rust Removal, Wood Restoration, Pressure Washing Consultation, Eco-Friendly Cleaning Solutions, Seasonal Cleaning Services, Parking Lot Cleaning, Heavy Equipment Cleaning, Historic Building Cleaning",

    [JobSectors.FENCING]:
      "Fence Installation, Fence Repair, Privacy Fence Installation, Chain Link Fence Installation, Wood Fence Installation, Vinyl Fence Installation, Aluminum Fence Installation, Pool Fence Installation, Electric Gate Installation, Fence Painting and Staining, Security Fence Installation, Fence Post Replacement, Decorative Fence Design, Farm Fencing, Wrought Iron Fence Installation, Automated Gate Systems, Bamboo Fencing, Dog Run Fencing, Temporary Construction Fencing, Split Rail Fence Installation, Acoustic Fencing, Lattice Work, Picket Fence Installation, Retaining Wall Fence Integration, Wildlife Barrier Fencing, Fence Height Extension, Custom Fence Gates",

    [JobSectors.FLOORING]:
      "Hardwood Flooring Installation, Laminate Flooring Installation, Tile Flooring Installation, Carpet Installation, Vinyl Flooring Installation, Bamboo Flooring Installation, Cork Flooring Installation, Stone Flooring Installation, Epoxy Flooring Installation, Radiant Floor Heating, Parquet Flooring Installation, Floor Refinishing, Water Damage Floor Repair, Custom Floor Design, Subfloor Installation, Floor Leveling, Floor Sanding, Staircase Flooring Installation, Concrete Floor Polishing, Eco-Friendly Flooring Options, Mosaic Flooring, Carpet Repair, Floor Staining, Commercial Flooring Solutions, Gym Flooring Installation, Heated Bathroom Floors, Antique Floor Restoration",

    [JobSectors.FOUNDATION_AND_WATERPROOFING]:
      "Foundation Repair, Basement Waterproofing, Crawl Space Encapsulation, Sump Pump Installation, Foundation Crack Repair, Exterior Waterproofing, Interior Waterproofing, Mold and Mildew Remediation, French Drain Installation, Foundation Inspection, Wall Anchors Installation, Vapor Barrier Installation, Structural Repair, Concrete Foundation Installation, Grading and Drainage Solutions, Foundation Sealing, Window Well Installation, Foundation Underpinning, Egress Window Installation, Soil Stabilization, Waterproof Membrane Installation, Basement Floor Sealing, Helical Pier Installation, Groundwater Management, Retaining Wall Waterproofing, Hydrostatic Pressure Relief",

    [JobSectors.FURNITURE_REPAIR]:
      "Upholstery Repair, Wood Furniture Repair, Leather Furniture Repair, Antique Furniture Restoration, Furniture Refinishing, Furniture Reupholstery, Scratch Repair, Frame Repair, Furniture Painting, Water Damage Repair, Furniture Polishing, Cane and Wicker Repair, Furniture Staining, Furniture Structural Repair, Custom Furniture Modifications, Touch-Up Services, Furniture Joint Reinforcement, Veneer Repair, Fabric Panel Replacement, Office Furniture Repair, Furniture Assembly Services, Furniture Cleaning, Glass Replacement in Furniture, Metal Furniture Repair, Furniture Hardware Replacement, Sofa Frame Repair, Custom Furniture Cushions",

    [JobSectors.GARAGE_DOOR_REPAIR_AND_INSTALLATION]:
      "Garage Door Installation, Garage Door Repair, Garage Door Opener Installation, Garage Door Spring Replacement, Garage Door Panel Replacement, Custom Garage Door Design, Garage Door Maintenance, Garage Door Opener Repair, Garage Door Insulation, Garage Door Weather Stripping, Garage Door Remote Programming, Smart Garage Door Integration, Commercial Garage Door Services, Garage Door Sensor Repair, Garage Door Balance Adjustment, Emergency Garage Door Service, Garage Door Noise Reduction, Garage Door Seal Replacement, Garage Door Track Alignment, Roll-Up Garage Door Installation, Garage Door Lock Repair, Sectional Garage Door Installation, Carriage Style Garage Door Installation",

    [JobSectors.GENERAL_CONTRACTING_AND_HANDYMAN]:
      "General Home Repairs, Light Fixture Installation, Painting Services, Tile Installation, Door Repair, Carpentry Services, Flooring Repairs, Furniture Assembly, Window Repair, Drywall Patching, Plumbing Repairs, Electrical Work, Shelving Installation, Gutter Repair, Deck Repairs, Fence Maintenance, Crown Molding Installation, Ceiling Fan Installation, Appliance Installation, Bathroom Fixes, Home Improvement Consultations, Smart Home Device Installation, Childproofing Services, Exterior Home Repairs, Safety Upgrades, Minor Roof Repairs, Custom Woodworking, Weatherproofing, Wallpaper Installation, Home Maintenance Plans",

    [JobSectors.GENERATORS]:
      "Generator Installation, Generator Repair, Generator Maintenance, Portable Generator Setup, Standby Generator Installation, Generator Fuel System Installation, Generator Transfer Switch Installation, Generator Battery Replacement, Emergency Generator Services, Generator Load Testing, Solar Generator Installation, Diesel Generator Installation, Natural Gas Generator Installation, Propane Generator Installation, Generator Noise Reduction Solutions, Generator Control Panel Installation, Generator Wiring and Cabling, Home Backup Generator Installation, Commercial Generator Services, Generator Inspection and Testing",
    [JobSectors.GLASS_SERVICES_RESIDENTIAL]:
      "Glass Shower Enclosure Installation, Storefront Glass Installation, Glass Repair Services, Mirror Installation, Glass Tabletop Fabrication, Glass Balustrades and Railings, Insulated Glass Unit (IGU) Installation, Skylight Installation and Repair",

    [JobSectors.GUTTER_CLEANING]:
      "Debris Removal, Downspout Unclogging, Gutter Inspection, Gutter Repair, Gutter Guard Installation, Gutter Resealing, Roof and Gutter Cleaning",

    [JobSectors.HOME_INSPECTIONS_AND_APPRAISALS]:
      "Home Buyer Inspections, Pre-Sale Home Inspections, Appraisal Services, Radon Testing, Mold Inspections, Termite Inspections, Energy Audits, Structural Inspections, Electrical System Inspection, Plumbing Inspection, HVAC Inspection, Roof Inspection, Foundation Inspection, Home Condition Reports, Property Valuation, Lead Paint Testing, Asbestos Testing, Septic System Inspection, Water Testing, Infrared Thermal Imaging, Environmental Assessments, Zoning Compliance Inspection, Historical Property Appraisal, Condo Inspections, Commercial Property Inspections, Rental Property Inspections, Insurance Appraisals",

    [JobSectors.HVAC]:
      "AC Installation, Heating System Installation, HVAC Repair, Duct Cleaning, Thermostat Installation, Air Quality Testing, Furnace Maintenance, Heat Pump Installation, Boiler Repair, Emergency HVAC Services, Ventilation System Installation, Energy-Efficient Upgrades, Refrigerant Leak Repair, AC Tune-Up, Heating System Tune-Up, Air Filter Replacement, HVAC System Inspection, Ductless Mini-Split Systems, Humidifier Installation, Geothermal Heating and Cooling, Commercial HVAC Services, Smart HVAC Solutions, Zone Control Systems, UV Air Sanitizer Installation, HVAC Retrofitting, Radiant Heating Installation",

    [JobSectors.INSULATION]:
      "Attic Insulation, Wall Insulation, Spray Foam Insulation, Basement Insulation, Crawl Space Insulation, Insulation Removal, Thermal Imaging Inspections, Soundproofing, Roof Insulation, Green Insulation Solutions, Insulation Upgrades, Radiant Barrier Installation, Insulation for New Construction, Blown-In Insulation, Fiberglass Insulation, Cellulose Insulation, Energy Audits, Garage Insulation, Fireproofing Insulation, Insulation Repair, Weatherization Services, Duct Insulation, Foam Board Insulation, Reflective Insulation, Mineral Wool Insulation, Eco-Friendly Insulation Options",

    [JobSectors.INTERIOR_CLEANING]:
      "House Cleaning, Carpet Cleaning, Window Cleaning, Deep Cleaning Services, Maid Service, Upholstery Cleaning, Floor Cleaning, Kitchen Cleaning, Bathroom Cleaning, Dusting and Polishing, Move-In/Move-Out Cleaning, Eco-Friendly Cleaning, Steam Cleaning, Tile and Grout Cleaning, Pet Odor Removal, Ceiling and Wall Cleaning, Blinds and Drapery Cleaning, Post-Construction Cleaning, Organizing Services, Vacation Rental Cleaning, AirBnB Cleaning, Sanitization Services, Laundry and Ironing Services, Housekeeping Services, Commercial Cleaning, Seasonal Cleaning, Home Disinfection Services",

    [JobSectors.INTERIOR_DESIGN]:
      "Residential Interior Design, Commercial Interior Design, Space Planning, Kitchen Design, Bathroom Design, Bedroom Design, Living Room Design, Color Consultation, Furniture Selection, Lighting Design, Floor Plan Design, Custom Window Treatments, Art and Accessory Selection, Sustainable Design, Home Office Design, Custom Cabinetry Design, 3D Rendering, Home Staging, Remodeling Design, Material Selection, Children's Room Design, Historic Interior Restoration, Accessibility Design, Feng Shui Consulting, Eclectic Design, Contemporary Design, Traditional Design, Minimalist Design",

    [JobSectors.JUNK_REMOVAL_AND_HAULING]:
      "Furniture Removal, Appliance Disposal, Yard Waste Removal, Construction Debris Hauling, Estate Cleanout, E-Waste Recycling, Garage Cleanout, Foreclosure Cleanouts, Office Cleanout, Hoarding Cleanout, Bulk Trash Removal, Hot Tub Removal, Shed Demolition and Removal, Scrap Metal Recycling, Storage Unit Cleanout, Donation Pickups, Hazardous Waste Disposal, Tire Recycling, Mattress Disposal, Refrigerator Disposal, Commercial Junk Removal, Storm Debris Removal, Land Clearing, Rental Property Cleanout, Attic Cleanout, Basement Cleanout, Real Estate Cleanout",

    [JobSectors.KITCHEN_REMODELING]:
      "Kitchen Design Services, Cabinet Installation, Countertop Installation, Kitchen Island Installation, Backsplash Installation, Kitchen Lighting, Flooring Installation, Kitchen Appliance Installation, Custom Kitchen Cabinets, Kitchen Expansion, Pantry Organization Solutions, Eco-Friendly Kitchen Remodeling, Open Kitchen Layouts, Smart Kitchen Upgrades, Kitchen Window Installation, Water Filtration Systems, Kitchen Sink and Faucet Installation, Bar and Wine Storage Solutions, Energy-Efficient Appliances, Modern Kitchen Design, Traditional Kitchen Remodel, Kitchen Storage Optimization, Breakfast Nook Addition",

    [JobSectors.LANDSCAPING]:
      "Landscape Design, Lawn Care and Maintenance, Garden Installation, Tree Planting, Hardscaping, Irrigation Systems, Outdoor Lighting, Water Features, Retaining Walls, Landscape Construction, Xeriscaping, Seasonal Planting, Patio Installation, Fertilization, Pest Control, Hedge and Shrub Trimming, Yard Cleanup, Drainage Solutions, Artificial Grass Installation, Edible Gardens, Landscape Renovation, Walkways and Paths, Fire Pit Installation, Sustainable Landscaping, Wildlife Attracting Landscaping, Landscape Lighting, Vertical Gardens, Water Conservation Landscaping, Native Plant Landscaping",

    [JobSectors.LOCKSMITH]:
      "Emergency Lockout Services, Lock Installation, Lock Repair, Key Duplication, High-Security Locks, Safe Installation, Rekeying Services, Electronic Locks, Master Key Systems, Broken Key Extraction, Lock Change, Car Key Replacement, Access Control Systems, Security Upgrades, Door Hardware Installation, Keyless Entry Systems, Padlock Services, Mailbox Locks, Deadbolt Installation, Smart Lock Installation, Cabinet Lock Services, Garage Door Locks, Peephole Installation, Lock Maintenance, Sliding Door Lock Repair, Window Lock Installation, Biometric Lock Systems",

    [JobSectors.MOVING_AND_STORAGE]:
      "Residential Moving, Commercial Moving, Long-Distance Moving, Local Moving, Packing and Unpacking Services, Storage Solutions, International Moving, Specialty Item Moving, Office Relocation, Moving Supplies, Piano Moving, Art and Antique Moving, Furniture Disassembly and Reassembly, Climate-Controlled Storage, Mobile Storage, Vehicle Transportation, Moving Insurance, Custom Crating, Senior Moving Services, Student Moving, Military Moving, Containerized Moving, Warehouse Storage, Inventory Management, Moving Consultation, Corporate Relocation, Last-Minute Moving Services",

    [JobSectors.MOSQUITO_CONTROL]:
      "Mosquito Inspection, Mosquito Barrier Treatment, Larvicide Application, Mosquito Misting System Installation, Mosquito Trap Installation, Seasonal Mosquito Control, Event Mosquito Control, Natural Mosquito Repellent Solutions, Outdoor Fogging Services, Eco-Friendly Mosquito Solutions, Residential Mosquito Control, Commercial Mosquito Control, Mosquito Habitat Reduction, Standing Water Treatment, Mosquito Bite Prevention, Custom Mosquito Control Plans, Tick and Mosquito Combination Treatment, Yard Mosquito Treatment, Mosquito Control for Pools and Water Features, Protective Mosquito Netting, Mosquito Breeding Site Elimination, Monthly Mosquito Treatments, All-Natural Mosquito Treatments, Mosquito Prevention Education",

    [JobSectors.OTHER]:
      "Custom Services, Miscellaneous Repairs, Specialized Home Services, Unique Installation Services, Personalized Consultations, Creative Solutions, Adaptive Services, On-Demand Services, Special Requests, Innovative Home Solutions, Tailored Assistance, Diverse Offerings, Non-Standard Services, Flexible Service Options, Bespoke Home Care, Varied Home Tasks, Exclusive Services, Wide-Ranging Support, Distinctive Home Needs, Customizable Home Maintenance",

    [JobSectors.PACKOUT_AND_STORAGE]:
      "Residential Packing and Storage, Commercial Packing and Storage, Long-term Storage, Short-term Storage, Climate-controlled Storage, Document and Records Storage, Art and Antique Storage, Vehicle Storage, Inventory Storage, Disaster Recovery Packout and Storage, Mobile Storage Solutions, Logistics and Distribution, White Glove Packing and Moving, Specialty Item Packing and Storage, Secure Storage, Storage Consultation and Planning, De-cluttering and Organizing Services, Inventory Management Services, Pick-up and Delivery Services, Auction and Estate Services",

    [JobSectors.PAINTING]:
      "Interior Painting, Exterior Painting, Deck Staining, Wallpaper Installation, Ceiling Painting, Drywall Repair, Cabinet Painting, Color Consultation, Faux Finishing, Trim Painting, Pressure Washing, Plaster Repair, Wallpaper Removal, Textured Painting, Custom Murals, Paint Removal, Wood Staining, Epoxy Floor Coating, Residential Painting, Commercial Painting, Lead Paint Removal, Specialty Coatings, High-Durability Coatings, Paint Matching, Graffiti Removal, Paint Restoration, Green Painting Solutions, Lacquer Finishing, Historic Home Painting, Paint Color Trend Consulting",

    [JobSectors.PEST_CONTROL]:
      "Insect Control, Rodent Control, Termite Inspection, Ant Control, Cockroach Extermination, Bed Bug Treatment, Mosquito Control, Wildlife Removal, Flea and Tick Treatment, Bee and Wasp Removal, Bird Control, Mole and Vole Control, Spider Control, Bat Removal, Organic Pest Control, Integrated Pest Management, Seasonal Pest Control, Residential Pest Control, Commercial Pest Control, Preventative Pest Services, Lawn Pest Treatment, Pantry Pest Treatment, Fumigation Services, Pest Exclusion Services, Scorpion Control, Tick Yard Treatment, Snail and Slug Control, Gopher Control",
    [JobSectors.PEST_PROTECTION]:
      "Pest Inspection, Termite Control, Bed Bug Treatment, Cockroach Control, Ant Control, Rodent Control, Flea Treatment, Tick Control, Mosquito Treatment, Spider Control, Wasp and Bee Removal, Moth Treatment, Silverfish Control, Earwig Control, Centipede Control, Fly Control, Scorpion Control, Carpet Beetle Treatment, Stored Product Pest Control, Pantry Pest Solutions, Insect Growth Regulator Application, Perimeter Pest Protection, Crawl Space Treatment, Lawn Pest Control, Eco-Friendly Pest Solutions, Pest Barrier Installation, Home Sealing Against Pests, Pest Prevention Program, Pest Control for Apartments, Commercial Pest Protection, Wildlife-Proofing for Pest Entry",

    [JobSectors.PET_SERVICES]:
      "Pet Sitting, Dog Walking, Pet Grooming, Pet Boarding, Pet Training, Doggy Daycare, Pet Waste Removal, Pet Transportation, Veterinary House Calls, Pet Nutrition Consulting, Pet Behavior Modification, Aquarium Maintenance, Emergency Pet Care, Pet Photography, Pet Insurance Services, Breed-Specific Care, Pet Supply Delivery, Animal Rescue Services, Bird Grooming, Mobile Pet Grooming, Exotic Pet Care, Senior Pet Care, Pet Event Services, Pet Wellness Checks, Cat Sitting, Small Animal Boarding, Dog Park Visits, Pet Birthday Parties, Puppy Socialization Classes",

    [JobSectors.PLUMBING]:
      "Leak Detection, Drain Cleaning, Faucet Repair, Toilet Repair, Pipe Repair, Water Heater Installation, Garbage Disposal Installation, Emergency Plumbing, Sewer Repair, Sump Pump Installation, Backflow Prevention, Water Filtration System Installation, Gas Line Services, Plumbing Inspections, Bathroom Plumbing, Kitchen Plumbing, Plumbing Maintenance, Hydro Jetting, Radiator Repair, Pipe Relining, Septic Tank Services, Water Pressure Issues, Tankless Water Heater Installation, Fixture Installation, Frozen Pipe Repair, Water Leak Repair, Plumbing Upgrades, Greywater Systems",

    [JobSectors.POOL_AND_SPA]:
      "Pool Cleaning, Spa Maintenance, Pool Filter Replacement, Hot Tub Cleaning, Pool Winterization, Spa Repair, Pool Heating System Installation, Pool Resurfacing, Pool Leak Detection, Spa Winterization, Pool Lighting Installation, Spa Filter Replacement, Pool Cover Installation, Jacuzzi Repair, Pool Tile Repair, Spa Lighting Installation, Pool Chemical Balancing, Hot Tub Cover Replacement, Pool Deck Repair, Spa Leak Detection, Pool Equipment Upgrade, Sauna Installation, Pool Automation, Hot Tub Winterization, Inground Pool Installation, Above Ground Pool Installation",

    [JobSectors.PROPERTY_MANAGEMENT]:
      "Tenant Screening, Property Maintenance, Rent Collection, Lease Management, Property Inspections, Eviction Services, Financial Reporting for Properties, Vendor Management, Property Marketing, Legal Compliance, Maintenance Coordination, Lease Renewal Management, Emergency Response, Utility Management, Property Renovation Coordination, Budgeting for Properties, Security Deposit Management, Tenant Communication, Property Value Assessment, Building Safety Compliance, Landscaping Management, Cleaning Services Coordination, Sustainable Property Practices, Tenant Move-In/Move-Out Coordination",

    [JobSectors.REAL_ESTATE]:
      "Property Listing, Buyer Representation, Seller Representation, Real Estate Market Analysis, Property Showings, Real Estate Consulting, Transaction Coordination, Property Valuation, Negotiation Services, Open House Organization, Relocation Services, Investment Property Services, Commercial Real Estate Services, First-Time Home Buyer Assistance, Property Staging, Lease Agreements, Real Estate Development, Property Management, Land Acquisition, Real Estate Marketing, Closing Services, Foreclosure Services, Short Sale Negotiation, Mortgage Referral Services, Luxury Real Estate Services",

    [JobSectors.RESTORATION_AND_REMEDIATION]:
      "Water Damage Restoration, Fire Damage Restoration, Mold Remediation, Smoke Damage Cleanup, Storm Damage Repair, Flood Damage Restoration, Biohazard Cleanup, Asbestos Removal, Lead Paint Removal, Odor Removal, Disaster Response Services, Structural Drying, Debris Removal, Emergency Board-Up Services, Indoor Air Quality Improvement, Contents Restoration, Thermal Imaging Inspections, Sewage Cleanup, Crime Scene Cleanup, Vandalism Repair, Historic Property Restoration, Electronics Restoration, Document Drying and Recovery, Environmental Remediation, Safe Demolition and Deconstruction",

    [JobSectors.ROOFING]:
      "Roof Repair, Roof Installation, Roof Inspection, Roof Replacement, Gutter Installation, Shingle Replacement, Leak Repair, Flat Roof Services, Metal Roofing, Tile Roofing, Skylight Installation, Roof Ventilation, Roof Coating, Roof Cleaning, Chimney Flashing Repair, Gutter Repair, Storm Damage Repair, Green Roofing, Energy Efficient Roofing, Emergency Roof Services, Snow Removal, Roof Waterproofing, Roof Insulation, Roof Deck Installation, Fascia and Soffit Repair, Roof Maintenance Programs, Commercial Roofing Services, Roof Design Services, Solar Panel Installation on Roof",

    [JobSectors.SENIOR_CARE]:
      "In-Home Senior Care, Alzheimer's Care, Companion Services, Personal Care Assistance, Respite Care, Medication Management, Senior Transportation Services, Meal Preparation, 24-Hour Care Services, Post-Hospitalization Care, Senior Wellness Programs, Mobility Assistance, Home Safety Assessments, Specialized Care for Chronic Conditions, Nursing Care Services, Palliative Care, Dementia Care, End-of-Life Care, Health Monitoring, Caregiver Support, Senior Activity Programs, Household Management, Technology Assistance for Seniors, Geriatric Care Management, Long-Term Care, Short-Term Senior Care",

    [JobSectors.SIDING]:
      "Vinyl Siding Installation, Fiber Cement Siding Installation, Wood Siding Repair, Siding Replacement, Aluminum Siding Repair, Siding Painting, Insulated Siding Installation, Soffit and Fascia Repair, Siding Inspection, Historic Siding Restoration, Siding Cleaning, Metal Siding Installation, Siding Color Matching, Stone Veneer Siding, Stucco Siding Repair, Siding Leak Repair, Energy Efficient Siding, Siding Trim Work, Cedar Shake Siding, Siding Damage Assessment, Board and Batten Siding, Commercial Siding Services, Siding Ventilation, Seamless Siding Installation, Fire-Resistant Siding, Siding Customization Options",

    [JobSectors.SOLAR_AND_ENERGY]:
      "Solar Panel Installation, Solar System Design, Solar Panel Maintenance, Energy Efficiency Audits, Solar Battery Storage Solutions, Solar Panel Repair, Solar Water Heating, Residential Solar Solutions, Commercial Solar Solutions, Solar Inverter Installation, Renewable Energy Consulting, Solar Panel Upgrade, Solar Financing Solutions, Wind Turbine Installation, Solar Electric System Installation, Off-Grid Solar Systems, Solar Tax Credit Assistance, Sustainable Energy Solutions, Solar Energy Monitoring, Green Building Consulting, Solar Carport Installation, Net Metering Setup, Solar Pool Heating, LED Lighting Upgrades",
    [JobSectors.TERMITE_INSPECTION]:
      "Comprehensive Termite Inspection, Pre-Construction Soil Treatment, Post-Infestation Termite Treatment and Control, Structural Damage Assessment and Documentation, Bait Station Installation and Monitoring for Termites, Termite-Resistant Barrier Installation, Drywood Termite Treatment, Annual Termite Prevention Plan, Subterranean Termite Treatment, Localized Spot Treatment for Termite Infestations",

    [JobSectors.TILE_AND_STONE]:
      "Tile Flooring Installation, Natural Stone Flooring, Bathroom Tile Installation, Kitchen Backsplash Installation, Tile Repair, Grout Cleaning, Stone Countertop Installation, Tile Sealing, Mosaic Tile Design, Stone Fireplace Installation, Outdoor Tile Installation, Tile Regrouting, Stone Wall Cladding, Marble Flooring Installation, Porcelain Tile Installation, Tile Removal, Stone Patio Installation, Custom Tile Work, Heated Tile Floor Installation, Tile Restoration, Stone Veneer Installation, Waterjet Tile Cutting, Tile Color Consultation, Travertine Installation, Slate Tile Installation, Tile Accent Walls",

    [JobSectors.TREE_REMOVAL_AND_TRIMMING]:
      "Tree Removal, Tree Trimming, Stump Grinding, Emergency Tree Services, Tree Pruning, Tree Health Assessment, Arborist Services, Tree Planting, Hedge Trimming, Tree Cabling and Bracing, Tree Disease Treatment, Brush Clearing, Land Clearing, Tree Risk Assessment, Ornamental Pruning, Root Pruning, Tree Transplanting, Storm Damage Cleanup, Fruit Tree Pruning, Tree Fertilization, Tree Pest Control, Tree Preservation, Vegetation Management, Deadwood Removal, Canopy Lifting, Tree Watering Services, Tree Mulching, Eco-Friendly Tree Removal, Tree Thinning, Tree Structure Evaluation",
    [JobSectors.WATER_TREATMENT]:
      "Water Quality Testing, Whole House Water Filtration, Reverse Osmosis System Installation, Water Softening, Hard Water Treatment, Iron and Sulfur Removal, UV Water Purification, Well Water Treatment, pH Balancing, Carbon Filtration Systems, Chlorine Removal, Water Purifier Installation, Drinking Water Filter Installation, Salt-Free Water Softeners, Chemical-Free Water Treatment, Alkaline Water Solutions, Sediment Filtration, Residential Water Treatment, Commercial Water Treatment, Water Contaminant Testing, Bacteria and Virus Removal, Nitrate Reduction, Point-of-Use Water Filters, Point-of-Entry Water Filtration, Lead and Heavy Metal Removal, Water Odor Control, Maintenance for Water Treatment Systems, Water Treatment Consultation, Eco-Friendly Water Solutions, Custom Water Treatment Systems",
    [JobSectors.WINDOWS_DOORS_AND_SIDING]:
      "Window Installation, Door Installation, Window Replacement, Door Replacement, Energy Efficient Windows, Sliding Door Installation, Vinyl Siding Installation, Window Repair, Door Repair, Custom Windows and Doors, Entry Door Installation, Siding Installation, Window Glass Replacement, Storm Door Installation, Siding Repair, Patio Door Installation, Historic Window Restoration, French Door Installation, Bay Window Installation, Window Insulation, Security Door Installation, Window Frame Repair, Custom Siding Solutions, Egress Window Installation, Garage Door Siding, Window Sealing, Skylight Installation",
    [JobSectors.WINDOW_TINTING]:
      "Automotive window tinting, Residential window tinting, Commercial window tinting, Safety and security film installation, UV protection window film installation, Heat reduction window film installation, Privacy window film installation, Decorative window film installation, Glare reduction window film installation, Anti-graffiti window film installation, Paint protection film installation, Headlight and taillight tinting, Window film removal services",
    [JobSectors.WILDLIFE_CONTROL_AND_REMOVAL]:
      "Animal Trapping, Bird Control, Bat Exclusion, Rodent Control, Snake Removal, Raccoon Removal, Opossum Removal, Skunk Removal, Squirrel Control, Dead Animal Removal, Wildlife Damage Repair, Wildlife Prevention Services, Wildlife Inspection and Assessment, Exclusion Barrier Installation, Humane Animal Removal, Nest Removal, Animal Droppings Cleanup, Attic Animal Proofing, Crawl Space Animal Proofing, Emergency Wildlife Removal, Wildlife Habitat Modification, Critter-proof Fencing, Professional Wildlife Consultation, Wildlife Repellent Installation, Bait Station Setup, Bird Netting Installation, Bird Spike Installation, Electric Fence Installation, Structural Animal Proofing, Hazardous Waste Removal from Wildlife",
    [JobSectors.WOOD_ROT_REPAIR]:
      "Wood Rot Inspection, Wood Rot Removal, Structural Wood Rot Repair, Sill Plate Replacement, Joist and Beam Repair, Wall Framing Repair, Floor Repair, Exterior Trim Replacement, Window Frame Repair, Door Frame Repair, Wood Replacement for Decks, Fascia Board Repair, Roof Overhang Repair, Foundation Repair, Wood Rot Treatment, Rot-Resistant Wood Installation, Waterproofing Solutions, Wood Sealing and Caulking, Gutter Repair to Prevent Rot, Mold Remediation, Spray Foam Insulation for Rot Prevention, Wood Hardener Application, Post and Beam Repair, Weatherproofing Services, Exterior Wood Restoration, Wooden Window Repair, Termite Damage Repair, Wood Restoration for Historical Buildings",
  };

  return jobSectorServiceTypeObj[jobSector]?.split(", ") ?? [];
}

export const AverageJobPriceMapper = {
  [JobSectors.ACCESSIBILITY_SOLUTIONS]: {
    Subcategory:
      "Wheelchair Ramp Installation, Stairlift Installation, Bathroom Accessibility Modifications, Grab Bar Installation, Door Widening for Wheelchair Access, Accessible Shower Installation, Kitchen Accessibility Upgrades, Automated Home Systems for Accessibility, Non-Slip Flooring Installation, Visual Alert Systems, Voice-Activated Home Systems, Accessible Pathway Installation, Elevator Installation, Accessible Lighting Solutions, Custom Accessibility Modifications, Emergency Response System Installation, Adaptive Equipment Installation, Therapeutic Equipment Installation, Safety Alarm Installation, Mobility Aids Installation",
    Industry: "Accessibility Solutions",
    "Estimated Cost Range of Leads": "$50 - $150",
    "National Average Lead Costs": "$100",
  },
  [JobSectors.AIR_QUALITY_AND_PURIFICATION]: {
    Subcategory:
      "Air Purifier Installation, HVAC Air Filtration Upgrades, Duct Cleaning and Sanitization, Humidity Control Solutions, Indoor Air Quality Testing, Radon Testing and Mitigation, Carbon Monoxide Detector Installation, Ventilation System Upgrades, Mold Testing and Remediation, Allergen Reduction Solutions, UV Light Air Sanitization, HEPA Filter Installation, Air Quality Monitoring Systems, Eco-Friendly Air Solutions, Energy-Efficient Air Systems, Air Dehumidifier Installation, Air Ionizer Installation, Air Vent Cleaning, Whole House Air Purification, Ozone Air Treatment",
    Industry: "Air Quality and Purification",
    "Estimated Cost Range of Leads": "$50 - $100",
    "National Average Lead Costs": "$75",
  },
  [JobSectors.AIR_DUCT_CLEANING]: {
    Subcategory:
      "Air Filter Replacement, Air Quality Testing, Air Vent Cleaning, Duct Cleaning and Sanitization, Dryer Vent Cleaning, Duct Inspection, Duct Replacement, Duct Sealing, Mold Removal from Ducts",
    Industry: "Air Duct Cleaning",
    "Estimated Cost Range of Leads": "$100 - $300",
    "National Average Lead Costs": "$150",
  },
  [JobSectors.APPLIANCES]: {
    Subcategory:
      "Appliance Repair, Appliance Installation, Refrigerator Maintenance, Washer and Dryer Repair, Dishwasher Repair, Oven and Range Repair, Microwave Repair, Small Appliance Repair, Smart Appliance Setup, Appliance Parts Replacement, Energy-Efficient Appliance Upgrades, Integrated Appliance Installation, Commercial Appliance Services, Appliance Maintenance Programs, Appliance Recycling, Gas Appliance Installation, Electric Appliance Installation, Refrigeration System Repair, Custom Appliance Fittings, Appliance Warranty Services",
    Industry: "Appliances",
    "Estimated Cost Range of Leads": "$50 - $100",
    "National Average Lead Costs": "$75",
  },
  [JobSectors.APPLIANCE_SALES]: {
    Subcategory:
      "Retail Appliance Sales, Online Appliance Sales, Commercial Appliance Sales, Smart Home Appliance Sales, Energy-Efficient Appliance Sales, Home Appliance Showrooms, Used Appliance Sales, Appliance Accessories Sales, Bulk Appliance Orders, Luxury Appliance Sales, Kitchen Appliance Packages, Appliance Leasing Options, Wholesale Appliance Distribution, Discount Appliance Sales, High-End Appliance Retail, Home Appliance Financing, Appliance Trade-In Programs, Appliance Delivery Services, Appliance Installation with Purchase, Extended Warranty Appliance Sales",
    Industry: "Appliance Sales",
    "Estimated Cost Range of Leads": "$50 - $100",
    "National Average Lead Costs": "$75",
  },
  [JobSectors.ASPHALT_AND_PAVING]: {
    Subcategory:
      "Driveway Paving, Asphalt Repair, Parking Lot Paving, Driveway Resurfacing, Sidewalk Installation, Asphalt Sealing, Pothole Repair, Line Striping and Marking, Asphalt Maintenance, Concrete Paving, Paver Installation, Retaining Wall Construction, Decorative Asphalt, Sports Court Paving, Pathway Paving, Commercial Paving Services, Paving Consultation and Design, Grading and Excavation, Paving  ",
    Industry: "Asphalt and Paving",
    "Estimated Cost Range of Leads": "$50 - $150",
    "National Average Lead Costs": "$100",
  },
  [JobSectors.AUTO_GLASS]: {
    Subcategory:
      "Auto Glass Chip Repair, Auto Glass Crack Repair, Automotive Glass Services, Glass Leak Repair, Glass Scratch Removal, Power Window Repair, Rear Window Replacement, Side Window Replacement, Window Regulator Replacement, Windshield Repair, Windshield Replacement, Window Tinting",
    Industry: "Auto Glass Repair and Replacement",
    "Estimated Cost Range of Leads": "$150 - $500",
    "National Average Lead Costs": "$250",
  },
  [JobSectors.BATHROOM_REMODELING]: {
    Subcategory:
      "Full Bathroom Renovation, Shower Installation, Bathtub Replacement, Toilet Installation, Vanity and Sink Upgrade, Bathroom Flooring Installation, Bathroom Tile Work, Lighting and Electrical Upgrades, Plumbing Overhaul, Accessibility Features, Cabinet Installation, Mirror Installation, Painting and Finishing, Exhaust Fan Installation, Heated Floors Installation, Custom Shower Doors, Bathroom Expansion, Waterproofing Services, Window Replacement, Countertop Installation",
    Industry: "Bathroom Remodeling",
    "Estimated Cost Range of Leads": "$100 - $200",
    "National Average Lead Costs": "$150",
  },
  [JobSectors.BAT_REMOVAL]: {
    Subcategory:
      "Bat Inspection, Bat Exclusion Service Bat Guano Cleanu Bat Habitat Remova Bat Proofing and Sealin Emergency Bat Remova Bat Colony Relocation",
    Industry: "Bat Removal",
    "Estimated Cost Range of Leads": "$100 - $200",
    "National Average Lead Costs": "$150",
  },
  [JobSectors.BLINDS]: {
    Subcategory:
      "Blind Installation, Blind Repair, Motorized Blinds Setup, Custom Blind Design, Blind Cleaning, Window Measurements, Cordless Blinds Installation, Vertical Blinds Installation, Roller Blinds Fitting, Venetian Blinds Installation, Smart Blinds Integration, Blind Color Customization, Energy-Efficient Blinds Installation, Blackout Blinds Installation, Child Safety Blinds Installation, Remote Controlled Blinds Setup, Blind Replacement, Window Treatment Consultation, Light Filtering Blinds Installation, Office Blinds Installation",
    Industry: "Blinds",
    "Estimated Cost Range of Leads": "$50 - $60",
    "National Average Lead Costs": "$55",
  },
  [JobSectors.CARPENTRY]: {
    Subcategory:
      "Custom Furniture Building, Cabinet Making, Wooden Frame Construction, Deck Building, Door Installation and Repair, Window Frame Construction, Shelving Installation, Trim and Molding Work, Staircase Building and Repair, Fence and Gate Making, Wooden Flooring Installation, Pergola and Gazebo Construction, Wooden Partition Building, Roof Framing, Custom Woodworking Projects, Playhouse and Treehouse Building, Closet Building, Wood Rot Repair, Handrail and Banister Installation, Wood Paneling",
    Industry: "Carpentry",
    "Estimated Cost Range of Leads": "$30 - $100",
    "National Average Lead Costs": "$65",
  },
  [JobSectors.CARPET_CLEANING]: {
    Subcategory:
      "Steam Cleaning, Stain Removal, Odor Removal, Eco-Friendly Cleaning, Carpet Deodorizing, Pet Stain and Odor Removal, Area Rug Cleaning, Carpet Repair and Stretching, Upholstery Cleaning, Commercial Carpet Cleaning, Carpet Protection Treatment, Dry Cleaning, Hot Water Extraction, Deep Carpet Cleaning, Carpet Inspection, Spot Cleaning, Carpet Sanitization, Allergy Relief Treatment, Wool Carpet Care",
    Industry: "Carpet Cleaning",
    "Estimated Cost Range of Leads": "$10 - $30",
    "National Average Lead Costs": "$20",
  },
  [JobSectors.CHIMNEY_SWEEP]: {
    Subcategory:
      "Chimney Cleaning, Chimney Inspection, Chimney Repair, Creosote Removal, Chimney Lining, Fireplace Cleaning, Chimney Cap Installation, Chimney Masonry Work, Chimney Waterproofing, Smoke Chamber Repair, Chimney Crown Repair, Flue Repair, Chimney Rebuilding, Firebox Repair, Chimney Damper Installation, Animal Removal, Chimney Leak Repair, Chimney Flashing, Chimney Tuckpointing, Chimney Draft Improvement",
    Industry: "Chimney Sweep",
    "Estimated Cost Range of Leads": "$20 - $50",
    "National Average Lead Costs": "$35",
  },
  [JobSectors.CHRISTMAS_LIGHTING]: {
    Subcategory:
      "Holiday Light Installation, Christmas Light Design, Light Hanging Service, Commercial Holiday Lighting, Residential Christmas Lighting, LED Christmas Lights Installation, Light Removal and Storage, Roofline Light Installation, Tree Lighting, Wreath and Garland Installation, Custom Light Displays, Outdoor Holiday Decorations, Timer Setup for Lights, Energy-Efficient Lighting, Repair of Christmas Lights, Window Decoration, Icicle Lights Installation, Animated Light Display Setup, Festive Yard Art Setup, Christmas Light Maintenance",
    Industry: "Christmas Lighting",
    "Estimated Cost Range of Leads": "$10 - $25",
    "National Average Lead Costs": "$20",
  },
  [JobSectors.CLOSETS_SHELVING_AND_ORGANIZATION]: {
    Subcategory:
      "Custom Closet Design, Shelving Installation, Closet Organizing Systems, Walk-in Closet Build, Garage Storage Solutions, Pantry Organization, Office Shelving, Kids' Closet Organization, Space Maximization, Closet Remodeling, Closet Accessory Installation, Shoe Storage Solutions, Wardrobe Design, Mudroom Storage, Laundry Room Organization, Linen Closet Organization, Closet Lighting, Custom Cabinetry, Jewelry Storage Solutions, Adjustable Shelving Setup",
    Industry: "Closets, Shelving, and Organization",
    "Estimated Cost Range of Leads": "$50 - $100",
    "National Average Lead Costs": "$75",
  },
  [JobSectors.CONCRETE_AND_MASONRY]: {
    Subcategory:
      "Concrete Driveway Installation, Masonry Repair, Stamped Concrete, Brickwork, Retaining Wall Building, Concrete Patio Installation, Stone Veneer Installation, Foundation Repair, Concrete Resurfacing, Sidewalk and Walkway Installation, Decorative Concrete Work, Concrete Sealing, Chimney Masonry, Fire Pit Construction, Concrete Staining, Block Wall Construction, Concrete Demolition and Removal, Stone Masonry, Concrete Countertop Installation, Brick Paver Installation",
    Industry: "Concrete and Masonry",
    "Estimated Cost Range of Leads": "$60 - $150",
    "National Average Lead Costs": "$105",
  },
  [JobSectors.CRAWLSPACES]: {
    Subcategory:
      "Encapsulation, Insulation, Waterproofing, Ventilation, Mold Remediation, Sump Pump Installation, Dehumidification, Structural Repair, Cleaning and Maintenance, Vapor Barrier Installation, Drainage Solutions, Access Door Installation, Floor Repair, Odor Removal, Inspections, Moisture Control, Radon Mitigation, Air Quality Improvement, Foundation Repair, Thermal Imaging Inspection",
    Industry: "Crawlspaces",
    "Estimated Cost Range of Leads": "$50 - $300",
    "National Average Lead Costs": "$175",
  },

  [JobSectors.CUSTOM_BUILDS_AND_REMODELING]: {
    Subcategory:
      "Custom Home Building, Kitchen Remodeling, Bathroom Remodeling, Basement Finishing, Room Additions, Home Extensions, Historical Renovation, Attic Conversion, Custom Carpentry, Window Installation, Door Installation, Custom Cabinetry, Custom Countertops, Home Office Remodeling, Sunroom Addition, Second Story Addition, Eco-Friendly Remodeling, Aging-In-Place Modifications, Open Floor Plan Conversion, Exterior Remodeling, Interior Design Services, Custom Flooring, Smart Home Upgrades, Energy Efficiency Upgrades, Home Theater Installation, Custom Lighting Solutions, Roof Remodeling, Custom Staircase Building, Home Gym Installation, Wine Cellar Construction",
    Industry: "Custom Builds and Remodeling",
    "Estimated Cost Range of Leads": "$100 - $250",
    "National Average Lead Costs": "$175",
  },
  [JobSectors.DECKS_AND_PATIOS]: {
    Subcategory:
      "Deck Building, Patio Installation, Deck Repair, Patio Repair, Composite Decking Installation, Patio Paver Installation, Deck Staining and Sealing, Patio Enclosures, Custom Deck Design, Patio Lighting Installation, Deck Expansion, Screened Porch Installation, Outdoor Kitchen Construction, Patio Landscaping, Deck Railing Installation, Fire Pit Installation, Gazebo Building, Outdoor Fireplace Construction, Deck Waterproofing, Patio Cover Installation, Pergola Installation, Deck Resurfacing, Outdoor Entertainment Area Design, ADA Compliant Deck Construction, Solar Panel Decking, Hot Tub Installation on Deck, Deck Lighting System, Patio Drainage Solutions",
    Industry: "Decks and Patios",
    "Estimated Cost Range of Leads": "$60 - $150",
    "National Average Lead Costs": "$105",
  },
  [JobSectors.DRAIN_AND_SEWER]: {
    Subcategory:
      "Drain Cleaning, Sewer Line Repair, Clogged Drain Repair, Sewer Line Inspection, Drainage System Installation, Septic Tank Services, Hydro Jetting, Sewer Line Replacement, Video Camera Pipe Inspection, Trenchless Sewer Repair, Sump Pump Installation, Sewer Ejector Pump Installation, Grease Trap Cleaning, Storm Drain Cleaning, Sewer Line Maintenance, French Drain Installation, Backwater Valve Installation, Sewer Odor Detection, Drainage Repair, Basement Waterproofing, Sewer Line Relining, Septic System Repair, Drain Vent Cleaning, Sewer Pipe Lining, Rain Garden Installation, Sewer Backup Prevention, Sewage Grinder Pump Installation, Sewer Cleanout Installation",
    Industry: "Drain and Sewer",
    "Estimated Cost Range of Leads": "$40 - $100",
    "National Average Lead Costs": "$70",
  },
  [JobSectors.DRYER_VENT_CLEANING]: {
    Subcategory:
      "Dryer Vent Inspection, Lint Removal, Vent Rerouting, Vent Repair, Booster Fan Installation, Transition Hose Replacement, Bird Guard Installation, Vent Cover Replacement, Dryer Vent Upgrades, Mold Removal in Vents, Energy Efficiency Assessment, Fire Safety Inspection, Multi-Unit Dryer Vent Cleaning, Airflow Testing, Dryer Vent System Restoration, Dryer Interior Cleaning, Rooftop Vent Cleaning, Dryer Vent Alarm Installation, Custom Vent Solutions, Dryer Vent Code Compliance, Pest Prevention in Vents, Magnetic Vent Closure Installation, Dryer Ventilation Optimization, Dryer Vent Relocation, Lint Sensor Installation, Exterior Vent Cleaning",
    Industry: "Dryer Vent Cleaning",
    "Estimated Cost Range of Leads": "$30 - $50",
    "National Average Lead Costs": "$40",
  },
  [JobSectors.DRYWALL]: {
    Subcategory:
      "Drywall Installation, Drywall Repair, Plaster Repair, Ceiling Drywall Repair, Soundproofing, Water Damage Repair, Texture Matching, Mold Remediation in Drywall, Drywall Finishing, Fire-Resistant Drywall Installation, Drywall Sanding, Popcorn Ceiling Removal, Custom Drywall Shapes, Acoustic Ceiling Installation, Insulation Installation, Drywall Art and Texturing, Drywall Patching, Vapor Barrier Installation, Wall Skimming, Drywall Demolition, LED Light Strip Installation in Drywall, Wallpaper Removal, Framing for Drywall, Green Board Installation, Drywall for Basements, Metal Stud Framing, Bullnose Corners Installation, Arched Drywall Construction",
    Industry: "Drywall",
    "Estimated Cost Range of Leads": "$40 - $90",
    "National Average Lead Costs": "$65",
  },
  [JobSectors.ELECTRICAL]: {
    Subcategory:
      "Electrical Panel Upgrade, Lighting Installation, Outlet Installation, Electrical Wiring, Generator Installation, Circuit Breaker Replacement, Electrical Inspection, Smart Home Automation, Surge Protection, Emergency Electrical Repairs, Electrical Troubleshooting, EV Charger Installation, Ceiling Fan Installation, Landscape Lighting, Pool and Hot Tub Wiring, Security System Installation, Recessed Lighting, Home Theater Wiring, Data Cabling, Smoke Detector Installation, Solar Panel Installation, Electrical Maintenance, GFCI Outlet Installation, Energy Efficient Lighting, Knob and Tube Replacement, Motion Sensor Lighting, LED Retrofit, Childproofing Electrical Outlets",
    Industry: "Electrical",
    "Estimated Cost Range of Leads": "$40 - $100",
    "National Average Lead Costs": "$70",
  },
  [JobSectors.EXTERIOR_CLEANING_AND_PRESSURE_WASHING]: {
    Subcategory:
      "House Washing, Driveway Cleaning, Deck Cleaning, Gutter Cleaning, Roof Cleaning, Sidewalk Cleaning, Graffiti Removal, Fence Cleaning, Pool Area Cleaning, Garage Floor Cleaning, Commercial Pressure Washing, Solar Panel Cleaning, Brick Cleaning, Stucco Cleaning, Window Washing, Moss and Algae Removal, Patio Cleaning, Awning Cleaning, Chimney Cleaning, Oil Stain Removal, Paint Preparation, Rust Removal, Wood Restoration, Pressure Washing Consultation, Eco-Friendly Cleaning Solutions, Seasonal Cleaning Services, Parking Lot Cleaning, Heavy Equipment Cleaning, Historic Building Cleaning",
    Industry: "Exterior Cleaning and Pressure Washing",
    "Estimated Cost Range of Leads": "$20 - $50",
    "National Average Lead Costs": "$35",
  },
  [JobSectors.FENCING]: {
    Subcategory:
      "Fence Installation, Fence Repair, Privacy Fence Installation, Chain Link Fence Installation, Wood Fence Installation, Vinyl Fence Installation, Aluminum Fence Installation, Pool Fence Installation, Electric Gate Installation, Fence Painting and Staining, Security Fence Installation, Fence Post Replacement, Decorative Fence Design, Farm Fencing, Wrought Iron Fence Installation, Automated Gate Systems, Bamboo Fencing, Dog Run Fencing, Temporary Construction Fencing, Split Rail Fence Installation, Acoustic Fencing, Lattice Work, Picket Fence Installation, Retaining Wall Fence Integration, Wildlife Barrier Fencing, Fence Height Extension, Custom Fence Gates",
    Industry: "Fencing",
    "Estimated Cost Range of Leads": "$40 - $100",
    "National Average Lead Costs": "$70",
  },
  [JobSectors.FLOORING]: {
    Subcategory:
      "Hardwood Flooring Installation, Laminate Flooring Installation, Tile Flooring Installation, Carpet Installation, Vinyl Flooring Installation, Bamboo Flooring Installation, Cork Flooring Installation, Stone Flooring Installation, Epoxy Flooring Installation, Radiant Floor Heating, Parquet Flooring Installation, Floor Refinishing, Water Damage Floor Repair, Custom Floor Design, Subfloor Installation, Floor Leveling, Floor Sanding, Staircase Flooring Installation, Concrete Floor Polishing, Eco-Friendly Flooring Options, Mosaic Flooring, Carpet Repair, Floor Staining, Commercial Flooring Solutions, Gym Flooring Installation, Heated Bathroom Floors, Antique Floor Restoration",
    Industry: "Flooring",
    "Estimated Cost Range of Leads": "$40 - $100",
    "National Average Lead Costs": "$70",
  },
  [JobSectors.FOUNDATION_AND_WATERPROOFING]: {
    Subcategory:
      "Foundation Repair, Basement Waterproofing, Crawl Space Encapsulation, Sump Pump Installation, Foundation Crack Repair, Exterior Waterproofing, Interior Waterproofing, Mold and Mildew Remediation, French Drain Installation, Foundation Inspection, Wall Anchors Installation, Vapor Barrier Installation, Structural Repair, Concrete Foundation Installation, Grading and Drainage Solutions, Foundation Sealing, Window Well Installation, Foundation Underpinning, Egress Window Installation, Soil Stabilization, Waterproof Membrane Installation, Basement Floor Sealing, Helical Pier Installation, Groundwater Management, Retaining Wall Waterproofing, Hydrostatic Pressure Relief",
    Industry: "Foundation and Waterproofing",
    "Estimated Cost Range of Leads": "$50 - $150",
    "National Average Lead Costs": "$100",
  },
  [JobSectors.FURNITURE_REPAIR]: {
    Subcategory:
      "Upholstery Repair, Wood Furniture Repair, Leather Furniture Repair, Antique Furniture Restoration, Furniture Refinishing, Furniture Reupholstery, Scratch Repair, Frame Repair, Furniture Painting, Water Damage Repair, Furniture Polishing, Cane and Wicker Repair, Furniture Staining, Furniture Structural Repair, Custom Furniture Modifications, Touch-Up Services, Furniture Joint Reinforcement, Veneer Repair, Fabric Panel Replacement, Office Furniture Repair, Furniture Assembly Services, Furniture Cleaning, Glass Replacement in Furniture, Metal Furniture Repair, Furniture Hardware Replacement, Sofa Frame Repair, Custom Furniture Cushions",
    Industry: "Furniture Repair",
    "Estimated Cost Range of Leads": "$20 - $60",
    "National Average Lead Costs": "$40",
  },
  [JobSectors.GARAGE_DOOR_REPAIR_AND_INSTALLATION]: {
    Subcategory:
      "Garage Door Installation, Garage Door Repair, Garage Door Opener Installation, Garage Door Spring Replacement, Garage Door Panel Replacement, Custom Garage Door Design, Garage Door Maintenance, Garage Door Opener Repair, Garage Door Insulation, Garage Door Weather Stripping, Garage Door Remote Programming, Smart Garage Door Integration, Commercial Garage Door Services, Garage Door Sensor Repair, Garage Door Balance Adjustment, Emergency Garage Door Service, Garage Door Noise Reduction, Garage Door Seal Replacement, Garage Door Track Alignment, Roll-Up Garage Door Installation, Garage Door Lock Repair, Sectional Garage Door Installation, Carriage Style Garage Door Installation",
    Industry: "Garage Door Repair and Installation",
    "Estimated Cost Range of Leads": "$50 - $75",
    "National Average Lead Costs": "$60",
  },
  [JobSectors.GENERAL_CONTRACTING_AND_HANDYMAN]: {
    Subcategory:
      "General Home Repairs, Light Fixture Installation, Painting Services, Tile Installation, Door Repair, Carpentry Services, Flooring Repairs, Furniture Assembly, Window Repair, Drywall Patching, Plumbing Repairs, Electrical Work, Shelving Installation, Gutter Repair, Deck Repairs, Fence Maintenance, Crown Molding Installation, Ceiling Fan Installation, Appliance Installation, Bathroom Fixes, Home Improvement Consultations, Smart Home Device Installation, Childproofing Services, Exterior Home Repairs, Safety Upgrades, Minor Roof Repairs, Custom Woodworking, Weatherproofing, Wallpaper Installation, Home Maintenance Plans",
    Industry: "General Contracting and Handyman",
    "Estimated Cost Range of Leads": "$45 - $100",
    "National Average Lead Costs": "$70",
  },
  [JobSectors.GENERATORS]: {
    Subcategory:
      "Generator Installation, Generator Repair, Generator Maintenance, Portable Generator Setup, Standby Generator Installation, Generator Fuel System Installation, Generator Transfer Switch Installation, Generator Battery Replacement, Emergency Generator Services, Generator Load Testing, Solar Generator Installation, Diesel Generator Installation, Natural Gas Generator Installation, Propane Generator Installation, Generator Noise Reduction Solutions, Generator Control Panel Installation, Generator Wiring and Cabling, Home Backup Generator Installation, Commercial Generator Services, Generator Inspection and Testing",
    Industry: "Electrical Generators",
    "Estimated Cost Range of Leads": "$10 - $300",
    "National Average Lead Costs": "$70",
  },
  [JobSectors.GLASS_SERVICES_RESIDENTIAL]: {
    Subcategory:
      "Glass Shower Enclosure Installation, Storefront Glass Installation, Glass Repair Services, Mirror Installation, Glass Tabletop Fabrication, Glass Balustrades and Railings, Insulated Glass Unit (IGU) Installation, Skylight Installation and Repair",
    Industry: "Glass Services (Residential)",
    "Estimated Cost Range of Leads": "$20 - $60",
    "National Average Lead Costs": "$40",
  },
  [JobSectors.GUTTER_CLEANING]: {
    Subcategory:
      "Debris Removal, Downspout Unclogging, Gutter Inspection, Gutter Repair, Gutter Guard Installation, Gutter Resealing, Roof and Gutter Cleaning",
    Industry: "Gutter Cleaning",
    "Estimated Cost Range of Leads": "$10 - $50",
    "National Average Lead Costs": "$30",
  },
  [JobSectors.HOME_INSPECTIONS_AND_APPRAISALS]: {
    Subcategory:
      "Home Buyer Inspections, Pre-Sale Home Inspections, Appraisal Services, Radon Testing, Mold Inspections, Termite Inspections, Energy Audits, Structural Inspections, Electrical System Inspection, Plumbing Inspection, HVAC Inspection, Roof Inspection, Foundation Inspection, Home Condition Reports, Property Valuation, Lead Paint Testing, Asbestos Testing, Septic System Inspection, Water Testing, Infrared Thermal Imaging, Environmental Assessments, Zoning Compliance Inspection, Historical Property Appraisal, Condo Inspections, Commercial Property Inspections, Rental Property Inspections, Insurance Appraisals",
    Industry: "Home Inspections and Appraisals",
    "Estimated Cost Range of Leads": "$45 - $150",
    "National Average Lead Costs": "$95",
  },
  [JobSectors.HVAC]: {
    Subcategory:
      "AC Installation, Heating System Installation, HVAC Repair, Duct Cleaning, Thermostat Installation, Air Quality Testing, Furnace Maintenance, Heat Pump Installation, Boiler Repair, Emergency HVAC Services, Ventilation System Installation, Energy-Efficient Upgrades, Refrigerant Leak Repair, AC Tune-Up, Heating System Tune-Up, Air Filter Replacement, HVAC System Inspection, Ductless Mini-Split Systems, Humidifier Installation, Geothermal Heating and Cooling, Commercial HVAC Services, Smart HVAC Solutions, Zone Control Systems, UV Air Sanitizer Installation, HVAC Retrofitting, Radiant Heating Installation",
    Industry: "HVAC",
    "Estimated Cost Range of Leads": "$60 - $325",
    "National Average Lead Costs": "$190",
  },
  [JobSectors.INSULATION]: {
    Subcategory:
      "Attic Insulation, Wall Insulation, Spray Foam Insulation, Basement Insulation, Crawl Space Insulation, Insulation Removal, Thermal Imaging Inspections, Soundproofing, Roof Insulation, Green Insulation Solutions, Insulation Upgrades, Radiant Barrier Installation, Insulation for New Construction, Blown-In Insulation, Fiberglass Insulation, Cellulose Insulation, Energy Audits, Garage Insulation, Fireproofing Insulation, Insulation Repair, Weatherization Services, Duct Insulation, Foam Board Insulation, Reflective Insulation, Mineral Wool Insulation, Eco-Friendly Insulation Options",
    Industry: "Insulation",
    "Estimated Cost Range of Leads": "$40 - $120",
    "National Average Lead Costs": "$80",
  },
  [JobSectors.INTERIOR_CLEANING]: {
    Subcategory:
      "House Cleaning, Carpet Cleaning, Window Cleaning, Deep Cleaning Services, Maid Service, Upholstery Cleaning, Floor Cleaning, Kitchen Cleaning, Bathroom Cleaning, Dusting and Polishing, Move-In/Move-Out Cleaning, Eco-Friendly Cleaning, Steam Cleaning, Tile and Grout Cleaning, Pet Odor Removal, Ceiling and Wall Cleaning, Blinds and Drapery Cleaning, Post-Construction Cleaning, Organizing Services, Vacation Rental Cleaning, AirBnB Cleaning, Sanitization Services, Laundry and Ironing Services, Housekeeping Services, Commercial Cleaning, Seasonal Cleaning, Home Disinfection Services",
    Industry: "Interior Cleaning",
    "Estimated Cost Range of Leads": "$30 - $50",
    "National Average Lead Costs": "$40",
  },
  [JobSectors.INTERIOR_DESIGN]: {
    Subcategory:
      "Residential Interior Design, Commercial Interior Design, Space Planning, Kitchen Design, Bathroom Design, Bedroom Design, Living Room Design, Color Consultation, Furniture Selection, Lighting Design, Floor Plan Design, Custom Window Treatments, Art and Accessory Selection, Sustainable Design, Home Office Design, Custom Cabinetry Design, 3D Rendering, Home Staging, Remodeling Design, Material Selection, Children's Room Design, Historic Interior Restoration, Accessibility Design, Feng Shui Consulting, Eclectic Design, Contemporary Design, Traditional Design, Minimalist Design",
    Industry: "Interior Design",
    "Estimated Cost Range of Leads": "$40 - $150",
    "National Average Lead Costs": "$95",
  },
  [JobSectors.JUNK_REMOVAL_AND_HAULING]: {
    Subcategory:
      "Furniture Removal, Appliance Disposal, Yard Waste Removal, Construction Debris Hauling, Estate Cleanout, E-Waste Recycling, Garage Cleanout, Foreclosure Cleanouts, Office Cleanout, Hoarding Cleanout, Bulk Trash Removal, Hot Tub Removal, Shed Demolition and Removal, Scrap Metal Recycling, Storage Unit Cleanout, Donation Pickups, Hazardous Waste Disposal, Tire Recycling, Mattress Disposal, Refrigerator Disposal, Commercial Junk Removal, Storm Debris Removal, Land Clearing, Rental Property Cleanout, Attic Cleanout, Basement Cleanout, Real Estate Cleanout",
    Industry: "Junk Removal and Hauling",
    "Estimated Cost Range of Leads": "$20 - $60",
    "National Average Lead Costs": "$40",
  },
  [JobSectors.KITCHEN_REMODELING]: {
    Subcategory:
      "Kitchen Design Services, Cabinet Installation, Countertop Installation, Kitchen Island Installation, Backsplash Installation, Kitchen Lighting, Flooring Installation, Kitchen Appliance Installation, Custom Kitchen Cabinets, Kitchen Expansion, Pantry Organization Solutions, Eco-Friendly Kitchen Remodeling, Open Kitchen Layouts, Smart Kitchen Upgrades, Kitchen Window Installation, Water Filtration Systems, Kitchen Sink and Faucet Installation, Bar and Wine Storage Solutions, Energy-Efficient Appliances, Modern Kitchen Design, Traditional Kitchen Remodel, Kitchen Storage Optimization, Breakfast Nook Addition",
    Industry: "Kitchen Remodeling",
    "Estimated Cost Range of Leads": "$100 - $200",
    "National Average Lead Costs": "$150",
  },
  [JobSectors.LANDSCAPING]: {
    Subcategory:
      "Landscape Design, Lawn Care and Maintenance, Garden Installation, Tree Planting, Hardscaping, Irrigation Systems, Outdoor Lighting, Water Features, Retaining Walls, Landscape Construction, Xeriscaping, Seasonal Planting, Patio Installation, Fertilization, Pest Control, Hedge and Shrub Trimming, Yard Cleanup, Drainage Solutions, Artificial Grass Installation, Edible Gardens, Landscape Renovation, Walkways and Paths, Fire Pit Installation, Sustainable Landscaping, Wildlife Attracting Landscaping, Landscape Lighting, Vertical Gardens, Water Conservation Landscaping, Native Plant Landscaping",
    Industry: "Landscaping",
    "Estimated Cost Range of Leads": "$40 - $110",
    "National Average Lead Costs": "$75",
  },
  [JobSectors.LOCKSMITH]: {
    Subcategory:
      "Emergency Lockout Services, Lock Installation, Lock Repair, Key Duplication, High-Security Locks, Safe Installation, Rekeying Services, Electronic Locks, Master Key Systems, Broken Key Extraction, Lock Change, Car Key Replacement, Access Control Systems, Security Upgrades, Door Hardware Installation, Keyless Entry Systems, Padlock Services, Mailbox Locks, Deadbolt Installation, Smart Lock Installation, Cabinet Lock Services, Garage Door Locks, Peephole Installation, Lock Maintenance, Sliding Door Lock Repair, Window Lock Installation, Biometric Lock Systems",
    Industry: "Locksmith",
    "Estimated Cost Range of Leads": "$30 - $60",
    "National Average Lead Costs": "$45",
  },
  [JobSectors.MOVING_AND_STORAGE]: {
    Subcategory:
      "Residential Moving, Commercial Moving, Long-Distance Moving, Local Moving, Packing and Unpacking Services, Storage Solutions, International Moving, Specialty Item Moving, Office Relocation, Moving Supplies, Piano Moving, Art and Antique Moving, Furniture Disassembly and Reassembly, Climate-Controlled Storage, Mobile Storage, Vehicle Transportation, Moving Insurance, Custom Crating, Senior Moving Services, Student Moving, Military Moving, Containerized Moving, Warehouse Storage, Inventory Management, Moving Consultation, Corporate Relocation, Last-Minute Moving Services",
    Industry: "Moving and Storage",
    "Estimated Cost Range of Leads": "$40 - $100",
    "National Average Lead Costs": "$70",
  },
  [JobSectors.MOSQUITO_CONTROL]: {
    Subcategory:
      "Mosquito Inspection, Mosquito Barrier Treatment, Larvicide Application, Mosquito Misting System Installation, Mosquito Trap Installation, Seasonal Mosquito Control, Event Mosquito Control, Natural Mosquito Repellent Solutions, Outdoor Fogging Services, Eco-Friendly Mosquito Solutions, Residential Mosquito Control, Commercial Mosquito Control, Mosquito Habitat Reduction, Standing Water Treatment, Mosquito Bite Prevention, Custom Mosquito Control Plans, Tick and Mosquito Combination Treatment, Yard Mosquito Treatment, Mosquito Control for Pools and Water Features, Protective Mosquito Netting, Mosquito Breeding Site Elimination, Monthly Mosquito Treatments, All-Natural Mosquito Treatments, Mosquito Prevention Education",
    Industry: "Mosquito Control",
    "Estimated Cost Range of Leads": "$80 - $300",
    "National Average Lead Costs": "$190",
  },
  [JobSectors.OTHER]: {
    Subcategory:
      "Custom Services, Miscellaneous Repairs, Specialized Home Services, Unique Installation Services, Personalized Consultations, Creative Solutions, Adaptive Services, On-Demand Services, Special Requests, Innovative Home Solutions, Tailored Assistance, Diverse Offerings, Non-Standard Services, Flexible Service Options, Bespoke Home Care, Varied Home Tasks, Exclusive Services, Wide-Ranging Support, Distinctive Home Needs, Customizable Home Maintenance",
    Industry: "Other",
    "Estimated Cost Range of Leads": "$20 - $100",
    "National Average Lead Costs": "$60",
  },
  [JobSectors.PACKOUT_AND_STORAGE]: {
    Subcategory:
      "Residential Packing and Storage, Commercial Packing and Storage, Long-term Storage, Short-term Storage, Climate-controlled Storage, Document and Records Storage, Art and Antique Storage, Vehicle Storage, Inventory Storage, Disaster Recovery Packout and Storage, Mobile Storage Solutions, Logistics and Distribution, White Glove Packing and Moving, Specialty Item Packing and Storage, Secure Storage, Storage Consultation and Planning, De-cluttering and Organizing Services, Inventory Management Services, Pick-up and Delivery Services, Auction and Estate Services",
    Industry: "Packout and Storage",
    "Estimated Cost Range of Leads": "$100 - $300",
    "National Average Lead Costs": "$200",
  },
  [JobSectors.PAINTING]: {
    Subcategory:
      "Interior Painting, Exterior Painting, Deck Staining, Wallpaper Installation, Ceiling Painting, Drywall Repair, Cabinet Painting, Color Consultation, Faux Finishing, Trim Painting, Pressure Washing, Plaster Repair, Wallpaper Removal, Textured Painting, Custom Murals, Paint Removal, Wood Staining, Epoxy Floor Coating, Residential Painting, Commercial Painting, Lead Paint Removal, Specialty Coatings, High-Durability Coatings, Paint Matching, Graffiti Removal, Paint Restoration, Green Painting Solutions, Lacquer Finishing, Historic Home Painting, Paint Color Trend Consulting",
    Industry: "Painting",
    "Estimated Cost Range of Leads": "$30 - $90",
    "National Average Lead Costs": "$60",
  },
  [JobSectors.PEST_CONTROL]: {
    Subcategory:
      "Insect Control, Rodent Control, Termite Inspection, Ant Control, Cockroach Extermination, Bed Bug Treatment, Mosquito Control, Wildlife Removal, Flea and Tick Treatment, Bee and Wasp Removal, Bird Control, Mole and Vole Control, Spider Control, Bat Removal, Organic Pest Control, Integrated Pest Management, Seasonal Pest Control, Residential Pest Control, Commercial Pest Control, Preventative Pest Services, Lawn Pest Treatment, Pantry Pest Treatment, Fumigation Services, Pest Exclusion Services, Scorpion Control, Tick Yard Treatment, Snail and Slug Control, Gopher Control",
    Industry: "Pest Control",
    "Estimated Cost Range of Leads": "$50 - $125",
    "National Average Lead Costs": "$85",
  },
  [JobSectors.PEST_PROTECTION]: {
    Subcategory:
      "Pest Inspection, Termite Control, Bed Bug Treatment, Cockroach Control, Ant Control, Rodent Control, Flea Treatment, Tick Control, Mosquito Treatment, Spider Control, Wasp and Bee Removal, Moth Treatment, Silverfish Control, Earwig Control, Centipede Control, Fly Control, Scorpion Control, Carpet Beetle Treatment, Stored Product Pest Control, Pantry Pest Solutions, Insect Growth Regulator Application, Perimeter Pest Protection, Crawl Space Treatment, Lawn Pest Control, Eco-Friendly Pest Solutions, Pest Barrier Installation, Home Sealing Against Pests, Pest Prevention Program, Pest Control for Apartments, Commercial Pest Protection, Wildlife-Proofing for Pest Entry",
    Industry: "Pest Protection",
    "Estimated Cost Range of Leads": "$75 - $200",
    "National Average Lead Costs": "$125",
  },
  [JobSectors.PET_SERVICES]: {
    Subcategory:
      "Pet Sitting, Dog Walking, Pet Grooming, Pet Boarding, Pet Training, Doggy Daycare, Pet Waste Removal, Pet Transportation, Veterinary House Calls, Pet Nutrition Consulting, Pet Behavior Modification, Aquarium Maintenance, Emergency Pet Care, Pet Photography, Pet Insurance Services, Breed-Specific Care, Pet Supply Delivery, Animal Rescue Services, Bird Grooming, Mobile Pet Grooming, Exotic Pet Care, Senior Pet Care, Pet Event Services, Pet Wellness Checks, Cat Sitting, Small Animal Boarding, Dog Park Visits, Pet Birthday Parties, Puppy Socialization Classes",
    Industry: "Pet Services",
    "Estimated Cost Range of Leads": "$10 - $30",
    "National Average Lead Costs": "$20",
  },
  [JobSectors.PLUMBING]: {
    Subcategory:
      "Leak Detection, Drain Cleaning, Faucet Repair, Toilet Repair, Pipe Repair, Water Heater Installation, Garbage Disposal Installation, Emergency Plumbing, Sewer Repair, Sump Pump Installation, Backflow Prevention, Water Filtration System Installation, Gas Line Services, Plumbing Inspections, Bathroom Plumbing, Kitchen Plumbing, Plumbing Maintenance, Hydro Jetting, Radiator Repair, Pipe Relining, Septic Tank Services, Water Pressure Issues, Tankless Water Heater Installation, Fixture Installation, Frozen Pipe Repair, Water Leak Repair, Plumbing Upgrades, Greywater Systems",
    Industry: "Plumbing",
    "Estimated Cost Range of Leads": "$40 - $150",
    "National Average Lead Costs": "$95",
  },
  [JobSectors.POOL_AND_SPA]: {
    Subcategory:
      "Pool Cleaning, Spa Maintenance, Pool Filter Replacement, Hot Tub Cleaning, Pool Winterization, Spa Repair, Pool Heating System Installation, Pool Resurfacing, Pool Leak Detection, Spa Winterization, Pool Lighting Installation, Spa Filter Replacement, Pool Cover Installation, Jacuzzi Repair, Pool Tile Repair, Spa Lighting Installation, Pool Chemical Balancing, Hot Tub Cover Replacement, Pool Deck Repair, Spa Leak Detection, Pool Equipment Upgrade, Sauna Installation, Pool Automation, Hot Tub Winterization, Inground Pool Installation, Above Ground Pool Installation",
    Industry: "Pool and Spa",
    "Estimated Cost Range of Leads": "$30 - $125",
    "National Average Lead Costs": "$75",
  },
  [JobSectors.PROPERTY_MANAGEMENT]: {
    Subcategory:
      "Tenant Screening, Property Maintenance, Rent Collection, Lease Management, Property Inspections, Eviction Services, Financial Reporting for Properties, Vendor Management, Property Marketing, Legal Compliance, Maintenance Coordination, Lease Renewal Management, Emergency Response, Utility Management, Property Renovation Coordination, Budgeting for Properties, Security Deposit Management, Tenant Communication, Property Value Assessment, Building Safety Compliance, Landscaping Management, Cleaning Services Coordination, Sustainable Property Practices, Tenant Move-In/Move-Out Coordination",
    Industry: "Property Management",
    "Estimated Cost Range of Leads": "$75 - $200",
    "National Average Lead Costs": "$135",
  },
  [JobSectors.REAL_ESTATE]: {
    Subcategory:
      "Property Listing, Buyer Representation, Seller Representation, Real Estate Market Analysis, Property Showings, Real Estate Consulting, Transaction Coordination, Property Valuation, Negotiation Services, Open House Organization, Relocation Services, Investment Property Services, Commercial Real Estate Services, First-Time Home Buyer Assistance, Property Staging, Lease Agreements, Real Estate Development, Property Management, Land Acquisition, Real Estate Marketing, Closing Services, Foreclosure Services, Short Sale Negotiation, Mortgage Referral Services, Luxury Real Estate Services",
    Industry: "Real Estate",
    "Estimated Cost Range of Leads": "$100 - $300",
    "National Average Lead Costs": "$200",
  },
  [JobSectors.RESTORATION_AND_REMEDIATION]: {
    Subcategory:
      "Water Damage Restoration, Fire Damage Restoration, Mold Remediation, Smoke Damage Cleanup, Storm Damage Repair, Flood Damage Restoration, Biohazard Cleanup, Asbestos Removal, Lead Paint Removal, Odor Removal, Disaster Response Services, Structural Drying, Debris Removal, Emergency Board-Up Services, Indoor Air Quality Improvement, Contents Restoration, Thermal Imaging Inspections, Sewage Cleanup, Crime Scene Cleanup, Vandalism Repair, Historic Property Restoration, Electronics Restoration, Document Drying and Recovery, Environmental Remediation, Safe Demolition and Deconstruction",
    Industry: "Restoration and Remediation",
    "Estimated Cost Range of Leads": "$250 - $1,000",
    "National Average Lead Costs": "$625",
  },
  [JobSectors.ROOFING]: {
    Subcategory:
      "Roof Repair, Roof Installation, Roof Inspection, Roof Replacement, Gutter Installation, Shingle Replacement, Leak Repair, Flat Roof Services, Metal Roofing, Tile Roofing, Skylight Installation, Roof Ventilation, Roof Coating, Roof Cleaning, Chimney Flashing Repair, Gutter Repair, Storm Damage Repair, Green Roofing, Energy Efficient Roofing, Emergency Roof Services, Snow Removal, Roof Waterproofing, Roof Insulation, Roof Deck Installation, Fascia and Soffit Repair, Roof Maintenance Programs, Commercial Roofing Services, Roof Design Services, Solar Panel Installation on Roof",
    Industry: "Roofing",
    "Estimated Cost Range of Leads": "$250 - $1,000",
    "National Average Lead Costs": "$625",
  },
  [JobSectors.SENIOR_CARE]: {
    Subcategory:
      "In-Home Senior Care, Alzheimer's Care, Companion Services, Personal Care Assistance, Respite Care, Medication Management, Senior Transportation Services, Meal Preparation, 24-Hour Care Services, Post-Hospitalization Care, Senior Wellness Programs, Mobility Assistance, Home Safety Assessments, Specialized Care for Chronic Conditions, Nursing Care Services, Palliative Care, Dementia Care, End-of-Life Care, Health Monitoring, Caregiver Support, Senior Activity Programs, Household Management, Technology Assistance for Seniors, Geriatric Care Management, Long-Term Care, Short-Term Senior Care",
    Industry: "Senior Care",
    "Estimated Cost Range of Leads": "$40 - $100",
    "National Average Lead Costs": "$70",
  },
  [JobSectors.SIDING]: {
    Subcategory:
      "Vinyl Siding Installation, Fiber Cement Siding Installation, Wood Siding Repair, Siding Replacement, Aluminum Siding Repair, Siding Painting, Insulated Siding Installation, Soffit and Fascia Repair, Siding Inspection, Historic Siding Restoration, Siding Cleaning, Metal Siding Installation, Siding Color Matching, Stone Veneer Siding, Stucco Siding Repair, Siding Leak Repair, Energy Efficient Siding, Siding Trim Work, Cedar Shake Siding, Siding Damage Assessment, Board and Batten Siding, Commercial Siding Services, Siding Ventilation, Seamless Siding Installation, Fire-Resistant Siding, Siding Customization Options",
    Industry: "Siding",
    "Estimated Cost Range of Leads": "$40 - $150",
    "National Average Lead Costs": "$95",
  },
  [JobSectors.SOLAR_AND_ENERGY]: {
    Subcategory:
      "Solar Panel Installation, Solar System Design, Solar Panel Maintenance, Energy Efficiency Audits, Solar Battery Storage Solutions, Solar Panel Repair, Solar Water Heating, Residential Solar Solutions, Commercial Solar Solutions, Solar Inverter Installation, Renewable Energy Consulting, Solar Panel Upgrade, Solar Financing Solutions, Wind Turbine Installation, Solar Electric System Installation, Off-Grid Solar Systems, Solar Tax Credit Assistance, Sustainable Energy Solutions, Solar Energy Monitoring, Green Building Consulting, Solar Carport Installation, Net Metering Setup, Solar Pool Heating, LED Lighting Upgrades",
    Industry: "Solar and Energy",
    "Estimated Cost Range of Leads": "$80 - $250",
    "National Average Lead Costs": "$165",
  },
  [JobSectors.TERMITE_INSPECTION]: {
    Subcategory:
      "Comprehensive Termite Inspection, Pre-Construction Soil Treatment, Post-Infestation Termite Treatment and Control, Structural Damage Assessment and Documentation, Bait Station Installation and Monitoring for Termites, Termite-Resistant Barrier Installation, Drywood Termite Treatment, Annual Termite Prevention Plan, Subterranean Termite Treatment, Localized Spot Treatment for Termite Infestations",
    Industry: "Termite Inspection",
    "Estimated Cost Range of Leads": "$40 - $150",
    "National Average Lead Costs": "$95",
  },
  [JobSectors.TILE_AND_STONE]: {
    Subcategory:
      "Tile Flooring Installation, Natural Stone Flooring, Bathroom Tile Installation, Kitchen Backsplash Installation, Tile Repair, Grout Cleaning, Stone Countertop Installation, Tile Sealing, Mosaic Tile Design, Stone Fireplace Installation, Outdoor Tile Installation, Tile Regrouting, Stone Wall Cladding, Marble Flooring Installation, Porcelain Tile Installation, Tile Removal, Stone Patio Installation, Custom Tile Work, Heated Tile Floor Installation, Tile Restoration, Stone Veneer Installation, Waterjet Tile Cutting, Tile Color Consultation, Travertine Installation, Slate Tile Installation, Tile Accent Walls",
    Industry: "Tile and Stone",
    "Estimated Cost Range of Leads": "$40 - $100",
    "National Average Lead Costs": "$70",
  },
  [JobSectors.TREE_REMOVAL_AND_TRIMMING]: {
    Subcategory:
      "Tree Removal, Tree Trimming, Stump Grinding, Emergency Tree Services, Tree Pruning, Tree Health Assessment, Arborist Services, Tree Planting, Hedge Trimming, Tree Cabling and Bracing, Tree Disease Treatment, Brush Clearing, Land Clearing, Tree Risk Assessment, Ornamental Pruning, Root Pruning, Tree Transplanting, Storm Damage Cleanup, Fruit Tree Pruning, Tree Fertilization, Tree Pest Control, Tree Preservation, Vegetation Management, Deadwood Removal, Canopy Lifting, Tree Watering Services, Tree Mulching, Eco-Friendly Tree Removal, Tree Thinning, Tree Structure Evaluation",
    Industry: "Tree Removal and Trimming",
    "Estimated Cost Range of Leads": "$30 - $100",
    "National Average Lead Costs": "$65",
  },
  [JobSectors.WATER_TREATMENT]: {
    Subcategory:
      "Water Quality Testing, Whole House Water Filtration, Reverse Osmosis System Installation, Water Softening, Hard Water Treatment, Iron and Sulfur Removal, UV Water Purification, Well Water Treatment, pH Balancing, Carbon Filtration Systems, Chlorine Removal, Water Purifier Installation, Drinking Water Filter Installation, Salt-Free Water Softeners, Chemical-Free Water Treatment, Alkaline Water Solutions, Sediment Filtration, Residential Water Treatment, Commercial Water Treatment, Water Contaminant Testing, Bacteria and Virus Removal, Nitrate Reduction, Point-of-Use Water Filters, Point-of-Entry Water Filtration, Lead and Heavy Metal Removal, Water Odor Control, Maintenance for Water Treatment Systems, Water Treatment Consultation, Eco-Friendly Water Solutions, Custom Water Treatment Systems",
    Industry: "Water Treatment",
    "Estimated Cost Range of Leads": "$75 - $250",
    "National Average Lead Costs": "$150",
  },
  [JobSectors.WINDOWS_DOORS_AND_SIDING]: {
    Subcategory:
      "Window Installation, Door Installation, Window Replacement, Door Replacement, Energy Efficient Windows, Sliding Door Installation, Vinyl Siding Installation, Window Repair, Door Repair, Custom Windows and Doors, Entry Door Installation, Siding Installation, Window Glass Replacement, Storm Door Installation, Siding Repair, Patio Door Installation, Historic Window Restoration, French Door Installation, Bay Window Installation, Window Insulation, Security Door Installation, Window Frame Repair, Custom Siding Solutions, Egress Window Installation, Garage Door Siding, Window Sealing, Skylight Installation",
    Industry: "Windows, Doors, and Siding",
    "Estimated Cost Range of Leads": "$40 - $150",
    "National Average Lead Costs": "$95",
  },
  [JobSectors.WINDOW_TINTING]: {
    Subcategory:
      "Automotive window tinting, Residential window tinting, Commercial window tinting, Safety and security film installation, UV protection window film installation, Heat reduction window film installation, Privacy window film installation, Decorative window film installation, Glare reduction window film installation, Anti-graffiti window film installation, Paint protection film installation, Headlight and taillight tinting, Window film removal services",
    Industry: "Window Tinting",
    "Estimated Cost Range of Leads": "$40 - $150",
    "National Average Lead Costs": "$95",
  },
  [JobSectors.WILDLIFE_CONTROL_AND_REMOVAL]: {
    Subcategory:
      "Animal Trapping, Bird Control, Bat Exclusion, Rodent Control, Snake Removal, Raccoon Removal, Opossum Removal, Skunk Removal, Squirrel Control, Dead Animal Removal, Wildlife Damage Repair, Wildlife Prevention Services, Wildlife Inspection and Assessment, Exclusion Barrier Installation, Humane Animal Removal, Nest Removal, Animal Droppings Cleanup, Attic Animal Proofing, Crawl Space Animal Proofing, Emergency Wildlife Removal, Wildlife Habitat Modification, Critter-proof Fencing, Professional Wildlife Consultation, Wildlife Repellent Installation, Bait Station Setup, Bird Netting Installation, Bird Spike Installation, Electric Fence Installation, Structural Animal Proofing, Hazardous Waste Removal from Wildlife",
    Industry: "Wildlife Control and Removal",
    "Estimated Cost Range of Leads": "$100 - $500",
    "National Average Lead Costs": "$250",
  },

  [JobSectors.WOOD_ROT_REPAIR]: {
    Subcategory:
      "Wood Rot Inspection, Wood Rot Removal, Structural Wood Rot Repair, Sill Plate Replacement, Joist and Beam Repair, Wall Framing Repair, Floor Repair, Exterior Trim Replacement, Window Frame Repair, Door Frame Repair, Wood Replacement for Decks, Fascia Board Repair, Roof Overhang Repair, Foundation Repair, Wood Rot Treatment, Rot-Resistant Wood Installation, Waterproofing Solutions, Wood Sealing and Caulking, Gutter Repair to Prevent Rot, Mold Remediation, Spray Foam Insulation for Rot Prevention, Wood Hardener Application, Post and Beam Repair, Weatherproofing Services, Exterior Wood Restoration, Wooden Window Repair, Termite Damage Repair, Wood Restoration for Historical Buildings",
    Industry: "Wood Rot Repair",
    "Estimated Cost Range of Leads": "$40 - $150",
    "National Average Lead Costs": "$95",
  },
};
