import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";
import { JobSectors } from "@/company/jobs/constants";

export interface UpdateAdminJobRequestPayload {
  sector?: JobSectors;
  summary?: string;
  jobType?: string;
}

const createPatchRequests = (
  payloads: Array<{
    id: number;
    jobDetails: UpdateAdminJobRequestPayload;
  }>
) => {
  return payloads.map(({ id, jobDetails }) => {
    const jobPayload = changeCaseKeys(jobDetails, "underscored");
    const updateJobUri = `/jobs/${id}`;
    return API.patch(updateJobUri, jobPayload);
  });
};

export function useUpdateJobsById(
  options: UseMutationOptions<
    void,
    AxiosError,
    Array<{ id: number; jobDetails: UpdateAdminJobRequestPayload }>
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  Array<{ id: number; jobDetails: UpdateAdminJobRequestPayload }>
> {
  return useMutation({
    mutationFn: async payloads => {
      const patchRequests = createPatchRequests(payloads);
      await axios.all(patchRequests);
    },
    ...options,
  });
}
