import { Delete } from "@mui/icons-material";
import ArchiveIcon from "@mui/icons-material/Archive";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Drawer,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridEventListener,
  GridFilterModel,
  GridRowId,
  GridRowModesModel,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import axios from "axios";
import changeCaseKeys from "change-case-keys";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  formatDateTime,
  formatDateV2,
  formatToDate,
  formatToDateTime,
} from "@/analytics/components/JobsTable";
import { USER_TYPE } from "@/auth/types";
import API from "@/common/api";
import { AppConstants } from "@/common/constants";
import { Languages } from "@/common/constants/languages";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useCreateTableView } from "@/company/api/useCreateTableView";
import { useQueryJobSearch } from "@/company/api/useQueryJobSearch";
import { useReRouteJob } from "@/company/api/useReRouteJob";
import { AdminChatProvider } from "@/company/context/admin-chat-context";
import { useCloseJob } from "@/company/jobs/api/useCloseJob";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { JobDrawerComponentById } from "@/company/jobs/components/JobDrawerById";
import { JobSectors } from "@/company/jobs/constants";
import { Job, JobStatus } from "@/company/jobs/types";
import { JobSource } from "@/company/jobs/types/job";
import { ProjectJobStatusChipProps } from "@/company/jobs/utils";
import {
  CompanyStatus,
  ConnectionStatus,
  PayReferralFeeSettings,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import {
  getGridDateOperatorsDataGrid,
  getGridNumericOperatorsDataGrid,
} from "@/utils";

import { useDeleteJob } from "../api/useDeleteJob";
import { useUpdateJobsById } from "../api/useUpdateJobsById";
import ConfirmationDialog from "../ConfirmationDialog";
import { InternalAdminCompanyDrawer } from "../InternalAdminCompanyDrawer";
import ReRouteJobDialog from "../ReRouteJobDialog";
import { CreateTableViewDialog } from "../saved-searches/CreateTableViewDialog";
import { TableType } from "../saved-searches/SavedSearchTable";
import {
  CustomToolbar,
  drawerWidth,
  getDifference,
  getGridStringOperatorsDataGrid,
  Main,
} from "../utils";
import ArchiveJobConfirmationDialog from "./ArchiveJobConfirmationDialog";
import CompanySelectionDialog from "./CompanySelectionDialog";

export default function InternalAdminJobsTable({
  name,
  preFilledColumns,
  payload,
}: {
  name?: string;
  preFilledColumns?: Array<any>;
  payload?: Record<string, any>;
}) {
  const [rows, setRows] = useState([]);
  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const unsavedChangesRef = useRef<{
    unsavedRows: Record<GridRowId, GridValidRowModel>;
    rowsBeforeChange: Record<GridRowId, GridValidRowModel>;
  }>({
    unsavedRows: {},
    rowsBeforeChange: {},
  });

  const [serverSorting, setServerSorting] = useState<
    undefined | GridSortModel
  >();

  const apiRef = useGridApiRef();

  // Custom filter operator for "is not"

  const handleDownload = async (jobId: number, type: JobUploadTypes) => {
    try {
      const response = await API.get(
        `/internal-admin/jobs/${jobId}/resource-url`
      ); // Replace with your API endpoint
      const videoUrl = response.data.data.url; // Adjust according to the API response

      // Create an anchor element and trigger the download

      const media = await axios.get(videoUrl, {
        responseType: "blob",
      });

      // Create a link element to trigger the download
      const urlObject = window.URL.createObjectURL(new Blob([media.data]));
      const link = document.createElement("a");
      link.href = urlObject;

      if (type === JobUploadTypes.VIDEO) {
        link.setAttribute("download", `video-job-id-${jobId}.mp4`);
      } else {
        link.setAttribute("download", `audio-job-id-${jobId}.mp3`);
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Cleanup
      link.remove();
    } catch (error) {
      console.error("Error downloading the video:", error);
    }
  };

  const [createTableViewDialogOpen, setCreateTableViewDialogOpen] =
    useState(false);

  const [serverFiltering, setServerFiltering] = useState<
    undefined | GridFilterModel
  >();

  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const [companyProfileId, setCompanyProfileId] = useState<number | null>();
  const [jobInvitationId, setJobInvitationId] = useState<number | null>();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [chosenJob, setChosenJob] = useState<Job | undefined>(undefined);
  const [cancelJobId, setCancelJobId] = useState<number | undefined>();
  const [deleteJobId, setDeleteJobId] = useState<number | undefined>();

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const {
    data: jobs,
    isFetching,
    isLoading,
    refetch,
  } = useQueryJobSearch(
    // paginationModel.page,
    // paginationModel.pageSize,
    // filters,
    // serverSorting,
    // serverFiltering,
    { refetchOnWindowFocus: false } // Two minutes
  );

  const [selectedJobId, setSelectedJobId] = useState<number | undefined>();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (
      params.field === "currentRoutedCompanyName" ||
      params.field === "currentRoutedCompanyId"
    ) {
      setCompanyProfileId(params.row.currentRoutedCompanyId);
      return;
    }
    if (params.field === "id") {
      setSelectedJobId(params.row.id);
      return;
    }
    if (
      params.field === "buyingCompanyName" ||
      params.field === "buyingCompanyId"
    ) {
      setCompanyProfileId(params.row.buyingCompanyId);
    }
    if (
      params.field === "createdByCompanyId" ||
      params.field === "createdByCompanyName"
    ) {
      setCompanyProfileId(params.row.createdByCompanyId);
    }

    if (
      params.field === "createdForCompanyId" ||
      params.field === "createdForCompanyName"
    ) {
      setCompanyProfileId(params.row.createdForCompanyId);
    }
  };

  useEffect(() => {
    setRows(jobs?.jobs);
  }, [jobs]);

  let columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
      type: "number",
      renderCell: params => params.row.id.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
    },
    {
      field: "publicId",
      headerName: "Public Id",
      filterOperators: getGridStringOperatorsDataGrid,
      renderCell: params => params.row.publicId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      width: 200,
    },
    {
      field: "sector",
      headerName: "Sector",
      width: 200,
      type: "singleSelect",
      valueGetter: (value, row) => row?.sector,
      valueOptions: [...Object.keys(JobSectors)],
      editable: true,
      renderCell: params => {
        return (
          <Chip
            key={params.row?.sector}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={snakeCaseJobSectors[params.row?.sector as JobSectors]}
            size="small"
          />
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) => formatToDateTime(row?.createdAt),
      renderCell: params => formatDateTime(params.row?.createdAt),
    },
    {
      field: "jobType",
      headerName: "Job Type",
      filterOperators: getGridStringOperatorsDataGrid,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            sx={{ cursor: "pointer" }}
            gap={1}
          >
            <Typography variant="body2">{params.row.jobType}</Typography>
          </Box>
        );
      },
      width: 200,
      type: "string",
      editable: true,
    },
    {
      field: "summary",
      headerName: "Summary",
      filterOperators: getGridStringOperatorsDataGrid,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            sx={{ cursor: "pointer" }}
            gap={1}
          >
            <Typography variant="body2">{params.row.summary}</Typography>
          </Box>
        );
      },
      width: 200,
      type: "string",
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      valueGetter: (value, row) => row.status,
      valueOptions: [...Object.keys(JobStatus)],
      renderCell: params => {
        if (params.row.status === JobStatus.NO_COMPANY_TO_ROUTE) {
          return (
            <Chip
              label="NO COMPANY TO ROUTE"
              sx={{
                bgcolor: "rgba(240, 68, 56, 0.12)",
                color: "#B42318",
              }}
            />
          );
        }
        return (
          <Chip
            label={
              ProjectJobStatusChipProps[params.row.status as JobStatus].label
            }
            sx={{
              bgcolor:
                ProjectJobStatusChipProps[params.row.status as JobStatus]
                  .bgcolor,
              color:
                ProjectJobStatusChipProps[params.row.status as JobStatus].color,
              fontSize: "12px",
              fontWeight: 600,
              height: "24px",
            }}
          />
        );
      },
      width: 180,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      type: "singleSelect",
      valueSetter: (value, row) => {
        return {
          ...row,
          type: value?.replaceAll(" ", "_"),
        };
      },
      valueGetter: (value, row) => row?.type,
      valueOptions: [...Object.keys(JobUploadTypes)],
    },
    {
      field: "createdByCompanyLogoUrl",
      headerName: "Created By Company Logo Url",
      filterable: false,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <img
              src={params.row.createdByCompanyLogoUrl}
              style={{
                width: "48px",
                height: "32px",
                objectFit: "contain",
              }}
              alt="company-logo"
            />
          </Box>
        );
      },
      sortable: false,
    },

    {
      field: "createdByCompanyId",
      headerName: "Created By Company ID",
      width: 150,
      renderCell: params => params.row.createdByCompanyId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      type: "number",
    },

    {
      field: "createdByCompanyName",
      headerName: "Created By Company Name",
      filterOperators: getGridStringOperatorsDataGrid,
      width: 200,
    },
    {
      field: "createdByCompanyMarkedOnPlatformAt",
      headerName: "Date Created By Company Was Marked On Platform",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.createdByCompanyMarkedOnPlatformAt),
      renderCell: params =>
        formatDateTime(params.row?.createdByCompanyMarkedOnPlatformAt),
    },

    {
      field: "createdByCompanyOnboardedAt",
      headerName: "Date Created By Company Onboarded",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.createdByCompanyOnboardedAt),
      renderCell: params =>
        formatDateTime(params.row?.createdByCompanyOnboardedAt),
    },
    {
      field: "createdByCompanyStatus",
      headerName: "Created By Company Status",
      width: 150,
      type: "singleSelect",
      valueOptions: [...Object.keys(CompanyStatus)],
    },
    {
      field: "createdByCompanyDeletedAt",
      headerName: "Date Created By Company Deleted",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.createdByCompanyDeletedAt),
      renderCell: params =>
        formatDateTime(params.row?.createdByCompanyDeletedAt),
    },
    {
      field: "createdByCompanyBrandId",
      headerName: "Created By Company Brand Id",
      width: 150,
      type: "number",
      renderCell: params => params.row.createdByCompanyBrandId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "createdByCompanyBrandName",
      headerName: "Created By Company Brand Name",
      width: 150,
      type: "string",
      filterOperators: getGridStringOperatorsDataGrid,
    },
    {
      field: "createdByCompanyFamilyOfBrandsId",
      headerName: "Created By Company Family Of Brands Id",
      renderCell: params =>
        params.row.createdByCompanyFamilyOfBrandsId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      width: 150,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "createdByCompanyFamilyOfBrandsName",
      headerName: "Created By Company Family Of Brands Name",
      width: 150,
      type: "string",
      filterOperators: getGridStringOperatorsDataGrid,
    },
    {
      field: "createdByCompanyPrivateEquityId",
      headerName: "Created By Company Private Equity Id",
      renderCell: params =>
        params.row.createdByCompanyPrivateEquityId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      width: 150,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "createdByCompanyPrivateEquityName",
      headerName: "Created By Company Private Equity Name",
      width: 150,
      type: "string",
      filterOperators: getGridStringOperatorsDataGrid,
    },
    {
      field: "createdByUserName",
      headerName: "Technician Name",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 150,
    },
    {
      field: "createdByUserEmail",
      headerName: "Technician Email",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "createdByUserCellPhone",
      headerName: "Technician Cell Phone",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "createdByUserCellPhoneCarrierType",
      headerName: "Technician Phone Carrier Type",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 150,
    },

    {
      field: "createdByUserId",
      headerName: "Technician ID",
      width: 150,
      renderCell: params => params.row.createdByUserId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      type: "number",
    },
    {
      field: "jobBuyingCost",
      headerName: "Job Completion Fee",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      valueFormatter: value => value && `$${value?.toString()}`,
      renderCell: params =>
        params.row?.jobBuyingCost ? `$${params.row?.jobBuyingCost}` : "",
    },
    {
      field: "leadPrice",
      headerName: "Pay Per Referral Fee",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      valueFormatter: value => value && `$${value?.toString()}`,
      renderCell: params =>
        params.row?.leadPrice ? `$${params.row?.leadPrice}` : "",
    },
    {
      field: "companyFee",
      headerName: "Company Fee",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      valueFormatter: value => value && `$${value?.toString()}`,
      renderCell: params =>
        params.row?.jobBuyingCost && params.row?.technicianFee
          ? `$${params.row?.jobBuyingCost - params.row?.technicianFee}`
          : "",
    },
    {
      field: "technicianFee",
      headerName: "Technician Fee",
      type: "number",
      valueFormatter: value => value && `$${value?.toString()}`,
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      renderCell: params =>
        params.row?.technicianFee ? `$${params.row?.technicianFee}` : "",
    },
    {
      field: "salePrice",
      headerName: "Sale Price",
      type: "number",
      valueFormatter: value => value && `$${value?.toString()}`,
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      renderCell: params =>
        params.row?.salePrice ? `$${params.row?.salePrice}` : "",
    },

    {
      field: "percentageFee",
      headerName:
        "Percentage Fee (this is the percentage of the sale price that will be paid as the referral fee)",
      width: 150,
      type: "number",
      valueFormatter: value => value && `${value?.toString()}%`,
      filterOperators: getGridNumericOperatorsDataGrid,
      renderCell: params =>
        params.row?.percentageFee ? `${params.row?.percentageFee}%` : "",
    },
    {
      field: "routeToHomeowner",
      headerName: "Route to Homeowner",
      width: 150,
      type: "boolean",
    },
    {
      field: "source",
      headerName: "Source",
      width: 150,
      type: "singleSelect",
      valueOptions: [...Object.keys(JobSource)],
      valueSetter: (value, row) => {
        return {
          ...row,

          source: value?.replaceAll(" ", "_"),
        };
      },
      valueGetter: (value, row) => row?.source,
    },
    {
      field: "buyingCompanyId",
      headerName: "Buying Company ID",
      renderCell: params => params.row.buyingCompanyId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      width: 150,
      type: "number",
    },
    {
      field: "buyingCompanyName",
      headerName: "Buying Company",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
      valueGetter: (value, row) => row.buyingCompanyName,
      renderCell: params => params.row?.buyingCompanyName,
    },
    {
      field: "buyingCompanyMarkedOnPlatformAt",
      headerName: "Date Buying Company Was Marked On Platform",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.buyingCompanyMarkedOnPlatformAt),
      renderCell: params =>
        formatDateTime(params.row?.buyingCompanyMarkedOnPlatformAt),
    },

    {
      field: "buyingCompanyOnboardedAt",
      headerName: "Date Buying Company Onboarded",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.buyingCompanyOnboardedAt),
      renderCell: params =>
        formatDateTime(params.row?.buyingCompanyOnboardedAt),
    },

    {
      field: "buyingCompanyBrandId",
      headerName: "Buying Company Brand Id",
      width: 150,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      renderCell: params => params.row.buyingCompanyBrandId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
    },
    {
      field: "buyingCompanyBrandName",
      headerName: "Buying Company Brand Name",
      width: 150,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "buyingCompanyFamilyOfBrandsId",
      headerName: "Buying Company Family Of Brands Id",
      width: 150,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueFormatter: value => value && `${value?.toString()}`,
      renderCell: params =>
        params.row.buyingCompanyFamilyOfBrandsId?.toString(),
    },
    {
      field: "buyingCompanyFamilyOfBrandsName",
      headerName: "Buying Company Family Of Brands Name",
      width: 150,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "buyingCompanyPrivateEquityId",
      headerName: "Buying Company Private Equity Id",
      width: 150,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueFormatter: value => value && `${value?.toString()}`,
      renderCell: params => params.row.buyingCompanyPrivateEquityId?.toString(),
    },
    {
      field: "buyingCompanyPrivateEquityName",
      headerName: "Buying Company Private Equity Name",
      width: 150,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "publicJobLink",
      headerName: "Public Job Link",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
      valueGetter: (value, row) =>
        `${window.location.origin}/open/job/${row?.publicId}`,
      renderCell: params =>
        `${window.location.origin}/open/job/${params.row?.publicId}`,
    },
    {
      field: "jobLandingPageInvitationLink",
      headerName: "Job Landing Page Invitation Link",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
      valueGetter: (value, row) =>
        `${window.location.origin}?jobId=${row?.publicId}&invitationCode=${row?.createdByCompanyInvitationCode}`,
      renderCell: params =>
        `${window.location.origin}?jobId=${params.row?.publicId}&invitationCode=${params.row?.createdByCompanyInvitationCode}`,
    },
    {
      field: "videoTranscription",
      headerName: "Video Transcription",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "videoLanguage",
      headerName: "Video Language",
      width: 150,
      type: "singleSelect",
      valueOptions: [...Object.keys(Languages)],
    },
    { field: "videoMimeType", headerName: "Video MIME Type", width: 150 },
    {
      field: "completionDate",
      headerName: "Completion Date",
      width: 150,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) => formatToDate(row?.completionDate),
      renderCell: params => formatDateV2(params.row?.completionDate),
    },
    {
      field: "homeownerAllowedAutoRoutingAt",
      headerName: "Auto Routing At",
      width: 150,

      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.homeownerAllowedAutoRoutingAt),
      renderCell: params =>
        formatDateTime(params.row?.homeownerAllowedAutoRoutingAt),
    },
    {
      field: "payReferralFeeSettings",
      headerName: "Pay Referral Fee Settings",
      width: 200,
      type: "singleSelect",
      valueGetter: (value, row) => row?.payReferralFeeSettings,
      valueSetter: (value, row) => {
        return {
          ...row,

          payReferralFeeSettings: value?.replaceAll(" ", "_"),
        };
      },
      valueOptions: [...Object.keys(PayReferralFeeSettings)],
    },
    {
      field: "createdByCompanyInvitationCode",
      headerName: "Company Invitation Code",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "createdByUserType",
      headerName: "Created By User Type",
      type: "singleSelect",
      valueGetter: (value, row) => row?.createdByUserType,
      valueSetter: (value, row) => {
        return {
          ...row,
          createdByUserType: value?.replaceAll(" ", "_"),
        };
      },
      valueOptions: [...Object.keys(USER_TYPE)],
      width: 150,
    },
    {
      field: "homeId",
      headerName: "Home ID",
      width: 150,
      type: "number",
      valueFormatter: value => value && `${value?.toString()}`,
      renderCell: params => params.row.homeId?.toString(),
    },
    {
      field: "homeStreetAddress",
      headerName: "Home Street Address",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "homeLatitude",
      headerName: "Home Latitude",
      width: 150,
      type: "number",
    },
    {
      field: "homeLongitude",
      headerName: "Home Longitude",
      width: 150,
      type: "number",
    },
    {
      field: "homeZipCode",
      headerName: "Home Zip Code",
      width: 150,
      type: "number",
    },
    { field: "homeownerName", headerName: "Homeowner Name", width: 150 },
    {
      field: "homeownerId",
      headerName: "Homeowner ID",
      width: 150,
      type: "number",
      valueFormatter: value => value && `${value?.toString()}`,
      renderCell: params => params.row.homeownerId?.toString(),
    },
    {
      field: "homeownerCellPhone",
      headerName: "Homeowner Cell Phone",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "homeownerViewedAt",
      headerName: "Homeowner Viewed At",
      width: 150,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) => formatToDateTime(row?.homeownerViewedAt),
      renderCell: params => formatDateTime(params.row?.homeownerViewedAt),
    },
    {
      field: "createdForCompanyId",
      headerName: "Created For Company ID",
      width: 150,
      type: "number",
      valueFormatter: value => value && `${value?.toString()}`,
      renderCell: params => params.row.createdForCompanyId?.toString(),
    },
    {
      field: "createdForCompanyName",
      headerName: "Created For Company Name",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "secondsBetweenCreationAndOpportunity",
      headerName: "Minutes Between Creation and Opportunity",
      width: 200,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueGetter: (value, row) =>
        row?.secondsBetweenCreationAndOpportunity
          ? row?.secondsBetweenCreationAndOpportunity / 60
          : 0,
    },
    {
      field: "opportunityTime",
      headerName: "Opportunity Time",
      width: 150,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) => formatToDateTime(row?.opportunityTime),
      renderCell: params => formatDateTime(params.row?.opportunityTime),
    },
    {
      field: "paidAt",
      headerName: "Paid At",
      width: 150,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) => formatToDateTime(row?.paidAt),
      renderCell: params => formatDateTime(params.row?.paidAt),
    },
    {
      field: "claimedAt",
      headerName: "Claimed At",
      width: 150,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) => formatToDateTime(row?.claimedAt),
      renderCell: params => formatDateTime(params.row?.claimedAt),
    },
    {
      field: "secondsBetweenRoutingAndAcceptance",
      headerName: "Minutes Between Job Routing and Acceptance of the Job",
      type: "number",
      description:
        "This is the time it took the company that bought the job to accept the job after it got routed to them",
      width: 200,
      valueGetter: (value, row) =>
        row?.secondsBetweenRoutingAndAcceptance
          ? row?.secondsBetweenRoutingAndAcceptance / 60
          : 0,
    },

    {
      field: "userWhoApprovedTheJobName",
      headerName: "User Who Approved the Job Name",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "totalHomeownerViews",
      headerName: "Total Homeowner Views",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
    },
    {
      field: "totalCompanyViews",
      headerName: "Total Company Views",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
    },

    {
      field: "submissionTime",
      headerName: "Job Submission Time",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) => formatToDateTime(row?.submissionTime),
      renderCell: params => formatDateTime(params.row?.submissionTime),
      description:
        "Date and time the job was actually submitted, meaning the user clicked the submit button, this must match the creation time of the job status HOMEOWNER_ACCEPTED; if a job status is CLOSED and has a submission time that only means 2 things: 1.- The user created a job, but left the modal before clicking submit and we mark it as CLOSE, so it doesn’t appear as Opportunity (pending action) on their side, this is usually the case with duplicated or with jobs they just created and never ended upt sending; 2.- They created a job, but then clicked Cancel Job in the modal",
    },
    {
      field: "secondsBetweenSubmissionAndCreation",
      headerName: "Minutes Between Job Creation and Submission",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      valueGetter: (value, row) =>
        row?.secondsBetweenSubmissionAndCreation
          ? row?.secondsBetweenSubmissionAndCreation / 60
          : 0,
    },
    {
      field: "currentRoutedCompanyId",
      headerName: "Curret Routed Company ID",
      valueFormatter: value => value && `${value?.toString()}`,
      renderCell: params => params.row.currentRoutedCompanyId?.toString(),

      width: 150,
      type: "number",
    },
    {
      field: "currentRoutedCompanyName",
      headerName: "Current Routed Company Name",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
      valueGetter: (value, row) => row.currentRoutedCompanyName,
      renderCell: params => params.row?.currentRoutedCompanyName,
    },
    {
      field: "totalCompaniesIgnoredTheJob",
      headerName: "Total Companies who Received the job and Ignored",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      description:
        "Total number of companies that received the job and ignored it (job routing got marked as EXPIRED).",
    },
    {
      field: "totalCompaniesPaidTheJob",
      headerName: "Total Companies who Received the job and Paid",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      description:
        "Total number of companies that received the job and paid for it.",
    },
    {
      field: "totalCompaniesPendingJobApproval",
      headerName:
        "Total Companies that Received the job and and haven't Approved or Declined",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      description:
        "Total number of companies that received the job and haven't approved nor declined. ",
    },
    {
      field: "totalCompaniesDeclinedTheJob",
      headerName: "Total Companies that Received the job and Declined",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      description:
        "Total number of companies that received the job and declined it.",
    },
    {
      field: "totalCompaniesPendingPayment",
      headerName:
        "Total Companies that Received the job and is still Pending Payment",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      description:
        "Total number of companies that received the job, approved it and haven't pay it nor inactivate it.",
    },
    {
      field: "totalCompaniesInactivated",
      headerName: "Total Companies that Received the job and Inactivated",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      description:
        "Total number of companies that received the job, approved and later inactivated it.",
    },
    {
      field: "buyingCompanyPayReferralFeeAfterJobIsDone",
      headerName: "Is Job PAID per closed Job",
      type: "boolean",
      width: 200,
      description:
        "A job is PAID per closed job If the buying company pays the referral fee after the job is done",
    },
    {
      field: "jobPromotionId",
      headerName: "Job Promotion Id",
      type: "number",
      width: 200,
      description: "The ID of the job promotion associated with the job",
    },
    {
      field: "amountPaidByTradeEngage",
      headerName: "Total Amount Paid By TradeEngage",
      width: 150,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueGetter: (value, row) => row?.tradeEngagePayoutInfo?.totalAmount,
    },
    {
      field: "lastTradeEngagePaymentDate",
      headerName: "Last TradeEngage Payment Date",
      width: 150,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.tradeEngagePayoutInfo?.lastPaymentDate),
      renderCell: params =>
        formatDateTime(params.row?.tradeEngagePayoutInfo?.lastPaymentDate),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      minWidth: 200,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <Tooltip
            key={`${id}-send-invite-to-company-tooltip`}
            title="Click to invite companies to job"
          >
            <GridActionsCellItem
              key={`${id}-send-invite-to-company`}
              disabled={
                ![JobStatus.NO_COMPANY_TO_ROUTE, JobStatus.CLOSED].includes(
                  row.status
                )
              }
              label="send-invite-to-company"
              color="inherit"
              icon={<SendIcon />}
              onClick={() => {
                setJobInvitationId(row.id);
              }}
            />
          </Tooltip>,
          <Tooltip
            key={`${id}-download-tooltip`}
            title="Click to download job media"
          >
            <GridActionsCellItem
              key={`${id}-download`}
              disabled={!row.type || row.type === JobUploadTypes.TEXT}
              label="Download"
              color="inherit"
              icon={<DownloadIcon />}
              onClick={() => {
                handleDownload(row.id, row.type);
              }}
            />
          </Tooltip>,
          <Tooltip
            key={`${id}-re-route-tooltip`}
            title="Click to re-route the job"
          >
            <GridActionsCellItem
              key={`${id}-re-route`}
              disabled={
                ![
                  JobStatus.NO_COMPANY_TO_ROUTE,
                  JobStatus.CLOSED,
                  JobStatus.HOMEOWNER_ACCEPTED,
                  JobStatus.HOMEOWNER_DELAYED,
                ].includes(row.status)
              }
              label="Re-route"
              color="inherit"
              icon={<ReplayIcon />}
              onClick={() => {
                setChosenJob(row);
              }}
            />
          </Tooltip>,
          <Tooltip key={`${id}-close-tooltip`} title="Click to close the job">
            <GridActionsCellItem
              key={`${id}-archive`}
              label="Archive"
              color="inherit"
              icon={<ArchiveIcon />}
              onClick={() => {
                setCancelJobId(row.id);
              }}
            />
          </Tooltip>,
          <Tooltip key={`${id}-delete-tooltip`} title="Click to delete the job">
            <GridActionsCellItem
              key={`${id}-delete`}
              disabled={
                AppConstants.environment === "production" &&
                ![JobStatus.NO_COMPANY_TO_ROUTE, JobStatus.CLOSED].includes(
                  row.status
                )
              }
              label="Delete"
              color="inherit"
              icon={<Delete />}
              onClick={() => {
                setDeleteJobId(row.id);
              }}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  const [columnWidths, setColumnWidths] = useState(() => {
    const savedColumnWidths = localStorage.getItem("job-datagridColumnWidths");
    return savedColumnWidths
      ? JSON.parse(savedColumnWidths)
      : columns.reduce((acc, col) => {
          acc[col.field] = col.width;
          return acc;
        }, {});
  });

  const [columnOrder, setColumnOrder] = useState(() => {
    const savedColumnOrder = localStorage.getItem("job-datagridColumnOrder");
    return savedColumnOrder
      ? JSON.parse(savedColumnOrder)
      : columns.map(column => column.field);
  });

  const handleColumnResize = (newColumn: any) => {
    setColumnWidths({
      ...columnWidths,
      [newColumn.colDef.field]: newColumn.width,
    });
  };

  columns = columns.map(column => ({
    ...column,
    width: columnWidths?.[column.field] ?? column.width,
  }));

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  const oldColumns = [...columns];

  columns = [
    ...columnOrder.map(field => oldColumns.find(col => col.field === field)),
    ...oldColumns.filter(col => !columnOrder.includes(col.field)),
  ];

  const { mutateAsync: createTableView, isLoading: creatingTableView } =
    useCreateTableView({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while saving search.";
        enqueueSnackbar(message, {
          variant: "error",
        });
        refetch();
      },
      onSuccess(tableView) {
        enqueueSnackbar({
          message: "Search saved successfully.",
          variant: "success",
        });
        navigate(`/admin/saved-searches/${tableView.id}`, {
          state: { tableView },
        });
      },
    });

  useEffect(() => {
    if (payload) {
      setServerFiltering(payload.serverFiltering);
      setServerSorting(payload.serverSorting);
      setPaginationModel({ pageSize: payload.size, page: payload.page });
      if (payload.columnOrder) setColumnOrder(payload.columnOrder);
    }
  }, [payload]);

  useEffect(() => {
    if (preFilledColumns) {
      const newColumnWidths = preFilledColumns.reduce((acc, col) => {
        acc[col.field] = col.width;
        return acc;
      }, {});
      setColumnWidths(newColumnWidths);
      setColumnVisibilityModel(
        columns.reduce((acc, col) => {
          acc[col.field] = preFilledColumns.some(
            preFilledColumn => preFilledColumn.field === col.field
          );
          return acc;
        }, {})
      );
      for (const column of preFilledColumns) {
        apiRef.current.setColumnIndex(column.field, column.position);
        apiRef.current.pinColumn(column.field, column.pinned);
      }
    }
  }, [preFilledColumns, apiRef]);

  useEffect(() => {
    localStorage.setItem(
      "job-datagridColumnWidths",
      JSON.stringify(columnWidths)
    );
  }, [columnWidths]);

  useEffect(() => {
    localStorage.setItem(
      "job-datagridColumnOrder",
      JSON.stringify(columnOrder)
    );
  }, [columnOrder]);

  const { mutateAsync: reRouteJobAPI } = useReRouteJob({
    onSuccess: () => {
      enqueueSnackbar({
        message: "Job re-routed successfully",
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while re-routing the job`,
        variant: "error",
      });
    },
  });

  const { isLoading: isClosingJob, mutateAsync: closeJob } = useCloseJob({
    onSuccess: () => {
      refetch();
      setCancelJobId(undefined);
      enqueueSnackbar({
        message: "Job archived successfully",
        variant: "success",
      });
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while cancelling the job";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const { isLoading: isDeletingJob, mutateAsync: deleteJob } = useDeleteJob({
    onSuccess: () => {
      refetch();
      setDeleteJobId(undefined);
      enqueueSnackbar({
        message: "Job deleted successfully",
        variant: "success",
      });
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while deleting the job";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const { mutateAsync: updateJobs, isLoading: isSavingJobUpdates } =
    useUpdateJobsById({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating jobs.";
        enqueueSnackbar(message, {
          variant: "error",
        });
        refetch();
      },
      onSuccess() {
        refetch();
        enqueueSnackbar({
          message: "Your changes have been saved.",
          variant: "success",
        });
      },
    });

  const doDeepCompare = (oldRow, newRow) => {
    const newRowCompare = changeCaseKeys(_.cloneDeep(newRow), "camelize");
    const oldRowCompare = changeCaseKeys(_.cloneDeep(oldRow), "camelize");

    const areEqual2 = _.isEqual(
      JSON.stringify(oldRowCompare),
      JSON.stringify(newRowCompare)
    );
    return areEqual2;
  };

  const processRowUpdate = (newRow, oldRow) => {
    const rowId = newRow.id;

    if (doDeepCompare(oldRow, newRow)) {
      return newRow;
    }
    unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }
    setHasUnsavedRows(true);
    return newRow;
  };

  const discardChanges = () => {
    setHasUnsavedRows(false);
    apiRef.current.updateRows(
      Object.values(unsavedChangesRef.current.rowsBeforeChange)
    );
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
    };
  };

  const saveChanges = async () => {
    try {
      const beforeRows = unsavedChangesRef.current.rowsBeforeChange;
      const updatedRows = unsavedChangesRef.current.unsavedRows;
      const payloads =
        Object.values(updatedRows)?.map(newRow => {
          const beforeRow = Object.values(beforeRows)?.find(
            row => row.id === newRow.id
          );

          const jobDiff = getDifference(newRow, beforeRow);

          return {
            id: newRow.id,
            jobDetails: jobDiff,
          };
        }) ?? [];
      await updateJobs(payloads);
      setHasUnsavedRows(false);
      unsavedChangesRef.current = {
        unsavedRows: {},
        rowsBeforeChange: {},
      };
    } catch (error) {
      console.error(error);
    }
  };

  const reRouteJob = (companyId: number) => {
    if (chosenJob) {
      const jobId = chosenJob.id;
      reRouteJobAPI({ jobId, receivingCompanyId: companyId }).then(() => {
        refetch();
      });
    }
    setChosenJob(undefined);
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const { column, targetIndex } = params;

    // Reorder the columns based on the drag and drop
    const newColumnOrder = [...columns];
    const columnIndex = newColumnOrder.findIndex(
      col => col.field === column.field
    );

    // Remove the moved column and reinsert it at the new index
    const [movedColumn] = newColumnOrder.splice(columnIndex, 1);
    newColumnOrder.splice(targetIndex - 1, 0, movedColumn);

    // Extract the new field order
    const newFieldOrder = newColumnOrder.map(col => col.field);

    setColumnOrder(newFieldOrder);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    setServerSorting(model);
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    setServerFiltering(model);
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Box display={"flex"} sx={{ height: "100%" }} overflow={"auto"}>
      <Main
        open={!!(companyProfileId || selectedJobId)}
        sx={{
          width: expanded ? "0%" : `calc(100% - ${drawerWidth}px)`,
          display: expanded ? "none" : "visible",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
          p={3}
        >
          {name ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                href="/admin/saved-searches"
              >
                Saved Searches
              </Link>
              <Link underline="hover" color="inherit">
                {name}
              </Link>
            </Breadcrumbs>
          ) : null}
          <Typography variant="h5">Jobs</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={3}
          py={1}
        >
          <Box
            display={"flex"}
            gap={3}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="subtitle1" fontSize={"16px"}>
              {jobs?.total
                ? `Showing ${Intl.NumberFormat("en-US").format(
                    jobs?.total ?? 0
                  )} jobs`
                : "Fetching results"}
            </Typography>
            <Box
              display={"flex"}
              gap={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Button
                startIcon={<BookmarkAddOutlinedIcon />}
                variant="outlined"
                onClick={() => {
                  setCreateTableViewDialogOpen(true);
                }}
              >
                Save Search
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 260px)",
            px: 3,
          }}
          position={"relative"}
        >
          <Box display={"flex"} gap={2} mb={1} position={"relative"}>
            <LoadingButton
              size="small"
              disabled={!hasUnsavedRows}
              loading={isSavingJobUpdates}
              variant="contained"
              loadingPosition="start"
              onClick={saveChanges}
            >
              Save
            </LoadingButton>
            <LoadingButton
              size="small"
              disabled={!hasUnsavedRows || isSavingJobUpdates}
              variant={
                !hasUnsavedRows || isSavingJobUpdates ? "contained" : "outlined"
              }
              onClick={discardChanges}
            >
              Discard Changes
            </LoadingButton>
          </Box>
          <DataGridPremium
            checkboxSelection
            disableRowSelectionOnClick
            pagination
            disableAggregation
            disableRowGrouping
            cellSelection
            apiRef={apiRef}
            columns={columns}
            loading={isFetching || isLoading}
            slots={{
              toolbar: () => (
                <CustomToolbar fileName="Jobs" includeQuickFilter={false} />
              ),
            }}
            sx={{
              "& .MuiDataGrid-row": {
                "--rowBorderColor": "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withRightBorder": {
                borderRightColor: "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withLeftBorder": {
                borderLeftColor: "#D9D9D9",
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            rows={rows ?? []}
            editMode="row"
            rowModesModel={rowModesModel}
            initialState={{
              density: "compact",
              pinnedColumns: {
                left: [
                  GRID_CHECKBOX_SELECTION_COL_DEF.field,
                  "logoUrl",
                  "name",
                ],
                right: ["actions"],
              },
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            slotProps={{
              toolbar: { setRowModesModel },
            }}
            sortModel={serverSorting}
            paginationModel={paginationModel}
            filterModel={serverFiltering}
            // paginationMode="server"
            pageSizeOptions={[5, 10, 25, 50, 100, 250, 500, 5000]}
            disableMultipleColumnsSorting={false}
            columnOrder={columnOrder}
            processRowUpdate={processRowUpdate}
            onColumnVisibilityModelChange={newModel =>
              setColumnVisibilityModel(newModel)
            }
            onColumnOrderChange={params => handleColumnOrderChange(params)}
            onRowModesModelChange={handleRowModesModelChange}
            onColumnWidthChange={handleColumnResize}
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={handleSortModelChange}
            onCellClick={handleCellClick}
            onPaginationModelChange={setPaginationModel}
          />
        </Box>
      </Main>
      {expanded && companyProfileId ? (
        <AdminChatProvider companyId={companyProfileId}>
          <InternalAdminCompanyDrawer
            open={!!companyProfileId}
            companyProfileId={companyProfileId}
            connectionStatus={ConnectionStatus.CONNECTED}
            expanded={expanded}
            onClose={() => {
              setCompanyProfileId(null);
              setExpanded(false);
            }}
            onExpand={() => setExpanded(prev => !prev)}
          />
        </AdminChatProvider>
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!companyProfileId}
        >
          {companyProfileId ? (
            <AdminChatProvider companyId={companyProfileId}>
              <InternalAdminCompanyDrawer
                open={!!companyProfileId}
                expanded={expanded}
                companyProfileId={companyProfileId}
                connectionStatus={ConnectionStatus.CONNECTED}
                onClose={() => {
                  setCompanyProfileId(null);
                  setExpanded(false);
                }}
                onExpand={() => setExpanded(prev => !prev)}
              />
            </AdminChatProvider>
          ) : null}
        </Drawer>
      )}
      {selectedJobId ? (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!selectedJobId}
        >
          {selectedJobId ? (
            <JobDrawerComponentById
              jobId={selectedJobId}
              onClose={() => {
                setSelectedJobId(undefined);
              }}
            />
          ) : null}
        </Drawer>
      ) : null}
      {chosenJob ? (
        <ReRouteJobDialog
          open={!!chosenJob}
          job={chosenJob}
          handleConfirm={(companyId: number) => {
            reRouteJob(companyId);
          }}
          onClose={() => {
            setChosenJob(undefined);
          }}
        />
      ) : null}
      {cancelJobId ? (
        <ArchiveJobConfirmationDialog
          open={!!cancelJobId}
          jobId={cancelJobId}
          disabled={isClosingJob || isDeletingJob}
          handleConfirm={() => {
            closeJob(cancelJobId);
          }}
          handleClose={() => {
            setCancelJobId(undefined);
          }}
        />
      ) : null}
      {jobInvitationId ? (
        <CompanySelectionDialog
          isOpen={!!jobInvitationId}
          jobId={jobInvitationId}
          onConfirm={() => {
            setJobInvitationId(undefined);
          }}
          onClose={() => {
            setJobInvitationId(undefined);
          }}
        />
      ) : null}
      {deleteJobId ? (
        <ConfirmationDialog
          open={!!deleteJobId}
          handleConfirm={() => {
            deleteJob(deleteJobId);
          }}
          handleClose={() => {
            setDeleteJobId(undefined);
          }}
          text={`Are you sure you want to delete the job ${deleteJobId}?`}
          title="Confirm Delete"
          confirmText="Delete"
        />
      ) : null}
      {createTableViewDialogOpen ? (
        <CreateTableViewDialog
          isOpen={createTableViewDialogOpen}
          creatingTableView={creatingTableView}
          onCreate={({
            name,
            description,
          }: {
            name: string;
            description?: string;
          }) => {
            const payload = {
              page: paginationModel.page,
              size: paginationModel.pageSize,
              serverSorting,
              serverFiltering,
              columnOrder,
            };
            const columns = apiRef.current.getVisibleColumns();
            const columnsPayload = columns.map(column => ({
              field: column.field,
              width: column.width,
              position: apiRef.current.getColumnPosition(column.field),
              pinned: apiRef.current.isColumnPinned(column.field),
            }));
            createTableView({
              name,
              tableName: TableType.JOB,
              description,
              body: payload,
              columns: columnsPayload,
            });
            setCreateTableViewDialogOpen(false);
          }}
          onClose={() => setCreateTableViewDialogOpen(false)}
        />
      ) : null}
    </Box>
  );
}
