import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { CompanyTransaction } from "../types";

export const USE_GET_COMPANY_TRANSACTIONS_URI =
  "/company-payments/transactions";

export function useGetCompanyTransactions(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<CompanyTransaction>> {
  return useQuery({
    queryKey: [USE_GET_COMPANY_TRANSACTIONS_URI],
    queryFn: async () => {
      const response = await API.get(USE_GET_COMPANY_TRANSACTIONS_URI);
      return response.data.data;
    },
    ...options,
  });
}
