import { Grid, Theme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useQueryAuthFacts } from "@/company/onboarding/api";
import { SidePanel } from "@/company/onboarding/components/panel/SidePanel";
import { tradeEngageColors } from "@/Theme/colors";

export function AuthLayout() {
  const { pathname } = useLocation();
  const { data } = useQueryAuthFacts(
    pathname.split("/").slice(-1).pop() as string
  );
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsError(true);
  };

  return (
    <Grid container columns={16} height={isMobile ? "100dvh" : "100vh"}>
      {data && isDesktop ? (
        <Grid
          item
          xs={16}
          md={4}
          sx={{
            ...(isLoading ? { bgcolor: tradeEngageColors.darkBlue } : {}),
            height: { xs: "fit-content", md: "100%" },
            position: "relative",
          }}
        >
          <img
            src={"/sidenav-background-gradient.png"}
            alt={"side panel gradient"}
            style={{
              display: isLoading || isError ? "none" : "block",
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              zIndex: -100,
            }}
            onLoad={handleLoad}
            onError={handleError}
          />
          <SidePanel
            title={data?.title ?? ""}
            isQuote={data?.isQuote ?? false}
            fact={data?.fact ?? {}}
            heading={data?.heading ?? {}}
            author={data?.author ?? ""}
            authorTitle={data?.authorTitle ?? ""}
          />
        </Grid>
      ) : null}
      <Grid
        item
        xs={16}
        md={data && isDesktop ? 12 : 16}
        overflow={"auto"}
        position={"relative"}
      >
        <img
          src={
            isMobile
              ? "/background-gradient-mobile.png"
              : "/background-gradient.png"
          }
          alt={"company logo"}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: -100,
          }}
        />
        <Outlet />
      </Grid>
    </Grid>
  );
}
