/* eslint-disable jsx-a11y/media-has-caption */

import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import TimelapseOutlinedIcon from "@mui/icons-material/TimelapseOutlined";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AuthRoutes } from "@/auth/constants";
import { useGetReferralInvitationInfo } from "@/campaign/api/useGetReferralInvitationInfo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useGetCompanyPublicDetails } from "@/company/api/useGetCompanyPublic";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { useGetOpenJobById } from "@/company/jobs/api/useGetOpenJobById";
import { JobSectors } from "@/company/jobs/constants";
import { JobStatus } from "@/company/jobs/types";
import { useQueryBrandLocationById } from "@/company/onboarding/api";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { useLeadContext } from "@/lead/context/lead-context";
import { CompanyInfo } from "@/open/components/company-info/CompanyInfo";
import { PublicRoutes } from "@/open/constants";

import { CompanyInitialMessage } from "../../company-message.tsx/CompanyInitialMessage";
import { InvitationMarketingPage } from "./InvitationMarketingPage";
import { JobSignUpPage } from "./JobSignUpPage";

export function MarketingPage() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [searchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();

  const referralInvitationId = searchParams.get("referralInvitationId");

  const { data: referralInvitation, isLoading: loading } =
    useGetReferralInvitationInfo(referralInvitationId, {
      enabled: !!referralInvitationId,
      refetchOnWindowFocus: false,
    });
  const jobPublicId = searchParams.get("jobId");

  const handleButtonClick = () => {
    const videoUrl =
      "https://tradeengage-app.s3.amazonaws.com/videos/You're+Invited+to+Join+TradeEngage.mp4";
    window.open(videoUrl, "_blank");
  };

  const navigate = useNavigate();

  const invitedCompanyId = searchParams.get("invitedCompanyId");
  const invitationCode = searchParams.get("invitationCode");
  const teInvitation = searchParams.get("teInvitation");

  const utmCampaign = searchParams.get("utm_campaign") ?? undefined;
  const utmSource = searchParams.get("utm_source") ?? undefined;
  const utmMedium = searchParams.get("utm_medium") ?? undefined;

  const { data: invitedCompanyProfile } = useQueryBrandLocationById(
    { id: invitedCompanyId },
    { enabled: !!invitedCompanyId }
  );

  const { data: job } = useGetOpenJobById(jobPublicId ? jobPublicId : null, {
    refetchOnWindowFocus: false,
    enabled: Boolean(jobPublicId),
  });

  const {
    data: invitingCompanyData,
    isFetching: isFetchingInvitingCompanyData,
  } = useGetCompanyPublicDetails(invitationCode);

  const { updateLeadDetails } = useLeadContext();
  const handleSalesClick = async () => {
    const url =
      "https://calendly.com/tradeengage-chelsea/talk-to-tradeengage-sales";
    window.open(url, "_blank");
  };

  const isReferralInvitation = !!(
    invitationCode ||
    invitedCompanyId ||
    referralInvitationId
  );

  const linkMenu = (
    <>
      {isReferralInvitation ? (
        <>
          {" "}
          <Divider sx={{ width: "100%" }} />
          <Box
            display={"flex"}
            width={"100%"}
            py={1}
            justifyContent={"space-between"}
            alignItems={"center"}
            textAlign={"start"}
          >
            <Typography variant="subtitle2">
              How does TradeEngage work?
            </Typography>
            <Button
              variant="outlined"
              size="small"
              sx={{
                width: "fit-content",
              }}
              endIcon={<PlayCircleFilledWhiteOutlinedIcon />}
              onClick={handleButtonClick}
            >
              Watch Video
            </Button>
          </Box>
        </>
      ) : (
        <>
          {" "}
          <Divider sx={{ width: "100%" }} />
          <Box
            display={"flex"}
            width={"100%"}
            py={1}
            justifyContent={"space-between"}
            alignItems={"center"}
            textAlign={"start"}
          >
            <Typography variant="subtitle2">
              Are you a franchisor or enterprise?
            </Typography>
            <Button
              variant="outlined"
              size="small"
              sx={{
                width: "fit-content",
              }}
              endIcon={<CalendarMonthOutlinedIcon />}
              onClick={handleSalesClick}
            >
              Talk to Sales
            </Button>
          </Box>
        </>
      )}
      <Divider sx={{ width: "100%" }} />
      {referralInvitation ? (
        <Box
          display={"flex"}
          width={"100%"}
          py={1}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="subtitle2">
            Review and add this referral partner?
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              width: "fit-content",
              cursor: "pointer",
            }}
            endIcon={<KeyboardArrowRightIcon />}
            onClick={async () => {
              await updateLeadDetails({
                id: invitedCompanyProfile?.id,
                zipCode: invitedCompanyProfile?.zipCode,
                name: invitedCompanyProfile?.name,
                sectors: invitedCompanyProfile?.sectors as Array<JobSectors>,
                utmCampaign,
                utmMedium,
                utmSource,
                ...(invitedCompanyProfile && invitedCompanyProfile.name
                  ? { accountType: COMPANY_ACCOUNT_TYPES.BRAND_LOCATION }
                  : {}),
              });
              if (invitedCompanyProfile) {
                navigate(
                  `${getNavigationPathFromRoutePaths([
                    PublicRoutes.BASE,
                    PublicRoutes.FDD_COMPANY_IDENTIFIED,
                  ])}${invitationCode ? "?" : ""}${
                    invitationCode ? `&invitationCode=${invitationCode}` : ""
                  }`
                );
                return;
              }
            }}
          >
            Get Started
          </Button>
        </Box>
      ) : (
        <Box
          display={"flex"}
          width={"100%"}
          py={1}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="subtitle2">
            Questions? Contact our team
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              width: "fit-content",
              cursor: "pointer",
            }}
            endIcon={<EmailOutlinedIcon />}
            onClick={() => {
              const mailtoLink = `mailto:info@tradeengage.com`;
              window.location.href = mailtoLink;
            }}
          >
            Email Us
          </Button>
        </Box>
      )}
    </>
  );

  const isJobMarketingPage = jobPublicId;

  const jobDetails =
    isJobMarketingPage && job && invitedCompanyProfile ? (
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "start",
          width: "100%",
          alignItems: "start",
          justifyContent: "space-between",
          p: 2,
          boxShadow: "0px 0px 0px 1px #F2F4F7",
          "&:active": {
            border: "1px solid #071D2F",
          },
          "&:focus": {
            border: "1px solid #071D2F",
          },

          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
        }}
      >
        {job.status === JobStatus.NO_COMPANY_TO_ROUTE ||
        job.status === JobStatus.CLOSED ? (
          <Box display={"flex"} pb={2}>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={1}
              sx={{
                bgcolor: "rgba(240, 68, 56, 0.08)",
                borderRadius: "12px",
                px: 3,
                py: 1,
              }}
            >
              <TimelapseOutlinedIcon sx={{ color: "#F04438" }} />
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#F04438",
                }}
              >
                {"Expiring Soon"}
              </Typography>
            </Box>
          </Box>
        ) : null}
        <Typography variant="h6" pb={2}>
          Job Type: {job?.jobType}
        </Typography>
        {invitationCode && invitingCompanyData ? (
          <Box
            pb={2}
            sx={{
              display: "inline-block",
              whiteSpace: "normal",
              overflowWrap: "break-word",
              wordBreak: "break-word",
            }}
          >
            <Chip
              avatar={
                <CompanyAvatar
                  height="18px"
                  maxWidth="250px"
                  logo={invitingCompanyData?.logoUrl ?? ""}
                  name={invitingCompanyData?.name ?? ""}
                />
              }
              label={
                <Typography
                  variant="subtitle1"
                  component="span"
                  fontWeight={500}
                  sx={{
                    display: "inline",
                    verticalAlign: "middle",
                  }}
                >
                  {invitingCompanyData?.name}
                </Typography>
              }
              sx={{ pl: 1.5 }}
              variant="outlined"
            />{" "}
            <Typography
              variant="body1"
              color="text.secondary"
              component="span"
              sx={{
                display: "inline",
                verticalAlign: "middle",
                whiteSpace: "normal", // Allows the text to wrap naturally
                wordBreak: "break-word", // Breaks long words if necessary
              }}
            >
              wants to refer jobs to you.{" "}
              {job?.type && job?.type !== JobUploadTypes.TEXT ? (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  display={"inline"}
                >
                  Click the {job?.type?.toLowerCase()} to{" "}
                  {job?.type === JobUploadTypes.AUDIO ? "listen to" : "watch"} a
                  brief summary of the job.
                </Typography>
              ) : null}
            </Typography>
          </Box>
        ) : null}
        {job?.type === JobUploadTypes.TEXT ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{ bgcolor: "#F8F9FA", borderRadius: 1, py: 1, px: 2 }}
          >
            <Typography variant="overline" color="text.secondary">
              Summary
            </Typography>
            <Typography variant="body2">{job?.summary}</Typography>
          </Box>
        ) : (
          <>
            <Box pt={1} width={"100%"}>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              {job?.type === JobUploadTypes.AUDIO ? (
                <audio
                  controls
                  playsInline
                  controlsList="nodownload"
                  style={{
                    width: "100%",
                    borderRadius: 3,
                    background: "white",
                  }}
                >
                  <source src={`${job.videoUrl}`} type="audio/mp4" />
                </audio>
              ) : job?.type === JobUploadTypes.VIDEO ? (
                <video
                  controls
                  playsInline
                  controlsList="nodownload"
                  style={{
                    width: "100%",
                    height: "220px",
                    borderRadius: 3,
                    background: "white",
                  }}
                >
                  <source src={`${job.videoUrl}#t=0.1`} type="video/mp4" />
                </video>
              ) : null}
            </Box>
          </>
        )}
        {isMobile ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            gap={1}
            width={"100%"}
          >
            <Divider sx={{ width: "100%", mt: 1, mb: 1 }} />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                navigate(
                  `/${AuthRoutes.BASE}/${AuthRoutes.JOB_SIGN_UP}?${searchParamsString}`
                );
              }}
            >
              Book Job
            </Button>
          </Box>
        ) : null}
      </Card>
    ) : (
      <Box my={5} textAlign="center">
        <CircularProgress />
      </Box>
    );

  if (invitationCode && !referralInvitationId && !jobPublicId) {
    return <InvitationMarketingPage />;
  }

  if (teInvitation == "true") {
    return <InvitationMarketingPage />;
  }

  return (
    <Grid
      container
      minHeight={"calc(100vh - 205px)"}
      overflow={"auto"}
      display="flex"
      justifyContent={"space-between"}
    >
      {loading && referralInvitationId ? (
        <Box my={5} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid
            item
            xs={12}
            lg={6}
            p={isMobile ? 2 : 4}
            pt={isMobile ? 1 : 2}
            textAlign={"center"}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"start"}
              alignItems={"start"}
              sx={{
                ...(isJobMarketingPage
                  ? { width: isMobile ? "100%" : "80%" }
                  : { width: "100%", maxWidth: "456px" }),
              }}
              py={2}
              gap={1}
            >
              <Chip
                icon={
                  <AutoFixHighOutlinedIcon
                    sx={{ color: "#16B364 !important" }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    display={"inline"}
                    sx={{ color: "#16B364", textOverflow: "ellipsis" }}
                  >
                    Referral Management Platform for the Trades
                  </Typography>
                }
                sx={{
                  background: "rgba(22, 179, 100, 0.08)",
                  color: "#16B364",
                  borderRadius: "12px",
                }}
              />
              {isJobMarketingPage ? (
                <Typography variant="h5" textAlign={"start"}>
                  You have a new{" "}
                  {snakeCaseJobSectors[job?.sector as JobSectors]} job on
                  TradeEngage!
                </Typography>
              ) : referralInvitation ? (
                <Typography variant="h4" textAlign={"start"}>
                  You have a new Referral Partnership Request on TradeEngage!
                </Typography>
              ) : (
                <Typography variant="h2" textAlign={"start"}>
                  Earn More with Every Job
                </Typography>
              )}

              <Box textAlign={"start"}>
                {referralInvitation || isJobMarketingPage ? (
                  <Typography variant="body1" color="text.secondary">
                    TradeEngage is your solution to connect with trusted
                    referral partners. Receive jobs and boost your earnings from
                    jobs you refer.{" "}
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      fontWeight={700}
                      {...(isJobMarketingPage ? { display: "inline" } : {})}
                    >
                      Built exclusively for the trades.
                    </Typography>
                  </Typography>
                ) : (
                  <Typography variant="body1" color="text.secondary">
                    TradeEngage, your platform to refer and receive jobs. Built
                    exclusively for the trades.
                  </Typography>
                )}
              </Box>
              {!isMobile && !jobPublicId ? linkMenu : null}
              {isJobMarketingPage ? jobDetails : null}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            p={isMobile ? 2 : 4}
            pt={isMobile ? 0 : 4}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              sx={{
                px: isMobile ? 1 : 2,
                py: isMobile ? 1 : 2,
                background: "#F8F9FA",
                borderRadius: 3,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: 16,
                ...(isJobMarketingPage
                  ? { width: isMobile ? "100%" : "80%" }
                  : {}),
                display: "inline-flex",
              }}
            >
              {referralInvitationId && referralInvitation ? (
                <CompanyInitialMessage
                  referralInvitationId={referralInvitationId}
                />
              ) : isJobMarketingPage ? (
                isMobile ? null : (
                  <JobSignUpPage />
                )
              ) : (
                <CompanyInfo isReferralInvitation={isReferralInvitation} />
              )}
            </Box>
          </Grid>
          {isMobile ? (
            <Grid
              item
              xs={12}
              lg={6}
              p={isMobile ? 2 : 4}
              pt={2}
              textAlign={"center"}
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {linkMenu}
            </Grid>
          ) : null}
        </>
      )}
    </Grid>
  );
}
