import { ExpandMore, Percent as PercentIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { NumberInputField } from "@/lib/form-fields/NumberInputField";
import { neutral } from "@/Theme/colors";

import { PayReferralFeeSettings } from "../types";
import { JobPricingInfoNotes } from "./company-settings/components/JobPricingInfoNotes";

interface JobSalePriceSelectFieldProps {
  label: string;
  paymentModeFieldName: string;
  salesPriceFieldName: string;
  percentageFeeFieldName: string;
  payReferralFeeAfterJobIsDoneFieldName: string;
  leadPriceFieldName: string;
  isPayForEveryReferralEnabledFieldName: string;
}

export function JobSalePriceSelectField(props: JobSalePriceSelectFieldProps) {
  const {
    label,
    paymentModeFieldName,
    salesPriceFieldName,
    percentageFeeFieldName,
    payReferralFeeAfterJobIsDoneFieldName,
    leadPriceFieldName,
    isPayForEveryReferralEnabledFieldName,
  } = props;

  const [payPerReferralExpanded, setPayPerReferralExpanded] =
    useState<boolean>(false);

  const handleChangePayPerReferralExpansion = () => {
    setPayPerReferralExpanded(
      payPerReferralExpanded => !payPerReferralExpanded
    );
  };

  const [payAfterJobCompletionExpanded, setPayAfterJobCompletionExpanded] =
    useState<boolean>(false);

  const handleChangePayAfterJobCompletionExpansion = () => {
    setPayAfterJobCompletionExpanded(
      payAfterJobCompletionExpanded => !payAfterJobCompletionExpanded
    );
  };

  const { enqueueSnackbar } = useSnackbar();
  const [paymentModeField, paymentModeMeta, paymentModeHelpers] =
    useField(paymentModeFieldName);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_leadPriceField, _leadPriceMeta, leadPriceHelpers] =
    useField(leadPriceFieldName);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_percentageFeeField, _percentageFeeMeta, percentageFeeHelpers] =
    useField(percentageFeeFieldName);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_salesPriceField, _salesPriceMeta, salesPriceHelpers] =
    useField(salesPriceFieldName);
  const [
    payReferralFeeAfterJobIsDoneField,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _payReferralFeeAfterJobIsDoneMeta,
    payReferralFeeAfterJobIsDoneHelpers,
  ] = useField(payReferralFeeAfterJobIsDoneFieldName);
  const [
    isPayForEveryReferralEnabledField,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _isPayForEveryReferralEnabledMeta,
    isPayForEveryReferralEnabledHelpers,
  ] = useField(isPayForEveryReferralEnabledFieldName);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Accordion
        expanded={payPerReferralExpanded}
        sx={{
          px: 0.5,
          py: 0.5,
          width: "100%",
          my: "0 !important",
          mx: "0 !important",
          padding: 1,
          borderRadius: "8px",
          border: 1,
          borderColor: neutral[200],
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore onClick={() => handleChangePayPerReferralExpansion()} />
          }
          sx={{
            padding: 0,
            ".MuiAccordionSummary-content": {
              margin: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              my: 0,
              mx: 0,
            },
          }}
        >
          <Stack direction="row" px={2}>
            <Typography variant="subtitle1">Pay for Every Referral</Typography>
          </Stack>
          <Switch
            checked={isPayForEveryReferralEnabledField.value}
            onChange={() => {
              if (!payReferralFeeAfterJobIsDoneField.value) {
                enqueueSnackbar({
                  message:
                    "At least one job pricing option must remain active. Enable either 'Pay for Every Referral' to reward partners as soon as a referral is received, or 'Pay When Job is Completed' to incentivize job completion.",
                  variant: "error",
                  includeContactSupportText: false,
                });
                return;
              }
              if (isPayForEveryReferralEnabledField.value) {
                leadPriceHelpers.setValue(null);
              }
              isPayForEveryReferralEnabledHelpers.setValue(
                !isPayForEveryReferralEnabledField.value
              );
            }}
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingX: 2,
            paddingBottom: 1,
            "& .MuiAccordionDetails-root": { py: 0.5 },
          }}
        >
          <Grid container p={0} gap={2}>
            <Grid item xs={12}>
              <NumberInputField
                name={leadPriceFieldName}
                label="Enter Price"
                placeholder="0.00"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} p={0}>
              <JobPricingInfoNotes
                notes={[
                  "You’ll pay this amount for every referral you receive",
                  "Funds are paid directly to the technician or CSR who referred the job to you",
                  "Payments are tracked in the Jobs Dashboard",
                ]}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={payAfterJobCompletionExpanded}
        sx={{
          px: 0.5,
          py: 0.5,
          width: "100%",
          my: "0 !important",
          mx: "0 !important",
          padding: 1,
          borderRadius: "8px",
          border: 1,
          borderColor: neutral[200],
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              onClick={() => handleChangePayAfterJobCompletionExpansion()}
            />
          }
          sx={{
            padding: 0,
            ".MuiAccordionSummary-content": {
              margin: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              my: 0,
              mx: 0,
            },
          }}
        >
          <Stack direction="row" px={2}>
            <Typography variant="subtitle1">
              Pay When Job is Completed
            </Typography>
          </Stack>
          <Switch
            checked={payReferralFeeAfterJobIsDoneField.value}
            onChange={() => {
              if (!isPayForEveryReferralEnabledField.value) {
                enqueueSnackbar({
                  message:
                    "At least one job pricing option must remain active. Enable either 'Pay for Every Referral' to reward partners as soon as a referral is received, or 'Pay When Job is Completed' to incentivize job completion.",
                  variant: "error",
                  includeContactSupportText: false,
                });
                return;
              }
              if (payReferralFeeAfterJobIsDoneField.value) {
                percentageFeeHelpers.setValue(null);
                salesPriceHelpers.setValue(null);
              }
              payReferralFeeAfterJobIsDoneHelpers.setValue(
                !payReferralFeeAfterJobIsDoneField.value
              );
            }}
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingX: 2,
            paddingBottom: 1,
            "& .MuiAccordionDetails-root": { py: 0.5 },
          }}
        >
          <Grid container p={0} gap={1}>
            <Grid item xs={12} p={0}>
              <FormControl
                error={Boolean(
                  paymentModeMeta.touched && paymentModeMeta.error
                )}
              >
                <Typography variant="body1" fontWeight={500}>
                  {label}
                </Typography>
                <RadioGroup
                  aria-labelledby="payment-mode-row-radio-buttons-group-label"
                  name="payment-mode-row-radio-buttons-group"
                  value={paymentModeField.value}
                  onChange={event => {
                    paymentModeHelpers.setValue(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value={PayReferralFeeSettings.FIXED_AMOUNT}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">Fixed Amount ($)</Typography>
                    }
                  />
                  {paymentModeField.value ===
                    PayReferralFeeSettings.FIXED_AMOUNT && (
                    <>
                      <NumberInputField
                        name={salesPriceFieldName}
                        label="Enter Price"
                        placeholder="0.00"
                        sx={{ marginLeft: 4 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                      <Divider
                        sx={{ width: "100%", marginTop: 2, marginBottom: 1 }}
                      />
                    </>
                  )}
                  <FormControlLabel
                    value={
                      PayReferralFeeSettings.DEPENDING_ON_JOB_AND_REFERRAL_PARTNER
                    }
                    control={<Radio />}
                    label={
                      <Box display={"flex"} gap={0.5} alignItems={"normal"}>
                        <Typography variant="body2">
                          Varies Depending on the Job and Referral Partner ($)
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value={PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE}
                    control={<Radio />}
                    label={
                      <Box display={"flex"} gap={0.5} alignItems={"normal"}>
                        <Typography variant="body2">
                          Percentage of the Final Sale Price (%)
                        </Typography>
                      </Box>
                    }
                  />
                  {paymentModeField.value ===
                    PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE && (
                    <>
                      <NumberInputField
                        name={percentageFeeFieldName}
                        label="Enter Job Fee Percentage"
                        placeholder="0"
                        sx={{ marginLeft: 4 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <PercentIcon />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          min: 0,
                          max: 100,
                          step: 1,
                        }}
                      />
                      <Divider sx={{ width: "100%", marginTop: 1 }} />
                    </>
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} p={0}>
              <JobPricingInfoNotes
                notes={[
                  "You’ll pay this amount when a job closes",
                  "If you also enabled Pay for Every Referral, you'll pay that amount first, then you'll pay this amount when the job closes. This gives technicians and CSRs a bonus for closed jobs!",
                  "After a job is completed, go to the Jobs Dashboard, click on a job and select Pay Referral Fee",
                ]}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
