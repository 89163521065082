import { PaidOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CallMadeSharpIcon from "@mui/icons-material/CallMadeSharp";
import CallReceivedSharpIcon from "@mui/icons-material/CallReceivedSharp";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import { Box, Button, Chip, Divider, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FC, useState } from "react";

import { BasicCompanySchema } from "@/company/api/useGetAllBrands";
import { JobSectors } from "@/company/jobs/constants";
import { PayReferralFeeSettings, PLATFORM_PRESENCE } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import BasicCompanyTable from "./BasicCompanyTable";
import { CreateReferralInvitationByCompanyIdDialog } from "./CreateReferralInvitationByCompany";
import { RecommendedPartnersDialog } from "./RecommendedPartnersDIalog";
import ReferralInvitationTable from "./ReferralInvitationTable";
import ReferralNetworkTable, { ReferralType } from "./ReferralNetworkTable";

export interface BrandLocationReferralSchema {
  sector: JobSectors;
  order: number;
  createdAt: DateTime;
  buyingPrice: number;
}

export interface BrandLocationReferralInvitationSchema {
  sectors: Array<JobSectors>;
  createdAt: DateTime;
  acceptedAt?: string;
  declinedAt?: string;
  invitationId: number;
}

export interface BrandLocationReferralNetworkSchema {
  referrals: Array<BrandLocationReferralSchema>;
  referralInvitationsSent: Array<BrandLocationReferralInvitationSchema>;
  referralInvitationsReceived: Array<BrandLocationReferralInvitationSchema>;
  affiliatedCompanies: Array<BasicCompanySchema>;
  recommendedPartners: Array<BasicCompanySchema>;
}

export interface ReferralNetworkOverviewProps {
  referralNetwork: BrandLocationReferralNetworkSchema;
  overview: any;
  outboundInvitations?: number;
  inboundInvitations?: number;
  companyId: number;
  expanded?: boolean;
  refetch: () => void;
  isFetchingProfile?: boolean;
}

export const ReferralNetworkOverview: FC<ReferralNetworkOverviewProps> = ({
  referralNetwork,
  overview,
  outboundInvitations,
  inboundInvitations,
  companyId,
  expanded,
  refetch,
  isFetchingProfile,
}) => {
  const [open, setOpen] = useState(false);
  const [referralInvitationOpen, setReferralInvitationOpen] = useState(false);

  const [selectedReferralType, setSelectedReferralType] =
    useState<ReferralType>(ReferralType.REFERRALS);
  return (
    <>
      <Box display={"flex"} pb={2} gap={1} flexDirection={"column"} pl={2}>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="h6">{overview.name}</Typography>
          {overview.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM ? (
            <VerifiedOutlinedIcon
              sx={{
                verticalAlign: "center",
                color: "#16DB65",
                ml: 0.5,
                height: "20px",
              }}
            />
          ) : null}
        </Box>
        <Typography variant="overline" color="text.secondary" mb={1}>
          Overview
        </Typography>
        {overview?.profile?.payPerReferral ? (
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
              gap={0.5}
              py={1}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  gutterBottom
                  variant="overline"
                  color="rgba(17, 25, 39, 0.38)"
                >
                  Pay Per Referral
                </Typography>
              </Box>
              {overview.sectors.map(({ sector, leadPrice }) => (
                <Box key={sector} display={"flex"}>
                  <PaidOutlined fontSize="small" sx={{ color: "#B5BCC4" }} />
                  <Typography
                    fontSize={"12px"}
                    fontWeight={400}
                    color="text.secondary"
                    ml={1}
                  >
                    {`Pays $${leadPrice ?? 25} / ${
                      snakeCaseJobSectors[sector]
                    }`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </>
        ) : null}
        {overview?.profile?.payReferralFeeAfterJobIsDone ? (
          <>
            {" "}
            <Divider />{" "}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              py={1}
              gap={0.5}
              flexDirection={"column"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  gutterBottom
                  variant="overline"
                  color="rgba(17, 25, 39, 0.38)"
                >
                  Pay Per Job Completion
                </Typography>
              </Box>
              {overview.sectors.map(
                ({ sector, buyingPrice, percentagePrice }) => (
                  <Box key={sector} display={"flex"}>
                    <PaidOutlined fontSize="small" sx={{ color: "#B5BCC4" }} />
                    <Typography
                      fontSize={"12px"}
                      fontWeight={400}
                      color="text.secondary"
                      ml={1}
                    >
                      {overview.profile?.payReferralFeeAfterJobIsDone &&
                      overview.profile?.payReferralFeeSettings ===
                        PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE
                        ? `Pays ${percentagePrice}% / ${snakeCaseJobSectors[sector]}`
                        : overview.profile?.payReferralFeeSettings ===
                          PayReferralFeeSettings.FIXED_AMOUNT
                        ? `Pays $${buyingPrice ?? 25} / ${
                            snakeCaseJobSectors[sector]
                          }`
                        : `Varies Depending on Job / ${snakeCaseJobSectors[sector]}`}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          </>
        ) : null}
        {overview.profile?.defaultTechnicianSplit ? (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            gap={5}
          >
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <PercentOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Technician Split
              </Typography>
            </Box>
            <Typography fontSize={"12px"} fontWeight={500}>
              {overview.profile?.defaultTechnicianSplit}%
            </Typography>
          </Box>
        ) : null}
        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={5}
        >
          <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
            <WebhookOutlinedIcon sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Open Network
            </Typography>
          </Box>
          <Chip
            sx={{
              color: overview.profile?.openNetworkEnabled
                ? "#16B364"
                : "text.secondary",
              bgcolor: overview.profile?.openNetworkEnabled
                ? "#EDFCF2"
                : "#F8F9FA",
            }}
            label={
              overview.profile?.openNetworkEnabled ? "ENABLED" : "DISABLED"
            }
            size="small"
          />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={5}
        >
          <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
            <PendingActionsOutlinedIcon sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Delay Referral Fee
            </Typography>
          </Box>
          <Chip
            sx={{
              color: overview.profile?.payReferralFeeAfterJobIsDone
                ? "#16B364"
                : "text.secondary",
              bgcolor: overview.profile?.payReferralFeeAfterJobIsDone
                ? "#EDFCF2"
                : "#F8F9FA",
            }}
            label={
              overview.profile?.payReferralFeeAfterJobIsDone
                ? "ENABLED"
                : "DISABLED"
            }
            size="small"
          />
        </Box>
        {referralNetwork.referrals ? (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            gap={5}
          >
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <GroupOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Total Partners
              </Typography>
            </Box>
            <Typography fontSize={"12px"} fontWeight={500}>
              {referralNetwork.referrals?.length}
            </Typography>
          </Box>
        ) : null}
        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={5}
        >
          <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
            <CallMadeSharpIcon sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Outbound Invitations
            </Typography>
          </Box>
          <Typography fontSize={"12px"} fontWeight={500}>
            {outboundInvitations ?? 0}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={5}
        >
          <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
            <CallReceivedSharpIcon sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Inbound Invitations
            </Typography>
          </Box>
          <Typography fontSize={"12px"} fontWeight={500}>
            {inboundInvitations ?? 0}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: "8px",
          /* elevation/outlined */
          boxShadow: "0px 0px 0px 1px #F2F4F7",
        }}
        pl={2}
        py={1}
      >
        <Typography variant="overline" color="rgba(17, 25, 39, 0.38)" py={2}>
          All Partners
        </Typography>
        <Box width="100%" display={"flex"} alignItems={"center"} gap={1} py={2}>
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => setOpen(true)}
          >
            Add Recommended Partners
          </Button>
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => setReferralInvitationOpen(true)}
          >
            Add Referral Invitation
          </Button>
        </Box>
        <Divider />
        <Box display={"flex"} gap={1} py={1} flexWrap={"wrap"}>
          <Button
            size="small"
            disabled={!referralNetwork.referrals?.length}
            sx={{
              bgcolor:
                selectedReferralType === ReferralType.REFERRALS
                  ? "#16B364"
                  : "rgba(17, 25, 39, 0.08)",
              color:
                selectedReferralType === ReferralType.REFERRALS
                  ? "white"
                  : "#111927",
              "&:hover": {
                color: "#111927",
              },
              "&:focus": {
                bgcolor: "#16B364",
              },
            }}
            onClick={() => setSelectedReferralType(ReferralType.REFERRALS)}
          >
            Referrals ({referralNetwork.referrals?.length})
          </Button>
          <Button
            size="small"
            disabled={!referralNetwork.referralInvitationsReceived?.length}
            sx={{
              bgcolor:
                selectedReferralType ===
                ReferralType.REFERRAL_INVITATION_ACCEPTED
                  ? "#16B364"
                  : "rgba(17, 25, 39, 0.08)",
              color:
                selectedReferralType ===
                ReferralType.REFERRAL_INVITATION_ACCEPTED
                  ? "white"
                  : "#111927",
              "&:hover": {
                color: "#111927",
              },
              "&:focus": {
                bgcolor: "#16B364",
              },
            }}
            onClick={() =>
              setSelectedReferralType(ReferralType.REFERRAL_INVITATION_ACCEPTED)
            }
          >
            Referral Invitations Received (
            {referralNetwork.referralInvitationsReceived?.length})
          </Button>
          <Button
            size="small"
            disabled={!referralNetwork.referralInvitationsSent?.length}
            sx={{
              bgcolor:
                selectedReferralType === ReferralType.REFERRAL_INVITATION_SENT
                  ? "#16B364"
                  : "rgba(17, 25, 39, 0.08)",
              color:
                selectedReferralType === ReferralType.REFERRAL_INVITATION_SENT
                  ? "white"
                  : "#111927",
              "&:hover": {
                color: "#111927",
              },
              "&:focus": {
                bgcolor: "#16B364",
              },
            }}
            onClick={() =>
              setSelectedReferralType(ReferralType.REFERRAL_INVITATION_SENT)
            }
          >
            Referral Invitations Sent (
            {referralNetwork.referralInvitationsSent?.length})
          </Button>
          <Button
            size="small"
            disabled={!referralNetwork.affiliatedCompanies?.length}
            sx={{
              bgcolor:
                selectedReferralType === ReferralType.AFFILIATE_COMPANIES
                  ? "#16B364"
                  : "rgba(17, 25, 39, 0.08)",
              color:
                selectedReferralType === ReferralType.AFFILIATE_COMPANIES
                  ? "white"
                  : "#111927",
              "&:hover": {
                color: "#111927",
              },
              "&:focus": {
                bgcolor: "#16B364",
              },
            }}
            onClick={() =>
              setSelectedReferralType(ReferralType.AFFILIATE_COMPANIES)
            }
          >
            Affiliate Companies ({referralNetwork.affiliatedCompanies?.length})
          </Button>
          <Button
            size="small"
            disabled={!referralNetwork.recommendedPartners?.length}
            sx={{
              bgcolor:
                selectedReferralType === ReferralType.RECOMMENDED_PARTNERS
                  ? "#16B364"
                  : "rgba(17, 25, 39, 0.08)",
              color:
                selectedReferralType === ReferralType.RECOMMENDED_PARTNERS
                  ? "white"
                  : "#111927",
              "&:hover": {
                color: "#111927",
              },
              "&:focus": {
                bgcolor: "#16B364",
              },
            }}
            onClick={() =>
              setSelectedReferralType(ReferralType.RECOMMENDED_PARTNERS)
            }
          >
            Recommended Partners ({referralNetwork.recommendedPartners?.length})
          </Button>
        </Box>
        {selectedReferralType === ReferralType.REFERRAL_INVITATION_SENT ||
        selectedReferralType === ReferralType.REFERRAL_INVITATION_ACCEPTED ? (
          <ReferralInvitationTable
            companyId={companyId}
            refetch={refetch}
            isFetchingProfile={isFetchingProfile}
            expanded={expanded}
            referralInvitations={
              selectedReferralType === ReferralType.REFERRAL_INVITATION_SENT
                ? referralNetwork?.referralInvitationsSent
                : referralNetwork?.referralInvitationsReceived
            }
          />
        ) : selectedReferralType === ReferralType.AFFILIATE_COMPANIES ? (
          <BasicCompanyTable
            companyId={companyId}
            refetch={refetch}
            isFetchingProfile={isFetchingProfile}
            expanded={expanded}
            type={ReferralType.AFFILIATE_COMPANIES}
            companies={referralNetwork?.affiliatedCompanies}
          />
        ) : selectedReferralType === ReferralType.RECOMMENDED_PARTNERS ? (
          <BasicCompanyTable
            companyId={companyId}
            refetch={refetch}
            isFetchingProfile={isFetchingProfile}
            expanded={expanded}
            type={ReferralType.RECOMMENDED_PARTNERS}
            companies={referralNetwork?.recommendedPartners}
          />
        ) : (
          <ReferralNetworkTable
            companyId={companyId}
            refetch={refetch}
            isFetchingProfile={isFetchingProfile}
            expanded={expanded}
            referrals={referralNetwork?.referrals}
          />
        )}
      </Box>
      {open ? (
        <RecommendedPartnersDialog
          open={open}
          companyId={companyId}
          refetch={() => refetch()}
          onClickConfirm={() => {
            setOpen(false);
          }}
          onClickCancel={() => {
            setOpen(false);
          }}
          onClickClose={() => {
            setOpen(false);
          }}
        />
      ) : null}
      {referralInvitationOpen ? (
        <CreateReferralInvitationByCompanyIdDialog
          open={referralInvitationOpen}
          referrerCompanyId={companyId}
          refetch={() => refetch()}
          onClickConfirm={() => setReferralInvitationOpen(false)}
          onClickCancel={() => setReferralInvitationOpen(false)}
          onClickClose={() => setReferralInvitationOpen(false)}
        />
      ) : null}
    </>
  );
};
