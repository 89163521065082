import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useCreateCompanyConnections(
  options: UseMutationOptions<
    void,
    AxiosError,
    {
      invitedCompanyIds?: Array<number>;
      interestedInCompanyGoogleIds?: Array<string>;
    }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  {
    invitedCompanyIds?: Array<number>;
    interestedInCompanyGoogleIds?: Array<string>;
  }
> {
  return useMutation({
    mutationFn: async ({
      invitedCompanyIds,
      interestedInCompanyGoogleIds,
    }: {
      invitedCompanyIds?: Array<number>;
      interestedInCompanyGoogleIds?: Array<string>;
    }) => {
      await API.post(`referral-invites/connections`, {
        invited_company_ids: invitedCompanyIds,
        interested_in_company_google_ids: interestedInCompanyGoogleIds,
      });
    },
    ...options,
  });
}
