import { Box } from "@mui/material";
import { EditorContent, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { useEffect } from "react";

import { isDefined } from "@/utils/isDefined";

interface TextEditorTextPreviewProps {
  content: string;
  lineClamp?: number;
  fontSize?: string;
}

export function TextEditorTextPreview(props: TextEditorTextPreviewProps) {
  const { content, lineClamp, fontSize } = props;

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: { keepMarks: true, keepAttributes: false },
      }),
    ],
    editable: false,
    content,
  });

  useEffect(() => {
    editor?.commands.setContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <Box
      sx={{
        "& .ProseMirror": {
          outline: "none",
          ...(isDefined(lineClamp) && {
            display: "-webkit-box",
            "-webkit-line-clamp": `${lineClamp}`,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
          "& ul": {
            margin: "0",
            padding: "0 0 0 24px",
            color: "#6C737F",
          },
          "& li": {
            margin: "0",
            padding: "2px 0",
            color: "#6C737F",
          },
          "& p": {
            margin: "0",
            "font-family": `"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`,
            color: "#6C737F",
            "font-weight": "400",
            ...(isDefined(fontSize) && {
              "font-size": fontSize,
            }),
          },
        },
      }}
    >
      <EditorContent editor={editor} />
    </Box>
  );
}
