/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, Typography } from "@mui/material";
import { closeSnackbar, CustomContentProps, SnackbarContent } from "notistack";
import React, { forwardRef } from "react";

import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

interface ErrorSnackBarProps extends CustomContentProps {
  onClose?: () => void;
  includeContactSupportText?: boolean;
  subText?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const ErrorSnackBar = forwardRef<HTMLDivElement, ErrorSnackBarProps>(
  (props, ref) => {
    const {
      // You have access to notistack props and options 👇🏼
      id,
      message,
      includeContactSupportText = true,
      subText,
      onClose,

      // as well as your own custom props 👇🏼
      ...other
    } = props;

    const { setContactSupportModalOpen } = useContactSupportModalContext();

    if (message == "Network Error") {
      return (
        <SnackbarContent ref={ref} role="alert" {...other}>
          <Box
            display={"flex"}
            sx={{
              padding: "6px 16px",
              borderRadius: "8px",
              background: "#FEE4E2",
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ pt: "10px", mr: "12px", width: "22px", height: "22px" }}>
              <ErrorOutlineOutlinedIcon
                fontSize="small"
                sx={{
                  color: "#F04438",
                }}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              py={1}
              sx={{ maxWidth: "480px", flexWrap: "wrap" }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  color: "#7A271A",
                }}
              >
                Network Connection Error
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "157%",
                  letterSpacing: "0.15px",
                  color: "#7A271A",
                }}
              >
                <>
                  Please check your internet connection or{" "}
                  <a
                    role="button"
                    style={{
                      textDecorationLine: "underline",
                      color: "#7A271A",
                    }}
                    onClick={event => {
                      event.preventDefault();
                      setContactSupportModalOpen(true);
                    }}
                  >
                    contact support
                  </a>
                  .
                </>
              </Typography>
            </Box>
            <CloseIcon
              fontSize="small"
              sx={{
                color: "#7A271A",
                cursor: "pointer",
                ml: 2,
                mt: "10px",
              }}
              onClick={() => {
                onClose?.();
                closeSnackbar(id);
              }}
            />
          </Box>
        </SnackbarContent>
      );
    }

    return (
      <SnackbarContent ref={ref} role="alert" {...other}>
        <Box
          display={"flex"}
          sx={{
            padding: "6px 16px",
            borderRadius: "8px",
            background: "#FEE4E2",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ pt: "10px", mr: "12px", width: "22px", height: "22px" }}>
            <ErrorOutlineOutlinedIcon
              fontSize="small"
              sx={{
                color: "#F04438",
              }}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            py={1}
            sx={{ maxWidth: "480px", flexWrap: "wrap" }}
          >
            {message ? (
              <Typography
                sx={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  color: "#7A271A",
                }}
              >
                {message}
              </Typography>
            ) : null}
            {includeContactSupportText || subText ? (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "157%",
                  letterSpacing: "0.15px",
                  color: "#7A271A",
                }}
              >
                {subText ?? (
                  <>
                    Please try again later or{" "}
                    <a
                      role="button"
                      style={{
                        textDecorationLine: "underline",
                        color: "#7A271A",
                      }}
                      onClick={event => {
                        event.preventDefault();
                        setContactSupportModalOpen(true);
                      }}
                    >
                      contact support
                    </a>
                    .
                  </>
                )}
              </Typography>
            ) : null}
          </Box>
          <CloseIcon
            fontSize="small"
            sx={{
              color: "#7A271A",
              cursor: "pointer",
              ml: 2,
              mt: "10px",
            }}
            onClick={() => {
              onClose?.();
              closeSnackbar(id);
            }}
          />
        </Box>
      </SnackbarContent>
    );
  }
);
