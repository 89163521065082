export enum HomeownerRoutes {
  BASE = "homeowner",
  DASHBOARD_HOME = "home",
  JOBS_LIST = "jobs",
  SERVICE_PROVIDERS = "service-providers",
  PERSONAL_SETTINGS = "personal-settings",
  JOB = "jobs/:jobId",
}

export enum HomeownerJobTabs {
  ALL = "ALL",
  PENDING = "PENDING",
  DELAYED = "DELAYED",
  DECLINED = "DECLINED",
  REQUESTED = "REQUESTED",
  COMPLETED = "COMPLETED",
}
