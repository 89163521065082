import { Session } from "@/auth/types";

import { JobSectors } from "../jobs/constants";
import { DefaultCompanyGroups, Referral, ReferralInvitation } from "../types";

export const isTypeReferral = (
  obj: Referral | ReferralInvitation
): obj is Referral => {
  return !!Object.hasOwn(obj, "order");
};

export const snakeCaseJobSectors: Record<JobSectors, string> = {
  ACCESSIBILITY_SOLUTIONS: "Accessibility Solutions",
  AIR_QUALITY_AND_PURIFICATION: "Air Quality and Purification",
  AIR_DUCT_CLEANING: "Air Duct Cleaning",
  APPLIANCES: "Appliances",
  APPLIANCE_SALES: "Appliance Sales",
  ASPHALT_AND_PAVING: "Asphalt and Paving",
  AUTO_GLASS: "Glass Services (Auto)",
  BATHROOM_REMODELING: "Bathroom Remodeling",
  BAT_REMOVAL: "Bat Removal",
  BLINDS: "Blinds",
  CARPENTRY: "Carpentry",
  CARPET_CLEANING: "Carpet Cleaning",
  CHIMNEY_SWEEP: "Chimney Sweep",
  CHRISTMAS_LIGHTING: "Christmas Lighting",
  CLOSETS_SHELVING_AND_ORGANIZATION: "Closets Shelving and Organization",
  CONCRETE_AND_MASONRY: "Concrete and Masonry",
  CRAWLSPACES: "Crawlspaces",
  CUSTOM_BUILDS_AND_REMODELING: "Custom Builds and Remodeling",
  DECKS_AND_PATIOS: "Decks and Patios",
  DRAIN_AND_SEWER: "Drain and Sewer",
  DRYER_VENT_CLEANING: "Dryer Vent Cleaning",
  DRYWALL: "Drywall",
  ELECTRICAL: "Electrical",
  EXTERIOR_CLEANING_AND_PRESSURE_WASHING:
    "Exterior Cleaning and Pressure Washing",
  FENCING: "Fencing",
  FLOORING: "Flooring",
  FOUNDATION_AND_WATERPROOFING: "Foundation and Waterproofing",
  FURNITURE_REPAIR: "Furniture Repair",
  GARAGE_DOOR_REPAIR_AND_INSTALLATION: "Garage Door Repair and Installation",
  GENERATORS: "Generators",
  GENERAL_CONTRACTING_AND_HANDYMAN: "General Contracting and Handyman",
  GLASS_SERVICES_RESIDENTIAL: "Glass Services (Residential)",
  GUTTER_CLEANING: "Gutter Cleaning",
  HOME_INSPECTIONS_AND_APPRAISALS: "Home Inspections and Appraisals",
  HVAC: "HVAC",
  INSULATION: "Insulation",
  INTERIOR_CLEANING: "Interior Cleaning",
  INTERIOR_DESIGN: "Interior Design",
  JUNK_REMOVAL_AND_HAULING: "Junk Removal and Hauling",
  KITCHEN_REMODELING: "Kitchen Remodeling",
  LANDSCAPING: "Landscaping",
  LOCKSMITH: "Locksmith",
  MOVING_AND_STORAGE: "Moving and Storage",
  MOSQUITO_CONTROL: "Mosquito Control",
  OTHER: "Other",
  PACKOUT_AND_STORAGE: "Packout and Storage",
  PAINTING: "Painting",
  PEST_CONTROL: "Pest Control",
  PEST_PROTECTION: "Pest Protection",
  PET_SERVICES: "Pet Services",
  PLUMBING: "Plumbing",
  POOL_AND_SPA: "Pool and Spa",
  PROPERTY_MANAGEMENT: "Property Management",
  REAL_ESTATE: "Real Estate",
  RESTORATION_AND_REMEDIATION: "Restoration and Remediation",
  ROOFING: "Roofing",
  SENIOR_CARE: "Senior Care",
  SIDING: "Siding",
  SOLAR_AND_ENERGY: "Solar and Energy",
  TERMITE_INSPECTION: "Termite Inspection",
  TILE_AND_STONE: "Tile and Stone",
  TREE_REMOVAL_AND_TRIMMING: "Tree Removal and Trimming",
  WATER_TREATMENT: "Water Treatment",
  WINDOWS_DOORS_AND_SIDING: "Windows Doors and Siding",
  WINDOW_TINTING: "Window Tinting",
  WILDLIFE_CONTROL_AND_REMOVAL: "Wildlife Control and Removal",
  WOOD_ROT_REPAIR: "Wood Rot Repair",
};

export const reverseSnakeCaseJobSectors: Record<string, JobSectors> = {
  "Accessibility Solutions": JobSectors.ACCESSIBILITY_SOLUTIONS,
  "Air Quality and Purification": JobSectors.AIR_QUALITY_AND_PURIFICATION,
  "Air Duct Cleaning": JobSectors.AIR_DUCT_CLEANING,
  Appliances: JobSectors.APPLIANCES,
  "Appliance Sales": JobSectors.APPLIANCE_SALES,
  "Asphalt and Paving": JobSectors.ASPHALT_AND_PAVING,
  "Glass Services (Auto)": JobSectors.AUTO_GLASS,
  "Glass Services (Residential)": JobSectors.GLASS_SERVICES_RESIDENTIAL,
  "Bathroom Remodeling": JobSectors.BATHROOM_REMODELING,
  "Bat Removal": JobSectors.BAT_REMOVAL,
  Blinds: JobSectors.BLINDS,
  Carpentry: JobSectors.CARPENTRY,
  "Carpet Cleaning": JobSectors.CARPET_CLEANING,
  "Chimney Sweep": JobSectors.CHIMNEY_SWEEP,
  "Christmas Lighting": JobSectors.CHRISTMAS_LIGHTING,
  "Closets Shelving and Organization":
    JobSectors.CLOSETS_SHELVING_AND_ORGANIZATION,
  "Concrete and Masonry": JobSectors.CONCRETE_AND_MASONRY,
  Crawlspaces: JobSectors.CRAWLSPACES,
  "Custom Builds and Remodeling": JobSectors.CUSTOM_BUILDS_AND_REMODELING,
  "Decks and Patios": JobSectors.DECKS_AND_PATIOS,
  "Drain and Sewer": JobSectors.DRAIN_AND_SEWER,
  "Dryer Vent Cleaning": JobSectors.DRYER_VENT_CLEANING,
  Drywall: JobSectors.DRYWALL,
  Electrical: JobSectors.ELECTRICAL,
  "Exterior Cleaning and Pressure Washing":
    JobSectors.EXTERIOR_CLEANING_AND_PRESSURE_WASHING,
  Fencing: JobSectors.FENCING,
  Flooring: JobSectors.FLOORING,
  "Foundation and Waterproofing": JobSectors.FOUNDATION_AND_WATERPROOFING,
  "Furniture Repair": JobSectors.FURNITURE_REPAIR,
  "Garage Door Repair and Installation":
    JobSectors.GARAGE_DOOR_REPAIR_AND_INSTALLATION,
  "General Contracting and Handyman":
    JobSectors.GENERAL_CONTRACTING_AND_HANDYMAN,
  Generators: JobSectors.GENERATORS,
  "Gutter Cleaning": JobSectors.GUTTER_CLEANING,
  "Home Inspections and Appraisals": JobSectors.HOME_INSPECTIONS_AND_APPRAISALS,
  HVAC: JobSectors.HVAC,
  Insulation: JobSectors.INSULATION,
  "Interior Cleaning": JobSectors.INTERIOR_CLEANING,
  "Interior Design": JobSectors.INTERIOR_DESIGN,
  "Junk Removal and Hauling": JobSectors.JUNK_REMOVAL_AND_HAULING,
  "Kitchen Remodeling": JobSectors.KITCHEN_REMODELING,
  Landscaping: JobSectors.LANDSCAPING,
  Locksmith: JobSectors.LOCKSMITH,
  "Moving and Storage": JobSectors.MOVING_AND_STORAGE,
  "Mosquito Control": JobSectors.MOSQUITO_CONTROL,
  Other: JobSectors.OTHER,
  "Packout and Storage": JobSectors.PACKOUT_AND_STORAGE,
  Painting: JobSectors.PAINTING,
  "Pest Control": JobSectors.PEST_CONTROL,
  "Pest Protection": JobSectors.PEST_PROTECTION,
  "Pet Services": JobSectors.PET_SERVICES,
  Plumbing: JobSectors.PLUMBING,
  "Pool and Spa": JobSectors.POOL_AND_SPA,
  "Property Management": JobSectors.PROPERTY_MANAGEMENT,
  "Real Estate": JobSectors.REAL_ESTATE,
  "Restoration and Remediation": JobSectors.RESTORATION_AND_REMEDIATION,
  Roofing: JobSectors.ROOFING,
  "Senior Care": JobSectors.SENIOR_CARE,
  Siding: JobSectors.SIDING,
  "Solar and Energy": JobSectors.SOLAR_AND_ENERGY,
  "Termite Inspection": JobSectors.TERMITE_INSPECTION,
  "Tile and Stone": JobSectors.TILE_AND_STONE,
  "Tree Removal and Trimming": JobSectors.TREE_REMOVAL_AND_TRIMMING,
  "Water Treatment": JobSectors.WATER_TREATMENT,
  "Windows Doors and Siding": JobSectors.WINDOWS_DOORS_AND_SIDING,
  "Window Tinting": JobSectors.WINDOW_TINTING,
  "Wildlife Control and Removal": JobSectors.WILDLIFE_CONTROL_AND_REMOVAL,
  "Wood Rot Repair": JobSectors.WOOD_ROT_REPAIR,
};

export const snakeCaseJobSectorsEmojis: Record<JobSectors, string> = {
  ACCESSIBILITY_SOLUTIONS: "🌐",
  AIR_QUALITY_AND_PURIFICATION: "🍃",
  AIR_DUCT_CLEANING: "💨",
  APPLIANCES: "🔧",
  APPLIANCE_SALES: "🔧",
  ASPHALT_AND_PAVING: "🛣️",
  AUTO_GLASS: "🚗",
  BATHROOM_REMODELING: "🚿",
  BAT_REMOVAL: "🦇",
  BLINDS: "🪟",
  CARPENTRY: "🔨",
  CARPET_CLEANING: "🧼",
  CHIMNEY_SWEEP: "🏠",
  CHRISTMAS_LIGHTING: "🎄",
  CLOSETS_SHELVING_AND_ORGANIZATION: "🚪",
  CONCRETE_AND_MASONRY: "🧱",
  CRAWLSPACES: "🧰",
  CUSTOM_BUILDS_AND_REMODELING: "🏡",
  DECKS_AND_PATIOS: "🪚",
  DRAIN_AND_SEWER: "🚿",
  DRYER_VENT_CLEANING: "🌬️",
  DRYWALL: "🏗️",
  ELECTRICAL: "💡",
  EXTERIOR_CLEANING_AND_PRESSURE_WASHING: "🧽",
  FENCING: "🛑",
  FLOORING: "🏠",
  FOUNDATION_AND_WATERPROOFING: "🏚️",
  FURNITURE_REPAIR: "🔧",
  GARAGE_DOOR_REPAIR_AND_INSTALLATION: "🚗",
  GENERAL_CONTRACTING_AND_HANDYMAN: "🔨",
  GENERATORS: "🔋",
  GLASS_SERVICES_RESIDENTIAL: "🪞",
  GUTTER_CLEANING: "🧹",
  HOME_INSPECTIONS_AND_APPRAISALS: "🏡",
  HVAC: "❄️",
  INSULATION: "🧤",
  INTERIOR_CLEANING: "🧹",
  INTERIOR_DESIGN: "🎨",
  JUNK_REMOVAL_AND_HAULING: "🚛",
  KITCHEN_REMODELING: "🍽️",
  LANDSCAPING: "🌿",
  LOCKSMITH: "🔐",
  MOVING_AND_STORAGE: "🚚",
  MOSQUITO_CONTROL: "🦟",
  OTHER: "🛌",
  PACKOUT_AND_STORAGE: "📦",
  PAINTING: "🎨",
  PEST_CONTROL: "🐜",
  PEST_PROTECTION: "🪳",
  PET_SERVICES: "🐾",
  PLUMBING: "🚿",
  POOL_AND_SPA: "🏊‍♂️",
  PROPERTY_MANAGEMENT: "🏢",
  REAL_ESTATE: "🏠",
  RESTORATION_AND_REMEDIATION: "🔄",
  ROOFING: "🏠",
  SENIOR_CARE: "♿",
  SIDING: "🏠",
  SOLAR_AND_ENERGY: "☀️",
  TERMITE_INSPECTION: "🐜",
  TILE_AND_STONE: "🧱",
  TREE_REMOVAL_AND_TRIMMING: "🌳",
  WATER_TREATMENT: "💧",
  WINDOWS_DOORS_AND_SIDING: "🪟",
  WINDOW_TINTING: "🕶️",
  WILDLIFE_CONTROL_AND_REMOVAL: "🦝",
  WOOD_ROT_REPAIR: "🪵",
};

export const getUserRoleLabel = (session: Session | null): string => {
  let userRoleLabel = "";
  const groupNames = session?.groups?.map(group => group.name) ?? [];
  if (groupNames?.includes(DefaultCompanyGroups.OWNER)) {
    userRoleLabel = "Owner";
  } else if (groupNames?.includes(DefaultCompanyGroups.ADMINISTRATOR)) {
    userRoleLabel = "Administrator";
  } else if (groupNames?.includes(DefaultCompanyGroups.TEAM_MEMBER)) {
    userRoleLabel = "Team Member";
  } else if (groupNames?.includes(DefaultCompanyGroups.JOB_APPROVER)) {
    userRoleLabel = "Job Approver";
  } else if (groupNames?.includes(DefaultCompanyGroups.FIELD_TEAM)) {
    userRoleLabel = "Field Team";
  }
  return userRoleLabel;
};
