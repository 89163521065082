import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useCreateReferralInterest(
  options: UseMutationOptions<void, AxiosError, string> = {}
): UseMutationResult<void, AxiosError, string> {
  return useMutation({
    mutationFn: async (googlePlacesId: string) => {
      await API.post(`referral-invites/create-by-google-places-id`, {
        google_places_id: googlePlacesId,
      });
    },
    ...options,
  });
}
