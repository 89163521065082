import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";

import { useUpdateJobPromotion } from "../api/useUpdateJobPromotion";
import { JobPromotion, JobPromotionStatus } from "../types";

interface JobPromotionStatusFieldProps {
  jobPromotion: JobPromotion;
  onUpdate: () => void;
}

export function JobPromotionStatusField(props: JobPromotionStatusFieldProps) {
  const { jobPromotion, onUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const isUserIsAdminOrOwner = session?.groups?.some(isAdministratorOrOwner);

  const {
    mutateAsync: updateJobPromotionStatusAsync,
    isLoading: isUpdatingJobPromotionStatus,
  } = useUpdateJobPromotion({
    onSuccess: () => {
      onUpdate();
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error updating the promotion status";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const isJobPromotionEnded =
    Math.ceil(
      DateTime.fromISO(jobPromotion.endDate.toString(), {
        zone: "utc",
      }).diff(DateTime.utc(), "days").days
    ) < 0;
  const isJobPromotionActive =
    jobPromotion.status === JobPromotionStatus.ACTIVE && !isJobPromotionEnded;

  if (!isUserIsAdminOrOwner) {
    // returning empty box to maintain layout
    return <Box />;
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isJobPromotionActive}
          disabled={isUpdatingJobPromotionStatus || isJobPromotionEnded}
          onChange={async event => {
            await updateJobPromotionStatusAsync({
              jobPromotionId: jobPromotion.id,
              status: !event.target.checked
                ? JobPromotionStatus.INACTIVE
                : JobPromotionStatus.ACTIVE,
            });
          }}
        />
      }
      label={
        <Typography variant="subtitle2" fontWeight={600}>
          {isJobPromotionActive ? "Active" : "Paused"}
        </Typography>
      }
      onClick={event => {
        event.stopPropagation();
      }}
    />
  );
}
