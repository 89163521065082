import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { CompanyRoutes } from "@/company/types";

export const OnboardingSteps: Record<
  ONBOARDING_STAGES,
  { nextStepRoute: string; percentage: number }
> = {
  [ONBOARDING_STAGES.ACCOUNT_TYPE]: {
    nextStepRoute: `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.SERVICE_DETAILS}`,
    percentage: Math.floor((1 / 9) * 100),
  },
  [ONBOARDING_STAGES.SERVICE_DETAILS]: {
    nextStepRoute: `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.INBOUND_PREFERENCES}`,
    percentage: Math.floor((2 / 9) * 100),
  },
  [ONBOARDING_STAGES.SERVICE_TERRITORY]: {
    nextStepRoute: `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.INBOUND_PREFERENCES}`,
    percentage: Math.floor((5 / 18) * 100),
  },
  [ONBOARDING_STAGES.INBOUND_PREFERENCES]: {
    nextStepRoute: `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.JOB_PRICE}`,
    percentage: Math.floor((3 / 9) * 100),
  },
  [ONBOARDING_STAGES.JOB_PRICE]: {
    nextStepRoute: `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.OUTBOUND_PREFERENCES}`,
    percentage: Math.floor((4 / 9) * 100),
  },
  [ONBOARDING_STAGES.OUTBOUND_PREFERENCES]: {
    nextStepRoute: `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.TECHNICIAN_SPLIT}`,
    percentage: Math.floor((5 / 9) * 100),
  },
  [ONBOARDING_STAGES.TECHNICIAN_SPLIT]: {
    nextStepRoute: `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.RECOMMENDED_PARTNERS}`,
    percentage: Math.floor((6 / 9) * 100),
  },
  [ONBOARDING_STAGES.RECOMMENDED_PARTNERS]: {
    nextStepRoute: `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.COMPANY_PROFILE}`,
    percentage: Math.floor((7 / 9) * 100),
  },
  [ONBOARDING_STAGES.COMPANY_PROFILE]: {
    nextStepRoute: `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.OPERATIONS_AND_SALES}`,
    percentage: Math.floor((8 / 9) * 100),
  },
  [ONBOARDING_STAGES.OPERATIONS_AND_SALES]: {
    nextStepRoute: `/${CompanyRoutes.BASE}/${CompanyRoutes.DASHBOARD_HOME}`,
    percentage: Math.floor((9 / 9) * 100),
  },
};

export const getCurrentOnboardingProgress = (pathname: string) => {
  const onboardingPath = pathname
    .split("/")
    .slice(-1)
    .pop() as ONBOARDING_STAGES;

  return OnboardingSteps[onboardingPath]?.percentage;
};

export function getRandomNumberInRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
