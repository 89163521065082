import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";
import { Job } from "@/company/jobs/types";

type CreateJobFromPromotionRequest = {
  jobPromotionId: number;
};

export function useCreateJobFromPromotion(
  options: UseMutationOptions<
    Job,
    AxiosError,
    CreateJobFromPromotionRequest
  > = {}
): UseMutationResult<Job, AxiosError, CreateJobFromPromotionRequest> {
  return useMutation({
    mutationFn: async ({ jobPromotionId }): Promise<Job> => {
      const response = await API.post(`/job-promotions/${jobPromotionId}/jobs`);
      return response.data.data;
    },
    ...options,
  });
}
