import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  debounce,
  MenuItem,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import zipcodes from "zipcodes";

import { AuthRoutes } from "@/auth/constants";
import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useFetchCompanyTerritory } from "@/company/api/useFetchCompanyTerritory";
import { JobSectors } from "@/company/jobs/constants";
import {
  useQueryLocation,
  useQuerySearchCompanies,
  useUpdateBrandLocationProfile,
  useUpdateCompanyServiceDetails,
} from "@/company/onboarding/api";
import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { CompanyRoutes, SearchCompanyProfile } from "@/company/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";
import { useLeadContext } from "@/lead/context/lead-context";

import { OnboardingContainer } from "../../container/OnboardingContainer";
import { OnboardingSteps } from "../../utils";
import TerritoryMap from "./TerritoryMap";
import { promisifiedFunction } from "./utils";

const validationSchema = yup.object({
  companyName: yup.string().required("Company Name is required"),
  zipCode: yup
    .string()
    .required("Zip Code is required")
    .oneOf(Object.keys(zipcodes.codes), "Invalid U.S. zip code")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits"),
  sectors: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least one Service Category")
    .max(3, "Number of Service Categories can not exceed 3")
    .required("Sectors are required"),
});

interface FormValues {
  companyName: string;
  zipCode: string;
  sectors: Array<JobSectors>;
}

export function ServiceDetails() {
  const [filterKeyword, setFilterKeyword] = useState<string>("");
  const { leadDetails } = useLeadContext();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { pathname } = useLocation();

  let step = 0;

  if (pathname.includes("territory")) {
    step = 1;
  }

  const { session, refreshSession } = useAuth();
  const company = session?.company;
  const companyAddress = session?.companyAddress;
  const companySectors = session?.companySectors;

  const debouncedFilterSetter = useMemo(
    () => debounce((keyword: string) => setFilterKeyword(keyword), 500),
    []
  );

  const debouncedZipCodeSetter = useMemo(
    () =>
      debounce((zipcode: string) => {
        if (zipcode !== selectedZipCode) {
          setSelectedTerritories([]);
        }
        setSelectedZipCode(zipcode);
      }),
    []
  );

  const [selectedTerritories, setSelectedTerritories] = useState<
    Array<google.maps.Data.Feature>
  >([]);

  const { data, isFetching: loading } = useQuerySearchCompanies(
    filterKeyword,
    !!filterKeyword
  );

  const {
    isLoading: updatingBrandLocationProfile,
    mutateAsync: updateBrandLocationProfile,
  } = useUpdateBrandLocationProfile(session?.brandLocationProfile?.id, {
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while updating company service details.";
      enqueueSnackbar(message, {
        variant: "error",
      });
    },
  });

  const { data: companyTerritory } = useFetchCompanyTerritory(company?.id, {
    enabled: !!company?.id,
  });
  const currentGeoIds = companyTerritory?.territoryGeoIds;

  const {
    mutateAsync: updateCompanyServiceDetails,
    isLoading: updatingCompanyServiceDetails,
  } = useUpdateCompanyServiceDetails(company?.id, {
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while updating company service details.";
      enqueueSnackbar(message, {
        variant: "error",
      });
    },
    onSuccess: async () => {
      await updateBrandLocationProfile({
        startedOnboardingAt: DateTime.now().toISO(),
      });
      refreshSession().then(() => {
        trackEvent(
          AnalyticsEvents.COMPANY_ONBOARDING_SECTOR_TERRITORY_ZIP_CODE_AND_NAME_ADDED,
          {
            companyId: company?.id,
            companyName: formik.values.companyName,
            zipCode: formik.values.zipCode,
            sectors: formik.values.sectors,
          }
        );
        if (step === 0 && isMobile) {
          trackEvent(
            AnalyticsEvents.COMPANY_ONBOARDING_SECTORS_ZIP_CODE_AND_NAME_ADDED,
            {
              companyId: company?.id,
              companyName: formik.values.companyName,
              zipCode: formik.values.zipCode,
              sectors: formik.values.sectors,
            }
          );
          navigate(
            `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.SERVICE_TERRITORY}`
          );
          return;
        }
        if (step === 1 || !isMobile) {
          if (!isMobile) {
            trackEvent(
              AnalyticsEvents.COMPANY_ONBOARDING_SECTORS_ZIP_CODE_AND_NAME_ADDED,
              {
                companyId: company?.id,
                companyName: formik.values.companyName,
                zipCode: formik.values.zipCode,
                sectors: formik.values.sectors,
              }
            );
          }
          if (selectedTerritories) {
            trackEvent(
              AnalyticsEvents.COMPANY_ONBOARDING_SECTOR_TERRITORY_ADDED,
              {
                companyId: company?.id,
              }
            );
          }

          navigate(
            OnboardingSteps[ONBOARDING_STAGES.SERVICE_DETAILS].nextStepRoute
          );
          return;
        }
      });
    },
  });

  const initialFormValues: FormValues = {
    companyName: "",
    zipCode: "",
    sectors: [],
  };

  const [selectedZipCode, setSelectedZipCode] = useState(
    companyAddress?.zipCode
  );

  const { data: location } = useQueryLocation(
    selectedZipCode ?? companyAddress?.zipCode ?? "",
    !!(selectedZipCode || companyAddress?.zipCode)
  );

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      const { companyName, zipCode, sectors } = values;
      const territoriesGeoJson = [];

      if (isMobile && step === 0) {
        await updateCompanyServiceDetails({
          companyDetails: {
            name: companyName ?? company?.name ?? "",
            zipCode: zipCode ?? companyAddress?.zipCode ?? "",
          },
          sectors:
            (sectors.map(
              sector => reverseSnakeCaseJobSectors[sector as JobSectors]
            ) as Array<JobSectors>) ?? [],
        });
        return;
      }
      if (isMobile && step === 1) {
        for (const feature of selectedTerritories) {
          const geoJson = await promisifiedFunction(
            feature.toGeoJson.bind(feature)
          );
          territoriesGeoJson.push(geoJson);
        }
        await updateCompanyServiceDetails({
          territories: territoriesGeoJson,
        });
        return;
      }
      for (const feature of selectedTerritories) {
        const geoJson = await promisifiedFunction(
          feature.toGeoJson.bind(feature)
        );
        territoriesGeoJson.push(geoJson);
      }
      await updateCompanyServiceDetails({
        territories: territoriesGeoJson,
        companyDetails: {
          name: companyName ?? company?.name ?? "",
          zipCode: zipCode ?? companyAddress?.zipCode ?? "",
        },
        sectors:
          (sectors.map(
            sector => reverseSnakeCaseJobSectors[sector as JobSectors]
          ) as Array<JobSectors>) ?? [],
      });
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (companySectors) {
      formik.setFieldValue(
        "sectors",
        companySectors.map(({ sector }) => snakeCaseJobSectors[sector])
      );
    }
    if (companyAddress?.zipCode) {
      formik.setFieldValue("zipCode", companyAddress.zipCode);
    }
    if (company?.name) {
      formik.setFieldValue("companyName", company.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySectors, companyAddress, company]);

  const content = (
    <Card
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: 1,
        ...(isMobile
          ? { p: 0, border: "none", boxShadow: "none", borderRadius: 0 }
          : { p: 2 }),
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      {(isMobile && step === 0) || !isMobile ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "flex-start",
            flexDirection: { xs: "column", md: "row" },
            ...(isMobile ? { px: 0, py: 4, gap: 3 } : { p: 1, gap: 1 }),
            width: "100%",
          }}
        >
          <Autocomplete
            freeSolo
            sx={{ width: { xs: "100%", md: "44%" } }}
            value={formik.values.companyName}
            options={
              data?.map((option: SearchCompanyProfile) => option.name) ?? []
            }
            loading={loading}
            renderInput={params => (
              <TextField
                {...params}
                required
                id="companyName"
                name="companyName"
                label="Company Name"
                value={formik.values.companyName}
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
                onChange={formik.handleChange}
              />
            )}
            onChange={async (_: SyntheticEvent, newValue?: string | null) => {
              const selectedCompany = data?.find(
                (record: SearchCompanyProfile) => record.name === newValue
              );
              if (selectedCompany?.zipCode) {
                await formik.setFieldValue("zipCode", selectedCompany.zipCode);
                if (
                  selectedCompany.zipCode?.toString()?.length === 5 &&
                  formik.values.zipCode !== selectedCompany.zipCode?.toString()
                ) {
                  debouncedZipCodeSetter(selectedCompany.zipCode);
                }
              }
              if (selectedCompany?.sectors) {
                const newSectors = selectedCompany?.sectors.filter(sector =>
                  Object.keys(snakeCaseJobSectors).includes(sector)
                );

                await formik.setFieldValue("sectors", newSectors, true);
              }
              await formik.setFieldValue("companyName", newValue);
            }}
            onInputChange={(_: unknown, newInputValue: string) => {
              if (newInputValue.length >= 3) {
                debouncedFilterSetter(newInputValue);
              }
            }}
            onBlur={formik.handleBlur}
          />
          {!isMobile ? (
            <Autocomplete
              multiple
              id="sectors"
              sx={{ width: { xs: "100%", md: "44%" } }}
              options={Object.keys(JobSectors).map(sector => {
                if (sector === JobSectors.HVAC) {
                  return sector.toString();
                }
                return snakeCaseJobSectors[sector.toString() as JobSectors];
              })}
              getOptionLabel={option => option}
              value={formik.values.sectors}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  helperText={formik.touched.sectors && formik.errors.sectors}
                  error={
                    formik.touched.sectors && Boolean(formik.errors.sectors)
                  }
                  label="Relevant Service Category"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} key={option} value={option}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    {<Typography>{option}</Typography>}
                    {selected ? (
                      <CheckCircleOutlineIcon color="primary" />
                    ) : null}
                  </Box>
                </MenuItem>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                    variant="outlined"
                  />
                ))
              }
              onChange={(_: SyntheticEvent, newValue: string[] | null) =>
                formik.setFieldValue("sectors", newValue)
              }
              onBlur={formik.handleBlur}
            />
          ) : null}
          <TextField
            required
            sx={{ width: { xs: "100%", md: "12%" } }}
            id="zipCode"
            name="zipCode"
            label="Zip Code"
            value={formik.values.zipCode}
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
            type="number"
            inputMode="numeric"
            InputProps={{
              inputProps: {
                pattern: "[0-9]*", // Only allow numeric values
                inputMode: "numeric", // Set input mode to numeric
                maxLength: 5,
              },
            }}
            onChange={event => {
              if (event.target.value?.length === 5) {
                debouncedZipCodeSetter(event.target.value);
              }
              if (event.target.value?.length <= 5) {
                formik.setFieldValue("zipCode", event.target.value, true);
              }
            }}
            onBlur={formik.handleBlur}
          />
          {isMobile ? (
            <Autocomplete
              multiple
              id="sectors"
              sx={{ width: { xs: "100%", md: "44%" } }}
              options={Object.keys(JobSectors).map(sector => {
                if (sector === JobSectors.HVAC) {
                  return sector.toString();
                }
                return snakeCaseJobSectors[sector.toString() as JobSectors];
              })}
              getOptionLabel={option => option}
              value={formik.values.sectors}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  helperText={formik.touched.sectors && formik.errors.sectors}
                  error={
                    formik.touched.sectors && Boolean(formik.errors.sectors)
                  }
                  label="Relevant Service Category"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} key={option} value={option}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    {<Typography>{option}</Typography>}
                    {selected ? (
                      <CheckCircleOutlineIcon color="primary" />
                    ) : null}
                  </Box>
                </MenuItem>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                    variant="outlined"
                  />
                ))
              }
              onChange={(_: SyntheticEvent, newValue: string[] | null) =>
                formik.setFieldValue("sectors", newValue)
              }
              onBlur={formik.handleBlur}
            />
          ) : null}
        </Box>
      ) : null}
      {(isMobile && step === 1) || !isMobile ? (
        <Box
          sx={{
            width: "100%",
            px: -1,
            flexGrow: 1,
            overflow: "auto",
            ...(isMobile ? { minHeight: "300px" } : {}),
          }}
        >
          <TerritoryMap
            location={location}
            currentGeoIds={currentGeoIds}
            selectedTerritories={selectedTerritories}
            onTerritoryClick={newSelectedTerritories =>
              setSelectedTerritories(newSelectedTerritories)
            }
          />
        </Box>
      ) : null}
    </Card>
  );

  const getHeading = () => {
    if (isMobile && step === 0) return "Service Details";
    if (isMobile && step === 1) return "Where is Your Service Territory?";
    return "Select Your Service Territory";
  };
  const getSubHeading = () => {
    if (isMobile && step === 0)
      return "Review your information below. You'll define your territory next.";
    if (isMobile && step === 1)
      return "Click the map below to select your service area.";
    return "Click the map below to select your service area and find the closest referral partners.";
  };

  return (
    <OnboardingContainer heading={getHeading()} subheading={getSubHeading()}>
      <Box
        sx={{
          display: "flex",

          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          height: "100%",
          overflow: "auto",
          alignSelf: "stretch",
          ...(isMobile
            ? { padding: "8px 0" }
            : { background: "#F3F4F6", padding: "8px 24px" }),
        }}
      >
        {content}
      </Box>
      <Stack
        spacing={2}
        mt={3}
        mb={2}
        mx={2}
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "end",
          alignSelf: "stretch",
          mx: 2,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            if (leadDetails?.name) {
              navigate(`/${AuthRoutes.BASE}/${AuthRoutes.SIGN_UP}`, {
                replace: true,
              });
              return;
            }
            navigate(`/${AuthRoutes.BASE}/${AuthRoutes.USER_TYPE}`, {
              replace: true,
            });
          }}
        >
          Back
        </Button>
        <LoadingButton
          disabled={updatingCompanyServiceDetails}
          loading={updatingCompanyServiceDetails}
          variant="contained"
          onClick={() => formik.submitForm()}
        >
          Continue
        </LoadingButton>
      </Stack>
    </OnboardingContainer>
  );
}
