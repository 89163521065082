/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Box, Card, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useCreateReferralInterest } from "@/company/api/useCreateReferralInterest";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { useConnectionLimitReachedModalContext } from "@/open/components/modals/LimitReachedContext";

import { CompanyAvatar } from "../company-avatar/CompanyAvatar";
import {
  InvitationDialog,
  InviteSource,
} from "../invitation-dialog/InvitationDialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface ExternalInviteProps {
  isOpen: boolean;
  companyPublicProfile: {
    logoUrl?: string;
    name: string;
    googlePlacesId: string;
  };
  onClose: (invitationSent?: boolean) => void;
}

const action = (snackbarId: SnackbarKey, onClick: () => void) => (
  <>
    <Typography
      sx={{
        color: "#16DB65",
        fontSize: "14px",
        fontWeight: 600,
        pr: 2,
        cursor: "pointer",
      }}
      onClick={() => {
        onClick();
        closeSnackbar(snackbarId);
      }}
    >
      Contact Us
    </Typography>
    <CloseIcon
      sx={{ color: "white", cursor: "pointer", mr: 1 }}
      fontSize="small"
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    />
  </>
);

export const ExternalInviteDialog: React.FC<ExternalInviteProps> = ({
  isOpen,
  companyPublicProfile,
  onClose,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const { setShowLimitReachedDialog } = useConnectionLimitReachedModalContext();
  const { mutateAsync: createReferralInterest, isLoading } =
    useCreateReferralInterest({
      onSuccess: () => {
        enqueueSnackbar({
          message: "Your request has been submitted.",
          variant: "success",
          subText: (
            <>
              Need help?{" "}
              <a
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={event => {
                  event.preventDefault();
                  setContactSupportModalOpen(true);
                }}
              >
                Contact support
              </a>
            </>
          ),
        });
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error?.message
          : "There was an error while trying to create the connection request.";
        if (error?.status_code === 409) {
          setShowLimitReachedDialog(message);
          return;
        }
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
    });

  const handleRequestPartnerClick = async () => {
    await createReferralInterest(companyPublicProfile.googlePlacesId);
    onClose(true);
  };

  const { enqueueSnackbar } = useSnackbar();

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="invite-trade-engage-title"
        open={isOpen}
        onClose={onClose}
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <DialogTitle
                sx={{ m: 0, p: 0 }}
                id="partner-removal-dialog-title"
              >
                <Typography variant={isDesktop ? "h5" : "h6"}>
                  Invite to TradeEngage
                </Typography>
              </DialogTitle>
              <IconButton
                aria-label="close"
                sx={{
                  color: theme => theme.palette.grey[500],
                }}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography
              gutterBottom
              variant="body2"
              maxWidth={"500px"}
              color="text.secondary"
            >
              This company is not on TradeEngage yet and we don&apos;t have any
              contact information for their team. Click Request Partner below
              and we&apos;ll follow up. Check back soon as new companies are
              joining TradeEngage daily!
            </Typography>
          </Box>
          <Card
            sx={{
              display: "flex",
              gap: 2,
              alignItems: { xs: "left", md: "center" },
              py: 3,
              px: 2,
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              boxShadow: "0px 0px 0px 1px #F2F4F7",
            }}
          >
            <Box
              pl={1}
              pr={2}
              sx={{
                gap: { xs: 2, md: 3 },
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <CompanyAvatar
                logo={companyPublicProfile.logoUrl}
                name={companyPublicProfile.name}
                height="40px"
              />
              <Typography variant="h6">{companyPublicProfile.name}</Typography>
            </Box>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              loading={isLoading}
              sx={{ width: "fit-content" }}
              onClick={handleRequestPartnerClick}
            >
              Request Partner
            </LoadingButton>
          </Card>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "left", md: "center" },
            }}
            gap={3}
            px={2}
          >
            <Typography
              variant="body2"
              sx={{
                width: { xs: "100%", md: "60%" },
              }}
            >
              Know their contact details? Click here to invite them to
              TradeEngage through your own network.
            </Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              sx={{ textWrap: "nowrap", px: 1, width: "fit-content" }}
              onClick={() => setInvitationDialogOpen(true)}
            >
              Invite Partner
            </Button>
          </Box>
        </Box>
      </BootstrapDialog>
      <InvitationDialog
        isOpen={invitationDialogOpen}
        source={InviteSource.EXTERNAL_INVITE}
        googlePlacesId={companyPublicProfile?.googlePlacesId}
        onClose={invitationSent => {
          setInvitationDialogOpen(false);
          if (invitationSent) {
            onClose(true);
          } else {
            onClose();
          }
        }}
      />
    </React.Fragment>
  );
};
