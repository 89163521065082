import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Chip,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { FC, SyntheticEvent, useEffect, useState } from "react";

import { HubSchema } from "../api/useGetHubs";

interface HubSelectionPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  hubs?: Array<HubSchema>;
  selectedGroups?: Array<HubSchema>;
  setSelectedGroups: (selectedGroups: Array<HubSchema>) => void;
}

export const GroupSelectionPopover: FC<HubSelectionPopoverProps> = props => {
  const {
    anchorEl,
    onClose,
    open,
    hubs,
    selectedGroups,
    setSelectedGroups,
    ...other
  } = props;

  const [pickedGroups, setPickedGroups] = useState<Array<HubSchema>>(
    selectedGroups ?? []
  );

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [tagsLimit, setTagsLimit] = useState<number | undefined>(2);

  const groupsWithAll = [{ id: "all", name: "All" }, ...(hubs ?? [])];

  useEffect(() => {
    if (selectedGroups?.length) {
      setPickedGroups(selectedGroups);
    }
  }, [selectedGroups]);

  return (
    <Popover
      disableScrollLock
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={!!open}
      onClose={() => {
        setTagsLimit(2);
        onClose?.();
      }}
      {...other}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "flex-start",
          width: isMobile ? "80vw" : "500px",

          flexGrow: { xs: 0, md: 1 },
          flex: "1 0 0",
          p: 2,
          boxShadow:
            "0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            py: 2,
            px: 0,
          }}
          title={<Typography variant="h6">Filter by Group</Typography>}
        />
        <Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
          <Autocomplete
            multiple
            id="groups"
            sx={{ width: "100%" }}
            limitTags={tagsLimit}
            value={pickedGroups}
            getOptionLabel={(option: HubSchema) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={groupsWithAll ?? []} // Ensures correct comparison for selection
            renderInput={params => (
              <TextField
                {...params}
                required
                id="name"
                name="name"
                label="Group Name"
                inputProps={{ "data-hj-allow": true, ...params.inputProps }}
              />
            )} // Data is already an array of `CompanyPublicProfile`
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} key={option.id} value={option}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  {<Typography>{option.name}</Typography>}
                  {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
                </Box>
              </MenuItem>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  size="small"
                  label={option.name}
                  {...getTagProps({ index })}
                  variant="outlined"
                />
              ))
            }
            onBlur={() => {
              setTagsLimit(undefined);
            }}
            onChange={(_: SyntheticEvent, newValue: HubSchema[] | null) => {
              if (newValue?.some(hub => hub.id === "all")) {
                setPickedGroups(hubs ?? []); // Select all companies
              } else {
                setPickedGroups(newValue as Array<HubSchema>);
              }
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setSelectedGroups([...pickedGroups]);
              setTagsLimit(2);
            }}
          >
            Apply
          </Button>
        </Box>
      </Card>
    </Popover>
  );
};
