import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import axiosInstance from "@/common/api";

import { JobSectors } from "../jobs/constants";
import { COMPANY_ACCOUNT_TYPES } from "../types";

export interface BasicCompanySchema {
  id: number;
  name: string;
  logoUrl?: string;
  sectors?: Array<JobSectors>;
  accountType?: COMPANY_ACCOUNT_TYPES;
}

export const GET_REFERRALS_COMPANY =
  "/internal-admin/companies/:id/referrals?sector=:sector";

export function useGetSectorReferrals(
  companyId: number,
  sector: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<BasicCompanySchema[]> {
  const url = GET_REFERRALS_COMPANY.replace(
    ":id",
    companyId?.toString() ?? ""
  ).replace(":sector", sector.toString());
  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      const response = await axiosInstance.get(url);
      return response.data.data;
    },
    ...options,
  });
}
