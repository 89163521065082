import { Close, Search } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { DateTime } from "luxon";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { DashboardTitle } from "@/common/DashboardTitle";
import { Seo } from "@/common/Seo/Seo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import {
  Chat,
  USE_GET_ALL_CHATS__GROUPED_URI,
  useFetchCompanyMessagesList,
} from "@/company/api/useFetchCompanyMessagesList";
import { ChatProvider } from "@/company/context/chat-context";
import { useReferralContext } from "@/company/context/referral-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import {
  CompanyPublicProfile,
  CompanyRoutes,
  ConnectionStatus,
} from "@/company/types";

import { CompanyAvatar } from "../../company-avatar/CompanyAvatar";
import { Message } from "../../company-drawer/Message";

export function CompanyMessagesPage() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    isLoading,
    data: companyMessagesList,
    refetch: refetchChat,
  } = useFetchCompanyMessagesList({
    refetchOnWindowFocus: false,
  });

  const { referralInvitations, isFetchingReferralInvitations } =
    useReferralInvitationContext();
  const { referralPartners, isFetchingReferralPartners } = useReferralContext();

  const [selectedChat, setSelectedChat] = useState<null | {
    latestChat: Chat;
    companyProfile: CompanyPublicProfile;
  }>(null);
  const [searchText, setSearchText] = useState("");

  const getConnectionStatus = useCallback(
    (currentCompany: CompanyPublicProfile): ConnectionStatus => {
      if (
        referralPartners?.some(
          referral =>
            referral.referredCompanyId === currentCompany?.id ||
            referral.referringCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.CONNECTED;

      if (
        referralInvitations?.some(
          invitation => invitation.invitedCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.PENDING;

      if (
        referralInvitations?.some(
          invitation => invitation.invitingCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.INVITED;

      return ConnectionStatus.NOT_CONNECTED;
    },
    [referralInvitations, referralPartners]
  );

  const filteredCompanyMessagesList = searchText
    ? companyMessagesList?.filter(item =>
        item.companyProfile.name.toLowerCase().startsWith(searchText)
      )
    : companyMessagesList;

  return (
    <>
      <Seo title="Company Messages" />
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"calc(100vh - 60px)"}
        pt={1}
      >
        <DashboardTitle
          title="Messages"
          subtitle="Message with potential and existing referral partners from here. Message homeowners associated with each job from the Jobs Dashboard."
        />
        {isLoading ||
        isFetchingReferralPartners ||
        isFetchingReferralInvitations ? (
          <Box textAlign={"center"} width="100%" mt={5}>
            <ComponentLoader />
          </Box>
        ) : companyMessagesList && companyMessagesList.length > 0 ? (
          <Container
            maxWidth={false}
            sx={{
              mx: 0,
              mt: 5,
              flexGrow: 1,
              display: "flex",
              overflow: "auto",
            }}
            disableGutters={true}
          >
            <Grid container flexGrow={1} display={"flex"} overflow={"auto"}>
              <Grid
                item
                container
                xs={12}
                md={isMobile ? 12 : 4}
                sx={{
                  overflow: "auto",
                  display: "flex",
                  height: "fit-content",
                  maxHeight: "100%",
                  pr: 2,
                }}
              >
                <Grid item xs={12} mb={3}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    placeholder="Search company name"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </Grid>
                {filteredCompanyMessagesList?.map(item => {
                  const createdAt = DateTime.fromFormat(
                    item.latestChat.createdAt.toString().split(".")[0],
                    "yyyy-MM-dd HH:mm:ss",
                    { zone: "utc" }
                  );
                  const ago = createdAt.toLocal().toRelative({
                    base: DateTime.now().toLocal(),
                  });
                  const todayStart = DateTime.local().startOf("day");

                  const formattedDate =
                    createdAt.toLocal() < todayStart
                      ? createdAt.toLocal().toFormat("MM/dd/yy")
                      : createdAt.toLocal().toFormat("h:mm a");

                  const isUnread =
                    item.latestChat.fromCompanyId == item.companyProfile.id &&
                    !item.latestChat.toCompanyReadAt;

                  return (
                    <>
                      <Grid
                        key={item.companyProfile.id}
                        container
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          borderRadius: "24px",
                          px: { xs: 0, md: 3 },
                          py: { xs: 2, md: 3 },
                          cursor: "pointer",
                          backgroundColor: (theme: Theme) =>
                            selectedChat?.companyProfile.id ===
                            item.companyProfile.id
                              ? theme.palette.primary.light
                              : theme.palette.background.paper,
                        }}
                        onClick={() => {
                          setSelectedChat(item);
                          queryClient.invalidateQueries({
                            queryKey: [USE_GET_ALL_CHATS__GROUPED_URI],
                          });
                        }}
                      >
                        <Grid
                          item
                          xs={10}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            borderRadius: "24px",
                          }}
                        >
                          <CompanyAvatar
                            logo={item.companyProfile.logoUrl || undefined}
                            name={item.companyProfile.name}
                            height={"48px"}
                            width={"48px"}
                            bgColor="white"
                            border="1px solid gray !important"
                          />
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            flexDirection={"column"}
                            mx={1}
                          >
                            <Typography gutterBottom variant="h6">
                              {item.companyProfile.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color={
                                isUnread ? "text.primary" : "text.secondary"
                              }
                            >
                              {_.truncate(item.latestChat.message, {
                                length: selectedChat || isMobile ? 35 : 50,
                              })}
                              {item.latestChat.message.length >
                              (selectedChat || isMobile ? 35 : 50)
                                ? "..."
                                : null}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-end"
                          flexDirection={"column"}
                        >
                          <Typography
                            gutterBottom
                            variant="caption"
                            color={isUnread ? "text.primary" : "text.secondary"}
                          >
                            {ago?.startsWith("in 0 seconds") ||
                            ago?.startsWith("0 seconds ago")
                              ? "Just now"
                              : `${formattedDate}`}
                          </Typography>
                          {isUnread ? (
                            <FiberManualRecordIcon
                              color="primary"
                              fontSize="small"
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                      {isMobile ? (
                        <Divider sx={{ width: "100%", my: 1 }} />
                      ) : null}
                    </>
                  );
                })}
              </Grid>
              {isMobile ? null : (
                <Grid
                  item
                  container
                  xs={false}
                  md={!isMobile ? 8 : false}
                  sx={{
                    px: 3,
                    borderLeft: isMobile ? null : "2px #F8F9FA solid",
                    overflow: "auto",
                    height: "100%",
                  }}
                >
                  {selectedChat && selectedChat.companyProfile ? (
                    <Box
                      width="100%"
                      height={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Box
                        mb={2}
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Box display="flex" alignItems={"center"}>
                          <CompanyAvatar
                            logo={
                              selectedChat.companyProfile.logoUrl || undefined
                            }
                            name={selectedChat.companyProfile.name}
                            height={"48px"}
                            width={"48px"}
                            bgColor="white"
                            border="1px solid gray !important"
                          />
                          <Typography variant="h6" ml={1}>
                            {selectedChat?.companyProfile.name}
                          </Typography>
                        </Box>

                        <IconButton onClick={() => setSelectedChat(null)}>
                          <Close />
                        </IconButton>
                      </Box>
                      <ChatProvider companyId={selectedChat.companyProfile.id}>
                        <Message
                          otherCompanyProfile={selectedChat.companyProfile}
                          connectionStatus={getConnectionStatus(
                            selectedChat.companyProfile
                          )}
                          removeBaseStyles={true}
                          onChatMessageAdd={() => {
                            refetchChat();
                          }}
                        />
                      </ChatProvider>
                    </Box>
                  ) : isMobile ? null : (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={"100%"}
                      height={"100%"}
                      gap={3}
                      flex={1}
                    >
                      <img
                        src="/empty-icon.png"
                        style={{ width: "132px", height: "128px" }}
                        alt="no pending jobs thumbnail"
                      />
                      <Box textAlign={"center"}>
                        <Typography gutterBottom variant="h6">
                          No Conversation Selected
                        </Typography>
                        <Typography variant="body2" color={"text.secondary"}>
                          Select a conversation from your inbox to message with
                          local partners.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </Container>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={5}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                No Messages Found
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                Click below to view your partners and start chatting with them.
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                navigate(
                  getNavigationPathFromRoutePaths([
                    CompanyRoutes.BASE,
                    CompanyRoutes.MANAGE_PARTNERS,
                  ])
                );
              }}
            >
              View Partners
            </Button>
          </Box>
        )}
      </Box>
      <Dialog
        fullScreen
        open={Boolean(selectedChat && isMobile)}
        onClose={() => setSelectedChat(null)}
      >
        <Box height="100vh" display={"flex"} flexDirection={"column"}>
          <Box
            my={3}
            mx={2}
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6">
              {selectedChat?.companyProfile.name}
            </Typography>
            <IconButton onClick={() => setSelectedChat(null)}>
              <Close />
            </IconButton>
          </Box>
          {selectedChat && (
            <Box mx={2} height={"100%"} overflow={"auto"}>
              <ChatProvider companyId={selectedChat.companyProfile.id}>
                <Message
                  otherCompanyProfile={selectedChat.companyProfile}
                  connectionStatus={getConnectionStatus(
                    selectedChat.companyProfile
                  )}
                />
              </ChatProvider>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
}
