/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import {
  closeSnackbar,
  CustomContentProps,
  SnackbarContent,
  SnackbarKey,
} from "notistack";
import { forwardRef } from "react";

interface SuccessSnackBarProps extends CustomContentProps {
  onClose?: () => void;
  actions?: (snackbarId: SnackbarKey) => React.ReactNode;
  subText?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const CustomBannerSnackBar = forwardRef<
  HTMLDivElement,
  SuccessSnackBarProps
>((props, ref) => {
  const {
    // You have access to notistack props and options 👇🏼
    id,
    message,
    subText,
    actions,
    onClose,

    // as well as your own custom props 👇🏼
    ...other
  } = props;

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Box
        display={"flex"}
        sx={{
          zIndex: 2000,
          backgroundColor: "white",
          padding: "6px 16px",
          borderRadius: "12px",
          justifyContent: "center",
          alignItems: "flex-start",
          p: 2,
          boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          "&:active": {
            border: "1px solid #071D2F",
          },
          "&:focus": {
            border: "1px solid #071D2F",
          },
          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
        }}
      >
        <Box display={"flex"} flexDirection={"column"} py={1}>
          {message ? <Typography variant="h6">{message}</Typography> : null}
          {subText ? (
            <Typography
              sx={{
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "157%",
                letterSpacing: "0.15px",
                //   color: "white",
              }}
            >
              {subText}
            </Typography>
          ) : null}
        </Box>
        <CloseIcon
          fontSize="small"
          sx={{
            //   color: "white",
            cursor: "pointer",
            width: "20px",
            height: "20px",
            mt: "10px",
            ml: 2,
          }}
          onClick={() => {
            onClose?.();
            closeSnackbar(id);
          }}
        />
      </Box>
    </SnackbarContent>
  );
});
