import { Box, CircularProgress, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useAuth } from "@/auth/context/jwt";
import { PayReferralFeeSettings } from "@/company/types";

import { UpdateSectorType } from "../ReferralMatrix";
import { PayAfterJobCompletion } from "./PayAfterJobCompletion";
import { PayPerReferral } from "./PayPerReferral";

export interface JobPriceSettingsProps {
  isLoading: boolean;
  setPayReferralFeeAfterJobCompletion: (value: boolean) => void;
  payReferralFeeAfterJobCompletion: boolean;
  setPayPerReferral: (value: boolean) => void;
  payPerReferral: boolean;
  paymentMode: PayReferralFeeSettings;
  setPaymentMode: (value: PayReferralFeeSettings) => void;
  updatedSectors: UpdateSectorType | null;
  setUpdatedSectors: (record: UpdateSectorType) => void;
}
export const JobPriceSettings: React.FC<JobPriceSettingsProps> = (
  props: JobPriceSettingsProps
) => {
  const {
    isLoading,
    updatedSectors,
    setUpdatedSectors,
    setPayReferralFeeAfterJobCompletion,
    payReferralFeeAfterJobCompletion,
    setPaymentMode,
    paymentMode,
    setPayPerReferral,
    payPerReferral,
  } = props;

  const { isInitialized } = useAuth();

  if (!isInitialized) return <>Loading...</>;
  const isPayPerReferralDisabled =
    props.payPerReferral && !props.payReferralFeeAfterJobCompletion;
  const isPayReferralFeeAfterJobCompletionDisabled =
    props.payReferralFeeAfterJobCompletion && !props.payPerReferral;

  return (
    <Grid container p={2}>
      <Grid item xs={12} lg={4} pr={2}>
        <Typography gutterBottom variant="h6">
          Job Price
        </Typography>
        <Typography gutterBottom variant="body2" color="text.secondary">
          This is the referral fee you pay to service providers, their
          technicians and CSRs to thank them for the referral. There are three
          ways to pay!
        </Typography>
        <Typography gutterBottom variant="body2" color="text.secondary" mt={3}>
          1. Pay for Every Referral - You&apos;ll pay for every referral you
          receive
        </Typography>
        <Typography gutterBottom variant="body2" color="text.secondary" mt={3}>
          2. Pay When Job is Completed - You&apos;ll only pay when the job
          closes
        </Typography>
        <Typography gutterBottom variant="body2" color="text.secondary" mt={3}>
          3. Both (Recommended) - You&apos;ll pay for every referral you receive
          and you&apos;ll have the option to pay a bonus if the job closes!
        </Typography>
        <Typography gutterBottom variant="body2" color="text.secondary" mt={3}>
          Referral fees can vary, but we suggest paying 5% of the total job cost
          or at least $50 for most service categories.
        </Typography>
      </Grid>
      <Grid item xs={12} lg={8} p={0}>
        {isLoading ? (
          <Box py={3} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <PayPerReferral
              disabled={isPayPerReferralDisabled}
              updatedSectors={updatedSectors}
              setUpdatedSectors={setUpdatedSectors}
              payPerReferral={payPerReferral}
              setPayPerReferral={setPayPerReferral}
            />
            <PayAfterJobCompletion
              disabled={isPayReferralFeeAfterJobCompletionDisabled}
              paymentMode={paymentMode}
              setPaymentMode={setPaymentMode}
              updatedSectors={updatedSectors}
              setUpdatedSectors={setUpdatedSectors}
              payReferralFeeAfterJobCompletion={
                payReferralFeeAfterJobCompletion
              }
              setPayReferralFeeAfterJobCompletion={
                setPayReferralFeeAfterJobCompletion
              }
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
