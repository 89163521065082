import { Avatar, Box, Typography } from "@mui/material";

import { neutral } from "@/Theme/colors";
import { getInitials } from "@/utils";

export interface HomeownerSideNavHeaderProps {
  name: string;
}
export function HomeownerSideNavHeader(props: HomeownerSideNavHeaderProps) {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      py={3}
      sx={{ ml: { xs: 2, md: 0 } }}
    >
      <Avatar
        alt={props.name}
        variant="rounded"
        sx={{
          width: "40px",
          height: "40px",
          "& img": {
            objectFit: "contain",
          },
        }}
      >
        {getInitials(props.name ?? "")}
      </Avatar>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        ml={3}
      >
        <Typography gutterBottom variant="h6" color={neutral[50]}>
          {props.name}
        </Typography>
        <Typography gutterBottom variant="caption" color={neutral[400]}>
          Homeowner
        </Typography>
      </Box>
    </Box>
  );
}
