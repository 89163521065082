import "./i18n";

import { Box, CircularProgress } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Helmet } from "react-helmet-async";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import {
  CAMPAIGN_NAME,
  CampaignLandingPage,
} from "./auth/components/screens/signup/ads/CampaignLandingPage";
import { CampaignMarketingSignUpPage } from "./auth/components/screens/signup/ads/CampaignMarketingSignUpPage";
import { useAuth } from "./auth/context/jwt";
import { AuthRouter } from "./auth/router";
import PageNotFoundScreen from "./common/404notfound";
import { ComponentLoader } from "./common/ComponentLoader/ComponentLoader";
import { AppConstants } from "./common/constants";
import ErrorBoundary from "./common/ErrorBoundary/ErrorBoundary";
import RootLayout from "./common/layouts/RootLayout";
import { CompanyDashboardLayout } from "./company/components/layout/CompanyDashboardLayout";
import { CompanyDashboardHomePage } from "./company/components/screens/dashboard-home/CompanyDashboardHomePage";
import { SupportChatProvider } from "./company/context/support-chat-context";
import { CompanyRouter } from "./company/router";
import { ConsultantRouter } from "./consultants/router";
import { HomeownerRouter } from "./homeowner/router";
import { AdminRouter } from "./internal-admin/router";
import { OpenLayout } from "./open/components/layout/OpenLayout";
import { useContactSupportModalContext } from "./open/components/modals/ContactSupportContext";
import { ContactSupportModal } from "./open/components/modals/ContactSupportModal";
import { CampaignMarketingPage } from "./open/components/screens/marketing/CampaignMarketingPage";
import { MarketingPage } from "./open/components/screens/marketing/MarketingPage";
import { OpenRouter } from "./open/router";

/* 
-Base Router
  - HomeOwnerRouter
    - My Jobs
    - Personal Settings
    - My Providers
  - CompanyUserRouter
    - OnboardingRouter
      - TeamMemberOnboardingRouter
        - technician accept terms
        - team member accept terms
        - view technician demo
      - CompanyOnboardingRouter
        - different company onboarding steps ...
    - Dashboard Home
    - All Jobs
    - My Jobs
    - Personal Settings
    - Company Settings
    - Explore Partners
    - Manage Partners
    - Messages
    - My Wallet
    - Team Members
  - Marketing (Public Pages)
    - LandingPageRouter
      - different landing pages ...
      - fdd company identified page
      - select google identity page
      - google company identified success page
    - Public Job Page
    - Public Service Provider Page
    - Contact Support
  - AuthRouter
    - Login
    - Sign up
    - Verify OTP
    - User Type / Company Account Type selection
  - Error Boundary
*/

export default function App() {
  const { contactSupportModalOpen, setContactSupportModalOpen } =
    useContactSupportModalContext();

  const { isAuthenticated, isInitialized } = useAuth();

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    // apiKey: AppConstants.googleApiKey,
  });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="" element={<RootLayout />} errorElement={<ErrorBoundary />}>
        {!AppConstants.CAMPAIGN_SITE ? OpenRouter() : null}
        {!AppConstants.CAMPAIGN_SITE ? AuthRouter() : null}
        {!AppConstants.CAMPAIGN_SITE ? CompanyRouter() : null}
        {!AppConstants.CAMPAIGN_SITE ? HomeownerRouter() : null}
        {!AppConstants.CAMPAIGN_SITE ? AdminRouter() : null}
        {!AppConstants.CAMPAIGN_SITE ? ConsultantRouter() : null}
        {AppConstants.CAMPAIGN_SITE ? (
          <Route
            path="/"
            loader={ComponentLoader}
            element={<PageNotFoundScreen />}
          />
        ) : (
          <Route
            path="/"
            loader={ComponentLoader}
            element={
              !isInitialized ? (
                <Box my={5} textAlign="center">
                  <CircularProgress />
                </Box>
              ) : !isAuthenticated ? (
                <OpenLayout>
                  <MarketingPage />
                </OpenLayout>
              ) : (
                <SupportChatProvider>
                  <CompanyDashboardLayout />
                </SupportChatProvider>
              )
            }
          >
            {isAuthenticated ? (
              <Route
                path={""}
                loader={ComponentLoader}
                element={<CompanyDashboardHomePage />}
              />
            ) : null}
          </Route>
        )}
        {AppConstants.CAMPAIGN_SITE ? (
          <>
            <Route
              path="/:location"
              loader={ComponentLoader}
              element={<CampaignLandingPage />}
            />
          </>
        ) : null}
        <Route
          path="/ad/:location"
          loader={ComponentLoader}
          element={
            <OpenLayout>
              <CampaignMarketingPage />
            </OpenLayout>
          }
        />
        <Route
          path="ad/signup/:location"
          loader={ComponentLoader}
          element={
            <OpenLayout>
              <CampaignMarketingSignUpPage />
            </OpenLayout>
          }
        />
        <Route
          path="ads/campaign"
          loader={ComponentLoader}
          element={<CampaignLandingPage />}
        />

        <Route
          path="ads/signup/:location"
          loader={ComponentLoader}
          element={
            <OpenLayout>
              <CampaignMarketingPage />
            </OpenLayout>
          }
        />
        <Route
          path="*"
          loader={ComponentLoader}
          element={<PageNotFoundScreen />}
        />
      </Route>
    )
  );

  return (
    <>
      <Helmet>
        <title>App</title>
        {AppConstants.CAMPAIGN_SITE &&
        AppConstants.environment === "production" ? (
          AppConstants.CAMPAIGN_SITE === CAMPAIGN_NAME.ADVANTA ? (
            <>
              <script
                async
                referrerPolicy="no-referrer-when-downgrade"
                src="https://tag.simpli.fi/sifitag/5952f39d-3057-4236-acf5-c4deeae98c5f"
              ></script>
              <script>{`!function () {var reb2b = window.reb2b = window.reb2b || [];if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);args.unshift(method);reb2b.push(args);return reb2b;};};for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("1VN080HJE36J");}();`}</script>{" "}
            </>
          ) : AppConstants.CAMPAIGN_SITE === CAMPAIGN_NAME.LIGHTSPEED ? (
            <>
              <script
                async
                referrerPolicy="no-referrer-when-downgrade"
                src="https://tag.simpli.fi/sifitag/5233de23-f79e-4652-8c57-eaf9b336cb3b"
              ></script>
              <script>{`!function () {var reb2b = window.reb2b = window.reb2b || [];if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);args.unshift(method);reb2b.push(args);return reb2b;};};for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("ZQO92DHDDYN7");}();`}</script>{" "}
            </>
          ) : null
        ) : AppConstants.environment === "production" ? (
          <>
            <script>{`!function () {var reb2b = window.reb2b = window.reb2b || [];if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);args.unshift(method);reb2b.push(args);return reb2b;};};for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("1LNKLDHVKQOJ");}();`}</script>
            <script>{`!function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); }('DzLR5a55XmAaBoQ2');`}</script>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-M2W1B9LB93"
            ></script>
            <script>
              {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-M2W1B9LB93');
        `}
            </script>
          </>
        ) : null}
      </Helmet>
      <RouterProvider router={router} />
      <ContactSupportModal
        isOpen={contactSupportModalOpen}
        onClose={() => {
          setContactSupportModalOpen(false);
        }}
      />
    </>
  );
}
