import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";
import zipcodes, { codes } from "zipcodes";

import { AuthRoutes } from "@/auth/constants";
import { User } from "@/auth/types";
import API from "@/common/api";
import { JobSectors } from "@/company/jobs/constants";
import {
  BrandLocationProfile,
  Company,
  CompanyAddress,
  CompanyGroup,
  CompanyPublicProfile,
  CompanySector,
  SearchCompanyProfile,
} from "@/company/types";
import { reverseSnakeCaseJobSectors } from "@/company/utils";
import { Lead } from "@/lead/types";
import { PublicRoutes } from "@/open/constants";
import { getS3KeysFromPresignedUrl, keysToSnakeCase } from "@/utils";

import { ONBOARDING_STAGES } from "../constants";

export interface BaseJWTToken {
  token: string;
  expires_at: Date;
}

export interface TokenResponse {
  access: BaseJWTToken;
  refresh: BaseJWTToken;
}

export interface UpdateCompanyRequestPayload {
  companyPayload: Company;
  companySectors?: Array<JobSectors>;
  logoFile?: any;
  isLogoDeleted?: boolean;
}

export interface UpdateAdminCompanyRequestPayload {
  company?: Partial<Company>;
  profile?: Partial<BrandLocationProfile>;
}

export interface CreateUserBulkRequestPayload {
  groupId: number;
  users: Array<Omit<User, "id">>;
}

export interface UpdateUserWithGroup {
  groupId: number;
  user: Partial<User>;
}

export interface LocationDetails {
  lat: number;
  lng: number;
  stateCode: string;
  zipCode?: string;
}

// TODO: Add proper response and option types to query and mutations
export function useQuerySearchCompanies(
  searchQuery: string,

  enabled = false
): UseQueryResult<Array<CompanyPublicProfile>> {
  const uri = "/companies/brand-locations/public";

  return useQuery({
    queryKey: [uri, searchQuery],
    queryFn: async () => {
      const response = await API.get(
        `${uri}?search=${encodeURIComponent(searchQuery)}`
      );
      return response.data.data;
    },
    enabled,
  });
}

export function useQuerySearchPublicCompanies(
  searchQuery: string,
  sectors?: Array<JobSectors>,
  zipCode?: string,
  enabled = false
): UseQueryResult<Array<CompanyPublicProfile>> {
  const uri = "/companies/brand-locations/public-profile";

  return useQuery({
    queryKey: [uri, searchQuery],
    queryFn: async () => {
      const response = await API.get(
        `${uri}?keyword=${encodeURIComponent(searchQuery)}${
          zipCode ? `&zip_code=${zipCode}` : ``
        }${
          sectors?.length
            ? `&${sectors?.map(sector => "sectors=" + sector).join("&")}`
            : ``
        }`
      );
      return response.data.data;
    },
    enabled,
  });
}

export function useQueryBrandLocationById(
  {
    id,
  }: {
    id: number;
  }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<SearchCompanyProfile> {
  const uri = "/companies/brand-locations/{brand_location_id}/public-profile";
  return useQuery({
    queryKey: [
      uri,
      {
        id,
      },
    ],
    queryFn: async () => {
      const response = await API.get(uri.replace("{brand_location_id}", id));
      const camelCaseResponse = response.data.data;
      return camelCaseResponse;
    },
    initialData: [],
    ...options,
  });
}

export function useQueryLocation(
  zipCode: string,
  enabled = false
): UseQueryResult<LocationDetails> {
  const uri = "/companies/location";
  return useQuery({
    queryKey: [
      uri,
      {
        zipCode,
      },
    ],
    queryFn: async () => {
      const {
        state,
        latitude: lat,
        longitude: lng,
      } = zipcodes.lookup(zipCode) || {};
      return { lat, lng, stateCode: state?.toLowerCase(), zipCode };
    },
    enabled,
    initialData: null,
  });
}
const deg2rad = function (value: number) {
  return value * 0.017453292519943295;
};
const haversine = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  // Retuns the great circle distance between two coordinate points in miles
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const radLat1 = deg2rad(lat1);
  const radLat2 = deg2rad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(radLat1) *
      Math.cos(radLat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return 3960 * c;
};

const lookupByCoords = (lat: number, lon: number) => {
  // Return the closest code to coordinates at lat,lon
  let minDist = Infinity;
  let minCode = null;
  for (const zip in codes) {
    const code = codes[zip];

    if (code.latitude && code.longitude) {
      const dist = haversine(lat, lon, code.latitude, code.longitude);
      if (dist < minDist) {
        minDist = dist;
        minCode = code;
      }
    }
  }
  return minCode;
};

export function useQueryLocationByCoords(
  latitude: number,
  longitude: number,
  enabled = false
): UseQueryResult<LocationDetails> {
  const uri = "/companies/location";
  return useQuery({
    queryKey: [`${uri}${latitude}${longitude}`],
    queryFn: async () => {
      const { state, zip = "" } = lookupByCoords(latitude, longitude) || {};
      return {
        lat: latitude,
        lng: longitude,
        stateCode: state?.toLowerCase(),
        zipCode: zip,
      };
    },
    enabled,
    initialData: null,
  });
}

export function useQueryBrandsUnderFamilyBrand(
  {
    familyBrandId,
    brandId,
    zipCode,
  }: {
    brandId?: number;
    familyBrandId?: number;
    zipCode?: string;
  },
  enabled = false
) {
  let uri = `/companies/family-of-brands/${familyBrandId}/brands`;
  if (zipCode && brandId) {
    uri += `?exclude_brand_id=${encodeURIComponent(
      brandId
    )}&zip_code=${zipCode}`;
  }

  return useQuery({
    queryKey: [
      uri,
      {
        brandId,
        zipCode,
        familyBrandId,
      },
    ],
    queryFn: async () => {
      const response = await API.get(uri);
      return response.data.data;
    },
    enabled,
  });
}

export function useQueryFetchCompanyGroups(
  companyId: number | undefined
): UseQueryResult<Array<CompanyGroup>> {
  const uri = `/groups`;

  return useQuery({
    queryKey: [uri, companyId],
    queryFn: async () => {
      const response = await API.get(uri);
      return response.data.data;
    },
    enabled: !!companyId,
  });
}

export function useQueryFetchCompanySectors(
  companyId: number | undefined
): UseQueryResult<Array<CompanySector>> {
  const uri = `/companies/${companyId}/sectors`;

  return useQuery({
    queryKey: [uri, companyId],
    queryFn: async () => {
      const response = await API.get(uri);
      return response.data.data;
    },
    enabled: !!companyId,
  });
}

export function useQueryFetchCompanyTerritory(
  companyId: number | undefined
): UseQueryResult<unknown> {
  const uri = `/companies/${companyId}/territory`;

  return useQuery({
    queryKey: [uri, companyId],
    queryFn: async () => {
      const response = await API.get(uri);
      return response.data.data.territory;
    },
    enabled: !!companyId,
  });
}

export function useQueryOnboardingFacts(onboardingStage: ONBOARDING_STAGES) {
  const uri = "/onboarding/facts";
  return useQuery({
    queryKey: [uri, onboardingStage],
    queryFn: async () => {
      const data: Record<
        ONBOARDING_STAGES,
        | {
            fact: {
              message: string;
              variant?: string;
              color?: string;
            };
            heading: {
              message: string;
              variant?: string;
              color?: string;
            };
            title?: string;
            isQuote?: boolean;
            author?: string;
            authorTitle?: string;
          }
        | undefined
      > = {
        [ONBOARDING_STAGES.ACCOUNT_TYPE]: {
          title: "Did you know?",
          fact: {
            message:
              "The probability of selling a new service to an existing customer is estimated to be 70%.",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          heading: {
            message:
              "TradeEngage expands your customer base by connecting you with affiliate brand customers needing your services.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
        [ONBOARDING_STAGES.COMPANY_PROFILE]: {
          isQuote: true,
          fact: {
            message:
              "Your brand should be driving the perception of superior service and value. So often, contractors hit a wall when trying to raise average tickets. Not because they don't perform a great service - but more because they simply DON'T LOOK LIKE THEY DO.",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "Dan Antonelli",
          authorTitle:
            "President of KickCharge® Creative and Author of Branded Not Blanded",
          heading: {
            message: "TradeEngage showcases your profile.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
        [ONBOARDING_STAGES.OPERATIONS_AND_SALES]: {
          isQuote: true,
          fact: {
            message:
              "If you’re trying to encourage and reinforce good work habits, try implementing a compensation system.",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "Jeremy Reets",
          authorTitle: "Owner of Reets Drying Academy",
          heading: {
            message: "TradeEngage implements a compensation system for you.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
        [ONBOARDING_STAGES.SERVICE_DETAILS]: {
          isQuote: true,
          fact: {
            message:
              "The owners in your area should be your new besties. Whoever was there first is going to have networking introductions and they can introduce you.",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "Tim Parmeter",
          authorTitle:
            "Founder & CEO of FranCoach and Host of the Franchising 101 Podcast",
          heading: {
            message: "TradeEngage finds those besties for you.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
        [ONBOARDING_STAGES.INBOUND_PREFERENCES]: {
          isQuote: true,
          fact: {
            message:
              "You're more likely to get a referral because there is a stronger impression of who you are and how great you are in your prospects' minds.",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "Rick Harshaw",
          authorTitle:
            "CEO of Level 10 Contractor and Author of Monopolize Your Marketplace",
          heading: {
            message: "TradeEngage boosts your profile.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
        [ONBOARDING_STAGES.OUTBOUND_PREFERENCES]: {
          isQuote: true,
          fact: {
            message:
              "The universal opportunity that I see contractors not always tapping into is that past work – existing customers that you’ve already done a job for – and how are you leveraging that list?",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "Anna Anderson",
          authorTitle:
            "CEO of Art Unlimited and Chair of National Women in Roofing",
          heading: {
            message: "TradeEngage follows up with homeowners for you.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
        [ONBOARDING_STAGES.JOB_PRICE]: {
          isQuote: true,
          fact: {
            message:
              "If you're receiving referrals and not following up, you're leaving money on the table. It's crucial to have a follow-up strategy to convert those referrals into customers.",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "James Dewane",
          authorTitle:
            "Founder of My Electricians Toolbox and Author of The S.P.A.R.K.S. Blueprint to Marketing for Local Trades",
          heading: {
            message: "TradeEngage strategizes for you.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
        [ONBOARDING_STAGES.TECHNICIAN_SPLIT]: {
          isQuote: true,
          fact: {
            message:
              "All technicians have the opportunity to offer and sell...All your technicians should be selling products and services that the customer needs.",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "Mike Treas",
          authorTitle: "Owner of The HVAC Sales Coach",
          heading: {
            message: "TradeEngage empowers your technicians to sell.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
        [ONBOARDING_STAGES.RECOMMENDED_PARTNERS]: {
          isQuote: true,
          fact: {
            message:
              "You need a great referral network of other contractors who will keep your customers happy and will actively reciprocate recommending you for the work you do.",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "Al Levi",
          authorTitle:
            "Business Consultant and Author of The 7-Power Contractor",
          heading: {
            message: "TradeEngage creates a great referral network for you.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
      };
      return data[onboardingStage];
    },
  });
}

export function useQueryAuthFacts(loginRoute: string) {
  const uri = "/auth/facts";
  return useQuery({
    queryKey: [uri, loginRoute],
    queryFn: async () => {
      const data: Record<
        string,
        | {
            fact: {
              message: string;
              variant?: string;
              color?: string;
            };
            heading: {
              message: string;
              variant?: string;
              color?: string;
            };
            title?: string;
            isQuote?: boolean;
            author?: string;
            authorTitle?: string;
          }
        | undefined
      > = {
        [AuthRoutes.USER_TYPE]: {
          isQuote: true,
          fact: {
            message:
              "Referrals are literally the EASIEST way to generate business. The close rate on referrals can be as high as 80% to even 90%.",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          heading: {
            message: "TradeEngage supercharges referrals for you.",
            variant: "h6",
            color: "#EDFCF2",
          },
          author: "Adam Bensman",
          authorTitle:
            "Host of The Roof Strategist podcast and Author of The Roofing Sales Survival Guide",
        },
        [PublicRoutes.FDD_COMPANY_IDENTIFIED]: {
          isQuote: true,
          fact: {
            message:
              "Imagine having an group of salespeople promoting your product or service, making you money while you sleep. 💰💤 The best businesses in 2024 will tap into this powerhouse strategy...But here’s the key: the real winners are those who pay their affiliates promptly.🕒",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "Tommy Mello",
          authorTitle:
            "Owner of A1 Garage Door Service and Host of the Home Service Expert podcast",
          heading: {
            message: "TradeEngage pays affiliates for you.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
        [PublicRoutes.IDENTIFY_EXTERNAL_COMPANY]: {
          isQuote: true,
          fact: {
            message:
              "Imagine having an group of salespeople promoting your product or service, making you money while you sleep. 💰💤 The best businesses in 2024 will tap into this powerhouse strategy...But here’s the key: the real winners are those who pay their affiliates promptly.🕒",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "Tommy Mello",
          authorTitle:
            "Owner of A1 Garage Door Service and Host of the Home Service Expert podcast",
          heading: {
            message: "TradeEngage pays affiliates for you.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
        [PublicRoutes.EXTERNAL_COMPANY_IDENTIFIED]: {
          isQuote: true,
          fact: {
            message:
              "Imagine having an group of salespeople promoting your product or service, making you money while you sleep. 💰💤 The best businesses in 2024 will tap into this powerhouse strategy...But here’s the key: the real winners are those who pay their affiliates promptly.🕒",
            variant: "subtitle1",
            color: "#F8F9FA",
          },
          author: "Tommy Mello",
          authorTitle:
            "Owner of A1 Garage Door Service and Host of the Home Service Expert podcast",
          heading: {
            message: "TradeEngage pays affiliates for you.",
            variant: "h6",
            color: "#EDFCF2",
          },
        },
      };
      return data[loginRoute];
    },
  });
}

export function useMutationCreateLead(
  options: UseMutationOptions<void, AxiosError, Lead> = {}
): UseMutationResult<void, AxiosError, Lead> {
  return useMutation({
    mutationFn: async (companyDetails?: Lead) => {
      const {
        id,
        name: company_name,
        zipCode: zip_code,
        sectors = [],
        email = "",
        phone = "",
        firstName: first_name = "",
        lastName: last_name = "",
        accountType: company_account_type,
        utmCampaign: utm_campaign,
        utmMedium: utm_medium,
        utmSource: utm_source,
      } = companyDetails ?? {};
      const uri = "/leads/";
      await API.post(uri, {
        id,
        company_name,
        zip_code,
        company_account_type,
        sectors,
        utm_campaign,
        utm_medium,
        utm_source,
        first_name,
        last_name,
        email,
        phone,
      });
    },
    ...options,
  });
}

export function useMutationCreateUsersBulk(
  options: UseMutationOptions<
    void,
    AxiosError,
    Array<CreateUserBulkRequestPayload>
  > = {}
): UseMutationResult<void, AxiosError, Array<CreateUserBulkRequestPayload>> {
  return useMutation({
    mutationFn: async (usersWithGroup: Array<CreateUserBulkRequestPayload>) => {
      const bulkUsersPayload = keysToSnakeCase({ usersWithGroup });
      const uri = `/groups/users/bulk`;
      await API.post(uri, bulkUsersPayload);
    },
    ...options,
  });
}

export function useMutationCreateGroupUsersBulk(
  options: UseMutationOptions<
    void,
    AxiosError,
    CreateUserBulkRequestPayload
  > = {}
): UseMutationResult<void, AxiosError, CreateUserBulkRequestPayload> {
  return useMutation({
    mutationFn: async (usersWithGroup: CreateUserBulkRequestPayload) => {
      const bulkUsersPayload = keysToSnakeCase(usersWithGroup.users);
      const uri = `/groups/${usersWithGroup.groupId}/users/bulk`;
      await API.post(uri, { users: bulkUsersPayload });
    },
    ...options,
  });
}

export function useMutationDeleteGroupUser(
  options: UseMutationOptions<
    void,
    AxiosError,
    { userId: number; groupId: number }
  > = {}
): UseMutationResult<void, AxiosError, { userId: number; groupId: number }> {
  return useMutation({
    mutationFn: async ({ userId, groupId }) => {
      const uri = `/groups/${groupId}/users/${userId}`;
      await API.delete(uri);
    },
    ...options,
  });
}

export function useMutationActivateUser(
  options: UseMutationOptions<
    void,
    AxiosError,
    { userId: number; groupId: number }
  > = {}
): UseMutationResult<void, AxiosError, { userId: number; groupId: number }> {
  return useMutation({
    mutationFn: async ({ userId, groupId }) => {
      const uri = `/groups/${groupId}/users/${userId}/activate`;
      await API.post(uri);
    },
    ...options,
  });
}

export function useTriggerProfileCompletenessNotification(
  options?: UseMutationOptions<void, AxiosError>
): UseMutationResult<void, AxiosError, void> {
  return useMutation({
    mutationFn: async () => {
      const uri = `/companies/profile-completeness`;
      await API.post(uri);
    },
    ...options,
  });
}

export function useMutationUpdateUserWithGroup(
  options: UseMutationOptions<void, AxiosError, UpdateUserWithGroup> = {}
): UseMutationResult<void, AxiosError, UpdateUserWithGroup> {
  return useMutation({
    mutationFn: async (userWithGroup: UpdateUserWithGroup) => {
      const bulkUsersPayload = keysToSnakeCase(userWithGroup.user);
      const uri = `/groups/${userWithGroup.groupId}/users/${userWithGroup.user.id}`;
      await API.patch(uri, bulkUsersPayload);
    },
    ...options,
  });
}

export function useUpdateCompany(
  companyId: number | undefined,
  options: UseMutationOptions<
    void,
    AxiosError,
    UpdateCompanyRequestPayload
  > = {}
): UseMutationResult<void, AxiosError, UpdateCompanyRequestPayload> {
  return useMutation({
    mutationFn: async (companyDetails: UpdateCompanyRequestPayload) => {
      let companyPayload = keysToSnakeCase(companyDetails.companyPayload);
      const updateCompanyUri = `/companies/${companyId}`;
      const updateCompanySectorsUri = `/companies/sectors/onboarding-bulk-create`;

      if (companyDetails.logoFile) {
        const imageMineType = companyDetails.logoFile.type.replace(
          "image/",
          ""
        );

        const UploadUrlResponse = await API.get(
          `/companies/logo-presigned-url?image_mime_type=${imageMineType}`
        );
        const uploadUrl = UploadUrlResponse.data.data;
        const { bucket: fssLogoBucket, objectKey: fssLogoKey } =
          getS3KeysFromPresignedUrl(uploadUrl);
        await axios.put(uploadUrl, companyDetails.logoFile);
        companyPayload.fssLogoBucket = fssLogoBucket;
        companyPayload.fssLogoKey = fssLogoKey;
      }
      if (companyDetails.isLogoDeleted) {
        companyPayload.fssLogoBucket = "";
        companyPayload.fssLogoKey = "";
      }
      companyPayload = keysToSnakeCase(companyPayload);
      const companyRequests = [API.patch(updateCompanyUri, companyPayload)];
      if (companyDetails.companySectors) {
        const companySectorsPayload = keysToSnakeCase(
          companyDetails.companySectors.map(
            sector => reverseSnakeCaseJobSectors[sector as JobSectors]
          )
        );
        companyRequests.push(
          API.post(updateCompanySectorsUri, { sectors: companySectorsPayload })
        );
      }
      await axios.all(companyRequests);
    },
    ...options,
  });
}

const createPatchRequests = (
  payloads: Array<{
    id: number;
    companyDetails: UpdateAdminCompanyRequestPayload;
  }>
) => {
  return payloads.map(({ id, companyDetails }) => {
    const companyPayload = changeCaseKeys(companyDetails, "underscored"); // Assuming you want to change key names to underscored
    const updateCompanyUri = `/internal-admin/companies/brand-location/${id}`;
    return API.patch(updateCompanyUri, companyPayload);
  });
};

export function useUpdateCompaniesById(
  options: UseMutationOptions<
    void,
    AxiosError,
    Array<{ id: number; companyDetails: UpdateAdminCompanyRequestPayload }>
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  Array<{ id: number; companyDetails: UpdateAdminCompanyRequestPayload }>
> {
  return useMutation({
    mutationFn: async payloads => {
      const patchRequests = createPatchRequests(payloads);
      await axios.all(patchRequests);
    },
    ...options,
  });
}
export function useUpdateBrandLocationProfile(
  brandLocationId: number | undefined,
  options: UseMutationOptions<void, AxiosError, BrandLocationProfile> = {}
): UseMutationResult<void, AxiosError, BrandLocationProfile> {
  return useMutation({
    mutationFn: async (brandLocationDetails: BrandLocationProfile) => {
      const companyPayload = keysToSnakeCase(brandLocationDetails);
      const updateBrandLocationUri = `/brand-location-profiles/${brandLocationId}`;
      await API.patch(updateBrandLocationUri, companyPayload);
    },
    ...options,
  });
}

export function useUpdateBrandLocationProfileById(
  options: UseMutationOptions<
    void,
    AxiosError,
    { id: number; payload: BrandLocationProfile }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { id: number; payload: BrandLocationProfile }
> {
  return useMutation({
    mutationFn: async ({ id, payload }) => {
      const companyPayload = keysToSnakeCase(payload);
      const updateBrandLocationUri = `/brand-location-profiles/${id}`;
      await API.patch(updateBrandLocationUri, companyPayload);
    },
    ...options,
  });
}

export function useUpdateCompanySectors(
  options: UseMutationOptions<
    void,
    AxiosError,
    Record<number, Array<string>>
  > = {}
): UseMutationResult<void, AxiosError, Record<number, Array<string>>> {
  return useMutation({
    mutationFn: async (companySectors: Record<number, Array<string>>) => {
      const updateSectorRequests = Object.keys(companySectors).map(sectorId => {
        return API.patch(`/companies/sectors/${sectorId}`, {
          service_types: companySectors[sectorId as unknown as number],
        });
      });
      await axios.all(updateSectorRequests);
    },
    ...options,
  });
}

export function useUpdateCompanyAddress(
  options: UseMutationOptions<void, AxiosError, CompanyAddress> = {}
): UseMutationResult<void, AxiosError, CompanyAddress> {
  return useMutation({
    mutationFn: async (companyAddressPayload: CompanyAddress) => {
      return API.patch(`/companies/address/`, {
        ...keysToSnakeCase(companyAddressPayload),
      });
    },
    ...options,
  });
}

export function useUpdateCompanyServiceDetails(
  companyId: number | undefined,
  options: UseMutationOptions<
    void,
    AxiosError,
    {
      companyDetails?: Company;
      sectors?: Array<JobSectors>;
      territories?: Array<unknown>;
    }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  {
    companyDetails?: Company;
    sectors?: Array<JobSectors>;
    territories?: Array<unknown>;
  }
> {
  return useMutation({
    mutationFn: async ({ companyDetails, territories, sectors }) => {
      const companyPayload = keysToSnakeCase(companyDetails);
      const updateCompanyUri = `/companies/v2/${companyId}`;
      // TODO: Add api to update all company details with 1 endpoint

      const requests = [
        ...(companyPayload
          ? [API.patch(updateCompanyUri, companyPayload)]
          : []),
        ...(sectors
          ? [
              API.post(`/companies/sectors/onboarding-bulk-create`, {
                sectors,
              }),
            ]
          : []),
      ];
      await axios.all(requests);
      if (territories?.length) {
        await API.patch(`/companies/${companyId}/territory`, { territories });
      }
    },
    ...options,
  });
}
