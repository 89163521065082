/* eslint-disable jsx-a11y/click-events-have-key-events */
import CloseIcon from "@mui/icons-material/Close";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Box, CardActions, CardHeader, Chip, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import converter from "number-to-words";
import { forwardRef, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { useReferralContext } from "@/company/context/referral-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import { JobSectors } from "@/company/jobs/constants";
import {
  PLATFORM_PRESENCE,
  Referral,
  ReferralInvitation,
  ReferralInvitationActions,
} from "@/company/types";

import { CompanyAvatar } from "../company-avatar/CompanyAvatar";
import { TabsEnum } from "../company-drawer/CompanyDrawer";
import { ReferralRemoveDialog } from "../referral-remove-dialog/ReferralRemoveDialog";
import { MoreMenuInvitation } from "./MoreMenuInvitation";
import { MoreMenuReferral } from "./MoreMenuReferral";

const isTypeReferral = (
  obj: Referral | ReferralInvitation
): obj is Referral => {
  return !!Object.hasOwn(obj, "order");
};

interface CompanyPartnerCardProps {
  orderId?: string;
  companyDetails: Referral | ReferralInvitation;
  jobSector: JobSectors;
  dragging?: boolean;
  onOpen?: (tab: TabsEnum) => void;
}

export const CompanyPartnerCard = forwardRef<
  HTMLDivElement,
  CompanyPartnerCardProps
>(function CompanyPartnerCard(props, ref) {
  const {
    orderId,
    dragging = false,
    onOpen,
    companyDetails,
    jobSector,
    ...other
  } = props;

  const { session } = useAuth();
  const company = session?.company;
  const familyOfBrands = session?.familyOfBrands;

  let otherCompanyProfile = companyDetails.otherCompanyProfile;

  const { data: companyProfileV2, isLoading } = useFetchCompanyProfile(
    otherCompanyProfile.id,
    {
      refetchOnWindowFocus: false,
    }
  );

  const action = (snackbarId: SnackbarKey) => (
    <Box display={"flex"} sx={{ mr: 1 }}>
      <CloseIcon
        sx={{
          color: "white",
          cursor: "pointer",
        }}
        fontSize="small"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      />
    </Box>
  );
  const { enqueueSnackbar } = useSnackbar();

  const { deleteReferral, refetchReferralPartners } = useReferralContext();

  const { updateReferralInvitation } = useReferralInvitationContext();

  const [referralRemovedDialogOpen, setReferralRemovelDialogOpen] =
    useState(false);

  if (!isLoading) {
    otherCompanyProfile = companyProfileV2!;
  }

  return (
    <>
      <Card
        elevation={dragging ? 8 : 1}
        sx={{
          ...(dragging && {
            backgroundColor: theme =>
              theme.palette.mode === "dark"
                ? "neutral.800"
                : "background.paper",
          }),
          px: 2,
          pt: 1,
          pb: 2,
          userSelect: "none",
          "&:hover": {
            backgroundColor: theme =>
              theme.palette.mode === "dark" ? "neutral.700" : "neutral.50",
          },
          "&.MuiPaper-elevation1": {
            boxShadow: 1,
          },
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          ...(isTypeReferral(companyDetails)
            ? {
                backgroundColor: theme =>
                  theme.palette.mode === "dark"
                    ? "neutral.800"
                    : "background.paper",
              }
            : { backgroundColor: "neutral.100", border: "1px dashed #B5BCC4" }),
        }}
        {...other}
        ref={ref}
      >
        <CardHeader
          sx={{ p: 0, m: 0 }}
          action={
            isTypeReferral(companyDetails) ? (
              <MoreMenuReferral
                onViewProfile={() => onOpen?.(TabsEnum.OVERVIEW)}
                onRemovePartnerShip={() => {
                  setReferralRemovelDialogOpen(true);
                }}
              />
            ) : (
              <MoreMenuInvitation
                onViewProfile={() => onOpen?.(TabsEnum.OVERVIEW)}
                {...(company?.id === companyDetails.invitedCompanyId
                  ? {
                      onAcceptInvitation: async () => {
                        await updateReferralInvitation({
                          invitationId: companyDetails.id,
                          action: ReferralInvitationActions.ACCEPT,
                        });
                        refetchReferralPartners();
                      },
                    }
                  : {})}
              />
            )
          }
          title={
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              gap={1}
              alignItems={"center"}
            >
              <Chip
                size="small"
                {...(isTypeReferral(companyDetails) && orderId
                  ? {
                      color: "primary",
                      label: converter.toOrdinal(orderId),
                      sx: { bgcolor: "#16DB65" },
                    }
                  : {
                      label: (
                        <Typography
                          variant="subtitle1"
                          fontSize="12px"
                          fontWeight={500}
                          color="text.secondary"
                        >
                          pending
                        </Typography>
                      ),
                    })}
                variant="filled"
              />
              {otherCompanyProfile.familyOfBrands?.id &&
              otherCompanyProfile.familyOfBrands?.id === familyOfBrands?.id ? (
                <Chip
                  size="small"
                  color="primary"
                  icon={
                    <StarBorderOutlinedIcon
                      sx={{ color: "#16DB65 !important" }}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      fontSize="10px"
                      fontWeight={500}
                      color="text.secondary"
                    >
                      Preferred Provider
                    </Typography>
                  }
                  sx={{
                    bgcolor: "neutral.100",
                    width: "fit-content",
                  }}
                  variant="filled"
                />
              ) : null}
            </Box>
          }
        />
        <Box my={1}>
          <CompanyAvatar
            height={"40px"}
            maxWidth="200px"
            logo={otherCompanyProfile?.logoUrl}
            name={otherCompanyProfile?.name ?? ""}
          />
        </Box>
        <Typography variant="h6">{otherCompanyProfile?.name}</Typography>
        <CardActions
          sx={{ display: "flex", justifyContent: "space-between", p: 1, pb: 0 }}
        >
          {companyDetails.otherCompanyProfile?.platformPresence ===
          PLATFORM_PRESENCE.ON_PLATFORM ? (
            <TimelineIcon
              color="action"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => onOpen?.(TabsEnum.ACTIVITY)}
            />
          ) : null}
          <MapsHomeWorkOutlinedIcon
            color="action"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => onOpen?.(TabsEnum.OVERVIEW)}
          />
          <StorefrontOutlinedIcon
            color="action"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => onOpen?.(TabsEnum.GOOGLE_PROFILE)}
          />
          {session?.groups?.some(isAdministratorOrOwner) ? (
            //eslint-disable-next-line jsx-a11y/click-events-have-key-events
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <img
              src="/message-dots-circle.svg"
              alt="chat message icon"
              style={{
                cursor: "pointer",
              }}
              onClick={() => onOpen?.(TabsEnum.MESSAGE)}
            />
          ) : null}
        </CardActions>
      </Card>
      <ReferralRemoveDialog
        isOpen={referralRemovedDialogOpen}
        companyName={companyDetails.otherCompanyProfile.name ?? ""}
        onRemove={async (reason: string) => {
          try {
            await deleteReferral(companyDetails.id, reason);
            enqueueSnackbar({
              message: `Successfully removed ${companyDetails.otherCompanyProfile.name}.`,
              variant: "success",
            });
          } catch (error) {
            enqueueSnackbar("Unable to delete referral partner.", {
              variant: "error",
            });
          }
          setReferralRemovelDialogOpen(false);
        }}
        onCancel={() => setReferralRemovelDialogOpen(false)}
      />
    </>
  );
});
