import { QuestionAnswerOutlined as QuestionAnswerOutlinedIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Rating,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { CustomToolbar } from "@/internal-admin/utils";
import { ConfirmationDialog } from "@/lib/dialogs/ConfirmationDialog";
import { useModalState } from "@/lib/modals/useModalState";
import { isDefined } from "@/utils/isDefined";

import { FacilitateConnectionDialog } from "../components/FacilitateConnectionDialog";
import { GoogleRatingsFilterForm } from "../components/GoogleRatingsFilterForm";
import { GoogleReviewsFilterForm } from "../components/GoogleReviewsFilterForm";
import {
  StrategicPartnerFiltersProvider,
  useStrategicPartnerFiltersContext,
} from "../hooks/useStrategicPartnerFilters";
import { FilterType, StrategicPartner } from "../types";

function StrategicPartners() {
  const facilitateConnectionModalState = useModalState();
  const { session } = useAuth();

  const {
    handleStrategicPartnerSelection,
    handleConfirmationDialogClose,
    confirmationDialogState,
    handleConfirmationDialogConfirm,
    strategicPartners,
    isLoadingStrategicPartners,
    selectedStrategicPartnerIds,
    selectedStrategicPartner,
    setSelectedStrategicPartner,
    googleRatingsFilter,
    googleReviewsFilter,
    handleGoogleRatingsFilterChange,
    handleGoogleReviewsFilterChange,
  } = useStrategicPartnerFiltersContext();

  const [googleRatingsFilterAnchorEl, setGoogleRatingsFilterAnchorEl] =
    useState<HTMLElement | null>(null);

  const [googleReviewsFilterAnchorEl, setGoogleReviewsFilterAnchorEl] =
    useState<HTMLElement | null>(null);

  const apiRef = useGridApiRef();

  const columns: GridColDef<StrategicPartner>[] = [
    {
      field: "businessName",
      headerName: "ROLLINS LOCATIONS",
      width: 500,
      valueGetter: (_value, row) => row.company.name,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1}
          >
            <CompanyAvatar
              logo={params.row.company.logoUrl}
              name={params.row.company.name}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
            <Typography variant="body2">{params.row.company.name}</Typography>
          </Box>
        );
      },
    },
    {
      field: "googleReviewsRating",
      headerName: "GOOGLE RATING",
      width: 200,
      type: "number",
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1.5}
          >
            <Typography variant="subtitle2" fontWeight={500}>
              {params.row?.googleReviewsRating?.toFixed(1)}
            </Typography>
            <Rating
              readOnly
              name="google-rating"
              defaultValue={params.row?.googleReviewsRating}
              value={params.row?.googleReviewsRating}
              precision={0.1}
            />
          </Box>
        );
      },
      headerAlign: "left",
    },
    {
      field: "googleReviewsCount",
      headerName: "GOOGLE REVIEWS",
      width: 150,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "googlePlacesId",
      headerName: "GOOGLE PLACES ID",
      width: 200,
      type: "string",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 300,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <Switch
            key={`${id}-facilitate-connection-switch`}
            checked={selectedStrategicPartnerIds.includes(row.company.id)}
            size="small"
            onChange={(_event, checked) => {
              handleStrategicPartnerSelection(row, checked);
            }}
          />,
          <Button
            key={`${id}-facilitate-connection`}
            sx={{
              paddingY: 0.5,
              paddingX: 1.5,
              "&.Mui-disabled": {
                color: "text.disabled",
              },
            }}
            size="small"
            variant="outlined"
            startIcon={<QuestionAnswerOutlinedIcon />}
            disabled={!selectedStrategicPartnerIds.includes(row.company.id)}
            onClick={() => {
              setSelectedStrategicPartner(row);
              facilitateConnectionModalState.openModal();
            }}
          >
            Facilitate Connection
          </Button>,
        ];
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          overflow: "auto",
          flexDirection: "column",
          paddingY: 0,
          paddingX: 2,
        }}
      >
        <Box
          sx={{
            top: 0,
            paddingY: 2,
          }}
          position={"sticky"}
          display={"flex"}
          bgcolor={"white"}
          zIndex={1200}
          flexDirection={"column"}
          width={"100%"}
          gap={1}
        >
          <Grid
            container
            sx={{
              top: 0,
              zIndex: 400,
            }}
            position="sticky"
            bgcolor="white"
          >
            <Grid item xs={12} lg={10}>
              <Typography gutterBottom variant="h5">
                {session?.company?.name} Permissions
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary">
                Manage your branch locations from here. Control the access to
                strategic partners and facilitate introductions!
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Stack
          direction={"row"}
          justifyContent={"start"}
          gap={2}
          marginBottom={1}
        >
          <Button
            size="small"
            variant="outlined"
            startIcon={
              <Switch
                size="small"
                checked={googleRatingsFilter.enabled}
                color="primary"
              />
            }
            onClick={event => {
              if (!googleRatingsFilter.enabled) {
                setGoogleRatingsFilterAnchorEl(event.currentTarget);
              } else {
                handleGoogleRatingsFilterChange({
                  ...googleRatingsFilter,
                  enabled: false,
                });
              }
            }}
          >
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Typography variant="subtitle2">Google Rating</Typography>
              {googleRatingsFilter.enabled && (
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={{ maxWidth: 200, textAlign: "left" }}
                >
                  {googleRatingsFilter.minValue} Stars
                </Typography>
              )}
            </Stack>
          </Button>
          <Button
            size="small"
            variant="outlined"
            startIcon={
              <Switch
                size="small"
                checked={googleReviewsFilter.enabled}
                color="primary"
              />
            }
            onClick={event => {
              if (!googleReviewsFilter.enabled) {
                setGoogleReviewsFilterAnchorEl(event.currentTarget);
              } else {
                handleGoogleReviewsFilterChange({
                  ...googleReviewsFilter,
                  enabled: false,
                });
              }
            }}
          >
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Typography variant="subtitle2">Number of Reviews</Typography>
              {googleReviewsFilter.enabled && (
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={{ maxWidth: 200, textAlign: "left" }}
                >
                  {googleReviewsFilter.minValue} Reviews
                </Typography>
              )}
            </Stack>
          </Button>
        </Stack>
        <DataGridPremium
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          pagination
          disableAggregation
          disableRowGrouping
          columns={columns}
          loading={isLoadingStrategicPartners}
          rows={strategicPartners ?? []}
          sx={{
            "& .MuiDataGrid-row": {
              "--rowBorderColor": "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withRightBorder": {
              borderRightColor: "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withLeftBorder": {
              borderLeftColor: "#D9D9D9",
            },
            "& .MuiDataGrid-topContainer": {
              background: "#F8F9FA",
            },
          }}
          apiRef={apiRef}
          initialState={{
            pinnedColumns: {
              left: ["businessName"],
              right: ["actions"],
            },
            density: "compact",
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 25,
              },
            },
          }}
          slots={{
            toolbar: () => (
              <CustomToolbar
                fileName="StrategicPartners"
                includeQuickFilter={true}
              />
            ),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          getRowId={row => row.company.id}
        />
      </Box>
      {isDefined(googleRatingsFilterAnchorEl) && (
        <GoogleRatingsFilterForm
          anchorEl={googleRatingsFilterAnchorEl}
          minValue={googleRatingsFilter.minValue}
          onSubmit={value => {
            handleGoogleRatingsFilterChange({
              enabled: true,
              minValue: value,
            });
            setGoogleRatingsFilterAnchorEl(null);
          }}
          onClose={() => {
            setGoogleRatingsFilterAnchorEl(null);
          }}
        />
      )}
      {isDefined(googleReviewsFilterAnchorEl) && (
        <GoogleReviewsFilterForm
          anchorEl={googleReviewsFilterAnchorEl}
          minValue={googleReviewsFilter.minValue}
          onSubmit={value => {
            handleGoogleReviewsFilterChange({
              enabled: true,
              minValue: value,
            });
            setGoogleReviewsFilterAnchorEl(null);
          }}
          onClose={() => {
            setGoogleReviewsFilterAnchorEl(null);
          }}
        />
      )}
      {isDefined(selectedStrategicPartner) &&
        facilitateConnectionModalState.modalIsOpen && (
          <FacilitateConnectionDialog
            modalState={facilitateConnectionModalState}
            selectedStrategicPartner={selectedStrategicPartner}
          />
        )}
      {isDefined(selectedStrategicPartner) && (
        <ConfirmationDialog
          open={confirmationDialogState.isOpen}
          handleClose={handleConfirmationDialogClose}
          handleConfirm={handleConfirmationDialogConfirm}
          text={`Turning this company ${
            confirmationDialogState.isTurningOn ? "on" : "off"
          } will override your Google ${
            confirmationDialogState.unmetFilter === FilterType.GOOGLE_REVIEWS
              ? "Reviews"
              : "Rating"
          } requirements. Are you sure?`}
          title="Head's up!"
          confirmText={`Yes, ${
            confirmationDialogState.isTurningOn ? "Turn On" : "Turn Off"
          }`}
          cancelText="No, Cancel"
        />
      )}
    </>
  );
}

export function StrategicPartnersPage() {
  return (
    <StrategicPartnerFiltersProvider>
      <StrategicPartners />
    </StrategicPartnerFiltersProvider>
  );
}
