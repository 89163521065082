import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { ElementType } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import {
  useMutationUpdateUserWithGroup,
  useQueryFetchCompanyGroups,
} from "@/company/onboarding/api";
import { DefaultCompanyGroups } from "@/company/types";
import { tradeEngageColors } from "@/Theme/colors";
import {
  getUSPhoneValidationSchema,
  NAME_REGEX,
  snakeCaseToTitleCase,
} from "@/utils";

import { STAFF_TYPES, StaffData } from "./types";
import {
  getCompanyGroupIdFromName,
  getRolesFromStaffType,
  getToolTipInfoFromGroupName,
} from "./utils";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .matches(NAME_REGEX, "Enter a valid name")
    .required("First Name is required"),
  lastName: yup
    .string()
    .matches(NAME_REGEX, "Enter a valid name")
    .required("Last Name is required"),
  email: yup
    .string()
    .email("Work Email Address must be a valid email address")
    .optional(),
  cellPhone: getUSPhoneValidationSchema(),
  role: yup.string().required("Role is required"),
});

export function EditStaffModal({
  isOpen,
  onSave,
  onClose,
  staffType,
  staffData,
}: {
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
  staffType: STAFF_TYPES;
  staffData: StaffData & {
    staffType: STAFF_TYPES;
  };
}) {
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { session, refreshSession } = useAuth();
  const { data: companyGroups, isLoading: fetchingCompanyGroups } =
    useQueryFetchCompanyGroups(session?.company?.id);
  const { mutateAsync: updateUserWithGroup, isLoading: updatingUser } =
    useMutationUpdateUserWithGroup({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating team members.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        refreshSession();
        onSave();
        formik.setValues({
          firstName: "",
          lastName: "",
          email: "",
          cellPhone: "",
          role:
            staffType == STAFF_TYPES.FIELD_TEAM ? STAFF_TYPES.FIELD_TEAM : "",
        });
        enqueueSnackbar({
          message: "Updated user successfully.",
          variant: "success",
        });
      },
    });

  const formik = useFormik({
    initialValues: {
      firstName: staffData.firstName ?? "",
      lastName: staffData.lastName ?? "",
      email: staffData.email ?? "",
      cellPhone: staffData?.cellPhone?.replace("+1", "") ?? "",
      role:
        staffType == STAFF_TYPES.FIELD_TEAM
          ? DefaultCompanyGroups.FIELD_TEAM
          : staffData.role ?? "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      const { firstName, lastName, email, cellPhone, role } = values;
      if (!companyGroups) return;
      const newRole =
        role === DefaultCompanyGroups.FIELD_TEAM &&
        staffType == STAFF_TYPES.FIELD_TEAM &&
        staffData.role === DefaultCompanyGroups.ADMINISTRATOR
          ? staffData.role
          : role;
      updateUserWithGroup({
        groupId: getCompanyGroupIdFromName(
          companyGroups,
          newRole?.replaceAll(" ", "_").toUpperCase() as DefaultCompanyGroups
        ),
        user: {
          id: staffData.id,
          firstName,
          lastName,
          email,
          cellPhone: `+1${cellPhone?.replace(/[\s()-]/g, "")}`,
          type: USER_TYPE.COMPANY_TEAM_MEMBER,
          jobTitle: newRole
            ?.replaceAll(" ", "_")
            .toUpperCase() as DefaultCompanyGroups,
        },
      });
    },
  });

  const onHandleOk = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      sx={{ m: { xs: -3, md: 0 } }}
      onClose={() => onClose()}
    >
      <Box
        component={"form"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          ...(isMobile ? { p: "24px 16px" } : { p: 3 }),
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            gap: 3,
            pb: 3,
          }}
        >
          <Box width={"360px"}>
            <Typography
              gutterBottom
              variant="h5"
              lineHeight={1}
              fontWeight={600}
            >
              Edit Team Member
            </Typography>
            <Typography variant="body2" color="text.secondary">
              The invitation will be resent only if there are changes to the
              mobile number or work email address
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            sx={{
              color: theme => theme.palette.grey[500],
              p: 0,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <FormControl fullWidth>
          <InputLabel id="role-select-label">Select Role*</InputLabel>
          <Select
            labelId="role-select-label"
            id="role"
            name="role"
            sx={{ width: "100%" }}
            value={formik.values.role}
            label="Select Role*"
            renderValue={value => (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"50%"}
              >
                {
                  <Typography sx={{ width: { xs: 1 / 2 }, fontSize: "14px" }}>
                    {snakeCaseToTitleCase(value.toString())}
                  </Typography>
                }
              </Box>
            )}
            error={formik.touched.role && Boolean(formik.errors.role)}
            onChange={formik.handleChange}
          >
            {getRolesFromStaffType(STAFF_TYPES.OPERATIONS).map(role => (
              <MenuItem key={role} value={role}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                  p={0}
                >
                  {
                    <Typography>
                      {snakeCaseToTitleCase(role.toString())}
                    </Typography>
                  }
                  <ToolTip
                    title={snakeCaseToTitleCase(role.toString())}
                    message={getToolTipInfoFromGroupName(role)}
                    placement="right"
                  >
                    <HelpOutlineIcon
                      sx={{ color: tradeEngageColors.darkBlue }}
                      fontSize="small"
                    />
                  </ToolTip>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: isMobile ? "column" : "row",
            width: "100%",
          }}
        >
          <TextField
            required
            sx={{ width: { xs: 1, lg: 1 / 2 }, fontSize: "12px" }}
            InputLabelProps={{ style: { fontSize: "12px" } }}
            id="firstName"
            name="firstName"
            label="First Name"
            value={formik.values.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <TextField
            required
            sx={{ width: { xs: 1, md: 1 / 2 }, fontSize: "12px" }}
            InputLabelProps={{ style: { fontSize: "12px" } }}
            id="lastName"
            name="lastName"
            label="Last Name"
            value={formik.values.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Box>
        <TextField
          required
          fullWidth
          disabled={
            formik.values.cellPhone?.replace(/[\s()-]/g, "") ===
            session?.user?.cellPhone?.replace("+1", "")
          }
          id="cellPhone"
          name="cellPhone"
          label="Mobile Number"
          sx={{ fontSize: "12px" }}
          InputLabelProps={{ style: { fontSize: "12px" } }}
          InputProps={{
            inputComponent: MaskedPhoneInput as unknown as
              | ElementType<InputBaseComponentProps>
              | undefined,
          }}
          value={formik.values.cellPhone}
          error={formik.touched.cellPhone && Boolean(formik.errors.cellPhone)}
          helperText={
            formik.values.cellPhone?.replace(/[\s()-]/g, "") ===
            session?.user?.cellPhone?.replace("+1", "")
              ? "Contact us to update this."
              : formik.touched.cellPhone && formik.errors.cellPhone
          }
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Work Email Address"
          sx={{ fontSize: "12px" }}
          InputLabelProps={{ style: { fontSize: "12px" } }}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Stack
          spacing={2}
          direction="row"
          textAlign={"center"}
          width={"100%"}
          mt={2}
        >
          <Button
            fullWidth
            variant="contained"
            disabled={updatingUser || fetchingCompanyGroups}
            onClick={onHandleOk}
          >
            Update
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
