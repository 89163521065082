import "./styles.css"; // Import CSS file

import { Close as CloseIcon } from "@mui/icons-material";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Backdrop, Button, Tooltip, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { CompanyRoutes } from "@/company/types";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { PublicRoutes } from "@/open/constants";
import { neutral, tradeEngageColors } from "@/Theme/colors";

import { useJobCampaignTourContext } from "../job-campaign-context";
import { useJobUploadContext } from "../job-upload-context";
import { NavLink } from "../layouts/DashboardLayout";
import { useShowToolTipContext } from "../show-tool-tip";
import { getNavigationPathFromRoutePaths } from "../utils/getNavigationPathFromRoutePaths";

const StyledFab = styled(Fab)({
  position: "fixed",
  zIndex: 1,
  bottom: 50,
  left: 0,
  right: 0,
  width: "75px",
  height: "75px",
  margin: "0 auto",
  color: neutral[50],
  backgroundColor: tradeEngageColors.green,
  // borderRadius: "50%",
  // border: "25px solid #F0F0F0",
});

export function BottomNavigationAction(props: NavLink): JSX.Element {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const { icon, label, link } = props;

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      color={props.link === pathname ? tradeEngageColors.green : "disabled"}
      py={2}
      width={"75px"}
      {...(link !==
      getNavigationPathFromRoutePaths([
        PublicRoutes.BASE,
        PublicRoutes.CONTACT_SUPPORT,
      ])
        ? { onClick: () => navigate(link) }
        : { onClick: () => setContactSupportModalOpen(true) })}
    >
      {icon}
      <Typography color="inherit" variant="caption" mt={1.5}>
        {label}
      </Typography>
    </Box>
  );
}

export interface BottomAppBarProps {
  navLinks: [NavLink, NavLink, NavLink, NavLink];
  onClickAction: () => void;
  actionIcon?: ReactNode;
}

const actions = [
  {
    icon: (
      <BackupOutlinedIcon
        sx={{
          bgcolor: "#16B364",
          color: "white",
          width: "32px",
          height: "32px",
          padding: "6px",
          borderRadius: "50%",
        }}
        color="primary"
      />
    ),
    name: "Upload Job Video",
  },
  {
    icon: (
      <WhatsAppIcon
        sx={{
          bgcolor: "#16B364",
          color: "white",
          width: "32px",
          height: "32px",
          padding: "6px",
          borderRadius: "50%",
        }}
      />
    ),
    name: "Submit via WhatsApp",
  },
];
export default function BottomAppBar(props: BottomAppBarProps) {
  const { navLinks, onClickAction, actionIcon } = props;
  const { session } = useAuth();
  const { jobUploadOpen, setJobUploadOpen } = useJobUploadContext();
  const { showToolTip, setShowToolTip } = useShowToolTipContext();
  const {
    campaignTourOpen,
    setCampaignTourOpen,
    campaignProfile,
    setCampaignProfile,
  } = useJobCampaignTourContext();
  const { pathname } = useLocation();

  const handleOpen = () => setJobUploadOpen(true);
  const handleClose = () => setJobUploadOpen(false);

  return (
    <AppBar
      position="fixed"
      sx={{
        height: "86px",
        top: "auto",
        bottom: 0,
        color: neutral[400],
        bgcolor: tradeEngageColors.darkBlue,
        px: 0,
      }}
    >
      <Toolbar sx={{ px: 0 }}>
        <Box
          alignSelf={"left"}
          width="50%"
          pl={2}
          pr={3.5}
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {[0, 1].map(index => (
            <BottomNavigationAction
              key={index}
              label={navLinks[index].label}
              icon={navLinks[index].icon}
              link={navLinks[index].link}
            />
          ))}
        </Box>
        {/* {session?.user?.type === USER_TYPE.HOMEOWNER ? ( */}
        {pathname.includes(
          getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.EXPLORE_PARTNERS,
          ])
        ) ? (
          <Tooltip
            arrow
            open={showToolTip}
            title={
              <Box display={"flex"} maxWidth={"140px"}>
                <Typography variant="caption" lineHeight={1.5}>
                  Invite your network through email or text message here!
                </Typography>
                <CloseIcon
                  fontSize="small"
                  sx={{
                    p: 0,
                    width: "16px",
                    height: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowToolTip(false);
                  }}
                />
              </Box>
            }
            componentsProps={{
              tooltip: {
                className: "bounce-tooltip", // Apply the animation class here
              },
              arrow: {
                style: { color: "#16b364" }, // Change the arrow color to match the tooltip background
              },
            }}
            placement="top"
          >
            <StyledFab
              aria-label="add"
              onClick={() => {
                onClickAction();
                setShowToolTip(false);
              }}
            >
              {/* {actionIcon ?? <AddIcon />} */}
              <img src="/mobile-action-icon.svg" alt="Submit a new project" />
            </StyledFab>
          </Tooltip>
        ) : campaignTourOpen ? (
          <>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: theme => theme.zIndex.drawer + 1,
              }}
              open={campaignTourOpen}
              onClick={() => {
                setCampaignTourOpen(false);
              }}
            ></Backdrop>

            <Box
              position="fixed"
              // bottom={"12px"}
              bottom={"40px"}
              right={"48px"}
              //  right={"100px"}
              sx={{
                ...(campaignTourOpen
                  ? { zIndex: theme => theme.zIndex.drawer + 1, color: "#fff" }
                  : {}),
              }}
            >
              <Backdrop open={campaignTourOpen} />
              <Tooltip
                disableFocusListener
                placement="top"
                open={campaignTourOpen}
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: "white",
                      backgroundColor: "#16B364",
                      bottom: "0",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#16B364",
                    },
                  },
                }}
                arrow={true}
                title={
                  <Box
                    display={"flex"}
                    p={2}
                    gap={1}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"start"}
                    sx={{
                      borderRadius: "6px",

                      background: "#16B364",
                    }}
                  >
                    <Typography
                      fontSize={"15px"}
                      fontWeight={600}
                      color="white"
                    >
                      Refer Your First Job
                    </Typography>
                    <Typography variant="subtitle2" color="white">
                      Record a video of a job opportunity and upload your video
                      {campaignProfile?.brand?.name
                        ? ` for ${campaignProfile?.brand?.name}`
                        : ""}
                      .
                    </Typography>
                    <Button
                      size="small"
                      sx={{
                        color: "#FFF",
                        bgcolor: "#16B364",
                        border: "1px solid #FFF",
                      }}
                      onClick={() => {
                        setCampaignTourOpen(false);
                        setCampaignProfile(undefined);
                      }}
                    >
                      Got it
                    </Button>
                  </Box>
                }
              >
                <StyledFab
                  aria-label="add"
                  onClick={() => {
                    onClickAction();
                    setCampaignTourOpen(false);
                    setCampaignProfile(undefined);
                  }}
                >
                  {/* {actionIcon ?? <AddIcon />} */}
                  <img
                    src="/mobile-action-icon.svg"
                    alt="Submit a new project"
                  />
                </StyledFab>
              </Tooltip>
            </Box>
          </>
        ) : (
          <StyledFab aria-label="add" onClick={onClickAction}>
            {/* {actionIcon ?? <AddIcon />} */}
            <img src="/mobile-action-icon.svg" alt="Submit a new project" />
          </StyledFab>
        )}

        <Box
          alignSelf={"right"}
          width="50%"
          pl={3.5}
          pr={2}
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {[2, 3].map(index => (
            <BottomNavigationAction
              key={index}
              label={navLinks[index].label}
              icon={navLinks[index].icon}
              link={navLinks[index].link}
            />
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
