import { LoadingButton } from "@mui/lab";
import { Box, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUpdateUserType } from "@/auth/api/useUpdateUserType";
import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { Seo } from "@/common/Seo/Seo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { OnboardingContainer } from "@/company/onboarding/components/container/OnboardingContainer";
import { OnboardingSteps } from "@/company/onboarding/components/utils";
import { ONBOARDING_STAGES } from "@/company/onboarding/constants";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";
import { HomeownerRoutes } from "@/homeowner/constants";

import { AccountTypeSelect } from "./AccountTypeSelect";

export function UserTypePage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { session, updateToken } = useAuth();

  const [selectedAccountType, setSelectedAccountType] = useState<
    USER_TYPE.HOMEOWNER | COMPANY_ACCOUNT_TYPES
  >(
    session?.user?.type === USER_TYPE.HOMEOWNER
      ? USER_TYPE.HOMEOWNER
      : session?.company?.accountType ?? COMPANY_ACCOUNT_TYPES.BRAND_LOCATION
  );

  const { isLoading: isUpdatingUserType, mutateAsync: updateUserType } =
    useUpdateUserType({
      onError() {
        enqueueSnackbar({
          message: "Error while updating company details.",
          variant: "error",
        });
      },
    });

  const onNext = async () => {
    try {
      const dataToUpdate = {
        userType:
          selectedAccountType === USER_TYPE.HOMEOWNER
            ? USER_TYPE.HOMEOWNER
            : USER_TYPE.COMPANY_TEAM_MEMBER,
        companyAccountType:
          selectedAccountType === USER_TYPE.HOMEOWNER
            ? undefined
            : selectedAccountType,
      };
      const data = await updateUserType(dataToUpdate);
      await updateToken(data);
      if (selectedAccountType === USER_TYPE.HOMEOWNER) {
        navigate(
          getNavigationPathFromRoutePaths([
            HomeownerRoutes.BASE,
            HomeownerRoutes.JOBS_LIST,
          ])
        );
      } else {
        trackEvent(AnalyticsEvents.COMPANY_ONBOARDING_ACCOUNT_TYPE_ADDED, {
          companyId: session?.company?.id,
          companyAccountType: selectedAccountType,
        });
        navigate(OnboardingSteps[ONBOARDING_STAGES.ACCOUNT_TYPE].nextStepRoute);
      }
    } catch {
      enqueueSnackbar({
        message: "Unable to Save Changes.",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Seo title="Account Type" />
      <OnboardingContainer
        heading="Who Are You Onboarding?"
        subheading="Select the option that best describes you or your business."
      >
        <Box
          sx={{
            display: "flex",
            padding: "32px 24px",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
            alignSelf: "stretch",
            background: "#F3F4F6",
            overflow: "auto",
          }}
        >
          <AccountTypeSelect
            selectedAccountType={selectedAccountType}
            onSelect={(accountType: COMPANY_ACCOUNT_TYPES) => {
              setSelectedAccountType(accountType);
            }}
          />
        </Box>
        <Stack
          spacing={2}
          mt={3}
          mb={2}
          mx={2}
          direction={"row"}
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "end",
            alignSelf: "stretch",
          }}
        >
          <LoadingButton
            loading={isUpdatingUserType}
            disabled={isUpdatingUserType}
            variant="contained"
            onClick={onNext}
          >
            Continue
          </LoadingButton>
        </Stack>
      </OnboardingContainer>
    </>
  );
}
