import {
  Box,
  Button,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { isDefined } from "@/utils/isDefined";

interface JobUploadButtonProps {
  isCreatingJob: boolean;
  mediaType: JobUploadTypes;
  onClickGenerateJob: () => void;
  transcription: string | undefined;
  videoFile: File | string | null;
}

export function JobUploadButton(props: JobUploadButtonProps) {
  const {
    isCreatingJob,
    mediaType,
    onClickGenerateJob,
    transcription,
    videoFile,
  } = props;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  if (isCreatingJob) {
    return (
      <Box display={"flex"} flexDirection={"column"} py={3}>
        <Typography variant="subtitle1">
          Uploading Your{" "}
          {mediaType === JobUploadTypes.VIDEO
            ? "Video"
            : mediaType === JobUploadTypes.AUDIO
            ? "Audio"
            : "Summary"}
        </Typography>
        <Typography variant="subtitle1" fontSize={"10px"} fontWeight={500}>
          Please do not close this window.
        </Typography>
        <LinearProgress sx={{ width: "100%", mt: 2 }} />
      </Box>
    );
  }

  if (
    !isDefined(videoFile) &&
    (mediaType === JobUploadTypes.VIDEO || mediaType === JobUploadTypes.AUDIO)
  ) {
    return null;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mt={mediaType === JobUploadTypes.TEXT ? 0 : 2}
      >
        <Button
          sx={{
            mr: 1,
            borderRadius: "8px",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
          }}
          disabled={mediaType === JobUploadTypes.TEXT && !transcription}
          size={isMobile ? "small" : "medium"}
          color="primary"
          variant="contained"
          onClick={() => {
            onClickGenerateJob();
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
