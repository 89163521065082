import { ChipProps, Typography } from "@mui/material";

import { Job, JobStatus } from "@/company/jobs/types";

export const TechnicianSentJobStatusFilterChipLabel = {
  [JobStatus.HOMEOWNER_DECLINED]: "Homeowner Declined",
  [JobStatus.HOMEOWNER_DELAYED]: "Homeowner Delayed",
  [JobStatus.HOMEOWNER_ACCEPTED]: "Homeowner Accepted",
  [JobStatus.PENDING_HOMEOWNER_ACTION]: "Pending Homeowner Approval",
  [JobStatus.ROUTED]: "Pending Partner Approval",
  [JobStatus.PENDING_REFERRAL_PAYMENT]: "Pending Referral Fee",
  [JobStatus.PAID]: "Commission Earned",
  [JobStatus.SUBMISSION]: "Video Processing",
  [JobStatus.OPPORTUNITY]: "Pending Your Review",
  [JobStatus.NO_COMPANY_TO_ROUTE]: "Pending Partner Update",
};

export const getTechnicianJobCardStatusChipProps = (job: Job): ChipProps => {
  const { status, buyingCompanyId } = job;
  const chipProps: ChipProps = {
    color: "info",
    label: "Pending",
    sx: {
      ml: { xs: 0, md: 0 },
      textTransform: "uppercase",
      cursor: "default",
      height: "24px",
      fontSize: "12px",
      fontWeight: 600,
    },
  };
  if (status === JobStatus.CLOSED) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "neutral.100",
      color: "text.secondary",
    };
    chipProps.label = <Typography variant="overline">Closed</Typography>;
  }
  if (status === JobStatus.SUBMISSION) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "neutral.100",
      color: "text.secondary",
    };
    chipProps.label = (
      <Typography variant="overline">Video Processing</Typography>
    );
  }

  if (status === JobStatus.OPPORTUNITY) {
    if (job.homeId) {
      chipProps.sx = {
        ...chipProps.sx,
        bgcolor: "#EDFCF2",
        color: "#16B364",
      };
      chipProps.label = (
        <Typography variant="overline">Pending Your Review</Typography>
      );
    } else {
      chipProps.sx = {
        ...chipProps.sx,
        bgcolor: "#EBEFFF",
        color: "#2970FF",
      };
      chipProps.label = (
        <Typography variant="overline">Incomplete: Add Homeowner</Typography>
      );
    }
  }

  if (status === JobStatus.PENDING_HOMEOWNER_ACTION) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "#EDFCF2",
      color: "#16B364",
    };
    chipProps.label = (
      <Typography variant="overline">Pending Homeowner Approval</Typography>
    );
  }
  if (status === JobStatus.HOMEOWNER_DECLINED) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "rgba(240, 68, 56, 0.12)",
      color: "#B42318",
    };
    chipProps.label = (
      <Typography variant="overline">Homeowner Declined</Typography>
    );
  }
  if (status === JobStatus.HOMEOWNER_DELAYED) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "rgba(247, 144, 9, 0.12)",
      color: "#B54708",
    };
    chipProps.label = (
      <Typography variant="overline">Homeowner Delayed</Typography>
    );
  }
  if (status === JobStatus.HOMEOWNER_ACCEPTED) {
    chipProps.color = "info";
    chipProps.label = (
      <Typography variant="overline">Homeowner Accepted</Typography>
    );
  }
  if (status === JobStatus.NO_COMPANY_TO_ROUTE) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "rgba(247, 144, 9, 0.12)",
      color: "#B54708",
    };
    chipProps.label = (
      <Typography variant="overline">Pending Partner Update</Typography>
    );
  }
  if (status === JobStatus.ROUTED) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "#EBEFFF",
      color: "#2970FF",
    };
    chipProps.label = (
      <Typography variant="overline">Pending Partner Approval</Typography>
    );
  }
  if (status === JobStatus.PENDING_REFERRAL_PAYMENT) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "rgba(247, 144, 9, 0.12)",
      color: "#B54708",
    };
    chipProps.label = (
      <Typography variant="overline">Pending Partner Update</Typography>
    );
  }
  if (buyingCompanyId && status === JobStatus.PAID) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "#16B364",
      color: "white",
    };
    chipProps.label = (
      <Typography variant="overline">COMMISSION EARNED💵!</Typography>
    );
  }

  return chipProps;
};
