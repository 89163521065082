import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { ReferralInvitation } from "../types";

export const USE_GET_REFERRAL_INVITATIONS_URI = "/referral-invites";

export function useGetReferralInvitationById(
  referralInviteId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: UseQueryOptions<any>
): UseQueryResult<ReferralInvitation> {
  const url = `${USE_GET_REFERRAL_INVITATIONS_URI}/${referralInviteId}`;
  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      const resp = await API.get(url);
      return resp.data.data;
    },
    ...options,
  });
}
