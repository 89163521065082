import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";
import { Job } from "@/company/jobs/types";

import { Home } from "../types";

export type UpdateHomeOptions = Omit<Home, "id">;

export function useLinkHomeToJob(
  options: UseMutationOptions<
    Job,
    AxiosError,
    { jobId: number; homeId: number }
  > = {}
): UseMutationResult<Job, AxiosError, { jobId: number; homeId: number }> {
  return useMutation({
    mutationFn: async ({ homeId, jobId }): Promise<Job> => {
      const response = await API.patch(`/jobs/${jobId}/link-home`, {
        home_id: homeId,
      });
      return response.data.data;
    },
    ...options,
  });
}
