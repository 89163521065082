import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useAuth } from "@/auth/context/jwt";
import { JobChatProvider } from "@/company/context/job-chat-context";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";
import JobTransactions from "@/internal-admin/jobs/components/JobTransactions";

import { useGetJobById } from "../api";
import { JobStatus, RoutedJobStatus, Source } from "../types/job";
import { HomeownerTab } from "./HomeownerTab";
import JobAttachments from "./JobAttachments";
import { JobMessage } from "./JobMessageTab";
import { JobOverview } from "./JobOverview";
import { JobProjectTab } from "./JobProjectTab";

interface JobDrawerProps {
  onClose?: () => void;
  onJobClose?: () => void;
  refreshJobs?: () => void;
  inactiveReason?: string;
  open?: boolean;
  source?: Source;
  jobStatus?: JobStatus | RoutedJobStatus;
  lastActivity?: string;
  jobId: number;
  isRoutedJob?: boolean;
  actionArea?: ReactNode;
  defaultTab?: JobDrawerTabsEnum;
}

export enum JobDrawerTabsEnum {
  PROJECT = "project",
  HOMEOWNER = "homeowner",
  MESSAGE = "message",
  REFERRAL_PARTNER = "referral-partner",
  ATTACHMENTS = "attachments",
  TRANSACTIONS = "transactions",
}

export const JobDrawerComponentById: FC<JobDrawerProps> = props => {
  const {
    onClose,
    open = false,
    jobId,
    jobStatus,
    lastActivity,
    actionArea,
    defaultTab,
    inactiveReason,
    refreshJobs,
    isRoutedJob,
    onJobClose,
    source,
    ...other
  } = props;
  const [currentTab, setCurrentTab] = useState<JobDrawerTabsEnum>(
    defaultTab ?? JobDrawerTabsEnum.PROJECT
  );
  const { session } = useAuth();

  const { data: job, isLoading } = useGetJobById(jobId);

  const [jobClosed, setJobClosed] = useState(false);

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: JobDrawerTabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  useEffect(() => {
    if (defaultTab) setCurrentTab(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (job?.status === JobStatus.CLOSED) {
      setJobClosed(true);
    }
  }, [job]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          flexDirection={"column"}
          height="100%"
          width="100%"
          gap={1}
        >
          <CircularProgress />
          <Typography variant="body1">
            Hang tight! We&apos;re fetching the job details.
          </Typography>
        </Box>
      ) : (
        <>
          <Paper
            sx={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            <Box
              position={"sticky"}
              sx={{ top: 0, zIndex: 400, pt: 1, background: "white" }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems={"center"}
                pb={2}
                sx={{ px: { xs: 1, sm: 3 }, pt: { xs: 1, sm: 3 } }}
              >
                <Typography variant="h6">{job?.jobType}</Typography>
                <IconButton
                  aria-label="close"
                  sx={{
                    color: theme => theme.palette.grey[500],
                  }}
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider sx={{ width: "100%" }} />
              <Tabs
                sx={{ px: 3 }}
                value={currentTab}
                onChange={handleTabsChange}
              >
                <Tab
                  value={JobDrawerTabsEnum.PROJECT}
                  label="Project Details"
                />
                {/* {job?.status === JobStatus.ROUTED ? (
                  <Tab
                    value={JobDrawerTabsEnum.REFERRAL_PARTNER}
                    label="Referral Partner"
                  />
                ) : null} */}
                {job?.homeId ? (
                  <Tab value={JobDrawerTabsEnum.HOMEOWNER} label="Homeowner" />
                ) : null}
                {job?.homeId ? (
                  <Tab value={JobDrawerTabsEnum.MESSAGE} label="Message" />
                ) : null}
                <Tab
                  value={JobDrawerTabsEnum.ATTACHMENTS}
                  label="Attachments"
                />
                {session?.company?.accountType ===
                  COMPANY_ACCOUNT_TYPES.SUPERADMIN && (
                  <Tab
                    value={JobDrawerTabsEnum.TRANSACTIONS}
                    label="Transactions"
                  />
                )}
              </Tabs>
              <Divider />
            </Box>
            <Box
              py={2}
              sx={{ px: { xs: 1, sm: 3 } }}
              height={"100%"}
              overflow={"auto"}
            >
              {currentTab === JobDrawerTabsEnum.TRANSACTIONS && jobId && (
                <JobTransactions jobId={jobId} />
              )}
              {currentTab === JobDrawerTabsEnum.PROJECT && job ? (
                <JobProjectTab
                  job={job}
                  isRoutedJob={isRoutedJob}
                  jobStatus={jobStatus ?? job?.status}
                  lastActivity={lastActivity}
                  inactiveReason={inactiveReason}
                  source={source}
                  changeTab={(tab: JobDrawerTabsEnum) => {
                    setCurrentTab(tab);
                  }}
                />
              ) : null}
              {currentTab === JobDrawerTabsEnum.HOMEOWNER && job ? (
                <HomeownerTab jobId={job?.id} jobStatus={job.status} />
              ) : null}
              {currentTab === JobDrawerTabsEnum.REFERRAL_PARTNER &&
              jobStatus === JobStatus.ROUTED &&
              job?.createdByCompanyId ? (
                <JobOverview companyId={job?.createdByCompanyId} />
              ) : null}
              {currentTab === JobDrawerTabsEnum.MESSAGE && job?.homeId ? (
                <JobChatProvider jobId={job?.id}>
                  <JobMessage disableChat={true} />
                </JobChatProvider>
              ) : null}
              {currentTab === JobDrawerTabsEnum.ATTACHMENTS ? (
                <JobAttachments
                  jobId={job?.id}
                  isRoutedJob={isRoutedJob}
                  status={jobStatus}
                />
              ) : null}
            </Box>
          </Paper>
        </>
      )}
    </Box>
  );
};
