/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
import {
  AddPhotoAlternateOutlined,
  AttachFile,
  ChatBubbleOutline,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBaseComponentProps,
  LinearProgress,
  MenuItem,
  Switch,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import {
  ElementType,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import {
  useCreateJob,
  useGetJobById,
  useUpdateJobById,
} from "@/company/jobs/api";
import { useCloseJob } from "@/company/jobs/api/useCloseJob";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { useDeleteJob } from "@/company/jobs/api/useDeleteJob";
import { useFetchRelatedJobSectors } from "@/company/jobs/api/useFetchRelatedJobSectors";
import { OCRJobDetails, useGetOCR } from "@/company/jobs/api/useGetOCR";
import { useSubmitJob } from "@/company/jobs/api/useSubmitJob";
import { useUpdateJobHome } from "@/company/jobs/api/useUpdateJobHome";
import { useUpdateJobSector } from "@/company/jobs/api/useUpdateJobSector";
import { JobSectors } from "@/company/jobs/constants";
import { Job, JobStatus } from "@/company/jobs/types";
import { CompanyRoutes, HOMEOWNER_APPROVAL_SETTING } from "@/company/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { neutral, tradeEngageColors } from "@/Theme/colors";
import { getUSPhoneValidationSchema } from "@/utils";

import { Languages } from "../constants/languages";
import { GoogleAutocomplete } from "../GoogleAutocomplete";
import { MaskedPhoneInput } from "../MaskedPhoneInput";
import { doesErrorHaveMessage } from "../utils/doesErrorHaveMessage";
import { JobDetailsUpload } from "./JobDetailsUpload";

export interface CreateJobDialogProps {
  isOpen: boolean;
  createdForCompanyId?: number;
  homeId?: number;
  defaultHomeownerDetails?: {
    firstName: string;
    lastName?: string;
    address?: string;
    cellPhone?: string;
    zipCode?: string;
  };
  defaultSummary?: string;
  onClose: () => void;
  jobsListUrl: string;
  refreshJobs?: () => Promise<unknown>;
}

export function CreateTechnicianJobDialogV1(props: CreateJobDialogProps) {
  const { session } = useAuth();
  const brandLocationProfile = session?.brandLocationProfile;
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const [zipCode, setZipCode] = useState<string | undefined>("");
  const [latitude, setLatitude] = useState<number | undefined>();
  const [longitude, setLongitude] = useState<number | undefined>();

  const [transcription, setTranscription] = useState<string | undefined>();
  const [homeownerDetailsUpload, setHomeownerDetailsUpload] = useState<
    File | undefined
  >();

  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      setHomeownerDetailsUpload(file);
      // Handle the file upload logic here
    }
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const onDrop = (files: File[]) => {
    const newFile = files && files.length > 0 ? files[0] : null;
    setVideoFile(newFile);
  };

  const { enqueueSnackbar } = useSnackbar();

  const [newJob, setNewJob] = useState<Job | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [videoFile, setVideoFile] = useState<any>(undefined);
  const [step, setStep] = useState(0);
  const [polling, setPolling] = useState(false);
  const [newUploadScreenOpen, setNewUploadScreenOpen] = useState(false);
  const [uploadedJobId, setUploadedJobId] = useState(false);

  const [fetchedOCRDetails, setFetchedOCRDetails] = useState(false);
  const [homeownerTextUpload, setHomeownerTextUpload] = useState(false);

  const [homeownerApprovalSwitch, setHomeownerApprovalSwitch] = useState(false);
  const [mediaType, setMediaType] = useState<JobUploadTypes>(
    JobUploadTypes.VIDEO
  );

  const { isLoading: isCreatingJob, mutateAsync: createNewJob } =
    useCreateJob();

  const { isLoading: isDeletingJob, mutateAsync: deleteJob } = useDeleteJob({
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while cancelling the job";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const { isLoading: isFetchingOCR, mutateAsync: getOCR } = useGetOCR({
    onSuccess: (jobDetails: OCRJobDetails) => {
      if (
        homeownerApprovalSwitch ||
        brandLocationProfile?.homeownerJobApprovalSettings ===
          HOMEOWNER_APPROVAL_SETTING.ROUTE_ALL_JOBS_TO_HOME_OWNER
      ) {
        if (jobDetails?.phone) {
          formik.setFieldValue("cellPhone", jobDetails?.phone, true);
        }
        setFetchedOCRDetails(true);
        return;
      }
      if (jobDetails?.firstName) {
        formik.setFieldValue("firstName", jobDetails?.firstName, true);
      }
      if (jobDetails?.lastName) {
        formik.setFieldValue("lastName", jobDetails?.lastName, true);
      }
      if (jobDetails?.phone) {
        formik.setFieldValue("cellPhone", jobDetails?.phone, true);
      }
      if (jobDetails?.address) {
        formik.setFieldValue("address", jobDetails?.address, true);
      }
      setFetchedOCRDetails(true);
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while fetching homeowner details from image.";
      setHomeownerDetailsUpload(undefined);
      setFetchedOCRDetails(false);
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const validationSchema = yup.object().shape({
    firstName:
      brandLocationProfile?.homeownerJobApprovalSettings ===
        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER ||
      !homeownerApprovalSwitch
        ? yup.string().required("First Name is required")
        : yup.string().optional(),
    lastName:
      brandLocationProfile?.homeownerJobApprovalSettings ===
        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER ||
      !homeownerApprovalSwitch
        ? yup.string().required("Last Name is required")
        : yup.string().optional(),
    address:
      brandLocationProfile?.homeownerJobApprovalSettings ===
        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER ||
      !homeownerApprovalSwitch
        ? yup.string().required("Home Address is required")
        : yup.string().optional(),
    sector: yup.string().optional(),
    cellPhone: getUSPhoneValidationSchema().required(
      "Mobile Number is required"
    ),
  });

  useEffect(() => {
    const details = props.defaultHomeownerDetails;
    if (details) {
      if (details.firstName) {
        formik.setFieldValue("firstName", details.firstName, true);
      }
      if (details.lastName) {
        formik.setFieldValue("lastName", details.lastName, true);
      }
      if (details.cellPhone) {
        formik.setFieldValue("cellPhone", details.cellPhone, true);
      }
      if (details.address) {
        formik.setFieldValue("address", details.address, true);
      }
      if (details.zipCode) {
        setZipCode(details.zipCode);
      }
    }
  }, [props.defaultHomeownerDetails]);

  const formik = useFormik({
    initialValues: {
      cellPhone: "",
      sector: "",
      firstName: "",
      lastName: "",
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ cellPhone }) => {
      console.log({ cellPhone });
    },
  });

  // const validationSchema = yup
  //   .object({
  //     firstName: yup.string().required("First Name is required"),
  //     lastName: yup.string().required("Last Name is required"),
  //     address: yup.string().required("Home Address is required"),
  //     cellPhone: getUSPhoneValidationSchema().required(
  //       "Mobile Number is required"
  //     ),
  //     sector: yup.string().optional(),
  //   })
  //   .optional();

  const { isLoading: updatingJobHome, mutateAsync: updateJobHome } =
    useUpdateJobHome({
      onSuccess: () => {
        setStep(2);
        setPolling(true);
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "here was an error while updating homeowner details.";
        enqueueSnackbar({
          message,
          style: {
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            maxWidth: "500px",
          },
          variant: "error",
          ...(error?.code === "cant_tie_job_to_a_company_member"
            ? {
                subText: (
                  <>
                    Questions?{" "}
                    <a
                      role="button"
                      style={{
                        textDecorationLine: "underline",
                        color: "#7A271A",
                      }}
                      onClick={event => {
                        event.preventDefault();
                        setContactSupportModalOpen(true);
                      }}
                    >
                      Contact support
                    </a>
                    .
                  </>
                ),
              }
            : {}),
        });
      },
    });

  const { isLoading: updatingJob, mutateAsync: updateJob } = useUpdateJobById({
    onSuccess: () => {
      updateJobHome({
        jobId: newJob?.id,
        cellPhone: `+1${formik.values.cellPhone.replace(/[\s()-]/g, "")}`,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        streetAddress: formik.values.address,
        zipCode,
        latitude,
        longitude,
      });
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while updating job details.";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const { isLoading: updatingJobSector, mutateAsync: updateJobSector } =
    useUpdateJobSector({
      onSuccess: (job: Job) => {
        setModifyJob(false);
        setNewJob(job);
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "There was an error while updating job sector details.";
        enqueueSnackbar({
          message,
          variant: "error",
        });
      },
    });

  const { isLoading: submittingJob, mutateAsync: submitJob } = useSubmitJob({
    onSuccess: () => {
      setUploadedJobId(newJob?.id);
      reset();
      props.refreshJobs?.();

      enqueueSnackbar({
        message: "Job submitted successfully.",
        variant: "success",
      });
      setNewUploadScreenOpen(true);
    },
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while submitting the job.`,
        variant: "error",
      });
    },
  });

  const {
    isFetching: isFetchingRelatedJobSectors,
    data: fetchedRelatedJobSectors,
  } = useFetchRelatedJobSectors(newJob?.sector as JobSectors, {
    enabled: !!newJob?.sector,
  });

  const { mutateAsync: closeJob } = useCloseJob();

  const {
    isFetching: fetchingJobStatus,
    isRefetching,
    error,
    data: job,
  } = useGetJobById(newJob?.id, {
    enabled: !!newJob?.id && step === 2,
    refetchInterval: () => {
      if (
        newJob?.status === JobStatus.OPPORTUNITY ||
        newJob?.status == JobStatus.VIDEO_PROCESSING_FAILED
      ) {
        if (newJob?.status === JobStatus.OPPORTUNITY && step === 2) setStep(3);
        setPolling(false);
        return false;
      }
      return 2000;
    },
  });

  useEffect(() => {
    if (job) {
      if (job?.status === JobStatus.VIDEO_PROCESSING_FAILED) {
        enqueueSnackbar({
          message: `There was an error while processing job video. Please try again.`,
          variant: "error",
        });
        reset();
      }
      setNewJob(job);
    }
  }, [job]);
  const handleVideoButtonClick = () => {
    const videoUrl =
      "https://tradeengage-app.s3.amazonaws.com/videos/Outdoor+electrical+box+shorting.mp4";
    window.open(videoUrl, "_blank");
  };

  const [modifyJob, setModifyJob] = useState(false);

  const reset = () => {
    setVideoFile(undefined);
    setNewJob(undefined);
    setStep(0);
    setZipCode(undefined);
    setLatitude(undefined);
    setLongitude(undefined);
    setMediaType(JobUploadTypes.VIDEO);
    setHomeownerTextUpload(false);
    setTranscription("");
    setPolling(false);
    setHomeownerDetailsUpload(undefined);
    setFetchedOCRDetails(false);
    setHomeownerApprovalSwitch(false);
    setModifyJob(false);
    formik.resetForm();
    return true;
  };

  const revertStep1 = async () => {
    if (newJob) {
      closeJob(newJob?.id, {
        onError: error => {
          console.error(error);
        },
      });
    }
    setHomeownerDetailsUpload(undefined);
    setFetchedOCRDetails(false);
    setHomeownerTextUpload(false);
    setPolling(false);
    setHomeownerApprovalSwitch(false);
    formik.resetForm();
    setZipCode(undefined);
    setLatitude(undefined);
    setLongitude(undefined);
    setHomeownerDetailsUpload(undefined);
    setStep(0);
  };
  const revertStep3 = () => {
    setStep(1);
  };

  const onClickGenerateJob = async () => {
    createNewJob(
      {
        videoFile,
        videoLanguage: Languages.ENGLISH,
        homeId: props.homeId,
        createdForCompanyId: props.createdForCompanyId,
        type: mediaType,
        ...(mediaType === JobUploadTypes.TEXT && transcription
          ? { summary: transcription }
          : {}),
      },
      {
        onSuccess: (data: Job) => {
          setNewJob(data);
          setStep(1);

          // props.refreshJobs?.();
          // navigate(props.jobsListUrl);
          //props.onClose();
        },
        onError: () => {
          setStep(0);
          enqueueSnackbar({
            message: `There was an error while creating ${
              session?.user?.type === USER_TYPE.HOMEOWNER
                ? "your project"
                : "the job"
            }.`,
            variant: "error",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (homeownerDetailsUpload) {
      getOCR({ jobId: newJob?.id, imageFile: homeownerDetailsUpload });
    }
  }, [homeownerDetailsUpload]);

  let homeownerDetailsRequired =
    brandLocationProfile?.homeownerJobApprovalSettings ===
    HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER;

  if (
    brandLocationProfile?.homeownerJobApprovalSettings ===
    HOMEOWNER_APPROVAL_SETTING.ROUTE_ALL_JOBS_TO_HOME_OWNER
  ) {
    homeownerDetailsRequired = false;
  } else if (
    brandLocationProfile?.homeownerJobApprovalSettings ===
    HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
  ) {
    homeownerDetailsRequired = !homeownerApprovalSwitch;
  }

  let disableHomeOwnerReviewRequiredNextButton = false;

  if (!homeownerDetailsRequired) {
    if (
      !formik.values.cellPhone ||
      (formik.touched.cellPhone && Boolean(formik.errors.cellPhone))
    ) {
      disableHomeOwnerReviewRequiredNextButton = true;
    }
  } else {
    if (
      !formik.values.cellPhone ||
      !formik.values.firstName ||
      !formik.values.lastName ||
      !formik.values.address ||
      (formik.touched.cellPhone && Boolean(formik.errors.cellPhone)) ||
      (formik.touched.firstName && Boolean(formik.errors.firstName)) ||
      (formik.touched.lastName && Boolean(formik.errors.lastName))
    ) {
      disableHomeOwnerReviewRequiredNextButton = true;
    }
  }

  if (updatingJobHome || updatingJob) {
    disableHomeOwnerReviewRequiredNextButton = true;
  }

  return (
    <Dialog
      fullWidth
      {...(isMobile ? { fullScreen: true, m: 0 } : { m: 3 })}
      open={props.isOpen}
      maxWidth="md"
      onClose={() => reset() && props.onClose()}
    >
      <DialogTitle component="div" width="95%">
        {newUploadScreenOpen ? null : (
          <>
            {" "}
            <Typography gutterBottom variant="h5">
              {session?.user?.type === USER_TYPE.HOMEOWNER
                ? "Add Home Project"
                : "Submit New Job"}
            </Typography>
            {session?.user?.type === USER_TYPE.HOMEOWNER ? (
              <Typography gutterBottom variant="body2" color="textSecondary">
                Create a new project and it will be routed to a trusted service
                provider.
              </Typography>
            ) : (
              <Typography gutterBottom variant="body2" color="textSecondary">
                Upload a job to refer to your network. It&apos;s easy!
              </Typography>
            )}
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <Typography variant="subtitle2">Need an example?</Typography>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  px: "10px",
                  py: "4px",
                  width: "fit-content",
                  borderRadius: "8px",
                }}
                endIcon={<PlayCircleFilledWhiteOutlinedIcon />}
                onClick={handleVideoButtonClick}
              >
                Watch Video
              </Button>
            </Box>
          </>
        )}
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => reset() && props.onClose()}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent
        sx={{ px: { xs: 0 }, pt: 0, overflow: "auto", position: "relative" }}
      >
        {newUploadScreenOpen ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            height={"100%"}
            px={3}
            mt={isMobile ? -2 : 0}
            pb={4}
            gap={1}
          >
            <img
              style={{
                height: "200px",
                width: "200px",
                marginBottom: "24px",
              }}
              src="/success.gif"
              alt="success"
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
              alignItems={"start"}
            >
              <Typography variant="h4" fontSize={"22px"}>
                Job Submitted Successfully!
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              <Typography variant="body1" color="text.secondary">
                Great! Your job will be routed and we&apos;ll let you know when
                your earnings are on their way. 💸
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Need to share more details about this job?
              </Typography>
              <Box display={"flex"} gap={2}>
                <Button
                  startIcon={<ChatBubbleOutline />}
                  variant="outlined"
                  size="small"
                  sx={{ width: "fit-content" }}
                  onClick={() => {
                    navigate(
                      `/${CompanyRoutes.BASE}/${CompanyRoutes.MY_JOBS}/${uploadedJobId}/message`
                    );
                    props.onClose();
                  }}
                >
                  Send a Message
                </Button>
                <Button
                  size="small"
                  startIcon={<AttachFile />}
                  variant="outlined"
                  sx={{ width: "fit-content" }}
                  onClick={() => {
                    navigate(
                      `/${CompanyRoutes.BASE}/${CompanyRoutes.MY_JOBS}/${uploadedJobId}/attachments`
                    );
                    props.onClose();
                  }}
                >
                  Add Attachments
                </Button>
              </Box>
            </Box>
            <Divider sx={{ width: "100%", my: 1.5 }} />
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              <Typography variant="body1" color="text.secondary">
                Do you have more jobs to refer?
              </Typography>
            </Box>
            <Box display={"flex"} width={"100%"}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<BackupOutlinedIcon />}
                onClick={() => setNewUploadScreenOpen(false)}
              >
                Upload New Job
              </Button>
            </Box>
          </Box>
        ) : (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              mt: 0,
            }}
          >
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  sx={{
                    mb: 1,
                    color: neutral[50],
                    borderRadius: 1,
                    bgcolor: tradeEngageColors.green,
                  }}
                >
                  {step > 0 ? <CheckIcon color="inherit" /> : "1"}
                </Avatar>
                <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
              </TimelineSeparator>
              {isCreatingJob ? (
                <TimelineContent sx={{ pb: 5, pt: 0.5 }}>
                  <Typography
                    gutterBottom
                    variant="overline"
                    {...(step > 0 ? { color: "#6C737F" } : {})}
                  >
                    Upload Job Video
                  </Typography>
                  <Box
                    width="100%"
                    pt={3}
                    display="flex"
                    flexDirection={"column"}
                  >
                    {videoFile ? (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      mediaType === JobUploadTypes.VIDEO ? (
                        <video
                          controls
                          controlsList="nodownload"
                          src={`${window.URL.createObjectURL(videoFile)}`}
                          width="72px"
                          height="72px"
                          style={{
                            marginBottom: "16px",
                            border: "1px solid black",
                          }}
                        />
                      ) : (
                        <audio
                          controls
                          controlsList="nodownload"
                          src={`${window.URL.createObjectURL(videoFile)}`}
                          width="72px"
                          height="72px"
                          style={{
                            marginBottom: "16px",
                          }}
                        />
                      )
                    ) : null}
                    <Typography gutterBottom variant="body2">
                      We&apos;re uploading your{" "}
                      {videoFile ? mediaType.toLowerCase() : "transcription"},
                      please do not close this window.
                    </Typography>
                    <LinearProgress sx={{ width: "100%" }} />
                  </Box>
                </TimelineContent>
              ) : (
                <TimelineContent sx={{ pb: 5, pt: 0.5 }}>
                  <Typography
                    gutterBottom
                    variant="overline"
                    {...(step > 0 ? { color: "#6C737F" } : {})}
                  >
                    Project Details
                  </Typography>
                  {step > 0 ? null : (
                    <JobDetailsUpload
                      reset={reset}
                      mediaType={mediaType}
                      setMediaType={setMediaType}
                      videoFile={videoFile}
                      transcription={transcription}
                      setTranscription={(text: string) =>
                        setTranscription(text)
                      }
                      defaultSummary={props.defaultSummary}
                      setVideoFile={file => setVideoFile(file)}
                      onClickGenerateJob={onClickGenerateJob}
                    />
                  )}
                </TimelineContent>
              )}
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  color="primary"
                  sx={{
                    my: 1,
                    borderRadius: 1,
                    color: step >= 1 ? neutral[50] : "textPrimary",
                    bgcolor: step >= 1 ? tradeEngageColors.green : neutral[200],
                  }}
                >
                  {step > 1 ? <CheckIcon color="inherit" /> : "2"}
                </Avatar>
                {step >= 1 ? (
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                ) : null}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                <Typography
                  variant="overline"
                  {...(step > 1 ? { color: "#6C737F" } : {})}
                >
                  Add Homeowner Info
                </Typography>
                {step === 1 ? (
                  <Box
                    width="100%"
                    pt={3}
                    gap={1}
                    display="flex"
                    flexDirection={"column"}
                  >
                    <Typography gutterBottom variant="subtitle1">
                      Enter Homeowner Details
                    </Typography>
                    <Box
                      display={"flex"}
                      gap={isMobile ? 1 : 2}
                      alignItems={"baseline"}
                      flexDirection={isMobile ? "column" : "row"}
                    >
                      {!homeownerTextUpload ? (
                        <>
                          <Typography
                            gutterBottom
                            variant="body2"
                            color="text.secondary"
                          >
                            Type in details instead?
                          </Typography>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setHomeownerTextUpload(true);
                              setHomeownerDetailsUpload(undefined);
                            }}
                          >
                            Switch to Text
                          </Button>
                        </>
                      ) : (
                        <Box
                          mb={isMobile ? 1 : 2}
                          display={"flex"}
                          gap={isMobile ? 0 : 2}
                          flexDirection={isMobile ? "column" : "row"}
                          alignItems={isMobile ? "start" : "center"}
                        >
                          <Typography
                            gutterBottom
                            variant="body2"
                            color="text.secondary"
                          >
                            Upload a screenshot of the Homeowner&apos;s profile?
                          </Typography>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => setHomeownerTextUpload(false)}
                          >
                            Upload
                          </Button>
                        </Box>
                      )}
                    </Box>
                    {!homeownerTextUpload ? (
                      <Box
                        display={"flex"}
                        width="100%"
                        gap={2}
                        flexDirection={"column"}
                      >
                        {homeownerDetailsUpload ? (
                          isFetchingOCR || !fetchedOCRDetails ? (
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              gap={0}
                              mt={3}
                            >
                              <Typography variant="subtitle1">
                                Extracting Homeowner Details from Image
                              </Typography>
                              <Typography
                                variant="caption"
                                fontSize={"0.625rem"}
                              >
                                Please do not close this window.
                              </Typography>
                              <LinearProgress sx={{ width: "100%", mt: 1 }} />
                            </Box>
                          ) : (
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              py={2}
                              gap={2}
                            >
                              <Box
                                sx={{
                                  borderRadius: "8px",
                                  border: "1px solid #E5E7EB",
                                  background: "#FFF",
                                  boxShadow: "0px 0px 0px 1px #F2F4F7",
                                  width: { xs: "100%", md: "49%" },
                                }}
                              >
                                <Box
                                  p={1}
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#F8F9FA",
                                    },
                                    opacity:
                                      brandLocationProfile?.homeownerJobApprovalSettings !==
                                      HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                        ? 0.5
                                        : 1,
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      fontSize={"13px"}
                                      fontWeight={600}
                                      color={"#16B364"}
                                    >
                                      Homeowner Review Required
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "11px",
                                        fontWeight: 400,
                                      }}
                                      color={"text.secondary"}
                                    >
                                      Do you want a homeowner to review and
                                      approve a job before it is routed to your
                                      referral partner?
                                    </Typography>
                                  </Box>
                                  <Switch
                                    color="primary"
                                    disabled={
                                      brandLocationProfile?.homeownerJobApprovalSettings !==
                                      HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                    }
                                    checked={
                                      brandLocationProfile?.homeownerJobApprovalSettings ===
                                      HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                        ? homeownerApprovalSwitch
                                        : brandLocationProfile?.homeownerJobApprovalSettings ===
                                          HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER
                                        ? false
                                        : true
                                    }
                                    onChange={() =>
                                      setHomeownerApprovalSwitch(
                                        homeownerApprovalSwitch =>
                                          !homeownerApprovalSwitch
                                      )
                                    }
                                  />
                                </Box>
                              </Box>
                              <Box
                                display={"flex"}
                                width="100%"
                                gap={2}
                                flexDirection={"column"}
                              >
                                {homeownerDetailsRequired ? (
                                  <Box display={"flex"} width="100%" gap={2}>
                                    <TextField
                                      required={homeownerDetailsRequired}
                                      id="firstName"
                                      name="firstName"
                                      label={"First Name"}
                                      sx={{
                                        width: "50%",
                                      }}
                                      error={
                                        formik.touched.firstName &&
                                        Boolean(formik.errors.firstName)
                                      }
                                      helperText={
                                        formik.touched.firstName &&
                                        formik.errors.firstName
                                      }
                                      value={formik.values.firstName ?? ""}
                                      onBlur={formik.handleBlur}
                                      onChange={formik.handleChange}
                                    />
                                    <TextField
                                      required={homeownerDetailsRequired}
                                      sx={{
                                        width: "50%",
                                      }}
                                      id="lastName"
                                      name="lastName"
                                      label={"Last Name"}
                                      error={
                                        formik.touched.lastName &&
                                        Boolean(formik.errors.lastName)
                                      }
                                      helperText={
                                        formik.touched.lastName &&
                                        formik.errors.lastName
                                      }
                                      value={formik.values.lastName ?? ""}
                                      onBlur={formik.handleBlur}
                                      onChange={formik.handleChange}
                                    />
                                  </Box>
                                ) : null}

                                <Box
                                  display={"flex"}
                                  width="100%"
                                  gap={2}
                                  flexDirection={isMobile ? "column" : "row"}
                                >
                                  {homeownerDetailsRequired ? (
                                    <Box sx={{ width: "100%" }}>
                                      <GoogleAutocomplete
                                        defaultOptions={[]}
                                        selectedValue={
                                          formik.values.address ?? ""
                                        }
                                        error={
                                          formik.touched.address &&
                                          Boolean(formik.errors.address)
                                        }
                                        helperText={
                                          formik.touched.address &&
                                          formik.errors.address
                                        }
                                        label={
                                          homeownerDetailsRequired
                                            ? "Home Address*"
                                            : "Home Address"
                                        }
                                        onBlur={event =>
                                          formik.setFieldTouched(
                                            "address",
                                            true
                                          )
                                        }
                                        onChange={(address: string) => {
                                          formik.setFieldValue(
                                            "address",
                                            address
                                          );
                                        }}
                                        onSelect={({
                                          selection,
                                          zipCode,
                                          latitude,
                                          longitude,
                                        }) => {
                                          setZipCode(zipCode);
                                          setLatitude(latitude);
                                          setLongitude(longitude);
                                          formik.setFieldValue(
                                            "address",
                                            selection
                                          );
                                        }}
                                      />
                                    </Box>
                                  ) : null}

                                  <TextField
                                    required
                                    sx={{
                                      width: {
                                        xs: "100%",
                                        md: homeownerDetailsRequired
                                          ? "100%"
                                          : "49%",
                                      },
                                    }}
                                    id="cellPhone"
                                    name="cellPhone"
                                    label={"Mobile Number"}
                                    error={
                                      formik.touched.cellPhone &&
                                      Boolean(formik.errors.cellPhone)
                                    }
                                    helperText={
                                      formik.touched.cellPhone &&
                                      formik.errors.cellPhone
                                    }
                                    value={formik.values.cellPhone ?? ""}
                                    InputProps={{
                                      inputComponent:
                                        MaskedPhoneInput as unknown as
                                          | ElementType<InputBaseComponentProps>
                                          | undefined,
                                    }}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          )
                        ) : (
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            py={2}
                            gap={2}
                          >
                            <Box
                              sx={{
                                borderRadius: "8px",
                                border: "1px solid #E5E7EB",
                                background: "#FFF",
                                boxShadow: "0px 0px 0px 1px #F2F4F7",
                                width: { xs: "100%", md: "70%" },
                              }}
                            >
                              <Box
                                p={1}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#F8F9FA",
                                  },
                                  opacity:
                                    brandLocationProfile?.homeownerJobApprovalSettings !==
                                    HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                      ? 0.5
                                      : 1,
                                }}
                              >
                                <Box>
                                  <Typography
                                    fontSize={"13px"}
                                    fontWeight={600}
                                    color={"#16B364"}
                                  >
                                    Homeowner Review Required
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "11px",
                                      fontWeight: 400,
                                    }}
                                    color={"text.secondary"}
                                  >
                                    Do you want a homeowner to review and
                                    approve a job before it is routed to your
                                    referral partner?
                                  </Typography>
                                </Box>
                                <Switch
                                  color="primary"
                                  disabled={
                                    brandLocationProfile?.homeownerJobApprovalSettings !==
                                    HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                  }
                                  checked={
                                    brandLocationProfile?.homeownerJobApprovalSettings ===
                                    HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                      ? homeownerApprovalSwitch
                                      : brandLocationProfile?.homeownerJobApprovalSettings ===
                                        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER
                                      ? false
                                      : true
                                  }
                                  onChange={() =>
                                    setHomeownerApprovalSwitch(
                                      homeownerApprovalSwitch =>
                                        !homeownerApprovalSwitch
                                    )
                                  }
                                />
                              </Box>
                            </Box>
                            <Box
                              px={isMobile ? 1 : 2}
                              py={1}
                              width={isMobile ? "100%" : "70%"}
                              sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "start",
                                borderRadius: "16px",
                                gap: 2,
                                background: "#FFF",
                                boxShadow: "0px 0px 0px 1px #F2F4F7",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "#F8F9FA",
                                },
                              }}
                              onClick={() => handleButtonClick()}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "50px",
                                  height: "50px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  background: "#e8fbf0",
                                }}
                              >
                                <AddPhotoAlternateOutlined color="primary" />
                              </Box>
                              <input
                                ref={fileInputRef}
                                type="file"
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={handleFileChange}
                              />
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                sx={{ ...(isMobile ? { width: "85%" } : {}) }}
                              >
                                <Typography fontSize={"13px"} fontWeight={600}>
                                  Upload a Screenshot from Your CRM
                                </Typography>
                                <Typography
                                  fontSize={"12px"}
                                  fontWeight={500}
                                  color={"text.secondary"}
                                >
                                  Easily share homeowner info by taking a
                                  screenshot from your field service app.
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box
                        display={"flex"}
                        width="100%"
                        gap={2}
                        flexDirection={"column"}
                      >
                        <Box
                          sx={{
                            borderRadius: "8px",
                            border: "1px solid #E5E7EB",
                            background: "#FFF",
                            boxShadow: "0px 0px 0px 1px #F2F4F7",
                            width: { xs: "100%", md: "49%" },
                          }}
                        >
                          <Box
                            p={1}
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              "&:hover": {
                                backgroundColor: "#F8F9FA",
                              },
                              opacity:
                                brandLocationProfile?.homeownerJobApprovalSettings !==
                                HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                  ? 0.5
                                  : 1,
                            }}
                          >
                            <Box>
                              <Typography
                                fontSize={"13px"}
                                fontWeight={600}
                                color={"#16B364"}
                              >
                                Homeowner Review Required
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  fontWeight: 400,
                                }}
                                color={"text.secondary"}
                              >
                                Do you want a homeowner to review and approve a
                                job before it is routed to your referral
                                partner?
                              </Typography>
                            </Box>
                            <Switch
                              color="primary"
                              disabled={
                                brandLocationProfile?.homeownerJobApprovalSettings !==
                                HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                              }
                              checked={
                                brandLocationProfile?.homeownerJobApprovalSettings ===
                                HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                  ? homeownerApprovalSwitch
                                  : brandLocationProfile?.homeownerJobApprovalSettings ===
                                    HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER
                                  ? false
                                  : true
                              }
                              onChange={() =>
                                setHomeownerApprovalSwitch(
                                  homeownerApprovalSwitch =>
                                    !homeownerApprovalSwitch
                                )
                              }
                            />
                          </Box>
                        </Box>
                        {homeownerDetailsRequired ? (
                          <Box display={"flex"} width="100%" gap={2}>
                            <TextField
                              required={homeownerDetailsRequired}
                              id="firstName"
                              name="firstName"
                              label={"First Name"}
                              sx={{
                                width: "50%",
                              }}
                              error={
                                formik.touched.firstName &&
                                Boolean(formik.errors.firstName)
                              }
                              helperText={
                                formik.touched.firstName &&
                                formik.errors.firstName
                              }
                              value={formik.values.firstName ?? ""}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                            />
                            <TextField
                              required={homeownerDetailsRequired}
                              sx={{
                                width: "50%",
                              }}
                              id="lastName"
                              name="lastName"
                              label={"Last Name"}
                              error={
                                formik.touched.lastName &&
                                Boolean(formik.errors.lastName)
                              }
                              helperText={
                                formik.touched.lastName &&
                                formik.errors.lastName
                              }
                              value={formik.values.lastName ?? ""}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                            />
                          </Box>
                        ) : null}
                        <Box
                          display={"flex"}
                          width="100%"
                          gap={2}
                          flexDirection={isMobile ? "column" : "row"}
                        >
                          {homeownerDetailsRequired ? (
                            <Box sx={{ width: "100%" }}>
                              <GoogleAutocomplete
                                defaultOptions={[]}
                                selectedValue={formik.values.address ?? ""}
                                error={
                                  formik.touched.address &&
                                  Boolean(formik.errors.address)
                                }
                                helperText={
                                  formik.touched.address &&
                                  formik.errors.address
                                }
                                label={
                                  homeownerDetailsRequired
                                    ? "Home Address*"
                                    : "Home Address"
                                }
                                onBlur={event =>
                                  formik.setFieldTouched("address", true)
                                }
                                onChange={(address: string) => {
                                  formik.setFieldValue("address", address);
                                }}
                                onSelect={({
                                  selection,
                                  zipCode,
                                  latitude,
                                  longitude,
                                }) => {
                                  setZipCode(zipCode);
                                  setLatitude(latitude);
                                  setLongitude(longitude);
                                  formik.setFieldValue("address", selection);
                                }}
                              />
                            </Box>
                          ) : null}
                          <TextField
                            required
                            sx={{
                              width: {
                                xs: "100%",
                                md: homeownerDetailsRequired ? "100%" : "49%",
                              },
                            }}
                            id="cellPhone"
                            name="cellPhone"
                            label={"Mobile Number"}
                            error={
                              formik.touched.cellPhone &&
                              Boolean(formik.errors.cellPhone)
                            }
                            helperText={
                              formik.touched.cellPhone &&
                              formik.errors.cellPhone
                            }
                            value={formik.values.cellPhone ?? ""}
                            InputProps={{
                              inputComponent: MaskedPhoneInput as unknown as
                                | ElementType<InputBaseComponentProps>
                                | undefined,
                            }}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        </Box>
                      </Box>
                    )}
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      mt={2}
                    >
                      <Button
                        variant="outlined"
                        color="inherit"
                        size={isMobile ? "small" : "medium"}
                        onClick={() => {
                          revertStep1();
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        sx={{
                          width: "fit-content",
                          ml: 1,
                        }}
                        disabled={disableHomeOwnerReviewRequiredNextButton}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          if (
                            brandLocationProfile?.homeownerJobApprovalSettings !==
                            HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                          ) {
                            updateJobHome({
                              jobId: newJob?.id,
                              cellPhone: `+1${formik.values.cellPhone.replace(
                                /[\s()-]/g,
                                ""
                              )}`,
                              firstName: formik.values.firstName,
                              lastName: formik.values.lastName,
                              streetAddress: formik.values.address,
                              zipCode,
                              latitude,
                              longitude,
                            });
                            return;
                          }
                          updateJob({
                            jobId: newJob?.id,
                            routeToHomeowner: homeownerApprovalSwitch,
                          });
                        }}
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                ) : null}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  color="primary"
                  sx={{
                    my: 1,
                    borderRadius: 1,
                    color: step >= 2 ? neutral[50] : "textPrimary",
                    bgcolor: step >= 2 ? tradeEngageColors.green : neutral[200],
                  }}
                >
                  {step > 2 ? <CheckIcon color="inherit" /> : "3"}
                </Avatar>
                {step >= 2 ? (
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                ) : null}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                <Typography
                  variant="overline"
                  {...(step > 2 ? { color: "#6C737F" } : {})}
                >
                  Generate Project
                </Typography>
                {step === 2 ? (
                  polling || updatingJobHome ? (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={0}
                      mt={3}
                    >
                      <Typography variant="subtitle1">
                        Fetching Job Summary
                      </Typography>
                      <Typography variant="caption" fontSize={"0.625rem"}>
                        Please do not close this window.
                      </Typography>
                      <LinearProgress sx={{ width: "100%", mt: 1 }} />
                    </Box>
                  ) : null
                ) : null}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  color="primary"
                  sx={{
                    my: 1,
                    borderRadius: 1,
                    color: step >= 3 ? neutral[50] : "textPrimary",
                    bgcolor: step >= 3 ? tradeEngageColors.green : neutral[200],
                  }}
                >
                  {step > 3 ? <CheckIcon color="inherit" /> : "4"}
                </Avatar>
                {step >= 3 ? (
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                ) : null}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                <Typography variant="overline">Modify Job</Typography>
                {step === 3 ? (
                  <Box width="100%" pt={3}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems={"baseline"}
                      width={"100%"}
                      flexDirection={"column"}
                      gap={1.5}
                    >
                      <Box
                        display="flex"
                        width={"100%"}
                        flexDirection={"column"}
                        gap={1.5}
                      >
                        <Typography gutterBottom variant="subtitle1">
                          Here is the Job Summary
                        </Typography>
                        <Typography gutterBottom variant="h6">
                          {job?.jobType}
                        </Typography>
                        {modifyJob ? (
                          <Autocomplete
                            options={(
                              fetchedRelatedJobSectors ??
                              Object.keys(JobSectors)
                            ).map(sector => {
                              if (sector === JobSectors.HVAC) {
                                return sector.toString();
                              }
                              return snakeCaseJobSectors[
                                sector.toString() as JobSectors
                              ];
                            })}
                            defaultValue={
                              snakeCaseJobSectors[
                                newJob?.sector as JobSectors
                              ] ?? snakeCaseJobSectors[JobSectors.OTHER]
                            }
                            value={formik.values.sector}
                            getOptionLabel={option => option}
                            renderInput={params => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                error={
                                  formik.touched.sector &&
                                  Boolean(formik.errors.sector)
                                }
                                helperText={
                                  formik.touched.sector && formik.errors.sector
                                }
                                value={formik.values.sector ?? []}
                                margin="normal"
                                InputLabelProps={{
                                  style: { marginBottom: "8px" },
                                }}
                                label="Select Service Categories"
                              />
                            )}
                            renderOption={(props, option, { selected }) => (
                              <MenuItem {...props} key={option} value={option}>
                                <Box
                                  display={"flex"}
                                  flexDirection={"row"}
                                  justifyContent={"space-between"}
                                  width={"100%"}
                                >
                                  {<Typography>{option}</Typography>}
                                  {selected ? (
                                    <CheckCircleOutlineIcon color="primary" />
                                  ) : null}
                                </Box>
                              </MenuItem>
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                // eslint-disable-next-line react/jsx-key
                                <Chip
                                  size="small"
                                  label={option}
                                  {...getTagProps({ index })}
                                  variant="outlined"
                                />
                              ))
                            }
                            onChange={(
                              _: SyntheticEvent,
                              newValue: string | null
                            ) => {
                              formik.setFieldValue(
                                "sector",
                                newValue as JobSectors
                              );
                              updateJobSector({
                                jobId: newJob?.id,
                                sector:
                                  reverseSnakeCaseJobSectors[
                                    newValue as JobSectors
                                  ],
                              });
                            }}
                            onBlur={formik.handleBlur}
                          />
                        ) : (
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={isMobile ? "start" : "center"}
                            flexDirection={isMobile ? "column" : "row"}
                            gap={1}
                            mb={isMobile ? 1 : 0}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"start"}
                              alignItems={isMobile ? "start" : "center"}
                              flexDirection={isMobile ? "column" : "row"}
                              gap={1}
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{ color: "#111927" }}
                              >
                                Service Category
                              </Typography>
                              <Chip
                                key={newJob?.sector}
                                sx={{
                                  color: "text.secondary",
                                  bgcolor: "#F8F9FA",
                                }}
                                label={
                                  snakeCaseJobSectors[
                                    newJob?.sector as JobSectors
                                  ] ?? snakeCaseJobSectors[JobSectors.OTHER]
                                }
                                size="small"
                              />
                            </Box>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                setModifyJob(true);
                              }}
                            >
                              Edit Service Category
                            </Button>
                          </Box>
                        )}
                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#111927" }}
                          >
                            Summary
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {newJob?.summary}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        width={"100%"}
                        justifyContent="start"
                        alignItems="start"
                        mt={1}
                      >
                        <Button
                          sx={{
                            mr: 1,
                          }}
                          color="primary"
                          variant="outlined"
                          disabled={submittingJob}
                          size={isMobile ? "small" : "medium"}
                          onClick={() => {
                            revertStep3();
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          sx={{
                            ml: 1,
                          }}
                          color="primary"
                          variant="contained"
                          disabled={submittingJob}
                          size={isMobile ? "small" : "medium"}
                          onClick={() => {
                            submitJob({ jobId: newJob?.id });
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        )}
      </DialogContent>

      {step !== 0 && newJob ? (
        <Box position={"sticky"} bottom={0} mb={2}>
          {" "}
          <Divider />
          <Box display={"flex"} gap={2} alignItems={"center"} mt={2} px={2}>
            <Typography variant="subtitle2" fontWeight={600}>
              Want to restart?
            </Typography>
            <Button
              startIcon={<CloseIcon />}
              variant="outlined"
              disabled={isDeletingJob}
              size="small"
              onClick={() => {
                deleteJob(newJob.id);
                reset();
              }}
            >
              Cancel Job
            </Button>
          </Box>
        </Box>
      ) : null}
    </Dialog>
  );
}
