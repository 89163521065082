import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { USER_TYPE } from "@/auth/types";
import { ChatMessageAdd } from "@/common/chat/ChatMessageAdd";
import { ParticipantsPopover } from "@/common/chat/ParticipantsPopover";
import { usePopover } from "@/common/hooks/use-popover";
import { useFetchCallRecordingById } from "@/company/api/useFetchCallRecordingById";
import { useJobChatContext } from "@/company/context/job-chat-context";

import { JobChatMessages } from "./JobChatMessages";

export const JobMessage = ({ disableChat }: { disableChat?: boolean }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    chats,
    buyingCompany,
    createdByCompany,
    homeowner,
    isLoading,
    updateChat,
    createdByUserWithinCompany,
  } = useJobChatContext();

  const popover = usePopover<HTMLButtonElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [searchParams] = useSearchParams();
  const callRecordingId = searchParams.get("call-recording-id");

  const {
    isLoading: loadingRecording,
    data: callRecording,
    refetch,
  } = useFetchCallRecordingById(callRecordingId, {
    enabled: !!callRecordingId,
  });

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const participants = [
    ...(buyingCompany
      ? [
          {
            id: buyingCompany?.id,
            avatar: buyingCompany?.logoUrl,
            name: buyingCompany?.name ?? "",
            type: USER_TYPE.COMPANY_TEAM_MEMBER,
          },
        ]
      : []),
    ...(createdByCompany
      ? [
          {
            id: createdByCompany?.id,
            avatar: createdByCompany?.logoUrl,
            name: createdByCompany?.name ?? "",
            type: USER_TYPE.COMPANY_TEAM_MEMBER,
          },
        ]
      : []),
    ...(homeowner
      ? [
          {
            id: homeowner?.id,
            name: `${homeowner?.firstName} ${homeowner?.lastName}`,
            type: USER_TYPE.HOMEOWNER,
          },
        ]
      : []),
    ...(createdByUserWithinCompany
      ? [
          {
            id: createdByUserWithinCompany?.id,
            name: `${createdByUserWithinCompany?.firstName} ${createdByUserWithinCompany?.lastName}`,
            type: USER_TYPE.COMPANY_TEAM_MEMBER,
            avatar: null,
            company: createdByCompany?.name,
          },
        ]
      : []),
  ];

  const participantIds = [
    ...(buyingCompany ? [buyingCompany?.id] : []),
    ...(createdByCompany ? [createdByCompany?.id] : []),
    ...(homeowner ? [homeowner?.id] : []),
    ...(createdByUserWithinCompany ? [createdByUserWithinCompany?.id] : []),
  ];

  const thread = {
    id: "1",
    messages: chats ?? [],
    participants,
    participantIds,
    type: "GROUP",
  };

  const messagesRef = useRef();

  useEffect(() => {
    // Scroll to the bottom when messages change
    if (messagesRef.current)
      messagesRef.current.scrollTop = messagesRef?.current?.scrollHeight;
  }, [chats]);

  return (
    <>
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        overflow={"auto"}
        pb={2}
      >
        <Box
          ref={popover.anchorRef}
          position={"static"}
          display="flex"
          alignItems="center"
          top={0}
          gap={2}
          width={"100%"}
          pb={1}
          px={3}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <AvatarGroup max={4}>
            {participants.map(participant => {
              if (participant.type === USER_TYPE.HOMEOWNER) {
                const firstInitial = (
                  participant.name?.split(" ")?.[0]?.[0] ?? ""
                ).toUpperCase();

                const secondInitial = (
                  participant.name?.split(" ")?.[1]?.[0] ?? ""
                ).toUpperCase();

                return (
                  <Avatar
                    key={participant.id}
                    sx={{
                      width: "32px",
                      height: "32px",
                      border: "1px solid gray !important",
                      bgcolor: "white",
                      "& img": { objectFit: "contain", bgcolor: "white" },
                    }}
                  >
                    {`${firstInitial}${secondInitial}` ?? ""}
                  </Avatar>
                );
              }
              if (
                participant.type === USER_TYPE.COMPANY_TEAM_MEMBER &&
                participant.avatar === null
              ) {
                const firstInitial = (
                  participant.name?.split(" ")?.[0]?.[0] ?? ""
                ).toUpperCase();

                const secondInitial = (
                  participant.name?.split(" ")?.[1]?.[0] ?? ""
                ).toUpperCase();

                return (
                  <Avatar
                    key={participant.id}
                    sx={{
                      width: "32px",
                      height: "32px",
                      border: "1px solid gray !important",
                      bgcolor: "white",
                      "& img": { objectFit: "contain", bgcolor: "white" },
                    }}
                  >
                    {`${firstInitial}${secondInitial}` ?? ""}
                  </Avatar>
                );
              }

              return (
                <Avatar
                  key={participant.id}
                  sx={{
                    width: "32px",
                    height: "32px",
                    border: "1px solid gray !important",
                    bgcolor: "white",
                    "& img": { objectFit: "contain", bgcolor: "white" },
                  }}
                  alt={participant.avatar}
                  src={participant.avatar}
                />
              );
            })}
          </AvatarGroup>
          <Typography variant="body2" color={"text.secondary"}>
            {participants?.length} participants in this chat
          </Typography>
        </Box>
        <Box
          ref={messagesRef}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: "1",
            overflowY: "auto",
            height: "100%",
            bgcolor: "#F8F9FA",
          }}
        >
          {" "}
          {isLoading ? (
            <Box py={3} textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <JobChatMessages
                chats={thread?.messages || []}
                participants={thread?.participants || []}
                initialChatAlert={
                  <Alert
                    severity="success"
                    variant="standard"
                    color="success"
                    sx={{ mt: 2 }}
                  >
                    This is the beginning of your conversation.
                  </Alert>
                }
              />
            </>
          )}
        </Box>
        {!disableChat ? (
          <Box position={"sticky"} bottom={0} px={1}>
            <Divider sx={{ mb: 2 }} />
            <ChatMessageAdd
              defaultText={
                callRecording?.followUpMessage
                  ? callRecording.followUpMessage
                  : ""
              }
              onSend={async (value: string) => {
                try {
                  updateChat(value);
                } catch {
                  enqueueSnackbar({
                    message:
                      "An error occurred while trying to send your message",
                  });
                }
              }}
            />
          </Box>
        ) : null}
      </Box>
      <ParticipantsPopover
        anchorEl={anchorEl}
        open={open}
        participants={participants}
        onClose={handlePopoverClose}
      />
    </>
  );
};
