import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TimelapseOutlinedIcon from "@mui/icons-material/TimelapseOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";

import { ToolTip } from "@/common/tool-tip/ToolTip";

interface Props {
  expiresAt: Date;
  setExpiringSoon: (expiringSoon: boolean) => void;
  showTimer?: boolean;
}

export const PendingDurationCircularProgress: FC<Props> = ({
  expiresAt,
  setExpiringSoon,
  showTimer = true,
}) => {
  const [expirationDuration, setExpirationDuration] = useState<number | null>(
    null
  );
  const [expirationTime, setExpirationTime] = useState<string | null>(null);

  const setExpirationDetails = () => {
    const currentDate = DateTime.utc();
    const expiresAtUTC = DateTime.fromISO(expiresAt.toString(), {
      zone: "utc",
    });
    const hourDiff = expiresAtUTC.diff(currentDate, "hours").hours;

    const hourDiffFormat = expiresAtUTC.diff(currentDate).toFormat("hh:mm");
    setExpirationTime(hourDiffFormat);

    setExpirationDuration(hourDiff);
    if (hourDiff < 12) {
      setExpiringSoon(true);
    }
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    setExpirationDetails();
    // Update the clock every second
    const intervalId = setInterval(() => {
      setExpirationDetails();
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  if (!expirationDuration || !expirationTime) return null;

  const hrs = expirationTime?.split(":")?.[0];
  const mins = expirationTime?.split(":")?.[1];
  const isExpiringSoon = expirationDuration < 12;

  return (
    <Box
      display={"flex"}
      gap={2}
      alignItems={isMobile ? "start" : "center"}
      flexDirection={"row"}
    >
      {showTimer ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
        >
          <Box
            sx={{
              padding: 1,
              background: "rgba(0, 0, 0, 0.04)",
              borderRadius: "12px",
              justifyContent: "center",
              alignItems: "center",
              width: "48px",
            }}
          >
            <Typography
              color={"text.secondary"}
              textAlign={"center"}
              variant="body2"
            >
              Hrs
            </Typography>
            <Typography
              sx={{
                ...(!isExpiringSoon ? {} : { color: "#F04438" }),
              }}
              textAlign={"center"}
              variant="h5"
            >
              {hrs}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            textAlign={"center"}
            color={"text.secondary"}
          >
            :
          </Typography>
          <Box
            sx={{
              padding: 1,
              background: "rgba(0, 0, 0, 0.04)",
              borderRadius: "12px",
              justifyContent: "center",
              alignItems: "center",
              width: "48px",
            }}
          >
            <Typography
              color={"text.secondary"}
              textAlign={"center"}
              variant="body2"
            >
              Mins
            </Typography>
            <Typography
              sx={{
                ...(!isExpiringSoon ? {} : { color: "#F04438" }),
              }}
              textAlign={"center"}
              variant="h5"
            >
              {mins}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={0.4}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={1}
          sx={{
            ...(!isExpiringSoon
              ? { bgcolor: "rgba(22, 179, 100, 0.08)" }
              : { bgcolor: "rgba(240, 68, 56, 0.08)" }),
            borderRadius: "12px",
            px: 3,
            py: 1,
          }}
        >
          {!isExpiringSoon ? (
            <TimerOutlinedIcon sx={{ color: "#16B364" }} />
          ) : (
            <TimelapseOutlinedIcon sx={{ color: "#F04438" }} />
          )}

          <Typography
            variant="subtitle2"
            sx={{
              ...(!isExpiringSoon
                ? { color: "#16B364" }
                : { color: "#F04438" }),
            }}
          >
            {!isExpiringSoon ? "Exclusively yours" : "Expiring Soon"}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={isMobile ? "start" : "center"}
          gap={0.5}
          px={1}
        >
          <Typography variant="caption" color={"text.secondary"}>
            Act fast while it is yours!
          </Typography>
          {isMobile ? null : (
            <ToolTip
              placement="bottom"
              message="TradeEngage jobs are exclusive to you. After 24 hours, they are sent to another referral partner. Act fast while the job is yours!"
            >
              <HelpOutlineIcon
                sx={{
                  background: "none",
                  color: "#6C737F",
                  width: "16px",
                  height: "16px",
                }}
              />
            </ToolTip>
          )}
        </Box>
      </Box>
    </Box>
  );
};
