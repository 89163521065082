import {
  AccessTime as AccessTimeIcon,
  AttachMoney as AttachMoneyIcon,
  CalendarToday as CalendarTodayIcon,
  Close as CloseIcon,
  PriceCheck as PriceCheckIcon,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";

import { UseModalState } from "@/lib/modals/useModalState";
import { TextEditorTextPreview } from "@/lib/text-editor/TextEditorTextPreview";
import { isDefined } from "@/utils/isDefined";

import { JobPromotionWithCompany } from "../types";
import { getReferralFeeText } from "../utils";
import { JobPromotionReferButton } from "./JobPromotionReferButton";

interface JobPromotionDetailsDrawerProps {
  modalState: UseModalState;
  jobPromotion: JobPromotionWithCompany;
}

export function JobPromotionDetailsDrawer(
  props: JobPromotionDetailsDrawerProps
) {
  const { jobPromotion, modalState } = props;

  const jobPromotionEndsInDays = Math.ceil(
    DateTime.fromISO(jobPromotion.endDate.toString(), {
      zone: "utc",
    }).diff(DateTime.utc(), "days").days
  );
  const jobPromotionExpiryLabel = () => {
    if (jobPromotionEndsInDays < 0) {
      return "Ended";
    }

    if (jobPromotionEndsInDays <= 1) {
      return "Ends Today";
    }

    return `Ends in ${jobPromotionEndsInDays} days`;
  };

  const isJobPromotionEndingSoon = jobPromotionEndsInDays <= 1;

  return (
    <Drawer
      anchor="right"
      open={modalState.modalIsOpen}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 400,
          paddingY: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
      onClose={modalState.closeModal}
    >
      <Stack gap={3}>
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
          paddingX={3}
        >
          <Box
            component="img"
            src={jobPromotion.company?.logoUrl}
            alt="company logo"
            sx={{
              height: "40px",
              width: "auto",
              maxWidth: "50%",
              objectFit: "contain",
              display: "block",
            }}
          />
          <IconButton
            aria-label="close"
            sx={{
              color: theme => theme.palette.grey[500],
            }}
            onClick={modalState.closeModal}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack
          gap={2}
          sx={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 210px)",
            paddingX: 3,
          }}
          flexGrow={1}
        >
          <Divider sx={{ width: "100%" }} />
          <Typography variant="h6">{jobPromotion.title}</Typography>
          <Stack gap={1}>
            <Typography variant="overline" color="text.disabled">
              Promotion Valid
            </Typography>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <CalendarTodayIcon fontSize="small" sx={{ color: "#B5BCC4" }} />
                <Typography variant="body2" color="text.secondary">
                  Until{" "}
                  {DateTime.fromISO(jobPromotion.endDate.toString(), {
                    zone: "utc",
                  })
                    .toLocal()
                    .toFormat("MM/dd")}
                </Typography>
              </Stack>
              <Chip
                size="small"
                icon={
                  isJobPromotionEndingSoon ? (
                    <AccessTimeIcon fontSize="small" />
                  ) : (
                    <CalendarTodayIcon fontSize="small" />
                  )
                }
                label={jobPromotionExpiryLabel()}
                sx={{
                  backgroundColor: isJobPromotionEndingSoon
                    ? "#FEE4E2"
                    : "#EDFCF2",
                  color: isJobPromotionEndingSoon ? "#F04438" : "#16B364",
                  borderRadius: 1,
                  ".MuiChip-icon": {
                    color: isJobPromotionEndingSoon ? "#F04438" : "#16B364",
                  },
                }}
              />
            </Stack>
          </Stack>
          <Divider sx={{ width: "100%" }} />
          <Stack gap={1}>
            <Typography variant="overline" color="text.disabled">
              Referral Fee
            </Typography>
            {isDefined(jobPromotion.leadPrice) && (
              <Stack direction="row" justifyContent={"space-between"}>
                <Stack direction="row" alignItems={"center"} gap={1}>
                  <AttachMoneyIcon fontSize="small" sx={{ color: "#B5BCC4" }} />
                  <Typography variant="subtitle1" color="primary">
                    {jobPromotion.leadPrice}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems={"center"} gap={1}>
                  <PriceCheckIcon fontSize="small" sx={{ color: "#B5BCC4" }} />
                  <Typography variant="body2" color="primary">
                    Pays for every referral
                  </Typography>
                </Stack>
              </Stack>
            )}
            {jobPromotion.payReferralFeeAfterJobIsDone && (
              <Stack direction="row" justifyContent={"space-between"}>
                <Stack direction="row" alignItems={"center"} gap={1}>
                  <AttachMoneyIcon fontSize="small" sx={{ color: "#B5BCC4" }} />
                  <Typography variant="subtitle1" color="primary">
                    {getReferralFeeText(jobPromotion)}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems={"center"} gap={1}>
                  <PriceCheckIcon fontSize="small" sx={{ color: "#B5BCC4" }} />
                  <Typography variant="body2" color="primary">
                    Pays when job closes
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
          <Divider sx={{ width: "100%" }} />
          <Stack gap={1}>
            <Typography variant="overline" color="text.disabled">
              WHAT’S INCLUDED
            </Typography>
            <Box sx={{ paddingY: 0.5 }}>
              <TextEditorTextPreview
                content={jobPromotion.description}
                fontSize="0.875rem"
              />
            </Box>
          </Stack>
          <Divider sx={{ width: "100%" }} />
          <Stack gap={1}>
            <Typography variant="overline" color="text.disabled">
              Courtesy of
            </Typography>
            <Typography variant="subtitle1">
              {jobPromotion.company.name}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={2} paddingX={3}>
        <Divider sx={{ width: "100%" }} />
        <JobPromotionReferButton jobPromotion={jobPromotion} />
      </Stack>
    </Drawer>
  );
}
