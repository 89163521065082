import { Close as CloseIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  debounce,
  Dialog,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useMemo, useState } from "react";

import { useCreateCompanyRelationships } from "@/company/api/useCreateCompanyRelationship";
import { useGetAllCompanies } from "@/company/api/useGellAllCompanies";
import { BasicCompanySchema } from "@/company/api/useGetAllBrands";
import { neutral } from "@/Theme/colors";

import { SEARCH_MINIMUM_CHAR_COUNT } from "./constants";

export interface CreateRelationshipDialogProps {
  open: boolean;
  onClickConfirm: () => void;
  onClickCancel: () => void;
  onClickClose: () => void;
  refetch: () => void;
}

export function CreateRelationshipDialog(props: CreateRelationshipDialogProps) {
  const { open, onClickCancel, onClickClose, refetch } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [keyword, setKeyword] = useState("");
  const debouncedSetter = useMemo(
    () => debounce((keyword: string) => setKeyword(keyword), 500),
    []
  );

  const [childKeyword, setChildKeyword] = useState("");

  const debouncedReferredSetter = useMemo(
    () =>
      debounce((childKeyword: string) => setChildKeyword(childKeyword), 500),
    []
  );

  const { mutateAsync: createCompanyRelationships, isLoading } =
    useCreateCompanyRelationships({
      onSuccess: () => {
        enqueueSnackbar({
          message: "Created company relationships successfully.",
          variant: "success",
        });
        onClickClose();
      },
      onError: () => {
        enqueueSnackbar({
          message: "Error while creating company relationships",
          variant: "error",
        });
      },
    });

  const [selectedParent, setSelectedParent] = useState<BasicCompanySchema>();

  const [selectedChildren, setSelectedChildren] = useState<
    Array<BasicCompanySchema>
  >([]);

  const { data: allParents, isLoading: isLoadingParentCompanies } =
    useGetAllCompanies(keyword, { enabled: !!keyword });

  const { data: allChildren, isLoading: isLoadingChildCompanies } =
    useGetAllCompanies(childKeyword, { enabled: !!childKeyword });

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
      sx={{ p: { xs: 0, md: 2 } }}
      onClose={() => onClickClose()}
    >
      <Box width="100%" p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Box>
            <Typography gutterBottom variant="h5">
              Create Company Relationships
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary">
              Select the parent and children companies from dropdown.
            </Typography>
          </Box>
          <IconButton onClick={() => onClickClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          mt={3}
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-start"}
          border={1}
          borderRadius={"20px"}
          p={3}
          borderColor={neutral[200]}
          width="100%"
        >
          <Autocomplete
            sx={{ width: "100%" }}
            id="parent"
            options={allParents ?? []}
            value={selectedParent}
            getOptionLabel={option =>
              `id=${option.id}, name=${option.name}, accountType=${option.accountType}`
            }
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                value={selectedParent}
                margin="normal"
                InputLabelProps={{
                  style: { marginBottom: "8px" },
                }}
                label="Select Parent Company"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} key={option} value={option}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                  overflow={"auto"}
                >
                  {
                    <Typography>{`id=${option.id}, name=${option.name}, accountType=${option.accountType}`}</Typography>
                  }
                  {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
                </Box>
              </MenuItem>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  size="small"
                  label={`id=${option.id}, name=${option.name}, accountType=${option.accountType}`}
                  {...getTagProps({ index })}
                  variant="outlined"
                />
              ))
            }
            onInputChange={(_: unknown, newInputValue: string) => {
              if (newInputValue.length >= SEARCH_MINIMUM_CHAR_COUNT) {
                debouncedSetter(newInputValue);
              }
            }}
            onChange={(_: SyntheticEvent, newValue) =>
              setSelectedParent(newValue)
            }
          />
        </Box>
        <Box
          mt={3}
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-start"}
          border={1}
          borderRadius={"20px"}
          p={3}
          borderColor={neutral[200]}
          width="100%"
        >
          <Autocomplete
            multiple
            disableCloseOnSelect
            sx={{ width: "100%" }}
            id="sectors"
            options={allChildren ?? []}
            value={selectedChildren ?? []}
            getOptionLabel={option =>
              `id=${option.id}, name=${option.name}, accountType=${option.accountType}`
            }
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                value={selectedChildren ?? []}
                margin="normal"
                InputLabelProps={{
                  style: { marginBottom: "8px" },
                }}
                label="Select Children Companies"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} key={option} value={option}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                  overflow={"auto"}
                >
                  {
                    <Typography>{`id=${option.id}, name=${option.name}, accountType=${option.accountType}`}</Typography>
                  }
                  {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
                </Box>
              </MenuItem>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  size="small"
                  label={`id=${option.id}, name=${option.name}, accountType=${option.accountType}`}
                  {...getTagProps({ index })}
                  variant="outlined"
                />
              ))
            }
            onInputChange={(_: unknown, newInputValue: string) => {
              if (newInputValue.length >= SEARCH_MINIMUM_CHAR_COUNT) {
                debouncedReferredSetter(newInputValue);
              }
            }}
            onChange={(_: SyntheticEvent, newValue) =>
              setSelectedChildren([...newValue])
            }
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={3}
          mt={2}
        >
          <Button color="inherit" variant="outlined" onClick={onClickCancel}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={!selectedChildren?.length || !selectedParent}
            onClick={() => {
              createCompanyRelationships({
                parentCompanyId: selectedParent?.id,
                childrenCompanyIds: selectedChildren.map(child => child.id),
              });
            }}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
}
