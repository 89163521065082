export enum TradeEngageLogoTypes {
  ICON_LIGHT = "ICON_LIGHT",
  ICON_DARK = "ICON_DARK",
  LOGO_LIGHT = "LOGO_LIGHT",
  LOGO_DARK = "LOGO_DARK",
}

export function TradeEngageLogo(
  props: React.ImgHTMLAttributes<HTMLImageElement> & {
    logotype?: TradeEngageLogoTypes;
  }
) {
  if (props.logotype == TradeEngageLogoTypes.ICON_LIGHT)
    return (
      <img
        alt="Trade Engage logo"
        src={"/icon.svg"}
        style={{ height: "33px" }}
        {...props}
      />
    );
  if (props.logotype == TradeEngageLogoTypes.ICON_DARK)
    return (
      <img
        alt="Trade Engage logo"
        src={"/icon.svg"}
        style={{ height: "33px" }}
        {...props}
      />
    );
  if (props.logotype == TradeEngageLogoTypes.LOGO_LIGHT)
    return (
      <img
        alt="Trade Engage logo"
        src={"/logo.svg"}
        style={{ height: "33px" }}
        {...props}
      />
    );
  if (props.logotype == TradeEngageLogoTypes.LOGO_DARK)
    return (
      <img
        alt="Trade Engage logo"
        src={"/logo.svg"}
        style={{ height: "33px" }}
        {...props}
      />
    );

  return (
    <img
      alt="Trade Engage logo"
      src={"/logo.svg"}
      style={{ height: "33px" }}
      {...props}
    />
  );
}
