import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  tabsClasses,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { ModifyJobHomeownerDialog } from "@/common/ModifyJobHomeownerDialog";
import { isCompanyActive } from "@/common/utils/accessControl";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import CancelJobDialog from "@/company/components/screens/job-calls-page/CancelJobDialog";
import { JobChatProvider } from "@/company/context/job-chat-context";

import { useGetJobById } from "../api";
import { useDeleteJob } from "../api/useDeleteJob";
import { useSubmitJob } from "../api/useSubmitJob";
import { useUpdateJobView } from "../api/useUpdateJobView";
import { JobStatus, RoutedJobStatus, Source } from "../types/job";
import { getLastActivityJob } from "../utils";
import { HomeownerTab } from "./HomeownerTab";
import JobAttachments from "./JobAttachments";
import { JobMessage } from "./JobMessageTab";
import { JobOverview } from "./JobOverview";
import { JobProjectTab } from "./JobProjectTab";

interface JobDrawerProps {
  onClose?: () => void;
  onJobClose?: () => void;
  refreshJobs?: () => void;
  inactiveReason?: string;
  open?: boolean;
  source?: Source;
  jobStatus?: JobStatus | RoutedJobStatus;
  lastActivity?: string;
  jobId: number;
  isRoutedJob?: boolean;
  actionArea?: ReactNode;
  defaultTab?: JobDrawerTabsEnum;
}

export enum JobDrawerTabsEnum {
  PROJECT = "project",
  HOMEOWNER = "homeowner",
  MESSAGE = "message",
  REFERRAL_PARTNER = "referral-partner",
  ATTACHMENTS = "attachments",
}

export const JobDrawer: FC<JobDrawerProps> = props => {
  const {
    onClose,
    open = false,
    jobId,
    jobStatus,
    lastActivity,
    actionArea,
    defaultTab,
    inactiveReason,
    refreshJobs,
    isRoutedJob,
    onJobClose,
    source,
    ...other
  } = props;
  const [currentTab, setCurrentTab] = useState<JobDrawerTabsEnum>(
    defaultTab ?? JobDrawerTabsEnum.PROJECT
  );

  const { data: job, isLoading, refetch: refetchJob } = useGetJobById(jobId);

  const { session } = useAuth();
  const [jobSubmitted, setJobSubmitted] = useState(false);
  const [showMessageTooltip, setShowMessageTooltip] = useState(false);
  const [showHomeownerTooltip, setShowHomeownerTooltip] = useState(false);
  const [showAttachmentsTooltip, setShowAttachmentsTooltip] = useState(false);

  const [jobClosed, setJobClosed] = useState(false);

  const company = session?.company;
  const [newHomeownerAdded, setNewJobHomeownerAdded] = useState(false);

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: JobDrawerTabsEnum): void => {
      if (value === JobDrawerTabsEnum.MESSAGE) setShowMessageTooltip(false);
      if (value === JobDrawerTabsEnum.HOMEOWNER) setShowHomeownerTooltip(false);
      if (value === JobDrawerTabsEnum.ATTACHMENTS)
        setShowAttachmentsTooltip(false);
      setCurrentTab(value);
    },
    []
  );

  useEffect(() => {
    if (defaultTab) {
      if (defaultTab === JobDrawerTabsEnum.MESSAGE)
        setShowMessageTooltip(false);
      if (defaultTab === JobDrawerTabsEnum.HOMEOWNER)
        setShowHomeownerTooltip(false);
      setCurrentTab(defaultTab);
    }
  }, [defaultTab]);

  const { isLoading: submittingJob, mutateAsync: submitJob } = useSubmitJob({
    onSuccess: () => {
      refreshJobs?.();
      setJobSubmitted(true);
      enqueueSnackbar({
        message: "Job submitted successfully.",
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while submitting the job.`,
        variant: "error",
      });
    },
  });

  const [cancelJobDialogOpen, setCancelJobDialogOpen] = useState(false);

  const { isLoading: isDeletingJob, mutateAsync: deleteJob } = useDeleteJob({
    onSuccess: () => {
      refreshJobs?.();
      setJobClosed(true);
      setCancelJobDialogOpen(false);
      onJobClose?.();
      onClose?.();
      enqueueSnackbar({
        message: "Job closed successfully.",
        variant: "success",
      });
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while cancelling the job";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const { mutateAsync: updateJobView } = useUpdateJobView({
    onError: error => {
      console.error("Error while adding job view by routed partner", { error });
    },
  });
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    if (job && !job.listingExtraInfo?.routedPartnerViewedAt && isRoutedJob) {
      updateJobView({ jobId });
    }
  }, [job, jobId, jobStatus]);

  useEffect(() => {
    if (job?.status === JobStatus.CLOSED) {
      setJobClosed(true);
    }
  }, [job]);

  useEffect(() => {
    refetchJob();
  }, [jobStatus]);

  useEffect(() => {
    if (job?.id && defaultTab !== JobDrawerTabsEnum.ATTACHMENTS) {
      setShowAttachmentsTooltip(true);
    }
  }, [job?.id, defaultTab]);

  useEffect(() => {
    if (jobStatus === RoutedJobStatus.PENDING_PAYMENT && isRoutedJob) {
      setShowHomeownerTooltip(true);
      setShowMessageTooltip(true);
    }
  }, [jobStatus, isRoutedJob]);

  const [modifyJobHomeownerDialogOpen, setModifyJobHomeownerDialogOpen] =
    useState(false);

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 500,
          px: 1.5,
          pt: 1.5,
        },
      }}
      {...(source === Source.CALL_CENTER && isMobile
        ? { sx: { zIndex: 1400 } }
        : {})}
      onClose={onClose}
      {...other}
    >
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          flexDirection={"column"}
          height="100%"
          width="100%"
          gap={1}
        >
          <CircularProgress />
          <Typography variant="body1">
            Hang tight! We&apos;re fetching the job details.
          </Typography>
        </Box>
      ) : (
        <>
          <Paper
            sx={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            <Box
              position={"sticky"}
              sx={{ top: 0, zIndex: 400, pt: 1, background: "white" }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems={"center"}
                pb={2}
                sx={{ px: { xs: 1, sm: 3 }, pt: { xs: 1, sm: 3 } }}
              >
                <Typography variant="h6">{job?.jobType}</Typography>
                <IconButton
                  aria-label="close"
                  sx={{
                    color: theme => theme.palette.grey[500],
                  }}
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider sx={{ width: "100%" }} />
              {source === Source.TECHNICIAN_DASHBOARD ? (
                <Tabs
                  value={currentTab}
                  variant="scrollable"
                  scrollButtons={isMobile ? true : false}
                  allowScrollButtonsMobile={isMobile ? true : false}
                  sx={{
                    px: isMobile ? 0 : 3,
                    ...(!isMobile
                      ? {}
                      : {
                          [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { display: "none" },
                          },
                        }),
                  }}
                  onChange={handleTabsChange}
                >
                  <Tab
                    value={JobDrawerTabsEnum.PROJECT}
                    label={"Project Details"}
                  />
                  {jobStatus === RoutedJobStatus.PENDING_REVIEW &&
                  job?.createdByUserType !== USER_TYPE.HOMEOWNER ? (
                    <Tab
                      value={JobDrawerTabsEnum.REFERRAL_PARTNER}
                      label="Referral Partner"
                    />
                  ) : null}
                  {(job?.createdByUserType === USER_TYPE.HOMEOWNER ||
                    jobStatus === RoutedJobStatus.PAID ||
                    job?.createdByCompanyId === company?.id ||
                    jobStatus == RoutedJobStatus.PENDING_PAYMENT) &&
                  job?.homeId ? (
                    <Tab
                      value={JobDrawerTabsEnum.HOMEOWNER}
                      label="Homeowner"
                    />
                  ) : null}
                  {(jobStatus == RoutedJobStatus.PAID ||
                    job?.createdByCompanyId === company?.id ||
                    jobStatus == RoutedJobStatus.PENDING_PAYMENT) &&
                  job?.homeId ? (
                    <Tab value={JobDrawerTabsEnum.MESSAGE} label="Message" />
                  ) : null}
                  <Tooltip
                    arrow
                    open={showAttachmentsTooltip}
                    PopperProps={{
                      modifiers: [
                        {
                          name: "zIndex",
                          enabled: true,
                          phase: "beforeWrite",
                          fn: ({ state }) => {
                            state.styles.popper.zIndex = 1500; // Adjust the zIndex as needed
                          },
                        },
                      ],
                    }}
                    title={
                      <Box display={"flex"} maxWidth={"140px"}>
                        <Typography variant="caption" lineHeight={1.5}>
                          {isRoutedJob ? "See" : "Upload"} job attachments here!
                        </Typography>
                        <CloseIcon
                          fontSize="small"
                          sx={{
                            p: 0,
                            width: "16px",
                            height: "16px",
                            cursor: "pointer",
                          }}
                          onClick={event => {
                            event.stopPropagation();
                            setShowAttachmentsTooltip(false);
                          }}
                        />
                      </Box>
                    }
                    componentsProps={{
                      tooltip: {
                        className: "bounce-tooltip", // Apply the animation class here
                      },
                      arrow: {
                        style: { color: "#16b364" }, // Change the arrow color to match the tooltip background
                      },
                    }}
                    placement="bottom"
                  >
                    <Tab
                      value={JobDrawerTabsEnum.ATTACHMENTS}
                      label="Attachments"
                    />
                  </Tooltip>
                </Tabs>
              ) : (
                <Tabs
                  value={currentTab}
                  variant="scrollable"
                  scrollButtons={isMobile ? true : false}
                  allowScrollButtonsMobile={isMobile ? true : false}
                  sx={{
                    px: isMobile ? 0 : 3,
                    ...(!isMobile
                      ? {}
                      : {
                          [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { display: "none" },
                          },
                        }),
                  }}
                  onChange={handleTabsChange}
                >
                  <Tab
                    value={JobDrawerTabsEnum.PROJECT}
                    label={isMobile ? "Project" : "Project Details"}
                  />
                  {jobStatus === RoutedJobStatus.PENDING_REVIEW &&
                  job?.createdByUserType !== USER_TYPE.HOMEOWNER ? (
                    <Tab
                      value={JobDrawerTabsEnum.REFERRAL_PARTNER}
                      label="Referral Partner"
                    />
                  ) : null}
                  {(job?.createdByUserType === USER_TYPE.HOMEOWNER ||
                    jobStatus === RoutedJobStatus.PAID ||
                    job?.createdByCompanyId === company?.id ||
                    jobStatus == RoutedJobStatus.PENDING_PAYMENT) &&
                  job?.homeId &&
                  isCompanyActive(company?.status) ? (
                    <Tooltip
                      arrow
                      open={showHomeownerTooltip}
                      PopperProps={{
                        modifiers: [
                          {
                            name: "zIndex",
                            enabled: true,
                            phase: "beforeWrite",
                            fn: ({ state }) => {
                              state.styles.popper.zIndex = 1500; // Adjust the zIndex as needed
                            },
                          },
                        ],
                      }}
                      title={
                        <Box display={"flex"} maxWidth={"140px"}>
                          <Typography variant="caption" lineHeight={1.5}>
                            See the homeowner information now.
                          </Typography>
                          <CloseIcon
                            fontSize="small"
                            sx={{
                              p: 0,
                              width: "16px",
                              height: "16px",
                              cursor: "pointer",
                            }}
                            onClick={event => {
                              event.stopPropagation();
                              setShowHomeownerTooltip(false);
                            }}
                          />
                        </Box>
                      }
                      componentsProps={{
                        tooltip: {
                          className: "bounce-tooltip", // Apply the animation class here
                        },
                        arrow: {
                          style: { color: "#16b364" }, // Change the arrow color to match the tooltip background
                        },
                      }}
                      placement="bottom"
                    >
                      <Tab
                        value={JobDrawerTabsEnum.HOMEOWNER}
                        label="Homeowner"
                      />
                    </Tooltip>
                  ) : null}
                  {(jobStatus == RoutedJobStatus.PAID ||
                    job?.createdByCompanyId === company?.id ||
                    jobStatus == RoutedJobStatus.PENDING_PAYMENT) &&
                  job?.homeId &&
                  isCompanyActive(company?.status) ? (
                    <Tooltip
                      arrow
                      open={showMessageTooltip}
                      PopperProps={{
                        modifiers: [
                          {
                            name: "zIndex",
                            enabled: true,
                            phase: "beforeWrite",
                            fn: ({ state }) => {
                              state.styles.popper.zIndex = 1500; // Adjust the zIndex as needed
                            },
                          },
                        ],
                      }}
                      title={
                        <Box display={"flex"} maxWidth={"140px"}>
                          <Typography variant="caption" lineHeight={1.5}>
                            Send a message to confirm it&apos;s booked.
                          </Typography>
                          <CloseIcon
                            fontSize="small"
                            sx={{
                              p: 0,
                              width: "16px",
                              height: "16px",
                              cursor: "pointer",
                            }}
                            onClick={event => {
                              event.stopPropagation();
                              setShowMessageTooltip(false);
                            }}
                          />
                        </Box>
                      }
                      componentsProps={{
                        tooltip: {
                          className: "bounce-tooltip", // Apply the animation class here
                        },
                        arrow: {
                          style: { color: "#16b364" }, // Change the arrow color to match the tooltip background
                        },
                      }}
                      placement="top"
                    >
                      <Tab value={JobDrawerTabsEnum.MESSAGE} label="Message" />
                    </Tooltip>
                  ) : null}
                  <Tooltip
                    arrow
                    open={showAttachmentsTooltip}
                    PopperProps={{
                      modifiers: [
                        {
                          name: "zIndex",
                          enabled: true,
                          phase: "beforeWrite",
                          fn: ({ state }) => {
                            state.styles.popper.zIndex = 1500; // Adjust the zIndex as needed
                          },
                        },
                      ],
                    }}
                    title={
                      <Box display={"flex"} maxWidth={"140px"}>
                        <Typography variant="caption" lineHeight={1.5}>
                          {isRoutedJob ? "See" : "Upload"} job attachments here!
                        </Typography>
                        <CloseIcon
                          fontSize="small"
                          sx={{
                            p: 0,
                            width: "16px",
                            height: "16px",
                            cursor: "pointer",
                          }}
                          onClick={event => {
                            event.stopPropagation();
                            setShowAttachmentsTooltip(false);
                          }}
                        />
                      </Box>
                    }
                    componentsProps={{
                      tooltip: {
                        className: "bounce-tooltip", // Apply the animation class here
                      },
                      arrow: {
                        style: { color: "#16b364" }, // Change the arrow color to match the tooltip background
                      },
                    }}
                    placement="bottom"
                  >
                    <Tab
                      value={JobDrawerTabsEnum.ATTACHMENTS}
                      label="Attachments"
                    />
                  </Tooltip>
                </Tabs>
              )}
              <Divider />
            </Box>
            {source !== Source.TECHNICIAN_DASHBOARD ? (
              <Box
                py={2}
                sx={{
                  px: {
                    ...(currentTab === JobDrawerTabsEnum.ATTACHMENTS
                      ? { xs: 0 }
                      : { xs: 1, sm: 3 }),
                  },
                }}
                height={"100%"}
                overflow={"auto"}
              >
                {currentTab === JobDrawerTabsEnum.PROJECT && job ? (
                  <JobProjectTab
                    job={job}
                    isRoutedJob={isRoutedJob}
                    jobStatus={jobStatus}
                    lastActivity={lastActivity}
                    inactiveReason={inactiveReason}
                    source={source}
                    changeTab={(tab: JobDrawerTabsEnum) => {
                      setCurrentTab(tab);
                    }}
                  />
                ) : null}
                {currentTab === JobDrawerTabsEnum.HOMEOWNER && job ? (
                  <HomeownerTab jobId={job?.id} jobStatus={job.status} />
                ) : null}
                {currentTab === JobDrawerTabsEnum.REFERRAL_PARTNER &&
                jobStatus === RoutedJobStatus.PENDING_REVIEW &&
                job?.createdByCompanyId ? (
                  <JobOverview companyId={job?.createdByCompanyId} />
                ) : null}
                {currentTab === JobDrawerTabsEnum.MESSAGE && job?.homeId ? (
                  <JobChatProvider jobId={job?.id}>
                    <JobMessage />
                  </JobChatProvider>
                ) : null}
                {currentTab === JobDrawerTabsEnum.ATTACHMENTS ? (
                  <JobAttachments
                    jobId={job?.id}
                    isRoutedJob={isRoutedJob}
                    status={jobStatus}
                  />
                ) : null}
              </Box>
            ) : (
              <Box
                py={2}
                sx={{ px: { xs: 1, sm: 3 } }}
                height={"100%"}
                overflow={"auto"}
              >
                {currentTab === JobDrawerTabsEnum.PROJECT && job ? (
                  <JobProjectTab
                    job={job}
                    isRoutedJob={isRoutedJob}
                    jobStatus={jobStatus}
                    lastActivity={getLastActivityJob(job)}
                    inactiveReason={inactiveReason}
                    source={source}
                    changeTab={(tab: JobDrawerTabsEnum) => {
                      setCurrentTab(tab);
                    }}
                  />
                ) : null}
                {currentTab === JobDrawerTabsEnum.HOMEOWNER && job ? (
                  <HomeownerTab jobId={job?.id} jobStatus={job.status} />
                ) : null}
                {currentTab === JobDrawerTabsEnum.MESSAGE && job?.homeId ? (
                  <JobChatProvider jobId={job?.id}>
                    <JobMessage />
                  </JobChatProvider>
                ) : null}
                {currentTab === JobDrawerTabsEnum.ATTACHMENTS ? (
                  <JobAttachments
                    jobId={job?.id}
                    isRoutedJob={isRoutedJob}
                    status={jobStatus}
                  />
                ) : null}
              </Box>
            )}
          </Paper>
          {modifyJobHomeownerDialogOpen ? (
            <ModifyJobHomeownerDialog
              job={job}
              isOpen={modifyJobHomeownerDialogOpen}
              onClose={homeownerAdded => {
                if (homeownerAdded) {
                  setNewJobHomeownerAdded(homeownerAdded);
                }
                setModifyJobHomeownerDialogOpen(false);
              }}
            />
          ) : null}
          {actionArea ? (
            <>
              <Divider />
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                position={"sticky"}
                bottom={0}
                bgcolor={"white"}
                spacing={2}
                p={3}
              >
                {actionArea}
              </Stack>
            </>
          ) : null}
          {job && !job.homeId && !newHomeownerAdded ? (
            <Box
              position={"sticky"}
              bottom={0}
              mb={2}
              ml={isMobile ? 0 : 2}
              sx={{ background: "white" }}
            >
              <Divider sx={{ mb: 2 }} />
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  setModifyJobHomeownerDialogOpen(true);
                }}
              >
                Add Homeowner Details
              </Button>
            </Box>
          ) : null}
          {(job?.homeId || newHomeownerAdded) &&
          job?.status === JobStatus.OPPORTUNITY &&
          !jobSubmitted ? (
            <Box
              position={"sticky"}
              bottom={0}
              mb={2}
              ml={isMobile ? 0 : 2}
              sx={{ background: "white" }}
            >
              <Divider sx={{ mb: 2 }} />
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={submittingJob}
                onClick={() => {
                  submitJob({ jobId: job?.id });
                }}
              >
                Submit
              </Button>
            </Box>
          ) : null}
          {job?.status === JobStatus.DRAFT && !jobClosed ? (
            <Box
              position={"sticky"}
              bottom={0}
              mb={2}
              ml={isMobile ? 0 : 2}
              sx={{ background: "white" }}
            >
              <Divider sx={{ mb: 2 }} />
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={isDeletingJob}
                onClick={() => {
                  setCancelJobDialogOpen(true);
                }}
              >
                Cancel Job
              </Button>
            </Box>
          ) : null}
          {cancelJobDialogOpen ? (
            <CancelJobDialog
              deletingJob={isDeletingJob}
              open={cancelJobDialogOpen}
              handleClose={() => setCancelJobDialogOpen(false)}
              handleConfirm={() => {
                deleteJob(job.id);
              }}
              job={job}
            />
          ) : null}
        </>
      )}
    </Drawer>
  );
};
