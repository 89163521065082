import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

export const USE_GET_UNREAD_CHATS_COUNT_URI = "/chats/unread-count";

export function useFetchUnreadMessagesCount(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<number> {
  return useQuery({
    queryKey: [USE_GET_UNREAD_CHATS_COUNT_URI],
    queryFn: async () => {
      const response = await API.get(USE_GET_UNREAD_CHATS_COUNT_URI);
      return response.data.data;
    },
    ...options,
  });
}
