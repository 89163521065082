import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import { ReactNode } from "react";

export interface TransactionCardProps {
  amount: number;
  finalBalance: number;
  transactionType: string;
  createdAt: string;
  isPositiveTransaction: boolean;
  secondaryText?: string;
  children?: ReactNode;
}

export function TransactionCard(props: TransactionCardProps) {
  const {
    transactionType,
    isPositiveTransaction,
    createdAt,
    amount,
    finalBalance,
    secondaryText,
    children,
  } = props;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {isPositiveTransaction ? (
          <NorthEastOutlinedIcon
            fontSize="large"
            color="info"
            sx={{
              bgcolor: theme => theme.palette.primary.light,
              color: theme => theme.palette.primary.main,
              borderRadius: 30,
              p: 1,
            }}
          />
        ) : (
          <SouthEastOutlinedIcon
            fontSize="large"
            sx={{
              bgcolor: "#FEF3F2",
              color: theme => theme.palette.error.main,
              borderRadius: 30,
              p: 1,
            }}
          />
        )}
        <Box ml={2}>
          <Typography gutterBottom variant="body1">
            {_.startCase(transactionType.toLowerCase())}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {DateTime.fromISO(createdAt.toString(), {
              zone: "utc",
            })
              .toLocal()
              .toFormat("MM/dd/yy")}
            {secondaryText ? ` | ${secondaryText}` : null}
          </Typography>
          {children}
        </Box>
      </Box>
      <Box textAlign="right">
        <Typography
          gutterBottom
          variant="body2"
          sx={{
            color: theme =>
              isPositiveTransaction
                ? theme.palette.primary.main
                : theme.palette.error.main,
          }}
        >
          {`${isPositiveTransaction ? "+" : "-"} $${Math.abs(amount).toFixed(
            2
          )}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          ${finalBalance}
        </Typography>
      </Box>
    </Box>
  );
}
