import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

const CancelJobDialog = ({
  open,
  deletingJob,
  handleClose,
  handleConfirm,
  job,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Payment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Cancel Job? Are you sure you want to cancel {job.jobType}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={deletingJob} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={deletingJob}
          onClick={handleConfirm}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelJobDialog;
