import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";

import { JobSectors } from "@/company/jobs/constants";
import { snakeCaseJobSectors } from "@/company/utils";

import { useBrandOverlapSearchFilterContext } from "../contexts/SearchFilterContext";

export default function ServiceCategoryCheckboxList({
  isAllChecked,
}: {
  isAllChecked: boolean;
}) {
  const { addFilter, filters } = useBrandOverlapSearchFilterContext();

  React.useEffect(() => {
    if (isAllChecked) {
      addFilter({
        sectors: Object.keys(JobSectors).map(sector => {
          if (sector === JobSectors.HVAC) {
            return sector.toString();
          }
          return snakeCaseJobSectors[sector.toString() as JobSectors];
        }) as Array<JobSectors>,
      });
    }
  }, [isAllChecked]);

  return (
    <Box>
      <Autocomplete
        multiple
        disableCloseOnSelect
        size="small"
        sx={{
          zIndex: 100,
          maxHeight: "260px",
          overflow: "auto",
          maxWidth: "300px",
        }}
        options={Object.keys(JobSectors).map(sector => {
          if (sector === JobSectors.HVAC) {
            return sector.toString();
          }
          return snakeCaseJobSectors[sector.toString() as JobSectors];
        })}
        value={filters.sectors ?? []}
        getOptionLabel={option => option}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            value={filters.sectors ?? []}
            margin="normal"
            InputLabelProps={{
              style: { marginBottom: "8px" },
            }}
            label="Select Service Categories"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props} key={option} value={option}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {<Typography>{option}</Typography>}
              {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
            </Box>
          </MenuItem>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              size="small"
              label={option}
              {...getTagProps({ index })}
              variant="outlined"
            />
          ))
        }
        onChange={(_: React.SyntheticEvent, newValue: string[] | null) => {
          addFilter({ sectors: newValue ? newValue : [] });
        }}
      />
    </Box>
  );
}
