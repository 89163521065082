import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { JobSectors } from "../jobs/constants";

export function useGetRelevanceOrderedJobSectorsList(
  companyId: number,
  options: UseQueryOptions<any>
): UseQueryResult<Array<JobSectors>> {
  const uri = `/companies/${companyId}/recommended-referral-job-sectors`;
  return useQuery({
    queryKey: [uri],
    queryFn: async () => {
      const response = await API.get(uri);
      return response.data.data;
    },
    ...options,
  });
}
