import ArchiveIcon from "@mui/icons-material/Archive";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  formatDateTime,
  formatToDateTime,
} from "@/analytics/components/JobsTable";
import BrandOverlapPage from "@/brand-overlap/BrandOverlapPage";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useGetTableViews } from "@/company/api/useGetTableViews";
import { useDeleteTableView } from "@/company/jobs/api/useDeleteTableView";
import { getGridDateOperatorsDataGrid } from "@/utils";

import InternalAdminCompany from "../InternalAdminCompany";
import InternalAdminRecommendedPartners from "../InternalAdminRecommendedPartners";
import InternalAdminJobsTable from "../jobs/InternalAdminJobsTable";
import ReferralInvitationsInternalAdmin from "../ReferralInvitations";
import InternalAdminUsersTable from "../users/InternalAdminUsersTable";
import {
  CustomToolbar,
  drawerWidth,
  getGridStringOperatorsDataGrid,
  Main,
} from "../utils";
import DeleteTableConfirmationDialog from "./DeleteSavedSearchConfirmationDialog";

export enum TableType {
  COMPANY = "company",
  BRAND_OVERLAP = "brand overlap",
  JOB = "job",
  REFERRAL_INVITATION = "referral invitation",
  RECOMMENDED_PARTNERS = "recommended partners",
  USER = "user",
}

export default function SavedSearchTable() {
  const [rows, setRows] = useState([]);

  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const { tableViewId } = useParams();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [deleteTableViewId, setDeleteTableViewId] = useState<
    number | undefined
  >();

  const {
    data: tableViews,
    isFetching,
    isLoading,
    refetch,
  } = useGetTableViews({ refetchOnWindowFocus: false });

  const [companyTableId, setCompanyTableId] = useState<number | undefined>();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (params.field === "actions") {
      return;
    }
    navigate(`/admin/saved-searches/${params.row.id}`);
  };

  useEffect(() => {
    setRows(tableViews);
  }, [tableViews]);

  useEffect(() => {
    refetch();
  }, [tableViewId]);

  let columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "tableName",
      headerName: "Table Name",
      type: "singleSelect",
      renderCell: params => {
        return (
          <Chip
            key={params.row?.tableName}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={params.row?.tableName?.replaceAll("_", " ")}
            size="small"
          />
        );
      },
      valueOptions: Object.keys(TableType),
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) => formatToDateTime(row?.createdAt),
      renderCell: params => formatDateTime(params.row?.createdAt),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <Tooltip
            key={`${id}-close-tooltip`}
            title="Click to delete this view"
          >
            <GridActionsCellItem
              key={`${id}-delete`}
              label="Delete"
              color="inherit"
              icon={<ArchiveIcon />}
              onClick={() => {
                setDeleteTableViewId(row.id);
              }}
            />
          </Tooltip>,
        ];
      },
    },
  ];
  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  const { isLoading: isDeletingTableView, mutateAsync: deleteTableView } =
    useDeleteTableView({
      onSuccess: () => {
        setDeleteTableViewId(undefined);
        refetch();
        enqueueSnackbar({
          message: "Search deleted successfully",
          variant: "success",
        });
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "There was an error while deleting the search";
        enqueueSnackbar({
          message,
          variant: "error",
        });
      },
    });

  useEffect(() => {
    if (tableViewId && tableViews?.length) {
      const table = tableViews.find(table => table.id == tableViewId);
      if (table) {
        setCompanyTableId(table.id);
      }
      return;
    }
    setCompanyTableId(undefined);
  }, [tableViewId, tableViews]);

  if (companyTableId) {
    const table = tableViews?.find(table => table.id === companyTableId);
    if (table?.tableName === TableType.COMPANY) {
      return (
        <InternalAdminCompany
          preFilledColumns={table?.columns}
          payload={table?.body}
          name={table?.name}
        />
      );
    }
    if (table?.tableName === TableType.BRAND_OVERLAP) {
      return (
        <BrandOverlapPage
          preFilledColumns={table?.columns}
          payload={table?.body}
          name={table?.name}
        />
      );
    }
    if (table?.tableName === TableType.REFERRAL_INVITATION) {
      return (
        <ReferralInvitationsInternalAdmin
          preFilledColumns={table?.columns}
          payload={table?.body}
          name={table?.name}
        />
      );
    }
    if (table?.tableName === TableType.JOB) {
      return (
        <InternalAdminJobsTable
          preFilledColumns={table?.columns}
          payload={table?.body}
          name={table?.name}
        />
      );
    }
    if (table?.tableName === TableType.USER) {
      return (
        <InternalAdminUsersTable
          preFilledColumns={table?.columns}
          payload={table?.body}
          name={table?.name}
        />
      );
    }
    if (table?.tableName === TableType.RECOMMENDED_PARTNERS) {
      return (
        <InternalAdminRecommendedPartners
          preFilledColumns={table?.columns}
          payload={table?.body}
          name={table?.name}
        />
      );
    }
  }

  return (
    <Box display={"flex"} sx={{ height: "100%" }} overflow={"auto"}>
      <Main
        open={true}
        sx={{
          width: expanded ? "0%" : `calc(100% - ${drawerWidth}px)`,
          display: expanded ? "none" : "visible",
        }}
      >
        <Box display={"flex"} alignItems={"center"} p={3}>
          <Typography variant="h5">Saves Searches</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={3}
          py={1}
        >
          <Box
            display={"flex"}
            gap={3}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="subtitle1" fontSize={"16px"}>
              {tableViews?.length
                ? `Showing ${Intl.NumberFormat("en-US").format(
                    tableViews?.length ?? 0
                  )} searches`
                : "Fetching results"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 260px)",
            px: 3,
          }}
          position={"relative"}
        >
          <DataGridPremium
            checkboxSelection
            disableRowSelectionOnClick
            pagination
            cellSelection
            columns={columns}
            loading={isFetching || isLoading}
            slots={{
              toolbar: () => <CustomToolbar fileName="Saved_Searches" />,
            }}
            sx={{
              "& .MuiDataGrid-row": {
                "--rowBorderColor": "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withRightBorder": {
                borderRightColor: "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withLeftBorder": {
                borderLeftColor: "#D9D9D9",
              },
            }}
            rows={rows ?? []}
            editMode="row"
            rowModesModel={rowModesModel}
            initialState={{
              density: "compact",
              pinnedColumns: {
                left: [
                  GRID_CHECKBOX_SELECTION_COL_DEF.field,
                  "logoUrl",
                  "name",
                ],
                right: ["actions"],
              },
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            slotProps={{
              toolbar: { setRowModesModel },
            }}
            paginationModel={paginationModel}
            paginationMode="server"
            rowCount={tableViews?.length ?? 0}
            pageSizeOptions={[5, 10, 25, 50, 100, 250, 500]}
            disableMultipleColumnsSorting={false}
            onRowModesModelChange={handleRowModesModelChange}
            onCellClick={handleCellClick}
            onPaginationModelChange={setPaginationModel}
          />
        </Box>
        {deleteTableViewId ? (
          <DeleteTableConfirmationDialog
            open={!!deleteTableViewId}
            tableViewId={deleteTableViewId}
            disabled={isDeletingTableView}
            handleConfirm={() => {
              deleteTableView(deleteTableViewId);
            }}
            handleClose={() => {
              setDeleteTableViewId(undefined);
            }}
          />
        ) : null}
      </Main>
    </Box>
  );
}
