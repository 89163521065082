/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Add as AddIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { CreateHomeownerJobDialog } from "@/common/CreateJobDialog/CreateHomeownerJobDialog";
import { DashboardTitle } from "@/common/DashboardTitle";
import { Seo } from "@/common/Seo/Seo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { Job, JobStatus } from "@/company/jobs/types";
import { useGetMyJobs } from "@/homeowner/api/useGetMyJobs";
import { HomeownerJobTabs, HomeownerRoutes } from "@/homeowner/constants";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { snakeCaseToTitleCase } from "@/utils";

import { HomeownerJobCard } from "../../HomeownerJobCard/HomeownerJobCard";
import { HomeownerJobDrawer } from "../../JobDrawer/JobDrawer";

const getFilteredJobs = (jobsList?: Job[]): Record<HomeownerJobTabs, Job[]> => {
  const data: Record<HomeownerJobTabs, Job[]> = {
    [HomeownerJobTabs.ALL]: jobsList ?? [],
    [HomeownerJobTabs.PENDING]: [],
    [HomeownerJobTabs.DECLINED]: [],
    [HomeownerJobTabs.DELAYED]: [],
    [HomeownerJobTabs.COMPLETED]: [],
    [HomeownerJobTabs.REQUESTED]: [],
  };
  jobsList?.forEach(job => {
    if (job.status === JobStatus.HOMEOWNER_DECLINED) {
      data[HomeownerJobTabs.DECLINED].push(job);
    } else if (job.status === JobStatus.PENDING_HOMEOWNER_ACTION) {
      data[HomeownerJobTabs.PENDING].push(job);
    } else if (job.status === JobStatus.HOMEOWNER_DELAYED) {
      data[HomeownerJobTabs.DELAYED].push(job);
    } else if (job.buyingCompanyId) {
      data[HomeownerJobTabs.COMPLETED].push(job);
    } else if (
      [
        JobStatus.HOMEOWNER_ACCEPTED,
        JobStatus.PAID,
        JobStatus.ROUTED,
        JobStatus.NO_COMPANY_TO_ROUTE,
      ].includes(job.status)
    ) {
      data[HomeownerJobTabs.REQUESTED].push(job);
    }
  });
  return data;
};

export function HomeownerJobsListPage() {
  const [searchParams] = useSearchParams();
  const urlJobId = searchParams.get("selectedJobId");
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const { session, isAuthenticated } = useAuth();

  const {
    data: jobs,
    isFetching,
    isError,
    refetch: refetchJobs,
  } = useGetMyJobs({
    refetchOnWindowFocus: false,
    enabled:
      !!(session?.user?.type === USER_TYPE.HOMEOWNER) && !!isAuthenticated,
  });
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTab, setSelectedTab] = useState<HomeownerJobTabs>(
    HomeownerJobTabs.PENDING
  );
  const [isCreateJobDialogOpen, setIsCreateJobDialogOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<number | null>(
    urlJobId ? parseInt(urlJobId) : null
  );

  if (isError) {
    enqueueSnackbar({
      message: "There was an error while fetching your jobs.",
      variant: "error",
    });
  }
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const filteredJobs = getFilteredJobs(jobs);

  const getEmptyScreenStrings = () => {
    if (selectedTab === HomeownerJobTabs.PENDING) {
      return (
        <>
          {" "}
          <Typography variant="h6" mb={0.5}>
            No Projects Pending
          </Typography>
          <Typography variant="body1" color="textSecondary" mb={2}>
            Follow up on your requested projects.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedTab(HomeownerJobTabs.REQUESTED);
            }}
          >
            All Requested Projects
          </Button>
        </>
      );
    }
    if (selectedTab === HomeownerJobTabs.REQUESTED) {
      return (
        <>
          {" "}
          <Typography variant="h6" mb={0.5}>
            No Projects Requested
          </Typography>
          <Typography variant="body1" color="textSecondary" mb={2}>
            See your project history.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedTab(HomeownerJobTabs.ALL);
            }}
          >
            All Projects
          </Button>
        </>
      );
    }
    if (selectedTab === HomeownerJobTabs.DELAYED) {
      return (
        <>
          {" "}
          <Typography variant="h6" mb={0.5}>
            No Projects Delayed
          </Typography>
          <Typography variant="body1" color="textSecondary" mb={2}>
            See your project history.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedTab(HomeownerJobTabs.ALL);
            }}
          >
            All Projects
          </Button>
        </>
      );
    }
    if (selectedTab === HomeownerJobTabs.DECLINED) {
      return (
        <>
          {" "}
          <Typography variant="h6" mb={0.5}>
            No Projects Declined
          </Typography>
          <Typography variant="body1" color="textSecondary" mb={2}>
            See your project history.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedTab(HomeownerJobTabs.ALL);
            }}
          >
            All Projects
          </Button>
        </>
      );
    }
    if (selectedTab === HomeownerJobTabs.COMPLETED) {
      return (
        <>
          {" "}
          <Typography variant="h6" mb={0.5}>
            No Projects Completed
          </Typography>
          <Typography variant="body1" color="textSecondary" mb={2}>
            See your project history.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedTab(HomeownerJobTabs.ALL);
            }}
          >
            All Projects
          </Button>
        </>
      );
    }
    return (
      <>
        {" "}
        <Typography variant="h6" mb={0.5}>
          {selectedTab === HomeownerJobTabs.ALL
            ? "No Projects Yet"
            : ` No Projects ${
                selectedTab === HomeownerJobTabs.PENDING
                  ? "Pending Review"
                  : snakeCaseToTitleCase(selectedTab.toString())
              } `}
        </Typography>
        <Typography variant="body1" color="textSecondary" mb={2}>
          Follow up on your requested projects
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (selectedTab === HomeownerJobTabs.ALL) {
              setIsCreateJobDialogOpen(true);
            } else {
              setSelectedTab(HomeownerJobTabs.ALL);
            }
          }}
        >
          {selectedTab === HomeownerJobTabs.ALL
            ? "Create a New Project"
            : "All Requested Projects"}
        </Button>
      </>
    );
  };

  return (
    <>
      <Seo title="Homehub Concierge" />
      <Box>
        <DashboardTitle
          title="HomeHub Concierge"
          subtitle={
            "Manage home projects recommended by your favorite service providers and submit new projects from here."
          }
          endButton={<Button />}
        />
        {isFetching ? (
          <Box mt={10} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container>
            <Grid container item xs={12} sx={{ mb: 3 }}>
              <Grid item xs={12}>
                <Tabs
                  scrollButtons
                  allowScrollButtonsMobile
                  TabScrollButtonProps={{ sx: { width: "20px" } }}
                  value={selectedTab}
                  variant="scrollable"
                  onChange={(_, newValue) => setSelectedTab(newValue)}
                >
                  <Tab
                    label={`Pending (${
                      filteredJobs[HomeownerJobTabs.PENDING].length
                    })`}
                    value={HomeownerJobTabs.PENDING}
                  />
                  <Tab
                    label={`Requested (${
                      filteredJobs[HomeownerJobTabs.REQUESTED].length
                    })`}
                    value={HomeownerJobTabs.REQUESTED}
                  />
                  <Tab
                    label={`Delayed (${
                      filteredJobs[HomeownerJobTabs.DELAYED].length
                    })`}
                    value={HomeownerJobTabs.DELAYED}
                  />
                  <Tab
                    label={`Declined (${
                      filteredJobs[HomeownerJobTabs.DECLINED].length
                    })`}
                    value={HomeownerJobTabs.DECLINED}
                  />
                  <Tab
                    label={`Completed (${
                      filteredJobs[HomeownerJobTabs.COMPLETED].length
                    })`}
                    value={HomeownerJobTabs.COMPLETED}
                  />
                  <Tab
                    label={`All (${filteredJobs[HomeownerJobTabs.ALL].length})`}
                    value={HomeownerJobTabs.ALL}
                  />
                </Tabs>
                <Divider sx={{ width: "100%" }} />
              </Grid>
              <Grid item xs={12} sx={{ pt: 3 }} />
              {filteredJobs[selectedTab] &&
              filteredJobs[selectedTab].length > 0 ? (
                filteredJobs[selectedTab].map(job => (
                  <Grid
                    key={job.id}
                    item
                    xs={12}
                    sx={{
                      mt: 2,
                    }}
                  >
                    <HomeownerJobCard
                      job={job}
                      onClick={() => {
                        if (job.status !== JobStatus.SUBMISSION) {
                          setSelectedJobId(job.id);
                        } else {
                          enqueueSnackbar({
                            message:
                              "Your video is processing. You will receive a text message notification when your video is ready.",
                            variant: "success",
                            subText: (
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "24px",
                                  letterSpacing: "0.15px",
                                  color: "white",
                                }}
                              >
                                Questions?{" "}
                                <a
                                  role="button"
                                  style={{
                                    textDecorationLine: "underline",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                  onClick={event => {
                                    event.preventDefault();
                                    setContactSupportModalOpen(true);
                                  }}
                                >
                                  Contact support
                                </a>
                              </Typography>
                            ),
                          });
                        }
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{ mt: isMobile ? 10 : 14, textAlign: "center" }}
                >
                  <img
                    src="/empty-list.svg"
                    style={{ margin: "16px" }}
                    alt="No Jobs Yet"
                  />
                  {getEmptyScreenStrings()}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      <HomeownerJobDrawer
        open={Boolean(selectedJobId)}
        jobId={selectedJobId}
        onClose={() => {
          setSelectedJobId(null);
          refetchJobs?.();
        }}
      />
      <Fab
        color="primary"
        aria-label="create project"
        sx={{
          position: "fixed",
          zIndex: 400,
          right: "30px",
          bottom: "20px",
        }}
        onClick={() => setIsCreateJobDialogOpen(true)}
      >
        <AddIcon />
      </Fab>
      <CreateHomeownerJobDialog
        jobsListUrl={getNavigationPathFromRoutePaths([
          HomeownerRoutes.BASE,
          HomeownerRoutes.JOBS_LIST,
        ])}
        refreshJobs={refetchJobs}
        isOpen={isCreateJobDialogOpen}
        onClose={() => setIsCreateJobDialogOpen(false)}
      />
    </>
  );
}
