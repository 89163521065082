import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";

import { usePopover } from "../hooks/use-popover";
import { AccountPopover } from "./account-popover";

export interface AccountButtonProps {
  edge?: "start" | "end";
  buttons: Array<{ label: string; link: string; icon: any }>;
}

export function AccountButton(props: AccountButtonProps) {
  const popover = usePopover<HTMLButtonElement>();

  return (
    <>
      <Box
        ref={popover.anchorRef}
        component={ButtonBase}
        sx={{
          alignItems: "center",
          display: "flex",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "divider",
          height: 32,
          width: 32,
          borderRadius: "50%",
          ...(props.edge === "start" ? { ml: -2 } : {}),
          ...(props.edge === "end" ? { mr: -2 } : {}),
        }}
        onClick={popover.handleOpen}
      >
        <Avatar sx={{ height: 32, width: 32 }} />
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        open={popover.open}
        buttons={props.buttons}
        onClose={popover.handleClose}
      />
    </>
  );
}
