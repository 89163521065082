import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

const IGNORE_CALL_RECORDING = "/call-recordings/:id/ignore";

export function useIgnoreCall(
  options: UseMutationOptions<void, AxiosError, number> = {}
): UseMutationResult<void, AxiosError, number> {
  return useMutation({
    mutationFn: async callRecordingId => {
      const url = IGNORE_CALL_RECORDING.replace(
        ":id",
        callRecordingId.toString()
      );

      return await API.post(url);
    },
    ...options,
  });
}
