import { Route } from "react-router-dom";

import AnalyticsHubPageDemo from "@/analytics/screens/AnalyticsHubDemo";
import BrandOverlapPage from "@/brand-overlap/BrandOverlapPage";
import SearchCriteriaBrandOverlap from "@/brand-overlap/common/SearchCriteria";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { AddReferralCampaignPage } from "@/internal-admin/referral-campaigns/pages/AddRefferalCampaignPage";

import InternalAdminCompany from "./InternalAdminCompany";
import { AdminLayout } from "./InternalAdminLayout";
import InternalAdminRecommendedPartners from "./InternalAdminRecommendedPartners";
import InternalAdminJobsTable from "./jobs/InternalAdminJobsTable";
import { InternalJobSearchFilterProvider } from "./jobs/useInternalJobSearchFilter";
import { ReferralCampaignsPage } from "./referral-campaigns/pages/ReferralCampaignsPage";
import ReferralInvitationsInternalAdmin from "./ReferralInvitations";
import SavedSearchTable from "./saved-searches/SavedSearchTable";
import SearchCriteria from "./SearchCriteria";
import { StrategicPartnersPage } from "./strategic-partners/pages/StrategicPartnersPage";
import CompanyTeamMembersPage from "./users/CompanyTeamMembersPage";
import InternalAdminUsersTable from "./users/InternalAdminUsersTable";

export function AdminRouter() {
  return (
    <Route path={"admin"} element={<AdminLayout />}>
      <Route
        path={"user"}
        loader={ComponentLoader}
        element={<CompanyTeamMembersPage />}
      />
      <Route
        path={"company"}
        loader={ComponentLoader}
        element={<InternalAdminCompany />}
      />
      <Route
        path={"referral-invitations"}
        loader={ComponentLoader}
        element={<ReferralInvitationsInternalAdmin />}
      />
      <Route
        path={"recommended-partners"}
        loader={ComponentLoader}
        element={<InternalAdminRecommendedPartners />}
      />
      <Route
        path={"jobs"}
        loader={ComponentLoader}
        element={
          <InternalJobSearchFilterProvider>
            <InternalAdminJobsTable />
          </InternalJobSearchFilterProvider>
        }
      />
      <Route
        path={"users"}
        loader={ComponentLoader}
        element={<InternalAdminUsersTable />}
      />
      <Route
        path={"saved-searches"}
        loader={ComponentLoader}
        element={<SavedSearchTable />}
      />
      <Route
        path={"saved-searches/:tableViewId"}
        loader={ComponentLoader}
        element={<SavedSearchTable />}
      />
      <Route
        path={"search-criteria"}
        loader={ComponentLoader}
        element={<SearchCriteria />}
      />
      <Route
        path={"bi/search-criteria"}
        loader={ComponentLoader}
        element={<SearchCriteriaBrandOverlap />}
      />
      <Route
        path={"bi/brand-overlap"}
        loader={ComponentLoader}
        element={<BrandOverlapPage />}
      />
      <Route
        path={"bi/analytics"}
        loader={ComponentLoader}
        element={<AnalyticsHubPageDemo />}
      />
      <Route
        path={"marketing/referral-campaigns"}
        loader={ComponentLoader}
        element={<ReferralCampaignsPage />}
      />
      <Route
        path={"marketing/referral-campaigns/add"}
        loader={ComponentLoader}
        element={<AddReferralCampaignPage />}
      />
      <Route
        path={"bi/strategic-partners"}
        loader={ComponentLoader}
        element={<StrategicPartnersPage />}
      />
      <Route
        path={"marketing/referral-campaigns/:referralCampaignId/edit"}
        loader={ComponentLoader}
        element={<AddReferralCampaignPage />}
      />
    </Route>
  );
}
