import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

export const USE_GET_UNREAD_ADMIN_CHATS_COUNT_URI =
  "/internal-admin/chats-company-to-user/{chat_id}/unread-count";

export function useFetchUnreadAdminMessagesCount(
  chatId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<number> {
  return useQuery({
    queryKey: [
      USE_GET_UNREAD_ADMIN_CHATS_COUNT_URI.replace("{chat_id}", chatId),
    ],
    queryFn: async () => {
      const response = await API.patch(
        USE_GET_UNREAD_ADMIN_CHATS_COUNT_URI.replace("{chat_id}", chatId)
      );
      return response.data.data;
    },
    ...options,
  });
}
