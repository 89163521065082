import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Seo } from "@/common/Seo/Seo";

import { PersonalInformation } from "../../personal-information/PersonalInformation";

export function ProfilePage() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <>
      <Seo title="Company Profile Information" />
      <Grid container>
        <Grid
          item
          position={"sticky"}
          sx={{ top: "56px", zIndex: 400, pt: 1 }}
          bgcolor={"white"}
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              {isMobile ? null : (
                <Typography variant="h5">{t("profile")}</Typography>
              )}

              <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
                {t("edit_your_personal_info_from_here")}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12} py={2}>
          <PersonalInformation />
        </Grid>
      </Grid>
    </>
  );
}
