import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export function ComponentLoader() {
  return (
    <Box textAlign="center" height="100%" width="100%">
      <CircularProgress />
    </Box>
  );
}
