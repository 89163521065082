/* eslint-disable jsx-a11y/media-has-caption */
import { Close } from "@mui/icons-material";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { useCreateJobFromRecording } from "@/company/api/useCreateJobRecording";
import {
  CallRecordingNotification,
  CallRecordingStatus,
  GET_CALL_RECORDING_NOTIFICATONS,
} from "@/company/api/useFetchCallNotificationsUnread";
import {
  CallRecording,
  CallType,
  GET_CALL_RECORDINGS,
} from "@/company/api/useFetchCallRecordings";
import { useUpdateCallRecordingStatus } from "@/company/api/useUpdateCallRecording";
import { useIgnoreCall } from "@/company/jobs/api/useIgnoreCall";
import { formatUSPhoneNumber } from "@/utils";

import CustomerRepresentative from "./components/CustomerRepresentative";
import HomeownerDetails from "./components/HomeownerDetails";

enum JobAssociation {
  SELLING_COMPANY = "SELLING_COMPANY",
  ROUTED_COMPANY = "ROUTED_COMPANY",
}
const ThinPaper = styled(Paper)(({ theme }) => ({
  width: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

const EmptyNotification = ({
  selectedRecordingNotification,
  selectedRecording,
  refetchCalls,
  hideCloseIcon,
  isMobile = false,
  onClose,
}: {
  selectedRecordingNotification: CallRecordingNotification;
  selectedRecording: CallRecording;
  onClose: () => void;
  hideCloseIcon?: boolean;
  refetchCalls: (recording: CallRecording) => void;
  isMobile?: boolean;
}) => {
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const { session } = useAuth();
  const queryClient = useQueryClient();

  const { mutateAsync: ignoreCall, isLoading: ignoringCall } = useIgnoreCall({
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while ignorinh the call.`,
        variant: "error",
      });
    },
  });
  useEffect(() => {
    if (selectedRecordingNotification?.status === CallRecordingStatus.NEW) {
      updateCallRecordingStatus({
        notificationId: selectedRecordingNotification?.id,
        status: CallRecordingStatus.NOTIFIED,
      });
    }
  }, [selectedRecordingNotification?.id]);

  const { mutateAsync: updateCallRecordingStatus } =
    useUpdateCallRecordingStatus({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [GET_CALL_RECORDINGS],
        });
        queryClient.invalidateQueries({
          queryKey: [GET_CALL_RECORDING_NOTIFICATONS],
        });
      },
    });

  const { isLoading: updatingRecording, mutateAsync: createJobFromRecording } =
    useCreateJobFromRecording({
      onSuccess: (recording: CallRecording) => {
        enqueueSnackbar({
          message: "Referral updated successfully.",
          variant: "success",
        });
        refetchCalls({ ...recording });
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while updating referral.`,
          variant: "error",
        });
      },
    });
  const updateReferralType = async (referralType: "inbound" | "outbound") => {
    createJobFromRecording({
      jobAssociation:
        referralType === "inbound"
          ? JobAssociation.ROUTED_COMPANY
          : JobAssociation.SELLING_COMPANY,
      recordingId: selectedRecording?.id,
      createdByUserId: session?.user?.id,
    });
  };

  const homeDetails =
    selectedRecording?.homeDetails ??
    (selectedRecording?.homeownerName || selectedRecording?.homeownerPhone)
      ? {
          home: {
            zipCode: selectedRecording?.homeownerZipCode,
            streetAddress: selectedRecording?.homeownerFullAddress,
          },
          homeowner: {
            name: selectedRecording?.homeownerName,
            cellPhone: selectedRecording?.homeownerPhone,
          },
        }
      : null;

  const createdAt = DateTime.fromFormat(
    selectedRecording.createdAt.toString().split(".")[0].split("T").join(" "),
    "yyyy-MM-dd HH:mm:ss",
    { zone: "utc" }
  );

  const todayStart = DateTime.local().startOf("day");

  const formattedDate =
    createdAt.toLocal() < todayStart
      ? createdAt.toLocal().toFormat("MM/dd/yy")
      : createdAt.toLocal().toFormat("h:mm a");
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      p={2}
      id="custom-scrollbar"
      sx={{ overflow: "auto", width: "100%" }}
    >
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          gap={1}
        >
          <Box display={"flex"} alignItems={"center"} gap={1}>
            {selectedRecording?.type === CallType.INBOUND ? (
              <SouthEastOutlinedIcon
                fontSize="large"
                color="info"
                sx={{
                  bgcolor: theme => theme.palette.info.light,
                  color: theme => theme.palette.info.main,
                  borderRadius: 30,
                  p: 1,
                  mr: 2,
                }}
              />
            ) : (
              <NorthEastOutlinedIcon
                fontSize="large"
                sx={{
                  bgcolor: theme => theme.palette.primary.light,
                  color: theme => theme.palette.primary.main,
                  borderRadius: 30,
                  p: 1,
                  mr: 2,
                }}
              />
            )}
            <Typography
              variant="overline"
              sx={{
                color:
                  selectedRecording?.type === CallType.INBOUND
                    ? "#2970FF"
                    : "#16B364",
              }}
            >
              {selectedRecording?.type === CallType.INBOUND
                ? `Inbound Call from ${formatUSPhoneNumber(
                    selectedRecording?.fromPhone ?? ""
                  )}`
                : `Outbound Call to ${formatUSPhoneNumber(
                    selectedRecording?.toPhone ?? ""
                  )}`}
            </Typography>
          </Box>
          {hideCloseIcon ? null : (
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          )}
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={1}
        >
          <Typography variant="h6">
            No Referral Opportunity Identified!
          </Typography>
        </Box>
        <Typography variant="body1" color={"text.secondary"}>
          We didn’t pick up a referral on this call. Did we miss something?
        </Typography>
        <Box display={"flex"} gap={1} width={"100%"}>
          <Button
            variant="contained"
            sx={{ width: "100%" }}
            disabled={updatingRecording}
            onClick={() => {
              updateReferralType("inbound");
            }}
          >
            Job was referred to me
          </Button>
          <Button
            sx={{ width: "100%" }}
            variant="outlined"
            disabled={updatingRecording}
            onClick={() => {
              updateReferralType("outbound");
            }}
          >
            I referred this job
          </Button>
        </Box>
        <Button
          disabled={updatingRecording || ignoringCall}
          sx={{ width: "100%" }}
          onClick={() => {
            ignoreCall(selectedRecording.id);
          }}
        >
          Ignore
        </Button>
      </Box>
      <Divider sx={{ borderColor: "#DCDFE4" }} />
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Typography variant="overline" color={"text.secondary"}>
          Call Details
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Created At: {formattedDate}
        </Typography>
        {selectedRecording?.audioPublicUrl ? (
          <>
            <Typography variant="subtitle2">Call Recording</Typography>
            <audio
              controls
              playsInline
              controlsList="nodownload"
              style={{
                width: "100%",
                borderRadius: 3,
                colorScheme: "light",
                height: "40px",
                background: "white",
                display: isAudioLoaded ? "block" : "none",
              }}
              onLoadedData={() => setIsAudioLoaded(true)}
            >
              <source
                src={selectedRecording?.audioPublicUrl}
                type="audio/mpeg"
              />
            </audio>
          </>
        ) : null}

        {selectedRecording?.jobSummary || selectedRecording?.callSummary ? (
          <>
            <Typography variant="subtitle2" mt={1}>
              Call Summary
            </Typography>
            <Typography variant="body1" color={"text.secondary"}>
              {selectedRecording?.callSummary ?? selectedRecording?.jobSummary}
            </Typography>
          </>
        ) : null}
      </Box>
      <Divider sx={{ borderColor: "#DCDFE4" }} />
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Typography variant="overline" color={"text.secondary"}>
          User Details
        </Typography>
        {homeDetails ? <HomeownerDetails homeDetails={homeDetails} /> : null}
        {selectedRecordingNotification?.csrName ??
        selectedRecording?.customerServiceRepresentative ? (
          <CustomerRepresentative
            name={
              selectedRecordingNotification?.csrName ??
              `${selectedRecording?.customerServiceRepresentative?.firstName} ${selectedRecording?.customerServiceRepresentative?.lastName}`
            }
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default EmptyNotification;
