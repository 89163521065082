import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

interface DeleteJobPromotionRequest {
  jobPromotionId: number;
}

export function useDeleteJobPromotion(
  options: UseMutationOptions<void, AxiosError, DeleteJobPromotionRequest> = {}
): UseMutationResult<void, AxiosError, DeleteJobPromotionRequest> {
  return useMutation({
    mutationFn: async ({ jobPromotionId }) => {
      await API.delete(`/job-promotions/${jobPromotionId}`);
    },
    ...options,
  });
}
