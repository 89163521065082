import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

const URL = "/internal-admin/users/:userId/archive";

export function useArchiveUserInternalAdmin(
  options: UseMutationOptions<void, AxiosError, number> = {}
): UseMutationResult<void, AxiosError, number> {
  return useMutation({
    mutationFn: async (userId: number) => {
      const url = URL.replace(":userId", userId.toString());
      return await API.post(url);
    },
    ...options,
  });
}
