/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhonelinkLockOutlinedIcon from "@mui/icons-material/PhonelinkLockOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { useMarkedInterestSetContext } from "@/company/context/marked-interest-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import { useCompanySearchContext } from "@/company/context/search-company-context";
import {
  Company,
  CompanyPublicProfile,
  ConnectionStatus,
  ExternalCompanyProfile,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { CompanyAvatar } from "../company-avatar/CompanyAvatar";
import { TabsEnum } from "../company-drawer/CompanyDrawer";
import { ExternalInviteDialog } from "../external-invitation-dialog/ExternalInvitationDialog";
import { MoreMenuMessaging } from "./MoreMenuMessaging";
import { getCompanySectorsChip } from "./utils";

export interface CompanyProfileCardProps {
  googleCompanyProfile?: ExternalCompanyProfile;
  connectionStatus?: ConnectionStatus;
  hasMarkedInterest?: boolean;
  companyProfile?: CompanyPublicProfile;
  showParentLogo?: boolean;
  parentNetworkCompany?: Company;
  onOpen?: (tab?: TabsEnum) => void;
}

export function CompanyProfileCard(props: CompanyProfileCardProps) {
  const {
    googleCompanyProfile,
    connectionStatus,
    hasMarkedInterest,
    showParentLogo,
    parentNetworkCompany,
    onOpen,
  } = props;

  let { companyProfile } = props;

  const { markedInterestSet, updateMarkedInterestSet } =
    useMarkedInterestSetContext();

  const [externalInviteDialogOpen, setExternalInviteDialogOpen] =
    useState(false);

  const [referralInviteSentLoading, setReferralInviteSentLoading] =
    useState(false);

  const { isFetching } = useCompanySearchContext();

  const parentLogoUrl = companyProfile?.familyOfBrands?.logoUrl;
  const parentCompanyName = companyProfile?.familyOfBrands?.name;

  const { session } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;

  const companySectors = session?.companySectors;

  const cardProfile = companyProfile ?? googleCompanyProfile;

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id, {
      refetchOnWindowFocus: false,
      enabled: !!company?.id,
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];
  const { createReferralInvitation, isFetchingReferralInvitations } =
    useReferralInvitationContext();

  const findNeededPartnersString = () => {
    const opportunitySectors = companySectors?.filter(sector => {
      const companySector = sector.sector;

      return (
        companyProfile?.opportunitySectors?.includes(companySector) &&
        !companyProfile?.sectors
          ?.map(sector => sector.sector)
          ?.includes(companySector)
      );
    });

    return opportunitySectors?.length
      ? snakeCaseJobSectors[opportunitySectors[0].sector]
      : null;
  };

  const { data: companyProfileV2, isLoading } = useFetchCompanyProfile(
    companyProfile?.id,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (companyProfileV2 && !isLoading) {
    companyProfile = companyProfileV2;
  }

  return (
    <>
      <Card
        sx={{
          p: 2,
          boxShadow: "0px 0px 0px 1px #F2F4F7",
          "&:active": {
            border: "1px solid #071D2F",
          },
          "&:focus": {
            border: "1px solid #071D2F",
          },
          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
        }}
        onClick={() => {
          if (companyProfile) {
            onOpen?.({
              tab: TabsEnum.OVERVIEW,
            });
            return;
          }
          onOpen?.({});
        }}
      >
        <Box
          sx={{ display: "flex", height: "80px", py: 1 }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <CompanyAvatar
            height={"40px"}
            maxWidth={"160px"}
            logo={cardProfile?.logoUrl}
            name={cardProfile?.name || ""}
          />
          {(parentLogoUrl && showParentLogo) || showParentLogo ? (
            <CompanyAvatar
              height={"32px"}
              maxWidth={"80px"}
              logo={parentLogoUrl}
              name={parentCompanyName || ""}
            />
          ) : null}
          {parentNetworkCompany ? (
            <CompanyAvatar
              height={"32px"}
              maxWidth={"80px"}
              logo={parentNetworkCompany.logoUrl}
              name={parentNetworkCompany.name || ""}
            />
          ) : null}
        </Box>
        <CardContent sx={{ p: 0 }}>
          <Box display={"flex"} gap={1} py={1} flexWrap={"wrap"}>
            {getCompanySectorsChip(
              companyProfile?.sectors,
              googleCompanyProfile?.sectors
            )}
          </Box>
          <Grid container py={1}>
            <Grid item xs={12} display={"flex"} alignItems={"center"}>
              <Typography display="inline" variant="h6">
                {" "}
                {cardProfile?.name}{" "}
                {!!(
                  companyProfile?.platformPresence ===
                    PLATFORM_PRESENCE.ON_PLATFORM ||
                  googleCompanyProfile?.tradeEngageCompanyId
                ) && (
                  <ToolTip
                    placement="bottom"
                    title="TradeEngage Participant"
                    message="This is a verified active business on TradeEngage."
                  >
                    <VerifiedOutlinedIcon
                      color="primary"
                      fontSize="small"
                      sx={{ ml: 1, verticalAlign: "center", color: "#16DB65" }}
                    />
                  </ToolTip>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Box display={"flex"} flexDirection={"column"} py={1}>
            {findNeededPartnersString() ? (
              <Box display={"flex"} alignItems={"center"} mb={0.5}>
                <ThumbUpAltOutlinedIcon
                  color="primary"
                  sx={{ width: "16px", marginRight: "8px" }}
                />

                <Typography
                  variant="caption"
                  color="primary"
                  alignItems={"center"}
                >
                  Needs {findNeededPartnersString() ?? ""}&nbsp;Partner
                </Typography>
              </Box>
            ) : null}
            <Box display={"flex"} flexDirection={"column"} gap={0.5}>
              {companyProfile?.sectors?.some(sector => {
                if (recommendedJobSectorsList?.length) {
                  return recommendedJobSectorsList?.includes(sector.sector);
                }
                return relevanceOrderedJobSectorsList?.includes(sector.sector);
              }) ||
              googleCompanyProfile?.sectors?.some(sector => {
                if (recommendedJobSectorsList?.length) {
                  return recommendedJobSectorsList?.includes(sector);
                }
                return relevanceOrderedJobSectorsList?.includes(sector);
              }) ? (
                <Box display={"flex"} alignItems={"center"}>
                  <img
                    src="/top-referral.svg"
                    alt="top referral category"
                    style={{ marginRight: "8px" }}
                  />
                  <Typography
                    variant="caption"
                    color="primary"
                    alignItems={"center"}
                  >
                    Top Referral Category
                  </Typography>
                </Box>
              ) : null}
              <Box display={"flex"} alignItems={"center"}>
                {companyProfile?.owners?.length &&
                (companyProfile?.owners[0].firstName ||
                  companyProfile?.owners[0].lastName) ? (
                  <>
                    <PersonOutlineOutlinedIcon
                      color="primary"
                      sx={{ width: "16px", mr: "2px" }}
                    />{" "}
                    <Typography
                      variant="caption"
                      color="primary"
                      alignItems={"center"}
                    >
                      {companyProfile?.owners[0].firstName
                        ? `${companyProfile?.owners[0].firstName}`
                        : ""}
                      {companyProfile?.owners[0].firstName &&
                      companyProfile?.owners[0].lastName
                        ? " "
                        : ""}
                      {companyProfile?.owners[0].lastName
                        ? `${companyProfile?.owners[0].lastName}`
                        : ""}
                    </Typography>
                  </>
                ) : null}
                {companyProfile?.owners?.[0]?.hasPhone &&
                (companyProfile?.owners?.[0]?.firstName ||
                  companyProfile?.owners?.[0]?.lastName) ? (
                  <ToolTip placement="bottom" message="Mobile Number Verified">
                    <PhonelinkLockOutlinedIcon
                      color="primary"
                      fontSize="small"
                      sx={{ width: "16px" }}
                    />
                  </ToolTip>
                ) : null}
                {companyProfile?.owners?.[0]?.hasEmail &&
                (companyProfile?.owners?.[0]?.firstName ||
                  companyProfile?.owners?.[0]?.lastName) ? (
                  <ToolTip placement="bottom" message="Email Address Verified">
                    <MarkEmailReadOutlinedIcon
                      color="primary"
                      fontSize="small"
                      sx={{ width: "16px" }}
                    />
                  </ToolTip>
                ) : null}
              </Box>
            </Box>
          </Box>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          sx={{ pt: 2 }}
        >
          {googleCompanyProfile && !companyProfile ? (
            hasMarkedInterest ||
            markedInterestSet.has(
              googleCompanyProfile?.googlePlacesId || ""
            ) ? (
              <MoreMenuMessaging
                companyProfile={googleCompanyProfile}
                showTradeEngageMessageOption={false}
                onMessageClick={() => {
                  onOpen?.({ tab: TabsEnum.MESSAGE });
                }}
              />
            ) : (
              <LoadingButton
                size="small"
                variant="contained"
                loading={isFetching}
                onClick={event => {
                  event.stopPropagation();
                  setExternalInviteDialogOpen(true);
                }}
              >
                Connect
              </LoadingButton>
            )
          ) : null}
          {companyProfile ? (
            connectionStatus === ConnectionStatus.CONNECTED ||
            connectionStatus === ConnectionStatus.PENDING ||
            connectionStatus === ConnectionStatus.INVITED ? (
              companyProfile.platformPresence ===
              PLATFORM_PRESENCE.OFF_PLATFORM ? (
                <MoreMenuMessaging
                  companyProfile={companyProfile}
                  onMessageClick={() => {
                    onOpen?.({ tab: TabsEnum.MESSAGE });
                  }}
                />
              ) : (
                <LoadingButton
                  size="small"
                  variant="outlined"
                  startIcon={<ChatBubbleOutlineOutlinedIcon />}
                  onClick={async event => {
                    event.preventDefault();
                    event.stopPropagation();
                    onOpen?.({ tab: TabsEnum.MESSAGE });
                  }}
                >
                  Send a Message
                </LoadingButton>
              )
            ) : (
              <LoadingButton
                size="small"
                loading={referralInviteSentLoading}
                variant="contained"
                onClick={async event => {
                  event.stopPropagation();
                  setReferralInviteSentLoading(true);
                  try {
                    await createReferralInvitation({
                      invitedCompanyId: companyProfile.id,
                    });
                    enqueueSnackbar({
                      message: (
                        <Typography>
                          Connection request sent with your default message.
                          Follow up with a personalized message or update your
                          default message from Settings.
                        </Typography>
                      ),
                      variant: "success",
                      subText: (
                        <Typography
                          sx={{
                            color: "#16DB65",
                            fontSize: "14px",
                            fontWeight: 600,
                            pr: 2,
                            cursor: "pointer",
                          }}
                          onClick={event => {
                            event.stopPropagation();
                            onOpen?.({ tab: TabsEnum.MESSAGE });
                          }}
                        >
                          Send a Message Now
                        </Typography>
                      ),
                    });
                  } catch (error) {
                    console.error(error);
                  }
                  setReferralInviteSentLoading(false);
                }}
              >
                Connect
              </LoadingButton>
            )
          ) : null}
        </Box>
      </Card>
      {googleCompanyProfile ? (
        <ExternalInviteDialog
          isOpen={externalInviteDialogOpen}
          companyPublicProfile={{
            logoUrl: googleCompanyProfile?.logoUrl,
            name: googleCompanyProfile?.name || "",
            googlePlacesId: googleCompanyProfile?.googlePlacesId || "",
          }}
          onClose={(invitationAccepted: boolean) => {
            if (invitationAccepted) {
              updateMarkedInterestSet(
                googleCompanyProfile?.googlePlacesId || ""
              );
            }
            setExternalInviteDialogOpen(false);
          }}
        />
      ) : null}
    </>
  );
}
