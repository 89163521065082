import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useDeleteRelationship(
  options: UseMutationOptions<void, AxiosError, number> = {}
): UseMutationResult<void, AxiosError, number> {
  return useMutation({
    mutationFn: async (relationshipId): Promise<void> => {
      await API.delete(`/internal-admin/relationships/${relationshipId}`);
      return;
    },
    ...options,
  });
}
