import {
  Box,
  Button,
  Card,
  CardHeader,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";

interface DateSelectionPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  dateRange?: { startDate?: DateTime; endDate?: DateTime };
  setDateRange: (dataRange: {
    startDate?: DateTime;
    endDate?: DateTime;
  }) => void;
}

export const DateSelectionPopover: FC<DateSelectionPopoverProps> = props => {
  const { anchorEl, onClose, open, dateRange, setDateRange, ...other } = props;

  const [startDate, setStartDate] = useState<DateTime | null>(
    dateRange?.startDate ? dateRange.startDate : null
  );

  const [endDate, setEndDate] = useState<DateTime | null>(
    dateRange?.endDate ? dateRange.endDate : null
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [minEndDate, setMinEndDate] = useState<DateTime | null>(null);

  const handleStartDateChange = (date: DateTime | null) => {
    setStartDate(date);
    if (date) {
      if (endDate && date > endDate) {
        setEndDate(date);
      }
      setMinEndDate(date);
    }
  };

  useEffect(() => {
    setStartDate(dateRange?.startDate);

    setEndDate(dateRange?.endDate);
  }, [dateRange]);

  return (
    <Popover
      disableScrollLock
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={!!open}
      onClose={onClose}
      {...other}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "flex-start",
          width: isMobile ? "80vw" : "500px",

          flexGrow: { xs: 0, md: 1 },
          flex: "1 0 0",
          p: 2,
          boxShadow:
            "0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            py: 2,
            px: 0,
          }}
          title={<Typography variant="h6">Filter by Date</Typography>}
        />
        <Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
          <Box>
            <Typography variant="overline" color={"text.secondary"}>
              Date Range
            </Typography>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <Box display={"flex"} gap={2}>
                <DatePicker
                  label="From"
                  value={startDate}
                  maxDate={DateTime.now()}
                  onChange={newValue => handleStartDateChange(newValue)}
                />
                <DatePicker
                  label="To"
                  value={endDate}
                  maxDate={DateTime.now()}
                  {...(minEndDate ? { minDate: minEndDate } : {})}
                  onChange={newValue => setEndDate(newValue)}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Button
            variant="contained"
            disabled={!startDate || !endDate}
            onClick={() => {
              setDateRange({ startDate, endDate });
            }}
          >
            Apply
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onClose?.();
            }}
          >
            Cancel
          </Button>
        </Box>
      </Card>
    </Popover>
  );
};
