import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { JobSectors } from "../jobs/constants";

export interface BasicCompanySchema {
  id: number;
  name: string;
  logoUrl?: string;
  sectors?: Array<JobSectors>;
}

export function useGetAllBrandLocations(
  keyword: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<BasicCompanySchema>> {
  return useQuery({
    queryKey: ["/internal-admin/companies/brand-location", keyword],
    queryFn: async () => {
      const response = await API.get(
        `/internal-admin/companies/brand-location?keyword=${keyword}`
      );
      return response.data.data;
    },
    ...options,
  });
}
