import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { CompanyCard } from "../types";

export const USE_GET_COMPANY_CARD_URI = "/company-payments/cards";

export function useGetCompanyCard(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<CompanyCard | null> {
  return useQuery({
    queryKey: [USE_GET_COMPANY_CARD_URI],
    queryFn: async () => {
      const response = await API.get(USE_GET_COMPANY_CARD_URI);
      if (response.data.data) {
        return response.data.data;
      }
      return null;
    },
    ...options,
  });
}
