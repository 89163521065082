import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

import { DefaultCompanyGroups } from "../types";

export function useAddUserToGroup(
  options: UseMutationOptions<
    void,
    AxiosError,
    { userId: number; groupName: DefaultCompanyGroups; companyId: number }
  >
): UseMutationResult<
  void,
  AxiosError,
  { userId: number; groupName: DefaultCompanyGroups; companyId: number }
> {
  return useMutation({
    mutationFn: async ({ userId, groupName, companyId }) => {
      const updateCompanyUri = `/internal-admin/users/${userId}/companies/${companyId}`;
      await API.post(updateCompanyUri, { group_name: groupName });
    },
    ...options,
  });
}
