import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Box, Divider, Typography } from "@mui/material";

import CheckboxList from "./CheckboxList";
import { useInternalCompanySearchFilterContext } from "./useInternalCompanySearchFilter";

export default function OwnershipSearchList() {
  const { addFilter, removeFilter, filters } =
    useInternalCompanySearchFilterContext();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
      p={2}
      sx={{
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
        background: "#FFF",
        width: "70%",
      }}
    >
      <Box display={"flex"} gap={2} pb={1}>
        <AccountCircleOutlinedIcon />
        <Typography fontSize={"14px"} fontWeight={600}>
          Ownership
        </Typography>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box display={"flex"} width={"100%"} gap={2} mt={1}>
        <Box display={"flex"} width={"70%"} flexDirection={"column"}>
          <CheckboxList
            parent={"Company Ownership"}
            checklist={[
              "Private Equity Backed",
              "Multi franchise brand",
              "Multiple owners",
              "Single service vs. multi-service",
              "Non-competing affiliate companies",
            ]}
            parentChecked={!!filters.companyOwnership}
            toggleParentChecked={() => {
              if (filters.companyOwnership) {
                removeFilter("companyOwnership");
              } else {
                addFilter({
                  companyOwnership: [
                    "Private Equity Backed",
                    "Multi franchise brand",
                    "Multiple owners",
                    "Single service vs. multi-service",
                    "Non-competing affiliate companies",
                  ],
                });
              }
            }}
            setSelectedList={list => {
              addFilter({
                companyOwnership: list,
              });
            }}
            selectedList={filters.companyOwnership}
          />
          <CheckboxList
            parent={"Owners within 10 years of 60 (retirement)"}
            parentChecked={!!filters.ownersNearRetirement}
            toggleParentChecked={() => {
              if (filters.ownersNearRetirement) {
                removeFilter("ownersNearRetirement");
              } else {
                addFilter({
                  ownersNearRetirement: true,
                });
              }
            }}
          />
          <CheckboxList
            parent={"Family operated business"}
            parentChecked={!!filters.familyOperatedBusiness}
            toggleParentChecked={() => {
              if (filters.familyOperatedBusiness) {
                removeFilter("familyOperatedBusiness");
              } else {
                addFilter({
                  familyOperatedBusiness: true,
                });
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
