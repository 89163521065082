import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import axiosInstance from "@/common/api";
import { BasicCompanySchema } from "@/company/api/useGellAllCompanies";
import { Company } from "@/company/types";

export interface HubSchema {
  id: number;
  name: string;
  companies: Array<BasicCompanySchema>;
  description?: string;
}

const url = "/companies/brands/:brandId";

export function useGetBrandById(
  brandId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Company> {
  return useQuery({
    queryKey: [`${url.replace(":brandId", brandId.toString())}`],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${url.replace(":brandId", brandId.toString())}`
      );
      return response.data.data;
    },
    ...options,
  });
}
