import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { DateTime } from "luxon";

import axiosInstance from "@/common/api";

export interface JobSourceCountByCompanySchema {
  company: string;
  count: number;
}

export interface JobStatisticsSchema {
  conversionRatio: number;
  totalJobsClosed: number;
  totalJobsEarnings: number;
  costPerAcquisition: number;
  averageJobPrice: number;
  jobsNotExpired: number;
  efficiencyCostSavings: number;
  techniciansFees: number;
  jobSourceCountByCompany?: Array<JobSourceCountByCompanySchema>;
  firstJobPaidAt?: string;
}
export const URL = "/enterprise/companies/summary";

export interface HubStatisticsParam {
  createdByCompanyIds?: Array<number>;
  fromDate?: DateTime;
  toDate?: DateTime;
}

export interface HubCompanySummarySchema {
  id: number;
  companyName: string;
  companyLogoUrl?: string;
  brandName?: string;
  brandId?: number;
  brandLogoUrl?: string;
  dateMarkedOnPlatform: DateTime;
  totalUsersInvited: number;
  totalUsersOnboarded: number;
  totalPendingInvitationsReceived: number;
  totalDeclinedInvitationsReceived: number;
  totalAcceptedInvitationsReceived: number;
  totalReferralInvitationsSent: number;
  totalPendingInvitationsSent: number;
  totalAcceptedInvitationsSent: number;
  totalDeclinedInvitationsSent: number;
  referralInvitationConversionRate: number;
  totalJobsSent: number;
  totalJobsReceived: number;
  totalJobsApproved: number;
  totalJobsDeclined: number;
  jobApprovalRate: number;
  totalEarnings: number;
  totalTechnicianEarnings: number;
  averageTechnicianEarningsPerJob: number;
}

export function useGetHubCompanySummary(
  params?: HubStatisticsParam,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<HubCompanySummarySchema>> {
  const createdByCompanyIdsParams = params?.createdByCompanyIds
    ?.map(createdByCompanyId => "company_ids=" + createdByCompanyId)
    ?.join("&");
  let uri = `${URL}?${createdByCompanyIdsParams}`;

  if (params?.fromDate) {
    uri += `&from_date=${params.fromDate.toFormat("yyyy-MM-dd")}`;
  }

  if (params?.toDate) {
    uri += `&to_date=${params.toDate.toFormat("yyyy-MM-dd")}`;
  }
  return useQuery({
    queryKey: [uri],
    queryFn: async () => {
      const response = await axiosInstance.get(uri);
      return response.data.data;
    },
    ...options,
  });
}
