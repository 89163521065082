import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Collapse, FormControlLabel, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { useState } from "react";

export default function CheckboxList({
  parent,
  checklist,
  setSelectedList,
  selectedList = [],
  toggleParentChecked,
  parentChecked,
}: {
  parent: string;
  parentChecked: boolean;
  toggleParentChecked: () => void;
  checklist?: Array<string>;
  setSelectedList?: (list: Array<string>) => void;
  selectedList?: Array<string>;
}) {
  const [open, setOpen] = useState(false);

  const handleToggle = (value: string) => () => {
    if (!checklist) return;
    const currentIndex = selectedList.indexOf(value);
    const newChecked = [...selectedList];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedList?.(newChecked);
  };

  return (
    <Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={1}
        sx={{
          ml: checklist ? 0 : 4,
        }}
      >
        {checklist ? (
          open ? (
            <ExpandLess onClick={() => setOpen(false)} />
          ) : (
            <ExpandMore onClick={() => setOpen(true)} />
          )
        ) : null}
        <FormControlLabel
          label={
            <Typography variant="subtitle2" color="text.secondary">
              {parent}
            </Typography>
          }
          control={
            <Checkbox
              sx={{ width: "20px", height: "20px", p: 0, m: 1 }}
              size="small"
              checked={parentChecked}
              onChange={() => toggleParentChecked()}
            />
          }
        />
      </Box>
      {checklist ? (
        <Collapse unmountOnExit in={open} timeout="auto">
          <Box pl={6}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {checklist.map(value => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem key={value} disablePadding>
                    <ListItemButton
                      dense
                      role={undefined}
                      onClick={handleToggle(value)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          disableRipple
                          size="small"
                          edge="start"
                          sx={{ width: "20px", height: "20px", p: 0 }}
                          checked={selectedList?.indexOf(value) !== -1}
                          tabIndex={-1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            {value}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Collapse>
      ) : null}
    </Box>
  );
}
