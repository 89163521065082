import {
  Box,
  Button,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { useModalState } from "@/lib/modals/useModalState";
import { TransactionCard } from "@/lib/transactions/TransactionCard";
import { isDefined } from "@/utils/isDefined";

import { useGetJobTransactions } from "../api/useGetJobTransactions";
import { JobTransactionType } from "../types";
import { AddJobTransactionForm } from "./AddJobTransactionForm";

interface JobTransactionsProps {
  jobId: number;
}

enum JobTransactionsTabs {
  USER_TRANSACTIONS = "USER_TRANSACTIONS",
  COMPANY_TRANSACTIONS = "COMPANY_TRANSACTIONS",
}

export default function JobTransactions(props: JobTransactionsProps) {
  const { jobId } = props;
  const addJobTransactionModalState = useModalState();
  const [selectedTab, setSelectedTab] = useState<JobTransactionsTabs>(
    JobTransactionsTabs.USER_TRANSACTIONS
  );

  const {
    data: jobTransactions = {
      userTransactions: [],
      companyTransactions: [],
    },
    isLoading: isLoadingJobTransactions,
    refetch: refetchJobTransactions,
  } = useGetJobTransactions({ jobId });

  const transactions =
    selectedTab === JobTransactionsTabs.USER_TRANSACTIONS
      ? jobTransactions.userTransactions
      : jobTransactions.companyTransactions;

  if (isLoadingJobTransactions) {
    return <ComponentLoader />;
  }

  return (
    <>
      <Stack spacing={1} height="100%">
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
        >
          <Tab
            value={JobTransactionsTabs.USER_TRANSACTIONS}
            label="User Transactions"
          />
          <Tab
            value={JobTransactionsTabs.COMPANY_TRANSACTIONS}
            label="Company Transactions"
          />
        </Tabs>
        <Divider />
        <Stack
          spacing={1}
          flexGrow={1}
          sx={{ overflowY: "auto", maxHeight: "100%", paddingX: 2 }}
        >
          {transactions.length > 0 ? (
            transactions.map(transaction => (
              <Box
                key={transaction.id}
                sx={{
                  marginY: 1,
                }}
              >
                <Divider sx={{ width: "100%", marginY: 1 }} />
                <TransactionCard
                  isPositiveTransaction={
                    transaction.concept ===
                    JobTransactionType.REFERRAL_FEE_EARNED
                  }
                  createdAt={transaction.createdAt}
                  amount={transaction.amount}
                  finalBalance={transaction.newBalance}
                  transactionType={transaction.concept}
                  secondaryText={
                    (!transaction.amount || transaction.amount <= 0) &&
                    selectedTab === JobTransactionsTabs.COMPANY_TRANSACTIONS
                      ? "(100% paid to your team)"
                      : undefined
                  }
                >
                  {isDefined(transaction.user) && (
                    <Typography variant="body2" color="text.secondary">
                      Paid To:{" "}
                      <Typography component="span" variant="subtitle1">
                        {transaction.user.firstName} {transaction.user.lastName}
                      </Typography>
                    </Typography>
                  )}
                  {isDefined(transaction.createdByUser) && (
                    <Typography variant="body2" color="text.secondary">
                      Paid By:{" "}
                      <Typography component="span" variant="subtitle1">
                        {transaction.createdByUser.firstName}{" "}
                        {transaction.createdByUser.lastName}
                      </Typography>
                      {" • "}
                      <Typography
                        component="span"
                        variant="subtitle2"
                        color="text.secondary"
                      >
                        {transaction.createdByUser.email}
                      </Typography>
                    </Typography>
                  )}
                </TransactionCard>
              </Box>
            ))
          ) : (
            <Stack justifyContent="center" alignItems="center">
              <Typography>No transactions found</Typography>
            </Stack>
          )}
        </Stack>
        <Stack justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            sx={{ width: "50%" }}
            onClick={addJobTransactionModalState.openModal}
          >
            Pay Referral Fee
          </Button>
        </Stack>
      </Stack>
      {addJobTransactionModalState.modalIsOpen && (
        <AddJobTransactionForm
          jobId={jobId}
          modalState={addJobTransactionModalState}
          onSuccess={() => {
            addJobTransactionModalState.closeModal();
            refetchJobTransactions();
          }}
        />
      )}
    </>
  );
}
