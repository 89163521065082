import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";

import { Job } from "../types/job";
import { JobContactInvite } from "./JobContactInvite";
import { JOB_SHARE_SOURCE, JobNetworkInvite } from "./JobNetworkInvite";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface JobShareDialogProps {
  isOpen: boolean;
  job: Job;
  onClose: () => void;
}

enum TabsEnum {
  CONTACT_INVITE = "EMAIL_INVITE",
  NETWORK_INVITE = "NETWORK_INVITE",
}

export const JobShareDialog: React.FC<JobShareDialogProps> = ({
  isOpen,
  job,
  onClose,
}) => {
  const { session } = useAuth();

  const user = session?.user;
  const [currentTab, setCurrentTab] = React.useState<TabsEnum>(
    TabsEnum.CONTACT_INVITE
  );

  const handleTabsChange = React.useCallback(
    (event: React.ChangeEvent<any>, value: TabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth
        aria-labelledby="invite-dialog-title"
        open={isOpen}
        sx={{ m: { xs: -3, md: 0 } }}
        onClose={onClose}
      >
        <Box sx={{ p: 3 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h5" m={0} p={0}>
              Share Project
            </Typography>
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography gutterBottom variant="body2" color="text.secondary">
            Share the project details with your contacts or a provider of your
            choosing.
          </Typography>
          <Tabs value={currentTab} onChange={handleTabsChange}>
            <Tab value={TabsEnum.CONTACT_INVITE} label="Email" />
            <Tab value={TabsEnum.NETWORK_INVITE} label="Share Link" />
          </Tabs>
          <Divider />
          <Box>
            {currentTab === TabsEnum.CONTACT_INVITE ? (
              <JobContactInvite jobId={job.id} />
            ) : null}
            {currentTab === TabsEnum.NETWORK_INVITE ? (
              <JobNetworkInvite
                job={job}
                source={
                  user?.type === USER_TYPE.HOMEOWNER
                    ? JOB_SHARE_SOURCE.HOMEOWNER
                    : undefined
                }
              />
            ) : null}
          </Box>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};
