/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import CloseIcon from "@mui/icons-material/Close";
import { Box, Theme, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface AccessControlDialogProps {
  isOpen: boolean;
  onClose: () => void;
  message?: string;
}

export const AccessControlJobDialog: React.FC<AccessControlDialogProps> = ({
  isOpen,
  onClose,
  message,
}) => {
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="access-control-dialog-title"
        open={isOpen}
        maxWidth="650px"
        onClose={() => onClose()}
      >
        <Box sx={{ p: 3 }}>
          <Box
            display={"flex"}
            alignItems={isMobile ? "start" : "center"}
            justifyContent={"space-between"}
            sx={{ p: 0, pb: 1.5 }}
          >
            <Typography variant="h5">
              You don’t have access to this yet
            </Typography>
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
                py: 0.5,
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            gutterBottom
            variant="body2"
            color={"text.secondary"}
            maxWidth={"500px"}
          >
            Your account is being verified. The TradeEngage team will reach out
            by phone or email to confirm your account. Questions?{" "}
            <Typography
              display="inline"
              variant="body2"
              color="#16B364"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setContactSupportModalOpen(true);
                onClose();
              }}
            >
              Contact support
            </Typography>
            .
          </Typography>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};
