import CallMadeSharpIcon from "@mui/icons-material/CallMadeSharp";
import CallReceivedSharpIcon from "@mui/icons-material/CallReceivedSharp";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { FC, useState } from "react";

import { ToolTip } from "@/common/tool-tip/ToolTip";
import { BasicCompanySchema } from "@/company/api/useGetAllBrands";
import { JobDrawerComponent } from "@/company/jobs/components/JobDrawerComponent";
import { JobSectors } from "@/company/jobs/constants";
import { Job, RoutedJobResponse } from "@/company/jobs/types/job";
import {
  getLastActivityJob,
  getLastActivityRoutedJob,
} from "@/company/jobs/utils";
import { PLATFORM_PRESENCE } from "@/company/types";

import JobReceivedTable from "./JobReceivedTable";
import JobSentTable from "./JobSentTable";

export interface BrandLocationReferralSchema {
  sector: JobSectors;
  order: number;
  createdAt: DateTime;
  buyingPrice: number;
}

export interface BrandLocationReferralInvitationSchema {
  sectors: Array<JobSectors>;
  createdAt: DateTime;
  acceptedAt?: string;
  declinedAt?: string;
}

export interface BrandLocationReferralNetworkSchema {
  referrals: Array<BrandLocationReferralSchema>;
  referralInvitationsSent: Array<BrandLocationReferralInvitationSchema>;
  referralInvitationsReceived: Array<BrandLocationReferralInvitationSchema>;
  affiliatedCompanies: Array<BasicCompanySchema>;
  recommendedPartners: Array<BasicCompanySchema>;
}

export interface ReferralNetworkOverviewProps {
  overview: any;
  jobs: any;
  companyId: number;
  expanded?: boolean;
  refetch: () => void;
}

enum JobType {
  JOBS_RECEIVED = "JOBS_RECEIVED",
  JOBS_SENT = "JOBS_SENT",
}

export const JobActivityOverview: FC<ReferralNetworkOverviewProps> = ({
  overview,
  companyId,
  jobs,
  expanded,
  refetch,
}) => {
  const [selectedJobType, setSelectedJobType] = useState<JobType>(
    JobType.JOBS_RECEIVED
  );

  const [currentSelectedJob, setCurrentSelectedJob] = useState<Job>();

  const [currentSelectedReceivedJob, setCurrentSelectedReceivedJob] = useState<{
    job: Job;
    jobRouting: RoutedJobResponse;
  }>();

  return (
    <>
      <Box
        display={"flex"}
        pb={2}
        gap={1}
        flexDirection={"column"}
        width={"100%"}
        pl={2}
        px={2}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="h6">{overview.name}</Typography>
          {overview.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM ? (
            <VerifiedOutlinedIcon
              sx={{
                verticalAlign: "center",
                color: "#16DB65",
                ml: 0.5,
                height: "20px",
              }}
            />
          ) : null}
        </Box>
        <Typography variant="overline" color="text.secondary" mb={1}>
          Overview
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={5}
        >
          <Box width="70%" display={"flex"} alignItems={"center"} gap={1}>
            <CallReceivedSharpIcon sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Received Jobs
            </Typography>
          </Box>
          <Typography fontSize={"12px"} fontWeight={500}>
            {jobs.receivedJobs ?? 0}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={5}
        >
          <Box width="70%" display={"flex"} alignItems={"center"} gap={1}>
            <CallMadeSharpIcon sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Sent Jobs
            </Typography>
          </Box>
          <Typography fontSize={"12px"} fontWeight={500}>
            {jobs.sentJobs ?? 0}
          </Typography>
        </Box>
        <Divider sx={{ width: "100%" }} />
        <Box width={"100%"}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography
              gutterBottom
              variant="overline"
              color="rgba(17, 25, 39, 0.38)"
            >
              REFERRAL BALANCE
            </Typography>
            <ToolTip
              placement="bottom"
              title="Referral Balance"
              message="This shows whether a company approves more jobs (longer green bar to the right) or refers more jobs (green bar to the left)."
            >
              <HelpOutlineIcon
                sx={{
                  background: "none",
                  color: "#6C737F",
                  width: "20px",
                  height: "20px",
                }}
              />
            </ToolTip>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} py={1}>
            <Typography variant="subtitle2">Jobs Approved</Typography>
            <Typography variant="subtitle2">Jobs Referred</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              py: 1,
            }}
          >
            <LinearProgress
              color="primary"
              variant="determinate"
              value={
                Math.floor(
                  overview.totalJobsApproved / overview.totalJobsSent
                ) * 100
              }
              sx={{ height: "8px" }}
            />
          </Box>
        </Box>
        {!currentSelectedReceivedJob && !currentSelectedJob ? (
          <Box
            sx={{
              borderRadius: "8px",
              /* elevation/outlined */
              boxShadow: "0px 0px 0px 1px #F2F4F7",
            }}
            pl={2}
            py={1}
          >
            <Typography
              variant="overline"
              color="rgba(17, 25, 39, 0.38)"
              py={2}
            >
              All Jobs
            </Typography>
            <Box display={"flex"} gap={1} py={1} flexWrap={"wrap"}>
              <Button
                size="small"
                sx={{
                  bgcolor:
                    selectedJobType === JobType.JOBS_RECEIVED
                      ? "#16B364"
                      : "rgba(17, 25, 39, 0.08)",
                  color:
                    selectedJobType === JobType.JOBS_RECEIVED
                      ? "white"
                      : "#111927",
                  "&:hover": {
                    color: "#111927",
                  },
                  "&:focus": {
                    bgcolor: "#16B364",
                  },
                }}
                onClick={() => setSelectedJobType(JobType.JOBS_RECEIVED)}
              >
                Received ({jobs.jobsReceived?.length})
              </Button>
              <Button
                size="small"
                sx={{
                  bgcolor:
                    selectedJobType === JobType.JOBS_SENT
                      ? "#16B364"
                      : "rgba(17, 25, 39, 0.08)",
                  color:
                    selectedJobType === JobType.JOBS_SENT ? "white" : "#111927",
                  "&:hover": {
                    color: "#111927",
                  },
                  "&:focus": {
                    bgcolor: "#16B364",
                  },
                }}
                onClick={() => setSelectedJobType(JobType.JOBS_SENT)}
              >
                Sent ({jobs.jobsSent?.length})
              </Button>
            </Box>
            {selectedJobType === JobType.JOBS_RECEIVED ? (
              <JobReceivedTable
                setCurrentSelectedReceivedJob={setCurrentSelectedReceivedJob}
                expanded={expanded}
                jobsReceived={jobs.jobsReceived}
              />
            ) : selectedJobType === JobType.JOBS_SENT ? (
              <JobSentTable
                setCurrentSelectedJob={setCurrentSelectedJob}
                companyId={companyId}
                refetch={refetch}
                invitationCode={overview.invitationCode}
                expanded={expanded}
                jobsSent={jobs.jobsSent}
              />
            ) : null}
          </Box>
        ) : null}
        {currentSelectedReceivedJob &&
        selectedJobType === JobType.JOBS_RECEIVED ? (
          <JobDrawerComponent
            open={!!currentSelectedReceivedJob}
            job={currentSelectedReceivedJob.job}
            jobId={currentSelectedReceivedJob.job.id}
            inactiveReason={
              currentSelectedReceivedJob?.jobRouting.inactiveReason
            }
            isRoutedJob={true}
            jobStatus={currentSelectedReceivedJob?.jobRouting.status}
            lastActivity={getLastActivityRoutedJob(
              currentSelectedReceivedJob?.jobRouting
            )}
            onClose={() => {
              setCurrentSelectedJob(undefined);
              setCurrentSelectedReceivedJob(undefined);
            }}
          />
        ) : null}
        {currentSelectedJob && selectedJobType === JobType.JOBS_SENT ? (
          <JobDrawerComponent
            open={!!currentSelectedJob}
            jobId={currentSelectedJob.id}
            job={currentSelectedJob}
            jobStatus={currentSelectedJob?.status}
            lastActivity={getLastActivityJob(currentSelectedJob as Job)}
            onClose={() => {
              setCurrentSelectedJob(undefined);
              setCurrentSelectedReceivedJob(undefined);
            }}
          />
        ) : null}
      </Box>
    </>
  );
};
