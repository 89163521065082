import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

import { ReferralInvitationActions } from "../types";

export function useUpdateReferralInvitation(
  options: UseMutationOptions<
    void,
    AxiosError,
    { invitationId: number; action: ReferralInvitationActions }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { invitationId: number; action: ReferralInvitationActions }
> {
  return useMutation({
    mutationFn: async ({ invitationId, action }) => {
      //TODO: add decline reason screen
      const updateInvitationUri = `/referral-invites/${invitationId}`;
      await API.patch(updateInvitationUri, {
        action,
        declined_reason: "not interested",
      });
    },
    ...options,
  });
}
