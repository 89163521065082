import constate from "constate";

import { useCreateHome } from "../api/useCreateHome";
import { useGetMyHomes } from "../api/useGetHomes";
import { useLinkHomeToJob } from "../api/useLinkHomeToJob";
import { useUpdateHome } from "../api/useUpdateHome";

function useHomesDetails() {
  const {
    data: homes,
    isFetching,
    refetch: refreshHomes,
  } = useGetMyHomes({
    refetchOnWindowFocus: false,
  });
  const { mutateAsync: updateHome, isLoading: isUpdating } = useUpdateHome();
  const { mutateAsync: createHome, isLoading: isCreating } = useCreateHome();
  const { mutateAsync: linkHomeToJob, isLoading: isLinkingHomeToJob } =
    useLinkHomeToJob();

  return {
    homes,
    isFetching,
    refreshHomes,
    updateHome,
    isUpdating,
    createHome,
    isCreating,
    isLinkingHomeToJob,
    linkHomeToJob,
  };
}

export const [HomesProvider, useHomesContext] = constate(useHomesDetails);
