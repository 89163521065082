import constate from "constate";
import { useEffect, useState } from "react";

import { useUpdateJobChat } from "../jobs/api/updateJobChat";
import { JobChat, useFetchJobChats } from "../jobs/api/useFetchJobChats";

function useChatDetails({ jobId }: { jobId: number }) {
  const [chats, setChats] = useState<Array<JobChat>>([]);

  const { data: chatsResponse, isLoading } = useFetchJobChats(jobId);

  const { mutateAsync: updateJobChatMutateFn } = useUpdateJobChat();

  const buyingCompany = chatsResponse?.buyingCompany;
  const homeowner = chatsResponse?.homeowner;
  const createdByCompany = chatsResponse?.createdByCompany;
  const createdByUserWithinCompany = chatsResponse?.createdByUserWithinCompany;

  useEffect(() => {
    if (chatsResponse) setChats(chatsResponse.chats);
  }, [chatsResponse]);

  const updateChat = async (message: string) => {
    const chat = {
      jobId,
      message,
    };
    const chatResult = await updateJobChatMutateFn(chat);
    const newChats = [...chats];
    newChats.push(chatResult);
    setChats(newChats);
  };

  return {
    chats,
    buyingCompany,
    createdByCompany,
    homeowner,
    createdByUserWithinCompany,
    isLoading,
    updateChat,
  };
}

export const [JobChatProvider, useJobChatContext] = constate(useChatDetails);
