import {
  Box,
  Card,
  Chip,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useAuth } from "@/auth/context/jwt";
import { Job, JobStatus } from "@/company/jobs/types";
import { getLastActivityJob } from "@/company/jobs/utils";
import { isDefined } from "@/utils/isDefined";

import { getHomeownerJobCardStatusChipProps } from "./utils";

export interface HomeownerJobCardProps {
  job: Job;
  onClick: () => void;
}

export function HomeownerJobCard(props: HomeownerJobCardProps) {
  const { session } = useAuth();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  let subtitle1 = `Recommended by`;
  let subtitle2 = `Last Activity: ${getLastActivityJob(props.job)}`;
  if (props.job.createdByUserId == session?.user?.id) {
    subtitle1 = `You created this service request`;
  }
  if (props.job.status === JobStatus.PENDING_HOMEOWNER_ACTION) {
    subtitle2 = "Act now to fast track scheduling!";
  }

  return (
    <Card
      sx={{
        display: "flex",
        cursor: "pointer",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        alignItems: { xs: "flex-start", md: "center" },
        py: 1,
        px: 2,
        pb: { xs: 2, md: 1 },
      }}
      onClick={props.onClick}
    >
      <Box
        display={"flex"}
        sx={{
          p: 1,
          flex: "1 0 0",
        }}
        width="100%"
        alignItems={isMobile ? "flex-start" : "center"}
        gap={2}
      >
        {isMobile ? null : (
          <img
            src="/video-thumbnail.svg"
            style={{ width: "40px" }}
            alt={props.job.jobType}
          />
        )}
        {isMobile ? (
          <Box display="flex" flexDirection="column" width="100%">
            <Typography gutterBottom variant="subtitle1">
              {`${isDefined(props.job.jobPromotion?.id) ? "Promotion: " : ""} ${
                props.job.jobType ?? "Processing..."
              }`}
            </Typography>
            <Box display="flex" gap="8px">
              <Typography
                gutterBottom
                minWidth={"40%"}
                variant="body2"
                color="text.secondary"
              >
                {subtitle1}
              </Typography>
              {props.job.listingExtraInfo?.createdByCompanyName && (
                <Typography color="primary" variant="body2">
                  {props.job.listingExtraInfo.createdByCompanyName}
                </Typography>
              )}
            </Box>
            <Box display="flex" gap="8px">
              <Typography
                gutterBottom
                minWidth={"40%"}
                variant="body2"
                color="text.secondary"
              >
                Last Activity
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {getLastActivityJob(props.job)}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column">
            <Typography gutterBottom variant="subtitle1">
              {`${isDefined(props.job.jobPromotion?.id) ? "Promotion: " : ""} ${
                props.job.jobType ?? "Processing..."
              }`}
            </Typography>
            <Box display="flex" gap="4px">
              <Typography gutterBottom variant="body2" color="text.secondary">
                {subtitle1}
              </Typography>
              {props.job.listingExtraInfo?.createdByCompanyName && (
                <Typography color="primary" variant="body2">
                  {props.job.listingExtraInfo.createdByCompanyName}
                </Typography>
              )}
            </Box>
            <Box display="flex" gap="4px">
              <Typography gutterBottom variant="body2" color="text.secondary">
                Last Activity
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {getLastActivityJob(props.job)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      {isMobile ? <Divider sx={{ width: "100%", mb: 2 }} /> : null}
      <Chip {...getHomeownerJobCardStatusChipProps(props.job)} />
    </Card>
  );
}
