import {
  DateTimePicker,
  DateTimePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useField, useFormikContext } from "formik";
import { DateTime } from "luxon";

interface DateTimePickerFieldProps
  extends Omit<DateTimePickerProps<DateTime>, "value" | "onChange"> {
  name: string;
}

export function DateTimePickerField(props: DateTimePickerFieldProps) {
  const { name, disabled, ...otherProps } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _meta, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DateTimePicker
        {...otherProps}
        {...field}
        value={field.value || null}
        disabled={isSubmitting || disabled}
        onChange={date => {
          helpers.setValue(date);
        }}
      />
    </LocalizationProvider>
  );
}
