import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

import { ChatThread } from "./useFetchAdminChatsByBrandLocation";

export function useCreateChatAdmin(
  options: UseMutationOptions<ChatThread, AxiosError, number> = {}
): UseMutationResult<ChatThread, AxiosError, number> {
  return useMutation({
    mutationFn: async (userId: number): Promise<ChatThread> => {
      const response = await API.post(
        "/internal-admin/companies/brand-location/chat-company-to-user",
        {
          to_user_id: userId,
        }
      );
      return response.data.data;
    },
    ...options,
  });
}
