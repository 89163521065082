import zipcodes from "zipcodes";

const radiusInMiles = 100;

// Function to find states within a given radius of a ZIP code
export function findStatesInRadius(zipCode: string, radius?: number) {
  // Find states within the bounding box
  const zipCodeInfoInRadius = zipcodes.radius(
    zipCode,
    radius ?? radiusInMiles,
    true
  ) as zipcodes.ZipCode[];
  const statesArray = zipCodeInfoInRadius.map(zipCodeInfo => zipCodeInfo.state);
  const statesSet = new Set(statesArray);
  return statesSet;
}

// Convert miles to meters (1 mile is approximately 1609.34 meters)
const radiusInMeters = radiusInMiles * 1609.34;

export function findBoundaryRestrictions(
  center: { lat: number; lng: number },
  radius?: number
) {
  const radi = radius ? radius * 1609.34 : radiusInMeters;
  // Calculate the northeast and southwest corners based on the radius
  const northeast = new google.maps.LatLng(
    center.lat + (180 / Math.PI) * (radi / 6378137),
    center.lng +
      ((180 / Math.PI) * (radi / 6378137)) /
        Math.cos((center.lat * Math.PI) / 180)
  );

  const southwest = new google.maps.LatLng(
    center.lat - (180 / Math.PI) * (radi / 6378137),
    center.lng -
      ((180 / Math.PI) * (radi / 6378137)) /
        Math.cos((center.lat * Math.PI) / 180)
  );
  const bounds = new google.maps.LatLngBounds(southwest, northeast);

  return bounds;
}

// Promisifying the asynchronous function
export const promisifiedFunction = (
  asyncFunctionWithCallback: (callback: (a: object) => void) => void
) => {
  return new Promise((resolve, reject) => {
    asyncFunctionWithCallback(result => {
      if (!result) {
        reject("Error while fetching geojson");
      } else {
        resolve(result);
      }
    });
  });
};
