import { Delete } from "@mui/icons-material";
import { Box, Chip, Tooltip } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useUpdateCompanyUser } from "@/company/api/useUpdateCompanyUser";
import { snakeCaseJobSectors } from "@/company/utils";

import { useDeleteReferral } from "./api/useDeleteReferral";
import ConfirmationDialog from "./ConfirmationDialog";
import { BrandLocationReferralSchema } from "./ReferralNetworkOverview";
import {
  CustomToolbar,
  formatDate,
  getGridStringOperatorsDataGrid,
} from "./utils";

export enum ReferralType {
  REFERRAL_INVITATION_SENT = "REFERRAL_INVITATION_SENT",
  REFERRAL_INVITATION_ACCEPTED = "REFERRAL_INVITATION_ACCEPTED",
  AFFILIATE_COMPANIES = "AFFILIATE_COMPANIES",
  RECOMMENDED_PARTNERS = "RECOMMENDED_PARTNERS",
  REFERRALS = "REFERRALS",
}

export default function ReferralNetworkTable({
  referrals,
  companyId,
  expanded,
  refetch,
  isFetchingProfile,
}: {
  referrals: Array<BrandLocationReferralSchema>;
  companyId: number;
  expanded?: boolean;
  refetch: () => void;
  isFetchingProfile?: boolean;
}) {
  const [rows, setRows] = useState([]);
  const [deleteRow, setDeleteRow] = useState<any | null | undefined>();

  const { mutateAsync: deleteReferral } = useDeleteReferral({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while deleting referral.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      enqueueSnackbar({
        message: "Referral deleted successfully.",
        variant: "success",
      });
      refetch();
    },
  });

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const { mutateAsync: updateUser, isLoading } = useUpdateCompanyUser({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while updating user details.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      enqueueSnackbar({
        message: "Your changes have been saved.",
        variant: "success",
      });
    },
  });

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const processRowUpdate = async (newRow: GridRowModel, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    await updateUser({
      companyId,
      userId: updatedRow.id,
      updatedUser: {
        ...newRow,
        firstName: newRow?.name?.split(" ")?.[0],
        lastName: newRow?.name?.split(" ")?.[1],
        oldGroups: oldRow.types,
        newGroups: newRow.types,
      },
    });

    setRows(
      rows.map(row =>
        row.id === newRow.id
          ? {
              ...updatedRow,
              firstName: newRow?.name?.split(" ")?.[0],
              lastName: newRow?.name?.split(" ")?.[1],
            }
          : row
      )
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    setRows(referrals);
  }, [referrals]);

  function getRowId(row) {
    return `${row.id}-${row.sector}`;
  }

  let columns: GridColDef[] = [
    {
      field: "logoUrl",
      headerName: "Logo",
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <img
              src={params.row.logoUrl}
              style={{
                width: "48px",
                height: "32px",
                objectFit: "contain",
              }}
              alt="company-logo"
            />
          </Box>
        );
      },
      sortable: false,
    },
    {
      field: "id",
      headerName: "ID",
      width: 200,
      renderCell: params => params.row?.id.toString(),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },

    {
      field: "order",
      headerName: "Order",
      width: 200,
    },
    {
      field: "sector",
      headerName: "Sector",
      width: 200,
      renderCell: params => {
        return (
          <Chip
            key={params.row?.sector}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={snakeCaseJobSectors[params.row?.sector]}
            size="small"
          />
        );
      },
    },
    {
      field: "buyingPrice",
      headerName: "Buying Price",
      type: "number",
      headerAlign: "left",
      valueFormatter: value => `$${value}`,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "string",
      width: 200,
      renderCell: params => formatDate(params.row?.createdAt),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <Tooltip
            key={`${id}-delete-tooltip`}
            title="Click to delete referral"
          >
            <GridActionsCellItem
              key={`${id}-delete`}
              label="Delete Referral"
              color="inherit"
              icon={<Delete />}
              onClick={() => {
                setDeleteRow(row);
              }}
            />
          </Tooltip>,
        ];
      },
    },
  ];
  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  return (
    <Box display={"flex"} width={"100%"}>
      <Box
        sx={{
          height: 400,
          position: "relative",
          width: "100%",
        }}
      >
        <DataGridPremium
          checkboxSelection
          disableRowSelectionOnClick
          pagination
          columns={columns}
          loading={isFetchingProfile}
          getRowId={getRowId}
          sx={{
            "& .MuiDataGrid-row": {
              "--rowBorderColor": "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withRightBorder": {
              borderRightColor: "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withLeftBorder": {
              borderLeftColor: "#D9D9D9",
            },
          }}
          rows={rows ?? []}
          slots={{
            ...(expanded
              ? {
                  toolbar: () => <CustomToolbar fileName="Referral_Networks" />,
                }
              : {}),
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
          initialState={{
            density: "compact",
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "logoUrl", "name"],
              right: ["actions"],
            },
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          slotProps={{
            toolbar: { setRowModesModel },
          }}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
        />
      </Box>
      {deleteRow && (
        <ConfirmationDialog
          title="Delete Referral"
          confirmText="Delete"
          open={!!deleteRow}
          text={`Are you sure you want to delete the referral with ${
            deleteRow.name
          } for sector ${snakeCaseJobSectors[deleteRow.sector]}?`}
          handleClose={() => setDeleteRow(null)}
          handleConfirm={() => {
            deleteReferral(deleteRow.referralId);
            setDeleteRow(null);
          }}
        />
      )}
    </Box>
  );
}
