import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

import { JobPromotionExclusion } from "../types";

interface CreateJobPromotionExclusionRequest {
  jobPromotionId: number;
}

export function useUpsertJobPromotionExclusion(
  options: UseMutationOptions<
    JobPromotionExclusion,
    AxiosError,
    CreateJobPromotionExclusionRequest
  > = {}
): UseMutationResult<
  JobPromotionExclusion,
  AxiosError,
  CreateJobPromotionExclusionRequest
> {
  return useMutation({
    mutationFn: async ({ jobPromotionId }): Promise<JobPromotionExclusion> => {
      const response = await API.put(
        `/job-promotions/${jobPromotionId}/exclusions`
      );
      return response.data.data;
    },
    ...options,
  });
}
