import { Add, Restore } from "@mui/icons-material";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Drawer,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridEventListener,
  GridFilterModel,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  formatDateTime,
  formatToDateTime,
} from "@/analytics/components/JobsTable";
import { Languages } from "@/common/constants/languages";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useArchiveUser } from "@/company/api/useArchiveUser";
import { useCreateTableView } from "@/company/api/useCreateTableView";
import { useGetUsersInternalAdmin } from "@/company/api/useGetUsersInternalAdmin";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { AdminChatProvider } from "@/company/context/admin-chat-context";
import { JobDrawerComponentById } from "@/company/jobs/components/JobDrawerById";
import { JobStatus } from "@/company/jobs/types";
import {
  ChipObj,
  UserOnboardingStatusChipObj,
} from "@/company/onboarding/components/screen/operations-sales-info/StaffTable";
import {
  CarrierType,
  ConnectionStatus,
  DefaultCompanyGroups,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { useUpdateUsersById } from "@/internal-admin/api/useUpdateUsersByIds";
import { UserOnboardingStatus } from "@/user/types";
import {
  getGridDateOperatorsDataGrid,
  getGridNumericOperatorsDataGrid,
  snakeCaseToTitleCase,
} from "@/utils";

import { useActivateUser } from "../api/useActivateUser";
import { useArchiveUserInternalAdmin } from "../api/useArchiveUser";
import { useResendUserInviteAdmin } from "../api/useResendUserInviteAdmin";
import ConfirmationDialog from "../ConfirmationDialog";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import { InternalAdminCompanyDrawer } from "../InternalAdminCompanyDrawer";
import { CreateTableViewDialog } from "../saved-searches/CreateTableViewDialog";
import { TableType } from "../saved-searches/SavedSearchTable";
import {
  CustomToolbar,
  drawerWidth,
  formatDate,
  getDifference,
  getGridStringOperatorsDataGrid,
  Main,
  renderEditTextarea,
} from "../utils.tsx";
import CreateCompanyTeamMemberDialog from "./CreateCompanyTeamMemberDialog";

export default function InternalAdminUsersTable({
  name,
  preFilledColumns,
  payload,
}: {
  name?: string;
  preFilledColumns?: Array<any>;
  payload?: Record<string, any>;
}) {
  const {
    data: users,
    isLoading: loadingUsers,
    refetch,
  } = useGetUsersInternalAdmin({
    refetchOnWindowFocus: false,
  });
  const [createTableViewDialogOpen, setCreateTableViewDialogOpen] =
    useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const navigate = useNavigate();

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const apiRef = useGridApiRef();
  const [
    openCreateCompanyTeamMemberDialog,
    setOpenCreateCompanyTeamMemberDialog,
  ] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<number | undefined>();
  const [resendRowId, setResendRowId] = useState<number | undefined>();

  const [expanded, setExpanded] = useState(false);

  const [companyProfileId, setCompanyProfileId] = useState<number | null>();

  const unsavedChangesRef = useRef<{
    unsavedRows: Record<GridRowId, GridValidRowModel>;
    rowsBeforeChange: Record<GridRowId, GridValidRowModel>;
  }>({
    unsavedRows: {},
    rowsBeforeChange: {},
  });

  const discardChanges = () => {
    setHasUnsavedRows(false);
    apiRef.current.updateRows(
      Object.values(unsavedChangesRef.current.rowsBeforeChange)
    );
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
    };
  };

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (params.field === "companyId") {
      setCompanyProfileId(params.row.companyId);
      return;
    }
    if (
      params.field === "lastJobSentId" ||
      params.field === "lastJobApprovedId" ||
      params.field === "lastJobPaidId" ||
      params.field === "lastJobDeclinedId" ||
      params.field === "lastJobInactivatedId" ||
      params.field === "lastJobEarnedId" ||
      params.field === "lastReferralEarnedJobId"
    ) {
      setSelectedJobId(params.row[params.field]);
      return;
    }
  };

  const saveChanges = async () => {
    try {
      // Persist updates in the database
      setIsSaving(true);
      const beforeRows = unsavedChangesRef.current.rowsBeforeChange;
      const updatedRows = unsavedChangesRef.current.unsavedRows;
      const payloads =
        Object.values(updatedRows)?.map(newRow => {
          const beforeRow = Object.values(beforeRows)?.find(
            row => row.id === newRow.id
          );

          const updatedUser = getDifference(newRow, beforeRow);
          // TODO: replace companyId with new users data company Id
          return {
            userId: newRow.id,
            updatedUser,
          };
        }) ?? [];
      await updateUsers(payloads);
      setHasUnsavedRows(false);
      unsavedChangesRef.current = {
        unsavedRows: {},
        rowsBeforeChange: {},
      };
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  const { isLoading: isDeleting, mutateAsync: archiveUser } = useArchiveUser({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while archiving user.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      enqueueSnackbar({
        message: "User archived successfully.",
        variant: "success",
      });
    },
  });

  const { mutateAsync: updateUsers, isLoading: updatingUsers } =
    useUpdateUsersById({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating users.";
        enqueueSnackbar(message, {
          variant: "error",
        });
        refetch();
      },
      onSuccess() {
        enqueueSnackbar({
          message: "Your changes have been saved.",
          variant: "success",
        });
      },
    });

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [serverFiltering, setServerFiltering] = useState<
    undefined | GridFilterModel
  >();
  const [serverSorting, setServerSorting] = useState<
    undefined | GridSortModel
  >();
  const [deleteRowName, setDeleteRowName] = useState<string>("");

  const processRowUpdate = async (newRow: GridRowModel, oldRow) => {
    const rowId = newRow.id;
    const areEqual = _.isEqual(JSON.stringify(oldRow), JSON.stringify(newRow));

    if (areEqual) {
      return newRow;
    }
    unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }
    setHasUnsavedRows(true);
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const [deleteRowId, setDeleteRowId] = useState<number | null | undefined>(
    null
  );

  const { mutateAsync: archiveUserInternalAdmin } = useArchiveUserInternalAdmin(
    {
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while archiving user.";
        enqueueSnackbar(message, {
          variant: "error",
        });
        refetch();
      },
      onSuccess() {
        enqueueSnackbar({
          message: "User archived successfully.",
          variant: "success",
        });
        refetch();
      },
    }
  );

  const { mutateAsync: activateUser } = useActivateUser({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while activating user.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      enqueueSnackbar({
        message: "User activated successfully.",
        variant: "success",
      });
      refetch();
    },
  });

  const { mutateAsync: resendUserInvite, isLoading: isResendingUserInvite } =
    useResendUserInviteAdmin({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while resending user invite.";
        enqueueSnackbar(message, {
          variant: "error",
        });
        refetch();
      },
      onSuccess() {
        enqueueSnackbar({
          message: "User invite resent successfully.",
          variant: "success",
        });
        refetch();
      },
    });

  let columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 100,
      editable: false,
      type: "number",
      renderCell: params => params.row.id.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "firstName",
      headerName: "First Name",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 150,
      editable: true,
      // renderCell: params => `${params.row.name} ${params.row.lastName}`,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 150,
      editable: true,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 150,
      editable: false,
      valueGetter: (value, row) => `${row.firstName} ${row.lastName}`.trim(),
    },
    {
      field: "platformPresence",
      width: 200,
      headerName: "Platform Presence",
      editable: true,
      type: "singleSelect",
      valueOptions: [...Object.keys(PLATFORM_PRESENCE)],
    },
    {
      field: "cellPhone",
      editable: true,
      headerName: "Phone",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 150,
    },
    {
      field: "cellPhoneCarrierType",
      editable: false,
      headerName: "Phone Carrier Type",
      width: 150,
      type: "singleSelect",
      valueOptions: [...Object.keys(CarrierType)],
    },
    {
      field: "email",
      editable: true,
      headerName: "Email",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "secondaryCellPhone",
      editable: true,
      headerName: "Secondary Phone",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 150,
    },

    {
      field: "secondaryCellPhoneCarrierType",
      editable: true,
      headerName: "Secondary Phone Carrier Type",
      width: 150,
      type: "singleSelect",
      valueOptions: [...Object.keys(CarrierType)],
    },
    {
      field: "secondaryEmail",
      editable: true,
      headerName: "Secondary Email",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 150,
    },
    {
      field: "balance",
      headerName: "BALANCE",
      renderCell: params => `$${params.row.balance?.toString()}`,
      valueFormatter: value => value && `$${value?.toString()}`,
      width: 200,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lifetimeEarnings",
      headerName: "Lifetime Earnings",
      width: 150,
      type: "number",
      renderCell: params => `$${params.row.lifetimeEarnings?.toString()}`,
      valueFormatter: value => value && `$${value?.toString()}`,
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastReferralEarnedAmount",
      headerName: "Last Referral Earned Amount",
      width: 150,
      type: "number",
      renderCell: params =>
        params.row.lastReferralEarnedAmount
          ? `$${params.row.lastReferralEarnedAmount?.toString()}`
          : null,
      valueFormatter: value => value && `$${value?.toString()}`,
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastReferralEarnedAt",
      headerName: "Date Last Referral Earned",
      width: 150,
      type: "date",
      valueFormatter: formatDate,
      filterOperators: getGridDateOperatorsDataGrid,
    },
    {
      field: "lastReferralEarnedJobId",
      headerName: "Last Referral Earned Job ID",
      width: 150,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "createdAt",
      valueFormatter: formatDate,
      headerName: "Created At",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
    },
    {
      field: "onboardingStatus",
      width: 200,
      headerName: "Onboarding Status",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      renderCell: params => {
        return (
          <Chip
            key={params.row.onboardingStatus}
            sx={{
              color:
                UserOnboardingStatusChipObj[
                  params.row.onboardingStatus as UserOnboardingStatus
                ].color,
              bgcolor:
                UserOnboardingStatusChipObj[
                  params.row.onboardingStatus as UserOnboardingStatus
                ].bgColor,
            }}
            label={
              <Typography variant="overline">
                {snakeCaseToTitleCase(
                  params.row.onboardingStatus.toString() ?? ""
                )}
              </Typography>
            }
            size="small"
          />
        );
      },
    },
    {
      field: "lastInvitedAt",
      valueFormatter: formatDate,
      headerName: "Last Invited At",
      width: 200,
    },
    {
      field: _.camelCase("terms_and_conditions_accepted_at"),
      editable: true,
      type: "date",
      valueFormatter: formatDate,
      headerName: "Onboarded At",
      filterOperators: getGridDateOperatorsDataGrid,
      width: 200,
    },
    {
      field: "preferredLanguage",
      editable: true,
      headerName: "Preferred Language",
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(Languages)],
    },
    {
      field: "stripeAccountId",
      editable: false,
      headerName: "Stripe Account Id",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    // {
    //   field: _.camelCase("terms_and_conditions_accepted_at"),
    //   editable: true,
    //   type: "date",
    //   valueFormatter: formatDate,
    //   headerName: "Accepted Terms and Conditions At",
    //   filterOperators: getGridDateOperatorsDataGrid,
    //   width: 200,
    // },
    {
      field: "numberOfCompaniesAssociated",
      headerName: "Number of Companies Associated",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "companyLogoUrl",
      headerName: "Company Logo Url",
      width: 150,
      sortable: false,
      editable: false,
      type: "string",
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <CompanyAvatar
              logo={params.row.companyLogoUrl || undefined}
              name={params.row.companyName}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
          </Box>
        );
      },
    },
    {
      field: "companyId",
      headerName: "Company Id",
      width: 150,
      editable: false,
      type: "number",
      valueFormatter: value => value && `${value?.toString()}`,
      renderCell: params => params.row.companyId?.toString(),

      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 150,
      editable: false,
      type: "string",
      filterOperators: getGridStringOperatorsDataGrid,
    },
    {
      field: "companyMarkedOnPlatformAt",
      headerName: "Date Company Was Marked On Platform",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.companyMarkedOnPlatformAt),
      renderCell: params =>
        formatDateTime(params.row?.companyMarkedOnPlatformAt),
    },
    {
      field: "companyOnboardedAt",
      headerName: "Date Company Completed Onboarding",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) => formatToDateTime(row?.companyOnboardedAt),
      renderCell: params => formatDateTime(params.row?.companyOnboardedAt),
    },
    {
      field: "brandId",
      headerName: "Brand Id",
      renderCell: params => params.row.brandId?.toString(),

      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "brandName",
      headerName: "Brand Name",
      width: 150,
      editable: false,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "familyOfBrandsId",
      headerName: "Family Of Brands Id",
      width: 150,
      editable: false,
      type: "number",
      renderCell: params => params.row.familyOfBrandsId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "familyOfBrandsName",
      headerName: "Family Of Brands Name",
      width: 150,
      editable: false,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "privateEquityId",
      headerName: "Private Equity Id",
      renderCell: params => params.row.privateEquityId?.toString(),
      width: 150,
      editable: false,
      type: "number",
      valueFormatter: value => value && `${value?.toString()}`,
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "privateEquityName",
      headerName: "Private Equity Name",
      width: 150,
      editable: false,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "companyGroups",
      headerName: "Company Roles",
      width: 150,
      editable: true,
      valueOptions: [...Object.keys(DefaultCompanyGroups)],
      renderEditCell: renderEditTextarea,
      renderCell: params => {
        const sectorChips = params.row?.companyGroups?.map(role => (
          <Chip
            key={`${role}-${params.row.companyId}`}
            sx={{
              color: ChipObj[role as DefaultCompanyGroups].color,
              bgcolor: ChipObj[role as DefaultCompanyGroups].bgColor,
            }}
            label={
              <Typography variant="overline">
                {snakeCaseToTitleCase(role?.toString() ?? "")}
              </Typography>
            }
            size="small"
          />
        ));
        return sectorChips;
      },
    },
    {
      field: "jobTitle",
      editable: true,
      headerName: "Job Title",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 150,
    },
    {
      field: "deletedAt",
      headerName: "Deleted At",
      type: "date",
      editable: false,
      valueFormatter: formatDate,
    },
    {
      field: "lastLoginAt",
      headerName: "Last Login At",
      type: "date",
      editable: false,
      valueFormatter: formatDate,
    },

    {
      field: "lastWithdrawalAt",
      headerName: "Last Withdrawal At",
      width: 150,
      editable: false,
      type: "date",
      valueFormatter: formatDate,
    },
    {
      field: "lastWithdrawalAmount",
      headerName: "Last Withdrawal Amount",
      renderCell: params =>
        params.row.lastWithdrawalAmount
          ? `$${params.row.lastWithdrawalAmount?.toString()} `
          : null,
      valueFormatter: value => value && `$${value?.toString()}`,
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastJobSentAt",
      headerName: "Date Last Job Submitted",
      width: 150,
      editable: false,
      type: "date",
      valueFormatter: formatDate,
    },
    {
      field: "daysSinceLastJobSent",
      headerName: "Days Since Last Job Submitted",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastJobSentId",
      renderCell: params => params.row.lastJobSentId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      headerName: "ID Last Job Submitted",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastJobSentStatus",
      headerName: "Status Last Job Submitted",
      width: 150,
      editable: false,
      type: "singleSelect",
      valueOptions: [...Object.keys(JobStatus)],
    },
    {
      field: "totalJobsSent",
      headerName: "Total Jobs Submitted",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      description:
        "This is the sum of Total Jobs Submitted Pending Homeowner Action + Total Jobs Submitted Pending Referral Payment + Total Jobs Submitted Homeowner Declined + Total Jobs Submitted Homeowner Delayed + Total Jobs Submitted No Company To Route + Total Jobs Submitted Currently Routed +  Total Jobs Submitted Earned ",
    },
    {
      field: "totalJobsSentPendingHomeownerAction",
      headerName: "Total Jobs Submitted Pending Homeowner Action",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalJobsSentPendingReferralPayment",
      headerName: "Total Jobs Submitted Pending Referral Payment",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalJobsSentHomeownerDeclined",
      headerName: "Total Jobs Submitted Homeowner Declined",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalJobsSentHomeownerDelayed",
      headerName: "Total Jobs Submitted Homeowner Delayed",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalJobsSentNoCompanyToRoute",
      headerName: "Total Jobs Submitted No Company To Route",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalJobsSentCurrentlyRouted",
      headerName: "Total Jobs Submitted Currently Routed",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalJobsEarned",
      headerName: "Total Jobs Submitted Earned",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastJobApprovedAt",
      headerName: "Last Job Approved At",
      width: 150,
      editable: false,
      type: "date",
      valueFormatter: formatDate,
    },
    {
      field: "lastJobApprovedId",
      headerName: "Last Job Approved Id",
      renderCell: params => params.row.lastJobApprovedId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalJobsApproved",
      headerName: "Total Jobs Approved",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastJobPaidAt",
      headerName: "Last Job Paid At",
      width: 150,
      editable: false,
      type: "date",
      valueFormatter: formatDate,
    },
    {
      field: "lastJobPaidId",
      headerName: "Last Job Paid Id",
      renderCell: params => params.row.lastJobPaidId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalJobsPaid",
      headerName: "Total Jobs Paid",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastJobDeclinedAt",
      headerName: "Last Job Declined At",
      width: 150,
      editable: false,
      type: "date",
      valueFormatter: formatDate,
    },
    {
      field: "lastJobDeclinedId",
      headerName: "Last Job Declined Id",
      width: 150,
      editable: false,
      type: "number",
      renderCell: params => params.row.lastJobDeclinedId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalJobsDeclined",
      headerName: "Total Jobs Declined",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastJobInactivatedAt",
      headerName: "Last Job Inactivated At",
      width: 150,
      editable: false,
      type: "date",
      valueFormatter: formatDate,
    },
    {
      field: "lastJobInactivatedId",
      headerName: "Last Job Inactivated Id",
      width: 150,
      editable: false,
      renderCell: params => params.row.lastJobInactivatedId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalJobsInactivated",
      headerName: "Total Jobs Inactivated",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastJobEarnedAt",
      headerName: "Last Job Earned At",
      width: 150,
      editable: false,
      type: "date",
      valueFormatter: formatDate,
    },
    {
      field: "lastJobEarnedId",
      headerName: "Last Job Earned Id",
      renderCell: params => params.row.lastJobEarnedId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "lastJobEarnedFee",
      headerName: "Last Job Earned Fee",
      width: 150,
      editable: false,
      type: "number",
      renderCell: params =>
        params.row.lastJobEarnedFee &&
        `$${params.row.lastJobEarnedFee?.toString()}`,
      valueFormatter: value => value && `$${value?.toString()}`,
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    {
      field: "totalReferralFeesEarned",
      headerName: "Total Referral Fees Earned",
      width: 150,
      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      renderCell: params =>
        params.row.totalReferralFeesEarned &&
        `$${params.row.totalReferralFeesEarned?.toString()}`,
      valueFormatter: value => value && `$${value?.toString()}`,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      type: "date",
      editable: false,
      valueFormatter: formatDate,
    },
    {
      field: "linkedinUrl",
      headerName: "LinkedIn Url",
      width: 150,
      editable: true,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "profilePictureUrl",
      headerName: "Profile Picture Url",
      width: 150,
      editable: true,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "linkedinTitle",
      headerName: "LinkedIn Title",
      width: 150,
      editable: true,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "linkedinCompany",
      headerName: "LinkedIn Company",
      width: 150,
      editable: true,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "facebookUrl",
      headerName: "Facebook Url",
      width: 150,
      editable: true,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "twitterUrl",
      headerName: "Twitter Url",
      width: 150,
      editable: true,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "instagramUrl",
      headerName: "Instagram Url",
      width: 150,
      editable: true,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      editable: true,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 120,
      align: "right",
      headerAlign: "right",
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const actions = [];
        if (!row.deletedAt && !row.lastLoginAt) {
          actions.push(
            <Tooltip
              key={`${id}-resend-invite-user-tooltip`}
              title="Resend Onboarding Invite"
            >
              <GridActionsCellItem
                key={`${id}-resend-invite`}
                icon={<NotificationsActiveIcon />}
                label="Resend Invite"
                color="inherit"
                disabled={isResendingUserInvite}
                onClick={() => {
                  setResendRowId(row.id);
                }}
              />
            </Tooltip>
          );
        }
        if (row.deletedAt) {
          actions.push(
            <Tooltip key={`${id}-restore-user-tooltip`} title="Un-delete user">
              <GridActionsCellItem
                key={`${id}-restore`}
                icon={<Restore />}
                label="Restore"
                color="inherit"
                onClick={() => {
                  activateUser(row.id);
                }}
              />
            </Tooltip>
          );
        } else {
          actions.push(
            <Tooltip key={`${id}-deactivate-user`} title="Mark user as deleted">
              <GridActionsCellItem
                key={`${id}-delete`}
                icon={<DeleteIcon />}
                label="Delete"
                color="inherit"
                onClick={() => {
                  setDeleteRowId(row.id);
                  setDeleteRowName(`${row.firstName} ${row.lastName}`.trim());
                }}
              />
            </Tooltip>
          );
        }
        return actions;
      },
    },
  ];

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  const [columnOrder, setColumnOrder] = useState(() => {
    const savedColumnOrder = localStorage.getItem("user-datagridColumnOrder");
    return savedColumnOrder
      ? JSON.parse(savedColumnOrder)
      : columns.map(column => column.field);
  });

  const [columnWidths, setColumnWidths] = useState(() => {
    const savedColumnWidths = localStorage.getItem("user-datagridColumnWidths");
    return savedColumnWidths
      ? JSON.parse(savedColumnWidths)
      : columns.reduce((acc, col) => {
          acc[col.field] = col.width;
          return acc;
        }, {});
  });

  const handleColumnResize = (newColumn: any) => {
    setColumnWidths({
      ...columnWidths,
      [newColumn.colDef.field]: newColumn.width,
    });
  };

  columns = columns.map(column => ({
    ...column,
    width: columnWidths?.[column.field] ?? column.width,
  }));

  const { mutateAsync: createTableView, isLoading: creatingTableView } =
    useCreateTableView({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while saving search.";
        enqueueSnackbar(message, {
          variant: "error",
        });
        refetch();
      },
      onSuccess(tableView) {
        enqueueSnackbar({
          message: "Search saved successfully.",
          variant: "success",
        });
        navigate(`/admin/saved-searches/${tableView.id}`, {
          state: { tableView },
        });
      },
    });

  useEffect(() => {
    if (payload) {
      setServerFiltering(payload.serverFiltering);
      setServerSorting(payload.serverSorting);
      setPaginationModel({ pageSize: payload.size, page: payload.page });
      if (payload.columnOrder) setColumnOrder(payload.columnOrder);
    }
  }, [payload]);

  useEffect(() => {
    if (preFilledColumns) {
      const newColumnWidths = preFilledColumns.reduce((acc, col) => {
        acc[col.field] = col.width;
        return acc;
      }, {});
      setColumnWidths(newColumnWidths);
      setColumnVisibilityModel(
        columns.reduce((acc, col) => {
          acc[col.field] = preFilledColumns.some(
            preFilledColumn => preFilledColumn.field === col.field
          );
          return acc;
        }, {})
      );
      for (const column of preFilledColumns) {
        apiRef.current.setColumnIndex(column.field, column.position);
        apiRef.current.pinColumn(column.field, column.pinned);
      }
    }
  }, [preFilledColumns, apiRef]);

  useEffect(() => {
    localStorage.setItem(
      "user-datagridColumnWidths",
      JSON.stringify(columnWidths)
    );
  }, [columnWidths]);

  useEffect(() => {
    localStorage.setItem(
      "user-datagridColumnOrder",
      JSON.stringify(columnOrder)
    );
  }, [columnOrder]);

  const handleSortModelChange = (model: GridSortModel) => {
    setServerSorting(model);
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    setServerFiltering(model);
  };

  const oldColumns = [...columns];
  columns = [
    ...columnOrder.map(field => oldColumns.find(col => col.field === field)),
    ...oldColumns.filter(col => !columnOrder.includes(col.field)),
  ];

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const { column, targetIndex } = params;

    // Reorder the columns based on the drag and drop
    const newColumnOrder = [...columns];
    const columnIndex = newColumnOrder.findIndex(
      col => col.field === column.field
    );

    // Remove the moved column and reinsert it at the new index
    const [movedColumn] = newColumnOrder.splice(columnIndex, 1);
    newColumnOrder.splice(targetIndex - 1, 0, movedColumn);

    // Extract the new field order
    const newFieldOrder = newColumnOrder.map(col => col.field);

    setColumnOrder(newFieldOrder);
  };

  return (
    <Box display={"flex"} sx={{ height: "100%" }} overflow={"auto"}>
      <Main
        open={!!(companyProfileId || selectedJobId)}
        sx={{
          width: expanded ? "0%" : `calc(100% - ${drawerWidth}px)`,
          display: expanded ? "none" : "visible",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
          px={3}
          pt={3}
          pb={1}
        >
          {name ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                href="/admin/saved-searches"
              >
                Saved Searches
              </Link>
              <Link underline="hover" color="inherit">
                {name}
              </Link>
            </Breadcrumbs>
          ) : null}
          <Typography variant="h5">Users</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={3}
          pb={1}
        >
          <Box
            display={"flex"}
            gap={3}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="subtitle1" fontSize={"16px"}>
              {users?.total
                ? `Showing ${Intl.NumberFormat("en-US").format(
                    users?.total ?? 0
                  )} users`
                : "Fetching results"}
            </Typography>
            <Box
              display={"flex"}
              gap={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Button
                startIcon={<BookmarkAddOutlinedIcon />}
                variant="outlined"
                onClick={() => {
                  setCreateTableViewDialogOpen(true);
                }}
              >
                Save Search
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 260px)",
            px: 3,
          }}
          position={"relative"}
        >
          <Box
            sx={{
              height: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            <Box display={"flex"} gap={2} mb={1} position={"relative"}>
              <LoadingButton
                size="small"
                disabled={!hasUnsavedRows}
                loading={isSaving}
                variant="contained"
                loadingPosition="start"
                onClick={saveChanges}
              >
                Save
              </LoadingButton>
              <LoadingButton
                size="small"
                disabled={!hasUnsavedRows || isSaving}
                variant={!hasUnsavedRows || isSaving ? "contained" : "outlined"}
                onClick={discardChanges}
              >
                Discard Changes
              </LoadingButton>
              <LoadingButton
                size="small"
                startIcon={<Add />}
                variant={"contained"}
                onClick={() => setOpenCreateCompanyTeamMemberDialog(true)}
              >
                Create Company Team Member
              </LoadingButton>
            </Box>
            <DataGridPremium
              checkboxSelection
              disableRowSelectionOnClick
              pagination
              disableAggregation
              disableRowGrouping
              loading={loadingUsers}
              columns={columns}
              apiRef={apiRef}
              sx={{
                "& .MuiDataGrid-row": {
                  "--rowBorderColor": "#D9D9D9",
                },
                "& .MuiDataGrid-cell--withRightBorder": {
                  borderRightColor: "#D9D9D9",
                },
                "& .MuiDataGrid-cell--withLeftBorder": {
                  borderLeftColor: "#D9D9D9",
                },
              }}
              rows={users?.users ?? []}
              slots={{
                toolbar: () => (
                  <CustomToolbar fileName="Users" includeQuickFilter={false} />
                ),
              }}
              rowModesModel={rowModesModel}
              sortModel={serverSorting}
              columnVisibilityModel={columnVisibilityModel}
              paginationModel={paginationModel}
              filterModel={serverFiltering}
              pageSizeOptions={[5, 10, 25, 50, 100, 250, 500, 5000]}
              processRowUpdate={processRowUpdate}
              initialState={{
                density: "compact",
                pinnedColumns: {
                  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "name"],
                  right: ["actions"],
                },
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              slotProps={{
                toolbar: { setRowModesModel },
              }}
              columnOrder={columnOrder}
              onColumnVisibilityModelChange={newModel =>
                setColumnVisibilityModel(newModel)
              }
              onRowModesModelChange={handleRowModesModelChange}
              onColumnOrderChange={params =>
                handleColumnOrderChange(params, columns, setColumnOrder)
              }
              onColumnWidthChange={handleColumnResize}
              onFilterModelChange={handleFilterModelChange}
              onSortModelChange={handleSortModelChange}
              onCellClick={handleCellClick}
              onPaginationModelChange={setPaginationModel}
            />
          </Box>
          <DeleteConfirmationDialog
            open={!!deleteRowId}
            text={deleteRowName}
            handleClose={() => setDeleteRowId(null)}
            handleConfirm={() => {
              archiveUserInternalAdmin(deleteRowId!);
              setDeleteRowId(null);
              setDeleteRowName("");
            }}
          />
        </Box>
      </Main>
      {expanded && companyProfileId ? (
        <AdminChatProvider companyId={companyProfileId}>
          <InternalAdminCompanyDrawer
            open={!!companyProfileId}
            companyProfileId={companyProfileId}
            connectionStatus={ConnectionStatus.CONNECTED}
            expanded={expanded}
            onClose={() => {
              setCompanyProfileId(null);
              setExpanded(false);
            }}
            onExpand={() => setExpanded(prev => !prev)}
          />
        </AdminChatProvider>
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!companyProfileId}
        >
          {companyProfileId ? (
            <AdminChatProvider companyId={companyProfileId}>
              <InternalAdminCompanyDrawer
                open={!!companyProfileId}
                expanded={expanded}
                companyProfileId={companyProfileId}
                connectionStatus={ConnectionStatus.CONNECTED}
                onClose={() => {
                  setCompanyProfileId(null);
                  setExpanded(false);
                }}
                onExpand={() => setExpanded(prev => !prev)}
              />
            </AdminChatProvider>
          ) : null}
        </Drawer>
      )}
      {selectedJobId ? (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!selectedJobId}
        >
          {selectedJobId ? (
            <JobDrawerComponentById
              jobId={selectedJobId}
              onClose={() => {
                setSelectedJobId(undefined);
              }}
            />
          ) : null}
        </Drawer>
      ) : null}
      {openCreateCompanyTeamMemberDialog ? (
        <CreateCompanyTeamMemberDialog
          open={openCreateCompanyTeamMemberDialog}
          onClose={() => setOpenCreateCompanyTeamMemberDialog(false)}
        />
      ) : null}
      {createTableViewDialogOpen ? (
        <CreateTableViewDialog
          isOpen={createTableViewDialogOpen}
          creatingTableView={creatingTableView}
          onCreate={({
            name,
            description,
          }: {
            name: string;
            description?: string;
          }) => {
            const payload = {
              page: paginationModel.page,
              size: paginationModel.pageSize,
              serverSorting,
              serverFiltering,
              columnOrder,
            };
            const columns = apiRef.current.getVisibleColumns();
            const columnsPayload = columns.map(column => ({
              field: column.field,
              width: column.width,
              position: apiRef.current.getColumnPosition(column.field),
              pinned: apiRef.current.isColumnPinned(column.field),
            }));
            createTableView({
              name,
              tableName: TableType.USER,
              description,
              body: payload,
              columns: columnsPayload,
            });
            setCreateTableViewDialogOpen(false);
          }}
          onClose={() => setCreateTableViewDialogOpen(false)}
        />
      ) : null}
      <ConfirmationDialog
        open={!!resendRowId}
        handleClose={() => setResendRowId(undefined)}
        handleConfirm={() => {
          resendUserInvite({
            userId: resendRowId!,
            companyId: users?.users?.find(row => row.id == resendRowId!)
              ?.companyId,
          });
          setResendRowId(undefined);
        }}
        text={`Are you sure you want to re-send the onboarding invites?`}
        title="Send Onboarding Invitations"
        confirmText="Send"
      />
    </Box>
  );
}
