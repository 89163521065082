import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";
import { JobSectors } from "@/company/jobs/constants";
import { PayReferralFeeSettings } from "@/company/types";

import { JobPromotion, JobPromotionStatus } from "../types";

interface UpdateJobPromotionRequest {
  jobPromotionId: number;
  status?: JobPromotionStatus;
  title?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  sector?: JobSectors;
  payReferralFeeAfterJobIsDone?: boolean;
  payReferralFeeSettings?: PayReferralFeeSettings;
  referralSalePrice?: number | null;
  referralPercentageFee?: number | null;
  leadPrice?: number | null;
}

export function useUpdateJobPromotion(
  options: UseMutationOptions<
    JobPromotion,
    AxiosError,
    UpdateJobPromotionRequest
  > = {}
): UseMutationResult<JobPromotion, AxiosError, UpdateJobPromotionRequest> {
  return useMutation({
    mutationFn: async ({ jobPromotionId, ...data }): Promise<JobPromotion> => {
      const response = await API.patch(
        `/job-promotions/${jobPromotionId}`,
        changeCaseKeys(data, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
