import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Card,
  Chip,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { ToolTip } from "@/common/tool-tip/ToolTip";

import { useGetJobHistoryByCompanyId } from "../api/useGetJobHistoryById";
import { Job, JobStatus } from "../types";
import {
  getLastActivityJob,
  SentJobStatusChipColor,
  SentJobStatusFilterChipLabel,
} from "../utils";
import { CompanyProfileDrawer } from "./CompanyProfileDrawer";

interface JobHistoryProps {
  homeOwnerId: number;
  companyId: number;
}

export const JobHistoryByCompanyId: FC<JobHistoryProps> = ({
  homeOwnerId,
  companyId,
}) => {
  const { data: jobs } = useGetJobHistoryByCompanyId(homeOwnerId, companyId);
  const { session } = useAuth();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const filteredJobs = jobs?.filter(job => {
    return Object.keys(SentJobStatusFilterChipLabel).includes(job.status);
  });
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >();

  const getJobStatusString = (job: Job) => {
    if (companyId === job.buyingCompanyId) {
      return "PAID";
    }
    return job.status === JobStatus.PAID
      ? `+$${job.jobBuyingCost}`
      : SentJobStatusFilterChipLabel[job.status]
          ?.toString()
          .replaceAll("_", " ")
          .toUpperCase();
  };

  const getJobStatusChipStyles = (job: Job) => {
    if (companyId === job.buyingCompanyId) {
      return {
        bgcolor: "#EBEFFF",
        color: "#2970FF",
        height: "24px",
      };
    }
    return {
      bgcolor: SentJobStatusChipColor[job.status].bgcolor,
      color: SentJobStatusChipColor[job.status].color,
      height: "24px",
    };
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Box pt={3} pb={2} display={"flex"} alignItems={"center"}>
        <Typography variant="overline">Job History</Typography>
        <ToolTip
          placement="bottom"
          message="The company listed is the one that recommended the job to the homeowner."
        >
          <HelpOutlineIcon
            sx={{
              background: "none",
              color: "#6C737F",
              width: "16px",
              height: "16px",
            }}
          />
        </ToolTip>
      </Box>
      {filteredJobs?.map(job => (
        <Card
          key={job.id}
          sx={{
            boxShadow: "0px 0px 0px 1px #F2F4F7",
            "&:active": {
              border: "1px solid #071D2F",
            },
            "&:focus": {
              border: "1px solid #071D2F",
            },
            "&:hover": {
              boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
            },
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"start"}
            flexDirection={"column"}
          >
            <Box
              display={"flex"}
              sx={{
                flexDirection: "column",
                p: 2,
              }}
            >
              <Typography variant="subtitle2" pb={1}>
                {job.jobType}
              </Typography>
              {job.listingExtraInfo?.createdByCompanyName ? (
                <Typography
                  variant="subtitle2"
                  fontWeight={700}
                  sx={{
                    color: "#16B364",
                    textTransform: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedCompanyId(
                      job.listingExtraInfo?.createdByCompanyId
                    );
                  }}
                >
                  {job.listingExtraInfo?.createdByCompanyName}
                </Typography>
              ) : null}
              <Typography variant="body2" color="text.secondary">
                Last Activity: {getLastActivityJob(job)}
              </Typography>
              <Box px={0} pt={2} pb={1}>
                <Chip
                  label={
                    <Typography
                      variant="overline"
                      sx={{ fontSize: "12px", fontWeight: 600 }}
                    >
                      {getJobStatusString(job)}
                    </Typography>
                  }
                  sx={getJobStatusChipStyles(job)}
                />
              </Box>
            </Box>
          </Box>
        </Card>
      ))}
      {selectedCompanyId ? (
        <CompanyProfileDrawer
          open={!!selectedCompanyId}
          companyId={selectedCompanyId}
          onClose={() => {
            setSelectedCompanyId(undefined);
          }}
        />
      ) : null}
    </Box>
  );
};
