import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { Box, Button, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { AppConstants } from "@/common/constants";

export function ContactSupportPage() {
  return (
    <Box textAlign="center" minHeight={"calc(100vh - 205px)"} overflow={"auto"}>
      <Box
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Typography variant="h5" m={0} p={0}>
            Contact Support
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary">
          Questions or feedback? Contact us and a team member will respond as
          soon as possible.
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"center"}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
          gap={4}
          width={"100%"}
          height={"100%"}
        >
          <Card
            sx={{
              width: { xs: "100%", md: "50%" },
              p: 3,
              cursor: "pointer",
              boxShadow: "0px 0px 0px 1px #F2F4F7",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              gap={1.5}
            >
              <IconButton
                aria-label="mail"
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: "#16DB651A",
                }}
              >
                <MailOutlineOutlinedIcon
                  sx={{ color: "#16DB65", width: "24px", height: "24px" }}
                />
              </IconButton>
              <Box>
                <Typography variant="subtitle1">Email</Typography>
                <Button color="primary" href="mailto:support@tradeengage.com">
                  support@tradeengage.com
                </Button>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              width: { xs: "100%", md: "50%" },
              p: 3,
              cursor: "pointer",
              boxShadow: "0px 0px 0px 1px #F2F4F7",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1.5}
            >
              <IconButton
                aria-label="mail"
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: "#16DB651A",
                }}
              >
                <LocalPhoneOutlinedIcon
                  sx={{ color: "#16DB65", width: "24px", height: "24px" }}
                />
              </IconButton>
              <Box>
                <Typography variant="subtitle1">Phone Number</Typography>
                <Button
                  color="primary"
                  href={`tel:${AppConstants.supportContactNumber}`}
                >
                  +1 (786) 673-1551
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
