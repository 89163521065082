import { ChipProps, Typography } from "@mui/material";

import { Job, JobStatus } from "@/company/jobs/types";

export const getHomeownerJobCardStatusChipProps = (
  job: Job,
  removeMarginOnMobile = false
): ChipProps => {
  const { status, buyingCompanyId } = job;
  const chipProps: ChipProps = {
    sx: {
      ml: { xs: removeMarginOnMobile ? 0 : 1, md: 0 },
      height: "24px",
      textTransform: "uppercase",
      cursor: "default",
      fontSize: "12px",
      fontWeight: 600,
    },
  };

  if (status === JobStatus.SUBMISSION) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "neutral.100",
      color: "text.secondary",
    };
    chipProps.label = (
      <Typography variant="overline">Video Processing</Typography>
    );
  }

  if (status === JobStatus.OPPORTUNITY) {
    // If by any reason the job is shared with the HO while still being an OPPORTUNITY, show this message
    chipProps.label = (
      <Typography variant="overline">Pending Company Review</Typography>
    );
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "#EDFCF2",
      color: "#16B364",
    };
  }

  if (status === JobStatus.PENDING_HOMEOWNER_ACTION) {
    chipProps.label = (
      <Typography variant="overline">Pending Review</Typography>
    );
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "#EDFCF2",
      color: "#16B364",
    };
  }
  if (status === JobStatus.HOMEOWNER_DECLINED) {
    chipProps.label = <Typography variant="overline">Declined</Typography>;
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "rgba(240, 68, 56, 0.12)",
      color: "#B42318",
    };
  }
  if (status === JobStatus.HOMEOWNER_DELAYED) {
    chipProps.label = <Typography variant="overline">Delayed</Typography>;
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "rgba(247, 144, 9, 0.12)",
      color: "#B54708",
    };
  }
  if (
    [
      JobStatus.HOMEOWNER_ACCEPTED,
      JobStatus.ROUTED,
      JobStatus.NO_COMPANY_TO_ROUTE,
    ].includes(status)
  ) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "#EBEFFF",
      color: "#2970FF",
    };
    chipProps.label = <Typography variant="overline">Requested</Typography>;
  }
  if (status === JobStatus.PENDING_REFERRAL_PAYMENT) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "#16B364",
      color: "white",
    };
    chipProps.label = (
      <Typography variant="overline">Pending Partner Update</Typography>
    );
  }
  if (
    status === JobStatus.PAID ||
    status === JobStatus.PENDING_REFERRAL_PAYMENT
  ) {
    chipProps.sx = {
      ...chipProps.sx,
      bgcolor: "#16B364",
      color: "white",
    };
    chipProps.label = <Typography variant="overline">Completed</Typography>;
  }

  return chipProps;
};
