import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useMarkChatsWithAdminAsRead(
  options: UseMutationOptions<void, AxiosError, number> = {}
): UseMutationResult<void, AxiosError, number> {
  return useMutation({
    mutationFn: async (chatId: number) => {
      await API.patch(`/internal-admin/chats-company-to-user/${chatId}/read`);
    },
    ...options,
  });
}
