import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Box, Paper, Stack, styled, Typography } from "@mui/material";

const ThinPaper = styled(Paper)(({ theme }) => ({
  width: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

const AddJobDetailsCard = ({
  addJobDetailsDialogOpen,
}: {
  addJobDetailsDialogOpen?: (value: boolean) => void;
}) => {
  return (
    <>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Stack
          alignItems="flex-start"
          direction={"row"}
          justifyContent={"center"}
          spacing={2}
          sx={{
            ml: 0,
            mr: "auto",
          }}
        >
          <Box
            sx={{
              height: "40px",
              width: "40px",
              p: 1,
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              p: 1,
              borderRadius: "8px",
              background: "#EDFCF2",
              boxShadow: "0px 0px 0px 1px #F2F4F7",
            }}
          >
            <Typography variant="body1" sx={{ color: "#16DB65" }}>
              Unable to identify job{" "}
              <Typography
                variant="body1"
                sx={{ color: "rgba(17, 25, 39, 0.38)" }}
                display={"inline"}
              >
                (TradeEngage AI)
              </Typography>
            </Typography>
          </Box>
        </Stack>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Stack
          alignItems="flex-start"
          direction={"row"}
          justifyContent={"center"}
          spacing={2}
          sx={{
            ml: 0,
            mr: "auto",
          }}
        >
          {/* <Box
            sx={{
              height: "40px",
              width: "40px",
              p: 1,
            }}
          /> */}
          <ThinPaper
            variant="outlined"
            sx={{
              width: "100%",
              display: "flex",
              gap: 1,
              alignItems: "start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: 2,
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <WarningAmberRoundedIcon
                sx={{
                  bgcolor: "#FEE4E2",
                  borderRadius: "50%",
                  width: "48px",
                  height: "48px",
                  color: "#F04438",
                  p: 1,
                  pb: 1.2,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "center",
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  textAlign={"start"}
                  mb={1}
                >
                  No Job Identified
                </Typography>
                <Typography
                  variant="body2"
                  fontSize={"14px"}
                  color={"text.secondary"}
                  textAlign={"start"}
                >
                  Click the &quot;Add Job Info&quot; button to add the details
                  for this job.
                </Typography>
              </Box>
            </Box>
            {/* <Box
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
              width={"100%"}
              mt={2}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => addJobDetailsDialogOpen(true)}
              >
                Add Job Details
              </Button>
            </Box> */}
          </ThinPaper>
        </Stack>
      </Box>
    </>
  );
};

export default AddJobDetailsCard;
