import { Close as CloseIcon } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { TextEditorTextPreview } from "@/lib/text-editor/TextEditorTextPreview";
import { isDefined } from "@/utils/isDefined";

import { referralCampaignConfigurationSteps } from "../../constants";
import {
  ReferralCampaign,
  ReferralCampaignConfigurationStep,
} from "../../types";

interface CampaignBasicInfoProps {
  referralCampaign: Omit<ReferralCampaign, "id" | "createdAt" | "updatedAt">;
  onClose?: () => void;
  indexOfActiveStep?: number;
}

export function CampaignBasicInfo(props: CampaignBasicInfoProps) {
  const { referralCampaign, onClose, indexOfActiveStep } = props;

  const isLoading =
    isDefined(indexOfActiveStep) &&
    indexOfActiveStep <=
      referralCampaignConfigurationSteps.indexOf(
        ReferralCampaignConfigurationStep.CAMPAIGN_BASIC_INFO
      );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="start">
        {isLoading ? (
          <Skeleton variant="rounded" width="100%" height="30px" />
        ) : (
          <Typography variant="h5" fontWeight={700}>
            {referralCampaign.name}
          </Typography>
        )}
        {isDefined(onClose) && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
      <Stack>
        {isLoading ? (
          <Stack gap={1}>
            <Skeleton variant="rounded" width="100%" height="15px" />
            <Skeleton variant="rounded" width="100%" height="15px" />
            <Skeleton variant="rounded" width="100%" height="15px" />
            <Skeleton variant="rounded" width="100%" height="15px" />
          </Stack>
        ) : (
          <>
            <Typography variant="body2" color="text.secondary">
              From{" "}
              {DateTime.fromISO(referralCampaign.startDate, {
                zone: "utc",
              })
                .toLocal()
                .toFormat("MM/dd/yy hh:mm a")}{" "}
              to{" "}
              {DateTime.fromISO(referralCampaign.endDate, {
                zone: "utc",
              })
                .toLocal()
                .toFormat("MM/dd/yy hh:mm a")}
            </Typography>
            <Box sx={{ paddingY: 0.5 }}>
              <TextEditorTextPreview
                content={referralCampaign.description}
                fontSize="14px"
              />
            </Box>
          </>
        )}
      </Stack>
    </>
  );
}
