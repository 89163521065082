import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useState } from "react";

import CreateCompanyTeamMemberDialog from "./CreateCompanyTeamMemberDialog";

export default function CompanyTeamMembersPage() {
  const [
    openCreateCompanyTeamMemberDialog,
    setOpenCreateCompanyTeamMemberDialog,
  ] = useState(false);

  return (
    <Box
      display={"flex"}
      sx={{ height: "100%" }}
      overflow={"auto"}
      flexDirection={"column"}
    >
      <Box display={"flex"} alignItems={"center"} p={3}>
        <Typography variant="h5">Company Team Members</Typography>
      </Box>
      <Box sx={{ height: "calc(100vh - 260px)", position: "relative", px: 3 }}>
        <Box display={"flex"} gap={2} mb={1} position={"relative"}>
          <LoadingButton
            startIcon={<Add />}
            variant={"contained"}
            size="small"
            onClick={() => setOpenCreateCompanyTeamMemberDialog(true)}
          >
            Create Company Team Member
          </LoadingButton>
        </Box>
      </Box>
      {/* Dialogs */}
      {openCreateCompanyTeamMemberDialog ? (
        <CreateCompanyTeamMemberDialog
          open={openCreateCompanyTeamMemberDialog}
          onClose={() => setOpenCreateCompanyTeamMemberDialog(false)}
        />
      ) : null}
    </Box>
  );
}
