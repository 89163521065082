import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Chip } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridComparatorFn,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useArchiveUser } from "@/company/api/useArchiveUser";
import { InternalCompanyRelationshipSchema } from "@/company/api/useCompanyParentRelationships";
import { useDeleteRelationship } from "@/company/api/useDeleteRelationship";
import { useUpdateCompanyUser } from "@/company/api/useUpdateCompanyUser";

import DeleteRelationshipConfirmationDialog from "./DeleteRelationshipConfirmationDialog";
import {
  CustomToolbar,
  getGridStringOperatorsDataGrid,
  renderJobSectorsArea,
} from "./utils";

export default function ParentRelationshipTable({
  relationshipData,
  companyId,
  expanded,
  refetch,
}: {
  relationshipData: Array<InternalCompanyRelationshipSchema>;
  companyId: number;
  expanded?: boolean;
  refetch: () => void;
}) {
  const [rows, setRows] = useState([]);
  const [deleteRowId, setDeleteRowId] = useState<GridRowId | null | undefined>(
    null
  );

  const { isLoading: isDeleting, mutateAsync: archiveUser } = useArchiveUser({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while deleting relationship.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      refetch();
      enqueueSnackbar({
        message: "Relationship deleted successfully.",
        variant: "success",
      });
    },
  });
  const sectorComparator: GridComparatorFn<Array<string>> = (v1, v2) => {
    const minLength = Math.min(v1.length, v2.length);

    for (let i = 0; i < minLength; i++) {
      if (v1[i] < v2[i]) return -1;
      if (v1[i] > v2[i]) return 1;
    }

    // If all elements are equal so far, the shorter array comes first
    return v1.length - v2.length;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const { mutateAsync: updateUser, isLoading } = useUpdateCompanyUser({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while updating user details.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      enqueueSnackbar({
        message: "Your changes have been saved.",
        variant: "success",
      });
    },
  });

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const processRowUpdate = async (newRow: GridRowModel, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    await updateUser({
      companyId,
      userId: updatedRow.id,
      updatedUser: {
        ...newRow,
        firstName: newRow?.name?.split(" ")?.[0],
        lastName: newRow?.name?.split(" ")?.[1],
        oldGroups: oldRow.types,
        newGroups: newRow.types,
      },
    });

    setRows(
      rows.map(row =>
        row.id === newRow.id
          ? {
              ...updatedRow,
              firstName: newRow?.name?.split(" ")?.[0],
              lastName: newRow?.name?.split(" ")?.[1],
            }
          : row
      )
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    setRows(relationshipData);
  }, [relationshipData]);

  function getRowId(row) {
    return `${row.relationshipId}`;
  }

  const { isLoading: isDeletingRelationship, mutateAsync: deleteRelationship } =
    useDeleteRelationship({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while deleting relationship.";
        enqueueSnackbar(message, {
          variant: "error",
        });
        refetch();
      },
      onSuccess() {
        enqueueSnackbar({
          message: "Relationship deleted successfully.",
          variant: "success",
        });
        refetch();
      },
    });

  const handleDeleteClick = (id: GridRowId) => {
    setRows(rows.filter(row => row.id !== id));
    deleteRelationship(id);
  };

  let columns: GridColDef[] = [
    {
      field: "relationshipId",
      headerName: "ID",
      width: 200,
      renderCell: params => params.row.relationshipId?.toString(),
    },
    {
      field: "childrenCompany-id",
      editable: true,
      headerName: "Children Company Id",
      renderCell: params => params.row.childrenCompany?.id?.toString(),

      width: 200,
      valueGetter: (value, row) => row.childrenCompany?.id,
      valueSetter: (value, row) => {
        return {
          ...row,
          childrenCompany: {
            ...row.childrenCompany,
            id: value,
          },
        };
      },
    },
    {
      field: "childrenCompany-logoUrl",
      headerName: "Children Company Logo",
      width: 200,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <img
              src={params.row.childrenCompany.logoUrl}
              style={{
                width: "48px",
                height: "32px",
                objectFit: "contain",
              }}
              alt="company-logo"
            />
          </Box>
        );
      },
      sortable: false,
    },
    {
      field: "childrenCompany-name",
      editable: true,
      headerName: "Children Company Name",
      width: 200,
      valueGetter: (value, row) => row.childrenCompany?.name,
      valueSetter: (value, row) => {
        return {
          ...row,
          childrenCompany: {
            ...row.childrenCompany,
            name: value,
          },
        };
      },
    },
    {
      field: "childrenCompany-accountType",
      editable: true,
      headerName: "Children Company Account Type",
      width: 200,
      valueGetter: (value, row) => row.childrenCompany?.accountType,
      valueSetter: (value, row) => {
        return {
          ...row,
          childrenCompany: {
            ...row.childrenCompany,
            accountType: value,
          },
        };
      },
    },
    {
      field: "childrenCompany-sectors",
      headerName: "Children Service Categories",
      editable: true,
      type: "string",
      width: 200,
      sortComparator: sectorComparator,
      renderEditCell: renderJobSectorsArea,
      valueFormatter: value => `${value}`,
      renderCell: params => {
        const sectorChips = params.row?.childrenCompany?.sectors?.map(
          sector => (
            <Chip
              key={sector}
              sx={{
                color: "text.secondary",
                bgcolor: "#F8F9FA",
              }}
              label={sector}
              size="small"
            />
          )
        );
        return sectorChips;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            key={`${id}-delete`}
            icon={<DeleteIcon />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setDeleteRowId(id);
            }}
          />,
        ];
      },
    },
  ];

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  return (
    <Box display={"flex"} width={"100%"}>
      <Box
        sx={{
          height: 400,
          position: "relative",
          width: "100%",
        }}
      >
        <DataGridPremium
          checkboxSelection
          disableRowSelectionOnClick
          pagination
          columns={columns}
          getRowId={getRowId}
          sx={{
            "& .MuiDataGrid-row": {
              "--rowBorderColor": "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withRightBorder": {
              borderRightColor: "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withLeftBorder": {
              borderLeftColor: "#D9D9D9",
            },
          }}
          rows={rows ?? []}
          slots={{
            ...(expanded
              ? {
                  toolbar: () => (
                    <CustomToolbar
                      fileName="Company_Relationships"
                      includeQuickFilter={false}
                    />
                  ),
                }
              : {}),
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
          initialState={{
            density: "compact",
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "logoUrl", "name"],
              right: ["actions"],
            },
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          slotProps={{
            toolbar: { setRowModesModel },
          }}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
        />
      </Box>
      <DeleteRelationshipConfirmationDialog
        open={!!deleteRowId}
        handleClose={() => setDeleteRowId(null)}
        handleConfirm={() => {
          handleDeleteClick(deleteRowId);
          setDeleteRowId(null);
        }}
      />
    </Box>
  );
}
