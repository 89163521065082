import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

import { Job } from "../types";

const SWITCH_JOB_BY_ID_URL = "/jobs/:id/switch-companies";

export type SwitchJobRequest = {
  jobId: string;
};

export type UpdateJobResponse = { job: Job };

export function useSwitchJobById(
  options: UseMutationOptions<Job, AxiosError, SwitchJobRequest> = {}
): UseMutationResult<Job, AxiosError, SwitchJobRequest> {
  return useMutation({
    mutationFn: async (data: SwitchJobRequest) => {
      const url = SWITCH_JOB_BY_ID_URL.replace(":id", data.jobId.toString());
      const resp = await API.post(url);
      return resp?.data?.data;
    },
    ...options,
  });
}
