import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

export const GET_USER_STRIPE_ACCOUNT_URI = "/users/stripe/account";

export interface StripeAccountDetails {
  bankName: string;
  bankLast4Digits: string;
  lastWithdrawDate?: string;
  actionRequired: boolean;
}

export function useGetStripeAccountDetails(
  options?: UseQueryOptions<any>
): UseQueryResult<StripeAccountDetails> {
  return useQuery({
    queryKey: [GET_USER_STRIPE_ACCOUNT_URI],
    queryFn: async () => {
      const response = await API.get(GET_USER_STRIPE_ACCOUNT_URI);
      return response.data.data;
    },
    ...options,
  });
}
