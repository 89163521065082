import { LoadingButton } from "@mui/lab";
import { Card, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { useAuth } from "@/auth/context/jwt";
import { AppConstants } from "@/common/constants";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyRoutes } from "@/company/types";
import {
  GetStripeLinkPayload,
  StripeLinkType,
  useGetStripeLink,
} from "@/user/api/useGetStripeAccountLink";
import { StripeAccountStatus } from "@/user/types";

export function AddBankAccountCard({
  stripeStatus,
  isFetchingStatus,
}: {
  stripeStatus?: StripeAccountStatus;
  isFetchingStatus: boolean;
}) {
  const { refreshSession } = useAuth();

  const getStripeLinkPayload: GetStripeLinkPayload = {
    linkType: StripeLinkType.UPDATE,
    returnUrl:
      AppConstants.platformBaseUrl +
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.MY_WALLET,
      ]),
    refreshUrl: window.location.href,
  };

  const { isLoading: isFetchingLink, mutateAsync: getStripeLink } =
    useGetStripeLink({
      onError: () => {
        enqueueSnackbar(
          "There was an error while fetching your stripe account link.",
          { variant: "error" }
        );
      },
      onSuccess: () => {
        refreshSession();
      },
    });
  return (
    <Card
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        bgcolor: "white",
        boxShadow: "0px 0px 0px 1px #F2F4F7",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src="/bank.svg"
        alt="missing bank"
        style={{ width: "100px", height: "100px" }}
      />
      <Typography variant="h6">
        Missing{" "}
        {stripeStatus === StripeAccountStatus.NEEDS_CREATION
          ? "Stripe"
          : "Bank"}{" "}
        Info
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Click{" "}
        {stripeStatus === StripeAccountStatus.NEEDS_CREATION
          ? "Connect Stripe"
          : "Add Bank"}{" "}
        to withdraw your balance
      </Typography>
      <LoadingButton
        fullWidth
        variant="contained"
        disabled={isFetchingStatus || isFetchingLink}
        loading={isFetchingStatus || isFetchingLink}
        onClick={async () => {
          const link = await getStripeLink(getStripeLinkPayload);
          if (link) window.location.href = link;
        }}
      >
        {stripeStatus === StripeAccountStatus.NEEDS_CREATION
          ? "Connect Stripe"
          : " Add Bank Account"}
      </LoadingButton>
    </Card>
  );
}
