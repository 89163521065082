import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

const REFERRAL_INVITATION_RESPONSE_URI = "/referral-invites/public/respond";

export function useUpdateReferralInvitationResponse(
  options: UseMutationOptions<
    void,
    AxiosError,
    { response: string; referralInvitationId: string; userId?: number }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { response: string; referralInvitationId: string; userId?: number }
> {
  return useMutation({
    mutationFn: async ({ userId, referralInvitationId, response }) => {
      const url = REFERRAL_INVITATION_RESPONSE_URI;
      await API.post(
        url,
        changeCaseKeys(
          { userId, referralInvitationId, response },
          "underscored"
        )
      );
    },
    ...options,
  });
}

interface CreateJobReferralRequest {
  fromCompanyId: number;
  toCompanyId: number;
  homeownerFirstName: string;
  homeownerLastName: string;
  homeownerCellPhone: string;
  homeownerEmail: string;
  address: string;
  projectDetails: string;
  sector: string;
}

export function useCreateJobReferral(
  options: UseMutationOptions<void, AxiosError, CreateJobReferralRequest> = {}
): UseMutationResult<void, AxiosError, CreateJobReferralRequest> {
  return useMutation({
    mutationFn: async data => {
      await API.post("/jobs/public", changeCaseKeys(data, "underscored"));
    },
    ...options,
  });
}
