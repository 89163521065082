import {
  Close as CloseIcon,
  QuestionAnswerOutlined as QuestionAnswerOutlinedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import debounce from "lodash/debounce";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import * as yup from "yup";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { TextInputField } from "@/lib/form-fields/TextInputField";
import { UseModalState } from "@/lib/modals/useModalState";

import { useGetExternalConnections } from "../api/useGetExternalConnections";
import { StrategicPartner } from "../types";

export const facilitateConnectionFormSchema = yup.object().shape({
  searchWord: yup.string(),
  message: yup.string().required("Message is required"),
  selectedConnections: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        userName: yup.string().required(),
      })
    )
    .required("Connections are required")
    .min(1),
});

export type FacilitateConnectionFormFields = yup.InferType<
  typeof facilitateConnectionFormSchema
>;

interface FacilitateConnectionDialogProps {
  modalState: UseModalState;
  selectedStrategicPartner: StrategicPartner;
}

export function FacilitateConnectionDialog(
  props: FacilitateConnectionDialogProps
) {
  const { modalState, selectedStrategicPartner } = props;
  const { enqueueSnackbar } = useSnackbar();
  const facilitateConnectionFormMethods =
    useFormik<FacilitateConnectionFormFields>({
      initialValues: {
        searchWord: "",
        selectedConnections: [],
        message: `Dear Rollins partners,\n
I’m excited to introduce our Rollins brands to strategic partners like you. At Rollins, we protect millions of customers from pests such as termites, rodents, and wildlife. We’re committed to building strong partnerships, maximizing the value we bring to our customers.\n
To streamline this collaboration, Rollins uses TradeEngage, the referral enablement software for the trades. By signing up through the link below, you’ll be automatically connected with our local team, enabling seamless, instant referrals.\n
We look forward to growing together and supporting each other in delivering top-notch service!`,
      },
      validationSchema: facilitateConnectionFormSchema,
      validateOnMount: true,
      onSubmit: () => {
        console.log("submit");
      },
    });

  const {
    data: externalConnections,
    isLoading: isLoadingExternalConnections,
    refetch: refetchExternalConnections,
  } = useGetExternalConnections({
    searchWord: facilitateConnectionFormMethods.values.searchWord,
  });

  useEffect(() => {
    const debouncedRefetch = debounce(() => {
      refetchExternalConnections();
    }, 300);

    debouncedRefetch();

    return () => {
      debouncedRefetch.cancel();
    };
  }, [
    refetchExternalConnections,
    facilitateConnectionFormMethods.values.searchWord,
  ]);

  return (
    <Dialog
      fullWidth
      open={modalState.modalIsOpen}
      maxWidth="lg"
      PaperProps={{
        sx: {
          padding: 3,
          height: "80vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
      onClose={modalState.closeModal}
    >
      <DialogTitle
        component="div"
        sx={{
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack>
          <Typography gutterBottom variant="h5" fontWeight={700}>
            Facilitate Connection
          </Typography>
          <Typography gutterBottom variant="body2" color={"text.secondary"}>
            Introduce your local team to a Rollins strategic partner. Customize
            your message from here.
          </Typography>
        </Stack>
        <IconButton
          aria-label="close"
          sx={{
            color: theme => theme.palette.grey[500],
            width: 40,
            height: 40,
          }}
          onClick={modalState.closeModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <FormikProvider value={facilitateConnectionFormMethods}>
        <DialogContent
          sx={{
            padding: 0,
            flexGrow: 1,
            marginTop: 1.5,
            overflow: "hidden",
            display: "flex",
          }}
        >
          {isLoadingExternalConnections ? (
            <ComponentLoader />
          ) : (
            <Grid container sx={{ height: "100%" }}>
              <Grid
                item
                xs={12}
                md={5.5}
                sx={{
                  border: theme => `1px solid ${theme.palette.neutral[100]}`,
                  borderRadius: 1,
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <Box padding={1}>
                  <TextInputField
                    fullWidth
                    name="searchWord"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        ".MuiFilledInput-input": {
                          padding: 1,
                        },
                      },
                    }}
                    placeholder="Search by company name or keyword"
                  />
                </Box>
                <List disablePadding>
                  {externalConnections?.map(externalConnection => {
                    const isSelected =
                      facilitateConnectionFormMethods.values.selectedConnections.some(
                        selectedConnection =>
                          selectedConnection.id === externalConnection.id
                      );
                    return (
                      <ListItem
                        key={externalConnection.id}
                        disablePadding
                        sx={{
                          backgroundColor: isSelected ? "#EDFCF2" : "",
                        }}
                      >
                        <ListItemButton
                          sx={{ padding: 0 }}
                          onClick={() => {
                            facilitateConnectionFormMethods.setFieldValue(
                              "selectedConnections",
                              isSelected
                                ? facilitateConnectionFormMethods.values.selectedConnections.filter(
                                    selectedConnection =>
                                      selectedConnection.id !==
                                      externalConnection.id
                                  )
                                : [
                                    ...facilitateConnectionFormMethods.values
                                      .selectedConnections,
                                    {
                                      id: externalConnection.id,
                                      userName: `${externalConnection.users[0]?.firstName} ${externalConnection.users[0]?.lastName}`,
                                    },
                                  ]
                            );
                          }}
                        >
                          <ListItemIcon>
                            <Stack
                              direction="row"
                              gap={0}
                              alignItems="center"
                              paddingLeft={0.5}
                            >
                              <Checkbox
                                disableRipple
                                edge="start"
                                size="small"
                                checked={isSelected}
                              />
                              <Box
                                sx={{
                                  height: 32,
                                  width: 48,
                                  objectFit: "contain",
                                  bgColor: "white",
                                }}
                                component="img"
                                src={externalConnection.logoUrl}
                              />
                            </Stack>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="caption"
                                fontWeight={500}
                                display="block"
                              >
                                {externalConnection.name}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="caption"
                                color="text.secondary"
                                fontWeight={500}
                                display="block"
                              >
                                {externalConnection.users[0]?.firstName}{" "}
                                {externalConnection.users[0]?.lastName}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Grid item xs={12} md={6.5} sx={{ height: "100%", paddingX: 1 }}>
                <Stack gap={1.5}>
                  <Stack>
                    <Typography
                      variant="caption"
                      fontWeight={600}
                      color="text.disabled"
                    >
                      ROLLINS LOCATION
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="subtitle2" fontWeight={500}>
                        {selectedStrategicPartner.company.name}
                      </Typography>
                      <Box
                        sx={{ height: 24 }}
                        component="img"
                        src={selectedStrategicPartner.company.logoUrl}
                      />
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="caption"
                      fontWeight={600}
                      color="text.disabled"
                    >
                      CONNECTIONS INCLUDED
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        listStyle: "none",
                        gap: 1,
                      }}
                    >
                      {facilitateConnectionFormMethods.values
                        .selectedConnections.length > 0 ? (
                        facilitateConnectionFormMethods.values.selectedConnections.map(
                          currentSelectedConnection => {
                            return (
                              <Chip
                                key={currentSelectedConnection.id}
                                size="small"
                                label={currentSelectedConnection.userName}
                                sx={{ backgroundColor: "#11192714" }}
                                onDelete={() => {
                                  facilitateConnectionFormMethods.setFieldValue(
                                    "selectedConnections",
                                    facilitateConnectionFormMethods.values.selectedConnections.filter(
                                      selectedConnection =>
                                        currentSelectedConnection.id !==
                                        selectedConnection.id
                                    )
                                  );
                                }}
                              />
                            );
                          }
                        )
                      ) : (
                        <Typography variant="body2">
                          No connections selected
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                  <TextInputField
                    multiline
                    fullWidth
                    rows={16}
                    name="message"
                    label="Message"
                    sx={{
                      flexGrow: 1,
                    }}
                  />
                  <Stack direction="row" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      startIcon={<QuestionAnswerOutlinedIcon />}
                      disabled={!facilitateConnectionFormMethods.isValid}
                      onClick={() => {
                        enqueueSnackbar(
                          "Successfully sent connection requests",
                          {
                            variant: "success",
                          }
                        );
                        modalState.closeModal();
                      }}
                    >
                      Send Message
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </FormikProvider>
    </Dialog>
  );
}
