import { Box, FormLabel, Stack, Typography } from "@mui/material";
import { EditorContent, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";

import { isDefined } from "@/utils/isDefined";

interface BulletListInputFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  maxRows?: number;
}

export function BulletListInputField(props: BulletListInputFieldProps) {
  const { name, label, maxRows } = props;
  const [field, meta, helpers] = useField(name);
  const { isSubmitting, setFieldTouched } = useFormikContext();

  const isInErrorState = Boolean(meta.touched && meta.error);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: { keepMarks: true, keepAttributes: false },
      }),
    ],
    editable: !isSubmitting,
    content: field.value,
    onBeforeCreate: ({ editor }) => {
      const initialContent = field.value;
      editor.storage.initialContent = initialContent;
    },
    onUpdate: ({ editor }) => {
      if (isDefined(maxRows)) {
        const jsonContent = editor.getJSON();
        const bulletList = jsonContent.content?.find(
          node => node.type === "bulletList"
        );
        const currentRows = bulletList?.content?.length ?? 0;

        if (currentRows > maxRows) {
          const prevContent = editor.storage.lastValidContent;
          if (prevContent) {
            editor.commands.setContent(prevContent, false);
            return;
          }
        } else {
          editor.storage.lastValidContent = editor.getHTML();
        }
      }
      const content = editor.getHTML();
      helpers.setValue(content);
    },
    onCreate: ({ editor }) => {
      if (!editor.isActive("bulletList")) {
        editor.commands.toggleBulletList();
      }
      editor.storage.lastValidContent = editor.getHTML();
    },
    onFocus: () => {
      setFieldTouched(name, true, false);
    },
  });

  useEffect(() => {
    if (editor) {
      const handleUpdate = () => {
        if (!editor.isActive("bulletList")) {
          editor.commands.clearContent();
          editor.commands.toggleBulletList();
          editor.commands.focus();
        }
      };

      const handlePaste = (event: ClipboardEvent) => {
        if (!isDefined(maxRows)) return;

        const jsonContent = editor.getJSON();
        const bulletList = jsonContent.content?.find(
          node => node.type === "bulletList"
        );
        const currentRows = bulletList?.content?.length ?? 0;

        const clipboardText = event.clipboardData?.getData("text/plain");
        if (clipboardText) {
          const newLines = clipboardText
            .split("\n")
            .filter(line => line.trim()).length;

          if (currentRows + newLines - 1 >= maxRows) {
            event.preventDefault();
            return;
          }
        }
      };

      editor.on("update", handleUpdate);
      editor.view.dom.addEventListener("paste", handlePaste);

      return () => {
        editor.off("update", handleUpdate);
        editor.view.dom.removeEventListener("paste", handlePaste);
      };
    }
  }, [editor, maxRows]);

  return (
    <Stack gap={0.5}>
      <Box
        sx={{
          border: "1px solid #E5E7EB",
          paddingY: 1,
          borderRadius: 1,
          "& .ProseMirror": {
            outline: "none",
            minHeight: "100px",
            paddingX: "12px",
            "& ul": {
              margin: "0",
              padding: "0 0 0 24px",
            },
            "& li": {
              margin: "0",
              padding: "2px 0",
            },
            "& p": {
              margin: "0",
              padding: "2px 0",
              "font-family": `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
              color: "#111927",
              "font-weight": "500",
            },
          },
          "& .ProseMirror:focus": {
            outline: "none",
          },
          "&:focus-within": {
            outline: `2px solid ${isInErrorState ? "#F04438" : "#16B364"}`,
            boxShadow: `0 0 0 2px ${isInErrorState ? "#F04438" : "#16B364"}`,
            outlineOffset: "-1px",
          },
          ...(isInErrorState && {
            borderColor: "#F04438",
            outlineOffset: "-1px",
            boxShadow: "0 0 0 2px #F04438",
          }),
        }}
      >
        <FormLabel sx={{ paddingX: 1.5 }}>{label}</FormLabel>
        <EditorContent editor={editor} />
      </Box>
      {isInErrorState && (
        <Typography
          color="error.main"
          sx={{
            fontWeight: 500,
            marginX: "14px",
            marginY: 0,
            fontSize: "0.75rem",
          }}
        >
          {meta.error}
        </Typography>
      )}
    </Stack>
  );
}
