/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Add as AddIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "lodash";
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import { FC, KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import API from "@/common/api";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useCreateCompanyConnections } from "@/company/api/useCreateCompanyConnections";
import { useCreateInvitation } from "@/company/api/useCreateInvitation";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { useReferralContext } from "@/company/context/referral-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import { Company, CompanyPublicProfile } from "@/company/types";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { useConnectionLimitReachedModalContext } from "@/open/components/modals/LimitReachedContext";

interface Column {
  id: "email";
  label: string;
  minWidth?: number;
  align?: "left";
}

interface EmailInviteProps {
  onSendInvite?: () => void;
  googlePlacesId?: string;
}

const columns: readonly Column[] = [{ id: "email", label: "Email Address" }];

export const EmailInvite: FC<EmailInviteProps> = ({
  onSendInvite,
  googlePlacesId,
}) => {
  const [companyId, setCompanyId] = useState<number | null>(null);

  const { data: companyProfileByParam } = useFetchCompanyProfile(companyId, {
    refetchOnWindowFocus: false,
    enabled: !!companyId,
  });
  const [currentCompanyProfile, setCurrentCompanyProfile] =
    useState<CompanyPublicProfile | null>(null);

  const [emails, setEmails] = useState<
    Array<{ email: string; company?: Company }>
  >([]);
  const [email, setEmail] = useState("");

  const [page, setPage] = useState(0);
  const [fetchingSearchedCompany, setFetchingSearchedCompany] = useState(false);
  const { setShowLimitReachedDialog } = useConnectionLimitReachedModalContext();

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { enqueueSnackbar } = useSnackbar();
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const { referralPartners } = useReferralContext();
  const { referralInvitations } = useReferralInvitationContext();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleDelete = (deletedIndex: number) => {
    let newEmailsList = [...emails];
    newEmailsList = newEmailsList.filter(
      (email, index) => index !== deletedIndex
    );
    setEmails(newEmailsList);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (companyProfileByParam) {
      setCurrentCompanyProfile(companyProfileByParam);
    }
  }, [companyProfileByParam]);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const action = (snackbarId: SnackbarKey, onClick: () => void) => (
    <>
      <Typography
        sx={{
          color: "#16DB65",
          fontSize: "14px",
          fontWeight: 600,
          pr: 2,
          cursor: "pointer",
        }}
        onClick={() => {
          onClick();
          closeSnackbar(snackbarId);
        }}
      >
        Contact Us
      </Typography>
      <CloseIcon
        sx={{ color: "white", cursor: "pointer", mr: 1 }}
        fontSize="small"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      />
    </>
  );

  const {
    isLoading: creatingConnections,
    mutateAsync: createCompanyConnections,
  } = useCreateCompanyConnections({
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error?.message
        : "There was an error while creating the connection requests";
      if (error?.status_code === 409) {
        setShowLimitReachedDialog(message);
        return;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
    },
  });

  const { mutateAsync: createInvitation, isLoading: creatingInvitations } =
    useCreateInvitation({
      onSuccess: () => {
        enqueueSnackbar({
          message: "Your request has been submitted.",
          variant: "success",
          subText: (
            <>
              Need help?{" "}
              <a
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={event => {
                  event.preventDefault();
                  setContactSupportModalOpen(true);
                }}
              >
                Contact support
              </a>
            </>
          ),
        });
        setEmails([]);
        onSendInvite?.();
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error?.message
          : "There was an error while trying to send the invite.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
    });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onAdd = async (email: string) => {
    const uri = "/companies";
    const uriWithSearchQuery = `${uri}?email=${email}`;
    try {
      setFetchingSearchedCompany(true);
      const response = await API.get(uriWithSearchQuery);
      const companyResult = response.data.data;
      if (companyResult) {
        if (
          referralPartners?.some(
            referralPartner =>
              referralPartner.referredCompanyId === companyResult.id ||
              referralPartner.referringCompanyId === companyResult.id
          )
        ) {
          enqueueSnackbar("You're already connected to this company!", {
            variant: "error",
          });
          return;
        }
        if (
          referralInvitations?.some(
            referralInvitation =>
              (referralInvitation.invitedCompanyId === companyResult.id ||
                referralInvitation.invitingCompanyId === companyResult.id) &&
              !referralInvitation.declinedAt
          )
        ) {
          enqueueSnackbar("You're already connected to this company!", {
            variant: "error",
          });
          return;
        }
        setEmails(emails => [...emails, { email, company: companyResult }]);
        setEmail("");
        return;
      }
    } catch (error) {
      console.error({ error });
    } finally {
      setFetchingSearchedCompany(false);
    }
    setEmails(emails => [...emails, { email }]);
    setEmail("");
  };

  const handleSendInviteClick = async () => {
    const invitedCompanyIds = emails
      .filter(email => email.company)
      .map(email => email.company?.id) as Array<number>;
    const connectionRequestEmails = emails
      .filter(email => !email.company)
      .map(email => email.email);
    if (invitedCompanyIds) {
      await createCompanyConnections({
        invitedCompanyIds,
      });
    }
    if (connectionRequestEmails) {
      await createInvitation({
        googlePlacesId,
        emails: connectionRequestEmails,
      });
    }
    setEmails([]);
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === "Enter") {
      onAdd(email);
    }
  };

  const onCompanyClick = (companyId: number) => {
    setCompanyId(companyId);
  };

  return (
    <>
      <Box py={3} display={"flex"} flexDirection={"column"} gap={3}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <TextField
            fullWidth
            label="Enter Recipient's Email Address"
            name="email"
            type="email"
            value={email}
            onKeyUp={handleKeyUp}
            onChange={event => setEmail(event?.target.value)}
          />
          <LoadingButton
            variant="outlined"
            startIcon={<AddIcon />}
            disabled={!email || fetchingSearchedCompany}
            loading={fetchingSearchedCompany}
            onClick={() => {
              onAdd(email);
            }}
          >
            Add
          </LoadingButton>
        </Box>
      </Box>
      <Box>
        <TableContainer sx={{ height: 110 }} component={Paper}>
          <Table stickyHeader size="small" aria-label="email invite table">
            <TableHead>
              <TableRow sx={{ height: "2px" }}>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: isDesktop ? "300" : "80" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  key={"action"}
                  style={{ minWidth: isDesktop ? "170" : "80" }}
                  align="right"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                      {columns.map(column => {
                        const value = row.email;
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Box display={"flex"} gap={1} alignItems={"center"}>
                              {row.company ? (
                                <Avatar
                                  alt={`${row.company.name ?? ""}`}
                                  src={row.company.logoUrl}
                                  sx={{
                                    border: "1px solid gray !important",
                                    "& img": {
                                      objectFit: "contain",
                                      bgcolor: "white",
                                    },
                                  }}
                                >
                                  {`${row.company.name}` ?? ""}
                                </Avatar>
                              ) : (
                                <Avatar />
                              )}
                              <Box
                                display={"flex"}
                                flexDirection={isDesktop ? "row" : "column"}
                                alignItems={isDesktop ? "center" : "start"}
                                gap={isDesktop ? 2 : 0}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#6C737F" }}
                                >
                                  {" "}
                                  {value}
                                </Typography>
                                {row.company ? (
                                  <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                  >
                                    {" "}
                                    <Typography
                                      color="primary"
                                      variant="caption"
                                    >
                                      {row.company?.name}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      fontSize={"11px"}
                                    >
                                      <Typography
                                        variant="caption"
                                        fontSize={"11px"}
                                        display={"inline"}
                                        color={"primary"}
                                      >
                                        *{" "}
                                      </Typography>
                                      Found on TradeEngage
                                    </Typography>
                                  </Box>
                                ) : null}
                              </Box>
                            </Box>
                          </TableCell>
                        );
                      })}
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          aria-label="delete"
                          onClick={() => handleDelete(index)}
                        >
                          <PersonRemoveOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={_.uniq(emails).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Typography
          variant="caption"
          sx={{ fontStyle: "italic", color: "rgba(17, 25, 39, 0.38)" }}
        >
          <Typography
            variant="caption"
            display={"inline"}
            sx={{ fontStyle: "italic", color: "#16DB65" }}
          >
            *
          </Typography>
          Sending invitations to email addresses already registered on
          TradeEngage will trigger a connection request.
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          p={3}
        >
          <Button
            variant="contained"
            startIcon={<ForwardToInboxOutlinedIcon />}
            disabled={
              !emails?.length || creatingConnections || creatingInvitations
            }
            onClick={handleSendInviteClick}
          >
            Send Invite{" "}
            {_.uniq(emails).length ? `(${_.uniq(emails).length})` : ""}
          </Button>
        </Stack>
        {/* {currentCompanyProfile ? (
          <CompanyDrawer
            open={!!currentCompanyProfile}
            companyProfile={currentCompanyProfile}
            connectionStatus={ConnectionStatus.NOT_CONNECTED}
            onClose={() => {
              setCurrentCompanyProfile(null);
            }}
          />
        ) : null} */}
      </Box>
    </>
  );
};
