import { Close as CloseIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  debounce,
  Dialog,
  FormControl,
  IconButton,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { ElementType, SyntheticEvent, useMemo, useState } from "react";
import * as Yup from "yup";

import { Languages } from "@/common/constants/languages";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { BasicCompanySchema } from "@/company/api/useGellAllCompanies";
import { useGetAllBrandLocations } from "@/company/api/useGetAllBrandLocations";
import {
  DefaultCompanyGroupReadableNames,
  DefaultCompanyGroups,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { useCreateUser } from "@/internal-admin/api/useCreateUser";
import { getUSPhoneValidationSchema } from "@/utils";

import { SEARCH_MINIMUM_CHAR_COUNT } from "../constants";

export interface CreateCompanyTeamMemberDialogProps {
  open: boolean;
  onClose: () => void;
}

const validationSchema = Yup.object({
  phone: getUSPhoneValidationSchema().optional(),
  email: Yup.string()
    .email("Email Address must be a valid email address")
    .optional(),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
});

export default function CreateCompanyTeamMemberDialog(
  props: CreateCompanyTeamMemberDialogProps
) {
  const { open, onClose } = props;
  const [companyKeyword, setCompanyKeyword] = useState("");
  const [selectedCompany, setSelectedCompany] =
    useState<BasicCompanySchema | null>(null);

  const debouncedReferredSetter = useMemo(
    () =>
      debounce(
        (companyKeyword: string) => setCompanyKeyword(companyKeyword),
        500
      ),
    []
  );

  const {
    data: allBrandLocationCompanies,
    isLoading: isLoadingBrandLocationCompanies,
  } = useGetAllBrandLocations(companyKeyword, {
    enabled: !!companyKeyword,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: createUser, isLoading: isCreatingUser } = useCreateUser({
    onSuccess: () => {
      enqueueSnackbar({
        message: "User created successfully.",
        variant: "success",
      });
      onClose();
    },
    onError: error => {
      enqueueSnackbar({
        message: error.message ? error.message : "Error while creating user",
        variant: "error",
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      cellPhone: "",
      jobTitle: "",
      language: Languages.ENGLISH,
      platformPresence: PLATFORM_PRESENCE.OFF_PLATFORM,
      group: DefaultCompanyGroups.OWNER,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const {
        firstName,
        lastName,
        email,
        cellPhone,
        jobTitle,
        language,
        platformPresence,
        group,
      } = values;

      if (!selectedCompany) {
        enqueueSnackbar({
          message: "Please select a company",
          variant: "error",
        });
        return;
      }

      createUser({
        user: {
          firstName,
          lastName,
          email,
          cellPhone,
          jobTitle,
          language,
          platformPresence,
        },
        companyId: selectedCompany.id,
        group,
      });
    },
  });
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      sx={{ p: { xs: 0, md: 2 } }}
      onClose={onClose}
    >
      <Box width="100%" p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Box>
            <Typography gutterBottom variant="h5">
              Create Company Team Member
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary">
              Fill all the required fields to create a new company team member.
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          mt={3}
          display="flex"
          flexDirection={"column"}
          width="100%"
          gap={2}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            gap={2}
          >
            <TextField
              fullWidth
              required
              id="firstName"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <TextField
              fullWidth
              required
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Box>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            gap={2}
          >
            <TextField
              fullWidth
              id="cellPhone"
              name="cellPhone"
              margin="normal"
              label="Mobile Number"
              InputProps={{
                inputComponent: MaskedPhoneInput as unknown as
                  | ElementType<InputBaseComponentProps>
                  | undefined,
              }}
              value={formik.values.cellPhone}
              error={
                formik.touched.cellPhone && Boolean(formik.errors.cellPhone)
              }
              helperText={formik.touched.cellPhone && formik.errors.cellPhone}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <TextField
              fullWidth
              sx={{ mt: 2, mb: 1 }}
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Box>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            gap={2}
            mt={1}
          >
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="language-label">Language</InputLabel>
              <Select
                required
                labelId="language-label"
                label="Select Language"
                id="language"
                name="language"
                value={formik.values.language}
                sx={{ width: "100%" }}
                onChange={formik.handleChange}
              >
                <MenuItem value={Languages.ENGLISH}>English</MenuItem>
                <MenuItem value={Languages.SPANISH}>Spanish</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "50%" }}
              id="jobTitle"
              name="jobTitle"
              label="Job Title"
              value={formik.values.jobTitle}
              error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
              helperText={formik.touched.jobTitle && formik.errors.jobTitle}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Box>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            gap={2}
            mt={2}
          >
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="platform-presence-label">
                Platform Presence
              </InputLabel>
              <Select
                required
                labelId="platform-presence-label"
                label="Platform Presence"
                id="platformPresence"
                name="platformPresence"
                value={formik.values.platformPresence}
                sx={{ width: "100%" }}
                onChange={formik.handleChange}
              >
                <MenuItem value={PLATFORM_PRESENCE.OFF_PLATFORM}>
                  Off Platform
                </MenuItem>
                <MenuItem value={PLATFORM_PRESENCE.ON_PLATFORM}>
                  On Platform
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="group-select-label">Group</InputLabel>
              <Select
                required
                label="Group"
                labelId="group-select-label"
                id="group"
                name="group"
                value={formik.values.group}
                sx={{ width: "100%" }}
                onChange={formik.handleChange}
              >
                {Object.values(DefaultCompanyGroups).map(group => (
                  <MenuItem key={group} value={group}>
                    {DefaultCompanyGroupReadableNames[group]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            gap={2}
          >
            <Autocomplete
              disableCloseOnSelect
              sx={{ width: "100%" }}
              id="sectors"
              options={allBrandLocationCompanies ?? []}
              value={selectedCompany ?? null}
              getOptionLabel={option => `id=${option.id}, name=${option.name}`}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  value={selectedCompany ?? []}
                  margin="normal"
                  InputLabelProps={{
                    style: { marginBottom: "8px" },
                  }}
                  label="Company"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} key={option.id} value={option}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    overflow={"auto"}
                  >
                    {
                      <Typography>{`id=${option.id}, name=${option.name}`}</Typography>
                    }
                    {selected ? (
                      <CheckCircleOutlineIcon color="primary" />
                    ) : null}
                  </Box>
                </MenuItem>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    size="small"
                    label={`id=${option.id}, name=${option.name}`}
                    {...getTagProps({ index })}
                    variant="outlined"
                  />
                ))
              }
              onInputChange={(_: unknown, newInputValue: string) => {
                if (newInputValue.length >= SEARCH_MINIMUM_CHAR_COUNT) {
                  debouncedReferredSetter(newInputValue);
                }
              }}
              onChange={(_: SyntheticEvent, newValue) =>
                setSelectedCompany(newValue)
              }
            />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={3}
          mt={2}
        >
          <Button color="inherit" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.dirty ||
              isLoadingBrandLocationCompanies ||
              !selectedCompany ||
              isCreatingUser
            }
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
}
