import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import numeral from "numeral";

import {
  CompanyTransaction,
  CompanyTransactionConcepts,
} from "@/company/types";

export interface CompanyTransactionCardProps {
  transaction: CompanyTransaction;
}

export function CompanyTransactionCard(props: CompanyTransactionCardProps) {
  const { transaction } = props;

  const isPositiveTransaction = [
    CompanyTransactionConcepts.CREDITS_REPLENISHED,
    CompanyTransactionConcepts.REFERRAL_FEE_EARNED,
    CompanyTransactionConcepts.THIRD_REFERRAL_EARNED_BONUS,
    CompanyTransactionConcepts.SIGNUP_BONUS,
  ].includes(transaction.concept);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {isPositiveTransaction ? (
          <NorthEastOutlinedIcon
            fontSize="large"
            sx={{
              bgcolor: theme => theme.palette.primary.light,
              color: theme => theme.palette.primary.main,
              borderRadius: 30,
              p: 1,
            }}
          />
        ) : (
          <SouthEastOutlinedIcon
            fontSize="large"
            color="info"
            sx={{
              bgcolor: theme => theme.palette.info.light,
              color: theme => theme.palette.info.main,
              borderRadius: 30,
              p: 1,
            }}
          />
        )}
        <Box ml={2}>
          <Typography gutterBottom variant="body1">
            {`${_.startCase(transaction.concept.toString().toLowerCase())} ${
              transaction.concept ===
                CompanyTransactionConcepts.REFERRAL_FEE_EARNED &&
              !transaction.amount
                ? "(100% paid to your team)"
                : ""
            }`}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {DateTime.fromISO(transaction.createdAt.toString(), {
              zone: "utc",
            })
              .toLocal()
              .toFormat("MM/dd/yy")}
            {transaction.job && transaction.job.jobType
              ? ` | ${transaction.job.jobType}`
              : null}
          </Typography>
        </Box>
      </Box>
      <Box textAlign="right">
        <Typography
          gutterBottom
          variant="body2"
          sx={{
            color: theme =>
              isPositiveTransaction
                ? theme.palette.primary.main
                : theme.palette.info.main,
          }}
        >
          {`${isPositiveTransaction ? "+" : "-"} ${
            transaction?.amount
              ? numeral(Math.abs(transaction.amount).toFixed(2)).format(
                  "$0,0.00"
                )
              : "$0.00"
          }`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {transaction?.newBalance
            ? numeral(transaction.newBalance.toFixed(2)).format("$0,0.00")
            : "$0.00"}
        </Typography>
      </Box>
    </Box>
  );
}
