/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Add as AddIcon } from "@mui/icons-material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { FIRST_CAMPAIGN_LOGIN_KEY, useAuth } from "@/auth/context/jwt";
import { useBackdropContext } from "@/common/backdrop-context";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import CreateTechnicianJobDialogWrapper from "@/common/CreateJobDialog/CreateTechnicianJobDialogWrapper";
import { useJobCampaignTourContext } from "@/common/job-campaign-context";
import { useJobUploadContext } from "@/common/job-upload-context";
import { Seo } from "@/common/Seo/Seo";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { isCompanyActive } from "@/common/utils/accessControl";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { useGetCompanyPublicDetails } from "@/company/api/useGetCompanyPublic";
import { useGetProfileCompletenessInfo } from "@/company/api/useGetProfileCompletenessInfo";
import { USE_GET_REFERRAL_INVITATIONS_URI } from "@/company/api/useGetReferralInvitations";
import { useCompanyPaymentsContext } from "@/company/context/payments-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import { useGetRoutedJobsByCompany } from "@/company/jobs/api/useGetRoutedJobsByCompany";
import { AccessControlJobDialog } from "@/company/jobs/components/AccessControlJobDialog";
import { JobCompletionPaymentDialog } from "@/company/jobs/components/JobCompletionPaymentDialog";
import { MarkInactiveJobDialog } from "@/company/jobs/components/MarkInactiveJobDialog";
import { JobSectors } from "@/company/jobs/constants";
import { RoutedJobResponse, RoutedJobStatus } from "@/company/jobs/types/job";
import {
  useQueryBrandLocationById,
  useQueryBrandsUnderFamilyBrand,
} from "@/company/onboarding/api";
import {
  CompanyPublicProfile,
  CompanyRoutes,
  ConnectionStatus,
  DefaultCompanyGroups,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { FEATURE_FLAGS } from "@/featureFlags";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

import { useUpdateRoutedJobStatus } from "../../../jobs/api/useUpdateRoutedJobStatus";
import { DeclineJobDialog } from "../../../jobs/components/DeclineJobDialog";
import {
  JobDrawer,
  JobDrawerTabsEnum,
} from "../../../jobs/components/JobDrawer";
import { getLastActivityRoutedJob } from "../../../jobs/utils";
import { CompanyDrawer, TabsEnum } from "../../company-drawer/CompanyDrawer";
import { CompanyPaymentDialog } from "../../company-payments-tab/CompanyPaymentDialog";
import { CompanyDashboardHomePageInProgress } from "./CompanyDashboardHomePageInProgress";
import { FactsCarousel } from "./FactsCarousel";
import { GettingStartedCheckList } from "./GettingStartedCheckList";
import { PendingInvitationsComponent } from "./PendingInvitationsComponent";
import { PendingJobs } from "./PendingJobs";

export const speedDialActions = [
  {
    icon: (
      <BackupOutlinedIcon
        sx={{
          bgcolor: "#16B364",
          color: "white",
          width: "32px",
          height: "32px",
          padding: "6px",
          borderRadius: "50%",
        }}
        color="primary"
      />
    ),
    name: "Upload Job Video",
  },
  // {
  //   icon: (
  //     <WhatsAppIcon
  //       sx={{
  //         bgcolor: "#16B364",
  //         color: "white",
  //         width: "32px",
  //         height: "32px",
  //         padding: "6px",
  //         borderRadius: "50%",
  //       }}
  //     />
  //   ),
  //   name: "Submit via WhatsApp",
  // },
];

export function CompanyDashboardHomePage() {
  const [isImageLoadError, setIsImgaeLoadError] = useState(false);
  const [showCampaignCompany, setShowCampaignCompany] = useState(false);
  const [companyProfileById, setCompanyProfileById] =
    useState<CompanyPublicProfile | null>(null);
  const { backDropOn, setBackDropOn } = useBackdropContext();
  const [jobCompletionDialogOpen, setJobCompletionDialogOpen] = useState(false);
  const [markInactiveDialogOpen, setMarkInactiveDialogOpen] = useState(false);
  const [onApproveJobByInactiveCompany, setOnApproveJobByInactiveCompany] =
    useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

  const location = useLocation();

  const removeSearchParams = () => {
    const urlWithoutSearchParams = location.pathname;
    navigate(urlWithoutSearchParams, { replace: true });
  };

  const { jobUploadOpen, setJobUploadOpen } = useJobUploadContext();
  const handleOpen = () => {
    setOpen(false);
    setJobUploadOpen(true);
    setBackDropOn(true);
  };
  const handleClose = () => {
    setJobUploadOpen(false);
    setBackDropOn(false);
  };
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const publicJobId = searchParams.get("publicJobId");
  const invitationCode = searchParams.get("invitationCode");
  const companyIdByParam = searchParams.get("companyId");

  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);
  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const handleVideoButtonClick = () => {
    const videoUrl =
      "https://tradeengage-app.s3.amazonaws.com/videos/You're+Invited+to+Join+TradeEngage.mp4";
    window.open(videoUrl, "_blank");
  };

  /**
   * Function to fetch routed jobs to the company
   */
  const flags = useFlags();
  const {
    data: routedJobs,
    isLoading: isLoadingRoutedJobs,
    refetch: refetchRoutedJobs,
  } = useGetRoutedJobsByCompany(
    {
      status: [RoutedJobStatus.PENDING_REVIEW],
    },
    undefined,
    flags[FEATURE_FLAGS.JOBS_JOB_ROUTINGS_V2_ENDPOINT]
  );

  const { session } = useAuth();
  const user = session?.user;
  const campaign = session?.campaign;

  const company = session?.company;
  const familyOfBrands = session?.familyOfBrands;

  const companySectors = session?.companySectors;
  const brandLocationProfile = session?.brandLocationProfile;

  const { data: profileCompleteness } = useGetProfileCompletenessInfo();

  const navigate = useNavigate();

  const { referralInvitations, isFetchingReferralInvitations } =
    useReferralInvitationContext();

  const { refetchCard, refetchTransactions } = useCompanyPaymentsContext();

  const { mutateAsync: updateRoutedJob, isLoading: updatingRoutedJob } =
    useUpdateRoutedJobStatus({
      onSuccess: routedJob => {
        if (
          routedJob.status === RoutedJobStatus.PAID ||
          routedJob.status === RoutedJobStatus.PENDING_PAYMENT
        ) {
          enqueueSnackbar({
            message: `${
              routedJob.status === RoutedJobStatus.PAID ? "Paid!" : "Approved!"
            }`,
            variant: "success",
          });
          refetchCard?.();
          refetchTransactions?.();
          setCurrentSelectedJob(routedJob);
        }
        refetchRoutedJobs();
        if (publicJobId) {
          removeSearchParams();
        }
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating job status.";
        if (
          error?.code === "not_enough_balance" ||
          error?.code === "not_enough_credits"
        ) {
          setPaymentDialogOpen(true);
        }
        enqueueSnackbar(
          `${
            error?.code === "not_enough_balance" ||
            error?.code === "not_enough_credits"
              ? "Insufficient funds."
              : message
          }`,
          {
            ...(error?.code === "not_enough_balance" ||
            error?.code === "not_enough_credits"
              ? {
                  subText: (
                    <>
                      Please add TradeEngage credits or update your card to
                      approve this job. Questions?{" "}
                      <a
                        role="button"
                        style={{
                          textDecorationLine: "underline",
                          color: "#7A271A",
                          cursor: "pointer",
                        }}
                        onClick={event => {
                          event.preventDefault();
                          setContactSupportModalOpen(true);
                        }}
                      >
                        Contact support
                      </a>
                      .
                    </>
                  ),
                }
              : {}),
            variant: "error",
            autoHideDuration: 10000,
          }
        );
      },
    });

  const [declineJobDialogOpen, setDeclineJobDialogOpen] = useState(false);

  const [defaultJobDrawerTab, setDefaultJobDrawerTab] =
    useState<JobDrawerTabsEnum>(JobDrawerTabsEnum.PROJECT);

  const [currentSelectedJob, setCurrentSelectedJob] =
    useState<RoutedJobResponse>();

  const { data: searchCompanyRecord } = useQueryBrandLocationById({
    id: company?.id,
  });

  const { data: { count: totalParentBrandsCount } = {} } =
    useQueryBrandsUnderFamilyBrand(
      {
        familyBrandId: searchCompanyRecord?.familyOfBrands?.id,
      },
      !!searchCompanyRecord?.familyOfBrands
    );

  const { data: companyProfile, isFetching: isFetchingProfile } =
    useGetCompanyPublicDetails(invitationCode, {
      enabled: !!invitationCode,
      refetchOnWindowFocus: false,
    });

  const { data: invitingCompanyProfile } = useFetchCompanyProfile(
    companyProfile?.id,
    {
      enabled: !!companyProfile?.id && !!showCampaignCompany,
      refetchOnWindowFocus: false,
    }
  );

  const { data: companyProfileByIdParam } = useFetchCompanyProfile(
    companyIdByParam,
    {
      enabled: !!companyIdByParam,
      refetchOnWindowFocus: false,
    }
  );

  const { data: campaignCompanyProfile } = useFetchCompanyProfile(
    campaign?.companyId,
    {
      enabled: !!campaign?.companyId && !!showCampaignCompany,
      refetchOnWindowFocus: false,
    }
  );

  const campaignProfile = campaignCompanyProfile ?? invitingCompanyProfile;

  const [open, setOpen] = useState(false);
  const { campaignTourOpen, setCampaignTourOpen, setCampaignProfile } =
    useJobCampaignTourContext();

  const routedJobsLimit = routedJobs
    ?.filter(({ expiresAt }) => {
      const currentDate = DateTime.utc();
      const expiresAtUTC = DateTime.fromISO(expiresAt.toString(), {
        zone: "utc",
      });
      const millisecondsDiff = expiresAtUTC.diff(
        currentDate,
        "milliseconds"
      ).milliseconds;
      return millisecondsDiff > 0;
    })
    ?.slice(0, 5);

  const groupNames = session?.groups?.map(group => group.name) ?? [];

  const pendingInvitations =
    groupNames?.includes(DefaultCompanyGroups.JOB_APPROVER) &&
    !groupNames?.includes(DefaultCompanyGroups.ADMINISTRATOR) &&
    !groupNames?.includes(DefaultCompanyGroups.OWNER)
      ? []
      : referralInvitations
          ?.filter(
            invitation =>
              !invitation.declinedAt &&
              invitation.invitedCompanyId === company?.id
          )
          ?.slice(0, 5);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    if (
      !groupNames?.includes(DefaultCompanyGroups.ADMINISTRATOR) &&
      !groupNames?.includes(DefaultCompanyGroups.OWNER) &&
      !groupNames?.includes(DefaultCompanyGroups.JOB_APPROVER)
    ) {
      if (groupNames?.includes(DefaultCompanyGroups.TEAM_MEMBER)) {
        return navigate(
          getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.ALL_JOBS,
          ])
        );
      }

      if (groupNames?.includes(DefaultCompanyGroups.FIELD_TEAM)) {
        return navigate(
          getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.TECHNICIAN_DEMO,
          ])
        );
      }
    }
  }, [groupNames]);

  const isFirstLogin = localStorage.getItem(FIRST_CAMPAIGN_LOGIN_KEY);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [USE_GET_REFERRAL_INVITATIONS_URI],
    });
  }, [queryClient]);

  useEffect(() => {
    if (companyProfileByIdParam) {
      setCompanyProfileById(companyProfileByIdParam);
    }
  }, [companyProfileByIdParam]);

  // TODO: will be replaced with actual count once the search algo for referrals is finalised
  const randomRecommendedReferralPartnerCount = Math.floor(
    Math.random() * (40 - 25 + 1) + 25
  );

  if (isFirstLogin) {
    localStorage.removeItem(FIRST_CAMPAIGN_LOGIN_KEY);
    setShowCampaignCompany(true);
  }

  const isProfileComplete = profileCompleteness?.percentage === 100;
  const isOnboardingIncomplete =
    !isProfileComplete && !brandLocationProfile?.startedOnboardingAt;

  const showDashboardHomePage =
    (groupNames?.includes(DefaultCompanyGroups.JOB_APPROVER) &&
      !groupNames?.includes(DefaultCompanyGroups.ADMINISTRATOR) &&
      !groupNames?.includes(DefaultCompanyGroups.OWNER)) ||
    pendingInvitations?.length ||
    routedJobsLimit?.length ||
    isOnboardingIncomplete;

  const showEmptyPendingJobsCardForJobApprover =
    groupNames?.includes(DefaultCompanyGroups.JOB_APPROVER) &&
    !groupNames?.includes(DefaultCompanyGroups.ADMINISTRATOR) &&
    !groupNames?.includes(DefaultCompanyGroups.OWNER) &&
    !isOnboardingIncomplete;

  const showEmptyPendingJobsCardForPublicJob =
    publicJobId &&
    isOnboardingIncomplete &&
    !isLoadingRoutedJobs &&
    !routedJobsLimit?.some(job => job.publicId === publicJobId);

  return (
    <>
      <Seo title="Company Home" />
      {isLoadingRoutedJobs || isFetchingReferralInvitations ? (
        <Box py={5} textAlign={"center"}>
          <CircularProgress />
        </Box>
      ) : showDashboardHomePage ? (
        <Container disableGutters maxWidth="100%" sx={{ bgcolor: "white" }}>
          <Grid container spacing={2} bgcolor={"white"}>
            {isMobile ? null : (
              <Grid
                item
                xs={12}
                sx={{
                  top: "48px",
                  zIndex: backDropOn ? 0 : 400,
                  pt: 1,
                  pb: 2,
                }}
                position={"sticky"}
                bgcolor={"white"}
              >
                <Typography variant="h5">
                  Welcome back, {user?.firstName ?? ""}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} lg={8} pt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      boxShadow: "0px 0px 0px 1px #F2F4F7;",
                      display: "flex",
                      position: "relative",
                      flexDirection: "column",
                      backgroundSize: "108% 120%",
                      backgroundPosition: "center",
                      backgroundImage: 'url("/card.png")',
                      px: 1,
                      pb: 3,
                      pt: 2,
                      gap: 3,
                    }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      width={"100%"}
                      alignItems={"start"}
                      px={1}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"start"}
                        alignItems={"center"}
                        px={1}
                        gap={1}
                        width="100%"
                      >
                        <img
                          src="/video.gif"
                          alt="dollar"
                          style={{
                            width: "48px",
                            height: "48px",
                          }}
                        />

                        <Typography
                          variant="h6"
                          sx={{
                            color: "#16DB65",
                          }}
                        >
                          Give to Get! Submit your AI-driven referral today
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"start"}
                        alignItems={"start"}
                        px={1}
                        gap={2}
                        width="100%"
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#D2D6DB",
                          }}
                        >
                          {`Record a video of a job opportunity. We’ll summarize it and route it to your referral partner. If you’ve set up a commission with your referral partner, your team will earn instantly.`}
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"start"}
                        alignItems={"start"}
                        px={1}
                        pt={1.5}
                        gap={3}
                        width="100%"
                      >
                        {" "}
                        <Typography
                          width={"100%"}
                          variant="subtitle1"
                          sx={{
                            color: "white",
                          }}
                        >
                          {isMobile ? (
                            "Tap the + sign to get started!"
                          ) : (
                            <>
                              Click{" "}
                              <Typography
                                variant="subtitle1"
                                display={"inline"}
                                sx={{
                                  cursor: "pointer",
                                  color: "#16DB65",
                                }}
                                onClick={() => {
                                  setIsJobDialogOpen(true);
                                }}
                              >
                                here{" "}
                              </Typography>
                              to get started!
                            </>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                {isLoadingRoutedJobs ? (
                  <Box py={5}>
                    <ComponentLoader />
                  </Box>
                ) : routedJobsLimit?.length ? (
                  <PendingJobs
                    setCurrentSelectedJob={setCurrentSelectedJob}
                    setDefaultJobDrawerTab={setDefaultJobDrawerTab}
                    routedJobs={routedJobsLimit}
                  />
                ) : showEmptyPendingJobsCardForJobApprover ? (
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "none !important",
                        overflow: "hidden",
                        alignItems: "center",
                        alignSelf: "stretch",
                        border: "1px #E8ECEF solid",
                        "&:hover": {
                          boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
                        },
                        px: 0,
                        py: 1,
                      }}
                    >
                      <CardHeader
                        sx={{
                          display: "flex",
                          pt: 1,
                          pb: 2,
                          px: 3,
                          alignItems: "center",
                          alignSelf: "stretch",
                        }}
                        title={
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={1}
                          >
                            <Typography gutterBottom variant={"h6"}>
                              Pending Jobs
                            </Typography>
                            <Divider />
                          </Box>
                        }
                      />
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexGrow: 1,
                          alignSelf: "stretch",
                          //background: "#F3F4F6",
                          overflow: "auto",
                          px: isMobile ? 0 : 1,
                          py: 1,
                        }}
                      >
                        <Box
                          py={5}
                          px={5}
                          width={"100%"}
                          height={"100%"}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={1}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"start"}
                            gap={1}
                          >
                            <Typography variant="body1">
                              You&apos;re all caught up!
                            </Typography>
                            <Button
                              variant="contained"
                              onClick={() => {
                                navigate(
                                  getNavigationPathFromRoutePaths([
                                    CompanyRoutes.BASE,
                                    CompanyRoutes.ALL_JOBS,
                                  ])
                                );
                              }}
                            >
                              View Job History
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Card>
                  </Grid>
                ) : showEmptyPendingJobsCardForPublicJob ? (
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "none !important",
                        overflow: "hidden",
                        alignItems: "center",
                        alignSelf: "stretch",
                        border: "1px #E8ECEF solid",
                        "&:hover": {
                          boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
                        },
                        px: 0,
                        py: 1,
                      }}
                    >
                      <CardHeader
                        sx={{
                          display: "flex",
                          pt: 1,
                          pb: 2,
                          px: 3,
                          alignItems: "center",
                          alignSelf: "stretch",
                        }}
                        title={
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={1}
                          >
                            <Typography gutterBottom variant={"h6"}>
                              Pending Jobs
                            </Typography>
                            <Divider />
                          </Box>
                        }
                      />
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          alignItems: "flex-center",
                          flexGrow: 1,
                          alignSelf: "stretch",
                          //background: "#F3F4F6",
                          overflow: "auto",
                          px: isMobile ? 0 : 1,
                          py: 1,
                        }}
                      >
                        <Box
                          py={5}
                          px={5}
                          width={"100%"}
                          height={"100%"}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={1}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              textAlign={"center"}
                            >
                              Unfortunately, this job posting has expired.
                              Companies have a limited window to respond to job
                              listings to ensure customer quality and
                              satisfaction.
                            </Typography>
                            <Button
                              variant="contained"
                              onClick={() => {
                                navigate(
                                  getNavigationPathFromRoutePaths([
                                    CompanyRoutes.BASE,
                                    CompanyRoutes.ALL_JOBS,
                                  ])
                                );
                              }}
                            >
                              View Job History
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Card>
                  </Grid>
                ) : null}
                {isFetchingReferralInvitations ? (
                  <Box py={5}>
                    <ComponentLoader />
                  </Box>
                ) : pendingInvitations?.length ? (
                  <PendingInvitationsComponent
                    pendingInvitations={pendingInvitations}
                  />
                ) : null}
                {isOnboardingIncomplete ? (
                  <Grid item xs={12}>
                    <GettingStartedCheckList
                      profileCompleteness={profileCompleteness}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} mb={1} {...(isDesktop ? { px: 2 } : {})}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      boxShadow: "0px 0px 0px 1px #F2F4F7;",
                      py: 4,
                      px: 3,
                      bgcolor: "#F8F9FA",
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                    }}
                  >
                    <>
                      <Box display="flex" gap={2} height={"100%"}>
                        <IconButton
                          aria-label="close"
                          sx={{
                            bgcolor: "white",
                            borderRadius: "20px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          <TravelExploreOutlinedIcon
                            sx={{
                              color: "black",
                              width: "22px",
                              height: "22px",
                            }}
                          />
                        </IconButton>
                        <Box display="flex" flexDirection={"column"}>
                          {familyOfBrands ? (
                            <>
                              {" "}
                              <Typography variant="subtitle1" fontWeight={400}>
                                You&apos;re affiliated with{" "}
                                <Typography
                                  variant="subtitle1"
                                  display={"inline"}
                                  fontWeight={700}
                                >
                                  {familyOfBrands.name}!
                                </Typography>
                              </Typography>
                              {familyOfBrands.logoUrl && !isImageLoadError ? (
                                <img
                                  src={familyOfBrands.logoUrl}
                                  alt="parent logo"
                                  style={{
                                    maxWidth: "200px",
                                    maxHeight: "112px",
                                    padding: "4px 0",
                                    margin: "0 16px",
                                  }}
                                  onError={() => setIsImgaeLoadError(true)}
                                />
                              ) : null}
                              {totalParentBrandsCount ? (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {familyOfBrands.name} has over{" "}
                                  {totalParentBrandsCount} brands and growing!
                                  Add them as referral partners today.
                                </Typography>
                              ) : null}
                            </>
                          ) : (
                            <>
                              <Typography variant="subtitle1" fontWeight={700}>
                                Start building referral partnerships today!
                              </Typography>
                              {randomRecommendedReferralPartnerCount &&
                              companySectors?.length ? (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  <Typography
                                    variant="subtitle1"
                                    display={"inline"}
                                    fontWeight={500}
                                  >
                                    {randomRecommendedReferralPartnerCount}{" "}
                                  </Typography>
                                  referral partners offer services complementary
                                  to{" "}
                                  <Typography
                                    variant="body2"
                                    display={"inline"}
                                    fontWeight={700}
                                    sx={{ color: "#16B364" }}
                                  >
                                    {
                                      snakeCaseJobSectors[
                                        companySectors?.[0].sector as JobSectors
                                      ]
                                    }
                                  </Typography>{" "}
                                  near you. Connect with them today!
                                </Typography>
                              ) : null}
                            </>
                          )}
                          {groupNames?.includes(
                            DefaultCompanyGroups.JOB_APPROVER
                          ) &&
                          !groupNames?.includes(
                            DefaultCompanyGroups.ADMINISTRATOR
                          ) &&
                          !groupNames?.includes(
                            DefaultCompanyGroups.OWNER
                          ) ? null : (
                            <Button
                              variant="outlined"
                              size="small"
                              sx={{
                                width: "fit-content",
                                mt: 2,
                              }}
                              endIcon={<ArrowForwardOutlinedIcon />}
                              onClick={() =>
                                navigate(
                                  getNavigationPathFromRoutePaths([
                                    CompanyRoutes.BASE,
                                    CompanyRoutes.EXPLORE_PARTNERS,
                                  ])
                                )
                              }
                            >
                              Explore Partners
                            </Button>
                          )}
                        </Box>
                      </Box>
                      <Divider sx={{ border: "0.2px #E5E7EB solid" }} />
                    </>
                    {groupNames?.includes(DefaultCompanyGroups.JOB_APPROVER) &&
                    !groupNames?.includes(DefaultCompanyGroups.ADMINISTRATOR) &&
                    !groupNames?.includes(DefaultCompanyGroups.OWNER) ? (
                      <>
                        {" "}
                        <FactsCarousel />
                        <Divider />
                      </>
                    ) : null}
                    <Box display="flex" gap={2} height={"100%"}>
                      <IconButton
                        aria-label="close"
                        sx={{
                          bgcolor: "white",
                          borderRadius: "20px",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <VideocamOutlinedIcon
                          sx={{
                            color: "black",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </IconButton>
                      <Box display="flex" flexDirection={"column"}>
                        <Typography variant="subtitle1">
                          Get to know the platform
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Watch our quick start video for tips
                        </Typography>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            width: "fit-content",
                            mt: 2,
                          }}
                          endIcon={<ArrowForwardOutlinedIcon />}
                          onClick={handleVideoButtonClick}
                        >
                          Watch Video
                        </Button>
                      </Box>
                    </Box>
                    <Divider sx={{ border: "0.2px #E5E7EB solid" }} />
                    <Box display="flex" gap={2} height={"100%"}>
                      <IconButton
                        aria-label="close"
                        sx={{
                          bgcolor: "white",
                          borderRadius: "20px",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <SupportAgentOutlinedIcon
                          sx={{
                            color: "black",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </IconButton>
                      <Box display="flex" flexDirection={"column"}>
                        <Typography variant="subtitle1">Need Help?</Typography>
                        <Typography variant="body2" color="text.secondary">
                          Message our Support Team
                        </Typography>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            width: "fit-content",
                            mt: 2,
                          }}
                          endIcon={<ArrowForwardOutlinedIcon />}
                          onClick={() => setContactSupportModalOpen(true)}
                        >
                          Contact Support
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <CompanyDashboardHomePageInProgress
          campaignCompanyProfile={campaignProfile}
        />
      )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={campaignTourOpen && !isMobile}
        onClick={() => {
          setCampaignTourOpen(false);
          setCampaignProfile(undefined);
        }}
      ></Backdrop>
      {isMobile ? null : (
        <Box
          position="fixed"
          // bottom={"12px"}
          bottom={"40px"}
          right={"48px"}
          //  right={"100px"}
          sx={{
            ...(campaignTourOpen
              ? { zIndex: theme => theme.zIndex.drawer + 1, color: "#fff" }
              : {}),
          }}
        >
          <Backdrop open={jobUploadOpen} />
          <Tooltip
            disableFocusListener
            placement="left"
            open={campaignTourOpen && !isMobile}
            componentsProps={{
              tooltip: {
                sx: {
                  color: "white",
                  backgroundColor: "#16B364",
                  bottom: "0",
                },
              },
              arrow: {
                sx: {
                  color: "#16B364",
                },
              },
            }}
            arrow={true}
            title={
              <Box
                display={"flex"}
                p={2}
                gap={1}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"start"}
                sx={{
                  borderRadius: "6px",

                  background: "#16B364",
                }}
              >
                <Typography fontSize={"15px"} fontWeight={600} color="white">
                  Refer Your First Job
                </Typography>
                <Typography variant="subtitle2" color="white">
                  Record a video of a job opportunity and upload your video
                  {campaignProfile?.brand?.name
                    ? ` for ${campaignProfile?.brand?.name}`
                    : ""}
                  .
                </Typography>
                <Button
                  size="small"
                  sx={{
                    color: "#FFF",
                    bgcolor: "#16B364",
                    border: "1px solid #FFF",
                  }}
                  onClick={() => {
                    setCampaignTourOpen(false);
                    setCampaignProfile(undefined);
                  }}
                >
                  Got it
                </Button>
              </Box>
            }
          >
            {campaignTourOpen ? (
              <Fab
                color="primary"
                aria-label="add-invite"
                onClick={() => {
                  setIsJobDialogOpen(true);
                  setCampaignTourOpen(false);
                  setCampaignProfile(undefined);
                  handleClose();
                }}
              >
                <AddIcon />
              </Fab>
            ) : (
              <ToolTip placement="bottom" message="Upload a Job">
                <Fab
                  color="primary"
                  aria-label="add-invite"
                  onClick={() => {
                    setIsJobDialogOpen(true);
                    setCampaignTourOpen(false);
                    setCampaignProfile(undefined);
                    handleClose();
                  }}
                >
                  <AddIcon />
                </Fab>
              </ToolTip>
            )}
          </Tooltip>
        </Box>
      )}
      {campaignProfile && showCampaignCompany ? (
        <CompanyDrawer
          isCampaignTour={showCampaignCompany}
          open={!!campaignProfile}
          companyProfile={campaignProfile}
          connectionStatus={ConnectionStatus.CONNECTED}
          defaultTab={TabsEnum.MESSAGE}
          onClose={() => {
            setCampaignTourOpen(true);
            setCampaignProfile(campaignProfile);
            setShowCampaignCompany(false);
          }}
        />
      ) : null}
      {companyProfileById ? (
        <CompanyDrawer
          open={!!companyProfileById}
          connectionStatus={ConnectionStatus.PENDING}
          companyProfile={companyProfileById}
          defaultTab={TabsEnum.OVERVIEW}
          onClose={() => {
            setCompanyProfileById(null);
          }}
        />
      ) : null}
      {isJobDialogOpen ? (
        <CreateTechnicianJobDialogWrapper
          isOpen={isJobDialogOpen}
          jobsListUrl={getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.MY_JOBS,
          ])}
          onClose={() => {
            setIsJobDialogOpen(false);
          }}
        />
      ) : null}
      {currentSelectedJob ? (
        <JobDrawer
          open={!!currentSelectedJob}
          jobId={(currentSelectedJob as RoutedJobResponse).jobId}
          jobStatus={currentSelectedJob?.status}
          defaultTab={defaultJobDrawerTab}
          isRoutedJob={true}
          lastActivity={getLastActivityRoutedJob(
            currentSelectedJob as RoutedJobResponse
          )}
          {...(currentSelectedJob?.status === RoutedJobStatus.PENDING_REVIEW
            ? {
                actionArea: (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      disabled={updatingRoutedJob}
                      onClick={() => {
                        if (!isCompanyActive(company?.status)) {
                          setOnApproveJobByInactiveCompany(true);
                          return;
                        }
                        updateRoutedJob({
                          jobId: currentSelectedJob.id,
                          data: {
                            status:
                              brandLocationProfile?.payReferralFeeAfterJobIsDone
                                ? RoutedJobStatus.PENDING_PAYMENT.toString()
                                : RoutedJobStatus.PAID.toString(),
                          },
                        });
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      size="small"
                      color="error"
                      variant="outlined"
                      onClick={() => {
                        if (!isCompanyActive(company?.status)) {
                          setOnApproveJobByInactiveCompany(true);
                          return;
                        }
                        setDeclineJobDialogOpen(true);
                      }}
                    >
                      Decline
                    </Button>
                  </>
                ),
              }
            : {})}
          {...(currentSelectedJob?.status === RoutedJobStatus.PENDING_PAYMENT
            ? {
                actionArea: (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setJobCompletionDialogOpen(true);
                      }}
                    >
                      Pay Referral Fee
                    </Button>
                    <Button
                      size="small"
                      sx={{
                        color: "#F04438 !important",
                        borderColor: "rgba(240, 68, 56, 0.50) !important",
                      }}
                      variant="outlined"
                      onClick={() => {
                        setMarkInactiveDialogOpen(true);
                      }}
                    >
                      Mark Inactive
                    </Button>
                  </>
                ),
              }
            : {})}
          onClose={() => {
            setCurrentSelectedJob(undefined);
          }}
        />
      ) : null}
      {declineJobDialogOpen && currentSelectedJob && (
        <DeclineJobDialog
          isOpen={declineJobDialogOpen}
          jobName={currentSelectedJob?.jobType ?? ""}
          onCancel={() => {
            setCurrentSelectedJob(undefined);
            setDeclineJobDialogOpen(false);
          }}
          onRemove={(reason: string) => {
            updateRoutedJob({
              jobId: currentSelectedJob.id,
              data: {
                status: RoutedJobStatus.DECLINED.toString(),
                declinedReason: reason,
              },
            });
            setDeclineJobDialogOpen(false);
            setCurrentSelectedJob(undefined);
          }}
        />
      )}
      {jobCompletionDialogOpen && currentSelectedJob && (
        <JobCompletionPaymentDialog
          isOpen={jobCompletionDialogOpen}
          job={currentSelectedJob as RoutedJobResponse}
          onClose={(refetch?: boolean) => {
            if (refetch) {
              refetchRoutedJobs();
              setCurrentSelectedJob(undefined);
            }
            setJobCompletionDialogOpen(false);
          }}
        />
      )}
      {markInactiveDialogOpen && currentSelectedJob && (
        <MarkInactiveJobDialog
          isOpen={markInactiveDialogOpen}
          jobId={(currentSelectedJob as RoutedJobResponse).jobId}
          onCancel={() => {
            setMarkInactiveDialogOpen(false);
          }}
          onRemove={reason => {
            updateRoutedJob({
              jobId: currentSelectedJob.id,
              data: {
                status: RoutedJobStatus.INACTIVE.toString(),
                inactiveReason: reason,
              },
            });
            setCurrentSelectedJob(undefined);
            setMarkInactiveDialogOpen(false);
          }}
        />
      )}
      {onApproveJobByInactiveCompany ? (
        <AccessControlJobDialog
          isOpen={onApproveJobByInactiveCompany}
          onClose={() => {
            setOnApproveJobByInactiveCompany(false);
          }}
        />
      ) : null}
      {paymentDialogOpen ? (
        <CompanyPaymentDialog
          open={paymentDialogOpen}
          onClose={() => setPaymentDialogOpen(false)}
        />
      ) : null}
    </>
  );
}
