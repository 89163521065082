import { Close as CloseIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBaseComponentProps,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { ElementType, SyntheticEvent, useEffect } from "react";
import * as yup from "yup";
import zipcodes from "zipcodes";

import { useAuth } from "@/auth/context/jwt";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import {
  JobAssociation,
  useAssociateCompanyToJob,
} from "@/company/jobs/api/useAssociateCompanyToJob";
import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import { ExternalCompanyProfile } from "@/company/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";
import { getUSPhoneValidationSchema } from "@/utils";

export interface UpdateJobSectorDialogProps {
  isOpen: boolean;
  job: Job;
  googleCompanyProfile?: ExternalCompanyProfile;
  onClose: () => void;
  onConfirm: () => void;
  jobAssociation: JobAssociation;
}

export function AddCompanyInfo(props: UpdateJobSectorDialogProps) {
  const { job, googleCompanyProfile, onConfirm, jobAssociation } = props;
  const { session } = useAuth();

  const validationSchema = yup.object().shape({
    name: yup.string().required("Company Name is required"),
    googlePlacesId: yup.string().optional(),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().optional(),
    email: yup.string().email("Must be a valid email address").optional(),
    sectors: googleCompanyProfile
      ? yup.array().optional()
      : yup
          .array()
          .min(1, "Please select at least one Service Category")
          .required("Sectors are required")
          .of(yup.string()),
    zipCode: googleCompanyProfile
      ? yup.string().optional()
      : yup
          .string()
          .required("Zip Code is required")
          .oneOf(Object.keys(zipcodes.codes), "Invalid U.S. zip code")
          .min(5, "Must be exactly 5 digits")
          .max(5, "Must be exactly 5 digits"),
    cellPhone: getUSPhoneValidationSchema().required(
      "Mobile Number is required"
    ),
  });

  const { isLoading: associatingCompany, mutateAsync: associateCompany } =
    useAssociateCompanyToJob({
      onSuccess: () => {
        onConfirm();
        enqueueSnackbar({
          message: "Partner confirmed successfully.",
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while associating the partner to job.`,
          variant: "error",
        });
      },
    });

  const formik = useFormik({
    initialValues: {
      googlePlacesId: "",
      name: "",
      cellPhone: "",
      firstName: "",
      lastName: "",
      email: "",
      zipCode: "",
      sectors: [] as Array<JobSectors>,
    },
    validationSchema: validationSchema,
    onSubmit: async ({
      cellPhone,
      name,
      email,
      lastName,
      firstName,
      googlePlacesId,
      zipCode,
      sectors,
    }) => {
      associateCompany({
        jobId: job.id,
        googlePlacesId,
        jobAssociation,
        company: {
          name,
          zipCode,
          sectors: sectors.map(sector => reverseSnakeCaseJobSectors[sector]),
        },
        user: {
          firstName,
          lastName,
          email,
          cellPhone: `+1${cellPhone.replace(/[\s()-]/g, "")}`,
        },
      });
    },
  });

  useEffect(() => {
    if (googleCompanyProfile) {
      if (googleCompanyProfile?.googlePlacesId) {
        formik.setFieldValue(
          "googlePlacesId",
          googleCompanyProfile.googlePlacesId
        );
      }
      if (googleCompanyProfile?.name) {
        formik.setFieldValue("name", googleCompanyProfile?.name);
      }
      if (googleCompanyProfile?.sectors?.length)
        formik.setFieldValue(
          "sectors",
          googleCompanyProfile?.sectors.map(
            sector => snakeCaseJobSectors[sector]
          )
        );
    }
  }, [googleCompanyProfile]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      fullWidth
      sx={{ m: { xs: -3, md: 0 } }}
      open={props.isOpen}
      maxWidth="md"
      onClose={() => props.onClose()}
    >
      <DialogTitle component="div" width="95%" sx={{ px: 2 }}>
        <Typography gutterBottom variant="h5">
          Add Company Info
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => props.onClose()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: { xs: 0 }, pt: 0 }}>
        <Box width="100%" pt={0}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems={"center"}
            width={"100%"}
            flexDirection={"column"}
            gap={2}
            px={2}
          >
            <Box width="100%" gap={1} display="flex" flexDirection={"column"}>
              <Box width="100%" gap={1} display="flex" flexDirection={"column"}>
                <Typography gutterBottom variant="subtitle1">
                  Enter Company Details
                </Typography>
                <Box
                  display={"flex"}
                  width="100%"
                  gap={2}
                  flexDirection={"column"}
                >
                  <Box display={"flex"} width="100%" gap={2}>
                    <TextField
                      required
                      id="name"
                      name="name"
                      disabled={!!googleCompanyProfile}
                      label={"Company Name"}
                      sx={{
                        width: "100%",
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      value={formik.values.name ?? ""}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Box>
                {googleCompanyProfile ? (
                  <Box
                    display={"flex"}
                    width="100%"
                    gap={2}
                    flexDirection={"column"}
                  >
                    <Box display={"flex"} width="100%" gap={2}>
                      <TextField
                        id="googlePlacesId"
                        name="googlePlacesId"
                        disabled={!!googleCompanyProfile}
                        label={"Google Places Id"}
                        sx={{
                          width: "100%",
                        }}
                        error={
                          formik.touched.googlePlacesId &&
                          Boolean(formik.errors.googlePlacesId)
                        }
                        helperText={
                          formik.touched.googlePlacesId &&
                          formik.errors.googlePlacesId
                        }
                        value={formik.values.googlePlacesId ?? ""}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </Box>
                  </Box>
                ) : null}
                {!googleCompanyProfile ? (
                  <Box
                    display={"flex"}
                    width="100%"
                    gap={2}
                    flexDirection={"column"}
                  >
                    <Box
                      display={"flex"}
                      width="100%"
                      gap={2}
                      alignItems={"center"}
                    >
                      <TextField
                        required
                        id="zipCode"
                        name="zipCode"
                        label="Zip Code"
                        sx={{
                          width: "100%",
                        }}
                        value={formik.values.zipCode}
                        error={
                          formik.touched.zipCode &&
                          Boolean(formik.errors.zipCode)
                        }
                        helperText={
                          formik.touched.zipCode && formik.errors.zipCode
                        }
                        type="number"
                        inputMode="numeric"
                        inputProps={{ pattern: "[0-9]*" }}
                        onChange={event => {
                          if (event.target.value?.length <= 5) {
                            formik.setFieldValue(
                              "zipCode",
                              event.target.value,
                              true
                            );
                          }
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Box>
                  </Box>
                ) : null}
                {!googleCompanyProfile ? (
                  <Box
                    display={"flex"}
                    width="100%"
                    gap={2}
                    flexDirection={"column"}
                  >
                    <Box
                      display={"flex"}
                      width="100%"
                      gap={2}
                      alignItems={"center"}
                    >
                      <Box width={"100%"}>
                        <Autocomplete
                          multiple
                          sx={{ m: 0 }}
                          options={Object.keys(JobSectors).map(sector => {
                            if (sector === JobSectors.HVAC) {
                              return sector.toString();
                            }
                            return snakeCaseJobSectors[
                              sector.toString() as JobSectors
                            ];
                          })}
                          value={formik.values.sectors}
                          getOptionLabel={option => option}
                          renderInput={params => (
                            <TextField
                              {...params}
                              required
                              sx={{ py: 0 }}
                              error={
                                formik.touched.sectors &&
                                Boolean(formik.errors.sectors)
                              }
                              helperText={
                                formik.touched.sectors && formik.errors.sectors
                              }
                              value={formik.values.sectors ?? []}
                              InputLabelProps={{
                                style: { marginBottom: "8px" },
                              }}
                              label="Select Service Categories"
                            />
                          )}
                          renderOption={(props, option, { selected }) => (
                            <MenuItem {...props} key={option} value={option}>
                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                {<Typography>{option}</Typography>}
                                {selected ? (
                                  <CheckCircleOutlineIcon color="primary" />
                                ) : null}
                              </Box>
                            </MenuItem>
                          )}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              // eslint-disable-next-line react/jsx-key
                              <Chip
                                size="small"
                                label={option}
                                {...getTagProps({ index })}
                                variant="outlined"
                              />
                            ))
                          }
                          onChange={(
                            _: SyntheticEvent,
                            newValue: string[] | null
                          ) =>
                            formik.setFieldValue(
                              "sectors",
                              (newValue ?? []) as JobSectors[]
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : null}
                <Box
                  display={"flex"}
                  width="100%"
                  gap={2}
                  mt={1}
                  flexDirection={"column"}
                >
                  <Typography variant="subtitle1">
                    {" "}
                    User Contact Info
                  </Typography>
                  <Box display={"flex"} width="100%" gap={2}>
                    <TextField
                      required
                      id="firstName"
                      name="firstName"
                      label={"First Name"}
                      sx={{
                        width: "50%",
                      }}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      value={formik.values.firstName ?? ""}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="lastName"
                      name="lastName"
                      label={"Last Name"}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      value={formik.values.lastName ?? ""}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  width="100%"
                  gap={2}
                  flexDirection={"column"}
                >
                  <Box display={"flex"} width="100%" gap={2}>
                    <TextField
                      required
                      sx={{
                        width: "50%",
                      }}
                      id="cellPhone"
                      name="cellPhone"
                      label={"Mobile Number"}
                      error={
                        formik.touched.cellPhone &&
                        Boolean(formik.errors.cellPhone)
                      }
                      helperText={
                        formik.touched.cellPhone && formik.errors.cellPhone
                      }
                      value={formik.values.cellPhone ?? ""}
                      InputProps={{
                        inputComponent: MaskedPhoneInput as unknown as
                          | ElementType<InputBaseComponentProps>
                          | undefined,
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="email"
                      name="email"
                      label={"Email Address"}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      value={formik.values.email ?? ""}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Box>
              </Box>
              <Button
                sx={{
                  mr: 1,
                  mt: 2,
                  width: "fit-content",
                }}
                color="primary"
                variant="contained"
                disabled={
                  (!formik.dirty && !Object.keys(formik.touched).length) ||
                  associatingCompany
                }
                onClick={() => formik.handleSubmit()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
