import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Slider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { useGetAllBrands } from "@/company/api/useGetAllBrands";

import { useGetMyBrands } from "../api/useGetMyBrands";
import { useBrandOverlapSearchFilterContext } from "../contexts/SearchFilterContext";
import BrandInterestsCheckboxList from "./BrandInterestsCheckboxList";
import BrandSearchCheckboxList from "./BrandSearchCheckboxList";

const overlappingDistanceMarks = [
  {
    value: 0,
    label: "MIN",
  },
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 75,
  },
  {
    value: 100,
    label: "MAX",
  },
];

export default function BrandSearchList() {
  const [brandsChecked, setBrandsChecked] = useState(false);
  const [otherBrandsChecked, setOtherBrandsChecked] = useState(false);

  const [brandsOpen, setBrandsOpen] = useState(false);
  const { addFilter, filters } = useBrandOverlapSearchFilterContext();
  const { data: myBrands } = useGetMyBrands();
  const { data: allBrands } = useGetAllBrands();

  useEffect(() => {
    if (brandsChecked) addFilter({ brands: myBrands ?? [] });
  }, [brandsChecked, myBrands]);

  useEffect(() => {
    if (otherBrandsChecked) addFilter({ otherBrands: [] });
  }, [otherBrandsChecked, allBrands]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
      p={2}
      sx={{
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
        background: "#FFF",
      }}
    >
      <Box display={"flex"} gap={2} alignItems={"center"} pb={1}>
        <HomeOutlinedIcon />
        <Typography fontSize={"14px"} fontWeight={600}>
          Brand Search
        </Typography>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box display={"flex"} width={"100%"} gap={2} mt={1}>
        <Box display={"flex"} width={"50%"} flexDirection={"column"}>
          <Box>
            <Typography variant="overline" color="text.secondary">
              My Brands
            </Typography>
          </Box>
          <Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              {brandsOpen ? (
                <ExpandLess onClick={() => setBrandsOpen(false)} />
              ) : (
                <ExpandMore onClick={() => setBrandsOpen(true)} />
              )}
              <FormControlLabel
                label={
                  <Typography variant="subtitle2" color="text.secondary">
                    All ({myBrands?.length ?? 0})
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    sx={{ width: "20px", height: "20px", p: 0, m: 1 }}
                    checked={brandsChecked}
                    onChange={() =>
                      setBrandsChecked(checked => {
                        return !checked;
                      })
                    }
                  />
                }
              />
            </Box>
            <Collapse unmountOnExit in={brandsOpen} timeout="auto">
              <Box pl={6}>
                <BrandSearchCheckboxList
                  isAllChecked={brandsChecked}
                  allbrands={myBrands ?? []}
                />
              </Box>
            </Collapse>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            width={"100%"}
            textAlign="start"
            pb={5}
            mt={4}
          >
            <Typography variant="overline" color="text.secondary">
              Overlapping Distance(in miles)
            </Typography>

            <Slider
              size="small"
              valueLabelDisplay="auto"
              getAriaValueText={value => `${value}`}
              max={100}
              min={1}
              marks={overlappingDistanceMarks}
              step={1}
              sx={{ p: 0, m: 0, width: "75%", pb: 5, ml: 1 }}
              color="primary"
              value={
                filters.overlappingDistance ? filters.overlappingDistance : 30
              }
              onChange={(_, newValue) => {
                addFilter({ overlappingDistance: newValue });
              }}
            />
          </Box>
        </Box>
        <Box display={"flex"} width={"50%"} flexDirection={"column"}>
          <Box>
            <Typography variant="overline" color="text.secondary">
              Brands of Interest
            </Typography>
          </Box>
          <Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              {brandsOpen ? (
                <ExpandLess onClick={() => setBrandsOpen(false)} />
              ) : (
                <ExpandMore onClick={() => setBrandsOpen(true)} />
              )}
              <FormControlLabel
                label={
                  <Typography variant="subtitle2" color="text.secondary">
                    All
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    sx={{ width: "20px", height: "20px", p: 0, m: 1 }}
                    checked={otherBrandsChecked}
                    onChange={() =>
                      setOtherBrandsChecked(checked => {
                        return !checked;
                      })
                    }
                  />
                }
              />
            </Box>
            <Collapse unmountOnExit in={brandsOpen} timeout="auto">
              <Box pl={6}>
                <BrandInterestsCheckboxList
                  isAllChecked={otherBrandsChecked}
                  allBrands={allBrands ?? []}
                />
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
