import { Languages } from "@/common/constants/languages";
import { JobSectors } from "@/company/jobs/constants";
import {
  BrandLocationProfile,
  Company,
  COMPANY_ACCOUNT_TYPES,
  CompanyAddress,
  CompanyGroup,
  CompanySector,
  PLATFORM_PRESENCE,
} from "@/company/types";

export enum USER_TYPE {
  COMPANY_TEAM_MEMBER = "COMPANY_TEAM_MEMBER",
  HOMEOWNER = "HOMEOWNER",
}

export interface User {
  name?: string;
  firstName: string;
  lastName: string;
  email?: string;
  cellPhone: string;
  jobTitle?: string;
  stripeAccountId?: string;
  type: USER_TYPE;
  id: number;
  lastLoginAt?: string;
  lastInvitedAt?: string;
  preferredLanguage?: Languages;
  acceptedWhatsappMessagingTerms?: boolean;
  acceptedTermsAndConditions?: boolean;
  platformPresence?: PLATFORM_PRESENCE;
  balance: number;
}

export interface SignInRequest {
  cellPhone?: string;
  email?: string;
  otp: string;
}

export interface SignUpRequest {
  user: Required<Omit<User, "id" | "stripeAccountId" | "balance">>;
  brandLocationId?: number;
  invitationCode?: string;
  campaignCode?: string;
  redirectTo?: string;
  jobIdToLink?: number;
  initialJobRoutedId?: number;
  jobInvitationId?: number;
  utmParams?: {
    campaign?: string;
    source?: string;
    medium?: string;
  };
  publicJobId?: string;
  company?: Partial<Company>;
}

export interface BaseJWTToken {
  token: string;
  expires_at: Date;
}

export interface TokenDetails {
  access: BaseJWTToken;
  refresh: BaseJWTToken;
}

export type SignInResponse = TokenDetails;

export interface AuthenticateUserRequest {
  accessToken: string;
}

export interface Campaign {
  id: string;
  companyId: string;
  code: string;
  sector?: JobSectors;
  videoLink?: string;
  companyCampaignSectors?: Array<JobSectors>;
}

export interface Session {
  user?: User;
  company?: Company;
  groups?: CompanyGroup[];
  companyAddress?: CompanyAddress;
  companySectors?: CompanySector[];
  permissions?: string[];
  brandLocationProfile?: BrandLocationProfile;
  brand?: Company;
  familyOfBrands?: Company;
  campaign?: Campaign;
  privateEquity?: Company;
  consultants?: Array<Company>;
}

export interface RequestLoginOtpRequest {
  cell_phone?: string;
  email?: string;
}

export interface UpdateUserTypeRequest {
  userType: USER_TYPE;
  companyAccountType?: COMPANY_ACCOUNT_TYPES;
}
