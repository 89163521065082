import DownloadIcon from "@mui/icons-material/Download";
import { Box, Card, Chip, Drawer, Tooltip, Typography } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridFilterModel,
  GridRowModesModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import axios from "axios";
import { capitalCase } from "change-case";
import numeral from "numeral";
import { useEffect, useState } from "react";

import {
  formatDateTime,
  formatDateV2,
  formatToDate,
  formatToDateTime,
} from "@/analytics/components/JobsTable";
import { useAuth } from "@/auth/context/jwt";
import API from "@/common/api";
import { Languages } from "@/common/constants/languages";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { JobDrawerComponentById } from "@/company/jobs/components/JobDrawerById";
import { JobSectors } from "@/company/jobs/constants";
import { JobStatus } from "@/company/jobs/types";
import { RoutedJobStatus, Source } from "@/company/jobs/types/job";
import {
  ProjectConsultantJobStatusChipProps,
  ProjectJobStatusChipProps,
} from "@/company/jobs/utils";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";
import {
  CustomToolbar,
  formatDate,
  getGridStringOperatorsDataGrid,
} from "@/internal-admin/utils";
import {
  getGridDateOperatorsDataGrid,
  getGridNumericOperatorsDataGrid,
} from "@/utils";

export default function HubJobsTable({ jobs }: { jobs: Array<any> }) {
  const [rows, setRows] = useState([]);
  const { session } = useAuth();

  const [serverSorting, setServerSorting] = useState<
    undefined | GridSortModel
  >();
  const apiRef = useGridApiRef();

  const handleDownload = async (jobId: number, type: JobUploadTypes) => {
    try {
      const response = await API.get(`/jobs/${jobId}/resource-url`); // Replace with your API endpoint
      const videoUrl = response.data.data; // Adjust according to the API response

      // Create an anchor element and trigger the download

      const media = await axios.get(videoUrl, {
        responseType: "blob",
      });

      // Create a link element to trigger the download
      const urlObject = window.URL.createObjectURL(new Blob([media.data]));
      const link = document.createElement("a");
      link.href = urlObject;

      if (type === JobUploadTypes.VIDEO) {
        link.setAttribute("download", `video-job-id-${jobId}.mp4`);
      } else {
        link.setAttribute("download", `audio-job-id-${jobId}.mp3`);
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Cleanup
      link.remove();
    } catch (error) {
      console.error("Error downloading the video:", error);
    }
  };

  const [serverFiltering, setServerFiltering] = useState<
    undefined | GridFilterModel
  >();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const [selectedJobId, setSelectedJobId] = useState<number | undefined>();
  const [jobStatus, setJobStatus] = useState<string | undefined>();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (params.field === "jobType") {
      setSelectedJobId(params.row.id);
      let status = params?.row?.status;
      if (params?.row?.status === JobStatus.NO_COMPANY_TO_ROUTE) {
        if (params?.row?.latestJobRoutingStatus === RoutedJobStatus.DECLINED) {
          status = RoutedJobStatus.DECLINED;
        } else if (
          params?.row?.latestJobRoutingStatus === RoutedJobStatus.EXPIRED
        ) {
          status = "IGNORED";
        } else {
          status = "NO_REFERRAL_PARTNER_MATCHED";
        }
      }
      setJobStatus(status);
    }
  };
  useEffect(() => {
    setRows(jobs);
  }, [jobs]);

  let columns: GridColDef[] = [
    {
      field: "jobType",
      headerName: "Job Type",
      filterOperators: getGridStringOperatorsDataGrid,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            sx={{ cursor: "pointer" }}
            gap={1}
          >
            <Typography variant="body2">{params.row.jobType}</Typography>
          </Box>
        );
      },
      width: 200,
    },
    {
      field: "sector",
      headerName: "Sector",
      width: 200,
      type: "singleSelect",
      valueGetter: (_value, row) => {
        if (row?.sector) return snakeCaseJobSectors[row?.sector as JobSectors];
        return row?.sector;
      },
      valueOptions: [...Object.keys(reverseSnakeCaseJobSectors)],
      valueSetter: params => {
        return { ...params.row, sector: params.value };
      },
      renderCell: params => {
        return (
          <Chip
            key={params.row?.sector}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={snakeCaseJobSectors[params.row?.sector as JobSectors]}
            size="small"
          />
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (_value, row) => formatToDateTime(row?.createdAt),
      renderCell: params => formatDate(params.row?.createdAt),
    },

    {
      field: "summary",
      headerName: "Summary",
      filterOperators: getGridStringOperatorsDataGrid,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            sx={{ cursor: "pointer" }}
            gap={1}
          >
            <Typography variant="body2">{params.row.summary}</Typography>
          </Box>
        );
      },
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      valueSetter: params => {
        return { ...params.row, status: params.value };
      },
      valueGetter: (_value, row) => {
        let status = row?.status;
        if (row?.status === JobStatus.NO_COMPANY_TO_ROUTE) {
          if (row?.latestJobRoutingStatus === RoutedJobStatus.DECLINED) {
            status = RoutedJobStatus.DECLINED;
          } else if (row?.latestJobRoutingStatus === RoutedJobStatus.EXPIRED) {
            status = "IGNORED";
          } else {
            status = "NO REFERRAL PARTNER MATCHED";
          }
        }
        if (status) return capitalCase(status);
        return row?.status;
      },
      valueOptions: [
        JobStatus.HOMEOWNER_ACCEPTED,
        JobStatus.HOMEOWNER_DECLINED,
        JobStatus.HOMEOWNER_DELAYED,
        JobStatus.PENDING_HOMEOWNER_ACTION,
        JobStatus.PAID,
        JobStatus.PENDING_REFERRAL_PAYMENT,
        JobStatus.ROUTED,
        JobStatus.CLOSED,
        RoutedJobStatus.DECLINED,
        "NO REFERRAL PARTNER MATCHED",
        "IGNORED",
      ].map(status => capitalCase(status)),
      renderCell: params => {
        if (params.row.status === JobStatus.NO_COMPANY_TO_ROUTE) {
          if (params.row.latestJobRoutingStatus === RoutedJobStatus.DECLINED) {
            return (
              <Chip
                label={
                  ProjectJobStatusChipProps[
                    params.row.latestJobRoutingStatus as RoutedJobStatus
                  ].label
                }
                sx={{
                  bgcolor:
                    ProjectJobStatusChipProps[
                      params.row.latestJobRoutingStatus as RoutedJobStatus
                    ].bgcolor,
                  color:
                    ProjectJobStatusChipProps[
                      params.row.latestJobRoutingStatus as RoutedJobStatus
                    ].color,
                  fontSize: "12px",
                  fontWeight: 600,
                  height: "24px",
                }}
              />
            );
          }
          if (params.row.latestJobRoutingStatus === RoutedJobStatus.EXPIRED) {
            return (
              <Chip
                label={"IGNORED"}
                sx={{
                  bgcolor:
                    ProjectJobStatusChipProps[
                      params.row.latestJobRoutingStatus as RoutedJobStatus
                    ].bgcolor,
                  color:
                    ProjectJobStatusChipProps[
                      params.row.latestJobRoutingStatus as RoutedJobStatus
                    ].color,
                  fontSize: "12px",
                  fontWeight: 600,
                  height: "24px",
                }}
              />
            );
          }
          return (
            <Chip
              label="NO REFERRAL PARTNER MATCHED"
              sx={{
                bgcolor: "rgba(240, 68, 56, 0.12)",
                color: "#B42318",
                fontSize: "12px",
                fontWeight: 600,
                height: "24px",
              }}
            />
          );
        }
        return (
          <Chip
            label={
              ProjectConsultantJobStatusChipProps[
                params.row.status as JobStatus
              ].label
            }
            sx={{
              bgcolor:
                ProjectConsultantJobStatusChipProps[
                  params.row.status as JobStatus
                ].bgcolor,
              color:
                ProjectConsultantJobStatusChipProps[
                  params.row.status as JobStatus
                ].color,
              fontSize: "12px",
              fontWeight: 600,
              height: "24px",
            }}
          />
        );
      },
      width: 180,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      type: "singleSelect",
      valueSetter: (value, row) => {
        return {
          ...row,
          type: value?.replaceAll(" ", "_"),
        };
      },
      valueGetter: (_value, row) => row?.type,
      valueOptions: [...Object.keys(JobUploadTypes)],
    },
    {
      field: "createdByCompanyLogoUrl",
      headerName: "Created By Company Logo Url",
      filterable: false,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <img
              src={params.row.createdByCompanyLogoUrl}
              style={{
                width: "48px",
                height: "32px",
                objectFit: "contain",
              }}
              alt="company-logo"
            />
          </Box>
        );
      },
      sortable: false,
    },
    {
      field: "createdByCompanyName",
      headerName: "Created By Company Name",
      filterOperators: getGridStringOperatorsDataGrid,
      width: 200,
    },
    {
      field: "createdByCompanyBrandName",
      headerName: "Created By Company Brand Name",
      width: 150,
      type: "string",
      filterOperators: getGridStringOperatorsDataGrid,
    },
    {
      field: "createdByCompanyFamilyOfBrandsName",
      headerName: "Created By Company Family Of Brands Name",
      width: 150,
      type: "string",
      filterOperators: getGridStringOperatorsDataGrid,
    },
    {
      field: "createdByCompanyPrivateEquityName",
      headerName: "Created By Company Private Equity Name",
      width: 150,
      type: "string",
      filterOperators: getGridStringOperatorsDataGrid,
    },
    {
      field: "createdByUserName",
      headerName: "Technician Name",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 150,
    },
    {
      field: "createdByUserEmail",
      headerName: "Technician Email",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "createdByUserCellPhone",
      headerName: "Technician Cell Phone",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "jobBuyingCost",
      headerName: "Referral Fee",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      valueFormatter: value => value && `${numeral(value).format("$0,0.00")}`,
      renderCell: params =>
        params.row?.jobBuyingCost
          ? `${numeral(params.row?.jobBuyingCost).format("$0,0.00")}`
          : "",
    },
    {
      field: "companyFee",
      headerName: "Company Fee",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      valueFormatter: value => value && `${numeral(value).format("$0,0.00")}`,
      renderCell: params =>
        params.row?.jobBuyingCost && params.row?.technicianFee
          ? `${numeral(
              params.row?.jobBuyingCost - params.row?.technicianFee
            ).format("$0,0.00")}`
          : "",
    },
    {
      field: "technicianFee",
      headerName: "Fee Paid to the Technician or CSR that referred this Job",
      type: "number",
      valueFormatter: value => value && `${numeral(value).format("$0,0.00")}`,
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 280,
      renderCell: params =>
        params.row?.technicianFee
          ? `${numeral(params.row?.technicianFee).format("$0,0.00")}`
          : "",
    },
    {
      field: "salePrice",
      headerName: "Sale Price",
      description: "This is the the sale price of the job",
      type: "number",
      valueFormatter: value => value && `${numeral(value).format("$0,0.00")}`,
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      renderCell: params =>
        params.row?.salePrice
          ? `${numeral(params.row?.salePrice).format("$0,0.00")}`
          : "",
    },

    {
      field: "percentageFee",
      headerName: "Percentage Fee",
      description:
        "This is the percentage of the sale price that will be paid as the referral fee",
      width: 150,
      type: "number",
      valueFormatter: value => value && `${value?.toString()}%`,
      filterOperators: getGridNumericOperatorsDataGrid,
      renderCell: params =>
        params.row?.percentageFee ? `${params.row?.percentageFee}%` : "",
    },
    {
      field: "routeToHomeowner",
      headerName: "Route to Homeowner",
      width: 150,
      type: "boolean",
    },
    {
      field: "buyingCompanyName",
      headerName: "Buying Company",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
      valueGetter: (_value, row) => row.buyingCompanyName,
      renderCell: params => params.row?.buyingCompanyName,
    },
    {
      field: "buyingCompanyBrandName",
      headerName: "Buying Company Brand Name",
      width: 150,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "buyingCompanyFamilyOfBrandsName",
      headerName: "Buying Company Family Of Brands Name",
      width: 150,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "buyingCompanyPrivateEquityName",
      headerName: "Buying Company Private Equity Name",
      width: 150,
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
    },
    {
      field: "videoTranscription",
      headerName: "Video Transcription",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    ...(session?.company?.accountType === COMPANY_ACCOUNT_TYPES.SUPERADMIN
      ? [
          {
            field: "videoLanguage",
            headerName: "Video Language",
            width: 150,
            type: "singleSelect",
            valueOptions: [...Object.keys(Languages)],
          },
          {
            field: "opportunityTime",
            headerName: "Opportunity Time",
            width: 150,
            type: "date",
            filterOperators: getGridDateOperatorsDataGrid,
            valueGetter: (_value, row) =>
              formatToDateTime(row?.opportunityTime),
            renderCell: params => formatDateTime(params.row?.opportunityTime),
          },
          {
            field: "homeownerViewedAt",
            headerName: "Homeowner Viewed At",
            width: 150,
            type: "date",
            filterOperators: getGridDateOperatorsDataGrid,
            valueGetter: (_value, row) =>
              formatToDateTime(row?.homeownerViewedAt),
            renderCell: params => formatDateTime(params.row?.homeownerViewedAt),
          },
          {
            field: "submissionTime",
            headerName: "Job Submission Time",
            width: 200,
            type: "date",
            filterOperators: getGridDateOperatorsDataGrid,
            valueGetter: (_value, row) => formatToDateTime(row?.submissionTime),
            renderCell: params => formatDateTime(params.row?.submissionTime),
          },
        ]
      : []),
    {
      field: "completionDate",
      headerName: "Completion Date",
      width: 150,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (_value, row) => formatToDate(row?.completionDate),
      renderCell: params => formatDateV2(params.row?.completionDate),
    },
    {
      field: "homeStreetAddress",
      headerName: "Home Street Address",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "homeLatitude",
      headerName: "Home Latitude",
      width: 150,
      type: "number",
    },
    {
      field: "homeLongitude",
      headerName: "Home Longitude",
      width: 150,
      type: "number",
    },
    {
      field: "homeZipCode",
      headerName: "Home Zip Code",
      width: 150,
      type: "number",
    },
    { field: "homeownerName", headerName: "Homeowner Name", width: 150 },
    {
      field: "homeownerCellPhone",
      headerName: "Homeowner Cell Phone",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "createdForCompanyName",
      headerName: "Created For Company Name",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "paidAt",
      headerName: "Paid At",
      width: 150,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (_value, row) => formatToDateTime(row?.paidAt),
      renderCell: params => formatDate(params.row?.paidAt),
    },
    {
      field: "userWhoApprovedTheJobName",
      headerName: "User Who Approved the Job Name",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
    },
    {
      field: "currentRoutedCompanyName",
      headerName: "Company Pending Job Review",
      filterOperators: getGridStringOperatorsDataGrid,
      type: "string",
      width: 200,
      valueGetter: (_value, row) => row.currentRoutedCompanyName,
      renderCell: params => params.row?.currentRoutedCompanyName,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 120,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <Tooltip
            key={`${id}-download-tooltip`}
            title="Click to download job media"
          >
            <GridActionsCellItem
              key={`${id}-download`}
              disabled={!row.type || row.type === JobUploadTypes.TEXT}
              label="Download"
              color="inherit"
              icon={<DownloadIcon />}
              onClick={() => {
                handleDownload(row.id, row.type);
              }}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  const [columnWidths, setColumnWidths] = useState(() => {
    const savedColumnWidths = localStorage.getItem(
      "hub-job-datagridColumnWidths"
    );
    return savedColumnWidths
      ? JSON.parse(savedColumnWidths)
      : columns.reduce((acc, col) => {
          acc[col.field] = col.width;
          return acc;
        }, {});
  });

  const handleColumnResize = (newColumn: any) => {
    setColumnWidths({
      ...columnWidths,
      [newColumn.colDef.field]: newColumn.width,
    });
  };

  columns = columns.map(column => ({
    ...column,
    width: columnWidths?.[column.field] ?? column.width,
  }));

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  useEffect(() => {
    localStorage.setItem(
      "hub-job-datagridColumnWidths",
      JSON.stringify(columnWidths)
    );
  }, [columnWidths]);

  const handleSortModelChange = (model: GridSortModel) => {
    setServerSorting(model);
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    setServerFiltering(model);
  };

  return (
    <Card
      sx={{
        position: "relative",
        boxShadow: "0px 0px 0px 1px #F2F4F7",
        px: 3,
        py: 2,
      }}
    >
      <Box display={"flex"} sx={{ height: "100%" }} flexDirection={"column"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
          p={1}
          mb={2}
          gap={0.5}
        >
          <Typography variant="h6">Job History</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Jobs listed in this table include only those referred by
            participants in this group.
          </Typography>
        </Box>
        <Box
          sx={{
            height: "568px",
            px: 0,
          }}
          overflow={"auto"}
          position={"relative"}
        >
          <DataGridPremium
            checkboxSelection
            disableRowSelectionOnClick
            pagination
            disableAggregation
            disableRowGrouping
            cellSelection
            apiRef={apiRef}
            columns={columns}
            slots={{
              toolbar: () => (
                <CustomToolbar fileName="Jobs" includeQuickFilter={true} />
              ),
            }}
            sx={{
              "& .MuiDataGrid-row": {
                "--rowBorderColor": "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withRightBorder": {
                borderRightColor: "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withLeftBorder": {
                borderLeftColor: "#D9D9D9",
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            rows={rows ?? []}
            editMode="row"
            rowModesModel={rowModesModel}
            initialState={{
              density: "compact",
              pinnedColumns: {
                left: [
                  GRID_CHECKBOX_SELECTION_COL_DEF.field,
                  "logoUrl",
                  "name",
                ],
                right: ["actions"],
              },
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            slotProps={{
              toolbar: { setRowModesModel },
            }}
            sortModel={serverSorting}
            paginationModel={paginationModel}
            filterModel={serverFiltering}
            // paginationMode="server"
            pageSizeOptions={[5, 10, 25, 50, 100, 250, 500]}
            disableMultipleColumnsSorting={false}
            onColumnVisibilityModelChange={newModel =>
              setColumnVisibilityModel(newModel)
            }
            onRowModesModelChange={handleRowModesModelChange}
            onColumnWidthChange={handleColumnResize}
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={handleSortModelChange}
            onCellClick={handleCellClick}
            onPaginationModelChange={setPaginationModel}
          />
        </Box>
        {selectedJobId ? (
          <Drawer
            open={!!selectedJobId}
            anchor="right"
            PaperProps={{
              sx: {
                width: "100%",
                maxWidth: 500,
                px: 1.5,
                pt: 1.5,
              },
            }}
            onClose={() => {
              setSelectedJobId(undefined);
              setJobStatus(undefined);
            }}
          >
            {selectedJobId ? (
              <JobDrawerComponentById
                jobId={selectedJobId}
                source={
                  session?.company?.accountType !==
                  COMPANY_ACCOUNT_TYPES.SUPERADMIN
                    ? Source.CONSULTANT
                    : undefined
                }
                jobStatus={jobStatus as JobStatus}
                onClose={() => {
                  setSelectedJobId(undefined);
                  setJobStatus(undefined);
                }}
              />
            ) : null}
          </Drawer>
        ) : null}
      </Box>
    </Card>
  );
}
