import { Divider, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";

import { useAuth } from "@/auth/context/jwt";
import { User, USER_TYPE } from "@/auth/types";
import { ChatMessage } from "@/common/chat/ChatMessage";
import { Participant } from "@/common/chat/types";
import { Company } from "@/company/types";
import { ChatDate, isTypeDate } from "@/utils";

import { JobChat } from "../api/useFetchJobChats";

const insertDates = (chats: Array<JobChat>): Array<JobChat | ChatDate> => {
  let currentDate: string | null = null;
  const formattedChats: Array<JobChat | ChatDate> = [];

  chats.forEach(chat => {
    const chatDate = DateTime.fromISO(chat.createdAt.toString(), {
      zone: "utc",
    }).toFormat("LLL dd");

    if (chatDate !== currentDate) {
      const today = DateTime.now().toLocal().toFormat("LLL dd yyyy");
      const date = DateTime.fromISO(chat.createdAt.toString(), {
        zone: "utc",
      })
        .toLocal()
        .toFormat("LLL dd yyyy");
      formattedChats.push({
        date: chatDate,
        isDate: true,
        ...(date === today ? { isToday: true } : {}),
      });
      currentDate = chatDate;
    }

    formattedChats.push(chat);
  });

  return formattedChats;
};

const getAuthor = (
  message: JobChat,
  participants: Participant[],
  user?: User,
  company?: Company
) => {
  if (message.createdByUserId === user?.id) {
    return {
      name: `${user?.firstName} ${user?.lastName}` ?? "Me",
      isUser: true,
    };
  }
  if (message.createdByCompanyId === company?.id) {
    return {
      name: company?.name ?? "Me",
      avatar: company?.logoUrl,
      isUser: true,
    };
  }

  const author = participants.find(participant => {
    if (participant.type === USER_TYPE.COMPANY_TEAM_MEMBER) {
      return (
        (participant.id === message.createdByUserId && participant.company) ||
        participant.id === message.createdByCompanyId
      );
    }
    return participant.id === message.createdByUserId;
  });

  return { ...author, isUser: false };
};

interface ChatMessagesProps {
  initialChatAlert?: ReactNode;
  chats?: JobChat[];
  participants: Participant[];
}

export const JobChatMessages: FC<ChatMessagesProps> = props => {
  const { chats = [], participants, initialChatAlert, ...other } = props;

  const { session } = useAuth();
  const company = session?.company;
  const user = session?.user;

  if (!company && !user) return null;

  const chatsWithDates = insertDates(chats);

  return (
    <Stack spacing={2} sx={{ p: 3 }} {...other}>
      {initialChatAlert}
      {chatsWithDates.map(chat => {
        if (isTypeDate(chat)) {
          if (chat.isToday)
            return (
              <Divider sx={{ width: "100%" }}>
                <Typography variant="overline" color="text.secondary">
                  Today
                </Typography>
              </Divider>
            );
          return (
            <Divider sx={{ width: "100%" }}>
              <Typography variant="overline" color="text.secondary">
                {chat.date}
              </Typography>
            </Divider>
          );
        }
        const author = getAuthor(chat, participants, user, company);
        return (
          <ChatMessage
            key={chat.id}
            authorAvatar={author.avatar}
            authorName={author.name ?? ""}
            message={chat.message}
            createdAt={DateTime.fromISO(chat.createdAt.toString(), {
              zone: "utc",
            })}
            position={author.isUser ? "right" : "left"}
          />
        );
      })}
    </Stack>
  );
};

JobChatMessages.propTypes = {
  chats: PropTypes.array,
};
