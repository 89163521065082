import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { User, USER_TYPE } from "@/auth/types";
import API from "@/common/api";

import { Home } from "../types/job";

export interface JobChat {
  id: number;
  jobId: number;
  message: string;
  createdByUserId: number;
  createdByUserType: USER_TYPE;
  createdByCompanyId?: number;
  createdAt: Date;
  updatedAt?: Date;
}

export function useFetchHomeownerDetails(
  jobId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<{ homeowner: User; home: Home; notes?: string }> {
  return useQuery({
    queryKey: [`jobs/${jobId}/homeowner`],
    queryFn: async () => {
      const response = await API.get(`jobs/${jobId}/homeowner`);
      return response.data.data;
    },
    ...options,
  });
}
