import {
  CompanyGroup,
  CompanyStatus,
  DefaultCompanyGroups,
} from "@/company/types";

export const isAdministratorOrOwner = (group: CompanyGroup): boolean => {
  return (
    group.name === DefaultCompanyGroups.ADMINISTRATOR ||
    group.name === DefaultCompanyGroups.OWNER
  );
};

export const isAdministrator = (group: CompanyGroup): boolean => {
  return group.name === DefaultCompanyGroups.ADMINISTRATOR;
};

export const isOwner = (group: CompanyGroup): boolean => {
  return group.name === DefaultCompanyGroups.OWNER;
};

export const isTechnician = (group: CompanyGroup): boolean => {
  return group.name === DefaultCompanyGroups.FIELD_TEAM;
};

export const canSeeAllJobs = (group: CompanyGroup): boolean => {
  return group.name !== DefaultCompanyGroups.FIELD_TEAM;
};

export const canSeeMyJobs = (group: CompanyGroup): boolean => {
  return (
    group.name === DefaultCompanyGroups.ADMINISTRATOR ||
    group.name === DefaultCompanyGroups.OWNER ||
    group.name === DefaultCompanyGroups.FIELD_TEAM ||
    group.name === DefaultCompanyGroups.JOB_APPROVER ||
    group.name === DefaultCompanyGroups.TEAM_MEMBER
  );
};

export const canSeeWallet = (group: CompanyGroup): boolean => {
  return (
    group.name === DefaultCompanyGroups.ADMINISTRATOR ||
    group.name === DefaultCompanyGroups.OWNER ||
    group.name === DefaultCompanyGroups.FIELD_TEAM ||
    group.name === DefaultCompanyGroups.JOB_APPROVER ||
    group.name === DefaultCompanyGroups.TEAM_MEMBER
  );
};

export const canSeeCompanyDashboard = (group: CompanyGroup): boolean => {
  return (
    group.name === DefaultCompanyGroups.ADMINISTRATOR ||
    group.name === DefaultCompanyGroups.OWNER ||
    group.name === DefaultCompanyGroups.JOB_APPROVER
  );
};

export const isCompanyActive = (status: CompanyStatus | undefined): boolean => {
  return status === CompanyStatus.ACTIVE;
};

export const canSeeCompanyMessages = (group: CompanyGroup): boolean => {
  return group.name !== DefaultCompanyGroups.FIELD_TEAM;
};

export const canSeeCompanySettings = (group: CompanyGroup): boolean => {
  return group.name !== DefaultCompanyGroups.FIELD_TEAM;
};

export const canSeeCompanyTeamMembers = (group: CompanyGroup): boolean => {
  return (
    group.name === DefaultCompanyGroups.ADMINISTRATOR ||
    group.name === DefaultCompanyGroups.OWNER
  );
};

export const canSeeReferralMatrix = (group: CompanyGroup): boolean => {
  return (
    group.name === DefaultCompanyGroups.ADMINISTRATOR ||
    group.name === DefaultCompanyGroups.OWNER ||
    group.name === DefaultCompanyGroups.JOB_APPROVER
  );
};

export const canSeeExplorePartners = (group: CompanyGroup): boolean => {
  return (
    group.name === DefaultCompanyGroups.ADMINISTRATOR ||
    group.name === DefaultCompanyGroups.OWNER
  );
};

export const canUpdatePersonalInfo = (group: CompanyGroup): boolean => {
  return group.name !== DefaultCompanyGroups.FIELD_TEAM;
};

export const canSeeHubs = (group: CompanyGroup): boolean => {
  return (
    group.name === DefaultCompanyGroups.ADMINISTRATOR ||
    group.name === DefaultCompanyGroups.OWNER
  );
};
