import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { HomeownerRoutes } from "@/homeowner/constants";

import { HomeownerJobDrawer } from "../../JobDrawer/JobDrawer";

export function HomeownerJobDetailsPage() {
  const { jobId } = useParams();
  const navigate = useNavigate();

  const gotoJobsListPage = () =>
    navigate(
      getNavigationPathFromRoutePaths([
        HomeownerRoutes.BASE,
        HomeownerRoutes.JOBS_LIST,
      ])
    );

  if (!jobId) {
    return (
      <Box my={5} textAlign="center">
        <Typography variant="h6">
          There was an error whiling fetching the project details.
        </Typography>
        <Button
          href={getNavigationPathFromRoutePaths([
            HomeownerRoutes.BASE,
            HomeownerRoutes.JOBS_LIST,
          ])}
          color="primary"
          sx={{ my: 3 }}
          onClick={gotoJobsListPage}
        >
          Go Home
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <HomeownerJobDrawer
        open
        fullscreen
        jobId={parseInt(jobId) ?? 0}
        onClose={gotoJobsListPage}
      />
    </Box>
  );
}
