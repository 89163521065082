import { type StandardTextFieldProps, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { type ReactElement, useId } from "react";

interface TextInputFieldProps
  extends Omit<StandardTextFieldProps, "variant" | "type"> {
  name: string;
}

export function TextInputField(props: TextInputFieldProps): ReactElement {
  const { name, disabled, ...otherProps } = props;
  const [field, meta] = useField(name);
  const { isSubmitting } = useFormikContext();

  const id = useId();

  return (
    <TextField
      {...otherProps}
      {...field}
      id={id}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error ? meta.error : ""}
      disabled={isSubmitting || disabled}
    />
  );
}
