import { COMPANY_ACCOUNT_TYPES } from "@/company/types";

export enum ONBOARDING_ROUTES {
  BASE = "onboarding",
  TEAM_MEMBER_ONBOARDING = "team-member-onboarding",
}

export enum ONBOARDING_STAGES {
  ACCOUNT_TYPE = "account-type",
  COMPANY_PROFILE = "company-profile",
  INBOUND_PREFERENCES = "inbound-preferences",
  OUTBOUND_PREFERENCES = "outbound-preferences",
  JOB_PRICE = "job-price",
  TECHNICIAN_SPLIT = "technician-split",
  RECOMMENDED_PARTNERS = "recommended-partners",
  OPERATIONS_AND_SALES = "operations-and-sales",
  SERVICE_DETAILS = "service-details",
  SERVICE_TERRITORY = "service-territory",
}

export enum COMPANY_TEAM_MEMBER_ONBOARDING_ROUTES {
  TECHNICIAN_DEMO = "technician-demo",
  TECHNICIAN_ONBOARDING = "technician",
  TEAM_MEMBER_ONBOARDING = "team-member",
}

export const AccountTypeCardOptions = {
  [COMPANY_ACCOUNT_TYPES.INDEPENDENT_CONTRACTOR]: {
    title: "Independent Contractor",
    description:
      "I am a self-employed general contractor, handyman, or technician, working independently without a team.",
    value: COMPANY_ACCOUNT_TYPES.INDEPENDENT_CONTRACTOR,
  },
  [COMPANY_ACCOUNT_TYPES.BRAND_LOCATION]: {
    title: "Company",
    description:
      "I am registering a home services company with one or more locations, operating independently or as a franchise.",
    value: COMPANY_ACCOUNT_TYPES.BRAND_LOCATION,
  },
  [COMPANY_ACCOUNT_TYPES.INDEPENDENT_COMPANY]: {
    title: "Independent Company",
    description:
      "I am registering a home services company with one or more locations, operating independently.",
    value: COMPANY_ACCOUNT_TYPES.INDEPENDENT_COMPANY,
  },
};
