/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { CallType } from "@/company/api/useFetchCallRecordings";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { AddCompanyInfo } from "@/company/components/screens/job-calls-page/AddCompanyInfo";
import {
  AssociateCompanyRequest,
  JobAssociation,
} from "@/company/jobs/api/useAssociateCompanyToJob";
import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import {
  Company,
  CompanyPublicProfile,
  ExternalCompanyProfile,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { ExplorePartnerJobCard } from "./ExplorePartnerJobCard";

export interface PartnerSelectionProps {
  potentialReferralPartner?: Partial<Company>;
  onCancel: () => void;
  sector: JobSectors;
  setSector: (sector: JobSectors) => void;
  refetchPotentialReferralPartner: () => void;
  onNext: () => void;
  associateCompany: UseMutateAsyncFunction<
    void,
    AxiosError<unknown, any>,
    AssociateCompanyRequest,
    unknown
  >;
  submittingJob: boolean;
  associatingCompany: boolean;
  referralType: CallType;
  job: Job;
}

const ThinPaper = styled(Paper)(({ theme }) => ({
  width: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

export function PartnerSelection(props: PartnerSelectionProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { session } = useAuth();
  const { sector, setSector } = props;

  const company = session?.company;
  const [partnerCompanyProfile, setPartnerCompanyProfile] = useState<
    CompanyPublicProfile | ExternalCompanyProfile | undefined
  >();
  const [addReferralPartnerDialogOpen, setAddReferralPartnerDialogOpen] =
    useState(false);
  const [addCompanyInfoDialogOpen, setAddCompanyInfoOpen] = useState(false);

  useEffect(() => {
    if (
      !props.potentialReferralPartner ||
      props.referralType === CallType.INBOUND
    ) {
      setAddReferralPartnerDialogOpen(true);
    }
  }, [props.potentialReferralPartner, props.referralType]);

  return (
    <>
      {props.submittingJob || props.associatingCompany ? (
        <Box display={"flex"} flexDirection={"column"} gap={0} mt={3}>
          <Typography variant="subtitle1">Submitting Job</Typography>
          <Typography variant="caption" fontSize={"0.625rem"}>
            Please do not close this window.
          </Typography>
          <LinearProgress sx={{ width: "100%", mt: 1 }} />
        </Box>
      ) : props.potentialReferralPartner &&
        props.referralType === CallType.OUTBOUND ? (
        <Box width="100%" pt={3}>
          <Typography gutterBottom variant="subtitle1">
            Here is the recommended referral partner
          </Typography>
          <Box
            display={"flex"}
            gap={isMobile ? 1 : 2}
            alignItems={"baseline"}
            flexWrap={"wrap"}
            mt={2}
            flexDirection={"column"}
          >
            {props.potentialReferralPartner ? (
              <Card
                sx={{
                  p: 2,
                  boxShadow: "0px 0px 0px 1px #F2F4F7",
                  border: "1px solid #F2F4F7",
                  cursor: "pointer",
                  "&:active": {
                    border: "1px solid #071D2F",
                  },
                  "&:focus": {
                    border: "1px solid #071D2F",
                  },
                  "&:hover": {
                    boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
                  },
                }}
              >
                <Box my={1}>
                  <CompanyAvatar
                    height={"40px"}
                    maxWidth="200px"
                    logo={props.potentialReferralPartner?.logoUrl}
                    name={props.potentialReferralPartner?.name ?? ""}
                  />
                </Box>
                <Chip
                  key={sector}
                  sx={{ color: "text.secondary", bgcolor: "#F8F9FA" }}
                  label={
                    snakeCaseJobSectors?.[sector as JobSectors] ??
                    snakeCaseJobSectors[JobSectors.OTHER]
                  }
                  size="small"
                />
                <Box my={1} display={"flex"} gap={0.5}>
                  <Typography variant="h6">
                    {props.potentialReferralPartner?.name}
                  </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box display={"flex"} width={"100%"} justifyContent={"end"}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setAddReferralPartnerDialogOpen(true);
                    }}
                  >
                    Change Partner
                  </Button>
                </Box>
              </Card>
            ) : null}
          </Box>
          <Box display={"flex"} gap={1} mt={2}>
            <Button
              variant="contained"
              disabled={props.submittingJob || props.associatingCompany}
              onClick={() => {
                props.associateCompany({
                  jobId: props.job.id,
                  jobAssociation:
                    props.referralType === CallType.INBOUND
                      ? JobAssociation.SELLING_COMPANY
                      : JobAssociation.ROUTED_COMPANY,
                  existingCompanyId: props.potentialReferralPartner?.id,
                });
              }}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                props.onCancel();
              }}
            >
              Back
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          pt={3}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Stack
            alignItems="flex-start"
            direction={"row"}
            justifyContent={"center"}
            spacing={2}
            sx={{
              ml: 0,
              mr: "auto",
            }}
          >
            <ThinPaper
              variant="outlined"
              sx={{
                width: "100%",
                display: "flex",
                gap: 2,
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <WarningAmberRoundedIcon
                sx={{
                  bgcolor: "#FEE4E2",
                  borderRadius: "50%",
                  width: "48px",
                  height: "48px",
                  color: "#F04438",
                  p: 1,
                  pb: 1.2,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "center",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  textAlign={"start"}
                  mb={1}
                >
                  {props.referralType === CallType.OUTBOUND
                    ? "No Referral Partner Identified"
                    : "No Referring Partner Identified"}
                </Typography>
                <Typography
                  variant="body2"
                  color={"text.secondary"}
                  textAlign={"start"}
                >
                  {props.referralType === CallType.OUTBOUND
                    ? "Please select confirm a new or existing referral partner you want this job routed to"
                    : "Please select confirm a new or existing referral partner that referred you this job."}
                </Typography>
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                  width={"100%"}
                  mt={2}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setAddReferralPartnerDialogOpen(true)}
                  >
                    Confirm Partner
                  </Button>
                </Box>
              </Box>
            </ThinPaper>
          </Stack>
          <Button
            variant="outlined"
            onClick={() => {
              props.onCancel();
            }}
          >
            Back
          </Button>
        </Box>
      )}
      {addReferralPartnerDialogOpen && props.job ? (
        <ExplorePartnerJobCard
          isSellingCompany={props.referralType === CallType.OUTBOUND}
          job={props.job}
          sector={sector}
          setSector={(currentSector: JobSectors) => setSector(currentSector)}
          companyId={company?.id}
          refetchCalls={() => props.refetchPotentialReferralPartner()}
          isOpen={addReferralPartnerDialogOpen}
          onAddNew={() => {
            setAddCompanyInfoOpen(true);
            setAddReferralPartnerDialogOpen(false);
          }}
          onConfirm={(
            companyProfile: CompanyPublicProfile | ExternalCompanyProfile
          ) => {
            if (companyProfile?.googlePlacesId && !companyProfile?.id) {
              setAddCompanyInfoOpen(true);
              setPartnerCompanyProfile(companyProfile);
            } else {
              props.associateCompany({
                jobId: props.job.id,
                jobAssociation:
                  props.referralType === CallType.INBOUND
                    ? JobAssociation.SELLING_COMPANY
                    : JobAssociation.ROUTED_COMPANY,
                existingCompanyId: companyProfile?.id,
              });
            }
            setAddReferralPartnerDialogOpen(false);
          }}
          onClose={() => {
            setAddReferralPartnerDialogOpen(false);
          }}
        />
      ) : null}
      {addCompanyInfoDialogOpen && props.job ? (
        <AddCompanyInfo
          job={props.job}
          isOpen={addCompanyInfoDialogOpen}
          jobAssociation={
            props.referralType === CallType.INBOUND
              ? JobAssociation.SELLING_COMPANY
              : JobAssociation.ROUTED_COMPANY
          }
          googleCompanyProfile={partnerCompanyProfile}
          onConfirm={() => {
            setAddCompanyInfoOpen(false);
            setPartnerCompanyProfile(undefined);
            props.onNext();
          }}
          onClose={() => {
            setAddCompanyInfoOpen(false);
            setPartnerCompanyProfile(undefined);
          }}
        />
      ) : null}
    </>
  );
}
