import { Button, Card, CardHeader, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { enqueueSnackbar } from "notistack";
import * as React from "react";

import { useAuth } from "@/auth/context/jwt";
import { useQueryLocation } from "@/company/onboarding/api";
import TerritoryMap from "@/company/onboarding/components/screen/service-details/TerritoryMap";
import { promisifiedFunction } from "@/company/onboarding/components/screen/service-details/utils";

import { useFetchCompanyTerritoryById } from "./api/useFetchTerritoryById";
import { useUpdateServiceTerritoryById } from "./api/useUpdateTerritoryById";

export const CompanyServiceTerritory: React.FC<{
  companyId: number;
  expanded: boolean;
  zipCode?: number;
}> = ({ companyId, expanded, zipCode }) => {
  const [selectedTerritories, setSelectedTerritories] = React.useState<
    Array<google.maps.Data.Feature>
  >([]);

  const { isInitialized } = useAuth();

  const { data: location } = useQueryLocation(zipCode ?? "", !!zipCode);

  const { data: companyTerritory } = useFetchCompanyTerritoryById(companyId);
  const currentGeoIds = companyTerritory?.territoryGeoIds;

  const { mutateAsync: updateServiceTerritory } = useUpdateServiceTerritoryById(
    companyId,
    {
      onError() {
        enqueueSnackbar({
          message: "Error while updating company territory.",
          variant: "error",
        });
      },
      onSuccess() {
        enqueueSnackbar({
          message: "Your changes have been saved.",
          variant: "success",
        });
      },
    }
  );

  if (!isInitialized || !location) return <>Loading...</>;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "flex-start",
            columnGap: 1,
            padding: 3,
            width: "100%",
            flexGrow: { xs: 0, md: 1 },
            flex: "1 0 0",
            height: "70vh",
          }}
        >
          <CardHeader
            sx={{
              display: "flex",
              p: 0,
              pb: 2,
            }}
            subheader={
              <Typography
                color={"text.secondary"}
                variant={!expanded ? "body2" : "body1"}
              >
                Click the map below to select your service area and find the
                closest referral partners
              </Typography>
            }
          />
          <TerritoryMap
            location={location}
            currentGeoIds={currentGeoIds}
            selectedTerritories={selectedTerritories}
            companyId={companyId}
            onTerritoryClick={newSelectedTerritories =>
              setSelectedTerritories(newSelectedTerritories)
            }
          />
          <Stack
            spacing={2}
            width={"100%"}
            direction="row"
            textAlign={"center"}
            sx={{
              justifyContent: {
                xs: "center",
                lg: expanded ? "flex-end" : "center",
              },
            }}
            pt={3}
          >
            <Button
              variant="contained"
              onClick={async () => {
                const territoriesGeoJson = [];
                for (const feature of selectedTerritories) {
                  const geoJson = await promisifiedFunction(
                    feature.toGeoJson.bind(feature)
                  );
                  territoriesGeoJson.push(geoJson);
                }
                await updateServiceTerritory(territoriesGeoJson);
              }}
            >
              Save
            </Button>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};
