import {
  Add as AddIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useEffect, useState } from "react";

import { Seo } from "@/common/Seo/Seo";
import {
  CompanyExploreFilters,
  useQueryExploreCompanies,
} from "@/company/api/useExploreConnections";
import { JobSectors } from "@/company/jobs/constants";
import { CompanyPublicProfile } from "@/company/types";

import { CompanyProfileCard } from "../../company-profile-card/CompanyProfileCard";
import { InvitationDialog } from "../../invitation-dialog/InvitationDialog";

export const ExplorePartnerTabs = [
  { label: "Pending Invitations", filter: {} },
  { label: "Affiliate Companies", filter: {} },
  { label: "Companies You May Know", filter: {} },
  { label: "Explore", filters: {} },
];
export interface CompanyFiltersModal {
  initialValues: CompanyExploreFilters;
  applyFilters: (filters: CompanyExploreFilters) => void;
  variant: "permanent" | "temporary";
  closeFilters: () => void;
}

export const filterValidationSchema = {};

export function FiltersModal(props: CompanyFiltersModal) {
  const formik = useFormik({
    initialValues: props.initialValues,
    validationSchema: filterValidationSchema,
    onSubmit: async values => props.applyFilters(values),
  });

  return (
    <Drawer
      variant={props.variant}
      anchor="right"
      open={true}
      onClose={props.closeFilters}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography variant="body1">Filters</Typography>
        <IconButton onClick={props.closeFilters}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <Autocomplete
          multiple
          id="sectors"
          options={Object.keys(JobSectors).map(sector => sector.toString())}
          getOptionLabel={option => option}
          renderInput={params => (
            <TextField
              {...params}
              required
              fullWidth
              size="small"
              sx={{ mt: "16px" }}
              InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
              label="Select Sector Category"
            />
          )}
          renderOption={(props, option, { selected }) => (
            <MenuItem
              {...props}
              key={option}
              value={option}
              sx={{ justifyContent: "space-between" }}
            >
              {option}
              {selected ? <CheckIcon color="info" /> : null}
            </MenuItem>
          )}
          onChange={(_: SyntheticEvent, newValue: string[] | null) =>
            formik.setFieldValue("sectors", newValue)
          }
          onBlur={formik.handleBlur}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => formik.handleSubmit()}
        >
          Apply Filters
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => formik.resetForm()}
        >
          Reset Filters
        </Button>
      </Box>
    </Drawer>
  );
}

export function SuggestedPartnersPage() {
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);
  const [filters, setFilters] = useState<CompanyExploreFilters>({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedCompany, setSelectedCompany] =
    useState<CompanyPublicProfile | null>(null);

  const {
    isFetching,
    data,
    isError,
    refetch: searchCompanies,
  } = useQueryExploreCompanies(filters, {
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
  });

  if (isError) {
    enqueueSnackbar("There was an error fetching companies.", {
      variant: "error",
    });
  }

  useEffect(() => {
    searchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Seo title="Explore New Connections" />
      <Grid container>
        <Grid item xs={12} lg={isFiltersOpen && isDesktop ? false : 12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              <Typography variant="h4">Explore</Typography>
              <Typography variant="body2" color="text.secondary">
                Scroll down to see your pending partnerships and to connect with
                new referral partners.
              </Typography>
            </Box>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              size="large"
              onClick={() => setIsInvitationDialogOpen(true)}
            >
              Invite
            </Button>
          </Box>
          <Grid
            container
            sx={{ py: 2 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={10} lg={11}>
              {/* Convert to auto complete component */}
              <TextField
                fullWidth
                variant="outlined"
                name="filterKeyword"
                placeholder="Search by company name or keyword"
                value={filters.query ?? ""}
                onKeyDown={key => key.code === "Enter" && searchCompanies()}
                onChange={event =>
                  setFilters({ ...filters, query: event.target.value })
                }
              />
            </Grid>
            <Grid item xs={2} lg={1}>
              {isDesktop ? (
                <Button
                  fullWidth
                  color="primary"
                  startIcon={<FilterAltOutlinedIcon />}
                  onClick={() => setIsFiltersOpen(true)}
                >
                  Filter
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="primary"
                  onClick={() => setIsFiltersOpen(true)}
                >
                  <FilterAltOutlinedIcon />
                </Button>
              )}
            </Grid>
          </Grid>
          <Tabs
            value={selectedTab}
            variant="scrollable"
            scrollButtons="auto"
            onChange={(_event, newValue) => setSelectedTab(newValue)}
          >
            {ExplorePartnerTabs.map(tab => (
              <Tab key={tab.label} label={tab.label} />
            ))}
          </Tabs>
          {isFetching ? (
            <Box py={3} textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Grid container>
              {data && data.length > 0 ? (
                data.map((companyData, index) => (
                  <Grid key={index} item xs={12} sm={6} lg={4}>
                    <CompanyProfileCard companyProfile={companyData} />
                  </Grid>
                ))
              ) : (
                <Box textAlign="center" width="100%" py={3}>
                  <Typography variant="body1">
                    There are no companies matching your filters, please click
                    on the filters button above and update them.
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
        {isFiltersOpen && isDesktop && (
          <FiltersModal
            variant={isDesktop ? "temporary" : "permanent"}
            initialValues={filters}
            applyFilters={async newFilters => {
              setFilters(newFilters);
              setIsFiltersOpen(false);
              await searchCompanies();
            }}
            closeFilters={() => setIsFiltersOpen(false)}
          />
        )}
      </Grid>
      {isInvitationDialogOpen && (
        <InvitationDialog
          isOpen={true}
          onClose={() => setIsInvitationDialogOpen(false)}
        />
      )}
    </>
  );
}
