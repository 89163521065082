import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Slider,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import CheckboxList from "../../internal-admin/CheckboxList";
import { useBrandOverlapSearchFilterContext } from "../contexts/SearchFilterContext";

const googleRatingMarks = [
  {
    value: 0,
    label: "MIN",
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
    label: "MAX",
  },
];

const googleReviewsMarks = [
  {
    value: 0,
    label: "MIN",
  },
  {
    value: 1000,
  },
  {
    value: 2000,
  },
  {
    value: 3000,
  },
  {
    value: 4000,
  },
  {
    value: 5000,
  },
  {
    value: 6000,
  },
  {
    value: 7000,
  },
  {
    value: 8000,
  },
  {
    value: 9000,
  },
  {
    value: 10000,
    label: "MAX",
  },
];

export default function ReviewAndQualitySearchList() {
  const { addFilter, removeFilter, filters } =
    useBrandOverlapSearchFilterContext();

  const [open, setOpen] = useState(false);
  const [ratingOpen, setRatingOpen] = useState(false);
  const [numberOfReviewsOpen, setNumberOfReviewsOpen] = useState(false);
  const [numberOfWebsiteVisitorsOpen, setNumberOfWebsiteVisitorsOpen] =
    useState(false);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
      p={2}
      sx={{
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
        background: "#FFF",
        width: "50%",
      }}
    >
      <Box display={"flex"} gap={2} pb={1}>
        <StarBorderOutlinedIcon />
        <Typography fontSize={"14px"} fontWeight={600}>
          Reviews & Quality Signals
        </Typography>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box display={"flex"} width={"100%"} flexDirection={"column"} mt={1}>
        <CheckboxList
          parent={"Better Business Bureau"}
          checklist={[
            "BBB Rating & Accreditation",
            "Filed Complaints",
            "Local BBB",
          ]}
          parentChecked={!!filters.betterBusinessBureau}
          toggleParentChecked={() => {
            if (filters.betterBusinessBureau) {
              removeFilter("betterBusinessBureau");
            } else {
              addFilter({
                betterBusinessBureau: [
                  "BBB Rating & Accreditation",
                  "Filed Complaints",
                  "Local BBB",
                ],
              });
            }
          }}
          setSelectedList={list => {
            addFilter({
              betterBusinessBureau: list,
            });
          }}
          selectedList={filters.betterBusinessBureau}
        />
        <Box>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            {open ? (
              <ExpandLess onClick={() => setOpen(false)} />
            ) : (
              <ExpandMore onClick={() => setOpen(true)} />
            )}
            <Typography variant="subtitle2" color="text.secondary">
              Google Reviews
            </Typography>
          </Box>
          <Collapse unmountOnExit in={open} timeout="auto">
            <Box pl={2}>
              <Box
                display={"flex"}
                alignItems={"start"}
                flexDirection={"column"}
                gap={1}
              >
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  {ratingOpen ? (
                    <ExpandLess onClick={() => setRatingOpen(false)} />
                  ) : (
                    <ExpandMore onClick={() => setRatingOpen(true)} />
                  )}
                  <FormControlLabel
                    label={
                      <Typography variant="subtitle2" color="text.secondary">
                        Rating
                      </Typography>
                    }
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: "20px", height: "20px", p: 0, m: 1 }}
                        checked={!_.isNil(filters.googleRating)}
                        onChange={() => {
                          if (!_.isNil(filters.googleRating)) {
                            removeFilter("googleRating");
                          } else {
                            addFilter({ googleRating: [0, 5] });
                          }
                        }}
                      />
                    }
                  />
                </Box>
              </Box>
              <Collapse in={ratingOpen} timeout="auto">
                <Box pl={8} width={"100%"} textAlign="start" pb={5}>
                  <Slider
                    size="small"
                    valueLabelDisplay="auto"
                    getAriaValueText={value => `${value}`}
                    max={5}
                    min={0}
                    marks={googleRatingMarks}
                    step={0.1}
                    sx={{ p: 0, m: 0, width: "75%" }}
                    color="primary"
                    value={filters.googleRating ? filters.googleRating : [0, 5]}
                    disabled={_.isNil(filters.googleRating)}
                    onChange={(_, newValue) => {
                      addFilter({ googleRating: newValue });
                    }}
                  />
                </Box>
              </Collapse>
            </Box>
            <Box pl={2}>
              <Box
                display={"flex"}
                alignItems={"start"}
                flexDirection={"column"}
                gap={1}
              >
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  {numberOfReviewsOpen ? (
                    <ExpandLess onClick={() => setNumberOfReviewsOpen(false)} />
                  ) : (
                    <ExpandMore onClick={() => setNumberOfReviewsOpen(true)} />
                  )}
                  <FormControlLabel
                    label={
                      <Typography variant="subtitle2" color="text.secondary">
                        Number of Reviews
                      </Typography>
                    }
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: "20px", height: "20px", p: 0, m: 1 }}
                        checked={!_.isNil(filters.numberOfGoogleReviews)}
                        onChange={() => {
                          if (!_.isNil(filters.numberOfGoogleReviews)) {
                            removeFilter("numberOfGoogleReviews");
                          } else {
                            addFilter({ numberOfGoogleReviews: [0, 10000] });
                          }
                        }}
                      />
                    }
                  />
                </Box>
              </Box>
              <Collapse in={numberOfReviewsOpen} timeout="auto">
                <Box pl={8} width={"100%"} textAlign="start" pb={5}>
                  <Slider
                    size="small"
                    valueLabelDisplay="auto"
                    getAriaValueText={value => `${value}`}
                    max={10000}
                    min={0}
                    step={1000}
                    marks={googleReviewsMarks}
                    sx={{ p: 0, m: 0, width: "75%" }}
                    color="primary"
                    value={
                      filters.numberOfGoogleReviews
                        ? filters.numberOfGoogleReviews
                        : [0, 10000]
                    }
                    disabled={_.isNil(filters.numberOfGoogleReviews)}
                    onChange={(_, newValue) => {
                      addFilter({
                        numberOfGoogleReviews: newValue,
                      });
                    }}
                  />
                </Box>
              </Collapse>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <Box
            display={"flex"}
            alignItems={"start"}
            flexDirection={"column"}
            gap={1}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              {numberOfWebsiteVisitorsOpen ? (
                <ExpandLess
                  onClick={() => setNumberOfWebsiteVisitorsOpen(false)}
                />
              ) : (
                <ExpandMore
                  onClick={() => setNumberOfWebsiteVisitorsOpen(true)}
                />
              )}
              <FormControlLabel
                label={
                  <Typography variant="subtitle2" color="text.secondary">
                    Website Visitors (Last 30 Days)
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    sx={{ width: "20px", height: "20px", p: 0, m: 1 }}
                    checked={!_.isNil(filters.numberOfWebsiteVisitors)}
                    onChange={() => {
                      if (!_.isNil(filters.numberOfWebsiteVisitors)) {
                        removeFilter("numberOfWebsiteVisitors");
                      } else {
                        addFilter({ numberOfWebsiteVisitors: [0, 10000] });
                      }
                    }}
                  />
                }
              />
            </Box>
          </Box>
          <Collapse in={numberOfWebsiteVisitorsOpen} timeout="auto">
            <Box pl={8} width={"100%"} textAlign="start" pb={5}>
              <Slider
                key={"numberOfWebsiteVisitorsOpen"}
                size="small"
                valueLabelDisplay="auto"
                getAriaValueText={value => `${value}`}
                max={10000}
                min={0}
                step={100}
                sx={{ p: 0, m: 0, width: "75%" }}
                marks={googleReviewsMarks}
                color="primary"
                defaultValue={[0, 10000]}
                value={
                  filters.numberOfWebsiteVisitors
                    ? filters.numberOfWebsiteVisitors
                    : [0, 10000]
                }
                disabled={_.isNil(filters.numberOfWebsiteVisitors)}
                onChange={(_, newValue) => {
                  addFilter({ numberOfWebsiteVisitors: newValue });
                }}
              />
            </Box>
          </Collapse>
        </Box>
        <CheckboxList
          parent={"Historical Lawsuits"}
          parentChecked={!!filters.historicalLawsuits}
          toggleParentChecked={() => {
            if (filters.historicalLawsuits) {
              removeFilter("historicalLawsuits");
            } else {
              addFilter({
                historicalLawsuits: true,
              });
            }
          }}
        />
        <CheckboxList
          parent={"Confirmed insured"}
          parentChecked={!!filters.confirmedInsured}
          toggleParentChecked={() => {
            if (filters.confirmedInsured) {
              removeFilter("confirmedInsured");
            } else {
              addFilter({
                confirmedInsured: true,
              });
            }
          }}
        />
      </Box>
    </Box>
  );
}
