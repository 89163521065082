import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  MenuItem,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useEffect, useState } from "react";
import * as yup from "yup";

import { ToolTip } from "@/common/tool-tip/ToolTip";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { JobPromotionWithCompany } from "@/company/job-promotions/types";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { useFetchRelatedJobSectors } from "@/company/jobs/api/useFetchRelatedJobSectors";
import { useSubmitJob } from "@/company/jobs/api/useSubmitJob";
import { useUpdateJobSector } from "@/company/jobs/api/useUpdateJobSector";
import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";
import { isDefined } from "@/utils/isDefined";

const validationSchema = yup.object().shape({
  sector: yup.string().optional(),
});

interface CreatedJobSummaryProps {
  newJob: Job;
  setNewJob: (job: Job) => void;
  onSubmit: () => void;
  revertStep: () => void;
  jobPromotion?: JobPromotionWithCompany;
}

export function CreatedJobSummary(props: CreatedJobSummaryProps) {
  const { newJob, setNewJob, onSubmit, revertStep, jobPromotion } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [jobType, setJobType] = useState("");
  const [jobSummary, setJobSummary] = useState("");

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { data: fetchedRelatedJobSectors } = useFetchRelatedJobSectors(
    newJob?.sector as JobSectors,
    {
      enabled: !!newJob?.sector,
    }
  );

  const { mutateAsync: updateJobSector } = useUpdateJobSector({
    onSuccess: (job: Job) => {
      setNewJob(job);
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while updating job sector details.";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const { isLoading: submittingJob, mutateAsync: submitJob } = useSubmitJob({
    onSuccess: () => {
      onSubmit();
    },
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while submitting the job.`,
        variant: "error",
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      sector: isDefined(newJob?.sector)
        ? reverseSnakeCaseJobSectors[newJob?.sector]
        : "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ sector }) => {
      console.log({ sector });
    },
  });

  useEffect(() => {
    if (newJob?.summary) {
      setJobSummary(newJob.summary);
    }
    if (newJob?.jobType) {
      setJobType(newJob.jobType);
    }
  }, [newJob]);

  const renderJobSummary = () => {
    if (
      isDefined(jobPromotion) &&
      newJob?.type === JobUploadTypes.JOB_PROMOTION
    ) {
      return (
        <Stack gap={2}>
          <Typography gutterBottom variant="subtitle1">
            Here is the Summary
          </Typography>
          <Stack gap={3}>
            <Box>
              <Typography variant="subtitle2">Service Category</Typography>
              <Chip
                label={snakeCaseJobSectors[jobPromotion?.sector]}
                size="small"
                sx={{
                  backgroundColor: "#E5E7EB",
                  color: "text.secondary",
                  borderRadius: "12px",
                }}
              />
            </Box>
            <Box>
              <Typography variant="subtitle2">Promo Applied</Typography>
              <Typography variant="body2" color="text.secondary">
                {jobPromotion?.title}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">Service Provider</Typography>
              <Typography variant="body2" color="text.secondary">
                {jobPromotion?.company.name}
              </Typography>
              <Box
                component="img"
                src={jobPromotion.company?.logoUrl}
                alt="company logo"
                sx={{
                  height: "44px",
                  width: "auto",
                  maxWidth: "50%",
                  objectFit: "contain",
                  display: "block",
                }}
              />
            </Box>
          </Stack>
        </Stack>
      );
    }

    return (
      <Box display="flex" width={"100%"} flexDirection={"column"} gap={1}>
        <Typography gutterBottom variant="subtitle1" sx={{ mb: 1 }}>
          Here is the Summary
        </Typography>
        <Box display="flex" width={"100%"} flexDirection={"column"}>
          <Typography
            gutterBottom
            variant="subtitle2"
            sx={{ color: "#111927" }}
          >
            Service Category
          </Typography>
          <Autocomplete
            sx={{ m: 0 }}
            options={(fetchedRelatedJobSectors ?? Object.keys(JobSectors)).map(
              sector => {
                if (sector === JobSectors.HVAC) {
                  return sector.toString();
                }
                return snakeCaseJobSectors[sector.toString() as JobSectors];
              }
            )}
            defaultValue={
              snakeCaseJobSectors[newJob?.sector as JobSectors] ??
              snakeCaseJobSectors[JobSectors.OTHER]
            }
            value={formik.values.sector}
            getOptionLabel={option => option}
            renderInput={params => (
              <TextField
                {...params}
                required
                fullWidth
                error={formik.touched.sector && Boolean(formik.errors.sector)}
                helperText={formik.touched.sector && formik.errors.sector}
                value={formik.values.sector ?? []}
                margin="normal"
                sx={{ mt: 0 }}
                InputLabelProps={{
                  style: { marginBottom: "8px" },
                }}
                label="Select Service Categories"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} key={option} value={option}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  {<Typography>{option}</Typography>}
                  {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
                </Box>
              </MenuItem>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                  variant="outlined"
                />
              ))
            }
            onChange={(_: SyntheticEvent, newValue: string | null) => {
              formik.setFieldValue("sector", newValue as JobSectors);
              updateJobSector({
                jobId: newJob?.id,
                sector: reverseSnakeCaseJobSectors[newValue as JobSectors],
              });
            }}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box display={"flex"} gap={0.5} alignItems={"center"}>
          <Typography
            gutterBottom
            variant="subtitle2"
            sx={{ color: "#111927" }}
          >
            Job Type
          </Typography>
          <ToolTip
            placement="bottom"
            message={`A brief title based on the job you uploaded.
                         After analyzing the content, we summarize it
                         into a one-liner. Example: "Faucet
                         Repair" for a plumbing video.`}
          >
            <HelpOutlineIcon
              sx={{
                background: "none",
                color: "#6C737F",
                width: "20px",
                height: "17px",
              }}
            />
          </ToolTip>
        </Box>
        <TextField
          required
          fullWidth
          multiline
          hiddenLabel
          maxRows={8}
          id="jobType"
          name="jobType"
          margin="normal"
          InputLabelProps={{ shrink: false }}
          inputProps={{ minLength: 3, maxLength: 50 }}
          helperText={
            jobType.length < 3
              ? `Minimum length is 3 characters`
              : jobType.length > 50
              ? `Maximum 50 characters`
              : ""
          }
          error={jobType?.length < 3 || jobType?.length > 50}
          sx={{ my: 0, mx: 0, mt: -1 }}
          value={jobType}
          onChange={e => setJobType(e.target.value)}
        />
        <Box display={"flex"} width={"100%"} justifyContent={"end"} mt={-1}>
          <Typography
            fontSize={"12px"}
            color="text.secondary"
            fontWeight={400}
            mr={1}
          >
            {jobType?.length ?? 0}/500 Characters
          </Typography>
        </Box>
        <Box>
          <Typography
            gutterBottom
            variant="subtitle2"
            sx={{ color: "#111927" }}
          >
            Summary
          </Typography>
          <TextField
            required
            fullWidth
            multiline
            hiddenLabel
            id="summary"
            name="summary"
            margin="normal"
            InputLabelProps={{ shrink: false }}
            maxRows={8}
            inputProps={{ minLength: 3, maxLength: 500 }}
            helperText={
              jobSummary.length < 3
                ? `Minimum length is 3 characters`
                : jobSummary.length > 500
                ? `Maximum 500 characters`
                : ""
            }
            error={jobSummary?.length < 3 || jobSummary?.length > 500}
            sx={{ my: 0, mx: 0, p: 0 }}
            value={jobSummary}
            onChange={e => setJobSummary(e.target.value)}
          />
          <Box display={"flex"} width={"100%"} justifyContent={"end"} mt={0.5}>
            <Typography
              fontSize={"12px"}
              color="text.secondary"
              fontWeight={400}
              mr={1}
            >
              {jobSummary?.length ?? 0}/500 Characters
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems={"baseline"}
      width={"100%"}
      flexDirection={"column"}
      gap={1.5}
    >
      {renderJobSummary()}
      <Box
        display="flex"
        width={"100%"}
        justifyContent="start"
        alignItems="start"
        mt={1}
      >
        <Button
          sx={{
            mr: 1,
            borderRadius: "8px",
          }}
          color="primary"
          variant="outlined"
          disabled={submittingJob}
          size={isMobile ? "small" : "medium"}
          onClick={() => {
            revertStep();
          }}
        >
          Back
        </Button>
        <Button
          sx={{
            ml: 1,
            borderRadius: "8px",
          }}
          color="primary"
          variant="contained"
          disabled={submittingJob}
          size={isMobile ? "small" : "medium"}
          onClick={() => {
            submitJob({
              jobId: newJob?.id,
              jobSummary,
              jobType,
            });
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
