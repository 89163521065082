import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";
import { keysToSnakeCase } from "@/utils";

import { ExternalCompanyProfile } from "../types";
import { CompanyExploreFilters } from "./useExploreConnections";

export function useQueryExploreGoogleCompanies(
  filters?: CompanyExploreFilters,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<ExternalCompanyProfile>> {
  const uri = "/company-search/serp";
  const newFilters = { ...filters };

  const searchQuery = new URLSearchParams(keysToSnakeCase({ ...newFilters }));

  const uriWithSearchQuery = `${uri}?${searchQuery.toString()}`;

  return useQuery({
    queryKey: [uriWithSearchQuery.toString()],
    queryFn: async () => {
      const response = await API.get(uriWithSearchQuery);
      const camelCaseResponse = response.data.data;
      const result = camelCaseResponse.map(data => data.googleCompany);
      return result ?? [];
    },
    ...options,
  });
}
