import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css"; // Create a CSS file for custom styling

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhonelinkLockOutlinedIcon from "@mui/icons-material/PhonelinkLockOutlined";
import PhonelinkSetupOutlinedIcon from "@mui/icons-material/PhonelinkSetupOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Slider from "react-slick";

import { useAuth } from "@/auth/context/jwt";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useQueryExploreGoogleCompanies } from "@/company/api/useQueryExploreGoogleCompanies";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { JobSectors } from "@/company/jobs/constants";
import {
  useQuerySearchPublicCompanies,
  useUpdateBrandLocationProfile,
} from "@/company/onboarding/api";
import {
  CompanyPublicProfile,
  CompanyRoutes,
  ExternalCompanyProfile,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { useLeadContext } from "@/lead/context/lead-context";

export function CompanyIdentificationExternalLoggedIn() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { leadDetails: companyDetails } = useLeadContext();

  const [activeCompany, setActiveCompany] = useState<
    CompanyPublicProfile | ExternalCompanyProfile | undefined
  >();
  const { session, refreshSession } = useAuth();
  const brandLocationProfile = session?.brandLocationProfile;
  const company = session?.company;
  const companySectors = session?.companySectors;

  const companyAddress = session?.companyAddress;

  const [searchParams] = useSearchParams();
  const publicJobId = searchParams.get("publicJobId");
  const invitationCode = searchParams.get("invitationCode");

  const { isFetching: isFetchingGoogleCompanies, data: googleCompaniesData } =
    useQueryExploreGoogleCompanies(
      {
        zipCode: companyAddress?.zipCode,
        keyword: company?.name,
        limit: 8,
      },
      {
        refetchOnWindowFocus: false,
        enabled: !!(!!company?.name && !!companyAddress?.zipCode),
        retry: false,
      }
    );

  const { data: offPlatformCompanies, isFetching: loading } =
    useQuerySearchPublicCompanies(
      company?.name,
      companySectors?.map(({ sector }) => sector),
      companyAddress?.zipCode,
      !!company?.name
    );

  useEffect(() => {
    if (
      !isFetchingGoogleCompanies &&
      !loading &&
      (!googleCompaniesData || googleCompaniesData?.length < 1) &&
      (!offPlatformCompanies || offPlatformCompanies?.length < 1)
    ) {
      if (publicJobId) {
        navigate(
          `${getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.DASHBOARD_HOME,
          ])}?publicJobId=${publicJobId}`,
          { replace: true }
        );
        return;
      }
      if (invitationCode) {
        navigate(
          `${getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.DASHBOARD_HOME,
          ])}?invitationCode=${invitationCode}`,
          { replace: true }
        );
        return;
      }
      navigate(
        `${getNavigationPathFromRoutePaths([
          CompanyRoutes.BASE,
          CompanyRoutes.DASHBOARD_HOME,
        ])}`,
        { replace: true }
      );
    }
  }, [
    isFetchingGoogleCompanies,
    googleCompaniesData,
    offPlatformCompanies,
    loading,
    publicJobId,
    invitationCode,
  ]);

  const deDupedGoogleCompaniesData = googleCompaniesData?.filter(company => {
    return !offPlatformCompanies?.some(
      offPlatformCompany =>
        offPlatformCompany?.googlePlacesId === company.googlePlacesId
    );
  });

  const limitedOffPlatformCompanies = offPlatformCompanies?.slice(0, 10);
  const limitedGoogleCompanies = limitedOffPlatformCompanies?.length
    ? deDupedGoogleCompaniesData?.slice(
        0,
        10 - limitedOffPlatformCompanies?.length
      )
    : deDupedGoogleCompaniesData?.slice(0, 10);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    afterChange: (currentIndex: number) => {
      let activeCompany;
      const totalOffPlatformCompanies =
        limitedOffPlatformCompanies?.length || 0;
      const totalGoogleCompanies = limitedGoogleCompanies?.length || 0;

      // Check if the current index is within the limitedOffPlatformCompanies range
      if (
        limitedOffPlatformCompanies &&
        currentIndex < totalOffPlatformCompanies
      ) {
        activeCompany = limitedOffPlatformCompanies[currentIndex];
      }
      // Otherwise, it belongs to limitedGoogleCompanies
      else if (
        limitedGoogleCompanies &&
        currentIndex - totalOffPlatformCompanies < totalGoogleCompanies
      ) {
        activeCompany =
          limitedGoogleCompanies[currentIndex - totalOffPlatformCompanies];
      }
      setActiveCompany(activeCompany);
    },
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "custom_button__bar",
    nextArrow: (
      <KeyboardArrowRightIcon
        sx={{ color: "gray", "&:hover": { color: "gray" } }}
      />
    ),
    prevArrow: (
      <KeyboardArrowLeftIcon
        sx={{ color: "gray", "&:hover": { color: "gray" } }}
      />
    ),
  };
  const navigate = useNavigate();

  const {
    isLoading: updatingBrandLocationProfile,
    mutateAsync: updateBrandLocationProfile,
  } = useUpdateBrandLocationProfile(brandLocationProfile?.id, {
    onError() {
      enqueueSnackbar("Error while updating brand location details.", {
        variant: "error",
      });
    },
  });

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      p={2}
      minHeight={"calc(100vh - 205px)"}
      overflow={"auto"}
    >
      {isMobile ? (
        <Box
          display={"flex"}
          position={"absolute"}
          top={"16px"}
          left={"16px"}
          pb={4}
          pt={3}
          width="100%"
          justifyContent={"start"}
        >
          <TradeEngageLogo logotype={TradeEngageLogoTypes.LOGO_DARK} />
        </Box>
      ) : null}{" "}
      {isFetchingGoogleCompanies || loading ? (
        <Box py={3} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            maxWidth={"460px"}
            width={isMobile ? "95%" : "100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            {...(isMobile ? { px: 0 } : {})}
            gap={3}
          >
            <Typography
              variant="h6"
              sx={{ color: "#6C737F", fontWeight: "700", textAlign: "center" }}
            >
              Identify Your Company
            </Typography>
            {googleCompaniesData?.length || offPlatformCompanies?.length ? (
              <Box
                sx={{
                  boxShadow: "0px 0px 0px 1px #F2F4F7;",
                  width: "100%",
                  height: "100%",
                  borderRadius: "16px",
                  mb: "32px",
                }}
                className="slider-container"
              >
                <Slider {...settings}>
                  {limitedOffPlatformCompanies?.map(companyProfile => (
                    <Box
                      key={companyProfile.id}
                      px={3}
                      py={1}
                      sx={{ cursor: "pointer", width: "100%" }}
                      flexDirection={"column"}
                      alignItems={"start"}
                      display={"flex"}
                    >
                      <Box
                        display={"flex"}
                        width={"100%"}
                        flexDirection={"column"}
                        alignItems={"start"}
                        sx={{ p: 2, bgcolor: "white" }}
                      >
                        <CompanyAvatar
                          height={"80px"}
                          maxWidth={"200px"}
                          logo={companyProfile?.logoUrl}
                          name={companyProfile?.name || ""}
                        />
                      </Box>
                      <Box display={"flex"} gap={1} mt={1} flexWrap={"wrap"}>
                        {companyProfile?.sectors?.length
                          ? companyProfile?.sectors?.map(({ sector }) => (
                              <Chip
                                key={sector}
                                sx={{
                                  color: "text.secondary",
                                  bgcolor: "#F8F9FA",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  lineHeight: "20px",
                                  letterSpacing: 0.14,
                                  wordWrap: "break-word",
                                }}
                                label={
                                  snakeCaseJobSectors[sector as JobSectors] ??
                                  snakeCaseJobSectors[JobSectors.OTHER]
                                }
                                size="small"
                              />
                            ))
                          : null}
                      </Box>
                      <Grid container py={1}>
                        <Grid
                          item
                          xs={12}
                          display={"flex"}
                          alignItems={"start"}
                          gap={0.5}
                          position={"relative"}
                        >
                          <Typography
                            display="inline"
                            variant="h6"
                            verticalAlign={"center"}
                          >
                            {companyProfile?.name}{" "}
                            <span style={{ display: "inline-block" }}>
                              {" "}
                              {!!(
                                companyProfile?.platformPresence ===
                                PLATFORM_PRESENCE.ON_PLATFORM
                              ) && (
                                <ToolTip
                                  placement="bottom"
                                  title="TradeEngage Participant"
                                  message="This is a verified active business on TradeEngage."
                                >
                                  <VerifiedOutlinedIcon
                                    color="primary"
                                    fontSize="small"
                                    sx={{
                                      verticalAlign: "center",
                                      color: "#16DB65",
                                      display: "inline",
                                      mt: 0.2,
                                      ml: 0.2,
                                    }}
                                  />
                                </ToolTip>
                              )}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box display={"flex"} alignItems={"center"}>
                        {companyProfile?.owners?.length &&
                        (companyProfile?.owners[0].firstName ||
                          companyProfile?.owners[0].lastName) ? (
                          <>
                            <PersonOutlineOutlinedIcon
                              color="primary"
                              sx={{ width: "16px", mr: "2px" }}
                            />{" "}
                            <Typography
                              variant="caption"
                              color="primary"
                              alignItems={"center"}
                            >
                              {companyProfile?.owners[0].firstName
                                ? `${companyProfile?.owners[0].firstName}`
                                : ""}
                              {companyProfile?.owners[0].firstName &&
                              companyProfile?.owners[0].lastName
                                ? " "
                                : ""}
                              {companyProfile?.owners[0].lastName
                                ? `${companyProfile?.owners[0].lastName}`
                                : ""}
                            </Typography>
                          </>
                        ) : null}
                        {companyProfile?.owners?.[0]?.hasPhone &&
                        (companyProfile?.owners?.[0]?.firstName ||
                          companyProfile?.owners?.[0]?.lastName) ? (
                          <ToolTip
                            placement="bottom"
                            message="Mobile Number Verified"
                          >
                            <PhonelinkLockOutlinedIcon
                              color="primary"
                              fontSize="small"
                              sx={{ width: "16px" }}
                            />
                          </ToolTip>
                        ) : null}
                        {companyProfile?.owners?.[0]?.hasEmail &&
                        (companyProfile?.owners?.[0]?.firstName ||
                          companyProfile?.owners?.[0]?.lastName) ? (
                          <ToolTip
                            placement="bottom"
                            message="Email Address Verified"
                          >
                            <MarkEmailReadOutlinedIcon
                              color="primary"
                              fontSize="small"
                              sx={{ width: "16px" }}
                            />
                          </ToolTip>
                        ) : null}
                      </Box>
                      {companyProfile?.numberEmployees ? (
                        <Box display={"flex"} alignItems={"center"}>
                          <PeopleOutlineOutlinedIcon
                            color="primary"
                            sx={{ width: "16px", mr: "2px" }}
                          />{" "}
                          <Typography
                            variant="caption"
                            color="primary"
                            alignItems={"center"}
                          >
                            {companyProfile?.numberEmployees} Employees
                          </Typography>
                        </Box>
                      ) : null}
                      {companyProfile?.yearEstablished ? (
                        <Box display={"flex"} alignItems={"center"}>
                          <VerifiedUserOutlinedIcon
                            color="primary"
                            sx={{ width: "16px", mr: "2px" }}
                          />
                          <Typography
                            variant="caption"
                            color="primary"
                            alignItems={"center"}
                          >
                            {DateTime.now().year -
                            (companyProfile?.yearEstablished ??
                              DateTime.now().year)
                              ? DateTime.now().year -
                                (companyProfile?.yearEstablished ??
                                  DateTime.now().year)
                              : "< 1"}{" "}
                            Years in Business
                          </Typography>
                        </Box>
                      ) : null}
                      {companyProfile?.websiteUrl ? (
                        <Box display={"flex"} alignItems={"start"} gap={0.5}>
                          <LanguageOutlinedIcon
                            fontSize="small"
                            sx={{ color: "#16B364" }}
                          />
                          <Link
                            color="#16B364"
                            href={companyProfile?.websiteUrl}
                            target="_blank"
                            rel="noreferrer"
                            sx={{
                              color: "#16B364",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: isMobile
                                ? "200px !important"
                                : "340px !important",
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#16B364",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: isMobile
                                  ? "200px !important"
                                  : "340px !important",
                              }}
                            >
                              {companyProfile?.websiteUrl}
                            </Typography>
                          </Link>
                        </Box>
                      ) : null}
                      {companyProfile?.streetAddress ||
                      companyProfile?.city ||
                      companyProfile?.state ||
                      companyProfile?.zipCode ? (
                        <Box display={"flex"} alignItems={"center"} gap={0.5}>
                          <LocationOnOutlinedIcon
                            fontSize="small"
                            sx={{ color: "#16B364" }}
                          />
                          <Typography
                            sx={{
                              color: "#16B364",
                              textOverflow: "ellipsis",
                            }}
                            variant="caption"
                          >
                            {`${
                              companyProfile?.streetAddress
                                ? ` ${companyProfile?.streetAddress}${
                                    companyProfile?.city ||
                                    companyProfile?.state ||
                                    companyProfile?.zipCode
                                      ? ","
                                      : ""
                                  }`
                                : ""
                            }${
                              companyProfile?.city
                                ? ` ${companyProfile?.city}${
                                    companyProfile?.state ||
                                    companyProfile?.zipCode
                                      ? ","
                                      : ""
                                  }`
                                : ""
                            }${
                              companyProfile?.state
                                ? ` ${companyProfile?.state}`
                                : ""
                            }${
                              companyProfile?.zipCode
                                ? ` ${companyProfile?.zipCode}`
                                : ""
                            }`}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  ))}
                  {limitedGoogleCompanies?.map(company => (
                    <Box
                      key={company?.googlePlacesId}
                      px={3}
                      py={1}
                      sx={{ cursor: "pointer", width: "100%", height: "100%" }}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      alignItems={"start"}
                      display={"flex"}
                    >
                      <Box
                        display={"flex"}
                        width={"100%"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        alignItems={"start"}
                        sx={{ p: 2, bgcolor: "white" }}
                        gap={1}
                      >
                        {company?.name ? (
                          <Typography variant="h6">{company.name}</Typography>
                        ) : null}
                        {company?.website ? (
                          <Box display={"flex"} alignItems={"start"} gap={0.5}>
                            <LanguageOutlinedIcon
                              fontSize="small"
                              sx={{ color: "#16B364" }}
                            />
                            <Link
                              color="#16B364"
                              href={company.website}
                              target="_blank"
                              rel="noreferrer"
                              sx={{
                                color: "#16B364",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: isMobile
                                  ? "200px !important"
                                  : "340px !important",
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "#16B364",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: isMobile
                                    ? "200px !important"
                                    : "340px !important",
                                }}
                              >
                                {company.website}
                              </Typography>
                            </Link>
                          </Box>
                        ) : null}
                        {company?.address ? (
                          <Box display={"flex"} alignItems={"center"} gap={0.5}>
                            <LocationOnOutlinedIcon
                              fontSize="small"
                              sx={{ color: "#16B364" }}
                            />
                            <Typography
                              sx={{
                                color: "#16B364",
                                textOverflow: "ellipsis",
                              }}
                              variant="caption"
                            >
                              {company.address}
                            </Typography>
                          </Box>
                        ) : null}
                        {company?.phone ? (
                          <Box display={"flex"} alignItems={"center"} gap={0.5}>
                            <PhonelinkSetupOutlinedIcon
                              fontSize="small"
                              sx={{ color: "#16B364" }}
                            />
                            <Typography
                              sx={{ color: "#16B364" }}
                              variant="caption"
                            >
                              {company.phone}
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  ))}
                </Slider>
              </Box>
            ) : null}
            <Button
              color="primary"
              sx={{ width: "100%" }}
              variant="contained"
              disabled={updatingBrandLocationProfile}
              onClick={async () => {
                const currentCompany = activeCompany
                  ? activeCompany
                  : limitedOffPlatformCompanies?.length
                  ? limitedOffPlatformCompanies[0]
                  : limitedGoogleCompanies?.length
                  ? limitedGoogleCompanies[0]
                  : null;
                if (publicJobId || invitationCode) {
                  await updateBrandLocationProfile({
                    googlePlacesId: currentCompany?.googlePlacesId,
                  });
                  refreshSession();
                }
                navigate(
                  `${getNavigationPathFromRoutePaths([
                    CompanyRoutes.BASE,
                    CompanyRoutes.DASHBOARD_HOME,
                  ])}?${publicJobId ? `&publicJobId=${publicJobId}` : ""}${
                    invitationCode ? `&invitationCode=${invitationCode}` : ""
                  }`
                );
                return;
              }}
            >
              This is my Company
            </Button>
          </Box>
          <Typography mt={3} variant="body2" color="text.secondary">
            Can’t find your company? That’s ok, let’s{" "}
            <Link
              color="#16B364"
              rel="noreferrer"
              underline="none"
              sx={{
                cursor: "pointer",
              }}
              variant="subtitle2"
              onClick={() => {
                if (publicJobId) {
                  navigate(
                    `${getNavigationPathFromRoutePaths([
                      CompanyRoutes.BASE,
                      CompanyRoutes.DASHBOARD_HOME,
                    ])}?publicJobId=${publicJobId}`,
                    { replace: true }
                  );
                  return;
                }
                if (invitationCode) {
                  navigate(
                    `${getNavigationPathFromRoutePaths([
                      CompanyRoutes.BASE,
                      CompanyRoutes.DASHBOARD_HOME,
                    ])}?invitationCode=${invitationCode}`,
                    { replace: true }
                  );
                  return;
                }
                navigate(
                  `${getNavigationPathFromRoutePaths([
                    CompanyRoutes.BASE,
                    CompanyRoutes.DASHBOARD_HOME,
                  ])}`,
                  { replace: true }
                );
              }}
            >
              <Typography
                py={4}
                display="inline"
                variant="subtitle2"
                color="#16B364"
              >
                continue
              </Typography>
            </Link>
          </Typography>
        </>
      )}
    </Box>
  );
}
