import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { JobChat } from "./useFetchJobChats";

export function useUpdateJobChat(
  options: UseMutationOptions<
    JobChat,
    AxiosError,
    Pick<JobChat, "jobId" | "message">
  > = {}
): UseMutationResult<JobChat, AxiosError, Pick<JobChat, "jobId" | "message">> {
  return useMutation({
    mutationFn: async (
      chat: Pick<JobChat, "jobId" | "message">
    ): Promise<JobChat> => {
      const response = await API.post(
        "job-chats",
        changeCaseKeys(chat, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
