export enum AnalyticsEvents {
  // TECHNICIAN EVENTS
  CLICKED_ON_CONNECT_STRIPE = "CLICKED_ON_CONNECT_STRIPE",
  SKIPPED_STRIPE_CONNECT = "SKIPPED_STRIPE_CONNECT",
  WATCHED_TECHNICIAN_DEMO = "WATCHED_TECHNICIAN_DEMO",

  // COMPANY ONBOARDING EVENTS
  COMPANY_ONBOARDING_ACCOUNT_TYPE_ADDED = "COMPANY_ONBOARDING_ACCOUNT_TYPE_ADDED",
  COMPANY_ONBOARDING_SECTOR_TERRITORY_ZIP_CODE_AND_NAME_ADDED = "COMPANY_ONBOARDING_SECTOR_TERRITORY_ZIP_CODE_AND_NAME_ADDED",
  COMPANY_ONBOARDING_SECTORS_ZIP_CODE_AND_NAME_ADDED = "COMPANY_ONBOARDING_SECTORS_ZIP_CODE_AND_NAME_ADDED",
  COMPANY_ONBOARDING_SECTOR_TERRITORY_ADDED = "COMPANY_ONBOARDING_SECTOR_TERRITORY_ADDED",
  SKIPPED_COMPANY_ONBOARDING_ADDING_SECTOR_JOB_TYPES_DATA = "SKIPPED_COMPANY_ONBOARDING_ADDING_SECTOR_JOB_TYPES_DATA",
  COMPANY_ONBOARDING_SECTOR_JOB_TYPES_DATA_ADDED = "COMPANY_ONBOARDING_SECTOR_JOB_TYPES_DATA_ADDED",
  COMPANY_ONBOARDING_BASIC_COMPANY_DATA_ADDED = "COMPANY_ONBOARDING_BASIC_COMPANY_DATA_ADDED",
  SKIPPED_COMPANY_ONBOARDING_ADDING_BASIC_COMPANY_DATA = "SKIPPED_COMPANY_ONBOARDING_ADDING_BASIC_COMPANY_DATA",
  COMPANY_ONBOARDING_ADDED_TEAM_MEMBERS = "COMPANY_ONBOARDING_ADDED_TEAM_MEMBERS",
  SKIPPED_COMPANY_ONBOARDING_ADDING_TEAM_MEMBERS = "SKIPPED_COMPANY_ONBOARDING_ADDING_TEAM_MEMBERS",
  COMPANY_ONBOARDING_INBOUND_PREFERENCES_ADDED = "COMPANY_ONBOARDING_INBOUND_PREFERENCES_ADDED",
  SKIPPED_COMPANY_ONBOARDING_ADDING_INBOUND_PREFERENCES_DATA = "SKIPPED_COMPANY_ONBOARDING_ADDING_INBOUND_PREFERENCES_DATA",
  COMPANY_ONBOARDING_OUTBOUND_PREFERENCES_ADDED = "COMPANY_ONBOARDING_OUTBOUND_PREFERENCES_ADDED",
  SKIPPED_COMPANY_ONBOARDING_ADDING_OUTBOUND_PREFERENCES_DATA = "SKIPPED_COMPANY_ONBOARDING_ADDING_OUTBOUND_PREFERENCES_DATA",
  COMPANY_ONBOARDING_SECTOR_JOB_PRICE_ADDED = "COMPANY_ONBOARDING_SECTOR_JOB_PRICE_ADDED",
  SKIPPED_COMPANY_ONBOARDING_ADDING_SECTOR_JOB_PRICE_DATA = "SKIPPED_COMPANY_ONBOARDING_ADDING_SECTOR_JOB_PRICE_DATA",
  COMPANY_ONBOARDING_TECHNICIAN_SPLIT_ADDED = "COMPANY_ONBOARDING_TECHNICIAN_SPLIT_ADDED",
  SKIPPED_COMPANY_ONBOARDING_ADDING_TECHNICIAN_SPLIT_PRICE_DATA = "SKIPPED_COMPANY_ONBOARDING_ADDING_TECHNICIAN_SPLIT_DATA",
  COMPANY_ONBOARDING_COMPLETED = "COMPANY_ONBOARDING_COMPLETED",
  PAGE_VIEWED = "PAGE_VIEWED",

  // COMPANY PAYMENT EVENTS
  CLICKED_ON_BUY_CREDITS = "CLICKED_ON_BUY_CREDITS",
  CANCELLED_PURCHASE_ON_REVIEW = "CANCELLED_PURCHASE_ON_REVIEW",
  CHANGED_CARD_ON_PURCHASE_REVIEW = "CHANGED_CARD_ON_PURCHASE_REVIEW",

  // CAMPAIGN EVENTS
  CLICKED_CAMPAIGN_GET_STARTED = "CLICKED_CAMPAIGN_GET_STARTED",

  // EXTERNAL INVITATION EVENTS
  CLICKED_SEND_EMAIL_MESSAGE = "CLICKED_SEND_EMAIL_MESSAGE",
  CLICKED_SEND_TEXT_MESSAGE = "CLICKED_SEND_TEXT_MESSAGE",

  // VIDEO PROCESSING EVENTS
  UPLOAD_TO_S3_DURATION = "UPLOAD_TO_S3_DURATION",

  CLICKED_LOG_OUT = "CLICKED_LOG_OUT",
}
