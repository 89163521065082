import constate from "constate";
import { useState } from "react";

export const LEAD_DATA_STORAGE_KEY = "leads";

function useContactSupportModal() {
  const [contactSupportModalOpen, setContactSupportModalOpen] = useState(false);

  return { contactSupportModalOpen, setContactSupportModalOpen };
}

export const [ContactSupportModalProvider, useContactSupportModalContext] =
  constate(useContactSupportModal);
