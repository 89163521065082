import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonRemoveAlt1OutlinedIcon from "@mui/icons-material/PersonRemoveAlt1Outlined";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import type { FC } from "react";

import { useAuth } from "@/auth/context/jwt";
import { usePopover } from "@/common/hooks/use-popover";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";

interface MoreMenuProps {
  onViewProfile: () => void;
  onRemovePartnerShip: () => void;
}

export const MoreMenuReferral: FC<MoreMenuProps> = ({
  onViewProfile,
  onRemovePartnerShip,
}) => {
  const { session } = useAuth();
  const popover = usePopover<HTMLButtonElement>();

  return (
    <>
      <ToolTip message="More options">
        <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
          <SvgIcon>
            <MoreVertIcon />
          </SvgIcon>
        </IconButton>
      </ToolTip>
      <Menu
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        open={popover.open}
        PaperProps={{
          sx: {
            maxWidth: "100%",
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        onClose={popover.handleClose}
      >
        <MenuItem
          onClick={() => {
            onViewProfile();
            popover.handleClose();
          }}
        >
          <ListItemIcon>
            <SvgIcon>
              <HomeWorkOutlinedIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="View Profile" />
        </MenuItem>
        {session?.groups?.some(isAdministratorOrOwner) ? (
          <MenuItem
            onClick={() => {
              onRemovePartnerShip();
              popover.handleClose();
            }}
          >
            <ListItemIcon>
              <SvgIcon>
                <PersonRemoveAlt1OutlinedIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Remove Partnership" />
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};
