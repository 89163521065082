import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import zipcodes from "zipcodes";

import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { isCompanyActive } from "@/common/utils/accessControl";
import {
  useQueryBrandLocationById,
  useUpdateCompany,
} from "@/company/onboarding/api";
import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { CompanyRoutes } from "@/company/types";

import { OnboardingContainer } from "../../container/OnboardingContainer";
import * as utils from "../../utils";

const validationSchema = yup
  .object({
    yearEstablished: yup.date().nullable().optional(),
    numberEmployees: yup
      .number()
      .nullable()
      .min(0, "Can not be a negative number")
      .optional(),
    websiteUrl: yup
      .string()
      // .matches(URL_REGEX, "Website URL must be a valid URL")
      .optional(),
    description: yup
      .string()
      .optional()
      .max(1000, "Company description must be less than than 1000 characters"),
    email: yup.string().email("Must be a valid email address").optional(),
    zipCode: yup
      .string()
      .required("Zip Code is required")
      .oneOf(Object.keys(zipcodes.codes), "Invalid U.S. zip code")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
  })
  .optional();

export function CompanyProfile() {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { session, refreshSession } = useAuth();

  const { companyAddress, company } = session ?? {};

  const { data: searchCompanyRecord } = useQueryBrandLocationById(
    {
      id: company?.id,
    },
    { enabled: !!company?.id }
  );

  const { isLoading: updatingCompany, mutateAsync: updateCompany } =
    useUpdateCompany(session?.company?.id, {
      onError() {
        enqueueSnackbar("Error while updating company details.", {
          variant: "error",
        });
      },
      onSuccess() {
        trackEvent(
          AnalyticsEvents.COMPANY_ONBOARDING_BASIC_COMPANY_DATA_ADDED,
          {
            ...formik.values,
            companyId: company?.id,
          }
        );
        refreshSession();
        navigate(
          utils.OnboardingSteps[ONBOARDING_STAGES.COMPANY_PROFILE].nextStepRoute
        );
      },
    });

  const formik = useFormik({
    initialValues: {
      yearEstablished: null,
      numberEmployees: null,
      websiteUrl: "",
      description: "",
      zipCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({
      yearEstablished,
      numberEmployees,
      websiteUrl,
      zipCode,
      description,
    }) => {
      const companyPayload = {
        yearEstablished: yearEstablished ? yearEstablished?.year : "null",
        numberEmployees: numberEmployees ? numberEmployees : "null",
        websiteUrl,
        description,
      };
      await updateCompany({
        companyPayload,
      });
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    const zipCode = companyAddress?.zipCode;
    if (zipCode) {
      formik.setFieldValue("zipCode", zipCode);
    }
  }, [companyAddress]);

  const minDate = DateTime.fromObject({ year: 1944, month: 1, day: 1 });

  useEffect(() => {
    if (company) {
      if (company?.yearEstablished) {
        formik.setFieldValue(
          "yearEstablished",
          DateTime.utc(company?.yearEstablished)
        );
      }
      if (company?.numberEmployees)
        formik.setFieldValue("numberEmployees", company?.numberEmployees);
      if (company?.websiteUrl)
        formik.setFieldValue("websiteUrl", company?.websiteUrl);
      if (company?.description) {
        formik.setFieldValue("description", company?.description);
      }
      if (company?.description) {
        formik.setFieldValue("description", company?.description);
      }
    }
  }, [company]);

  return (
    <OnboardingContainer
      heading="Complete Your Company Profile"
      subheading="Populating your profile will help you earn the trust of your referral partners and improve our job routing."
    >
      <Box
        sx={{
          display: "flex",
          ...(isMobile ? { padding: "8px 8px" } : { padding: "8px 24px" }),
          flexDirection: "column",
          alignItems: "flex-start",
          flexGrow: 1,
          alignSelf: "stretch",
          background: "#F3F4F6",
          overflow: "auto",
        }}
      >
        <Box
          component="form"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
          }}
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              columnGap: "16px",
              rowGap: "8px",
              ...(isMobile
                ? { padding: "24px 16px", borderRadius: "8px" }
                : { padding: "24px" }),
              width: "100%",
            }}
          >
            <Typography gutterBottom variant="h6" textAlign={"left"}>
              Company Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
                alignItems: "center",
                columnGap: "8px",
                rowGap: "8px",
                width: "100%",
              }}
            >
              <TextField
                sx={{ width: { xs: 1, md: 1 } }}
                id="websiteUrl"
                name="websiteUrl"
                label="Website"
                value={formik.values.websiteUrl}
                error={
                  formik.touched.websiteUrl && Boolean(formik.errors.websiteUrl)
                }
                helperText={
                  formik.touched.websiteUrl && formik.errors.websiteUrl
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
                alignItems: "center",
                columnGap: "8px",
                rowGap: "8px",
                width: "100%",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  disableFuture
                  sx={{ width: { xs: 1, md: 1 / 2 } }}
                  label={"Year Established"}
                  views={["year"]}
                  minDate={minDate}
                  maxDate={DateTime.now()}
                  value={formik.values.yearEstablished}
                  onChange={newValue =>
                    formik.setFieldValue("yearEstablished", newValue)
                  }
                />
              </LocalizationProvider>
              <TextField
                sx={{ width: { xs: 1, md: 1 / 2 } }}
                id="numberEmployees"
                name="numberEmployees"
                label="Number of Employees"
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                value={formik.values.numberEmployees}
                error={
                  formik.touched.numberEmployees &&
                  Boolean(formik.errors.numberEmployees)
                }
                helperText={
                  formik.touched.numberEmployees &&
                  formik.errors.numberEmployees
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            {/* <Box
              sx={{
                width: "100%",
              }}
            >
              <GoogleAutocomplete
                fullWidth
                label="Business Address"
                selectedValue={formik.values.streetAddress ?? ""}
                onSelect={({ selection, zipCode }) => {
                  formik.setFieldValue("streetAddress", selection);
                  formik.setFieldValue("zipCode", zipCode);
                }}
                error={
                  formik.touched.streetAddress &&
                  Boolean(formik.errors.streetAddress)
                }
                helperText={
                  formik.touched.streetAddress && formik.errors.streetAddress
                }
              />
            </Box> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                columnGap: "8px",
                rowGap: "8px",
                width: "100%",
              }}
            >
              <TextField
                fullWidth
                multiline
                id="description"
                name="description"
                label="Describe Your Company and Your Ideal Referrals"
                rows={2}
                maxRows={4}
                value={formik.values.description}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : "This will be shown on your company profile for other service providers to get to know you."
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
          </Card>
        </Box>
      </Box>
      <Stack
        spacing={2}
        mt={3}
        mb={2}
        mx={2}
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "end",
          alignSelf: "stretch",
        }}
      >
        <Button
          variant="text"
          sx={{
            px: 0,
          }}
          onClick={() => {
            trackEvent(
              AnalyticsEvents.SKIPPED_COMPANY_ONBOARDING_ADDING_BASIC_COMPANY_DATA,
              {
                ...formik.values,
                companyId: company?.id,
              }
            );

            navigate(
              utils.OnboardingSteps[ONBOARDING_STAGES.COMPANY_PROFILE]
                .nextStepRoute
            );
          }}
        >
          Skip for Now
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            isCompanyActive(company?.status)
              ? navigate(
                  `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.RECOMMENDED_PARTNERS}`,
                  { replace: true }
                )
              : navigate(
                  `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.TECHNICIAN_SPLIT}`,
                  { replace: true }
                )
          }
        >
          Back
        </Button>
        <LoadingButton
          loading={updatingCompany}
          variant="contained"
          onClick={() => formik.handleSubmit()}
        >
          Continue
        </LoadingButton>
      </Stack>
    </OnboardingContainer>
  );
}
