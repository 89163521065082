import changeCaseKeys from "change-case-keys";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useAuth } from "../../auth/context/jwt";
import axiosInstance from "../api";
import { AnalyticsEvents } from "./enums";

export function usePageTrackingAnalytics(
  eventProperties: Record<string, unknown> = {}
) {
  const location = useLocation();
  const { session } = useAuth();
  const [initialized, setInitialized] = useState(false);
  const [searchParams] = useSearchParams();
  const phone = searchParams.get("phone");
  const email = searchParams.get("email");

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      axiosInstance.post("/common/v2/send-analytics-event", {
        event_name: AnalyticsEvents.PAGE_VIEWED,
        event_properties: changeCaseKeys(
          { uri: location.pathname + location.search },
          "underscored"
        ),
        user_id: session?.user?.id,
        ...(phone ? { phone } : {}),
        ...(email ? { email } : {}),
      });
    }
  }, [
    initialized,
    location?.search,
    location?.pathname,
    session?.user,
    phone,
    email,
  ]);
}
