/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import {
  closeSnackbar,
  CustomContentProps,
  SnackbarContent,
  SnackbarKey,
} from "notistack";
import { forwardRef } from "react";

interface SuccessSnackBarProps extends CustomContentProps {
  onClose?: () => void;
  actions?: (snackbarId: SnackbarKey) => React.ReactNode;
  subText?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const SuccessSnackBar = forwardRef<HTMLDivElement, SuccessSnackBarProps>(
  (props, ref) => {
    const {
      // You have access to notistack props and options 👇🏼
      id,
      message,
      subText,
      actions,
      onClose,

      // as well as your own custom props 👇🏼
      ...other
    } = props;

    return (
      <SnackbarContent ref={ref} role="alert" {...other}>
        <Box
          display={"flex"}
          sx={{
            backgroundColor: "#071D2F",
            padding: "6px 16px",
            borderRadius: "12px",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ pt: "10px", mr: "12px", width: "22px", height: "22px" }}>
            <CheckCircleOutlineOutlinedIcon
              fontSize="small"
              sx={{ color: "#16DB65" }}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"} py={1}>
            {message ? (
              <Typography
                sx={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  color: "white",
                }}
              >
                {message}
              </Typography>
            ) : null}
            {subText ? (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "157%",
                  letterSpacing: "0.15px",
                  color: "white",
                }}
              >
                {subText}
              </Typography>
            ) : null}
          </Box>
          <CloseIcon
            fontSize="small"
            sx={{
              color: "white",
              cursor: "pointer",
              width: "20px",
              height: "20px",
              mt: "10px",
              ml: 2,
            }}
            onClick={() => {
              onClose?.();
              closeSnackbar(id);
            }}
          />
        </Box>
      </SnackbarContent>
    );
  }
);
