import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { DashboardTitle } from "@/common/DashboardTitle";
import { Seo } from "@/common/Seo/Seo";
import { useGetTechnicianLeaderboard } from "@/company/api/useGetTechnicianLeaderboard";

import { DesktopPage } from "./DesktopPage";
import { EmptyScreen } from "./EmptyScreen";
import { MobilePage } from "./MobilePage";

export enum Duration {
  THIS_WEEK = "THIS_WEEK",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH",
}

export function LeaderboardPage() {
  const { session } = useAuth();
  const user = session?.user;

  const [selectedDuration, setSelectedDuration] = useState<Duration>(
    Duration.THIS_WEEK
  );
  const now = DateTime.local();
  const startOfWeek = now.startOf("week");
  const endOfWeek = now.endOf("week");
  const startOfLastWeek = startOfWeek.minus({ weeks: 1 });
  const endOfLastWeek = endOfWeek.minus({ weeks: 1 });
  const startOfLastMonth = now.startOf("month").minus({ months: 1 });
  const endOfLastMonth = now.startOf("month").minus({ days: 1 }).endOf("month");

  const [filterPeriod, setFilterPeriod] = useState({
    fromDate: startOfWeek.toISODate(),
    toDate: endOfWeek.toISODate(),
  });

  const { data: leaderboardData, isFetching: isLoading } =
    useGetTechnicianLeaderboard(filterPeriod, {
      cacheTime: 0, // Do not keep cache
      staleTime: 0,
      refetchOnWindowFocus: false, // Refetch on window focus is pretty annoying
    });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  leaderboardData?.sort((a, b) => {
    if (a.earnings == b.earnings) {
      return a.firstName.localeCompare(b.firstName);
    }
    return 0; // Do nothing if earnings are different
  });

  const ownRecord = user && leaderboardData?.find(tech => tech.id === user.id);

  useEffect(() => {
    if (selectedDuration === Duration.THIS_WEEK) {
      setFilterPeriod({
        fromDate: startOfWeek.toISODate(),
        toDate: endOfWeek.toISODate(),
      });
      return;
    }
    if (selectedDuration === Duration.LAST_WEEK) {
      setFilterPeriod({
        fromDate: startOfLastWeek.toISODate(),
        toDate: endOfLastWeek.toISODate(),
      });
      return;
    }
    if (selectedDuration === Duration.LAST_MONTH) {
      setFilterPeriod({
        fromDate: startOfLastMonth.toISODate(),
        toDate: endOfLastMonth.toISODate(),
      });
      return;
    }
  }, [selectedDuration]);
  const showEmptyScreen =
    !leaderboardData?.length ||
    (leaderboardData?.length === 1 && ownRecord?.id === leaderboardData[0].id);

  return (
    <>
      <Seo title={"Leaderboard"} />
      <Box
        sx={{
          minHeight: "calc(100vh - 60px)",
          pt: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoading ? (
          <Box mt={10} justifyContent="center" width={"100%"} display={"flex"}>
            <CircularProgress />
          </Box>
        ) : showEmptyScreen ? (
          <EmptyScreen leaderboardData={leaderboardData} />
        ) : (
          <>
            {isMobile ? null : (
              <Box
                sx={{
                  top: isMobile ? "0" : "56px",
                }}
                position={isMobile ? "relative" : "sticky"}
                display={"flex"}
                bgcolor={"white"}
                zIndex={1200}
                flexDirection={"column"}
                width={"100%"}
                justifyContent={"space-between"}
                alignItems={"start"}
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <DashboardTitle
                    title={"Leaderboard"}
                    icon={
                      <EmojiEventsOutlinedIcon
                        sx={{ color: "#16DB65", mt: 0.2 }}
                      />
                    }
                  />
                </Box>
              </Box>
            )}
            <Grid container>
              <Grid container item xs={12} gap={isMobile ? 0 : 2}>
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{ ...(isMobile ? { bgcolor: "#071D2F" } : {}) }}
                  >
                    <Box
                      display="flex"
                      pb={1}
                      gap={1}
                      my={2}
                      width={"100%"}
                      justifyContent={isMobile ? "end" : "start"}
                    >
                      <Chip
                        label={
                          <Typography
                            variant="subtitle1"
                            fontSize="13px"
                            fontWeight={500}
                            sx={{
                              ...(isMobile &&
                              selectedDuration !== Duration.THIS_WEEK
                                ? { color: "white" }
                                : {}),
                            }}
                          >
                            This Week
                          </Typography>
                        }
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(selectedDuration === Duration.THIS_WEEK
                          ? { color: "primary", variant: "filled" }
                          : {
                              variant: "outlined",
                              ...(isMobile
                                ? {
                                    "& .MuiFormControlLabel-label": {
                                      color: "white",
                                    },
                                  }
                                : {}),
                            })}
                        onClick={() => {
                          setSelectedDuration(Duration.THIS_WEEK);
                        }}
                      />
                      <Chip
                        label={
                          <Typography
                            variant="subtitle1"
                            fontSize="13px"
                            fontWeight={500}
                            sx={{
                              ...(isMobile &&
                              selectedDuration !== Duration.LAST_WEEK
                                ? { color: "white" }
                                : {}),
                            }}
                          >
                            Last Week
                          </Typography>
                        }
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(selectedDuration === Duration.LAST_WEEK
                          ? {
                              color: "primary",
                              variant: "filled",
                            }
                          : {
                              variant: "outlined",
                              ...(isMobile
                                ? {
                                    "& .MuiFormControlLabel-label": {
                                      color: "white",
                                    },
                                  }
                                : {}),
                            })}
                        onClick={() => {
                          setSelectedDuration(Duration.LAST_WEEK);
                        }}
                      />
                      <Chip
                        label={
                          <Typography
                            variant="subtitle1"
                            fontSize="13px"
                            fontWeight={500}
                            sx={{
                              ...(isMobile &&
                              selectedDuration !== Duration.LAST_MONTH
                                ? { color: "white" }
                                : {}),
                            }}
                          >
                            Last Month
                          </Typography>
                        }
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(selectedDuration === Duration.LAST_MONTH
                          ? { color: "primary", variant: "filled" }
                          : {
                              variant: "outlined",
                            })}
                        onClick={() => {
                          setSelectedDuration(Duration.LAST_MONTH);
                        }}
                      />
                    </Box>
                  </Grid>
                  {isMobile ? (
                    <MobilePage leaderboardData={leaderboardData} />
                  ) : (
                    <DesktopPage leaderboardData={leaderboardData} />
                  )}
                </>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}
