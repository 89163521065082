import { Box, Chip, Drawer } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridEventListener,
} from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";

import { AdminChatProvider } from "@/company/context/admin-chat-context";
import { JobStatus } from "@/company/jobs/types";
import { ProjectJobStatusChipProps } from "@/company/jobs/utils";
import { ConnectionStatus } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { InternalAdminCompanyDrawer } from "./InternalAdminCompanyDrawer";
import {
  CustomToolbar,
  drawerWidth,
  formatDate,
  getGridStringOperatorsDataGrid,
} from "./utils";

export default function JobReceivedTable({
  jobsReceived,
  setCurrentSelectedReceivedJob,
  expanded,
}: {
  jobsReceived: any;
  expanded?: boolean;
  setCurrentSelectedReceivedJob: () => void;
}) {
  const [rows, setRows] = useState([]);
  const [companyProfileId, setCompanyProfileId] = useState<number | null>();

  useEffect(() => {
    if (jobsReceived?.length) {
      setRows(jobsReceived);
      return;
    }
    setRows([]);
  }, [jobsReceived]);

  let columns: GridColDef[] = [
    {
      field: "jobRouting-jobType",
      headerName: "Job Type",
      width: 200,
      valueGetter: (value, row) => row.jobRouting?.jobType,
    },
    {
      field: "job-id",
      headerName: "Job ID",
      renderCell: params => params.row.job?.id?.toString(),

      width: 200,
      valueGetter: (value, row) => row.job?.id,
    },
    {
      field: "job-status",
      headerName: "Job Status",
      width: 200,
      valueGetter: (_, row) => row.job?.status,
      renderCell: params => {
        if (params.row.job?.status === JobStatus.NO_COMPANY_TO_ROUTE) {
          return (
            <Chip
              label="NO COMPANY TO ROUTE"
              sx={{
                bgcolor: "rgba(240, 68, 56, 0.12)",
                color: "#B42318",
              }}
            />
          );
        }
        return (
          <Chip
            label={ProjectJobStatusChipProps[params.row.job?.status].label}
            sx={{
              bgcolor:
                ProjectJobStatusChipProps[params.row.job?.status].bgcolor,
              color: ProjectJobStatusChipProps[params.row.job?.status].color,
              fontSize: "12px",
              fontWeight: 600,
              height: "24px",
            }}
          />
        );
      },
    },
    {
      field: "job-sector",
      headerName: "Sector",
      width: 200,
      valueGetter: (_, row) => row.job?.sector,
      renderCell: params => {
        return (
          <Chip
            key={`sector-chip-${params.row.job?.id}`}
            label={snakeCaseJobSectors[params.row.job?.sector]}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            size="small"
          />
        );
      },
    },
    {
      field: "jobRouting-id",
      headerName: "Job Routing ID",
      width: 200,
      renderCell: params => params.row.jobRouting?.id?.toString(),

      valueGetter: (value, row) => row.jobRouting?.id,
    },
    {
      field: "jobRouting-status",
      headerName: "Job Routing Status",
      width: 200,
      valueGetter: (_, row) => row.jobRouting?.status,
      renderCell: params => {
        return (
          <Chip
            label={
              ProjectJobStatusChipProps[params.row.jobRouting?.status].label
            }
            sx={{
              bgcolor:
                ProjectJobStatusChipProps[params.row.jobRouting?.status]
                  .bgcolor,
              color:
                ProjectJobStatusChipProps[params.row.jobRouting?.status].color,
              fontSize: "12px",
              fontWeight: 600,
              height: "24px",
            }}
          />
        );
      },
    },
    {
      field: "jobRouting-createdAt",
      headerName: "Created At",
      type: "string",
      width: 200,
      valueGetter: (_, row) => row.jobRouting?.createdAt,
      renderCell: params => formatDate(params.row.jobRouting?.createdAt),
    },
    {
      field: "listingExtraInfo-technicianName",
      headerName: "Technician name",
      type: "string",
      width: 200,
      valueGetter: (_, row) => row.job?.listingExtraInfo?.technicianName,
      renderCell: params => params.row.job?.listingExtraInfo?.technicianName,
    },
    {
      field: "listingExtraInfo-createdByCompanyId",
      headerName: "Selling Company Id",

      valueGetter: (_, row) => row.job?.listingExtraInfo?.createdByCompanyId,
      renderCell: params =>
        params.row.job?.listingExtraInfo?.createdByCompanyId,
    },
    {
      field: "listingExtraInfo-createdByCompanyLogoUrl",
      headerName: "Selling Company Logo Url",
      filterable: false,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <img
              src={params?.row.job?.listingExtraInfo?.createdByCompanyLogoUrl}
              style={{
                width: "48px",
                height: "32px",
                objectFit: "contain",
              }}
              alt="company-logo"
            />
          </Box>
        );
      },
      sortable: false,
    },
    {
      field: "listingExtraInfo-createdByCompanyName",
      headerName: "Selling Company",
      type: "string",
      width: 200,
      valueGetter: (_, row) => row.job?.listingExtraInfo?.createdByCompanyName,
      renderCell: params =>
        params.row.job?.listingExtraInfo?.createdByCompanyName,
    },
    {
      field: "jobBuyingCost",
      headerName: "Referral Fee",
      type: "string",
      width: 200,
      valueGetter: (_, row) => row?.job?.jobBuyingCost,
      renderCell: params =>
        params.row?.job?.jobBuyingCost
          ? `$${params.row?.job?.jobBuyingCost}`
          : "",
    },
    {
      field: "technicianFee",
      headerName: "Technician Fee",
      type: "string",
      width: 200,
      valueGetter: (_, row) => row?.job?.technicianFee,
      renderCell: params =>
        params.row?.job?.technicianFee
          ? `$${params.row?.job?.technicianFee}`
          : "",
    },
    {
      field: "salePrice",
      headerName: "Sale Price",

      type: "string",
      width: 200,
      valueGetter: (_, row) => row?.job?.salePrice,
      renderCell: params =>
        params.row?.job?.salePrice ? `$${params.row?.job?.salePrice}` : "",
    },
    {
      field: "routeToHomeowner",
      headerName: "Route to Homeowner",
      type: "string",
      width: 200,
      valueGetter: (_, row) =>
        row?.job?.listingExtraInfo?.routeToHomeowner ? "Yes" : "No",
      renderCell: params =>
        params.row?.job?.listingExtraInfo?.routeToHomeowner ? "Yes" : "No",
    },
    {
      field: "payReferralFeeSettings",
      headerName: "Referral Fee Job's Settings",
      type: "string",
      width: 200,
      valueGetter: (_, row) => row?.job?.payReferralFeeSettings,
      renderCell: params =>
        params.row?.job?.payReferralFeeSettings?.replaceAll("_", " "),
    },
  ];

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    const job = jobsReceived.find(
      job => job?.jobRouting?.id === params.row.jobRouting?.id
    );
    if (
      params.field === "jobRouting-jobType" &&
      jobsReceived.some(
        job => job?.jobRouting?.id === params.row.jobRouting?.id
      )
    ) {
      setCurrentSelectedReceivedJob(job);
    }
    if (params.field === "listingExtraInfo-createdByCompanyName")
      setCompanyProfileId(params.row.job?.listingExtraInfo?.createdByCompanyId);
  };

  return (
    <Box display={"flex"} width={"100%"}>
      <Box
        sx={{
          height: 400,
          position: "relative",
          width: "100%",
        }}
      >
        <DataGridPremium
          checkboxSelection
          disableRowSelectionOnClick
          pagination
          getRowId={row => row.jobRouting?.id}
          columns={columns}
          sx={{
            "& .MuiDataGrid-row": {
              "--rowBorderColor": "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withRightBorder": {
              borderRightColor: "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withLeftBorder": {
              borderLeftColor: "#D9D9D9",
            },
          }}
          rows={rows ?? []}
          slots={{
            ...(expanded
              ? {
                  toolbar: () => (
                    <CustomToolbar
                      fileName="Jobs_Received"
                      includeQuickFilter={false}
                    />
                  ),
                }
              : {}),
          }}
          editMode="row"
          initialState={{
            density: "compact",
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "jobType"],
              right: ["actions"],
            },
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          onCellClick={handleCellClick}
        />
      </Box>
      {companyProfileId ? (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!companyProfileId}
        >
          {companyProfileId ? (
            <AdminChatProvider companyId={companyProfileId}>
              <InternalAdminCompanyDrawer
                open={!!companyProfileId}
                expanded={expanded}
                companyProfileId={companyProfileId}
                connectionStatus={ConnectionStatus.CONNECTED}
                onClose={() => {
                  setCompanyProfileId(null);
                }}
              />
            </AdminChatProvider>
          ) : null}
        </Drawer>
      ) : null}
    </Box>
  );
}
