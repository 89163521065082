import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

import { Job, JobStatus } from "../types";

const SUBMMIT_JOB_URL = "/jobs/:id/submit";

export type UpdateJobRequest = {
  jobId: string;
  status: JobStatus;
  summary: string;
};

export type UpdateJobResponse = { job: Job };

export function useSubmitJob(
  options: UseMutationOptions<
    Job,
    AxiosError,
    {
      jobId: number;
      autoApproveJob?: boolean;
      source?: string;
      jobSummary?: string;
      jobType?: string;
    }
  > = {}
): UseMutationResult<
  Job,
  AxiosError,
  {
    jobId: number;
    autoApproveJob?: boolean;
    source?: string;
    jobSummary?: string;
    jobType?: string;
  }
> {
  return useMutation({
    mutationFn: async ({
      jobId,
      autoApproveJob,
      source,
      jobSummary,
      jobType,
    }) => {
      const url = SUBMMIT_JOB_URL.replace(":id", jobId.toString());
      const resp = await API.post(url, {
        auto_approve_job: !!autoApproveJob,
        ...(source ? { source } : {}),
        ...(jobSummary ? { summary: jobSummary } : {}),
        ...(jobType ? { job_type: jobType } : {}),
      });
      return resp?.data?.data;
    },
    ...options,
  });
}
