import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { DateTime } from "luxon";

import API from "@/common/api";

export const USE_GET_ALL_CHATS__GROUPED_URI =
  "/internal-admin/companies/brand-location/{company_id}/chats";

export interface ChatParticipant {
  type: ChatTypeParticipant;
  id: number;
  name: string;
  logoUrl?: string;
  email?: string;
}

export interface ChatMessage {
  fromCompanyId?: number;
  toCompanyId?: number;
  toUserId?: number;
  fromUserId?: number;
  jobId?: number;
  message: string;
  createdAt: DateTime;
  readAt?: DateTime;
  id: number;
}

export enum ChatTypeParticipant {
  TEAM_MEMBER = "TEAM_MEMBER",
  COMPANY = "COMPANY",
  HOMEOWNER = "HOMEOWNER",
}
export enum ChatType {
  COMPANY_TO_COMPANY = "COMPANY_TO_COMPANY",
  JOB = "JOB",
  COMPANY_TO_USER = "COMPANY_TO_USER",
}

export interface ChatThread {
  type: ChatType;
  participants: Array<ChatParticipant>;
  messages: Array<ChatMessage>;
  createdAt: string;
  jobId?: number;
  jobType?: string;
  id?: number;
  unreadMessages?: number;
}

export function useFetchAdminChatsByBrandLocation(
  companyId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<ChatThread>> {
  return useQuery({
    queryKey: [USE_GET_ALL_CHATS__GROUPED_URI, companyId],
    queryFn: async () => {
      const response = await API.get(
        `${USE_GET_ALL_CHATS__GROUPED_URI.replace("{company_id}", companyId)}`
      );
      return response.data.data;
    },
    ...options,
  });
}
