import { Skeleton, Stack, Typography } from "@mui/material";

import { isDefined } from "@/utils/isDefined";

import { referralCampaignConfigurationSteps } from "../../constants";
import {
  ReferralCampaign,
  ReferralCampaignConfigurationStep,
} from "../../types";

interface CampaignGoalProps {
  referralCampaign: Omit<ReferralCampaign, "id" | "createdAt" | "updatedAt">;
  indexOfActiveStep?: number;
}

export function CampaignGoal(props: CampaignGoalProps) {
  const { referralCampaign, indexOfActiveStep } = props;

  const campaignGoalStepIndex = referralCampaignConfigurationSteps.indexOf(
    ReferralCampaignConfigurationStep.CAMPAIGN_GOAL
  );

  if (
    isDefined(indexOfActiveStep) &&
    indexOfActiveStep < campaignGoalStepIndex
  ) {
    return null;
  }

  return (
    <Stack gap={1}>
      <Typography variant="h6" fontWeight={700}>
        Goal
      </Typography>
      {indexOfActiveStep === campaignGoalStepIndex ? (
        <>
          <Skeleton variant="rounded" width="100%" height="15px" />
          <Skeleton variant="rounded" width="100%" height="15px" />
          <Skeleton variant="rounded" width="100%" height="15px" />
        </>
      ) : (
        <Typography variant="subtitle2" color="text.secondary" fontWeight={500}>
          {referralCampaign.goal === "Other"
            ? referralCampaign.otherGoal
            : referralCampaign.goal}
        </Typography>
      )}
    </Stack>
  );
}
