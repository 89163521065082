import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

import { UserTransaction } from "../types";

export const WITHDRAW_BALANCE_URL = "/users/withdraw";

export function useWithdrawBalance(
  options: UseMutationOptions<UserTransaction, AxiosError, number> = {}
): UseMutationResult<UserTransaction, AxiosError, number> {
  return useMutation({
    mutationFn: async (amount: number) => {
      const response = await API.post(WITHDRAW_BALANCE_URL, { amount });
      return response.data.data;
    },
    ...options,
  });
}
