import { Add as AddIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useFetchUsersWithGroups } from "@/company/api/useFetchUsersWithGroups";
import {
  useMutationActivateUser,
  useMutationDeleteGroupUser,
  useQueryFetchCompanyGroups,
  useTriggerProfileCompletenessNotification,
} from "@/company/onboarding/api";
import { ONBOARDING_STAGES } from "@/company/onboarding/constants";
import { DefaultCompanyGroups } from "@/company/types";

import { OnboardingContainer } from "../../container/OnboardingContainer";
import { OnboardingSteps } from "../../utils";
import { AddStaffForm } from "./AddStaffForm";
import { AddStaffModal } from "./AddStaffModal";
import { EditStaffModal } from "./EditStaffModal";
import { StaffTable } from "./StaffTable";
import { STAFF_TYPES, StaffData } from "./types";
import { getCompanyGroupIdFromName } from "./utils";

export function OperationsAndSales() {
  const tabs = Object.values(STAFF_TYPES).map(staffType => ({
    label: staffType,
    value: staffType,
  }));

  const [currentTab, setCurrentTab] = useState<string>(STAFF_TYPES.OPERATIONS);

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: string): void => {
      setCurrentTab(value);
    },
    []
  );

  const [addModalOpen, setAddModalOpen] = useState(false);

  const [selectedStaffIndex, setSelectedStaffIndex] = useState<
    number | undefined
  >();

  const initialStaffDataObj: Record<STAFF_TYPES, Array<StaffData>> = {
    [STAFF_TYPES.OPERATIONS]: [],
    [STAFF_TYPES.FIELD_TEAM]: [],
  };
  const [staffDataObj, setStaffDataObj] = useState(initialStaffDataObj);
  const { session } = useAuth();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { data: companyGroups, isLoading: fetchingCompanyGroups } =
    useQueryFetchCompanyGroups(session?.company?.id);

  const {
    data: userWithGroups,
    refetch: refetchUsers,
    isLoading: fetchingUsersWithGroups,
  } = useFetchUsersWithGroups();

  const { mutateAsync: deleteUser, isLoading: deletingUser } =
    useMutationDeleteGroupUser({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while deleting team member.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        refetchUsers();
        enqueueSnackbar({
          message: "Deleted user successfully.",
          variant: "success",
        });
      },
    });

  const { mutateAsync: activateUser, isLoading: activatingUser } =
    useMutationActivateUser({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while inviting team member.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        refetchUsers();
        enqueueSnackbar({
          message: "Invited user successfully.",
          variant: "success",
        });
      },
    });

  const { mutateAsync: triggerProfileCompletenessNotification } =
    useTriggerProfileCompletenessNotification({});

  const navigate = useNavigate();

  const onEditClose = () => {
    if (selectedStaffIndex !== undefined && selectedStaffIndex >= 0) {
      refetchUsers();
    }
  };

  const onEditMember = (index: number) => {
    setSelectedStaffIndex(index);
  };

  const onNext = async () => {
    if (!companyGroups) return;
    triggerProfileCompletenessNotification();
    trackEvent(
      userWithGroups && userWithGroups.length > 0
        ? AnalyticsEvents.COMPANY_ONBOARDING_ADDED_TEAM_MEMBERS
        : AnalyticsEvents.SKIPPED_COMPANY_ONBOARDING_ADDING_TEAM_MEMBERS,
      {
        company_id: session?.company?.id,
        userWithGroups:
          userWithGroups && userWithGroups.length > 0
            ? userWithGroups
            : undefined,
        source: "NEXT_BUTTON",
      }
    );
    trackEvent(AnalyticsEvents.COMPANY_ONBOARDING_COMPLETED, {
      companyId: session?.company?.id,
      source: "NEXT_BUTTON",
    });
    navigate(
      OnboardingSteps[ONBOARDING_STAGES.OPERATIONS_AND_SALES].nextStepRoute
    );
  };

  const onModalClose = () => {
    refetchUsers();
  };

  const onRemoveMember = (staffType: STAFF_TYPES, index: number) => {
    const newStaffData = [...staffDataObj[staffType]];
    const deletedUser = newStaffData.find(member => member.id === index);
    if (deletedUser)
      deleteUser({
        userId: deletedUser.id,
        groupId: getCompanyGroupIdFromName(
          companyGroups ?? [],
          deletedUser.role ?? ""
        ),
      });
  };

  /**
   * Use effect to populate default staff data from already existing users
   */
  useEffect(() => {
    const defaultStaffObject: Record<STAFF_TYPES, Array<StaffData>> = {
      [STAFF_TYPES.OPERATIONS]: [],
      [STAFF_TYPES.FIELD_TEAM]: [],
    };

    userWithGroups?.forEach(userWithGroups => {
      const { groups, user } = userWithGroups;
      /**
       * We need to show administrator as both a field team and operations member
       */
      if (groups[0].name === DefaultCompanyGroups.ADMINISTRATOR) {
        defaultStaffObject[STAFF_TYPES.FIELD_TEAM].push({
          ...user,
          role: groups[0].name as DefaultCompanyGroups,
        } as StaffData);
        defaultStaffObject[STAFF_TYPES.OPERATIONS].push({
          ...user,
          role: groups[0].name as DefaultCompanyGroups,
        } as StaffData);
      } else {
        /**
         * Logic to show member belonging to field team show them in a separate tab
         */
        const staffType =
          groups[0].name === DefaultCompanyGroups.FIELD_TEAM
            ? STAFF_TYPES.FIELD_TEAM
            : STAFF_TYPES.OPERATIONS;
        defaultStaffObject[staffType].push({
          ...user,
          role: groups[0].name as DefaultCompanyGroups,
        } as StaffData);
      }
    });
    setStaffDataObj(defaultStaffObject);
  }, [userWithGroups]);

  const onActivateMember = (staffType: STAFF_TYPES, index: number) => {
    const newStaffData = [...staffDataObj[staffType]];
    const activatingUser = newStaffData.find(member => member.id === index);
    if (activatingUser)
      activateUser({
        userId: activatingUser.id,
        groupId: getCompanyGroupIdFromName(
          companyGroups ?? [],
          activatingUser.role ?? ""
        ),
      });
  };

  if (fetchingCompanyGroups) {
    return <div>Loading...</div>;
  }

  return (
    <OnboardingContainer
      heading="Add Team Members"
      subheading="Invite your team to approve jobs and your Field Team to submit jobs and earn from their own referrals!"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: isMobile ? "column" : "row",
          flexGrow: 1,
          alignSelf: "stretch",
          ...(isMobile
            ? { padding: "8px 0" }
            : { background: "#F3F4F6", padding: "32px 24px" }),
          overflow: "auto",
          columnGap: "16px",
          rowGap: "8px",
        }}
      >
        {isMobile ? (
          <Box
            display={"flex"}
            pt={2}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                textTransform: "uppercase",
                lineHeight: "24px",
                letterSpacing: 0.5,
                wordWrap: "break-word",
              }}
            >
              All Team Members
            </Typography>
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setAddModalOpen(true)}
            >
              New Team Member
            </Button>
          </Box>
        ) : (
          <Box display={"flex"} flexDirection={"column"}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                columnGap: "8px",
                padding: "24px",
                overflow: "visible",
                width: { xs: "100%", lg: "40%", minWidth: "320px" },
              }}
            >
              <AddStaffForm
                staffType={currentTab as STAFF_TYPES}
                onClose={onModalClose}
              />
            </Card>
            {/* <TeamMemberBonusCard /> */}
          </Box>
        )}
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            columnGap: "8px",
            ...(isMobile
              ? { p: 0, border: "none", boxShadow: "none", borderRadius: 0 }
              : { padding: "24px" }),
            width: "100%",
            height: "100%",
          }}
        >
          <Tabs
            indicatorColor="primary"
            scrollButtons={false}
            textColor="primary"
            value={currentTab}
            variant="scrollable"
            sx={{
              paddingBottom: "8px",
            }}
            onChange={handleTabsChange}
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          {currentTab === STAFF_TYPES.OPERATIONS && (
            <>
              <StaffTable
                key={currentTab}
                staffType={currentTab}
                staff={staffDataObj[currentTab].map(member => ({
                  ...member,
                  name: member.firstName + " " + member.lastName,
                }))}
                isActivatingUser={activatingUser}
                activateUser={onActivateMember}
                onEditMember={onEditMember}
                onRemoveMember={onRemoveMember}
              />
            </>
          )}
          {currentTab === STAFF_TYPES.FIELD_TEAM && (
            <>
              <StaffTable
                key={currentTab}
                staffType={currentTab}
                staff={staffDataObj[currentTab].map(member => ({
                  ...member,
                  name: member.firstName + " " + member.lastName,
                }))}
                isActivatingUser={activatingUser}
                activateUser={onActivateMember}
                onEditMember={onEditMember}
                onRemoveMember={onRemoveMember}
              />
            </>
          )}
        </Card>
      </Box>
      <Stack
        spacing={2}
        mt={3}
        mb={2}
        mx={2}
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "end",
          alignSelf: "stretch",
        }}
      >
        <Button
          variant="text"
          sx={{
            px: 0,
          }}
          onClick={() => {
            triggerProfileCompletenessNotification();
            trackEvent(
              AnalyticsEvents.SKIPPED_COMPANY_ONBOARDING_ADDING_TEAM_MEMBERS,
              {
                companyId: session?.company?.id,
                source: "SKIP_BUTTON",
              }
            );
            trackEvent(AnalyticsEvents.COMPANY_ONBOARDING_COMPLETED, {
              companyId: session?.company?.id,
              source: "SKIP_BUTTON",
            });
            navigate(
              OnboardingSteps[ONBOARDING_STAGES.OPERATIONS_AND_SALES]
                .nextStepRoute
            );
          }}
        >
          Skip for Now
        </Button>
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Back
        </Button>
        <LoadingButton
          loading={
            fetchingCompanyGroups || fetchingUsersWithGroups || deletingUser
          }
          variant="contained"
          onClick={onNext}
        >
          Finish
        </LoadingButton>
      </Stack>
      {selectedStaffIndex !== undefined && selectedStaffIndex >= 0 ? (
        <EditStaffModal
          isOpen={selectedStaffIndex !== undefined && selectedStaffIndex >= 0}
          staffData={{
            ...staffDataObj[currentTab as STAFF_TYPES]?.find(
              (member, index) => member.id === selectedStaffIndex
            ),
            staffType: currentTab as STAFF_TYPES,
          }}
          staffType={currentTab as STAFF_TYPES}
          onClose={() => {
            setSelectedStaffIndex(undefined);
          }}
          onSave={() => {
            onEditClose();
            setSelectedStaffIndex(undefined);
          }}
        />
      ) : null}
      {addModalOpen ? (
        <AddStaffModal
          staffType={currentTab as STAFF_TYPES}
          isOpen={addModalOpen}
          onClose={() => {
            setAddModalOpen(false);
            refetchUsers();
          }}
        />
      ) : null}
    </OnboardingContainer>
  );
}
