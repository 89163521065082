import type { PaletteColor } from "@mui/material/styles/createPalette";

import type { ColorPreset } from ".";
import { blue, green, indigo, neutral, orange, purple, red } from "./colors";

export const getPrimary = (preset?: ColorPreset): PaletteColor => {
  switch (preset) {
    case "blue":
      return blue;
    case "green":
      return green;
    case "indigo":
      return indigo;
    case "purple":
      return purple;
    case "orange":
      return orange;
    case "red":
      return red;
    default:
      console.error(
        'Invalid color preset, accepted values: "blue", "green", "indigo" or "purple"".'
      );
      return green;
  }
};

export const getSecondary = (): PaletteColor => {
  return {
    lightest: neutral[50],
    light: neutral[100],
    main: "#384250",
    dark: "#1C2536",
    darkest: "#111927",
    contrastText: "#FFFFFF",
  };
};
