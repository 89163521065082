import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import axiosInstance from "@/common/api";

import { Job } from "../types";

export const GET_ROUTING_BY_JOB_ID_URL = "/jobs/:id/job-routing";

export function useGetRoutingByJobId(
  jobId: number | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Job> {
  const url = GET_ROUTING_BY_JOB_ID_URL.replace(":id", jobId?.toString() ?? "");
  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      const response = await axiosInstance.get(url);
      return response.data.data;
    },
    enabled: Boolean(jobId),
    ...options,
  });
}
