import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, SyntheticEvent } from "react";

import { useAuth } from "@/auth/context/jwt";
import { CompanyExploreFilters } from "@/company/api/useExploreConnections";
import { JobSectors } from "@/company/jobs/constants";
import { snakeCaseJobSectors } from "@/company/utils";

import { otherFilterOptions } from "./utils";

interface FilterDrawerProps {
  onClose?: () => void;
  applyFilters: () => void;
  resetFilters: () => void;
  setDrawerFilters: (filters: Partial<CompanyExploreFilters>) => void;
  drawerFilters: Partial<CompanyExploreFilters>;
  open?: boolean;
}

export const FilterDrawer: FC<FilterDrawerProps> = props => {
  const {
    onClose,
    applyFilters,
    resetFilters,
    open = false,
    setDrawerFilters,
    drawerFilters,
    ...other
  } = props;

  const { session } = useAuth();
  const companySectors = session?.companySectors;
  const campaign = session?.campaign;

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
          height: "100vh",
          maxWidth: 500,
          px: 3,
          py: 3,
        },
      }}
      onClose={onClose}
      {...other}
    >
      <Box display="flex" justifyContent="space-between" alignItems={"center"}>
        <Typography variant="h6">Filters</Typography>
        <IconButton
          aria-label="close"
          sx={{
            color: theme => theme.palette.grey[500],
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={3} flex={"1 0 0"}>
        <Autocomplete
          multiple
          id="sectors"
          options={Object.keys(JobSectors)
            .filter(
              sector =>
                !companySectors
                  ?.map(companySector => companySector.sector)
                  .includes(sector as JobSectors)
            )
            .filter(
              sector =>
                !campaign?.companyCampaignSectors?.includes(
                  sector as JobSectors
                )
            )
            .map(sector => {
              if (sector === JobSectors.HVAC) {
                return sector.toString();
              }
              return snakeCaseJobSectors[sector.toString() as JobSectors];
            })}
          value={drawerFilters.sectors ?? []}
          getOptionLabel={option => option}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              value={drawerFilters.sectors ?? []}
              margin="normal"
              InputLabelProps={{
                style: { marginBottom: "8px" },
              }}
              label="Select Service Categories"
            />
          )}
          renderOption={(props, option, { selected }) => (
            <MenuItem {...props} key={option} value={option}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                {<Typography>{option}</Typography>}
                {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
              </Box>
            </MenuItem>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                size="small"
                label={option}
                {...getTagProps({ index })}
                variant="outlined"
              />
            ))
          }
          onChange={(_: SyntheticEvent, newValue: string[] | null) =>
            setDrawerFilters({
              ...drawerFilters,
              sectors: (newValue ?? []) as JobSectors[],
            })
          }
        />
        <Box>
          <Typography variant="overline">other filters</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={drawerFilters.linkedCompanies}
                  onChange={event =>
                    setDrawerFilters({
                      ...drawerFilters,
                      linkedCompanies: event.target.checked,
                    })
                  }
                />
              }
              label={otherFilterOptions.linkedCompanies}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={drawerFilters.topReferralCategoryCheck}
                  onChange={event =>
                    setDrawerFilters({
                      ...drawerFilters,
                      topReferralCategoryCheck: event.target.checked,
                    })
                  }
                />
              }
              label={otherFilterOptions.topReferralCategoryCheck}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={drawerFilters.otherNearbyCheck}
                  onChange={event =>
                    setDrawerFilters({
                      ...drawerFilters,
                      otherNearbyCheck: event.target.checked,
                    })
                  }
                />
              }
              label={otherFilterOptions.otherNearbyCheck}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={drawerFilters.ownerContactInformationAvailable}
                  onChange={event =>
                    setDrawerFilters({
                      ...drawerFilters,
                      ownerContactInformationAvailable: event.target.checked,
                    })
                  }
                />
              }
              label={otherFilterOptions.ownerContactInformationAvailable}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!drawerFilters.franchiseCheck}
                  onChange={event =>
                    setDrawerFilters({
                      ...drawerFilters,
                      franchiseCheck: event.target.checked,
                    })
                  }
                />
              }
              label={otherFilterOptions.franchiseCheck}
            />
          </FormGroup>
        </Box>
      </Box>
      <Box>
        <Divider />
        <Stack spacing={2} py={2} direction={"row"}>
          <Button
            variant="contained"
            onClick={() => {
              applyFilters();
            }}
          >
            Apply Filters
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              resetFilters();
            }}
          >
            Reset Filters
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};
