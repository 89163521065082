import { CompanyGroup, DefaultCompanyGroups } from "@/company/types";

import { STAFF_TYPES } from "./types";

export const StaffTypeToGroupMapper = {
  [STAFF_TYPES.FIELD_TEAM]: DefaultCompanyGroups.ADMINISTRATOR,
  [STAFF_TYPES.OPERATIONS]: DefaultCompanyGroups.ADMINISTRATOR,
};

export function getRolesFromStaffType(staffType: STAFF_TYPES) {
  if (staffType === STAFF_TYPES.FIELD_TEAM) {
    return [DefaultCompanyGroups.FIELD_TEAM];
  }
  if (staffType === STAFF_TYPES.OPERATIONS) {
    return [
      DefaultCompanyGroups.ADMINISTRATOR,
      DefaultCompanyGroups.OWNER,
      DefaultCompanyGroups.JOB_APPROVER,
      DefaultCompanyGroups.TEAM_MEMBER,
      DefaultCompanyGroups.FIELD_TEAM,
    ];
  }
  return [];
}

export function getCompanyGroupIdFromName(
  companyGroups: Array<CompanyGroup>,
  groupName: string
) {
  return companyGroups.find(group => group.name === groupName)?.id ?? -1;
}

export function getToolTipInfoFromGroupName(
  groupName: DefaultCompanyGroups
): string {
  switch (groupName) {
    case DefaultCompanyGroups.ADMINISTRATOR: {
      return "This user has full account access. It is typically the owner or business development manager. You must have at least one administrator.";
    }
    case DefaultCompanyGroups.TEAM_MEMBER: {
      return "Additional operations team members who will access TradeEngage, but will not be authorized to approve jobs or modify the Referral Matrix.";
    }
    case DefaultCompanyGroups.FIELD_TEAM: {
      return "The Field Team is anyone who will be submitting jobs and referral opportunities. In most companies, the field team consists of technicians and often sales professionals.";
    }
    case DefaultCompanyGroups.OWNER: {
      return "This is your boss. The business owner or franchisee who will want to hone in on the team’s performance.";
    }
    case DefaultCompanyGroups.JOB_APPROVER: {
      return "Typically a Sales Manager or Customer Service Representative. This person will be responsible for approving jobs referred to you!";
    }
    default: {
      return "This is the role of this user";
    }
  }
}
