import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { AppConstants } from "./constants";

export function setupSentry() {
  if (AppConstants.environment !== "local") {
    Sentry.init({
      dsn: AppConstants.sentryDsn,
      environment: AppConstants.environment,
      integrations: [
        new Sentry.BrowserTracing({
          finalTimeout: 60 * 1000,
          // See docs for support of different versions of variation of react router
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
        new Sentry.Replay(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // TODO
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/api.tradeengage\.com\/api/,
        /^https:\/\/api-staging.tradeengage\.com\/api/,
        /^https:\/\/api-demo.tradeengage\.com\/api/,
      ],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
    window.addEventListener(
      "unhandledrejection",
      (event: PromiseRejectionEvent) => {
        event.promise.catch(error => {
          Sentry.captureException(error);
          console.error("Unhandled promise rejection:", error);
        });
      }
    );
  }
}
