import constate from "constate";
import { DateTime } from "luxon";
import { useState } from "react";

import { JobSectors } from "@/company/jobs/constants";

// Type for the filter functions

// Type for the filters object
export interface InternalAdminFilters {
  sectors?: Array<JobSectors>;
  createdAt?: DateTime;
  nonCompetingCompanies?: boolean;
  franchise?: Array<string>;
  businessAge?: Array<string>;
  relevantSectors?: boolean;
  confirmedInsured?: boolean;
  existingCustomerReferralProgram?: boolean;
  existingReferral?: boolean;
  companyOwnership?: Array<string>;
  ownersNearRetirement?: boolean;
  familyOperatedBusiness?: boolean;
  mutualConnections?: boolean;
  localReferralNetwork?: Array<string>;
  collaborateWithOtherPartners?: boolean;
  tradeengageActivity?: Array<string>;
  betterBusinessBureau?: Array<string>;
  googleRating?: Array<number>;
  numberOfGoogleReviews?: Array<number>;
  historicalLawsuits?: boolean;
  numberOfWebsiteVisitors?: Array<number>;
  numberOfEmployees?: number;
  brickAndMortarLocation?: boolean;
  contactInformation?: Array<string>;
  detailsTechnologyStack?: boolean;
  onlineScheduling?: boolean;
  website?: boolean;
  marketPresence?: Array<string>;
  countOnly?: boolean;
  zipCodes?: Array<string>;
  milesRadius?: number;
  keyword?: string;
  archived?: boolean;
  teamTrainingDate?: DateTime;
}

export const filterKeyToLabel = {
  sectors: "Service Categories",
  createdAt: "Company Creation Date",
  nonCompetingCompanies: "Non-Competing Affiliate Companies ",
  franchise: "Franchise",
  businessAge: "Business Age",
  relevantSectors: "Relevant Sectors",
  confirmedInsured: "Confirmed insured",
  existingCustomerReferralProgram: "Existing customer referral program",
  existingReferral: "Existing referral",
  companyOwnership: "Company Ownership",
  ownersNearRetirement: "Owners within 10 Years of 60 (Retirement Age)",
  familyOperatedBusiness: "Family operated business",
  mutualConnections: "Mutual Connections",
  localReferralNetwork: "Member of Local Referral Network",
  collaborateWithOtherPartners:
    "Signals that They Collaborate with other Partners",
  tradeengageActivity: "TradeEngage Activity",
  betterBusinessBureau: "Better Business Bureau",
  googleRating: "Google Rating",
  numberOfGoogleReviews: "Google Reviews",
  historicalLawsuits: "Historical Lawsuits",
  numberOfWebsiteVisitors: "Website Visitors",
  numberOfEmployees: "Number of Employees",
  brickAndMortarLocation: "Brick & Mortar Location",
  contactInformation: "Contact Information",
  detailsTechnologyStack: "Technology Stack",
  onlineScheduling: "Online Scheduling",
  website: "Website",
  marketPresence: "Marketplace Presence",
  states: "States",
  zipCodes: "Zip Codes",
  milesRadius: "Miles Radius",
  brands: "Brands",
  otherBrands: "Brands of Interest",
  archived: "Archived Companies",
  overlappingDistance: "Overlapping Distance",
};

// Create a custom hook for managing filters with TypeScript
const useInternalCompanySearchFilter = (
  initialFilters: InternalAdminFilters = { zipCodes: [] }
): {
  filters: InternalAdminFilters;
  addFilter: (filter: Partial<InternalAdminFilters>) => void;
  addFilters: (filter: Partial<InternalAdminFilters>) => void;
  removeFilter: (key: string) => void;
  removeAllFilter: () => void;
} => {
  const [filters, setFilters] = useState<InternalAdminFilters>(initialFilters);

  const addFilter = (filter: Partial<InternalAdminFilters>) => {
    setFilters(prevFilters => ({ ...prevFilters, ...filter }));
  };

  const removeFilter = (key: string) => {
    setFilters(prevFilters => {
      const { [key]: _, ...rest } = prevFilters;
      return { ...rest };
    });
  };

  const removeAllFilter = () => {
    setFilters({});
  };
  const addFilters = (filters: Partial<InternalAdminFilters>) => {
    setFilters(filters);
  };

  return {
    filters,
    addFilter,
    addFilters,
    removeFilter,
    removeAllFilter,
  };
};

export const [
  InternalCompanySearchFilterProvider,
  useInternalCompanySearchFilterContext,
] = constate(useInternalCompanySearchFilter);
