import {
  type AutocompleteProps,
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import _ from "lodash";

interface Option {
  label: string;
  value: string;
}

interface AutocompleteFieldProps
  extends Omit<
    AutocompleteProps<Option, boolean, boolean, false>,
    "renderInput"
  > {
  name: string;
  label: string;
  required?: boolean;
}

export function AutocompleteField(props: AutocompleteFieldProps) {
  const {
    name,
    options,
    label,
    multiple,
    disabled = false,
    required = false,
    placeholder,
    ...otherProps
  } = props;
  const [field, meta, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  const getValue = () => {
    if (multiple) {
      return options.filter(option => field.value.includes(option.value)) || [];
    }
    return options.find(option => option.value === field.value) ?? null;
  };

  return (
    <Autocomplete<Option, boolean, boolean, false>
      {...otherProps}
      multiple={multiple}
      options={options?.sort()}
      value={getValue()}
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionKey={option => option.value}
      renderInput={params => (
        <TextField
          {...params}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error ? meta.error : ""}
          placeholder={placeholder}
          label={label}
          required={required}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <>
            <Chip
              size="small"
              label={option.label}
              {...getTagProps({ index })}
              variant="outlined"
            />
          </>
        ))
      }
      disabled={isSubmitting || disabled}
      onChange={(_event, data) => {
        if (_.isArray(data)) {
          helpers.setValue(data?.map(item => item.value) ?? []);
          return;
        }
        helpers.setValue(data?.value ?? "");
      }}
      onBlur={() => helpers.setTouched(true)}
    />
  );
}
