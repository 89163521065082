import { Box, Modal, Typography } from "@mui/material";

// Define the styles for the modal content

export const HtmlModal = ({
  open,
  onClose,
  htmlContent,
}: {
  open: boolean;
  onClose: () => void;
  htmlContent: string;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          padding: 4,
          borderRadius: 2,
        }}
      >
        <Typography
          component="div"
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </Box>
    </Modal>
  );
};
