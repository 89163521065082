import { useQuery, UseQueryResult } from "@tanstack/react-query";

import axiosInstance from "@/common/api";

import { type Job } from "../types";

const GET_HOMEOWNER_JOB_HISTORY = "/homeowner/:id/job-history";

export function useGetJobHistoryById(
  homeOwnerId: number
): UseQueryResult<Array<Job>> {
  const url = GET_HOMEOWNER_JOB_HISTORY.replace(":id", homeOwnerId.toString());

  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      const response = await axiosInstance.get(url);
      return response.data.data;
    },
  });
}
