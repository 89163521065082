import constate from "constate";
import { useCallback, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { isDefined } from "@/utils/isDefined";

import { useGetStrategicPartners } from "../api/useGetStrategicPartners";
import { FilterType, StrategicPartner } from "../types";

interface StrategicPartnersFilter {
  enabled: boolean;
  minValue: number;
}

function useStrategicPartnerFilters() {
  const { session } = useAuth();

  const { data: strategicPartners, isLoading: isLoadingStrategicPartners } =
    useGetStrategicPartners({ familyOfBrandsId: session?.company?.id ?? -1 });

  const [confirmationDialogState, setConfirmationDialogState] = useState<{
    isOpen: boolean;
    isTurningOn: boolean;
    unmetFilter: FilterType | undefined;
  }>({ isOpen: false, isTurningOn: false, unmetFilter: undefined });

  const [selectedStrategicPartner, setSelectedStrategicPartner] =
    useState<StrategicPartner | null>(null);
  const [selectedStrategicPartnerIds, setSelectedStrategicPartnerIds] =
    useState<number[]>([]);

  const [googleRatingsFilter, setGoogleRatingsFilter] =
    useState<StrategicPartnersFilter>({ enabled: false, minValue: 1 });

  const [googleReviewsFilter, setGoogleReviewsFilter] =
    useState<StrategicPartnersFilter>({ enabled: false, minValue: 1 });

  const recalculateSelectedStrategicPartnerIds = useCallback(
    (
      ratingsFilter: StrategicPartnersFilter,
      reviewsFilter: StrategicPartnersFilter
    ) => {
      if (!strategicPartners) return;

      const filteredPartners = strategicPartners.filter(partner => {
        if (
          ratingsFilter.enabled &&
          partner.googleReviewsRating < ratingsFilter.minValue
        ) {
          return false;
        }
        if (
          reviewsFilter.enabled &&
          partner.googleReviewsCount < reviewsFilter.minValue
        ) {
          return false;
        }

        if (!ratingsFilter.enabled && !reviewsFilter.enabled) {
          return false;
        }

        return true;
      });

      setSelectedStrategicPartnerIds(
        filteredPartners.map(partner => partner.company.id)
      );
    },
    [strategicPartners]
  );

  const handleGoogleReviewsFilterChange = useCallback(
    (params: { enabled: boolean; minValue: number }) => {
      const newFilter = { enabled: params.enabled, minValue: params.minValue };
      setGoogleReviewsFilter(prev => ({
        ...prev,
        ...newFilter,
      }));
      recalculateSelectedStrategicPartnerIds(googleRatingsFilter, newFilter);
    },
    [googleRatingsFilter, recalculateSelectedStrategicPartnerIds]
  );

  const handleGoogleRatingsFilterChange = useCallback(
    (params: { enabled: boolean; minValue: number }) => {
      const newFilter = { enabled: params.enabled, minValue: params.minValue };
      setGoogleRatingsFilter(prev => ({
        ...prev,
        ...newFilter,
      }));
      recalculateSelectedStrategicPartnerIds(newFilter, googleReviewsFilter);
    },
    [googleReviewsFilter, recalculateSelectedStrategicPartnerIds]
  );

  const validateFilters = useCallback(
    (
      partner: StrategicPartner,
      isEnabling: boolean
    ): {
      shouldShowDialog: boolean;
      unmetFilter?: FilterType;
    } => {
      const validations = [
        {
          value: partner.googleReviewsRating,
          filter: googleRatingsFilter,
          filterType: FilterType.GOOGLE_RATINGS,
        },
        {
          value: partner.googleReviewsCount,
          filter: googleReviewsFilter,
          filterType: FilterType.GOOGLE_REVIEWS,
        },
      ];

      for (const { value, filter, filterType } of validations) {
        const meetsRequirement = value >= filter.minValue;

        if (filter.enabled) {
          if (isEnabling && !meetsRequirement) {
            return { shouldShowDialog: true, unmetFilter: filterType };
          }
          if (!isEnabling && meetsRequirement) {
            return { shouldShowDialog: true, unmetFilter: filterType };
          }
        }
      }

      return { shouldShowDialog: false };
    },
    [googleRatingsFilter, googleReviewsFilter]
  );

  const handleStrategicPartnerSelection = useCallback(
    (partner: StrategicPartner, checked: boolean) => {
      setSelectedStrategicPartner(partner);

      const { shouldShowDialog, unmetFilter } = validateFilters(
        partner,
        checked
      );

      if (shouldShowDialog) {
        setConfirmationDialogState({
          isOpen: true,
          isTurningOn: checked,
          unmetFilter,
        });
        return;
      }

      setSelectedStrategicPartnerIds(prev =>
        checked
          ? [...prev, partner.company.id]
          : prev.filter(id => id !== partner.company.id)
      );
    },
    [validateFilters]
  );

  const handleConfirmationDialogConfirm = () => {
    if (!confirmationDialogState.isOpen || !isDefined(selectedStrategicPartner))
      return;

    if (confirmationDialogState.isTurningOn) {
      // If the user is turning on a strategic partner that does not meet the Google Reviews or Google Rating filter requirements,
      // set the Google Reviews or Google Ratings filters to disabled.
      setSelectedStrategicPartnerIds(prev => [
        ...prev,
        selectedStrategicPartner.company.id,
      ]);
      if (
        googleReviewsFilter.enabled &&
        selectedStrategicPartner.googleReviewsCount <
          googleReviewsFilter.minValue
      ) {
        setGoogleReviewsFilter(prev => ({
          ...prev,
          enabled: false,
        }));
      }

      if (
        googleRatingsFilter.enabled &&
        selectedStrategicPartner.googleReviewsRating <
          googleRatingsFilter.minValue
      ) {
        setGoogleRatingsFilter(prev => ({
          ...prev,
          enabled: false,
        }));
      }
    } else {
      // If the user is turning off a strategic partner that meets the Google Reviews or Google Rating filter requirements,
      // set the Google Reviews or Google Ratings filters to disabled.
      setSelectedStrategicPartnerIds(prev =>
        prev.filter(id => id !== selectedStrategicPartner.company.id)
      );
      if (
        googleReviewsFilter.enabled &&
        selectedStrategicPartner.googleReviewsCount >=
          googleReviewsFilter.minValue
      ) {
        setGoogleReviewsFilter(prev => ({
          ...prev,
          enabled: false,
        }));
      }

      if (
        googleRatingsFilter.enabled &&
        selectedStrategicPartner.googleReviewsRating >=
          googleRatingsFilter.minValue
      ) {
        setGoogleRatingsFilter(prev => ({
          ...prev,
          enabled: false,
        }));
      }
    }

    setConfirmationDialogState(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogState(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  return {
    isLoadingStrategicPartners,
    strategicPartners,
    handleStrategicPartnerSelection,
    handleConfirmationDialogConfirm,
    handleConfirmationDialogClose,
    confirmationDialogState,
    selectedStrategicPartnerIds,
    selectedStrategicPartner,
    setSelectedStrategicPartner,
    googleRatingsFilter,
    googleReviewsFilter,
    handleGoogleReviewsFilterChange,
    handleGoogleRatingsFilterChange,
  };
}

export const [
  StrategicPartnerFiltersProvider,
  useStrategicPartnerFiltersContext,
] = constate(useStrategicPartnerFilters);
