import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { Job } from "@/company/jobs/types";
import { HomeownerAction } from "@/homeowner/api/useHomeOwnerActOnJob";

export interface HomeownerJobDelayDialogProps {
  job: Job;
  open: boolean;
  isUpdating?: boolean;
  onClose: () => void;
  actOnJob: (data: HomeownerAction) => void;
  isMobile?: boolean;
}

export const HomeownerDelayReasons = [];
export const HomeownerDeclineReasons = [];

export function HomeownerJobDelayDialog(props: HomeownerJobDelayDialogProps) {
  const { open, onClose, actOnJob, job, isUpdating } = props;
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");

  const reset = () => {
    setReason("");
    setOtherReason("");
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <DialogTitle component="div">
        <Typography gutterBottom variant="h5">
          Delay Project?
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => {
          reset();
          onClose();
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pt: 0, px: 3 }}>
        <Typography gutterBottom variant="body2">
          {`This service, `}
          <strong>{job.jobType}</strong>
          {`, will be saved in your HomeHub list until you're ready to get started. You can always get started by clicking 'Submit Request'.`}
        </Typography>
        <Typography variant="body2" sx={{ my: 2 }}>
          Select the reason you delayed this job and it will be stored for your
          future reference.
        </Typography>
        <FormControl disabled={isUpdating}>
          <RadioGroup
            value={reason}
            onChange={e => {
              setOtherReason("");
              setReason(e.target.value);
            }}
          >
            <FormControlLabel
              label={
                <Typography variant={props.isMobile ? "caption" : "body2"}>
                  I have higher priority home service projects
                </Typography>
              }
              control={<Radio />}
              value="I have higher priority home service projects"
            />
            <FormControlLabel
              label={
                <Typography variant={props.isMobile ? "caption" : "body2"}>
                  I don&apos;t have the budget for this project right now.
                </Typography>
              }
              value="I don't have the budget for this project right now"
              control={<Radio />}
            />
            <FormControlLabel
              value="I'm unsure and need more time to decide"
              control={<Radio />}
              label={
                <Typography variant={props.isMobile ? "caption" : "body2"}>
                  I&apos;m unsure and need more time to decide
                </Typography>
              }
            />
            <FormControlLabel
              value="Other"
              control={<Radio />}
              label={
                <Typography variant={props.isMobile ? "caption" : "body2"}>
                  Other
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
        {reason === "Other" ? (
          <TextareaAutosize
            style={{
              marginTop: "16px",
              marginBottom: "16px",
              width: "100%",
              padding: "8px",
              borderRadius: "8px",
              borderColor: "#E5E7EB",
              fontSize: "0.875rem",
              fontWeight: 400,
              lineHeight: 1.57,
              fontFamily:
                '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
              color: " #6C737F",
            }}
            value={otherReason}
            minRows={5}
            placeholder="🗒️ Add any relevant notes you might need later!"
            onChange={e => setOtherReason(e.target.value)}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        {!isUpdating && (
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            No, Cancel
          </Button>
        )}
        <LoadingButton
          loading={isUpdating}
          size="small"
          variant="contained"
          color="primary"
          disabled={
            (reason && reason === "Other" && otherReason === "") || !reason
          }
          onClick={() =>
            actOnJob({
              action: "DELAY",
              delayReason: reason && reason === "Other" ? otherReason : reason,
            })
          }
        >
          Yes, Delay
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
