import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { GoogleCompanyProfileDetails } from "@/common/GoogleCompanyDrawer/GoogleCompanyProfileDetails";
import { USE_GET_ALL_CHATS__GROUPED_URI } from "@/company/api/useFetchCompanyMessagesList";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { ChatProvider } from "@/company/context/chat-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import {
  ConnectionStatus,
  PLATFORM_PRESENCE,
  ReferralInvitation,
  ReferralInvitationActions,
} from "@/company/types";

import { CompanyAvatar } from "../company-avatar/CompanyAvatar";
import { Activity } from "./Activity";
import { Message } from "./Message";
import { Overview } from "./Overview";

interface ReferralInvitationDrawerProps {
  onClose?: () => void;
  open?: boolean;
  invitation: ReferralInvitation;
  connectionStatus: ConnectionStatus;
  defaultTab?: TabsEnum;
}

export enum TabsEnum {
  OVERVIEW = "overview",
  ACTIVITY = "activity",
  MESSAGE = "message",
  GOOGLE_PROFILE = "google-profile",
}

export const ReferralInvitationDrawer: FC<
  ReferralInvitationDrawerProps
> = props => {
  const {
    onClose,
    open = false,
    invitation,
    defaultTab,
    connectionStatus,
    ...other
  } = props;
  const [currentTab, setCurrentTab] = useState<TabsEnum>(
    defaultTab ?? TabsEnum.OVERVIEW
  );

  const queryClient = useQueryClient();

  const [referralAccepted, setReferralAccepted] = useState(false);

  let companyProfile = invitation.otherCompanyProfile;

  const { data: companyProfileV2, isLoading } = useFetchCompanyProfile(
    invitation.otherCompanyProfile.id,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: TabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  const action = (snackbarId: SnackbarKey, customAction?: ReactNode) => (
    <>
      {customAction}
      <CloseIcon
        sx={{ color: "white", cursor: "pointer", mr: 1 }}
        fontSize="small"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      />
    </>
  );

  const { updateReferralInvitation, refetchReferralInvitations } =
    useReferralInvitationContext();
  useEffect(() => {
    if (currentTab === TabsEnum.MESSAGE) {
      queryClient.invalidateQueries({
        queryKey: [USE_GET_ALL_CHATS__GROUPED_URI],
      });
    }
  }, [currentTab, queryClient]);

  const otherCompanyId = companyProfile.id;
  if (!otherCompanyId) return null;

  if (companyProfileV2) {
    companyProfile = companyProfileV2;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          width: "100%",

          background: "white",
          px: 1.5,
          maxWidth: 500,
        },
      }}
      onClose={() => {
        if (referralAccepted) refetchReferralInvitations();
        onClose?.();
      }}
      {...other}
    >
      <Box display={"flex"} flexDirection={"column"} height={"100%"}>
        <Box
          position={"sticky"}
          sx={{ top: 0, zIndex: 400, pt: 1, background: "white" }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            px={3}
            pb={2}
            pt={3}
          >
            <CompanyAvatar
              height={"48px"}
              logo={companyProfile.logoUrl}
              name={companyProfile.name ?? ""}
            />
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={() => {
                if (referralAccepted) refetchReferralInvitations();
                onClose?.();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Tabs sx={{ px: 3 }} value={currentTab} onChange={handleTabsChange}>
            {companyProfile.platformPresence ===
            PLATFORM_PRESENCE.ON_PLATFORM ? (
              <Tab value={TabsEnum.ACTIVITY} label="Activity" />
            ) : null}
            <Tab value={TabsEnum.OVERVIEW} label="Overview" />
            {companyProfile.profile.googlePlacesId ? (
              <Tab value={TabsEnum.GOOGLE_PROFILE} label="Google Reviews" />
            ) : null}
            <Tab value={TabsEnum.MESSAGE} label="Message" />
          </Tabs>
          <Divider />
        </Box>
        <Box p={1.5} pb={0} flex={1} overflow={"auto"}>
          {currentTab === TabsEnum.OVERVIEW ? (
            <Overview companyProfile={companyProfile} />
          ) : null}
          {currentTab === TabsEnum.ACTIVITY ? (
            <Activity otherCompanyProfile={companyProfile} />
          ) : null}
          {currentTab === TabsEnum.MESSAGE ? (
            <ChatProvider companyId={otherCompanyId}>
              <Message
                otherCompanyProfile={companyProfile}
                connectionStatus={connectionStatus}
              />
            </ChatProvider>
          ) : null}
          {currentTab === TabsEnum.GOOGLE_PROFILE ? (
            <GoogleCompanyProfileDetails
              googlePlacesId={companyProfile.profile.googlePlacesId}
              companyName={companyProfile?.name}
              isVerified={
                companyProfile.platformPresence ===
                PLATFORM_PRESENCE.ON_PLATFORM
              }
            />
          ) : null}
        </Box>
      </Box>

      {connectionStatus === ConnectionStatus.INVITED &&
      currentTab !== TabsEnum.GOOGLE_PROFILE &&
      currentTab !== TabsEnum.MESSAGE ? (
        <>
          <Divider />
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            p={3}
            position="sticky"
            bottom={"0"}
            bgcolor={"white"}
            zIndex={1000}
          >
            <Button
              size="small"
              variant="contained"
              onClick={async () => {
                await updateReferralInvitation({
                  invitationId: invitation.id,
                  action: ReferralInvitationActions.ACCEPT,
                  shouldRefresh: false,
                });
                setReferralAccepted(true);
                enqueueSnackbar({
                  message: `${invitation.otherCompanyProfile.name} is now a Referral
                  Partner.`,
                  variant: "success",
                });
                setCurrentTab(TabsEnum.MESSAGE);
              }}
            >
              Accept
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={async () => {
                await updateReferralInvitation({
                  invitationId: invitation.id,
                  action: ReferralInvitationActions.IGNORE,
                });
                enqueueSnackbar({
                  message: `You have ignored the invitation from 
                ${invitation.otherCompanyProfile.name}.`,
                  variant: "success",
                });
                onClose?.();
              }}
            >
              Ignore
            </Button>
          </Stack>
        </>
      ) : null}
    </Drawer>
  );
};
