import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Slider, Typography } from "@mui/material";
import numeral from "numeral";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { CompanyCard } from "@/company/types";
import { neutral } from "@/Theme/colors";

export interface BuyCreditsComponentProps {
  setCreditsToBuy: (value: number) => void;
  creditsToBuy: number;
  isBuyingCredits: boolean;
  onClickBuyCredits: () => void;
  onClose?: () => void;
  card?: CompanyCard;
  isFetchingCard: boolean;
  title?: string;
  subtitle?: string;
}

export function BuyCreditsComponent(props: BuyCreditsComponentProps) {
  const { session } = useAuth();
  const {
    creditsToBuy,
    isBuyingCredits,
    setCreditsToBuy,
    onClickBuyCredits,
    onClose,
    isFetchingCard,
    title,
    subtitle,
  } = props;

  return (
    <Box p={2} width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Typography gutterBottom variant="h6">
            {title ?? "Buy Credits"}
          </Typography>
          <Typography gutterBottom variant="body2" color="textSecondary">
            {subtitle ??
              "Add credits to pay the referral fee to service providers and their technicians when they refer jobs to you."}
          </Typography>
          <Box display="flex" gap="4px">
            <Typography
              gutterBottom
              variant="overline"
              sx={{ color: neutral[400] }}
            >
              RECOMMENDED AMOUNT:
            </Typography>
            <Typography gutterBottom variant="overline" color="textPrimary">
              <strong>$300</strong>
            </Typography>
          </Box>
        </Box>
        {onClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Typography gutterBottom variant="h4" textAlign={"center"}>
        {numeral(creditsToBuy).format("$0,0")}
      </Typography>
      <Box px={2} my={2} textAlign="center">
        <Slider
          size="small"
          valueLabelDisplay="auto"
          valueLabelFormat={value => numeral(value).format("$0,0")}
          getAriaValueText={value => numeral(value).format("$0,0")}
          max={1000}
          min={100}
          step={100}
          color="primary"
          value={creditsToBuy}
          disabled={isBuyingCredits || isFetchingCard}
          onChange={(_, newValue) => setCreditsToBuy(newValue as number)}
        />
      </Box>
      <LoadingButton
        fullWidth
        color="primary"
        variant="contained"
        loading={isBuyingCredits}
        disabled={!session?.groups?.some(isAdministratorOrOwner)}
        onClick={onClickBuyCredits}
      >
        Buy Credits
      </LoadingButton>
    </Box>
  );
}
