import constate from "constate";
import { useEffect, useState } from "react";

import { ChatThread } from "../api/useFetchAdminChatsByBrandLocation";
import { useFetchAdminChatsTeamMember } from "../api/useFetchAdminChatsTeamMember";
import { useUpdateChatAdmin } from "../api/useUpdateChatAdmin";

function useChatDetails() {
  const [chats, setChats] = useState<Array<ChatThread>>([]);

  const {
    data: chatsResponse,
    isLoading,
    refetch,
  } = useFetchAdminChatsTeamMember();

  useEffect(() => {
    if (chatsResponse) {
      setChats([chatsResponse]);
    }
  }, [chatsResponse]);

  const { mutateAsync: updateChat, isLoading: updatingChat } =
    useUpdateChatAdmin();

  useEffect(() => {
    if (chatsResponse) {
      setChats([chatsResponse]);
    }
  }, [chatsResponse]);

  return {
    chats,
    isLoading: isLoading,
    updateChat,
    updatingChat,
    refetch,
  };
}

export const [SupportChatProvider, useSupportChatContext] =
  constate(useChatDetails);
