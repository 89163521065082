import { Box, Chip, Paper, styled, Typography } from "@mui/material";

import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import { snakeCaseJobSectors } from "@/company/utils";

const ThinPaper = styled(Paper)(({ theme }) => ({
  width: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

const JobDetails = ({ selectedJob }: { selectedJob: Job }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Typography variant="overline" color={"text.secondary"}>
        Job Details
      </Typography>
      <Typography variant="subtitle1">Service Category</Typography>
      <Chip
        sx={{
          color: "text.secondary",
          bgcolor: "#F8F9FA",
          width: "fit-content",
          mr: 1,
          mb: 1,
        }}
        label={snakeCaseJobSectors[selectedJob?.sector as JobSectors]}
      />
      <Typography variant="subtitle1">Job Title</Typography>
      <Typography variant="body1" color={"text.secondary"}>
        {selectedJob?.jobType}
      </Typography>
      <Typography variant="subtitle1">Job Summary</Typography>
      <Typography variant="body1" color={"text.secondary"}>
        {selectedJob?.summary}
      </Typography>
    </Box>
  );
};

export default JobDetails;
