import { Add as AddIcon } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  Tab,
  Tabs,
  tabsClasses,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { useBackdropContext } from "@/common/backdrop-context";
import {
  AppConstants,
  InitialWhatsappMessage,
} from "@/common/constants/AppConstants";
import {
  CreateCSRJobDialog,
  CSRJobUploadSource,
} from "@/common/CreateJobDialog/CreateCSRJobDialog";
import CreateTechnicianJobDialogWrapper from "@/common/CreateJobDialog/CreateTechnicianJobDialogWrapper";
import { DashboardTitle } from "@/common/DashboardTitle";
import { useJobUploadContext } from "@/common/job-upload-context";
import { Seo } from "@/common/Seo/Seo";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { canSeeMyJobs } from "@/common/utils/accessControl";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useGetSentJobsByCompany } from "@/company/jobs/api/useGetSentJobs";
import {
  JobDrawer,
  JobDrawerTabsEnum,
} from "@/company/jobs/components/JobDrawer";
import { JobSectors } from "@/company/jobs/constants";
import { Job, JobStatus } from "@/company/jobs/types";
import { JobSource, Source } from "@/company/jobs/types/job";
import { CompanyRoutes, DefaultCompanyGroups } from "@/company/types";
import { FEATURE_FLAGS } from "@/featureFlags";
import { TechnicianJobShareDialog } from "@/homeowner/components/job-share-dialog/TechnicianJobShareDialog";
import { snakeCaseToTitleCase } from "@/utils";

import { TechnicianJobCard } from "../../TechnicianJobCard/TechnicianJobCard";
import { TechnicianSentJobStatusFilterChipLabel } from "../../TechnicianJobCard/utils";
import {
  TechnicianJobFilterDrawer,
  TechnicianJobFilters,
} from "./TechnicianJobFilterDrawer";

export enum TechnicianJobTabs {
  ALL = "ALL",

  PENDING_SELF = "PENDING_SELF",
}

const getFilteredJobs = (
  jobsList?: Job[]
): Record<TechnicianJobTabs, Job[]> => {
  const data: Record<TechnicianJobTabs, Job[]> = {
    [TechnicianJobTabs.ALL]:
      jobsList?.filter(
        job => job.status !== JobStatus.VIDEO_PROCESSING_FAILED
      ) ?? [],

    [TechnicianJobTabs.PENDING_SELF]: [],
  };
  jobsList?.forEach(job => {
    if (job.status === JobStatus.OPPORTUNITY) {
      data[TechnicianJobTabs.PENDING_SELF].push(job);
    }
  });
  return data;
};

export function TechnicianJobDashboardPage() {
  const { session } = useAuth();
  const companyGroups = session?.groups;
  const [searchParams] = useSearchParams();
  const { setJobUploadOpen } = useJobUploadContext();
  const urlJobId = searchParams.get("selectedJobId");
  const { t } = useTranslation();
  const { setBackDropOn } = useBackdropContext();
  const [sentJobsFilters, setSentJobsFilter] = useState<TechnicianJobFilters>(
    {}
  );

  const openJobUploadDialog = () => {
    if (
      companyGroups?.some(
        group =>
          group.name === DefaultCompanyGroups.ADMINISTRATOR ||
          group.name === DefaultCompanyGroups.OWNER ||
          group.name === DefaultCompanyGroups.JOB_APPROVER ||
          group.name === DefaultCompanyGroups.FIELD_TEAM ||
          group.name === DefaultCompanyGroups.TEAM_MEMBER
      )
    ) {
      if (isMobile) {
        setJobUploadOpen(true);
        return;
      }
      setIsJobDialogOpen(true);
      return;
    }
    setIsCSRJobDialogOpen(true);
  };

  const [defaultJobDrawerTab, setDefaultJobDrawerTab] =
    useState<JobDrawerTabsEnum>(JobDrawerTabsEnum.PROJECT);

  const { openJobModal, tab } = useParams();
  const applyJobSentFilters = (filters: TechnicianJobFilters) => {
    setSentJobsFilter({ ...sentJobsFilters, ...filters });
    setFilterDrawerOpen(false);
  };

  const resetJobSentFilters = () => {
    setSentJobsFilter({});
    setFilterDrawerOpen(false);
  };

  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);
  const [isCSRJobDialogOpen, setIsCSRJobDialogOpen] = useState(false);

  const flags = useFlags();
  const {
    data: jobs,
    isLoading,
    refetch: refreshJobs,
    isError,
  } = useGetSentJobsByCompany(
    {
      createdByUserId: session?.user?.id,
      ...sentJobsFilters,
      status: [...(sentJobsFilters.status ? [sentJobsFilters.status] : [])],
    },
    {
      refetchOnWindowFocus: false,
      ...(AppConstants.environment !== "local"
        ? { refetchInterval: 3000 }
        : {}),
      enabled: !!session?.groups?.some(canSeeMyJobs),
    },
    flags[FEATURE_FLAGS.JOBS_JOB_ROUTINGS_V2_ENDPOINT]
  );

  const { enqueueSnackbar } = useSnackbar();

  const [selectedTab, setSelectedTab] = useState<TechnicianJobTabs>(
    TechnicianJobTabs.PENDING_SELF
  );
  const [selectedJobId, setSelectedJobId] = useState<number | null>(
    urlJobId ? parseInt(urlJobId) : null
  );
  const [jobToShare, setJobToShare] = useState<Job | null>(null);

  if (isError) {
    enqueueSnackbar({
      message: "There was an error while fetching your jobs.",
      variant: "error",
    });
  }

  const allSectors = _.uniq(
    jobs?.filter(job => job.sector)?.map(job => job.sector) ?? []
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    if (openJobModal === "open-job-dialog") {
      openJobUploadDialog();
      return;
    }
    if (openJobModal) {
      setSelectedJobId(openJobModal as unknown as number);
      if (tab) {
        setDefaultJobDrawerTab(tab as JobDrawerTabsEnum);
      }
    }
  }, [openJobModal, setIsJobDialogOpen, tab]);

  const filteredJobs = getFilteredJobs(jobs);

  const getJobEmptyComponent = () => {
    if (selectedTab === TechnicianJobTabs.PENDING_SELF) {
      return (
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={2}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                Refer a Job to Earn!
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                Upload a job video to boost your earnings.
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                openJobUploadDialog();
              }}
            >
              Refer a Job
            </Button>
          </Box>
        </Grid>
      );
    }
    if (selectedTab === TechnicianJobTabs.PENDING) {
      return (
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={2}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                Refer a Job to Earn!
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                You don’t have any jobs pending review. Upload a job video to
                boost your earnings.
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                openJobUploadDialog();
              }}
            >
              Refer a Job
            </Button>
          </Box>
        </Grid>
      );
    }
    if (selectedTab === TechnicianJobTabs.EARNED) {
      return (
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={2}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                Refer a Job to Earn!
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                Upload a job video to boost your earnings.
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                openJobUploadDialog();
              }}
            >
              Refer a Job
            </Button>
          </Box>
        </Grid>
      );
    }
    if (selectedTab === TechnicianJobTabs.DECLINED) {
      return (
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={2}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                Refer a Job to Earn!
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                Upload a job video to boost your earnings.
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                openJobUploadDialog();
              }}
            >
              Refer a Job
            </Button>
          </Box>
        </Grid>
      );
    }
    if (selectedTab === TechnicianJobTabs.DELAYED) {
      return (
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={2}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                Refer a Job to Earn!
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                Upload a job video to boost your earnings.
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                openJobUploadDialog();
              }}
            >
              Refer a Job
            </Button>
          </Box>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} sx={{ mt: 5 }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"100%"}
          gap={3}
          my={2}
          flex={1}
        >
          <img
            src="/empty-icon.png"
            style={{ width: "132px", height: "128px" }}
            alt="no pending jobs thumbnail"
          />
          <Box textAlign={"center"}>
            <Typography gutterBottom variant="h5">
              Refer a Job to Earn!
            </Typography>
            <Typography variant="body1" color={"text.secondary"}>
              You don’t have any jobs pending review. Upload a job video to
              boost your earnings.
            </Typography>
          </Box>
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              openJobUploadDialog();
            }}
          >
            Refer a Job
          </Button>
        </Box>
      </Grid>
    );
  };

  const getFilterChips = () => {
    const chips = [] as Array<ReactElement>;
    Object.keys(sentJobsFilters).map(filter => {
      if (filter === "sectors") {
        sentJobsFilters?.sectors?.map(sector =>
          chips.push(
            <Chip
              key={sector}
              label={sector}
              sx={{ mr: 1 }}
              color="primary"
              variant="outlined"
              size="small"
              onDelete={() => {
                const newSectors = sentJobsFilters.sectors?.filter(
                  drawerSector => drawerSector !== sector
                );
                setSentJobsFilter({
                  ...sentJobsFilters,
                  sectors: newSectors,
                });
              }}
            />
          )
        );
      } else {
        if (sentJobsFilters[filter]) {
          chips.push(
            <Chip
              key={
                filter === "dateRange"
                  ? sentJobsFilters[filter]?.startDate?.toLocaleString()
                  : snakeCaseToTitleCase(
                      TechnicianSentJobStatusFilterChipLabel[
                        sentJobsFilters[filter]
                      ]
                    )
              }
              label={
                filter === "dateRange"
                  ? `${sentJobsFilters[
                      filter
                    ]?.startDate?.toLocaleString()}-${sentJobsFilters[
                      filter
                    ]?.endDate?.toLocaleString()}`
                  : snakeCaseToTitleCase(
                      TechnicianSentJobStatusFilterChipLabel[
                        sentJobsFilters[filter]
                      ]
                    )
              }
              color="primary"
              variant="outlined"
              sx={{ mr: 1 }}
              size="small"
              onDelete={() => {
                setSentJobsFilter({
                  ...sentJobsFilters,
                  [filter]: undefined,
                });
              }}
            />
          );
        }
      }
    });
    return chips;
  };

  const filteredChips = getFilterChips();

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  return (
    <>
      <Seo title={t("jobs_dashboard")} />
      <Box
        sx={{
          minHeight: "calc(100vh - 60px)",
          pt: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            top: "56px",
            pt: 1,
          }}
          position={"sticky"}
          display={"flex"}
          bgcolor={"white"}
          zIndex={1200}
          flexDirection={"column"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"start"}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={isMobile ? "column" : "row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <DashboardTitle
              title={t("my_jobs")}
              subtitle={t("my_jobs_subtitle")}
            />
            <Grid
              item
              xs={isMobile ? 12 : 4}
              lg={2}
              display="flex"
              {...(isMobile ? { justifyContent: "start", width: "100%" } : {})}
            >
              <Button
                color="primary"
                size={isMobile ? "small" : "medium"}
                startIcon={<FilterAltOutlinedIcon />}
                sx={{
                  ...(isMobile ? { p: 0, py: 0.3 } : {}),
                  verticalAlign: "middle",
                }}
                onClick={() => setFilterDrawerOpen(true)}
              >
                Add Filters
              </Button>
            </Grid>
          </Box>
          {filteredChips.length ? (
            <Grid item xs={12} width={"100%"}>
              {filteredChips?.length ? (
                <Grid item xs={12} lg={12} width={"100%"}>
                  <Box
                    display={"flex"}
                    width={"100%"}
                    justifyContent={"space-between"}
                    flexDirection={isMobile ? "column" : "row"}
                    alignItems={"center"}
                    py={1}
                    pr={3}
                  >
                    <Box
                      display={"flex"}
                      flexWrap={"wrap"}
                      gap={1}
                      sx={{
                        ...(isMobile ? { width: "100%" } : { width: "100%" }),
                        flexWrap: isMobile ? "none" : "wrap",
                        overflow: isMobile ? "auto" : "none",
                      }}
                    >
                      {filteredChips}
                    </Box>
                    <Button
                      color="error"
                      variant="text"
                      sx={{
                        flex: "auto",
                        minWidth: isMobile ? "none" : "200px",
                      }}
                      startIcon={<CloseOutlinedIcon />}
                      onClick={() => {
                        setSentJobsFilter({});
                      }}
                    >
                      Clear All Filters
                    </Button>
                  </Box>
                  <Typography fontWeight={600}>
                    {jobs?.length
                      ? `${jobs?.length} Results found`
                      : "Fetching Filtered Jobs"}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Tabs
                {...(isMobile
                  ? {
                      TabScrollButtonProps: { sx: { width: "20px" } },
                      sx: {
                        [`& .${tabsClasses.scrollButtons}`]: {
                          "&.Mui-disabled": { opacity: 0.3 },
                        },
                      },
                    }
                  : {})}
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
              >
                <Tab
                  label={`${t("pending_submission")} (${
                    filteredJobs[TechnicianJobTabs.PENDING_SELF].length
                  })`}
                  value={TechnicianJobTabs.PENDING_SELF}
                />
                <Tab
                  label={`${t("all")} (${
                    filteredJobs[TechnicianJobTabs.ALL].length
                  })`}
                  value={TechnicianJobTabs.ALL}
                />
              </Tabs>
              <Divider sx={{ width: "100%" }} />
            </Grid>
          )}
        </Box>
        {isLoading ? (
          <Box mt={10} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container>
            <Grid container item xs={12}>
              {!filteredChips?.length ? (
                <>
                  {filteredJobs[selectedTab] &&
                  filteredJobs[selectedTab].length > 0
                    ? filteredJobs[selectedTab].map(job => (
                        <Grid
                          key={job.id}
                          item
                          xs={12}
                          sx={{
                            my: 2,
                          }}
                        >
                          <TechnicianJobCard
                            job={job}
                            onClick={() => {
                              if (job.status === JobStatus.SUBMISSION) {
                                enqueueSnackbar({
                                  message: `Your video is processing. ${
                                    job.source !== JobSource.IN_APP
                                      ? "You will receive a whatsapp message when your video is ready. Questions? Contact support."
                                      : ""
                                  }`,
                                  variant: "warning",
                                });
                              } else if (
                                job.status === JobStatus.OPPORTUNITY &&
                                job.source !== JobSource.IN_APP
                              ) {
                                window.location.href = InitialWhatsappMessage;
                              } else {
                                setSelectedJobId(job.id);
                              }
                            }}
                            onClickShare={() => setJobToShare(job)}
                          />
                        </Grid>
                      ))
                    : getJobEmptyComponent()}
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Divider sx={{ width: "100%" }} />
                  </Grid>
                  {jobs && jobs.length > 0
                    ? jobs.map(job => (
                        <Grid
                          key={job.id}
                          item
                          xs={12}
                          sx={{
                            my: 2,
                          }}
                        >
                          <TechnicianJobCard
                            job={job}
                            onClick={() => {
                              if (job.status === JobStatus.SUBMISSION) {
                                enqueueSnackbar({
                                  message: `Your video is processing. ${
                                    job.source !== JobSource.IN_APP
                                      ? "You will receive a whatsapp message when your video is ready. Questions? Contact support."
                                      : ""
                                  }`,
                                  variant: "warning",
                                });
                              } else if (
                                job.status === JobStatus.OPPORTUNITY &&
                                job.source !== JobSource.IN_APP
                              ) {
                                window.location.href = InitialWhatsappMessage;
                              } else {
                                setSelectedJobId(job.id);
                              }
                            }}
                            onClickShare={() => setJobToShare(job)}
                          />
                        </Grid>
                      ))
                    : getJobEmptyComponent()}
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      {isMobile ? null : (
        <Box
          position="fixed"
          // bottom={"12px"}
          // right={"100px"}
          bottom={"40px"}
          right={"48px"}
        >
          {/* <Backdrop open={jobUploadOpen} />
          <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%, -32%)",
            }}
            icon={<SpeedDialIcon />}
            open={jobUploadOpen}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            {speedDialActions.map((action, index) => (
              <SpeedDialAction
                key={action.name}
                tooltipOpen
                icon={action.icon}
                tooltipTitle={action.name} // Apply custom tooltip class
                classes={{ staticTooltipLabel: "custom-tooltip" }}
                tooltipPlacement="left"
                onClick={
                  index === 1
                    ? () => (window.location.href = InitialWhatsappMessage)
                    : () => {
                        handleClose();
                        setIsJobDialogOpen(true);
                      }
                }
              />
            ))}
          </SpeedDial> */}
          <ToolTip placement="bottom" message="Upload a Job">
            <Fab
              color="primary"
              aria-label="add-invite"
              onClick={() => {
                openJobUploadDialog();
              }}
            >
              <AddIcon />
            </Fab>
          </ToolTip>
          {isJobDialogOpen ? (
            <CreateTechnicianJobDialogWrapper
              isOpen={isJobDialogOpen}
              jobsListUrl={getNavigationPathFromRoutePaths([
                CompanyRoutes.BASE,
                CompanyRoutes.MY_JOBS,
              ])}
              onClose={() => {
                setIsJobDialogOpen(false);
              }}
            />
          ) : null}
        </Box>
      )}
      {filterDrawerOpen ? (
        <TechnicianJobFilterDrawer
          allSectors={allSectors as Array<JobSectors>}
          open={filterDrawerOpen}
          applyFilters={applyJobSentFilters}
          resetFilters={resetJobSentFilters}
          drawerFilters={sentJobsFilters}
          onClose={() => {
            setFilterDrawerOpen(false);
          }}
        />
      ) : null}
      {selectedJobId && (
        <JobDrawer
          open={Boolean(selectedJobId)}
          source={Source.TECHNICIAN_DASHBOARD}
          defaultTab={defaultJobDrawerTab ?? JobDrawerTabsEnum.PROJECT}
          jobId={selectedJobId}
          refreshJobs={refreshJobs}
          onClose={() => setSelectedJobId(null)}
        />
      )}

      {isCSRJobDialogOpen ? (
        <CreateCSRJobDialog
          isOpen={isCSRJobDialogOpen}
          jobsListUrl={getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.MY_JOBS,
          ])}
          source={CSRJobUploadSource.JOBS_DASHBOARD}
          onClose={() => setIsCSRJobDialogOpen(false)}
        />
      ) : null}
      {jobToShare && (
        <TechnicianJobShareDialog
          job={jobToShare}
          isOpen={Boolean(jobToShare)}
          onClose={() => {
            setJobToShare(null);
          }}
        />
      )}
    </>
  );
}
