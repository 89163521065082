import { PayReferralFeeSettings } from "../types";
import { JobPromotion } from "./types";

export function getReferralFeeText(jobPromotion: JobPromotion): string {
  if (
    jobPromotion.payReferralFeeSettings === PayReferralFeeSettings.FIXED_AMOUNT
  ) {
    return `${jobPromotion.referralSalePrice}`;
  }

  if (
    jobPromotion.payReferralFeeSettings ===
    PayReferralFeeSettings.DEPENDING_ON_JOB_AND_REFERRAL_PARTNER
  ) {
    return "Varies";
  }

  return `${jobPromotion.referralPercentageFee}%`;
}
