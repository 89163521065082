import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel_company_selection.css"; // Create a CSS file for custom styling

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import { useAuth } from "@/auth/context/jwt";
import { getDefaultLoginRedirectTo } from "@/auth/utils";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import { useQueryAuthenticateCompanies } from "@/company/api/useQueryAuthenticateCompanies";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { Company } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

export function CompanySelectionPage({
  changeWorkspaceInApp,
  onChange,
}: {
  changeWorkspaceInApp?: boolean;
  onChange?: () => void;
}) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [redirecting, setRedirecting] = useState(false);
  const [activeCompany, setActiveCompany] = useState<Company>();
  const { session, signInToCompany, refreshSession, loginRequestData } =
    useAuth();
  const company = session?.company;

  const { isFetching: fetchingCompaniesWithGroup, data: companiesWithGroup } =
    useQueryAuthenticateCompanies();

  const searchParams = new URLSearchParams(
    loginRequestData?.redirectTo?.split("?")[1]
  );

  const companyId = searchParams ? searchParams.get("companyId") : null;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      companiesWithGroup && companiesWithGroup?.length < 4
        ? companiesWithGroup?.length
        : 3,
    slidesToScroll: 1,
    dotsClass: "custom_button__bar",
    nextArrow: (
      <KeyboardArrowRightIcon
        sx={{ color: "gray", "&:hover": { color: "gray" } }}
      />
    ),
    prevArrow: (
      <KeyboardArrowLeftIcon
        sx={{ color: "gray", "&:hover": { color: "gray" } }}
      />
    ),
  };
  const navigate = useNavigate();

  const handleCompanySelection = async (companyId?: number) => {
    try {
      setRedirecting(true);

      await signInToCompany({
        companyId: companyId ? companyId : activeCompany?.id,
      });
      refreshSession();
      if (changeWorkspaceInApp && onChange) {
        const urlWithoutParams =
          window.location.origin + window.location.pathname;
        // Update the browser's URL and reload the page
        window.history.replaceState(null, "", urlWithoutParams);
        // Reload the page
        window.location.reload();
        onChange();
        return;
      }
      if (
        loginRequestData?.redirectTo === "null" ||
        loginRequestData?.redirectTo?.startsWith("null")
      ) {
        navigate(getDefaultLoginRedirectTo(session));
        setRedirecting(false);
        return;
      }

      navigate(
        loginRequestData?.redirectTo ?? getDefaultLoginRedirectTo(session)
      );
      setRedirecting(false);
      return;
    } catch (error) {
      setRedirecting(false);
      enqueueSnackbar({
        message: `There was an error while signing in to company.`,
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (company && companiesWithGroup?.length) {
      if (
        companiesWithGroup?.some(
          ({ company: otherCompany }) => otherCompany?.id === company.id
        )
      ) {
        setActiveCompany(company);
      }
    }
  }, [company, companiesWithGroup]);

  useEffect(() => {
    if (
      companiesWithGroup?.length &&
      companyId &&
      Number(companyId) &&
      !changeWorkspaceInApp
    ) {
      if (
        companiesWithGroup?.some(
          ({ company: otherCompany }) => otherCompany?.id == companyId
        )
      ) {
        handleCompanySelection(companyId);
      }
    }
  }, [companyId, companiesWithGroup]);

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={isMobile ? "start" : "center"}
      alignItems={"center"}
      px={changeWorkspaceInApp ? 4 : 2}
      py={changeWorkspaceInApp ? 0 : 2}
      {...(isMobile
        ? {}
        : {
            height: changeWorkspaceInApp ? "70%" : "100%",
            minHeight: changeWorkspaceInApp
              ? "calc(100vh - 250px)"
              : "calc(100vh - 205px)",
          })}
      overflow={"auto"}
    >
      {fetchingCompaniesWithGroup ? (
        <Box py={3} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            maxWidth={isMobile ? "100%" : "948px"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            {...(isMobile ? { px: 0 } : {})}
            gap={3}
          >
            <Box
              display={"flex"}
              top={"16px"}
              left={"16px"}
              pb={2}
              pt={0}
              flexDirection={"column"}
              gap={1}
              sx={{
                width: isMobile
                  ? "100%"
                  : companiesWithGroup && companiesWithGroup?.length < 4
                  ? `${companiesWithGroup?.length * 320}px !important`
                  : "100%",
              }}
              alignItems={"start"}
            >
              {!changeWorkspaceInApp ? (
                <TradeEngageLogo
                  logotype={TradeEngageLogoTypes.LOGO_DARK}
                  mb={1}
                />
              ) : null}
              {changeWorkspaceInApp && isMobile ? null : (
                <Typography variant="h5" pl={changeWorkspaceInApp ? 2 : 0}>
                  Which workspace do you want to access?
                </Typography>
              )}

              {companiesWithGroup?.length ? (
                isMobile ? (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    sx={{
                      width: "100%",
                      height: changeWorkspaceInApp ? "80vh" : "75vh",
                      overflow: "auto",
                      py: 2,
                      px: 1,
                    }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={2}
                      sx={{
                        width: "100%",

                        overflow: "visible",
                      }}
                    >
                      {companiesWithGroup?.map(
                        ({ company, companySectors }, index) => (
                          <Card
                            key={company.id}
                            sx={{
                              maxwidth: "400px",
                              ...(index === companiesWithGroup?.length - 1
                                ? { mb: 5 }
                                : {}),
                              p: 2,
                              px: 2,
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                              width: "100%",

                              boxShadow: "0px 0px 0px 2px #F2F4F7",
                              "&:active": {
                                border: "1px solid #071D2F",
                              },
                              "&:focus": {
                                border: "1px solid #071D2F",
                              },
                              cursor: "pointer",
                              "&:hover": {
                                boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
                              },
                              ...(activeCompany?.id == company?.id
                                ? { border: "1px solid #071D2F" }
                                : {}),
                            }}
                            onClick={() => {
                              setActiveCompany(company);
                            }}
                          >
                            <Box
                              sx={{ display: "flex" }}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              pb={1}
                            >
                              <CompanyAvatar
                                height={"40px"}
                                maxWidth={"160px"}
                                logo={company?.logoUrl}
                                name={company?.name || ""}
                              />
                            </Box>
                            <CardContent sx={{ p: "0 !important" }}>
                              <Box
                                display={"flex"}
                                gap={1}
                                mt={1}
                                flexWrap={"wrap"}
                              >
                                {companySectors?.length
                                  ? companySectors.map(({ sector }) => (
                                      <Chip
                                        key={sector}
                                        sx={{
                                          color: "text.secondary",
                                          bgcolor: "#F8F9FA",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          lineHeight: "20px",
                                          letterSpacing: 0.14,
                                          wordWrap: "break-word",
                                        }}
                                        label={
                                          snakeCaseJobSectors[
                                            sector as JobSectors
                                          ] ??
                                          snakeCaseJobSectors[JobSectors.OTHER]
                                        }
                                        size="small"
                                      />
                                    ))
                                  : null}
                              </Box>
                              <Grid container pt={2}>
                                <Grid
                                  item
                                  xs={12}
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <span>
                                    <Typography display="inline" variant="h6">
                                      {company?.name}{" "}
                                    </Typography>
                                  </span>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        )
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    className="slider-container"
                    sx={{
                      width:
                        companiesWithGroup?.length < 4
                          ? `${companiesWithGroup?.length * 320}px !important`
                          : "100%",

                      mb: "32px",
                    }}
                  >
                    <Slider {...settings}>
                      {companiesWithGroup?.map(
                        ({ company, companySectors }) => (
                          <Card
                            key={company.id}
                            sx={{
                              p: 2,

                              minHeight: "200px",
                              display: "flex",
                              flexWrap: "wrap",
                              width: "300px !important",
                              boxShadow: "0px 0px 0px 1px #F2F4F7",
                              "&:active": {
                                border: "1px solid #071D2F",
                              },
                              "&:focus": {
                                border: "1px solid #071D2F",
                              },
                              cursor: "pointer",
                              "&:hover": {
                                boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
                              },
                              ...(activeCompany?.id == company?.id
                                ? { border: "1px solid #071D2F" }
                                : {}),
                            }}
                            onClick={() => {
                              setActiveCompany(company);
                            }}
                          >
                            <Box
                              sx={{ display: "flex" }}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              pb={1}
                            >
                              <CompanyAvatar
                                height={"40px"}
                                maxWidth={"160px"}
                                logo={company?.logoUrl}
                                name={company?.name || ""}
                              />
                            </Box>
                            <CardContent sx={{ p: "0 !important" }}>
                              <Box
                                display={"flex"}
                                gap={1}
                                mt={1}
                                flexWrap={"wrap"}
                              >
                                {companySectors?.length
                                  ? companySectors.map(({ sector }) => (
                                      <Chip
                                        key={sector}
                                        sx={{
                                          color: "text.secondary",
                                          bgcolor: "#F8F9FA",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          lineHeight: "20px",
                                          letterSpacing: 0.14,
                                          wordWrap: "break-word",
                                        }}
                                        label={
                                          snakeCaseJobSectors[
                                            sector as JobSectors
                                          ] ??
                                          snakeCaseJobSectors[JobSectors.OTHER]
                                        }
                                        size="small"
                                      />
                                    ))
                                  : null}
                              </Box>
                              <Grid container pt={2}>
                                <Grid
                                  item
                                  xs={12}
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <span>
                                    <Typography display="inline" variant="h6">
                                      {company?.name}{" "}
                                    </Typography>
                                  </span>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        )
                      )}
                    </Slider>
                  </Box>
                )
              ) : null}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                position: isMobile ? "absolute" : "relative",
                ...(isMobile
                  ? {
                      bottom: "0",
                      position: "absolute",
                      background: "white",
                      p: 2,
                      pb: 3,
                      pt: 0.5,
                    }
                  : {}),
              }}
            >
              {isMobile ? (
                <Divider sx={{ width: "100%", pt: 1, mb: 1 }} />
              ) : null}
              <Button
                color="primary"
                variant="contained"
                sx={{ width: "80%", mr: isMobile ? 0 : 2 }}
                disabled={!activeCompany || redirecting}
                onClick={() => handleCompanySelection()}
              >
                Go to Dashboard
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
