import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Card, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { useAuth } from "@/auth/context/jwt";
import { AppConstants } from "@/common/constants";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyRoutes } from "@/company/types";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import {
  GetStripeLinkPayload,
  StripeLinkType,
  useGetStripeLink,
} from "@/user/api/useGetStripeAccountLink";

export function StripeInfoCard() {
  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const handleIconClick = () => {
    const url = "https://stripe.com/";
    window.open(url, "_blank");
  };

  const { refreshSession } = useAuth();

  const getStripeDashboardLinkPayload: GetStripeLinkPayload = {
    linkType: StripeLinkType.STRIPE_DASHBOARD,
    returnUrl:
      AppConstants.platformBaseUrl +
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.MY_WALLET,
      ]),
    refreshUrl: window.location.href,
  };

  const { isLoading: isFetchingLink, mutateAsync: getStripeLink } =
    useGetStripeLink({
      onError: () => {
        enqueueSnackbar(
          "There was an error while fetching your stripe account link.",
          { variant: "error" }
        );
      },
      onSuccess: () => {
        refreshSession();
      },
    });

  return (
    <Card
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        bgcolor: "white",
        boxShadow: "0px 0px 0px 1px #F2F4F7",
        border: "0px solid #F3F4F6",
        background: "#F8F9FA",
        width: "100%",
      }}
    >
      <Box>
        <Typography variant="h6">How Will Payouts be Processed?</Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={400}
          variant="body2"
          color="text.secondary"
        >
          We partner with Stripe for secure financial services.
        </Typography>
      </Box>
      <Card
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          bgcolor: "rgba(22, 219, 101, 0.10)",
          boxShadow: "0px 0px 0px 1px #F2F4F7",
          borderRadius: "8px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          gap={2}
          sx={{ width: "100%" }}
        >
          <img src="/icon.svg" alt="te-icon" width="32px" height="32px" />
          <SwapHorizOutlinedIcon
            sx={{
              color: "#11192761",
              width: "20px",
              height: "20px",
            }}
          />
          <img
            src="/stripe-icon.svg"
            alt="stripe"
            width="32px"
            height="32px"
            style={{ marginLeft: "4px" }}
          />
        </Box>
      </Card>
      <LoadingButton
        loading={isFetchingLink}
        disabled={isFetchingLink}
        variant="contained"
        onClick={async () => {
          const link = await getStripeLink(getStripeDashboardLinkPayload);
          if (link) {
            window.open(link, "_blank");
          }
        }}
      >
        My Stripe Dashboard
      </LoadingButton>
      <Typography
        fontSize={"14px"}
        fontWeight={400}
        variant="body2"
        color="text.secondary"
      >
        Deposits take 1-2 days depending on your bank! Questions?{" "}
        <Typography
          fontSize={"14px"}
          fontWeight={700}
          variant="body2"
          color="#16B364"
          sx={{ cursor: "pointer" }}
          display={"inline"}
          onClick={() => {
            setContactSupportModalOpen(true);
          }}
        >
          Contact support
        </Typography>{" "}
        or visit{" "}
        <Typography
          fontSize={"14px"}
          fontWeight={700}
          variant="body2"
          color="#16B364"
          sx={{ cursor: "pointer" }}
          display={"inline"}
          onClick={() => {
            handleIconClick();
          }}
        >
          Stripe.com
        </Typography>
        .
      </Typography>
    </Card>
  );
}
