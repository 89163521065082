import { Divider, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";

import { useAuth } from "@/auth/context/jwt";
import { Chat } from "@/company/api/useFetchChats";
import { Company } from "@/company/types";
import { ChatDate, isTypeDate } from "@/utils";

import { ChatMessage } from "./ChatMessage";
import type { Participant } from "./types";

const insertDates = (chats: Array<Chat>): Array<Chat | ChatDate> => {
  let currentDate: string | null = null;
  const formattedChats: Array<Chat | ChatDate> = [];
  chats.forEach(chat => {
    const chatDate = DateTime.fromFormat(
      chat.createdAt.toString().split(".")[0],
      "yyyy-MM-dd HH:mm:ss",
      { zone: "utc" }
    ).toFormat("LLL dd");

    if (chatDate !== currentDate) {
      const today = DateTime.now().toLocal().toFormat("LLL dd yyyy");
      const date = DateTime.fromFormat(
        chat.createdAt.toString().split(".")[0],
        "yyyy-MM-dd HH:mm:ss",
        { zone: "utc" }
      )
        .toLocal()
        .toFormat("LLL dd yyyy");
      formattedChats.push({
        date: chatDate,
        isDate: true,
        ...(date === today ? { isToday: true } : {}),
      });
      currentDate = chatDate;
    }

    formattedChats.push(chat);
  });

  return formattedChats;
};

const insertAdminDates = (chats: Array<Chat>): Array<Chat | ChatDate> => {
  let currentDate: string | null = null;
  const formattedChats: Array<Chat | ChatDate> = [];

  chats.forEach(chat => {
    const chatDate = DateTime.fromFormat(
      chat.createdAt.toString().split(".")[0].split("T").join(),
      "yyyy-MM-dd HH:mm:ss",
      { zone: "utc" }
    ).toFormat("LLL dd");

    if (chatDate !== currentDate) {
      const today = DateTime.now().toLocal().toFormat("LLL dd yyyy");
      const date = DateTime.fromFormat(
        chat.createdAt.toString().split(".")[0].split("T").join(),
        "yyyy-MM-dd HH:mm:ss",
        { zone: "utc" }
      )
        .toLocal()
        .toFormat("LLL dd yyyy");
      formattedChats.push({
        date: chatDate,
        isDate: true,
        ...(date === today ? { isToday: true } : {}),
      });
      currentDate = chatDate;
    }

    formattedChats.push(chat);
  });

  return formattedChats;
};

const getAuthor = (
  message: Chat,
  participants: Participant[],
  company: Company
) => {
  if (message.fromCompanyId === company?.id) {
    return {
      name: company.name ?? "Me",
      avatar: company.logoUrl,
      isUser: true,
    };
  }

  return {
    avatar: participants[0].avatar,
    name: participants[0].name,
    isUser: false,
  };
};

interface ChatMessagesProps {
  initialChatAlert?: ReactNode;
  chats?: Chat[];
  participants?: Participant[];
  isAdmin?: boolean;
}

export const ChatMessages: FC<ChatMessagesProps> = props => {
  const {
    chats = [],
    participants = [],
    initialChatAlert,
    isAdmin,
    ...other
  } = props;

  const { session } = useAuth();
  const company = session?.company;

  if (!company) return null;

  const chatsWithDates = isAdmin ? insertAdminDates(chats) : insertDates(chats);

  return (
    <Stack spacing={2} sx={{ p: 3 }} {...other}>
      {initialChatAlert}
      {chatsWithDates.map(chat => {
        if (isTypeDate(chat)) {
          if (chat.isToday)
            return (
              <Divider sx={{ width: "100%" }}>
                <Typography variant="overline" color="text.secondary">
                  Today
                </Typography>
              </Divider>
            );
          return (
            <Divider sx={{ width: "100%" }}>
              <Typography variant="overline" color="text.secondary">
                {chat.date}
              </Typography>
            </Divider>
          );
        }

        const author = getAuthor(chat, participants, company);

        return (
          <ChatMessage
            key={chat.id}
            authorAvatar={author.avatar}
            authorName={author.name}
            message={chat.message}
            createdAt={DateTime.fromFormat(
              chat.createdAt.toString().split(".")[0],
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )}
            position={author.isUser ? "right" : "left"}
          />
        );
      })}
    </Stack>
  );
};

ChatMessages.propTypes = {
  chats: PropTypes.array,
  participants: PropTypes.array,
};
