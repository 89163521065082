import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { DateTime } from "luxon";

import API from "@/common/api";

import { Company } from "../types";
import { Chat } from "./useFetchChats";

export interface ReferralInvitationInternalAdmin {
  id: number;
  invitingCompanyId: number;
  invitedCompanyId: number;
  createdByUserId: number;
  createdAt: DateTime;
  declinedAt?: DateTime;
  acceptedAt?: DateTime;
  chat?: Chat;
  invitedCompany: Company;
  invitingCompany: Company;
  declinedReason?: string;
  numberOfInvitationsReceived?: number;
}

export function useQueryReferralInvitationsInternalAdmin(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<ReferralInvitationInternalAdmin>> {
  const uri = "/internal-admin/referral-invitations";

  return useQuery({
    queryKey: [uri.toString()],
    queryFn: async () => {
      const response = await API.get(uri);
      const camelCaseResponse = response.data.data;
      return camelCaseResponse;
    },
    ...options,
  });
}
