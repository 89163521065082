import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { isDefined } from "@/utils/isDefined";

import { ReferralCampaign, ReferralCampaignStatus } from "../types";

interface UpdateReferralCampaignRequest
  extends Partial<
    Omit<ReferralCampaign, "id" | "status" | "createdAt" | "updatedAt">
  > {
  referralCampaignId: number;
  status?: ReferralCampaignStatus;
}

export function useUpdateReferralCampaign(
  options: UseMutationOptions<
    void,
    AxiosError,
    UpdateReferralCampaignRequest
  > = {}
): UseMutationResult<void, AxiosError, UpdateReferralCampaignRequest> {
  return useMutation({
    mutationFn: async ({ referralCampaignId, ...data }): Promise<void> => {
      const storageKey = "referral-campaigns";
      const referralCampaigns = localStorage.getItem(storageKey);
      if (isDefined(referralCampaigns)) {
        const parsedReferralCampaigns = JSON.parse(referralCampaigns);
        const updatedReferralCampaigns = parsedReferralCampaigns.map(
          (referralCampaign: ReferralCampaign) => {
            if (referralCampaign.id === referralCampaignId) {
              return { ...referralCampaign, ...data };
            }
            return referralCampaign;
          }
        );
        localStorage.setItem(
          storageKey,
          JSON.stringify(updatedReferralCampaigns)
        );
      }
    },
    ...options,
  });
}
