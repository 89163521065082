import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { ReferralInvitation } from "../types";

export const USE_GET_REFERRAL_INVITATIONS_URI = "/referral-invites";

export function useGetReferralInvitations(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: UseQueryOptions<any>
): UseQueryResult<Array<ReferralInvitation>> {
  return useQuery({
    queryKey: [USE_GET_REFERRAL_INVITATIONS_URI],
    queryFn: async () => {
      const resp = await API.get(USE_GET_REFERRAL_INVITATIONS_URI);
      return resp.data.data;
    },
    ...options,
  });
}
