import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import API from "@/common/api";
import { getS3KeysFromPresignedUrl, keysToSnakeCase } from "@/utils";

export function useUpdateCompanyLogo(
  companyId: number | undefined,
  options: UseMutationOptions<
    void,
    AxiosError,
    { logoFile?: any; isLogoDeleted?: boolean }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { logoFile?: any; isLogoDeleted?: boolean }
> {
  return useMutation({
    mutationFn: async ({ logoFile, isLogoDeleted }) => {
      let companyPayload = {};

      if (logoFile) {
        const imageMineType = logoFile.type.replace("image/", "");
        const UploadUrlResponse = await API.get(
          `/internal-admin/companies/${companyId}/logo-presigned-url?image_mime_type=${imageMineType}`
        );
        const uploadUrl = UploadUrlResponse.data.data;
        const { bucket: fssLogoBucket, objectKey: fssLogoKey } =
          getS3KeysFromPresignedUrl(uploadUrl);
        await axios.put(uploadUrl, logoFile);
        companyPayload.fssLogoBucket = fssLogoBucket;
        companyPayload.fssLogoKey = fssLogoKey;
      }
      if (isLogoDeleted) {
        companyPayload.fssLogoBucket = "";
        companyPayload.fssLogoKey = "";
      }
      companyPayload = keysToSnakeCase(companyPayload);
      const updateCompanyUri = `/internal-admin/companies/brand-location/${companyId}`;
      return API.patch(updateCompanyUri, { company: companyPayload });
    },
    ...options,
  });
}
