import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { Job, JobStatus } from "../types";

const UPDATE_JOB_SECTOR_URL = "/jobs/:id";

export type UpdateJobRequest = {
  jobId: string;
  status: JobStatus;
  summary: string;
};

export type UpdateJobResponse = { job: Job };

export function useUpdateJobSector(
  options: UseMutationOptions<
    Job,
    AxiosError,
    { jobId: number; sector?: string; summary?: string; jobType?: string }
  > = {}
): UseMutationResult<
  Job,
  AxiosError,
  { jobId: number; sector?: string; summary?: string; jobType?: string }
> {
  return useMutation({
    mutationFn: async ({ jobId, sector, summary, jobType }) => {
      const url = UPDATE_JOB_SECTOR_URL.replace(":id", jobId.toString());
      const data = { sector, summary, jobType };
      const response = await API.patch(
        url,
        changeCaseKeys(data, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
