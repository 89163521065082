import { forwardRef, Ref } from "react";
// eslint-disable-next-line import/no-unresolved
import { IMaskInput } from "react-imask";

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const MaskedPhoneInput = forwardRef<HTMLElement, Props>(
  function TextMaskInput(props, ref) {
    const { onChange, name } = props;

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <IMaskInput
        {...props}
        overwrite
        mask="(#00) 000-0000"
        definitions={{
          "#": /[2-9]/,
        }}
        inputRef={ref as unknown as Ref<HTMLInputElement> | undefined}
        pattern="[0-9]*"
        inputMode="numeric"
        onAccept={(value: string) => {
          onChange({ target: { name, value } });
        }}
      />
    );
  }
);
