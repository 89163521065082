import { Box, Typography } from "@mui/material";

import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

const PageNotFoundScreen = () => {
  const { setContactSupportModalOpen } = useContactSupportModalContext();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      textAlign={"center"}
      p={3}
    >
      <img
        src="/maintenance.png"
        style={{ width: "132px", height: "128px" }}
        alt="maintenance thumbnail"
      />
      <Typography gutterBottom variant="h5">
        404 Error
      </Typography>
      <Typography variant="body1" color="text.secondary">
        This page cannot be found.
      </Typography>
      <Typography variant="body1" color="text.secondary" mt={3}>
        If this issue continues, please{" "}
        <Typography
          variant="body1"
          color={"#16B364"}
          fontWeight={600}
          display={"inline"}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => setContactSupportModalOpen(true)}
        >
          contact support.
        </Typography>
      </Typography>
    </Box>
  );
};

export default PageNotFoundScreen;
