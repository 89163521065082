import { Box } from "@mui/material";

import { CreateCSRJobDialog } from "@/common/CreateJobDialog/CreateCSRJobDialog";
import { Seo } from "@/common/Seo/Seo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyRoutes } from "@/company/types";

export function CSRJobPage() {
  return (
    <>
      <Seo title={"csr job creation flow"} />
      <Box
        sx={{
          minHeight: "calc(100vh - 60px)",
          pt: 1,
          display: "flex",
          flexDirection: "column",
        }}
      ></Box>
      <CreateCSRJobDialog
        isOpen={true}
        jobsListUrl={getNavigationPathFromRoutePaths([
          CompanyRoutes.BASE,
          CompanyRoutes.MY_JOBS,
        ])}
      />
    </>
  );
}
