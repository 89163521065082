import { Route } from "react-router-dom";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { TechnicianHomePage } from "@/technician/components/screens/technician-demo/TechnicianHomePage";
import { TechnicianOnboardingSetLanguagePreferencePage } from "@/technician/onboarding/components/screens/set-language-preference/TechnicianOnboardingSetLanguagePreferencePage";

import { CompanyTeamMemberPersonalOnboardingPage } from "../components/screen/team-member-personal-onboarding/CompanyTeamMemberPersonalOnboardingPage";
import {
  COMPANY_TEAM_MEMBER_ONBOARDING_ROUTES,
  ONBOARDING_ROUTES,
} from "../constants";
import { TeamMemberOnboardingLayout } from "./TeamMemberOnboardingLayout";

export function TeamMemberOnboardingRouter() {
  return (
    <Route
      path={ONBOARDING_ROUTES.TEAM_MEMBER_ONBOARDING}
      element={<TeamMemberOnboardingLayout />}
    >
      <Route
        path={COMPANY_TEAM_MEMBER_ONBOARDING_ROUTES.TEAM_MEMBER_ONBOARDING}
        loader={ComponentLoader}
        element={<CompanyTeamMemberPersonalOnboardingPage />}
      />
      <Route
        path={COMPANY_TEAM_MEMBER_ONBOARDING_ROUTES.TECHNICIAN_ONBOARDING}
        loader={ComponentLoader}
        element={<TechnicianOnboardingSetLanguagePreferencePage />}
      />
      <Route
        path={COMPANY_TEAM_MEMBER_ONBOARDING_ROUTES.TECHNICIAN_DEMO}
        loader={ComponentLoader}
        element={<TechnicianHomePage />}
      />
    </Route>
  );
}
