import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

export const USE_GET_CHATS_URI = "/chats";

export interface Chat {
  id: number;
  fromCompanyId: number;
  toCompanyId: number;
  message: string;
  createdAt: string;
  toCompanyReadAt?: string;
}

export function useCompanyShare(
  companyId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<{ referringCompanyId: number; sharingUserId: number }> {
  return useQuery({
    queryKey: [`companies/${companyId}/share`, companyId],
    queryFn: async () => {
      const response = await API.get(`companies/${companyId}/share`);
      return response.data.data;
    },
    ...options,
  });
}
