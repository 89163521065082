import { keysToSnakeCase } from "@/utils";

import { AnalyticsEvents } from "./enums";
import { identifyUserOnHotJar, setupHotJar } from "./hotjar";
import {
  identifyUserOnMixPanel,
  setupMixPanel,
  trackEventOnMixPanel,
} from "./mixpanel";

export function trackEvent(
  eventName: AnalyticsEvents,
  eventProperties: Record<string, unknown>
): void {
  const data = keysToSnakeCase(eventProperties);
  trackEventOnMixPanel(eventName, data);
}

export function setupAppAnalytics(): void {
  setupMixPanel();
  setupHotJar();
}

export function identifyUserForAnalytics(
  userId: number,
  userPropertiesForAnalytics?: Record<string, string | number | boolean | Date>
): void {
  identifyUserOnMixPanel(userId);
  identifyUserOnHotJar(
    userId,
    userPropertiesForAnalytics ? userPropertiesForAnalytics : undefined
  );
}
