import { createContext } from "react";

import type { Settings } from "./types";

export const defaultSettings: Settings = {
  colorPreset: "green",
  contrast: "normal",
  direction: "ltr",
  layout: "vertical",
  navColor: "evident",
  paletteMode: "light",
  responsiveFontSizes: false,
  stretch: false,
};

export interface State extends Settings {
  openDrawer: boolean;
  isInitialized: boolean;
}

export const initialState: State = {
  ...defaultSettings,
  isInitialized: false,
  openDrawer: false,
};

export interface SettingsContextType extends State {
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  handleReset: () => void;
  handleUpdate: (settings: Settings) => void;
  isCustom: boolean;
}

export const SettingsContext = createContext<SettingsContextType>({
  ...initialState,
  handleDrawerClose: () => {
    return;
  },
  handleDrawerOpen: () => {
    return;
  },
  handleReset: () => {
    return;
  },
  handleUpdate: () => {
    return;
  },
  isCustom: false,
});
