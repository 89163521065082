import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { type FC, Fragment } from "react";

import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";

interface ChatMessageProps {
  authorAvatar?: string | null;
  authorName: string;
  message: string;
  createdAt: DateTime;
  position?: "left" | "right";
}

export const ChatMessage: FC<ChatMessageProps> = props => {
  const { authorAvatar, authorName, message, createdAt, position, ...other } =
    props;

  const ago = createdAt.toLocal().toRelative({
    base: DateTime.now().toLocal(),
  });

  const formattedDate = createdAt.toLocal().toFormat("h:mm a");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: position === "right" ? "flex-end" : "flex-start",
      }}
      {...other}
    >
      <Stack
        alignItems="flex-start"
        direction={position === "right" ? "row-reverse" : "row"}
        spacing={2}
        sx={{
          maxWidth: 500,
          ml: position === "right" ? "auto" : 0,
          mr: position === "left" ? "auto" : 0,
        }}
      >
        <CompanyAvatar
          logo={authorAvatar || undefined}
          name={authorName}
          height={"32px"}
          width={"32px"}
          bgColor="white"
          border="1px solid gray !important"
        />
        <Box sx={{ flexGrow: 1 }}>
          <Card
            sx={{
              backgroundColor:
                position === "right" ? "primary.main" : "background.paper",
              color:
                position === "right" ? "primary.contrastText" : "text.primary",
              px: 2,
              py: 1,
            }}
          >
            <Box sx={{ mb: 1 }}>
              <Link
                color="inherit"
                sx={{ cursor: "default", textDecoration: "none!important;" }}
                variant="subtitle2"
              >
                {authorName}
              </Link>
            </Box>
            <Typography
              color="inherit"
              variant="body1"
              sx={{ wordBreak: "break-word" }}
            >
              {message.split("\n").map((line, index) => (
                <Fragment key={index}>
                  {line}
                  <br />
                </Fragment>
              ))}
            </Typography>
          </Card>
          <Box
            sx={{
              display: "flex",
              justifyContent: position === "right" ? "flex-end" : "flex-start",
              mt: 1,
              px: 2,
            }}
          >
            <Typography noWrap color="text.secondary" variant="caption">
              {ago?.startsWith("in 0 seconds") ||
              ago?.startsWith("0 seconds ago")
                ? "Just now"
                : `${formattedDate}`}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

ChatMessage.propTypes = {
  authorAvatar: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  position: PropTypes.oneOf(["left", "right"]),
};
