import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import { User } from "@/auth/types";
import API from "@/common/api";

export function useUpdateCompanyUser(
  options: UseMutationOptions<
    void,
    AxiosError,
    { companyId: number; userId: number; updatedUser: Partial<User> }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { companyId: number; userId: number; updatedUser: Partial<User> }
> {
  return useMutation({
    mutationFn: async ({ companyId, userId, updatedUser }): Promise<void> => {
      const payload = changeCaseKeys(updatedUser, "underscored");

      const response = await API.patch(
        `/internal-admin/companies/brand-location/${companyId}/users/${userId}`,
        payload
      );
      return;
    },
    ...options,
  });
}
