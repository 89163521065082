import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import type { Theme } from "@mui/material/styles/createTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactNode } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { AuthRoutes } from "@/auth/constants";
import { useAuth } from "@/auth/context/jwt";
import { CompanyRoutes } from "@/company/types";
import { getHeaderFromPath } from "@/utils";

import { AccountButton } from "../account-button";
import { useBackdropContext } from "../backdrop-context";
import { TradeEngageLogo, TradeEngageLogoTypes } from "../Logo";
import { getNavigationPathFromRoutePaths } from "../utils/getNavigationPathFromRoutePaths";

export const TOP_NAV_HEIGHT = "56px";

interface TopNavProps {
  showTradeengageLogo?: boolean;
  onMobileNavOpen?: () => void;
  isMarketingPage?: boolean;
  accountButtons: Array<{ label: string; link: string; icon: ReactNode }>;
}

export function TopNav(props: TopNavProps) {
  const { isInitialized, isAuthenticated } = useAuth();
  const { backDropOn } = useBackdropContext();

  const location = useLocation();

  const isPublicPath = location.pathname?.includes("/open");

  const landingPath = "/";
  const adsPage = "/ad";
  // Check if the current path is the landing path
  const isLandingPath = location.pathname === landingPath;
  const isAdsPath = location.pathname.includes(adsPage);

  const isOpenPath = location.pathname.includes("/open");

  const appendSearchParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.toString() ? `?${searchParams.toString()}` : "";
  };

  const { onMobileNavOpen, isMarketingPage } = props;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const isHubPage =
    location.pathname.startsWith("/consultant/home") ||
    location.pathname.startsWith("/company/hubs");

  const header = getHeaderFromPath(location.pathname);

  const [searchParams] = useSearchParams();
  const extension = searchParams.get("chrome-extension");
  const referralInvitationId = searchParams.get("referralInvitationId");
  const jobPublicId = searchParams.get("jobId");
  const invitedCompanyId = searchParams.get("invitedCompanyId");
  const invitationCode = searchParams.get("invitationCode");

  const isReferralInvitation = !!(
    jobPublicId ||
    invitationCode ||
    invitedCompanyId ||
    referralInvitationId
  );

  return (
    <Box
      component="header"
      sx={{
        backgroundColor: theme => theme.palette.background.default,
        position: "sticky",
        top: 0,
        left: 0,
        ...(backDropOn ? {} : { zIndex: theme => theme.zIndex.appBar }),
        width: "100%",
        ...(isMedium &&
        location.pathname.startsWith(
          getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.LEADERBOARD,
          ])
        )
          ? { bgcolor: "#071D2F" }
          : {}),
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent={"space-between"}
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: isMobile ? 2 : 5,
          position: "relative",
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {(!isDesktop || isHubPage) && onMobileNavOpen && extension != "true" && (
            <IconButton sx={{ pl: 0 }} onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          )}
          {props.showTradeengageLogo && (
            <TradeEngageLogo
              logotype={
                isOpenPath
                  ? isDesktop
                    ? TradeEngageLogoTypes.LOGO_LIGHT
                    : TradeEngageLogoTypes.ICON_DARK
                  : TradeEngageLogoTypes.LOGO_DARK
              }
            />
          )}
          {isMedium && header ? (
            header === "Leaderboard" ? (
              <Box display="flex" gap={1} alignItems="center" mb={0.5}>
                <EmojiEventsOutlinedIcon sx={{ color: "white", mt: 0.2 }} />
                <Typography variant="h6" sx={{ color: "white" }}>
                  {header}
                </Typography>
              </Box>
            ) : (
              <Typography variant="h6">{header}</Typography>
            )
          ) : null}
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          {!isInitialized ? null : isAuthenticated ? (
            <>
              <AccountButton edge="end" buttons={props.accountButtons} />
            </>
          ) : (
            <>
              {!isMarketingPage && !isAdsPath ? (
                <Button
                  href={`${getNavigationPathFromRoutePaths([
                    AuthRoutes.BASE,
                    AuthRoutes.SIGN_UP,
                  ])}${appendSearchParams()}`}
                  variant="contained"
                  color="primary"
                >
                  Get Started
                </Button>
              ) : null}
              {(isMarketingPage && isReferralInvitation) || isAdsPath ? null : (
                <Button
                  href={`/${AuthRoutes.BASE}/${
                    AuthRoutes.LOGIN
                  }${appendSearchParams()}`}
                  variant="outlined"
                  color="inherit"
                  sx={{ mr: 2 }}
                >
                  Login
                </Button>
              )}
            </>
          )}
        </Stack>
      </Stack>
      {isPublicPath || isLandingPath ? null : <Divider />}
    </Box>
  );
}
