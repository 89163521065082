import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { Box, Divider, Typography } from "@mui/material";

import CheckboxList from "./CheckboxList";
import { useInternalCompanySearchFilterContext } from "./useInternalCompanySearchFilter";

export default function ReferralNetworkSearchList() {
  const { addFilter, removeFilter, filters } =
    useInternalCompanySearchFilterContext();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
      p={2}
      sx={{
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
        background: "#FFF",
        width: "50%",
      }}
    >
      <Box display={"flex"} gap={2} pb={1}>
        <GroupOutlinedIcon />
        <Typography fontSize={"14px"} fontWeight={600}>
          Referral Network
        </Typography>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box display={"flex"} width={"100%"} flexDirection={"column"} mt={1}>
        <CheckboxList
          parent={"Mutual Connections"}
          parentChecked={!!filters.mutualConnections}
          toggleParentChecked={() => {
            if (filters.mutualConnections) {
              removeFilter("mutualConnections");
            } else {
              addFilter({
                mutualConnections: true,
              });
            }
          }}
        />
        <CheckboxList
          parent={"Member of Local Referral Network"}
          checklist={[
            "National associations & trade groups",
            "National referral networks",
            "Home service roundtables",
            "Single service vs. multi-service",
            "Chambers of commerce",
          ]}
          parentChecked={!!filters.localReferralNetwork}
          toggleParentChecked={() => {
            if (filters.localReferralNetwork) {
              removeFilter("localReferralNetwork");
            } else {
              addFilter({
                localReferralNetwork: [
                  "National associations & trade groups",
                  "National referral networks",
                  "Home service roundtables",
                  "Single service vs. multi-service",
                  "Chambers of commerce",
                ],
              });
            }
          }}
          setSelectedList={list => {
            addFilter({
              localReferralNetwork: list,
            });
          }}
          selectedList={filters.localReferralNetwork}
        />
        <CheckboxList
          parent={"Signals that They Collaborate with other Partners"}
          parentChecked={!!filters.collaborateWithOtherPartners}
          toggleParentChecked={() => {
            if (filters.collaborateWithOtherPartners) {
              removeFilter("collaborateWithOtherPartners");
            } else {
              addFilter({
                collaborateWithOtherPartners: true,
              });
            }
          }}
        />
        <CheckboxList
          parent={"TradeEngage Activity"}
          checklist={["Pays Referral Fee", "TradeEngage User"]}
          parentChecked={!!filters.tradeengageActivity}
          toggleParentChecked={() => {
            if (filters.tradeengageActivity) {
              removeFilter("tradeengageActivity");
            } else {
              addFilter({
                tradeengageActivity: ["Pays Referral Fee", "TradeEngage User"],
              });
            }
          }}
          setSelectedList={list => {
            addFilter({
              tradeengageActivity: list,
            });
          }}
          selectedList={filters.tradeengageActivity}
        />
      </Box>
    </Box>
  );
}
