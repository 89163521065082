import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useUpdateReferralOrder(
  options: UseMutationOptions<
    void,
    AxiosError,
    {
      referralId: number;
      newOrderId: number;
    }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  {
    referralId: number;
    newOrderId: number;
  }
> {
  return useMutation({
    mutationFn: async ({
      referralId,
      newOrderId,
    }: {
      referralId: number;
      newOrderId: number;
    }) => {
      const updateInvitationUri = `/referrals/v2/${referralId}`;
      await API.patch(updateInvitationUri, { order: newOrderId });
    },
    ...options,
  });
}
