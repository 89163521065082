import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

interface CreateTableView {
  name: string;
  tableName: string;
  id?: number;
  description?: string;
  body: Record<string, unknown>;
  columns: Record<string, unknown>;
}

export function useCreateTableView(
  options: UseMutationOptions<CreateTableView, AxiosError, CreateTableView> = {}
): UseMutationResult<CreateTableView, AxiosError, CreateTableView> {
  return useMutation({
    mutationFn: async (payload: CreateTableView): Promise<CreateTableView> => {
      const payloadCamelCase = changeCaseKeys(payload, "underscored");
      const response = await API.post(
        `/internal-admin/table-views`,
        payloadCamelCase
      );
      return response.data.data;
    },
    ...options,
  });
}
