import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useArchiveUser(
  options: UseMutationOptions<
    void,
    AxiosError,
    { companyId: number; userId: number }
  > = {}
): UseMutationResult<void, AxiosError, { companyId: number; userId: number }> {
  return useMutation({
    mutationFn: async ({ companyId, userId }): Promise<void> => {
      const response = await API.delete(
        `/internal-admin/companies/brand-location/${companyId}/users/${userId}`
      );
      return;
    },
    ...options,
  });
}
