import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../../../company/components/screens/dashboard-home/FactsCarousel.css";

import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
// import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import { Box, Button, Card, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

// import { InitialWhatsappMessage } from "@/common/constants/AppConstants";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyRoutes } from "@/company/types";

export const FactsCarousel = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => {
  const navigate = useNavigate();

  const items = [
    {
      id: 1,
      description:
        "Take a video or record a voice memo. Upload it here and we'll route it to a partner so you earn commission!",
      title: "Upload Your Job Videos",
      onClickAction: () =>
        navigate(
          `${getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.MY_JOBS,
          ])}/open-job-dialog`
        ),
      actionButtonText: "Refer a Job",
      icon: (
        <UploadFileIcon
          color="primary"
          fontSize="large"
          sx={{ height: "50px", width: "50px" }}
        />
      ),
    },
    {
      id: 2,
      description:
        "You can add or update your bank account, make withdrawals and view your earnings here.",
      title: "Check Out Your Wallet",
      onClickAction: () =>
        navigate(
          getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.MY_WALLET,
          ])
        ),
      actionButtonText: "View My Wallet",
      icon: (
        <AccountBalanceWalletOutlinedIcon
          color="primary"
          fontSize="large"
          sx={{ height: "50px", width: "50px" }}
        />
      ),
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: "button__bar",
    arrows: false,
  };

  return (
    <Card
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src="/next-tip.svg"
        alt="next=tip"
        width={width ?? "111px"}
        height={height ?? "18px"}
      />
      <Slider {...settings}>
        {items.map(item => (
          <Box key={item.id}>
            <Box display="flex" flexDirection={"column"} pt={4}>
              <Typography gutterBottom variant="h6">
                {item.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" mb={3}>
                {item.description}
              </Typography>
              <Box
                height="90px"
                width="100%"
                alignItems={"center"}
                display="flex"
                justifyContent={"center"}
                bgcolor={"primary.light"}
                mb={1}
              >
                {item.icon}
              </Box>
              <Button
                color="primary"
                variant="contained"
                onClick={item.onClickAction}
              >
                {item.actionButtonText}
              </Button>
            </Box>
          </Box>
        ))}
      </Slider>
    </Card>
  );
};
