import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

export function useCreateReferral(
  options: UseMutationOptions<
    void,
    AxiosError,
    {
      referringCompanyId: number;
      referredCompanyId: number;
    }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  {
    referringCompanyId: number;
    referredCompanyId: number;
  }
> {
  return useMutation({
    mutationFn: async ({ referringCompanyId, referredCompanyId }) => {
      const updateCompanyUri = `/internal-admin/referrals`;
      await API.post(
        updateCompanyUri,
        changeCaseKeys(
          {
            referringCompanyId,
            referredCompanyId,
          },
          "underscored"
        )
      );
    },
    ...options,
  });
}
