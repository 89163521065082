import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "lodash";

import { useAuth } from "@/auth/context/jwt";
import { AppConstants } from "@/common/constants";
import { JobSectors } from "@/company/jobs/constants";
import { Job, JobStatus } from "@/company/jobs/types";
import { RoutedJobStatus } from "@/company/jobs/types/job";
import { changeDateToLocal } from "@/company/jobs/utils";
import { PayReferralFeeSettings } from "@/company/types";
import { isDefined } from "@/utils/isDefined";

import { getTechnicianJobCardStatusChipProps } from "./utils";

export interface TechnicianJobCardProps {
  job: Job;
  onClick: () => void;
  onClickShare: () => void;
}

export function TechnicianJobCard(props: TechnicianJobCardProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { job } = props;
  const { session } = useAuth();
  const company = session?.company;

  const isJobDeclined =
    (job?.listingExtraInfo?.latestJobRouting?.status ===
      RoutedJobStatus.INACTIVE ||
      job?.listingExtraInfo?.latestJobRouting?.status ===
        RoutedJobStatus.DECLINED) &&
    job?.status === JobStatus.NO_COMPANY_TO_ROUTE;

  const getSubtitle2 = () => {
    if (props.job.technicianFee)
      return `Commission: $${props.job.technicianFee.toFixed(2)}`;
    const oneHourCompanyId =
      AppConstants.environment === "production" ? 3391 : 4199;
    const mrElectricCompanyId =
      AppConstants.environment === "production" ? 3390 : 4186;
    if (
      (AppConstants.environment === "production" ||
        AppConstants.environment === "demo") &&
      company?.id === oneHourCompanyId &&
      (props.job.listingExtraInfo?.buyingCompanyId === mrElectricCompanyId ||
        props.job.listingExtraInfo?.routedToCompanyId === mrElectricCompanyId ||
        props.job.listingExtraInfo?.potentialReferralForCompanyId ===
          mrElectricCompanyId) &&
      props.job.sector?.toLowerCase() === JobSectors.ELECTRICAL.toLowerCase()
    ) {
      return `Commission: $35+`;
    }
    if (
      props.job.payReferralFeeSettings === PayReferralFeeSettings.FIXED_AMOUNT
    ) {
      if (props.job.listingExtraInfo?.buyingCompanySectors) {
        const commission =
          props.job.listingExtraInfo?.buyingCompanySectors?.find(
            sector => sector.sector === props.job.sector
          )?.buyingPrice ?? 25;
        return `Commission: $${(
          (commission *
            (session?.brandLocationProfile?.defaultTechnicianSplit ?? 100)) /
          100
        ).toFixed(2)}`;
      }
      if (props.job.listingExtraInfo?.routedToCompanySectors) {
        const commission =
          props.job.listingExtraInfo?.routedToCompanySectors?.find(
            sector => sector.sector === props.job.sector
          )?.buyingPrice ?? 25;
        return `Commission: $${(
          (commission *
            (session?.brandLocationProfile?.defaultTechnicianSplit ?? 100)) /
          100
        ).toFixed(2)}`;
      }
      if (props.job.listingExtraInfo?.potentialReferralForCompanySectors) {
        const commission =
          props.job.listingExtraInfo?.potentialReferralForCompanySectors?.find(
            sector => sector.sector === props.job.sector
          )?.buyingPrice ?? 25;
        return `Commission: $${(
          (commission *
            (session?.brandLocationProfile?.defaultTechnicianSplit ?? 100)) /
          100
        ).toFixed(2)}`;
      }
      return `Commission: Pending`;
    }
    if (
      props.job.payReferralFeeSettings ===
      PayReferralFeeSettings.DEPENDING_ON_JOB_AND_REFERRAL_PARTNER
    ) {
      return "Commission: Varies based on the job";
    }
    if (
      props.job.payReferralFeeSettings ===
      PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE
    ) {
      return `Commission: ${props.job.percentageFee}% of the sale price`;
    }
    return `Commission: Pending`;
  };

  const subtitle1 = props.job.sector
    ? `${_.startCase(props.job.sector?.toLocaleLowerCase())}`
    : "Processing...";
  const subtitle2 = getSubtitle2();
  const subtitle3 = `Submitted: ${changeDateToLocal(
    props.job.createdAt?.toString() ?? ""
  )}`;

  const jobDeclinedComponent = isJobDeclined ? (
    <Box display={"flex"} alignItems={"start"} flexDirection={"column"}>
      {job?.listingExtraInfo?.latestJobRouting?.companyId ? (
        <Typography variant="body2" color="text.secondary">
          <Typography
            variant="body2"
            color="text.secondary"
            display={"inline"}
            fontWeight={500}
          >
            Declined By:
          </Typography>{" "}
          <Typography display={"inline"} variant="body2" fontWeight={700}>
            {job.listingExtraInfo?.latestJobRouting?.companyName}
          </Typography>
        </Typography>
      ) : null}
      {job?.listingExtraInfo?.latestJobRouting?.inactiveReason ? (
        <Typography variant="body2" color="text.secondary">
          <Typography
            variant="body2"
            color="text.secondary"
            display={"inline"}
            fontWeight={500}
          >
            Reason:
          </Typography>{" "}
          {job?.listingExtraInfo?.latestJobRouting?.inactiveReason}
        </Typography>
      ) : null}
      {job?.listingExtraInfo?.latestJobRouting?.declinedReason ? (
        <Typography variant="body2" color="text.secondary">
          <Typography
            variant="body2"
            color="text.secondary"
            display={"inline"}
            fontWeight={500}
          >
            Reason:
          </Typography>{" "}
          {job?.listingExtraInfo?.latestJobRouting?.declinedReason}
        </Typography>
      ) : null}
    </Box>
  ) : null;

  return (
    <Card
      sx={{
        display: "flex",
        cursor: "pointer",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        alignItems: { xs: "flex-start", md: "center" },
        py: 1,
        px: 2,
        pb: { xs: 2, md: 1 },
      }}
      onClick={props.onClick}
    >
      <Box
        display={"flex"}
        sx={{
          p: 1,
          flex: "1 0 0",
        }}
        alignItems={isMobile ? "flex-start" : "center"}
        gap={2}
      >
        {isMobile ? null : (
          <img
            src="/video-thumbnail.svg"
            style={{ width: "40px" }}
            alt={props.job.jobType}
          />
        )}
        <Box display="flex" flexDirection="column">
          <Typography gutterBottom variant="subtitle1">
            {`${isDefined(props.job.jobPromotion?.id) ? "Promotion: " : ""} ${
              props.job.jobType ?? "Processing..."
            }`}
          </Typography>
          <Box display="flex">
            <Typography gutterBottom variant="body2" color="textSecondary">
              {subtitle1}
              &nbsp;|&nbsp;
            </Typography>
            <Typography gutterBottom variant="body2" color="primary">
              {subtitle2}
            </Typography>
          </Box>
          <Typography gutterBottom variant="body2" color="textSecondary">
            {subtitle3}
          </Typography>
          {jobDeclinedComponent}
        </Box>
      </Box>
      {isMobile ? (
        <Divider sx={{ width: "100%", mb: 2 }} />
      ) : isJobDeclined ? (
        <Chip
          label={<Typography variant="overline">JOB DECLINED</Typography>}
          sx={{
            ml: { xs: 2, md: 0 },
            textTransform: "uppercase",
            cursor: "default",
            height: "24px",
            fontSize: "12px",
            fontWeight: 600,
            bgcolor: "rgba(240, 68, 56, 0.12)",
            color: "#B42318",
          }}
        />
      ) : (
        <Chip {...getTechnicianJobCardStatusChipProps(props.job, true)} />
      )}
      {isMobile ? (
        <Box
          width="100%"
          display="fleX"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {" "}
          {isJobDeclined ? (
            <Chip
              label={<Typography variant="overline">JOB DECLINED</Typography>}
              sx={{
                ml: { xs: 2, md: 0 },
                textTransform: "uppercase",
                cursor: "default",
                height: "24px",
                fontSize: "12px",
                fontWeight: 600,
                bgcolor: "rgba(240, 68, 56, 0.12)",
                color: "#B42318",
              }}
            />
          ) : (
            <Chip {...getTechnicianJobCardStatusChipProps(props.job)} />
          )}
          {[
            JobStatus.PENDING_HOMEOWNER_ACTION,
            JobStatus.HOMEOWNER_DELAYED,
          ].includes(props.job.status) && isMobile ? (
            <Button
              variant="contained"
              sx={{
                cursor: "pointer",
                py: 1,
                px: 1,
                minWidth: "unset",
                width: "fit-content",
              }}
              onClick={props.onClickShare}
            >
              <ShareIcon />
            </Button>
          ) : null}
        </Box>
      ) : null}
    </Card>
  );
}
