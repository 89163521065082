import {
  Add as AddIcon,
  ContentCopy as ContentCopyIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  SummarizeOutlined as SummarizeOutlinedIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Fab,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import debounce from "lodash/debounce";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useModalState } from "@/lib/modals/useModalState";
import { isDefined } from "@/utils/isDefined";

import { useGetReferralCampaigns } from "../api/useGetReferralCampaigns";
import { ReferralCampaignCard } from "../components/ReferralCampaignCard";
import { ReferralCampaignDetails } from "../components/ReferralCampaignDetails";
import {
  ReferralCampaignsSearchFilterForm,
  SearchReferralCampaignsFormFields,
} from "../components/ReferralCampaignsSearchFilterForm";
import { ReferralCampaign, ReferralCampaignStatus } from "../types";

enum ReferralCampaignTabs {
  ALL_CAMPAIGNS = "ALL_CAMPAIGNS",
  ACTIVE_CAMPAIGNS = "ACTIVE_CAMPAIGNS",
  INACTIVE_CAMPAIGNS = "INACTIVE_CAMPAIGNS",
  DRAFT_CAMPAIGNS = "DRAFT_CAMPAIGNS",
}

export function ReferralCampaignsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const referralCampaignDetailsModalState = useModalState();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<ReferralCampaignTabs>(
    ReferralCampaignTabs.ALL_CAMPAIGNS
  );
  const [selectedReferralCampaign, setSelectedReferralCampaign] =
    useState<ReferralCampaign | null>(null);
  const [
    referralCampaignMoreOptionsAnchorEl,
    setReferralCampaignMoreOptionsAnchorEl,
  ] = useState<HTMLElement | null>(null);

  const searchReferralCampaignsFormMethods =
    useFormik<SearchReferralCampaignsFormFields>({
      initialValues: {
        searchWord: "",
      },
      onSubmit: () => {
        console.log("searchWord");
      },
    });

  const {
    data: referralCampaigns = [],
    isLoading: isLoadingReferralCampaigns,
    refetch: refetchReferralCampaigns,
  } = useGetReferralCampaigns(
    {
      searchWord: searchReferralCampaignsFormMethods.values.searchWord,
    },
    {
      onError: () => {
        enqueueSnackbar({
          message: "Error while getting the referral campaigns",
          variant: "error",
        });
      },
    }
  );

  useEffect(() => {
    const debouncedRefetch = debounce(() => {
      refetchReferralCampaigns();
    }, 300);

    debouncedRefetch();

    return () => {
      debouncedRefetch.cancel();
    };
  }, [
    refetchReferralCampaigns,
    searchReferralCampaignsFormMethods.values.searchWord,
  ]);

  const renderReferralCampaigns = () => {
    if (isLoadingReferralCampaigns) {
      return <ComponentLoader />;
    }

    if (!referralCampaigns.length) {
      return (
        <Box sx={{ flexGrow: 1, alignContent: "center" }}>
          <Stack gap={2} alignItems="center">
            <Box
              component="img"
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no referral campaigns"
            />
            <Typography variant="h6" color="text.secondary">
              No referral campaigns found
            </Typography>
          </Stack>
        </Box>
      );
    }

    const filteredReferralCampaigns = referralCampaigns?.filter(
      referralCampaign => {
        if (selectedTab === ReferralCampaignTabs.ALL_CAMPAIGNS) {
          return true;
        }

        if (selectedTab === ReferralCampaignTabs.ACTIVE_CAMPAIGNS) {
          return (
            referralCampaign.status === ReferralCampaignStatus.ACTIVE &&
            !referralCampaign.isDraft
          );
        }

        if (selectedTab === ReferralCampaignTabs.INACTIVE_CAMPAIGNS) {
          return (
            referralCampaign.status === ReferralCampaignStatus.INACTIVE &&
            !referralCampaign.isDraft
          );
        }

        return referralCampaign.isDraft;
      }
    );

    return (
      <Grid container>
        {filteredReferralCampaigns?.map(referralCampaign => (
          <Grid key={referralCampaign.id} item xs={12} md={6} lg={4}>
            <ReferralCampaignCard
              referralCampaign={referralCampaign}
              onClick={() => {
                setSelectedReferralCampaign(referralCampaign);
                referralCampaignDetailsModalState.openModal();
              }}
              onMoreOptionsClick={event => {
                event.stopPropagation();
                setSelectedReferralCampaign(referralCampaign);
                setReferralCampaignMoreOptionsAnchorEl(event.currentTarget);
              }}
              onUpdate={() => {
                refetchReferralCampaigns();
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          overflow: "auto",
          flexDirection: "column",
          paddingY: 0,
          paddingX: 2,
        }}
      >
        <Box
          sx={{
            top: 0,
            paddingY: 2,
          }}
          position={"sticky"}
          display={"flex"}
          bgcolor={"white"}
          zIndex={1200}
          flexDirection={"column"}
          width={"100%"}
          gap={1}
        >
          <Grid
            container
            sx={{
              top: 0,
              zIndex: 400,
            }}
            position="sticky"
            bgcolor="white"
          >
            <Grid item xs={12} lg={10}>
              <Typography gutterBottom variant="h5">
                Referral Campaigns
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary">
                Incentivize your teams to refer services offered by Rollins
                brands and strategic partners.
              </Typography>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Stack
                sx={{
                  justifyContent: "center",
                  alignItems: "flex-end",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    navigate(
                      getNavigationPathFromRoutePaths([
                        "admin",
                        "marketing",
                        "referral-campaigns",
                        "add",
                      ])
                    );
                  }}
                >
                  Create New
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <FormikProvider value={searchReferralCampaignsFormMethods}>
            <ReferralCampaignsSearchFilterForm
              formMethods={searchReferralCampaignsFormMethods}
              isLoading={isLoadingReferralCampaigns}
            />
          </FormikProvider>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
          >
            <Tab
              label={`All (${referralCampaigns.length})`}
              value={ReferralCampaignTabs.ALL_CAMPAIGNS}
            />
            <Tab
              label={`Active (${
                referralCampaigns.filter(
                  referralCampaign =>
                    referralCampaign.status === ReferralCampaignStatus.ACTIVE &&
                    !referralCampaign.isDraft
                ).length
              })`}
              value={ReferralCampaignTabs.ACTIVE_CAMPAIGNS}
            />
            <Tab
              label={`Inactive (${
                referralCampaigns.filter(
                  referralCampaign =>
                    referralCampaign.status ===
                      ReferralCampaignStatus.INACTIVE &&
                    !referralCampaign.isDraft
                ).length
              })`}
              value={ReferralCampaignTabs.INACTIVE_CAMPAIGNS}
            />
            <Tab
              label={`Drafts (${
                referralCampaigns.filter(
                  referralCampaign => referralCampaign.isDraft
                ).length
              })`}
              value={ReferralCampaignTabs.DRAFT_CAMPAIGNS}
            />
          </Tabs>
          <Divider sx={{ width: "100%" }} />
        </Box>
        {renderReferralCampaigns()}

        <Fab
          color="primary"
          aria-label="add-job-promotion"
          sx={{
            position: "fixed",
            bottom: theme => theme.spacing(3),
            right: theme => theme.spacing(3),
          }}
          onClick={() => {
            navigate(
              getNavigationPathFromRoutePaths([
                "admin",
                "marketing",
                "referral-campaigns",
                "add",
              ])
            );
          }}
        >
          <AddIcon />
        </Fab>
      </Box>
      {isDefined(selectedReferralCampaign) && (
        <Menu
          anchorEl={referralCampaignMoreOptionsAnchorEl}
          open={isDefined(referralCampaignMoreOptionsAnchorEl)}
          sx={{
            borderRadius: "8px",
          }}
          onClose={() => setReferralCampaignMoreOptionsAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              navigate(
                getNavigationPathFromRoutePaths([
                  "admin",
                  "marketing",
                  "referral-campaigns",
                  selectedReferralCampaign.id.toString(),
                  "edit",
                ])
              );
            }}
          >
            <EditOutlinedIcon
              sx={{ marginRight: 1, color: "text.secondary" }}
            />
            <Typography variant="body1">Edit</Typography>
          </MenuItem>
          <MenuItem>
            <ContentCopyIcon sx={{ marginRight: 1, color: "text.secondary" }} />
            <Typography variant="body1">Duplicate</Typography>
          </MenuItem>
          <MenuItem>
            <DeleteOutlineOutlinedIcon
              sx={{ marginRight: 1, color: "text.secondary" }}
            />
            <Typography variant="body1">Delete</Typography>
          </MenuItem>
          <MenuItem>
            <SummarizeOutlinedIcon
              sx={{ marginRight: 1, color: "text.secondary" }}
            />
            <Typography variant="body1">Activity</Typography>
          </MenuItem>
        </Menu>
      )}
      {isDefined(selectedReferralCampaign) && (
        <Drawer
          anchor="right"
          open={referralCampaignDetailsModalState.modalIsOpen}
          PaperProps={{
            sx: {
              width: "100%",
              maxWidth: 500,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            },
          }}
          onClose={referralCampaignDetailsModalState.closeModal}
        >
          <ReferralCampaignDetails
            referralCampaign={selectedReferralCampaign}
            onClose={referralCampaignDetailsModalState.closeModal}
          />
        </Drawer>
      )}
    </>
  );
}
