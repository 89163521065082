import AddIcon from "@mui/icons-material/Add";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ShareIcon from "@mui/icons-material/Share";
import TimelineIcon from "@mui/icons-material/Timeline";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { AppConstants } from "@/common/constants/AppConstants";
import { CreateHomeownerJobDialog } from "@/common/CreateJobDialog/CreateHomeownerJobDialog";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { getCompanySectorsChip } from "@/company/components/company-profile-card/utils";
import { Job } from "@/company/jobs/types";
import { getLastActivityJob } from "@/company/jobs/utils";
import {
  Company,
  CompanyPublicProfile,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { HomeownerRoutes } from "@/homeowner/constants";
import { PublicRoutes } from "@/open/constants";

export interface ServiceProviderCardProps {
  companyProfile: CompanyPublicProfile;
  lastJob: Job;
  referredByCompany?: Company;
  onClick: () => void;
}

export function ServiceProviderCard(props: ServiceProviderCardProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { onClick, companyProfile, lastJob, referredByCompany } = props;

  const [isCreateJobDialogOpen, setIsCreateJobDialogOpen] = useState(false);

  const onClickRefer = async () => {
    const { companyProfile } = props;
    const message = `👋 Do you need a home service provider for ${
      snakeCaseJobSectors[companyProfile.sectors[0].sector]
    } work? AI completed a project with ${
      companyProfile.name
    } after they were recommended to me on TradeEngage. You can learn more about their team and manage your home projects by tapping the link.`;

    const shareDetails = {
      url:
        AppConstants.platformBaseUrl +
        getNavigationPathFromRoutePaths([
          PublicRoutes.BASE,
          PublicRoutes.SERVICE_PROVIDER.replace(
            ":id",
            String(props.companyProfile.profile.googlePlacesId)
          ),
        ]),
      title: `Check out the service provider - ${props.companyProfile.familyOfBrands?.name} `,
      text: message,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareDetails);
      } catch (error) {
        console.error({
          message:
            "There was an error while sharing the details of the service provider.",
        });
      }
    } else {
      navigator.clipboard.writeText(shareDetails.url);
      enqueueSnackbar({
        message: "Link copied!",
        variant: "success",
      });
    }
  };

  return (
    <>
      <Card
        sx={{
          p: 2,
          boxShadow: "0px 0px 0px 1px #F2F4F7",
          "&:active": {
            border: "1px solid #071D2F",
          },
          "&:focus": {
            border: "1px solid #071D2F",
          },
          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
          width: { xs: "100%", md: "100%" },
          minWidth: { xs: "100%", md: "350px" },
        }}
      >
        <Box
          sx={{ display: "flex", height: "80px", py: 1 }}
          justifyContent={"space-between"}
          alignItems={"center"}
          onClick={onClick}
        >
          <CompanyAvatar
            height={"40px"}
            logo={companyProfile.logoUrl}
            name={companyProfile.name}
          />
        </Box>
        <CardContent sx={{ p: 0, pb: 3 }} onClick={onClick}>
          <Box display={"flex"} gap={1} py={1} flexWrap={"wrap"}>
            {getCompanySectorsChip(companyProfile?.sectors)}
          </Box>
          {companyProfile.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM ? (
            <Box display="flex" alignItems="center" mt={1}>
              <Typography variant="h6">{companyProfile.name}</Typography>
              <ToolTip
                placement="bottom"
                title="TradeEngage Verified"
                message="This is a verified active business on TradeEngage."
              >
                <VerifiedOutlinedIcon
                  color="primary"
                  fontSize="small"
                  sx={{ ml: 1, verticalAlign: "center", color: "#16DB65" }}
                />
              </ToolTip>
            </Box>
          ) : null}
          {lastJob ? (
            <Box display="flex" alignItems="center" my={1}>
              <TimelineIcon fontSize="small" color="disabled" sx={{ mr: 1 }} />
              <Typography variant="caption" color="textSecondary">
                Last Activity: {getLastActivityJob(lastJob)}
              </Typography>
            </Box>
          ) : null}
          <Box display="flex" alignItems="center" my={1}>
            <EngineeringIcon fontSize="small" color="disabled" sx={{ mr: 1 }} />
            {referredByCompany?.name ? (
              <>
                <Typography variant="caption" color="textSecondary" mr={0.5}>
                  Referred by:
                </Typography>
                <Typography variant="caption" color="primary">
                  {referredByCompany?.name}
                </Typography>
              </>
            ) : lastJob ? (
              <Typography variant="caption" color="textSecondary" mr={0.5}>
                Last Technician: {lastJob.listingExtraInfo?.technicianName}
              </Typography>
            ) : null}
          </Box>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap="4px"
          pt={2}
        >
          <Button
            startIcon={<ShareIcon />}
            color="primary"
            variant="contained"
            onClick={onClickRefer}
          >
            Refer
          </Button>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            color="secondary"
            onClick={() => setIsCreateJobDialogOpen(true)}
          >
            Request Service
          </Button>
        </Box>
      </Card>
      <CreateHomeownerJobDialog
        isOpen={isCreateJobDialogOpen}
        createdForCompanyId={companyProfile.id}
        jobsListUrl={getNavigationPathFromRoutePaths([
          HomeownerRoutes.BASE,
          HomeownerRoutes.JOBS_LIST,
        ])}
        onClose={() => setIsCreateJobDialogOpen(false)}
      />
    </>
  );
}
