import * as yup from "yup";

export enum ReferralCampaignStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ReferralCampaignPrizeType {
  CASH = "CASH",
  GIFTCARD = "GIFTCARD",
  TICKETS = "TICKETS",
  OTHERS = "OTHERS",
}

export const referralCampaignPrizeSchema = yup.object().shape({
  prizeName: yup.string().required("Prize Name is required"),
  prizeType: yup.mixed<ReferralCampaignPrizeType>().required(),
});

export type ReferralCampaignPrize = yup.InferType<
  typeof referralCampaignPrizeSchema
>;

export enum ReferralCampaignConfigurationStep {
  CAMPAIGN_BRANDING = "CAMPAIGN_BRANDING",
  CAMPAIGN_GEOGRAPHY = "CAMPAIGN_GEOGRAPHY",
  CAMPAIGN_BASIC_INFO = "CAMPAIGN_BASIC_INFO",
  CAMPAIGN_GOAL = "CAMPAIGN_GOAL",
  CAMPAIGN_SUCCESS_CRITERIA = "CAMPAIGN_SUCCESS_CRITERIA",
  CAMPAIGN_REWARDS = "CAMPAIGN_REWARDS",
  CAMPAIGN_ADDITIONAL_SETTINGS = "CAMPAIGN_ADDITIONAL_SETTINGS",
}

export enum ReferralCampaignNotificationChannel {
  EMAIL = "EMAIL",
  TEXT = "TEXT",
  IN_APP = "IN_APP",
}

export enum ReferralCampaignSendUpdatesFrequency {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export enum ReferralCampaignTargetParticipants {
  ADMIN_AND_OWNERS = "ADMIN_AND_OWNERS",
  OPERATIONS = "OPERATIONS",
  FIELD_TEAM = "FIELD_TEAM",
}

export interface ReferralCampaign {
  id: number;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  status: ReferralCampaignStatus;
  isDraft: boolean;
  goal: string;
  otherGoal?: string;
  successCriteria: {
    criteria: string;
    numberOfReferrals: number;
    numberOfDays: number;
  }[];
  prizes: ReferralCampaignPrize[];
  brands: string[];
  specificBrands?: string[];
  geographies: string[];
  notificationChannel: ReferralCampaignNotificationChannel;
  sendUpdatesFrequency: ReferralCampaignSendUpdatesFrequency;
  targetParticipants: ReferralCampaignTargetParticipants;
  createdAt: string;
  updatedAt: string;
  currentStep?: ReferralCampaignConfigurationStep;
}
