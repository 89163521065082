import { Avatar, Box, Typography } from "@mui/material";

import { neutral } from "@/Theme/colors";
import { getInitials } from "@/utils";

export interface CompanySideNavHeaderProps {
  companyName: string;
  userRoleLabel: string;
  companyLogoUrl?: string;
}
export default function SideNavHeader(props: CompanySideNavHeaderProps) {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      gap={1}
      alignItems="flex-start"
      py={3}
      sx={{ ml: { xs: 2, md: 0 } }}
    >
      <Box display="flex" alignItems="flex-start" sx={{ ml: { xs: 2, md: 0 } }}>
        <Avatar
          alt={props.companyName}
          src={props.companyLogoUrl}
          variant="rounded"
          sx={{
            width: "40px",
            height: "40px",
            "& img": {
              objectFit: "contain",
            },
          }}
        >
          {getInitials(props.companyName ?? "")}
        </Avatar>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          ml={3}
          mr={2}
          maxWidth={"272px"}
        >
          <Typography gutterBottom variant="h6" color={neutral[50]}>
            {props.companyName}
          </Typography>
          <Typography gutterBottom variant="caption" color={neutral[400]}>
            {props.userRoleLabel}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
