import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";

interface AddReferralCampaignFormInputFieldProps {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  includeConnector?: boolean;
}

export function AddReferralCampaignFormInputField(
  props: AddReferralCampaignFormInputFieldProps
) {
  const { title, icon, children, includeConnector = true } = props;
  return (
    <Box sx={{ position: "relative" }}>
      <Card
        variant="outlined"
        elevation={0}
        sx={{ padding: 2, borderColor: "#DCDFE4" }}
      >
        <CardHeader
          title={title}
          avatar={
            <Avatar
              sx={{
                backgroundColor: theme => theme.palette.common.white,
                boxShadow: "0px 3px 14px 0px #00000014",
              }}
            >
              {icon}
            </Avatar>
          }
          sx={{ padding: 0 }}
        />
        <Divider sx={{ marginY: 1 }} />
        <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
          {children}
        </CardContent>
      </Card>
      {includeConnector && (
        <Box
          sx={{
            position: "absolute",
            left: "28px",
            height: "32px",
            width: "1px",
            backgroundColor: "#DCDFE4",
            zIndex: 0,
          }}
        />
      )}
    </Box>
  );
}
