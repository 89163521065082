import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { Job, JobStatus } from "../types";

const UPDATE_JOB_HOME_URL = "/jobs/:id/homeowner";

export type UpdateJobRequest = {
  jobId: string;
  status: JobStatus;
  summary: string;
};

export type UpdateJobResponse = { job: Job };

export function useUpdateJobHome(
  options: UseMutationOptions<
    void,
    AxiosError,
    {
      jobId: number;
      cellPhone: string;
      firstName?: string;
      lastName?: string;
      streetAddress?: string;
      zipCode?: string;
      latitude?: number;
      longitude?: number;
    }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  {
    jobId: number;
    cellPhone: string;
    firstName?: string;
    lastName?: string;
    streetAddress?: string;
    zipCode?: string;
    latitude?: number;
    longitude?: number;
  }
> {
  return useMutation({
    mutationFn: async ({
      jobId,
      cellPhone,
      firstName,
      lastName,
      streetAddress,
      zipCode,
      latitude,
      longitude,
    }) => {
      const url = UPDATE_JOB_HOME_URL.replace(":id", jobId.toString());
      const data = {
        cellPhone,
        firstName,
        lastName,
        ...(streetAddress || zipCode || (latitude && longitude)
          ? { home: { streetAddress, zipCode, latitude, longitude } }
          : {}),
      };
      return await API.post(url, changeCaseKeys(data, "underscored"));
    },
    ...options,
  });
}
