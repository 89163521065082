/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import CloseIcon from "@mui/icons-material/Close";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import PercentIcon from "@mui/icons-material/Percent";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { SettingsTabs } from "@/company/components/screens/company-settings/CompanySettingsPage";
import { useCompanyPaymentsContext } from "@/company/context/payments-context";
import { CompanyRoutes, PayReferralFeeSettings } from "@/company/types";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

import { useGetRoutingByJobId } from "../api/useGetRoutingByJobId";
import { useUpdateRoutedJobStatus } from "../api/useUpdateRoutedJobStatus";
import { Job, RoutedJobStatus } from "../types/job";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface JobCompletionPaymentDialogByJobProps {
  isOpen: boolean;
  jobResponse: Job;
  onClose: (refetch?: boolean) => void;
}

export const JobCompletionPaymentDialogByJob: React.FC<
  JobCompletionPaymentDialogByJobProps
> = ({ isOpen, jobResponse, onClose }) => {
  const { session } = useAuth();
  const [endDate, setEndDate] = React.useState<DateTime | null>(DateTime.now());

  const companySectors = session?.companySectors;

  const { data: job } = useGetRoutingByJobId(jobResponse.id);

  const [salesPrice, setSalesPrice] = React.useState<number>(0);
  const [referralFeePrice, setReferralFeePrice] = React.useState<number>(0);

  const { refetchCard, refetchTransactions } = useCompanyPaymentsContext();

  const navigate = useNavigate();
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const { mutateAsync: updateRoutedJob, isLoading: makingJobPayment } =
    useUpdateRoutedJobStatus({
      onSuccess: () => {
        enqueueSnackbar({
          message: "Referral Fee paid successfully.",
          variant: "success",
        });
        refetchCard();
        refetchTransactions();
        onClose(true);
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating job status.";
        enqueueSnackbar(
          `${
            error?.code === "not_enough_balance"
              ? "You don't have enough credits to approve this job."
              : message
          }`,
          {
            ...(error?.code === "not_enough_balance"
              ? {
                  subText: (
                    <>
                      Click{" "}
                      <a
                        role="button"
                        style={{
                          textDecorationLine: "underline",
                          color: "#7A271A",
                          cursor: "pointer",
                        }}
                        onClick={event => {
                          event.preventDefault();
                          navigate(
                            getNavigationPathFromRoutePaths([
                              CompanyRoutes.BASE,
                              CompanyRoutes.COMPANY_SETTINGS,
                              SettingsTabs.PAYMENT,
                            ])
                          );
                        }}
                      >
                        here
                      </a>{" "}
                      to buy credits. Questions?{" "}
                      <a
                        role="button"
                        style={{
                          textDecorationLine: "underline",
                          color: "#7A271A",
                          cursor: "pointer",
                        }}
                        onClick={event => {
                          event.preventDefault();
                          setContactSupportModalOpen(true);
                        }}
                      >
                        Contact support
                      </a>
                      .
                    </>
                  ),
                }
              : {}),
            variant: "error",
            autoHideDuration: 10000,
          }
        );
      },
    });

  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth
        aria-labelledby="invite-dialog-title"
        open={isOpen}
        sx={{ m: { xs: -3, md: 0 } }}
        onClose={onClose}
      >
        <Box sx={{ p: 3 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h5" m={0} p={0}>
              Ready to Pay Your Referral Partner?
            </Typography>
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            gutterBottom
            variant="body2"
            color="text.secondary"
            sx={{ mt: { xs: 1, md: 0 } }}
          >
            Fill out the details below and we&apos;ll use your TradeEngage
            balance to pay referral commission. If your balance is low,
            we&apos;ll charge the credit card on file.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
              columnGap: "8px",
              rowGap: "8px",
              width: "100%",
              py: 1,
              px: 1,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                sx={{ width: { xs: 1, md: 1 / 2 } }}
                label={"Date Completed"}
                value={endDate}
                maxDate={DateTime.now()}
                onChange={newValue => setEndDate(newValue)}
              />
            </LocalizationProvider>
            <TextField
              sx={{ width: { xs: 1, md: 1 / 2 } }}
              id="salesPrice"
              required={
                jobResponse?.payReferralFeeSettings ===
                PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE
              }
              name="salesPrice"
              label="Sale Price"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ color: "rgba(17, 25, 39, 0.38)" }}
                  >
                    $
                  </InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              value={salesPrice}
              onChange={event => {
                setSalesPrice(event.target.value);
              }}
            />
            {jobResponse?.payReferralFeeSettings !==
            PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE ? (
              <TextField
                disabled={
                  jobResponse?.payReferralFeeSettings ===
                  PayReferralFeeSettings.FIXED_AMOUNT
                }
                required={
                  jobResponse?.payReferralFeeSettings !==
                  PayReferralFeeSettings.FIXED_AMOUNT
                }
                sx={{ width: { xs: 1, md: 1 / 2 } }}
                id="jobPrice"
                name="jobPrice"
                label="Referral Fee"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ color: "rgba(17, 25, 39, 0.38)" }}
                    >
                      $
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                value={
                  jobResponse?.payReferralFeeSettings ===
                  PayReferralFeeSettings.FIXED_AMOUNT
                    ? companySectors?.find(
                        sector => sector.sector === jobResponse?.sector
                      )?.buyingPrice
                    : referralFeePrice
                }
                onChange={event => {
                  setReferralFeePrice(event.target.value);
                }}
              />
            ) : null}
          </Box>
          {jobResponse?.payReferralFeeSettings ===
          PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE ? (
            <>
              {" "}
              <Box>
                <Typography variant="subtitle1">
                  Referral Fee Calculation
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "8px",
                  rowGap: "8px",
                  width: "100%",
                  py: 1,
                  px: 1,
                }}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Percentage"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PercentIcon sx={{ mt: 2 }} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  name="Referral Fee Percentage"
                  type="number"
                  value={jobResponse?.percentageFee}
                  inputProps={{
                    min: 0,
                    max: 100,
                    step: 1,
                  }}
                />
                <TextField
                  disabled
                  sx={{ width: { xs: 1 } }}
                  id="jobPrice"
                  name="jobPrice"
                  label="Referral Fee"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "rgba(17, 25, 39, 0.38)" }}
                      >
                        $
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  value={(
                    (jobResponse?.percentageFee / 100) *
                    salesPrice
                  ).toFixed(2)}
                />
              </Box>
            </>
          ) : null}
          <Box display={"flex"} alignItems={"center"} gap={1} mt={1}>
            <Typography variant="caption">
              Want to change your referral fee for future jobs ?{" "}
              <Typography
                display={"inline"}
                fontSize={"13px"}
                fontWeight={600}
                sx={{ color: "#16B364", cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    `/${CompanyRoutes.BASE}/${CompanyRoutes.COMPANY_SETTINGS}/${SettingsTabs.REFERRAL_MATRIX}`
                  );
                }}
              >
                Go to Settings
              </Typography>
            </Typography>
          </Box>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="medium"
              variant="contained"
              disabled={
                makingJobPayment ||
                ((salesPrice === null || salesPrice === undefined) &&
                  jobResponse?.payReferralFeeSettings ===
                    PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE)
              }
              startIcon={<PaidOutlinedIcon />}
              onClick={event => {
                event.stopPropagation();
                updateRoutedJob({
                  jobId: job.id,
                  data: {
                    ...(salesPrice || salesPrice === 0
                      ? { salePrice: salesPrice }
                      : {}),
                    ...((jobResponse?.payReferralFeeSettings ===
                      PayReferralFeeSettings.DEPENDING_ON_JOB_AND_REFERRAL_PARTNER &&
                      referralFeePrice) ||
                    referralFeePrice === 0
                      ? { referralFeePrice }
                      : {}),
                    status: RoutedJobStatus.PAID.toString(),
                    completionDate: endDate?.toISODate() ?? "",
                  },
                });
              }}
            >
              Pay
            </Button>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};
