export const AppConstants = {
  sentryDsn:
    import.meta.env.VITE_SENTRY_DSN ??
    "https://da793c62edd3a789aa4c31931edafc74@o4505835217158144.ingest.sentry.io/4505997654949888",
  mixpanelApiToken:
    import.meta.env.VITE_MIXPANEL_API_TOKEN ??
    "d2f282706ce1864e9bccc2c2ca53fdfa",
  supportContactNumber:
    import.meta.env.SUPPORT_CONTACT_NUMBER ?? "+17866731551",
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL ?? "http://localhost:8000/api",
  platformBaseUrl:
    import.meta.env.VITE_PLATFORM_BASE_URL ?? "http://localhost:3000",
  googleApiKey:
    import.meta.env.VITE_GOOGLE_API_KEY ??
    "AIzaSyDtEiYRP0P3uVSD4xM4bPbVbW7shMcViBs",
  environment: import.meta.env.VITE_APP_ENV ?? "local",
  s3BucketPathStatesGeoJson:
    "https://tradeengage-geojsons.s3.amazonaws.com/states",
  googleMapId: import.meta.env.VITE_GOOGLE_MAP_ID ?? "",
  stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ?? "",
  HOTJAR_SITE_ID: import.meta.env.VITE_HOTJAR_SITE_ID ?? "3646726",
  CAMPAIGN_SITE: import.meta.env.VITE_CAMPAIGN_SITE,
  tradeEngageAppUtmId: "trade_engage_app",
  launchDarklyClientSideID:
    import.meta.env.VITE_APP_ENV === "production"
      ? "64fb71f077149b12748dcb15"
      : "64fb71f077149b12748dcb14",
  technicianWhatsappBotNumber:
    import.meta.env.VITE_TECHNICIAN_WHATSAPP_BOT_NUMBER ?? "+14154633549",
  technicianDemoVideoUrl:
    "https://tradeengage-app.s3.amazonaws.com/videos/TradeEngage+for+Technicians.mp4",
};

export const InitialWhatsappMessage = `https://api.whatsapp.com/send?phone=${AppConstants.technicianWhatsappBotNumber}&text=%F0%9F%91%8B%20Welcome%20to%20TradeEngage.%20Tap%20the%20plus%20sign%20to%20upload%20a%20job%20video%20or%20tap%20the%20arrow%20to%20send%20this%20message%20and%20receive%20instructions.`;
