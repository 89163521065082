import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Drawer, IconButton } from "@mui/material";
import { FC } from "react";

import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { Overview } from "@/company/components/company-drawer/Overview";

interface CompanyDrawerProps {
  onClose?: () => void;
  open?: boolean;
  companyId: number;
}

export const CompanyProfileDrawer: FC<CompanyDrawerProps> = props => {
  const { onClose, open = false, companyId, ...other } = props;

  const { data: companyProfile, isLoading } = useFetchCompanyProfile(companyId);

  if (!companyProfile) return null;

  const otherCompanyId = companyProfile?.id;
  if (!otherCompanyId) return null;

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 500,
          background: "white",
          px: 1.5,
        },
      }}
      onClose={onClose}
      {...other}
    >
      <Box display={"flex"} flexDirection={"column"} height={"100%"}>
        <Box
          position={"sticky"}
          sx={{
            top: 0,
            zIndex: 400,
            pt: 1,
            background: "white",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            px={3}
            pb={2}
            pt={3}
          >
            <CompanyAvatar
              height={"48px"}
              logo={companyProfile.logoUrl}
              name={companyProfile.name ?? ""}
            />
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
        </Box>
        <Box p={1.5} pb={0} flex={1}>
          <Overview companyProfile={companyProfile} />
        </Box>
      </Box>
    </Drawer>
  );
};
