import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { isDefined } from "@/utils/isDefined";

import { ReferralCampaign, ReferralCampaignStatus } from "../types";

type CreateReferralCampaignRequest = Omit<
  ReferralCampaign,
  "id" | "createdAt" | "updatedAt" | "status"
>;

export function useCreateReferralCampaign(
  options: UseMutationOptions<
    ReferralCampaign,
    AxiosError,
    CreateReferralCampaignRequest
  > = {}
): UseMutationResult<
  ReferralCampaign,
  AxiosError,
  CreateReferralCampaignRequest
> {
  const storageKey = "referral-campaigns";
  return useMutation({
    mutationFn: async (data): Promise<ReferralCampaign> => {
      const referralCampaigns = localStorage.getItem(storageKey);
      let parsedReferralCampaigns: ReferralCampaign[] = [];
      if (isDefined(referralCampaigns)) {
        parsedReferralCampaigns = JSON.parse(referralCampaigns);
      }

      const newReferralCampaign: ReferralCampaign = {
        ...data,
        id: parsedReferralCampaigns.length + 1,
        status: ReferralCampaignStatus.INACTIVE,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      parsedReferralCampaigns.push(newReferralCampaign);
      localStorage.setItem(storageKey, JSON.stringify(parsedReferralCampaigns));
      return newReferralCampaign;
    },
    ...options,
  });
}
