import { Add, Close as CloseIcon } from "@mui/icons-material";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  debounce,
  Drawer,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridComparatorFn,
  GridEventListener,
  GridFilterModel,
  GridRowId,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import changeCaseKeys from "change-case-keys";
import _ from "lodash";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useCreateTableView } from "@/company/api/useCreateTableView";
import { useDeleteCompany } from "@/company/api/useDeleteCompany";
import { useQueryCompanySearch } from "@/company/api/useQueryCompanySearch";
import { AdminChatProvider } from "@/company/context/admin-chat-context";
import { JobSectors } from "@/company/jobs/constants";
import { useUpdateCompaniesById } from "@/company/onboarding/api";
import {
  CarrierType,
  CHANNEL_ONBOARDED,
  COMPANY_ACCOUNT_TYPES,
  COMPANY_PRIORITY,
  ConnectionStatus,
  PLATFORM_PRESENCE,
} from "@/company/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";
import {
  getGridDateOperatorsDataGrid,
  getGridNumericOperatorsDataGrid,
} from "@/utils";
import { isDefined } from "@/utils/isDefined";

import { CompanyAvatar } from "../company/components/company-avatar/CompanyAvatar";
import { EditBrandArea } from "./common/EditBrandArea";
import { EditNotesArea } from "./common/EditNotesArea";
import { SEARCH_MINIMUM_CHAR_COUNT } from "./constants";
import { CreateReferralDialog } from "./CreateReferralDialog";
import { CreateReferralInvitationDialog } from "./CreateReferralInvitationDialog";
import { CreateRelationshipDialog } from "./CreateRelationshipDialog";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { InternalAdminCompanyDrawer } from "./InternalAdminCompanyDrawer";
import { CreateTableViewDialog } from "./saved-searches/CreateTableViewDialog";
import { TableType } from "./saved-searches/SavedSearchTable";
import { useGeographyLocationFilterContext } from "./useGeographyLocationFilter";
import {
  filterKeyToLabel,
  useInternalCompanySearchFilterContext,
} from "./useInternalCompanySearchFilter";
import {
  alignableSourceFields,
  bniSourceFields,
  CustomToolbar,
  drawerWidth,
  fddSourceFields,
  formatDate,
  getDifference,
  getGridStringOperatorsDataGrid,
  Main,
  profileFields,
  renderJobSectorsArea,
  serpSourceFields,
  websiteSourceFields,
} from "./utils";

const compareSectors = (a, b) => {
  const minLength = Math.min(a.length, b.length);

  for (let i = 0; i < minLength; i++) {
    if (a[i] < b[i]) return -1;
    if (a[i] > b[i]) return 1;
  }

  // If all elements are equal so far, the shorter array comes first
  return a.length - b.length;
};

const sectorComparator: GridComparatorFn<Array<string>> = (v1, v2) => {
  const minLength = Math.min(v1.length, v2.length);

  for (let i = 0; i < minLength; i++) {
    if (v1[i] < v2[i]) return -1;
    if (v1[i] > v2[i]) return 1;
  }

  // If all elements are equal so far, the shorter array comes first
  return v1.length - v2.length;
};

const formatYear = value => {
  if (!value) return ""; // If value is undefined or null
  const dt = DateTime.fromISO(value); // Convert to DateTime
  return dt.year; // Return only the year
};

export default function InternalAdminCompany({
  name,
  preFilledColumns,
  payload,
}: {
  name?: string;
  preFilledColumns?: Array<any>;
  payload?: Record<string, any>;
}) {
  const [keyword, setKeyword] = useState("");
  const { session } = useAuth();
  const user = session?.user;
  const [searchDisabled, setSearchDisabled] = useState(
    !!(preFilledColumns || payload)
  );

  const [deleteRowId, setDeleteRowId] = useState<GridRowId | null | undefined>(
    null
  );
  const [deleteRowName, setDeleteRowName] = useState<string>("");

  const [expanded, setExpanded] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  const [hideCriteria, setHideCriteria] = React.useState(false);
  const [companyProfileId, setCompanyProfileId] = useState<number | null>();

  const { removeFilter, removeAllFilter, filters, addFilter, addFilters } =
    useInternalCompanySearchFilterContext();

  const debouncedSetter = useMemo(
    () =>
      debounce((keyword: string) => {
        addFilter({ keyword });
      }, 500),
    []
  );

  const [serverSorting, setServerSorting] = React.useState<
    undefined | GridSortModel
  >();

  const [serverFiltering, setServerFiltering] = React.useState<
    undefined | GridFilterModel
  >();

  const navigate = useNavigate();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const { mutateAsync: updateCompany } = useUpdateCompaniesById({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while updating companies.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      refetch();
      enqueueSnackbar({
        message: "Your changes have been saved.",
        variant: "success",
      });
    },
  });

  const { mutateAsync: createTableView, isLoading: creatingTableView } =
    useCreateTableView({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while saving search.";
        enqueueSnackbar(message, {
          variant: "error",
        });
        refetch();
      },
      onSuccess(tableView) {
        enqueueSnackbar({
          message: "Search saved successfully.",
          variant: "success",
        });
        navigate(`/admin/saved-searches/${tableView.id}`, {
          state: { tableView },
        });
      },
    });
  const { mutateAsync: deleteCompany } = useDeleteCompany({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while archiving company.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      enqueueSnackbar({
        message: "Company archived successfully.",
        variant: "success",
      });
    },
  });

  const handleDeleteClick = (id: GridRowId) => {
    setRows(rows.filter(row => row.id !== id));
    deleteCompany(id);
  };

  const removePrefix = (input, prefix) => {
    const output = {};

    for (const [key, value] of Object.entries(input)) {
      if (key.startsWith(prefix)) {
        const newKey = key.slice(prefix.length);
        output[newKey] = value;
      }
    }

    return output;
  };

  useEffect(() => {
    if (payload) {
      if (payload.filters.keyword) {
        setKeyword(payload.filters.keyword);
      }
      setServerFiltering(payload.serverFiltering);
      setServerSorting(payload.serverSorting);
      setPaginationModel({ pageSize: payload.size, page: payload.page });
      addFilters(payload.filters);
      setSearchDisabled(false);
      if (payload.columnOrder) setColumnOrder(payload.columnOrder);
    }
  }, [payload]);

  const areAllValuesUndefined = obj => {
    return Object.values(obj).every(value => value === undefined);
  };

  const processRowUpdate = (newRow, oldRow) => {
    const rowId = newRow.id;

    if (doDeepCompare(oldRow, newRow)) {
      return newRow;
    }
    unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }
    setHasUnsavedRows(true);
    return newRow;
  };

  const discardChanges = () => {
    setHasUnsavedRows(false);
    apiRef.current.updateRows(
      Object.values(unsavedChangesRef.current.rowsBeforeChange)
    );
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
    };
  };

  const saveChanges = async () => {
    try {
      // Persist updates in the database
      setIsSaving(true);
      const beforeRows = unsavedChangesRef.current.rowsBeforeChange;
      const updatedRows = unsavedChangesRef.current.unsavedRows;
      const payloads =
        Object.values(updatedRows)?.map(newRow => {
          const beforeRow = Object.values(beforeRows)?.find(
            row => row.id === newRow.id
          );
          let newBrand = false;
          if (beforeRow) {
            newBrand = beforeRow.brand?.id !== newRow.brand?.id;
          }

          const profileObject = removePrefix(newRow, "profile-");
          const companyDiff = getDifference(newRow, beforeRow);

          return {
            id: newRow.id,
            companyDetails: {
              ...(newBrand ? { newBrandId: newRow.brand.id } : {}),
              company: {
                ...companyDiff,
                ...(companyDiff.platformPresence
                  ? {
                      platformPresence: companyDiff.platformPresence?.replace(
                        " ",
                        "_"
                      ),
                    }
                  : {}),
                ...(companyDiff.sectors
                  ? {
                      newSectors: companyDiff.sectors?.map?.(
                        sector => reverseSnakeCaseJobSectors[sector]
                      ),
                      oldSectors: beforeRow.sectors?.map?.(
                        sector => reverseSnakeCaseJobSectors[sector]
                      ),
                    }
                  : {}),
              },
              ...(companyDiff.alignableSource &&
              !areAllValuesUndefined(companyDiff.alignableSource)
                ? {
                    alignableSource: {
                      ...companyDiff.alignableSource,
                    },
                  }
                : {}),
              ...(companyDiff.serpSource &&
              !areAllValuesUndefined(companyDiff.serpSource)
                ? {
                    serpSource: {
                      ...companyDiff.serpSource,
                    },
                  }
                : {}),
              ...(companyDiff.bniSource &&
              !areAllValuesUndefined(companyDiff.bniSource)
                ? {
                    bniSource: {
                      ...companyDiff.bniSource,
                    },
                  }
                : {}),
              ...(companyDiff.fddSource &&
              !areAllValuesUndefined(companyDiff.fddSource)
                ? {
                    fddSource: {
                      ...companyDiff.fddSource,
                    },
                  }
                : {}),
              ...(companyDiff.websiteSource &&
              !areAllValuesUndefined(companyDiff.websiteSource)
                ? {
                    websiteSource: {
                      ...companyDiff.websiteSource,
                    },
                  }
                : {}),
              ...(companyDiff?.profile ||
              companyDiff?.["profile-inboundSectors"] ||
              companyDiff?.["profile-outboundSectors"]
                ? {
                    profile: {
                      ...companyDiff?.profile,
                      ...(companyDiff?.["profile-inboundSectors"]
                        ? {
                            inboundSectors:
                              profileObject?.inboundSectors?.map?.(
                                sector => reverseSnakeCaseJobSectors[sector]
                              ),
                          }
                        : {}),
                      ...(companyDiff?.["profile-outboundSectors"]
                        ? {
                            outboundSectors:
                              profileObject?.outboundSectors?.map?.(
                                sector => reverseSnakeCaseJobSectors[sector]
                              ),
                          }
                        : {}),
                    },
                  }
                : {}),
            },
          };
        }) ?? [];
      await updateCompany(payloads);
      setHasUnsavedRows(false);
      unsavedChangesRef.current = {
        unsavedRows: {},
        rowsBeforeChange: {},
      };
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  const { removeLocations } = useGeographyLocationFilterContext();

  const doDeepCompare = (oldRow, newRow) => {
    const newRowCompare = changeCaseKeys(_.cloneDeep(newRow), "camelize");
    newRowCompare.serpSource = changeCaseKeys(
      newRowCompare.serpSource,
      "camelize"
    );
    newRowCompare.fddSource = changeCaseKeys(
      newRowCompare.fddSource,
      "camelize"
    );
    newRowCompare.alignableSource = changeCaseKeys(
      newRowCompare.alignableSource,
      "camelize"
    );
    newRowCompare.websiteSource = changeCaseKeys(
      newRowCompare.websiteSource,
      "camelize"
    );
    newRowCompare.bniSource = changeCaseKeys(
      newRowCompare.bniSource,
      "camelize"
    );
    if (
      newRowCompare.serpSource &&
      areAllValuesUndefined(newRowCompare.serpSource)
    ) {
      newRowCompare.serpSource = null;
    }
    if (
      newRowCompare.alignableSource &&
      areAllValuesUndefined(newRowCompare.alignableSource)
    ) {
      newRowCompare.alignableSource = null;
    }
    if (
      newRowCompare.websiteSource &&
      areAllValuesUndefined(newRowCompare.websiteSource)
    ) {
      newRowCompare.websiteSource = null;
    }
    if (
      newRowCompare.fddSource &&
      areAllValuesUndefined(newRowCompare.fddSource)
    ) {
      newRowCompare.fddSource = null;
    }
    if (
      newRowCompare.bniSource &&
      areAllValuesUndefined(newRowCompare.bniSource)
    ) {
      newRowCompare.bniSource = null;
    }

    const oldRowCompare = changeCaseKeys(_.cloneDeep(oldRow), "camelize");
    oldRowCompare.serpSource = changeCaseKeys(
      oldRowCompare.serpSource,
      "camelize"
    );
    oldRowCompare.fddSource = changeCaseKeys(
      oldRowCompare.fddSource,
      "camelize"
    );
    oldRowCompare.alignableSource = changeCaseKeys(
      oldRowCompare.alignableSource,
      "camelize"
    );
    oldRowCompare.websiteSource = changeCaseKeys(
      oldRowCompare.websiteSource,
      "camelize"
    );
    oldRowCompare.bniSource = changeCaseKeys(
      oldRowCompare.bniSource,
      "camelize"
    );
    if (
      oldRowCompare.serpSource &&
      areAllValuesUndefined(oldRowCompare.serpSource)
    ) {
      oldRowCompare.serpSource = null;
    }
    if (
      oldRowCompare.alignableSource &&
      areAllValuesUndefined(oldRowCompare.alignableSource)
    ) {
      oldRowCompare.alignableSource = null;
    }
    if (
      oldRowCompare.websiteSource &&
      areAllValuesUndefined(oldRowCompare.websiteSource)
    ) {
      oldRowCompare.websiteSource = null;
    }
    if (
      oldRowCompare.fddSource &&
      areAllValuesUndefined(oldRowCompare.fddSource)
    ) {
      oldRowCompare.fddSource = null;
    }
    if (
      oldRowCompare.bniSource &&
      areAllValuesUndefined(oldRowCompare.bniSource)
    ) {
      oldRowCompare.bniSource = null;
    }

    const areEqual2 = _.isEqual(
      JSON.stringify(oldRowCompare),
      JSON.stringify(newRowCompare)
    );
    return areEqual2;
  };

  const getFilterChips = () => {
    const chips = [] as Array<React.ReactElement>;
    Object.keys(filters)
      .filter(filter => filter !== "milesRadius" && filter !== "keyword")
      .map(filter => {
        if (
          filter === "googleRating" ||
          filter === "numberOfGoogleReviews" ||
          filter === "numberOfWebsiteVisitors"
        ) {
          chips.push(
            <Button
              sx={{ px: 1, py: 0.5, borderRadius: "8px" }}
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              onClick={() => {
                removeFilter(filter);
              }}
            >
              <Typography
                fontSize={"13px"}
                fontWeight={500}
                color="text.secondary"
              >
                {`${filterKeyToLabel[filter]}: `}
                <Typography
                  display={"inline"}
                  fontSize={"13px"}
                  fontWeight={500}
                  sx={{ color: "#16B364" }}
                >{` ${filters[filter][0]}-${filters[filter][1]}`}</Typography>
              </Typography>
            </Button>
          );
        } else if (Array.isArray(filters[filter])) {
          chips.push(
            <Button
              sx={{ px: 1, py: 0.5, borderRadius: "8px" }}
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              onClick={() => {
                if (filter === "zipCodes") {
                  removeLocations();
                }
                removeFilter(filter);
                removeFilter("milesInRadius");
              }}
            >
              <Typography
                fontSize={"13px"}
                fontWeight={500}
                color="text.secondary"
              >
                {`${filterKeyToLabel[filter]}: `}
                <Typography
                  display={"inline"}
                  fontSize={"13px"}
                  fontWeight={500}
                  sx={{ color: "#16B364" }}
                >{` ${filters[filter]?.length} Selected`}</Typography>
              </Typography>
            </Button>
          );
        } else if (typeof filter === "number") {
          chips.push(
            <Button
              sx={{ px: 1, py: 0.5, borderRadius: "8px" }}
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              onClick={() => {
                removeFilter(filter);
              }}
            >
              <Typography
                fontSize={"13px"}
                fontWeight={500}
                color="text.secondary"
              >
                {`${filterKeyToLabel[filter]}: `}
                <Typography
                  display={"inline"}
                  fontSize={"13px"}
                  fontWeight={500}
                  sx={{ color: "#16B364" }}
                >{` ${filters[filter]}`}</Typography>
              </Typography>
            </Button>
          );
        } else {
          chips.push(
            <Button
              sx={{ px: 1, py: 0.5, borderRadius: "8px" }}
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              onClick={() => {
                removeFilter(filter);
              }}
            >
              <Typography
                fontSize={"13px"}
                fontWeight={500}
                color="text.secondary"
              >
                {`${filterKeyToLabel[filter]}`}
              </Typography>
            </Button>
          );
        }
      });
    return chips;
  };

  const {
    data: companies,
    isFetching,
    isLoading,
    refetch,
  } = useQueryCompanySearch(
    paginationModel.page,
    paginationModel.pageSize,
    filters,
    serverSorting,
    serverFiltering,
    { refetchOnWindowFocus: false, enabled: !searchDisabled } // Two minutes
  );

  useEffect(() => {
    const seenIds = new Set();

    const fetchedRows = companies?.companies
      ?.filter(item => {
        if (seenIds.has(item.id)) {
          return false; // If ID is already in the set, exclude this item
        } else {
          seenIds.add(item.id); // Otherwise, add ID to set and include the item
          return true;
        }
      })
      .map(company => ({
        ...company,
        sectors: company?.sectors?.map(
          sector => snakeCaseJobSectors[sector.sector]
        ),
        "profile-inboundSectors": company?.profile?.inboundSectors?.map(
          sector => snakeCaseJobSectors[sector]
        ),
        "profile-outboundSectors": company?.profile?.outboundSectors?.map(
          sector => snakeCaseJobSectors[sector]
        ),
      }));
    setRows(fetchedRows);
  }, [companies]);

  const columns = useMemo(() => {
    const gridColumnDefs: GridColDef[] = [
      {
        field: GRID_CHECKBOX_SELECTION_COL_DEF.field,
        headerName: "Checkbox Selection",
        width: 50,
        hideable: false,
        filterable: false,
        sortable: false,
      }, // Checkbox selection column
      {
        field: "logo",
        headerName: "Logo",
        sortable: false,
        filterable: false,
        renderCell: params => {
          return (
            <Box
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
              height={"100%"}
            >
              <CompanyAvatar
                logo={params.row.logoUrl || undefined}
                name={params.row.name}
                height={"32px"}
                width={"48px"}
                bgColor="white"
                border="1px solid gray !important"
                isAdminProfile={true}
              />
            </Box>
          );
        },
      },
      { field: "name", headerName: "Company Name", width: 200, editable: true },
      {
        field: "id",
        headerName: "Id",
        width: 200,
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        renderCell: params => params.row.id.toString(),
        valueFormatter: value => value && `${value?.toString()}`,
      },
      {
        field: "sectors",
        headerName: "Service Categories",
        editable: true,
        type: "singleSelect",
        valueOptions: Object.values(JobSectors),
        width: 200,
        sortComparator: sectorComparator,
        renderEditCell: renderJobSectorsArea,
        valueFormatter: value => `${value}`,
        renderCell: params => {
          const sectorChips = params.row?.sectors?.map(sector => (
            <Chip
              key={sector}
              sx={{
                color: "text.secondary",
                bgcolor: "#F8F9FA",
              }}
              label={sector}
              size="small"
            />
          ));
          return sectorChips;
        },
      },
      {
        field: "accountType",
        headerName: "Company Type",
        editable: false,
        width: 200,
        type: "singleSelect",
        renderCell: params => {
          return params.row.accountType?.replaceAll("_", " ");
        },
        valueOptions: [COMPANY_ACCOUNT_TYPES.BRAND_LOCATION],
      },
      {
        width: 200,
        field: "platformPresence",
        headerName: "Platform Presence",
        editable: true,
        type: "singleSelect",
        valueSetter: (value, row) => {
          return {
            ...row,

            platformPresence: value?.replaceAll(" ", "_"),
          };
        },
        valueOptions: [
          PLATFORM_PRESENCE.ON_PLATFORM,
          PLATFORM_PRESENCE.OFF_PLATFORM,
        ],
      },
      {
        field: "yearEstablished",
        headerName: "Year Established",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        valueFormatter: params => formatYear(params),
        headerAlign: "left",
        width: 200,
        editable: true,
      },
      {
        field: "numberEmployees",
        headerName: "No. of Employees",
        width: 200,
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        editable: true,
        headerAlign: "left",
      },
      {
        field: "websiteUrl",
        headerName: "Website Url",
        width: 200,
        editable: true,
      },
      {
        field: "invitationCode",
        headerName: "Invitation Code",
        width: 200,
        editable: true,
      },
      {
        field: "balance",
        headerName: "Balance",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        width: 200,
        valueFormatter: value => value && `$${value?.toString()}`,
        renderCell: params =>
          params.row.balance && `$${params.row.balance?.toString()}`,
        headerAlign: "left",
      },
      {
        field: "datetimeLastBalanceUpdated",
        headerName: "Date Last Balance Updated",
        type: "date",
        width: 200,
        editable: false,
        sortable: false,
        filterable: false,
        valueFormatter: formatDate,
        filterOperators: getGridDateOperatorsDataGrid,
      },
      { field: "street", headerName: "Street", width: 200, editable: true },
      { field: "city", headerName: "City", width: 200, editable: true },
      { field: "state", headerName: "State", width: 200, editable: true },
      { field: "zipCode", headerName: "Zip Code", width: 200, editable: true },
      {
        field: "description",
        headerName: "Description",
        width: 200,
        editable: true,
      },
      {
        field: "brandId",
        headerName: "Brand Id",
        editable: false,
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        valueFormatter: value => value && `${value?.toString()}`,

        renderCell: params => params.row.brand?.id.toString(),
        valueGetter: (value, row) => row.brand?.id,
      },
      {
        field: "brand",
        headerName: "Brand",
        editable: true,
        type: "string",
        renderEditCell: params => <EditBrandArea {...params} />,
        valueGetter: (value, row) => row.brand?.name,
        valueSetter: (value, row) => {
          return {
            ...row,
            brand: value,
          };
        },
        renderCell: params => {
          return params.row?.brand?.name;
        },
        width: 200,
      },
      {
        field: "familyOfBrandsId",
        headerName: "Family of Brands Id",
        editable: false,
        type: "number",
        renderCell: params => params.row.familyOfBrands?.id.toString(),
        valueFormatter: value => value && `${value?.toString()}`,
        filterOperators: getGridNumericOperatorsDataGrid,
        valueGetter: (value, row) => row.familyOfBrands?.id,
      },
      {
        field: "familyOfBrands",
        valueGetter: (value, row) => row.familyOfBrands?.name,
        valueSetter: (value, row) => {
          return {
            ...row,
            familyOfBrands: {
              ...row.profile,
              name: value,
            },
          };
        },
        headerName: "Family of Brands",
        width: 200,
      },
      {
        field: "privateEquityId",
        headerName: "Private Equity Id",
        editable: false,
        type: "number",
        renderCell: params => params.row.privateEquity?.id.toString(),
        valueFormatter: value => value && `${value?.toString()}`,
        filterOperators: getGridNumericOperatorsDataGrid,
        valueGetter: (value, row) => row.privateEquity?.id,
      },
      {
        field: "privateEquity",
        headerName: "Private Equity",
        valueGetter: (value, row) => row.privateEquity?.name,
        valueSetter: (value, row) => {
          return {
            ...row,
            privateEquity: {
              ...row.profile,
              name: value,
            },
          };
        },
        width: 200,
      },
      {
        field: "deletedAt",
        headerName: "Date Deleted",
        type: "date",
        editable: false,
        valueFormatter: formatDate,
        width: 200,
        filterOperators: getGridDateOperatorsDataGrid,
      },
      {
        field: "ownersInformationName",
        headerName: "Owner's Name",
        width: 200,
        type: "string",
        headerAlign: "left",
        valueGetter: (value, row) => row?.ownersInformation?.[0]?.[0],
        renderCell: params => params.row?.ownersInformation?.[0]?.[0],
      },
      {
        field: "ownersInformationEmail",
        headerName: "Owner's Email",
        width: 200,
        type: "string",
        headerAlign: "left",
        valueGetter: (value, row) => row?.ownersInformation?.[0]?.[3],
        renderCell: params => params.row?.ownersInformation?.[0]?.[3],
      },
      {
        field: "ownersInformationPhone",
        headerName: "Owner's Phone",
        width: 200,
        type: "string",
        headerAlign: "left",
        valueGetter: (value, row) => row?.ownersInformation?.[0]?.[1],
        renderCell: params => params.row?.ownersInformation?.[0]?.[1],
      },
      {
        field: "ownersInformationPhoneCarrierType",
        headerName: "Owner's Phone Carrier Type",
        width: 200,
        type: "singleSelect",
        valueOptions: [...Object.keys(CarrierType)],
        headerAlign: "left",
        valueGetter: (value, row) => row?.ownersInformation?.[0]?.[2],
        renderCell: params => params.row?.ownersInformation?.[0]?.[2],
      },
      {
        field: "notes",
        headerName: "Company Notes",
        width: 200,
        type: "string",
        headerAlign: "left",
        editable: true,
        renderEditCell: params => <EditNotesArea {...params} user={user} />,
      },
      {
        field: "fssLogoBucket",
        headerName: "FSS Logo Bucket",
        width: 200,
        editable: true,
      },
      {
        field: "fssLogoKey",
        headerName: "FSS Logo Key",
        width: 200,
        editable: true,
      },
      {
        field: "stripeCustomerId",
        headerName: "Stripe Customer Id",
        width: 200,
      },

      {
        field: "stripeCardId",
        headerName: "Stripe Card Id",
        width: 200,
      },
      {
        field: "totalUsers",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Users",
        description:
          "Total number of users both ON_PLATFORM and OFF_PLATFORM, this number is equal to the sum of Total Users Invited + Total Users Onboarded + Total Users Not Invited Nor Onboarded",
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        field: "totalUsersInvited",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Users Invited",
        description:
          "Total number of users that are currently invited, and haven't onboarded yet",
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        field: "totalUsersOnboarded",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Users Onboarded",
        description: "Total number of users that are Onboarded",
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        field: "totalUsersNotInvitedNorOnboarded",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Users Not Invited Nor Onboarded",
        description: "Total number of users that aren't invited nor onboarded",
        width: 200,
        filterable: false,
        sortable: false,
      },

      {
        field: "totalTechnicians",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Technicians",
        description:
          "Total number of Technicians both ON_PLATFORM and OFF_PLATFORM, this number is equal to the sum of Total Technicians Invited + Total Technicians Onboarded + Total Technicians Not Invited Nor Onboarded",
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        field: "totalTechniciansInvited",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Technicians Invited",
        description:
          "Total number of Technicians that are currently invited, and haven't onboarded yet",
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        field: "totalTechniciansOnboarded",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Technicians Onboarded",
        description: "Total number of Technicians that are Onboarded",
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        field: "totalTechniciansNotInvitedNorOnboarded",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Technicians Not Invited Nor Onboarded",
        description:
          "Total number of Technicians that aren't invited nor onboarded",
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        field: "totalRecommendedPartners",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Recommended Partners",
        width: 200,
      },
      {
        field: "totalActiveReferrals",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Number of Referral Partners Sectors",
        width: 200,
        description:
          "Total number of active referrals, not unique referral partners, if a company has 1 referral partner that does Painting and HVAC, then it has 2 active referrals.",
      },
      {
        field: "totalActiveReferralPartners",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Unique Active Referral Partners",
        width: 200,
        sortable: false,
        filterable: false,
        editable: false,
        description:
          "Total number of unique active referral partners, if a company has 1 referral partner that does Painting and HVAC, then it has 1 unique referral partner. It can be calculated by adding Total Accepted Invitations Sent + Total Accepted Invitations Received - Total Unique Referral Partners Removed + Total Unique Referral Partners Created Manually",
      },
      {
        field: "totalReferralsDeleted",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Unique Referral Partners Removed",
        width: 200,
        sortable: false,
        filterable: false,
      },
      {
        field: "totalReferralsCreatedWithoutInvitation",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Unique Referral Partners Created Manually",
        width: 200,
        sortable: false,
        filterable: false,
        description:
          "Total number of unique referral partners created manually, if a company has 1 referral partner that does Painting and HVAC, then it's counted as one",
      },
      {
        field: "totalReferralInvitationsReceived",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Referral Invitations Received",
        width: 200,
      },
      {
        field: "totalPendingInvitationsReceived",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Pending Invitations Received",
        width: 200,
      },

      {
        field: "totalDeclinedInvitationsReceived",
        headerName: "Total Declined Invitations Received",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        width: 200,
      },
      {
        field: "totalAcceptedInvitationsReceived",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Accepted Invitations Received",
        width: 200,
      },
      {
        field: "totalReferralInvitationsSent",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Referral Invitations Sent",
        width: 200,
      },
      {
        field: "totalPendingInvitationsSent",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Pending Invitations Sent",
        width: 200,
      },
      {
        field: "totalAcceptedInvitationsSent",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Accepted Invitations Sent",
        width: 200,
      },
      {
        field: "totalDeclinedInvitationsSent",
        headerName: "Total Declined Invitations Sent",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        width: 200,
      },
      {
        field: "referralInvitationConversionRate",
        headerName: "Referral Invitation Conversion Rate",
        type: "number",
        valueFormatter: value => value && `${value?.toString()}%`,
        renderCell: params =>
          params.row.referralInvitationConversionRate
            ? `${params.row.referralInvitationConversionRate?.toString()}%`
            : null,

        description:
          "Percentage of referral invitations sent that were converted into referrals",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        width: 200,
        sortable: false,
        filterable: false,
      },
      {
        field: "totalJobsSent",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Jobs Submitted",
        width: 200,
      },
      {
        field: "totalJobsReceived",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Jobs Received",
        width: 200,
      },
      {
        field: "totalJobsApproved",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Jobs Approved",
        width: 200,
      },
      {
        field: "totalJobsDeclined",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Total Jobs Declined",
        width: 200,
      },
      {
        field: "totalEarnings",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        valueFormatter: value => value && `$${value?.toString()}`,
        renderCell: params =>
          params.row.totalEarnings &&
          `$${params.row.totalEarnings?.toString()}`,

        headerAlign: "left",
        headerName: "Total Earnings",
        width: 200,
        description:
          "Total earnings of all users, this number is equal to the sum of Total Technician Earnings + Total Company Earnings, it includes earnings from jobs taht didn't convert and TradeEngage paid",
      },
      {
        field: "totalCompanyEarnings",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        valueFormatter: value => value && `$${value?.toString()}`,
        renderCell: params =>
          params.row.totalCompanyEarnings &&
          `$${params.row.totalCompanyEarnings?.toString()}`,
        headerAlign: "left",
        headerName: "Total Company Earnings",
        width: 200,
        filterable: false,
        sortable: false,
        description:
          "Total earnings of the company, this number is equal to Total Earnings - Total Technician Earnings",
      },
      {
        field: "totalTechnicianEarnings",
        type: "number",
        filterOperators: getGridNumericOperatorsDataGrid,
        valueFormatter: value => value && `$${value?.toString()}`,
        renderCell: params =>
          params.row.totalTechnicianEarnings &&
          `$${params.row.totalTechnicianEarnings?.toString()}`,
        headerAlign: "left",
        headerName: "Total Technician Earnings",
        width: 200,
        description:
          "Total earnings of all technicians (in this context it means whoever has sent a job), it includes earnings from jobs taht didn't convert and TradeEngage paid",
      },
      {
        field: "averageTechnicianEarnings",
        type: "number",
        valueFormatter: value => value && `$${value?.toString()}`,
        renderCell: params =>
          params.row.averageTechnicianEarnings &&
          `$${params.row.averageTechnicianEarnings?.toString()}`,
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Average Technician Earnings",
        width: 200,
        description:
          "Average Technician Earnings, this is calculated by dividing the technician earnings by the number of technicians",
      },
      {
        field: "averageTechnicianEarningsPerJob",
        type: "number",
        valueFormatter: value => value && `$${value?.toString()}`,
        renderCell: params =>
          params.row.averageTechnicianEarnings &&
          `$${params.row.averageTechnicianEarnings?.toString()}`,
        filterOperators: getGridNumericOperatorsDataGrid,
        headerAlign: "left",
        headerName: "Average Technician Earnings Per Job",
        width: 200,
        description:
          "Average earnings of all technicians (in this context it means whoever has sent a job), it includes earnings from jobs taht didn't convert and TradeEngage paid",
      },
      {
        field: "datetimeLastJobReceived",
        headerName: "Date Last Job Received",
        type: "date",
        valueFormatter: formatDate,
        width: 200,
        filterOperators: getGridDateOperatorsDataGrid,
      },
      {
        field: "daysSinceReceivingLastJob",
        headerName: "Days Since Receiving Last Job",
        type: "number",
        width: 200,
        filterOperators: getGridNumericOperatorsDataGrid,
      },
      {
        field: "datetimeLastJobSent",
        headerName: "Date Last Job Submitted",
        type: "date",
        valueFormatter: formatDate,
        width: 200,
        filterOperators: getGridDateOperatorsDataGrid,
      },
      {
        field: "daysSinceSubmittingLastJob",
        headerName: "Days Since Submitting Last Job",
        type: "number",
        width: 200,
        filterOperators: getGridNumericOperatorsDataGrid,
      },
      {
        field: "createdAt",
        headerName: "Date Created",
        type: "date",
        valueFormatter: formatDate,
        width: 200,
        filterOperators: getGridDateOperatorsDataGrid,
      },
      {
        field: "activatedAt",
        headerName: "Date Activated",
        type: "date",
        valueFormatter: formatDate,
        width: 200,
        description:
          "Date the company's status was marked as ACTIVE on the platform",
        filterOperators: getGridDateOperatorsDataGrid,
      },
      {
        field: "markedOnPlatformAt",
        headerName: "Date Marked On PLatform",
        type: "date",
        valueFormatter: formatDate,
        description:
          "Date the company's platform presence was marked as ON_PLATFORM on the platform",
        width: 200,
        filterOperators: getGridDateOperatorsDataGrid,
      },
      {
        field: "status",
        editable: true,
        headerName: "Status",
        width: 200,
      },
      {
        field: "invitedByCompanyId",
        headerName: "Invited By Company Id",
        width: 200,
        filterable: false,
        sortable: false,
        description:
          "Inviting company for this company (A), if company A onboarded through a referral invitation we use the company that sent the referral invitation, if not we use the first company that sent the first referral invitation to company A, before company A was activated.",
        editable: false,
        type: "number",
        renderCell: params => params.row.invitedByCompany?.id.toString(),
        valueFormatter: value => value && `${value?.toString()}`,
        valueGetter: (value, row) => row.invitedByCompany?.id,
      },
      {
        field: "invitedByCompanyName",
        headerName: "Invited By Company Name",
        width: 200,
        editable: false,
        filterable: false,
        sortable: false,
        type: "string",
        description:
          "Inviting company for this company (A), if company A onboarded through a referral invitation we use the company that sent the referral invitation, if not we use the first company that sent the first referral invitation to company A, before company A was activated.",
        valueGetter: (value, row) => row.invitedByCompany?.name,
      },
      {
        field: "channelOnboarded",
        headerName: "Channel Onboarded",
        width: 200,
        editable: false,
        filterable: false,
        sortable: false,
        type: "singleSelect",
        valueOptions: [...Object.keys(CHANNEL_ONBOARDED)],
        description:
          "Channel onboarded for this company (A), if company A onboarded through a referral invitation we use the channel REFERRAL_INVITATION, CAMPAIGN if they onboarded through a campaign, and LANDING_PAGE for the others.",
      },
      {
        field: "lastReferralInvitationReceivedAt",
        headerName: "Date Last Referral Invitation Received",
        type: "date",
        valueFormatter: formatDate,
        width: 200,
        sortable: false,
        filterable: false,
        editable: false,
        description: "Date of the last referral invitation received",
      },
      {
        field: "lastReferralInvitationSentAt",
        headerName: "Date Last Referral Invitation Sent",
        type: "date",
        valueFormatter: formatDate,
        width: 200,
        sortable: false,
        filterable: false,
        editable: false,
        description: "Date of the last referral was sent",
      },

      {
        field: "lastReferralPaymentReceivedAt",
        headerName: "Date Last Referral Payment Received",
        type: "date",
        valueFormatter: formatDate,
        width: 200,
        sortable: false,
        filterable: false,
        editable: false,
        description: "Date of the last referral payment was received",
      },
      {
        field: "lastReferralPaymentReceivedAmount",
        headerName: "Amount Last Referral Payment Received",
        type: "number",
        valueFormatter: value => value && `$${value?.toString()}`,
        renderCell: params =>
          params.row.lastReferralPaymentReceivedAmount &&
          `$${params.row.lastReferralPaymentReceivedAmount?.toString()}`,
        width: 200,
        sortable: false,
        filterable: false,
        editable: false,
        description: "Amount of the last referral payment was received",
      },
      {
        field: "lastReferralPaymentSentAt",
        headerName: "Date Last Referral Payment Sent",
        type: "date",
        valueFormatter: formatDate,
        width: 200,
        sortable: false,
        filterable: false,
        editable: false,
        description: "Date of the last referral payment was sent",
      },
      {
        field: "lastReferralPaymentSentAmount",
        headerName: "Amount Last Referral Payment Sent",
        type: "number",
        valueFormatter: value => value && `$${value?.toString()}`,
        renderCell: params =>
          params.row.lastReferralPaymentSentAmount &&
          `$${params.row.lastReferralPaymentSentAmount?.toString()}`,
        width: 200,
        sortable: false,
        filterable: false,
        editable: false,
        description: "Amount of the last referral payment was sent",
      },
      {
        field: "priority",
        headerName: "Priority",
        width: 200,
        editable: true,
        type: "singleSelect",
        valueOptions: [...Object.keys(COMPANY_PRIORITY)],
        description: "Company Priority.",
      },
      {
        field: "nextSteps",
        headerName: "Next Steps",
        width: 200,
        editable: true,
        type: "string",
        description: "Next steps to follow up with the company.",
      },
      {
        field: "tradeEngageOwner",
        headerName: "Trade Engage Owner",
        width: 200,
        editable: true,
        type: "singleSelect",
        valueOptions: [
          "andrew.fusselbaugh@tradeengage.com",
          "chelsea.fusselbaugh@tradeengage.com",
          "victor.castillo@tradeengage.com",
          "rahul.mehra@tradeengage.com",
          "zarrar.shaikh@tradeengage.com",
          "vikas.parsa@tradeengage.com",
        ],
        description: "Company's Trade Engage Owner.",
      },
      {
        field: "teamTrainingDate",
        headerName: "Team Training Date",
        width: 200,
        editable: true,
        type: "date",
        valueFormatter: value =>
          value && DateTime.fromJSDate(value).toFormat("MM/dd/yy"),
        filterOperators: getGridDateOperatorsDataGrid,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        valueGetter: (value, _row) => {
          return value ? new Date(value) : null;
        },
        valueSetter: (value, row) => {
          return {
            ...row,
            teamTrainingDate: value
              ? DateTime.fromJSDate(value).toISODate()
              : null,
          };
        },
      },
      ...(filters.archived
        ? []
        : [
            {
              field: "actions",
              type: "actions",
              headerName: "Actions",
              width: 100,
              cellClassName: "actions",
              getActions: ({ id, row }) => {
                return [
                  <GridActionsCellItem
                    key={`${id}-delete`}
                    icon={<DeleteIcon />}
                    label="Delete"
                    color="inherit"
                    onClick={() => {
                      setDeleteRowName(row.name);
                      setDeleteRowId(id);
                    }}
                  />,
                ];
              },
            },
          ]),
    ];

    return [
      ...gridColumnDefs,
      ...profileFields,
      ...fddSourceFields,
      ...serpSourceFields,
      ...bniSourceFields,
      ...websiteSourceFields,
      ...alignableSourceFields,
    ];
  }, [filters.archived, user]);

  const [columnOrder, setColumnOrder] = useState(() => {
    const savedColumnOrder = localStorage.getItem(
      "company-datagridColumnOrder"
    );
    if (isDefined(savedColumnOrder)) {
      const savedColumnOrderJson = JSON.parse(savedColumnOrder);
      return [
        ...savedColumnOrderJson,
        ...columns
          .filter(column => !savedColumnOrderJson.includes(column.field))
          .map(column => column.field),
      ];
    }

    return columns.map(column => column.field);
  });

  const [columnWidths, setColumnWidths] = useState<Record<string, number>>(
    () => {
      const savedColumnWidths = localStorage.getItem(
        "company-datagridColumnWidths"
      );
      return savedColumnWidths
        ? JSON.parse(savedColumnWidths)
        : columns.reduce<Record<string, number>>((acc, col) => {
            acc[col.field] = col.width ?? 200;
            return acc;
          }, {});
    }
  );

  useEffect(() => {
    localStorage.setItem(
      "company-datagridColumnWidths",
      JSON.stringify(columnWidths)
    );
  }, [columnWidths]);

  const handleColumnResize = (newColumn: any) => {
    setColumnWidths(prev => ({
      ...prev,
      [newColumn.colDef.field]: newColumn.width,
    }));
  };

  const orderedColumns = useMemo(() => {
    const finalColumns = columns.map(column => {
      return {
        ...column,
        width: columnWidths?.[column.field] ?? column.width,
        ...(!column.type || column.type === "string"
          ? { filterOperators: getGridStringOperatorsDataGrid }
          : {}),
      };
    });

    return columnOrder
      .map((field: string) => finalColumns.find(col => col.field === field))
      .filter(col => col !== undefined);
  }, [columnOrder, columns, columnWidths]);

  const apiRef = useGridApiRef();

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const [referralInvitationOpen, setReferralInvitationOpen] = useState(false);
  const [referralOpen, setReferralOpen] = useState(false);

  const [createRelationshipsOpen, setCreateRelationshipsOpen] = useState(false);
  const [createTableViewDialogOpen, setCreateTableViewDialogOpen] =
    useState(false);

  const handleSortModelChange = (model: GridSortModel) => {
    setServerSorting(model);
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    setServerFiltering(model);
  };

  useEffect(() => {
    refetch();
  }, [serverSorting, refetch, serverFiltering]);

  const unsavedChangesRef = React.useRef<{
    unsavedRows: Record<GridRowId, GridValidRowModel>;
    rowsBeforeChange: Record<GridRowId, GridValidRowModel>;
  }>({
    unsavedRows: {},
    rowsBeforeChange: {},
  });
  const [isSaving, setIsSaving] = React.useState(false);

  const handleColumnOrderChange = useCallback(
    (params: GridColumnOrderChangeParams) => {
      const { column, targetIndex } = params;

      const newColumnOrder = [...columnOrder];
      const columnIndex = newColumnOrder.indexOf(column.field);

      const [movedColumn] = newColumnOrder.splice(columnIndex, 1);
      newColumnOrder.splice(targetIndex, 0, movedColumn);

      setColumnOrder(newColumnOrder);
      localStorage.setItem(
        "company-datagridColumnOrder",
        JSON.stringify(newColumnOrder)
      );
    },
    [columnOrder]
  );

  useEffect(() => {
    if (keyword.length >= SEARCH_MINIMUM_CHAR_COUNT || keyword === "") {
      debouncedSetter(keyword);
    }
  }, [debouncedSetter, keyword]);

  useEffect(() => {
    if (preFilledColumns) {
      const newColumnWidths = preFilledColumns.reduce((acc, col) => {
        acc[col.field] = col.width;
        return acc;
      }, {});
      setColumnWidths(newColumnWidths);
      setColumnVisibilityModel(
        columns.reduce((acc, col) => {
          acc[col.field] = preFilledColumns.some(
            preFilledColumn => preFilledColumn.field === col.field
          );
          return acc;
        }, {})
      );
      for (const column of preFilledColumns) {
        apiRef.current.setColumnIndex(column.field, column.position);
        apiRef.current.pinColumn(column.field, column.pinned);
      }
    }
  }, [preFilledColumns, apiRef]);

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (params.field === "name") setCompanyProfileId(params.row.id);
  };

  return (
    <Box display={"flex"} sx={{ height: "100%" }} overflow={"auto"}>
      <Main
        open={!!companyProfileId}
        sx={{
          width: expanded ? "0%" : `calc(100% - ${drawerWidth}px)`,
          display: expanded ? "none" : "visible",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
          p={3}
        >
          {name ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                href="/admin/saved-searches"
              >
                Saved Searches
              </Link>
              <Link underline="hover" color="inherit">
                {name}
              </Link>
            </Breadcrumbs>
          ) : null}
          <Typography variant="h5">Local Companies and Franchises</Typography>
        </Box>
        {!hideCriteria &&
        Object.keys(filters)?.length &&
        !(Object.keys(filters)?.length === 1 && !_.isNil(filters.keyword)) ? (
          <Box
            display={"flex"}
            gap={1}
            alignItems={"center"}
            flexWrap={"wrap"}
            px={3}
          >
            {getFilterChips()}{" "}
            <Button
              sx={{ color: "red", mr: 2 }}
              startIcon={<CloseOutlinedIcon />}
              onClick={() => {
                removeLocations();
                removeAllFilter();
              }}
            >
              Clear All
            </Button>
          </Box>
        ) : null}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={3}
          py={1}
        >
          <Box
            display={"flex"}
            gap={3}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="subtitle1" fontSize={"16px"}>
              {companies?.total
                ? `Showing ${Intl.NumberFormat("en-US").format(
                    companies?.total ?? 0
                  )} companies`
                : "Fetching results"}
            </Typography>
            {Object.keys(filters)?.length &&
            !(
              Object.keys(filters)?.length === 1 && !_.isNil(filters.keyword)
            ) &&
            !hideCriteria ? (
              <Button
                variant="outlined"
                size="small"
                startIcon={<KeyboardArrowUpOutlinedIcon />}
                onClick={() => {
                  setHideCriteria(true);
                }}
              >
                Hide Criteria
              </Button>
            ) : null}
            {Object.keys(filters)?.length &&
            !(
              Object.keys(filters)?.length === 1 && !_.isNil(filters.keyword)
            ) &&
            hideCriteria ? (
              <Button
                variant="outlined"
                size="small"
                startIcon={<KeyboardArrowDownOutlinedIcon />}
                onClick={() => {
                  setHideCriteria(false);
                }}
              >
                Show Criteria
              </Button>
            ) : null}
          </Box>
          <Box
            display={"flex"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              startIcon={<BookmarkAddOutlinedIcon />}
              variant="outlined"
              onClick={() => {
                setCreateTableViewDialogOpen(true);
              }}
            >
              Save Search
            </Button>
            <Button
              startIcon={<FilterAltOutlinedIcon />}
              variant="contained"
              onClick={() => {
                navigate(`/admin/search-criteria`);
              }}
            >
              Add Search Criteria
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 260px)",
            position: "relative",
            px: 3,
          }}
        >
          <TextField
            sx={{
              width: companyProfileId ? "30%" : "50%",
              position: {
                xs: "relative",
              },
              zIndex: 100,
            }}
            variant="outlined"
            size={"small"}
            name="filterKeyword"
            placeholder="Search by company name or keyword"
            value={keyword}
            //   onKeyUp={handleKeyUp}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {keyword ? (
                    // eslint-disable-next-line react/jsx-sort-props
                    <CloseIcon
                      fontSize="small"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setKeyword("");
                        removeFilter("keyword");
                      }}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
            onChange={event => {
              setKeyword(event.target.value);
            }}
          />
          <Box
            sx={{
              position: "relative",
              mt: 2,
            }}
          >
            <Box display={"flex"} gap={2} mb={1} position={"relative"}>
              <LoadingButton
                size="small"
                disabled={!hasUnsavedRows}
                loading={isSaving}
                variant="contained"
                loadingPosition="start"
                onClick={saveChanges}
              >
                Save
              </LoadingButton>
              <LoadingButton
                size="small"
                disabled={!hasUnsavedRows || isSaving}
                variant={!hasUnsavedRows || isSaving ? "contained" : "outlined"}
                onClick={discardChanges}
              >
                Discard Changes
              </LoadingButton>
              <LoadingButton
                startIcon={<Add />}
                size="small"
                variant={"contained"}
                onClick={() => {
                  setReferralInvitationOpen(true);
                }}
              >
                Create Referral Invitation
              </LoadingButton>
              <LoadingButton
                startIcon={<Add />}
                size="small"
                variant={"contained"}
                onClick={() => {
                  setReferralOpen(true);
                }}
              >
                Create Referral
              </LoadingButton>
              <LoadingButton
                startIcon={<Add />}
                size="small"
                variant={"contained"}
                onClick={() => {
                  setCreateRelationshipsOpen(true);
                }}
              >
                Create Company Relationships
              </LoadingButton>
            </Box>
            <Box height={"1000px"}>
              <DataGridPremium
                checkboxSelection
                disableRowSelectionOnClick
                cellSelection
                keepNonExistentRowsSelected
                pagination
                disableAggregation
                disableRowGrouping
                filterMode={"server"}
                filterModel={serverFiltering}
                sortingMode={"server"}
                columns={orderedColumns}
                loading={isFetching || isLoading}
                rows={rows ?? []}
                slots={{
                  toolbar: () => (
                    <CustomToolbar
                      fileName="BrandLocations"
                      includeQuickFilter={false}
                    />
                  ),
                }}
                sx={{
                  "& .MuiDataGrid-row": {
                    "--rowBorderColor": "#D9D9D9",
                  },
                  "& .MuiDataGrid-cell--withRightBorder": {
                    borderRightColor: "#D9D9D9",
                  },
                  "& .MuiDataGrid-cell--withLeftBorder": {
                    borderLeftColor: "#D9D9D9",
                  },
                  "& .MuiDataGrid-topContainer": {
                    background: "#F8F9FA",
                  },
                }}
                sortModel={serverSorting}
                apiRef={apiRef}
                columnVisibilityMenuProps={{
                  disableColumnReorder: true, // Disable reordering in the Hide Columns menu
                }}
                columnVisibilityModel={columnVisibilityModel}
                processRowUpdate={processRowUpdate}
                initialState={{
                  pinnedColumns: {
                    left: [
                      GRID_CHECKBOX_SELECTION_COL_DEF.field,
                      "logo",
                      "name",
                    ],
                    right: ["actions"],
                  },
                  density: "compact",
                }}
                paginationModel={paginationModel}
                paginationMode="server"
                rowCount={companies?.total}
                pageSizeOptions={[5, 10, 25, 50, 100, 250, 500, 5000]}
                disableMultipleColumnsSorting={false}
                columnOrder={columnOrder}
                onColumnVisibilityModelChange={newModel =>
                  setColumnVisibilityModel(newModel)
                }
                onColumnOrderChange={params => handleColumnOrderChange(params)}
                onFilterModelChange={handleFilterModelChange}
                onColumnWidthChange={handleColumnResize}
                onSortModelChange={handleSortModelChange}
                onCellClick={handleCellClick}
                onPaginationModelChange={setPaginationModel}
              />
            </Box>
          </Box>
        </Box>
      </Main>
      {expanded && companyProfileId ? (
        <AdminChatProvider companyId={companyProfileId}>
          <InternalAdminCompanyDrawer
            open={!!companyProfileId}
            companyProfileId={companyProfileId}
            connectionStatus={ConnectionStatus.CONNECTED}
            expanded={expanded}
            refetchCompanies={() => refetch()}
            onClose={() => {
              setCompanyProfileId(null);
              setExpanded(false);
            }}
            onExpand={() => setExpanded(prev => !prev)}
          />
        </AdminChatProvider>
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!companyProfileId}
        >
          {companyProfileId ? (
            <AdminChatProvider companyId={companyProfileId}>
              <InternalAdminCompanyDrawer
                open={!!companyProfileId}
                expanded={expanded}
                companyProfileId={companyProfileId}
                connectionStatus={ConnectionStatus.CONNECTED}
                refetchCompanies={() => refetch()}
                onClose={() => {
                  setCompanyProfileId(null);
                  setExpanded(false);
                }}
                onExpand={() => setExpanded(prev => !prev)}
              />
            </AdminChatProvider>
          ) : null}
        </Drawer>
      )}

      <DeleteConfirmationDialog
        open={!!deleteRowId}
        text={deleteRowName}
        handleClose={() => setDeleteRowId(null)}
        handleConfirm={() => {
          if (deleteRowId) {
            handleDeleteClick(deleteRowId);
          }
          setDeleteRowId(null);
          setDeleteRowName("");
        }}
      />
      {referralInvitationOpen ? (
        <CreateReferralInvitationDialog
          open={referralInvitationOpen}
          refetch={() => refetch()}
          onClickConfirm={() => setReferralInvitationOpen(false)}
          onClickCancel={() => setReferralInvitationOpen(false)}
          onClickClose={() => setReferralInvitationOpen(false)}
        />
      ) : null}
      {referralOpen ? (
        <CreateReferralDialog
          open={referralOpen}
          refetch={() => refetch()}
          onClickConfirm={() => setReferralOpen(false)}
          onClickCancel={() => setReferralOpen(false)}
          onClickClose={() => setReferralOpen(false)}
        />
      ) : null}
      {createRelationshipsOpen ? (
        <CreateRelationshipDialog
          open={createRelationshipsOpen}
          refetch={() => refetch()}
          onClickConfirm={() => setCreateRelationshipsOpen(false)}
          onClickCancel={() => setCreateRelationshipsOpen(false)}
          onClickClose={() => setCreateRelationshipsOpen(false)}
        />
      ) : null}
      {createTableViewDialogOpen ? (
        <CreateTableViewDialog
          isOpen={createTableViewDialogOpen}
          creatingTableView={creatingTableView}
          onCreate={({
            name,
            description,
          }: {
            name: string;
            description?: string;
          }) => {
            const payload = {
              page: paginationModel.page,
              size: paginationModel.pageSize,
              filters,
              serverSorting,
              serverFiltering,
              columnOrder,
            };
            const columns = apiRef.current.getVisibleColumns();
            const columnsPayload = columns.map(column => ({
              field: column.field,
              width: column.width,
              position: apiRef.current.getColumnPosition(column.field),
              pinned: apiRef.current.isColumnPinned(column.field),
            }));
            createTableView({
              name,
              tableName: TableType.COMPANY,
              description,
              body: payload,
              columns: columnsPayload,
            });
            setCreateTableViewDialogOpen(false);
          }}
          onClose={() => setCreateTableViewDialogOpen(false)}
        />
      ) : null}
    </Box>
  );
}
