import { GroupsOutlined as GroupsOutlinedIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useField } from "formik";
import * as yup from "yup";

import { useGetMyBrands } from "@/brand-overlap/api/useGetMyBrands";
import { AutocompleteField } from "@/lib/form-fields/AutocompleteField";
import { CheckboxGroupField } from "@/lib/form-fields/CheckboxGroupField";

import { AddReferralCampaignFormInputField } from "./AddReferralCampaignFormInputField";

export const referralCampaignBrandOptions = ["All", "Specific Brands"];

export const referralCampaignBrandingFormSchema = yup.object().shape({
  brands: yup
    .array()
    .of(yup.string().required())
    .required("Campaign Branding is required")
    .min(1, "Campaign Branding is required"),
  specificBrands: yup
    .array()
    .of(yup.string().required())
    .when("brands", {
      is: (brands: string[]) => brands.includes("Specific Brands"),
      then: s =>
        s
          .required("Specific Brands are required")
          .min(1, "Specific Brands are required"),
      otherwise: s => s.nullable(),
    }),
});

export type ReferralCampaignBrandingFormFields = yup.InferType<
  typeof referralCampaignBrandingFormSchema
>;

export function ReferralCampaignBrandingForm() {
  const [field] = useField("brands");

  const { data: brands = [] } = useGetMyBrands();

  return (
    <AddReferralCampaignFormInputField
      title="Brand(s)"
      icon={<GroupsOutlinedIcon />}
      includeConnector={false}
    >
      <Box sx={{ marginTop: 2 }}>
        <CheckboxGroupField
          label="SELECT AT LEAST ONE"
          name="brands"
          options={referralCampaignBrandOptions.map(option => ({
            label: option,
            value: option,
          }))}
        />
        {field.value?.includes("Specific Brands") && (
          <Box sx={{ paddingX: 4 }}>
            <AutocompleteField
              fullWidth
              multiple
              name="specificBrands"
              label="Enter Specific Brands"
              placeholder="Enter Specific Brands"
              options={brands?.map(brand => ({
                label: brand.name,
                value: brand.id.toString(),
              }))}
            />
          </Box>
        )}
      </Box>
    </AddReferralCampaignFormInputField>
  );
}
