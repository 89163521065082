/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { CallType } from "@/company/api/useFetchCallRecordings";

export interface JobTypeSelectionProps {
  setReferralType: (type: CallType) => void;
  onNext: () => void;
  onCancel: () => void;
  referralType: CallType;
}

export function JobTypeSelection(props: JobTypeSelectionProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box width="100%" pt={3}>
      <Typography gutterBottom variant="subtitle1">
        What type of job is this?
      </Typography>
      <Box
        display={"flex"}
        gap={isMobile ? 1 : 2}
        alignItems={"baseline"}
        flexWrap={"wrap"}
        flexDirection={"column"}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="referral-type-row-radio-buttons-group-label"
            name="referral-type-row-radio-buttons-group"
            value={props.referralType}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setReferralType(
                (event.target as HTMLInputElement).value as CallType
              )
            }
          >
            <FormControlLabel
              value={CallType.OUTBOUND}
              control={<Radio />}
              label={
                <Box display={"flex"} flexDirection={"column"} gap={0.5} mt={3}>
                  <Typography variant="body1">Outbound Job</Typography>
                  <Typography variant="body2" color={"text.secondary"}>
                    I want to refer this job
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              value={CallType.INBOUND}
              control={<Radio />}
              label={
                <Box display={"flex"} flexDirection={"column"} gap={0.5} mt={3}>
                  <Typography variant="body1">Inbound Job</Typography>
                  <Typography variant="body2" color={"text.secondary"}>
                    This job was referred to me
                  </Typography>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box display={"flex"} gap={1} mt={2}>
        <Button
          variant="outlined"
          onClick={() => {
            props.onCancel();
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            props.onNext();
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
