import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
} from "@mui/material";
import { useField } from "formik";

import { isDefined } from "@/utils/isDefined";

interface CheckboxGroupFieldProps {
  label?: string;
  name: string;
  checkboxSize?: CheckboxProps["size"];
  options: {
    label: string;
    value: string;
  }[];
}

export function CheckboxGroupField(props: CheckboxGroupFieldProps) {
  const { label, name, checkboxSize = "medium", options } = props;

  const [field, meta, helpers] = useField(name);

  return (
    <FormControl>
      {isDefined(label) && <FormLabel sx={{ mb: 1 }}>{label}</FormLabel>}
      <FormGroup>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={field.value.includes(option.value)}
                size={checkboxSize}
                onChange={(_event, checked) => {
                  helpers.setTouched(true);
                  if (checked) {
                    helpers.setValue([...field.value, option.value]);
                  } else {
                    helpers.setValue(
                      field.value.filter(
                        (value: string) => value !== option.value
                      )
                    );
                  }
                }}
              />
            }
            label={<Typography variant="body2">{option.label}</Typography>}
            onBlur={field.onBlur}
          />
        ))}
      </FormGroup>
      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}
