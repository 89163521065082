/* eslint-disable jsx-a11y/media-has-caption */
import { Close as CloseIcon } from "@mui/icons-material";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";

import { isDefined } from "@/utils/isDefined";

import AudioRecorder from "../AudioRecorder";

interface JobDetailsAudioFormProps {
  videoFile: File | null;
  setVideoFile: (file: File | null) => void;
  isCreatingJob: boolean;
}

export function JobDetailsAudioForm(props: JobDetailsAudioFormProps) {
  const { setVideoFile, videoFile, isCreatingJob } = props;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleAudioFileChange = (file: File | null) => {
    setVideoFile(file);
  };

  return (
    <>
      <Box display={"flex"} gap={1} flexDirection={"column"}>
        <Typography gutterBottom variant="body2" color="text.secondary">
          Record a brief audio.{" "}
          <Typography
            variant="body2"
            display={"inline"}
            fontWeight={700}
            color="primary"
          >
            Speak clearly for the best results!
          </Typography>
        </Typography>
        {videoFile ? null : (
          <Box display={"flex"} flexDirection={"column"} pt={3}>
            <AudioRecorder
              setAudioURL={(file: File | null) => handleAudioFileChange(file)}
            />
          </Box>
        )}
      </Box>
      {isDefined(videoFile) && !isCreatingJob && (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            width: "100%",
            gap: 1,
            height: "64px",
          }}
        >
          <audio
            controls
            controlsList="nodownload"
            src={`${window.URL.createObjectURL(videoFile)}`}
            style={{ width: isMobile ? "90%" : "300px" }}
          />
          <CloseIcon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setVideoFile(null);
            }}
          />
        </Box>
      )}
    </>
  );
}
