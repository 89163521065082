import { Chip } from "@mui/material";

import { ToolTip } from "@/common/tool-tip/ToolTip";
import { JobSectors } from "@/company/jobs/constants";
import { CompanySector } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

export const getCompanySectorsChip = (
  companySectors?: Array<CompanySector>,
  googleCompanySectors?: Array<JobSectors>
) => {
  if (!companySectors?.length && !googleCompanySectors?.length) {
    return null;
  }
  return (
    <>
      {companySectors?.length
        ? companySectors
            .slice(0, 3)
            .map(({ sector }) => (
              <Chip
                key={sector}
                sx={{ color: "text.secondary", bgcolor: "#F8F9FA" }}
                label={
                  snakeCaseJobSectors[sector as JobSectors] ??
                  snakeCaseJobSectors[JobSectors.OTHER]
                }
                size="small"
              />
            ))
        : googleCompanySectors?.length
        ? googleCompanySectors
            .slice(0, 3)
            .map(sector => (
              <Chip
                key={sector}
                sx={{ color: "text.secondary", bgcolor: "#F8F9FA" }}
                label={
                  snakeCaseJobSectors[sector as JobSectors] ??
                  snakeCaseJobSectors[JobSectors.OTHER]
                }
                size="small"
              />
            ))
        : null}
      {companySectors?.length && companySectors?.length > 3 ? (
        <ToolTip
          placement="bottom"
          message={companySectors
            ?.slice(3)
            .map(({ sector }) => snakeCaseJobSectors[sector as JobSectors])
            .join(", ")}
        >
          <Chip
            key={companySectors
              ?.slice(3)
              .map(({ sector }) => snakeCaseJobSectors[sector as JobSectors])
              .join(", ")}
            sx={{ color: "text.secondary", bgcolor: "#F8F9FA" }}
            label={`+${companySectors.length - 3}`}
            size="small"
          />
        </ToolTip>
      ) : googleCompanySectors?.length && googleCompanySectors?.length > 3 ? (
        <ToolTip
          placement="bottom"
          message={googleCompanySectors
            ?.slice(3)
            .map(sector => snakeCaseJobSectors[sector as JobSectors])
            .join(", ")}
        >
          <Chip
            key={googleCompanySectors
              ?.slice(3)
              .map(sector => snakeCaseJobSectors[sector as JobSectors])
              .join(", ")}
            sx={{ color: "text.secondary", bgcolor: "#F8F9FA" }}
            label={`+${googleCompanySectors.length - 3}`}
            size="small"
          />
        </ToolTip>
      ) : null}
    </>
  );
};
