import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  OutlinedInput,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { ChangeEvent, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { JobSectors } from "@/company/jobs/constants";
import { useQueryBrandLocationById } from "@/company/onboarding/api";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";
import { useLeadContext } from "@/lead/context/lead-context";
import { PublicRoutes } from "@/open/constants";

import { useGetReferralInvitationInfo } from "../../../campaign/api/useGetReferralInvitationInfo";
import { useUpdateReferralInvitationResponse } from "../../api";
import { ChatInitialMessage } from "./ChatInitialMessage";

export function CompanyInitialMessage({
  referralInvitationId,
}: {
  referralInvitationId: string;
}) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const invitedCompanyId = searchParams.get("invitedCompanyId");
  const invitationCode = searchParams.get("invitationCode");

  const utmCampaign = searchParams.get("utm_campaign") ?? undefined;
  const utmSource = searchParams.get("utm_source") ?? undefined;
  const utmMedium = searchParams.get("utm_medium") ?? undefined;

  const { data: invitedCompanyProfile, isFetching } = useQueryBrandLocationById(
    { id: invitedCompanyId },
    { enabled: !!invitedCompanyId }
  );

  const [body, setBody] = useState<string>();
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setBody(event.target.value);
    },
    []
  );

  const { data, isFetching: loading } = useGetReferralInvitationInfo(
    referralInvitationId,
    { enabled: !!referralInvitationId }
  );

  const { updateLeadDetails } = useLeadContext();

  const {
    mutateAsync: addResponse,
    isLoading: creatingReferralInvitationResponse,
  } = useUpdateReferralInvitationResponse({
    onError() {
      enqueueSnackbar("Error while sending response.", {
        variant: "error",
      });
    },
    onSuccess: async () => {
      await updateLeadDetails({
        id: invitedCompanyProfile?.id,
        zipCode: invitedCompanyProfile?.zipCode,
        name: invitedCompanyProfile?.name,
        sectors: invitedCompanyProfile?.sectors as Array<JobSectors>,
        utmCampaign,
        utmMedium,
        utmSource,
        ...(invitedCompanyProfile && invitedCompanyProfile.name
          ? { accountType: COMPANY_ACCOUNT_TYPES.BRAND_LOCATION }
          : {}),
      });
      if (invitedCompanyProfile) {
        navigate(
          `${getNavigationPathFromRoutePaths([
            PublicRoutes.BASE,
            PublicRoutes.FDD_COMPANY_IDENTIFIED,
          ])}${invitationCode || referralInvitationId ? "?" : ""}${
            invitationCode ? `&invitationCode=${invitationCode}` : ""
          }${
            referralInvitationId
              ? `&referralInvitationId=${referralInvitationId}`
              : ""
          }`
        );
        return;
      }
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isFetching || loading ? (
        <Box my={5} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <Card
          component={"form"}
          sx={{
            margin: isMobile ? "12px" : "20px",
            gap: 2,
            px: isMobile ? 2 : 4,
            pb: 4,
            maxWidth: "440px",
          }}
        >
          <CardHeader
            sx={{
              display: "flex",
              pb: 2,
              px: 0,
              alignItems: "center",
              alignSelf: "stretch",
            }}
            subheader={
              <Typography
                color={"text.secondary"}
                variant="body2"
                sx={{ pt: 1 }}
              >
                Reply now by sending a message below.
              </Typography>
            }
            title={`${
              data?.invitingUser?.firstName
                ? `${data?.invitingUser?.firstName} from `
                : ""
            }  ${data?.invitingCompany?.name} sent you a message`}
          />
          <ChatInitialMessage
            companyProfile={data?.invitingCompany}
            invitingUser={data?.invitingUser}
            invitedCompanyProfile={invitedCompanyProfile}
            authorAvatar={
              data?.invitingCompany?.logoUrl ?? data?.invitingCompany?.name
            }
            authorName={
              data?.invitingUser?.firstName + " " + data?.invitingUser?.lastName
            }
            authorTitle={data?.invitingCompany?.name ?? ""}
            message={data?.lastChat?.message ?? ""}
            position={"left"}
          />
          <OutlinedInput
            multiline
            fullWidth
            minRows={4}
            maxRows={20}
            placeholder="Type a Reply"
            size="small"
            value={body}
            onChange={handleChange}
          />
          <Button
            fullWidth
            disabled={!body || creatingReferralInvitationResponse}
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() =>
              addResponse({
                response: body?.replace(/\n$/, "") ?? "",
                referralInvitationId,
              })
            }
          >
            Reply
          </Button>
        </Card>
      )}
    </Box>
  );
}
