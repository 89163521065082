import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { USER_TYPE } from "@/auth/types";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";

export const AccountTypeCardOptions = {
  [COMPANY_ACCOUNT_TYPES.BRAND_LOCATION]: {
    title: "Franchise Company",
    description:
      "I am a franchisee operating a home services franchise in one or more territories.",
    value: COMPANY_ACCOUNT_TYPES.BRAND_LOCATION,
  },
  [COMPANY_ACCOUNT_TYPES.INDEPENDENT_COMPANY]: {
    title: "Independent Company",
    description:
      "I am an independent home services company with one or more locations (not part of a franchise system).",
    value: COMPANY_ACCOUNT_TYPES.INDEPENDENT_COMPANY,
  },
  [COMPANY_ACCOUNT_TYPES.INDEPENDENT_CONTRACTOR]: {
    title: "Independent Contractor",
    description:
      "I am a self-employed general contractor, handyman, or technician, working independently without a team.",
    value: COMPANY_ACCOUNT_TYPES.INDEPENDENT_CONTRACTOR,
  },
  [USER_TYPE.HOMEOWNER]: {
    title: "Homeowner",
    description:
      "I am a homeowner seeking home improvement, repair, and remodeling services.",
    value: USER_TYPE.HOMEOWNER,
  },
};

export const AccountTypeSelect = ({
  onSelect,
  selectedAccountType,
}: {
  onSelect: (value: COMPANY_ACCOUNT_TYPES) => void;
  selectedAccountType: COMPANY_ACCOUNT_TYPES | USER_TYPE.HOMEOWNER;
}) => (
  <Box sx={{ p: 3, width: "100%" }}>
    <Stack
      component={RadioGroup}
      spacing={3}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onSelect(event.target.value as unknown as COMPANY_ACCOUNT_TYPES);
      }}
    >
      {Object.values(AccountTypeCardOptions).map(option => (
        <Paper
          key={option.value}
          sx={{
            alignItems: "flex-start",
            display: "flex",
            p: 2,
            width: "100%",
            ...(selectedAccountType === option.value
              ? {
                  border: "2px solid #16DB65",
                  background: "#EDFCF2",
                }
              : {}),
            "&:hover": { border: "2px solid #16DB65" },
          }}
          variant="outlined"
        >
          <FormControlLabel
            key={option.value}
            control={<Radio />}
            label={
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2">{option.title}</Typography>
                <Typography color="text.secondary" variant="body2">
                  {option.description}
                </Typography>
              </Box>
            }
            checked={selectedAccountType === option.value}
            value={option.value}
          />
        </Paper>
      ))}
    </Stack>
  </Box>
);
