export const AUTH_STORAGE_KEY = "accessToken";

export const AuthRoutes = {
  BASE: "auth",
  LOGIN: "login",
  VERIFY_OTP: "verify-otp",
  SIGN_UP: "signup",
  JOB_SIGN_UP: "job-signup",
  INVITATION_SIGN_UP: "invitation-signup",
  USER_TYPE: "user-type",
};

export const MIN_LOGIN_OTP_REQUEST_INTERVAL_IN_SECONDS = 120;
