/* eslint-disable jsx-a11y/media-has-caption */
import {
  AddPhotoAlternateOutlined,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBaseComponentProps,
  LinearProgress,
  Switch,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { ElementType, useEffect, useRef, useState } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { CallType } from "@/company/api/useFetchCallRecordings";
import {
  useCreateJob,
  useGetJobById,
  useUpdateJobById,
} from "@/company/jobs/api";
import { useAssociateCompanyToJob } from "@/company/jobs/api/useAssociateCompanyToJob";
import { useCloseJob } from "@/company/jobs/api/useCloseJob";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { useDeleteJob } from "@/company/jobs/api/useDeleteJob";
import { useFetchJobRecommendedPartner } from "@/company/jobs/api/useFetchJobRecommendedPartner";
import { OCRJobDetails, useGetOCR } from "@/company/jobs/api/useGetOCR";
import { useSubmitJob } from "@/company/jobs/api/useSubmitJob";
import { useUpdateJobHome } from "@/company/jobs/api/useUpdateJobHome";
import { JobSectors } from "@/company/jobs/constants";
import { Job, JobStatus } from "@/company/jobs/types";
import { HOMEOWNER_APPROVAL_SETTING } from "@/company/types";
import { neutral, tradeEngageColors } from "@/Theme/colors";
import { getUSPhoneValidationSchema } from "@/utils";

import { Languages } from "../constants/languages";
import { GoogleAutocomplete } from "../GoogleAutocomplete";
import { MaskedPhoneInput } from "../MaskedPhoneInput";
import { doesErrorHaveMessage } from "../utils/doesErrorHaveMessage";
import { JobTypeSelection } from "./JobTypeSelection";
import { PartnerSelection } from "./PartnerSelection";

export enum CSRJobUploadSource {
  JOBS_DASHBOARD = "JOBS_DASHBOARD",
  CHROME_EXTENSION = "CHROME_EXTENSION",
}
export interface CreateJobDialogProps {
  isOpen: boolean;
  source?: CSRJobUploadSource;
  createdForCompanyId?: number;
  homeId?: number;
  defaultHomeownerDetails?: {
    firstName: string;
    lastName?: string;
    address?: string;
    cellPhone?: string;
    zipCode?: string;
  };
  defaultSummary?: string;
  onClose?: () => void;
  jobsListUrl: string;
  refreshJobs?: () => Promise<unknown>;
}

export function CreateCSRJobDialog(props: CreateJobDialogProps) {
  const { session } = useAuth();
  const brandLocationProfile = session?.brandLocationProfile;

  const [zipCode, setZipCode] = useState<string | undefined>("");
  const [latitude, setLatitude] = useState<number | undefined>();
  const [longitude, setLongitude] = useState<number | undefined>();

  const [transcription, setTranscription] = useState<string | undefined>();
  const [homeownerDetailsUpload, setHomeownerDetailsUpload] = useState<
    File | undefined
  >();

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      setHomeownerDetailsUpload(file);
      // Handle the file upload logic here
    }
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const onDrop = (files: File[]) => {
    const newFile = files && files.length > 0 ? files[0] : null;
    setVideoFile(newFile);
  };

  const { enqueueSnackbar } = useSnackbar();

  const [newJob, setNewJob] = useState<Job | undefined>(undefined);
  const [referralType, setReferralType] = useState<CallType>(CallType.OUTBOUND);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [videoFile, setVideoFile] = useState<any>(undefined);
  const [step, setStep] = useState(0);
  const [polling, setPolling] = useState(false);
  const [newUploadScreenOpen, setNewUploadScreenOpen] = useState(false);
  const [fetchedOCRDetails, setFetchedOCRDetails] = useState(false);
  const [homeownerTextUpload, setHomeownerTextUpload] = useState(false);

  const [homeownerApprovalSwitch, setHomeownerApprovalSwitch] = useState(false);
  const [mediaType, setMediaType] = useState<JobUploadTypes>(
    JobUploadTypes.VIDEO
  );

  const [sector, setSector] = useState<JobSectors | null>();

  useEffect(() => {
    if (newJob?.sector) {
      setSector(newJob.sector);
    }
  }, [newJob]);

  const { isLoading: isCreatingJob, mutateAsync: createNewJob } =
    useCreateJob();

  const { isLoading: isDeletingJob, mutateAsync: deleteJob } = useDeleteJob({
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while cancelling the job";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const { isLoading: isFetchingOCR, mutateAsync: getOCR } = useGetOCR({
    onSuccess: (jobDetails: OCRJobDetails) => {
      if (
        homeownerApprovalSwitch ||
        brandLocationProfile?.homeownerJobApprovalSettings ===
          HOMEOWNER_APPROVAL_SETTING.ROUTE_ALL_JOBS_TO_HOME_OWNER
      ) {
        if (jobDetails?.phone) {
          formik.setFieldValue("cellPhone", jobDetails?.phone, true);
        }
        setFetchedOCRDetails(true);
        return;
      }
      if (jobDetails?.firstName) {
        formik.setFieldValue("firstName", jobDetails?.firstName, true);
      }
      if (jobDetails?.lastName) {
        formik.setFieldValue("lastName", jobDetails?.lastName, true);
      }
      if (jobDetails?.phone) {
        formik.setFieldValue("cellPhone", jobDetails?.phone, true);
      }
      if (jobDetails?.address) {
        formik.setFieldValue("address", jobDetails?.address, true);
      }
      setFetchedOCRDetails(true);
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while fetching homeowner details from image.";
      setHomeownerDetailsUpload(undefined);
      setFetchedOCRDetails(false);
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const validationSchema = yup.object().shape({
    firstName:
      brandLocationProfile?.homeownerJobApprovalSettings ===
        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER ||
      !homeownerApprovalSwitch
        ? yup.string().required("First Name is required")
        : yup.string().optional(),
    lastName:
      brandLocationProfile?.homeownerJobApprovalSettings ===
        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER ||
      !homeownerApprovalSwitch
        ? yup.string().required("Last Name is required")
        : yup.string().optional(),
    address:
      brandLocationProfile?.homeownerJobApprovalSettings ===
        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER ||
      !homeownerApprovalSwitch
        ? yup.string().required("Home Address is required")
        : yup.string().optional(),
    sector: yup.string().optional(),
    cellPhone: getUSPhoneValidationSchema().required(
      "Mobile Number is required"
    ),
  });

  useEffect(() => {
    const details = props.defaultHomeownerDetails;
    if (details) {
      if (details.firstName) {
        formik.setFieldValue("firstName", details.firstName, true);
      }
      if (details.lastName) {
        formik.setFieldValue("lastName", details.lastName, true);
      }
      if (details.cellPhone) {
        formik.setFieldValue("cellPhone", details.cellPhone, true);
      }
      if (details.address) {
        formik.setFieldValue("address", details.address, true);
      }
      if (details.zipCode) {
        setZipCode(details.zipCode);
      }
    }
  }, [props.defaultHomeownerDetails]);

  const formik = useFormik({
    initialValues: {
      cellPhone: "",
      sector: "",
      firstName: "",
      lastName: "",
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ cellPhone }) => {
      console.log({ cellPhone });
    },
  });

  const { isLoading: updatingJobHome, mutateAsync: updateJobHome } =
    useUpdateJobHome({
      onSuccess: () => {
        setStep(1.5);
        setPolling(true);
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while updating homeowner details.`,
          variant: "error",
        });
      },
    });

  const { isLoading: updatingJob, mutateAsync: updateJob } = useUpdateJobById({
    onSuccess: () => {
      updateJobHome({
        jobId: newJob?.id,
        cellPhone: `+1${formik.values.cellPhone.replace(/[\s()-]/g, "")}`,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        streetAddress: formik.values.address,
        zipCode,
        latitude,
        longitude,
      });
    },
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while updating job details.`,
        variant: "error",
      });
    },
  });

  const { mutateAsync: closeJob } = useCloseJob();

  const {
    isFetching: loadingPotentialReferralPartner,
    data: potentialReferralPartner,
    refetch: refetchPotentialReferralPartner,
  } = useFetchJobRecommendedPartner(newJob?.id, { enabled: !!newJob });

  const { isLoading: submittingJob, mutateAsync: submitJob } = useSubmitJob({
    onSuccess: () => {
      reset();
      props.refreshJobs?.();
      enqueueSnackbar({
        message: "Job submitted successfully.",
        variant: "success",
      });
      setNewUploadScreenOpen(true);
    },
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while submitting the job.`,
        variant: "error",
      });
    },
  });

  const { data: job } = useGetJobById(newJob?.id, {
    enabled: !!newJob?.id && step === 1.5,
    refetchInterval: () => {
      if (
        (newJob?.summary && newJob?.status === JobStatus.OPPORTUNITY) ||
        newJob?.status == JobStatus.VIDEO_PROCESSING_FAILED
      ) {
        if (
          newJob?.summary &&
          newJob?.status === JobStatus.OPPORTUNITY &&
          step === 1.5
        ) {
          refetchPotentialReferralPartner();
          setStep(2);
        }
        setPolling(false);
        return false;
      }
      return 2000;
    },
  });

  useEffect(() => {
    if (job) {
      if (job?.status === JobStatus.VIDEO_PROCESSING_FAILED) {
        enqueueSnackbar({
          message: `There was an error while processing job video. Please try again.`,
          variant: "error",
        });
        reset();
      }
      setNewJob(job);
    }
  }, [job]);

  const { isLoading: associatingCompany, mutateAsync: associateCompany } =
    useAssociateCompanyToJob({
      onSuccess: () => {
        submitJob({
          jobId: newJob?.id,
          autoApproveJob: referralType === CallType.INBOUND,
        });
        enqueueSnackbar({
          message: "Partner confirmed successfully.",
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while associating the partner to job.`,
          variant: "error",
        });
      },
    });

  const reset = () => {
    setVideoFile(undefined);
    setNewJob(undefined);
    setStep(0);
    setZipCode(undefined);
    setLatitude(undefined);
    setLongitude(undefined);
    setMediaType(JobUploadTypes.VIDEO);
    setHomeownerTextUpload(false);
    setTranscription("");
    setPolling(false);
    setReferralType(CallType.OUTBOUND);
    setHomeownerDetailsUpload(undefined);
    setFetchedOCRDetails(false);
    setHomeownerApprovalSwitch(false);
    formik.resetForm();
    return true;
  };

  const revertStep1 = () => {
    if (newJob) {
      closeJob(newJob?.id, {
        onError: error => {
          console.error(error);
        },
      });
    }
    setHomeownerDetailsUpload(undefined);
    setFetchedOCRDetails(false);
    setHomeownerTextUpload(false);
    setPolling(false);
    setHomeownerApprovalSwitch(false);
    formik.resetForm();
    setZipCode(undefined);
    setLatitude(undefined);
    setLongitude(undefined);
    setHomeownerDetailsUpload(undefined);
    setStep(0);
  };

  const onClickGenerateJob = async () => {
    createNewJob(
      {
        videoFile,
        videoLanguage: Languages.ENGLISH,
        homeId: props.homeId,
        createdForCompanyId: props.createdForCompanyId,
        type: mediaType,
        source: props.source ?? CSRJobUploadSource.CHROME_EXTENSION,
        ...(mediaType === JobUploadTypes.TEXT && transcription
          ? { summary: transcription }
          : {}),
      },
      {
        onSuccess: (data: Job) => {
          setNewJob(data);
          setStep(1);
        },
        onError: () => {
          setStep(0);
          enqueueSnackbar({
            message: `There was an error while creating ${
              session?.user?.type === USER_TYPE.HOMEOWNER
                ? "your project"
                : "the job"
            }.`,
            variant: "error",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (homeownerDetailsUpload) {
      getOCR({ jobId: newJob?.id, imageFile: homeownerDetailsUpload });
    }
  }, [homeownerDetailsUpload]);

  let homeownerDetailsRequired =
    brandLocationProfile?.homeownerJobApprovalSettings ===
    HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER;

  if (
    brandLocationProfile?.homeownerJobApprovalSettings ===
    HOMEOWNER_APPROVAL_SETTING.ROUTE_ALL_JOBS_TO_HOME_OWNER
  ) {
    homeownerDetailsRequired = false;
  } else if (
    brandLocationProfile?.homeownerJobApprovalSettings ===
    HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
  ) {
    homeownerDetailsRequired = !homeownerApprovalSwitch;
  }

  let disableHomeOwnerReviewRequiredNextButton = false;

  if (!homeownerDetailsRequired) {
    if (
      !formik.values.cellPhone ||
      (formik.touched.cellPhone && Boolean(formik.errors.cellPhone))
    ) {
      disableHomeOwnerReviewRequiredNextButton = true;
    }
  } else {
    if (
      !formik.values.cellPhone ||
      !formik.values.firstName ||
      !formik.values.lastName ||
      !formik.values.address ||
      (formik.touched.cellPhone && Boolean(formik.errors.cellPhone)) ||
      (formik.touched.firstName && Boolean(formik.errors.firstName)) ||
      (formik.touched.lastName && Boolean(formik.errors.lastName))
    ) {
      disableHomeOwnerReviewRequiredNextButton = true;
    }
  }

  if (updatingJobHome || updatingJob) {
    disableHomeOwnerReviewRequiredNextButton = true;
  }

  return (
    <Dialog
      fullWidth
      fullScreen
      maxWidth={"lg"}
      open={props.isOpen}
      onClose={() => {
        reset() && props.onClose?.();
      }}
    >
      <DialogTitle component="div" width="100%">
        {newUploadScreenOpen ? null : (
          <>
            <Typography gutterBottom variant="h5">
              Submit New Job
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary">
              Upload a job to your network. It&apos;s easy!
            </Typography>
          </>
        )}
      </DialogTitle>
      {props.source === CSRJobUploadSource.JOBS_DASHBOARD ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
          onClick={() => reset() && props.onClose?.()}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <DialogContent
        sx={{ px: { xs: 0 }, pt: 0, overflow: "auto", position: "relative" }}
      >
        {newUploadScreenOpen ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            px={3}
            py={4}
            gap={1}
          >
            <img
              style={{
                height: "250px",
                width: "250px",
              }}
              src="/success.gif"
              alt="success"
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
              alignItems={"start"}
            >
              <Typography variant="h4" fontSize={"22px"}>
                Job Submitted Successfully!
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              {referralType === CallType.INBOUND ? (
                <Typography variant="body2" color="text.secondary">
                  Great! Your job is created. 💸
                </Typography>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  Great! Your job will be routed and we&apos;ll let you know
                  when your earnings are on their way. 💸
                </Typography>
              )}
            </Box>
            <Divider sx={{ width: "100%", my: 1.5 }} />
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              <Typography variant="body2" color="text.secondary">
                Do you have more jobs?
              </Typography>
            </Box>
            <Box display={"flex"} width={"100%"}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<BackupOutlinedIcon />}
                onClick={() => setNewUploadScreenOpen(false)}
              >
                Upload New Job
              </Button>
            </Box>
          </Box>
        ) : (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              mt: 0,
            }}
          >
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  sx={{
                    mb: 1,
                    color: neutral[50],
                    borderRadius: 1,
                    bgcolor: tradeEngageColors.green,
                  }}
                >
                  {step > 0 ? <CheckIcon color="inherit" /> : "1"}
                </Avatar>
                <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
              </TimelineSeparator>
              {isCreatingJob ? (
                <TimelineContent sx={{ pb: 5, pt: 0.5 }}>
                  <Typography
                    gutterBottom
                    variant="overline"
                    {...(step > 0 ? { color: "#6C737F" } : {})}
                  >
                    Upload Job Summary
                  </Typography>
                  <Box
                    width="100%"
                    pt={3}
                    display="flex"
                    flexDirection={"column"}
                  >
                    {videoFile ? (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      mediaType === JobUploadTypes.VIDEO ? (
                        <video
                          controls
                          controlsList="nodownload"
                          src={`${window.URL.createObjectURL(videoFile)}`}
                          width="72px"
                          height="72px"
                          style={{
                            marginBottom: "16px",
                            border: "1px solid black",
                          }}
                        />
                      ) : (
                        <audio
                          controls
                          controlsList="nodownload"
                          src={`${window.URL.createObjectURL(videoFile)}`}
                          width="72px"
                          height="72px"
                          style={{
                            marginBottom: "16px",
                          }}
                        />
                      )
                    ) : null}
                    <Typography gutterBottom variant="body2">
                      We&apos;re uploading your{" "}
                      {videoFile ? mediaType.toLowerCase() : "summary"}, please
                      do not close this window.
                    </Typography>
                    <LinearProgress sx={{ width: "100%" }} />
                  </Box>
                </TimelineContent>
              ) : (
                <TimelineContent sx={{ pb: 5, pt: 0.5 }}>
                  <Typography
                    gutterBottom
                    variant="overline"
                    {...(step > 0 ? { color: "#6C737F" } : {})}
                  >
                    Job Details
                  </Typography>
                  {step > 0 ? null : (
                    <Box
                      display={"flex"}
                      py={2}
                      gap={2}
                      flexDirection={"column"}
                    >
                      <Box
                        display={"flex"}
                        width={"100%"}
                        flexDirection={"column"}
                      >
                        <Typography
                          variant="body2"
                          mb={0.5}
                          ml={0.3}
                          fontSize={"12px"}
                          color="text.secondary"
                          fontWeight={400}
                        >
                          Write a brief job description for your referral
                          partner, including the service category, type of work,
                          and level of urgency.
                        </Typography>
                        <TextField
                          multiline
                          minRows={isMobile ? 4 : 2}
                          maxRows={4}
                          id="transcription"
                          name="transcription"
                          label="Job Summary"
                          value={transcription ?? ""}
                          type="string"
                          InputProps={{
                            inputProps: {
                              maxLength: 500,
                            },
                          }}
                          onChange={event => {
                            setTranscription(event.target.value);
                            setMediaType(JobUploadTypes.TEXT);
                          }}
                        />
                        <Box
                          display={"flex"}
                          width={"100%"}
                          justifyContent={"end"}
                          mt={0.5}
                        >
                          {" "}
                          <Typography
                            fontSize={"12px"}
                            color="text.secondary"
                            fontWeight={400}
                            mr={1}
                          >
                            {transcription?.length ?? 0}/500 Characters
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Button
                          sx={{
                            mr: 1,
                          }}
                          disabled={!transcription}
                          size={isMobile ? "small" : "medium"}
                          color="primary"
                          variant="contained"
                          onClick={() => onClickGenerateJob()}
                        >
                          Next
                        </Button>
                      </Box>{" "}
                    </Box>
                  )}
                </TimelineContent>
              )}
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  color="primary"
                  sx={{
                    my: 1,
                    borderRadius: 1,
                    color: step >= 1 ? neutral[50] : "textPrimary",
                    bgcolor: step >= 1 ? tradeEngageColors.green : neutral[200],
                  }}
                >
                  {step > 1 ? <CheckIcon color="inherit" /> : "2"}
                </Avatar>
                {step >= 1 ? (
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                ) : null}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                <Typography
                  variant="overline"
                  {...(step > 1 ? { color: "#6C737F" } : {})}
                >
                  Add Homeowner Info
                </Typography>
                {step === 1 || step === 1.5 ? (
                  polling || updatingJobHome ? (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={0}
                      mt={3}
                    >
                      <Typography variant="subtitle1">
                        Processing Job
                      </Typography>
                      <Typography variant="caption" fontSize={"0.625rem"}>
                        Please do not close this window.
                      </Typography>
                      <LinearProgress sx={{ width: "100%", mt: 1 }} />
                    </Box>
                  ) : (
                    <Box
                      width="100%"
                      pt={3}
                      gap={1}
                      display="flex"
                      flexDirection={"column"}
                    >
                      <Typography gutterBottom variant="subtitle1">
                        Enter Homeowner Details
                      </Typography>
                      <Box
                        display={"flex"}
                        gap={isMobile ? 1 : 2}
                        alignItems={"baseline"}
                        flexDirection={isMobile ? "column" : "row"}
                      >
                        {!homeownerTextUpload ? (
                          <>
                            <Typography
                              gutterBottom
                              variant="body2"
                              color="text.secondary"
                            >
                              Type in details instead?
                            </Typography>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                setHomeownerTextUpload(true);
                                setHomeownerDetailsUpload(undefined);
                              }}
                            >
                              Switch to Text
                            </Button>
                          </>
                        ) : (
                          <Box
                            mb={isMobile ? 1 : 2}
                            display={"flex"}
                            gap={isMobile ? 0 : 2}
                            flexDirection={isMobile ? "column" : "row"}
                            alignItems={isMobile ? "start" : "center"}
                          >
                            <Typography
                              gutterBottom
                              variant="body2"
                              color="text.secondary"
                            >
                              Upload a screenshot of the Homeowner&apos;s
                              profile?
                            </Typography>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => setHomeownerTextUpload(false)}
                            >
                              Upload
                            </Button>
                          </Box>
                        )}
                      </Box>
                      {!homeownerTextUpload ? (
                        <Box
                          display={"flex"}
                          width="100%"
                          gap={2}
                          flexDirection={"column"}
                        >
                          {homeownerDetailsUpload ? (
                            isFetchingOCR || !fetchedOCRDetails ? (
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                gap={0}
                                mt={3}
                              >
                                <Typography variant="subtitle1">
                                  Extracting Homeowner Details from Image
                                </Typography>
                                <Typography
                                  variant="caption"
                                  fontSize={"0.625rem"}
                                >
                                  Please do not close this window.
                                </Typography>
                                <LinearProgress sx={{ width: "100%", mt: 1 }} />
                              </Box>
                            ) : (
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                py={2}
                                gap={2}
                              >
                                <Box
                                  sx={{
                                    borderRadius: "8px",
                                    border: "1px solid #E5E7EB",
                                    background: "#FFF",
                                    boxShadow: "0px 0px 0px 1px #F2F4F7",
                                    width: { xs: "100%", md: "49%" },
                                  }}
                                >
                                  <Box
                                    p={1}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "#F8F9FA",
                                      },
                                      opacity:
                                        brandLocationProfile?.homeownerJobApprovalSettings !==
                                        HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                          ? 0.5
                                          : 1,
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        fontSize={"13px"}
                                        fontWeight={600}
                                        color={"#16B364"}
                                      >
                                        Homeowner Review Required
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "11px",
                                          fontWeight: 400,
                                        }}
                                        color={"text.secondary"}
                                      >
                                        The job will be sent to the Homeowner
                                        for review, before it&apos;s forwarded
                                        to your referral partner.
                                      </Typography>
                                    </Box>
                                    <Switch
                                      color="primary"
                                      disabled={
                                        brandLocationProfile?.homeownerJobApprovalSettings !==
                                        HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                      }
                                      checked={
                                        brandLocationProfile?.homeownerJobApprovalSettings ===
                                        HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                          ? homeownerApprovalSwitch
                                          : brandLocationProfile?.homeownerJobApprovalSettings ===
                                            HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER
                                          ? false
                                          : true
                                      }
                                      onChange={() =>
                                        setHomeownerApprovalSwitch(
                                          homeownerApprovalSwitch =>
                                            !homeownerApprovalSwitch
                                        )
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  display={"flex"}
                                  width="100%"
                                  gap={2}
                                  flexDirection={"column"}
                                >
                                  {homeownerDetailsRequired ? (
                                    <Box display={"flex"} width="100%" gap={2}>
                                      <TextField
                                        required={homeownerDetailsRequired}
                                        id="firstName"
                                        name="firstName"
                                        label={"First Name"}
                                        sx={{
                                          width: "50%",
                                        }}
                                        error={
                                          formik.touched.firstName &&
                                          Boolean(formik.errors.firstName)
                                        }
                                        helperText={
                                          formik.touched.firstName &&
                                          formik.errors.firstName
                                        }
                                        value={formik.values.firstName ?? ""}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                      />
                                      <TextField
                                        required={homeownerDetailsRequired}
                                        sx={{
                                          width: "50%",
                                        }}
                                        id="lastName"
                                        name="lastName"
                                        label={"Last Name"}
                                        error={
                                          formik.touched.lastName &&
                                          Boolean(formik.errors.lastName)
                                        }
                                        helperText={
                                          formik.touched.lastName &&
                                          formik.errors.lastName
                                        }
                                        value={formik.values.lastName ?? ""}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                      />
                                    </Box>
                                  ) : null}

                                  <Box
                                    display={"flex"}
                                    width="100%"
                                    gap={2}
                                    flexDirection={isMobile ? "column" : "row"}
                                  >
                                    {homeownerDetailsRequired ? (
                                      <Box sx={{ width: "100%" }}>
                                        <GoogleAutocomplete
                                          defaultOptions={[]}
                                          selectedValue={
                                            formik.values.address ?? ""
                                          }
                                          error={
                                            formik.touched.address &&
                                            Boolean(formik.errors.address)
                                          }
                                          helperText={
                                            formik.touched.address &&
                                            formik.errors.address
                                          }
                                          label={
                                            homeownerDetailsRequired
                                              ? "Home Address*"
                                              : "Home Address"
                                          }
                                          onBlur={event =>
                                            formik.setFieldTouched(
                                              "address",
                                              true
                                            )
                                          }
                                          onChange={(address: string) => {
                                            formik.setFieldValue(
                                              "address",
                                              address
                                            );
                                          }}
                                          onSelect={({
                                            selection,
                                            zipCode,
                                            latitude,
                                            longitude,
                                          }) => {
                                            setZipCode(zipCode);
                                            setLatitude(latitude);
                                            setLongitude(longitude);
                                            formik.setFieldValue(
                                              "address",
                                              selection
                                            );
                                          }}
                                        />
                                      </Box>
                                    ) : null}

                                    <TextField
                                      required
                                      sx={{
                                        width: {
                                          xs: "100%",
                                          md: homeownerDetailsRequired
                                            ? "100%"
                                            : "49%",
                                        },
                                      }}
                                      id="cellPhone"
                                      name="cellPhone"
                                      label={"Mobile Number"}
                                      error={
                                        formik.touched.cellPhone &&
                                        Boolean(formik.errors.cellPhone)
                                      }
                                      helperText={
                                        formik.touched.cellPhone &&
                                        formik.errors.cellPhone
                                      }
                                      value={formik.values.cellPhone ?? ""}
                                      InputProps={{
                                        inputComponent:
                                          MaskedPhoneInput as unknown as
                                            | ElementType<InputBaseComponentProps>
                                            | undefined,
                                      }}
                                      onBlur={formik.handleBlur}
                                      onChange={formik.handleChange}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            )
                          ) : (
                            <Box display={"flex"} py={2} gap={2}>
                              <Box
                                sx={{
                                  borderRadius: "8px",
                                  border: "1px solid #E5E7EB",
                                  background: "#FFF",
                                  boxShadow: "0px 0px 0px 1px #F2F4F7",
                                  width: { xs: "100%", md: "49%" },
                                }}
                              >
                                <Box
                                  p={1}
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#F8F9FA",
                                    },
                                    opacity:
                                      brandLocationProfile?.homeownerJobApprovalSettings !==
                                      HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                        ? 0.5
                                        : 1,
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      fontSize={"13px"}
                                      fontWeight={600}
                                      color={"#16B364"}
                                    >
                                      Homeowner Review Required
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "11px",
                                        fontWeight: 400,
                                      }}
                                      color={"text.secondary"}
                                    >
                                      Do you want a homeowner to review and
                                      approve a job before it is routed to your
                                      referral partner?
                                    </Typography>
                                  </Box>
                                  <Switch
                                    color="primary"
                                    disabled={
                                      brandLocationProfile?.homeownerJobApprovalSettings !==
                                      HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                    }
                                    checked={
                                      brandLocationProfile?.homeownerJobApprovalSettings ===
                                      HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                        ? homeownerApprovalSwitch
                                        : brandLocationProfile?.homeownerJobApprovalSettings ===
                                          HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER
                                        ? false
                                        : true
                                    }
                                    onChange={() =>
                                      setHomeownerApprovalSwitch(
                                        homeownerApprovalSwitch =>
                                          !homeownerApprovalSwitch
                                      )
                                    }
                                  />
                                </Box>
                              </Box>
                              <Box
                                px={isMobile ? 1 : 2}
                                py={1}
                                width={isMobile ? "100%" : "70%"}
                                sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "start",
                                  borderRadius: "16px",
                                  gap: 2,
                                  background: "#FFF",
                                  boxShadow: "0px 0px 0px 1px #F2F4F7",
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "#F8F9FA",
                                  },
                                }}
                                onClick={() => handleButtonClick()}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "50px",
                                    height: "50px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                    background: "#e8fbf0",
                                  }}
                                >
                                  <AddPhotoAlternateOutlined color="primary" />
                                </Box>
                                <input
                                  ref={fileInputRef}
                                  type="file"
                                  style={{ display: "none" }}
                                  accept="image/*"
                                  onChange={handleFileChange}
                                />
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  sx={{ ...(isMobile ? { width: "85%" } : {}) }}
                                >
                                  <Typography
                                    fontSize={"13px"}
                                    fontWeight={600}
                                  >
                                    Upload a Screenshot from Your CRM
                                  </Typography>
                                  <Typography
                                    fontSize={"12px"}
                                    fontWeight={500}
                                    color={"text.secondary"}
                                  >
                                    Easily share homeowner info by taking a
                                    screenshot from your field service app.
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Box
                          display={"flex"}
                          width="100%"
                          gap={2}
                          flexDirection={"column"}
                        >
                          <Box
                            sx={{
                              borderRadius: "8px",
                              border: "1px solid #E5E7EB",
                              background: "#FFF",
                              boxShadow: "0px 0px 0px 1px #F2F4F7",
                              width: { xs: "100%", md: "49%" },
                            }}
                          >
                            <Box
                              p={1}
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#F8F9FA",
                                },
                                opacity:
                                  brandLocationProfile?.homeownerJobApprovalSettings !==
                                  HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                    ? 0.5
                                    : 1,
                              }}
                            >
                              <Box>
                                <Typography
                                  fontSize={"13px"}
                                  fontWeight={600}
                                  color={"#16B364"}
                                >
                                  Homeowner Review Required
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    fontWeight: 400,
                                  }}
                                  color={"text.secondary"}
                                >
                                  Do you want a homeowner to review and approve
                                  a job before it is routed to your referral
                                  partner?
                                </Typography>
                              </Box>
                              <Switch
                                color="primary"
                                disabled={
                                  brandLocationProfile?.homeownerJobApprovalSettings !==
                                  HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                }
                                checked={
                                  brandLocationProfile?.homeownerJobApprovalSettings ===
                                  HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                                    ? homeownerApprovalSwitch
                                    : brandLocationProfile?.homeownerJobApprovalSettings ===
                                      HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER
                                    ? false
                                    : true
                                }
                                onChange={() =>
                                  setHomeownerApprovalSwitch(
                                    homeownerApprovalSwitch =>
                                      !homeownerApprovalSwitch
                                  )
                                }
                              />
                            </Box>
                          </Box>
                          {homeownerDetailsRequired ? (
                            <Box display={"flex"} width="100%" gap={2}>
                              <TextField
                                required={homeownerDetailsRequired}
                                id="firstName"
                                name="firstName"
                                label={"First Name"}
                                sx={{
                                  width: "50%",
                                }}
                                error={
                                  formik.touched.firstName &&
                                  Boolean(formik.errors.firstName)
                                }
                                helperText={
                                  formik.touched.firstName &&
                                  formik.errors.firstName
                                }
                                value={formik.values.firstName ?? ""}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                              />
                              <TextField
                                required={homeownerDetailsRequired}
                                sx={{
                                  width: "50%",
                                }}
                                id="lastName"
                                name="lastName"
                                label={"Last Name"}
                                error={
                                  formik.touched.lastName &&
                                  Boolean(formik.errors.lastName)
                                }
                                helperText={
                                  formik.touched.lastName &&
                                  formik.errors.lastName
                                }
                                value={formik.values.lastName ?? ""}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                              />
                            </Box>
                          ) : null}
                          <Box
                            display={"flex"}
                            width="100%"
                            gap={2}
                            flexDirection={isMobile ? "column" : "row"}
                          >
                            {homeownerDetailsRequired ? (
                              <Box sx={{ width: "100%" }}>
                                <GoogleAutocomplete
                                  defaultOptions={[]}
                                  selectedValue={formik.values.address ?? ""}
                                  error={
                                    formik.touched.address &&
                                    Boolean(formik.errors.address)
                                  }
                                  helperText={
                                    formik.touched.address &&
                                    formik.errors.address
                                  }
                                  label={
                                    homeownerDetailsRequired
                                      ? "Home Address*"
                                      : "Home Address"
                                  }
                                  onBlur={event =>
                                    formik.setFieldTouched("address", true)
                                  }
                                  onChange={(address: string) => {
                                    formik.setFieldValue("address", address);
                                  }}
                                  onSelect={({
                                    selection,
                                    zipCode,
                                    latitude,
                                    longitude,
                                  }) => {
                                    setZipCode(zipCode);
                                    setLatitude(latitude);
                                    setLongitude(longitude);
                                    formik.setFieldValue("address", selection);
                                  }}
                                />
                              </Box>
                            ) : null}
                            <TextField
                              required
                              sx={{
                                width: {
                                  xs: "100%",
                                  md: homeownerDetailsRequired ? "100%" : "49%",
                                },
                              }}
                              id="cellPhone"
                              name="cellPhone"
                              label={"Mobile Number"}
                              error={
                                formik.touched.cellPhone &&
                                Boolean(formik.errors.cellPhone)
                              }
                              helperText={
                                formik.touched.cellPhone &&
                                formik.errors.cellPhone
                              }
                              value={formik.values.cellPhone ?? ""}
                              InputProps={{
                                inputComponent: MaskedPhoneInput as unknown as
                                  | ElementType<InputBaseComponentProps>
                                  | undefined,
                              }}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                            />
                          </Box>
                        </Box>
                      )}
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        mt={2}
                      >
                        <Button
                          variant="outlined"
                          color="inherit"
                          size={isMobile ? "small" : "medium"}
                          onClick={() => {
                            revertStep1();
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          sx={{
                            width: "fit-content",
                            ml: 1,
                          }}
                          disabled={disableHomeOwnerReviewRequiredNextButton}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            if (
                              brandLocationProfile?.homeownerJobApprovalSettings !==
                              HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                            ) {
                              updateJobHome({
                                jobId: newJob?.id,
                                cellPhone: `+1${formik.values.cellPhone.replace(
                                  /[\s()-]/g,
                                  ""
                                )}`,
                                firstName: formik.values.firstName,
                                lastName: formik.values.lastName,
                                streetAddress: formik.values.address,
                                zipCode,
                                latitude,
                                longitude,
                              });
                              return;
                            }
                            updateJob({
                              jobId: newJob?.id,
                              routeToHomeowner: homeownerApprovalSwitch,
                            });
                          }}
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  )
                ) : null}
              </TimelineContent>
            </TimelineItem>
            {/* <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  color="primary"
                  sx={{
                    my: 1,
                    borderRadius: 1,
                    color: step >= 2 ? neutral[50] : "textPrimary",
                    bgcolor: step >= 2 ? tradeEngageColors.green : neutral[200],
                  }}
                >
                  {step > 2 ? <CheckIcon color="inherit" /> : "3"}
                </Avatar>
                {step >= 2 ? (
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                ) : null}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                <Typography
                  variant="overline"
                  {...(step > 2 ? { color: "#6C737F" } : {})}
                >
                  Generate Project
                </Typography>
                {step === 2 ? (
                  polling || updatingJobHome ? (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={0}
                      mt={3}
                    >
                      <Typography variant="subtitle1">
                        Processing Job
                      </Typography>
                      <Typography variant="caption" fontSize={"0.625rem"}>
                        Please do not close this window.
                      </Typography>
                      <LinearProgress sx={{ width: "100%", mt: 1 }} />
                    </Box>
                  ) : null
                ) : null}
              </TimelineContent>
            </TimelineItem> */}
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  color="primary"
                  sx={{
                    my: 1,
                    borderRadius: 1,
                    color: step >= 2 ? neutral[50] : "textPrimary",
                    bgcolor: step >= 2 ? tradeEngageColors.green : neutral[200],
                  }}
                >
                  {step > 2 ? <CheckIcon color="inherit" /> : "3"}
                </Avatar>
                {step >= 2 ? (
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                ) : null}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                <Typography
                  variant="overline"
                  {...(step > 2 ? { color: "#6C737F" } : {})}
                >
                  Confirm job type
                </Typography>
                {step === 2 ? (
                  <JobTypeSelection
                    setReferralType={(referralType: CallType) =>
                      setReferralType(referralType)
                    }
                    referralType={referralType}
                    onCancel={() => {
                      setReferralType(CallType.OUTBOUND);
                      setStep(1);
                    }}
                    onNext={() => setStep(3)}
                  />
                ) : null}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  color="primary"
                  sx={{
                    my: 1,
                    borderRadius: 1,
                    color: step >= 3 ? neutral[50] : "textPrimary",
                    bgcolor: step >= 3 ? tradeEngageColors.green : neutral[200],
                  }}
                >
                  {step > 3 ? <CheckIcon color="inherit" /> : "4"}
                </Avatar>
                {step >= 3 ? (
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                ) : null}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                <Typography
                  variant="overline"
                  {...(step > 3 ? { color: "#6C737F" } : {})}
                >
                  Confirm Partner
                </Typography>
                {step === 3 ? (
                  !newJob ? (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={0}
                      mt={3}
                    >
                      <Typography variant="subtitle1">
                        Fetching Job Details
                      </Typography>
                      <Typography variant="caption" fontSize={"0.625rem"}>
                        Please do not close this window.
                      </Typography>
                      <LinearProgress sx={{ width: "100%", mt: 1 }} />
                    </Box>
                  ) : (
                    <PartnerSelection
                      sector={sector}
                      setSector={(newSector: JobSectors) => {
                        setSector(newSector);
                      }}
                      submittingJob={submittingJob}
                      associatingCompany={associatingCompany}
                      referralType={referralType}
                      associateCompany={associateCompany}
                      refetchPotentialReferralPartner={() =>
                        refetchPotentialReferralPartner()
                      }
                      potentialReferralPartner={potentialReferralPartner}
                      job={newJob}
                      onCancel={() => {
                        setStep(2);
                      }}
                      onNext={() =>
                        submitJob({
                          jobId: newJob?.id,
                          autoApproveJob: referralType === CallType.INBOUND,
                        })
                      }
                    />
                  )
                ) : null}
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        )}
      </DialogContent>
      {step !== 0 && newJob ? (
        <Box position={"sticky"} bottom={0} mb={2}>
          {" "}
          <Divider />
          <Box display={"flex"} gap={2} alignItems={"center"} mt={2} px={2}>
            <Typography variant="subtitle2" fontWeight={600}>
              Want to restart?
            </Typography>
            <Button
              startIcon={<CloseIcon />}
              variant="outlined"
              disabled={isDeletingJob}
              size="small"
              onClick={() => {
                deleteJob(newJob.id);
                reset();
              }}
            >
              Cancel Job
            </Button>
          </Box>
        </Box>
      ) : null}
    </Dialog>
  );
}
