import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { useCompanyPaymentsContext } from "@/company/context/payments-context";
import { neutral } from "@/Theme/colors";

export function CompanyPaymentDialog({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}) {
  const { session } = useAuth();

  const {
    createCard,
    refetchCard,
    isBuyingCredits,
    isFetchingCard,
    isCreatingCard,
    card,
  } = useCompanyPaymentsContext();

  const stripeElements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();

  const showErrorNotification = (errorMessage?: string) => {
    enqueueSnackbar({
      variant: "error",
      message: errorMessage ?? `There was an error while connecting to stripe.`,
    });
  };

  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [holderName, setHolderName] = useState("");
  const [validationError, setValidationError] = useState("");

  const onSubmitCardDetails = async () => {
    if (!stripe || !stripeElements) {
      showErrorNotification();
      return;
    }
    const cardCvcElement = stripeElements.getElement(CardCvcElement);
    const cardNumberElement = stripeElements.getElement(CardNumberElement);
    const cardExpiryElement = stripeElements.getElement(CardExpiryElement);
    if (!cardCvcElement || !cardNumberElement || !cardExpiryElement) {
      showErrorNotification();
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    if (result.error || !result.paymentMethod.card) {
      showErrorNotification(
        result.error?.message ??
          "There was an error connecting to stripe, please try again after sometime."
      );
      setIsStripeLoading(false);
      return;
    }

    if (!holderName) {
      setValidationError("The cardholder's name is required.");
    }

    const { token, error } = await stripe.createToken(cardNumberElement, {
      name: holderName,
    });
    if (error || !token) {
      showErrorNotification(error?.message);
      setIsStripeLoading(false);
      return;
    }
    setIsStripeLoading(false);

    createCard(token.id, {
      onSuccess: () => {
        enqueueSnackbar({
          message: `Your card has been added!`,
          variant: "success",
        });
        refetchCard();
        onClose();
      },
      onError: () => {
        showErrorNotification();
      },
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        sx={{ p: 2 }}
        onClose={() => onClose()}
      >
        {isStripeLoading || isFetchingCard || isBuyingCredits ? (
          <Box my={5} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box width="100%" p={1}>
            <DialogTitle component="div" p={0}>
              <Typography gutterBottom variant="h5">
                {card ? "Update" : "Add"} Card to Approve
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                You cannot approve this job because you don&apos;t have enough
                credits. {card ? "Update" : "Add"} card to pay referral fees to
                your partners and their teams.
              </Typography>
            </DialogTitle>
            <IconButton
              disabled={isCreatingCard || isStripeLoading}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
              onClick={() => onClose()}
            >
              <CloseIcon />
            </IconButton>
            <Box px={3} pb={3}>
              <Divider sx={{ width: "100%", mb: 2 }} />
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" textAlign={"right"}>
                    Card Number
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <CardNumberElement
                    options={{
                      iconStyle: "default",
                      style: {
                        base: {
                          fontSize: "0.875rem",
                          fontWeight: 500,
                          lineHeight: "24px",
                          fontFamily:
                            '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                          "::placeholder": {
                            fontWeight: 300,
                            color: neutral[400],
                          },
                        },
                      },
                    }}
                    onChange={e => {
                      e.error && setValidationError(e.error.message);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ width: "100%" }} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" textAlign={"right"}>
                    Expiration Date
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <CardExpiryElement
                    options={{
                      style: {
                        base: {
                          fontSize: "0.875rem",
                          fontWeight: 500,

                          fontFamily:
                            '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                          "::placeholder": {
                            fontWeight: 300,
                            color: neutral[400],
                          },
                        },
                      },
                    }}
                    onChange={e => {
                      e.error && setValidationError(e.error.message);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ width: "100%" }} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" textAlign={"right"}>
                    CVC
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <CardCvcElement
                    options={{
                      style: {
                        base: {
                          fontSize: "0.875rem",
                          fontWeight: 500,
                          lineHeight: "24px",
                          fontFamily:
                            '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                          "::placeholder": {
                            fontWeight: 300,
                            color: neutral[400],
                          },
                        },
                      },
                    }}
                    onChange={e => {
                      e.error && setValidationError(e.error.message);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ width: "100%" }} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" textAlign={"right"}>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    value={holderName}
                    placeholder="Cardholder Name"
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      "& input::placeholder": {
                        fontWeight: 300,
                        fontSize: "0.875rem",
                        color: neutral[400],
                      },
                    }}
                    onChange={e => setHolderName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ width: "100%" }} />
                </Grid>
              </Grid>
              <LoadingButton
                fullWidth
                color="primary"
                variant="contained"
                sx={{ my: 2 }}
                loading={isCreatingCard || isStripeLoading}
                disabled={
                  !holderName || !session?.groups?.some(isAdministratorOrOwner)
                }
                onClick={onSubmitCardDetails}
              >
                Add Card
              </LoadingButton>
              <Typography
                variant="body2"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                Powered by
                <img
                  src="/stripe-logo.svg"
                  alt="stripe"
                  width="34px"
                  height="14px"
                  style={{ marginLeft: "4px" }}
                />
              </Typography>
            </Box>
          </Box>
        )}
      </Dialog>
    </>
  );
}
