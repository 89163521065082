import { Box, Chip, Typography } from "@mui/material";
import { FC, useState } from "react";

import { isDefined } from "@/utils/isDefined";

import { Job, JobStatus, RoutedJobStatus } from "../types/job";
import {
  getHomeownerFormattedName,
  getLastActivityJob,
  SentJobStatusChipColor,
  SentJobStatusChipLabel,
} from "../utils";
import { CompanyProfileDrawer } from "./CompanyProfileDrawer";
import { JobCardContainer } from "./JobCardContainer";

interface JobsCardProps {
  job: Job;
  onClick?: () => void;
}

export const JobSentCard: FC<JobsCardProps> = ({ job, onClick }) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >();
  const isJobDeclined =
    (job?.listingExtraInfo?.latestJobRouting?.status ===
      RoutedJobStatus.INACTIVE ||
      job?.listingExtraInfo?.latestJobRouting?.status ===
        RoutedJobStatus.DECLINED) &&
    job?.status === JobStatus.NO_COMPANY_TO_ROUTE;

  const subText = (status: JobStatus) => {
    if (
      status === JobStatus.PAID ||
      status === JobStatus.PENDING_REFERRAL_PAYMENT
    ) {
      return (
        <>
          Recommended by{" "}
          <Typography display={"inline"} variant="body2" fontWeight={600}>
            {job.listingExtraInfo?.technicianName}
          </Typography>{" "}
          {job.buyingCompanyId ? (
            <>
              |{" "}
              <Typography
                display={"inline"}
                color="primary"
                variant="body2"
                fontWeight={700}
                sx={{ cursor: "pointer" }}
                onClick={event => {
                  event.stopPropagation();
                  setSelectedCompanyId(job.buyingCompanyId);
                }}
              >
                {job.listingExtraInfo?.buyingCompanyName}
              </Typography>{" "}
              Appreciates the Referral!
            </>
          ) : null}
        </>
      );
    }
    if (
      status === JobStatus.HOMEOWNER_DELAYED ||
      status === JobStatus.OPPORTUNITY ||
      status === JobStatus.HOMEOWNER_DECLINED ||
      status === JobStatus.PENDING_HOMEOWNER_ACTION ||
      status === JobStatus.HOMEOWNER_ACCEPTED ||
      status === JobStatus.NO_COMPANY_TO_ROUTE ||
      status === JobStatus.EXPIRED
    ) {
      if (isJobDeclined) {
        return (
          <Box display={"flex"} alignItems={"start"} flexDirection={"column"}>
            {" "}
            <Typography variant="body2" color="text.secondary">
              Recommended by{" "}
              <Typography display={"inline"} variant="body2" fontWeight={600}>
                {job.listingExtraInfo?.technicianName}
              </Typography>{" "}
              {job.listingExtraInfo?.potentialReferralForCompanyId ? (
                <>
                  | Referral for{" "}
                  <Typography
                    display={"inline"}
                    color="primary"
                    variant="body2"
                    fontWeight={700}
                    sx={{ cursor: "pointer" }}
                    onClick={event => {
                      event.stopPropagation();
                      setSelectedCompanyId(
                        job.listingExtraInfo?.potentialReferralForCompanyId
                      );
                    }}
                  >
                    {job.listingExtraInfo?.potentialReferralForCompanyName}
                  </Typography>
                </>
              ) : null}
            </Typography>
            {job?.listingExtraInfo?.latestJobRouting?.companyId ? (
              <Typography variant="body2" color="text.secondary">
                <Typography
                  variant="body2"
                  color="text.secondary"
                  display={"inline"}
                  fontWeight={500}
                >
                  Declined By:
                </Typography>{" "}
                <Typography
                  display={"inline"}
                  color="primary"
                  variant="body2"
                  fontWeight={700}
                  sx={{ cursor: "pointer" }}
                  onClick={event => {
                    event.stopPropagation();
                    setSelectedCompanyId(
                      job.listingExtraInfo?.latestJobRouting?.companyId
                    );
                  }}
                >
                  {job.listingExtraInfo?.latestJobRouting?.companyName}
                </Typography>
              </Typography>
            ) : null}
            {job?.listingExtraInfo?.latestJobRouting?.inactiveReason ? (
              <Typography variant="body2" color="text.secondary">
                <Typography
                  variant="body2"
                  color="text.secondary"
                  display={"inline"}
                  fontWeight={500}
                >
                  Reason:
                </Typography>{" "}
                {job?.listingExtraInfo?.latestJobRouting?.inactiveReason}
              </Typography>
            ) : null}
            {job?.listingExtraInfo?.latestJobRouting?.declinedReason ? (
              <Typography variant="body2" color="text.secondary">
                <Typography
                  variant="body2"
                  color="text.secondary"
                  display={"inline"}
                  fontWeight={500}
                >
                  Reason:
                </Typography>{" "}
                {job?.listingExtraInfo?.latestJobRouting?.declinedReason}
              </Typography>
            ) : null}
          </Box>
        );
      }
      return (
        <>
          Recommended by{" "}
          <Typography display={"inline"} variant="body2" fontWeight={600}>
            {job.listingExtraInfo?.technicianName}
          </Typography>{" "}
          {job.listingExtraInfo?.potentialReferralForCompanyId ? (
            <>
              | Referral for{" "}
              <Typography
                display={"inline"}
                color="primary"
                variant="body2"
                fontWeight={700}
                sx={{ cursor: "pointer" }}
                onClick={event => {
                  event.stopPropagation();
                  setSelectedCompanyId(
                    job.listingExtraInfo?.potentialReferralForCompanyId
                  );
                }}
              >
                {job.listingExtraInfo?.potentialReferralForCompanyName}
              </Typography>
            </>
          ) : null}
        </>
      );
    }
    if (status === JobStatus.ROUTED) {
      return (
        <>
          Recommended by{" "}
          <Typography display={"inline"} variant="body2" fontWeight={600}>
            {job.listingExtraInfo?.technicianName}
          </Typography>{" "}
          {job.listingExtraInfo?.routedToCompanyId ? (
            <>
              |{" "}
              <Typography
                display={"inline"}
                color="primary"
                variant="body2"
                fontWeight={700}
                onClick={event => {
                  event.stopPropagation();
                  setSelectedCompanyId(job.listingExtraInfo?.routedToCompanyId);
                }}
              >
                {job.listingExtraInfo?.routedToCompanyName}
              </Typography>{" "}
              has pending action items
            </>
          ) : null}
        </>
      );
    }

    return null;
  };

  const lastActivity = getLastActivityJob(job);

  return (
    <>
      <JobCardContainer
        jobId={job.id}
        title={`${isDefined(job.jobPromotion?.id) ? "Promotion: " : ""}${
          job.jobType ?? ""
        }`}
        subTitle={
          <>
            {subText(job.status)}
            {job?.listingExtraInfo?.homeownerName ? (
              <Typography color="text.secondary" variant="body2">
                Homeowner:{" "}
                {getHomeownerFormattedName(job.listingExtraInfo.homeownerName)}
              </Typography>
            ) : null}
          </>
        }
        statusArea={
          <>
            {isJobDeclined ? (
              <Chip
                label={
                  <Typography
                    variant="overline"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {"JOB DECLINED"}
                  </Typography>
                }
                sx={{
                  bgcolor: "rgba(240, 68, 56, 0.12)",
                  color: "#B42318",
                  height: "24px",
                }}
              />
            ) : (
              <Chip
                label={
                  <Typography
                    variant="overline"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {job.status === JobStatus.PAID
                      ? `${
                          job.jobBuyingCost ? `+$${job.jobBuyingCost}` : "PAID"
                        }`
                      : SentJobStatusChipLabel[job.status]}
                  </Typography>
                }
                sx={{
                  color: SentJobStatusChipColor[job.status].color,
                  bgcolor: SentJobStatusChipColor[job.status].bgcolor,
                  height: "24px",
                }}
              />
            )}
            <Typography variant="overline" color="text.disabled">
              {job.status === JobStatus.PENDING_HOMEOWNER_ACTION &&
              job.listingExtraInfo?.homeownerViewedAt
                ? `Homeowner Viewed At: ${lastActivity}`
                : job.status === JobStatus.PENDING_HOMEOWNER_ACTION &&
                  job.listingExtraInfo?.homeownerReceivedAt
                ? `Homeowner Received At: ${lastActivity}`
                : job.status === JobStatus.PAID
                ? `Earned: ${lastActivity}`
                : `Last Activity: ${lastActivity}`}
            </Typography>
          </>
        }
        onClick={onClick}
      />
      {selectedCompanyId ? (
        <CompanyProfileDrawer
          open={!!selectedCompanyId}
          companyId={selectedCompanyId}
          onClose={() => {
            setSelectedCompanyId(undefined);
          }}
        />
      ) : null}
    </>
  );
};
