import { Helmet } from "react-helmet-async";

interface SeoProps {
  title: string;
}

export function Seo(props: SeoProps) {
  return (
    <Helmet>
      <title>TradeEngage</title>
    </Helmet>
  );
}
