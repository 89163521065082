import { Delete } from "@mui/icons-material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box, Chip, Tooltip } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useTriggerReferralInvitationNotification } from "@/company/api/useTriggerReferralInvitationNotification";
import { useUpdateCompanyUser } from "@/company/api/useUpdateCompanyUser";
import { JobSectors } from "@/company/jobs/constants";
import { snakeCaseJobSectors } from "@/company/utils";

import { useDeleteReferralInvitations } from "./api/useDeleteReferralInvitations";
import ConfirmationDialog from "./ConfirmationDialog";
import { BrandLocationReferralInvitationSchema } from "./ReferralNetworkOverview";
import {
  CustomToolbar,
  formatDate,
  getGridStringOperatorsDataGrid,
  renderJobSectorsArea,
} from "./utils";

export enum ReferralType {
  REFERRAL_INVITATION_SENT = "REFERRAL_INVITATION_SENT",
  REFERRAL_INVITATION_ACCEPTED = "REFERRAL_INVITATION_ACCEPTED",
  AFFILIATE_COMPANIES = "AFFILIATE_COMPANIES",
  RECOMMENDED_PARTNERS = "RECOMMENDED_PARTNERS",
  REFERRALS = "REFERRALS",
}

export default function ReferralInvitationTable({
  referralInvitations,
  companyId,
  expanded,
  refetch,
  isFetchingProfile,
}: {
  referralInvitations: Array<BrandLocationReferralInvitationSchema>;
  companyId: number;
  expanded?: boolean;
  refetch: () => void;
  isFetchingProfile?: boolean;
}) {
  const [rows, setRows] = useState([]);
  const [triggerReferralNotificationId, setTriggerReferralNotificationId] =
    useState<number | null>(null);
  const [deleteInviteId, setDeleteInviteId] = useState<
    GridRowId | null | undefined | number
  >(null);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const { mutateAsync: updateUser, isLoading } = useUpdateCompanyUser({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while updating user details.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      enqueueSnackbar({
        message: "Your changes have been saved.",
        variant: "success",
      });
    },
  });

  const { mutateAsync: triggerNotification } =
    useTriggerReferralInvitationNotification({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while triggering notification.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        enqueueSnackbar({
          message: "Notifications sent successfully.",
          variant: "success",
        });
      },
    });

  const { mutateAsync: deleteReferralInvitations, isLoading: isDeleting } =
    useDeleteReferralInvitations({
      onSuccess: () => {
        refetch();
        enqueueSnackbar({
          message: "Invitations deleted successfully.",
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while deleting invitations.`,
          variant: "error",
        });
      },
    });

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const processRowUpdate = async (newRow: GridRowModel, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    await updateUser({
      companyId,
      userId: updatedRow.id,
      updatedUser: {
        ...newRow,
        firstName: newRow?.name?.split(" ")?.[0],
        lastName: newRow?.name?.split(" ")?.[1],
        oldGroups: oldRow.types,
        newGroups: newRow.types,
      },
    });

    setRows(
      rows.map(row =>
        row.id === newRow.id
          ? {
              ...updatedRow,
              firstName: newRow?.name?.split(" ")?.[0],
              lastName: newRow?.name?.split(" ")?.[1],
            }
          : row
      )
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    setRows(referralInvitations);
  }, [referralInvitations]);

  let columns: GridColDef[] = [
    {
      field: "logoUrl",
      headerName: "Logo",
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <img
              src={params.row.logoUrl}
              style={{
                width: "48px",
                height: "32px",
                objectFit: "contain",
              }}
              alt="company-logo"
            />
          </Box>
        );
      },
      sortable: false,
    },
    {
      field: "id",
      headerName: "Company Id",
      width: 200,
      renderCell: params => params.row?.id?.toString(),
    },
    {
      field: "invitationId",
      headerName: "Invitation Id",
      width: 200,
    },
    {
      field: "manual",
      headerName: "Manual",
      width: 200,
      type: "boolean",
      description:
        "Whether this invitation was manually sent to an email, and not to a company on the database",
    },
    {
      field: "email",
      headerName: "Manual Email",
      width: 200,
      type: "string",
      description: "The email the invitation was manually sent to",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "string",
      width: 200,
      renderCell: params => formatDate(params.row?.createdAt),
    },
    {
      field: "acceptedAt",
      headerName: "Accepted At",
      type: "string",
      width: 200,
      renderCell: params => formatDate(params.row?.acceptedAt),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "sectors",
      headerName: "Service Categories",
      type: "string",
      width: 200,
      renderEditCell: renderJobSectorsArea,
      //valueOptions: Object.keys(reverseSnakeCaseJobSectors),
      renderCell: params => {
        const sectorChips = params.row?.sectors?.map((sector: JobSectors) => (
          <Chip
            key={sector}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={snakeCaseJobSectors[sector as JobSectors]}
            size="small"
          />
        ));
        return sectorChips;
      },
    },
    {
      field: "invitationLink",
      headerName: "Invitation Link",
      width: 200,
    },
    {
      field: "ownersName",
      headerName: "Owners Name",
      width: 200,
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[0],
      renderCell: params => params.row?.ownersInformation?.[0]?.[0],
    },
    {
      field: "ownersPhone",
      headerName: "Owners Phone",
      width: 200,
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[1],
      renderCell: params => params.row?.ownersInformation?.[0]?.[1],
    },
    {
      field: "ownersPhoneCarrierType",
      headerName: "Owners Phone Carrier Type",
      width: 200,
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[2],
      renderCell: params => params.row?.ownersInformation?.[0]?.[2],
    },
    {
      field: "ownersEmail",
      headerName: "Owners Email",
      width: 200,
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[3],
      renderCell: params => params.row?.ownersInformation?.[0]?.[3],
    },
    {
      field: "declinedAt",
      headerName: "Declined At",
      type: "string",
      width: 200,
      renderCell: params => formatDate(params.row?.declinedAt),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      align: "right",
      headerAlign: "right",
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <Tooltip
            key={`${id}-trigger-invite-tooltip`}
            title="Click to re-trigger referral invitation notifications"
          >
            <GridActionsCellItem
              key={`${id}-trigger-invite`}
              icon={<NotificationsActiveIcon />}
              label="Notify"
              color="inherit"
              onClick={() => {
                setTriggerReferralNotificationId(row.invitationId);
              }}
            />
          </Tooltip>,
          ...(row.acceptedAt
            ? []
            : [
                <GridActionsCellItem
                  key={`${id}-delete`}
                  icon={<Delete />}
                  label="Delete"
                  color="inherit"
                  disabled={isDeleting}
                  onClick={() => {
                    setDeleteInviteId(row.invitationId);
                  }}
                />,
              ]),
        ];
      },
    },
  ];

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  return (
    <Box display={"flex"} width={"100%"}>
      <Box
        sx={{
          height: 400,
          position: "relative",
          width: "100%",
        }}
      >
        <ConfirmationDialog
          open={!!triggerReferralNotificationId}
          handleClose={() => setTriggerReferralNotificationId(null)}
          handleConfirm={() => {
            triggerNotification(triggerReferralNotificationId!);
            setTriggerReferralNotificationId(null);
          }}
          text={`Are you sure you want to re-trigger the notifications?`}
          title="Send Referral Invitation Notifications"
          confirmText="Send"
        />
        <DataGridPremium
          checkboxSelection
          disableRowSelectionOnClick
          pagination
          getRowId={row => row.invitationId}
          columns={columns}
          sx={{
            "& .MuiDataGrid-row": {
              "--rowBorderColor": "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withRightBorder": {
              borderRightColor: "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withLeftBorder": {
              borderLeftColor: "#D9D9D9",
            },
          }}
          rows={rows ?? []}
          slots={{
            ...(expanded
              ? {
                  toolbar: () => (
                    <CustomToolbar fileName="Referral_Invitations" />
                  ),
                }
              : {}),
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          loading={isLoading || isDeleting || isFetchingProfile}
          processRowUpdate={processRowUpdate}
          initialState={{
            density: "compact",
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "logoUrl", "name"],
              right: ["actions"],
            },
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          slotProps={{
            toolbar: { setRowModesModel },
          }}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
        />
      </Box>
      <ConfirmationDialog
        open={!!deleteInviteId}
        handleClose={() => setDeleteInviteId(undefined)}
        handleConfirm={() => {
          deleteReferralInvitations({
            referralInvitationIds: [deleteInviteId as unknown as number],
          });
          setDeleteInviteId(undefined);
        }}
        text={`Are you sure you want to delete the invite?`}
        title="Confirm Invite Deletion"
        confirmText="Delete"
      />
    </Box>
  );
}
