import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  FormControl,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { ElementType, useEffect } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import {
  useMutationCreateGroupUsersBulk,
  useQueryFetchCompanyGroups,
} from "@/company/onboarding/api";
import { DefaultCompanyGroups } from "@/company/types";
import {
  getUSPhoneValidationSchema,
  NAME_REGEX,
  snakeCaseToTitleCase,
} from "@/utils";

import { STAFF_TYPES } from "./types";
import {
  getCompanyGroupIdFromName,
  getRolesFromStaffType,
  getToolTipInfoFromGroupName,
} from "./utils";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .matches(NAME_REGEX, "Enter a valid name")
    .required("First Name is required"),
  lastName: yup
    .string()
    .matches(NAME_REGEX, "Enter a valid name")
    .required("Last Name is required"),
  email: yup.string().email("Email address must be a valid email").optional(),
  cellPhone: getUSPhoneValidationSchema(),
  role: yup.string().required("Role is required"),
});

export function AddStaffForm({
  onClose,
  staffType,
}: {
  onClose: () => void;
  staffType: STAFF_TYPES;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const { data: companyGroups, isLoading: fetchingCompanyGroups } =
    useQueryFetchCompanyGroups(session?.company?.id);

  const { mutateAsync: updateUsersBulk, isLoading: updatingUser } =
    useMutationCreateGroupUsersBulk({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while adding team member.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        onClose();
        enqueueSnackbar({
          message: `${snakeCaseToTitleCase(
            formik.values.role?.toString()
          )} Invite Sent!`,
          variant: "success",
        });
        formik.resetForm();
        formik.setFieldValue(
          "role",
          staffType == STAFF_TYPES.FIELD_TEAM
            ? DefaultCompanyGroups.FIELD_TEAM
            : ""
        );
      },
    });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      cellPhone: "",
      role: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      const { firstName, lastName, email, cellPhone, role } = values;
      if (!companyGroups) {
        return;
      }
      const groupId = getCompanyGroupIdFromName(
        companyGroups,
        role?.replaceAll(" ", "_").toUpperCase() as DefaultCompanyGroups
      );

      updateUsersBulk({
        groupId,
        users: [
          {
            firstName,
            lastName,
            email,
            cellPhone: `+1${cellPhone?.replace(/[\s()-]/g, "")}`,
            jobTitle: _.startCase(
              role.toString().toLowerCase().replaceAll("_", " ")
            ),
            type: USER_TYPE.COMPANY_TEAM_MEMBER,
            balance: 0,
          },
        ],
      });
    },
  });

  const onHandleOk = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    formik.setFieldValue(
      "role",
      staffType == STAFF_TYPES.FIELD_TEAM ? DefaultCompanyGroups.FIELD_TEAM : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffType]);

  return (
    <Box
      component={"form"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        lineHeight={1}
        fontWeight={600}
        textAlign={"left"}
      >
        Add Team Member
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gap={1} pt={"12px"}>
        <FormControl fullWidth>
          <InputLabel id="role-select-label">Select Role*</InputLabel>
          <Select
            required
            labelId="role-select-label"
            id="role"
            name="role"
            value={formik.values.role}
            label="Select Role*"
            renderValue={value => (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                {
                  <Typography
                    sx={{ width: { xs: 1, md: 1 / 2 }, fontSize: "14px" }}
                  >
                    {snakeCaseToTitleCase(value.toString())}
                  </Typography>
                }
              </Box>
            )}
            error={formik.touched.role && Boolean(formik.errors.role)}
            onChange={formik.handleChange}
          >
            {getRolesFromStaffType(STAFF_TYPES.OPERATIONS).map(role => (
              <MenuItem key={role} value={role}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  {
                    <Typography>
                      {snakeCaseToTitleCase(role.toString())}
                    </Typography>
                  }
                  <ToolTip
                    title={snakeCaseToTitleCase(role.toString())}
                    message={getToolTipInfoFromGroupName(role)}
                    placement="right"
                  >
                    <HelpOutlineIcon
                      sx={{
                        background: "none",
                        color: "#6C737F",
                        width: "20px",
                        height: "20px",
                      }}
                      fontSize="small"
                    />
                  </ToolTip>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          sx={{ width: { xs: 1 }, fontSize: "12px" }}
          InputLabelProps={{ style: { fontSize: "12px" } }}
          id="firstName"
          name="firstName"
          label="First Name"
          value={formik.values.firstName}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <TextField
          required
          sx={{ width: { xs: 1 }, fontSize: "12px" }}
          InputLabelProps={{ style: { fontSize: "12px" } }}
          id="lastName"
          name="lastName"
          label="Last Name"
          value={formik.values.lastName}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <TextField
          required
          fullWidth
          id="cellPhone"
          name="cellPhone"
          label="Mobile Number"
          sx={{ fontSize: "12px" }}
          InputLabelProps={{ style: { fontSize: "12px" } }}
          InputProps={{
            inputComponent: MaskedPhoneInput as unknown as
              | ElementType<InputBaseComponentProps>
              | undefined,
          }}
          value={formik.values.cellPhone}
          error={formik.touched.cellPhone && Boolean(formik.errors.cellPhone)}
          helperText={formik.touched.cellPhone && formik.errors.cellPhone}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <TextField
          fullWidth
          id="email"
          name="email"
          type="email"
          label="Work Email Address"
          sx={{ fontSize: "12px" }}
          InputLabelProps={{ style: { fontSize: "12px" } }}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Box>
      <Stack
        spacing={2}
        direction="row"
        textAlign={"center"}
        width={"100%"}
        mt={2}
      >
        <Button
          fullWidth
          variant="contained"
          disabled={updatingUser || fetchingCompanyGroups}
          onClick={onHandleOk}
        >
          Send Invite
        </Button>
      </Stack>
    </Box>
  );
}
