import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { DateTime } from "luxon";

import axiosInstance from "@/common/api";
import { reverseSnakeCaseJobSectors } from "@/company/utils";
import { keysToSnakeCase } from "@/utils";

import { JobSectors } from "../constants";
import { type Job, JobStatus } from "../types";

const GET_JOBS_URL = "/jobs/v2";

export interface SentJobRequestFilters {
  dateRange?: { startDate?: DateTime; endDate?: DateTime };
  sectors?: Array<JobSectors>;
  status?: Array<JobStatus>;
  createdByUserId?: number;
}

export function useGetSentJobsByCompany(
  filters?: SentJobRequestFilters,
  options?: UseQueryOptions<any>,
  isV2?: boolean
): UseQueryResult<Array<Job>> {
  const newFilters: Record<string, unknown> = {};

  const sectors = filters?.sectors
    ?.map(sector => reverseSnakeCaseJobSectors[sector as JobSectors])
    ?.map(sector => "sectors=" + sector)
    .join("&");

  const statuses = filters?.status
    ?.map(status => status.toString().replaceAll(" ", "_").toUpperCase())
    ?.map(status => "statuses=" + status)
    .join("&");

  if (filters?.dateRange) {
    newFilters.fromDate = filters.dateRange?.startDate?.toISO();
    newFilters.toDate = filters.dateRange?.endDate?.toISO();
  }
  if (filters?.createdByUserId) {
    newFilters.createdByUserId = filters.createdByUserId;
  }

  const searchQuery = new URLSearchParams(keysToSnakeCase({ ...newFilters }));
  const uriWithSearchQuery = `${GET_JOBS_URL}?${searchQuery.toString()}${
    sectors ? `&${sectors}` : ""
  }${statuses ? `&${statuses}` : ""}`;
  return useQuery({
    queryKey: [uriWithSearchQuery],
    queryFn: async () => {
      const response = await axiosInstance.get(uriWithSearchQuery);
      return response.data.data;
    },
    ...options,
  });
}
