import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import { FC } from "react";

import { useAuth } from "@/auth/context/jwt";
import { useCompanyShare } from "@/company/api/useCompanyShare";
import { CompanyPublicProfile } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

export const ProfileNetworkInvite: FC<{
  companyProfile: CompanyPublicProfile;
}> = ({ companyProfile }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const sessionCompany = session?.company;

  const { data: link, isLoading } = useCompanyShare(companyProfile.id, {
    enabled: !!companyProfile.id,
  });

  const messageBodyEmail = `Hi,

We recommend ${companyProfile.name} for all of your ${
    snakeCaseJobSectors[companyProfile.sectors?.[0]?.sector]
  } needs. Click the link below to view the company profile, request a service and track all of your home service projects on TradeEngage for free.

${link?.link}

Rest assured, you are in good hands!
      
Best,
The Team at ${sessionCompany?.name}`;

  const messageBodySms = `👋 This is ${sessionCompany?.name}. We recommend ${
    companyProfile.name
  } for all your ${
    snakeCaseJobSectors[companyProfile.sectors?.[0]?.sector]
  } needs. Tap the link below to see their profile and request a service on TradeEngage for free! ${
    link?.link
  }`;

  const handleMailClick = () => {
    const emailSubject = encodeURIComponent(
      snakeCaseJobSectors[companyProfile.sectors?.[0]?.sector]
    );
    const emailBody = encodeURIComponent(messageBodyEmail);
    const mailtoLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
    window.location.href = mailtoLink;
  };

  const handleSMSClick = () => {
    const body = encodeURIComponent(messageBodySms);
    if (navigator.userAgent.match(/Android/i)) {
      window.open(`sms:?&body=${body}`, "_blank");
    }
    if (navigator.userAgent.match(/iPhone/i)) {
      window.open(`sms:?&body=${body}`, "_blank"); // TODO: Add sms format for iOS
    }
    window.open(`sms:?&body=${body}`, "_blank");
  };
  return (
    <Box
      py={3}
      display={"flex"}
      flexDirection={"column"}
      gap={3}
      sx={{ height: "356.50px" }}
    >
      {" "}
      {isLoading ? (
        <Box py={3} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display={"flex"} justifyContent={"center"} gap={4} py={3}>
            <Box textAlign={"center"}>
              <IconButton
                color="primary"
                sx={{ background: "#F3F4F6" }}
                onClick={() => handleSMSClick()}
              >
                <MessageOutlinedIcon />
              </IconButton>
              <Typography color="text.secondary" variant="body2">
                Text Message
              </Typography>
            </Box>
            <Box textAlign={"center"}>
              <IconButton
                color="primary"
                sx={{ background: "#F3F4F6" }}
                onClick={() => handleMailClick()}
              >
                <MailOutlineOutlinedIcon />
              </IconButton>
              <Typography color="text.secondary" variant="body2">
                Email
              </Typography>
            </Box>
          </Box>
          <Divider>
            <Typography
              display={"inline"}
              variant="body2"
              color={"text.disabled"}
            >
              Copy Link
            </Typography>
          </Divider>
          <Box
            display={"flex"}
            width={"100%"}
            sx={{
              gap: 2,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              disabled
              fullWidth
              hiddenLabel
              size="small"
              sx={{
                bgcolor: "neutral.100",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InsertLinkIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: false }}
              name="link"
              value={link?.link}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ width: "fit-content" }}
              startIcon={<ContentCopyOutlinedIcon />}
              onClick={() => {
                copy(link?.link);
                enqueueSnackbar({
                  message: "The link was copied!",
                  variant: "success",
                });
              }}
            >
              Copy Link
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
