import "./styles.css"; // Import your custom styles

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  tabsClasses,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { useGetHubs } from "@/analytics/api/useGetHubs";
import { useAuth } from "@/auth/context/jwt";
import { isCompanyActive } from "@/common/utils/accessControl";
import {
  CompanyExploreFilters,
  SearchedCompany,
} from "@/company/api/useExploreConnections";
import { useGetReferralInterests } from "@/company/api/useGetReferralInterests";
import { useMarkedInterestSetContext } from "@/company/context/marked-interest-context";
import { useReferralContext } from "@/company/context/referral-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import {
  CompanyPublicProfile,
  ConnectionStatus,
  PLATFORM_PRESENCE,
  ReferralInvitation,
} from "@/company/types";

import { CompanyDrawer, TabsEnum } from "../../company-drawer/CompanyDrawer";
import { ExternalCompanyDrawer } from "../../company-drawer/ExternalCompanyDrawer";
import { ReferralInvitationDrawer } from "../../company-drawer/ReferralInvitationDrawer";
import { CompanyInvitationCard } from "../../company-profile-card/CompanyInvitationCard";
import { CompanyProfileCard } from "../../company-profile-card/CompanyProfileCard";
import { ConsultingNetwork } from "./ConsultingNetwork";
import { HubNetwork } from "./HubNetwork";
import { OtherRecommendations } from "./OtherRecommendations";
import { PrivateEquityNetwork } from "./PrivateEquityNetwork";

export const ExplorePartnerTabs = [
  { label: "Pending Invitations", filter: {} },
  { label: "Affiliate Companies", filter: {} },
  { label: "Companies You May Know", filter: {} },
  { label: "Explore", filters: {} },
];
export interface CompanyFiltersModal {
  initialValues: CompanyExploreFilters;
  applyFilters: (filters: CompanyExploreFilters) => void;
  variant: "permanent" | "temporary";
  closeFilters: () => void;
}

export enum TabsEnumPartners {
  PENDING_INVITATIONS = "PENDING_INVITATIONS",
  AFFILIATE_COMPANIES = "AFFILIATE_COMPANIES",
  PRIVATE_EQUITY = "PRIVATE_EQUITY",
  OTHER_RECOMMENDATIONS = "OTHER_RECOMMENDATIONS",
}

export const filterValidationSchema = {};

export function ExplorePartnersTabScreen({
  companies,
  isFetchingCompanies,
  handleTabsChange,
  setFiltersThroughRecommendationTab,
  currentTab,
  showFirstInviteTooltip,
  setShowTooltip,
  isTabPanelSticky = true,
  showRecommendedPartnersOnly = false,
}: {
  companies?: Array<SearchedCompany>;
  isFetchingCompanies?: boolean;
  showFirstInviteTooltip: boolean;
  handleTabsChange: (event: ChangeEvent<any>, value: TabsEnumPartners) => void;
  setFiltersThroughRecommendationTab?: (
    drawerFilters: Partial<CompanyExploreFilters>
  ) => void;
  currentTab: TabsEnumPartners;
  isTabPanelSticky?: boolean;
  setShowTooltip?: (showToolTip: boolean) => void;
  showRecommendedPartnersOnly?: boolean;
}) {
  const [selectedTab, setSelectedTab] = useState<TabsEnum | null>(null);
  const { updateMarkedInterestSet } = useMarkedInterestSetContext();

  const [currentCompanyProfile, setCurrentCompanyProfile] =
    useState<CompanyPublicProfile | null>(null);

  const [selectedCompany, setSelectedCompany] =
    useState<SearchedCompany | null>(null);

  const [currentInvitation, setCurrentInvitation] =
    useState<ReferralInvitation | null>(null);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const { referralPartners, isFetchingReferralPartners } = useReferralContext();
  const { referralInvitations, isFetchingReferralInvitations } =
    useReferralInvitationContext();

  const { data: referralInterests, isFetching: isFetchingReferralInterests } =
    useGetReferralInterests({ refetchOnWindowFocus: false });

  let otherCompanies = companies?.filter(
    company =>
      !referralPartners?.some(
        partner =>
          partner.otherCompanyProfile.id === company?.companyPublicProfile?.id
      )
  );

  const googlePlacesIdSet = new Set<string>();

  otherCompanies = otherCompanies?.filter(company => {
    const googleId =
      company.companyPublicProfile?.profile.googlePlacesId ??
      company.googleCompanyProfile?.googlePlacesId;
    if (googleId) {
      if (googlePlacesIdSet.has(googleId)) return false;
      googlePlacesIdSet.add(googleId);
      return true;
    }
    return true;
  });

  const referralInterestsFiltered = referralInterests?.filter(company => {
    const googleId =
      company.companyPublicProfile?.profile.googlePlacesId ??
      company.googleCompanyProfile?.googlePlacesId;
    if (googleId) {
      if (googlePlacesIdSet.has(googleId)) return false;
      googlePlacesIdSet.add(googleId);
      return true;
    }
    return true;
  });

  const pendingInvitations = referralInvitations?.filter(
    invitation => !invitation.declinedAt && !invitation.acceptedAt
  );

  const getConnectionStatus = useCallback(
    (currentCompany: CompanyPublicProfile): ConnectionStatus => {
      if (
        referralPartners?.some(
          referral =>
            referral.referredCompanyId === currentCompany?.id ||
            referral.referringCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.CONNECTED;

      if (
        referralInvitations?.some(
          invitation => invitation.invitedCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.PENDING;

      if (
        referralInvitations?.some(
          invitation => invitation.invitingCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.INVITED;

      return ConnectionStatus.NOT_CONNECTED;
    },
    [referralInvitations, referralPartners]
  );

  const { session } = useAuth();
  const { data: hubs } = useGetHubs();
  const company = session?.company;
  const familyOfBrands = session?.familyOfBrands;
  const consultants = session?.consultants;

  const privateEquity = session?.privateEquity;
  const user = session?.user;

  const brandLocationProfile = session?.brandLocationProfile;

  const getTabPanelStyles = () => {
    if (isTabPanelSticky && !isDesktop) {
      return {
        position: "fixed",
        top: "112px",
        width: "100%",
        pr: 4,
      };
    }
    if (!isTabPanelSticky && !isDesktop) {
      return {
        position: "relative",
      };
    }
    if (isDesktop) {
      return {
        position: "sticky",
        top:
          isCompanyActive(company?.status) &&
          brandLocationProfile?.showExplorePartners
            ? "208px"
            : "123px",
      };
    }
  };

  useEffect(() => {
    if (currentCompanyProfile) {
      setShowTooltip?.(false);
    }
  }, [currentCompanyProfile]);

  useEffect(() => {
    if (currentInvitation) {
      setShowTooltip?.(false);
    }
  }, [currentInvitation]);

  useEffect(() => {
    if (selectedCompany) {
      setShowTooltip?.(false);
    }
  }, [selectedCompany]);

  const firstOffPlatformInvitation = pendingInvitations?.find(
    invitation =>
      invitation.otherCompanyProfile.platformPresence ===
      PLATFORM_PRESENCE.OFF_PLATFORM
  );

  console.log({ pendingInvitations, referralInvitations, referralPartners });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            ...getTabPanelStyles(),
            background: "white",
            zIndex: 1000,
          }}
        >
          <Tabs
            value={currentTab}
            variant="scrollable"
            scrollButtons={isDesktop ? false : true}
            allowScrollButtonsMobile={isDesktop ? false : true}
            sx={{
              ...(isDesktop
                ? {}
                : {
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                  }),
            }}
            onChange={handleTabsChange}
          >
            <Tab
              value={TabsEnumPartners.PENDING_INVITATIONS}
              label="Pending Invitations"
            />
            {familyOfBrands ? (
              <Tab
                value={TabsEnumPartners.AFFILIATE_COMPANIES}
                label="Affiliate Companies"
              />
            ) : null}
            {privateEquity ? (
              <Tab
                value={TabsEnumPartners.PRIVATE_EQUITY}
                label={`${privateEquity.name} Network`}
              />
            ) : null}
            {hubs?.length
              ? hubs?.map(hub => (
                  <Tab key={hub.id} value={hub.id} label={`${hub.name}`} />
                ))
              : null}
            {consultants?.length
              ? consultants?.map(consultant => (
                  <Tab
                    key={consultant.id}
                    value={consultant.id}
                    label={`${consultant.name} Network`}
                  />
                ))
              : null}
            <Tab
              value={TabsEnumPartners.OTHER_RECOMMENDATIONS}
              label="Other Recommendations"
            />
          </Tabs>
          <Divider />
        </Box>
        {currentTab === TabsEnumPartners.PENDING_INVITATIONS ? (
          <Grid
            container
            py={isDesktop ? 3 : 1}
            sx={{ ...(isTabPanelSticky && !isDesktop ? { mt: "112px" } : {}) }}
          >
            {isFetchingReferralPartners || isFetchingReferralInvitations ? (
              <Box
                py={3}
                mt={6}
                textAlign="center"
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                gap={2}
              >
                <CircularProgress />
                <Typography variant="body1">
                  Hang tight! We&apos;re building your list of pending referral
                  invitations.
                </Typography>
              </Box>
            ) : (
              <>
                {pendingInvitations && pendingInvitations.length > 0
                  ? pendingInvitations.map((invitation, index) => {
                      return (
                        <Grid key={index} item xs={12} sm={6} lg={4} p={2}>
                          <CompanyInvitationCard
                            isFirstOffPlatformCompany={
                              firstOffPlatformInvitation?.id === invitation.id
                            }
                            showTooltip={showFirstInviteTooltip}
                            referralInvitation={invitation}
                            onMessageMenuClick={() => {
                              setShowTooltip?.(false);
                            }}
                            onOpen={(tab?: TabsEnum) => {
                              if (tab) {
                                setSelectedTab(tab);
                                setCurrentInvitation(invitation ?? null);
                              }
                            }}
                          />
                        </Grid>
                      );
                    })
                  : null}
                {referralInterestsFiltered &&
                referralInterestsFiltered.length > 0
                  ? referralInterestsFiltered.map((companyData, index) => (
                      <Grid key={index} item xs={12} sm={6} lg={4} p={2}>
                        <CompanyProfileCard
                          {...(companyData.companyPublicProfile
                            ? {
                                companyProfile:
                                  companyData.companyPublicProfile,
                                connectionStatus: referralPartners?.some(
                                  referral =>
                                    referral.referredCompanyId ===
                                      company?.id ||
                                    referral.referringCompanyId === company?.id
                                )
                                  ? ConnectionStatus.CONNECTED
                                  : ConnectionStatus.NOT_CONNECTED,
                              }
                            : {})}
                          {...(companyData.googleCompanyProfile
                            ? {
                                googleCompanyProfile:
                                  companyData.googleCompanyProfile,
                                hasMarkedInterest:
                                  !!companyData.hasMarkedInterest,
                              }
                            : {})}
                          showParentLogo={false}
                          onOpen={({ tab }) => {
                            if (tab) {
                              setSelectedTab(tab);
                              setCurrentCompanyProfile(
                                companyData.companyPublicProfile ?? null
                              );
                            } else {
                              setSelectedCompany(companyData ?? null);
                            }
                          }}
                        />
                      </Grid>
                    ))
                  : null}
                {referralInterests?.length ||
                pendingInvitations?.length ? null : (
                  <Box textAlign="center" width="100%" py={3}>
                    {brandLocationProfile?.showExplorePartners ? (
                      <Typography variant="body1">
                        You don&apos;t have any pending invitations. Connect
                        with referral partners by exploring affiliate companies
                        or other recommendations!
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        You don&apos;t have any pending invitations.{" "}
                        <Link
                          color="#16B364"
                          href={"mailto:info@tradeengage.com"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Typography
                            display="inline"
                            variant="body1"
                            color="#16B364"
                          >
                            Contact the TradeEngage team
                          </Typography>
                        </Link>{" "}
                        to connect with your affiliate companies and more home
                        service providers in your area.
                      </Typography>
                    )}
                  </Box>
                )}
              </>
            )}
          </Grid>
        ) : null}
        {currentTab === TabsEnumPartners.AFFILIATE_COMPANIES ? (
          <Grid
            container
            py={3}
            sx={{ ...(isTabPanelSticky && !isDesktop ? { mt: "112px" } : {}) }}
          >
            {isFetchingReferralPartners || isFetchingCompanies ? (
              <Box
                py={3}
                mt={6}
                textAlign="center"
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                gap={2}
              >
                <CircularProgress />
                <Typography variant="body1">
                  Hang tight! We&apos;re building your custom list of affiliate
                  referral partners.
                </Typography>
              </Box>
            ) : otherCompanies && otherCompanies.length > 0 ? (
              otherCompanies.map((companyData, index) => (
                <Grid key={index} item xs={12} sm={6} lg={4} p={2}>
                  <CompanyProfileCard
                    {...(companyData.companyPublicProfile
                      ? {
                          companyProfile: companyData.companyPublicProfile,
                          connectionStatus: getConnectionStatus(
                            companyData.companyPublicProfile
                          ),
                        }
                      : {})}
                    {...(companyData.googleCompanyProfile
                      ? {
                          googleCompanyProfile:
                            companyData.googleCompanyProfile,
                          hasMarkedInterest: !!companyData.hasMarkedInterest,
                        }
                      : {})}
                    showParentLogo={true}
                    onOpen={({ tab }) => {
                      if (tab) {
                        setSelectedTab(tab);
                        setCurrentCompanyProfile(
                          companyData.companyPublicProfile ?? null
                        );
                      } else {
                        setSelectedCompany({
                          ...companyData,
                        });
                      }
                    }}
                  />
                </Grid>
              ))
            ) : (
              <Box
                textAlign="center"
                width="100%"
                py={3}
                sx={{
                  ...(isTabPanelSticky && !isDesktop ? { mt: "112px" } : {}),
                }}
              >
                <Typography variant="body1">
                  You don&apos;t have any nearby affiliate companies. Connect
                  with referral partners by exploring other recommendations!
                </Typography>
              </Box>
            )}
          </Grid>
        ) : null}
        {currentTab === TabsEnumPartners.PRIVATE_EQUITY ? (
          <PrivateEquityNetwork
            privateEquity={privateEquity}
            isTabPanelSticky={isTabPanelSticky}
            setSelectedCompany={setSelectedCompany}
            setCurrentCompanyProfile={setCurrentCompanyProfile}
            setSelectedTab={setSelectedTab}
            referralPartners={referralPartners}
            referralInvitations={referralInvitations}
          />
        ) : null}
        {hubs?.some(hub => hub.id == currentTab) ? (
          <HubNetwork
            hub={hubs?.find(hub => hub.id == currentTab)}
            isTabPanelSticky={isTabPanelSticky}
            setSelectedCompany={setSelectedCompany}
            setCurrentCompanyProfile={setCurrentCompanyProfile}
            setSelectedTab={setSelectedTab}
            referralPartners={referralPartners}
            referralInvitations={referralInvitations}
          />
        ) : null}
        {consultants?.some(consultant => consultant.id == currentTab) ? (
          <ConsultingNetwork
            consultant={consultants?.find(
              consultant => consultant.id == currentTab
            )}
            isTabPanelSticky={isTabPanelSticky}
            setSelectedCompany={setSelectedCompany}
            setCurrentCompanyProfile={setCurrentCompanyProfile}
            setSelectedTab={setSelectedTab}
            referralPartners={referralPartners}
            referralInvitations={referralInvitations}
          />
        ) : null}
        {currentTab === TabsEnumPartners.OTHER_RECOMMENDATIONS ? (
          <OtherRecommendations
            isTabPanelSticky={isTabPanelSticky}
            setFiltersThroughRecommendationTab={
              setFiltersThroughRecommendationTab
            }
            showRecommendedPartnersOnly={showRecommendedPartnersOnly}
            setSelectedCompany={setSelectedCompany}
            setCurrentCompanyProfile={setCurrentCompanyProfile}
            setSelectedTab={setSelectedTab}
            referralPartners={referralPartners}
            referralInvitations={referralInvitations}
          />
        ) : null}
      </Grid>
      {selectedTab && currentCompanyProfile ? (
        <CompanyDrawer
          open={!!currentCompanyProfile}
          companyProfile={currentCompanyProfile}
          connectionStatus={getConnectionStatus(currentCompanyProfile)}
          defaultTab={selectedTab}
          onClose={() => {
            setCurrentCompanyProfile(null);
            setSelectedTab(null);
          }}
        />
      ) : null}
      {selectedTab && currentInvitation ? (
        <ReferralInvitationDrawer
          open={!!currentInvitation}
          invitation={currentInvitation}
          connectionStatus={getConnectionStatus(
            currentInvitation.otherCompanyProfile
          )}
          defaultTab={selectedTab}
          onClose={() => {
            setCurrentInvitation(null);
            setSelectedTab(null);
          }}
        />
      ) : null}
      {selectedCompany ? (
        <ExternalCompanyDrawer
          open={!!selectedCompany}
          companyGoogleProfile={selectedCompany.googleCompanyProfile}
          hasMarkedInterest={!!selectedCompany?.hasMarkedInterest}
          onClose={markedInterest => {
            if (
              markedInterest &&
              selectedCompany.googleCompanyProfile?.googlePlacesId
            ) {
              updateMarkedInterestSet(
                selectedCompany.googleCompanyProfile?.googlePlacesId ?? ""
              );
            }
            setSelectedCompany(null);
          }}
        />
      ) : null}
    </Grid>
  );
}
