import { Job } from "@/company/jobs/types";

export enum UserTransactionConcepts {
  REFERRAL_FEE_EARNED = "REFERRAL_FEE_EARNED",
  WITHDRAWAL = "WITHDRAWAL",
}

export enum UserOnboardingStatus {
  CREATED = "CREATED",
  ONBOARDED = "ONBOARDED",
  INVITED = "INVITED",
}

export interface UserTransaction {
  id: number;
  userId: number;
  amount: number;
  oldBalance: number;
  newBalance: number;
  concept: UserTransactionConcepts;
  createdAt: string;
  jobId?: number;
  job?: Job;
}

export enum StripeAccountStatus {
  NEEDS_UPDATE = "NEEDS_UPDATE",
  NEEDS_CREATION = "NEEDS_CREATION",
  OK = "OK",
}
