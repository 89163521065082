import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Chip,
  MenuItem,
  Popper,
  styled,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { FC, SyntheticEvent, useEffect, useState } from "react";

import { ExternalBasicCompanySchema } from "@/company/types";

const StyledPopper = styled(Popper)({
  "& .MuiAutocomplete-paper": {
    maxHeight: "10px", // Set maximum height for the dropdown
    overflowY: "auto", // Enable vertical scrolling
  },
});

interface CompanySelectionPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  companies?: Array<ExternalBasicCompanySchema>;
  selectedCompanies?: Array<ExternalBasicCompanySchema>;

  setSelectedCompanies: (
    selectedCompanies: Array<ExternalBasicCompanySchema>
  ) => void;
}

export const CompanySelectionPopover: FC<
  CompanySelectionPopoverProps
> = props => {
  const {
    anchorEl,
    onClose,
    open,
    companies,
    selectedCompanies,
    setSelectedCompanies,
    ...other
  } = props;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [tagsLimit, setTagsLimit] = useState<number | undefined>(2);
  const companiesWithAll = [{ id: "all", name: "All" }, ...(companies ?? [])];

  const [pickedCompanies, setPickedCompanies] = useState<
    Array<ExternalBasicCompanySchema>
  >(selectedCompanies ?? []);

  useEffect(() => {
    if (selectedCompanies?.length) {
      setPickedCompanies(selectedCompanies);
    }
  }, [selectedCompanies]);

  return (
    <Popover
      disableScrollLock
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={!!open}
      onClose={() => {
        setTagsLimit(2);
        onClose?.();
      }}
      {...other}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "flex-start",
          width: isMobile ? "80vw" : "500px",

          flexGrow: { xs: 0, md: 1 },
          flex: "1 0 0",
          p: 2,
          boxShadow:
            "0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            py: 2,
            px: 0,
          }}
          title={<Typography variant="h6">Filter by Company</Typography>}
        />
        <Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
          <Autocomplete
            multiple
            limitTags={tagsLimit}
            id="companies"
            sx={{
              width: "100%",
            }}
            value={pickedCompanies}
            getOptionLabel={(option: ExternalBasicCompanySchema) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id} // Ensures correct comparison for selection
            options={companiesWithAll ?? []} // Data is already an array of `CompanyPublicProfile`
            renderInput={params => (
              <TextField
                {...params}
                required
                id="name"
                name="name"
                label="Company Name"
                inputProps={{
                  ...params.inputProps,
                  "data-hj-allow": true,
                  style: {
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <Box
                      sx={{
                        maxHeight: "100px", // Define the max height for tags container
                        overflowY: "auto", // Enable vertical scroll
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {params.InputProps.startAdornment}
                    </Box>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} key={option.id} value={option}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  {<Typography>{option.name}</Typography>}
                  {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
                </Box>
              </MenuItem>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  size="small"
                  label={option.name}
                  {...getTagProps({ index })}
                  variant="outlined"
                />
              ))
            }
            onBlur={event => {
              event.preventDefault();
              event.stopPropagation();
              setTagsLimit(undefined);
            }}
            onChange={(
              _: SyntheticEvent,
              newValue: ExternalBasicCompanySchema[] | null
            ) => {
              if (newValue?.some(company => company.id === "all")) {
                setPickedCompanies(companies ?? []); // Select all companies
              } else {
                setPickedCompanies(
                  newValue as Array<ExternalBasicCompanySchema>
                );
              }
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setSelectedCompanies(pickedCompanies);
              setTagsLimit(2);
            }}
          >
            Apply
          </Button>
        </Box>
      </Card>
    </Popover>
  );
};
