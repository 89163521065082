import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { User, USER_TYPE } from "@/auth/types";
import API from "@/common/api";
import { CompanyPublicProfile } from "@/company/types";

export const USE_GET_CHATS_URI = "/job-chats";

export interface JobChat {
  id: number;
  jobId: number;
  message: string;
  createdByUserId: number;
  createdByUserType: USER_TYPE;
  createdByCompanyId?: number;
  createdAt: Date;
  updatedAt?: Date;
}

export interface JobChatResponse {
  buyingCompany?: CompanyPublicProfile;
  createdByCompany?: CompanyPublicProfile;
  homeowner?: User;
  createdByUserWithinCompany?: User;
  chats: Array<JobChat>;
}

export function useFetchJobChats(
  jobId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<JobChatResponse> {
  return useQuery({
    queryKey: [`${USE_GET_CHATS_URI}/${jobId}`],
    queryFn: async () => {
      const response = await API.get(`${USE_GET_CHATS_URI}/${jobId}`);
      return response.data.data;
    },
    ...options,
  });
}
