import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { GridColDef } from "@mui/x-data-grid-premium";
import changeCaseKeys from "change-case-keys";
import { DateTime } from "luxon";
import numeral from "numeral";
import { useEffect, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { usePopover } from "@/common/hooks/use-popover";
import { BasicCompanySchema } from "@/company/api/useGellAllCompanies";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { CompaniesJobPromotionsList } from "@/company/job-promotions/components/CompaniesJobPromotionsList";
import {
  COMPANY_ACCOUNT_TYPES,
  ExternalBasicCompanySchema,
} from "@/company/types";
import { formatDate } from "@/internal-admin/utils";
import {
  getGridDateOperatorsDataGrid,
  getGridNumericOperatorsDataGrid,
} from "@/utils";

import { useGetHubJobCharts } from "../api/useGetHubJobCharts";
import { useGetHubJobsTable } from "../api/useGetHubJobsTable";
import { HubSchema, useGetHubs } from "../api/useGetHubs";
import { useGetHubStatistics } from "../api/useGetHubStatistics";
// import { AnalyticsBarChart } from "../components/AnalyticsBarChart";
import AnalyticsBarChart from "../components/AnalyticsBarChartReCharts";
import { AnalyticsJobSubmittedBarChart } from "../components/AnalyticsJobSubmittedBarChart";
import { AnalyticsSources } from "../components/AnalyticsSources";
import { AnalyticsStats } from "../components/AnalyticsStats";
import { CompanySelectionPopover } from "../components/CompanySelectionPopover";
import { DateSelectionPopover } from "../components/DateSelectionPopover";
import { GroupSelectionPopover } from "../components/GroupSelectionPopover";
import HubCompanySummaryTable from "../components/HubCompanySummaryTable";
import HubJobsTable from "../components/HubJobsTable";
import { formatNumber } from "./utils";

export interface Group {
  name: string;
  id: number;
}

const AnalyticsHubPageDemo = () => {
  const { data: hubs, isLoading: isLoadingHubs } = useGetHubs({
    cacheTime: 0, // Do not keep cache
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const [allCompanies, setAllCompanies] = useState<Array<BasicCompanySchema>>(
    []
  );

  const { session } = useAuth();
  const [selectedCompanies, setSelectedCompanies] = useState<
    Array<ExternalBasicCompanySchema>
  >([]);

  const [startDate, setStartDate] = useState<DateTime | null>(null);

  const [endDate, setEndDate] = useState<DateTime | null>(null);
  const [selectedGroups, setSelectedGroups] = useState<Array<HubSchema>>([]);

  const { data: hubStatistics, isFetching: isLoadingHubStatistics } =
    useGetHubStatistics(
      {
        createdByCompanyIds: selectedCompanies?.map(company => company.id),
        fromDate: startDate,
        toDate: endDate,
      },
      {
        enabled: !!allCompanies.length,
        cacheTime: 0, // Do not keep cache
        staleTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const { data: jobCharts, isFetching: isLoadingJobCharts } =
    useGetHubJobCharts(
      {
        createdByCompanyIds: selectedCompanies?.map(company => company.id),
        fromDate: startDate,
        toDate: endDate,
      },
      {
        enabled: !!allCompanies.length,
        cacheTime: 0, // Do not keep cache
        staleTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const { data: jobsTable, isFetching: isLoadingHubJobsTable } =
    useGetHubJobsTable(
      {
        createdByCompanyIds: selectedCompanies?.map(company => company.id),
        fromDate: startDate,
        toDate: endDate,
      },
      {
        enabled: !!allCompanies.length,
        cacheTime: 0, // Do not keep cache
        staleTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  let summaries = [
    {
      id: 5973,
      company_name: "Critter Control of Nashville",
      company_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Critter+Control_Logo.png",
      brand_name: "Critter Control",
      brand_id: 316,
      brand_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Critter+Control_Logo.png",
      date_marked_on_platform: "2024-11-08T09:50:14.479620",
      total_users_invited: 0,
      total_users_onboarded: 0,
      total_pending_invitations_received: 0,
      total_declined_invitations_received: 0,
      total_accepted_invitations_received: 0,
      total_referral_invitations_sent: 4432,
      total_referral_invitations_received: 4432,
      total_pending_invitations_sent: 0,
      total_accepted_invitations_sent: 0,
      total_declined_invitations_sent: 0,
      referral_invitation_conversion_rate: "0.00",
      total_jobs_sent: 4432,
      total_jobs_received: 4432,
      total_jobs_approved: 0,
      total_jobs_declined: 0,
      job_approval_rate: "93",
      total_earnings: "93",
      total_technician_earnings: "0",
      average_technician_earnings_per_job: "0",
      total_jobs_sold: 0,
      total_jobs_not_sold: 0,
      total_jobs_pending: 0,
      total_jobs_sent_conversion_rate: "93",
      total_active_referral_partners: 0,
      fees_paid_to_technicians: "58104.0086486373",
      date_last_job_submitted: "06/11/2024",
      date_last_job_received: "06/11/2024",
      team_member_referring_most_jobs: "James Smith",
    },
    {
      id: 6295,
      company_name: "Orkin of Bowling Green (Branch #987)",
      company_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Orkin_Logo.png",
      brand_name: "Orkin",
      brand_id: 294,
      brand_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Orkin_Logo.png",
      date_marked_on_platform: null,
      total_users_invited: 0,
      total_users_onboarded: 0,
      total_pending_invitations_received: 0,
      total_declined_invitations_received: 0,
      total_accepted_invitations_received: 0,
      total_referral_invitations_sent: 0,
      total_referral_invitations_received: 0,
      total_pending_invitations_sent: 0,
      total_accepted_invitations_sent: 0,
      total_declined_invitations_sent: 0,
      referral_invitation_conversion_rate: "88",
      total_jobs_sent: 1231,
      total_jobs_received: 1231,
      total_jobs_approved: 0,
      total_jobs_declined: 0,
      job_approval_rate: "88",
      total_earnings: "88",
      total_technician_earnings: "0",
      average_technician_earnings_per_job: "0",
      total_jobs_sold: 0,
      total_jobs_not_sold: 0,
      total_jobs_pending: 0,
      total_jobs_sent_conversion_rate: "88",
      total_active_referral_partners: 0,
      fees_paid_to_technicians: "16138.5457234821",
      date_last_job_submitted: "28/10/2024",
      date_last_job_received: "28/10/2024",
      team_member_referring_most_jobs: "Daniel Wilson",
    },
    {
      id: 6455,
      company_name: "Orkin of Madison (Branch #431)",
      company_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Orkin_Logo.png",
      brand_name: "Orkin",
      brand_id: 294,
      brand_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Orkin_Logo.png",
      date_marked_on_platform: null,
      total_users_invited: 0,
      total_users_onboarded: 0,
      total_pending_invitations_received: 0,
      total_declined_invitations_received: 0,
      total_accepted_invitations_received: 0,
      total_referral_invitations_sent: 0,
      total_referral_invitations_received: 0,
      total_pending_invitations_sent: 0,
      total_accepted_invitations_sent: 0,
      total_declined_invitations_sent: 0,
      referral_invitation_conversion_rate: "0.00",
      total_jobs_sent: 3213,
      total_jobs_received: 3213,
      total_jobs_approved: 0,
      total_jobs_declined: 0,
      job_approval_rate: "96",
      total_earnings: "96",
      total_technician_earnings: "0",
      average_technician_earnings_per_job: "0",
      total_jobs_sold: 0,
      total_jobs_not_sold: 0,
      total_jobs_pending: 0,
      total_jobs_sent_conversion_rate: "96",
      total_active_referral_partners: 0,
      fees_paid_to_technicians: "42122.7842482111",
      date_last_job_submitted: "06/11/2024",
      date_last_job_received: "06/11/2024",
      team_member_referring_most_jobs: "Christopher Moore",
    },
    {
      id: 6459,
      company_name: "Orkin of Nashville - Commercial (Branch #427)",
      company_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Orkin_Logo.png",
      brand_name: "Orkin",
      brand_id: 294,
      brand_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Orkin_Logo.png",
      date_marked_on_platform: null,
      total_users_invited: 0,
      total_users_onboarded: 0,
      total_pending_invitations_received: 0,
      total_declined_invitations_received: 0,
      total_accepted_invitations_received: 0,
      total_referral_invitations_sent: 0,
      total_referral_invitations_received: 0,
      total_pending_invitations_sent: 0,
      total_accepted_invitations_sent: 0,
      total_declined_invitations_sent: 0,
      referral_invitation_conversion_rate: "0.00",
      total_jobs_sent: 8439,
      total_jobs_received: 8439,
      total_jobs_approved: 0,
      total_jobs_declined: 0,
      job_approval_rate: "91",
      total_earnings: "91",
      total_technician_earnings: "0",
      average_technician_earnings_per_job: "0",
      total_jobs_sold: 0,
      total_jobs_not_sold: 0,
      total_jobs_pending: 0,
      total_jobs_sent_conversion_rate: "91",
      total_active_referral_partners: 0,
      fees_paid_to_technicians: "110636.220439046",
      date_last_job_submitted: "04/11/2024",
      date_last_job_received: "04/11/2024",
      team_member_referring_most_jobs: "William Anderson",
    },
    {
      id: 6460,
      company_name: "Orkin of Nashville (Branch #436)",
      company_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Orkin_Logo.png",
      brand_name: "Orkin",
      brand_id: 294,
      brand_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Orkin_Logo.png",
      date_marked_on_platform: "2024-11-08T09:54:21.343082",
      total_users_invited: 1,
      total_users_onboarded: 1,
      total_pending_invitations_received: 0,
      total_declined_invitations_received: 0,
      total_accepted_invitations_received: 0,
      total_referral_invitations_sent: 0,
      total_referral_invitations_received: 0,
      total_pending_invitations_sent: 0,
      total_accepted_invitations_sent: 0,
      total_declined_invitations_sent: 0,
      referral_invitation_conversion_rate: "0.00",
      total_jobs_sent: 3259,
      total_jobs_received: 3259,
      total_jobs_approved: 0,
      total_jobs_declined: 0,
      job_approval_rate: "91",
      total_earnings: "91",
      total_technician_earnings: "0",
      average_technician_earnings_per_job: "0",
      total_jobs_sold: 0,
      total_jobs_not_sold: 0,
      total_jobs_pending: 0,
      total_jobs_sent_conversion_rate: "91",
      total_active_referral_partners: 0,
      fees_paid_to_technicians: "42725.8493199253",
      date_last_job_submitted: "08/11/2024",
      date_last_job_received: "08/11/2024",
      team_member_referring_most_jobs: "David Taylor",
    },
    {
      id: 6542,
      company_name: "OPC Pest Services of Bowling Green",
      company_logo_url:
        "https://tradeengage-videos.s3.amazonaws.com/companies/5708/logo.png",
      brand_name: "OPC Pest Services",
      brand_id: 5708,
      brand_logo_url:
        "https://tradeengage-videos.s3.amazonaws.com/companies/5708/logo.png",
      date_marked_on_platform: null,
      total_users_invited: 0,
      total_users_onboarded: 0,
      total_pending_invitations_received: 0,
      total_declined_invitations_received: 0,
      total_accepted_invitations_received: 0,
      total_referral_invitations_sent: 0,
      total_referral_invitations_received: 0,
      total_pending_invitations_sent: 0,
      total_accepted_invitations_sent: 0,
      total_declined_invitations_sent: 0,
      referral_invitation_conversion_rate: "0.00",
      total_jobs_sent: 2222,
      total_jobs_received: 2222,
      total_jobs_approved: 0,
      total_jobs_declined: 0,
      job_approval_rate: "78",
      total_earnings: "78",
      total_technician_earnings: "0",
      average_technician_earnings_per_job: "0",
      total_jobs_sold: 0,
      total_jobs_not_sold: 0,
      total_jobs_pending: 0,
      total_jobs_sent_conversion_rate: "78",
      total_active_referral_partners: 0,
      fees_paid_to_technicians: "29130.6649858466",
      date_last_job_submitted: "04/11/2024",
      date_last_job_received: "04/11/2024",
      team_member_referring_most_jobs: "Michael Brown",
    },
    {
      id: 6458,
      company_name: "Orkin of Murfreesboro (Branch #434)",
      company_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Orkin_Logo.png",
      brand_name: "Orkin",
      brand_id: 294,
      brand_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Orkin_Logo.png",
      date_marked_on_platform: null,
      total_users_invited: 0,
      total_users_onboarded: 0,
      total_pending_invitations_received: 0,
      total_declined_invitations_received: 0,
      total_accepted_invitations_received: 0,
      total_referral_invitations_sent: 0,
      total_referral_invitations_received: 0,
      total_pending_invitations_sent: 0,
      total_accepted_invitations_sent: 0,
      total_declined_invitations_sent: 0,
      referral_invitation_conversion_rate: "0.00",
      total_jobs_sent: 9853,
      total_jobs_received: 9853,
      total_jobs_approved: 0,
      total_jobs_declined: 0,
      job_approval_rate: "93",
      total_earnings: "93",
      total_technician_earnings: "0",
      average_technician_earnings_per_job: "0",
      total_jobs_sold: 0,
      total_jobs_not_sold: 0,
      total_jobs_pending: 0,
      total_jobs_sent_conversion_rate: "93",
      total_active_referral_partners: 0,
      fees_paid_to_technicians: "129173.91633913",
      date_last_job_submitted: "28/10/2024",
      date_last_job_received: "28/10/2024",
      team_member_referring_most_jobs: "John Davis",
    },
    {
      id: 5966,
      company_name: "Critter Control of Columbia",
      company_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Critter+Control_Logo.png",
      brand_name: "Critter Control",
      brand_id: 316,
      brand_logo_url:
        "https://app-tradeengage.s3.amazonaws.com/Brand/Critter+Control_Logo.png",
      date_marked_on_platform: "2024-11-08T09:50:14.479620",
      total_users_invited: 1,
      total_users_onboarded: 1,
      total_pending_invitations_received: 0,
      total_declined_invitations_received: 0,
      total_accepted_invitations_received: 0,
      total_referral_invitations_sent: 3231,
      total_referral_invitations_received: 3231,
      total_pending_invitations_sent: 0,
      total_accepted_invitations_sent: 0,
      total_declined_invitations_sent: 0,
      referral_invitation_conversion_rate: "89",
      total_jobs_sent: 3231,
      total_jobs_received: 3231,
      total_jobs_approved: 0,
      total_jobs_declined: 0,
      job_approval_rate: "89",
      total_earnings: "89",
      total_technician_earnings: "0",
      average_technician_earnings_per_job: "0",
      total_jobs_sold: 0,
      total_jobs_not_sold: 0,
      total_jobs_pending: 0,
      total_jobs_sent_conversion_rate: "89",
      total_active_referral_partners: 0,
      fees_paid_to_technicians: "42358.7662327949",
      date_last_job_submitted: "08/11/2024",
      date_last_job_received: "08/11/2024",
      team_member_referring_most_jobs: "Alex Johnson",
    },
    {
      id: 8130,
      company_name: "Northwest Exterminating of Nashville",
      company_logo_url:
        "https://tradeengage-videos.s3.amazonaws.com/companies/8130/logos/51ba6975-0aff-4388-8c22-9068777e59ed.png",
      brand_name: "Northwest Exterminating",
      brand_id: 3623,
      brand_logo_url:
        "https://tradeengage-app.s3.amazonaws.com/Brand/Northwest_Logo.png",
      date_marked_on_platform: "2024-11-07T10:30:01",
      total_users_invited: 4,
      total_users_onboarded: 4,
      total_pending_invitations_received: 2,
      total_declined_invitations_received: 0,
      total_accepted_invitations_received: 0,
      total_referral_invitations_sent: 3,
      total_referral_invitations_received: 2,
      total_pending_invitations_sent: 3,
      total_accepted_invitations_sent: 0,
      total_declined_invitations_sent: 0,
      referral_invitation_conversion_rate: "0.00",
      total_jobs_sent: 2132,
      total_jobs_received: 2132,
      total_jobs_approved: 2,
      total_jobs_declined: 0,
      job_approval_rate: "78",
      total_earnings: "78",
      total_technician_earnings: "0",
      average_technician_earnings_per_job: "0",
      total_jobs_sold: 0,
      total_jobs_not_sold: 3,
      total_jobs_pending: 2,
      total_jobs_sent_conversion_rate: "78",
      total_active_referral_partners: 12,
      fees_paid_to_technicians: "27950.7550629275",
      date_last_job_submitted: "08/11/2024",
      date_last_job_received: "08/11/2024",
      team_member_referring_most_jobs: "Thomas Martinez",
    },
    {
      id: 8196,
      company_name: "Missquito Nashville - Coming Soon!",
      company_logo_url:
        "https://tradeengage-app.s3.amazonaws.com/Brand/Missquito_Logo.png",
      brand_name: "Missquito",
      brand_id: 3293,
      brand_logo_url:
        "https://tradeengage-app.s3.amazonaws.com/Brand/Missquito_Logo.png",
      date_marked_on_platform: "2024-11-08T10:05:24.124638",
      total_users_invited: 0,
      total_users_onboarded: 0,
      total_pending_invitations_received: 0,
      total_declined_invitations_received: 0,
      total_accepted_invitations_received: 0,
      total_referral_invitations_sent: 0,
      total_referral_invitations_received: 0,
      total_pending_invitations_sent: 0,
      total_accepted_invitations_sent: 0,
      total_declined_invitations_sent: 0,
      referral_invitation_conversion_rate: "0",
      total_jobs_sent: 0,
      total_jobs_received: 0,
      total_jobs_approved: 0,
      total_jobs_declined: 0,
      job_approval_rate: "0",
      total_earnings: "0",
      total_technician_earnings: "0",
      average_technician_earnings_per_job: "0",
      total_jobs_sold: 0,
      total_jobs_not_sold: 0,
      total_jobs_pending: 0,
      total_jobs_sent_conversion_rate: "0",
      total_active_referral_partners: 0,
      fees_paid_to_technicians: "0.00",
      date_last_job_submitted: null,
      date_last_job_received: null,
      team_member_referring_most_jobs: "Robert Gonzalez",
    },
  ];
  summaries = changeCaseKeys(summaries, "camelize");
  let companySummaries = summaries?.length ? [...summaries] : [];

  companySummaries = companySummaries?.sort((a, b) => {
    if (a.companyName < b.companyName) return -1;
    if (a.companyName > b.companyName) return 1;
    return 0;
  });

  const popover = usePopover<HTMLButtonElement>();
  const groupPopover = usePopover<HTMLButtonElement>();
  const datePopover = usePopover<HTMLButtonElement>();

  useEffect(() => {
    if (selectedGroups?.length) {
      const companies = [];
      const companyIdSet = new Set<number>();
      for (const hub of selectedGroups) {
        if (hub.companies) {
          for (const hubCompany of hub.companies) {
            if (!companyIdSet.has(hubCompany.id)) {
              companyIdSet.add(hubCompany.id);
              companies.push(hubCompany);
            }
          }
        }
      }
      setAllCompanies(companies);
      setSelectedCompanies(companies);
    }
  }, [selectedGroups]);

  useEffect(() => {
    if (hubs?.length) {
      setSelectedGroups(hubs);
    }
  }, [hubs]);

  const companyUserSummaryColumns: GridColDef[] = [
    {
      field: "company",
      headerName: "Company",
      width: 250,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1}
          >
            <CompanyAvatar
              logo={params.row?.companyLogoUrl || undefined}
              name={params.row?.companyName}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
            <Typography variant="body2">{params.row?.companyName}</Typography>
          </Box>
        );
      },
      valueGetter: (value, row) => row?.companyName,
      sortable: true,
    },
    {
      field: "dateMarkedOnPlatform",
      headerName: "Date Marked On Platform",
      description: "Date on which the user was added to the platform.",
      type: "date",
      width: 200,
      valueFormatter: formatDate,
      filterOperators: getGridDateOperatorsDataGrid,
    },
    {
      field: "totalUsersInvited",
      headerName: "Total Users Invited",
      description:
        "Total number of users that are currently invited, and haven't onboarded yet",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
    },
    {
      field: "totalUsersOnboarded",
      headerName: "Total Users Onboarded",
      description: "Total number of users that are Onboarded",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
    },
  ];
  const companyPartnerSummaryColumns: GridColDef[] = [
    {
      field: "company",
      headerName: "Company",
      width: 400,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1}
          >
            <CompanyAvatar
              logo={params.row?.companyLogoUrl || undefined}
              name={params.row?.companyName}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
            <Typography variant="body2">{params.row?.companyName}</Typography>
          </Box>
        );
      },
      valueGetter: (value, row) => row?.companyName,
      sortable: true,
    },
    {
      field: "totalActiveReferralPartners",
      headerName: "Total Active Referral Partners",
      description: "Total number of active referral partners of the company.",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 300,
    },
    {
      field: "totalReferralInvitationsSent",
      headerName: "Total Referral Invitations Sent",
      description: "Total number of referral invitations sent by the company.",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 300,
    },
    {
      field: "totalPendingInvitationsSent",
      headerName: "Total Pending Invitations Sent",
      description:
        "Total number of referral invitations sent by the company which are pending action from the receiving company.",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 300,
    },
    {
      field: "totalAcceptedInvitationsSent",
      headerName: "Total Accepted Invitations Sent",
      description:
        "Total number of accepted referral invitations sent by the company.",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 300,
    },
    {
      field: "totalDeclinedInvitationsSent",
      headerName: "Total Declined Invitations Sent",
      description:
        "Total number of referral invitations declined by the receiving company.",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 300,
    },
    {
      field: "referralInvitationConversionRate",
      headerName: "Referral Invitation Conversion Rate",
      description:
        "Rate of invitations accepted out of the total invitations sent by the company.",
      type: "number",
      valueFormatter: value => value && `${value?.toString()}%`,
      renderCell: params =>
        params.row.referralInvitationConversionRate
          ? `${params.row.referralInvitationConversionRate?.toString()}%`
          : null,
      filterOperators: getGridNumericOperatorsDataGrid,
      headerAlign: "left",
      width: 300,
    },
    {
      field: "totalReferralInvitationsReceived",
      headerName: "Total Referral Invitations Received",
      description:
        "Total number of referral invitations received by the company.",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 300,
    },
    {
      field: "totalAcceptedInvitationsReceived",
      headerName: "Total Accepted Invitations Received",
      type: "number",
      description: "Total number of invitations accepted by the company.",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 300,
    },
    {
      field: "totalPendingInvitationsReceived",
      headerName: "Total Pending Invitations Received",
      description:
        "Total number of invitations received by the company which are still pending.",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 300,
    },
    {
      field: "totalDeclinedInvitationsReceived",
      headerName: "Total Declined Invitations Received",
      type: "number",
      description: "Total number of invitations declined by the company.",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 300,
    },
  ];
  const companyJobSummaryColumns: GridColDef[] = [
    {
      field: "company",
      headerName: "Company",
      width: 400,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1}
          >
            <CompanyAvatar
              logo={params.row?.companyLogoUrl || undefined}
              name={params.row?.companyName}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
            <Typography variant="body2">{params.row?.companyName}</Typography>
          </Box>
        );
      },
      valueGetter: (value, row) => row?.companyName,
      sortable: true,
    },
    {
      field: "totalJobsSent",
      headerName: "Referrals Sent",
      // description:
      //   "Total number of jobs that have been referred by the company.",
      type: "number",
      headerAlign: "left",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
    },
    // {
    //   field: "totalJobsSold",
    //   headerName: "Total Referred Jobs That Converted",
    //   description:
    //     "Total Number of Jobs Referred by the Company That Were Purchased by Another Company.",
    //   type: "number",
    //   filterOperators: getGridNumericOperatorsDataGrid,
    //   width: 200,
    // },
    // {
    //   field: "totalJobsNotSold",
    //   headerName: "Total Referred Jobs That Did Not Convert",
    //   description:
    //     "Total Number of Jobs Referred by the Company That Were Not Purchased by Another Company",
    //   type: "number",
    //   filterOperators: getGridNumericOperatorsDataGrid,
    //   width: 200,
    // },
    {
      field: "totalJobsSentConversionRate",
      headerName: "Conversion Rate of Referrals Sent",
      description:
        "This is the percentage of referrals sent that were approved by referral partners.",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 320,
      valueFormatter: value => value && `${value?.toString()}%`,
      renderCell: params =>
        params.row.totalJobsSentConversionRate
          ? `${params.row.totalJobsSentConversionRate?.toString()}%`
          : null,
      headerAlign: "left",
    },
    {
      field: "dateLastJobSubmitted",
      headerName: "Last Referral Sent",
      description: "Date of the Last Referred Job Submitted.",
      width: 200,
      //   valueFormatter: formatDate,
    },

    {
      field: "totalJobsReceived",
      headerName: "Referrals Received",
      // description: "Total number of referrals received by the company.",
      type: "number",
      headerAlign: "left",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
    },
    // {
    //   field: "totalJobsApproved",
    //   headerName: "Total Referrals Received Approved",
    //   description: "Total number of referrals approved by the company.",
    //   type: "number",
    //   headerAlign: "left",
    //   filterOperators: getGridNumericOperatorsDataGrid,
    //   width: 300,
    // },
    // {
    //   field: "totalJobsDeclined",
    //   headerName: "Total Referrals Received Declined",
    //   description: "Total number of referrals declined by the company.",
    //   type: "number",
    //   filterOperators: getGridNumericOperatorsDataGrid,
    //   width: 300,
    //   headerAlign: "left",
    // },
    // {
    //   field: "totalJobsPending",
    //   headerName: "Total Referrals Received Pending",
    //   description:
    //     "Total number of referrals that haven't been accepted or declined yet.",
    //   type: "number",
    //   filterOperators: getGridNumericOperatorsDataGrid,
    //   width: 300,
    //   headerAlign: "left",
    // },
    {
      field: "jobApprovalRate",
      headerName: "Conversion Rate of Referrals Received",
      description:
        "This is the percentage of referrals received that were approved.",
      type: "number",
      valueFormatter: value => value && `${value?.toString()}%`,
      renderCell: params =>
        params.row.jobApprovalRate
          ? `${params.row.jobApprovalRate?.toString()}%`
          : null,
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 300,
      headerAlign: "left",
    },
    {
      field: "dateLastJobReceived",
      headerName: "Last Referral Received",
      // description: "Date of the last Referral Received.",
      width: 300,
      headerAlign: "left",
      //   valueFormatter: formatDate,
    },
    {
      field: "totalEarnings",
      headerName: "Referral Fees Earned",
      // description: "Total earnings of the company from jobs referred.",
      width: 200,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueFormatter: value =>
        value && `${numeral(value?.toString()).format("$0,0.00")}`,
      renderCell: params =>
        params.row.totalEarnings &&
        `${numeral(params.row.totalEarnings?.toString()).format("$0,0.00")}`,
      headerAlign: "left",
    },
    {
      field: "feesPaidToTechnicians",
      headerName: "Referral Fees Paid",
      // description:
      //   "Amount of fees the company has paid to technicians and CSRs for successfully referring jobs to this company.",
      width: 250,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueFormatter: value =>
        value && `${numeral(value?.toString()).format("$0,0.00")}`,
      renderCell: params =>
        params.row.feesPaidToTechnicians &&
        `${numeral(params.row.feesPaidToTechnicians?.toString()).format(
          "$0,0.00"
        )}`,
      headerAlign: "left",
    },
    // {
    //   field: "totalTechnicianEarnings",
    //   headerName: "Total Technician & CSR Earnings",
    //   description:
    //     "Total earnings of the technicians and CSRs of this company for succesfully referring jobs.",
    //   width: 200,
    //   type: "number",
    //   filterOperators: getGridNumericOperatorsDataGrid,
    //   valueFormatter: value =>
    //     value && `${numeral(value?.toString()).format("$0,0.00")}`,
    //   renderCell: params =>
    //     params.row.totalTechnicianEarnings &&
    //     `${numeral(params.row.totalTechnicianEarnings?.toString()).format(
    //       "$0,0.00"
    //     )}`,
    //   headerAlign: "left",
    // },
    // {
    //   field: "averageTechnicianEarningsPerJob",
    //   headerName: "Average Technician & CSR Earnings Per Job",
    //   description:
    //     "Average earnings of the technicians and CSRs of the company per job, calculated by dividing Total Technician & CSR Earnings by Total Referred Jobs That Converted",
    //   type: "number",
    //   width: 300,
    //   filterOperators: getGridNumericOperatorsDataGrid,
    //   valueFormatter: value =>
    //     value && `${numeral(value?.toString()).format("$0,0.00")}`,
    //   renderCell: params =>
    //     params.row.averageTechnicianEarningsPerJob &&
    //     `${numeral(
    //       params.row.averageTechnicianEarningsPerJob?.toString()
    //     ).format("$0,0.00")}`,
    //   headerAlign: "left",
    // },
    {
      field: "teamMemberReferringMostJobs",
      headerName: "Most Active User",
      description: "Team member who referred the most jobs.",
      width: 250,
    },
  ];

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const jobsPerDayChartSeries = [
    {
      name: "Jobs",
      data: [
        368, 424, 326, 394, 444, 438, 358, 300, 378, 442, 432, 426, 448, 340,
        408, 418, 384, 336, 440, 430, 350, 306, 342, 314, 376, 392, 450, 406,
        428, 428, 392, 456,
      ],
    },
  ];

  const jobsPerDayCategories = [
    "10/08/24",
    "10/09/24",
    "10/10/24",
    "10/11/24",
    "10/12/24",
    "10/13/24",
    "10/14/24",
    "10/15/24",
    "10/16/24",
    "10/17/24",
    "10/18/24",
    "10/19/24",
    "10/20/24",
    "10/21/24",
    "10/22/24",
    "10/23/24",
    "10/24/24",
    "10/25/24",
    "10/26/24",
    "10/27/24",
    "10/28/24",
    "10/29/24",
    "10/30/24",
    "10/31/24",
    "11/01/24",
    "11/02/24",
    "11/03/24",
    "11/04/24",
    "11/05/24",
    "11/06/24",
    "11/07/24",
    "11/08/24",
  ];

  const jobsCummulativePerDayChartSeries = [
    {
      name: "Cumulative Jobs",
      data: [
        368, 792, 1118, 1512, 1956, 2394, 2752, 3052, 3430, 3872, 4304, 4730,
        5178, 5518, 5926, 6344, 6728, 7064, 7504, 7934, 8284, 8590, 8932, 9246,
        9622, 10014, 10464, 10870, 11298, 11726, 12118, 12574,
      ],
    },
  ];

  const totalJobsReceivedData = companySummaries?.map(
    summary => summary.totalJobsReceived
  );
  const totalJobsSentData = companySummaries?.map(
    summary => summary.totalJobsSent
  );
  const jobBarChartSeries = [
    {
      name: "Referrals Received",
      data: [111, 783, 1482, 990, 360, 3734, 2276, 841, 666, 1329],
    },
    {
      name: "Jobs Referred",
      data: [783, 360, 783, 1482, 3734, 2276, 841, 666, 1329, 990],
    },
  ];
  const companies = companySummaries?.map(summary => {
    return {
      name: summary.companyName,
      logoUrl: summary.companyLogoUrl,
      id: summary.id,
      brandId: summary.brandId,
      brandName: summary.brandName,
      brandLogoUrl: summary.brandLogoUrl,
    };
  });
  const jobBarChartCategories = companies;

  const jobsCummulativePerDayyCategories = [
    "10/08/24",
    "10/09/24",
    "10/10/24",
    "10/11/24",
    "10/12/24",
    "10/13/24",
    "10/14/24",
    "10/15/24",
    "10/16/24",
    "10/17/24",
    "10/18/24",
    "10/19/24",
    "10/20/24",
    "10/21/24",
    "10/22/24",
    "10/23/24",
    "10/24/24",
    "10/25/24",
    "10/26/24",
    "10/27/24",
    "10/28/24",
    "10/29/24",
    "10/30/24",
    "10/31/24",
    "11/01/24",
    "11/02/24",
    "11/03/24",
    "11/04/24",
    "11/05/24",
    "11/06/24",
    "11/07/24",
    "11/08/24",
  ];

  const enableTrackingData =
    session?.company?.accountType !== COMPANY_ACCOUNT_TYPES.BRAND_LOCATION &&
    session?.company?.accountType !== COMPANY_ACCOUNT_TYPES.FAMILY_BRANDS;

  return (
    <Container
      disableGutters
      maxWidth="100%"
      sx={{ bgcolor: "white", pl: 3, pr: 2, height: "100%", overflow: "auto" }}
    >
      {!isLoadingHubs && !hubs?.length ? (
        <Box
          sx={{
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            gap={3}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="video thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="body1">
                No {!hubs?.length ? "Hub" : "Company"} Added
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                Looks like you don&apos;t have any{" "}
                {!hubs?.length ? "hub" : "company"} linked to your account yet.
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Grid
          container
          spacing={1}
          bgcolor={"white"}
          sx={{ m: "0 !important" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              pt: 2,
              pb: 2,
              top: "0",
              zIndex: 1000,
              position: "sticky",
              bgcolor: "white",
            }}
          >
            {isMobile ? null : (
              <>
                <Box
                  display="flex"
                  flexWrap={"wrap"}
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="h5">Reporting & Analytics</Typography>{" "}
                </Box>
                {isLoadingHubStatistics || isLoadingHubs ? (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ pt: 1 }}
                  >
                    Fetching Details
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ pt: 1 }}
                  >
                    Discover the impact of joining forces! See insights into
                    company performance, promotions, and referral activity.
                  </Typography>
                )}{" "}
              </>
            )}
            <Grid xs={12} md={12} mt={2}>
              <Box display={"flex"} flexWrap={"wrap"} gap={1} mb={2}>
                <Box>
                  <Button
                    ref={groupPopover.anchorRef}
                    sx={{ px: 1, py: 0.5, borderRadius: "8px" }}
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={
                      <ArrowDropDownCircleOutlinedIcon
                        sx={{ color: "text.secondary" }}
                      />
                    }
                    onClick={() => {
                      groupPopover.open
                        ? groupPopover.handleClose()
                        : groupPopover.handleOpen();
                    }}
                  >
                    <Typography variant="subtitle2" color={"text.secondary"}>
                      Groups:{" "}
                      <Typography
                        variant="subtitle2"
                        display={"inline"}
                        color={"primary"}
                      >
                        {selectedGroups?.length === 1
                          ? `${selectedGroups?.[0].name}`
                          : `${selectedGroups?.length} Selected`}
                      </Typography>
                    </Typography>
                  </Button>
                  <GroupSelectionPopover
                    hubs={hubs}
                    setSelectedGroups={(selectedGroups: Array<Group>) => {
                      setSelectedGroups([...selectedGroups]);
                      groupPopover.handleClose();
                    }}
                    selectedGroups={selectedGroups}
                    anchorEl={groupPopover.anchorRef.current}
                    open={groupPopover.open}
                    onClose={groupPopover.handleClose}
                  />
                </Box>
                <Box>
                  <Button
                    ref={popover.anchorRef}
                    sx={{ px: 1, py: 0.5, borderRadius: "8px" }}
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={
                      <ArrowDropDownCircleOutlinedIcon
                        sx={{ color: "text.secondary" }}
                      />
                    }
                    onClick={() => {
                      popover.open
                        ? popover.handleClose()
                        : popover.handleOpen();
                    }}
                  >
                    <Typography variant="subtitle2" color={"text.secondary"}>
                      Companies:{" "}
                      <Typography
                        variant="subtitle2"
                        display={"inline"}
                        color={"primary"}
                      >
                        {selectedCompanies?.length === 1
                          ? `${selectedCompanies?.[0].name}`
                          : `${selectedCompanies?.length} Selected`}
                      </Typography>
                    </Typography>
                  </Button>
                  <CompanySelectionPopover
                    companies={
                      allCompanies as unknown as Array<ExternalBasicCompanySchema>
                    }
                    setSelectedCompanies={(
                      selectedCompanies: Array<ExternalBasicCompanySchema>
                    ) => {
                      setSelectedCompanies([...selectedCompanies]);
                      popover.handleClose();
                    }}
                    selectedCompanies={selectedCompanies}
                    anchorEl={popover.anchorRef.current}
                    open={popover.open}
                    onClose={popover.handleClose}
                  />
                </Box>
                <Box>
                  <Button
                    ref={datePopover.anchorRef}
                    sx={{ px: 1, py: 0.5, borderRadius: "8px" }}
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={
                      <ArrowDropDownCircleOutlinedIcon
                        sx={{ color: "text.secondary" }}
                      />
                    }
                    {...(!startDate || !endDate
                      ? {}
                      : {
                          endIcon: (
                            <DoNotDisturbOnOutlinedIcon
                              sx={{
                                color: "text.secondary",
                                cursor: "pointer",
                              }}
                              onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                setStartDate(null);
                                setEndDate(null);
                              }}
                            />
                          ),
                        })}
                    onClick={() => {
                      datePopover.open
                        ? datePopover.handleClose()
                        : datePopover.handleOpen();
                    }}
                  >
                    {!startDate || !endDate ? (
                      <Typography variant="subtitle2" color={"text.secondary"}>
                        Add Date Range
                      </Typography>
                    ) : (
                      <Typography variant="subtitle2" color={"text.secondary"}>
                        Date Range:{" "}
                        <Typography
                          variant="subtitle2"
                          display={"inline"}
                          color={"primary"}
                        >
                          {startDate.toFormat("MM/dd/yy").toString()} -{" "}
                          {endDate.toFormat("MM/dd/yy").toString()}
                        </Typography>
                      </Typography>
                    )}
                  </Button>
                  <DateSelectionPopover
                    dateRange={{ startDate, endDate }}
                    setDateRange={({
                      startDate,
                      endDate,
                    }: {
                      startDate: DateTime;
                      endDate: DateTime;
                    }) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                      datePopover.handleClose();
                    }}
                    anchorEl={datePopover.anchorRef.current}
                    open={datePopover.open}
                    keepMounted={false}
                    onClose={datePopover.handleClose}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          {
            <Container maxWidth={"xl"} sx={{ mt: 0 }}>
              <Grid
                container
                spacing={{
                  xs: 3,
                  lg: 4,
                }}
                sx={{ my: "0 !important" }}
              >
                {isLoadingHubStatistics ||
                isLoadingHubs ||
                isLoadingHubJobsTable ? (
                  <Box
                    my={5}
                    textAlign="center"
                    width={"100%"}
                    justifyContent={"center"}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Grid xs={12} md={4}>
                      <AnalyticsStats
                        action={
                          <Typography color="text.secondary" variant="body2">
                            Info Text
                          </Typography>
                        }
                        title="Total Revenue from Referrals"
                        value={`$${formatNumber(18457093)}`}
                        toolTip="Without integrations, figures from all companies are reliant on self-reporting and could be inaccurate."
                        disabled={false}
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <AnalyticsStats
                        action={
                          <Typography color="text.secondary" variant="body2">
                            Info Text
                          </Typography>
                        }
                        title="Cost Per Acquisition (CPA)"
                        value={`2.7%`}
                        toolTip="Without integrations, figures from all companies are reliant on self-reporting and could be inaccurate."
                        disabled={false}
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <AnalyticsStats
                        action={
                          <Typography color="text.secondary" variant="body2">
                            Info Text
                          </Typography>
                        }
                        title="Inbound Referral Conversion"
                        value={`${formatNumber(91)}%`}
                        toolTip="Without integrations, figures from all companies are reliant on self-reporting and could be inaccurate."
                        disabled={false}
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <AnalyticsStats
                        action={
                          <Typography color="text.secondary" variant="body2">
                            Info Text
                          </Typography>
                        }
                        toolTip="Time savings for dispatchers, CSRs & 
                managers by eliminating patch-workflows such as text messaging, 
                spreadsheet tracking/calculations, check/cash delivery, and 
                homeowner coordination. This conservatively assumes 45 minute 
                savings per job and $50 per hour labor costs."
                        title="Efficiency Cost Savings"
                        value={`$${formatNumber(204306.38, false)}`}
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <AnalyticsStats
                        action={
                          <Typography color="text.secondary" variant="body2">
                            Info Text
                          </Typography>
                        }
                        title="Number of Referrals Received"
                        value={`${formatNumber(5987)}`}
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <AnalyticsStats
                        action={
                          <Typography color="text.secondary" variant="body2">
                            Info Text
                          </Typography>
                        }
                        toolTip="This is the total amount of money that companies have paid to technicians and CSRs for referrals."
                        title="Fees Paid to Technicians & CSRs"
                        value={`$${formatNumber(498342)}`}
                      />
                    </Grid>
                  </>
                )}
                <Grid xs={12} lg={12}>
                  <AnalyticsJobSubmittedBarChart
                    title={"Jobs Referred Per Day"}
                    tooltip={"Chart of Jobs Referred by companies Per Day "}
                    yLabel="Jobs Count"
                    xLabel="Date"
                    categories={jobsPerDayCategories}
                    chartSeries={jobsPerDayChartSeries}
                  />
                </Grid>
                <Grid xs={12} lg={12}>
                  <AnalyticsSources
                    title={"Cumulative Jobs Referred Per Day"}
                    tooltip={
                      "Chart of Cumulative Jobs Referred by companies Per Day "
                    }
                    yLabel="Jobs Count"
                    xLabel="Date"
                    categories={jobsCummulativePerDayyCategories}
                    chartSeries={jobsCummulativePerDayChartSeries}
                  />
                </Grid>
                <Grid xs={12} lg={12}>
                  <AnalyticsBarChart
                    title={"Job Breakdown by Company"}
                    subtitle={
                      "Job balances and activity will vary by trade sector."
                    }
                    tooltip={
                      "Bar Chart to track jobs sent and received by companies"
                    }
                    yLabel="Jobs Count"
                    xLabel="Companies"
                    categories={jobBarChartCategories}
                    chartSeries={jobBarChartSeries}
                  />
                </Grid>
                {!enableTrackingData ? null : (
                  <Grid xs={12} lg={12}>
                    <HubJobsTable jobs={jobsTable} />
                  </Grid>
                )}
                <>
                  {!enableTrackingData ? null : (
                    <>
                      <Grid xs={12} lg={12}>
                        <HubCompanySummaryTable
                          title={"User Tracking Summary"}
                          companySummaries={companySummaries}
                          columns={companyUserSummaryColumns}
                        />
                      </Grid>
                      <Grid xs={12} lg={12}>
                        <HubCompanySummaryTable
                          title={"Referral Tracking Summary"}
                          companySummaries={companySummaries}
                          columns={companyPartnerSummaryColumns}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid xs={12} lg={12}>
                    <HubCompanySummaryTable
                      title={"Job Summary"}
                      companySummaries={companySummaries}
                      columns={companyJobSummaryColumns}
                    />
                  </Grid>
                </>
                <Grid xs={12} lg={12}>
                  <Paper
                    elevation={1}
                    sx={{
                      maxHeight: "720px",
                      overflowY: "auto",
                      boxShadow: "0px 0px 0px 1px #F2F4F7",
                      borderRadius: "20px",
                      padding: 2,
                    }}
                  >
                    <Stack gap={2}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"start"}
                        padding={2}
                      >
                        <Typography variant="h6">Promotions</Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                          Support each other and leverage each other&apos;s
                          sales force! These are the promotional campaigns
                          offered by companies in this group.
                        </Typography>
                      </Box>
                      <CompaniesJobPromotionsList
                        companyIds={selectedCompanies.map(
                          company => company.id
                        )}
                      />
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          }
        </Grid>
      )}
    </Container>
  );
};

export default AnalyticsHubPageDemo;
