import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";

export interface CreateTableViewDialogProps {
  isOpen: boolean;
  creatingTableView: boolean;
  onCreate: ({
    name,
    description,
  }: {
    name: string;
    description: string;
  }) => void;
  onClose: () => void;
}

export function CreateTableViewDialog(props: CreateTableViewDialogProps) {
  const { onClose, isOpen, onCreate, creatingTableView = false } = props;
  const { session } = useAuth();

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ name, description }) => {
      if (name) {
        onCreate({
          name,
          description,
        });
      }
    },
  });

  return (
    <Dialog
      fullWidth
      sx={{ m: { xs: -3, md: 0 } }}
      open={props.isOpen}
      maxWidth="md"
      onClose={() => props.onClose()}
    >
      <DialogTitle component="div" width="95%" sx={{ px: 2 }}>
        <Typography gutterBottom variant="h5">
          Add Save Search Details
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => props.onClose()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: { xs: 0 }, pt: 2 }}>
        <Box width="100%" pt={0}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems={"center"}
            width={"100%"}
            flexDirection={"column"}
            gap={2}
            px={2}
          >
            <Box display={"flex"} width={"100%"}>
              <TextField
                fullWidth
                required
                id="name"
                name="name"
                label="Name"
                sx={{ my: 0 }}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            <Box display={"flex"} width={"100%"} flexDirection={"column"}>
              <TextField
                multiline
                fullWidth
                id="description"
                name="description"
                label="Description"
                InputProps={{
                  inputProps: {
                    maxLength: 200,
                  },
                }}
                rows={4}
                maxRows={4}
                value={formik.values.description}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : null
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"end"}
                mt={0.5}
              >
                {" "}
                <Typography
                  fontSize={"12px"}
                  color="text.secondary"
                  fontWeight={400}
                  mr={1}
                >
                  {formik.values.description?.length ?? 0}/200 Characters
                </Typography>
              </Box>
            </Box>
          </Box>
          <Stack
            direction="row"
            gap={2}
            mt={2}
            sx={{
              display: "flex",
              alignItems: { xs: "flex-center", lg: "flex-center" },
              justifyContent: { xs: "flex-center", lg: "flex-end" },
              alignSelf: "stretch",
              width: "100%",
            }}
            px={2}
          >
            <LoadingButton
              loading={creatingTableView}
              variant="contained"
              color="primary"
              disabled={
                !!(
                  (!formik.dirty && !Object.keys(formik.touched).length) ||
                  creatingTableView
                )
              }
              onClick={() => formik.handleSubmit()}
            >
              Create Save Search
            </LoadingButton>
            <LoadingButton
              loading={creatingTableView}
              variant="outlined"
              color="primary"
              onClick={() => onClose()}
            >
              Cancel
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
