import * as i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./resources/en.json";
import es from "./resources/es.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    es: { translation: es },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

export const LanguageMaps = {
  ENGLISH: "en",
  SPANISH: "en",
};

export default i18n;
