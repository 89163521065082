import constate from "constate";
import { useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

import { authApi } from "@/auth/api";
import { Session, SignInResponse, SignUpRequest } from "@/auth/types";
import { getAccessToken, Issuer } from "@/auth/utils";
import { identifyUserForAnalytics } from "@/common/analytics";
import { CountryCodes } from "@/common/constants/CountryCodes";
import { removeToken, setToken } from "@/common/toeknUtils";
import { Company } from "@/company/types";
import { LanguageMaps } from "@/i18n";
import { LEAD_DATA_STORAGE_KEY } from "@/lead/context/lead-context";

const AUTH_STORAGE_KEY = "accessToken";
export const MANAGE_PARTNERS_INFO_TOAST_KEY = "MANAGE_PARTNERS_INFO_TOAST_KEY";
export const FIRST_CAMPAIGN_LOGIN_KEY = "FIRST_CAMPAIGN_LOGIN_KEY";

export interface UpdateCompanyDetailsData {
  company: Company;
}
export interface LoginRequestData {
  cellPhone?: string;
  email?: string;
  redirectTo: string | undefined;
}
export interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  session: Session | null;
  loginRequestData: LoginRequestData | null;
}

export const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  session: null,
  loginRequestData: null,
};

enum ActionType {
  INITIALIZE = "INITIALIZE",
  REQUESTED_LOGIN_OTP = "REQUESTED_LOGIN_OTP",
  SIGN_IN = "SIGN_IN",
  SIGN_UP = "SIGN_UP",
  SIGN_OUT = "SIGN_OUT",
  REFRESH_SESSION = "REFRESH_SESSION",
  UPDATE_COMPANY_DETAILS = "UPDATE_COMPANY_DETAILS",
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    session: Session | null;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    session: Session;
  };
};

type SignUpAction = {
  type: ActionType.SIGN_UP;
  payload: {
    session: Session;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type RefreshSessionAction = {
  type: ActionType.REFRESH_SESSION;
  payload: {
    session: Session;
  };
};

type RequestLoginOtpAction = {
  type: ActionType.REQUESTED_LOGIN_OTP;
  payload: LoginRequestData;
};

type UpdateCompanyDetailsAction = {
  type: ActionType.UPDATE_COMPANY_DETAILS;
  payload: UpdateCompanyDetailsData;
};

type Action =
  | InitializeAction
  | SignInAction
  | SignUpAction
  | SignOutAction
  | RequestLoginOtpAction
  | RefreshSessionAction
  | UpdateCompanyDetailsAction;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Handler = (state: State, action: any) => State;

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, session } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      session,
    };
  },
  REQUESTED_LOGIN_OTP: (state: State, action: RequestLoginOtpAction): State => {
    return {
      ...state,
      loginRequestData: action.payload,
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { session } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      session,
    };
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { session } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      session,
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    session: null,
  }),
  REFRESH_SESSION: (state: State, action: RefreshSessionAction): State => {
    const { session } = action.payload;

    return {
      ...state,
      session,
    };
  },
  UPDATE_COMPANY_DETAILS: (
    state: State,
    action: UpdateCompanyDetailsAction
  ): State => {
    const { company } = action.payload;
    return {
      ...state,
      session: {
        ...state.session,
        company,
      },
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

function useAuthDetails() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { i18n } = useTranslation();

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const accessToken = getAccessToken();

      if (accessToken) {
        // TODO: Add logic for refresh token in case of access token expiry
        const session = await authApi.authenticateUser();
        if (session.user?.preferredLanguage) {
          i18n.changeLanguage(LanguageMaps[session.user.preferredLanguage]);
        }

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            session,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            session: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          session: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const requestLoginOtp = useCallback(
    async ({
      cellPhone,
      email,
      redirectTo,
    }: {
      cellPhone?: string;
      email?: string;
      redirectTo?: string;
    }): Promise<void> => {
      await authApi.requestLoginOTP({
        ...(cellPhone ? { cell_phone: CountryCodes.US + cellPhone } : {}),
        email,
      });
      dispatch({
        type: ActionType.REQUESTED_LOGIN_OTP,
        payload: { cellPhone, email, redirectTo },
      });
    },
    [dispatch]
  );

  const signIn = useCallback(
    async ({
      cellPhone,
      email,
      otp,
    }: {
      cellPhone?: string;
      email?: string;
      otp: string;
    }): Promise<Session> => {
      const { access, refresh } = await authApi.signIn({
        ...(cellPhone ? { cellPhone: CountryCodes.US + cellPhone } : {}),
        email,
        otp,
      });
      setToken(JSON.stringify({ access, refresh }));

      const session = await authApi.authenticateUser();
      if (session.user?.id) {
        identifyUserForAnalytics(session.user.id, {
          name: `${session.user.firstName} ${session?.user?.lastName}`,
        });
        if (session.user.preferredLanguage) {
          i18n.changeLanguage(LanguageMaps[session.user.preferredLanguage]);
        }
      }

      dispatch({
        type: ActionType.SIGN_IN,
        payload: { session },
      });
      return session;
    },
    [dispatch]
  );

  const signInToCompany = useCallback(
    async ({ companyId }: { companyId: number }): Promise<Session> => {
      const { access, refresh } = await authApi.signInToCompany(companyId);
      setToken(JSON.stringify({ access, refresh }));

      const session = await authApi.authenticateUser();
      if (session.user?.id) {
        identifyUserForAnalytics(session.user.id, {
          name: `${session.user.firstName} ${session?.user?.lastName}`,
        });
        if (session.user.preferredLanguage) {
          i18n.changeLanguage(LanguageMaps[session.user.preferredLanguage]);
        }
      }

      dispatch({
        type: ActionType.SIGN_IN,
        payload: { session },
      });
      return session;
    },
    [dispatch]
  );

  const updateToken = useCallback(
    async ({ access, refresh }: SignInResponse): Promise<Session> => {
      setToken(JSON.stringify({ access, refresh }));
      const session = await authApi.authenticateUser();
      if (session.user?.id) {
        identifyUserForAnalytics(session.user.id, {
          name: `${session.user.firstName} ${session?.user?.lastName}`,
        });
        if (session.user.preferredLanguage) {
          i18n.changeLanguage(LanguageMaps[session.user.preferredLanguage]);
        }
      }
      dispatch({
        type: ActionType.SIGN_IN,
        payload: { session },
      });
      return session;
    },
    [dispatch]
  );

  const signUp = useCallback(
    async ({
      user,
      brandLocationId,
      redirectTo,
      invitationCode,
      campaignCode,
      jobIdToLink,
      initialJobRoutedId,
      utmParams,
      company,
      publicJobId,
      jobInvitationId,
    }: SignUpRequest): Promise<void> => {
      await authApi.signUp({
        user,
        brandLocationId,
        invitationCode,
        campaignCode,
        jobIdToLink,
        utmParams,
        initialJobRoutedId,
        company,
        publicJobId,
        jobInvitationId,
      });
      dispatch({
        type: ActionType.REQUESTED_LOGIN_OTP,
        payload: {
          cellPhone: user.cellPhone,
          redirectTo,
        },
      });
    },
    []
  );

  const signOut = useCallback(async (): Promise<void> => {
    removeToken();
    localStorage.removeItem(LEAD_DATA_STORAGE_KEY);

    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  const refreshSession = useCallback(async (): Promise<Session> => {
    const session = await authApi.authenticateUser();
    if (session.user?.id) {
      identifyUserForAnalytics(session.user.id, {
        name: `${session.user.firstName} ${session?.user?.lastName}`,
      });
      if (session.user.preferredLanguage) {
        i18n.changeLanguage(LanguageMaps[session.user.preferredLanguage]);
      }
    }
    dispatch({
      type: ActionType.REFRESH_SESSION,
      payload: { session },
    });
    return session;
  }, [dispatch]);

  const updateCompanyDetails = useCallback(
    (newCompany: Company) => {
      dispatch({
        type: ActionType.UPDATE_COMPANY_DETAILS,
        payload: { company: newCompany },
      });
    },
    [dispatch]
  );

  return {
    ...state,
    issuer: Issuer.JWT,
    requestLoginOtp,
    signIn,
    signInToCompany,
    signUp,
    signOut,
    refreshSession,
    updateCompanyDetails,
    updateToken,
  };
}

export const [AuthProvider, useAuth] = constate(useAuthDetails);
