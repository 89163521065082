import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import type { FC } from "react";

import { CompanyCard } from "@/company/types";

const brandIcon: Record<string, string> = {
  Mastercard: "/mastercard.svg",
  Visa: "/visa.svg",
  Diners: "/diners.svg",
  Discover: "/discover.svg",
  Unionpay: "/unionpay.svg",
  Amex: "/amex.jbg",
  Jcb: "/jcb.svg",
};

interface CryptoCardProps {
  card: CompanyCard;
}

export const CryptoCard: FC<CryptoCardProps> = props => {
  const {
    lastFourDigits,
    cardholderName,
    expirationMonth,
    expirationYear,
    brand,
  } = props.card;
  const specificDate = DateTime.fromObject({
    year: expirationYear,
    month: expirationMonth,
  });
  const formattedDate = specificDate.toFormat("MM/yy");

  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        backgroundImage: `url("/credit-card.svg")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        py: 4,
        px: 4,
        borderRadius: "20px",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img src="/contactless.svg" alt="contactless" />
        <Box
          sx={{
            height: 32,
            "& img": {
              height: "100%",
            },
          }}
        >
          {brandIcon[brand] ? (
            <img
              alt=""
              src={brandIcon[brand] ?? "/generic-credit-card-brand.svg"}
            />
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          mt: 6,
          mb: 3,
        }}
      >
        <Typography
          sx={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 100%)",
            backgroundClip: "text",
            fontSize: 24,
            fontWeight: 700,
            letterSpacing: "0.1em",
            lineHeight: "32px",
            textFillColor: "transparent",
          }}
        >
          **** **** **** {lastFourDigits}
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography color="white" variant="body2">
            Cardholder Name
          </Typography>
          <Typography
            color="white"
            sx={{
              fontSize: 16,
              fontWeight: 700,
              lineHeight: "24px",
              mt: 1,
              textShadow: "0px 1px 4px rgba(18, 109, 177, 0.58)",
            }}
          >
            {cardholderName ?? "-"}
          </Typography>
        </div>
        <Box sx={{ ml: 2 }}>
          <Typography color="white" variant="body2">
            Expiration Date
          </Typography>
          <Typography
            color="white"
            sx={{
              fontSize: 16,
              fontWeight: 700,
              lineHeight: "24px",
              mt: 1,
              textShadow: "0px 1px 4px rgba(18, 109, 177, 0.58)",
            }}
          >
            {formattedDate}
          </Typography>
        </Box>
        <Box sx={{ ml: 2 }}>
          <img src="/sim.svg" alt="sim" />
        </Box>
      </Box>
    </Box>
  );
};
