import { Avatar, Card, CardHeader, Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { FC } from "react";

import {
  ChatParticipant,
  ChatTypeParticipant,
} from "@/company/api/useFetchAdminChatsByBrandLocation";

interface ParticipantsPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  participants: Array<ChatParticipant>;
}

export const AdminParticipantsPopover: FC<ParticipantsPopoverProps> = props => {
  const { anchorEl, onClose, open, participants, ...other } = props;

  return (
    <Popover
      disableScrollLock
      anchorEl={anchorEl}
      sx={{
        pointerEvents: "none",
      }}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={!!open}
      onClose={onClose}
      {...other}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "flex-start",
          width: "100%",
          flexGrow: { xs: 0, md: 1 },
          flex: "1 0 0",
          boxShadow:
            "0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            py: 2,
            px: 3,
          }}
          title={
            <Typography variant="h6">
              All Chat Participants ({participants.length})
            </Typography>
          }
        />
        <>
          {participants.map(participant => {
            let avatar = (
              <Avatar
                key={participant.id}
                sx={{
                  width: "32px",
                  height: "32px",
                  border: "1px solid gray",
                  bgcolor: "white",
                  "& img": { objectFit: "contain", bgcolor: "white" },
                }}
                alt={participant.name}
                src={participant.logoUrl}
              />
            );
            if (participant.type === ChatTypeParticipant.HOMEOWNER) {
              const firstInitial = (
                participant.name?.split(" ")?.[0]?.[0] ?? ""
              ).toUpperCase();

              const secondInitial = (
                participant.name?.split(" ")?.[1]?.[0] ?? ""
              ).toUpperCase();
              avatar = (
                <Avatar
                  key={participant.id}
                  sx={{
                    width: "32px",
                    height: "32px",
                    border: "1px solid gray",
                    bgcolor: "white",
                    "& img": { objectFit: "contain" },
                  }}
                >
                  {`${firstInitial}${secondInitial}` ?? ""}
                </Avatar>
              );
            }

            return (
              <Box key={participant.id} sx={{ width: "100%" }}>
                <Divider sx={{ width: "100%" }} />
                <Box
                  display={"flex"}
                  gap={2}
                  py={1}
                  px={3}
                  alignItems={"center"}
                >
                  {avatar}
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography variant="subtitle2">
                      {participant.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {participant.type === ChatTypeParticipant.TEAM_MEMBER
                        ? "Team Member"
                        : participant.type === ChatTypeParticipant.COMPANY
                        ? "Company"
                        : "Homeowner"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </>
      </Card>
    </Popover>
  );
};
