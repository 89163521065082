import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SyntheticEvent, useState } from "react";

import {
  BasicCompanySchema,
  useGetSectorReferrals,
} from "@/company/api/useGetSectorReferrals";
import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import { snakeCaseJobSectors } from "@/company/utils";

const ReRouteJobDialog = ({
  open,
  onClose,
  handleConfirm,
  job,
}: {
  open: boolean;
  job: Job;
  onClose: () => void;
  handleConfirm: (companyId: number) => void;
}) => {
  const [company, setNewCompany] = useState<BasicCompanySchema | null>(null);

  const { data: companies, isLoading } = useGetSectorReferrals(
    job.createdByCompanyId,
    job.sector,
    { refetchOnWindowFocus: false }
  );

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        Re-route a job
        <Typography variant="subtitle1" color="textSecondary">
          Choose a specific partner wihin the{" "}
          {job?.sector
            ? snakeCaseJobSectors[job?.sector as JobSectors]
            : "Other"}{" "}
          industry
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box my={5} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Autocomplete
            options={companies ?? []}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                required
                fullWidth
                value={company?.id}
                margin="normal"
                InputLabelProps={{
                  style: { marginBottom: "8px" },
                }}
                label="Select Company"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} key={option} value={option}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  {<Typography>{option.name}</Typography>}
                  {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
                </Box>
              </MenuItem>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  size="small"
                  label={option.name}
                  {...getTagProps({ index })}
                  variant="outlined"
                />
              ))
            }
            onChange={(
              _: SyntheticEvent,
              newValue: BasicCompanySchema | null
            ) => {
              setNewCompany(newValue);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!company || isLoading}
          variant="contained"
          onClick={() => {
            if (company) {
              handleConfirm(company.id);
            }
          }}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReRouteJobDialog;
