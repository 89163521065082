import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Card, Chip, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import {
  getServiceTypesByJobSector,
  JobSectors,
} from "@/company/jobs/constants";
import { useUpdateCompanySectors } from "@/company/onboarding/api";
import { snakeCaseJobSectors } from "@/company/utils";

import { SettingsTabs } from "../screens/company-settings/CompanySettingsPage";

interface ServiceTypesProps {
  handleTabsChange?: (tab: SettingsTabs) => void;
}

export const ServiceTypes: React.FC<ServiceTypesProps> = ({
  handleTabsChange,
}) => {
  const { session, refreshSession } = useAuth();
  const navigate = useNavigate();

  const [sectorData, setSectorData] = useState<{
    [key in JobSectors]?: Array<string>;
  }>({});

  const companySectors = session?.companySectors;

  const {
    mutateAsync: updateCompanySectors,
    isLoading: updatingCompanySectors,
  } = useUpdateCompanySectors({
    onError() {
      enqueueSnackbar("Error while updating service types of sectors.", {
        variant: "error",
      });
    },
    onSuccess() {
      enqueueSnackbar({
        message: "Your changes have been saved.",
        variant: "success",
      });
      refreshSession();
    },
  });

  const sectors = companySectors?.map(({ sector }) => sector);
  // Object to fetch default list of service types for each sector of company
  const sectorToJobServiceObj: { [key in JobSectors]?: Array<string> } = {};
  sectors?.forEach(
    (sector: JobSectors) =>
      (sectorToJobServiceObj[sector] = getServiceTypesByJobSector(sector))
  );

  /**
   * As we create a new object sectorToJobServiceObj on each render.
   * Hence need to setSectorData inside the effect. Otherwise it leads to infinite looping.
   * The other approach would have been to useRef for the object. Preferred this one as this
   * cleaner.
   */
  useEffect(() => {
    // Object to fetch default list of service types for each sector of company
    const newSectorData: { [key in JobSectors]?: Array<string> } = {};
    companySectors?.forEach(sector => {
      if (sector.updatedAt) {
        newSectorData[sector.sector] = sector.serviceTypes ?? [];
      } else {
        newSectorData[sector.sector] = getServiceTypesByJobSector(
          sector.sector
        );
      }
    });
    setSectorData(newSectorData);
  }, [companySectors]);

  const handleDelete = (sector: JobSectors, chipClicked: string) => {
    let newSectorServices = [...(sectorData[sector] ?? [])];
    const isServiceSelected = newSectorServices.some(
      chip => chip === chipClicked
    );
    if (!isServiceSelected) {
      newSectorServices.push(chipClicked);
    } else {
      newSectorServices = newSectorServices.filter(
        chip => chip !== chipClicked
      );
    }
    setSectorData(sectors => ({ ...sectors, [sector]: newSectorServices }));
  };

  const onSave = async () => {
    const requestPayload: Record<number, Array<string>> = {};
    Object.keys(sectorData).map(sectorName => {
      if (sectorData[sectorName as JobSectors]) {
        const sector = companySectors?.find(
          sector => sector.sector === sectorName
        );
        if (sector && sectorData[sectorName as JobSectors])
          requestPayload[sector.id] = sectorData[
            sectorName as JobSectors
          ] as Array<string>;
      }
    });
    await updateCompanySectors(requestPayload);
  };

  return (
    <Grid container p={3}>
      <Grid
        item
        xs={12}
        display={"flex"}
        gap={1}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ flexDirection: { xs: "column", lg: "row" } }}
        pb={2}
      >
        <Box>
          <Typography gutterBottom variant="h6">
            Are there any services your company doesn&apos;t provide?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You may still receive some jobs like these, but we’ll do our best to
            route them to another partner!
          </Typography>
        </Box>
        {session?.groups?.some(isAdministratorOrOwner) ? (
          <LoadingButton
            loading={updatingCompanySectors}
            variant="outlined"
            onClick={() => {
              handleTabsChange?.(SettingsTabs.COMPANY_PROFILE);
            }}
          >
            Change Service Categories
          </LoadingButton>
        ) : null}
      </Grid>
      <Grid item xs={12} p={1}>
        {Object.keys(sectorToJobServiceObj)?.map(sector => (
          <Card
            key={sector}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              columnGap: "8px",
              width: "100%",
              p: 2,
              mb: 1,
              boxShadow: "0px 0px 0px 1px #F2F4F7",
              "&:active": {
                border: "1px solid #071D2F",
              },
              "&:hover": {
                boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
              },
              "&:focus": {
                border: "1px solid #071D2F",
              },
            }}
          >
            <Box width={"100%"} py={2} px={3}>
              <Typography variant="h6">
                {snakeCaseJobSectors[sector as JobSectors]}
              </Typography>
              {session?.groups?.some(isAdministratorOrOwner) ? (
                <Typography variant="body2" color="text.secondary">
                  Click a job type to remove it from your services list
                </Typography>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                rowGap: "4px",
                columnGap: "4px",
                flexWrap: "wrap",
                alignItems: "center",
                px: 3,
                py: 2,
              }}
            >
              {sectorToJobServiceObj[sector as JobSectors]?.map(data => {
                return (
                  <Chip
                    key={data.trim()}
                    color="primary"
                    label={data.trim()}
                    sx={{
                      ...(sectorData[sector as JobSectors]?.includes(
                        data.trim()
                      )
                        ? { outline: "1px solid #16B364" }
                        : {}),
                    }}
                    variant={
                      sectorData[sector as JobSectors]?.includes(data.trim())
                        ? "filled"
                        : "outlined"
                    }
                    deleteIcon={
                      sectorData[sector as JobSectors]?.includes(
                        data.trim()
                      ) ? (
                        <CheckCircleIcon />
                      ) : (
                        <CircleOutlinedIcon />
                      )
                    }
                    {...(session?.groups?.some(isAdministratorOrOwner)
                      ? {
                          onClick: () =>
                            handleDelete(sector as JobSectors, data),
                          onDelete: () =>
                            handleDelete(sector as JobSectors, data),
                        }
                      : {})}
                  />
                );
              })}
            </Box>
          </Card>
        ))}
        {session?.groups?.some(isAdministratorOrOwner) ? (
          <Stack
            spacing={2}
            m={2}
            direction={{ xs: "column", md: "row" }}
            sx={{
              display: "flex",
              alignItems: { xs: "flex-center", lg: "flex-end" },
              justifyContent: { xs: "center", lg: "flex-end" },
              alignSelf: "stretch",
            }}
          >
            <LoadingButton
              loading={updatingCompanySectors}
              variant="contained"
              onClick={onSave}
            >
              Save
            </LoadingButton>
          </Stack>
        ) : null}
      </Grid>
    </Grid>
  );
};
