import constate from "constate";
import { useState } from "react";

import { CompanyPublicProfile } from "@/company/types";

function useJobCampaigTour() {
  const [campaignTourOpen, setCampaignTourOpen] = useState(false);
  const [campaignProfile, setCampaignProfile] = useState<
    CompanyPublicProfile | undefined
  >();

  return {
    campaignTourOpen,
    setCampaignTourOpen,
    campaignProfile,
    setCampaignProfile,
  };
}

export const [JobCampaignTourProvider, useJobCampaignTourContext] =
  constate(useJobCampaigTour);
