import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { JobSectors } from "../jobs/constants";

export const USE_GET_DEFAULT_SECTORS_PREFERENCE_URI =
  "/companies/default-sectors-preference";

export interface Chat {
  id: number;
  fromCompanyId: number;
  toCompanyId: number;
  message: string;
  createdAt: string;
}

export function useFetchDefaultSectorPreferences(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<JobSectors>> {
  return useQuery({
    queryKey: [USE_GET_DEFAULT_SECTORS_PREFERENCE_URI],
    queryFn: async () => {
      const response = await API.get(
        `${USE_GET_DEFAULT_SECTORS_PREFERENCE_URI}`
      );
      return response.data.data;
    },
    ...options,
  });
}
