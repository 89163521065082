import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import API from "@/common/api";
import { Languages } from "@/common/constants/languages";
import { Job } from "@/company/jobs/types";
import { getS3KeysFromPresignedUrl } from "@/utils";

export enum JobUploadTypes {
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  TEXT = "TEXT",
  JOB_PROMOTION = "JOB_PROMOTION",
}

export type CreateJobData = {
  videoLanguage: Languages;
  homeId?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  videoFile: any;
  type?: JobUploadTypes;
  createdForCompanyId?: number;
  summary?: string;
  source?: string;
};

export function useCreateJob(
  options: UseMutationOptions<Job, AxiosError, CreateJobData> = {}
): UseMutationResult<Job, AxiosError, CreateJobData> {
  return useMutation({
    mutationFn: async (data): Promise<Job> => {
      let dataToSend = {};

      if (data.type !== JobUploadTypes.TEXT && data.videoFile) {
        const videoMimeType = data.videoFile.type.replace("video/", "");

        // upload video
        let uploadUrlApi =
          "/jobs/video-upload-url?video_mime_type=" + videoMimeType;
        if (data.homeId) {
          uploadUrlApi += `&home_id=${data.homeId}`;
        }
        const UploadUrlResponse = await API.get(uploadUrlApi);
        const uploadUrl = UploadUrlResponse.data.data;
        // Start the timer for upload to s3
        const s3UploadStart = performance.now();

        await axios.put(uploadUrl, data.videoFile);
        // Calculate time for S3 upload
        const s3UploadDuration = (
          (performance.now() - s3UploadStart) /
          1000
        )?.toFixed(2);

        trackEvent(AnalyticsEvents.UPLOAD_TO_S3_DURATION, {
          uploadUrl,
          duration: s3UploadDuration,
          companyId: data.createdForCompanyId,
        });

        const s3Info = getS3KeysFromPresignedUrl(uploadUrl);
        dataToSend = {
          fssBucketKey: s3Info?.bucket,
          fssObjectKey: s3Info?.objectKey,
          videoLanguage: data.videoLanguage.toString(),
          videoMimeType,
          homeId: data.homeId,
          createdForCompanyId: data.createdForCompanyId,
        };
      } else {
        dataToSend = {
          summary: data.summary,
          homeId: data.homeId,
          createdForCompanyId: data.createdForCompanyId,
        };
      }
      dataToSend.type = data.type;
      dataToSend.source = data.source;

      // submit new job
      const response = await API.post(
        `/jobs`,
        changeCaseKeys(dataToSend, "underscored")
      );

      return response.data.data;
    },
    ...options,
  });
}
