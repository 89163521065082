import _ from "lodash";
import { DateTime } from "luxon";

import {
  Job,
  JobStatus,
  ReceivedReferralsChips,
  RoutedJobResponse,
  RoutedJobStatus,
  SentReferralsChips,
} from "../types/job";

export const getSubtitleDetailsForReceivedReferrals = (
  chipType: ReceivedReferralsChips
) => {
  return {
    [ReceivedReferralsChips.PENDING_REVIEWS]:
      "Jobs referred to you from your trusted partners. Be a good partner and act fast, as job expires after 24 hours",
    [ReceivedReferralsChips.ALL_HISTORY]:
      "Here you can view active jobs to chat with homeowners and see your job history.",
  };
};

export const JobStatusChipColor = {
  [RoutedJobStatus.PAID]: {
    bgcolor: "#EDFCF2",
    color: "#16B364",
  },
  [RoutedJobStatus.DECLINED]: {
    bgcolor: "rgba(240, 68, 56, 0.12)",
    color: "#B42318",
  },
  [RoutedJobStatus.PENDING_PAYMENT]: {
    bgcolor: "#EBEFFF",
    color: "#2970FF",
  },
  [RoutedJobStatus.INACTIVE]: {
    bgcolor: "neutral.100",
    color: "text.secondary",
  },
  [RoutedJobStatus.EXPIRED]: {
    bgcolor: "neutral.100",
    color: "text.secondary",
  },
};

/**
 * Sent job status mapper
 */
export const SentJobStatusChipColor = {
  [JobStatus.OPPORTUNITY]: {
    bgcolor: "#EDFCF2",
    color: "#16B364",
  },
  [JobStatus.SUBMISSION]: {
    bgcolor: "#EDFCF2",
    color: "#16B364",
  },

  [JobStatus.HOMEOWNER_DECLINED]: {
    bgcolor: "rgba(240, 68, 56, 0.12)",
    color: "#B42318",
  },
  [JobStatus.HOMEOWNER_DELAYED]: {
    bgcolor: "rgba(247, 144, 9, 0.12)",
    color: "#B54708",
  },
  [JobStatus.PENDING_HOMEOWNER_ACTION]: {
    bgcolor: "#EBEFFF",
    color: "#2970FF",
  },
  [JobStatus.PENDING_REFERRAL_PAYMENT]: {
    bgcolor: "rgba(247, 144, 9, 0.12)",
    color: "#B54708",
  },
  [JobStatus.HOMEOWNER_ACCEPTED]: {
    bgcolor: "#2970FF",
    color: "#FFFFFF",
  },
  [JobStatus.ROUTED]: {
    bgcolor: "#EBEFFF",
    color: "#2970FF",
  },
  [JobStatus.PAID]: {
    bgcolor: "#EDFCF2",
    color: "#16B364",
  },
  [JobStatus.NO_COMPANY_TO_ROUTE]: {
    bgcolor: "rgba(247, 144, 9, 0.12)",
    color: "#B54708",
  },
};

export const ProjectConsultantJobStatusChipProps: Record<
  string,
  { label: string; bgcolor: string; color: string }
> = {
  [JobStatus.HOMEOWNER_DECLINED]: {
    label: JobStatus.HOMEOWNER_DECLINED.toString().replaceAll("_", " "),
    bgcolor: "rgba(240, 68, 56, 0.12)",
    color: "#B42318",
  },
  [JobStatus.HOMEOWNER_DELAYED]: {
    label: JobStatus.HOMEOWNER_DELAYED.toString().replaceAll("_", " "),
    bgcolor: "rgba(247, 144, 9, 0.12)",
    color: "#B54708",
  },
  [JobStatus.PENDING_HOMEOWNER_ACTION]: {
    label: "PENDING HOMEOWNER APPROVAL",
    bgcolor: "#EBEFFF",
    color: "#2970FF",
  },
  [JobStatus.PENDING_REFERRAL_PAYMENT]: {
    label: "PENDING REFERRAL PAYMENT",
    bgcolor: "rgba(247, 144, 9, 0.12)",
    color: "#B54708",
  },
  [JobStatus.ROUTED]: {
    bgcolor: "#EBEFFF",
    color: "#2970FF",
    label: "PENDING PARTNER APPROVAL",
  },
  [JobStatus.PAID]: {
    label: JobStatus.PAID,
    bgcolor: "#EDFCF2",
    color: "#16B364",
  },
  [JobStatus.DRAFT]: {
    label: JobStatus.DRAFT,
    bgcolor: "neutral.100",
    color: "text.secondary",
  },
  IGNORED: {
    label: "IGNORED",
    bgcolor: "neutral.100",
    color: "text.secondary",
  },
  [JobStatus.HOMEOWNER_ACCEPTED]: {
    label: "HOMEOWNER ACCEPTED",
    bgcolor: "#2970FF",
    color: "#FFFFFF",
  },

  DECLINED: {
    label: "DECLINED",
    bgcolor: "rgba(240, 68, 56, 0.12)",
    color: "#B42318",
  },
  NO_REFERRAL_PARTNER_MATCHED: {
    label: "NO REFERRAL PARTNER MATCHED",
    bgcolor: "rgba(240, 68, 56, 0.12)",
    color: "#B42318",
  },
  [JobStatus.OPPORTUNITY]: {
    bgcolor: "neutral.100",
    color: "text.secondary",
    label: "OPPORTUNITY",
  },
  [JobStatus.SUBMISSION]: {
    bgcolor: "neutral.100",
    color: "text.secondary",
    label: "SUBMISSION",
  },
  [JobStatus.VIDEO_PROCESSING_FAILED]: {
    bgcolor: "neutral.100",
    color: "text.secondary",
    label: "VIDEO PROCESSING FAILED",
  },
  [JobStatus.CLOSED]: {
    bgcolor: "neutral.100",
    color: "text.secondary",
    label: "CLOSED",
  },
};

export const ProjectJobStatusChipProps: Record<
  JobStatus | RoutedJobStatus,
  { label: string; bgcolor: string; color: string }
> = {
  [JobStatus.HOMEOWNER_DECLINED]: {
    label: JobStatus.HOMEOWNER_DECLINED.toString().replaceAll("_", " "),
    bgcolor: "rgba(240, 68, 56, 0.12)",
    color: "#B42318",
  },
  [JobStatus.HOMEOWNER_DELAYED]: {
    label: JobStatus.HOMEOWNER_DELAYED.toString().replaceAll("_", " "),
    bgcolor: "rgba(247, 144, 9, 0.12)",
    color: "#B54708",
  },
  [JobStatus.PENDING_HOMEOWNER_ACTION]: {
    label: "PENDING HOMEOWNER APPROVAL",
    bgcolor: "#EBEFFF",
    color: "#2970FF",
  },
  [JobStatus.PENDING_REFERRAL_PAYMENT]: {
    label: "PENDING PARTNER UPDATE",
    bgcolor: "rgba(247, 144, 9, 0.12)",
    color: "#B54708",
  },
  [JobStatus.ROUTED]: {
    bgcolor: "#EBEFFF",
    color: "#2970FF",
    label: "PENDING PARTNER APPROVAL",
  },
  [JobStatus.OPPORTUNITY]: {
    bgcolor: "neutral.100",
    color: "text.secondary",
    label: "OPPORTUNITY",
  },
  [JobStatus.SUBMISSION]: {
    bgcolor: "neutral.100",
    color: "text.secondary",
    label: "SUBMISSION",
  },
  [JobStatus.PAID]: {
    label: "COMMISSION EARNED!",
    bgcolor: "#16B364",
    color: "white",
  },
  [JobStatus.DRAFT]: {
    label: JobStatus.DRAFT,
    bgcolor: "neutral.100",
    color: "text.secondary",
  },
  [RoutedJobStatus.DECLINED]: {
    label: RoutedJobStatus.DECLINED,
    bgcolor: "rgba(240, 68, 56, 0.12)",
    color: "#B42318",
  },
  [RoutedJobStatus.EXPIRED]: {
    label: RoutedJobStatus.EXPIRED,
    bgcolor: "neutral.100",
    color: "text.secondary",
  },
  [RoutedJobStatus.INACTIVE]: {
    label: RoutedJobStatus.INACTIVE,
    bgcolor: "neutral.100",
    color: "text.secondary",
  },
  [RoutedJobStatus.PENDING_REVIEW]: {
    label: "PENDING APPROVAL",
    bgcolor: "#EDFCF2",
    color: "#16B364",
  },
  [RoutedJobStatus.PENDING_PAYMENT]: {
    label: "PENDING PAYMENT",
    bgcolor: "#EBEFFF",
    color: "#2970FF",
  },
  [RoutedJobStatus.PAID]: {
    label: RoutedJobStatus.PAID,
    bgcolor: "#EDFCF2",
    color: "#16B364",
  },
  [JobStatus.VIDEO_PROCESSING_FAILED]: {
    bgcolor: "neutral.100",
    color: "text.secondary",
    label: "VIDEO PROCESSING FAILED",
  },
  [JobStatus.HOMEOWNER_ACCEPTED]: {
    label: "HOMEOWNER ACCEPTED",
    bgcolor: "#2970FF",
    color: "#FFFFFF",
  },
  [JobStatus.CLOSED]: {
    bgcolor: "neutral.100",
    color: "text.secondary",
    label: "CLOSED",
  },
  [JobStatus.NO_COMPANY_TO_ROUTE]: {
    label: "PENDING PARTNER UPDATE",
    bgcolor: "rgba(247, 144, 9, 0.12)",
    color: "#B54708",
  },
};

export const SentJobStatusChipLabel = {
  [JobStatus.HOMEOWNER_DECLINED]:
    JobStatus.HOMEOWNER_DECLINED.toString().replaceAll("_", " "),
  [JobStatus.OPPORTUNITY]: "Pending Review",
  [JobStatus.SUBMISSION]: "Video Processing",
  [JobStatus.HOMEOWNER_DELAYED]:
    JobStatus.HOMEOWNER_DELAYED.toString().replaceAll("_", " "),
  [JobStatus.HOMEOWNER_ACCEPTED]:
    JobStatus.HOMEOWNER_ACCEPTED.toString().replaceAll("_", " "),
  [JobStatus.PENDING_HOMEOWNER_ACTION]: "PENDING HOMEOWNER APPROVAL",
  [JobStatus.PENDING_REFERRAL_PAYMENT]: "PENDING PARTNER UPDATE",
  [JobStatus.ROUTED]: "PENDING PARTNER APPROVAL",
  [JobStatus.NO_COMPANY_TO_ROUTE]: "PENDING PARTNER UPDATE",
  [JobStatus.PAID]: "Commission Earned!",
};

export const SentJobStatusFilterChipLabel = {
  [JobStatus.HOMEOWNER_DECLINED]: JobStatus.HOMEOWNER_DECLINED,
  [JobStatus.PENDING_REFERRAL_PAYMENT]: "PENDING PARTNER UPDATE",
  [JobStatus.HOMEOWNER_DELAYED]: JobStatus.HOMEOWNER_DELAYED,
  [JobStatus.HOMEOWNER_ACCEPTED]: JobStatus.HOMEOWNER_ACCEPTED,
  [JobStatus.PENDING_HOMEOWNER_ACTION]: "PENDING_HOMEOWNER_APPROVAL",
  [JobStatus.ROUTED]: "PENDING_PARTNER_APPROVAL",
  [JobStatus.PAID]: "COMMISSION_EARNED!",
  [JobStatus.SUBMISSION]: JobStatus.SUBMISSION,
  [JobStatus.OPPORTUNITY]: JobStatus.OPPORTUNITY,
  [JobStatus.NO_COMPANY_TO_ROUTE]: "PENDING PARTNER UPDATE",
};

export const getSubtitleDetailsForSentReferrals = (
  chipType: SentReferralsChips
) => {
  return {
    [SentReferralsChips.EARNED]:
      "These are jobs submitted by your technicians and paid to you from your partners! You’ve earned [$##] to lower your job acquisition costs of buying referrals!",
    [SentReferralsChips.PENDING_HOMEOWNER]:
      "These are jobs the homeowner are evaluating.",
    [SentReferralsChips.PENDING_PARTNER]:
      "These are jobs pending action from your referral partner. Feel free to reach out to give them a nudge!",
    [SentReferralsChips.ALL_HISTORY]:
      "These are all of your referral jobs sent.",
  };
};

export const JobStatusFromSentJobsChip = {
  [SentReferralsChips.EARNED]: [JobStatus.PAID],
  [SentReferralsChips.PENDING_HOMEOWNER]: [
    JobStatus.HOMEOWNER_DELAYED,
    JobStatus.PENDING_HOMEOWNER_ACTION,
  ],
  [SentReferralsChips.PENDING_PARTNER]: [
    JobStatus.ROUTED,
    JobStatus.PENDING_REFERRAL_PAYMENT,
  ],
  [SentReferralsChips.ALL_HISTORY]: [],
};

export const JobStatusFromRoutedJobsChip = {
  [ReceivedReferralsChips.PENDING_REVIEWS]: [RoutedJobStatus.PENDING_REVIEW],
  [ReceivedReferralsChips.PENDING_PAYMENT]: [RoutedJobStatus.PENDING_PAYMENT],
  [ReceivedReferralsChips.ALL_HISTORY]: [],
};

export const changeDateToLocal = (
  date: string,
  inputFormat?: string,
  outputFormat?: string
) => {
  if (inputFormat) {
    return DateTime.fromFormat(date, inputFormat, {
      zone: "utc",
    })
      .toLocal()
      .toFormat(outputFormat ?? "MM/dd/yy hh:mm a");
  }
  return DateTime.fromISO(date, {
    zone: "utc",
  })
    .toLocal()
    .toFormat(outputFormat ?? "MM/dd/yy hh:mm a");
};

export const getLastActivityRoutedJob = (job: RoutedJobResponse) => {
  if (job.status === RoutedJobStatus.PAID && job.paidAt) {
    return changeDateToLocal(job.paidAt.toString());
  }
  if (job.status === RoutedJobStatus.PENDING_PAYMENT && job.acceptedAt) {
    return changeDateToLocal(job.acceptedAt.toString());
  }
  if (job.status === RoutedJobStatus.DECLINED && job.declinedAt) {
    return changeDateToLocal(job.declinedAt.toString());
  }
  if (job.status === RoutedJobStatus.EXPIRED && job.expiresAt) {
    return changeDateToLocal(job.expiresAt.toString());
  }
  if (job.status === RoutedJobStatus.PENDING_REVIEW && job.createdAt) {
    return changeDateToLocal(job.createdAt.toString());
  }
  if (job.status === RoutedJobStatus.INACTIVE && job.inactiveAt) {
    return changeDateToLocal(job.inactiveAt.toString());
  }
  return undefined;
};

export const getLastActivityJob = (job: Job) => {
  if (job.status === JobStatus.PAID && job.listingExtraInfo?.boughtAt) {
    return changeDateToLocal(job.listingExtraInfo.boughtAt.toString());
  }

  if (
    job.status === JobStatus.PENDING_REFERRAL_PAYMENT &&
    job.listingExtraInfo?.boughtAt
  ) {
    return changeDateToLocal(job.listingExtraInfo.boughtAt.toString());
  }

  if (
    job.status === JobStatus.ROUTED &&
    job.listingExtraInfo?.routedToCompanyAt
  ) {
    return changeDateToLocal(job.listingExtraInfo.routedToCompanyAt.toString());
  }
  if (
    job.status === JobStatus.HOMEOWNER_DECLINED &&
    job.listingExtraInfo?.homeownerDeclinedAt
  ) {
    return changeDateToLocal(
      job.listingExtraInfo.homeownerDeclinedAt.toString()
    );
  }
  if (
    job.status === JobStatus.HOMEOWNER_DELAYED &&
    job.listingExtraInfo?.homeownerDelayedAt
  ) {
    return changeDateToLocal(
      job.listingExtraInfo.homeownerDelayedAt.toString()
    );
  }
  if (
    job.status === JobStatus.PENDING_HOMEOWNER_ACTION &&
    job.listingExtraInfo?.homeownerViewedAt
  ) {
    return changeDateToLocal(job.listingExtraInfo.homeownerViewedAt.toString());
  }
  if (
    job.status === JobStatus.PENDING_HOMEOWNER_ACTION &&
    job.listingExtraInfo?.homeownerReceivedAt
  ) {
    return changeDateToLocal(
      job.listingExtraInfo.homeownerReceivedAt.toString()
    );
  }
  return changeDateToLocal(job?.updatedAt?.toString() ?? "");
};

export const getRecommendedProviderDetails = (
  job: Job
): {
  companyName: string | undefined;
  googlePlacesId: string | undefined;
  logoUrl: string | undefined;
  id: number | undefined;
} | null => {
  const recommendedProviderDetails = job?.listingExtraInfo?.buyingCompanyId
    ? {
        companyName: job?.listingExtraInfo?.buyingCompanyName,
        googlePlacesId: job?.listingExtraInfo?.buyingCompanyGooglePlacesId,
        logoUrl: job?.listingExtraInfo?.buyingCompanyLogoUrl,
        id: job?.listingExtraInfo.buyingCompanyId,
      }
    : job?.listingExtraInfo?.routedToCompanyId
    ? {
        companyName: job?.listingExtraInfo?.routedToCompanyName,
        googlePlacesId: job?.listingExtraInfo?.routedToCompanyGooglePlacesId,
        logoUrl: job?.listingExtraInfo?.routedToCompanyLogoUrl,
        id: job?.listingExtraInfo.routedToCompanyId,
      }
    : job?.listingExtraInfo?.potentialReferralForCompanyId
    ? {
        companyName: job?.listingExtraInfo?.potentialReferralForCompanyName,
        googlePlacesId:
          job?.listingExtraInfo?.potentialReferralForCompanyGooglePlacesId,
        logoUrl: job?.listingExtraInfo?.potentialReferralForCompanyLogoUrl,
        id: job?.listingExtraInfo.potentialReferralForCompanyId,
      }
    : null;
  return recommendedProviderDetails;
};

export const getHomeownerFormattedName = (name: string) => {
  const nameParts = name.trim().split(" ");

  if (nameParts.length === 1) {
    return _.capitalize(nameParts[0]);
  }

  const lastNameInitial = _.capitalize(nameParts[nameParts.length - 1])[0];
  const otherNames = nameParts.slice(0, -1).map(_.capitalize).join(" ");

  return `${otherNames} ${lastNameInitial}.`;
};
