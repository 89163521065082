import constate from "constate";
import { useSnackbar } from "notistack";

import { useAuth } from "@/auth/context/jwt";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { useGetUserStripeStatus } from "@/user/api/useGetUserStripeStatus";
import { useGetUserTransactions } from "@/user/api/useGetUserTransactions";
import { useWithdrawBalance } from "@/user/api/useWithdrawBalance";
import { UserTransaction } from "@/user/types";

const MAX_AMOUNT_THRESHOLD = 599;

function useTechnicianPayments() {
  const { session, refreshSession } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const {
    isFetching: isFetchingStatus,
    data: stripeStatus,
    refetch: refetchStripeStatus,
  } = useGetUserStripeStatus({
    refetchOnWindowFocus: false,
    onError: () => {
      enqueueSnackbar("There was an error while fetching your transactions.", {
        variant: "error",
      });
    },
  });

  const {
    isFetching: isFetchingTransactions,
    data: transactions,
    refetch: refetchTransactions,
  } = useGetUserTransactions({
    refetchOnWindowFocus: false,
    onError: () => {
      enqueueSnackbar("There was an error while fetching your transactions.", {
        variant: "error",
      });
    },
  });

  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const { mutateAsync: withdrawBalance, isLoading: isWithdrawing } =
    useWithdrawBalance({
      onError: error => {
        if (error.code === "cant_withdraw_money_more_than_threshold") {
          enqueueSnackbar({
            message: error.message,
            variant: "info",
            style: {
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              maxWidth: "500px",
            },
          });
        } else {
          enqueueSnackbar(
            "There was an error while withdrawing your balance.",
            {
              variant: "error",
            }
          );
        }
      },
      onSuccess: (transaction: UserTransaction) => {
        if (
          transaction &&
          transaction.oldBalance <= MAX_AMOUNT_THRESHOLD &&
          transaction.newBalance > MAX_AMOUNT_THRESHOLD
        ) {
          enqueueSnackbar({
            message:
              "Soon you'll be able to withdraw these funds through Venmo or gift cards. Hold tight while we enable this feature to make your withdrawals easier!",
            variant: "info",
            style: {
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              maxWidth: "500px",
            },
          });
        }
        enqueueSnackbar({
          message:
            "Your withdrawal is being processed and you will receive a text message from Stripe within 1-2 days. Processing times vary based on your bank.",
          variant: "success",
        });
        refreshSession();
        refetchTransactions();
      },
    });

  return {
    stripeAccountId: session?.user?.stripeAccountId ?? null,
    balance: session?.user?.balance ?? 0,
    transactions,
    stripeStatus,
    isFetchingTransactions,
    isFetchingStatus,
    isWithdrawing,
    refetchTransactions,
    refetchStripeStatus,
    withdrawBalance,
  };
}

export const [TechnicianPaymentsProvider, useTechniciansPaymentsContext] =
  constate(useTechnicianPayments);
