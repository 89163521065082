import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import axiosInstance from "@/common/api";
import { CallRecording } from "@/company/api/useFetchCallRecordings";

export function useUpdateNoReferral(
  options: UseMutationOptions<
    CallRecording,
    AxiosError,
    { callRecordingId: number }
  > = {}
): UseMutationResult<CallRecording, AxiosError, { callRecordingId: number }> {
  return useMutation({
    mutationFn: async ({ callRecordingId }) => {
      const url = `call-recordings/${callRecordingId}/no-referral`;
      const response = await axiosInstance.post(url);
      return response.data.data;
    },
    ...options,
  });
}
