import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
// eslint-disable-next-line import/no-unresolved
import * as changeKeys from "change-case/keys";

import API from "@/common/api";

import { CompanyPublicProfile } from "../types";

export function useFetchCompanyProfile(
  companyId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<CompanyPublicProfile> {
  const uri = `companies/${companyId}/profile`;

  return useQuery({
    queryKey: [uri],
    queryFn: async () => {
      const response = await API.get(uri);
      return changeKeys.camelCase(response.data.data);
    },
    ...options,
  });
}
