import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Close as CloseIcon } from "@mui/icons-material";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Rating,
  Theme,
  Typography,
} from "@mui/material";
import Slider from "react-slick";

import { useFetchGoogleCompanyProfile } from "@/company/api/useFetchGoogleCompanyProfile";
import { GoogleCompanyProfile } from "@/company/types";

import { ToolTip } from "../tool-tip/ToolTip";

export interface GoogleProfileProps {
  googlePlacesId?: string;
  googleProfile?: GoogleCompanyProfile;
  companyName?: string;
  isVerified?: boolean;
  onClose?: () => void;
}

export function GoogleCompanyProfileDetails(props: GoogleProfileProps) {
  const { data, isFetching } = useFetchGoogleCompanyProfile(
    props.googlePlacesId ?? "",
    { refetchOnWindowFocus: false, enabled: Boolean(props.googlePlacesId) }
  );

  if (!props.googlePlacesId) return null;

  const reviewImagesCarouselSettings = {
    dots: true,
    speed: 500,

    arrows: true,
  };

  const googleProfile = props.googleProfile ?? data;

  return (
    <Box display={"flex"} flexDirection={"column"}>
      {isFetching ? (
        <Box my={5} textAlign="center">
          <CircularProgress />
        </Box>
      ) : googleProfile ? (
        <>
          <Box bgcolor={theme => theme.palette.common.white}>
            <Alert
              variant="standard"
              severity="info"
              sx={{
                my: 2,
                backgroundColor: (theme: Theme) => theme.palette.info.light,
                color: (theme: Theme) => theme.palette.info.main,
              }}
            >
              <Typography variant="body2">
                This profile is generated from Google.
              </Typography>
            </Alert>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h5" pr={1}>
                  {props?.companyName ?? googleProfile?.name}
                  {props?.isVerified ? (
                    <ToolTip
                      placement="bottom"
                      title="TradeEngage Participant"
                      message="This is a verified active business on TradeEngage."
                    >
                      <VerifiedOutlinedIcon
                        sx={{
                          verticalAlign: "center",
                          color: "#16DB65",
                          ml: 1,
                        }}
                      />
                    </ToolTip>
                  ) : null}
                </Typography>
              </Box>
              {props.onClose && (
                <IconButton
                  aria-label="close"
                  sx={{
                    color: theme => theme.palette.grey[500],
                  }}
                  onClick={props.onClose}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
              gap={2}
              py={2}
            >
              <Box display={"flex"} alignItems={"center"} py={1}>
                <Typography variant="h6">
                  {googleProfile?.rating?.toFixed(1)}
                </Typography>
                <Rating
                  readOnly
                  name="google-rating"
                  defaultValue={googleProfile?.rating}
                  value={googleProfile?.rating}
                  precision={0.1}
                />
                {googleProfile.reviewsCount ? (
                  <Typography variant="caption" color={"#11192761"}>
                    ({googleProfile.reviewsCount} Total Reviews)
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Typography
              fontSize={"12px"}
              fontStyle={"normal"}
              fontWeight={"600"}
            >
              {googleProfile?.userReviews?.mostRelevant?.length ?? 0} REVIEWS
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Listed in order of relevance
            </Typography>
          </Box>
          {googleProfile?.userReviews?.mostRelevant ? (
            <Box display={"flex"} flexDirection={"column"} gap={2} mt={2}>
              {googleProfile?.userReviews?.mostRelevant?.map(
                (review, index) => {
                  return (
                    <Box
                      key={index}
                      pb={2}
                      display={"flex"}
                      flexDirection={"column"}
                      gap={2}
                    >
                      <Box display={"flex"} gap={2}>
                        {review?.username ? (
                          <Avatar>{`${
                            review?.username
                              ?.toUpperCase()
                              ?.split(" ")?.[0]?.[0]
                          }${
                            review?.username
                              ?.toUpperCase()
                              ?.split(" ")?.[1]?.[0]
                          }`}</Avatar>
                        ) : (
                          <Avatar />
                        )}
                        <Box>
                          <Typography variant="subtitle2">
                            {review.username ?? "unknown"}
                          </Typography>
                          {review ? (
                            <Box display={"flex"} gap={1} alignItems={"center"}>
                              <Rating
                                readOnly
                                size="small"
                                name="google-rating"
                                defaultValue={review?.rating}
                                value={review?.rating}
                                precision={0.1}
                              />
                              {review.date ? (
                                <Typography
                                  variant="body2"
                                  color={"text.secondary"}
                                >
                                  {review.date}
                                </Typography>
                              ) : null}
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                      {review?.description ? (
                        <Typography variant="body2">
                          {review?.description}
                        </Typography>
                      ) : null}
                      {review?.images ? (
                        <Box>
                          <Slider
                            {...{
                              ...reviewImagesCarouselSettings,
                              slidesToShow: review?.images?.length,
                            }}
                          >
                            {review?.images?.map((item, index) => (
                              <Box key={index} p={1}>
                                <img
                                  src={item.thumbnail}
                                  alt={item.title}
                                  style={{ maxWidth: "160px" }}
                                />
                              </Box>
                            ))}
                          </Slider>
                        </Box>
                      ) : null}
                    </Box>
                  );
                }
              )}
            </Box>
          ) : null}
        </>
      ) : (
        <Typography variant="body2">There was an error</Typography>
      )}
    </Box>
  );
}
