import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { Seo } from "@/common/Seo/Seo";
import { canSeeCompanyTeamMembers } from "@/common/utils/accessControl";

import { CompanyPaymentsTab } from "../../company-payments-tab/CompanyPaymentsTab";
import { CompanyProfileInformation } from "../../company-profile-information/CompanyProfileInformation";
import { ReferralMatrix } from "../../company-settings/ReferralMatrix";
import { ServiceTerritory } from "../../company-settings/ServiceTerritory";
import { ServiceTypes } from "../../company-settings/ServiceTypes";

export const enum SettingsTabs {
  COMPANY_PROFILE = "COMPANY_PROFILE",
  REFERRAL_MATRIX = "REFERRAL_MATRIX",
  SERVICES = "SERVICES",
  SERVICE_TERRITORY = "SERVICE_TERRITORY",
  PAYMENT = "PAYMENT",
  NOTIFICATIONS = "NOTIFICATIONS",
}

export function CompanySettingsPage() {
  const { tab } = useParams();

  const { session } = useAuth();
  const company = session?.company;

  const [currentTab, setCurrentTab] = useState<SettingsTabs>(
    SettingsTabs.COMPANY_PROFILE
  );

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: SettingsTabs): void => {
      setCurrentTab(value);
    },
    []
  );

  const handleTabsChangeProp = useCallback((value: SettingsTabs): void => {
    setCurrentTab(value);
  }, []);

  useEffect(() => {
    if (tab) {
      setCurrentTab(tab.toUpperCase() as SettingsTabs);
    }
  }, [tab]);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const TabsBar = (
    <Grid item xs={12}>
      <Tabs
        value={currentTab}
        variant="scrollable"
        scrollButtons={isDesktop ? false : true}
        allowScrollButtonsMobile={isDesktop ? false : true}
        onChange={handleTabsChange}
      >
        <Tab value={SettingsTabs.COMPANY_PROFILE} label={`Company Profile`} />
        <Tab
          value={SettingsTabs.SERVICE_TERRITORY}
          label={`Service Territory`}
        />
        <Tab value={SettingsTabs.SERVICES} label={`Services`} />
        {session?.groups?.some(canSeeCompanyTeamMembers) ? (
          <Tab value={SettingsTabs.REFERRAL_MATRIX} label={`Referral Matrix`} />
        ) : null}
        <Tab value={SettingsTabs.PAYMENT} label={`Payment`} />
        {/* <Tab value={SettingsTabs.NOTIFICATIONS} label={`Notifications`} /> */}
      </Tabs>
      <Divider />
    </Grid>
  );
  return (
    <>
      <Seo title="Company Settings" />
      <Grid container>
        <Grid
          item
          position={"sticky"}
          sx={{ top: "56px", zIndex: 400, pt: 1 }}
          bgcolor={"white"}
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              {isMobile ? null : <Typography variant="h5">Settings</Typography>}
              <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
                Configure your settings and access your payments from here.
              </Typography>
            </Box>
          </Box>
          {TabsBar}
        </Grid>
        <Grid item xs={12} lg={12} py={2}>
          {currentTab === SettingsTabs.COMPANY_PROFILE ? (
            <CompanyProfileInformation
              handleTabsChange={handleTabsChangeProp}
            />
          ) : null}
          {currentTab === SettingsTabs.REFERRAL_MATRIX ? (
            <ReferralMatrix />
          ) : null}
          {currentTab === SettingsTabs.SERVICE_TERRITORY ? (
            <ServiceTerritory />
          ) : null}
          {currentTab === SettingsTabs.SERVICES ? (
            <ServiceTypes handleTabsChange={handleTabsChangeProp} />
          ) : null}
          {currentTab === SettingsTabs.PAYMENT ? <CompanyPaymentsTab /> : null}
        </Grid>
      </Grid>
    </>
  );
}
