import { DateTime } from "luxon";

import { USER_TYPE } from "@/auth/types";
import { JobPromotion } from "@/company/job-promotions/types";
import { CompanySector, PayReferralFeeSettings } from "@/company/types";

import { JobUploadTypes } from "../api/useCreateJob";
import { JobSectors } from "../constants";

export enum JobStatus {
  SUBMISSION = "SUBMISSION",
  VIDEO_PROCESSING_FAILED = "VIDEO_PROCESSING_FAILED",
  OPPORTUNITY = "OPPORTUNITY",
  PENDING_HOMEOWNER_ACTION = "PENDING_HOMEOWNER_ACTION",
  PENDING_REFERRAL_PAYMENT = "PENDING_REFERRAL_PAYMENT",
  EXPIRED = "EXPIRED",
  HOMEOWNER_ACCEPTED = "HOMEOWNER_ACCEPTED",
  ROUTED = "ROUTED",
  PAID = "PAID",
  HOMEOWNER_DECLINED = "HOMEOWNER_DECLINED",
  HOMEOWNER_DELAYED = "HOMEOWNER_DELAYED",
  CLOSED = "CLOSED",
  NO_COMPANY_TO_ROUTE = "NO_COMPANY_TO_ROUTE",
  DRAFT = "DRAFT",
}

export enum JobSource {
  IN_APP = "IN_APP",
  WHATSAPP = "WHATSAPP",
  CHROME_EXTENSION = "CHROME_EXTENSION",
}

export const isJobHomeownerAccepted = (jobStatus: JobStatus) => {
  if (
    jobStatus === JobStatus.ROUTED ||
    jobStatus === JobStatus.PAID ||
    jobStatus === JobStatus.EXPIRED ||
    jobStatus === JobStatus.NO_COMPANY_TO_ROUTE ||
    jobStatus === JobStatus.HOMEOWNER_ACCEPTED ||
    jobStatus === JobStatus.CLOSED ||
    jobStatus === JobStatus.PENDING_REFERRAL_PAYMENT
  )
    return true;
  return false;
};

export enum RoutedJobStatus {
  PENDING_REVIEW = "PENDING",
  PAID = "PAID",
  EXPIRED = "EXPIRED",
  INACTIVE = "INACTIVE",
  DECLINED = "DECLINED",
  PENDING_PAYMENT = "PENDING_PAYMENT",
}

export interface Home {
  id: number;
  label?: string;
  homeownerId: number;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: number;
  country: string;
  createdAt: Date;
}

export interface JobListingExtraInformation {
  createdByCompanyGooglePlacesId?: string;
  createdByCompanyId?: number;
  createdByCompanyName?: string;
  createdByCompanyLogoUrl?: string;
  buyingCompanyName?: string;
  buyingCompanyLogoUrl?: string;
  buyingCompanySchedulingUrl?: string;
  buyingCompanyId?: number;
  buyingCompanyGooglePlacesId?: string;
  boughtAt?: Date;
  potentialReferralForCompanyName?: string;
  potentialReferralForCompanyLogoUrl?: string;
  potentialReferralForCompanyId?: number;
  potentialReferralForCompanyGooglePlacesId?: string;
  homeownerReceivedAt?: Date;
  homeownerDelayedAt?: Date;
  homeownerDeclinedAt?: Date;
  routedToCompanyId?: number;
  routedToCompanyGooglePlacesId?: string;
  routedToCompanyName?: string;
  routedToCompanyLogoUrl?: string;
  routedToCompanyAt?: Date;
  technicianName: string;
  homeownerViewedAt?: Date;
  routedPartnerViewedAt?: Date;
  homeownerDeclinedReason?: string;
  homeownerDelayedReason?: string;
  homeownerName?: string;
  homeownerId?: number;
  routedToCompanySectors?: Array<CompanySector>;
  potentialReferralForCompanySectors?: Array<CompanySector>;
  buyingCompanySectors?: Array<CompanySector>;
  latestJobRouting?: RoutedJobResponse;
}

export enum CallCategory {
  BUSINESS_REFERS_BUSINESS = "BUSINESS_REFERS_BUSINESS",
  BUSINESS_REFERRED_HOMEOWNER = "BUSINESS_REFERRED_HOMEOWNER",
  BUSINESS_REFERS_HOMEOWNER = "BUSINESS_REFERS_HOMEOWNER",
  HOMEOWNER_SCHEDULING_A_JOB = "HOMEOWNER_SCHEDULING_A_JOB",
  NO_CATEGORY = "NO_CATEGORY",
  WORK_ORDER_FOLLOW_UP = "WORK_ORDER_FOLLOW_UP",
  REGISTER_AS_VENDOR = "REGISTER_AS_VENDOR",
}

export interface Job {
  id: number;
  status: JobStatus;
  publicId?: string;
  title?: string;
  sector?: string;
  sectorSpanish?: string;
  summary?: string;
  summarySpanish?: string;
  createdByCompanyId?: number;
  createdForCompanyId?: number;
  createdByUserId: number;
  createdByUserType: USER_TYPE;
  buyingCompanyId?: number;
  jobType?: string;
  jobTypeSpanish?: string;
  videoUrl?: string;
  costRangeEstimate?: string;
  homeId?: number;
  createdAt: Date;
  updatedAt?: Date;
  listingExtraInfo?: JobListingExtraInformation;
  actualCost?: number;
  jobBuyingCost?: number;
  lastActivity?: Date;
  technicianFee?: number;
  source?: JobSource;
  type?: JobUploadTypes;
  routeToHomeowner?: boolean;
  percentageFee?: string;
  payReferralFeeSettings?: PayReferralFeeSettings;
  callCategory?: CallCategory;
  leadPrice?: boolean;
  jobPromotion?: Pick<JobPromotion, "id" | "title" | "referralSalePrice">;
}

export interface RoutedJobResponse {
  id: number;
  jobId: number;
  companyId: number;
  companyName?: string;
  companyLogoUrl?: string;
  status: RoutedJobStatus;
  summary: string;
  createdById: number;
  createdAt: Date;
  updatedAt: Date;
  inactiveAt?: Date;
  acceptedAt: Date;
  paidAt: Date;
  declinedAt: Date;
  declinedReason: string;
  expiresAt: Date;
  jobType: string;
  courtesyOf: { companyName: string; companyId: number };
  recommendedBy: string;
  lastActivity: Date;
  buyingCost: number;
  inactiveReason?: string;
  job?: Job;
}

export enum JobDashboardTabs {
  REFERRALS_RECEIVED,
  REFERRALS_SENT,
}

export enum ReceivedReferralsChips {
  PENDING_REVIEWS = "PENDING_REVIEWS",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  ALL_HISTORY = "REFERRAL_RECEIVED_ALL_HISTORY",
}

export enum SentReferralsChips {
  PENDING_HOMEOWNER = "PENDING_HOMEOWNER",
  PENDING_PARTNER = "PENDING_PARTNER",
  EARNED = "EARNED",
  ALL_HISTORY = "REFERRAL_SENT_ALL_HISTORY",
}

export interface SentJobFilters {
  dateRange?: { startDate?: DateTime; endDate?: DateTime };
  sectors?: Array<JobSectors>;
  status?: JobStatus;
}

export interface RoutedJobFilters {
  dateRange?: { startDate?: DateTime; endDate?: DateTime };
  sectors?: Array<JobSectors>;
  status?: RoutedJobStatus;
}

export enum Source {
  TECHNICIAN_DASHBOARD = "TECHNICIAN_DASHBOARD",
  HOMEOWNER_DASHBOARD = "HOMEOWNER_DASHBOARD",
  BRAND_LOCATION_DASHBOARD = "BRAND_LOCATION_DASHBOARD",
  CALL_CENTER = "CALL_CENTER",
  CONSULTANT = "CONSULTANT",
}
