import "./styles.css"; // Import your custom styles

import {
  Add as AddIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Drawer,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { Seo } from "@/common/Seo/Seo";
import { useShowToolTipContext } from "@/common/show-tool-tip";
import {
  isAdministratorOrOwner,
  isCompanyActive,
} from "@/common/utils/accessControl";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import {
  CompanyExploreFilters,
  SearchedCompany,
  useQueryExploreCompanies,
} from "@/company/api/useExploreConnections";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { useMarkedInterestSetContext } from "@/company/context/marked-interest-context";
import { useReferralContext } from "@/company/context/referral-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import { useCompanySearchContext } from "@/company/context/search-company-context";
import { AccessControlDialog } from "@/company/jobs/components/AccessControlDialog";
import { JobSectors } from "@/company/jobs/constants";
import {
  CompanyPublicProfile,
  CompanyRoutes,
  ConnectionStatus,
  ReferralInvitation,
} from "@/company/types";
import { customCompanyProfileSort } from "@/utils";

import { CompanyDrawer, TabsEnum } from "../../company-drawer/CompanyDrawer";
import { ExternalCompanyDrawer } from "../../company-drawer/ExternalCompanyDrawer";
import { ReferralInvitationDrawer } from "../../company-drawer/ReferralInvitationDrawer";
import { InvitationDialog } from "../../invitation-dialog/InvitationDialog";
import ExplorePartnersList from "./ExplorePartnersList";
import {
  ExplorePartnersTabScreen,
  TabsEnumPartners,
} from "./ExplorePartnersTabScreen";
import { FilterDrawer } from "./FilterDrawer";
import { otherFilterOptions } from "./utils";

export const ExplorePartnerTabs = [
  { label: "Pending Invitations", filter: {} },
  { label: "Affiliate Companies", filter: {} },
  { label: "Companies You May Know", filter: {} },
  { label: "Explore", filters: {} },
];
export interface CompanyFiltersModal {
  initialValues: CompanyExploreFilters;
  applyFilters: (filters: CompanyExploreFilters) => void;
  variant: "permanent" | "temporary";
  closeFilters: () => void;
}

export const filterValidationSchema = {};

export function FiltersModal(props: CompanyFiltersModal) {
  const formik = useFormik({
    initialValues: props.initialValues,
    validationSchema: filterValidationSchema,
    onSubmit: async values => props.applyFilters(values),
  });

  return (
    <Drawer
      variant={props.variant}
      anchor="right"
      open={true}
      onClose={props.closeFilters}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography variant="body1">Filters</Typography>
        <IconButton onClick={props.closeFilters}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <Autocomplete
          multiple
          id="sectors"
          options={Object.keys(JobSectors).map(sector => sector.toString())}
          getOptionLabel={option => option}
          renderInput={params => (
            <TextField
              {...params}
              required
              fullWidth
              size="small"
              sx={{ mt: "16px" }}
              InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
              label="Select Sector Category"
            />
          )}
          renderOption={(props, option, { selected }) => (
            <MenuItem
              {...props}
              key={option}
              value={option}
              sx={{ justifyContent: "space-between" }}
            >
              {option}
              {selected ? <CheckIcon color="info" /> : null}
            </MenuItem>
          )}
          onChange={(_: SyntheticEvent, newValue: string[] | null) =>
            formik.setFieldValue("sectors", newValue)
          }
          onBlur={formik.handleBlur}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => formik.handleSubmit()}
        >
          Apply Filters
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => formik.resetForm()}
        >
          Reset Filters
        </Button>
      </Box>
    </Drawer>
  );
}

export function ExplorePartnersPage() {
  const { companyId, tab } = useParams();
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(true);
  const [showFirstInviteTooltip, setShowFirstInviteTooltip] = useState(true);

  const { setShowToolTip } = useShowToolTipContext();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const [selectedTab, setSelectedTab] = useState<TabsEnum | null>(null);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [drawerFilters, setDrawerFilters] = useState<
    Partial<CompanyExploreFilters>
  >({});

  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);
  const [filters, setFilters] = useState<CompanyExploreFilters>({});
  const [tabFilters, setTabFilters] = useState<CompanyExploreFilters>({});

  const { session } = useAuth();
  const company = session?.company;
  const user = session?.user;

  const brandLocationProfile = session?.brandLocationProfile;

  const { data: companyProfileByParam } = useFetchCompanyProfile(companyId, {
    refetchOnWindowFocus: false,
    enabled: !!companyId,
  });

  const [showRecommendedPartnersOnly, setShowRecommendedPartnersOnly] =
    useState(false);

  const [searchParams] = useSearchParams();

  const showRecommendedPartners = searchParams.get("show-recommended-partners");
  const exploreTab = searchParams.get("exploreTab");

  useEffect(() => {
    if (showRecommendedPartners == "true") setShowRecommendedPartnersOnly(true);
  }, [showRecommendedPartners]);

  useEffect(() => {
    if (exploreTab == "affiliate-companies") {
      setCurrentTab(TabsEnumPartners.AFFILIATE_COMPANIES);
    }
  }, [exploreTab]);

  const setFiltersThroughRecommendationTab = (
    tabRecommendedFilters: Partial<CompanyExploreFilters>
  ) => {
    setFilters({ ...filters, ...tabRecommendedFilters });
    setDrawerFilters({ ...drawerFilters, ...tabRecommendedFilters });
  };

  const getFilterChips = () => {
    const chips = [] as Array<ReactElement>;
    Object.keys(filters).map(filter => {
      if (filter === "sectors") {
        filters?.sectors?.map(sector =>
          chips.push(
            <Chip
              key={sector}
              label={sector}
              sx={{ mr: 1 }}
              color="primary"
              variant="outlined"
              size="small"
              onDelete={() => {
                const newSectors = filters.sectors?.filter(
                  drawerSector => drawerSector !== sector
                );
                setDrawerFilters({ ...drawerFilters, sectors: newSectors });
                setFilters({ ...filters, sectors: newSectors });
              }}
            />
          )
        );
      } else {
        if (
          !!filters[filter] &&
          Object.keys(otherFilterOptions).includes(filter)
        ) {
          chips.push(
            <Chip
              key={filter}
              label={otherFilterOptions[filter]}
              color="primary"
              variant="outlined"
              sx={{ mr: 1 }}
              size="small"
              onDelete={() => {
                setDrawerFilters({ ...drawerFilters, [filter]: false });
                setFilters({ ...filters, [filter]: false });
              }}
            />
          );
        }
      }
    });
    return chips;
  };

  const applyFilters = () => {
    setFilters(filters => ({ ...filters, ...drawerFilters }));
    setIsFiltersOpen(false);
  };

  const resetFilters = () => {
    setDrawerFilters({});
    setFilters({ ...(searchKeyword ? { keyword: searchKeyword } : {}) });
    setIsFiltersOpen(false);
  };

  const areTabsActive = !Object.keys(filters).some(filter => {
    if (filter === "sectors") {
      if (filters?.sectors?.length) return true;
    } else if (filters[filter]) {
      return true;
    }
    return false;
  });
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [currentTab, setCurrentTab] = useState<TabsEnumPartners>(
    TabsEnumPartners.PENDING_INVITATIONS
  );

  const { isFetching, data, setEnabled, updateFilter } =
    useCompanySearchContext();

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id ?? 0, {
      refetchOnWindowFocus: false,
      enabled: !!company?.id,
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];

  const { isFetching: isFetchingGoogleCompanies, data: googleCompaniesData } =
    useQueryExploreCompanies(
      {
        ...filters,
        nearby: true,
        isGoogleCompany: true,
      },
      recommendedJobSectorsList?.length
        ? recommendedJobSectorsList
        : relevanceOrderedJobSectorsList,
      {
        refetchOnWindowFocus: false,
        enabled: !!(
          (!!recommendedJobSectorsList || !!relevanceOrderedJobSectorsList) &&
          !!Object.keys(filters)?.length
        ),
        refetchOnReconnect: false,
        retry: false,
      }
    );

  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollPos, setLastScrollPos] = useState<number | null>(null);

  const stickyDivRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const divOffsetTop = stickyDivRef.current?.offsetTop;
      const scrollPos = window.scrollY;
      if (!isSticky && scrollPos >= divOffsetTop - 56) {
        setLastScrollPos(scrollPos);
      }
      if (divOffsetTop === 56 && lastScrollPos && scrollPos <= lastScrollPos) {
        setIsSticky(scrollPos >= lastScrollPos);
      } else setIsSticky(scrollPos >= divOffsetTop - 56);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPos]);

  useEffect(() => {
    updateFilter({ ...filters, ...(areTabsActive ? { ...tabFilters } : {}) });
  }, [areTabsActive, filters, tabFilters]);

  const {
    referralPartners,
    isFetchingReferralPartners,
    refetchReferralPartners,
  } = useReferralContext();
  const { referralInvitations, refetchReferralInvitations } =
    useReferralInvitationContext();

  useEffect(() => {
    if (
      currentTab === TabsEnumPartners.AFFILIATE_COMPANIES ||
      currentTab === TabsEnumPartners.PENDING_INVITATIONS ||
      currentTab === TabsEnumPartners.OTHER_RECOMMENDATIONS
    )
      refetchReferralInvitations();
    refetchReferralPartners();
  }, [currentTab]);

  const memoizedOtherCompanies = useMemo(() => {
    let otherCompanies = data ?? [];

    if (googleCompaniesData?.length) {
      otherCompanies = [...otherCompanies, ...googleCompaniesData];
      otherCompanies.sort(customCompanyProfileSort);
    }
    const googlePlacesIdSet = new Set<string>();

    otherCompanies = otherCompanies.filter(company => {
      const googleId =
        company.companyPublicProfile?.profile.googlePlacesId ??
        company.googleCompanyProfile?.googlePlacesId;
      if (googleId) {
        if (googlePlacesIdSet.has(googleId)) return false;
        googlePlacesIdSet.add(googleId);
        return true;
      }
      return true;
    });
    return otherCompanies;
  }, [data, googleCompaniesData]);

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: TabsEnumPartners): void => {
      setCurrentTab(value);
      if (value === TabsEnumPartners.AFFILIATE_COMPANIES) {
        setTabFilters({
          affiliatedCompanies: true,
        });
        return;
      }
      setTabFilters({});
      setShowRecommendedPartnersOnly(false);
    },
    []
  );

  const [currentCompanyProfile, setCurrentCompanyProfile] =
    useState<CompanyPublicProfile | null>(null);

  const [selectedCompany, setSelectedCompany] =
    useState<SearchedCompany | null>(null);

  const [currentInvitation, setCurrentInvitation] =
    useState<ReferralInvitation | null>(null);

  const getConnectionStatus = useCallback(
    (currentCompany: CompanyPublicProfile): ConnectionStatus => {
      if (
        referralPartners?.some(
          referral =>
            referral.referredCompanyId === currentCompany?.id ||
            referral.referringCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.CONNECTED;

      if (
        referralInvitations?.some(
          invitation => invitation.invitedCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.PENDING;

      if (
        referralInvitations?.some(
          invitation => invitation.invitingCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.INVITED;

      return ConnectionStatus.NOT_CONNECTED;
    },
    [referralInvitations, referralPartners]
  );

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === "Enter") {
      if (searchKeyword) {
        setFilters(filter => ({
          ...filter,
          keyword: searchKeyword,
        }));
        return;
      }
      setFilters(filter => {
        const newFilter = { ...filter };
        delete newFilter.keyword;
        return newFilter;
      });
    }
  };

  const closeToolTips = () => {
    setShowTooltip(false);
    setShowToolTip(false);
    setShowFirstInviteTooltip(false);
  };
  useEffect(() => {
    if (currentCompanyProfile) {
      closeToolTips();
    }
  }, [currentCompanyProfile]);

  useEffect(() => {
    if (currentInvitation) {
      closeToolTips();
    }
  }, [currentInvitation]);

  useEffect(() => {
    if (isInvitationDialogOpen) {
      closeToolTips();
    }
  }, [isInvitationDialogOpen]);

  useEffect(() => {
    if (selectedCompany) {
      closeToolTips();
    }
  }, [selectedCompany]);

  const filteredChips = getFilterChips();

  useEffect(() => {
    if (currentTab === TabsEnumPartners.PENDING_INVITATIONS && areTabsActive) {
      setEnabled(false);
      return;
    }
    setEnabled(true);
  }, [currentTab, areTabsActive, setEnabled]);

  useEffect(() => {
    if (companyProfileByParam) {
      setSelectedTab(tab as TabsEnum);
      setCurrentCompanyProfile(companyProfileByParam);
    }
  }, [companyProfileByParam, tab]);

  useEffect(() => {
    if (!session?.groups?.some(isAdministratorOrOwner)) {
      return navigate(
        getNavigationPathFromRoutePaths([
          CompanyRoutes.BASE,
          CompanyRoutes.DASHBOARD_HOME,
        ])
      );
    }
  }, [session]);

  const { updateMarkedInterestSet } = useMarkedInterestSetContext();

  return (
    <>
      <Seo title="Explore New Connections" />
      <Grid container>
        <Grid
          item
          {...(isDesktop
            ? { sx: { position: "sticky", top: "56px", zIndex: 400, pt: 1 } }
            : { zIndex: 400, pt: 1 })}
          bgcolor={"white"}
          xs={12}
          lg={isFiltersOpen && isDesktop ? false : 12}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              {isMobile ? null : (
                <Typography variant="h5">Explore Partners</Typography>
              )}
              <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
                See your pending partnerships and add recommended referral
                partners.
                {isCompanyActive(company?.status) &&
                brandLocationProfile?.showExplorePartners
                  ? " Explore new partnerships by searching on the page below."
                  : ""}
              </Typography>
            </Box>
          </Box>
          {isCompanyActive(company?.status) &&
          brandLocationProfile?.showExplorePartners ? (
            <Grid
              ref={stickyDivRef}
              container
              sx={{
                py: isMobile ? 1 : 2,
                ...(isSticky && isMobile
                  ? {
                      position: "fixed",
                      top: "56px",
                      zIndex: 400,
                      pr: 4,
                      bgcolor: "white",
                    }
                  : { position: "relative" }),
              }}
              gap={{ xs: 0, lg: 0 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={9.5} lg={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size={isMobile ? "small" : "medium"}
                  name="filterKeyword"
                  placeholder="Search by company name or keyword"
                  value={searchKeyword ?? ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchKeyword ? (
                          // eslint-disable-next-line react/jsx-sort-props
                          <CloseIcon
                            fontSize="small"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setSearchKeyword("");
                              setFilters(filter => {
                                const newFilter = { ...filter };
                                delete newFilter.keyword;
                                return newFilter;
                              });
                            }}
                          />
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                  onKeyUp={handleKeyUp}
                  onChange={event => setSearchKeyword(event.target.value)}
                />
              </Grid>
              <Grid item xs={1} lg={1.5} px={{ xs: 0, lg: 2 }}>
                {isMobile ? (
                  <IconButton
                    size="small"
                    sx={{
                      p: 0.5,
                      bgcolor: "#16B364",
                      borderRadius: "8px",
                      mx: 1,
                      "&:hover": { bgcolor: "#16B364" },
                    }}
                    onClick={() => {
                      if (searchKeyword) {
                        setFilters(filter => ({
                          ...filter,
                          keyword: searchKeyword,
                        }));
                        return;
                      }
                      setFilters(filter => {
                        const newFilter = { ...filter };
                        delete newFilter.keyword;
                        return newFilter;
                      });
                    }}
                  >
                    <SearchOutlinedIcon sx={{ color: "white" }} />
                  </IconButton>
                ) : (
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    startIcon={<SearchOutlinedIcon />}
                    onClick={() => {
                      if (searchKeyword) {
                        setFilters(filter => ({
                          ...filter,
                          keyword: searchKeyword,
                        }));
                        return;
                      }
                      setFilters(filter => {
                        const newFilter = { ...filter };
                        delete newFilter.keyword;
                        return newFilter;
                      });
                    }}
                  >
                    Search
                  </Button>
                )}
              </Grid>
              <Grid item xs={1.5} lg={1.5}>
                {isMobile ? (
                  <IconButton
                    size="small"
                    color="primary"
                    sx={{
                      p: 0.5,
                      borderRadius: "8px",
                      mx: 2,
                    }}
                    onClick={() => {
                      closeToolTips();
                      setIsFiltersOpen(true);
                    }}
                  >
                    <FilterAltOutlinedIcon />
                  </IconButton>
                ) : (
                  <Button
                    fullWidth
                    color="primary"
                    startIcon={<FilterAltOutlinedIcon />}
                    onClick={() => {
                      closeToolTips();
                      setIsFiltersOpen(true);
                    }}
                  >
                    Add Filters
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : null}
          {filteredChips?.length ? (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDirection={isMobile ? "column" : "row"}
              py={1}
              pr={3}
            >
              <Box
                display={"flex"}
                gap={1}
                sx={{
                  ...(isMobile ? { width: "100%" } : { minWidth: "85%" }),
                  flexWrap: isMobile ? "none" : "wrap",
                  overflow: isMobile ? "auto" : "none",
                }}
              >
                {filteredChips}
              </Box>
              <Button
                color="error"
                variant="text"
                sx={{ flex: "auto", minWidth: isMobile ? "none" : "200px" }}
                startIcon={<CloseOutlinedIcon />}
                onClick={() => {
                  setDrawerFilters({});
                  setFilters({
                    ...(searchKeyword ? { keyword: searchKeyword } : {}),
                  });
                }}
              >
                Clear All Filters
              </Button>
            </Box>
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          lg={isFiltersOpen && isDesktop ? false : 12}
          sx={{ ...(isSticky && isMobile ? { mt: "56px" } : {}) }}
        >
          {areTabsActive ? (
            <ExplorePartnersTabScreen
              isTabPanelSticky={isSticky}
              isFetchingCompanies={isFetching}
              showRecommendedPartnersOnly={showRecommendedPartnersOnly}
              companies={data}
              handleTabsChange={handleTabsChange}
              currentTab={currentTab}
              showFirstInviteTooltip={showFirstInviteTooltip}
              setShowTooltip={(value: boolean) => {
                closeToolTips();
              }}
              setFiltersThroughRecommendationTab={
                setFiltersThroughRecommendationTab
              }
            />
          ) : isFetching ||
            isFetchingReferralPartners ||
            isFetchingGoogleCompanies ? (
            <Box py={3} textAlign="center">
              <CircularProgress />
              <Typography variant="body1">
                Hang tight! We&apos;re building your custom list of recommended
                referral partners.
              </Typography>
            </Box>
          ) : (
            <>
              <Grid container p={3}>
                <Grid item xs={12} lg={isFiltersOpen && isDesktop ? false : 12}>
                  <Typography gutterBottom variant="h6">
                    {memoizedOtherCompanies?.length ?? 0} Potential Referral
                    Partnerships Found
                  </Typography>
                </Grid>
                <ExplorePartnersList
                  otherRecommendedCompanies={memoizedOtherCompanies}
                  getConnectionStatus={getConnectionStatus}
                  setCurrentCompanyProfile={setCurrentCompanyProfile}
                  setSelectedCompany={setSelectedCompany}
                  setSelectedTab={setSelectedTab}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {selectedTab && currentInvitation ? (
        <ReferralInvitationDrawer
          open={!!currentInvitation}
          invitation={currentInvitation}
          connectionStatus={getConnectionStatus(
            currentInvitation.otherCompanyProfile
          )}
          defaultTab={selectedTab}
          onClose={() => {
            setCurrentInvitation(null);
            setSelectedTab(null);
          }}
        />
      ) : null}
      {selectedTab && currentCompanyProfile ? (
        <CompanyDrawer
          open={!!currentCompanyProfile}
          companyProfile={currentCompanyProfile}
          connectionStatus={getConnectionStatus(currentCompanyProfile)}
          defaultTab={selectedTab}
          onClose={() => {
            setCurrentCompanyProfile(null);
            setSelectedTab(null);
          }}
        />
      ) : null}
      {selectedCompany ? (
        <ExternalCompanyDrawer
          open={!!selectedCompany}
          companyGoogleProfile={selectedCompany.googleCompanyProfile}
          hasMarkedInterest={!!selectedCompany?.hasMarkedInterest}
          onClose={markedInterest => {
            if (
              markedInterest &&
              selectedCompany.googleCompanyProfile?.googlePlacesId
            ) {
              updateMarkedInterestSet(
                selectedCompany.googleCompanyProfile?.googlePlacesId ?? ""
              );
            }
            setSelectedCompany(null);
          }}
        />
      ) : null}
      {isInvitationDialogOpen && (
        <InvitationDialog
          isOpen={true}
          onClose={() => setIsInvitationDialogOpen(false)}
        />
      )}
      <FilterDrawer
        open={isFiltersOpen}
        drawerFilters={drawerFilters}
        setDrawerFilters={setDrawerFilters}
        applyFilters={applyFilters}
        resetFilters={resetFilters}
        onClose={() => setIsFiltersOpen(false)}
      />
      {isMobile ? null : (
        <Box position="fixed" bottom={"64px"} right={"72px"}>
          <Tooltip
            arrow
            open={showTooltip}
            title={
              <Box display={"flex"} maxWidth={"140px"}>
                <Typography variant="caption" lineHeight={1.5}>
                  Invite your network through email or text message here!
                </Typography>
                <CloseIcon
                  fontSize="small"
                  sx={{
                    p: 0,
                    width: "16px",
                    height: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    closeToolTips();
                  }}
                />
              </Box>
            }
            componentsProps={{
              tooltip: {
                className: "bounce-tooltip", // Apply the animation class here
              },
              arrow: {
                style: { color: "#16b364" }, // Change the arrow color to match the tooltip background
              },
            }}
            placement="top"
          >
            <Fab
              color="primary"
              aria-label="add-invite"
              onClick={() => {
                closeToolTips();
                setIsInvitationDialogOpen(true);
              }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box>
      )}
      {(!brandLocationProfile?.showExplorePartners ||
        !isCompanyActive(company?.status)) &&
      currentTab === TabsEnumPartners.OTHER_RECOMMENDATIONS ? (
        <AccessControlDialog
          isOpen={
            (!brandLocationProfile?.showExplorePartners ||
              !isCompanyActive(company?.status)) &&
            currentTab === TabsEnumPartners.OTHER_RECOMMENDATIONS
          }
          onClose={() => {
            setCurrentTab(TabsEnumPartners.PENDING_INVITATIONS);
          }}
        />
      ) : null}
    </>
  );
}
