/* eslint-disable jsx-a11y/media-has-caption */
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { GoogleCompanyProfileDetails } from "@/common/GoogleCompanyDrawer/GoogleCompanyProfileDetails";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { useGetOpenJobById } from "@/company/jobs/api/useGetOpenJobById";
import { JobStatus } from "@/company/jobs/types";
import { getRecommendedProviderDetails } from "@/company/jobs/utils";
import {
  JobProjectTab,
  Source,
} from "@/homeowner/components/JobDrawer/JobProjectTab";

enum TabsEnum {
  PROJECT = "Project",
  RECOMMENDED_PROVIDER = "Recommended provider",
}

export function JobPublicPage() {
  const { id } = useParams();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [currentTab, setCurrentTab] = useState<TabsEnum>(TabsEnum.PROJECT);
  const handleTabsChange = useCallback(
    (_: ChangeEvent<unknown>, value: TabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  const {
    data: job,
    isFetching: isFetchingJobDetails,
    isError: isErrorFetchingJobDetails,
  } = useGetOpenJobById(id ? id : null, {
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });

  if (isErrorFetchingJobDetails) {
    enqueueSnackbar({
      message:
        "It looks like this job link isn’t valid. Please try a different one.",
      variant: "error",
    });
  }

  if (!id) {
    enqueueSnackbar({
      message:
        "It looks like this job link isn’t valid. Please try a different one.",
      variant: "error",
    });
  }

  const recommendedProviderDetails = job
    ? getRecommendedProviderDetails(job)
    : null;

  return (
    <Grid
      container
      mt={2}
      minHeight={"calc(100vh - 205px)"}
      overflow={"auto"}
      flexDirection={isMobile ? "column" : "row"}
    >
      <Grid item xs={12} px={2}>
        {isErrorFetchingJobDetails || !id ? (
          <Alert
            severity="error"
            sx={{
              color: "#6d331b",
              bgcolor: "#fae5e2",
            }}
          >
            <Typography variant="body2">
              It looks like this job link isn’t valid. Please try a different
              one.
            </Typography>
          </Alert>
        ) : (
          <Alert
            severity="info"
            sx={{
              color: "#00359E",
              bgcolor: "#EBEFFF",
            }}
          >
            <Typography variant="body2">This project is view only.</Typography>
          </Alert>
        )}
        {isMobile && job ? (
          <Typography variant="h6" my={3} textAlign="left">
            {job.jobType}
          </Typography>
        ) : null}
      </Grid>
      {job?.type === JobUploadTypes.TEXT ? null : (
        <Grid item xs={12} lg={6} p={4} pt={2} textAlign={"center"}>
          {isFetchingJobDetails ? (
            <CircularProgress sx={{ my: 5 }} />
          ) : job?.type === JobUploadTypes.AUDIO ? (
            <audio
              controls
              controlsList="nodownload"
              style={{
                width: "100%",
                marginTop: isMobile ? "0" : "48px",
                borderRadius: "10px",
                background: "white",
              }}
            >
              <source src={`${job?.videoUrl}`} type="audio/mp4" />
            </audio>
          ) : job?.type === JobUploadTypes.VIDEO ? (
            <video
              controls
              controlsList="nodownload"
              style={{
                width: "100%",
                maxHeight: isMobile ? "200px" : "560px",
                borderRadius: "10px",
                background: "white",
              }}
            >
              <source src={`${job?.videoUrl}#t=0.1`} type="video/mp4" />
            </video>
          ) : null}
        </Grid>
      )}
      <Grid item xs={12} lg={job?.type === JobUploadTypes.TEXT ? 6 : 6}>
        <Paper
          sx={{
            minHeight: isMobile ? "auto" : "calc(100vh - 276px)",
            overflowY: "auto",
          }}
        >
          <Box
            position={"sticky"}
            sx={{ top: 0, zIndex: 400, pt: 1, background: "white" }}
          >
            {!isMobile ? (
              <Box
                display="flex"
                justifyContent="space-between"
                px={3}
                pb={2}
                pt={3}
              >
                <Typography variant="h6">{job?.jobType}</Typography>
              </Box>
            ) : null}
            <Tabs sx={{ px: 3 }} value={currentTab} onChange={handleTabsChange}>
              <Tab value={TabsEnum.PROJECT} label="Project" />
              {recommendedProviderDetails?.googlePlacesId ? (
                <Tab
                  value={TabsEnum.RECOMMENDED_PROVIDER}
                  label={
                    job?.status === JobStatus.PAID
                      ? "Referral Partner"
                      : "Recommended Provider"
                  }
                />
              ) : null}
            </Tabs>
            <Divider />
          </Box>
          {isFetchingJobDetails ? (
            <Box my={5} textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box py={2} px={3}>
              {job && currentTab === TabsEnum.PROJECT ? (
                <JobProjectTab
                  source={Source.PUBLIC_JOB_PAGE}
                  job={job}
                  openServiceProviderTab={() =>
                    setCurrentTab(TabsEnum.RECOMMENDED_PROVIDER)
                  }
                />
              ) : null}
              {currentTab === TabsEnum.RECOMMENDED_PROVIDER &&
              recommendedProviderDetails?.googlePlacesId ? (
                <GoogleCompanyProfileDetails
                  googlePlacesId={recommendedProviderDetails.googlePlacesId?.toString()}
                  companyName={recommendedProviderDetails.companyName}
                  isVerified={true}
                />
              ) : null}
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
