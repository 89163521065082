import { Card, Typography } from "@mui/material";
import numeral from "numeral";

export interface BalanceCardProps {
  balance: string | number;
}

export function BalanceCard(props: BalanceCardProps) {
  const { balance } = props;

  return (
    <Card
      sx={{
        p: 3,
        width: "100%",
        height: "100%",
        background: "rgba(22, 219, 101, 0.10)",
        backgroundColor: theme => theme.palette.primary.light,
        display: "flex",
        alignContent: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
      }}
      elevation={0}
    >
      <img src="/balance.svg" width="84px" height="66px" alt="balance" />
      <Typography variant="overline" color={"text.secondary"} pt={1} pb={1}>
        Current Credit Balance
      </Typography>
      <Typography variant="h4">{numeral(balance).format("$0,0.00")}</Typography>
    </Card>
  );
}
