import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Box, Paper, styled, Typography } from "@mui/material";

import { CallRecording, CallType } from "@/company/api/useFetchCallRecordings";

const ThinPaper = styled(Paper)(({ theme }) => ({
  width: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

const FindPartner = ({
  selectedRecording,
  referralType,
}: {
  selectedRecording: CallRecording;
  referralType?: "inbound" | "outbound";
}) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Typography variant="overline" color={"text.secondary"}>
        Recommended Partner
      </Typography>
      <Box
        sx={{
          borderRadius: "8px",
          p: 2,
          gap: "4px",
          display: "flex",
          flexDirection: "column",
          border: "1px solid #DCDFE4",
          background: "#FFF",
        }}
      >
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <WarningAmberRoundedIcon
            sx={{
              bgcolor: "#FEE4E2",
              borderRadius: "50%",
              width: "32px",
              height: "32px",
              color: "#F04438",
              p: 0.5,
              pb: 1.2,
            }}
          />
          <Typography variant="subtitle1">No Partner Identified</Typography>
        </Box>
        {referralType ? (
          <Typography variant="body2" color={"text.secondary"}>
            {referralType === "outbound"
              ? "Select an existing partner or find a new partner to refer this job and earn areferral commission."
              : 'Click the "Link Partner" button to choose the referral partner who referred this job and pay a referral fee to show your gratitude.'}
          </Typography>
        ) : (
          <Typography variant="body2" color={"text.secondary"}>
            {selectedRecording?.job?.createdByCompanyId ===
            selectedRecording?.company?.id
              ? selectedRecording?.company?.id &&
                selectedRecording?.type === CallType.INBOUND
                ? "Select an existing partner or find a new partner to refer this job and earn areferral commission."
                : "Link Partner to select an existing partner or find a new partner and earn a referral commission."
              : 'Click the "Add Partner" button to choose the referral partner who referred this job and pay a referral fee to show your gratitude.'}
          </Typography>
        )}

        {/* <Button
          variant="contained"
          size="small"
          sx={{ width: 'fit-content' }}
          onClick={() => {
            handlePartnerClick()
          }}
        >
          {selectedRecording?.job?.createdByCompanyId ===
            selectedRecording?.company?.id && selectedRecording?.type === CallType.INBOUND
            ? 'Find a Partner'
            : 'Link Partner'}
        </Button> */}
      </Box>
    </Box>
  );
};

export default FindPartner;
