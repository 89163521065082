import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Chip } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useArchiveUser } from "@/company/api/useArchiveUser";
import { useDeleteRecommendedPartners } from "@/company/api/useDeleteRecommendedPartners";
import { BasicCompanySchema } from "@/company/api/useGetAllBrands";
import { useUpdateCompanyUser } from "@/company/api/useUpdateCompanyUser";
import { snakeCaseJobSectors } from "@/company/utils";

import DeleteConfirmationRecommendedPartnersDialog from "./DeleteConfirmationRecommendedPartnersDialog";
import {
  CustomToolbar,
  getGridStringOperatorsDataGrid,
  renderJobSectorsArea,
} from "./utils";

export enum ReferralType {
  REFERRAL_INVITATION_SENT = "REFERRAL_INVITATION_SENT",
  REFERRAL_INVITATION_ACCEPTED = "REFERRAL_INVITATION_ACCEPTED",
  AFFILIATE_COMPANIES = "AFFILIATE_COMPANIES",
  RECOMMENDED_PARTNERS = "RECOMMENDED_PARTNERS",
  REFERRALS = "REFERRALS",
}

export default function BasicCompanyTable({
  companies,
  type,
  companyId,
  expanded,
  refetch,
  isFetchingProfile,
}: {
  companies: Array<BasicCompanySchema>;
  companyId: number;
  type: ReferralType;
  expanded?: boolean;
  refetch: () => void;
  isFetchingProfile?: boolean;
}) {
  const [rows, setRows] = useState([]);

  const [deleteRowId, setDeleteRowId] = useState<GridRowId | null | undefined>(
    null
  );
  const [deleteRowName, setDeleteRowName] = useState<string>("");

  const { isLoading: isDeleting, mutateAsync: archiveUser } = useArchiveUser({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while archiving user.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      enqueueSnackbar({
        message: "User archived successfully.",
        variant: "success",
      });
    },
  });

  const {
    mutateAsync: deleteRecommendedPartners,
    isLoading: isDeletingRecommendedPartner,
  } = useDeleteRecommendedPartners({
    onSuccess: () => {
      refetch();
      enqueueSnackbar({
        message: "Archived recommended partners successfully.",
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar({
        message: "Error while archiving recommended partners",
        variant: "error",
      });
    },
  });

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const { mutateAsync: updateUser, isLoading } = useUpdateCompanyUser({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while updating user details.";
      enqueueSnackbar(message, {
        variant: "error",
      });
      refetch();
    },
    onSuccess() {
      enqueueSnackbar({
        message: "Your changes have been saved.",
        variant: "success",
      });
    },
  });

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => {
    deleteRecommendedPartners({ companyId, recommendedPartnerId: id });
    setRows(rows.filter(row => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find(row => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter(row => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    await updateUser({
      companyId,
      userId: updatedRow.id,
      updatedUser: {
        ...newRow,
        firstName: newRow?.name?.split(" ")?.[0],
        lastName: newRow?.name?.split(" ")?.[1],
        oldGroups: oldRow.types,
        newGroups: newRow.types,
      },
    });

    setRows(
      rows.map(row =>
        row.id === newRow.id
          ? {
              ...updatedRow,
              firstName: newRow?.name?.split(" ")?.[0],
              lastName: newRow?.name?.split(" ")?.[1],
            }
          : row
      )
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    setRows(companies);
  }, [companies]);

  let columns: GridColDef[] = [
    {
      field: "logoUrl",
      headerName: "Logo",
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <img
              src={params.row.logoUrl}
              style={{
                width: "48px",
                height: "32px",
                objectFit: "contain",
              }}
              alt="company-logo"
            />
          </Box>
        );
      },
      sortable: false,
    },
    {
      field: "id",
      headerName: "ID",
      renderCell: params => params.row.id?.toString(),

      width: 200,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "sectors",
      headerName: "Service Categories",
      type: "string",
      width: 200,
      renderEditCell: renderJobSectorsArea,
      //valueOptions: Object.keys(reverseSnakeCaseJobSectors),
      renderCell: params => {
        const sectorChips = params.row?.sectors?.map(sector => (
          <Chip
            key={sector}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={snakeCaseJobSectors[sector]}
            size="small"
          />
        ));
        return sectorChips;
      },
    },
    {
      field: "ownersInformationName",
      headerName: "Owner's Name",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[0],
      renderCell: params => params.row?.ownersInformation?.[0]?.[0],
    },
    {
      field: "ownersInformationEmail",
      headerName: "Owner's Email",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[3],
      renderCell: params => params.row?.ownersInformation?.[0]?.[3],
    },
    {
      field: "ownersInformationPhone",
      headerName: "Owner's Phone",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[1],
      renderCell: params => params.row?.ownersInformation?.[0]?.[1],
    },
    {
      field: "ownersInformationPhoneCarrierType",
      headerName: "Owner's Phone Carrier Type",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[2],
      renderCell: params => params.row?.ownersInformation?.[0]?.[2],
    },
    ...(type === ReferralType.RECOMMENDED_PARTNERS
      ? [
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 120,
            cellClassName: "actions",
            getActions: ({ id, row }) => {
              return [
                <GridActionsCellItem
                  key={`${id}-delete`}
                  icon={<DeleteIcon />}
                  label="Delete"
                  color="inherit"
                  onClick={() => {
                    setDeleteRowId(id);
                    setDeleteRowName(row.name);
                  }}
                />,
              ];
            },
          },
        ]
      : []),
  ];

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  return (
    <Box display={"flex"} width={"100%"}>
      <Box
        sx={{
          height: 400,
          position: "relative",
          width: "100%",
        }}
      >
        <DataGridPremium
          checkboxSelection
          disableRowSelectionOnClick
          pagination
          columns={columns}
          sx={{
            "& .MuiDataGrid-row": {
              "--rowBorderColor": "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withRightBorder": {
              borderRightColor: "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withLeftBorder": {
              borderLeftColor: "#D9D9D9",
            },
          }}
          rows={rows ?? []}
          slots={{
            ...(expanded ? { toolbar: () => <CustomToolbar /> } : {}),
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          loading={isFetchingProfile}
          processRowUpdate={processRowUpdate}
          initialState={{
            density: "compact",
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "logoUrl", "name"],
              right: ["actions"],
            },
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          slotProps={{
            toolbar: { setRowModesModel },
          }}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
        />
      </Box>
      <DeleteConfirmationRecommendedPartnersDialog
        open={!!deleteRowId}
        companyName={deleteRowName}
        handleClose={() => setDeleteRowId(null)}
        handleConfirm={() => {
          handleDeleteClick(deleteRowId);
          setDeleteRowId(null);
          setDeleteRowName("");
        }}
      />
    </Box>
  );
}
