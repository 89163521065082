/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react";
import axios, { InternalAxiosRequestConfig } from "axios";
import changeCaseKeys from "change-case-keys";

import { getAccessToken, getRefreshToken } from "@/auth/utils";
import { LEAD_DATA_STORAGE_KEY } from "@/lead/context/lead-context";

import { AppConstants } from "./constants";
import { removeToken, setToken } from "./toeknUtils";

function serializeParams(params: Array<string>) {
  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}

export const apiRequestHandler = async (
  config: InternalAxiosRequestConfig<any>
) => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return config;
  }
  const authHeader = `Bearer ${accessToken.token}`;
  config.headers.Authorization = authHeader;
  return {
    ...config,
    headers: config.headers,
  };
};

export const apiErrorHandler = async (
  error: any
): Promise<never | undefined> => {
  if (error.response) {
    if (error.response.status) {
      if (
        error.response.status === 403 ||
        (error.response.status >= 500 && error.response.status < 600)
      ) {
        Sentry.captureException(error);
      }
      if (error.response.status === 401) {
        const path = window.location.pathname + window.location.search;
        if (!error.config._retry) {
          error.config._retry = true;
          try {
            const refreshToken = getRefreshToken();
            if (refreshToken) {
              const authHeader = `Bearer ${refreshToken.token}`;
              const response = await axios.post(
                `${AppConstants.apiBaseUrl}/auth/refresh-tokens`,
                undefined,
                {
                  headers: {
                    authentication: authHeader,
                  },
                }
              );
              const newAccessToken = response.data.data.access;
              setToken(JSON.stringify({ access: newAccessToken }));

              // Retry the original request with the new access token
              return axiosInstance(error.config);
            } else {
              removeToken();
              localStorage.removeItem(LEAD_DATA_STORAGE_KEY);

              if (path.includes("auth/login")) {
                return;
              }
              window.location.href = `/auth/login?redirectTo=${
                path ? encodeURIComponent(path) : ""
              }`;
              // Error refreshing token, redirect to login
              return Promise.reject({
                unauthenticated: true,
                message: "You need to login to continue.",
              });
            }
          } catch (refreshError) {
            removeToken();
            localStorage.removeItem(LEAD_DATA_STORAGE_KEY);

            if (path.includes("auth/login")) {
              return;
            }
            window.location.href = `/auth/login?redirectTo=${
              path ? encodeURIComponent(path) : ""
            }`;
            // Error refreshing token, redirect to login
            Promise.reject({
              unauthenticated: true,
              message: "You need to login to continue.",
            });
          }
          return;
        }
        removeToken();
        localStorage.removeItem(LEAD_DATA_STORAGE_KEY);

        if (path.includes("auth/login")) {
          return;
        }
        window.location.href = `/auth/login?redirectTo=${
          path ? encodeURIComponent(path) : ""
        }`;
        return Promise.reject({
          unauthenticated: true,
          message: "You need to login to continue.",
        });
      }
      if (error.response.status === 404) {
        return Promise.reject({
          notFound: true,
          message:
            error.response?.data?.message ??
            "The requested resource was not found",
        });
      }
      if (error.response.status === 403) {
        return Promise.reject({
          message: "You do not have enough permission to complete the request.",
          unauthorized: true,
        });
      }
    }
    return Promise.reject(error.response.data);
  }

  if (error.request) {
    // Handles the case where the request fails before it get's to the server.
    const { status, statusText, responseURL } = error.request;
    return Promise.reject({
      requestFailed: true,
      message: `Network Error`,
    });
  }

  // Handles the case where the request is cancelled (Eg: maybe in the cleanup function of a useEffect)
  if (!axios.isCancel(error)) return;
};

const axiosInstance = axios.create({
  baseURL: AppConstants.apiBaseUrl,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(apiRequestHandler);

axiosInstance.interceptors.response.use(function (response) {
  const data = response.data;
  response.data = changeCaseKeys(data, "camelize");

  return response;
}, apiErrorHandler);

export default axiosInstance;
