import Cookies from "js-cookie";

import { AUTH_STORAGE_KEY } from "@/auth/constants";

// Check if cookies are enabled
function areCookiesEnabled() {
  try {
    Cookies.set("test_cookie", "test", { expires: 1 });
    const isCookieSet = Cookies.get("test_cookie") === "test";
    Cookies.remove("test_cookie");
    return isCookieSet;
  } catch (e) {
    return false;
  }
}

export const setToken = (value: string) => {
  if (areCookiesEnabled()) {
    Cookies.set(AUTH_STORAGE_KEY, value, { expires: 45 });
  } else {
    localStorage.setItem(AUTH_STORAGE_KEY, value);
  }
};

// Get a value (cookie or localStorage)
export const getToken = () => {
  if (areCookiesEnabled()) {
    return Cookies.get(AUTH_STORAGE_KEY);
  } else {
    return localStorage.getItem(AUTH_STORAGE_KEY);
  }
};

// Remove a value (cookie or localStorage)
export const removeToken = () => {
  if (areCookiesEnabled()) {
    Cookies.remove(AUTH_STORAGE_KEY);
  } else {
    localStorage.removeItem(AUTH_STORAGE_KEY);
  }
};
