import { Box } from "@mui/material";
import { FC } from "react";
import QRCode from "react-qr-code";

import { AppConstants } from "@/common/constants";

export const ContactInvite: FC<{ jobId: string | undefined }> = ({ jobId }) => {
  const link = `${AppConstants.platformBaseUrl}/open/job/${jobId}`;

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      p={4}
      gap={2}
      sx={{ height: "356.50px" }}
    >
      <QRCode
        style={{ height: "200px", maxWidth: "100%", width: "100%" }}
        value={link}
      />
    </Box>
  );
};
