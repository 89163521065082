import { getToken } from "@/common/toeknUtils";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyRoutes, DefaultCompanyGroups } from "@/company/types";
import { HomeownerRoutes } from "@/homeowner/constants";

import { AuthRoutes } from "../constants";
import { BaseJWTToken, Session, TokenDetails, USER_TYPE } from "../types";

export enum Issuer {
  Auth0 = "Auth0",
  Firebase = "Firebase",
  JWT = "JWT",
  Amplify = "Amplify",
}

export function getAccessToken(): BaseJWTToken | undefined {
  const tokenDetails = getToken();
  if (tokenDetails) {
    const tokenObj: TokenDetails = JSON.parse(decodeURIComponent(tokenDetails));
    return tokenObj.access;
  }
}

export function getRefreshToken(): BaseJWTToken | undefined {
  const tokenDetails = getToken();
  if (tokenDetails) {
    const tokenObj: TokenDetails = JSON.parse(decodeURIComponent(tokenDetails));
    return tokenObj.refresh;
  }
}

export function getDefaultLoginRedirectTo(session: Session | null): string {
  if (!session) {
    return getNavigationPathFromRoutePaths([AuthRoutes.BASE, AuthRoutes.LOGIN]);
  }
  if (!session.user?.type) {
    return getNavigationPathFromRoutePaths([
      AuthRoutes.BASE,
      AuthRoutes.USER_TYPE,
    ]);
  }
  if (session.user?.type === USER_TYPE.HOMEOWNER) {
    return getNavigationPathFromRoutePaths([
      HomeownerRoutes.BASE,
      HomeownerRoutes.JOBS_LIST,
    ]);
  }
  // TODO: Update this logic based on company.account_type and other groups and permissions the user has
  const groupNames = session.groups?.map(group => group.name) ?? [];
  if (groupNames?.includes(DefaultCompanyGroups.FIELD_TEAM)) {
    return getNavigationPathFromRoutePaths([
      CompanyRoutes.BASE,
      CompanyRoutes.TECHNICIAN_DEMO,
    ]);
  }

  if (
    groupNames?.includes(DefaultCompanyGroups.JOB_APPROVER) ||
    groupNames?.includes(DefaultCompanyGroups.TEAM_MEMBER)
  ) {
    return getNavigationPathFromRoutePaths([
      CompanyRoutes.BASE,
      CompanyRoutes.ALL_JOBS,
    ]);
  }
  return getNavigationPathFromRoutePaths([
    CompanyRoutes.BASE,
    CompanyRoutes.DASHBOARD_HOME,
  ]);
}
