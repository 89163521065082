import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { SearchedCompany } from "./useExploreConnections";

export const USE_GET_REFERRAL_INTERESTS_URI = "/referral-interests";

export function useGetReferralInterests(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<SearchedCompany>> {
  return useQuery({
    queryKey: [USE_GET_REFERRAL_INTERESTS_URI],
    queryFn: async () => {
      const resp = await API.get(USE_GET_REFERRAL_INTERESTS_URI);
      const responseData = resp.data.data;
      const result = responseData
        .filter(company => {
          if (company.googleCompany && !company.googleCompany.name) {
            return false;
          }
          return true;
        })
        .map(data => ({
          googleCompanyProfile: data.googleCompany,
          companyPublicProfile: data.tradeEngageCompany,
          hasMarkedInterest: data.hasMarkedInterest,
        }));
      return result;
    },
    ...options,
  });
}
