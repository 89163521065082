/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Add as AddIcon } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Fab,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { useBackdropContext } from "@/common/backdrop-context";
import { InitialWhatsappMessage } from "@/common/constants/AppConstants";
import CreateTechnicianJobDialogWrapper from "@/common/CreateJobDialog/CreateTechnicianJobDialogWrapper";
import { useJobUploadContext } from "@/common/job-upload-context";
import { Seo } from "@/common/Seo/Seo";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { isCompanyActive } from "@/common/utils/accessControl";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyPaymentDialog } from "@/company/components/company-payments-tab/CompanyPaymentDialog";
import { useCompanyPaymentsContext } from "@/company/context/payments-context";
import { CompanyRoutes } from "@/company/types";
import { FEATURE_FLAGS } from "@/featureFlags";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { snakeCaseToTitleCase } from "@/utils";

import { useGetJobById } from "../api";
import { useGetJobRoutingById } from "../api/useGetJobRoutingById";
import { useGetRoutedJobsByCompany } from "../api/useGetRoutedJobsByCompany";
import { useGetSentJobsByCompany } from "../api/useGetSentJobs";
import { useUpdateRoutedJobStatus } from "../api/useUpdateRoutedJobStatus";
import { AccessControlJobDialog } from "../components/AccessControlJobDialog";
import { DeclineJobDialog } from "../components/DeclineJobDialog";
import { JobCompletionPaymentDialog } from "../components/JobCompletionPaymentDialog";
import { JobDrawer, JobDrawerTabsEnum } from "../components/JobDrawer";
import { JobRoutedFilterDrawer } from "../components/JobRoutedFilterDrawer";
import { JobSentCard } from "../components/JobSentCard";
import { JobSentFilterDrawer } from "../components/JobSentFilterDrawer";
import { MarkInactiveJobDialog } from "../components/MarkInactiveJobDialog";
import { RoutedJobCard } from "../components/RoutedJobCard";
import { SubtitleTextReferrals } from "../components/SubtitleTextReferrals";
import {
  Job,
  JobDashboardTabs,
  JobSource,
  JobStatus,
  ReceivedReferralsChips,
  RoutedJobFilters,
  RoutedJobResponse,
  RoutedJobStatus,
  SentJobFilters,
  SentReferralsChips,
} from "../types/job";
import {
  getLastActivityJob,
  getLastActivityRoutedJob,
  JobStatusFromRoutedJobsChip,
  JobStatusFromSentJobsChip,
  SentJobStatusChipLabel,
  SentJobStatusFilterChipLabel,
} from "../utils";

export function JobDashboardPage() {
  const { id, tab, jobType } = useParams();
  const { session } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;

  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const [onApproveJobByInactiveCompany, setOnApproveJobByInactiveCompany] =
    useState(false);

  const [currentReferralReceivedChip, setCurrentReferralReceivedChip] =
    useState<ReceivedReferralsChips>(ReceivedReferralsChips.PENDING_REVIEWS);

  const [declineJobDialogOpen, setDeclineJobDialogOpen] = useState(false);
  const [jobCompletionDialogOpen, setJobCompletionDialogOpen] = useState(false);
  const [markInactiveDialogOpen, setMarkInactiveDialogOpen] = useState(false);

  const [currentReferralSentChip, setCurrentReferralSentChip] =
    useState<SentReferralsChips>(SentReferralsChips.PENDING_HOMEOWNER);

  const [currentTab, setCurrentTab] = useState<JobDashboardTabs>(
    JobDashboardTabs.REFERRALS_RECEIVED
  );

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: JobDashboardTabs): void => {
      setCurrentTab(value);
    },
    []
  );

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const navigate = useNavigate();

  const applyJobSentFilters = (filters: SentJobFilters) => {
    setSentJobsFilter({ ...sentJobsFilters, ...filters });
    setFilterDrawerOpen(false);
  };
  const applyJobReceivedFilters = (filters: RoutedJobFilters) => {
    setReceivedJobsFilter({ ...routedJobsFilters, ...filters });
    setFilterDrawerOpen(false);
  };

  const resetJobSentFilters = () => {
    setSentJobsFilter({});
    setFilterDrawerOpen(false);
  };
  const resetJobReceivedFilters = () => {
    setReceivedJobsFilter({});
    setFilterDrawerOpen(false);
  };
  const [drawerOpenedByParams, setDrawerOpenedByParams] = useState(false);
  const { backDropOn, setBackDropOn } = useBackdropContext();

  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);
  const { setJobUploadOpen } = useJobUploadContext();

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const [sentJobsFilters, setSentJobsFilter] = useState<SentJobFilters>({});
  const [routedJobsFilters, setReceivedJobsFilter] = useState<RoutedJobFilters>(
    {}
  );

  const getFilterChips = () => {
    const chips = [] as Array<ReactElement>;
    if (currentTab === JobDashboardTabs.REFERRALS_RECEIVED) {
      Object.keys(routedJobsFilters).map(filter => {
        if (filter === "sectors") {
          routedJobsFilters?.sectors?.map(sector =>
            chips.push(
              <Chip
                key={sector}
                label={sector}
                sx={{ mr: 1 }}
                color="primary"
                variant="outlined"
                size="small"
                onDelete={() => {
                  const newSectors = routedJobsFilters.sectors?.filter(
                    drawerSector => drawerSector !== sector
                  );
                  setReceivedJobsFilter({
                    ...routedJobsFilters,
                    sectors: newSectors,
                  });
                }}
              />
            )
          );
        } else if (filter === "dateRange") {
          chips.push(
            <Chip
              key={routedJobsFilters[filter]?.startDate?.toLocaleString()}
              label={`${routedJobsFilters[
                filter
              ]?.startDate?.toLocaleString()}-${routedJobsFilters[
                filter
              ]?.endDate?.toLocaleString()}`}
              color="primary"
              variant="outlined"
              sx={{ mr: 1 }}
              size="small"
              onDelete={() => {
                setReceivedJobsFilter({
                  ...routedJobsFilters,
                  [filter]: undefined,
                });
              }}
            />
          );
        } else {
          if (routedJobsFilters[filter]) {
            chips.push(
              <Chip
                key={snakeCaseToTitleCase(routedJobsFilters[filter])}
                label={
                  routedJobsFilters[filter] === "PENDING"
                    ? `Pending Review`
                    : routedJobsFilters[filter] === "PAID" ||
                      routedJobsFilters[filter] === "ACCEPTED"
                    ? `Paid`
                    : snakeCaseToTitleCase(routedJobsFilters[filter])
                }
                color="primary"
                variant="outlined"
                sx={{ mr: 1 }}
                size="small"
                onDelete={() => {
                  setReceivedJobsFilter({
                    ...routedJobsFilters,
                    [filter]: undefined,
                  });
                }}
              />
            );
          }
        }
      });
      return chips;
    }
    if (currentTab === JobDashboardTabs.REFERRALS_SENT) {
      Object.keys(sentJobsFilters).map(filter => {
        if (filter === "sectors") {
          sentJobsFilters?.sectors?.map(sector =>
            chips.push(
              <Chip
                key={sector}
                label={sector}
                sx={{ mr: 1 }}
                color="primary"
                variant="outlined"
                size="small"
                onDelete={() => {
                  const newSectors = sentJobsFilters.sectors?.filter(
                    drawerSector => drawerSector !== sector
                  );
                  setSentJobsFilter({
                    ...sentJobsFilters,
                    sectors: newSectors,
                  });
                }}
              />
            )
          );
        } else {
          if (sentJobsFilters[filter]) {
            chips.push(
              <Chip
                key={
                  filter === "dateRange"
                    ? sentJobsFilters[filter]?.startDate?.toLocaleString()
                    : snakeCaseToTitleCase(
                        SentJobStatusFilterChipLabel[sentJobsFilters[filter]]
                      )
                }
                label={
                  filter === "dateRange"
                    ? `${sentJobsFilters[
                        filter
                      ]?.startDate?.toLocaleString()}-${sentJobsFilters[
                        filter
                      ]?.endDate?.toLocaleString()}`
                    : snakeCaseToTitleCase(
                        SentJobStatusFilterChipLabel[sentJobsFilters[filter]]
                      )
                }
                color="primary"
                variant="outlined"
                sx={{ mr: 1 }}
                size="small"
                onDelete={() => {
                  setSentJobsFilter({
                    ...sentJobsFilters,
                    [filter]: undefined,
                  });
                }}
              />
            );
          }
        }
      });
      return chips;
    }
    return chips;
  };

  const { refetchTransactions, refetchCard } = useCompanyPaymentsContext();

  const { mutateAsync: updateRoutedJob, isLoading: updatingRoutedJob } =
    useUpdateRoutedJobStatus({
      onSuccess: routedJob => {
        if (
          routedJob.status === RoutedJobStatus.PAID ||
          routedJob.status === RoutedJobStatus.PENDING_PAYMENT
        ) {
          enqueueSnackbar({
            message: `${
              routedJob.status === RoutedJobStatus.PAID ? "Paid!" : "Approved!"
            }`,
            variant: "success",
          });
          refetchCard();
          refetchTransactions();
        }
        setCurrentSelectedJob(routedJob);
        refetchRoutedJobs();
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating job status.";
        if (
          error?.code === "not_enough_balance" ||
          error?.code === "not_enough_credits"
        )
          setPaymentDialogOpen(true);
        enqueueSnackbar(
          `${
            error?.code === "not_enough_balance" ||
            error?.code === "not_enough_credits"
              ? "Insufficient funds."
              : message
          }`,
          {
            ...(error?.code === "not_enough_balance" ||
            error?.code === "not_enough_credits"
              ? {
                  subText: (
                    <>
                      Please add TradeEngage credits or update your card to
                      approve this job. Questions?{" "}
                      <a
                        role="button"
                        style={{
                          textDecorationLine: "underline",
                          color: "#7A271A",
                          cursor: "pointer",
                        }}
                        onClick={event => {
                          event.preventDefault();
                          setContactSupportModalOpen(true);
                        }}
                      >
                        Contact support
                      </a>
                      .
                    </>
                  ),
                }
              : {}),
            variant: "error",
            autoHideDuration: 10000,
          }
        );
      },
    });

  /**
   * Function to get filter chips when filter is applied using filter drawer
   */
  const filteredChips = getFilterChips();

  /**
   * Function to get request param filters for routed jobs to the company
   */
  const getRoutedJobRequestFilter = () => {
    if (filteredChips?.length) {
      return {
        ...routedJobsFilters,
        status: [
          ...(routedJobsFilters.status ? [routedJobsFilters.status] : []),
        ],
      };
    }
    return {
      status: JobStatusFromRoutedJobsChip[currentReferralReceivedChip],
    };
  };

  /**
   * Function to get request param filters for jobs referred by company
   */
  const getSentJobRequestFilter = () => {
    if (filteredChips?.length) {
      return {
        ...sentJobsFilters,
        status: [...(sentJobsFilters.status ? [sentJobsFilters.status] : [])],
      };
    }
    return {
      status: JobStatusFromSentJobsChip[currentReferralSentChip],
    };
  };

  /**
   * Function to fetch routed jobs to the company
   */
  const flags = useFlags();

  const {
    data: routedJobs,
    isFetching: isLoadingRoutedJobs,
    refetch: refetchRoutedJobs,
  } = useGetRoutedJobsByCompany(
    getRoutedJobRequestFilter(),
    {
      refetchOnWindowFocus: false,
    },
    flags[FEATURE_FLAGS.JOBS_JOB_ROUTINGS_V2_ENDPOINT]
  );
  /**
   * Function to fetch jobs referred by the company
   */
  const { data: sentJobs, isLoading: isLoadingSentJobs } =
    useGetSentJobsByCompany(
      getSentJobRequestFilter(),
      {
        refetchInterval: 3000,
      },
      flags[FEATURE_FLAGS.JOBS_JOB_ROUTINGS_V2_ENDPOINT]
    );

  const { data: routedJobById } = useGetJobRoutingById(id, {
    enabled: !!(id && jobType === "routed-job"),
    refetchOnWindowFocus: false,
  });

  const { data: jobById } = useGetJobById(id, {
    enabled: !!(id && jobType === "job"),
    refetchOnWindowFocus: false,
  });

  const [currentSelectedJob, setCurrentSelectedJob] = useState<
    Job | RoutedJobResponse
  >();

  const [defaultJobDrawerTab, setDefaultJobDrawerTab] =
    useState<JobDrawerTabsEnum>(JobDrawerTabsEnum.PROJECT);

  const TabsBar = (
    <Grid item xs={12} lg={isDesktop ? false : 12}>
      <Tabs value={currentTab} onChange={handleTabsChange}>
        <Tab
          value={JobDashboardTabs.REFERRALS_RECEIVED}
          label={`Referrals Received`}
        />
        <Tab value={JobDashboardTabs.REFERRALS_SENT} label={`Referrals Sent`} />
      </Tabs>
      <Divider />
    </Grid>
  );

  const currentRoutedJobs = routedJobs?.filter(({ expiresAt, status }) => {
    const currentDate = DateTime.utc();
    const expiresAtUTC = DateTime.fromISO(expiresAt.toString(), {
      zone: "utc",
    });
    const millisecondsDiff = expiresAtUTC.diff(
      currentDate,
      "milliseconds"
    ).milliseconds;
    const alreadyExpired =
      status === RoutedJobStatus.PENDING_REVIEW && millisecondsDiff < 0;
    if (
      currentReferralReceivedChip === ReceivedReferralsChips.PENDING_REVIEWS
    ) {
      return !alreadyExpired;
    }
    if (
      currentReferralReceivedChip === ReceivedReferralsChips.PENDING_PAYMENT
    ) {
      return status === RoutedJobStatus.PENDING_PAYMENT;
    }

    return status === RoutedJobStatus.PENDING_REVIEW
      ? millisecondsDiff > 0
      : true;
  });

  useEffect(() => {
    if (drawerOpenedByParams) return;
    if (routedJobById || jobById) {
      if (tab) {
        setDefaultJobDrawerTab(tab as JobDrawerTabsEnum);
      }
      if (jobById) {
        setCurrentTab(JobDashboardTabs.REFERRALS_SENT);
      } else {
        setCurrentTab(JobDashboardTabs.REFERRALS_RECEIVED);
      }
      setDrawerOpenedByParams(true);
      setCurrentSelectedJob(routedJobById || jobById);
    }
  }, [routedJobById, tab, jobById, drawerOpenedByParams]);

  const filteredSentJobs = sentJobs?.filter(job =>
    Object.keys(SentJobStatusChipLabel).includes(job.status)
  );

  return (
    <>
      <Seo title="Jobs Dashboard" />
      <Container
        disableGutters
        maxWidth="100%"
        sx={{
          minHeight: "calc(100vh - 60px)",
          pt: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          sx={{
            top: "56px",
            zIndex: backDropOn ? 0 : 400,
            pt: 1,
          }}
          position={"sticky"}
          bgcolor={"white"}
        >
          {" "}
          {isMobile ? null : (
            <Grid item xs={8} lg={10}>
              <Typography variant="h5">Jobs Dashboard</Typography>
            </Grid>
          )}
          <Grid
            item
            xs={isMobile ? 12 : 4}
            lg={2}
            display="flex"
            {...(isMobile ? { justifyContent: "start" } : {})}
          >
            <Button
              color="primary"
              size={isMobile ? "small" : "medium"}
              startIcon={<FilterAltOutlinedIcon />}
              sx={{
                ...(isMobile ? { p: 0, pt: 0.3 } : {}),
                verticalAlign: "middle",
              }}
              onClick={() => setFilterDrawerOpen(true)}
            >
              Add Filters
            </Button>
          </Grid>
          {filteredChips?.length ? (
            <Grid item xs={12} lg={12}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={isMobile ? "column" : "row"}
                alignItems={"center"}
                py={1}
                pr={3}
              >
                <Box
                  display={"flex"}
                  flexWrap={"wrap"}
                  gap={1}
                  sx={{
                    ...(isMobile ? { width: "100%" } : { width: "100%" }),
                    flexWrap: isMobile ? "none" : "wrap",
                    overflow: isMobile ? "auto" : "none",
                  }}
                >
                  {filteredChips}
                </Box>
                <Button
                  color="error"
                  variant="text"
                  sx={{ flex: "auto", minWidth: isMobile ? "none" : "200px" }}
                  startIcon={<CloseOutlinedIcon />}
                  onClick={() => {
                    setSentJobsFilter({});
                    setReceivedJobsFilter({});
                  }}
                >
                  Clear All Filters
                </Button>
              </Box>
              <Typography fontWeight={600}>
                {currentTab === JobDashboardTabs.REFERRALS_RECEIVED
                  ? routedJobs?.length
                  : filteredSentJobs?.length}{" "}
                Results found
              </Typography>
            </Grid>
          ) : (
            <>
              {TabsBar}
              <Grid item xs={12} lg={isDesktop ? false : 12} py={2}>
                {currentTab === JobDashboardTabs.REFERRALS_RECEIVED ? (
                  <Box>
                    <Box display="flex" pb={1} gap={1}>
                      <Chip
                        label={`Pending Review`}
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(currentReferralReceivedChip ===
                        ReceivedReferralsChips.PENDING_REVIEWS
                          ? { color: "primary", variant: "filled" }
                          : { variant: "outlined" })}
                        onClick={() => {
                          setCurrentReferralReceivedChip(
                            ReceivedReferralsChips.PENDING_REVIEWS
                          );
                        }}
                      />
                      <Chip
                        label={`Pending Payment`}
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(currentReferralReceivedChip ===
                        ReceivedReferralsChips.PENDING_PAYMENT
                          ? { color: "primary", variant: "filled" }
                          : { variant: "outlined" })}
                        onClick={() => {
                          setCurrentReferralReceivedChip(
                            ReceivedReferralsChips.PENDING_PAYMENT
                          );
                        }}
                      />
                      <Chip
                        label={`All History`}
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(currentReferralReceivedChip ===
                        ReceivedReferralsChips.ALL_HISTORY
                          ? { color: "primary", variant: "filled" }
                          : { variant: "outlined" })}
                        onClick={() => {
                          setCurrentReferralReceivedChip(
                            ReceivedReferralsChips.ALL_HISTORY
                          );
                        }}
                      />
                    </Box>
                    <SubtitleTextReferrals
                      jobChipType={currentReferralReceivedChip}
                    />
                  </Box>
                ) : null}
                {currentTab === JobDashboardTabs.REFERRALS_SENT ? (
                  <Box>
                    <Box display="flex" flexWrap={"wrap"} pb={1} gap={1}>
                      <Chip
                        label={`Pending Homeowner`}
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(currentReferralSentChip ===
                        SentReferralsChips.PENDING_HOMEOWNER
                          ? { color: "primary", variant: "filled" }
                          : { variant: "outlined" })}
                        onClick={() => {
                          setCurrentReferralSentChip(
                            SentReferralsChips.PENDING_HOMEOWNER
                          );
                        }}
                      />
                      <Chip
                        label={`Pending Partner`}
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(currentReferralSentChip ===
                        SentReferralsChips.PENDING_PARTNER
                          ? { color: "primary", variant: "filled" }
                          : { variant: "outlined" })}
                        onClick={() => {
                          setCurrentReferralSentChip(
                            SentReferralsChips.PENDING_PARTNER
                          );
                        }}
                      />
                      <Chip
                        label={`Earned`}
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(currentReferralSentChip ===
                        SentReferralsChips.EARNED
                          ? { color: "primary", variant: "filled" }
                          : { variant: "outlined" })}
                        onClick={() => {
                          setCurrentReferralSentChip(SentReferralsChips.EARNED);
                        }}
                      />
                      <Chip
                        label={`All History`}
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(currentReferralSentChip ===
                        SentReferralsChips.ALL_HISTORY
                          ? { color: "primary", variant: "filled" }
                          : { variant: "outlined" })}
                        onClick={() => {
                          setCurrentReferralSentChip(
                            SentReferralsChips.ALL_HISTORY
                          );
                        }}
                      />
                    </Box>
                    <SubtitleTextReferrals
                      jobChipType={currentReferralSentChip}
                      {...(currentReferralSentChip === SentReferralsChips.EARNED
                        ? {
                            totalEarning:
                              sentJobs?.reduce(
                                (sum, job) => sum + (job?.jobBuyingCost ?? 0),
                                0
                              ) ?? 0,
                          }
                        : {})}
                    />
                  </Box>
                ) : null}
              </Grid>
            </>
          )}
        </Grid>
        {currentTab === JobDashboardTabs.REFERRALS_RECEIVED &&
        !currentRoutedJobs?.length &&
        !isLoadingRoutedJobs ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={2}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                Give to Get!
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                You don’t have any jobs pending review. Upload a video to refer
                a job and boost your chances of getting one in return.
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                if (isMobile) {
                  setJobUploadOpen(true);
                  return;
                }
                setIsJobDialogOpen(true);
              }}
            >
              Refer a Job
            </Button>
          </Box>
        ) : null}
        {currentTab === JobDashboardTabs.REFERRALS_SENT &&
        !filteredSentJobs?.length &&
        !isLoadingSentJobs ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={2}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                Give to Get!
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                {currentReferralSentChip === SentReferralsChips.EARNED
                  ? "You haven't earned referral commission yet. Upload a video to refer a job today."
                  : "You don’t have any jobs pending review. Upload a video to refer a job today."}
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                if (isMobile) {
                  setJobUploadOpen(true);
                  return;
                }
                setIsJobDialogOpen(true);
              }}
            >
              Refer a Job
            </Button>
          </Box>
        ) : null}
        <Box my={2} width={"100%"}>
          {isLoadingRoutedJobs || isLoadingSentJobs ? (
            <Box py={5} width={"100%"} textAlign="center">
              <CircularProgress />
            </Box>
          ) : currentTab === JobDashboardTabs.REFERRALS_RECEIVED &&
            currentRoutedJobs?.length ? (
            <Box
              display={"flex"}
              flexDirection={"column"}
              p={3}
              gap={2}
              sx={{ bgcolor: "#F8F9FA" }}
            >
              {currentRoutedJobs?.map(job => (
                <RoutedJobCard
                  key={job.id}
                  job={job}
                  changeTab={(tab?: JobDrawerTabsEnum) => {
                    if (tab) {
                      setDefaultJobDrawerTab(tab);
                      setCurrentSelectedJob(job);
                    }
                  }}
                  onClick={() => {
                    setCurrentSelectedJob(job);
                  }}
                />
              ))}
            </Box>
          ) : null}
          {currentTab === JobDashboardTabs.REFERRALS_SENT &&
          filteredSentJobs?.length ? (
            <Box
              display={"flex"}
              flexDirection={"column"}
              p={3}
              gap={2}
              sx={{ bgcolor: "#F8F9FA" }}
            >
              {filteredSentJobs.map(job => (
                <JobSentCard
                  key={`${job.id}${job.status}`}
                  job={job}
                  onClick={() => {
                    if (job.status === JobStatus.SUBMISSION) {
                      enqueueSnackbar({
                        message: `Your video is processing. ${
                          job.source !== JobSource.IN_APP
                            ? "You will receive a whatsapp message when your video is ready. Questions? Contact support."
                            : ""
                        }`,
                        variant: "warning",
                      });
                    } else if (
                      job.status === JobStatus.OPPORTUNITY &&
                      job.source !== JobSource.IN_APP
                    ) {
                      window.location.href = InitialWhatsappMessage;
                    } else {
                      setCurrentSelectedJob(job);
                    }
                  }}
                />
              ))}
            </Box>
          ) : null}
        </Box>
      </Container>
      {filterDrawerOpen && currentTab === JobDashboardTabs.REFERRALS_SENT ? (
        <JobSentFilterDrawer
          open={
            filterDrawerOpen && currentTab === JobDashboardTabs.REFERRALS_SENT
          }
          applyFilters={applyJobSentFilters}
          resetFilters={resetJobSentFilters}
          drawerFilters={sentJobsFilters}
          onClose={() => {
            setFilterDrawerOpen(false);
          }}
        />
      ) : null}
      {filterDrawerOpen &&
      currentTab === JobDashboardTabs.REFERRALS_RECEIVED ? (
        <JobRoutedFilterDrawer
          open={
            filterDrawerOpen &&
            currentTab === JobDashboardTabs.REFERRALS_RECEIVED
          }
          applyFilters={applyJobReceivedFilters}
          resetFilters={resetJobReceivedFilters}
          drawerFilters={routedJobsFilters}
          onClose={() => {
            setFilterDrawerOpen(false);
          }}
        />
      ) : null}
      {currentSelectedJob &&
      currentTab === JobDashboardTabs.REFERRALS_RECEIVED ? (
        <JobDrawer
          open={!!currentSelectedJob}
          defaultTab={defaultJobDrawerTab}
          jobId={(currentSelectedJob as RoutedJobResponse).jobId}
          inactiveReason={
            (currentSelectedJob as RoutedJobResponse).inactiveReason ||
            (currentSelectedJob as RoutedJobResponse).declinedReason
          }
          isRoutedJob={true}
          jobStatus={currentSelectedJob?.status}
          lastActivity={getLastActivityRoutedJob(
            currentSelectedJob as RoutedJobResponse
          )}
          {...(currentSelectedJob?.status === RoutedJobStatus.PENDING_REVIEW
            ? {
                actionArea: (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      disabled={updatingRoutedJob}
                      onClick={() => {
                        if (!isCompanyActive(company?.status)) {
                          setOnApproveJobByInactiveCompany(true);
                          return;
                        }
                        updateRoutedJob({
                          jobId: currentSelectedJob.id,
                          data: {
                            status:
                              brandLocationProfile?.payReferralFeeAfterJobIsDone
                                ? RoutedJobStatus.PENDING_PAYMENT.toString()
                                : RoutedJobStatus.PAID.toString(),
                          },
                        });
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      size="small"
                      color="error"
                      variant="outlined"
                      disabled={updatingRoutedJob}
                      onClick={() => {
                        if (!isCompanyActive(company?.status)) {
                          setOnApproveJobByInactiveCompany(true);
                          return;
                        }
                        setDeclineJobDialogOpen(true);
                      }}
                    >
                      Decline
                    </Button>
                  </>
                ),
              }
            : {})}
          {...(currentSelectedJob?.status === RoutedJobStatus.PENDING_PAYMENT
            ? {
                actionArea: (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setJobCompletionDialogOpen(true);
                      }}
                    >
                      Pay Referral Fee
                    </Button>
                    <Button
                      size="small"
                      sx={{
                        color: "#F04438 !important",
                        borderColor: "rgba(240, 68, 56, 0.50) !important",
                      }}
                      variant="outlined"
                      onClick={() => {
                        setMarkInactiveDialogOpen(true);
                      }}
                    >
                      Mark Inactive
                    </Button>
                  </>
                ),
              }
            : {})}
          onClose={() => {
            setCurrentSelectedJob(undefined);
            setDefaultJobDrawerTab(JobDrawerTabsEnum.PROJECT);
          }}
        />
      ) : null}
      {currentSelectedJob && currentTab === JobDashboardTabs.REFERRALS_SENT ? (
        <JobDrawer
          open={!!currentSelectedJob}
          defaultTab={defaultJobDrawerTab}
          jobId={currentSelectedJob.id}
          jobStatus={currentSelectedJob?.status}
          lastActivity={getLastActivityJob(currentSelectedJob as Job)}
          onClose={() => {
            setCurrentSelectedJob(undefined);
            setDefaultJobDrawerTab(JobDrawerTabsEnum.PROJECT);
          }}
        />
      ) : null}
      {isMobile ? null : (
        <Box
          position="fixed"
          // bottom={"12px"}
          // right={"100px"}
          bottom={"40px"}
          right={"48px"}
        >
          {/* <Backdrop open={jobUploadOpen} />
          <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%, -32%)",
            }}
            icon={<SpeedDialIcon />}
            open={jobUploadOpen}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            {speedDialActions.map((action, index) => (
              <SpeedDialAction
                key={action.name}
                tooltipOpen
                icon={action.icon}
                tooltipTitle={action.name} // Apply custom tooltip class
                classes={{ staticTooltipLabel: "custom-tooltip" }}
                tooltipPlacement="left"
                onClick={
                  index === 1
                    ? () => (window.location.href = InitialWhatsappMessage)
                    : () => {
                        handleClose();
                        setIsJobDialogOpen(true);
                      }
                }
              />
            ))}
          </SpeedDial> */}
          <ToolTip placement="bottom" message="Upload a Job">
            <Fab
              color="primary"
              aria-label="add-invite"
              onClick={() => setIsJobDialogOpen(true)}
            >
              <AddIcon />
            </Fab>
          </ToolTip>
        </Box>
      )}
      {isJobDialogOpen ? (
        <CreateTechnicianJobDialogWrapper
          isOpen={isJobDialogOpen}
          jobsListUrl={getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.MY_JOBS,
          ])}
          onClose={() => setIsJobDialogOpen(false)}
        />
      ) : null}
      {declineJobDialogOpen && currentSelectedJob && (
        <DeclineJobDialog
          isOpen={declineJobDialogOpen}
          jobName={currentSelectedJob?.jobType ?? ""}
          onCancel={() => {
            setDeclineJobDialogOpen(false);
          }}
          onRemove={(reason: string) => {
            updateRoutedJob({
              jobId: currentSelectedJob.id,
              data: {
                status: RoutedJobStatus.DECLINED.toString(),
                declinedReason: reason,
              },
            });
            setDeclineJobDialogOpen(false);
            setCurrentSelectedJob(undefined);
            setDefaultJobDrawerTab(JobDrawerTabsEnum.PROJECT);
          }}
        />
      )}
      {jobCompletionDialogOpen && currentSelectedJob && (
        <JobCompletionPaymentDialog
          isOpen={jobCompletionDialogOpen}
          job={currentSelectedJob as RoutedJobResponse}
          onClose={(refetch?: boolean) => {
            if (refetch) {
              refetchRoutedJobs();
              setCurrentSelectedJob(undefined);
              setDefaultJobDrawerTab(JobDrawerTabsEnum.PROJECT);
            }

            setJobCompletionDialogOpen(false);
          }}
        />
      )}
      {markInactiveDialogOpen && currentSelectedJob && (
        <MarkInactiveJobDialog
          isOpen={markInactiveDialogOpen}
          jobId={(currentSelectedJob as RoutedJobResponse).jobId}
          onCancel={() => {
            setMarkInactiveDialogOpen(false);
          }}
          onRemove={reason => {
            updateRoutedJob({
              jobId: currentSelectedJob.id,
              data: {
                status: RoutedJobStatus.INACTIVE.toString(),
                inactiveReason: reason,
              },
            });
            setCurrentSelectedJob(undefined);
            setMarkInactiveDialogOpen(false);
            setDefaultJobDrawerTab(JobDrawerTabsEnum.PROJECT);
          }}
        />
      )}
      {onApproveJobByInactiveCompany ? (
        <AccessControlJobDialog
          isOpen={onApproveJobByInactiveCompany}
          onClose={() => {
            setOnApproveJobByInactiveCompany(false);
          }}
        />
      ) : null}
      {paymentDialogOpen ? (
        <CompanyPaymentDialog
          open={paymentDialogOpen}
          onClose={() => setPaymentDialogOpen(false)}
        />
      ) : null}
    </>
  );
}
