import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import axiosInstance from "@/common/api";
import { BasicCompanySchema } from "@/company/api/useGellAllCompanies";

export interface HubSchema {
  id: number;
  name: string;
  companies: Array<BasicCompanySchema>;
  description?: string;
}

export const GET_HUBS_URL = "/hubs";

export function useGetHubs(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<HubSchema>> {
  return useQuery({
    queryKey: [GET_HUBS_URL],
    queryFn: async () => {
      const response = await axiosInstance.get(GET_HUBS_URL);
      return response.data.data;
    },
    ...options,
  });
}
