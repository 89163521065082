/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputBaseComponentProps,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { ElementType, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import zipcodes from "zipcodes";

import { AuthRoutes } from "@/auth/constants";
import { FIRST_CAMPAIGN_LOGIN_KEY, useAuth } from "@/auth/context/jwt";
import { useGetCampaignPublic } from "@/campaign/api/useGetCampaignPublic";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { AppConstants } from "@/common/constants";
import { Languages } from "@/common/constants/languages";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { Seo } from "@/common/Seo/Seo";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { COMPANY_ACCOUNT_TYPES, CompanyRoutes } from "@/company/types";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { SettingsConsumer, SettingsProvider } from "@/settings";
import { createTheme } from "@/Theme";
import { getUSPhoneValidationSchema, NAME_REGEX } from "@/utils";

import { CAMPAIGN_NAME, campaignProperties } from "./CampaignLandingPage";

export function CampaignSignUpPage() {
  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const campaignName = AppConstants.CAMPAIGN_SITE as CAMPAIGN_NAME;

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("company");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const inputRef = useRef(null);

  const [searchParams] = useSearchParams();
  const { location } = useParams();

  const campaignCode = location;
  const utmCampaign = searchParams.get("utm_campaign");
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");

  const { signUp } = useAuth();
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .matches(NAME_REGEX, "Enter a valid name")
      .required("First Name is required"),
    lastName: yup
      .string()
      .matches(NAME_REGEX, "Enter a valid name")
      .required("Last Name is required"),
    companyName:
      value === "technician"
        ? yup.string()
        : yup.string().required("Company Name is required"),
    email: yup
      .string()
      .email("Work Email Address must be a valid email address")
      .optional(),
    cellPhone: getUSPhoneValidationSchema(),
    acceptedTermsAndConditions: yup
      .boolean()
      .oneOf([true], "You must accept the Terms and Conditions"),
    acceptedWhatsappMessagingTerms: yup
      .boolean()
      .oneOf(
        [true],
        "You must agree to receive messages on WhatsApp to submit job videos"
      ),
    preferredLanguage: yup
      .string()
      .oneOf(
        [Languages.ENGLISH, Languages.SPANISH],
        "It should either english or spanish"
      )
      .required("Preferred language is required"),
    zipCode: yup
      .string()
      .required("Zip Code is required")
      .oneOf(Object.keys(zipcodes.codes), "Invalid U.S. zip code")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      cellPhone: "",
      companyName: "",
      email: "",
      zipCode: "",
      acceptedTermsAndConditions: false,
      preferredLanguage: Languages.ENGLISH,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const {
        firstName,
        lastName,
        cellPhone,
        companyName,
        zipCode,
        email,
        acceptedTermsAndConditions,
        preferredLanguage,
      } = values;
      try {
        setLoading(true);
        await signUp({
          utmParams: {
            campaign: utmCampaign ?? undefined,
            medium: utmMedium ?? undefined,
            source: utmSource ?? undefined,
          },
          user: {
            firstName,
            lastName,
            cellPhone: cellPhone.replace(/[\s()-]/g, ""),
            email: email ?? "",
            jobTitle: value === "technician" ? "Technician" : "Administrator",
            acceptedTermsAndConditions,
            preferredLanguage,
          },
          company: {
            name:
              companyName && value === "technician"
                ? companyName
                : `${firstName} ${lastName}`,
            zipCode,
            accountType:
              value === "technician"
                ? COMPANY_ACCOUNT_TYPES.INDEPENDENT_CONTRACTOR
                : COMPANY_ACCOUNT_TYPES.BRAND_LOCATION,
          },
          campaignCode: campaignCode ?? undefined,
          redirectTo: campaignCode
            ? `/${CompanyRoutes.BASE}/${CompanyRoutes.DASHBOARD_HOME}`
            : undefined,
        });
        localStorage.setItem(FIRST_CAMPAIGN_LOGIN_KEY, "true");
        parent.window.open(
          `${AppConstants.platformBaseUrl}/${AuthRoutes.BASE}/${
            AuthRoutes.VERIFY_OTP
          }?phone=${cellPhone.replace(/[\s()-]/g, "")}`,
          "_blank"
        );
      } catch (error) {
        let message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while signing up user.";
        if (
          message.includes("An account with that mobile number already exists")
        ) {
          message = "An account with that mobile number already exists.";
        }
        enqueueSnackbar(message, {
          variant: "error",
          subText: message.includes(
            "An account with that mobile number already exists"
          ) ? (
            <>
              Questions?{" "}
              <a
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "#7A271A",
                  cursor: "pointer",
                }}
                onClick={event => {
                  event.preventDefault();
                  setContactSupportModalOpen(true);
                }}
              >
                Contact Support
              </a>
              .
            </>
          ) : (
            <>
              Please{" "}
              <a
                href={`/${AuthRoutes.BASE}/${AuthRoutes.LOGIN}`}
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "#7A271A",
                }}
              >
                sign in
              </a>{" "}
              or{" "}
              <a
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "#7A271A",
                  cursor: "pointer",
                }}
                onClick={event => {
                  event.preventDefault();
                  setContactSupportModalOpen(true);
                }}
              >
                contact support
              </a>
              .
            </>
          ),
        });
      }
      setLoading(false);
    },
  });

  const {
    data: campaign,
    error,
    isLoading,
    isFetching,
  } = useGetCampaignPublic(campaignCode, {
    enabled: !!campaignCode,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (error?.notFound) {
      navigate("/");
    }
  }, [error]);

  if (isLoading || isFetching) {
    return <ComponentLoader />;
  }

  return (
    <>
      <Seo title="Sign Up" />
      <Box
        sx={{ p: "0 !important", height: "100vh", overflow: "auto" }}
        className="custom-scrollbar"
      >
        <SettingsProvider>
          <SettingsConsumer>
            {settings => {
              // Prevent theme flicker when restoring custom settings from browser storage
              if (!settings.isInitialized) {
                // return null;
              }

              const theme = createTheme({
                colorPreset: campaignName
                  ? campaignProperties[campaignName].colorTheme
                  : "green",
                contrast: settings.contrast,
                direction: settings.direction,
                paletteMode: settings.paletteMode,
                responsiveFontSizes: settings.responsiveFontSizes,
              });
              return (
                <ThemeProvider theme={theme}>
                  <Box
                    sx={{
                      pt: { xs: 2, md: 1.5 },
                      pb: { xs: 2, md: 1.5 },
                      px: { xs: 2, md: 4 },
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: { xs: "flex-start", md: "center" },
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: { xs: "center", md: "start" },
                      }}
                    >
                      <img
                        style={{
                          height: campaignName
                            ? campaignName === CAMPAIGN_NAME.MR_ELECTRIC
                              ? "70px"
                              : "100px"
                            : "40px",
                          ...(campaignName === CAMPAIGN_NAME.MR_ELECTRIC
                            ? { width: "250px" }
                            : {}),
                          objectFit: "contain",
                        }}
                        src={
                          campaignName
                            ? campaignProperties[campaignName].logoUrl
                            : "/logo.svg"
                        }
                        alt="success"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "8px",
                        width: "100%",
                        py: 1,
                      }}
                    >
                      <Typography variant="h5">
                        Sign Up to Partner with Us
                      </Typography>
                      <Box
                        component={"form"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1.5,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            columnGap: "8px",
                            width: "100%",
                          }}
                        >
                          <FormControl>
                            <FormLabel id="row-radio-buttons-group-label">
                              <Typography
                                fontSize={"14px"}
                                fontWeight={400}
                                color={"black"}
                              >
                                Which best describes you?
                              </Typography>
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={value}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="company"
                                color="#FB6125"
                                control={<Radio />}
                                label={
                                  <Typography variant="body2">
                                    Company
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value="technician"
                                color="#FB6125"
                                control={<Radio />}
                                label={
                                  <Typography variant="body2">
                                    Technician
                                  </Typography>
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        {value !== "technician" ? (
                          <TextField
                            required
                            fullWidth
                            inputRef={inputRef}
                            id="companyName"
                            name="companyName"
                            label="Company Name"
                            value={formik.values.companyName}
                            error={
                              formik.touched.companyName &&
                              Boolean(formik.errors.companyName)
                            }
                            helperText={
                              formik.touched.companyName &&
                              formik.errors.companyName
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        ) : null}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            columnGap: "8px",
                            width: "100%",
                          }}
                        >
                          <TextField
                            required
                            sx={{ width: { xs: 1 / 2 } }}
                            id="firstName"
                            name="firstName"
                            label="First Name"
                            value={formik.values.firstName}
                            error={
                              formik.touched.firstName &&
                              Boolean(formik.errors.firstName)
                            }
                            helperText={
                              formik.touched.firstName &&
                              formik.errors.firstName
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                          <TextField
                            required
                            sx={{ width: { xs: 1 / 2 } }}
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            value={formik.values.lastName}
                            error={
                              formik.touched.lastName &&
                              Boolean(formik.errors.lastName)
                            }
                            helperText={
                              formik.touched.lastName && formik.errors.lastName
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Box>
                        <TextField
                          required
                          fullWidth
                          inputRef={inputRef}
                          id="cellPhone"
                          name="cellPhone"
                          label="Mobile Number"
                          value={formik.values.cellPhone}
                          error={
                            formik.touched.cellPhone &&
                            Boolean(formik.errors.cellPhone)
                          }
                          helperText={
                            formik.touched.cellPhone && formik.errors.cellPhone
                          }
                          InputProps={{
                            inputComponent: MaskedPhoneInput as unknown as
                              | ElementType<InputBaseComponentProps>
                              | undefined,
                          }}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Email Address"
                          value={formik.values.email}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        <TextField
                          required
                          fullWidth
                          type="number"
                          id="zipCode"
                          name="zipCode"
                          label="Zip Code"
                          inputMode="numeric"
                          sx={{ mx: 0 }}
                          inputProps={{
                            pattern: "[0-9]*",
                            "data-hj-allow": true,
                          }}
                          value={formik.values.zipCode}
                          error={
                            formik.touched.zipCode &&
                            Boolean(formik.errors.zipCode)
                          }
                          helperText={
                            formik.touched.zipCode && formik.errors.zipCode
                          }
                          onChange={event => {
                            if (event.target.value?.length <= 5) {
                              formik.setFieldValue(
                                "zipCode",
                                event.target.value,
                                true
                              );
                            }
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="preferred-language">
                            Preferred Language
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="preferred-language"
                            id="preferred-language"
                            label="Preferred Language"
                            name="preferredLanguage"
                            value={formik.values.preferredLanguage}
                            error={
                              formik.touched.preferredLanguage &&
                              Boolean(formik.errors.preferredLanguage)
                            }
                            onChange={formik.handleChange}
                          >
                            <MenuItem value={Languages.ENGLISH}>
                              English
                            </MenuItem>
                            <MenuItem value={Languages.SPANISH}>
                              Spanish
                            </MenuItem>
                          </Select>
                          <FormHelperText>
                            {
                              "This will be used only for translating and analyzing videos"
                            }
                          </FormHelperText>
                        </FormControl>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          width={"100%"}
                          px={2}
                        >
                          <FormControl sx={{ width: "100%" }}>
                            <FormControlLabel
                              sx={{
                                "& .MuiTypography-root": {
                                  lineHeight: "normal",
                                },
                              }}
                              value={formik.values.acceptedTermsAndConditions}
                              control={
                                <Checkbox
                                  sx={{
                                    mx: 0.5,
                                    p: 0,
                                    // mt: 0.1,
                                    "& svg": { width: "16px" },
                                  }}
                                  size="small"
                                  name="acceptedTermsAndConditions"
                                  checked={Boolean(
                                    formik.values.acceptedTermsAndConditions
                                  )}
                                  onChange={formik.handleChange}
                                />
                              }
                              label={
                                <Box display={"flex"} alignItems={"center"}>
                                  <Typography
                                    variant="caption"
                                    fontWeight={400}
                                    fontSize={"0.8rem"}
                                    color="text.secondary"
                                  >
                                    I have read and accepted the{" "}
                                    <Link
                                      color={
                                        campaignName
                                          ? campaignProperties[campaignName]
                                              .termsAndConditionColor
                                          : "#16B364"
                                      }
                                      target="_blank"
                                      href={`${AppConstants.platformBaseUrl}/open/terms-of-service`}
                                      rel="noreferrer"
                                    >
                                      <Typography
                                        // py={4}
                                        display="inline"
                                        variant="caption"
                                        fontWeight={600}
                                        fontSize={"0.8rem"}
                                        color={
                                          campaignName
                                            ? campaignProperties[campaignName]
                                                .termsAndConditionColor
                                            : "#16B364"
                                        }
                                      >
                                        Terms and Conditions
                                      </Typography>
                                    </Link>
                                    .
                                  </Typography>
                                </Box>
                              }
                            />
                            {formik.touched.acceptedTermsAndConditions &&
                              formik.errors.acceptedTermsAndConditions && (
                                <FormHelperText error>
                                  {formik.errors.acceptedTermsAndConditions}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Box>
                        {value === "technician" ? (
                          <Box height={"58px"}></Box>
                        ) : null}
                        <LoadingButton
                          fullWidth
                          disabled={
                            !formik.isValid ||
                            (!formik.dirty &&
                              !Object.keys(formik.touched).length)
                          }
                          sx={{
                            ...(campaignName === CAMPAIGN_NAME.A1_GARAGE
                              ? { borderRadius: 0 }
                              : {}),
                            ...(campaignName === CAMPAIGN_NAME.A1_GARAGE &&
                            !(
                              !formik.isValid ||
                              (!formik.dirty &&
                                !Object.keys(formik.touched).length)
                            )
                              ? { border: "2px solid #562100" }
                              : {}),
                          }}
                          loading={loading}
                          variant="contained"
                          onClick={() => formik.handleSubmit()}
                        >
                          SIGN UP
                        </LoadingButton>
                        {campaignName ? (
                          <Box
                            display={"flex"}
                            width="100%"
                            gap={2}
                            alignItems={"center"}
                            justifyContent={"start"}
                          >
                            <img
                              style={{ height: "25px", objectFit: "contain" }}
                              src={"/powered-by.png"}
                              alt="powered by"
                            />
                            <Typography
                              fontSize={"14px"}
                              fontWeight={400}
                              color={"text.secondary"}
                            >
                              Powered by TradeEngage
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                </ThemeProvider>
              );
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </Box>
    </>
  );
}
