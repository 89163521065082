import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { type FC, Fragment, useState } from "react";

import { User } from "../../../auth/types";
import { usePopover } from "../../../common/hooks/use-popover";
import { CompanyAvatar } from "../../../company/components/company-avatar/CompanyAvatar";
import {
  CompanyPublicProfile,
  SearchCompanyProfile,
} from "../../../company/types";
import { CompanyInfoPopover } from "./CompanyInfoPopover";

interface ChatMessageProps {
  authorAvatar?: string | null;
  authorName: string;
  authorTitle: string;
  message: string;
  position?: "left" | "right";
  companyProfile: CompanyPublicProfile;
  invitedCompanyProfile: SearchCompanyProfile;
  invitingUser: User;
}

export const ChatInitialMessage: FC<ChatMessageProps> = props => {
  const {
    authorAvatar,
    authorName,
    authorTitle,
    message,
    position,
    companyProfile,
    invitedCompanyProfile,
    invitingUser,
    ...other
  } = props;

  const popover = usePopover<HTMLButtonElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: position === "right" ? "flex-end" : "flex-start",
        mb: 2,
      }}
      {...other}
    >
      <Stack
        alignItems="flex-start"
        direction={position === "right" ? "row-reverse" : "row"}
        spacing={2}
        sx={{
          maxWidth: 500,
          width: "100%",
          ml: position === "right" ? "auto" : 0,
          mr: position === "left" ? "auto" : 0,
        }}
      >
        <Box
          ref={popover.anchorRef}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <CompanyAvatar
            logo={authorAvatar || undefined}
            name={authorTitle}
            height={"32px"}
            width={"32px"}
            bgColor="white"
            border="1px solid gray !important"
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Card
            sx={{
              backgroundColor:
                position === "right" ? "primary.main" : "background.paper",
              color:
                position === "right" ? "primary.contrastText" : "text.primary",
              px: 2,
              py: 1,
            }}
          >
            <Box sx={{ mb: 1 }}>
              <Typography color="inherit" variant="subtitle2">
                {authorName}
              </Typography>
              <Typography
                color="text.secondary"
                variant="subtitle2"
                fontSize={"12px"}
                fontWeight={500}
              >
                {authorTitle}
              </Typography>
            </Box>
            <Typography
              color="inherit"
              variant="body1"
              sx={{
                display: "-webkit-box",
                wordBreak: "break-word",
                overflow: "scroll",
                maxHeight: "100px",
                textOverflow: "none",
              }}
            >
              {message.split("\n").map((line, index) => (
                <Fragment key={index}>
                  {line}
                  <br />
                </Fragment>
              ))}
            </Typography>
          </Card>
        </Box>
      </Stack>
      <CompanyInfoPopover
        anchorEl={anchorEl}
        open={open}
        companyProfile={companyProfile}
        invitingUser={invitingUser}
        invitedCompanyProfile={invitedCompanyProfile}
      />
    </Box>
  );
};
