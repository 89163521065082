import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Fragment, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { PublicRoutes } from "@/open/constants";
import { neutral, tradeEngageColors } from "@/Theme/colors";

import { NavLink, SectionedNavLinks } from "../layouts/DashboardLayout";
import { TradeEngageLogo, TradeEngageLogoTypes } from "../Logo";
import { RouterLink } from "../router-link";
import { getNavigationPathFromRoutePaths } from "../utils/getNavigationPathFromRoutePaths";

export interface SideNavProps {
  primaryNavLinks: NavLink[];
  sectionedNavLinks?: SectionedNavLinks[];
  header?: ReactNode;
}

export function SideNav(props: SideNavProps) {
  const { signOut } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        position: "sticky",
        top: 0,
        pl: 2,
        border: "1px solid",
        borderColor: "divider",
        background: tradeEngageColors.darkBlue,
      }}
    >
      <Stack
        sx={{
          height: "100%",
          overflowY: "auto",
          background: tradeEngageColors.darkBlue,
          "&::-webkit-scrollbar": {
            display: "none",
          } /* Chrome, Safari and Opera */,
          "-ms-overflow-style": "none" /* IE and Edge */,
          "scrollbar-width": "none" /* Firefox */,
        }}
      >
        {props.header ? props.header : null}
        <Box
          component="nav"
          display="flex"
          flexDirection="column"
          sx={{
            flexGrow: 1,
          }}
        >
          {props.primaryNavLinks.map(navLink => (
            <ListItemButton
              key={navLink.link}
              {...(navLink.link !==
              getNavigationPathFromRoutePaths([
                PublicRoutes.BASE,
                PublicRoutes.CONTACT_SUPPORT,
              ])
                ? { component: RouterLink, href: navLink.link }
                : {
                    onClick: () => {
                      setContactSupportModalOpen(true);
                    },
                  })}
              sx={{
                borderRadius: 1,
                px: 1,
                py: 0.5,
                flexGrow: 0,
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    navLink.link === pathname
                      ? tradeEngageColors.green
                      : "#B5BCC4",
                }}
              >
                <SvgIcon fontSize="small">{navLink.icon}</SvgIcon>
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body1"
                  fontWeight={"600"}
                  fontSize={"14px"}
                  color={navLink.link === pathname ? neutral[50] : "#B5BCC4"}
                >
                  {navLink.label}
                </Typography>
              </ListItemText>
              <Box pr={1}>{navLink.endIcon}</Box>
            </ListItemButton>
          ))}
          {props.sectionedNavLinks &&
            props.sectionedNavLinks.map(({ sectionName, navLinks }) => (
              <Fragment key={`nav_section_${sectionName}`}>
                <ListSubheader
                  disableGutters
                  sx={{ background: tradeEngageColors.darkBlue }}
                >
                  <Typography
                    variant="overline"
                    color={"#B5BCC4"}
                    fontWeight={"600"}
                    fontSize={"14px"}
                  >
                    {sectionName}
                  </Typography>
                </ListSubheader>
                {navLinks.map(navLink => (
                  <ListItemButton
                    key={navLink.link}
                    {...(navLink.link !==
                    getNavigationPathFromRoutePaths([
                      PublicRoutes.BASE,
                      PublicRoutes.CONTACT_SUPPORT,
                    ])
                      ? { component: RouterLink, href: navLink.link }
                      : {
                          onClick: () => {
                            setContactSupportModalOpen(true);
                          },
                        })}
                    sx={{
                      borderRadius: 1,
                      px: 1,
                      py: 0.5,
                      flexGrow: 0,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          navLink.link === pathname
                            ? tradeEngageColors.green
                            : "#B5BCC4",
                      }}
                    >
                      <SvgIcon fontSize="small">{navLink.icon}</SvgIcon>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="body1"
                        color={
                          navLink.link === pathname ? neutral[50] : "#B5BCC4"
                        }
                        fontWeight={"600"}
                        fontSize={"14px"}
                      >
                        {navLink.label}
                      </Typography>
                    </ListItemText>
                    <Box pr={1}>{navLink.endIcon}</Box>
                  </ListItemButton>
                ))}
              </Fragment>
            ))}
        </Box>
        <Divider sx={{ ml: -2, borderColor: neutral[500] }} />
        <Box
          sx={{
            p: 3,
            ml: -2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TradeEngageLogo
            style={{ width: "120px", height: "20px" }}
            logotype={TradeEngageLogoTypes.LOGO_LIGHT}
          />
        </Box>
      </Stack>
    </Box>
  );
}
