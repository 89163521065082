import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { JobSectors } from "../constants";

export function useFetchRelatedJobSectors(
  sector?: JobSectors,
  options?: UseQueryOptions<any>
): UseQueryResult<Array<JobSectors>> {
  const queryUri = `/jobs/sectors`;
  return useQuery({
    queryKey: [queryUri],
    queryFn: async () => {
      const response = await API.get(`${queryUri}`);
      return response.data.data?.sectors ?? [];
    },
    ...options,
  });
}
