import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Divider,
  IconButton,
  Tab,
  Tabs,
  tabsClasses,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useCallback, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";

import { useGetBrandById } from "./api/useGetBrandById";
import { ProfileOverview } from "./common/ProfileOverview";

interface CompanyDrawerProps {
  onClose?: () => void;
  open?: boolean;
  expanded?: boolean;
  companyId: number;
  defaultTab?: TabsEnum;
  onExpand?: () => void;
}

export enum TabsEnum {
  USERS = "users",
  BRAND_LOCATIONS = "brand-locations",
  PROFILE = "profile",
}

export const BrandDrawer: FC<CompanyDrawerProps> = props => {
  const { onClose, companyId, defaultTab, onExpand, expanded } = props;
  const [currentTab, setCurrentTab] = useState<TabsEnum>(
    defaultTab ?? TabsEnum.PROFILE
  );

  const { session } = useAuth();

  const { closeSnackbar } = useSnackbar();

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: TabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  const { data: company, isLoading } = useGetBrandById(companyId, {
    enabled: !!companyId,
  });

  console.log({ company });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      sx={{ ...(expanded ? { width: "400px" } : {}) }}
    >
      <Box
        position={"sticky"}
        sx={{
          top: 0,
          zIndex: 400,
          pt: 1,
          background: "white",
        }}
      >
        <Box display="flex" justifyContent="space-between" px={3} pb={2} pt={3}>
          <CompanyAvatar
            height={"48px"}
            logo={company?.logoUrl}
            name={company?.name ?? ""}
          />
          <Box display={"flex"} gap={2}>
            {/* <IconButton
              aria-label="expand"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={() => {
                closeSnackbar();
                onExpand?.();
              }}
            >
              {expanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton> */}
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={() => {
                closeSnackbar();
                onClose?.();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Tabs
          sx={{
            px: 3,

            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
          value={currentTab}
          variant="scrollable"
          TabScrollButtonProps={{ sx: { width: "20px" } }}
          scrollButtons={true}
          allowScrollButtonsMobile={true}
          onChange={handleTabsChange}
        >
          <Tab value={TabsEnum.PROFILE} label="Profile" />
        </Tabs>
        <Divider />
      </Box>
      <Box p={1.5} pb={0} flex={1} overflow={"auto"}>
        {currentTab === TabsEnum.PROFILE ? (
          <ProfileOverview company={company} />
        ) : null}
      </Box>
    </Box>
  );
};
