import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhonelinkLockOutlinedIcon from "@mui/icons-material/PhonelinkLockOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import {
  Card,
  CardContent,
  Chip,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import React, { FC } from "react";

import { User } from "../../../auth/types";
import { ToolTip } from "../../../common/tool-tip/ToolTip";
import { CompanyAvatar } from "../../../company/components/company-avatar/CompanyAvatar";
import { JobSectors } from "../../../company/jobs/constants";
import {
  CompanyPublicProfile,
  PLATFORM_PRESENCE,
  SearchCompanyProfile,
} from "../../../company/types";
import { snakeCaseJobSectors } from "../../../company/utils";

interface CompanyInfoPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  companyProfile: CompanyPublicProfile;
  invitedCompanyProfile: SearchCompanyProfile;
  invitingUser: User;
}

export const CompanyInfoPopover: FC<CompanyInfoPopoverProps> = props => {
  const {
    anchorEl,
    onClose,
    open,
    companyProfile,
    invitedCompanyProfile,
    invitingUser,
    ...other
  } = props;

  const findNeededPartnersString = () => {
    const opportunitySectors = invitedCompanyProfile?.sectors?.filter(
      sector => {
        const companySector = sector;

        return (
          companyProfile?.opportunitySectors?.includes(companySector) &&
          !companyProfile?.sectors
            ?.map(sector => sector.sector)
            ?.includes(companySector)
        );
      }
    );

    return opportunitySectors?.length
      ? snakeCaseJobSectors[opportunitySectors[0]]
      : null;
  };

  const recommendedJobSectorsList = [...(invitedCompanyProfile?.sectors ?? [])];

  return (
    <Popover
      disableScrollLock
      anchorEl={anchorEl}
      sx={{
        pointerEvents: "none",
      }}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={!!open}
      onClose={onClose}
      {...other}
    >
      <Card
        sx={{
          px: 2,
          pt: 2,
          boxShadow: "0px 0px 0px 1px #F2F4F7",
          "&:active": {
            border: "1px solid #071D2F",
          },
          "&:focus": {
            border: "1px solid #071D2F",
          },
          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
        }}
      >
        <Box
          sx={{ display: "flex", py: 1 }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <CompanyAvatar
            height={"40px"}
            maxWidth={"160px"}
            logo={companyProfile?.logoUrl}
            name={companyProfile?.name || ""}
          />
        </Box>
        <CardContent sx={{ p: 0 }}>
          <Box display={"flex"} gap={1} py={1} flexWrap={"wrap"}>
            {companyProfile?.sectors?.length
              ? companyProfile?.sectors.map(({ sector }) => (
                  <Chip
                    key={sector}
                    sx={{ color: "text.secondary", bgcolor: "#F8F9FA" }}
                    label={
                      snakeCaseJobSectors[sector as JobSectors] ??
                      snakeCaseJobSectors[JobSectors.OTHER]
                    }
                    size="small"
                  />
                ))
              : null}
          </Box>
          <Grid container py={0.5}>
            <Grid item xs={12} display={"flex"} alignItems={"center"}>
              <Typography display="inline" variant="h6">
                {" "}
                {companyProfile?.name}{" "}
              </Typography>
              {!!(
                companyProfile?.platformPresence ===
                PLATFORM_PRESENCE.ON_PLATFORM
              ) && (
                <ToolTip
                  placement="bottom"
                  title="TradeEngage Participant"
                  message="This is a verified active business on TradeEngage."
                >
                  <VerifiedOutlinedIcon
                    color="primary"
                    fontSize="small"
                    sx={{ ml: 1, verticalAlign: "center", color: "#16DB65" }}
                  />
                </ToolTip>
              )}
            </Grid>
          </Grid>
          <Box display={"flex"} flexDirection={"column"} pt={1}>
            {findNeededPartnersString() ? (
              <Box display={"flex"} alignItems={"center"}>
                <ThumbUpAltOutlinedIcon
                  color="primary"
                  sx={{ width: "16px", marginRight: "8px" }}
                />

                <Typography
                  variant="caption"
                  color="primary"
                  alignItems={"center"}
                >
                  Needs {findNeededPartnersString() ?? ""}&nbsp;Partner
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Box display={"flex"} alignItems={"center"} py={0.5}>
            <Typography variant="h6">
              {companyProfile?.googleReviewRating?.toFixed(1)}
            </Typography>
            <Rating
              readOnly
              name="google-rating"
              defaultValue={companyProfile?.googleReviewRating}
              value={companyProfile?.googleReviewRating}
              precision={0.1}
            />
          </Box>
          <Box display={"flex"} gap={1}>
            {companyProfile?.sectors.some(sector => {
              if (recommendedJobSectorsList?.length) {
                return recommendedJobSectorsList?.includes(sector.sector);
              }
              return false;
            }) ? (
              <Box display={"flex"} alignItems={"center"}>
                <img
                  src="/top-referral.svg"
                  alt="top referral category"
                  style={{ marginRight: "8px" }}
                />
                <Typography
                  variant="caption"
                  color="primary"
                  alignItems={"center"}
                >
                  Top Referral Category
                </Typography>
              </Box>
            ) : null}
            <Box display={"flex"} alignItems={"center"}>
              {invitingUser &&
              (invitingUser?.firstName || invitingUser?.lastName) ? (
                <>
                  <PersonOutlineOutlinedIcon
                    color="primary"
                    sx={{ width: "16px", mr: "2px" }}
                  />{" "}
                  <Typography
                    variant="caption"
                    color="primary"
                    alignItems={"center"}
                  >
                    {invitingUser?.firstName
                      ? `${invitingUser?.firstName}`
                      : ""}
                    {invitingUser?.firstName && invitingUser?.lastName
                      ? " "
                      : ""}
                    {invitingUser?.lastName ? `${invitingUser?.lastName}` : ""}
                  </Typography>
                </>
              ) : null}
              {invitingUser?.cellPhone &&
              (invitingUser?.firstName || invitingUser?.lastName) ? (
                <ToolTip placement="bottom" message="Mobile Number Verified">
                  <PhonelinkLockOutlinedIcon
                    color="primary"
                    fontSize="small"
                    sx={{ width: "16px" }}
                  />
                </ToolTip>
              ) : null}
              {invitingUser?.email &&
              (invitingUser?.firstName || invitingUser?.lastName) ? (
                <ToolTip placement="bottom" message="Email Address Verified">
                  <MarkEmailReadOutlinedIcon
                    color="primary"
                    fontSize="small"
                    sx={{ width: "16px" }}
                  />
                </ToolTip>
              ) : null}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Popover>
  );
};
