import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { Company, CompanyGroup, CompanySector } from "../types";

export interface CompanyWithGroupsResponse {
  company: Company;
  groups: Array<CompanyGroup>;
  companySectors: Array<CompanySector>;
}

export function useQueryAuthenticateCompanies(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<CompanyWithGroupsResponse>> {
  const uri = "/auth/authenticate/user/companies";

  return useQuery({
    queryKey: [uri],
    queryFn: async () => {
      const response = await API.get(uri);
      const camelCaseResponse = response.data.data;
      return camelCaseResponse;
    },
    ...options,
  });
}
