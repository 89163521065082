/* eslint-disable jsx-a11y/media-has-caption */
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputBaseComponentProps,
  Link,
  Paper,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { ElementType } from "react";
import Lottie from "react-lottie";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { GoogleAutocomplete } from "@/common/GoogleAutocomplete";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { JobSectors } from "@/company/jobs/constants";
import { useQueryBrandLocationById } from "@/company/onboarding/api";
import { snakeCaseJobSectors } from "@/company/utils";
import { useCreateJobReferral } from "@/open/api";
import { getUSPhoneValidationSchema, NAME_REGEX } from "@/utils";

import animationData from "../company-identification/confetti-lottie.json";

export function JobReferralPage() {
  const [searchParams] = useSearchParams();
  const referringCompanyId = searchParams.get("referringCompanyId")
    ? parseInt(searchParams.get("referringCompanyId") as string)
    : -1;
  const referedCompanyId = searchParams.get("referedCompanyId")
    ? parseInt(searchParams.get("referedCompanyId") as string)
    : -1;
  const jobSector = searchParams.get("jobSector");

  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(780));

  const defaultConfettiOptions = {
    loop: 1,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .matches(NAME_REGEX, "Enter a valid name")
      .required("First Name is required"),
    lastName: yup
      .string()
      .matches(NAME_REGEX, "Enter a valid name")
      .required("Last Name is required"),
    email: yup
      .string()
      .email("Work Email Address must be a valid email address")
      .optional(),
    mobileNumber: getUSPhoneValidationSchema(),
    address: yup.string().required("Address is required"),
    projectDetails: yup.string().required("Project Details are required"),
    acceptedTermsAndConditions: yup
      .boolean()
      .oneOf([true], "You must accept the Terms and Conditions"),
  });

  const { mutateAsync: submitJobAsync, isSuccess: isSubmitJobSuccessfully } =
    useCreateJobReferral({
      onSuccess: () => {
        formik.resetForm();
        enqueueSnackbar({
          message: "Request submitted successfully.",
          variant: "success",
        });
      },
    });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "",
      address: "",
      projectDetails: "",
      acceptedTermsAndConditions: false,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      submitJobAsync({
        fromCompanyId: referringCompanyId,
        toCompanyId: referedCompanyId,
        homeownerFirstName: values.firstName,
        homeownerLastName: values.lastName,
        homeownerCellPhone: values.mobileNumber.replace(/[\s()-]/g, ""),
        homeownerEmail: values.email,
        address: values.address,
        projectDetails: values.projectDetails,
        sector: jobSector ?? "",
      });
    },
  });

  const { data: referringCompany, isLoading: isFetchingReferringCompany } =
    useQueryBrandLocationById(
      {
        id: referringCompanyId,
      },
      { enabled: referringCompanyId !== -1 }
    );

  const { data: referedCompany, isLoading: isFetchingReferredCompany } =
    useQueryBrandLocationById(
      {
        id: referedCompanyId,
      },
      { enabled: referedCompanyId !== -1 }
    );

  if (isFetchingReferringCompany || isFetchingReferredCompany) {
    return <ComponentLoader />;
  }

  return (
    <Box
      sx={{
        position: { xs: "relative" },
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
      }}
    >
      <img
        src={"/background-gradient.png"}
        alt={"company logo"}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          zIndex: -100,
        }}
      />
      <Box
        sx={{
          maxWidth: isMobile ? "100%" : "780px",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <img
          src={"/christmas-lights.png"}
          alt="christmas lights"
          style={{
            height: "42px",
            objectFit: "contain",
          }}
        />
        <Box
          sx={{
            display: "flex",
            paddingX: isMobile ? 2 : 4,
            paddingY: 1,
            gap: 1,
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={referringCompany?.logoUrl}
            alt="referring company logo"
            sx={{
              height: isMobile ? "64px" : "96px",
              width: "auto",
              maxWidth: "50%",
              objectFit: "contain",
              display: "block",
            }}
          />
          <Box
            component="img"
            src={referedCompany?.logoUrl}
            alt="refered company logo"
            sx={{
              height: isMobile ? "64px" : "96px",
              width: "auto",
              maxWidth: "50%",
              objectFit: "contain",
              display: "block",
            }}
          />
        </Box>
        <Paper
          sx={{
            borderRadius: 3,
            padding: isMobile ? 2 : 3,
            gap: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          elevation={3}
        >
          <Stack gap={2}>
            <Box gap={1}>
              <Typography variant="h5" fontWeight={700}>
                Submit a Request for your{" "}
                {snakeCaseJobSectors[jobSector as JobSectors]}. We’ll Get in
                Touch!
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Get professional holiday lighting and ensure your homes
                electrical setup is ready
              </Typography>
            </Box>
            <Box
              component={"form"}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  columnGap: "8px",
                  width: "100%",
                }}
              >
                <TextField
                  required
                  sx={{ width: { xs: 1 / 2 } }}
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={formik.values.firstName}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <TextField
                  required
                  sx={{ width: { xs: 1 / 2 } }}
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  value={formik.values.lastName}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  columnGap: "8px",
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  required
                  id="mobileNumber"
                  name="mobileNumber"
                  label="Mobile Number"
                  value={formik.values.mobileNumber}
                  error={
                    formik.touched.mobileNumber &&
                    Boolean(formik.errors.mobileNumber)
                  }
                  helperText={
                    formik.touched.mobileNumber && formik.errors.mobileNumber
                  }
                  InputProps={{
                    inputComponent: MaskedPhoneInput as unknown as
                      | ElementType<InputBaseComponentProps>
                      | undefined,
                  }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email Address"
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <GoogleAutocomplete
                  defaultOptions={[]}
                  selectedValue={formik.values.address ?? ""}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  label="Address*"
                  onChange={(address: string) => {
                    formik.setFieldValue("address", address);
                  }}
                  onSelect={({ selection }) => {
                    formik.setFieldValue("address", selection);
                  }}
                />
              </Box>
              <TextField
                required
                multiline
                fullWidth
                minRows={isMobile ? 4 : 2}
                maxRows={4}
                id="projectDetails"
                name="projectDetails"
                label="Add Project Details"
                placeholder={`Describe your ${
                  snakeCaseJobSectors[jobSector as JobSectors]
                } project`}
                value={formik.values.projectDetails}
                error={
                  formik.touched.projectDetails &&
                  Boolean(formik.errors.projectDetails)
                }
                helperText={
                  formik.touched.projectDetails && formik.errors.projectDetails
                }
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormControl sx={{ width: "100%" }}>
                <FormControlLabel
                  value={formik.values.acceptedTermsAndConditions}
                  control={
                    <Checkbox
                      name="acceptedTermsAndConditions"
                      checked={Boolean(
                        formik.values.acceptedTermsAndConditions
                      )}
                      sx={{ paddingY: 0 }}
                      onChange={formik.handleChange}
                    />
                  }
                  label={
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        I have read the{" "}
                        <Link
                          color="#16B364"
                          href={"/open/terms-of-service"}
                          rel="noreferrer"
                        >
                          <Typography
                            py={4}
                            display="inline"
                            variant="subtitle2"
                            color="#16B364"
                          >
                            Terms and Conditions
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
                  }
                />
                {formik.touched.acceptedTermsAndConditions &&
                  formik.errors.acceptedTermsAndConditions && (
                    <FormHelperText error>
                      {formik.errors.acceptedTermsAndConditions}
                    </FormHelperText>
                  )}
              </FormControl>

              <LoadingButton
                fullWidth
                disabled={
                  !formik.isValid ||
                  (!formik.dirty && !Object.keys(formik.touched).length)
                }
                variant="contained"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </LoadingButton>
              {isSubmitJobSuccessfully && (
                <Box position={"fixed"} sx={{ zIndex: 100 }}>
                  <Lottie
                    options={defaultConfettiOptions}
                    isClickToPauseDisabled={true}
                    height={400}
                    width={400}
                  />
                </Box>
              )}
            </Box>
          </Stack>
          <Box
            display={"flex"}
            width="100%"
            gap={2}
            alignItems={"center"}
            justifyContent={"start"}
            marginTop={1}
          >
            <img
              style={{ height: "25px", objectFit: "contain" }}
              src={"/powered-by.png"}
              alt="powered by"
            />
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              color={"text.secondary"}
            >
              Powered by TradeEngage
            </Typography>
          </Box>
        </Paper>
        <img
          src={"/christmas-lights.png"}
          alt="christmas lights"
          style={{
            height: "42px",
            objectFit: "contain",
          }}
        />
      </Box>
    </Box>
  );
}
