/* eslint-disable jsx-a11y/media-has-caption */

import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhonelinkLockOutlinedIcon from "@mui/icons-material/PhonelinkLockOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DateTime } from "luxon";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { CampaignMarketingSignUpPage } from "@/auth/components/screens/signup/ads/CampaignMarketingSignUpPage";
import { useGetCampaignPublic } from "@/campaign/api/useGetCampaignPublic";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { useFetchCompanyProfilePublic } from "@/company/api/useFetchCompanyProfilePublic";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { JobSectors } from "@/company/jobs/constants";
import { PLATFORM_PRESENCE } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

export function CampaignMarketingPage() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { location } = useParams();
  const [searchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();

  const campaignCode = location;
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const url =
      "https://calendly.com/tradeengage-chelsea/book-tradeengage-demo";
    window.open(url, "_blank");
  };

  const {
    data: campaign,
    error,
    isLoading,
    isFetching,
  } = useGetCampaignPublic(campaignCode, {
    enabled: !!campaignCode,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: companyProfile, isFetching: isFetchingProfile } =
    useFetchCompanyProfilePublic(campaign?.companyId, {
      enabled: !!campaign?.companyId,
      refetchOnWindowFocus: false,
    });

  const linkMenu = (
    <>
      <Divider sx={{ width: "100%", mb: 2 }} />
      <Box
        display={"flex"}
        width={"100%"}
        py={1}
        justifyContent={"space-between"}
        alignItems={"center"}
        textAlign={"start"}
      >
        <Typography variant="subtitle2">How does TradeEngage work?</Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{
            width: "fit-content",
          }}
          endIcon={<CalendarTodayOutlinedIcon />}
          onClick={handleButtonClick}
        >
          Book Demo
        </Button>
      </Box>
    </>
  );

  return (
    <Grid
      container
      minHeight={"calc(100vh - 205px)"}
      overflow={"auto"}
      display="flex"
      justifyContent={"space-between"}
    >
      <Grid
        item
        xs={12}
        lg={6}
        p={isMobile ? 2 : 4}
        pt={isMobile ? 1 : 2}
        textAlign={"center"}
        display="flex"
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          alignItems={"start"}
          sx={{
            width: isMobile ? "100%" : "80%",
          }}
          py={2}
          gap={1}
        >
          <Chip
            icon={
              <AutoFixHighOutlinedIcon sx={{ color: "#16B364 !important" }} />
            }
            label={
              <Typography
                variant="body2"
                display={"inline"}
                sx={{ color: "#16B364", textOverflow: "ellipsis" }}
              >
                Referral Management Platform for the Trades
              </Typography>
            }
            sx={{
              background: "rgba(22, 179, 100, 0.08)",
              color: "#16B364",
              borderRadius: "12px",
            }}
          />
          <Typography variant={isMobile ? "h3" : "h2"} textAlign={"start"}>
            Connect with us on TradeEngage
          </Typography>
          <Box textAlign={"start"}>
            <Typography variant="body1" color="text.secondary">
              TradeEngage, your platform to refer and receive jobs. Built
              exclusively for the trades.
            </Typography>
          </Box>
          {isFetchingProfile || isLoading || isFetching ? (
            <Box my={5} textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="h6" mt={1} mb={0.5}>
                About Us
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                  width: "100%",
                  alignItems: "start",
                  justifyContent: "space-between",
                  p: 2,
                  borderRadius: 2,
                  bgcolor: "#F8F9FA",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"start"}
                  sx={{
                    p: 2,
                    bgcolor: "white",
                    width: "100%",
                    borderRadius: 2,
                    boxShadow: "0px 0px 0px 1px #F2F4F7",
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"start"}
                    sx={{ p: 2, bgcolor: "white" }}
                  >
                    <CompanyAvatar
                      height={"40px"}
                      maxWidth={"160px"}
                      logo={companyProfile?.logoUrl}
                      name={companyProfile?.name || ""}
                    />
                  </Box>
                  <Box display={"flex"} gap={1} mt={1} flexWrap={"wrap"}>
                    {companyProfile?.sectors?.length
                      ? companyProfile?.sectors.map(({ sector }) => (
                          <Chip
                            key={sector}
                            sx={{
                              color: "text.secondary",
                              bgcolor: "#F8F9FA",
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              letterSpacing: 0.14,
                              wordWrap: "break-word",
                            }}
                            label={
                              snakeCaseJobSectors[sector as JobSectors] ??
                              snakeCaseJobSectors[JobSectors.OTHER]
                            }
                            size="small"
                          />
                        ))
                      : null}
                  </Box>
                  <Grid container py={1}>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      alignItems={"start"}
                      gap={0.5}
                      position={"relative"}
                    >
                      <Typography
                        display="inline"
                        variant="h6"
                        verticalAlign={"center"}
                      >
                        {companyProfile?.name}{" "}
                        <span style={{ display: "inline-block" }}>
                          {" "}
                          {!!(
                            companyProfile?.platformPresence ===
                            PLATFORM_PRESENCE.ON_PLATFORM
                          ) && (
                            <ToolTip
                              placement="bottom"
                              title="TradeEngage Participant"
                              message="This is a verified active business on TradeEngage."
                            >
                              <VerifiedOutlinedIcon
                                color="primary"
                                fontSize="small"
                                sx={{
                                  verticalAlign: "center",
                                  color: "#16DB65",
                                  display: "inline",
                                  mt: 0.2,
                                  ml: 0.2,
                                }}
                              />
                            </ToolTip>
                          )}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box display={"flex"} alignItems={"center"}>
                    {companyProfile?.owners?.length &&
                    (companyProfile?.owners[0].firstName ||
                      companyProfile?.owners[0].lastName) ? (
                      <>
                        <PersonOutlineOutlinedIcon
                          color="primary"
                          sx={{ width: "16px", mr: "2px" }}
                        />{" "}
                        <Typography
                          variant="caption"
                          color="primary"
                          alignItems={"center"}
                        >
                          {companyProfile?.owners[0].firstName
                            ? `${companyProfile?.owners[0].firstName}`
                            : ""}
                          {companyProfile?.owners[0].firstName &&
                          companyProfile?.owners[0].lastName
                            ? " "
                            : ""}
                          {companyProfile?.owners[0].lastName
                            ? `${companyProfile?.owners[0].lastName}`
                            : ""}
                        </Typography>
                      </>
                    ) : null}
                    {companyProfile?.owners?.[0]?.hasPhone &&
                    (companyProfile?.owners?.[0]?.firstName ||
                      companyProfile?.owners?.[0]?.lastName) ? (
                      <ToolTip
                        placement="bottom"
                        message="Mobile Number Verified"
                      >
                        <PhonelinkLockOutlinedIcon
                          color="primary"
                          fontSize="small"
                          sx={{ width: "16px" }}
                        />
                      </ToolTip>
                    ) : null}
                    {companyProfile?.owners?.[0]?.hasEmail &&
                    (companyProfile?.owners?.[0]?.firstName ||
                      companyProfile?.owners?.[0]?.lastName) ? (
                      <ToolTip
                        placement="bottom"
                        message="Email Address Verified"
                      >
                        <MarkEmailReadOutlinedIcon
                          color="primary"
                          fontSize="small"
                          sx={{ width: "16px" }}
                        />
                      </ToolTip>
                    ) : null}
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <PeopleOutlineOutlinedIcon
                      color="primary"
                      sx={{ width: "16px", mr: "2px" }}
                    />{" "}
                    <Typography
                      variant="caption"
                      color="primary"
                      alignItems={"center"}
                    >
                      {companyProfile?.numberEmployees} Employees
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <VerifiedUserOutlinedIcon
                      color="primary"
                      sx={{ width: "16px", mr: "2px" }}
                    />
                    <Typography
                      variant="caption"
                      color="primary"
                      alignItems={"center"}
                    >
                      {DateTime.now().year -
                      (companyProfile?.yearEstablished ?? DateTime.now().year)
                        ? DateTime.now().year -
                          (companyProfile?.yearEstablished ??
                            DateTime.now().year)
                        : "< 1"}{" "}
                      Years in Business
                    </Typography>
                  </Box>
                  {isMobile ? (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexDirection={"column"}
                      gap={1}
                      width={"100%"}
                    >
                      <Divider sx={{ width: "100%", mt: 1, mb: 1 }} />
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          navigate(
                            `/ad/signup/${campaignCode}?${searchParamsString}`
                          );
                        }}
                      >
                        Connect with Us
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Grid
          item
          xs={12}
          textAlign={"center"}
          display="flex"
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            width: isMobile ? "100%" : "80%",
          }}
        >
          {linkMenu}
        </Grid>
      </Grid>
      {isMobile ? null : (
        <Grid
          item
          xs={12}
          lg={6}
          p={isMobile ? 2 : 4}
          pt={isMobile ? 1 : 2}
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <Box
            sx={{
              px: isMobile ? 1 : 2,
              py: isMobile ? 1 : 2,
              background: "#F8F9FA",
              borderRadius: 3,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 16,
              width: isMobile ? "100%" : "80%",
              display: "inline-flex",
            }}
          >
            <CampaignMarketingSignUpPage />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
