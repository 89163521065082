import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { Referral } from "../types";

export const USE_GET_REFERRALS_URI = "/referrals/v2";

export function useGetReferralPartners(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: UseQueryOptions<any>
): UseQueryResult<Array<Referral>> {
  return useQuery({
    queryKey: [USE_GET_REFERRALS_URI],

    queryFn: async () => {
      const resp = await API.get(USE_GET_REFERRALS_URI);
      return resp.data.data;
    },
    ...options,
  });
}
