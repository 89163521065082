import changeCaseKeys from "change-case-keys";
import mixpanel from "mixpanel-browser";

import axiosInstance from "../api";
import { AppConstants } from "../constants";
import { AnalyticsEvents } from "./enums";

export function setupMixPanel(): void {
  if (AppConstants.environment !== "local") {
    mixpanel.init(AppConstants.mixpanelApiToken, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  }
}

export function trackEventOnMixPanel(
  eventName: AnalyticsEvents,
  eventProperties: Record<string, unknown>
): void {
  if (AppConstants.environment !== "local") {
    if (
      navigator.doNotTrack === "1" ||
      window.doNotTrack === "1" ||
      navigator.msDoNotTrack === "1"
    ) {
      axiosInstance.post("/common/send-analytics-event", {
        event_name: eventName,
        event_properties: changeCaseKeys(eventProperties, "underscored"),
      });
    } else {
      mixpanel.track(eventName, eventProperties);
    }
  } else {
    console.info(
      `Skipping event ${eventName} with properties - ${JSON.stringify(
        eventProperties
      )} as env is local`
    );
  }
}

export function identifyUserOnMixPanel(userId: number): void {
  if (AppConstants.environment !== "local") {
    mixpanel.identify(String(userId));
  } else {
    console.info(`skipping identify for ${userId} on mixpanel as env is local`);
  }
}
