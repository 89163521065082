import {
  GridFilterInputValueProps,
  GridRenderEditCellParams,
} from "@mui/x-data-grid-premium";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { useState } from "react";

export function EditTimePicker(props: GridRenderEditCellParams) {
  const { id, value, field, api } = props;
  const [time, setTime] = useState(
    value ? DateTime.fromFormat(value, "HH:mm:ss") : null
  );

  const handleChange = (newValue: DateTime | null) => {
    setTime(newValue);
    if (newValue) {
      api.setEditCellValue({ id, field, value: newValue.toFormat("HH:mm:ss") });
    } else {
      api.setEditCellValue({ id, field, value: null });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <TimePicker
        value={time}
        sx={{
          ".MuiInputBase-input": {
            padding: "8px",
          },
        }}
        onChange={handleChange}
      />
    </LocalizationProvider>
  );
}

export function EditTimePickerFilter(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;

  const handleFilterChange = (newValue: DateTime | null) => {
    applyValue({
      ...item,
      value: newValue ? newValue.toFormat("HH:mm:ss") : null,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <TimePicker
        value={item.value ? DateTime.fromFormat(item.value, "HH:mm:ss") : null}
        slotProps={{
          textField: {
            fullWidth: true,
            sx: {
              padding: "0",
              ".MuiInputBase-root": {
                padding: "12px",
              },
              ".MuiInputBase-input": {
                padding: "0px",
              },
            },
          },
        }}
        onChange={handleFilterChange}
      />
    </LocalizationProvider>
  );
}
