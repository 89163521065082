import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import { User } from "@/auth/types";
import API from "@/common/api";

const createPatchRequests = (
  payloads: Array<{
    userId: number;
    updatedUser: Partial<User>;
  }>
) => {
  return payloads.map(({ userId, updatedUser }) => {
    const userPayload = changeCaseKeys(updatedUser, "underscored");
    const updateUserUri = `/users/${userId}`;
    return API.patch(updateUserUri, userPayload);
  });
};

export function useUpdateUsersById(
  options: UseMutationOptions<
    void,
    AxiosError,
    Array<{ userId: number; updatedUser: Partial<User> }>
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  Array<{ userId: number; updatedUser: Partial<User> }>
> {
  return useMutation({
    mutationFn: async (
      users: Array<{
        userId: number;
        updatedUser: Partial<User>;
      }>
    ): Promise<void> => {
      const patchRequests = createPatchRequests(users);
      await axios.all(patchRequests);
    },
    ...options,
  });
}
