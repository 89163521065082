import { Chip, Typography } from "@mui/material";
import { FC, useState } from "react";

import { isDefined } from "@/utils/isDefined";

import { RoutedJobResponse, RoutedJobStatus } from "../types/job";
import {
  getHomeownerFormattedName,
  getLastActivityRoutedJob,
  JobStatusChipColor,
} from "../utils";
import { CompanyProfileDrawer } from "./CompanyProfileDrawer";
import { JobCardContainer } from "./JobCardContainer";
import { JobDrawerTabsEnum } from "./JobDrawer";
import { PendingDurationCircularProgress } from "./PendingDurationCircularProgress";

interface JobsCardProps {
  job: RoutedJobResponse;
  onClick: () => void;
  changeTab?: (tab?: JobDrawerTabsEnum) => void;
}

export const RoutedJobCard: FC<JobsCardProps> = ({
  job,
  onClick,
  changeTab,
}) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >();

  const [expiringSoon, setExpiringSoon] = useState(false);
  const lastActivity = getLastActivityRoutedJob(job);
  return (
    <>
      <JobCardContainer
        jobId={job.id}
        {...(expiringSoon
          ? { border: "0.50px rgba(240, 68, 56, 0.50) solid" }
          : {})}
        title={`${isDefined(job.job?.jobPromotion?.id) ? "Promotion: " : ""}${
          job.jobType
        }`}
        subTitle={
          <>
            {job.courtesyOf?.companyName ? (
              <>
                {" "}
                Courtesy of{" "}
                <Typography
                  display={"inline"}
                  variant="body2"
                  color="primary"
                  fontWeight={600}
                  sx={{ cursor: "pointer" }}
                  onClick={event => {
                    event.stopPropagation();
                    if (job.courtesyOf) {
                      setSelectedCompanyId(job.courtesyOf.companyId);
                    } else {
                      changeTab?.(JobDrawerTabsEnum.HOMEOWNER);
                    }
                  }}
                >
                  {job.courtesyOf?.companyName}
                </Typography>{" "}
              </>
            ) : null}
            {job.status === RoutedJobStatus.PENDING_REVIEW ||
            !lastActivity ? null : (
              <>
                {" "}
                |
                <Typography
                  display={"inline"}
                  color="text.secondary"
                  variant="body2"
                >
                  {" "}
                  {`Last Activity: ${lastActivity}`}
                </Typography>{" "}
              </>
            )}
            {job.status !== RoutedJobStatus.PENDING_REVIEW &&
            job?.job?.listingExtraInfo?.homeownerName ? (
              <Typography color="text.secondary" variant="body2">
                Homeowner:{" "}
                {getHomeownerFormattedName(
                  job.job.listingExtraInfo.homeownerName
                )}
              </Typography>
            ) : null}
          </>
        }
        statusArea={
          job.status === RoutedJobStatus.PENDING_REVIEW ? (
            <PendingDurationCircularProgress
              expiresAt={job.expiresAt}
              setExpiringSoon={setExpiringSoon}
            />
          ) : (
            <Chip
              label={
                <Typography
                  variant="overline"
                  sx={{ fontSize: "12px", fontWeight: 600 }}
                >
                  {job.status === RoutedJobStatus.PAID
                    ? "PAID"
                    : job.status.toString().toUpperCase().replace("_", " ")}
                </Typography>
              }
              sx={{
                bgcolor: JobStatusChipColor[job.status].bgcolor,
                color: JobStatusChipColor[job.status].color,
                height: "24px",
              }}
            />
          )
        }
        onClick={onClick}
      />
      {selectedCompanyId ? (
        <CompanyProfileDrawer
          open={!!selectedCompanyId}
          companyId={selectedCompanyId}
          onClose={() => {
            setSelectedCompanyId(undefined);
          }}
        />
      ) : null}
    </>
  );
};
