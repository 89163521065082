import { Chat } from "./api/useFetchChats";
import { JobSectors } from "./jobs/constants";
import { Job } from "./jobs/types";

export enum CompanyRoutes {
  BASE = "company",
  DASHBOARD_HOME = "home",
  COMPANY_SETTINGS = "settings",
  WELCOME = "welcome",
  MESSAGES = "messages",
  JOB_CALLS = "job-calls",
  JOB_CALLS_MODAL = "job-calls-modal",
  SUPPORT_MESSAGES = "support-messages",
  MANAGE_PARTNERS = "manage-partners",
  REFERRAL_PARTNERS = "referral-partners",
  SUGGESTED_PARTNERS = "suggested-partners",
  ALL_JOBS = "all-jobs",
  MY_JOBS = "my-jobs",
  CSR_JOBS = "csr-jobs",
  EXPLORE_PARTNERS = "explore-partners",
  PROFILE = "profile",
  TEAM_MEMBERS = "team-members",
  MY_WALLET = "my-wallet",
  TECHNICIAN_DEMO = "technician-demo",
  LEADERBOARD = "leaderboard",
  ANALYTICS = "analytics",
  JOB_PROMOTIONS = "job-promotions",
  HUBS = "hubs",
}

export enum COMPANY_ACCOUNT_TYPES {
  INDEPENDENT_CONTRACTOR = "INDEPENDENT_CONTRACTOR",
  BRAND_LOCATION = "BRAND_LOCATION",
  CONSULTANT = "CONSULTANT",
  SUPERADMIN = "SUPERADMIN",
  PRIVATE_EQUITY = "PRIVATE_EQUITY",
  FAMILY_BRANDS = "FAMILY_BRANDS",
  BRAND = "BRAND",
  INDEPENDENT_COMPANY = "INDEPENDENT_COMPANY",
  HOLDING_COMPANY = "HOLDING_COMPANY",
}

export enum PLATFORM_PRESENCE {
  ON_PLATFORM = "ON_PLATFORM",
  OFF_PLATFORM = "OFF_PLATFORM",
}

export enum CompanyStatus {
  NEW = "NEW",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface CompanyAddress {
  zipCode: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  country?: string;
}
export interface Company {
  id?: number;
  accountType?: COMPANY_ACCOUNT_TYPES;
  zipCode?: string;
  description?: string;
  name?: string;
  companyAddress?: CompanyAddress;
  sectors?: Array<JobSectors>;
  yearEstablished?: number;
  numberEmployees?: number;
  websiteUrl?: string;
  defaultTechnicianNeedsJobApproval?: boolean;
  logoUrl?: string;
  openNetworkEnabled?: boolean;
  invitationCode?: string;
  balance?: number;
  invitationLink?: string;
  createdByCampaignId?: string;
  status?: CompanyStatus;
  notes?: string;
  googlePlacesId?: string;
}

export enum BRAND_CATEGORY {
  FRANCHISE = "FRANCHISE",
  INDEPENDENT = "INDEPENDENT",
}

export enum HOMEOWNER_APPROVAL_SETTING {
  ROUTE_ALL_JOBS_TO_HOME_OWNER = "ROUTE_ALL_JOBS_TO_HOME_OWNER",
  DONT_ROUTE_ANY_JOB_TO_HOME_OWNER = "DONT_ROUTE_ANY_JOB_TO_HOME_OWNER",
  TECHNICIANS_DECISION_TO_ROUTE = "TECHNICIANS_DECISION_TO_ROUTE",
}

export enum PayReferralFeeSettings {
  PERCENTAGE_OF_SALE_PRICE = "PERCENTAGE_OF_SALE_PRICE",
  FIXED_AMOUNT = "FIXED_AMOUNT",
  DEPENDING_ON_JOB_AND_REFERRAL_PARTNER = "DEPENDING_ON_JOB_AND_REFERRAL_PARTNER",
}

export enum CHANNEL_ONBOARDED {
  REFERRAL_INVITATION = "REFERRAL_INVITATION",
  LANDING_PAGE = "LANDING_PAGE",
  TRADEENGAGE_CAMPAIGN = "TRADEENGAGE_CAMPAIGN",
}

export enum COMPANY_PRIORITY {
  TIER_1 = "TIER_1",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export enum SMS_STATUS {
  SENT = "SENT",
  FAILED = "FAILED",
  CARRIER_TYPE_CANT_RECEIVE_SMS = "CARRIER_TYPE_CANT_RECEIVE_SMS",
}

export interface BrandLocationProfile {
  id?: number;
  inboundSectors?: Array<JobSectors>;
  outboundSectors?: Array<JobSectors>;
  businessEmail?: string;
  businessPhone?: string;
  businessPhoneCarrierType?: CarrierType;
  defaultTechnicianSplit?: number;
  openNetworkEnabled?: boolean;
  googleLocalServicesUrl?: string;
  googlePlacesId?: string;
  brandCategory?: BRAND_CATEGORY;
  payReferralFeeAfterJobIsDone?: boolean;
  invitationMessage?: string;
  homeownerJobApprovalSettings?: HOMEOWNER_APPROVAL_SETTING;
  payReferralFeeSettings?: PayReferralFeeSettings;
  schedulingUrl?: string;
  showExplorePartners?: boolean;
  callAnalyticsEnabled?: boolean;
  showJobAnalytics?: boolean;
  startedOnboardingAt?: Date;
  payPerReferral?: boolean;
  upsellingEnabled?: boolean;
}

export enum DefaultCompanyGroups {
  ADMINISTRATOR = "ADMINISTRATOR",
  TEAM_MEMBER = "TEAM_MEMBER",
  JOB_APPROVER = "JOB_APPROVER",
  FIELD_TEAM = "FIELD_TEAM",
  OWNER = "OWNER",
}

export const canReceiveSms = (carrierType: CarrierType) => {
  if (
    carrierType === CarrierType.MOBILE ||
    carrierType === CarrierType.PERSONAL ||
    carrierType === CarrierType.PREMIUM ||
    carrierType === CarrierType.PAGER ||
    carrierType === CarrierType.VOIP ||
    carrierType === CarrierType.TOLLFREE ||
    carrierType === CarrierType.FIXEDVOIP
  ) {
    return true;
  }
  return false;
};

export const DefaultCompanyGroupReadableNames = {
  [DefaultCompanyGroups.ADMINISTRATOR]: "Administrator",
  [DefaultCompanyGroups.TEAM_MEMBER]: "Team Member",
  [DefaultCompanyGroups.JOB_APPROVER]: "Job Approver",
  [DefaultCompanyGroups.FIELD_TEAM]: "Field Team",
  [DefaultCompanyGroups.OWNER]: "Owner",
};

export interface CompanyGroup {
  id: number;
  name: string;
  canBeDeleted: boolean;
}

export interface CompanySector {
  id: number;
  sector: JobSectors;
  serviceTypes?: Array<string>;
  buyingPrice: number;
  leadPrice: number;
  percentagePrice: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface FddRecord {
  private_equity: string;
  family_brands: string;
  family_brands_company_name: string;
  brand: string;
  business_model: string;
  company_sector: string;
  brand_location_name: string;
  zip_code?: number;
}

export enum CarrierType {
  LANDLINE = "LANDLINE",
  MOBILE = "MOBILE",
  VOIP = "VOIP",
  NONFIXEDVOIP = "NONFIXEDVOIP",
  FIXEDVOIP = "FIXEDVOIP",
  PERSONAL = "PERSONAL",
  TOLLFREE = "TOLLFREE",
  PREMIUM = "PREMIUM",
  SHAREDCOST = "SHAREDCOST",
  UAN = "UAN",
  VOICEMAIL = "VOICEMAIL",
  PAGER = "PAGER",
  UNKNOWN = "UNKNOWN",
}

export interface BasicOwner {
  firstName: string;
  lastName?: string;
  hasPhone?: boolean;
  hasEmail?: boolean;
  phone?: string;
  email?: string;
  phoneCarrierType?: CarrierType;
}

export interface ExternalBasicCompanySchema {
  id: number;
  name: string;
  logoUrl?: string;
  accountType: COMPANY_ACCOUNT_TYPES;
  platformPresence: PLATFORM_PRESENCE;
}

export interface CompanyPublicProfile {
  id: number;
  name: string;
  fddRecordId?: string;
  logoUrl?: string;
  verified: boolean;
  accountType: COMPANY_ACCOUNT_TYPES;
  platformPresence?: PLATFORM_PRESENCE;
  profile: BrandLocationProfile;
  numberEmployees: number;
  owners?: Array<BasicOwner>;
  brand?: ExternalBasicCompanySchema;
  familyOfBrands?: ExternalBasicCompanySchema;
  yearEstablished?: number;
  parentLogoUrl?: string;
  numEmployees?: number;
  isFranchise: boolean;
  receivingJobsPercentage: number;
  sendingJobsPercentage: number;
  mutualReferralPartners: Array<number>;
  totalPartners?: number;
  sectors: Array<CompanySector>;
  googlePlacesId?: string;
  googleLocalServicesUrl?: string;
  totalReferralPartners?: number;
  matchingRecommendedReferralJobSectors?: Array<JobSectors>;
  opportunitySectors?: Array<JobSectors>;
  competitorSectors?: Array<JobSectors>;
  description?: string;
  lastActivity?: string;
  googleReviewRating?: number;
  streetAddress?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  websiteUrl?: string;
  activePromotionsCount?: number;
}

export interface BaseSearchCompanyProfile {
  id: number;
  name: string;
  logoUrl?: string;
  zipCode: string;
  sectors: Array<JobSectors>;
}

export interface SearchCompanyProfile {
  id: number;
  name: string;
  brand?: BaseSearchCompanyProfile;
  familyOfBrands?: BaseSearchCompanyProfile;
  logoUrl?: string;
  zipCode: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  websiteUrl?: string;
  sectors: Array<JobSectors>;
  googlePlacesId?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface

export interface GoogleImage {
  title?: string;
  thumbnail: string;
}

export interface GoogleUserReview {
  username?: string;
  rating?: number;
  description?: string;
  images?: Array<GoogleImage>;
  date?: string;
}

export interface GoogleUserReviews {
  mostRelevant?: Array<GoogleUserReview>;
}
export interface GoogleCompanyProfile {
  google_places_id: string;
  sectors?: JobSectors[];
  name?: string;
  logoUrl?: string;
  rating?: number;
  userReviews?: GoogleUserReviews;
  reviewsCount?: number;
}

export interface ReferralInvitation {
  id: number;
  invitingCompanyId: number;
  invitedCompanyId: number;
  createdByUserId: number;
  declinedReason?: string;
  createdAt?: Date;
  declinedAt?: Date;
  acceptedAt?: Date;
  otherCompanyProfile: CompanyPublicProfile;
  chat?: Chat;
}

export interface Referral {
  id: number;
  referringCompanyId: number;
  referredCompanyId: number;
  createdByUserId: number;
  sector: JobSectors;
  order: number;
  createdAt: Date;
  buyingPrice: number;
  otherCompanyProfile: CompanyPublicProfile;
  chat?: Array<Chat>;
}

export enum ReferralInvitationActions {
  ACCEPT = "ACCEPT",
  IGNORE = "DECLINE",
}
export interface ExternalCompanyProfile {
  logoUrl?: string;
  googlePlacesId: string;
  name: string;
  address?: string;
  description?: string;
  rating?: number;
  sectors: Array<JobSectors>;
  tradeEngageCompanyId?: number;
  website?: string;
  phone?: string;
}

export enum ConnectionStatus {
  CONNECTED,
  NOT_CONNECTED,
  INTERESTED,
  PENDING,
  INVITED,
}

export interface CompanyCard {
  brand: string;
  lastFourDigits: string;
  expirationMonth: number;
  expirationYear: number;
  cardholderName?: string;
}

export enum CompanyTransactionConcepts {
  CREDITS_REPLENISHED = "CREDITS_REPLENISHED",
  STRIPE_PROCESSING_FEE = "STRIPE_PROCESSING_FEE",
  TECHNICIAN_SPLIT = "TECHNICIAN_SPLIT",
  REFERRAL_FEE_EARNED = "REFERRAL_FEE_EARNED",
  JOB_PURCHASED = "JOB_PURCHASED",
  SIGNUP_BONUS = "SIGNUP_BONUS",
  THIRD_REFERRAL_EARNED_BONUS = "THIRD_REFERRAL_EARNED_BONUS",
  REFERRAL_PURCHASED = "REFERRAL_PURCHASED",
}

export interface CompanyTransaction {
  id: number;
  companyId: number;
  amount: number;
  oldBalance: number;
  newBalance: number;
  concept: CompanyTransactionConcepts;
  createdAt: Date;
  job: Job;
}

export interface CompanyProfileCompleteness {
  percentage: number;
  mobileVerificationCompleted: boolean;
  serviceDetailsCompleted: boolean;
  companyProfileCompleted: boolean;
  referralPartnersCompleted: boolean;
  inviteTeamMembersCompleted: boolean;
}
