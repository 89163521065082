import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  CachedOutlined as CachedOutlinedIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
} from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import * as yup from "yup";

import { RadioGroupField } from "@/lib/form-fields/RadioGroupField";

import {
  referralCampaignNotificationChannelLabels,
  referralCampaignSendUpdatesFrequencyLabels,
  referralCampaignTargetParticipantsLabels,
} from "../../constants";
import {
  ReferralCampaignNotificationChannel,
  ReferralCampaignSendUpdatesFrequency,
  ReferralCampaignTargetParticipants,
} from "../../types";
import { AddReferralCampaignFormInputField } from "./AddReferralCampaignFormInputField";

export const referralCampaignAdditionalSettingsFormSchema = yup.object().shape({
  notificationChannel: yup
    .mixed<ReferralCampaignNotificationChannel>()
    .required("Notification Channel is required"),
  sendUpdatesFrequency: yup
    .mixed<ReferralCampaignSendUpdatesFrequency>()
    .required("Send Updates Frequency is required"),
  targetParticipants: yup
    .mixed<ReferralCampaignTargetParticipants>()
    .required("Target Participants is required"),
});

export type ReferralCampaignAdditionalSettingsFormFields = yup.InferType<
  typeof referralCampaignAdditionalSettingsFormSchema
>;

export function ReferralCampaignAdditionalSettingsForm() {
  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant="overline" fontWeight={600} color="text.disabled">
          COMMUNICATION SETTINGS
        </Typography>
        <AddReferralCampaignFormInputField
          title="Notification Channel"
          icon={<NotificationsOutlinedIcon />}
        >
          <RadioGroupField
            row
            name="notificationChannel"
            options={[
              ReferralCampaignNotificationChannel.TEXT,
              ReferralCampaignNotificationChannel.EMAIL,
              ReferralCampaignNotificationChannel.IN_APP,
            ].map(channel => ({
              label: referralCampaignNotificationChannelLabels[channel],
              value: channel,
            }))}
          />
        </AddReferralCampaignFormInputField>
      </Stack>
      <AddReferralCampaignFormInputField
        title="Send Updates"
        icon={<CachedOutlinedIcon />}
      >
        <RadioGroupField
          row
          name="sendUpdatesFrequency"
          options={[
            ReferralCampaignSendUpdatesFrequency.DAILY,
            ReferralCampaignSendUpdatesFrequency.WEEKLY,
            ReferralCampaignSendUpdatesFrequency.MONTHLY,
          ].map(frequency => ({
            label: referralCampaignSendUpdatesFrequencyLabels[frequency],
            value: frequency,
          }))}
        />
      </AddReferralCampaignFormInputField>
      <AddReferralCampaignFormInputField
        includeConnector={false}
        title="Target Participants"
        icon={<AccountCircleOutlinedIcon />}
      >
        <RadioGroupField
          row
          name="targetParticipants"
          options={[
            ReferralCampaignTargetParticipants.ADMIN_AND_OWNERS,
            ReferralCampaignTargetParticipants.OPERATIONS,
            ReferralCampaignTargetParticipants.FIELD_TEAM,
          ].map(participant => ({
            label: referralCampaignTargetParticipantsLabels[participant],
            value: participant,
          }))}
        />
      </AddReferralCampaignFormInputField>
    </Stack>
  );
}
