import AddIcon from "@mui/icons-material/Add";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { Box, Button, Divider, Typography } from "@mui/material";
import { FC, useState } from "react";

import { BrandLocationUserGroup } from "@/company/api/useGetUsersByCompanyId";
import { PLATFORM_PRESENCE } from "@/company/types";

import { AddUserToGroupDialog } from "./AddUserToGroup";
import UserTable from "./UserTable";

interface OverviewProps {
  usersWithGroups: Array<BrandLocationUserGroup>;
  createNewChatThread: (userId: number) => void;
  companyId: number;
  overview: any;
  isFetchingProfile?: boolean;
  expanded?: boolean;
  refetch: () => void;
}

export const UserOverview: FC<OverviewProps> = ({
  usersWithGroups,
  createNewChatThread,
  companyId,
  overview,
  isFetchingProfile,
  expanded,
  refetch,
}) => {
  const [userDialogOpen, setUserDialogOpen] = useState(false);

  return (
    <>
      <Box
        display={"flex"}
        pb={2}
        flexDirection={"column"}
        width={"50%"}
        pl={2}
      >
        <Box display={"flex"} alignItems={"center"} pb={1}>
          <Typography variant="h6">{overview.name}</Typography>
          {overview.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM ? (
            <VerifiedOutlinedIcon
              sx={{
                verticalAlign: "center",
                color: "#16DB65",
                ml: 0.5,
                height: "20px",
              }}
            />
          ) : null}
        </Box>
        <Typography variant="overline" color="text.secondary" mb={1}>
          Overview
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={5}
        >
          <Box width="70%" display={"flex"} alignItems={"center"} gap={1}>
            <GroupOutlinedIcon sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Associated Users
            </Typography>
          </Box>
          <Typography fontSize={"12px"} fontWeight={500}>
            {
              usersWithGroups?.filter(
                userWithGroup =>
                  userWithGroup.user.platformPresence ===
                  PLATFORM_PRESENCE.ON_PLATFORM
              ).length
            }{" "}
            of {usersWithGroups?.length}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={5}
        >
          <Box width="70%" display={"flex"} alignItems={"center"} gap={1}>
            <GroupsOutlinedIcon sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              All Users
            </Typography>
          </Box>
          <Typography fontSize={"12px"} fontWeight={500}>
            {usersWithGroups?.length}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: "8px",
          /* elevation/outlined */
          boxShadow: "0px 0px 0px 1px #F2F4F7",
        }}
        pl={2}
        py={1}
      >
        <Typography variant="overline" color="rgba(17, 25, 39, 0.38)" py={2}>
          All Users
        </Typography>
        <Box py={2}>
          <Button
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setUserDialogOpen(true);
            }}
          >
            Add User To Group
          </Button>
          <Divider sx={{ width: "100%", mt: 2 }} />
        </Box>
        <UserTable
          expanded={expanded}
          companyId={companyId}
          refetch={refetch}
          users={usersWithGroups}
          createNewChatThread={createNewChatThread}
        />
      </Box>
      {userDialogOpen ? (
        <AddUserToGroupDialog
          companyId={companyId}
          open={userDialogOpen}
          refetch={() => {
            refetch();
          }}
          onClickConfirm={() => {
            setUserDialogOpen(false);
          }}
          onClickCancel={() => {
            setUserDialogOpen(false);
          }}
          onClickClose={() => {
            setUserDialogOpen(false);
          }}
        />
      ) : null}
    </>
  );
};
