export interface StrategicPartner {
  company: {
    id: number;
    name: string;
    logoUrl: string;
    state: string;
  };
  users: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }[];
  googleReviewsCount: number;
  googleReviewsRating: number;
  googlePlacesId: string;
}

export enum FilterType {
  GOOGLE_RATINGS = "googleRatings",
  GOOGLE_REVIEWS = "googleReviews",
}
