import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import API from "@/common/api";

export type CreateOCRJobData = {
  jobId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imageFile: any;
};

export interface OCRJobDetails {
  firstName?: string;
  lastName?: string;
  phone?: string;
  address?: string;
}
export function useGetOCR(
  options: UseMutationOptions<OCRJobDetails, AxiosError, CreateOCRJobData> = {}
): UseMutationResult<OCRJobDetails, AxiosError, CreateOCRJobData> {
  return useMutation({
    mutationFn: async (data): Promise<OCRJobDetails> => {
      const videoMimeType = data.imageFile.type.replace("image/", "");

      // upload video
      const uploadUrlApi =
        `jobs/${data.jobId}/homeowner-ocrs-url?image_mime_type=` +
        videoMimeType;
      const UploadUrlResponse = await API.get(uploadUrlApi);
      const uploadResponse = UploadUrlResponse.data.data;
      await axios.put(uploadResponse.url, data.imageFile);

      // submit new job

      const response = await API.get(
        `/homeowner/extract-information-from-ocr?object_key=${uploadResponse.objectKey}`
      );
      return response.data.data;
    },
    ...options,
  });
}
