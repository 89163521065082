import {
  Add as AddIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  DragIndicatorOutlined as DragIndicatorOutlinedIcon,
  EmojiEventsOutlined as EmojiEventsOutlinedIcon,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FieldArray, useField } from "formik";
import * as yup from "yup";

import { AutocompleteField } from "@/lib/form-fields/AutocompleteField";
import { TextInputField } from "@/lib/form-fields/TextInputField";

import { referralCampaignPrizeTypeLabels } from "../../constants";
import {
  ReferralCampaignPrize,
  referralCampaignPrizeSchema,
  ReferralCampaignPrizeType,
} from "../../types";
import { getOrdinalSuffix } from "../../utils";
import { AddReferralCampaignFormInputField } from "./AddReferralCampaignFormInputField";

export const referralCampaignPrizeFormSchema = yup.object().shape({
  prizes: yup.array().of(referralCampaignPrizeSchema).required(),
});

export type ReferralCampaignPrizeFormFields = yup.InferType<
  typeof referralCampaignPrizeFormSchema
>;

export function ReferralCampaignPrizeForm() {
  const [field] = useField("prizes");

  return (
    <AddReferralCampaignFormInputField
      includeConnector={false}
      title="Compensation/prize"
      icon={<EmojiEventsOutlinedIcon />}
    >
      <FieldArray name="prizes">
        {({ push, remove }) => {
          return (
            <>
              <Stack gap={1}>
                {field.value.map(
                  (_prize: ReferralCampaignPrize, index: number) => (
                    <Grid
                      key={index}
                      container
                      gap={0.5}
                      sx={{
                        paddingY: 1,
                        borderBottom: theme =>
                          `1px solid ${theme.palette.neutral[100]}`,
                      }}
                    >
                      <Grid item xs={1.25}>
                        <Chip
                          sx={{
                            height: "56px",
                            paddingY: 1,
                            borderRadius: 1,
                            "& .MuiChip-label": {
                              display: "block",
                              whiteSpace: "normal",
                            },
                          }}
                          label={
                            <Stack gap={0.5} alignItems="center">
                              <Typography variant="caption" fontWeight={500}>
                                Rank
                              </Typography>
                              <Typography variant="h6" fontWeight={700}>
                                {index + 1}
                                {getOrdinalSuffix(index + 1)}
                              </Typography>
                            </Stack>
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <AutocompleteField
                          disableClearable
                          name={`prizes.${index}.prizeType`}
                          label="Prize Type"
                          options={[
                            ReferralCampaignPrizeType.CASH,
                            ReferralCampaignPrizeType.GIFTCARD,
                            ReferralCampaignPrizeType.TICKETS,
                            ReferralCampaignPrizeType.OTHERS,
                          ].map(prizeType => ({
                            label: referralCampaignPrizeTypeLabels[prizeType],
                            value: prizeType,
                          }))}
                        />
                      </Grid>
                      <Grid item xs={5.5}>
                        <TextInputField
                          fullWidth
                          name={`prizes.${index}.prizeName`}
                          label="Prize"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={1.75}>
                        <Stack
                          direction="row"
                          gap={0.5}
                          sx={{ alignItems: "center", height: "56px" }}
                        >
                          <IconButton>
                            <DragIndicatorOutlinedIcon />
                          </IconButton>
                          <IconButton
                            disabled={field.value.length === 1}
                            onClick={() => remove(index)}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  )
                )}
              </Stack>
              <Button
                disableRipple
                size="small"
                variant="outlined"
                color="secondary"
                startIcon={<AddIcon />}
                sx={{ borderRadius: 1, marginTop: 3 }}
                disabled={field.value.length >= 4}
                onClick={() =>
                  push({
                    prizeType: ReferralCampaignPrizeType.CASH,
                    prizeName: "",
                  })
                }
              >
                Add New
              </Button>
            </>
          );
        }}
      </FieldArray>
    </AddReferralCampaignFormInputField>
  );
}
