import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

import { type CompanyCard } from "../types";

export function useCompanyCreateCard(
  options: UseMutationOptions<CompanyCard, AxiosError, string> = {}
): UseMutationResult<CompanyCard, AxiosError, string> {
  return useMutation({
    mutationFn: async (token: string): Promise<CompanyCard> => {
      const response = await API.post(`company-payments/cards`, {
        token,
      });
      return response.data.data;
    },
    ...options,
  });
}
