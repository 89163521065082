import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useCloseJob(
  options: UseMutationOptions<void, AxiosError, number> = {}
): UseMutationResult<void, AxiosError, number> {
  return useMutation({
    mutationFn: async (jobId: number): Promise<void> => {
      await API.delete(`jobs/${jobId}`);
    },
    ...options,
  });
}
