/* eslint-disable jsx-a11y/media-has-caption */
import { ArrowRight, TimerOutlined } from "@mui/icons-material";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { AuthRoutes } from "@/auth/constants";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useGetOpenJobById } from "@/company/jobs/api/useGetOpenJobById";
import { JobSectors } from "@/company/jobs/constants";
import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { COMPANY_ACCOUNT_TYPES, CompanyRoutes } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { useLeadContext } from "@/lead/context/lead-context";
import { neutral } from "@/Theme/colors";

import { getFileExtension } from "../../../../utils";

export function ClaimJobLandingPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { updateLeadDetails } = useLeadContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    updateLeadDetails(
      {
        name: searchParams.get("name") ?? undefined,
        zipCode: searchParams.get("zipcode") ?? undefined,
        sectors: searchParams.get("sectors")
          ? (searchParams.get("sectors")?.split(",") as JobSectors[])
          : undefined,
        utmCampaign: searchParams.get("utm_campaign") ?? undefined,
        utmMedium: searchParams.get("utm_medium") ?? undefined,
        utmSource: searchParams.get("utm_source") ?? undefined,
        accountType:
          searchParams.get("account_type") &&
          (searchParams.get("account_type") as COMPANY_ACCOUNT_TYPES)
            ? (searchParams.get("account_type") as COMPANY_ACCOUNT_TYPES)
            : undefined,
        initialJobRoutedId: id && parseInt(id) ? parseInt(id) : undefined,
        redirectTo:
          searchParams.get("account_type") &&
          (searchParams.get("account_type") as COMPANY_ACCOUNT_TYPES)
            ? getNavigationPathFromRoutePaths([
                CompanyRoutes.BASE,
                ONBOARDING_ROUTES.BASE,
                ONBOARDING_STAGES.SERVICE_DETAILS,
              ])
            : getNavigationPathFromRoutePaths([
                AuthRoutes.BASE,
                AuthRoutes.USER_TYPE,
              ]),
      },
      true
    );
  }, [searchParams, id]);

  const {
    data: job,
    isFetching: isFetchingJobDetails,
    isError: isErrorFetchingJobDetails,
  } = useGetOpenJobById(id && parseInt(id) ? parseInt(id) : null, {
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });

  if (isErrorFetchingJobDetails) {
    enqueueSnackbar({
      message:
        "There was an error while trying to fetch the details of the job.",
      variant: "error",
    });
    return (
      <Grid
        container
        mt={2}
        minHeight={"calc(100vh - 205px)"}
        overflow={"auto"}
      >
        <Typography color="error">
          There Was an Error, please try again later!
        </Typography>
      </Grid>
    );
  }

  if (!id) {
    enqueueSnackbar({
      message:
        "There was an error while trying to fetch the details of the job.",
      variant: "error",
    });
    return (
      <Grid
        container
        mt={2}
        minHeight={"calc(100vh - 205px)"}
        overflow={"auto"}
      >
        <Typography color="error">The Link seems to be invalid!</Typography>
      </Grid>
    );
  }

  if (isFetchingJobDetails) {
    return (
      <Grid
        container
        mt={2}
        minHeight={"calc(100vh - 205px)"}
        overflow={"auto"}
      >
        <Box my={5} textAlign="center" width="100%">
          <CircularProgress />
        </Box>
      </Grid>
    );
  }

  const handleVideoButtonClick = () => {
    const videoUrl =
      "https://tradeengage-app.s3.amazonaws.com/videos/You're+Invited+to+Join+TradeEngage.mp4";
    window.open(videoUrl, "_blank");
  };

  return (
    <Grid
      container
      minHeight={"calc(100vh - 205px)"}
      overflow={"auto"}
      display="flex"
      justifyContent={"space-between"}
      maxWidth={"lg"}
      mx={"auto"}
    >
      <Grid
        item
        xs={12}
        lg={6}
        p={isMobile ? 2 : 4}
        pt={isMobile ? 1 : 2}
        textAlign={"center"}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          alignItems={"start"}
          maxWidth={"456px"}
          width={"100%"}
          py={2}
          gap={1}
        >
          <Chip
            icon={
              <AutoFixHighOutlinedIcon sx={{ color: "#16B364 !important" }} />
            }
            label={
              <Typography
                variant="body2"
                display={"inline"}
                sx={{ color: "#16B364", textOverflow: "ellipsis" }}
              >
                Referral Management Platform for the Trades
              </Typography>
            }
            sx={{
              background: "rgba(22, 179, 100, 0.08)",
              color: "#16B364",
              borderRadius: "12px",
            }}
          />
          <Typography variant="h2" textAlign={"start"}>
            You have a new {job?.jobType} job on TradeEngage!
          </Typography>
          <Box textAlign={"start"}>
            <Typography variant="body1" color="text.secondary">
              TradeEngage is your solution to connect with trusted referral
              partners. Receive jobs and boost your earnings from jobs you
              refer. <strong>Built exclusively for the trades.</strong>
            </Typography>
          </Box>
          <Box textAlign={"left"} my={3}>
            <Typography gutterBottom variant="h6">
              Job Type: {job?.jobType}
            </Typography>
            <Typography gutterBottom variant="body1" color="text.secondary">
              Click the{" "}
              {getFileExtension(job?.videoUrl) === "mp3" ? "audio" : "video"} to
              {getFileExtension(job?.videoUrl) === "mp3" ? "get" : "watch"} a
              quick summary of the job.
            </Typography>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            {getFileExtension(job?.videoUrl) === "mp3" ? (
              <audio
                controls
                controlsList="nodownload"
                style={{
                  width: "100%",
                  borderRadius: 3,
                  background: "white",
                  marginTop: "16px",
                  border: "1px solid black",
                }}
              >
                <source src={`${job?.videoUrl}`} type="audio/mpeg" />
              </audio>
            ) : (
              <video
                controls
                playsInline
                controlsList="nodownload"
                style={{
                  width: "100%",
                  height: "280px",
                  borderRadius: 3,
                  background: "white",
                  marginTop: "16px",
                  border: "1px solid black",
                }}
              >
                <source src={`${job?.videoUrl}#t=0.1`} type="video/mp4" />
              </video>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          p={4}
          m={2}
          sx={{ borderRadius: "24px", backgroundColor: neutral[50] }}
        >
          <Typography mb={1.5} variant="h6">
            Ready to take on this Job?
          </Typography>
          <Alert
            color="error"
            icon={<TimerOutlined />}
            sx={{
              borderRadius: "24px",
              color: (theme: Theme) => theme.palette.error.dark,
              width: "fit-content",
            }}
          >
            {"Exclusively yours for 24 hours!"}
          </Alert>
          <Box display="flex" alignItems={"center"} my={2}>
            <Typography variant="subtitle2" mr={2}>
              Service Category
            </Typography>
            <Typography variant="body2">
              {job?.sector
                ? snakeCaseJobSectors[job?.sector as JobSectors]
                : null}
            </Typography>
          </Box>
          <Divider sx={{ width: "100%" }} />
          <Box my={2}>
            <Typography mb={1.5} variant="subtitle2">
              TradeEngage Job Summary
            </Typography>
            <Typography variant="body2">{job?.summary}</Typography>
          </Box>
          <Divider sx={{ width: "100%" }} />
          <Box
            display={"flex"}
            width={"100%"}
            py={1}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="subtitle2">
              Get in touch with the homeowner today
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{
                width: "fit-content",
                cursor: "pointer",
              }}
              endIcon={<ArrowRight />}
              onClick={() => {
                navigate(
                  getNavigationPathFromRoutePaths([
                    AuthRoutes.BASE,
                    AuthRoutes.SIGN_UP,
                  ])
                );
              }}
            >
              Chat With The Homeowner
            </Button>
          </Box>
          <Divider sx={{ width: "100%" }} />
          <Box
            display={"flex"}
            width={"100%"}
            py={1}
            justifyContent={"space-between"}
            alignItems={"center"}
            textAlign={"start"}
          >
            <Typography
              variant="subtitle2"
              {...(isMobile ? { fontSize: "12px" } : { fontSize: "14px" })}
            >
              How does TradeEngage work?
            </Typography>
            <Button
              variant="outlined"
              size="small"
              sx={{
                width: "fit-content",
              }}
              endIcon={<PlayCircleFilledWhiteOutlinedIcon />}
              onClick={handleVideoButtonClick}
            >
              Watch Video
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
