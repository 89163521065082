/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Button, Theme, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useRef } from "react";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { Languages } from "@/common/constants/languages";
import { JobDetailsPromotionForm } from "@/company/job-promotions/components/JobDetailsPromotionForm";
import { JobUploadTypes, useCreateJob } from "@/company/jobs/api/useCreateJob";
import { Job } from "@/company/jobs/types";

import { JobDetailsAudioForm } from "./JobDetailsAudioForm";
import { JobDetailsTextForm } from "./JobDetailsTextForm";
import { JobDetailsVideoForm } from "./JobDetailsVideoForm";
import { JobUploadButton } from "./JobUploadButton";

export interface JobMediaUploadProps {
  videoFile: File | null;
  setVideoFile: (file: File | null) => void;
  transcription: string | undefined;
  setTranscription: (text: string) => void;
  mediaType: JobUploadTypes;
  setMediaType: (type: JobUploadTypes) => void;
  defaultSummary?: string;
  createdForCompanyId?: number;
  homeId?: number;
  onSubmit: (job: Job) => void;
}

export function JobMediaUploadWeb(props: JobMediaUploadProps) {
  const {
    defaultSummary,
    onSubmit,
    videoFile,
    setVideoFile,
    transcription,
    setTranscription,
    mediaType,
    setMediaType,
  } = props;
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const fileInputRef = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileChange = (event: ChangeEvent<any>) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.includes("video/")) {
        setVideoFile(file);
        event.target.value = null;
      } else {
        enqueueSnackbar({
          message: "Invalid file type. Please upload a video file.",
          variant: "error",
        });
        event.target.value = null;
      }
    }
  };

  useEffect(() => {
    const shouldReopenModal = localStorage.getItem("reOpenModal");
    if (shouldReopenModal === "true") {
      setMediaType(JobUploadTypes.AUDIO);
      localStorage.removeItem("reOpenModal");
    }
  }, []);

  useEffect(() => {
    if (defaultSummary) {
      setTranscription(defaultSummary);
      setMediaType(JobUploadTypes.TEXT);
    }
  }, [defaultSummary]);

  const jobUploadTypeOptions = isMobile
    ? [
        { label: "Video", value: JobUploadTypes.VIDEO },
        { label: "Audio", value: JobUploadTypes.AUDIO },
        { label: "Promos", value: JobUploadTypes.JOB_PROMOTION },
        { label: "Text", value: JobUploadTypes.TEXT },
      ]
    : [
        { label: "Text", value: JobUploadTypes.TEXT },
        { label: "Promos", value: JobUploadTypes.JOB_PROMOTION },
        { label: "Video", value: JobUploadTypes.VIDEO },
        { label: "Audio", value: JobUploadTypes.AUDIO },
      ];

  const { isLoading: isCreatingJob, mutateAsync: createNewJob } = useCreateJob({
    onSuccess: (data: Job) => {
      onSubmit(data);
    },
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while creating ${
          session?.user?.type === USER_TYPE.HOMEOWNER
            ? "your project"
            : "the job"
        }.`,
        variant: "error",
      });
    },
  });

  const renderJobDetailsForm = () => {
    if (mediaType === JobUploadTypes.AUDIO) {
      return (
        <JobDetailsAudioForm
          setVideoFile={setVideoFile}
          videoFile={videoFile}
          isCreatingJob={isCreatingJob}
        />
      );
    }

    if (mediaType === JobUploadTypes.VIDEO) {
      return (
        <JobDetailsVideoForm
          setVideoFile={setVideoFile}
          videoFile={videoFile}
          fileInputRef={fileInputRef}
          isCreatingJob={isCreatingJob}
        />
      );
    }

    if (mediaType === JobUploadTypes.JOB_PROMOTION) {
      return <JobDetailsPromotionForm onSubmit={onSubmit} />;
    }

    return (
      <JobDetailsTextForm
        isCreatingJob={isCreatingJob}
        transcription={transcription}
        setTranscription={setTranscription}
      />
    );
  };

  return (
    <Box width="100%" pt={3}>
      <Box display={"flex"} gap={2} flexDirection={"column"}>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          accept="video/*"
          onChange={handleFileChange}
        />
        <Box display={"flex"} gap={1}>
          {jobUploadTypeOptions.map(jobUploadTypeOption => (
            <Button
              key={jobUploadTypeOption.value}
              sx={{ borderRadius: "12px", ...(isMobile && { padding: 1 }) }}
              size="large"
              variant={
                mediaType === jobUploadTypeOption.value
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                setMediaType(jobUploadTypeOption.value);
                setVideoFile(null);
                setTranscription("");
              }}
            >
              {jobUploadTypeOption.label}
            </Button>
          ))}
        </Box>
        {renderJobDetailsForm()}
        {mediaType !== JobUploadTypes.JOB_PROMOTION && (
          <JobUploadButton
            videoFile={videoFile}
            isCreatingJob={isCreatingJob}
            transcription={transcription}
            mediaType={mediaType}
            onClickGenerateJob={() => {
              createNewJob({
                videoFile,
                videoLanguage: Languages.ENGLISH,
                homeId: props.homeId,
                createdForCompanyId: props.createdForCompanyId,
                type: mediaType,
                ...(mediaType === JobUploadTypes.TEXT && transcription
                  ? { summary: transcription }
                  : {}),
              });
            }}
          />
        )}
      </Box>
    </Box>
  );
}
