import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { User } from "@/auth/types";
import API from "@/common/api";

export function useGetUsers(
  keyword: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<Partial<User>>> {
  return useQuery({
    queryKey: ["/internal-admin/users", keyword],
    queryFn: async () => {
      const response = await API.get(
        `/internal-admin/users?keyword=${keyword}`
      );
      return response.data.data;
    },
    ...options,
  });
}
