import constate from "constate";
import { useState } from "react";

// Type for the filter functions

// Type for the filters object
export interface InternalJobFilters {
  keyword?: string;
}

export const filterJobKeyToLabel = {
  sectors: "Service Categories",
  createdAt: "Company Creation Date",
  nonCompetingCompanies: "Non-Competing Affiliate Companies ",
  franchise: "Franchise",
  businessAge: "Business Age",
  relevantSectors: "Relevant Sectors",
  confirmedInsured: "Confirmed insured",
  existingCustomerReferralProgram: "Existing customer referral program",
  existingReferral: "Existing referral",
  companyOwnership: "Company Ownership",
  ownersNearRetirement: "Owners within 10 Years of 60 (Retirement Age)",
  familyOperatedBusiness: "Family operated business",
  mutualConnections: "Mutual Connections",
  localReferralNetwork: "Member of Local Referral Network",
  collaborateWithOtherPartners:
    "Signals that They Collaborate with other Partners",
  tradeengageActivity: "TradeEngage Activity",
  betterBusinessBureau: "Better Business Bureau",
  googleRating: "Google Rating",
  numberOfGoogleReviews: "Google Reviews",
  historicalLawsuits: "Historical Lawsuits",
  numberOfWebsiteVisitors: "Website Visitors",
  numberOfEmployees: "Number of Employees",
  brickAndMortarLocation: "Brick & Mortar Location",
  contactInformation: "Contact Information",
  detailsTechnologyStack: "Technology Stack",
  onlineScheduling: "Online Scheduling",
  website: "Website",
  marketPresence: "Marketplace Presence",
  zipCodes: "Zip Codes",
  milesRadius: "Miles Radius",
  archived: "Archived Companies",
};

// Create a custom hook for managing filters with TypeScript
const useInternalJobSearchFilter = (
  initialFilters: InternalJobFilters = { keyword: "" }
): {
  filters: InternalJobFilters;
  addFilter: (filter: Partial<InternalJobFilters>) => void;
  removeFilter: (key: string) => void;
  removeAllFilter: () => void;
} => {
  const [filters, setFilters] = useState<InternalJobFilters>(initialFilters);

  const addFilter = (filter: Partial<InternalJobFilters>) => {
    setFilters(prevFilters => ({ ...prevFilters, ...filter }));
  };

  const removeFilter = (key: string) => {
    setFilters(prevFilters => {
      const { [key]: _, ...rest } = prevFilters;
      return { ...rest };
    });
  };

  const removeAllFilter = () => {
    setFilters({});
  };

  return {
    filters,
    addFilter,
    removeFilter,
    removeAllFilter,
  };
};

export const [
  InternalJobSearchFilterProvider,
  useInternalJobSearchFilterContext,
] = constate(useInternalJobSearchFilter);
