import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { JobTransaction } from "../types";

interface GetJobTransactionsResponse {
  userTransactions: JobTransaction[];
  companyTransactions: JobTransaction[];
}

export function useGetJobTransactions(
  params: {
    jobId: number;
  },
  options: UseQueryOptions<GetJobTransactionsResponse> = {}
): UseQueryResult<GetJobTransactionsResponse> {
  const url = `/jobs/${params.jobId}/transactions`;

  return useQuery({
    queryKey: [url, params],
    queryFn: async (): Promise<GetJobTransactionsResponse> => {
      const response = await API.get(url);
      return response.data.data;
    },
    ...options,
  });
}
