import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { JobSectors } from "../jobs/constants";
import {
  COMPANY_ACCOUNT_TYPES,
  CompanyPublicProfile,
  ExternalCompanyProfile,
} from "../types";

export interface CompanyExploreFilters {
  keyword?: string;
  sectors?: JobSectors[];
  linkedCompanies?: boolean;
  excludeRecordIds?: string;
  excludeCompanyIds?: string;
  nearby?: boolean;
  accountTypes?: COMPANY_ACCOUNT_TYPES[];
  parentCompanyId?: number | null;
  searchRadius?: number;
  zipCode?: string;
  googleRating?: Array<1 | 2 | 3 | 4>;
  ownerContactInformationAvailable?: boolean;
  affiliatedCompanies?: boolean;
  hasMutualReferrals?: boolean;
  activeOnTradeEngage?: boolean;
  topReferralCategories?: JobSectors[];
  topReferralCategoryCheck?: boolean;
  otherNearbyCheck?: boolean;
  franchiseCheck?: boolean;
  excludeCurrentCompany?: boolean;
  fddDisregardNearbyLocations?: boolean;
  limit?: number;
  isGoogleCompany?: boolean;
}

export interface SearchedCompany {
  googleCompanyProfile?: ExternalCompanyProfile;
  companyPublicProfile?: CompanyPublicProfile;
  hasMarkedInterest?: boolean;
}

export function useQueryCompanyBrandProfile(
  companyId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<any> {
  const uri = `/internal-admin/companies/brand-location/${companyId}`;

  return useQuery({
    queryKey: [uri.toString()],
    queryFn: async () => {
      const response = await API.get(uri);
      const camelCaseResponse = response.data.data;
      return camelCaseResponse;
    },
    ...options,
  });
}
