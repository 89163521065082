import CloseIcon from "@mui/icons-material/Close";
import { closeSnackbar, SnackbarKey } from "notistack";
import { ReactNode } from "react";

export function NotifyAction(
  snackbarId: SnackbarKey,
  customAction?: ReactNode
) {
  return (
    <>
      {customAction}
      <CloseIcon
        sx={{
          color: "white",
          cursor: "pointer",
          mr: 1,
          width: "20px",
          height: "20px",
        }}
        fontSize="small"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      />
    </>
  );
}
