import { type StandardTextFieldProps, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { type ReactElement, useId } from "react";

interface NumberInputFieldProps
  extends Omit<StandardTextFieldProps, "variant" | "type"> {
  name: string;
}

export function NumberInputField(props: NumberInputFieldProps): ReactElement {
  const { name, disabled, ...otherProps } = props;
  const [field, meta, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  const id = useId();

  return (
    <TextField
      {...otherProps}
      {...field}
      type="number"
      id={id}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error ? meta.error : ""}
      disabled={isSubmitting || disabled}
      InputLabelProps={{ shrink: true }}
      onChange={event => {
        const value = event.target.value;
        helpers.setValue(value === "" ? null : parseInt(value, 10));
      }}
    />
  );
}
