import { Box, Button, Popover, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";

import { RadioGroupField } from "@/lib/form-fields/RadioGroupField";
import { isDefined } from "@/utils/isDefined";

interface GoogleReviewsFilterFormProps {
  anchorEl: HTMLElement | null;
  minValue: number;
  onSubmit: (value: number) => void;
  onClose: () => void;
}

export function GoogleReviewsFilterForm(props: GoogleReviewsFilterFormProps) {
  const { anchorEl, minValue, onSubmit, onClose } = props;

  const googleReviewsFilterFormMethods = useFormik<{ minValue: number }>({
    initialValues: { minValue },
    onSubmit: values => {
      onSubmit(values.minValue);
    },
  });

  return (
    <Popover
      anchorEl={anchorEl}
      open={isDefined(anchorEl)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={onClose}
    >
      <Box sx={{ padding: 2, width: 272 }}>
        <Typography variant="subtitle1" fontWeight={500}>
          Google Reviews
        </Typography>
        <Typography variant="body2" fontWeight={400} color="text.secondary">
          Set a minimum number of reviews to limit participation.
        </Typography>
        <FormikProvider value={googleReviewsFilterFormMethods}>
          <Box component="form">
            <Box sx={{ padding: 1 }}>
              <RadioGroupField
                name="minValue"
                radioSize="small"
                options={[
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                  { label: "500", value: 500 },
                  { label: "1,000", value: 1000 },
                  { label: "5,000", value: 5000 },
                ]}
              />
            </Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => googleReviewsFilterFormMethods.submitForm()}
            >
              Apply
            </Button>
          </Box>
        </FormikProvider>
      </Box>
    </Popover>
  );
}
