import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Tab, Tabs, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { styled, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useCallback, useState } from "react";

import { useCompanySearchContext } from "@/company/context/search-company-context";

import { EmailInvite } from "./EmailInvite";
import { NetworkInvite } from "./NetworkInvite";
import { QRInvite } from "./QRInvite";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface InvitationDialogProps {
  isOpen: boolean;
  onClose: (invitationSent?: boolean) => void;
  source?: InviteSource;
  googlePlacesId?: string;
}

export enum InviteSource {
  EXTERNAL_INVITE = "EXTERNAL_INVITE",
}

enum TabsEnum {
  EMAIL_INVITE = "EMAIL_INVITE",
  NETWORK_INVITE = "NETWORK_INVITE",
  QR_CODE_INVITE = "QR_CODE_INVITE",
}

export const InvitationDialog: React.FC<InvitationDialogProps> = ({
  isOpen,
  onClose,
  source,
  googlePlacesId,
}) => {
  const [currentTab, setCurrentTab] = useState<TabsEnum>(TabsEnum.EMAIL_INVITE);
  const { searchCompanies } = useCompanySearchContext();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const handleTabsChange = useCallback(
    (event: React.ChangeEvent<any>, value: TabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="invite-dialog-title"
        open={isOpen}
        sx={{ m: { xs: -3, md: 0 } }}
        onClose={onClose}
      >
        <Box sx={{ p: 3, ...(isDesktop ? { width: "600px" } : {}) }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h5" m={0} p={0}>
              New Invite
            </Typography>
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography gutterBottom variant="body2" color="text.secondary">
            Send custom invitations to referral partners using the links below.
          </Typography>
          <Tabs value={currentTab} onChange={handleTabsChange}>
            <Tab value={TabsEnum.EMAIL_INVITE} label="Email Invite" />
            <Tab value={TabsEnum.NETWORK_INVITE} label="Copy Link" />
            <Tab value={TabsEnum.QR_CODE_INVITE} label="Scan Code" />
          </Tabs>
          <Divider />
          <Box>
            {currentTab === TabsEnum.EMAIL_INVITE ? (
              <EmailInvite
                googlePlacesId={googlePlacesId}
                onSendInvite={() => {
                  if (source === InviteSource.EXTERNAL_INVITE) {
                    onClose(true);
                  }
                  onClose();
                }}
              />
            ) : null}
            {currentTab === TabsEnum.NETWORK_INVITE ? <NetworkInvite /> : null}
            {currentTab === TabsEnum.QR_CODE_INVITE ? <QRInvite /> : null}
          </Box>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};
