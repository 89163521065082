import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { useLeadContext } from "@/lead/context/lead-context";

import { trackEvent } from "../analytics";
import { AnalyticsEvents } from "../analytics/enums";
import LogOut01 from "../icons/untitled-ui/duocolor/log-out-01";
import { RouterLink } from "../router-link";
import { getNavigationPathFromRoutePaths } from "../utils/getNavigationPathFromRoutePaths";

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  buttons: Array<{ label: string; link: string; icon: any }>;
}

export const AccountPopover: FC<AccountPopoverProps> = props => {
  const { anchorEl, onClose, open, buttons, ...other } = props;
  const { signOut } = useAuth();
  const { updateLeadDetails } = useLeadContext();
  const navigate = useNavigate();

  return (
    <Popover
      disableScrollLock
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      open={!!open}
      PaperProps={{ sx: { width: 250 } }}
      onClose={onClose}
      {...other}
    >
      <Box sx={{ p: 1 }}>
        {buttons.map(button => (
          <ListItemButton
            key={button.link}
            component={RouterLink}
            href={button.link}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
            onClick={onClose}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">{button.icon}</SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">{button.label}</Typography>}
            />
          </ListItemButton>
        ))}
        <ListItemButton
          component={RouterLink}
          href={getNavigationPathFromRoutePaths([])}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
          onClick={() => {
            trackEvent(AnalyticsEvents.CLICKED_LOG_OUT, {});
            updateLeadDetails({});
            signOut();
            onClose && onClose();
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <LogOut01 />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Log Out</Typography>}
          />
        </ListItemButton>
      </Box>
    </Popover>
  );
};
