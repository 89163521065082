import { Route } from "react-router-dom";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";

import { HomeownerDashboardLayout } from "./components/layout/HomeownerLayout";
import { HomeownerDashboardHomePage } from "./components/screens/dashboard-home/DashboardHomePage";
import { HomeownerJobDetailsPage } from "./components/screens/job-details/HomeownerJobDetailsPage";
import { HomeownerJobsListPage } from "./components/screens/jobs-list/HomeownerJobsListPage";
import { HomeownerPersonalSettingsPage } from "./components/screens/personal-settings/PersonalSettingsPage";
import { HomeownerServiceProvidersPage } from "./components/screens/service-providers/ServiceProvidersPage";
import { HomeownerRoutes } from "./constants";

export function HomeownerRouter() {
  // All routes below are protected routes, the HomeownerDashboardLayout has the ProtectedRoute
  return (
    <Route path={HomeownerRoutes.BASE} element={<HomeownerDashboardLayout />}>
      <Route
        path={HomeownerRoutes.DASHBOARD_HOME}
        loader={ComponentLoader}
        element={<HomeownerDashboardHomePage />}
      />
      <Route
        path={HomeownerRoutes.JOBS_LIST}
        loader={ComponentLoader}
        element={<HomeownerJobsListPage />}
      />
      <Route
        path={HomeownerRoutes.SERVICE_PROVIDERS}
        loader={ComponentLoader}
        element={<HomeownerServiceProvidersPage />}
      />
      <Route
        path={`${HomeownerRoutes.SERVICE_PROVIDERS}/:companyId`}
        loader={ComponentLoader}
        element={<HomeownerServiceProvidersPage />}
      />
      <Route
        path={HomeownerRoutes.PERSONAL_SETTINGS}
        loader={ComponentLoader}
        element={<HomeownerPersonalSettingsPage />}
      />
      <Route
        path={HomeownerRoutes.JOB}
        loader={ComponentLoader}
        element={<HomeownerJobDetailsPage />}
      />
    </Route>
  );
}
