import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { DateTime } from "luxon";

import axiosInstance from "@/common/api";
import { reverseSnakeCaseJobSectors } from "@/company/utils";
import { keysToSnakeCase } from "@/utils";

import { JobSectors } from "../constants";
import { RoutedJobResponse, RoutedJobStatus } from "../types/job";

const GET_ROUTED_JOBS_URL = "/job-routings";
const GET_ROUTED_JOBS_URL_V2 = "/job-routings/v2";

export interface RoutedJobRequestFilters {
  dateRange?: { startDate?: DateTime; endDate?: DateTime };
  sectors?: Array<JobSectors>;
  status?: Array<RoutedJobStatus>;
}

export function useGetRoutedJobsByCompany(
  filters?: RoutedJobRequestFilters,
  options?: UseQueryOptions<any>,
  isV2?: boolean
): UseQueryResult<Array<RoutedJobResponse>> {
  const newFilters = {};

  const sectors = filters?.sectors
    ?.map(sector => reverseSnakeCaseJobSectors[sector as JobSectors])
    ?.map(sector => "sectors=" + sector)
    .join("&");

  const statuses = filters?.status
    ?.map(status => status.toString().replaceAll(" ", "_").toUpperCase())
    ?.map(status => "statuses=" + status)
    .join("&");

  if (filters?.dateRange) {
    newFilters.fromDate = filters.dateRange?.startDate?.toISO();
    newFilters.toDate = filters.dateRange?.endDate?.toISO();
  }

  const searchQuery = new URLSearchParams(keysToSnakeCase({ ...newFilters }));
  const uriWithSearchQuery = `${
    isV2 ? GET_ROUTED_JOBS_URL_V2 : GET_ROUTED_JOBS_URL
  }?${searchQuery.toString()}${sectors ? `&${sectors}` : ""}${
    statuses ? `&${statuses}` : ""
  }`;
  return useQuery({
    queryKey: [uriWithSearchQuery],
    queryFn: async () => {
      const response = await axiosInstance.get(uriWithSearchQuery);
      return response.data.data;
    },
    ...options,
  });
}
