import {
  CardGiftcard as CardGiftcardIcon,
  HubOutlined,
} from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SvgIcon from "@mui/icons-material/Business";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { Avatar, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetHubs } from "@/analytics/api/useGetHubs";
import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import CreateTechnicianJobDialogWrapper from "@/common/CreateJobDialog/CreateTechnicianJobDialogWrapper";
import BarChartSquare02 from "@/common/icons/untitled-ui/duocolor/bar-chart-square-02";
import HomeSmile from "@/common/icons/untitled-ui/duocolor/home-smile";
import { useJobUploadContext } from "@/common/job-upload-context";
import {
  DashboardLayout,
  NavLink,
  SectionedNavLinks,
} from "@/common/layouts/DashboardLayout";
import ProtectedRoute from "@/common/ProtectedRoutes";
import {
  canSeeAllJobs,
  canSeeCompanyDashboard,
  canSeeCompanyMessages,
  canSeeCompanySettings,
  canSeeCompanyTeamMembers,
  canSeeExplorePartners,
  canSeeHubs,
  canSeeMyJobs,
  canSeeReferralMatrix,
  canSeeWallet,
  isAdministratorOrOwner,
  isTechnician,
} from "@/common/utils/accessControl";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useFetchCompanyCallRecordingNotificationList } from "@/company/api/useFetchCallNotificationsUnread";
import { useFetchUnreadMessagesCount } from "@/company/api/useFetchUnreadMessagesCount";
import { useFetchUnreadAdminMessagesCount } from "@/company/api/useFetchUnreadSuperAdminChats";
import { MarkedInterestSetProvider } from "@/company/context/marked-interest-context";
import { ReferralProvider } from "@/company/context/referral-context";
import { ReferralInvitationProvider } from "@/company/context/referral-invitations-context";
import { CompanySearchProvider } from "@/company/context/search-company-context";
import { useSupportChatContext } from "@/company/context/support-chat-context";
import { useGetSentJobsByCompany } from "@/company/jobs/api/useGetSentJobs";
import { LimitReachedDialog } from "@/company/jobs/components/LimitReachDialog";
import {
  COMPANY_TEAM_MEMBER_ONBOARDING_ROUTES,
  ONBOARDING_ROUTES as COMPANY_ONBOARDING_ROUTES,
} from "@/company/onboarding/constants";
import { COMPANY_ACCOUNT_TYPES, CompanyRoutes } from "@/company/types";
import { getUserRoleLabel } from "@/company/utils";
import { FEATURE_FLAGS } from "@/featureFlags";
import { useConnectionLimitReachedModalContext } from "@/open/components/modals/LimitReachedContext";
import { PublicRoutes } from "@/open/constants";
import { neutral, tradeEngageColors } from "@/Theme/colors";

import { InvitationDialog } from "../invitation-dialog/InvitationDialog";
import { CompanySideNavHeader } from "./CompanySideNavHeader";

export function CompanyDashboardLayout() {
  const { session } = useAuth();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { jobUploadOpen, setJobUploadOpen } = useJobUploadContext();
  const { data: hubs } = useGetHubs();

  const flags = useFlags();
  const {
    refetch: refetchJobs,
    isFetching,
    isError,
  } = useGetSentJobsByCompany(
    { createdByUserId: session?.user?.id },
    {
      refetchOnWindowFocus: false,
      enabled: session?.groups?.some(canSeeMyJobs),
    },
    flags[FEATURE_FLAGS.JOBS_JOB_ROUTINGS_V2_ENDPOINT]
  );

  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);

  const { data: unreadMessagesCount } = useFetchUnreadMessagesCount({
    refetchOnWindowFocus: true,
    enabled: !!session?.groups?.some(canSeeCompanyMessages),
  });

  const { isLoading, data: companyRecordingNotificationList } =
    useFetchCompanyCallRecordingNotificationList({
      refetchOnWindowFocus: false,
      enabled: !!session?.groups?.some(canSeeCompanyMessages),
    });

  const { chats } = useSupportChatContext();

  const { showLimitReachedDialog, setShowLimitReachedDialog } =
    useConnectionLimitReachedModalContext();

  const { data: unreadSupportMessagesCount } = useFetchUnreadAdminMessagesCount(
    chats?.[0]?.id,
    {
      refetchOnWindowFocus: true,
      enabled: !!session?.groups?.some(canSeeMyJobs) && !!chats?.[0]?.id,
    }
  );

  const companyPrimaryNavLinks: NavLink[] = [
    ...(session?.groups?.some(canSeeCompanyDashboard)
      ? [
          {
            label: t("home"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.DASHBOARD_HOME,
            ]),
            icon: <HomeSmile />,
          },
        ]
      : []),
    // eslint-disable-next-line no-constant-condition
    ...(session?.groups?.some(canSeeAllJobs) &&
    session?.brandLocationProfile?.showJobAnalytics
      ? [
          {
            label: "Reporting & Analytics",
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.ANALYTICS,
            ]),
            icon: <ShowChartIcon />,
          },
        ]
      : []),
    ...(session?.groups?.some(isTechnician)
      ? [
          {
            label: t("technician_home"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.TECHNICIAN_DEMO,
            ]),
            icon: <HomeSmile />,
          },
        ]
      : []),
    ...(session?.groups?.some(canSeeCompanyMessages)
      ? [
          {
            label: "Call Analytics",
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.JOB_CALLS,
            ]),
            icon: <PermPhoneMsgOutlinedIcon />,
            endIcon:
              companyRecordingNotificationList?.length ?? 0 > 0 ? (
                <Avatar
                  variant="circular"
                  color="primary"
                  sx={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: tradeEngageColors.green,
                    color: neutral[50],
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight={"600"}
                    fontSize={"14px"}
                    color="inherit"
                  >
                    {companyRecordingNotificationList?.length}
                  </Typography>
                </Avatar>
              ) : null,
          },
          {
            label: t("messages"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.MESSAGES,
            ]),
            icon: <EmailOutlinedIcon />,
            endIcon:
              unreadMessagesCount && unreadMessagesCount > 0 ? (
                <Avatar
                  variant="circular"
                  color="primary"
                  sx={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: tradeEngageColors.green,
                    color: neutral[50],
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight={"600"}
                    fontSize={"14px"}
                    color="inherit"
                  >
                    {unreadMessagesCount}
                  </Typography>
                </Avatar>
              ) : null,
          },
        ]
      : []),
    ...(session?.groups?.some(canSeeMyJobs)
      ? [
          {
            label: "Leaderboard",
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.LEADERBOARD,
            ]),
            icon: <EmojiEventsOutlinedIcon />,
          },
          {
            label: "TradeEngage Team Messages",
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.SUPPORT_MESSAGES,
            ]),
            icon: <EmailOutlinedIcon />,
            endIcon:
              unreadSupportMessagesCount && unreadSupportMessagesCount > 0 ? (
                <Avatar
                  variant="circular"
                  color="primary"
                  sx={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: tradeEngageColors.green,
                    color: neutral[50],
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight={"600"}
                    fontSize={"14px"}
                    color="inherit"
                  >
                    {unreadSupportMessagesCount}
                  </Typography>
                </Avatar>
              ) : null,
          },
        ]
      : []),
    ...(hubs?.length && session?.groups?.some(canSeeHubs)
      ? [
          {
            label: "Hubs",
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.HUBS,
            ]),
            icon: <HubOutlined />,
          },
        ]
      : []),
  ];

  const companySectionedNavLinks: SectionedNavLinks[] = [
    ...(session?.groups?.some(canSeeAllJobs) ||
    session?.groups?.some(canSeeMyJobs)
      ? [
          {
            sectionName: t("jobs_dashboard"),
            navLinks: [
              ...(session?.groups?.some(canSeeAllJobs)
                ? [
                    {
                      label: t("all_jobs"),
                      link: getNavigationPathFromRoutePaths([
                        CompanyRoutes.BASE,
                        CompanyRoutes.ALL_JOBS,
                      ]),
                      icon: <BarChartSquare02 />,
                    },
                  ]
                : []),
              ...(session?.groups?.some(canSeeMyJobs)
                ? [
                    {
                      label: t("my_jobs"),
                      link: getNavigationPathFromRoutePaths([
                        CompanyRoutes.BASE,
                        CompanyRoutes.MY_JOBS,
                      ]),
                      icon: <OndemandVideoIcon />,
                    },
                  ]
                : []),
              ...[
                {
                  label: "Promotions",
                  link: getNavigationPathFromRoutePaths([
                    CompanyRoutes.BASE,
                    CompanyRoutes.JOB_PROMOTIONS,
                  ]),
                  icon: <CardGiftcardIcon />,
                },
              ],
            ],
          },
        ]
      : []),
    ...(session?.groups?.some(canSeeReferralMatrix)
      ? [
          {
            sectionName: t("referral_matrix"),
            navLinks: [
              ...(session?.groups?.some(canSeeExplorePartners)
                ? [
                    {
                      label: t("explore_partners"),
                      link: getNavigationPathFromRoutePaths([
                        CompanyRoutes.BASE,
                        CompanyRoutes.EXPLORE_PARTNERS,
                      ]),
                      icon: <TravelExploreIcon />,
                    },
                  ]
                : []),
              {
                label: t("manage_partners"),
                link: getNavigationPathFromRoutePaths([
                  CompanyRoutes.BASE,
                  CompanyRoutes.MANAGE_PARTNERS,
                ]),
                icon: (
                  <SvgIcon fontSize="small">
                    <img src="/icon-dark.svg" alt="manage partners logo" />
                  </SvgIcon>
                ),
              },
            ],
          },
        ]
      : []),
    {
      sectionName: t("manage"),
      navLinks: [
        {
          label: t("profile"),
          link: getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.PROFILE,
          ]),
          icon: <ManageAccountsOutlinedIcon />,
        },
        ...(session?.groups?.some(canSeeWallet)
          ? [
              {
                label: t("my_wallet"),
                link: getNavigationPathFromRoutePaths([
                  CompanyRoutes.BASE,
                  CompanyRoutes.MY_WALLET,
                ]),
                icon: <AttachMoneyIcon />,
              },
            ]
          : []),
        ...(session?.groups?.some(isTechnician) &&
        !session?.groups?.some(isAdministratorOrOwner)
          ? [
              {
                label: "Providers",
                link: getNavigationPathFromRoutePaths([
                  CompanyRoutes.BASE,
                  CompanyRoutes.REFERRAL_PARTNERS,
                ]),
                icon: <ContactPhoneOutlinedIcon />,
              },
            ]
          : []),
        ...(session?.groups?.some(canSeeCompanyTeamMembers)
          ? [
              {
                label: t("team_members"),
                link: getNavigationPathFromRoutePaths([
                  CompanyRoutes.BASE,
                  CompanyRoutes.TEAM_MEMBERS,
                ]),
                icon: <PeopleOutlineOutlinedIcon />,
              },
            ]
          : []),
        ...(session?.groups?.some(canSeeCompanySettings)
          ? [
              {
                label: t("settings"),
                link: getNavigationPathFromRoutePaths([
                  CompanyRoutes.BASE,
                  CompanyRoutes.COMPANY_SETTINGS,
                ]),
                icon: <SettingsOutlinedIcon />,
              },
            ]
          : []),
      ],
    },
    {
      sectionName: t("help"),
      navLinks: [
        {
          label: t("contact_support"),
          link: getNavigationPathFromRoutePaths([
            PublicRoutes.BASE,
            PublicRoutes.CONTACT_SUPPORT,
          ]),
          icon: <SupportAgentOutlinedIcon />,
        },
      ],
    },
  ];

  const CompanyTeamMemberAccountButtons = [
    {
      label: t("profile"),
      link: getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.PROFILE,
      ]),
      icon: <ManageAccountsOutlinedIcon />,
    },
  ];

  const BottomNavLinks: [NavLink, NavLink, NavLink, NavLink] =
    session?.groups?.some(isAdministratorOrOwner)
      ? [
          {
            label: t("home"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.DASHBOARD_HOME,
            ]),
            icon: <HomeSmile />,
          },
          {
            label: t("jobs"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.ALL_JOBS,
            ]),
            icon: <BarChartSquare02 />,
          },
          {
            label: t("partners"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.MANAGE_PARTNERS,
            ]),
            icon: (
              <SvgIcon fontSize="small">
                <img src="/icon-dark.svg" alt="manage partners logo" />
              </SvgIcon>
            ),
          },
          {
            label: t("support"),
            link: getNavigationPathFromRoutePaths([
              PublicRoutes.BASE,
              PublicRoutes.CONTACT_SUPPORT,
            ]),
            icon: <SupportAgentOutlinedIcon color="inherit" />,
          },
        ]
      : session?.groups?.some(isTechnician)
      ? [
          {
            label: t("my_jobs"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.MY_JOBS,
            ]),
            icon: <OndemandVideoIcon />,
          },
          {
            label: "Providers",
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.REFERRAL_PARTNERS,
            ]),
            icon: <ContactPhoneOutlinedIcon />,
          },
          {
            label: t("my_wallet"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.MY_WALLET,
            ]),
            icon: <AttachMoneyIcon color="inherit" />,
          },
          {
            label: "Promos",
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.JOB_PROMOTIONS,
            ]),
            icon: <CardGiftcardIcon color="inherit" />,
          },
          // {
          //   label: t("support"),
          //   link: getNavigationPathFromRoutePaths([
          //     PublicRoutes.BASE,
          //     PublicRoutes.CONTACT_SUPPORT,
          //   ]),
          //   icon: <SupportAgentOutlinedIcon color="inherit" />,
          // },
        ]
      : [
          {
            label: t("jobs"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.ALL_JOBS,
            ]),
            icon: <BarChartSquare02 />,
          },
          {
            label: t("messages"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.MESSAGES,
            ]),
            icon: <EmailOutlinedIcon />,
          },
          {
            label: t("profile"),
            link: getNavigationPathFromRoutePaths([
              CompanyRoutes.BASE,
              CompanyRoutes.PROFILE,
            ]),
            icon: <ManageAccountsOutlinedIcon color="inherit" />,
          },
          {
            label: t("support"),
            link: getNavigationPathFromRoutePaths([
              PublicRoutes.BASE,
              PublicRoutes.CONTACT_SUPPORT,
            ]),
            icon: <SupportAgentOutlinedIcon color="inherit" />,
          },
        ];

  useEffect(() => {
    if (session?.company?.accountType === COMPANY_ACCOUNT_TYPES.CONSULTANT) {
      return navigate("/consultant/home");
    }
  }, [session]);

  // Function to handle reopening the job modal after reload
  const handleOpenJobModal = () => {
    const shouldReopenModal = localStorage.getItem("reOpenModal");
    if (shouldReopenModal === "true") {
      setJobUploadOpen(true); // Assume setOpen is the state setter for your MUI modal
    }
  };

  useEffect(() => {
    handleOpenJobModal();
  }, []);

  return (
    <>
      <DashboardLayout
        primaryNavLinks={companyPrimaryNavLinks}
        sectionedNavLinks={companySectionedNavLinks}
        sideNavHeader={
          <CompanySideNavHeader
            companyName={session?.company?.name || ""}
            userRoleLabel={getUserRoleLabel(session)}
            companyLogoUrl={session?.company?.logoUrl}
          />
        }
        accountButtons={CompanyTeamMemberAccountButtons}
        bottomNavBarProps={{
          navLinks: BottomNavLinks,
          onClickAction: () =>
            pathname.includes(
              getNavigationPathFromRoutePaths([
                CompanyRoutes.BASE,
                CompanyRoutes.EXPLORE_PARTNERS,
              ])
            ) ||
            (pathname.includes(
              getNavigationPathFromRoutePaths([
                CompanyRoutes.BASE,
                CompanyRoutes.MANAGE_PARTNERS,
              ])
            ) &&
              session?.groups?.some(canSeeExplorePartners))
              ? setIsInvitationDialogOpen(true)
              : setJobUploadOpen(true),
          actionIcon: (
            <img src="/whatsapp-redirect.svg" alt="submit jobs on whatsapp" />
          ),
          enabled:
            session?.groups?.some(canSeeMyJobs) &&
            pathname !==
              getNavigationPathFromRoutePaths([
                CompanyRoutes.BASE,
                COMPANY_ONBOARDING_ROUTES.BASE,
                COMPANY_TEAM_MEMBER_ONBOARDING_ROUTES.TECHNICIAN_DEMO,
              ]),
        }}
      >
        <ReferralInvitationProvider>
          <ReferralProvider>
            <CompanySearchProvider>
              <MarkedInterestSetProvider>
                <ProtectedRoute userRole={USER_TYPE.COMPANY_TEAM_MEMBER} />
              </MarkedInterestSetProvider>
            </CompanySearchProvider>
          </ReferralProvider>
        </ReferralInvitationProvider>
      </DashboardLayout>
      {jobUploadOpen ? (
        <CreateTechnicianJobDialogWrapper
          isOpen={jobUploadOpen}
          jobsListUrl={getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.MY_JOBS,
          ])}
          refreshJobs={refetchJobs}
          onClose={() => setJobUploadOpen(false)}
        />
      ) : null}
      {isInvitationDialogOpen && (
        <InvitationDialog
          isOpen={true}
          onClose={() => setIsInvitationDialogOpen(false)}
        />
      )}
      {showLimitReachedDialog ? (
        <LimitReachedDialog
          isOpen={!!showLimitReachedDialog}
          message={showLimitReachedDialog}
          onClose={() => setShowLimitReachedDialog("")}
        />
      ) : null}
    </>
  );
}
