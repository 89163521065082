import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";
import { keysToSnakeCase } from "@/utils";

import { ReferralInvitationActions } from "../types";

export function useCreateReferralInvitation(
  options: UseMutationOptions<
    void,
    AxiosError,
    { invitedCompanyId: number; initialMessage?: ReferralInvitationActions }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { invitedCompanyId: number; initialMessage?: ReferralInvitationActions }
> {
  return useMutation({
    mutationFn: async ({ invitedCompanyId, initialMessage }) => {
      const payload = keysToSnakeCase({ invitedCompanyId, initialMessage });
      const updateInvitationUri = `/referral-invites`;
      await API.post(updateInvitationUri, payload);
    },
    ...options,
  });
}
