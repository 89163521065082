import {
  Box,
  Button,
  CircularProgress,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Lottie from "react-lottie";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AuthRoutes } from "@/auth/constants";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useFetchRecommendedSectors } from "@/company/api/useFetchRecommendedSectors";
import { snakeCaseJobSectors } from "@/company/utils";
import { useLeadContext } from "@/lead/context/lead-context";

import animationData from "./confetti-lottie.json";

export function CompanyIdentificationExternalSuccess() {
  const [searchParams] = useSearchParams();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const defaultOptions = {
    loop: 1,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { leadDetails: companyDetails } = useLeadContext();
  const navigate = useNavigate();
  const googlePlacesId = searchParams.get("googlePlacesId");
  const brandLocationId = searchParams.get("brandLocationId");

  const invitationCode = searchParams.get("invitationCode");
  const campaignCode = searchParams.get("campaignCode");

  const randomRecommendedReferralPartnerCount =
    Math.floor(Math.random() * (30 - 15 + 1)) + 15;

  const sectors = [...(companyDetails?.sectors ?? [])];

  const lastSector = sectors?.length > 1 ? sectors.pop() : null;

  const { isFetching: isFetchingRecommendedSectors, data: recommendedSectors } =
    useFetchRecommendedSectors(
      companyDetails?.sectors ?? [],

      {
        refetchOnWindowFocus: false,
        enabled: !!companyDetails?.sectors,
        retry: false,
      }
    );

  const isLoading = isFetchingRecommendedSectors;

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      p={2}
      minHeight={"calc(100vh - 205px)"}
      overflow={"auto"}
    >
      {isMobile ? (
        <Box
          display={"flex"}
          position={"absolute"}
          top={"16px"}
          left={"16px"}
          pb={4}
          pt={3}
          width="100%"
          justifyContent={"start"}
        >
          <TradeEngageLogo logotype={TradeEngageLogoTypes.LOGO_DARK} />
        </Box>
      ) : null}{" "}
      <Box position={"fixed"}>
        {isLoading ? null : (
          <Lottie
            options={defaultOptions}
            isClickToPauseDisabled={true}
            height={400}
            width={400}
          />
        )}
      </Box>
      <Box
        maxWidth={"460px"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"start"}
        gap={3}
      >
        {" "}
        {isLoading ? (
          <Box py={3} textAlign="center" width={"100%"}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Typography variant="h3" fontWeight={400}>
              Sign up now to connect with over{" "}
              <Typography
                display="inline"
                fontWeight={700}
                variant="h3"
                sx={{ color: "#16B364" }}
              >
                {randomRecommendedReferralPartnerCount}{" "}
                {recommendedSectors?.[0]
                  ? snakeCaseJobSectors[recommendedSectors?.[0]]
                  : ""}{" "}
              </Typography>
              providers and other relevant home services businesses that give{" "}
              <Typography
                display="inline"
                fontWeight={700}
                variant="h3"
                sx={{ color: "#16B364" }}
              >
                {sectors?.map(sector => snakeCaseJobSectors[sector]).join(", ")}{" "}
              </Typography>
              {lastSector ? (
                <>
                  {" "}
                  and{" "}
                  <Typography
                    variant="h3"
                    display="inline"
                    sx={{ color: "#16B364" }}
                    fontWeight="500"
                  >
                    {snakeCaseJobSectors[lastSector]}{" "}
                  </Typography>
                </>
              ) : null}
              referrals.
            </Typography>
          </Box>
        )}
        <Button
          color="primary"
          sx={{ width: "100%" }}
          variant="contained"
          disabled={!!isLoading}
          onClick={() => {
            navigate(
              `${getNavigationPathFromRoutePaths([
                AuthRoutes.BASE,
                AuthRoutes.SIGN_UP,
              ])}?redirectTo=/${AuthRoutes.BASE}/${AuthRoutes.USER_TYPE}${
                googlePlacesId ? `&googlePlacesId=${googlePlacesId}` : ""
              }${brandLocationId ? `&brandLocationId=${brandLocationId}` : ""}${
                invitationCode ? `&invitationCode=${invitationCode}` : ""
              }${campaignCode ? `&campaignCode=${campaignCode}` : ""}`
            );
          }}
        >
          Get Started For Free
        </Button>
      </Box>
      <Typography mt={3} variant="body2" color="text.secondary">
        Not a Company?{" "}
        <Link
          color="#16B364"
          rel="noreferrer"
          underline="none"
          sx={{
            cursor: "pointer",
            zIndex: 1000,
          }}
          variant="subtitle2"
          onClick={() => {
            navigate(
              `${getNavigationPathFromRoutePaths([
                AuthRoutes.BASE,
                AuthRoutes.SIGN_UP,
              ])}?redirectTo=/${AuthRoutes.BASE}/${AuthRoutes.USER_TYPE}${
                googlePlacesId ? `&googlePlacesId=${googlePlacesId}` : ""
              }${brandLocationId ? `&brandLocationId=${brandLocationId}` : ""}${
                invitationCode ? `&invitationCode=${invitationCode}` : ""
              }${campaignCode ? `&campaignCode=${campaignCode}` : ""}`
            );
          }}
        >
          <Typography
            mt={4}
            display="inline"
            variant="subtitle2"
            color="#16B364"
          >
            Sign Up as a Homeowner
          </Typography>
        </Link>
      </Typography>
    </Box>
  );
}
