import { Grid, Theme, useMediaQuery } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { USER_TYPE } from "@/auth/types";
import ProtectedRoute from "@/common/ProtectedRoutes";
import { Seo } from "@/common/Seo/Seo";
import { useQueryOnboardingFacts } from "@/company/onboarding/api";
import { ONBOARDING_STAGES } from "@/company/onboarding/constants";
import { tradeEngageColors } from "@/Theme/colors";

import { SidePanel } from "../panel/SidePanel";

export function OnboardingLayout() {
  const { pathname } = useLocation();
  const { data } = useQueryOnboardingFacts(
    pathname.split("/").slice(-1).pop() as ONBOARDING_STAGES
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const seoName = pathname.split("/").slice(-1).pop() as ONBOARDING_STAGES;

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsError(true);
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  return (
    <>
      <Seo title={_.startCase(seoName.replaceAll("-", " "))} />
      <Grid container columns={16} height={isMobile ? "100%" : "100vh"}>
        {data && isDesktop ? (
          <Grid
            item
            xs={16}
            md={4}
            sx={{
              ...(isLoading ? { bgcolor: tradeEngageColors.darkBlue } : {}),
              position: "relative",
              height: { xs: "fit-content", md: "100%" },
            }}
          >
            <img
              src={"/sidenav-background-gradient.png"}
              alt={"side panel gradient"}
              style={{
                display: isLoading || isError ? "none" : "block",
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                zIndex: -100,
              }}
              onLoad={handleLoad}
              onError={handleError}
            />
            <SidePanel
              title={data?.title ?? ""}
              isQuote={data?.isQuote ?? false}
              fact={data?.fact ?? {}}
              heading={data?.heading ?? {}}
              author={data?.author ?? ""}
              authorTitle={data?.authorTitle ?? ""}
            />
          </Grid>
        ) : null}
        <Grid item xs={16} md={data && isDesktop ? 12 : 16} overflow={"auto"}>
          <ProtectedRoute userRole={USER_TYPE.COMPANY_TEAM_MEMBER} />
        </Grid>
      </Grid>
    </>
  );
}
