import {
  AddPhotoAlternateOutlined,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBaseComponentProps,
  LinearProgress,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { ElementType, useEffect, useRef, useState } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { GoogleAutocomplete } from "@/common/GoogleAutocomplete";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { CallRecording } from "@/company/api/useFetchCallRecordings";
import { useUpdateJobById } from "@/company/jobs/api";
import { OCRJobDetails, useGetOCR } from "@/company/jobs/api/useGetOCR";
import { useUpdateJobHome } from "@/company/jobs/api/useUpdateJobHome";
import { Job } from "@/company/jobs/types";
import { getUSPhoneValidationSchema } from "@/utils";

export interface UpdateJobSectorDialogProps {
  isOpen: boolean;
  job: Job;
  recording: CallRecording;
  isSellingCompany: boolean;
  onClose: (isHomeownerAdded?: boolean) => void;
}

export function ModifyJobHomeownerDetailsDialog(
  props: UpdateJobSectorDialogProps
) {
  const { job, isSellingCompany, recording } = props;
  const { session } = useAuth();
  const brandLocationProfile = session?.brandLocationProfile;
  const [homeownerDetailsUpload, setHomeownerDetailsUpload] = useState<
    File | undefined
  >();
  const [zipCode, setZipCode] = useState<string | undefined>("");
  const [latitude, setLatitude] = useState<number | undefined>();
  const [longitude, setLongitude] = useState<number | undefined>();

  const [homeownerApprovalSwitch, setHomeownerApprovalSwitch] = useState(false);
  const [fetchedOCRDetails, setFetchedOCRDetails] = useState(false);
  const [homeownerTextUpload, setHomeownerTextUpload] = useState(false);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      setHomeownerDetailsUpload(file);
      // Handle the file upload logic here
    }
  };

  const { isLoading: isFetchingOCR, mutateAsync: getOCR } = useGetOCR({
    onSuccess: (jobDetails: OCRJobDetails) => {
      if (jobDetails?.phone) {
        formik.setFieldValue("cellPhone", jobDetails?.phone, true);
      }

      if (jobDetails?.firstName) {
        formik.setFieldValue("firstName", jobDetails?.firstName, true);
      }
      if (jobDetails?.lastName) {
        formik.setFieldValue("lastName", jobDetails?.lastName, true);
      }
      if (jobDetails?.phone) {
        formik.setFieldValue("cellPhone", jobDetails?.phone, true);
      }
      if (jobDetails?.firstName) {
        formik.setFieldValue("address", jobDetails?.address, true);
      }
      setFetchedOCRDetails(true);
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while fetching homeowner details from image.";
      setHomeownerDetailsUpload(undefined);
      setFetchedOCRDetails(false);
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    address: yup.string().required("Home Address is required"),
    cellPhone: getUSPhoneValidationSchema().required(
      "Mobile Number is required"
    ),
  });

  const formik = useFormik({
    initialValues: {
      cellPhone: "",
      firstName: "",
      lastName: "",
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ cellPhone }) => {
      console.log({ cellPhone });
    },
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: updatingJobHome, mutateAsync: updateJobHome } =
    useUpdateJobHome({
      onSuccess: () => {
        props.onClose(true);
        enqueueSnackbar({
          message: "Added homeowner details for job successfully.",
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while updating homeowner details.`,
          variant: "error",
        });
      },
    });

  const { isLoading: updatingJob, mutateAsync: updateJob } = useUpdateJobById({
    onSuccess: () => {
      updateJobHome({
        jobId: job?.id,
        cellPhone: `+1${formik.values.cellPhone.replace(/[\s()-]/g, "")}`,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        streetAddress: formik.values.address,
        zipCode,
        latitude,
        longitude,
      });
    },
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while updating job details.`,
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (homeownerDetailsUpload) {
      getOCR({ jobId: job?.id, imageFile: homeownerDetailsUpload });
    }
  }, [homeownerDetailsUpload]);

  useEffect(() => {
    if (recording) {
      let setHomeownerTextType = false;
      if (recording.homeownerName) {
        formik.setFieldValue(
          "firstName",
          recording.homeownerName?.split(" ")?.[0],
          true
        );
        formik.setFieldValue(
          "lastName",
          recording.homeownerName?.split(" ")?.[1],
          true
        );
        setHomeownerTextType = true;
      }
      if (recording.homeownerPhone) {
        formik.setFieldValue("cellPhone", recording.homeownerPhone, true);
        setHomeownerTextType = true;
      }
      if (recording.homeownerFullAddress) {
        formik.setFieldValue("address", recording.homeownerFullAddress, true);
        setHomeownerTextType = true;
      }
      if (recording.homeownerZipCode) {
        setZipCode(recording.homeownerZipCode);
        setHomeownerTextType = true;
      }
      if (setHomeownerTextType) {
        setHomeownerTextUpload(true);
      }
    }
  }, [recording]);

  return (
    <Dialog
      fullWidth
      sx={{ m: { xs: -3, md: 0 } }}
      open={props.isOpen}
      maxWidth="md"
      onClose={() => props.onClose()}
    >
      <DialogTitle component="div" width="95%" sx={{ px: 2 }}>
        <Typography gutterBottom variant="h5">
          Add Homeowner Info
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => props.onClose()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: { xs: 0 }, pt: 0 }}>
        <Box width="100%" pt={0}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems={"center"}
            width={"100%"}
            flexDirection={"column"}
            gap={2}
            px={2}
          >
            <Box width="100%" gap={1} display="flex" flexDirection={"column"}>
              <Box width="100%" gap={1} display="flex" flexDirection={"column"}>
                <Typography gutterBottom variant="subtitle1">
                  Enter Homeowner Details
                </Typography>
                <Box
                  display={"flex"}
                  gap={isMobile ? 1 : 2}
                  alignItems={"baseline"}
                  flexDirection={isMobile ? "column" : "row"}
                >
                  {!homeownerTextUpload ? (
                    <>
                      <Typography
                        gutterBottom
                        variant="body2"
                        color="text.secondary"
                      >
                        Type in details instead?
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setHomeownerTextUpload(true);
                          setHomeownerDetailsUpload(undefined);
                        }}
                      >
                        Switch to Text
                      </Button>
                    </>
                  ) : (
                    <Box
                      mb={isMobile ? 1 : 2}
                      display={"flex"}
                      gap={isMobile ? 0 : 2}
                      flexDirection={isMobile ? "column" : "row"}
                      alignItems={isMobile ? "start" : "center"}
                    >
                      <Typography
                        gutterBottom
                        variant="body2"
                        color="text.secondary"
                      >
                        Upload a screenshot of the Homeowner&apos;s profile?
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setHomeownerTextUpload(false)}
                      >
                        Upload
                      </Button>
                    </Box>
                  )}
                </Box>
                {!homeownerTextUpload ? (
                  <Box
                    display={"flex"}
                    width="100%"
                    gap={2}
                    flexDirection={"column"}
                  >
                    {homeownerDetailsUpload ? (
                      isFetchingOCR || !fetchedOCRDetails ? (
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={0}
                          mt={3}
                        >
                          <Typography variant="subtitle1">
                            Extracting Homeowner Details from Image
                          </Typography>
                          <Typography variant="caption" fontSize={"0.625rem"}>
                            Please do not close this window.
                          </Typography>
                          <LinearProgress sx={{ width: "100%", mt: 1 }} />
                        </Box>
                      ) : (
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          py={2}
                          gap={2}
                        >
                          <Box
                            display={"flex"}
                            width="100%"
                            gap={2}
                            flexDirection={"column"}
                          >
                            <Box display={"flex"} width="100%" gap={2}>
                              <TextField
                                required
                                id="firstName"
                                name="firstName"
                                label={"First Name"}
                                sx={{
                                  width: "50%",
                                }}
                                error={
                                  formik.touched.firstName &&
                                  Boolean(formik.errors.firstName)
                                }
                                helperText={
                                  formik.touched.firstName &&
                                  formik.errors.firstName
                                }
                                value={formik.values.firstName ?? ""}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                              />
                              <TextField
                                required
                                sx={{
                                  width: "50%",
                                }}
                                id="lastName"
                                name="lastName"
                                label={"Last Name"}
                                error={
                                  formik.touched.lastName &&
                                  Boolean(formik.errors.lastName)
                                }
                                helperText={
                                  formik.touched.lastName &&
                                  formik.errors.lastName
                                }
                                value={formik.values.lastName ?? ""}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                              />
                            </Box>
                            <Box
                              display={"flex"}
                              width="100%"
                              gap={2}
                              flexDirection={isMobile ? "column" : "row"}
                            >
                              <Box sx={{ width: "100%" }}>
                                <GoogleAutocomplete
                                  defaultOptions={[]}
                                  selectedValue={formik.values.address ?? ""}
                                  error={
                                    formik.touched.address &&
                                    Boolean(formik.errors.address)
                                  }
                                  helperText={
                                    formik.touched.address &&
                                    formik.errors.address
                                  }
                                  label={"Home Address*"}
                                  onChange={(address: string) => {
                                    formik.setFieldValue("address", address);
                                  }}
                                  onSelect={({
                                    selection,
                                    zipCode,
                                    latitude,
                                    longitude,
                                  }) => {
                                    setZipCode(zipCode);
                                    setLatitude(latitude);
                                    setLongitude(longitude);
                                    formik.setFieldValue("address", selection);
                                  }}
                                />
                              </Box>
                              <TextField
                                required
                                sx={{
                                  width: {
                                    xs: "100%",
                                    md: "100%",
                                  },
                                }}
                                id="cellPhone"
                                name="cellPhone"
                                label={"Mobile Number"}
                                error={
                                  formik.touched.cellPhone &&
                                  Boolean(formik.errors.cellPhone)
                                }
                                helperText={
                                  formik.touched.cellPhone &&
                                  formik.errors.cellPhone
                                }
                                value={formik.values.cellPhone ?? ""}
                                InputProps={{
                                  inputComponent:
                                    MaskedPhoneInput as unknown as
                                      | ElementType<InputBaseComponentProps>
                                      | undefined,
                                }}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )
                    ) : (
                      <Box display={"flex"} py={2} gap={2}>
                        <Box
                          px={isMobile ? 1 : 2}
                          py={1}
                          width={isMobile ? "100%" : "70%"}
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "start",
                            borderRadius: "16px",
                            gap: 2,
                            background: "#FFF",
                            boxShadow: "0px 0px 0px 1px #F2F4F7",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#F8F9FA",
                            },
                          }}
                          onClick={() => handleButtonClick()}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "50px",
                              height: "50px",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "50%",
                              background: "#e8fbf0",
                            }}
                          >
                            <AddPhotoAlternateOutlined color="primary" />
                          </Box>
                          <input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            sx={{ ...(isMobile ? { width: "85%" } : {}) }}
                          >
                            <Typography fontSize={"13px"} fontWeight={600}>
                              Upload a Screenshot from Your CRM
                            </Typography>
                            <Typography
                              fontSize={"12px"}
                              fontWeight={500}
                              color={"text.secondary"}
                            >
                              Easily share homeowner info by taking a screenshot
                              from your field service app.
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box
                    display={"flex"}
                    width="100%"
                    gap={2}
                    flexDirection={"column"}
                  >
                    <Box display={"flex"} width="100%" gap={2}>
                      <TextField
                        required
                        id="firstName"
                        name="firstName"
                        label={"First Name"}
                        sx={{
                          width: "50%",
                        }}
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                        value={formik.values.firstName ?? ""}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        required
                        sx={{
                          width: "50%",
                        }}
                        id="lastName"
                        name="lastName"
                        label={"Last Name"}
                        error={
                          formik.touched.lastName &&
                          Boolean(formik.errors.lastName)
                        }
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                        value={formik.values.lastName ?? ""}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </Box>
                    <Box
                      display={"flex"}
                      width="100%"
                      gap={2}
                      flexDirection={isMobile ? "column" : "row"}
                    >
                      <Box sx={{ width: "100%" }}>
                        <GoogleAutocomplete
                          defaultOptions={[]}
                          selectedValue={formik.values.address ?? ""}
                          error={
                            formik.touched.address &&
                            Boolean(formik.errors.address)
                          }
                          helperText={
                            formik.touched.address && formik.errors.address
                          }
                          label={"Home Address*"}
                          onChange={(address: string) => {
                            formik.setFieldValue("address", address);
                          }}
                          onSelect={({
                            selection,
                            zipCode,
                            latitude,
                            longitude,
                          }) => {
                            setZipCode(zipCode);
                            setLatitude(latitude);
                            setLongitude(longitude);
                            formik.setFieldValue("address", selection);
                          }}
                        />
                      </Box>
                      <TextField
                        required
                        sx={{
                          width: {
                            xs: "100%",
                            md: "100%",
                          },
                        }}
                        id="cellPhone"
                        name="cellPhone"
                        label={"Mobile Number"}
                        error={
                          formik.touched.cellPhone &&
                          Boolean(formik.errors.cellPhone)
                        }
                        helperText={
                          formik.touched.cellPhone && formik.errors.cellPhone
                        }
                        value={formik.values.cellPhone ?? ""}
                        InputProps={{
                          inputComponent: MaskedPhoneInput as unknown as
                            | ElementType<InputBaseComponentProps>
                            | undefined,
                        }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              <Button
                sx={{
                  mr: 1,
                  mt: 2,
                  width: "fit-content",
                }}
                disabled={
                  !formik.values.cellPhone ||
                  !formik.values.firstName ||
                  !formik.values.lastName ||
                  !formik.values.address ||
                  (formik.touched.cellPhone &&
                    Boolean(formik.errors.cellPhone)) ||
                  (formik.touched.firstName &&
                    Boolean(formik.errors.firstName)) ||
                  (formik.touched.lastName &&
                    Boolean(formik.errors.lastName)) ||
                  updatingJobHome ||
                  updatingJob
                }
                color="primary"
                variant="contained"
                onClick={() => {
                  updateJob({
                    jobId: job?.id,
                  });
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
