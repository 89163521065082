import constate from "constate";
import { useEffect, useState } from "react";

import {
  ChatThread,
  useFetchAdminChatsByBrandLocation,
} from "../api/useFetchAdminChatsByBrandLocation";
import { useUpdateChatAdmin } from "../api/useUpdateChatAdmin";

function useChatDetails({ companyId }: { companyId: number }) {
  const [chats, setChats] = useState<Array<ChatThread>>([]);

  const {
    data: chatsResponse,
    isLoading,
    refetch,
  } = useFetchAdminChatsByBrandLocation(companyId);

  const { mutateAsync: updateChat, isLoading: updatingChat } =
    useUpdateChatAdmin();

  useEffect(() => {
    if (chatsResponse && chatsResponse.length > 0) {
      setChats(chatsResponse);
    }
  }, [chatsResponse]);

  return {
    chats,
    isLoading: isLoading,
    updateChat,
    updatingChat,
    refetch,
  };
}

export const [AdminChatProvider, useAdminChatContext] =
  constate(useChatDetails);
