import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Divider, Grid, Rating, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { AppConstants } from "@/common/constants";

import { CAMPAIGN_NAME, campaignProperties } from "./CampaignLandingPage";

export function CampaignMobileLandingPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // Get current search params
  const campaignName = AppConstants.CAMPAIGN_SITE as CAMPAIGN_NAME;
  const searchParamsString = searchParams.toString();

  const { location } = useParams();

  const campaignCode = location;

  const handleIconClick = () => {
    const url =
      campaignCode === "dallas-fort-worth"
        ? "https://mrelectric.com/dallas"
        : campaignProperties[campaignName].url;
    window.open(url, "_blank");
  };

  return (
    <>
      <Helmet>
        <title>Campaign Sign Up</title>
        {AppConstants.CAMPAIGN_SITE ? (
          AppConstants.CAMPAIGN_SITE === CAMPAIGN_NAME.ADVANTA ? (
            <>
              <script
                async
                referrerPolicy="no-referrer-when-downgrade"
                src="https://tag.simpli.fi/sifitag/5952f39d-3057-4236-acf5-c4deeae98c5f"
              ></script>
              <script>{`!function () {var reb2b = window.reb2b = window.reb2b || [];if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);args.unshift(method);reb2b.push(args);return reb2b;};};for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("1VN080HJE36J");}();`}</script>{" "}
            </>
          ) : (
            <>
              <script
                async
                referrerPolicy="no-referrer-when-downgrade"
                src="https://tag.simpli.fi/sifitag/5233de23-f79e-4652-8c57-eaf9b336cb3b"
              ></script>
              <script>{`!function () {var reb2b = window.reb2b = window.reb2b || [];if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);args.unshift(method);reb2b.push(args);return reb2b;};};for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("ZQO92DHDDYN7");}();`}</script>{" "}
            </>
          )
        ) : (
          <script>{`!function () {var reb2b = window.reb2b = window.reb2b || [];if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);args.unshift(method);reb2b.push(args);return reb2b;};};for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("1LNKLDHVKQOJ");}();`}</script>
        )}
      </Helmet>
      {campaignName === CAMPAIGN_NAME.A1_GARAGE ? (
        <Grid
          container
          spacing={[0, 0, 0, 5]}
          justifyContent="space-between"
          sx={{ p: 0, m: 0 }}
        >
          <Grid
            item
            xs={12}
            lg={8}
            sx={{
              p: 0,
              m: 0,
            }}
          >
            <Box position={"relative"}>
              <img
                src="/a1-garage-mb.png"
                alt="a1 garage mobile"
                style={{
                  height: "750px",
                  width: "100%",
                }}
              />
              <Box
                sx={{
                  px: 2,
                  py: 2,
                  mx: 2,
                  bgcolor: "rgba(255, 240, 195, 0.90)",
                  zIndex: 100,
                  position: "absolute",
                  bottom: "20px",
                }}
              >
                <Typography
                  gutterBottom
                  fontSize={"32px"}
                  fontWeight={700}
                  sx={{ color: "#280F00" }}
                >
                  Need a Garage Door Partner?{" "}
                  <Typography
                    fontSize={"32px"}
                    fontWeight={700}
                    color={
                      campaignName === CAMPAIGN_NAME.A1_GARAGE
                        ? "#E82C2A"
                        : "#FB6125"
                    }
                    display={"inline"}
                  >
                    Get Paid.
                  </Typography>{" "}
                  It’s Easy.
                </Typography>
                <Typography
                  fontSize={"22px"}
                  fontWeight={400}
                  sx={{ color: "#562100" }}
                >
                  Are you a home service pro? Do your customers need garage door
                  repairs or installation? Refer these jobs to our team and
                  we’ll pay you{" "}
                  <Typography
                    fontSize={"22px"}
                    fontWeight={400}
                    display={"inline"}
                    sx={{ textDecorationLine: "underline", color: "#562100" }}
                  >
                    up to {campaignProperties[campaignName].price}
                  </Typography>
                  .*
                </Typography>
                <Box
                  sx={{
                    px: 0,
                    pt: 4,
                    pb: 2,
                    position: "sticky",
                    zIndex: 100,
                    top: 0,
                  }}
                >
                  <Box sx={{}}>
                    <LoadingButton
                      fullWidth
                      sx={{
                        background: "#E82C2A",
                        borderRadius: 0,
                        border: "2px solid #562100",
                      }}
                      variant="contained"
                      onClick={() =>
                        navigate(
                          `/ads/signup/${campaignCode}?${searchParamsString}`
                        )
                      }
                    >
                      GET STARTED
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                py: 3,
                px: 2,
                background: "#F8F9FA",
              }}
            >
              <Box
                sx={{
                  borderRadius: "20px",
                  background: "white",
                  py: 3,
                  px: 3,

                  /* elevation/custom */
                  boxShadow:
                    "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Box pb={3}>
                  <Typography variant="h4">
                    Earn in{" "}
                    <Typography
                      variant="h4"
                      display={"inline"}
                      sx={{ textDecorationLine: "underline" }}
                    >
                      3
                    </Typography>{" "}
                    Quick Steps!{" "}
                  </Typography>
                </Box>
                <Divider sx={{ width: "100%", borderColor: "#D9D9D9" }} />
                <Box display={"flex"} flexDirection="column" gap={2} pt={3}>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"center"}
                    gap={2}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box>
                        <img
                          style={{
                            height: "120px",
                            width: "120px",
                            objectFit: "contain",
                          }}
                          src={
                            campaignName === CAMPAIGN_NAME.A1_GARAGE
                              ? "record.gif"
                              : "/campaign-video.gif"
                          }
                          alt="success"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h1"
                          color={"rgba(17, 25, 39, 0.38)"}
                          sx={{ opacity: 0.3 }}
                        >
                          1
                        </Typography>
                      </Box>
                    </Box>
                    {campaignName === CAMPAIGN_NAME.A1_GARAGE ? (
                      <Box>
                        <Typography variant="h6">
                          Film a Video of the Garage
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          Use your mobile phone to take a 30 second video of the
                          garage service needs
                        </Typography>
                      </Box>
                    ) : (
                      <Box>
                        <Typography variant="h6">
                          Film a Video of the Water Damage
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          Use your cell phone to take a 30 second video
                          describing the service or damage
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"center"}
                    gap={2}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box>
                        <img
                          style={{
                            height: "120px",
                            width: "120px",
                            objectFit: "contain",
                          }}
                          src={
                            campaignName === CAMPAIGN_NAME.A1_GARAGE
                              ? "upload.gif"
                              : "/campaign-doc.gif"
                          }
                          alt="success"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h1"
                          color={"rgba(17, 25, 39, 0.38)"}
                          sx={{ opacity: 0.3 }}
                        >
                          2
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">Upload the Video</Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Sign up so you can upload the video. Artificial
                        Intelligence will summarize (and translate) your video,
                        then automatically send the job to our team
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"center"}
                    gap={2}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box>
                        <img
                          style={{
                            height: "120px",
                            width: "120px",
                            objectFit: "contain",
                          }}
                          src={
                            campaignName === CAMPAIGN_NAME.A1_GARAGE
                              ? "/get paid.gif"
                              : "/campaign-money.gif"
                          }
                          alt="success"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h1"
                          color={"rgba(17, 25, 39, 0.38)"}
                          sx={{ opacity: 0.3 }}
                        >
                          3
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Get Paid Up to {campaignProperties[campaignName].price}*
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Earnings are deposited into your account and funds can
                        be withdrawn instantly
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box pt={3}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                  flexDirection={"column"}
                >
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <Box sx={{ py: 2 }}>
                      <img
                        style={{}}
                        src="/google-reviews.png"
                        alt="google reviews"
                      />
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"start"}
                    justifyContent={"start"}
                    flexDirection={"column"}
                    gap={2}
                  >
                    <Typography variant="h4">
                      Trusted in{" "}
                      {campaignName === CAMPAIGN_NAME.A1_GARAGE
                        ? "the Phoenix"
                        : "Your Local"}{" "}
                      Community
                    </Typography>
                    {campaignName === "ADVANTA" ? (
                      <Typography variant="subtitle1">
                        Owned and operated by Sari Papazian since 2019.
                      </Typography>
                    ) : (
                      <Typography variant="subtitle1">
                        Owned and operated by Steve Fall since 2011.
                      </Typography>
                    )}
                  </Box>
                </Box>
                {campaignName === CAMPAIGN_NAME.ADVANTA ? (
                  <Grid
                    container
                    spacing={[0, 0, 0, 5]}
                    py={3}
                    px={0}
                    justifyContent="space-between"
                    gap={3}
                  >
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#8d6e63", color: "white" }}
                            >{`${
                              "Curtis"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Curtis Blaze" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            After a bad experience with another company{" "}
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              our insurance company referred us to AdvantaClean.{" "}
                            </Typography>
                            From the first contact Tedrick laid everything out
                            for us and exceed our expectations. He handed the
                            daily work to Abraham to lead and he did a great job
                            communicating and getting the job done. You will be
                            in good hands with this team!
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#33691f", color: "white" }}
                            >{`${
                              "Nadine"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Nadine El ghamraoui" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              I was very lucky being referred to this company.
                            </Typography>{" "}
                            We had pipe burst and it was just a disaster and
                            water everywhere. Dean and his team handled
                            everything from A-Z. They took care of the insurance
                            company, helped dry everything out and also did the
                            building back for us. I am truly grateful for Dean
                            and the rest of the team that made this entire
                            process relatively easy.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/review-amanda.png">{`${
                              "Amanada Thompson"
                                ?.toUpperCase()
                                ?.split(" ")?.[0]?.[0]
                            }${
                              "Amanada Thompson"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Amanada Thompson" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            I had a plumbing leak from my washing machine. I
                            looked for a restoration company I had used before,
                            they were getting bad reviews. I researched new
                            companies and found AdvantaClean. Wow, I can’t begin
                            to tell you how honest and wonderful they are.
                            Tedrick got back to me right away and responded to
                            each text/call. Just talked to Sari, the owner, and
                            his expertise and honesty have been such a pleasure!
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/review-adrianny.png">{`${
                              "Arianny Olivares"
                                ?.toUpperCase()
                                ?.split(" ")?.[0]?.[0]
                            }${
                              "Arianny Olivares"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Arianny Olivares" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            I had an emergency where the pipes on the roof of
                            the building exploded. That caused the ceiling of
                            the closet in one room to fall on top of all my
                            belongings, affecting them and flooding the entire
                            apartment. I called AdvantaClean of Norcross and
                            Buford as an emergency on the recommendation of a
                            friend. It was the solution to the problem,
                            excellent service and efficiency, I am very grateful
                            for the work they did.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ) : campaignName === CAMPAIGN_NAME.LIGHTSPEED ? (
                  <Grid
                    container
                    spacing={[0, 0, 0, 5]}
                    py={3}
                    px={0}
                    justifyContent="space-between"
                    gap={3}
                  >
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#ef6c02", color: "white" }}
                            >{`${
                              "Larry Jones"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Larry Jones" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Be hard to find another team as professional than
                            the one Steve put together. The techs are
                            responsible and great at their jobs and Paula is
                            indescribably AWESOME. Steve is the mastermind
                            behind the whole thing. I have personally used them
                            and{" "}
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              I refer my insurance clients{" "}
                            </Typography>
                            in need!
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#33691f", color: "white" }}
                              src="/review-zach.png"
                            >{`${
                              "Zach Fetzer"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Zach Fetzer" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              I referred Lightspeed Restoration to a client of
                              mine and they were very prompt and professional.
                            </Typography>{" "}
                            The customer service and coordination with me has
                            been top notch. I would refer them again in a
                            heartbeat!
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/review-sue.png">{`${
                              "Sue Nelson"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }${
                              "Sue Nelson"?.toUpperCase()?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Sue Nelson" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Having met Paula and learned about Lightspeed
                            Restoration no doubt{" "}
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              this company would be top of mind when I need to
                              refer one of my real estate clients who need
                              cleaning, sanitizing or mold restoration in their
                              home.
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/review-rachel.png">{`${
                              "Rachel McDonough"
                                ?.toUpperCase()
                                ?.split(" ")?.[0]?.[0]
                            }${
                              "Rachel McDonough"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Rachel McDonough" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Steve is responsive and helpful!{" "}
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              We will continue to refer our remodeling clients
                            </Typography>{" "}
                            to him when they have mold removal and other
                            specialty clean-up needs.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={[0, 0, 0, 5]}
                    py={3}
                    px={0}
                    justifyContent="space-between"
                    gap={3}
                  >
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#00579b", color: "white" }}
                            >{`${
                              "Ethan Blake"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Ethan Blake" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            As a real estate agent in North Scottsdale, I
                            recommend A1 for all my sellers getting homes ready
                            to list. Their technicians are always on time, super
                            friendly, and provide great value. They handle it
                            all smoothly.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#33691f", color: "white" }}
                              src="/lily-foster.png"
                            >{`${
                              "Lily Foster"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Lily Foster" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            I paint a lot of garages in Phoenix and I always
                            tell homeowners to use A1 Garage for their garage
                            door servicing needs. I&apos;ve seen firsthand how
                            fantastic these guys are. If you&apos;re spending
                            time and money fixing up your garage walls, replace
                            your garage doors with A1.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#bf360d", color: "white" }}
                            >{`${"Joan"?.toUpperCase()?.split(" ")?.[0]?.[0]}${
                              "Joan Sullivan"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Joan Sullivan" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            OK, A1 lives up to the hype. I run a driveway paving
                            business and my homeowners were asking for a garage
                            door service. I recently started recommending A1
                            Garage and my clients have never been happier. The
                            team knows what they’re doing and they’re super
                            fast.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/jessica-harper.png">{`${
                              "Jessica Harper"
                                ?.toUpperCase()
                                ?.split(" ")?.[0]?.[0]
                            }${
                              "Jessica Harper"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Jessica Harper" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            I manage several properties in North Phoenix and
                            often need last-minute parts and quick fixes for
                            garage doors before renters check in. A1 Garage is
                            my go-to for reliable and fast service. They always
                            come through, even on short notice.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Typography
                color="text.secondary"
                fontSize={"14px"}
                fontWeight={400}
              >
                *Total compensation will depend on the job type and may be up to{" "}
                {campaignProperties[campaignName].price}. Payment will be
                remitted after a job is signed and paid by direct deposit.
              </Typography>
            </Box>
            <Box
              width={"100%"}
              py={2}
              sx={{
                background: campaignProperties[campaignName].background,
              }}
              px={2}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Typography color="white" fontSize={"15px"} fontWeight={600}>
                  To learn more, visit{" "}
                  <Typography
                    color={campaignProperties[campaignName].linkColor}
                    fontSize={"15px"}
                    sx={{ cursor: "pointer" }}
                    display="inline"
                    fontWeight={600}
                    onClick={() => {
                      handleIconClick();
                    }}
                  >
                    our website
                  </Typography>
                </Typography>
                <OpenInNewOutlinedIcon
                  fontSize="small"
                  sx={{
                    color: campaignProperties[campaignName].linkColor,
                    ml: 0.5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleIconClick();
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : campaignName === CAMPAIGN_NAME.MR_ELECTRIC ? (
        <Grid
          container
          spacing={[0, 0, 0, 5]}
          justifyContent="space-between"
          sx={{ p: 0, m: 0 }}
        >
          <Grid
            item
            xs={12}
            lg={8}
            sx={{
              p: 0,
              m: 0,
            }}
          >
            <Box position={"relative"}>
              <img
                src="/mr-electric-mb.png"
                alt="mr electric mobile"
                style={{
                  height: "750px",
                  width: "100%",
                }}
              />
              <Box
                sx={{
                  px: 2,
                  py: 2,
                  top: 0,
                  height: "auto",
                  width: "100%",
                  left: 0,
                  zIndex: 1000,
                  position: "fixed",
                  display: "flex",
                  // flexDirection: "column",
                  gap: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "white",
                }}
              >
                <img
                  style={{
                    width: "150px",
                    objectFit: "contain",
                  }}
                  src={
                    campaignName
                      ? campaignProperties[campaignName].logoUrl
                      : "/logo.svg"
                  }
                  alt="success"
                />
                <Box
                  sx={{
                    px: 0,
                    display: "flex",
                    width: "fit-content",
                    justifyContent: "start",
                  }}
                >
                  <LoadingButton
                    fullWidth
                    sx={{
                      background: "#006AC6",
                    }}
                    variant="contained"
                    onClick={() => {
                      trackEvent(AnalyticsEvents.CLICKED_CAMPAIGN_GET_STARTED, {
                        campaignName,
                        campaignCode,
                      });
                      navigate(
                        `/ads/signup/${campaignCode}?${searchParamsString}`
                      );
                    }}
                  >
                    GET STARTED
                  </LoadingButton>
                </Box>
              </Box>
              <Box
                sx={{
                  px: 2,
                  py: 2,
                  mx: 2,
                  bgcolor: "#EBF3FB",
                  zIndex: 100,
                  position: "absolute",
                  bottom: "48px",
                }}
              >
                <Typography
                  gutterBottom
                  fontSize={"32px"}
                  fontWeight={700}
                  sx={{ color: "#280F00" }}
                >
                  Need an Electrical Partner?{" "}
                  <Typography
                    fontSize={"32px"}
                    fontWeight={700}
                    color={"#006AC6"}
                    display={"inline"}
                  >
                    Get Paid.
                  </Typography>{" "}
                  It’s Easy.
                </Typography>
                <Typography
                  fontSize={"22px"}
                  fontWeight={400}
                  sx={{ color: "#562100" }}
                >
                  Are you a home service pro and blocked by electrical work? Do
                  your customers need electrical repairs or installation? Refer
                  these jobs to our team and we’ll pay you!
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                py: 3,
                px: 2,
                background: "#F8F9FA",
              }}
            >
              <Box
                sx={{
                  borderRadius: "20px",
                  background: "white",
                  py: 3,
                  px: 3,

                  /* elevation/custom */
                  boxShadow:
                    "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Box pb={3}>
                  <Typography variant="h4">
                    Earn in{" "}
                    <Typography
                      variant="h4"
                      display={"inline"}
                      sx={{ textDecorationLine: "underline" }}
                    >
                      3
                    </Typography>{" "}
                    Quick Steps!{" "}
                  </Typography>
                </Box>
                <Divider sx={{ width: "100%", borderColor: "#D9D9D9" }} />
                <Box display={"flex"} flexDirection="column" gap={2} pt={3}>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"center"}
                    gap={2}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box>
                        <img
                          style={{
                            height: "120px",
                            width: "120px",
                            objectFit: "contain",
                          }}
                          src={campaignProperties[campaignName].filmVideoUrl}
                          alt="success"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h1"
                          color={"rgba(17, 25, 39, 0.38)"}
                          sx={{ opacity: 0.3 }}
                        >
                          1
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Film a Video of the Electrical repair
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Use your mobile phone to take a 30 second video of the
                        electrical service needs
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"center"}
                    gap={2}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box>
                        <img
                          style={{
                            height: "120px",
                            width: "120px",
                            objectFit: "contain",
                          }}
                          src={campaignProperties[campaignName].uploadGifUrl}
                          alt="success"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h1"
                          color={"rgba(17, 25, 39, 0.38)"}
                          sx={{ opacity: 0.3 }}
                        >
                          2
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">Upload the Video</Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Sign up so you can upload the video. Artificial
                        Intelligence will summarize (and translate) your video,
                        then automatically send the job to our team
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"center"}
                    gap={2}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box>
                        <img
                          style={{
                            height: "120px",
                            width: "120px",
                            objectFit: "contain",
                          }}
                          src={campaignProperties[campaignName].getPaidGifUrl}
                          alt="success"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h1"
                          color={"rgba(17, 25, 39, 0.38)"}
                          sx={{ opacity: 0.3 }}
                        >
                          3
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">Get Paid</Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Earnings are deposited into your account and funds can
                        be withdrawn instantly
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box pt={3}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                  flexDirection={"column"}
                >
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <Box sx={{ py: 2 }}>
                      <img
                        style={{}}
                        src="/google-reviews.png"
                        alt="google reviews"
                      />
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"start"}
                    justifyContent={"start"}
                    flexDirection={"column"}
                    gap={2}
                  >
                    <Typography variant="h4">
                      Trusted in{" "}
                      {campaignCode === "dallas-fort-worth"
                        ? "Dallas-Fort Worth Since 2012"
                        : "The Houston Community"}
                    </Typography>
                    <Typography variant="subtitle1">
                      Owned and operated by{" "}
                      {campaignCode === "dallas-fort-worth"
                        ? "Robert and Frances Lummus"
                        : `${campaignProperties[campaignName].owner} since ${campaignProperties[campaignName].yearEstablished}`}
                      .
                    </Typography>
                  </Box>
                </Box>
                {campaignCode === "dallas-fort-worth" ? (
                  <Grid
                    container
                    spacing={[0, 0, 0, 5]}
                    py={3}
                    px={0}
                    justifyContent="space-between"
                    gap={3}
                  >
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/jeffrey-gregory.png">{`${
                              ""?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }${
                              "Jeffrey Gregory"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Jeffrey Gregory" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Jeremiah did a great job diagnosing my issues. He
                            gave what I felt was a fair quote the following day
                            he was able to complete the work as promised,
                            despite some unforeseen issues once he got inside
                            the walls. His assistant Rhet was also excellent.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/brannon-cox.png">{`${
                              "Brannon Cox"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }${
                              "Brannon Cox"?.toUpperCase()?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Brannon Cox" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Great experience! We&apos;ve had multiple
                            contractors out to help with our house and Mr.
                            Electric was by FAR the best. Our rep was Junior
                            Alvarez and he stopped and explained exactly what he
                            was seeing, what his plan to fix it was and how long
                            it would take. A few weeks later and we&apos;ve had
                            no issues at all with the service and even had the
                            rep check back in to make sure we were satisfied.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#C2175B", color: "white" }}
                            >{`${
                              "Tom Allen"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Tom Allen" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Great service! Eric was tremendous. He diagnosed the
                            problem, ran to the store to get an unusual part and
                            then fixed a home outage very quickly. Very
                            competent and very friendly. Carrie was also
                            extremely friendly and helpful in working me in,
                            setting up the appointment on a Saturday and
                            following up with updates. The whole experience
                            couldn’t have been better. Happy ending to an outage
                            arising the day before Easter.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/stacia-jakes.png">{`${
                              "Stacia Jakes"
                                ?.toUpperCase()
                                ?.split(" ")?.[0]?.[0]
                            }${
                              "Stacia Jakes"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Stacia Jakes" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Ray came in and did the job quickly, and also
                            spotted some unsafe electrical hook up’s we had and
                            got them fixed right away to avoid melting wires and
                            fire. The job he came for was to unhook power supply
                            going to outside hot tub and make it safe for tub
                            removal. Ray went above and beyond. I will
                            definitely be contacting Mr Electric and requesting
                            Ray for future electrical needs.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={[0, 0, 0, 5]}
                    py={3}
                    px={0}
                    justifyContent="space-between"
                    gap={3}
                  >
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/adam-korn.png">{`${
                              "Adam Korn"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }${
                              "Adam Korn"?.toUpperCase()?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Adam Korn" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            We had Sam install a transfer switch for a portable
                            generator and we couldn&apos;t be more pleased with
                            the service. He explained everything in detail, made
                            sure we understood the costs, and he left the work
                            area cleaner than before he arrived. We would
                            recommend Mr. Electric and Sam any day and will no
                            doubt use them again.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#78919c", color: "white" }}
                            >{`${
                              "L. R."?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"L. R." ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Electrician Joshua Rogan from Mr. Electric is very
                            knowledgeable and professional. Joshua did an
                            outstanding job installing a new service panel on my
                            house and left the job site clean. I highly
                            recommend Mr. Electric and Joshua Rogan to anyone
                            needing electrical work performed at their home or
                            business. Mr. Electric does quality work at a fair
                            price.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#8d6e63", color: "white" }}
                            >{`${
                              "Denise Caldwell"
                                ?.toUpperCase()
                                ?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Denise Caldwell" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            This Electric company and Technician have the best
                            service and professional technician name Robert
                            Viema. This Technician was knowledgeable and
                            professional with his craft. He showed up and time
                            and took care of business. I highly recommend this
                            guy and company. He installed three beautiful
                            ceiling fans and three pendant lights.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#f4511e", color: "white" }}
                            >{`${
                              "Kevin"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Kevin" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Travis was extremely professional and knowledgeable
                            in the installation of an electrical outlet to
                            connect my generator to the breaker box. First, the
                            evaluation of situation and his detailing of the
                            quote was thorough and at no point were his
                            explanations confusing. Then on the day of the
                            install, he was prompt, performed the service as
                            advertised and then very thoroughly explained how to
                            operate our generator with the new hookup. Very
                            professional job all the way around.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
              {campaignProperties[campaignName].price ? (
                <Typography
                  color="text.secondary"
                  fontSize={"14px"}
                  fontWeight={400}
                >
                  *Total compensation will depend on the job type and may be up
                  to {campaignProperties[campaignName].price}. Payment will be
                  remitted after a job is signed and paid by direct deposit.
                </Typography>
              ) : null}
            </Box>
            <Box
              width={"100%"}
              py={2}
              sx={{
                background: campaignProperties[campaignName].background,
              }}
              px={2}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Typography color="white" fontSize={"15px"} fontWeight={600}>
                  To learn more, visit{" "}
                  <Typography
                    color={campaignProperties[campaignName].linkColor}
                    fontSize={"15px"}
                    sx={{ cursor: "pointer" }}
                    display="inline"
                    fontWeight={600}
                    onClick={() => {
                      handleIconClick();
                    }}
                  >
                    our website
                  </Typography>
                </Typography>
                <OpenInNewOutlinedIcon
                  fontSize="small"
                  sx={{
                    color: campaignProperties[campaignName].linkColor,
                    ml: 0.5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleIconClick();
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={[0, 0, 0, 5]}
          justifyContent="space-between"
          sx={{ p: 0, m: 0 }}
        >
          <Grid
            item
            xs={12}
            lg={8}
            sx={{
              background: campaignProperties[campaignName].backgroundGradient,
              p: 0,
              m: 0,
            }}
          >
            <Box
              sx={{
                px: 4,
                pt: 4,
              }}
            >
              <Box
              // sx={{
              //   ...(campaignName === CAMPAIGN_NAME.A1_GARAGE
              //     ? { bgcolor: "rgba(255, 240, 195, 0.90)" }
              //     : {}),
              // }}
              >
                <Typography gutterBottom variant="h4" color="white">
                  Need a Garage Door Partner?{" "}
                  <Typography
                    variant="h4"
                    color={
                      campaignName === CAMPAIGN_NAME.A1_GARAGE
                        ? "#E82C2A"
                        : "#FB6125"
                    }
                    display={"inline"}
                  >
                    Get Paid.
                  </Typography>{" "}
                  It’s Easy.
                </Typography>
                <Typography fontSize={"22px"} fontWeight={400} color="white">
                  Are you a home service pro? Do your customers need garage door
                  repairs or installation? Refer these jobs to our team and
                  we’ll pay you{" "}
                  <Typography
                    fontSize={"22px"}
                    fontWeight={400}
                    color="white"
                    display={"inline"}
                    sx={{ textDecorationLine: "underline" }}
                  >
                    up to {campaignProperties[campaignName].price}
                  </Typography>
                  .*
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                background: campaignProperties[campaignName].backgroundGradient,
                px: 4,
                py: 4,
                position: "sticky",
                zIndex: 100,
                top: 0,
              }}
            >
              <Box sx={{}}>
                <LoadingButton
                  fullWidth
                  sx={{
                    ...(campaignName == "ADVANTA"
                      ? {
                          border: "3px solid #F8895A",
                          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
                          borderRadius: 0,
                          background: "#C94610",
                        }
                      : campaignName == "LIGHTSPEED"
                      ? { background: "#FB6125" }
                      : {
                          background: "#E82C2A",
                          borderRadius: 0,
                          border: "2px solid #562100",
                        }),
                    "&:hover": {
                      color: "#FB6125",
                      bgcolor: "white",
                    },
                  }}
                  variant="contained"
                  onClick={() =>
                    navigate(
                      `/ads/signup/${campaignCode}?${searchParamsString}`
                    )
                  }
                >
                  GET STARTED
                </LoadingButton>
              </Box>
            </Box>
            <Box
              sx={{
                py: 3,
                px: 2,
                background: "#F8F9FA",
              }}
            >
              <Box
                sx={{
                  borderRadius: "20px",
                  background: "white",
                  py: 3,
                  px: 3,

                  /* elevation/custom */
                  boxShadow:
                    "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Box pb={3}>
                  <Typography variant="h4">
                    Earn in{" "}
                    <Typography
                      variant="h4"
                      display={"inline"}
                      sx={{ textDecorationLine: "underline" }}
                    >
                      3
                    </Typography>{" "}
                    Quick Steps!{" "}
                  </Typography>
                </Box>
                <Divider sx={{ width: "100%", borderColor: "#D9D9D9" }} />
                <Box display={"flex"} flexDirection="column" gap={2} pt={3}>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"center"}
                    gap={2}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box>
                        <img
                          style={{
                            height: "120px",
                            width: "120px",
                            objectFit: "contain",
                          }}
                          src={
                            campaignName === CAMPAIGN_NAME.A1_GARAGE
                              ? "record.gif"
                              : "/campaign-video.gif"
                          }
                          alt="success"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h1"
                          color={"rgba(17, 25, 39, 0.38)"}
                          sx={{ opacity: 0.3 }}
                        >
                          1
                        </Typography>
                      </Box>
                    </Box>
                    {campaignName === CAMPAIGN_NAME.A1_GARAGE ? (
                      <Box>
                        <Typography variant="h6">
                          Film a Video of the Garage
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          Use your mobile phone to take a 30 second video of the
                          garage service needs
                        </Typography>
                      </Box>
                    ) : (
                      <Box>
                        <Typography variant="h6">
                          Film a Video of the Water Damage
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          Use your cell phone to take a 30 second video
                          describing the service or damage
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"center"}
                    gap={2}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box>
                        <img
                          style={{
                            height: "120px",
                            width: "120px",
                            objectFit: "contain",
                          }}
                          src={
                            campaignName === CAMPAIGN_NAME.A1_GARAGE
                              ? "upload.gif"
                              : "/campaign-doc.gif"
                          }
                          alt="success"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h1"
                          color={"rgba(17, 25, 39, 0.38)"}
                          sx={{ opacity: 0.3 }}
                        >
                          2
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">Upload the Video</Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Sign up so you can upload the video. Artificial
                        Intelligence will summarize (and translate) your video,
                        then automatically send the job to our team
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"center"}
                    gap={2}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box>
                        <img
                          style={{
                            height: "120px",
                            width: "120px",
                            objectFit: "contain",
                          }}
                          src={
                            campaignName === CAMPAIGN_NAME.A1_GARAGE
                              ? "/get paid.gif"
                              : "/campaign-money.gif"
                          }
                          alt="success"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h1"
                          color={"rgba(17, 25, 39, 0.38)"}
                          sx={{ opacity: 0.3 }}
                        >
                          3
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Get Paid Up to {campaignProperties[campaignName].price}*
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        Earnings are deposited into your account and funds can
                        be withdrawn instantly
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box pt={3}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                  flexDirection={"column"}
                >
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <Box sx={{ py: 2 }}>
                      <img
                        style={{}}
                        src="/google-reviews.png"
                        alt="google reviews"
                      />
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"start"}
                    justifyContent={"start"}
                    flexDirection={"column"}
                    gap={2}
                  >
                    <Typography variant="h4">
                      Trusted in{" "}
                      {campaignName === CAMPAIGN_NAME.A1_GARAGE
                        ? "the Phoenix"
                        : "Your Local"}{" "}
                      Community
                    </Typography>
                    {campaignName === "ADVANTA" ? (
                      <Typography variant="subtitle1">
                        Owned and operated by Sari Papazian since 2019.
                      </Typography>
                    ) : (
                      <Typography variant="subtitle1">
                        Owned and operated by Steve Fall since 2011.
                      </Typography>
                    )}
                  </Box>
                </Box>
                {campaignName === CAMPAIGN_NAME.ADVANTA ? (
                  <Grid
                    container
                    spacing={[0, 0, 0, 5]}
                    py={3}
                    px={0}
                    justifyContent="space-between"
                    gap={3}
                  >
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#8d6e63", color: "white" }}
                            >{`${
                              "Curtis"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Curtis Blaze" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            After a bad experience with another company{" "}
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              our insurance company referred us to AdvantaClean.{" "}
                            </Typography>
                            From the first contact Tedrick laid everything out
                            for us and exceed our expectations. He handed the
                            daily work to Abraham to lead and he did a great job
                            communicating and getting the job done. You will be
                            in good hands with this team!
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#33691f", color: "white" }}
                            >{`${
                              "Nadine"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Nadine El ghamraoui" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              I was very lucky being referred to this company.
                            </Typography>{" "}
                            We had pipe burst and it was just a disaster and
                            water everywhere. Dean and his team handled
                            everything from A-Z. They took care of the insurance
                            company, helped dry everything out and also did the
                            building back for us. I am truly grateful for Dean
                            and the rest of the team that made this entire
                            process relatively easy.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/review-amanda.png">{`${
                              "Amanada Thompson"
                                ?.toUpperCase()
                                ?.split(" ")?.[0]?.[0]
                            }${
                              "Amanada Thompson"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Amanada Thompson" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            I had a plumbing leak from my washing machine. I
                            looked for a restoration company I had used before,
                            they were getting bad reviews. I researched new
                            companies and found AdvantaClean. Wow, I can’t begin
                            to tell you how honest and wonderful they are.
                            Tedrick got back to me right away and responded to
                            each text/call. Just talked to Sari, the owner, and
                            his expertise and honesty have been such a pleasure!
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/review-adrianny.png">{`${
                              "Arianny Olivares"
                                ?.toUpperCase()
                                ?.split(" ")?.[0]?.[0]
                            }${
                              "Arianny Olivares"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Arianny Olivares" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            I had an emergency where the pipes on the roof of
                            the building exploded. That caused the ceiling of
                            the closet in one room to fall on top of all my
                            belongings, affecting them and flooding the entire
                            apartment. I called AdvantaClean of Norcross and
                            Buford as an emergency on the recommendation of a
                            friend. It was the solution to the problem,
                            excellent service and efficiency, I am very grateful
                            for the work they did.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ) : campaignName === CAMPAIGN_NAME.LIGHTSPEED ? (
                  <Grid
                    container
                    spacing={[0, 0, 0, 5]}
                    py={3}
                    px={0}
                    justifyContent="space-between"
                    gap={3}
                  >
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#ef6c02", color: "white" }}
                            >{`${
                              "Larry Jones"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Larry Jones" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Be hard to find another team as professional than
                            the one Steve put together. The techs are
                            responsible and great at their jobs and Paula is
                            indescribably AWESOME. Steve is the mastermind
                            behind the whole thing. I have personally used them
                            and{" "}
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              I refer my insurance clients{" "}
                            </Typography>
                            in need!
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#33691f", color: "white" }}
                              src="/review-zach.png"
                            >{`${
                              "Zach Fetzer"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Zach Fetzer" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              I referred Lightspeed Restoration to a client of
                              mine and they were very prompt and professional.
                            </Typography>{" "}
                            The customer service and coordination with me has
                            been top notch. I would refer them again in a
                            heartbeat!
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/review-sue.png">{`${
                              "Sue Nelson"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }${
                              "Sue Nelson"?.toUpperCase()?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Sue Nelson" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Having met Paula and learned about Lightspeed
                            Restoration no doubt{" "}
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              this company would be top of mind when I need to
                              refer one of my real estate clients who need
                              cleaning, sanitizing or mold restoration in their
                              home.
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/review-rachel.png">{`${
                              "Rachel McDonough"
                                ?.toUpperCase()
                                ?.split(" ")?.[0]?.[0]
                            }${
                              "Rachel McDonough"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Rachel McDonough" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            Steve is responsive and helpful!{" "}
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              display={"inline"}
                            >
                              We will continue to refer our remodeling clients
                            </Typography>{" "}
                            to him when they have mold removal and other
                            specialty clean-up needs.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={[0, 0, 0, 5]}
                    py={3}
                    px={0}
                    justifyContent="space-between"
                    gap={3}
                  >
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#00579b", color: "white" }}
                            >{`${
                              "Ethan Blake"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Ethan Blake" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            As a real estate agent in North Scottsdale, I
                            recommend A1 for all my sellers getting homes ready
                            to list. Their technicians are always on time, super
                            friendly, and provide great value. They handle it
                            all smoothly.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#33691f", color: "white" }}
                              src="/lily-foster.png"
                            >{`${
                              "Lily Foster"?.toUpperCase()?.split(" ")?.[0]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Lily Foster" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            I paint a lot of garages in Phoenix and I always
                            tell homeowners to use A1 Garage for their garage
                            door servicing needs. I&apos;ve seen firsthand how
                            fantastic these guys are. If you&apos;re spending
                            time and money fixing up your garage walls, replace
                            your garage doors with A1.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar
                              sx={{ bgcolor: "#bf360d", color: "white" }}
                            >{`${"Joan"?.toUpperCase()?.split(" ")?.[0]?.[0]}${
                              "Joan Sullivan"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Joan Sullivan" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            OK, A1 lives up to the hype. I run a driveway paving
                            business and my homeowners were asking for a garage
                            door service. I recently started recommending A1
                            Garage and my clients have never been happier. The
                            team knows what they’re doing and they’re super
                            fast.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        p={3}
                        sx={{
                          borderRadius: "20px",
                          background: "#FFF",

                          /* elevation/custom */
                          boxShadow:
                            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                        }}
                      >
                        <Box
                          pb={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Box display={"flex"} gap={2} position="relative">
                            <img
                              src="/quote.png"
                              alt="quote"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "80px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                            />
                            <Avatar src="/jessica-harper.png">{`${
                              "Jessica Harper"
                                ?.toUpperCase()
                                ?.split(" ")?.[0]?.[0]
                            }${
                              "Jessica Harper"
                                ?.toUpperCase()
                                ?.split(" ")?.[1]?.[0]
                            }`}</Avatar>
                            <Box>
                              <Typography variant="subtitle2" fontWeight={700}>
                                {"Jessica Harper" ?? "unknown"}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Rating
                                  readOnly
                                  size="small"
                                  name="google-rating"
                                  defaultValue={5}
                                  value={5}
                                  precision={0.1}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Typography variant="body2">
                            I manage several properties in North Phoenix and
                            often need last-minute parts and quick fixes for
                            garage doors before renters check in. A1 Garage is
                            my go-to for reliable and fast service. They always
                            come through, even on short notice.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Typography
                color="text.secondary"
                fontSize={"14px"}
                fontWeight={400}
              >
                *Total compensation will depend on the job type and may be up to{" "}
                {campaignProperties[campaignName].price}. Payment will be
                remitted after a job is signed and paid by direct deposit.
              </Typography>
            </Box>
            <Box
              width={"100%"}
              py={2}
              sx={{
                background: campaignProperties[campaignName].background,
              }}
              px={2}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Typography color="white" fontSize={"15px"} fontWeight={600}>
                  To learn more, visit{" "}
                  <Typography
                    color={campaignProperties[campaignName].linkColor}
                    fontSize={"15px"}
                    display="inline"
                    fontWeight={600}
                  >
                    our website
                  </Typography>
                </Typography>
                <OpenInNewOutlinedIcon
                  fontSize="small"
                  sx={{
                    color: campaignProperties[campaignName].linkColor,
                    ml: 0.5,
                  }}
                  onClick={() => {
                    handleIconClick();
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
