import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { useState } from "react";

import { JobSectors } from "@/company/jobs/constants";

import CheckboxList from "./CheckboxList";
import ServiceCategoryCheckboxList from "./ServiceCategoryCheckboxList";
import { useInternalCompanySearchFilterContext } from "./useInternalCompanySearchFilter";

export enum TabsEnum {
  COMPANY_TYPE = "COMPANY_TYPE",
  OWNERSHIP = "OWNERSHIP",
  REFERRAL_NETWORK = "REFERRAL_NETWORK",
  REVIEW_AND_QUALITY_SIGNALS = "REVIEW_AND_QUALITY_SIGNALS",
  OTHER_CRITERIA = "OTHER_CRITERIA",
  SAVED_LIST = "SAVED_LIST",
}

export default function HomeBusinessSearchList() {
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const { addFilter, removeFilter, filters } =
    useInternalCompanySearchFilterContext();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
      p={2}
      sx={{
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
        background: "#FFF",
      }}
    >
      <Box display={"flex"} gap={2} alignItems={"center"} pb={1}>
        <HomeOutlinedIcon />
        <Typography fontSize={"14px"} fontWeight={600}>
          Home Service Businesses
        </Typography>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box display={"flex"} width={"100%"} gap={2} mt={1}>
        <Box display={"flex"} width={"50%"} flexDirection={"column"}>
          <Box>
            <Typography variant="overline" color="text.secondary">
              SERVICE CATEGORY
            </Typography>
          </Box>
          <Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              {open ? (
                <ExpandLess onClick={() => setOpen(false)} />
              ) : (
                <ExpandMore onClick={() => setOpen(true)} />
              )}
              <FormControlLabel
                label={
                  <Typography variant="subtitle2" color="text.secondary">
                    All ({Object.keys(JobSectors).length})
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    sx={{ width: "20px", height: "20px", p: 0, m: 1 }}
                    checked={checked}
                    onChange={() =>
                      setChecked(checked => {
                        return !checked;
                      })
                    }
                  />
                }
              />
            </Box>
            <Collapse unmountOnExit in={open} timeout="auto">
              <Box pl={6}>
                <ServiceCategoryCheckboxList isAllChecked={checked} />
              </Box>
            </Collapse>
          </Box>
          <Box>
            <Typography variant="overline" color="text.secondary">
              OTHER FILTERS
            </Typography>
            <Box>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  disableFuture
                  sx={{ width: { xs: 1 } }}
                  maxDate={DateTime.now()}
                  label={"Company Creation Date"}
                  onChange={newValue => {
                    if (newValue) {
                      addFilter({ createdAt: newValue });
                    } else {
                      removeFilter("createdAt");
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} width={"50%"} flexDirection={"column"}>
          <Box>
            <Typography variant="overline" color="text.secondary">
              More Filters
            </Typography>
          </Box>
          <CheckboxList
            parent={"Non-Competing Companies"}
            parentChecked={!!filters.nonCompetingCompanies}
            toggleParentChecked={() => {
              if (filters.nonCompetingCompanies) {
                removeFilter("nonCompetingCompanies");
              } else {
                addFilter({ nonCompetingCompanies: true });
              }
            }}
          />
          <CheckboxList
            parent={"Archived Companies"}
            parentChecked={!!filters.archived}
            toggleParentChecked={() => {
              if (filters.archived) {
                removeFilter("archived");
              } else {
                addFilter({ archived: true });
              }
            }}
          />
          <CheckboxList
            parent={"Franchise"}
            checklist={["Single-service", "Family of brands"]}
            parentChecked={!!filters.franchise}
            toggleParentChecked={() => {
              if (filters.franchise) {
                removeFilter("franchise");
              } else {
                addFilter({
                  franchise: ["Single-service", "Family of brands"],
                });
              }
            }}
            setSelectedList={list => {
              addFilter({
                franchise: list,
              });
            }}
            selectedList={filters.franchise}
          />
          <CheckboxList
            parent={"Business Age"}
            checklist={[
              "New <2 years",
              "Scaling 2-5 years",
              "Established 5-10 years",
              "Long Standing Partner>10 years",
              "Unknown",
            ]}
            parentChecked={!!filters.businessAge}
            toggleParentChecked={() => {
              if (filters.businessAge) {
                removeFilter("businessAge");
              } else {
                addFilter({
                  businessAge: [
                    "New <2 years",
                    "Scaling 2-5 years",
                    "Established 5-10 years",
                    "Long Standing Partner>10 years",
                    "Unknown",
                  ],
                });
              }
            }}
            setSelectedList={list => {
              addFilter({
                businessAge: list,
              });
            }}
            selectedList={filters.businessAge}
          />
          <CheckboxList
            parent={"Relevant sectors"}
            parentChecked={!!filters.relevantSectors}
            toggleParentChecked={() => {
              if (filters.relevantSectors) {
                removeFilter("relevantSectors");
              } else {
                addFilter({ relevantSectors: true });
              }
            }}
          />
          <CheckboxList
            parent={"Confirmed insured"}
            parentChecked={!!filters.confirmedInsured}
            toggleParentChecked={() => {
              if (filters.confirmedInsured) {
                removeFilter("confirmedInsured");
              } else {
                addFilter({ confirmedInsured: true });
              }
            }}
          />
          <CheckboxList
            parent={"Existing customer referral program"}
            parentChecked={!!filters.existingCustomerReferralProgram}
            toggleParentChecked={() => {
              if (filters.existingCustomerReferralProgram) {
                removeFilter("existingCustomerReferralProgram");
              } else {
                addFilter({ existingCustomerReferralProgram: true });
              }
            }}
          />
          <CheckboxList
            parent={"Existing referral"}
            parentChecked={!!filters.existingReferral}
            toggleParentChecked={() => {
              if (filters.existingReferral) {
                removeFilter("existingReferral");
              } else {
                addFilter({ existingReferral: true });
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
