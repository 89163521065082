import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { JobSalePriceSelectField } from "@/company/components/JobSalePriceSelectField";
import { JobSectors } from "@/company/jobs/constants";
import { PayReferralFeeSettings } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { AutocompleteField } from "@/lib/form-fields/AutocompleteField";
import { BulletListInputField } from "@/lib/form-fields/BulletListInputField";
import { DatePickerField } from "@/lib/form-fields/DatePickerField";
import { TextInputField } from "@/lib/form-fields/TextInputField";
import { UseModalState } from "@/lib/modals/useModalState";
import { isDefined } from "@/utils/isDefined";

import { useCreateJobPromotion } from "../api/useCreateJobPromotion";
import { useUpdateJobPromotion } from "../api/useUpdateJobPromotion";
import { JobPromotion } from "../types";

interface AddJobPromotionDialogProps {
  modalState: UseModalState;
  onSuccess: () => void;
  jobPromotion?: JobPromotion;
}

const addJobPromotionFormSchema = yup.object().shape({
  startDate: yup.mixed<DateTime>().required("Promotion Start Date is required"),
  endDate: yup.mixed<DateTime>().required("Promotion End Date is required"),
  title: yup.string().required("Promotion Title is required"),
  description: yup
    .string()
    .required("Promotion Description is required")
    .min(26, "Promotion Description is required"),
  sector: yup
    .mixed<JobSectors>()
    .required("Promotion Service Category is required"),
  payReferralFeeAfterJobIsDone: yup.boolean().required(),
  payReferralFeeSettings: yup
    .mixed<PayReferralFeeSettings>()
    .when("payReferralFeeAfterJobIsDone", {
      is: (payReferralFeeAfterJobIsDone: boolean) =>
        payReferralFeeAfterJobIsDone === true,
      then: s => s.required("Promotion Pay Referral Fee Settings is required"),
      otherwise: s => s.nullable(),
    }),
  referralSalePrice: yup
    .number()
    .when(["payReferralFeeSettings", "payReferralFeeAfterJobIsDone"], {
      is: (
        payReferralFeeSettings: string,
        payReferralFeeAfterJobIsDone: boolean
      ) =>
        payReferralFeeAfterJobIsDone === true &&
        payReferralFeeSettings === PayReferralFeeSettings.FIXED_AMOUNT,
      then: s => s.required("Referral Sale Price is required").min(1),
      otherwise: s => s.nullable(),
    }),
  referralPercentageFee: yup
    .number()
    .when(["payReferralFeeSettings", "payReferralFeeAfterJobIsDone"], {
      is: (
        payReferralFeeSettings: string,
        payReferralFeeAfterJobIsDone: boolean
      ) =>
        payReferralFeeAfterJobIsDone === true &&
        payReferralFeeSettings ===
          PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE,
      then: s =>
        s.required("Referral Fee Percentage is required").min(1).max(100),
      otherwise: s => s.nullable(),
    }),
  referralLeadPrice: yup.number().when("isPayForEveryReferralEnabled", {
    is: (isPayForEveryReferralEnabled: boolean) =>
      isPayForEveryReferralEnabled === true,
    then: s => s.required("Referral Price is required").min(1),
    otherwise: s => s.nullable(),
  }),
  isPayForEveryReferralEnabled: yup.boolean().required(),
});

type AddJobPromotionFormFields = yup.InferType<
  typeof addJobPromotionFormSchema
>;

export function AddJobPromotionDialog(props: AddJobPromotionDialogProps) {
  const { modalState, onSuccess, jobPromotion } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();

  const inEditMode = isDefined(jobPromotion);

  const {
    mutateAsync: createJobPromotionAsync,
    isLoading: isCreatingJobPromotion,
  } = useCreateJobPromotion({
    onSuccess: () => {
      modalState.closeModal();
      onSuccess();
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while creating the promotion";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const {
    mutateAsync: updateJobPromotionAsync,
    isLoading: isUpdatingJobPromotion,
  } = useUpdateJobPromotion({
    onSuccess: () => {
      modalState.closeModal();
      onSuccess();
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while updating the promotion";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const jobSectorOptions = useMemo(
    () =>
      session?.companySectors
        ?.map(companySector => {
          return {
            label: snakeCaseJobSectors[companySector.sector],
            value: companySector.sector,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label)),
    [session]
  );

  const addJobPromotionFormMethods = useFormik<AddJobPromotionFormFields>({
    initialValues: inEditMode
      ? {
          startDate: DateTime.fromISO(jobPromotion?.startDate),
          endDate: DateTime.fromISO(jobPromotion?.endDate),
          title: jobPromotion?.title,
          description: jobPromotion?.description,
          sector: jobPromotion?.sector,
          payReferralFeeSettings: jobPromotion?.payReferralFeeSettings,
          referralSalePrice: jobPromotion?.referralSalePrice ?? undefined,
          referralPercentageFee:
            jobPromotion?.referralPercentageFee ?? undefined,
          payReferralFeeAfterJobIsDone:
            jobPromotion?.payReferralFeeAfterJobIsDone,
          referralLeadPrice: jobPromotion?.leadPrice ?? undefined,
          isPayForEveryReferralEnabled: isDefined(jobPromotion?.leadPrice),
        }
      : {
          startDate: DateTime.now(),
          endDate: DateTime.now(),
          title: "",
          description: "",
          sector: jobSectorOptions?.[0].value ?? ("" as JobSectors),
          payReferralFeeSettings: PayReferralFeeSettings.FIXED_AMOUNT,
          referralLeadPrice: 25,
          referralSalePrice: 25,
          payReferralFeeAfterJobIsDone: false,
          isPayForEveryReferralEnabled: true,
        },
    validationSchema: addJobPromotionFormSchema,
    onSubmit: async data => {
      if (inEditMode) {
        await updateJobPromotionAsync({
          jobPromotionId: jobPromotion?.id,
          startDate: data.startDate.toISODate() ?? "",
          endDate: data.endDate.toISODate() ?? "",
          title: data.title,
          description: data.description,
          sector: data.sector,
          payReferralFeeAfterJobIsDone: data.payReferralFeeAfterJobIsDone,
          payReferralFeeSettings: data.payReferralFeeSettings,
          referralSalePrice: data.referralSalePrice,
          referralPercentageFee: data.referralPercentageFee,
          leadPrice: data.referralLeadPrice,
        });
        return;
      }
      await createJobPromotionAsync({
        startDate: data.startDate.toISODate() ?? "",
        endDate: data.endDate.toISODate() ?? "",
        title: data.title,
        description: data.description,
        sector: data.sector,
        payReferralFeeAfterJobIsDone: data.payReferralFeeAfterJobIsDone,
        ...(data.payReferralFeeAfterJobIsDone
          ? {
              payReferralFeeSettings: data.payReferralFeeSettings,
              referralSalePrice: data.referralSalePrice,
              referralPercentageFee: data.referralPercentageFee,
            }
          : {}),
        ...(data.isPayForEveryReferralEnabled
          ? { leadPrice: data.referralLeadPrice }
          : {}),
      });
      addJobPromotionFormMethods.resetForm();
    },
  });

  return (
    <Dialog
      fullWidth
      open={modalState.modalIsOpen}
      maxWidth="md"
      onClose={modalState.closeModal}
    >
      <DialogTitle component="div" sx={{ px: 2 }}>
        <Typography gutterBottom variant="h5">
          {inEditMode ? "Edit Promotion" : "Create New Promotion"}
        </Typography>
        <Typography gutterBottom variant="body2" color={"text.secondary"}>
          Your referral partners and their teams will refer this service to
          their own customers
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={modalState.closeModal}
      >
        <CloseIcon />
      </IconButton>
      <FormikProvider value={addJobPromotionFormMethods}>
        <DialogContent dividers>
          <Box component={"form"}>
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <DatePickerField
                  minDate={inEditMode ? undefined : DateTime.now()}
                  label={"Start Date"}
                  sx={{ flexGrow: 1 }}
                  name={"startDate"}
                />
                <DatePickerField
                  minDate={addJobPromotionFormMethods.values.startDate}
                  label={"End Date"}
                  sx={{ flexGrow: 1 }}
                  name={"endDate"}
                />
              </Stack>
              <TextInputField fullWidth required name="title" label="Title" />
              <BulletListInputField
                name="description"
                label="Description*"
                placeholder="Decscribe what is included here"
                maxRows={5}
              />
              <AutocompleteField
                disableClearable
                name={"sector"}
                options={jobSectorOptions ?? []}
                label="Select Job Service Category*"
              />
              <Box sx={{ paddingTop: 2 }}>
                <JobSalePriceSelectField
                  label={"Set Job Price"}
                  paymentModeFieldName="payReferralFeeSettings"
                  salesPriceFieldName="referralSalePrice"
                  percentageFeeFieldName="referralPercentageFee"
                  payReferralFeeAfterJobIsDoneFieldName="payReferralFeeAfterJobIsDone"
                  leadPriceFieldName="referralLeadPrice"
                  isPayForEveryReferralEnabledFieldName="isPayForEveryReferralEnabled"
                />
              </Box>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isCreatingJobPromotion}
            variant="contained"
            color="primary"
            disabled={
              !addJobPromotionFormMethods.isValid ||
              isCreatingJobPromotion ||
              isUpdatingJobPromotion
            }
            onClick={() => addJobPromotionFormMethods.handleSubmit()}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </FormikProvider>
    </Dialog>
  );
}
