import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { Home } from "../types";

export type UpdateHomeOptions = Omit<Home, "id">;

export function useUpdateHome(
  options: UseMutationOptions<
    Home,
    AxiosError,
    { homeId: number; updates: UpdateHomeOptions }
  > = {}
): UseMutationResult<
  Home,
  AxiosError,
  { homeId: number; updates: UpdateHomeOptions }
> {
  return useMutation({
    mutationFn: async ({ homeId, updates }): Promise<Home> => {
      const response = await API.patch(
        `/homeowner/homes/${homeId}`,
        changeCaseKeys(updates, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
