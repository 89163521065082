import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SyntheticEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { Seo } from "@/common/Seo/Seo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useGetReferralInvitations } from "@/company/api/useGetReferralInvitations";
import { useGetReferralPartners } from "@/company/api/useGetReferralPartners";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { JobSectors } from "@/company/jobs/constants";
import { CompanyRoutes, PLATFORM_PRESENCE } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { getInitialData, getSortedSectorsData } from "../manage-partners/utils";
import { ReferralProfileCard } from "./ReferralProfileCard";

export function ReferralPartnersPage() {
  const navigate = useNavigate();

  const { isFetching: isFetchingReferralPartners, data: referralPartners } =
    useGetReferralPartners({
      refetchOnWindowFocus: false,
    });

  const { session } = useAuth();
  const company = session?.company;
  const [filteredSectors, setFilteredSectors] = useState<Array<string>>([]);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const {
    data: relevanceOrderedJobSectorsList,
    isFetching: isFetchingRelevanceOrderedJobSectorsList,
  } = useGetRelevanceOrderedJobSectorsList(company?.id, {
    refetchOnWindowFocus: false,
  });

  const {
    isFetching: isFetchingReferralInvitations,
    data: referralInvitations,
  } = useGetReferralInvitations({
    refetchOnWindowFocus: false,
  });

  const combinedData = getInitialData();

  referralPartners?.forEach(partner => {
    if (Object.keys(combinedData).includes(partner.sector as JobSectors)) {
      combinedData[partner.sector].push(partner);
    }
  });

  const activeReferralInvitations = referralInvitations?.filter(
    invitation => !invitation.declinedAt
  );

  activeReferralInvitations?.forEach(invitation => {
    const partner = invitation.otherCompanyProfile;
    if (partner?.sectors?.length) {
      for (const sector of partner.sectors) {
        if (Object.keys(combinedData).includes(sector?.sector as JobSectors)) {
          combinedData[sector?.sector].push(invitation);
        }
      }
    }
  });

  const sortedInvitations = activeReferralInvitations?.sort((a, b) => {
    if (
      a.otherCompanyProfile?.platformPresence ===
        PLATFORM_PRESENCE.ON_PLATFORM &&
      b.otherCompanyProfile?.platformPresence !== PLATFORM_PRESENCE.ON_PLATFORM
    ) {
      return -1; // Sort a before b
    }
    if (
      a.otherCompanyProfile?.platformPresence !==
        PLATFORM_PRESENCE.ON_PLATFORM &&
      b.otherCompanyProfile?.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM
    ) {
      return 1; // Sort b before a
    }
    return 0; // Leave them as they are if both are the same
  });

  const sortedCombinedDataByRelevantJobSectors = getSortedSectorsData(
    combinedData,
    relevanceOrderedJobSectorsList ?? []
  );
  const memoizedReferralPartners = useMemo(() => {
    let uniqReferralPartners = referralPartners ?? [];

    const uniqReferralPartnerIds = new Set<number>();

    uniqReferralPartners =
      referralPartners?.filter(partner => {
        const id = partner.otherCompanyProfile.id;
        if (id) {
          if (uniqReferralPartnerIds.has(id)) return false;
          uniqReferralPartnerIds.add(id);
          return true;
        }
        return true;
      }) ?? [];
    if (filteredSectors?.length) {
      uniqReferralPartners = uniqReferralPartners.filter(partner =>
        partner.otherCompanyProfile.sectors.some(sector =>
          filteredSectors.includes(snakeCaseJobSectors[sector.sector])
        )
      );
    }
    return uniqReferralPartners;
  }, [referralPartners, filteredSectors]);

  return (
    <>
      <Seo title="My Team's Referral Partners" />
      <Container disableGutters maxWidth="100%">
        <Grid
          container
          flexDirection={"column"}
          sx={{
            ...(referralPartners?.length
              ? { top: "56px", zIndex: 400, pt: 1, position: "sticky" }
              : { position: "relative", pt: 1 }),
          }}
          bgcolor={"white"}
        >
          <Box maxWidth="100%" height={"fit-content "}>
            {isMobile ? null : (
              <Box display={"flex"} alignItems={"center"}>
                <Typography variant="h5">
                  My Team&apos;s Referral Partners
                </Typography>
              </Box>
            )}
            <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
              Recommend your team&apos;s partners to a homeowner.
            </Typography>
          </Box>
          {referralPartners?.length ||
          referralInvitations?.length ||
          isFetchingRelevanceOrderedJobSectorsList ||
          isFetchingReferralPartners ? (
            <Grid
              container
              pb={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} lg={12}>
                <Autocomplete
                  multiple
                  id="sectors"
                  sx={{ m: 0 }}
                  options={Object.keys(sortedCombinedDataByRelevantJobSectors)
                    .map(sector => {
                      if (sector === JobSectors.HVAC) {
                        return sector.toString();
                      }
                      return snakeCaseJobSectors[
                        sector.toString() as JobSectors
                      ];
                    })
                    .sort()}
                  getOptionLabel={option => option}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        style: { marginBottom: "8px" },
                      }}
                      label="Select Service Categories"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props} key={option} value={option}>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        {<Typography>{option}</Typography>}
                        {selected ? (
                          <CheckCircleOutlineIcon color="primary" />
                        ) : null}
                      </Box>
                    </MenuItem>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      // eslint-disable-next-line react/jsx-key
                      <Chip
                        size="small"
                        label={option}
                        {...getTagProps({ index })}
                        variant="outlined"
                      />
                    ))
                  }
                  onChange={(_: SyntheticEvent, newValue: string[] | null) =>
                    setFilteredSectors(newValue ?? [])
                  }
                />
              </Grid>
            </Grid>
          ) : (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              pt={"200px"}
              gap={3}
              flex={1}
            >
              <img
                src="/empty-icon.png"
                style={{ width: "132px", height: "128px" }}
                alt="no result"
              />
              <Box textAlign={"center"}>
                <Typography gutterBottom variant="h5">
                  No Referral Partners
                </Typography>
              </Box>
              <Button
                variant="contained"
                size="large"
                startIcon={<TravelExploreIcon />}
                onClick={() => {
                  navigate(
                    getNavigationPathFromRoutePaths([
                      CompanyRoutes.BASE,
                      CompanyRoutes.MY_JOBS,
                    ])
                  );
                }}
              >
                My Jobs
              </Button>
            </Box>
          )}
        </Grid>
        {isFetchingRelevanceOrderedJobSectorsList ||
        isFetchingReferralPartners ||
        isFetchingReferralInvitations ? (
          <Box py={5} textAlign={"center"}>
            <CircularProgress />
          </Box>
        ) : memoizedReferralPartners?.length ||
          activeReferralInvitations?.length ? (
          <Grid container spacing={2}>
            {" "}
            {memoizedReferralPartners?.map(referral => (
              <Grid key={referral.id} item xs={12} md={4}>
                <ReferralProfileCard
                  companyProfile={referral.otherCompanyProfile}
                />
              </Grid>
            ))}
            {sortedInvitations?.map(referralInvitation => (
              <Grid key={referralInvitation.id} item xs={12} md={4}>
                <ReferralProfileCard
                  companyProfile={referralInvitation.otherCompanyProfile}
                />
              </Grid>
            ))}
          </Grid>
        ) : null}
      </Container>
    </>
  );
}
