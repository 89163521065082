import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import * as React from "react";
import { SyntheticEvent, useEffect, useState } from "react";
import * as yup from "yup";
import zipcodes from "zipcodes";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { useUpdateServiceTerritory } from "@/company/api/useUpdateServiceTerritory";
import { JobSectors } from "@/company/jobs/constants";
import {
  useUpdateBrandLocationProfile,
  useUpdateCompany,
} from "@/company/onboarding/api";
import { snakeCaseJobSectors } from "@/company/utils";
import { getInitials } from "@/utils";

import { NotifyAction } from "../NotifyAction";
import { SettingsTabs } from "../screens/company-settings/CompanySettingsPage";

const validationSchema = yup
  .object({
    name: yup.string().required("Company Name is required"),
    yearEstablished: yup.date().nullable().optional(),
    numberEmployees: yup
      .number()
      .nullable()
      .min(0, "Can not be a negative number")
      .optional(),
    websiteUrl: yup
      .string()
      // .matches(URL_REGEX, "Website URL must be a valid URL")
      .optional(),
    schedulingUrl: yup
      .string()
      // .matches(URL_REGEX, "Website URL must be a valid URL")
      .optional(),
    // businessPhone: getUSPhoneValidationSchema().optional(),
    email: yup.string().email("Must be a valid email address").optional(),
    // businessEmail: yup
    //   .string()
    //   .email("Work Email Address must be a valid email address")
    //   .optional(),
    sectors: yup
      .array()
      .min(1, "Please select at least one Service Category")
      .required("Sectors are required")
      .of(yup.string()),
    zipCode: yup
      .string()
      .required("Zip Code is required")
      .oneOf(Object.keys(zipcodes.codes), "Invalid U.S. zip code")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
    description: yup
      .string()
      // .matches(URL_REGEX, "Google local services URL must be a valid URL")
      .optional(),
  })
  .optional();

interface CompanyProfileInformationProps {
  handleTabsChange?: (tab: SettingsTabs) => void;
}

export const CompanyProfileInformation: React.FC<
  CompanyProfileInformationProps
> = ({ handleTabsChange }) => {
  const { session, isInitialized, refreshSession } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;
  const familyOfBrands = session?.familyOfBrands;

  const companyAddress = session?.companyAddress;
  const companySectors = session?.companySectors;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [logoFile, setLogoFile] = useState<any>(undefined);
  const [currentLogoUrl, setCurrentLogoUrl] = useState<any>(undefined);

  const [imageDeleted, setImageDeleted] = useState(false);

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFile = event.target.files[0];
      const img = new Image();
      const objectUrl = window.URL.createObjectURL(newFile);
      img.onload = function () {
        window.URL.revokeObjectURL(objectUrl);
        if (this.height < 40 || this.width > 160) {
          enqueueSnackbar(
            <Typography variant="body2">
              For best results, ensure your logo is a minimum of 40px in height
              and 160px in width.
            </Typography>,
            {
              action: snackbarId => {
                return NotifyAction(snackbarId);
              },
              variant: "info",
            }
          );
        }
        setLogoFile(newFile);
        setImageDeleted(false);
      };
      img.src = objectUrl;
    } else {
      setLogoFile(undefined);
    }
  };

  const {
    isLoading: updatingServiceTerritory,
    mutateAsync: updateServiceTerritory,
  } = useUpdateServiceTerritory(company?.id, {
    onError() {
      enqueueSnackbar({
        message: "Error while updating company territory.",
        variant: "error",
      });
    },
  });

  const { isLoading: updatingCompany, mutateAsync: updateCompany } =
    useUpdateCompany(session?.company?.id, {
      onError() {
        enqueueSnackbar("Error while updating company details.", {
          variant: "error",
        });
      },
      async onSuccess() {
        setLogoFile(undefined);
        enqueueSnackbar({
          message: "Updated company details successfully.",
          variant: "success",
        });
        if (
          formik.values.zipCode !== companyAddress?.zipCode &&
          handleTabsChange
        ) {
          await updateServiceTerritory([]);
          handleTabsChange?.(SettingsTabs.SERVICE_TERRITORY);
          enqueueSnackbar({
            message:
              "Please reset your service territory so jobs can be routed to you.",
            variant: "info",
          });
        }
        refreshSession();
      },
    });

  const {
    isLoading: updatingBrandLocationProfile,
    mutateAsync: updateBrandLocationProfile,
  } = useUpdateBrandLocationProfile(session?.brandLocationProfile?.id, {
    onError() {
      enqueueSnackbar("Error while updating brand location details.", {
        variant: "error",
      });
    },
  });

  const minDate = DateTime.fromObject({ year: 1944, month: 1, day: 1 });

  const formik = useFormik({
    initialValues: {
      name: "",
      yearEstablished: null,
      numberEmployees: null,
      websiteUrl: "",
      googleLocalServicesUrl: "",
      // businessPhone: "",
      // businessEmail: "",
      zipCode: "",
      sectors: [],
      description: "",
      schedulingUrl: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({
      yearEstablished,
      numberEmployees,
      websiteUrl,
      googleLocalServicesUrl,
      // businessPhone,
      // businessEmail,
      zipCode,
      sectors,
      name,
      description,
      schedulingUrl,
    }) => {
      const companyPayload = {
        name,
        yearEstablished: yearEstablished ? yearEstablished?.year : "null",
        numberEmployees: numberEmployees ? numberEmployees : "null",
        websiteUrl,
        description,
        zipCode,
      };
      const brandLocationPayload = {
        // businessPhone: `+1${businessPhone.replace(/[\s()-]/g, "")}`,
        googleLocalServicesUrl,
        // businessEmail,
        schedulingUrl,
      };
      await updateBrandLocationProfile(brandLocationPayload);
      await updateCompany({
        companyPayload,
        companySectors: sectors,
        logoFile,
        isLogoDeleted: imageDeleted,
      });
    },
  });

  useEffect(() => {
    const zipCode = companyAddress?.zipCode;
    if (zipCode) {
      formik.setFieldValue("zipCode", zipCode);
    }
  }, [companyAddress]);

  useEffect(() => {
    if (company) {
      if (company?.yearEstablished) {
        formik.setFieldValue(
          "yearEstablished",
          DateTime.utc(company?.yearEstablished)
        );
      }
      if (company.logoUrl) {
        setCurrentLogoUrl(company.logoUrl);
      }
      if (company?.name) {
        formik.setFieldValue("name", company.name);
      }
      if (company?.numberEmployees)
        formik.setFieldValue("numberEmployees", company.numberEmployees);
      if (company?.websiteUrl)
        formik.setFieldValue("websiteUrl", company.websiteUrl);
      if (company?.description)
        formik.setFieldValue("description", company?.description);
    }
  }, [company]);

  useEffect(() => {
    if (brandLocationProfile) {
      if (brandLocationProfile?.googleLocalServicesUrl)
        formik.setFieldValue(
          "googleLocalServicesUrl",
          brandLocationProfile.googleLocalServicesUrl
        );
      if (brandLocationProfile.schedulingUrl) {
        formik.setFieldValue(
          "schedulingUrl",
          brandLocationProfile?.schedulingUrl
        );
      }
    }
  }, [brandLocationProfile]);

  useEffect(() => {
    if (companySectors?.length) {
      formik.setFieldValue(
        "sectors",
        companySectors.map(sector => snakeCaseJobSectors[sector.sector])
      );
    }
  }, [companySectors]);

  const [isImageLoadError, setIsImageLoadError] = useState(false);

  const handleDelete = () => {
    setLogoFile(undefined);
    setCurrentLogoUrl(undefined);
    setImageDeleted(true);
  };
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Grid container>
      {!isInitialized ? (
        <Box py={3} textAlign="center" width="100%">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container py={3}>
            {isMobile ? null : (
              <Grid item xs={12} lg={4} pr={4}>
                <Typography gutterBottom variant="h6">
                  Company Information
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    mb={2}
                    sx={{ mt: { xs: 2, lg: 0 } }}
                  >
                    {logoFile || (currentLogoUrl && !isImageLoadError) ? (
                      <img
                        src={
                          logoFile
                            ? window.URL.createObjectURL(logoFile)
                            : currentLogoUrl
                        }
                        alt={company?.name}
                        width="40px"
                        height="40px"
                        onError={error => {
                          console.error(error);
                          setIsImageLoadError(true);
                        }}
                      />
                    ) : (
                      <Avatar
                        variant="square"
                        sx={{ height: "40px", width: "40px" }}
                      >
                        {getInitials(company?.name ?? "")}
                      </Avatar>
                    )}
                    <Box ml={1}>
                      <Box display={"flex"}>
                        <Button
                          component="label"
                          sx={{ p: 0 }}
                          disabled={
                            !session?.groups?.some(isAdministratorOrOwner) ||
                            updatingCompany
                          }
                        >
                          Change Logo
                          <input
                            type="file"
                            style={{
                              clip: "rect(0 0 0 0)",
                              clipPath: "inset(50%)",
                              height: 1,
                              overflow: "hidden",
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              whiteSpace: "nowrap",
                              width: 1,
                            }}
                            accept="image/*"
                            onChange={onSelectFile}
                          />
                        </Button>
                        {!imageDeleted &&
                        (logoFile || company?.logoUrl) &&
                        session?.groups?.some(isAdministratorOrOwner) ? (
                          <IconButton
                            size="small"
                            aria-label="delete"
                            onClick={() => handleDelete()}
                          >
                            <DeleteForeverRoundedIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                      <Typography
                        variant="body2"
                        color={
                          !session?.groups?.some(isAdministratorOrOwner) ||
                          updatingCompany
                            ? "text.disabled"
                            : "text.secondary"
                        }
                      >
                        Logo should be at least 40px in height and have a
                        maximum width of 160px
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {familyOfBrands?.name ? (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="parentCompanyName"
                        name="parentCompanyName"
                        label="Parent Company Name"
                        disabled={true}
                        value={familyOfBrands?.name}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ py: "0 !important" }}>
                      <Typography variant="caption" color="text.secondary">
                        This is the family of brands that owns your franchisor.
                        Contact us to change this information.
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12} md={8}>
                  <TextField
                    required
                    disabled
                    aria-disabled
                    fullWidth
                    id="name"
                    name="name"
                    label="Company Name"
                    sx={{ my: 0 }}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    required
                    fullWidth
                    id="zipCode"
                    name="zipCode"
                    label="Zip Code"
                    disabled={
                      !session?.groups?.some(isAdministratorOrOwner) ||
                      updatingCompany
                    }
                    value={formik.values.zipCode}
                    error={
                      formik.touched.zipCode && Boolean(formik.errors.zipCode)
                    }
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                    type="number"
                    inputMode="numeric"
                    inputProps={{ pattern: "[0-9]*" }}
                    onChange={event => {
                      if (event.target.value?.length <= 5) {
                        formik.setFieldValue(
                          "zipCode",
                          event.target.value,
                          true
                        );
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sx={{ py: "0 !important" }}>
                  <Typography variant="caption" color="text.secondary">
                    Contact us to change your Company Name. Changing your Zip
                    Code will require you to reset your territory.
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: "0 !important" }}>
                  <Autocomplete
                    multiple
                    disabled={
                      !session?.groups?.some(isAdministratorOrOwner) ||
                      updatingCompany
                    }
                    options={Object.keys(JobSectors).map(sector => {
                      if (sector === JobSectors.HVAC) {
                        return sector.toString();
                      }
                      return snakeCaseJobSectors[
                        sector.toString() as JobSectors
                      ];
                    })}
                    value={formik.values.sectors}
                    getOptionLabel={option => option}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={
                          formik.touched.sectors &&
                          Boolean(formik.errors.sectors)
                        }
                        helperText={
                          formik.touched.sectors && formik.errors.sectors
                        }
                        value={formik.values.sectors ?? []}
                        margin="normal"
                        InputLabelProps={{
                          style: { marginBottom: "8px" },
                        }}
                        label="Select Service Categories"
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <MenuItem {...props} key={option} value={option}>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          width={"100%"}
                        >
                          {<Typography>{option}</Typography>}
                          {selected ? (
                            <CheckCircleOutlineIcon color="primary" />
                          ) : null}
                        </Box>
                      </MenuItem>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip
                          size="small"
                          label={option}
                          {...getTagProps({ index })}
                          variant="outlined"
                        />
                      ))
                    }
                    onChange={(_: SyntheticEvent, newValue: string[] | null) =>
                      formik.setFieldValue(
                        "sectors",
                        (newValue ?? []) as JobSectors[]
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Divider style={{ width: "100%" }} />
          <Grid container py={3}>
            <Grid item xs={12} lg={4} pr={4}>
              <Box display={"flex"} alignItems={"center"}>
                <Typography variant="h6" pt={"2px"}>
                  Where should we send job referrals?
                </Typography>
                <ToolTip message="We recommend providing a mobile number and work email address that are checked regularly. You don't want to miss out on new jobs.">
                  <HelpOutlineIcon
                    sx={{
                      ml: "2px",
                      color: "#6C737F",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </ToolTip>
              </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    id="businessPhone"
                    name="businessPhone"
                    label="Mobile Number"
                    disabled={
                      !session?.groups?.some(isAdministratorOrOwner) ||
                      updatingCompany
                    }
                    InputProps={{
                      inputComponent: MaskedPhoneInput as unknown as
                        | ElementType<InputBaseComponentProps>
                        | undefined,
                    }}
                    sx={{ my: 0 }}
                    value={formik.values.businessPhone}
                    error={
                      formik.touched.businessPhone &&
                      Boolean(formik.errors.businessPhone)
                    }
                    helperText={
                      formik.touched.businessPhone &&
                      formik.errors.businessPhone
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    id="businessEmail"
                    name="businessEmail"
                    label="Work Email Address"
                    disabled={
                      !session?.groups?.some(isAdministratorOrOwner) ||
                      updatingCompany
                    }
                    sx={{ my: 0 }}
                    value={formik.values.businessEmail}
                    error={
                      formik.touched.businessEmail &&
                      Boolean(formik.errors.businessEmail)
                    }
                    helperText={
                      formik.touched.businessEmail &&
                      formik.errors.businessEmail
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
          <Divider style={{ width: "100%" }} />
          <Grid container py={3}>
            <Grid item xs={12} lg={4} pr={4}>
              <Typography gutterBottom variant="h6">
                Additional Company Information
              </Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    id="description"
                    name="description"
                    label="Describe Your Company and Your Ideal Referrals"
                    rows={2}
                    maxRows={4}
                    value={formik.values.description}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                        ? formik.errors.description
                        : "This will be shown on your company profile for other service providers to get to know you."
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      disableFuture
                      sx={{ width: { xs: 1 } }}
                      disabled={
                        !session?.groups?.some(isAdministratorOrOwner) ||
                        updatingCompany
                      }
                      minDate={minDate}
                      maxDate={DateTime.now()}
                      label={"Year Established"}
                      views={["year"]}
                      value={formik.values.yearEstablished}
                      onChange={newValue =>
                        formik.setFieldValue("yearEstablished", newValue)
                      }
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="numberEmployees"
                    name="numberEmployees"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    label="Number of Employees"
                    sx={{ my: 0 }}
                    disabled={
                      !session?.groups?.some(isAdministratorOrOwner) ||
                      updatingCompany
                    }
                    value={formik.values.numberEmployees}
                    error={
                      formik.touched.numberEmployees &&
                      Boolean(formik.errors.numberEmployees)
                    }
                    helperText={
                      formik.touched.numberEmployees &&
                      formik.errors.numberEmployees
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="websiteUrl"
                    name="websiteUrl"
                    label="Website"
                    sx={{ my: 0 }}
                    disabled={
                      !session?.groups?.some(isAdministratorOrOwner) ||
                      updatingCompany
                    }
                    value={formik.values.websiteUrl}
                    error={
                      formik.touched.websiteUrl &&
                      Boolean(formik.errors.websiteUrl)
                    }
                    helperText={
                      formik.touched.websiteUrl && formik.errors.websiteUrl
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="schedulingUrl"
                    name="schedulingUrl"
                    label="Scheduling URL"
                    sx={{ my: 0 }}
                    disabled={
                      !session?.groups?.some(isAdministratorOrOwner) ||
                      updatingCompany
                    }
                    value={formik.values.schedulingUrl}
                    error={
                      formik.touched.schedulingUrl &&
                      Boolean(formik.errors.schedulingUrl)
                    }
                    helperText={
                      formik.touched.schedulingUrl &&
                      formik.errors.schedulingUrl
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <GoogleAutocomplete
                    fullWidth
                    selectedValue={formik.values.streetAddress ?? ""}
                    error={
                      formik.touched.streetAddress &&
                      Boolean(formik.errors.streetAddress)
                    }
                    helperText={
                      formik.touched.streetAddress &&
                      formik.errors.streetAddress
                    }
                    label="Business Address"
                    disabled={
                      !session?.groups?.some(isAdministratorOrOwner) ||
                      updatingCompany
                    }
                    onSelect={({ selection, zipCode }) => {
                      formik.setFieldValue("zipCode", zipCode);
                      formik.setFieldValue("streetAddress", selection);
                    }}
                  />
                </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    fullWidth
                    sx={{ my: 0 }}
                    id="city"
                    name="city"
                    label="City"
                    value={city}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    fullWidth
                    id="state"
                    name="state"
                    label="State"
                    value={state}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          {session?.groups?.some(isAdministratorOrOwner) ? (
            <Stack
              direction="row"
              gap={2}
              sx={{
                display: "flex",
                alignItems: { xs: "flex-center", lg: "flex-end" },
                justifyContent: "end",
                alignSelf: "stretch",
                width: "100%",
                mr: 3,
              }}
            >
              <LoadingButton
                loading={updatingCompany}
                variant="contained"
                color="primary"
                disabled={!formik.dirty && !Object.keys(formik.touched).length}
                onClick={() => formik.handleSubmit()}
              >
                Save
              </LoadingButton>
            </Stack>
          ) : null}
        </>
      )}
    </Grid>
  );
};
