import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useQueryExploreCompanies } from "@/company/api/useExploreConnections";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { CompanyDrawer } from "@/company/components/company-drawer/CompanyDrawer";
import { CompanyProfileCard } from "@/company/components/company-profile-card/CompanyProfileCard";
import { useReferralContext } from "@/company/context/referral-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import {
  CompanyPublicProfile,
  CompanyRoutes,
  ConnectionStatus,
} from "@/company/types";

export function ExploreConnections() {
  const MAX_RESULT_COUNT = 20;
  const { session } = useAuth();
  const navigate = useNavigate();

  const [currentCompanyProfile, setCurrentCompanyProfile] =
    useState<CompanyPublicProfile | null>(null);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const company = session?.company;
  const familyOfBrands = session?.familyOfBrands;
  const brandLocationProfile = session?.brandLocationProfile;

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id, {
      refetchOnWindowFocus: false,
      enabled: !!company,
      retry: false,
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];

  const { referralPartners, isFetchingReferralPartners } = useReferralContext();
  const { referralInvitations, isFetchingReferralInvitations } =
    useReferralInvitationContext();

  const { isFetching: isFetchningPotentialPartners, data: potentialPartners } =
    useQueryExploreCompanies(
      { familyOfBrandsNonAffiliatePartners: true },
      recommendedJobSectorsList?.length
        ? recommendedJobSectorsList
        : relevanceOrderedJobSectorsList,
      {
        refetchOnWindowFocus: false,
        enabled: !!(
          relevanceOrderedJobSectorsList || recommendedJobSectorsList
        ),
        retry: false,
      }
    );

  let recommendedCompanies =
    potentialPartners?.filter(
      company =>
        !referralInvitations?.some(
          invitation =>
            company?.companyPublicProfile?.id === invitation.invitedCompanyId ||
            company?.companyPublicProfile?.id === invitation.invitingCompanyId
        )
    ) ?? [];

  recommendedCompanies = recommendedCompanies?.filter(
    company =>
      !referralPartners?.some(
        partner =>
          partner.otherCompanyProfile.id === company?.companyPublicProfile?.id
      )
  );

  const getConnectionStatus = useCallback(
    (currentCompany: CompanyPublicProfile): ConnectionStatus => {
      if (
        referralPartners?.some(
          referral =>
            referral.referredCompanyId === currentCompany?.id ||
            referral.referringCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.CONNECTED;

      if (
        referralInvitations?.some(
          invitation => invitation.invitedCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.PENDING;

      if (
        referralInvitations?.some(
          invitation => invitation.invitingCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.INVITED;

      return ConnectionStatus.NOT_CONNECTED;
    },
    [referralInvitations, referralPartners]
  );

  if (!recommendedCompanies?.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "none !important",
          overflow: "hidden",
          alignItems: "center",
          alignSelf: "stretch",
          border: "1px #E8ECEF solid",
          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
          px: 0,
          py: 1,
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            pt: 3,
            pb: 2,
            px: 3,
            alignItems: "center",
            alignSelf: "stretch",
          }}
          subheader={
            <Typography color={"text.secondary"} variant="body2">
              These are key connections within your referral group that you
              haven&apos;t engaged with yet. Reach out, explore mutual contacts,
              and unlock opportunities to accelerate meaningful referral
              relationships.
            </Typography>
          }
          title={
            <Typography gutterBottom variant={"h6"}>
              Potential Introductions from Your Partners
            </Typography>
          }
        />
        {isFetchingReferralInvitations || isFetchingReferralPartners ? (
          <Box py={5}>
            <ComponentLoader />
          </Box>
        ) : (
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexGrow: 1,
              alignSelf: "stretch",
              background: "#F3F4F6",
              overflow: "auto",
              height: "400px",
              overflowY: "auto",
              px: isMobile ? 0 : 2,
              py: 1,
            }}
          >
            {recommendedCompanies && recommendedCompanies.length > 0 ? (
              recommendedCompanies.map((companyData, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  p={isMobile ? 1 : 2}
                >
                  <CompanyProfileCard
                    {...(companyData.companyPublicProfile
                      ? {
                          companyProfile: companyData.companyPublicProfile,
                          connectionStatus: getConnectionStatus(
                            companyData.companyPublicProfile
                          ),
                        }
                      : {})}
                    {...(companyData.googleCompanyProfile
                      ? {
                          googleCompanyProfile:
                            companyData.googleCompanyProfile,
                          hasMarkedInterest: !!companyData.hasMarkedInterest,
                        }
                      : {})}
                    showParentLogo={
                      !!companyData?.companyPublicProfile?.familyOfBrands
                        ?.name &&
                      familyOfBrands?.name ===
                        companyData?.companyPublicProfile?.familyOfBrands?.name
                    }
                    onOpen={() => {
                      if (companyData.companyPublicProfile) {
                        setCurrentCompanyProfile(
                          companyData.companyPublicProfile ?? null
                        );
                      }
                    }}
                  />
                </Grid>
              ))
            ) : (
              <Box
                textAlign="center"
                width="100%"
                py={3}
                display={"flex"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography variant="body1">
                  No key connections found.
                </Typography>
              </Box>
            )}
          </Grid>
        )}
        <Stack
          spacing={2}
          mt={1}
          mx={1}
          direction={"row"}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "start",
            alignSelf: "stretch",
          }}
        >
          <Button
            endIcon={<ArrowForwardIcon />}
            sx={{ color: "text.primary" }}
            onClick={() => {
              navigate(
                getNavigationPathFromRoutePaths([
                  CompanyRoutes.BASE,
                  CompanyRoutes.EXPLORE_PARTNERS,
                ])
              );
            }}
          >
            Explore Other Partners
          </Button>
        </Stack>
      </Card>
      {currentCompanyProfile ? (
        <CompanyDrawer
          open={!!currentCompanyProfile}
          companyProfile={currentCompanyProfile}
          connectionStatus={getConnectionStatus(currentCompanyProfile)}
          onClose={() => {
            setCurrentCompanyProfile(null);
          }}
        />
      ) : null}
    </Grid>
  );
}
