import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { Box, Chip, Paper, styled, Typography } from "@mui/material";

import { AppConstants } from "@/common/constants";
import { CallRecording } from "@/company/api/useFetchCallRecordings";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import { snakeCaseJobSectors } from "@/company/utils";

const ThinPaper = styled(Paper)(({ theme }) => ({
  width: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

const RecommendedPartner = ({
  selectedRecording,
  selectedJob,
}: {
  selectedRecording: CallRecording;
  selectedJob: Job;
}) => {
  const handlePayReferralClick = async () => {
    const url = `${AppConstants.platformBaseUrl}/company/job-calls-modal/${selectedRecording?.id}?chrome-extension=true`;
    window.open(url, "_blank");
  };
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Typography variant="overline" color={"text.secondary"}>
        {selectedRecording?.company?.id ===
        selectedRecording?.job?.createdForCompanyId
          ? "Referred By"
          : selectedRecording?.job?.createdForCompanyId
          ? "Referred To"
          : "Recommended Partner"}
      </Typography>
      <CompanyAvatar
        height={"40px"}
        maxWidth={"160px"}
        logo={selectedRecording?.potentialReferralPartner?.logoUrl}
        name={selectedRecording?.potentialReferralPartner?.name ?? ""}
      />
      <Box display={"flex"} alignItems="center" gap={1}>
        <Typography variant="body1" color="text.secondary">
          {selectedRecording?.potentialReferralPartner?.name}
        </Typography>
        <VerifiedOutlinedIcon
          sx={{
            verticalAlign: "center",
            color: "#16DB65",
            ml: 1,
          }}
        />
      </Box>
      <Box display={"flex"} gap={1} py={1} flexWrap={"wrap"}>
        <Chip
          key={selectedJob?.sector}
          sx={{ color: "text.secondary", bgcolor: "#F8F9FA" }}
          label={
            snakeCaseJobSectors[selectedJob?.sector as JobSectors] ??
            snakeCaseJobSectors[JobSectors.OTHER]
          }
          size="small"
        />
      </Box>
    </Box>
  );
};

export default RecommendedPartner;
