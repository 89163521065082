import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { JobSectors } from "../constants";
import { Job } from "../types";

const ASSOCIATE_COMPANY_URL = "/jobs/:id/assign-companies";

export enum JobAssociation {
  SELLING_COMPANY = "SELLING_COMPANY",
  ROUTED_COMPANY = "ROUTED_COMPANY",
}

export type AssociateCompanyRequest = {
  jobId: number;
  existingCompanyId?: number;
  jobAssociation: JobAssociation;
  googlePlacesId?: string;
  company?: { name: string; zipCode: string; sectors: Array<JobSectors> };
  user?: {
    firstName: string;
    lastName?: string;
    cellPhone: string;
    email: string;
  };
};

export type UpdateJobResponse = { job: Job };

export function useAssociateCompanyToJob(
  options: UseMutationOptions<void, AxiosError, AssociateCompanyRequest> = {}
): UseMutationResult<void, AxiosError, AssociateCompanyRequest> {
  return useMutation({
    mutationFn: async (associateCompanyDetails: AssociateCompanyRequest) => {
      const url = ASSOCIATE_COMPANY_URL.replace(
        ":id",
        associateCompanyDetails.jobId.toString()
      );
      const payload = changeCaseKeys(associateCompanyDetails, "underscored");

      return await API.post(url, payload);
    },
    ...options,
  });
}
