import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useDeleteCompany(
  options: UseMutationOptions<void, AxiosError, number> = {}
): UseMutationResult<void, AxiosError, number> {
  return useMutation({
    mutationFn: async (companyId): Promise<void> => {
      const response = await API.post(
        `/internal-admin/companies/brand-location/${companyId}/archive`
      );
      return;
    },
    ...options,
  });
}
