import { Avatar, Skeleton, Stack, Typography } from "@mui/material";

import { isDefined } from "@/utils/isDefined";

import { referralCampaignConfigurationSteps } from "../../constants";
import {
  ReferralCampaign,
  ReferralCampaignConfigurationStep,
} from "../../types";
import { getOrdinalSuffix } from "../../utils";

interface CampaignCompensationProps {
  referralCampaign: Omit<ReferralCampaign, "id" | "createdAt" | "updatedAt">;
  indexOfActiveStep?: number;
}

export function CampaignCompensation(props: CampaignCompensationProps) {
  const { referralCampaign, indexOfActiveStep } = props;

  const getPrizeIcon = (index: number) => {
    if (index === 1) {
      return "/crown.svg";
    }

    if (index === 2) {
      return "/2nd_place.svg";
    }

    if (index === 3) {
      return "/3rd_place.svg";
    }

    return "/4th_place.svg";
  };

  const campaignCompensationStepIndex =
    referralCampaignConfigurationSteps.indexOf(
      ReferralCampaignConfigurationStep.CAMPAIGN_REWARDS
    );

  if (
    isDefined(indexOfActiveStep) &&
    indexOfActiveStep < campaignCompensationStepIndex
  ) {
    return null;
  }

  return (
    <Stack gap={1}>
      <Typography variant="h6" fontWeight={700}>
        Compensation/Prize
      </Typography>
      {indexOfActiveStep === campaignCompensationStepIndex ? (
        <>
          {[1, 2, 3].map(index => (
            <Stack key={index} direction="row" gap={1} alignItems="center">
              <Skeleton variant="circular" width={48} height={48} />
              <Stack gap={0.5} flex={1}>
                <Skeleton variant="rounded" width="100%" height="15px" />
                <Skeleton variant="rounded" width="70%" height="15px" />
              </Stack>
            </Stack>
          ))}
        </>
      ) : (
        <>
          {referralCampaign.prizes?.map((prize, index) => (
            <Stack
              key={prize.prizeType}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" gap={1} alignItems="center">
                <Avatar
                  sx={{
                    borderRadius: 1,
                    backgroundColor: "#F8F9FA",
                    width: 42,
                    height: 42,
                  }}
                >
                  <img src={getPrizeIcon(index + 1)} alt="crown" width={36} />
                </Avatar>
                <Typography variant="subtitle2" fontWeight={500}>
                  {index + 1}
                  {getOrdinalSuffix(index + 1)} place
                </Typography>
              </Stack>
              <Typography
                variant="subtitle2"
                fontWeight={700}
                color="primary"
                maxWidth="50%"
                sx={{ wordWrap: "break-word", textAlign: "right" }}
              >
                {prize.prizeName}
              </Typography>
            </Stack>
          ))}
        </>
      )}
    </Stack>
  );
}
