import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { COMPANY_ACCOUNT_TYPES, CompanyRoutes } from "@/company/types";
import { useModalState } from "@/lib/modals/useModalState";
import { isDefined } from "@/utils/isDefined";

import { useGetJobPromotions } from "../api/useGetJobPromotions";
import { JobPromotionWithCompany } from "../types";
import { JobPromotionCard } from "./JobPromotionCard";
import { JobPromotionDetailsDrawer } from "./JobPromotionDetailsDrawer";

interface CompaniesJobPromotionsListProps {
  companyIds: number[];
}

export function CompaniesJobPromotionsList(
  props: CompaniesJobPromotionsListProps
) {
  const { companyIds } = props;
  const { session } = useAuth();
  const jobPromotionDetailsModalState = useModalState();
  const navigate = useNavigate();
  const [selectedJobPromotion, setSelectedJobPromotion] = useState<
    JobPromotionWithCompany | undefined
  >();

  const {
    data: jobPromotionsData,
    isLoading: isLoadingJobPromotions,
    refetch: refetchJobPromotions,
  } = useGetJobPromotions({
    filter: {
      companyIds,
    },
  });

  if (isLoadingJobPromotions) {
    return <ComponentLoader />;
  }

  if (!jobPromotionsData?.promotions.length) {
    return (
      <Box
        sx={{
          height: "100%",
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        <Stack gap={2} alignItems="center">
          <Box
            component="img"
            src="/empty-icon.png"
            style={{ width: "132px", height: "128px" }}
            alt="no job promotions"
          />
          <Typography variant="h6" color="text.secondary">
            No job promotions found
          </Typography>
          {session?.company?.accountType ===
          COMPANY_ACCOUNT_TYPES.BRAND_LOCATION ? (
            <Button
              variant="contained"
              sx={{ width: "fit-content" }}
              size="medium"
              onClick={() => {
                navigate(
                  `${getNavigationPathFromRoutePaths([
                    CompanyRoutes.BASE,
                    CompanyRoutes.JOB_PROMOTIONS,
                  ])}?openJobPromotion=true`
                );
              }}
            >
              Be the first to add a promotion!
            </Button>
          ) : null}
        </Stack>
      </Box>
    );
  }

  return (
    <Grid
      container
      gap={2}
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 360px)",
        justifyContent: "space-around",
      }}
    >
      {jobPromotionsData.promotions.map(jobPromotion => (
        <Grid key={jobPromotion.id} item>
          <JobPromotionCard
            jobPromotion={jobPromotion}
            onClick={() => {
              setSelectedJobPromotion(jobPromotion);
              jobPromotionDetailsModalState.openModal();
            }}
            onUpdate={() => {
              refetchJobPromotions();
            }}
          />
        </Grid>
      ))}
      {isDefined(selectedJobPromotion) && (
        <JobPromotionDetailsDrawer
          modalState={jobPromotionDetailsModalState}
          jobPromotion={selectedJobPromotion}
        />
      )}
    </Grid>
  );
}
