import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

// eslint-disable-next-line import/no-unresolved
import API from "@/common/api";

import { GoogleCompanyProfile } from "../types";

export function useFetchGoogleCompanyProfile(
  googlePlacesId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<GoogleCompanyProfile> {
  const uri = `/companies/google-profile/${googlePlacesId}`;

  return useQuery({
    queryKey: [uri],
    queryFn: async () => {
      const response = await API.get(uri);
      return response.data.data;
    },
    ...options,
  });
}
