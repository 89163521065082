import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";
import { Job } from "@/company/jobs/types";

export interface HomeownerAction {
  action: "APPROVE" | "DECLINE" | "DELAY";
  delayReason?: string;
  declineReason?: string;
  comment?: string;
  allowAutoRouting?: boolean;
  summary?: string;
  jobType?: string;
}

export function useHomeownerActOnJob(
  jobId: number | null,
  options: UseMutationOptions<Job, AxiosError, HomeownerAction> = {}
): UseMutationResult<Job, AxiosError, HomeownerAction> {
  return useMutation({
    mutationFn: async (updates: HomeownerAction): Promise<Job> => {
      const url = `/jobs/${jobId}/homeowner-action`;

      const response = await API.patch(
        url,
        changeCaseKeys(updates, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
