import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  Card,
  CardHeader,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import {
  CompanyPublicProfile,
  CompanyRoutes,
  ConnectionStatus,
  ReferralInvitation,
} from "@/company/types";

import {
  ReferralInvitationDrawer,
  TabsEnum,
} from "../../company-drawer/ReferralInvitationDrawer";
import { CompanyInvitationCard } from "../../company-profile-card/CompanyInvitationCard";

export function PendingInvitationsComponent({
  pendingInvitations,
}: {
  pendingInvitations: Array<ReferralInvitation>;
}) {
  const navigate = useNavigate();
  const [currentInvitation, setCurrentInvitation] =
    useState<ReferralInvitation | null>(null);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [selectedTab, setSelectedTab] = useState<TabsEnum | null>(null);

  const getConnectionStatus = useCallback(
    (currentCompany: CompanyPublicProfile): ConnectionStatus => {
      if (
        pendingInvitations?.some(
          invitation => invitation.invitedCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.PENDING;

      if (
        pendingInvitations?.some(
          invitation => invitation.invitingCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.INVITED;

      return ConnectionStatus.NOT_CONNECTED;
    },
    [pendingInvitations]
  );

  return (
    <Grid item xs={12}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "none !important",
          overflow: "hidden",
          alignItems: "center",
          alignSelf: "stretch",
          border: "1px #E8ECEF solid",
          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
          px: 0,
          py: 1,
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            pt: 3,
            pb: 2,
            px: 3,
            alignItems: "center",
            alignSelf: "stretch",
          }}
          subheader={
            <Typography color={"text.secondary"} variant="body2">
              Review new referral partner invitations. Be a good partner and
              message your connections back.
            </Typography>
          }
          title={
            <Typography gutterBottom variant={"h6"}>
              Pending Invitations ({pendingInvitations.length})
            </Typography>
          }
        />
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexGrow: 1,
            alignSelf: "stretch",
            background: "#F3F4F6",
            overflow: "auto",
            px: isMobile ? 0 : 2,
            py: 1,
          }}
        >
          {pendingInvitations.map((invitation, index) => (
            <Grid key={index} item xs={12} sm={6} lg={6} p={isMobile ? 1 : 2}>
              <CompanyInvitationCard
                referralInvitation={invitation}
                onOpen={(tab?: TabsEnum) => {
                  if (tab) {
                    setSelectedTab(tab);
                    setCurrentInvitation(invitation ?? null);
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Stack
          spacing={2}
          mt={1}
          mx={1}
          direction={"row"}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "start",
            alignSelf: "stretch",
          }}
        >
          <Button
            endIcon={<ArrowForwardIcon />}
            sx={{ color: "text.primary" }}
            onClick={() => {
              navigate(
                getNavigationPathFromRoutePaths([
                  CompanyRoutes.BASE,
                  CompanyRoutes.EXPLORE_PARTNERS,
                ])
              );
            }}
          >
            See All
          </Button>
        </Stack>
      </Card>
      {currentInvitation ? (
        <ReferralInvitationDrawer
          open={!!currentInvitation}
          defaultTab={selectedTab ?? undefined}
          invitation={currentInvitation}
          connectionStatus={getConnectionStatus(
            currentInvitation.otherCompanyProfile
          )}
          onClose={() => {
            setCurrentInvitation(null);
          }}
        />
      ) : null}
    </Grid>
  );
}
