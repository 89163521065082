import { DefaultCompanyGroups, PLATFORM_PRESENCE } from "@/company/types";
import { UserOnboardingStatus } from "@/user/types";

export interface StaffData {
  firstName: string;
  lastName: string;
  cellPhone: string;
  email: string;
  role?: DefaultCompanyGroups;
  id: number;
  platformPresence?: PLATFORM_PRESENCE;
  lastLoginAt?: string;
  onboardingStatus?: UserOnboardingStatus;
}

export enum STAFF_TYPES {
  OPERATIONS = "Operations",
  FIELD_TEAM = "Field Team",
}
