import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { isDefined } from "@/utils/isDefined";

import { ReferralCampaign } from "../types";

export function useGetReferralCampaign(
  params: {
    referralCampaignId: number;
  },
  options: UseQueryOptions<ReferralCampaign> = {}
): UseQueryResult<ReferralCampaign> {
  const { referralCampaignId } = params;
  const storageKey = "referral-campaigns";

  return useQuery({
    queryKey: [storageKey, referralCampaignId],
    queryFn: async (): Promise<ReferralCampaign> => {
      let parsedReferralCampaigns: ReferralCampaign[] = [];
      const referralCampaigns = localStorage.getItem(storageKey);
      if (isDefined(referralCampaigns)) {
        parsedReferralCampaigns = JSON.parse(referralCampaigns);
      }

      const referralCampaign = parsedReferralCampaigns.find(
        referralCampaign => referralCampaign.id === referralCampaignId
      );
      if (!isDefined(referralCampaign)) {
        throw new Error("Referral campaign not found");
      }
      return referralCampaign;
    },
    ...options,
  });
}
