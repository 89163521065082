import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import { SearchedCompany } from "@/company/api/useExploreConnections";
import { CompanyPublicProfile, ConnectionStatus } from "@/company/types";

import { TabsEnum } from "../../company-drawer/CompanyDrawer";
import { CompanyProfileCard } from "../../company-profile-card/CompanyProfileCard";

const ExplorePartnersList = React.memo(
  ({
    otherRecommendedCompanies,
    setSelectedTab,
    getConnectionStatus,
    setSelectedCompany,
    setCurrentCompanyProfile,
  }: {
    otherRecommendedCompanies: Array<SearchedCompany>;
    setSelectedTab: (tab: TabsEnum) => void;
    getConnectionStatus: (
      currentCompany: CompanyPublicProfile
    ) => ConnectionStatus;
    setSelectedCompany: (company: SearchedCompany) => void;
    setCurrentCompanyProfile: (companyProfile: CompanyPublicProfile) => void;
  }) => {
    return (
      <>
        {otherRecommendedCompanies && otherRecommendedCompanies.length > 0 ? (
          otherRecommendedCompanies.map((companyData, index) => (
            <Grid key={index} item xs={12} sm={6} lg={4} py={2} pr={2}>
              <CompanyProfileCard
                {...(companyData.companyPublicProfile
                  ? {
                      companyProfile: companyData.companyPublicProfile,
                      connectionStatus: getConnectionStatus(
                        companyData.companyPublicProfile
                      ),
                    }
                  : {})}
                {...(companyData.googleCompanyProfile
                  ? {
                      googleCompanyProfile: companyData.googleCompanyProfile,
                      hasMarkedInterest: !!companyData.hasMarkedInterest,
                    }
                  : {})}
                onOpen={({ tab }) => {
                  if (tab && companyData.companyPublicProfile) {
                    setSelectedTab(tab);
                    setCurrentCompanyProfile(
                      companyData.companyPublicProfile ?? null
                    );
                  } else {
                    setSelectedCompany(companyData ?? null);
                  }
                }}
              />
            </Grid>
          ))
        ) : (
          <Box textAlign="center" width="100%" py={3}>
            <Typography variant="body1">
              There are no companies matching your filters, please click on the
              filters button above and update them.
            </Typography>
          </Box>
        )}
      </>
    );
  }
);

ExplorePartnersList.displayName = "ExplorePartnersList";

export default ExplorePartnersList;
