import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { CallRecording } from "./useFetchCallRecordings";

export const GET_CALL_RECORDING_BY_ID_URI = "/call-recordings/:recordingId";

export function useFetchCallRecordingById(
  recordingId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<CallRecording> {
  return useQuery({
    queryKey: [GET_CALL_RECORDING_BY_ID_URI, recordingId],
    queryFn: async () => {
      const response = await API.get(
        `${GET_CALL_RECORDING_BY_ID_URI.replace(
          ":recordingId",
          recordingId?.toString()
        )}`
      );
      return response.data.data;
    },
    ...options,
  });
}
