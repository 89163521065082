/* eslint-disable jsx-a11y/media-has-caption */
import {
  DeleteForeverOutlined,
  MicNoneOutlined,
  PlayArrow,
  StopCircleOutlined,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Drawer,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { IMediaRecorder, MediaRecorder } from "extendable-media-recorder";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";

const AudioRecorder = ({
  setAudioURL,
}: {
  setAudioURL: (value: File | null) => void;
}) => {
  const mediaRecorderRef = useRef<IMediaRecorder | null>(null);
  const audioChunks = useRef([]);
  const streamRef = useRef<MediaStream | null>(null); // To store the media stream
  const [timer, setTimer] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Timer reference
  const [isRecording, setIsRecording] = useState(false);
  const isDeletedRef = useRef(false); // Use ref for isDeleted

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleButtonClick = event => {
    event.stopPropagation();
    setCountdown(3);
    setStartBackdrop(true);
  };

  useEffect(() => {
    if (isRecording) {
      intervalRef.current = setInterval(() => setTimer(prev => prev + 1), 1000);
    } else {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
  }, [isRecording]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      const mimeType = "audio/mp4";
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });

      mediaRecorderRef.current.ondataavailable = event => {
        try {
          audioChunks.current.push(event?.data);
        } catch (error) {
          Sentry.captureException(error);
          console.error("Error recording audio: ", error);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        try {
          if (isDeletedRef.current) {
            setAudioURL(null);
            isDeletedRef.current = null;
          } else {
            const audioBlob = new Blob(audioChunks.current, { type: mimeType });
            setAudioURL(audioBlob);
          }
          audioChunks.current = []; // Clear the chunks after recording

          // Stop all tracks in the stream to release the microphone
          if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
          }
        } catch (error) {
          console.log({ error });
          if (
            error?.name === "NotAllowedError" ||
            error?.name === "PermissionDeniedError"
          ) {
            // Show snackbar if permission is denied
            enqueueSnackbar(
              "Microphone access was denied. Please enable microphone permissions in your settings.",
              {
                variant: "error",
              }
            );
          }
          Sentry.captureException(error);
          console.error("Error recording audio: ", error);
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      if (
        error?.name === "NotAllowedError" ||
        error?.name === "PermissionDeniedError"
      ) {
        if (isMobile) localStorage.setItem("reOpenModal", "true");

        // Show snackbar if permission is denied
        enqueueSnackbar(
          "Microphone access was denied. Please enable microphone permissions in your settings.",
          {
            variant: "error",
            autoHideDuration: 20000,
            includeContactSupportText: false,
          }
        );
        window.location.reload();
      }
      Sentry.captureException(error);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef?.current?.stop();
    setTimer(0); // Reset the timer
    setIsRecording(false);
  };

  const handleDeleteRecording = () => {
    isDeletedRef.current = true;
    stopRecording();
  };

  const [startBackdrop, setStartBackdrop] = useState(false);
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    if (startBackdrop && countdown > 0) {
      const timer = setInterval(() => {
        setTimeout(() => {
          setCountdown(prev => prev - 1);
        }, 150);
      }, 500);

      return () => clearInterval(timer);
    }
    if (countdown === 0) {
      if (startBackdrop) {
        setStartBackdrop(false);
        startRecording();
      }
    }
  }, [countdown, startBackdrop]);

  return (
    <Box>
      <Backdrop
        sx={theme => ({ color: "#fff", zIndex: 2000 })}
        open={startBackdrop}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
            zIndex: 2000,
          }}
        >
          <Box
            sx={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              backgroundColor: "#071D2F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1,
              zIndex: 2000,
              boxShadow: `
              0 2px 4px rgba(0, 0, 0, 0.05),
              0 4px 8px rgba(0, 0, 0, 0.08),
              0 6px 12px rgba(0, 0, 0, 0.1)
            `,
            }}
          >
            <Typography variant="h1" color="white">
              {countdown}
            </Typography>
            <Typography
              variant="h6"
              fontSize={"15px"}
              fontWeight={600}
              width={"120px"}
              sx={{ cursor: "pointer", color: "#16DB65", textAlign: "center" }}
            >
              Describe what you see
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: "#071D2F",
              borderColor: "#071D2F",
              p: 2,
              borderRadius: "8px",
              textAlign: "center",
              boxShadow: `
                0 2px 4px rgba(0, 0, 0, 0.05),
                0 4px 8px rgba(0, 0, 0, 0.08),
                0 6px 12px rgba(0, 0, 0, 0.1)
              `,
            }}
          >
            <Typography gutterBottom variant="h6" sx={{ color: "#16DB65" }}>
              Ready to upload a job?
            </Typography>
            <Typography variant="h6" sx={{ color: "#16DB65" }}>
              Speak clearly for best results!
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      {isRecording ? null : (
        <Box display={"flex"} flexDirection={"column"} textAlign={"start"}>
          <Button
            sx={{
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
              borderRadius: "8px",
              width: isMobile ? "100%" : "fit-content",
            }}
            size="large"
            variant="contained"
            startIcon={<MicNoneOutlined />}
            onClick={async event => {
              event.preventDefault();
              event.stopPropagation();
              if (isMobile) {
                handleButtonClick(event);
                return;
              }
              startRecording();
              return;
            }}
          >
            Start Recording
          </Button>
          <Typography
            variant="body2"
            sx={{
              color: "gray",
              mt: 1,
              fontSize: "12px",
              textAlign: "start",
              maxWidth: "400px",
            }}
          >
            Note: Recording will not work if microphone permissions are not
            granted. Please enable them in your{" "}
            {isMobile ? "device" : "browser"} settings.
          </Typography>
        </Box>
      )}
      <Drawer anchor="bottom" open={!!isRecording} sx={{ zIndex: 20000 }}>
        <Box
          sx={{
            padding: 2,
            pb: 4,
            display: "flex",
            gap: 2,
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box py={2}>
            <Typography variant="h6">Now Recording</Typography>
          </Box>
          <Box>
            <img
              src="/audio-recording.gif"
              alt="recording"
              style={{
                width: "160px",
                height: "160px",
              }}
            />
          </Box>
          <Box py={2}>
            <Typography variant="h4" sx={{ color: "#111927" }}>
              {`${Math.floor(timer / 60)}:${(timer % 60)
                .toString()
                .padStart(2, "0")}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              mt: 2,
              width: "220px",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              color="primary"
              sx={{
                borderRadius: "12px",
                bgcolor: "#16B364",
                color: "white",
                p: 2,
                "&:hover": {
                  boxShadow: "none", // Remove any box-shadow change
                  borderRadius: "12px",
                  bgcolor: "#16B364",
                  color: "white",
                },
              }}
              onClick={() => (isRecording ? stopRecording() : startRecording())}
            >
              {isRecording ? <StopCircleOutlined /> : <PlayArrow />}
            </IconButton>
            <IconButton
              color="primary"
              sx={{
                borderRadius: "12px",
                bgcolor: "#FEF3F2",
                p: 2,
                color: "#F04438",

                "&:hover": {
                  boxShadow: "none", // Remove any box-shadow change
                  borderRadius: "12px",
                  bgcolor: "#FEF3F2",
                  color: "#F04438",
                },
              }}
              onClick={handleDeleteRecording}
            >
              <DeleteForeverOutlined />
            </IconButton>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default AudioRecorder;
