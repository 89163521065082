/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { LoadingButton } from "@mui/lab";
import { InputBaseComponentProps, Theme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { ElementType, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { AuthRoutes } from "@/auth/constants";
import { useAuth } from "@/auth/context/jwt";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { Seo } from "@/common/Seo/Seo";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import {
  appendSearchParamsToRedirectTo,
  getUSPhoneValidationSchema,
} from "@/utils";

const validationSchema = Yup.object({
  phone: getUSPhoneValidationSchema().optional(),
  email: Yup.string()
    .email("Work Email Address must be a valid email address")
    .optional(),
});

export function LoginPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const phone = searchParams.get("phone");
  const redirectTo = searchParams.get("redirectTo");
  const inputRef = useRef(null);
  const email = searchParams.get("email");

  const [loading, setLoading] = useState(false);
  const [inputOption, setInputOption] = useState<"email" | "phone">("phone");

  const { enqueueSnackbar } = useSnackbar();

  const { requestLoginOtp, isAuthenticated, isInitialized, session } =
    useAuth();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    if (isAuthenticated && session?.company && isInitialized) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated, isInitialized]);

  const requestOtp = useCallback(
    async ({ cellPhone, email }: { cellPhone?: string; email?: string }) => {
      try {
        setLoading(true);
        await requestLoginOtp({
          ...(cellPhone
            ? { cellPhone: cellPhone?.replace(/[\s()-]/g, "") }
            : {}),
          ...(email ? { email } : {}),
          redirectTo:
            `${redirectTo}${
              appendSearchParamsToRedirectTo()
                ? appendSearchParamsToRedirectTo()
                : ""
            }` ?? undefined,
        });
        navigate(`/${AuthRoutes.BASE}/${AuthRoutes.VERIFY_OTP}`);
      } catch (error: unknown) {
        if ((error as { notFound?: boolean; message?: string })?.notFound) {
          enqueueSnackbar({
            message: error?.message,
            variant: "error",
            subText: (
              <>
                Please{" "}
                <a
                  role="button"
                  style={{
                    textDecorationLine: "underline",
                    color: "#7A271A",
                  }}
                  onClick={event => {
                    event.preventDefault();
                    navigate(
                      `/${AuthRoutes.BASE}/${AuthRoutes.SIGN_UP}${
                        redirectTo ? `?redirectTo=${redirectTo}` : ""
                      }`
                    );
                  }}
                >
                  sign up
                </a>{" "}
                or{" "}
                <a
                  role="button"
                  style={{
                    textDecorationLine: "underline",
                    color: "#7A271A",
                  }}
                  onClick={event => {
                    event.preventDefault();
                    setContactSupportModalOpen(true);
                  }}
                >
                  contact support
                </a>
                .
              </>
            ),
          });
        } else {
          const message = doesErrorHaveMessage(error)
            ? error.message
            : "There was an error while requesting your OTP.";
          enqueueSnackbar({
            message,
            variant: "error",
          });
        }
      }
      setLoading(false);
    },
    [requestLoginOtp, navigate, redirectTo, enqueueSnackbar]
  );

  const appendSearchParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.toString() ? `?${searchParams.toString()}` : "";
  };

  const formik = useFormik({
    initialValues: {
      phone: phone ?? "",
      submit: null,
      email: "",
    },
    validationSchema,
    onSubmit: async ({ phone, email }): Promise<void> =>
      requestOtp({ cellPhone: phone, email }), // We add the us country code as default
  });

  useEffect(() => {
    if (phone) {
      setInputOption("phone");
      formik.setFieldValue("phone", phone);
      formik.setFieldTouched("phone", true);
      return;
    }
    if (email) {
      setInputOption("email");
      formik.setFieldValue("email", email);
      formik.setFieldTouched("email", true);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, phone]);

  return (
    <>
      <Seo title="Login" />
      <Box
        sx={{
          py: 4,
          px: { xs: 2, md: "300px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "16px",
            width: "100%",
            maxWidth: "540px",
            px: { xs: 2, md: "0" },
          }}
        >
          <Box
            display={"flex"}
            pb={2}
            py={4}
            width="100%"
            justifyContent={isMobile ? "center" : "start"}
          >
            <TradeEngageLogo logotype={TradeEngageLogoTypes.LOGO_DARK} />
          </Box>
          <Typography variant="h5">Welcome Back</Typography>
          <Typography variant="body2" sx={{ color: "#6C737F" }}>
            Log in by entering your{" "}
            {`${inputOption === "phone" ? "mobile number" : "email address"}`}.
          </Typography>
          <Box
            component={"form"}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {inputOption === "email" ? (
              <TextField
                fullWidth
                required
                id="email"
                name="email"
                label="Email Address"
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onBlur={formik.handleBlur}
                onChange={e => {
                  if (e.target.value?.length > 0) {
                    formik.setFieldValue("phone", "");
                  }
                  formik.handleChange(e);
                }}
              />
            ) : (
              <TextField
                fullWidth
                required
                inputRef={inputRef}
                error={!!(formik.touched.phone && formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                label="Mobile Number"
                name="phone"
                InputProps={{
                  inputComponent: MaskedPhoneInput as unknown as
                    | ElementType<InputBaseComponentProps>
                    | undefined,
                }}
                value={formik.values.phone}
                onBlur={formik.handleBlur}
                onChange={e => {
                  if (e.target.value?.length > 0) {
                    formik.setFieldValue("email", "");
                  }
                  formik.handleChange(e);
                }}
              />
            )}
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              sx={{ mt: 2 }}
            >
              {inputOption === "email" ? (
                <Typography variant="body2" sx={{ color: "#6C737F" }}>
                  Can’t access your inbox?{" "}
                  <Typography
                    display={"inline"}
                    variant="body2"
                    color="#16B364"
                    sx={{ cursor: "pointer" }}
                    fontWeight={500}
                    onClick={() => {
                      formik.setFieldValue("email", "");
                      setInputOption("phone");
                    }}
                  >
                    Log in with mobile number
                  </Typography>
                </Typography>
              ) : (
                <Typography variant="body2" sx={{ color: "#6C737F" }}>
                  Can’t access your mobile number?{" "}
                  <Typography
                    display={"inline"}
                    variant="body2"
                    color="#16B364"
                    sx={{ cursor: "pointer" }}
                    fontWeight={500}
                    onClick={() => {
                      formik.setFieldValue("phone", "");
                      setInputOption("email");
                    }}
                  >
                    Log in with email
                  </Typography>
                </Typography>
              )}
            </Box>
            <LoadingButton
              fullWidth
              loading={loading}
              disabled={
                !formik.isValid ||
                (!formik.dirty && !Object.keys(formik.touched).length) ||
                (!formik.values.phone && !formik.values.email)
              }
              sx={{ mt: 2 }}
              size="large"
              type="submit"
              variant="contained"
              onClick={() => formik.handleSubmit()}
            >
              Log In
            </LoadingButton>
            <Typography py={4} variant="body2" color="text.secondary">
              {"Don't have an account? "}
              <Link
                sx={{ cursor: "pointer" }}
                rel="noreferrer"
                underline="none"
                onClick={() => {
                  navigate(
                    `/${AuthRoutes.BASE}/${
                      AuthRoutes.SIGN_UP
                    }${appendSearchParams()}`
                  );
                }}
              >
                <Typography
                  py={4}
                  display="inline"
                  variant="subtitle2"
                  color="#16B364"
                >
                  Sign Up
                </Typography>
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
