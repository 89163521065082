import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { TokenDetails, USER_TYPE } from "@/auth/types";
import API from "@/common/api";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";
import { keysToSnakeCase } from "@/utils";

export interface UpdateUserTypeRequest {
  userType: USER_TYPE;
  companyAccountType?: COMPANY_ACCOUNT_TYPES;
}

export const UPDATE_USER_TYPE_URI = "/auth/user-type";

export function useUpdateUserType(
  options: UseMutationOptions<
    TokenDetails,
    AxiosError,
    UpdateUserTypeRequest
  > = {}
): UseMutationResult<TokenDetails, AxiosError, UpdateUserTypeRequest> {
  return useMutation({
    mutationFn: async (userTypeInfo: UpdateUserTypeRequest) => {
      const userPayload = keysToSnakeCase(userTypeInfo);
      const response = await API.post(UPDATE_USER_TYPE_URI, userPayload);
      return response.data.data;
    },
    ...options,
  });
}
