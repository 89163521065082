import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, IconButton, Tab, Tabs, Typography } from "@mui/material";
import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { JobChatProvider } from "@/company/context/job-chat-context";

import { useUpdateJobView } from "../api/useUpdateJobView";
import { Job, JobStatus, RoutedJobStatus, Source } from "../types/job";
import { getLastActivityJob } from "../utils";
import { HomeownerTab } from "./HomeownerTab";
import { JobMessage } from "./JobMessageTab";
import { JobProjectTab } from "./JobProjectTab";

interface JobDrawerProps {
  onClose?: () => void;
  refreshJobs?: () => void;
  inactiveReason?: string;
  open?: boolean;
  source?: Source;
  jobStatus?: JobStatus | RoutedJobStatus;
  lastActivity?: string;
  jobId: number;
  job: Job;
  isRoutedJob?: boolean;
  actionArea?: ReactNode;
  defaultTab?: JobDrawerTabsEnum;
  companyId?: number;
}

export enum JobDrawerTabsEnum {
  PROJECT = "project",
  HOMEOWNER = "homeowner",
  MESSAGE = "message",
  REFERRAL_PARTNER = "referral-partner",
}

export const JobDrawerComponent: FC<JobDrawerProps> = props => {
  const {
    onClose,
    open = false,
    jobId,
    job,
    jobStatus,
    lastActivity,
    actionArea,
    defaultTab,
    inactiveReason,
    refreshJobs,
    isRoutedJob,
    source,
    companyId,
    ...other
  } = props;
  const [currentTab, setCurrentTab] = useState<JobDrawerTabsEnum>(
    defaultTab ?? JobDrawerTabsEnum.PROJECT
  );

  const { session } = useAuth();

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: JobDrawerTabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  const { mutateAsync: updateJobView } = useUpdateJobView({
    onError: error => {
      console.error("Error while adding job view by routed partner", { error });
    },
  });

  useEffect(() => {
    if (job && !job.listingExtraInfo?.routedPartnerViewedAt && isRoutedJob) {
      updateJobView({ jobId });
    }
  }, [job, jobId, jobStatus]);

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
          pb={2}
          sx={{ px: { xs: 1, sm: 3 }, pt: { xs: 1, sm: 3 } }}
        >
          <Typography variant="h6">{job?.jobType}</Typography>
          <IconButton
            aria-label="close"
            sx={{
              color: theme => theme.palette.grey[500],
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ width: "100%" }} />
        {source === Source.TECHNICIAN_DASHBOARD ? null : (
          <Tabs sx={{ px: 3 }} value={currentTab} onChange={handleTabsChange}>
            <Tab value={JobDrawerTabsEnum.PROJECT} label="Project Details" />
            {jobStatus === RoutedJobStatus.PENDING_REVIEW &&
            job?.createdByUserType !== USER_TYPE.HOMEOWNER ? (
              <Tab
                value={JobDrawerTabsEnum.REFERRAL_PARTNER}
                label="Referral Partner"
              />
            ) : null}
            <Tab value={JobDrawerTabsEnum.HOMEOWNER} label="Homeowner" />
            {job?.homeId ? (
              <Tab value={JobDrawerTabsEnum.MESSAGE} label="Message" />
            ) : null}
          </Tabs>
        )}
        <Divider />
      </Box>
      {source !== Source.TECHNICIAN_DASHBOARD ? (
        <Box
          py={2}
          sx={{ px: { xs: 1, sm: 3 } }}
          height={"100%"}
          overflow={"auto"}
        >
          {currentTab === JobDrawerTabsEnum.PROJECT && job ? (
            <JobProjectTab
              job={job}
              isRoutedJob={isRoutedJob}
              jobStatus={jobStatus}
              lastActivity={lastActivity}
              inactiveReason={inactiveReason}
              source={source}
              changeTab={(tab: JobDrawerTabsEnum) => {
                setCurrentTab(tab);
              }}
            />
          ) : null}
          {currentTab === JobDrawerTabsEnum.HOMEOWNER && job ? (
            <HomeownerTab
              jobId={job?.id}
              jobStatus={job.status}
              companyId={companyId}
            />
          ) : null}
          {currentTab === JobDrawerTabsEnum.MESSAGE && job?.homeId ? (
            <JobChatProvider jobId={job?.id}>
              <JobMessage disableChat={true} />
            </JobChatProvider>
          ) : null}
        </Box>
      ) : (
        <Box
          py={2}
          sx={{ px: { xs: 1, sm: 3 } }}
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
        >
          <JobProjectTab
            job={job}
            changeTab={(tab: JobDrawerTabsEnum) => {
              setCurrentTab(tab);
            }}
            isRoutedJob={isRoutedJob}
            inactiveReason={inactiveReason}
            refreshJobs={refreshJobs}
            jobStatus={jobStatus}
            lastActivity={job ? getLastActivityJob(job) : lastActivity}
            source={source}
            onClose={onClose}
          />
        </Box>
      )}
    </Box>
  );
};
