import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

const DeleteConfirmationRecommendedPartnersDialog = ({
  open,
  handleClose,
  handleConfirm,
  companyName,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Archive</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Archive Recommended Partner? Are you sure you want to archive{" "}
          {companyName}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleConfirm}>
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationRecommendedPartnersDialog;
