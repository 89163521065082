import constate from "constate";
import { useSnackbar } from "notistack";

import { useDeleteReferral } from "../api/useDeleteReferral";
import { useGetReferralPartners } from "../api/useGetReferralPartners";
import { useUpdateReferralOrder } from "../api/useUpdateReferralOrder";

function useReferrals() {
  const {
    isFetching: isFetchingReferralPartners,
    data: referralPartners,
    refetch: refetchReferralPartners,
  } = useGetReferralPartners({
    refetchOnWindowFocus: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: deleteReferralRequest } = useDeleteReferral();

  const { mutateAsync: updateReferralOrderRequest } = useUpdateReferralOrder({
    onSuccess: () => {
      enqueueSnackbar({
        message: "Reordered referral partners successfully.",
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar("There was an error while updating referral order.", {
        variant: "error",
      });
    },
  });

  const deleteReferral = async (referralId: number, reason?: string) => {
    await deleteReferralRequest({ referralId, reason });
    await refetchReferralPartners();
  };

  const updateReferralOrder = async ({
    referralId,
    newOrderId,
  }: {
    referralId: number;
    newOrderId: number;
  }) => {
    await updateReferralOrderRequest({
      referralId,
      newOrderId: newOrderId,
    });
    await refetchReferralPartners();
  };

  return {
    isFetchingReferralPartners,
    referralPartners,
    refetchReferralPartners,
    deleteReferral,
    updateReferralOrder,
  };
}

export const [ReferralProvider, useReferralContext] = constate(useReferrals);
