import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { DateTime } from "luxon";

import API from "@/common/api";

import { ChatThread } from "./useFetchAdminChatsByBrandLocation";

export const USE_GET_ALL_CHATS__GROUPED_URI =
  "/internal-admin/companies/brand-location/{company_id}/chats";

export interface ChatParticipant {
  type: ChatTypeParticipant;
  id: number;
  name: string;
  logoUrl?: string;
}

export interface ChatMessage {
  fromCompanyId?: number;
  toCompanyId?: number;
  toUserId?: number;
  fromUserId?: number;
  jobId?: number;
  message: string;
  createdAt: DateTime;
  readAt?: DateTime;
  id: number;
}

export enum ChatTypeParticipant {
  TEAM_MEMBER = "TEAM_MEMBER",
  COMPANY = "COMPANY",
  HOMEOWNER = "HOMEOWNER",
}
export enum ChatType {
  COMPANY_TO_COMPANY = "COMPANY_TO_COMPANY",
  JOB = "JOB",
  COMPANY_TO_USER = "COMPANY_TO_USER",
}

export function useUpdateChatAdmin(
  options: UseMutationOptions<
    ChatThread,
    AxiosError,
    { message: string; chatId: number }
  > = {}
): UseMutationResult<
  ChatThread,
  AxiosError,
  { message: string; chatId: number }
> {
  return useMutation({
    mutationFn: async ({ message, chatId }): Promise<ChatThread> => {
      const response = await API.post(
        `/internal-admin/companies/brand-location/chat-company-to-user/${chatId}/messages`,
        { message }
      );
      return response.data.data;
    },
    ...options,
  });
}
