import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { JobTransaction, JobTransactionType } from "../types";

interface CreateJobTransactionRequest {
  jobId: number;
  transactionType: JobTransactionType;
  amount: number;
}

export function useCreateJobTransaction(
  options: UseMutationOptions<
    JobTransaction,
    AxiosError,
    CreateJobTransactionRequest
  > = {}
): UseMutationResult<JobTransaction, AxiosError, CreateJobTransactionRequest> {
  return useMutation({
    mutationFn: async ({ jobId, ...data }): Promise<JobTransaction> => {
      const response = await API.post(
        `/jobs/${jobId}/transactions`,
        changeCaseKeys(data, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
