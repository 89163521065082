import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { CallRecordingNotification } from "./useFetchCallNotificationsUnread";

export const GET_LATEST_CALL_RECORDING_URI =
  "/call-center-notifications/latest-notification";

export function useFetchLatestCallRecording(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<CallRecordingNotification> {
  return useQuery({
    queryKey: [GET_LATEST_CALL_RECORDING_URI],
    queryFn: async () => {
      const response = await API.get(`${GET_LATEST_CALL_RECORDING_URI}`);
      return response.data.data;
    },
    ...options,
  });
}
