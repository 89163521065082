import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmationDialog = ({
  open,
  handleClose,
  handleConfirm,
  text,
  title,
  confirmText,
}: {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  text: string;
  title: string;
  confirmText: string;
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h6"> {title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color={"text.secondary"}>
          {text}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 2, pb: 2 }}>
        <Button onClick={handleClose}>Close</Button>
        <Button variant="contained" onClick={handleConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
