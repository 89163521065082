/* eslint-disable jsx-a11y/media-has-caption */
import { Close } from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import {
  CallRecordingNotification,
  GET_CALL_RECORDING_NOTIFICATONS,
} from "@/company/api/useFetchCallNotificationsUnread";
import { useFetchCallRecordingById } from "@/company/api/useFetchCallRecordingById";
import {
  CallRecording,
  CallRecordingStatus,
  CallType,
  GET_CALL_RECORDINGS,
} from "@/company/api/useFetchCallRecordings";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { useUpdateCallRecordingStatus } from "@/company/api/useUpdateCallRecording";
import { useAssociateCompanyToJob } from "@/company/jobs/api/useAssociateCompanyToJob";
import { useSubmitJob } from "@/company/jobs/api/useSubmitJob";
import { useSwitchJobById } from "@/company/jobs/api/useSwitchJob";
import { useUpdateJobSector } from "@/company/jobs/api/useUpdateJobSector";
import { useUpdateNoReferral } from "@/company/jobs/api/useUpdateNoReferral";
import { JobCompletionPaymentDialogByJob } from "@/company/jobs/components/JobCompletionPaymentDialogByJob";
import { JobDrawer } from "@/company/jobs/components/JobDrawer";
import { JobSectors } from "@/company/jobs/constants";
import { Job, JobStatus } from "@/company/jobs/types";
import { Source } from "@/company/jobs/types/job";
import { getLastActivityJob } from "@/company/jobs/utils";
import {
  CompanyPublicProfile,
  ConnectionStatus,
  ExternalCompanyProfile,
  PayReferralFeeSettings,
} from "@/company/types";
import { formatUSPhoneNumber } from "@/utils";

import { CompanyDrawer } from "../../company-drawer/CompanyDrawer";
import { AddCompanyInfo } from "./AddCompanyInfo";
import AddJobDetailsCard from "./AddJobDetailsCard";
import { AddJobDetailsDialog } from "./AddJobDetailsDialog";
import CustomerRepresentative from "./components/CustomerRepresentative";
import FindPartner from "./components/FindPartner";
import HomeownerDetails from "./components/HomeownerDetails";
import JobDetails from "./components/JobDetails";
import RecommendedPartner from "./components/RecommendedPartner";
import EditHomeownerDetailsCard from "./EditHomeownerDetailsCard";
import { ExplorePartnersCard } from "./ExplorePartnersCard";
import { TabsEnum } from "./JobCallsPage";
import { ModifyJobHomeownerDetailsDialog } from "./ModifyHomeownerDetailsDialog";

enum JobAssociation {
  SELLING_COMPANY = "SELLING_COMPANY",
  ROUTED_COMPANY = "ROUTED_COMPANY",
}

const ReferralNotification = ({
  notification,
  selectedRecordingId,
  recording,
  refetchCalls,
  findPartner,
  setAddJobOpen,
  addJobOpen,
  setFindPartner,
  onClose,
  hideCloseIcon,
  setTab,
}: {
  notification: CallRecordingNotification;
  onClose: () => void;
  findPartner?: boolean;
  setTab: (tab: TabsEnum) => void;
  setFindPartner?: () => void;
  setAddJobOpen?: (openAddJob: boolean) => void;
  addJobOpen?: boolean;
  hideCloseIcon?: boolean;
  recording?: CallRecording;
  refetchCalls: (recording: CallRecording) => void;
  isMobile?: boolean;
  selectedRecordingId?: number;
}) => {
  const [jobReferred, setJobReferred] = useState(false);
  const [payDialogOpen, setPayDialogOpen] = useState(false);
  const { session } = useAuth();

  const queryClient = useQueryClient();
  const [partnerCompanyProfile, setPartnerCompanyProfile] = useState<
    CompanyPublicProfile | ExternalCompanyProfile | undefined
  >();

  const [modifyJobHomeownerDialogOpen, setModifyJobHomeownerDialogOpen] =
    useState(false);
  const [addReferralPartnerDialogOpen, setAddReferralPartnerDialogOpen] =
    useState(false);
  const [newHomeownerAdded, setNewJobHomeownerAdded] = useState(false);
  const [addCompanyInfoDialogOpen, setAddCompanyInfoOpen] = useState(false);

  const messagesRef = useRef();

  const [startTimer, setStartTimer] = useState(false);

  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >();

  const navigate = useNavigate();

  const { isLoading, data, refetch } = useFetchCallRecordingById(
    selectedRecordingId,
    {
      enabled: !!selectedRecordingId,
    }
  );

  const selectedRecording = (recording ?? data) as CallRecording;
  const refetchCall = recording
    ? refetchCalls
    : (_recording: CallRecording) => refetch();

  const hasModalOpened = useRef(false);
  const [jobDrawerOpen, setJobDrawerOpen] = useState(false);

  const [jobDetailsDialogOpen, addJobDetailsDialogOpen] = useState(false);
  const [jobPaid, setJobPaid] = useState(false);

  useEffect(() => {
    if (findPartner && !hasModalOpened.current) {
      setAddReferralPartnerDialogOpen(true);
      setFindPartner?.();
    }
  }, [findPartner, setFindPartner]);

  useEffect(() => {
    if (selectedRecording?.job?.status === JobStatus.ROUTED) {
      setJobReferred(true);
    } else {
      setJobReferred(false);
    }
  }, [selectedRecording?.job?.status]);

  const { mutateAsync: updateCallRecordingStatus } =
    useUpdateCallRecordingStatus({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [GET_CALL_RECORDINGS],
        });
        queryClient.invalidateQueries({
          queryKey: [GET_CALL_RECORDING_NOTIFICATONS],
        });
      },
    });

  useEffect(() => {
    if (notification?.status === CallRecordingStatus.NEW) {
      updateCallRecordingStatus({
        notificationId: notification?.id,
        status: CallRecordingStatus.NOTIFIED,
      });
    }
  }, [notification?.id]);

  const { isLoading: updatingJobDetails, mutateAsync: updateJobDetails } =
    useUpdateJobSector({
      onSuccess: (job: Job) => {
        refetchCall({ ...selectedRecording, job });
        refetchCalls({ ...selectedRecording, job });
        addJobDetailsDialogOpen(false);
        setAddJobOpen?.(false);
        if (!job.homeId) {
          setModifyJobHomeownerDialogOpen(true);
        }
        enqueueSnackbar({
          message: "Job updated successfully.",
          variant: "success",
        });
      },
      onError: () => {
        addJobDetailsDialogOpen(false);
        setAddJobOpen?.(false);
        enqueueSnackbar({
          message: `There was an error while updating job details.`,
          variant: "error",
        });
      },
    });

  const { isLoading: switchingJob, mutateAsync: switchCompanies } =
    useSwitchJobById({
      onSuccess: (job: Job) => {
        refetchCall({ ...selectedRecording, job });
        refetchCalls({ ...selectedRecording, job });
        enqueueSnackbar({
          message: "Referral updated successfully.",
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while updating referral.`,
          variant: "error",
        });
      },
    });

  const { isLoading: associatingCompany, mutateAsync: associateCompany } =
    useAssociateCompanyToJob({
      onSuccess: () => {
        enqueueSnackbar({
          message: "Partner confirmed successfully.",
          variant: "success",
        });
        submitJob({
          jobId: selectedRecording?.job?.id ?? 0,
          autoApproveJob:
            selectedRecording?.job?.createdForCompanyId ===
            selectedRecording?.company?.id,
        });
        return;
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while associating the partner to job.`,
          variant: "error",
        });
      },
    });

  const { isLoading: markingNoReferral, mutateAsync: markNoReferral } =
    useUpdateNoReferral({
      onSuccess: (recording: CallRecording) => {
        refetchCalls(recording);
        enqueueSnackbar({
          message: "Referral Updated successfully.",
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while updating referral.`,
          variant: "error",
        });
      },
    });

  const { isLoading: submittingJob, mutateAsync: submitJob } = useSubmitJob({
    onSuccess: async (job: Job) => {
      await refetch();
      refetchCalls({ ...selectedRecording, job });
      setTab(TabsEnum.ALL_HISTORY);
      setStartTimer(true);
      enqueueSnackbar({
        message: "Job submitted successfully.",
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while submitting the job.`,
        variant: "error",
      });
    },
  });

  useEffect(() => {
    let timer;
    if (startTimer) {
      timer = setTimeout(() => {
        setStartTimer(false);

        if (messagesRef.current) {
          messagesRef.current.scrollTop = messagesRef?.current?.scrollHeight;
        }
      }, 2400);
    }

    // Clean up the timer
    return () => clearTimeout(timer);
  }, [startTimer, messagesRef.current]);

  const getJobAssociationOfReferralPartnerCompany = (): JobAssociation => {
    const association =
      selectedRecording?.job?.createdByCompanyId ===
      selectedRecording?.company?.id
        ? JobAssociation.ROUTED_COMPANY
        : JobAssociation.SELLING_COMPANY;
    return association;
  };

  const getJobAssociationOfLoggedInCompany = (): JobAssociation => {
    const association =
      selectedRecording?.job?.createdByCompanyId ===
      selectedRecording?.company?.id
        ? JobAssociation.SELLING_COMPANY
        : JobAssociation.ROUTED_COMPANY;
    return association;
  };

  const confirmPartner = async () => {
    associateCompany({
      jobId: selectedRecording?.job?.id,
      existingCompanyId: selectedRecording?.potentialReferralPartner?.id,
      jobAssociation: getJobAssociationOfReferralPartnerCompany(),
    });
  };

  const referJob = async () => {
    submitJob({
      jobId: selectedRecording?.job?.id ?? 0,
    });
  };

  const approveJob = async () => {
    submitJob({
      jobId: selectedRecording?.job?.id ?? 0,
      autoApproveJob: true,
    });
  };

  const isReferringJob = associatingCompany || submittingJob;

  const handlePartnerClick = async () => {
    setAddReferralPartnerDialogOpen(true);
  };

  const handleJobClick = async () => {
    setJobDrawerOpen(true);
  };

  const handleAddJobClick = async () => {
    addJobDetailsDialogOpen(true);
  };

  const handlePayReferralFee = () => {
    setJobPaid(true);
  };

  const { data: companyProfile } = useFetchCompanyProfile(selectedCompanyId, {
    enabled: !!selectedCompanyId,
  });

  const handleAddHomeClick = async () => {
    setModifyJobHomeownerDialogOpen(true);
  };

  const subtitleText = () => {
    if (!selectedRecording?.job?.homeId) {
      if (
        selectedRecording?.job?.createdByCompanyId ===
        selectedRecording?.company?.id
      )
        return "Add Homeowner Information to refer this job to your partner";

      return "Add Homeowner Information to buy this job!";
    }
    if (
      selectedRecording?.job?.createdByCompanyId ===
      selectedRecording?.company?.id
    ) {
      if (selectedRecording?.job?.buyingCompanyId) {
        return "You just referred a job to your partner!";
      }
      if (
        selectedRecording?.job?.createdForCompanyId ||
        selectedRecording?.potentialReferralPartner
      ) {
        return "You can refer this job to your partner.";
      }
      if (selectedRecording?.type === CallType.INBOUND) {
        return "Find a partner to refer this job to so you can earn referral commission!";
      }
      return "You just referred a job to your partner! Link Partner to earn referral commission!";
    }
    if (selectedRecording?.job?.createdByCompanyId) {
      return "It sounds like you received a referral from someone! Add or confirm your referral partner and thank them with a referral fee.";
    }
    return "It sounds like you received a referral from someone! Add or confirm your referral partner and thank them with a referral fee.";
  };
  const action = () => {
    if (showEditHomeownerSection) {
      return "Add Homeowner Info";
    }
    if (showAddJob) {
      return "Add Job Info";
    }
    if (
      selectedRecording?.job?.createdByCompanyId ===
      selectedRecording?.company?.id
    ) {
      if (selectedRecording?.job?.buyingCompanyId) {
        return "Review";
      }
      if (
        selectedRecording?.job?.createdForCompanyId ||
        selectedRecording?.potentialReferralPartner
      ) {
        return "Refer Job";
      }
      if (selectedRecording?.type === CallType.INBOUND) {
        return "Find a Partner";
      }
      return "Link Partner";
    }
    if (selectedRecording?.job?.createdByCompanyId) {
      return "Approve Job from this Partner";
    }
    return "Add Partner & Approve Job";
  };

  useEffect(() => {
    if (selectedRecording?.job?.status !== JobStatus.DRAFT) {
      setJobReferred(true);
    } else {
      setJobReferred(false);
    }
  }, [selectedRecording?.job?.status]);

  useEffect(() => {
    if (selectedRecording?.job?.homeId && !newHomeownerAdded) {
      setNewJobHomeownerAdded(true);
    }
  }, [selectedRecording?.job?.homeId, newHomeownerAdded]);

  useEffect(() => {
    if (addJobOpen) {
      addJobDetailsDialogOpen(true);
    }
  }, [addJobOpen]);

  const mediaUrl =
    selectedRecording?.job?.videoUrl ?? selectedRecording?.audioPublicUrl;
  const showJobDetails =
    selectedRecording?.job?.jobType &&
    selectedRecording?.job?.sector &&
    selectedRecording?.job?.summary;

  const showAddJob =
    selectedRecording?.job?.status === JobStatus.DRAFT &&
    (!selectedRecording?.job?.jobType ||
      !selectedRecording?.job?.sector ||
      !selectedRecording?.job?.summary);

  const showEditHomeownerSection =
    !newHomeownerAdded &&
    selectedRecording?.job &&
    selectedRecording?.job.status === JobStatus.DRAFT;

  const showFindPartner =
    newHomeownerAdded &&
    selectedRecording?.job &&
    selectedRecording?.job.status === JobStatus.DRAFT &&
    !selectedRecording?.job.createdByCompanyId;

  const showPotentialPartner =
    selectedRecording?.potentialReferralPartner && selectedRecording?.job;

  const getActiveStep = () => {
    if (
      selectedRecording?.job?.createdForCompanyId ===
      selectedRecording?.company?.id
    ) {
      return selectedRecording?.job?.status ===
        JobStatus.PENDING_REFERRAL_PAYMENT
        ? 3
        : selectedRecording?.job?.status === JobStatus.PAID
        ? 4
        : selectedRecording?.job?.status === JobStatus.PENDING_HOMEOWNER_ACTION
        ? 2
        : jobReferred
        ? 1
        : 0;
    }
    return selectedRecording?.job?.status === JobStatus.PENDING_REFERRAL_PAYMENT
      ? 5
      : selectedRecording?.job?.status === JobStatus.PAID
      ? 6
      : jobReferred
      ? 4
      : 0;
  };

  const activeStep = getActiveStep();

  const outboundSteps = selectedRecording
    ? jobReferred
      ? [
          {
            label: `Outbound Referral<b> Identified & Consent Received from Customer`,
            description: DateTime.fromFormat(
              selectedRecording?.createdAt
                .toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Existing Referral Partner Confirmed in Territory & Service",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Message Sent to Customer with Details",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Message Sent to Referral Partner to Connect with Customer",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Awaiting Approval & Communication from Referral Partner",
            description: ``,
          },
          {
            label: "Commission earned, favor is due!",
            description: ``,
          },
        ]
      : selectedRecording?.job?.buyingCompanyId
      ? [
          {
            label:
              "Outbound Referral<b> Identified & Consent Received from Customer",
            description: DateTime.fromFormat(
              selectedRecording.job?.createdAt
                .toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Existing Referral Partner Confirmed in Territory & Service",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Message Sent to Customer with Details",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Message Sent to Referral Partner to Connect with Customer",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Awaiting Approval & Communication from Referral Partner",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Commission earned, favor is due!",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
        ]
      : []
    : [];

  const inboundSteps = selectedRecording
    ? jobReferred
      ? [
          {
            label: `Inbound Referral<b> Identified`,
            description: DateTime.fromFormat(
              selectedRecording.job?.createdAt
                .toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Referral Approved & Partner Linked",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Referral Partner Notified of Job Status",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Job Completed",
            description: ``,
          },
        ]
      : selectedRecording?.job?.buyingCompanyId
      ? [
          {
            label: "Inbound Referral<b> Identified",
            description: DateTime.fromFormat(
              selectedRecording?.createdAt
                .toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Referral Approved & Partner Linked",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Referral Partner Notified of Job Status",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
          {
            label: "Job Completed",
            description: DateTime.fromFormat(
              selectedRecording.job?.updatedAt
                ?.toString()
                .split(".")[0]
                .split("T")
                .join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )
              .toLocal()
              .toFormat("MM/dd/yy h:mm a"),
          },
        ]
      : []
    : [];

  const createdAt = DateTime.fromFormat(
    (selectedRecording?.createdAt ?? DateTime.now)
      ?.toString()
      .split(".")[0]
      .split("T")
      .join(" "),
    "yyyy-MM-dd HH:mm:ss",
    { zone: "utc" }
  );

  const todayStart = DateTime.local().startOf("day");

  const formattedDate =
    createdAt.toLocal() < todayStart
      ? createdAt.toLocal().toFormat("MM/dd/yy")
      : createdAt.toLocal().toFormat("h:mm a");

  const steps =
    selectedRecording?.job?.createdByCompanyId ===
    selectedRecording?.company?.id
      ? outboundSteps
      : inboundSteps;

  return (
    <>
      <Box
        ref={messagesRef}
        display={"flex"}
        flexDirection={"column"}
        px={2}
        id="custom-scrollbar"
        sx={{
          width: "100%",
          maxHeight: "80vh",
          overflow: "auto",
          position: "relative",
        }}
      >
        {isLoading ? (
          <Box my={5} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box display={"flex"} flexDirection={"column"} gap={1} pt={2}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                gap={1}
              >
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  {selectedRecording?.type === CallType.INBOUND ? (
                    <SouthEastOutlinedIcon
                      fontSize="large"
                      color="info"
                      sx={{
                        bgcolor: theme => theme.palette.info.light,
                        color: theme => theme.palette.info.main,
                        borderRadius: 30,
                        p: 1,
                        mr: 2,
                      }}
                    />
                  ) : (
                    <NorthEastOutlinedIcon
                      fontSize="large"
                      sx={{
                        bgcolor: theme => theme.palette.primary.light,
                        color: theme => theme.palette.primary.main,
                        borderRadius: 30,
                        p: 1,
                        mr: 2,
                      }}
                    />
                  )}
                  <Typography
                    variant="overline"
                    sx={{
                      color:
                        selectedRecording?.type === CallType.INBOUND
                          ? "#2970FF"
                          : "#16B364",
                    }}
                  >
                    {selectedRecording?.type === CallType.INBOUND
                      ? `Inbound Call from ${formatUSPhoneNumber(
                          selectedRecording?.fromPhone ?? ""
                        )}`
                      : `Outbound Call to ${formatUSPhoneNumber(
                          selectedRecording?.toPhone ?? ""
                        )}`}
                  </Typography>
                </Box>
                {hideCloseIcon ? null : (
                  <IconButton onClick={() => onClose()}>
                    <Close />
                  </IconButton>
                )}
              </Box>
              {selectedRecording?.job?.status === JobStatus.DRAFT ? (
                <>
                  {" "}
                  <Typography variant="h6">
                    New{" "}
                    {selectedRecording?.job?.createdByCompanyId ===
                    selectedRecording?.company?.id
                      ? "Outbound"
                      : "Inbound"}{" "}
                    Referral Identified!
                  </Typography>{" "}
                  <Typography variant="body1" color={"text.secondary"}>
                    {subtitleText()}
                  </Typography>
                </>
              ) : (
                <Typography variant="h6">
                  {selectedRecording?.job?.createdByCompanyId ===
                  selectedRecording?.company?.id
                    ? "Outbound"
                    : "Inbound"}{" "}
                  Referral
                </Typography>
              )}
            </Box>
            <Divider sx={{ borderColor: "#DCDFE4", my: 1 }} />
            {showEditHomeownerSection ? <EditHomeownerDetailsCard /> : null}
            {showAddJob && !showEditHomeownerSection ? (
              <AddJobDetailsCard />
            ) : null}
            {showPotentialPartner &&
            !associatingCompany &&
            !showEditHomeownerSection ? (
              <>
                <RecommendedPartner
                  selectedRecording={selectedRecording}
                  selectedJob={selectedRecording?.job}
                />
                <Divider sx={{ borderColor: "#DCDFE4", mt: 1 }} />
              </>
            ) : null}
            {selectedRecording &&
            (!selectedRecording?.job?.createdByCompanyId ||
              !selectedRecording?.job?.createdForCompanyId) &&
            !selectedRecording?.potentialReferralPartner &&
            !showEditHomeownerSection &&
            !showAddJob ? (
              submittingJob || associatingCompany ? (
                <Box my={5} textAlign="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <FindPartner selectedRecording={selectedRecording} />
                  <Divider sx={{ borderColor: "#DCDFE4", mt: 1 }} />
                </>
              )
            ) : null}
            <Box
              sx={{
                position: "sticky",
                top: 0,
                display: "flex",
                width: "100%",
                bgcolor: "white",
                py: 2,
              }}
            >
              {startTimer ? (
                <Box
                  px={0}
                  zIndex={2000000003}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <img src={"/confetti.gif"} alt="lottie" />
                </Box>
              ) : null}
              <Box
                display={"flex"}
                gap={1}
                width={"100%"}
                flexDirection={"column"}
              >
                <Button
                  disabled={isReferringJob}
                  variant={jobReferred ? "outlined" : "contained"}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    if (showEditHomeownerSection) {
                      handleAddHomeClick();
                      return;
                    }
                    if (showAddJob) {
                      handleAddJobClick();
                      return;
                    }
                    if (
                      showJobDetails &&
                      selectedRecording?.job?.status !== JobStatus.DRAFT
                    ) {
                      handleJobClick();
                      return;
                    }
                    if (
                      selectedRecording?.job?.createdByCompanyId ===
                      selectedRecording?.company?.id
                    ) {
                      if (selectedRecording?.job?.createdForCompanyId) {
                        referJob();
                        return;
                      }
                      if (selectedRecording?.potentialReferralPartner) {
                        confirmPartner();
                        return;
                      }
                    } else if (
                      selectedRecording?.job?.createdForCompanyId ===
                      selectedRecording?.company?.id
                    ) {
                      if (selectedRecording?.job?.createdByCompanyId) {
                        approveJob();
                        return;
                      }
                      if (selectedRecording?.potentialReferralPartner) {
                        confirmPartner();
                        return;
                      }
                    }
                    handlePartnerClick();
                  }}
                >
                  {showJobDetails &&
                  selectedRecording?.job?.status !== JobStatus.DRAFT
                    ? "Job Details"
                    : action()}
                </Button>
                {jobReferred ? null : (
                  <>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={1}
                      width={"100%"}
                    >
                      {selectedRecording?.potentialReferralPartner ? (
                        <Button
                          variant="outlined"
                          sx={{ width: "100%" }}
                          onClick={() => {
                            handlePartnerClick();
                          }}
                        >
                          Don’t see the correct partner?
                        </Button>
                      ) : null}
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={1}
                      width={"100%"}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          width: "50%",
                          "&.MuiButton-outlined": {
                            color: "#F04438", // Change the text color
                            borderColor: "#F04438", // Change the border color
                            "&:hover": {
                              backgroundColor: "#FEF3F2", // Change the background color on hover
                            },
                          },
                        }}
                        disabled={markingNoReferral || isReferringJob}
                        onClick={() => {
                          markNoReferral({
                            callRecordingId: selectedRecording?.id,
                          });
                        }}
                      >
                        Not a Referral
                      </Button>
                      {selectedRecording?.job?.status === JobStatus.DRAFT ? (
                        <Button
                          sx={{ width: "50%" }}
                          variant="none"
                          disabled={switchingJob}
                          startIcon={<SwapHorizontalCircleOutlinedIcon />}
                          onClick={() =>
                            switchCompanies({
                              jobId: selectedRecording?.job?.id,
                            })
                          }
                        >
                          Swap Referral{" "}
                          <ToolTip
                            placement="bottom"
                            title="Swap Referral"
                            message="Easily switch referral type from inbound to outbound, or vice versa."
                          >
                            <HelpOutlineIcon
                              sx={{
                                background: "none",
                                color: "#6C737F",
                                width: "20px",
                                height: "20px",
                                ml: 1,
                              }}
                            />
                          </ToolTip>
                        </Button>
                      ) : null}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Divider sx={{ marginBottom: "16px", borderColor: "#DCDFE4" }} />
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              {selectedRecording?.company?.id ===
                selectedRecording?.job?.createdByCompanyId &&
              selectedRecording?.potentialReferralPartner &&
              selectedRecording.potentialReferralPartnerPayReferralSettings ===
                PayReferralFeeSettings.FIXED_AMOUNT ? (
                <>
                  <Box
                    sx={{
                      borderRadius: "8px",
                      p: 2,
                      gap: "4px",
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid #16DB65",
                      background: "rgba(185, 244, 209, 0.30)",
                    }}
                  >
                    <Typography
                      fontSize={"13px"}
                      fontWeight={500}
                      color={"#16B364"}
                    >
                      Your Instant Potential Commission
                    </Typography>
                    <Typography variant="h5">
                      $
                      {selectedRecording.potentialReferralPartnerSectors
                        ?.find(
                          sector =>
                            sector.sector === selectedRecording?.job?.sector
                        )
                        ?.buyingPrice?.toFixed(2) ?? "25.00"}
                    </Typography>
                    <Typography variant="body2" color={"text.secondary"}>
                      Refer this job now to earn instantly on TradeEngage!{" "}
                    </Typography>
                  </Box>
                  <Divider sx={{ borderColor: "#DCDFE4" }} />
                </>
              ) : null}
              {selectedRecording?.job ? (
                <>
                  <JobDetails selectedJob={selectedRecording?.job} />
                  <Divider sx={{ borderColor: "#DCDFE4" }} />
                </>
              ) : null}
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Typography variant="overline" color={"text.secondary"}>
                  User Details
                </Typography>
                {selectedRecording?.homeDetails ? (
                  <HomeownerDetails
                    homeDetails={selectedRecording?.homeDetails}
                  />
                ) : null}
                {selectedRecording?.customerServiceRepresentative ??
                notification?.csrName ? (
                  <CustomerRepresentative
                    name={
                      selectedRecording?.customerServiceRepresentative
                        ? `${selectedRecording?.customerServiceRepresentative?.firstName} ${selectedRecording?.customerServiceRepresentative?.lastName}`
                        : notification?.csrName ?? ""
                    }
                  />
                ) : null}
                <Divider sx={{ borderColor: "#DCDFE4" }} />
              </Box>
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Typography variant="overline" color={"text.secondary"}>
                  Call Details
                </Typography>
                {selectedRecording?.job?.videoUrl ??
                selectedRecording?.audioPublicUrl ? (
                  <>
                    <Typography variant="subtitle2">Call Recording</Typography>
                    <Typography variant="caption" color="text.secondary">
                      Created At: {formattedDate}
                    </Typography>
                    <audio
                      controls
                      playsInline
                      controlsList="nodownload"
                      style={{
                        width: "100%",
                        borderRadius: 3,
                        colorScheme: "light",
                        height: "40px",
                        background: "white",
                      }}
                    >
                      <source
                        src={
                          selectedRecording?.job?.videoUrl ??
                          selectedRecording?.audioPublicUrl
                        }
                        type="audio/mpeg"
                      />
                    </audio>
                  </>
                ) : null}
                {selectedRecording?.transcription ? (
                  <>
                    <Typography variant="subtitle2">Transcript</Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      textAlign={"start"}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: selectedRecording?.transcription,
                        }}
                      ></span>
                    </Typography>
                  </>
                ) : null}
              </Box>
              {steps.length ? (
                <>
                  <Divider sx={{ borderColor: "#DCDFE4" }} />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      mb: 2,
                    }}
                  >
                    <Stack
                      alignItems="flex-start"
                      direction={"row"}
                      justifyContent={"center"}
                      spacing={2}
                      sx={{
                        width: "100%",
                        ml: 0,
                        mr: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          py: 1,
                          px: 2,
                          width: "100%",
                          borderRadius: "8px",
                          background: "#EDFCF2",
                          boxShadow: "0px 0px 0px 1px #F2F4F7",
                        }}
                      >
                        <Typography variant="body1" sx={{ color: "#16DB65" }}>
                          The Referral Trade is Engaged!{" "}
                          <Typography
                            variant="body1"
                            sx={{ color: "rgba(17, 25, 39, 0.38)" }}
                            display={"inline"}
                          >
                            (TradeEngage AI)
                          </Typography>
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                          TradeEngage’s magic will follow up on this message and
                          keep you updated
                        </Typography>
                        <Box display={"flex"} flexDirection={"column"} gap={1}>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            gap={1}
                          >
                            <Typography
                              variant="overline"
                              color="text.secondary"
                            >
                              Request Progress
                            </Typography>
                          </Box>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            gap={1}
                            alignItems={"center"}
                            width={"100%"}
                          >
                            <LinearProgress
                              variant="determinate"
                              value={Math.floor(
                                (activeStep / steps?.length) * 100
                              )}
                              sx={{
                                width: "90%",
                                flex: "none",
                                height: "4px",
                                [`&.${linearProgressClasses.colorPrimary}`]: {
                                  bgcolor: "#A2F1C1",
                                  color: "#15DB65",
                                },
                                [`& .${linearProgressClasses.bar}`]: {
                                  bgcolor: "#15DB65",
                                },
                              }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              {Math.floor((activeStep / steps?.length) * 100)}%
                            </Typography>
                          </Box>
                        </Box>
                        <Box display={"flex"} width={"100%"}>
                          <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                          >
                            {steps.map((step, index) => (
                              <Step
                                key={step.label}
                                disabled={index >= activeStep}
                                // onClick={() => {
                                //   if (index === 0 || index === 1) {
                                //     setSelectedCompanyId(recording?.potentialReferralPartner?.id)

                                //     return
                                //   }
                                //   if (index === 2) {
                                //     setDefaultJobDrawerTab(JobDrawerTabsEnum.HOMEOWNER)
                                //     setJobDrawerOpen(true)
                                //     return
                                //   }
                                //   if (index === 3) {
                                //     setSelectedCompanyId(recording?.potentialReferralPartner?.id)
                                //     setSelectedTab(TabsEnum.MESSAGE)
                                //     return
                                //   }
                                // }}
                                sx={{
                                  cursor: index < 4 ? "pointer" : "none",
                                  ...(index >= activeStep
                                    ? {
                                        "& .MuiStepLabel-root": {
                                          "& .MuiStepIcon-root": {
                                            color: theme =>
                                              index >= activeStep
                                                ? theme.palette.action.disabled
                                                : "inherit",
                                          },
                                          "& .MuiStepIcon-text": {
                                            fill: theme =>
                                              index >= activeStep
                                                ? "text.secondary"
                                                : "inherit",
                                          },
                                        },
                                      }
                                    : {}),
                                }}
                              >
                                <StepLabel
                                  optional={
                                    <Typography variant="caption">
                                      {step.description}
                                    </Typography>
                                  }
                                >
                                  {index >= activeStep ? (
                                    <Typography
                                      color="text.secondary"
                                      fontSize={"14px"}
                                      fontWeight={500}
                                      fontStyle={"italic"}
                                    >
                                      {step.label}
                                    </Typography>
                                  ) : step.label.includes("<b>") ? (
                                    <Typography variant="body2">
                                      <Typography
                                        variant="body2"
                                        fontWeight={700}
                                        display={"inline"}
                                      >
                                        {step.label.split("<b>")?.[0]}{" "}
                                      </Typography>
                                      {step.label.split("<b>")?.[1]}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      fontSize={"14px"}
                                      fontWeight={500}
                                    >
                                      {step.label}
                                    </Typography>
                                  )}
                                </StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </>
              ) : null}
            </Box>
          </>
        )}
      </Box>
      {companyProfile ? (
        <CompanyDrawer
          open={!!companyProfile}
          companyProfile={companyProfile}
          connectionStatus={ConnectionStatus.CONNECTED}
          onClose={() => {
            setSelectedCompanyId(undefined);
          }}
        />
      ) : null}
      {payDialogOpen && selectedRecording?.job ? (
        <JobCompletionPaymentDialogByJob
          isOpen={payDialogOpen}
          jobResponse={selectedRecording?.job}
          onClose={(refetch?: boolean) => {
            if (refetch) {
              refetchCall(selectedRecording);
              handlePayReferralFee();
            }

            setPayDialogOpen(false);
          }}
        />
      ) : null}
      {modifyJobHomeownerDialogOpen && selectedRecording?.job ? (
        <ModifyJobHomeownerDetailsDialog
          recording={selectedRecording}
          job={selectedRecording?.job}
          isSellingCompany={
            getJobAssociationOfLoggedInCompany() ===
            JobAssociation.SELLING_COMPANY
          }
          isOpen={modifyJobHomeownerDialogOpen}
          onClose={homeownerAdded => {
            if (homeownerAdded) {
              setNewJobHomeownerAdded(homeownerAdded);
              refetchCall({ ...selectedRecording });
            }
            setModifyJobHomeownerDialogOpen(false);
          }}
        />
      ) : null}
      {addReferralPartnerDialogOpen && selectedRecording?.job ? (
        <ExplorePartnersCard
          isSellingCompany={
            getJobAssociationOfLoggedInCompany() ===
            JobAssociation.SELLING_COMPANY
          }
          recording={selectedRecording}
          refetchCalls={() => refetchCall(selectedRecording)}
          job={selectedRecording?.job}
          isOpen={addReferralPartnerDialogOpen}
          onAddNew={() => {
            setAddCompanyInfoOpen(true);
            setAddReferralPartnerDialogOpen(false);
          }}
          onConfirm={(
            companyProfile: CompanyPublicProfile | ExternalCompanyProfile
          ) => {
            if (companyProfile?.googlePlacesId && !companyProfile?.id) {
              setAddCompanyInfoOpen(true);
              setPartnerCompanyProfile(companyProfile);
            } else {
              associateCompany({
                jobId: selectedRecording?.job?.id,
                jobAssociation: getJobAssociationOfReferralPartnerCompany(),
                existingCompanyId: companyProfile?.id,
              });
            }
            setAddReferralPartnerDialogOpen(false);
          }}
          onClose={() => setAddReferralPartnerDialogOpen(false)}
        />
      ) : null}
      {addCompanyInfoDialogOpen && selectedRecording?.job ? (
        <AddCompanyInfo
          job={selectedRecording?.job}
          isOpen={addCompanyInfoDialogOpen}
          jobAssociation={getJobAssociationOfReferralPartnerCompany()}
          googleCompanyProfile={partnerCompanyProfile}
          onConfirm={() => {
            setAddCompanyInfoOpen(false);
            refetchCall(selectedRecording);
            setPartnerCompanyProfile(undefined);
          }}
          onClose={() => {
            setAddCompanyInfoOpen(false);
            setPartnerCompanyProfile(undefined);
          }}
        />
      ) : null}
      {jobDetailsDialogOpen && selectedRecording?.job ? (
        <AddJobDetailsDialog
          isOpen={jobDetailsDialogOpen}
          recording={selectedRecording}
          isLoading={updatingJobDetails}
          job={selectedRecording?.job}
          editJob={({
            summary,
            serviceCategory,
            jobType,
          }: {
            summary: string;
            serviceCategory: JobSectors;
            jobType: string;
          }) => {
            updateJobDetails({
              jobId: selectedRecording?.job?.id,
              jobType,
              sector: serviceCategory,
              summary,
            });
          }}
          onClose={() => {
            setAddJobOpen?.(false);
            addJobDetailsDialogOpen(false);
          }}
        />
      ) : null}
      {jobDrawerOpen && selectedRecording?.job ? (
        <JobDrawer
          source={Source.CALL_CENTER}
          open={!!selectedRecording.job}
          jobId={selectedRecording.job.id}
          jobStatus={selectedRecording.job?.status}
          lastActivity={getLastActivityJob(selectedRecording.job)}
          onJobClose={() => {
            refetchCall(selectedRecording);
          }}
          onClose={() => {
            setJobDrawerOpen(false);
          }}
        />
      ) : null}
    </>
  );
};

export default ReferralNotification;
