import {
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarTodayIcon,
  DriveFileRenameOutlineOutlined as DriveFileRenameOutlineOutlinedIcon,
  EventBusy as EventBusyIcon,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { TextEditorTextPreview } from "@/lib/text-editor/TextEditorTextPreview";

import { useUpdateReferralCampaign } from "../api/useUpdateReferralCampaign";
import { ReferralCampaign, ReferralCampaignStatus } from "../types";

interface ReferralCampaignCardProps {
  referralCampaign: ReferralCampaign;
  onClick: () => void;
  onUpdate: () => void;
  onMoreOptionsClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export function ReferralCampaignCard(props: ReferralCampaignCardProps) {
  const { referralCampaign, onClick, onUpdate, onMoreOptionsClick } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { mutateAsync: updateReferralCampaignStatusAsync } =
    useUpdateReferralCampaign({
      onSuccess: () => {
        onUpdate();
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "There was an error updating the promotion status";
        enqueueSnackbar({
          message,
          variant: "error",
        });
      },
    });

  const referralCampaignHeader = () => {
    if (referralCampaign.isDraft) {
      return (
        <Typography variant="subtitle2" color="text.secondary">
          Last Edited{" "}
          {DateTime.fromISO(referralCampaign.updatedAt.toString(), {
            zone: "utc",
          })
            .toLocal()
            .toFormat("MM/dd/yy")}
        </Typography>
      );
    }

    const referralCampaignEndsInDays = Math.ceil(
      DateTime.fromISO(referralCampaign.endDate.toString(), {
        zone: "utc",
      }).diff(DateTime.utc(), "days").days
    );

    if (referralCampaignEndsInDays < 0) {
      return (
        <Chip
          size="small"
          icon={<EventBusyIcon fontSize="small" />}
          label={`Ended on ${DateTime.fromISO(
            referralCampaign.endDate.toString(),
            {
              zone: "utc",
            }
          )
            .toLocal()
            .toFormat("MM/dd/yy")}`}
          sx={{
            backgroundColor: "#F1F1F4",
            color: "text.secondary",
            borderRadius: 1,
            ".MuiChip-icon": {
              color: "text.secondary",
            },
          }}
        />
      );
    }

    if (referralCampaignEndsInDays <= 2) {
      return (
        <Chip
          size="small"
          icon={<AccessTimeIcon fontSize="small" />}
          label={
            referralCampaignEndsInDays === 0
              ? "Ends Today"
              : `Ends in ${referralCampaignEndsInDays} days`
          }
          sx={{
            backgroundColor: "#FEE4E2",
            color: "#F04438",
            borderRadius: 1,
            ".MuiChip-icon": {
              color: "#F04438",
            },
          }}
        />
      );
    }

    return (
      <Chip
        size="small"
        icon={<CalendarTodayIcon fontSize="small" />}
        label={`Ends in ${referralCampaignEndsInDays} days`}
        sx={{
          backgroundColor: "#EDFCF2",
          color: "#16B364",
          borderRadius: 1,
          ".MuiChip-icon": {
            color: "#16B364",
          },
        }}
      />
    );
  };

  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        minWidth: "360px",
        height: "260px",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        margin: 1,
      }}
      onClick={onClick}
    >
      <CardContent
        sx={{
          paddingX: 3,
          paddingTop: 3,
          paddingBottom: 2,
          overflow: "hidden",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Stack justifyContent="space-between" direction="row">
          <Box />
          {referralCampaignHeader()}
        </Stack>
        <Typography
          variant="h6"
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {referralCampaign.name}
        </Typography>
        <Box sx={{ paddingY: 0.5, overflow: "hidden", flexGrow: 1 }}>
          <TextEditorTextPreview
            content={referralCampaign.description}
            lineClamp={3}
            fontSize="14px"
          />
        </Box>
      </CardContent>
      <CardActions
        sx={{
          paddingX: 2,
          paddingTop: 1,
          paddingBottom: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {referralCampaign.isDraft ? (
          <Button
            variant="outlined"
            startIcon={<DriveFileRenameOutlineOutlinedIcon />}
            sx={{
              fontWeight: 500,
              color: "#212636",
            }}
            onClick={() => {
              navigate(
                getNavigationPathFromRoutePaths([
                  "admin",
                  "marketing",
                  "referral-campaigns",
                  referralCampaign.id.toString(),
                  "edit",
                ])
              );
            }}
          >
            Continue Editing
          </Button>
        ) : (
          <FormControlLabel
            control={
              <Switch
                checked={
                  referralCampaign.status === ReferralCampaignStatus.ACTIVE
                }
                disabled={
                  DateTime.fromISO(referralCampaign.endDate.toString(), {
                    zone: "utc",
                  }) < DateTime.utc()
                }
                onChange={async event => {
                  await updateReferralCampaignStatusAsync({
                    referralCampaignId: referralCampaign.id,
                    status: !event.target.checked
                      ? ReferralCampaignStatus.INACTIVE
                      : ReferralCampaignStatus.ACTIVE,
                  });
                }}
              />
            }
            label={
              <Typography
                variant="subtitle2"
                fontWeight={500}
                sx={{ color: "#212636" }}
              >
                {referralCampaign.status === ReferralCampaignStatus.ACTIVE
                  ? "Active"
                  : "Paused"}
              </Typography>
            }
            onClick={event => {
              event.stopPropagation();
            }}
          />
        )}
        <IconButton onClick={onMoreOptionsClick}>
          <MoreHorizIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
