/**
 * Appends query parameters to a given URL.
 *
 * @param {string} url - The base URL to which query parameters will be appended.
 * @param {Record<string, string | number | boolean>} params - An object representing the query parameters to append.
 * Each key-value pair corresponds to a query parameter, where the key is the parameter name, and the value is the parameter value.
 *
 * @returns {string} - The URL with the appended query parameters.
 *
 * @example
 * ```typescript
 * const url = "https://example.com";
 * const params = {
 *   search: "typescript",
 *   page: 2,
 *   active: true
 * };
 *
 * const newUrl = appendQueryParams(url, params);
 * console.log(newUrl);
 * // Output: "https://example.com?search=typescript&page=2&active=true"
 * ```
 */
export function appendQueryParams(
  url: string,
  params: Record<string, string | number | boolean>
): string {
  const urlObj = new URL(url);

  Object.keys(params).forEach(key => {
    urlObj.searchParams.append(key, String(params[key]));
  });

  return urlObj.toString();
}

/**
 * Removes keys from an object that have `null` or `undefined` values.
 *
 * @param {Record<string, any>} obj - The input object from which keys with `null` or `undefined` values will be removed.
 *
 * @returns {Record<string, any>} - A new object with the keys containing `null` or `undefined` values removed.
 *
 * @example
 * ```typescript
 * const data = {
 *   name: "Alice",
 *   age: null,
 *   email: "alice@example.com",
 *   address: undefined
 * };
 *
 * const cleanedData = removeNullAndUndefinedKeys(data);
 * console.log(cleanedData);
 * // Output: { name: "Alice", email: "alice@example.com" }
 * ```
 */
export function removeNullAndUndefinedKeys(
  obj: Record<string, unknown>
): Record<string, unknown> {
  const result: Record<string, unknown> = {};

  Object.keys(obj).forEach(key => {
    if (obj[key] !== null && obj[key] !== undefined) {
      result[key] = obj[key];
    }
  });

  return result;
}

/**
 * Converts an international phone number to its national format.
 *
 * @param {string} phoneNumber - The international phone number to be converted.
 * @param {string} [countryCode="+1"] - The country code that the phone number should be converted to. Defaults to "+1" (USA/Canada).
 *
 * @returns {string} - The phone number in national format.
 *
 * @example
 * ```typescript
 * const internationalNumber = "+14155552671";
 *
 * const nationalNumber = convertPhoneToNationalFormat(internationalNumber);
 * console.log(nationalNumber);
 * // Output: "4155552671"
 *
 * const anotherInternationalNumber = "+442071838750";
 * const countryCode = "44"; // UK country code
 *
 * const anotherNationalNumber = convertPhoneToNationalFormat(anotherInternationalNumber, countryCode);
 * console.log(anotherNationalNumber);
 * // Output: "2071838750"
 * ```
 */
export function convertPhoneToNationalFormat(
  phoneNumber: string | null | undefined,
  countryCode = "1"
): string | null | undefined {
  if (!phoneNumber) {
    return phoneNumber;
  }

  if (!phoneNumber.startsWith("+")) {
    return phoneNumber;
  }

  const regex = new RegExp(`^\\+${countryCode}`);
  return phoneNumber.replace(regex, "");
}
