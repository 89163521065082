/* eslint-disable jsx-a11y/media-has-caption */
import { Share } from "@mui/icons-material";
import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { ModifyJobDialog } from "@/common/ModifyJobDialog";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { JobShareDialog } from "@/company/jobs/components/JobShareDialog";
import { JobSectors } from "@/company/jobs/constants";
import { Job, JobStatus } from "@/company/jobs/types";
import {
  getLastActivityJob,
  getRecommendedProviderDetails,
} from "@/company/jobs/utils";
import { snakeCaseJobSectors } from "@/company/utils";

import { getHomeownerJobCardStatusChipProps } from "../HomeownerJobCard/utils";

export enum Source {
  PUBLIC_JOB_PAGE = "PUBLIC_JOB_PAGE",
  HOMEOWNER_JOB_DASHBOARD = "HOMEOWNER_JOB_DASHBOARD",
}

export interface JobProjectTabProps {
  job: Job;
  source?: Source;
  openCompanyDrawer?: () => void;
  openServiceProviderTab: () => void;
}
export function JobProjectTab(props: JobProjectTabProps) {
  const { session } = useAuth();
  const { job, source, openCompanyDrawer, openServiceProviderTab } = props;
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [modifyJobSectorDialogOpen, setModifyJobSectorDialogOpen] =
    useState(false);
  const [newSector, setNewJobSector] = useState<JobSectors | null>(null);

  const recommendedProviderDetails = getRecommendedProviderDetails(job);

  return (
    <>
      <Grid container width={"100%"} sx={{ pb: 2 }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>
            <Chip {...getHomeownerJobCardStatusChipProps(job, true)} />
          </Grid>
          {source !== Source.PUBLIC_JOB_PAGE ? (
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                startIcon={<Share />}
                color="inherit"
                sx={{ pr: 0 }}
                onClick={() => setShareDialogOpen(true)}
              >
                Share
              </Button>
            </Grid>
          ) : null}
        </Grid>
        {source !== Source.PUBLIC_JOB_PAGE ? (
          <Box py={2} width={"100%"}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            {job?.type === JobUploadTypes.AUDIO ? (
              <audio
                controls
                playsInline
                controlsList="nodownload"
                style={{
                  width: "100%",
                  borderRadius: 3,
                  background: "white",
                }}
              >
                <source src={`${job.videoUrl}`} type="audio/mpeg" />
              </audio>
            ) : job?.type === JobUploadTypes.VIDEO ? (
              <video
                controls
                playsInline
                controlsList="nodownload"
                style={{
                  width: "100%",
                  height: "220px",
                  borderRadius: 3,
                  background: "white",
                }}
              >
                <source src={`${job.videoUrl}#t=0.1`} type="video/mp4" />
              </video>
            ) : null}
          </Box>
        ) : null}
        {job.sector ? (
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            my={1.5}
            alignItems={"center"}
          >
            <Grid item xs={5}>
              <Typography variant="subtitle2">Service Category</Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} gap={2} alignItems={"center"}>
              <Typography variant="body2">
                {snakeCaseJobSectors[(newSector ?? job?.sector) as JobSectors]}
              </Typography>
              {(job.status === JobStatus.OPPORTUNITY ||
                job.status === JobStatus.SUBMISSION ||
                job.status === JobStatus.HOMEOWNER_DELAYED ||
                job.status === JobStatus.PENDING_HOMEOWNER_ACTION) &&
              source !== Source.PUBLIC_JOB_PAGE ? (
                <Button
                  sx={{
                    mr: 1,
                  }}
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setModifyJobSectorDialogOpen(true);
                  }}
                >
                  Modify
                </Button>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
        <Divider sx={{ width: "100%" }} />
        <Grid container item xs={12} justifyContent="space-between" my={1.5}>
          <Grid item xs={5}>
            <Typography variant="subtitle2">
              {job.status === JobStatus.PAID
                ? "Referral Partner"
                : "Recommended Provider"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {recommendedProviderDetails ? (
              <Box
                gap="4px"
                alignItems="flex-start"
                flexDirection="column"
                mb={1}
              >
                <CompanyAvatar
                  name={recommendedProviderDetails?.companyName ?? ""}
                  height="40px"
                  width="40px"
                  logo={recommendedProviderDetails.logoUrl}
                />
                <Typography
                  variant="body2"
                  {...(recommendedProviderDetails.googlePlacesId
                    ? {
                        sx: { cursor: "pointer", fontWeight: "bold" },
                        onClick: openServiceProviderTab,
                        color: "primary",
                      }
                    : {})}
                >
                  {recommendedProviderDetails.companyName}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2">Processing</Typography>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%" }} />
        {job.listingExtraInfo?.createdByCompanyName ? (
          <>
            <Grid container item xs={12} justifyContent="space-between" my={2}>
              <Grid item xs={5}>
                <Typography variant="subtitle2">Recommended By</Typography>
              </Grid>
              <Grid item xs={6}>
                <Box
                  gap="4px"
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  mb={1}
                >
                  <CompanyAvatar
                    name={job.listingExtraInfo?.createdByCompanyName ?? ""}
                    height="40px"
                    width="40px"
                    logo={job.listingExtraInfo?.createdByCompanyLogoUrl}
                  />
                  <Typography
                    sx={{
                      cursor:
                        source !== Source.PUBLIC_JOB_PAGE ? "pointer" : "auto",
                      fontWeight: "bold",
                    }}
                    variant="body2"
                    {...(source !== Source.PUBLIC_JOB_PAGE &&
                    job.listingExtraInfo?.createdByCompanyGooglePlacesId
                      ? { color: "primary", onClick: openCompanyDrawer }
                      : {})}
                  >
                    {job.listingExtraInfo?.createdByCompanyName}
                  </Typography>
                </Box>
                <Typography variant="body2">
                  {job.listingExtraInfo?.technicianName}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ width: "100%" }} />
          </>
        ) : job.createdByUserId === session?.user?.id ? (
          <>
            <Grid container item xs={12} justifyContent="space-between" my={2}>
              <Typography gutterBottom variant="subtitle2">
                You created this service request.
              </Typography>
            </Grid>
            <Divider sx={{ width: "100%" }} />
          </>
        ) : null}
        {job.summary ? (
          <>
            <Grid item xs={12} my={2}>
              <Typography gutterBottom variant="subtitle1">
                Summary
              </Typography>
              <Typography variant="body2">{job.summary}</Typography>
            </Grid>
            <Divider sx={{ width: "100%" }} />
          </>
        ) : null}
        <Grid container item xs={12} justifyContent="space-between" my={1.5}>
          <Grid item xs={5}>
            <Typography variant="subtitle2">Last Activity</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{getLastActivityJob(job)}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%" }} />
        {shareDialogOpen ? (
          <JobShareDialog
            job={job}
            isOpen={shareDialogOpen}
            onClose={() => {
              setShareDialogOpen(false);
            }}
          />
        ) : null}
        {modifyJobSectorDialogOpen ? (
          <ModifyJobDialog
            job={job}
            isOpen={modifyJobSectorDialogOpen}
            onClose={sector => {
              if (sector) {
                setNewJobSector(sector);
                //refreshJobs?.();
              }
              setModifyJobSectorDialogOpen(false);
            }}
          />
        ) : null}
      </Grid>
    </>
  );
}
