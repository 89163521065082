import {
  ChevronRight as ChevronRightIcon,
  RocketLaunchOutlined as RocketLaunchOutlinedIcon,
  SaveOutlined as SaveOutlinedIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { neutral } from "@/Theme/colors";
import { isDefined } from "@/utils/isDefined";

import { useCreateReferralCampaign } from "../api/useCreateReferralCampaign";
import { useGetReferralCampaign } from "../api/useGetReferralCampaign";
import { useUpdateReferralCampaign } from "../api/useUpdateReferralCampaign";
import {
  ReferralCampaignAdditionalSettingsForm,
  ReferralCampaignAdditionalSettingsFormFields,
  referralCampaignAdditionalSettingsFormSchema,
} from "../components/AddReferralCampaignForm/ReferralCampaignAdditionalSettingsForm";
import {
  ReferralCampaignBasicInfoForm,
  ReferralCampaignBasicInfoFormFields,
  referralCampaignBasicInfoFormSchema,
} from "../components/AddReferralCampaignForm/ReferralCampaignBasicInfoForm";
import {
  ReferralCampaignBrandingForm,
  ReferralCampaignBrandingFormFields,
  referralCampaignBrandingFormSchema,
  referralCampaignBrandOptions,
} from "../components/AddReferralCampaignForm/ReferralCampaignBrandingForm";
import {
  ReferralCampaignGeographyForm,
  ReferralCampaignGeographyFormFields,
  referralCampaignGeographyFormSchema,
} from "../components/AddReferralCampaignForm/ReferralCamPaignGeographyForm";
import {
  ReferralCampaignGoalForm,
  ReferralCampaignGoalFormFields,
  referralCampaignGoalFormSchema,
  referralCampaignGoalOptions,
} from "../components/AddReferralCampaignForm/ReferralCampaignGoalForm";
import {
  ReferralCampaignPrizeForm,
  ReferralCampaignPrizeFormFields,
  referralCampaignPrizeFormSchema,
} from "../components/AddReferralCampaignForm/ReferralCampaignPrizeForm";
import {
  ReferralCampaignSuccessCriteriaForm,
  ReferralCampaignSuccessCriteriaFormFields,
  referralCampaignSuccessCriteriaFormSchema,
  referralCampaignSuccessCriteriaOptions,
} from "../components/AddReferralCampaignForm/ReferralCampaignSuccessCriteria";
import { ReferralCampaignDetails } from "../components/ReferralCampaignDetails";
import {
  referralCampaignConfigurationStepLabels,
  referralCampaignConfigurationSteps,
} from "../constants";
import {
  ReferralCampaignConfigurationStep,
  ReferralCampaignNotificationChannel,
  ReferralCampaignPrizeType,
  ReferralCampaignSendUpdatesFrequency,
  ReferralCampaignStatus,
  ReferralCampaignTargetParticipants,
} from "../types";

type AddReferralCampaignFormFields = ReferralCampaignBrandingFormFields &
  ReferralCampaignGeographyFormFields &
  ReferralCampaignBasicInfoFormFields &
  ReferralCampaignGoalFormFields &
  ReferralCampaignPrizeFormFields &
  ReferralCampaignSuccessCriteriaFormFields &
  ReferralCampaignAdditionalSettingsFormFields;

const addReferralCampaignFormValidationSchema: Record<
  ReferralCampaignConfigurationStep,
  yup.AnySchema
> = {
  [ReferralCampaignConfigurationStep.CAMPAIGN_BRANDING]:
    referralCampaignBrandingFormSchema,
  [ReferralCampaignConfigurationStep.CAMPAIGN_GEOGRAPHY]:
    referralCampaignGeographyFormSchema,
  [ReferralCampaignConfigurationStep.CAMPAIGN_BASIC_INFO]:
    referralCampaignBasicInfoFormSchema,
  [ReferralCampaignConfigurationStep.CAMPAIGN_GOAL]:
    referralCampaignGoalFormSchema,
  [ReferralCampaignConfigurationStep.CAMPAIGN_SUCCESS_CRITERIA]:
    referralCampaignSuccessCriteriaFormSchema,
  [ReferralCampaignConfigurationStep.CAMPAIGN_REWARDS]:
    referralCampaignPrizeFormSchema,
  [ReferralCampaignConfigurationStep.CAMPAIGN_ADDITIONAL_SETTINGS]:
    referralCampaignAdditionalSettingsFormSchema,
};

export function AddReferralCampaignPage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { referralCampaignId } = useParams();

  const isEditMode = isDefined(referralCampaignId);

  const [currentStep, setCurrentStep] =
    useState<ReferralCampaignConfigurationStep>(
      ReferralCampaignConfigurationStep.CAMPAIGN_BRANDING
    );

  const {
    data: referralCampaign,
    isLoading: isReferralCampaignLoading,
    isError: isReferralCampaignError,
  } = useGetReferralCampaign(
    {
      referralCampaignId: Number(referralCampaignId),
    },
    {
      enabled: isEditMode,
      onError: () => {
        enqueueSnackbar("Referral campaign not found", {
          variant: "error",
        });
      },
    }
  );

  const { mutateAsync: createReferralCampaignAsync } =
    useCreateReferralCampaign({
      onSuccess: data => {
        enqueueSnackbar(
          data.isDraft
            ? "Referral campaign draft saved successfully"
            : "Referral campaign created successfully",
          {
            variant: "success",
          }
        );
        navigate(
          getNavigationPathFromRoutePaths([
            "admin",
            "marketing",
            "referral-campaigns",
          ])
        );
      },
    });

  const { mutateAsync: updateReferralCampaignAsync } =
    useUpdateReferralCampaign({
      onSuccess: () => {
        enqueueSnackbar("Referral campaign updated successfully", {
          variant: "success",
        });
        navigate(
          getNavigationPathFromRoutePaths([
            "admin",
            "marketing",
            "referral-campaigns",
          ])
        );
      },
    });

  const addReferralCampaignFormMethods =
    useFormik<AddReferralCampaignFormFields>({
      initialValues: {
        geographies: [],
        name: "",
        startDate: DateTime.now(),
        endDate: DateTime.now().plus({ days: 1 }),
        description: "",
        goal: referralCampaignGoalOptions[0],
        otherGoal: "",
        prizes: [
          {
            prizeName: "",
            prizeType: ReferralCampaignPrizeType.CASH,
          },
        ],
        brands: [referralCampaignBrandOptions[0]],
        specificBrands: [],
        notificationChannel: ReferralCampaignNotificationChannel.EMAIL,
        sendUpdatesFrequency: ReferralCampaignSendUpdatesFrequency.WEEKLY,
        targetParticipants: ReferralCampaignTargetParticipants.ADMIN_AND_OWNERS,
        successCriteria: [
          {
            criteria: referralCampaignSuccessCriteriaOptions[0],
            numberOfReferrals: 1,
            numberOfDays: 1,
          },
        ],
      },
      validationSchema: addReferralCampaignFormValidationSchema[currentStep],
      onSubmit: async data => {
        if (isEditMode) {
          await updateReferralCampaignAsync({
            ...data,
            referralCampaignId: Number(referralCampaignId),
            currentStep: undefined,
            isDraft: false,
            startDate: data.startDate.toISO() ?? "",
            endDate: data.endDate.toISO() ?? "",
          });
          return;
        }
        await createReferralCampaignAsync({
          ...data,
          currentStep: undefined,
          isDraft: false,
          startDate: data.startDate.toISO() ?? "",
          endDate: data.endDate.toISO() ?? "",
        });
      },
      validateOnMount: true,
      validateOnChange: true,
    });

  useEffect(() => {
    addReferralCampaignFormMethods.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (isEditMode && isDefined(referralCampaign)) {
      setCurrentStep(
        referralCampaign.currentStep ??
          ReferralCampaignConfigurationStep.CAMPAIGN_BRANDING
      );
      addReferralCampaignFormMethods.setValues({
        ...referralCampaign,
        startDate: DateTime.fromISO(referralCampaign.startDate),
        endDate: DateTime.fromISO(referralCampaign.endDate),
      });
    }
    addReferralCampaignFormMethods.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCampaign, isEditMode]);

  if (isReferralCampaignLoading && isEditMode) {
    return <ComponentLoader />;
  }

  if (isReferralCampaignError && isEditMode) {
    return (
      <Navigate
        to={getNavigationPathFromRoutePaths([
          "admin",
          "marketing",
          "referral-campaigns",
        ])}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        overflow: "hidden",
        flexDirection: "column",
        paddingY: 1,
        paddingX: 2,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingY: 1, borderBottom: `1px solid ${neutral[100]}` }}
      >
        <Typography variant="h5" fontWeight={700}>
          Create New Campaign
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            startIcon={<SaveOutlinedIcon />}
            onClick={async () => {
              if (isEditMode) {
                await updateReferralCampaignAsync({
                  ...addReferralCampaignFormMethods.values,
                  referralCampaignId: Number(referralCampaignId),
                  currentStep: currentStep,
                  isDraft: true,
                  startDate:
                    addReferralCampaignFormMethods.values.startDate.toISO() ??
                    "",
                  endDate:
                    addReferralCampaignFormMethods.values.endDate.toISO() ?? "",
                });
                return;
              }
              await createReferralCampaignAsync({
                ...addReferralCampaignFormMethods.values,
                currentStep: currentStep,
                isDraft: true,
                startDate:
                  addReferralCampaignFormMethods.values.startDate.toISO() ?? "",
                endDate:
                  addReferralCampaignFormMethods.values.endDate.toISO() ?? "",
              });
            }}
          >
            Finish Later
          </Button>
          {currentStep ===
          ReferralCampaignConfigurationStep.CAMPAIGN_ADDITIONAL_SETTINGS ? (
            <Button
              variant="contained"
              startIcon={<RocketLaunchOutlinedIcon />}
              onClick={() => addReferralCampaignFormMethods.handleSubmit()}
            >
              Publish
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={!addReferralCampaignFormMethods.isValid}
              onClick={() =>
                setCurrentStep(
                  prev =>
                    referralCampaignConfigurationSteps[
                      referralCampaignConfigurationSteps.indexOf(prev) + 1
                    ]
                )
              }
            >
              Next
            </Button>
          )}
        </Stack>
      </Stack>
      <FormikProvider value={addReferralCampaignFormMethods}>
        <Grid container sx={{ flexGrow: 1 }}>
          <Grid
            item
            xs={12}
            md={2.25}
            sx={{
              borderRight: `1px solid ${neutral[100]}`,
              padding: 2,
              paddingLeft: 0,
            }}
          >
            <Stack>
              <Typography variant="subtitle1" fontWeight={500}>
                Campaign Configuration
              </Typography>
              <Typography variant="body2" color="text.disabled">
                Build your campaign from here
              </Typography>
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            <Stack gap={1}>
              {referralCampaignConfigurationSteps.map((step, index) => {
                const isStepClickable = referralCampaignConfigurationSteps
                  .slice(0, index)
                  .every(step =>
                    addReferralCampaignFormValidationSchema[step].isValidSync(
                      addReferralCampaignFormMethods.values
                    )
                  );
                return (
                  <Chip
                    key={step}
                    clickable={isStepClickable}
                    label={
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2" fontWeight={500}>
                          {referralCampaignConfigurationStepLabels[step]}
                        </Typography>
                        {currentStep === step && (
                          <ChevronRightIcon fontSize="small" />
                        )}
                      </Stack>
                    }
                    sx={{
                      variant: "outlined",
                      width: "100%",
                      borderRadius: 1,
                      paddingX: 2,
                      backgroundColor:
                        currentStep === step ? "#EDFCF2" : "#F8F9FA",
                      justifyContent: "flex-start",
                      color: currentStep === step ? "#16B364" : "text.primary",
                      ".MuiChip-label": {
                        padding: 0,
                        width: "100%",
                      },
                    }}
                    onClick={() => {
                      if (isStepClickable) {
                        setCurrentStep(step);
                      }
                    }}
                  />
                );
              })}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={5.75}
            sx={{
              borderRight: `1px solid ${neutral[100]}`,
              padding: 2,
              overflowY: "auto",
              maxHeight: "calc(100vh - 125px)",
            }}
          >
            <Box component={"form"}>
              {currentStep ===
                ReferralCampaignConfigurationStep.CAMPAIGN_BRANDING && (
                <ReferralCampaignBrandingForm />
              )}
              {currentStep ===
                ReferralCampaignConfigurationStep.CAMPAIGN_GEOGRAPHY && (
                <ReferralCampaignGeographyForm />
              )}
              {currentStep ===
                ReferralCampaignConfigurationStep.CAMPAIGN_BASIC_INFO && (
                <ReferralCampaignBasicInfoForm inEditMode={isEditMode} />
              )}
              {currentStep ===
                ReferralCampaignConfigurationStep.CAMPAIGN_GOAL && (
                <ReferralCampaignGoalForm />
              )}
              {currentStep ===
                ReferralCampaignConfigurationStep.CAMPAIGN_SUCCESS_CRITERIA && (
                <ReferralCampaignSuccessCriteriaForm />
              )}
              {currentStep ===
                ReferralCampaignConfigurationStep.CAMPAIGN_REWARDS && (
                <ReferralCampaignPrizeForm />
              )}
              {currentStep ===
                ReferralCampaignConfigurationStep.CAMPAIGN_ADDITIONAL_SETTINGS && (
                <ReferralCampaignAdditionalSettingsForm />
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              borderRight: `1px solid ${neutral[100]}`,
              padding: 2,
              backgroundColor: "#F8F9FA",
            }}
          >
            <ReferralCampaignDetails
              referralCampaign={{
                ...addReferralCampaignFormMethods.values,
                status: ReferralCampaignStatus.INACTIVE,
                isDraft: false,
                startDate:
                  addReferralCampaignFormMethods.values.startDate.toISO() ?? "",
                endDate:
                  addReferralCampaignFormMethods.values.endDate.toISO() ?? "",
              }}
              activeStep={currentStep}
            />
          </Grid>
        </Grid>
      </FormikProvider>
    </Box>
  );
}
