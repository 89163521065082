/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
import { AddPhotoAlternateOutlined, HelpOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  InputBaseComponentProps,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { ElementType, useEffect, useRef, useState } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { useUpdateJobById } from "@/company/jobs/api";
import { OCRJobDetails, useGetOCR } from "@/company/jobs/api/useGetOCR";
import { useUpdateJobHome } from "@/company/jobs/api/useUpdateJobHome";
import { HOMEOWNER_APPROVAL_SETTING } from "@/company/types";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { getUSPhoneValidationSchema } from "@/utils";
import { isDefined } from "@/utils/isDefined";

import { GoogleAutocomplete } from "../GoogleAutocomplete";
import { MaskedPhoneInput } from "../MaskedPhoneInput";
import { doesErrorHaveMessage } from "../utils/doesErrorHaveMessage";

export interface HomeownerDetailsUploadProps {
  defaultHomeownerDetails?: {
    firstName: string;
    lastName?: string;
    address?: string;
    cellPhone?: string;
    zipCode?: string;
  };
  step: number;
  jobId: number;
  revertStep1?: () => void;
  onJobHomeUpload: () => void;
}

export function HomeownerDetailsUpload(props: HomeownerDetailsUploadProps) {
  const { step, onJobHomeUpload, defaultHomeownerDetails, revertStep1, jobId } =
    props;
  const { session } = useAuth();
  const [fetchedOCRDetails, setFetchedOCRDetails] = useState(false);
  const brandLocationProfile = session?.brandLocationProfile;
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const [zipCode, setZipCode] = useState<string | undefined>("");
  const [latitude, setLatitude] = useState<number | undefined>();
  const [longitude, setLongitude] = useState<number | undefined>();
  const [homeownerApprovalSwitch, setHomeownerApprovalSwitch] = useState(false);

  const [homeownerDetailsUpload, setHomeownerDetailsUpload] = useState<
    File | undefined
  >();

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      setHomeownerDetailsUpload(file);
      // Handle the file upload logic here
    }
    event.target.value = null;
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isFetchingOCR, mutateAsync: getOCR } = useGetOCR({
    onSuccess: (jobDetails: OCRJobDetails) => {
      if (
        homeownerApprovalSwitch ||
        brandLocationProfile?.homeownerJobApprovalSettings ===
          HOMEOWNER_APPROVAL_SETTING.ROUTE_ALL_JOBS_TO_HOME_OWNER
      ) {
        if (jobDetails?.phone) {
          formik.setFieldValue("cellPhone", jobDetails?.phone, true);
        }
        setFetchedOCRDetails(true);
        return;
      }
      if (jobDetails?.firstName) {
        formik.setFieldValue("firstName", jobDetails?.firstName, true);
      }
      if (jobDetails?.lastName) {
        formik.setFieldValue("lastName", jobDetails?.lastName, true);
      }
      if (jobDetails?.phone) {
        formik.setFieldValue("cellPhone", jobDetails?.phone, true);
      }
      if (jobDetails?.address) {
        formik.setFieldValue("address", jobDetails?.address, true);
      }
      setFetchedOCRDetails(true);
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while fetching homeowner details from image.";
      setHomeownerDetailsUpload(undefined);
      setFetchedOCRDetails(false);
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const validationSchema = yup.object().shape({
    firstName:
      brandLocationProfile?.homeownerJobApprovalSettings ===
        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER ||
      !homeownerApprovalSwitch
        ? yup.string().required("First Name is required")
        : yup.string().optional(),
    lastName:
      brandLocationProfile?.homeownerJobApprovalSettings ===
        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER ||
      !homeownerApprovalSwitch
        ? yup.string().required("Last Name is required")
        : yup.string().optional(),
    address:
      brandLocationProfile?.homeownerJobApprovalSettings ===
        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER ||
      !homeownerApprovalSwitch
        ? yup.string().required("Home Address is required")
        : yup.string().optional(),
    sector: yup.string().optional(),
    cellPhone: getUSPhoneValidationSchema().required(
      "Mobile Number is required"
    ),
  });

  useEffect(() => {
    const details = defaultHomeownerDetails;
    if (details) {
      if (details.firstName) {
        formik.setFieldValue("firstName", details.firstName, true);
      }
      if (details.lastName) {
        formik.setFieldValue("lastName", details.lastName, true);
      }
      if (details.cellPhone) {
        formik.setFieldValue("cellPhone", details.cellPhone, true);
      }
      if (details.address) {
        formik.setFieldValue("address", details.address, true);
      }
      if (details.zipCode) {
        setZipCode(details.zipCode);
      }
    }
  }, [defaultHomeownerDetails]);

  const formik = useFormik({
    initialValues: {
      cellPhone: "",
      sector: "",
      firstName: "",
      lastName: "",
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ cellPhone }) => {
      console.log({ cellPhone });
    },
  });

  const { isLoading: updatingJobHome, mutateAsync: updateJobHome } =
    useUpdateJobHome({
      onSuccess: () => {
        onJobHomeUpload();
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "here was an error while updating homeowner details.";
        if (error?.code === "cant_tie_job_to_a_company_member") {
          formik.setFieldError("cellPhone", message);
          return;
        }
        enqueueSnackbar({
          message,
          style: {
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            maxWidth: "500px",
          },
          variant: "error",
          ...(error?.code === "cant_tie_job_to_a_company_member"
            ? {
                subText: (
                  <>
                    Questions?{" "}
                    <a
                      role="button"
                      style={{
                        textDecorationLine: "underline",
                        color: "#7A271A",
                      }}
                      onClick={event => {
                        event.preventDefault();
                        setContactSupportModalOpen(true);
                      }}
                    >
                      Contact support
                    </a>
                    .
                  </>
                ),
              }
            : {}),
        });
      },
    });

  const { isLoading: updatingJob, mutateAsync: updateJob } = useUpdateJobById({
    onSuccess: () => {
      updateJobHome({
        jobId,
        cellPhone: `+1${formik.values.cellPhone.replace(/[\s()-]/g, "")}`,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        streetAddress: formik.values.address,
        zipCode,
        latitude,
        longitude,
      });
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while updating job details.";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (homeownerDetailsUpload) {
      getOCR({ jobId, imageFile: homeownerDetailsUpload });
    }
  }, [homeownerDetailsUpload]);

  let homeownerDetailsRequired =
    brandLocationProfile?.homeownerJobApprovalSettings ===
    HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER;

  if (
    brandLocationProfile?.homeownerJobApprovalSettings ===
    HOMEOWNER_APPROVAL_SETTING.ROUTE_ALL_JOBS_TO_HOME_OWNER
  ) {
    homeownerDetailsRequired = false;
  } else if (
    brandLocationProfile?.homeownerJobApprovalSettings ===
    HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
  ) {
    homeownerDetailsRequired = !homeownerApprovalSwitch;
  }

  let disableHomeOwnerReviewRequiredNextButton = false;

  if (!homeownerDetailsRequired) {
    if (
      !formik.values.cellPhone ||
      (formik.touched.cellPhone && Boolean(formik.errors.cellPhone))
    ) {
      disableHomeOwnerReviewRequiredNextButton = true;
    }
  } else {
    if (
      !formik.values.cellPhone ||
      !formik.values.firstName ||
      !formik.values.lastName ||
      !formik.values.address ||
      (formik.touched.cellPhone && Boolean(formik.errors.cellPhone)) ||
      (formik.touched.firstName && Boolean(formik.errors.firstName)) ||
      (formik.touched.lastName && Boolean(formik.errors.lastName))
    ) {
      disableHomeOwnerReviewRequiredNextButton = true;
    }
  }

  if (updatingJobHome || updatingJob) {
    disableHomeOwnerReviewRequiredNextButton = true;
  }

  const homeownerReviewRequired =
    brandLocationProfile?.homeownerJobApprovalSettings ===
    HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
      ? homeownerApprovalSwitch
      : brandLocationProfile?.homeownerJobApprovalSettings ===
        HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER
      ? false
      : true;

  if (step !== 1) return null;

  return (
    <Box width="100%" pt={3} gap={1} display="flex" flexDirection={"column"}>
      <Box
        display={"flex"}
        gap={isMobile ? 1 : 2}
        alignItems={"baseline"}
        flexDirection={"column"}
        width={"100%"}
      >
        {brandLocationProfile?.homeownerJobApprovalSettings !==
        HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE ? null : (
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            p={1}
            width={"100%"}
            sx={{
              borderRadius: "8px",
              border: homeownerReviewRequired
                ? "1px solid #16B364"
                : "1px solid #E5E7EB",
              background: "#FFF",
              boxShadow: "0px 0px 0px 1px #F2F4F7",
              opacity:
                brandLocationProfile?.homeownerJobApprovalSettings !==
                HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                  ? 0.5
                  : 1,
            }}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography variant="subtitle2">
                Does the homeowner know you&apos;re referring this job? If not,
                turn this on.
              </Typography>
              <Tooltip
                placement="top"
                title={
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      By turning this setting on
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 2, py: 0 }}>
                      <ListItem sx={{ display: "list-item", pl: 0, py: 0 }}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              sx={{ color: "rgba(255, 255, 255, 0.90)" }}
                            >
                              TradeEngage will send a text message to the
                              homeowner
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{ display: "list-item", pl: 0, py: 0 }}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              sx={{ color: "rgba(255, 255, 255, 0.90)" }}
                            >
                              TradeEngage will send a text message to the
                              homeowner
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </Box>
                }
              >
                <HelpOutline
                  sx={{
                    color: "#6C737F",
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Box>
            <Switch
              color="primary"
              disabled={
                brandLocationProfile?.homeownerJobApprovalSettings !==
                HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
              }
              checked={homeownerReviewRequired}
              onChange={() =>
                setHomeownerApprovalSwitch(
                  homeownerApprovalSwitch => !homeownerApprovalSwitch
                )
              }
            />
          </Box>
        )}
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleFileChange}
        />
        {isFetchingOCR ? (
          <Box display={"flex"} flexDirection={"column"} gap={0} mt={3}>
            <Typography variant="subtitle1">
              Extracting Homeowner Details from Image
            </Typography>
            <Typography variant="caption" fontSize={"0.625rem"}>
              Please do not close this window.
            </Typography>
            <LinearProgress sx={{ width: "100%", mt: 1 }} />
          </Box>
        ) : (
          <>
            <Box
              px={isMobile ? 1 : 2}
              py={1}
              width={"100%"}
              sx={{
                display: "flex",
                justifyContent: "space-between",

                alignItems: "center",
                borderRadius: "8px",
                border: "1px dashed #D2D6DB",
                gap: 1,
                background: "#F8F9FA",
                boxShadow: "0px 0px 0px 1px #F2F4F7",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#F8F9FA",
                },
              }}
              onClick={() => handleButtonClick()}
            >
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Typography variant="subtitle2">Upload Screenshot</Typography>
                <Tooltip
                  placement="bottom"
                  title={
                    <Typography
                      variant="body2"
                      sx={{ color: "rgba(255, 255, 255, 0.90)" }}
                    >
                      Take a screenshot from your field service app or snap a
                      picture of the address from anywhere.
                    </Typography>
                  }
                >
                  <HelpOutline
                    sx={{ color: "#6C737F", height: "20px", width: "20px" }}
                  />
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "50px",
                  height: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  background: "#e8fbf0",
                }}
              >
                <AddPhotoAlternateOutlined color="primary" />
              </Box>
            </Box>
            <Divider sx={{ width: "100%" }}>
              <Typography variant="overline" color={"text.secondary"}>
                OR
              </Typography>
            </Divider>
            <Box
              display={"flex"}
              gap={1}
              flexDirection={"column"}
              sx={{ width: "100%" }}
            >
              <Typography
                gutterBottom
                variant="subtitle2"
                sx={{ color: "#111927" }}
              >
                Type in the homeowner&apos;s contact info
              </Typography>
              {homeownerDetailsRequired ? (
                <Box display={"flex"} width="100%" gap={2}>
                  <TextField
                    required={homeownerDetailsRequired}
                    id="firstName"
                    name="firstName"
                    label={"First Name"}
                    sx={{
                      width: "50%",
                    }}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    value={formik.values.firstName ?? ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  <TextField
                    required={homeownerDetailsRequired}
                    sx={{
                      width: "50%",
                    }}
                    id="lastName"
                    name="lastName"
                    label={"Last Name"}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    value={formik.values.lastName ?? ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </Box>
              ) : null}
              <Box
                display={"flex"}
                width="100%"
                gap={2}
                flexDirection={isMobile ? "column" : "row"}
              >
                {homeownerDetailsRequired ? (
                  <Box sx={{ width: "100%" }}>
                    <GoogleAutocomplete
                      defaultOptions={[]}
                      selectedValue={formik.values.address ?? ""}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                      label={
                        homeownerDetailsRequired
                          ? "Home Address*"
                          : "Home Address"
                      }
                      onBlur={event => formik.setFieldTouched("address", true)}
                      onChange={(address: string) => {
                        formik.setFieldValue("address", address);
                      }}
                      onSelect={({
                        selection,
                        zipCode,
                        latitude,
                        longitude,
                      }) => {
                        setZipCode(zipCode);
                        setLatitude(latitude);
                        setLongitude(longitude);
                        formik.setFieldValue("address", selection);
                      }}
                    />
                  </Box>
                ) : null}
                <TextField
                  required
                  sx={{
                    width: {
                      xs: "100%",
                      md: homeownerDetailsRequired ? "100%" : "49%",
                    },
                  }}
                  id="cellPhone"
                  name="cellPhone"
                  label={"Mobile Number"}
                  error={
                    formik.touched.cellPhone && Boolean(formik.errors.cellPhone)
                  }
                  helperText={
                    formik.touched.cellPhone && formik.errors.cellPhone
                  }
                  value={formik.values.cellPhone ?? ""}
                  InputProps={{
                    inputComponent: MaskedPhoneInput as unknown as
                      | ElementType<InputBaseComponentProps>
                      | undefined,
                  }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Divider sx={{ width: "100%", mt: 1 }} />
            </Box>
          </>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mt={2}
      >
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            borderRadius: "8px",
            "&.Mui-disabled": {
              color: "text.disabled",
            },
          }}
          disabled={!isDefined(revertStep1)}
          size={isMobile ? "small" : "medium"}
          onClick={() => {
            setHomeownerDetailsUpload(undefined);
            setHomeownerApprovalSwitch(false);
            setLongitude(undefined);
            setLatitude(undefined);
            setZipCode(undefined);
            formik.resetForm();
            revertStep1?.();
          }}
        >
          Back
        </Button>
        <Button
          sx={{
            width: "fit-content",
            ml: 1,
            borderRadius: "8px",
          }}
          disabled={disableHomeOwnerReviewRequiredNextButton || isFetchingOCR}
          color="primary"
          variant="contained"
          onClick={() => {
            if (
              brandLocationProfile?.homeownerJobApprovalSettings !==
              HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
            ) {
              updateJobHome({
                jobId,
                cellPhone: `+1${formik.values.cellPhone.replace(
                  /[\s()-]/g,
                  ""
                )}`,
                firstName: formik.values.firstName,
                lastName: formik.values.lastName,
                streetAddress: formik.values.address,
                zipCode,
                latitude,
                longitude,
              });
              return;
            }
            updateJob({
              jobId,
              routeToHomeowner: homeownerApprovalSwitch,
            });
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
