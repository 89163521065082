import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { DateTime } from "luxon";

import API from "@/common/api";

import { JobSectors } from "../jobs/constants";

export interface InternalAdminJobFilters {
  sectors?: Array<JobSectors>;
  createdAt?: DateTime;
  nonCompetingCompanies?: boolean;
  franchise?: Array<string>;
  businessAge?: Array<string>;
  relevantSectors?: boolean;
  confirmedInsured?: boolean;
  existingCustomerReferralProgram?: boolean;
  existingReferral?: boolean;
  companyOwnership?: Array<string>;
  ownersNearRetirement?: boolean;
  familyOperatedBusiness?: boolean;
  mutualConnections?: boolean;
  localReferralNetwork?: Array<string>;
  collaborateWithOtherPartners?: boolean;
  tradeengageActivity?: Array<string>;
  betterBusinessBureau?: Array<string>;
  googleRating?: Array<number>;
  numberOfGoogleReviews?: Array<number>;
  historicalLawsuits?: boolean;
  numberOfWebsiteVisitors?: Array<number>;
  numberOfEmployees?: number;
  brickAndMortarLocation?: boolean;
  contactInformation?: Array<string>;
  detailsTechnologyStack?: boolean;
  onlineScheduling?: boolean;
  website?: boolean;
  marketPresence?: Array<string>;
  countOnly?: boolean;
  zipCodes?: Array<string>;
  milesRadius?: number;
  keyword?: string;
  archived?: boolean;
}

export interface Sorting {
  field: string;
  order: string;
}

export function useGetTableViews(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<any>> {
  const uri = "/internal-admin/table-views";

  return useQuery({
    queryKey: [uri.toString()],
    queryFn: async () => {
      const response = await API.get(uri);
      return response.data.data;
    },
    ...options,
  });
}
