import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";

export function SidePanel({
  isQuote,
  title,
  fact,
  heading,
  author,
  authorTitle,
}: {
  fact: {
    message: string;
    variant?: string;
    color?: string;
  };
  heading: {
    message: string;
    variant?: string;
    color?: string;
  };
  title?: string;
  isQuote?: boolean;
  author?: string;
  authorTitle?: string;
}) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        pt: { xs: 1, lg: 0 },
        pb: 0,
        px: 4,
        color: "white",
        rowGap: "40px",
        alignItems: "center",
        position: "sticky",
        overflowY: "auto",
        top: 0,
        ...(!isDesktop ? { height: "fit-content" } : { height: "100%" }),
      }}
    >
      {isDesktop ? (
        <TradeEngageLogo
          logotype={TradeEngageLogoTypes.LOGO_LIGHT}
          style={{
            left: "32px",
            height: "32px",
            top: "48px",
            paddingRight: "8px",
            position: "absolute",
          }}
        />
      ) : null}

      {isDesktop ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignSelf: "center",
            color: "white",
            rowGap: "20px",
            height: "inherit",
          }}
        >
          {isQuote ? (
            <FormatQuoteOutlinedIcon
              sx={{ color: "#16DB65", height: "50px", width: "50px" }}
            />
          ) : null}
          {title ? <Typography variant="h4">{title}</Typography> : null}
          <Typography
            variant={(fact.variant as Variant) ?? "h6"}
            color={fact.color}
            fontStyle={"italic"}
          >
            {fact.message}
          </Typography>
          <Box pb={1}>
            {author ? (
              <Typography
                sx={{
                  color: "#D2D6DB",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: 2,
                }}
              >
                {author}
              </Typography>
            ) : null}
            {authorTitle ? (
              <Typography
                sx={{
                  color: "#D2D6DB",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: 1.4,
                }}
              >
                {authorTitle}
              </Typography>
            ) : null}
          </Box>
          {title || isQuote ? (
            <Divider
              sx={{
                height: "1px",
                bgcolor: "primary.main",
                borderColor: "primary.main",
              }}
            />
          ) : null}
          <Typography
            py={1}
            variant={(heading.variant as Variant) ?? "h6"}
            color={heading.color}
          >
            {heading.message}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}
