import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

export const SemicircularProgressBar = ({ value }: { value: number }) => {
  return (
    <Box
      sx={{
        height: "80px",
        width: "144px",
      }}
    >
      <CircularProgressbarWithChildren
        value={value}
        circleRatio={0.5}
        strokeWidth={10}
        styles={{
          root: {
            transform: "rotate(0.75turn)",
          },
          path: {
            stroke: value >= 40 ? "#16B364" : "#F04438",
            strokeLinecap: "round",
          },
          trail: {
            stroke: value >= 40 ? "#EDFCF2" : "#FEF3F2",
            strokeLinecap: "round",
          },
        }}
      >
        <Typography fontSize={"24px"} mt={"-20px"}>
          {value}%
        </Typography>
      </CircularProgressbarWithChildren>
    </Box>
  );
};
