import { Box, Theme, useMediaQuery } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { DashboardTitle } from "@/common/DashboardTitle";
import { Seo } from "@/common/Seo/Seo";
import { canSeeCompanyMessages } from "@/common/utils/accessControl";
import {
  CallRecordingNotification,
  useFetchCompanyCallRecordingNotificationList,
} from "@/company/api/useFetchCallNotificationsUnread";
import { useFetchCallRecordingById } from "@/company/api/useFetchCallRecordingById";
import { CallCategory } from "@/company/jobs/types/job";

import EmptyNotification from "./EmptyNotification";
import ReferralNotification from "./ReferralNotification";
import ScheduledNotification from "./ScheduledNotification";

export enum TabsEnum {
  DRAFT = "DRAFT",
  ALL_HISTORY = "ALL_HISTORY",
}

export function JobCallModal() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { session } = useAuth();
  const company = session?.company;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { selectedRecordingId, findPartner } = useParams();

  const [currentFindPartner, setFindPartner] = useState(false);
  const [addJobOpen, setAddJobOpen] = useState(false);

  const { data: companyRecordingNotificationList } =
    useFetchCompanyCallRecordingNotificationList({
      refetchOnWindowFocus: false,
      enabled: !!session?.groups?.some(canSeeCompanyMessages),
    });

  const {
    isLoading,
    data: recordingById,
    refetch: refetchCall,
  } = useFetchCallRecordingById(selectedRecordingId, {
    enabled: !!selectedRecordingId,
  });

  const [selectedCallRecordingId, setSelectedCallRcordingId] = useState<
    null | number
  >(null);

  useEffect(() => {
    if (findPartner) {
      if (findPartner === "find-partner") {
        setFindPartner(true);
      } else if (findPartner === "add-job") {
        setAddJobOpen(true);
      }
    } else {
      setFindPartner(false);
      setAddJobOpen(false);
    }
  }, [findPartner]);

  useEffect(() => {
    if (recordingById) {
      setSelectedCallRcordingId(recordingById.id);
    }
  }, [recordingById]);

  return (
    <>
      <Seo title="Call Analytics Jobs Inbox" />
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"calc(100vh - 60px)"}
        pt={1}
      >
        <DashboardTitle
          title="Call Analytics"
          subtitle="Generate referrals right from your call analytics."
        />
        <Box mt={3}>
          {isLoading ? (
            <Box textAlign={"center"} width="100%" mt={5}>
              <ComponentLoader />
            </Box>
          ) : selectedCallRecordingId && recordingById ? (
            recordingById?.job ? (
              recordingById?.isThereAReferral ? (
                <ReferralNotification
                  findPartner={currentFindPartner}
                  hideCloseIcon={true}
                  notification={
                    companyRecordingNotificationList?.find(
                      notification =>
                        notification.callRecordingId === selectedCallRecordingId
                    ) as CallRecordingNotification
                  }
                  selectedRecordingId={selectedRecordingId}
                  setFindPartner={() => {
                    setFindPartner(false);
                  }}
                  refetchCalls={() => {
                    refetchCall();
                    setFindPartner(false);
                    setAddJobOpen(false);
                  }}
                  addJobOpen={addJobOpen}
                  setAddJobOpen={setAddJobOpen}
                  setTab={(tab: TabsEnum) => {
                    console.log({ tab });
                  }}
                  onClose={() => {
                    setFindPartner(false);
                  }}
                />
              ) : recordingById?.label ===
                CallCategory.HOMEOWNER_SCHEDULING_A_JOB ? (
                <ScheduledNotification
                  recording={recordingById}
                  selectedRecordingNotification={
                    companyRecordingNotificationList?.find(
                      notification =>
                        notification.callRecordingId === selectedCallRecordingId
                    ) as CallRecordingNotification
                  }
                  onClose={() => {
                    setSelectedCallRcordingId(null);
                  }}
                />
              ) : null
            ) : (
              <EmptyNotification
                selectedRecordingNotification={
                  companyRecordingNotificationList?.find(
                    notification =>
                      notification.callRecordingId === selectedCallRecordingId
                  ) as CallRecordingNotification
                }
                refetchCalls={() => {
                  refetchCall();
                }}
                selectedRecording={recordingById}
                onClose={() => {
                  setSelectedCallRcordingId(null);
                }}
              />
            )
          ) : null}
        </Box>
      </Box>
    </>
  );
}
