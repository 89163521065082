import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useDeleteReferralInvitations(
  options: UseMutationOptions<
    void,
    AxiosError,
    { referralInvitationIds: Array<number> }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { referralInvitationIds: Array<number> }
> {
  return useMutation({
    mutationFn: async ({ referralInvitationIds }) => {
      const uri = `/internal-admin/referral-invites`;
      await API.delete(uri, {
        data: { referral_invitation_ids: referralInvitationIds },
      });
    },
    ...options,
  });
}
