import constate from "constate";
import { useSnackbar } from "notistack";

import { useAuth } from "@/auth/context/jwt";
import { canSeeCompanyDashboard } from "@/common/utils/accessControl";

import { useCompanyBuyCredits } from "../api/useBuyCredits";
import { useCompanyCreateCard } from "../api/useCompanyCreateCard";
import { useGetCompanyCard } from "../api/useGetCard";
import { useGetCompanyTransactions } from "../api/useGetTransactions";

function useCompanyPayments() {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();

  const {
    isFetching: isFetchingCard,
    data: card,
    refetch: refetchCard,
  } = useGetCompanyCard({
    refetchOnWindowFocus: false,
    enabled: !!session?.groups?.some(canSeeCompanyDashboard),
    onError: () => {
      if (session?.groups?.some(canSeeCompanyDashboard))
        enqueueSnackbar("There was an error while fetching your card.", {
          variant: "error",
        });
    },
  });

  const {
    isFetching: isFetchingTransactions,
    data: transactions,
    refetch: refetchTransactions,
  } = useGetCompanyTransactions({
    enabled: !!session?.groups?.some(canSeeCompanyDashboard),
    refetchOnWindowFocus: false,
    onError: () => {
      if (session?.groups?.some(canSeeCompanyDashboard))
        enqueueSnackbar(
          "There was an error while fetching your transactions.",
          {
            variant: "error",
          }
        );
    },
  });

  const { mutateAsync: buyCredits, isLoading: isBuyingCredits } =
    useCompanyBuyCredits();

  const { mutateAsync: createCard, isLoading: isCreatingCard } =
    useCompanyCreateCard();

  return {
    card,
    transactions,
    isFetchingCard,
    isFetchingTransactions,
    refetchCard,
    refetchTransactions,
    buyCredits,
    isBuyingCredits,
    createCard,
    isCreatingCard,
  };
}

export const [CompanyPaymentsProvider, useCompanyPaymentsContext] =
  constate(useCompanyPayments);
