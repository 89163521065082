import {
  Box,
  Divider,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Fragment, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { PublicRoutes } from "@/open/constants";
import { neutral, tradeEngageColors } from "@/Theme/colors";

import { NavLink, SectionedNavLinks } from "../layouts/DashboardLayout";
import { TradeEngageLogo, TradeEngageLogoTypes } from "../Logo";
import { RouterLink } from "../router-link";
import { getNavigationPathFromRoutePaths } from "../utils/getNavigationPathFromRoutePaths";

const MOBILE_NAV_WIDTH = 280;

export interface MobileNavProps {
  primaryNavLinks: NavLink[];
  sectionedNavLinks: SectionedNavLinks[];
  isOpen: boolean;
  header?: ReactNode;
  closeMobileNav: () => void;
}

export function MobileNav(props: MobileNavProps): JSX.Element {
  const { signOut } = useAuth();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const navigate = useNavigate();
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  return (
    <Drawer
      anchor="left"
      open={props.isOpen}
      PaperProps={{
        sx: {
          width: MOBILE_NAV_WIDTH,
          background: tradeEngageColors.darkBlue,
        },
      }}
      variant="temporary"
      onClose={() => props.closeMobileNav()}
    >
      <Stack
        sx={{
          height: "100%",
          background: tradeEngageColors.darkBlue,
          ml: !isMobile ? 2 : 0,
        }}
      >
        {props.header ? props.header : null}
        <Box
          component="nav"
          display="flex"
          flexDirection="column"
          sx={{
            flexGrow: 1,
            pl: !isMobile ? 0 : 1,
          }}
        >
          {props.primaryNavLinks.map(navLink => (
            <ListItemButton
              key={navLink.link}
              {...(navLink.link !==
              getNavigationPathFromRoutePaths([
                PublicRoutes.BASE,
                PublicRoutes.CONTACT_SUPPORT,
              ])
                ? {
                    component: RouterLink,
                    href: navLink.link,
                    onClick: () => {
                      props.closeMobileNav();
                    },
                  }
                : {
                    onClick: () => {
                      setContactSupportModalOpen(true);
                      props.closeMobileNav();
                    },
                  })}
              sx={{
                borderRadius: 1,
                px: 1,
                py: 0.5,
                flexGrow: 0,
                ml: !isMobile ? 0 : 2,
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    navLink.link === pathname ? tradeEngageColors.green : "",
                }}
              >
                <SvgIcon fontSize="small">{navLink.icon}</SvgIcon>
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body1"
                  color={navLink.link === pathname ? neutral[50] : neutral[500]}
                >
                  {navLink.label}
                </Typography>
              </ListItemText>
              <Box pr={1}>{navLink.endIcon}</Box>
            </ListItemButton>
          ))}
          {props.sectionedNavLinks &&
            props.sectionedNavLinks.map(({ sectionName, navLinks }) => (
              <Fragment key={`nav_section_${sectionName}`}>
                <ListSubheader
                  sx={{ background: tradeEngageColors.darkBlue, pl: 1 }}
                >
                  <Typography variant="overline" color={neutral[500]}>
                    {sectionName}
                  </Typography>
                </ListSubheader>
                {navLinks.map(navLink => (
                  <ListItemButton
                    key={navLink.link}
                    {...(navLink.link !==
                    getNavigationPathFromRoutePaths([
                      PublicRoutes.BASE,
                      PublicRoutes.CONTACT_SUPPORT,
                    ])
                      ? {
                          component: RouterLink,
                          href: navLink.link,
                          onClick: () => {
                            props.closeMobileNav();
                          },
                        }
                      : {
                          onClick: () => {
                            setContactSupportModalOpen(true);
                            props.closeMobileNav();
                          },
                        })}
                    sx={{
                      borderRadius: 1,
                      px: 1,
                      py: 0.5,
                      flexGrow: 0,
                      ml: 2,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          navLink.link === pathname
                            ? tradeEngageColors.green
                            : "",
                      }}
                    >
                      <SvgIcon fontSize="small">{navLink.icon}</SvgIcon>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="body1"
                        color={
                          navLink.link === pathname ? neutral[50] : neutral[500]
                        }
                      >
                        {navLink.label}
                      </Typography>
                    </ListItemText>
                    <Box pr={1}>{navLink.endIcon}</Box>
                  </ListItemButton>
                ))}
              </Fragment>
            ))}
        </Box>
        <Divider sx={{ ml: -2, borderColor: neutral[500] }} />
        <Box
          sx={{
            p: 3,
            ml: -2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TradeEngageLogo
            style={{ width: "120px", height: "20px" }}
            logotype={TradeEngageLogoTypes.LOGO_LIGHT}
          />
        </Box>
      </Stack>
    </Drawer>
  );
}
