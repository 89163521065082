import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useCreateCompanyRelationships(
  options: UseMutationOptions<
    void,
    AxiosError,
    { parentCompanyId: number; childrenCompanyIds: Array<number> }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { parentCompanyId: number; childrenCompanyIds: Array<number> }
> {
  return useMutation({
    mutationFn: async ({ parentCompanyId, childrenCompanyIds }) => {
      const updateCompanyUri = `/internal-admin/relationships`;
      await API.post(updateCompanyUri, {
        parent_company_id: parentCompanyId,
        children_companies_ids: childrenCompanyIds,
      });
    },
    ...options,
  });
}
