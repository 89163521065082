import { Delete } from "@mui/icons-material";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from "@mui/icons-material/Send";
import { Box, Chip, Drawer, Tooltip } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
} from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { formatDateTime } from "@/analytics/components/JobsTable";
import API from "@/common/api";
import { AppConstants } from "@/common/constants";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useReRouteJob } from "@/company/api/useReRouteJob";
import { AdminChatProvider } from "@/company/context/admin-chat-context";
import { Job, JobStatus } from "@/company/jobs/types";
import { ProjectJobStatusChipProps } from "@/company/jobs/utils";
import { ConnectionStatus } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { useDeleteJob } from "./api/useDeleteJob";
import ConfirmationDialog from "./ConfirmationDialog";
import { InternalAdminCompanyDrawer } from "./InternalAdminCompanyDrawer";
import CompanySelectionDialog from "./jobs/CompanySelectionDialog";
import ReRouteJobDialog from "./ReRouteJobDialog";
import {
  CustomToolbar,
  drawerWidth,
  getGridStringOperatorsDataGrid,
} from "./utils";

export default function JobSentTable({
  jobsSent,
  companyId,
  setCurrentSelectedJob,
  expanded,
  refetch,
  invitationCode,
}: {
  jobsSent: any;
  companyId: number;
  expanded?: boolean;
  setCurrentSelectedJob: () => void;
  refetch: () => void;
  invitationCode?: string;
}) {
  const [rows, setRows] = useState([]);
  const [chosenJob, setChosenJob] = useState<Job | undefined>(undefined);
  const [jobInvitationId, setJobInvitationId] = useState<number | null>();
  const [deleteJobId, setDeleteJobId] = useState<number | undefined>();
  const [companyProfileId, setCompanyProfileId] = useState<number | null>();

  useEffect(() => {
    setRows(jobsSent);
  }, [jobsSent]);

  let columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
      renderCell: params => params.row.id?.toString(),
    },

    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: params => {
        if (params.row.status === JobStatus.NO_COMPANY_TO_ROUTE) {
          return (
            <Chip
              label="NO COMPANY TO ROUTE"
              sx={{
                bgcolor: "rgba(240, 68, 56, 0.12)",
                color: "#B42318",
              }}
            />
          );
        }
        return (
          <Chip
            label={ProjectJobStatusChipProps[params.row.status].label}
            sx={{
              bgcolor: ProjectJobStatusChipProps[params.row.status].bgcolor,
              color: ProjectJobStatusChipProps[params.row.status].color,
              fontSize: "12px",
              fontWeight: 600,
              height: "24px",
            }}
          />
        );
      },
    },
    {
      field: "jobType",
      headerName: "Job Type",
      width: 200,
    },
    {
      field: "sector",
      headerName: "Sector",
      width: 200,
      renderCell: params => {
        return (
          <Chip
            key={params.row?.sector}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={snakeCaseJobSectors[params.row?.sector]}
            size="small"
          />
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "string",
      width: 200,
      valueGetter: (value, row) => formatDateTime(row?.createdAt),
      renderCell: params => formatDateTime(params.row?.createdAt),
    },
    {
      field: "listingExtraInfo-technicianName",
      headerName: "Technician name",
      type: "string",
      width: 200,
      valueGetter: (value, row) => row.listingExtraInfo?.technicianName,
      renderCell: params => params.row?.listingExtraInfo?.technicianName,
    },
    {
      field: "listingExtraInfo-routedCompanyName",
      headerName: "Routed Company",
      type: "string",
      width: 200,
      valueGetter: (value, row) => row.listingExtraInfo?.routedToCompanyName,
      renderCell: params => params.row?.listingExtraInfo?.routedToCompanyName,
    },

    {
      field: "listingExtraInfo-buyingCompanyName",
      headerName: "Buying Company",
      type: "string",
      width: 200,
      valueGetter: (value, row) => row.listingExtraInfo?.buyingCompanyName,
      renderCell: params => params.row?.listingExtraInfo?.buyingCompanyName,
    },
    {
      field: "jobBuyingCost",
      headerName: "Referral Fee",
      type: "string",
      width: 200,
      renderCell: params =>
        params.row?.jobBuyingCost ? `$${params.row?.jobBuyingCost}` : "",
    },
    {
      field: "technicianFee",
      headerName: "Technician Fee",
      type: "string",
      width: 200,
      renderCell: params =>
        params.row?.technicianFee ? `$${params.row?.technicianFee}` : "",
    },
    {
      field: "salePrice",
      headerName: "Sale Price",
      type: "string",
      width: 200,
      renderCell: params =>
        params.row?.salePrice ? `$${params.row?.salePrice}` : "",
    },
    {
      field: "routeToHomeowner",
      headerName: "Route to Homeowner",
      type: "string",
      width: 200,
      renderCell: params =>
        params.row?.listingExtraInfo?.routeToHomeowner ? "Yes" : "No",
    },
    {
      field: "payReferralFeeSettings",
      headerName: "Referral Fee Job's Settings",
      type: "string",
      width: 200,
      renderCell: params =>
        params.row?.payReferralFeeSettings?.replaceAll("_", " "),
    },
    {
      field: "publicJobId",
      headerName: "Public Job ID",
      type: "string",
      renderCell: params => params.row.publicJobId?.toString(),

      width: 200,
      valueGetter: (value, row) => row.publicId,
    },
    {
      field: "publicJobLink",
      headerName: "Public Job Link",
      type: "string",
      width: 200,
      valueGetter: (value, row) =>
        `${window.location.origin}/open/job/${row?.publicId}`,
      renderCell: params =>
        `${window.location.origin}/open/job/${params.row?.publicId}`,
    },
    {
      field: "jobLandingPageInvitationLink",
      headerName: "Job Landing Page Invitation Link",
      type: "string",
      width: 200,
      valueGetter: (value, row) =>
        `${window.location.origin}?jobId=${row?.publicId}&invitationCode=${invitationCode}`,
      renderCell: params =>
        `${window.location.origin}?jobId=${params.row?.publicId}&invitationCode=${invitationCode}`,
    },
    {
      field: "submissionTime",
      headerName: "Job Submission Time",
      type: "string",
      width: 200,
      valueGetter: (value, row) =>
        formatDateTime(row?.listingExtraInfo?.jobSubmittedAt),
      renderCell: params =>
        formatDateTime(params.row?.listingExtraInfo?.jobSubmittedAt),
    },
    {
      field: "secondsBetweenCreationAndSubmission",
      headerName: "Seconds Between Job Creation and Submission",
      type: "number",
      width: 200,
      valueGetter: (value, row) =>
        row.listingExtraInfo?.secondsBetweenCreationAndSubmission,
      renderCell: params =>
        params.row.listingExtraInfo?.secondsBetweenCreationAndSubmission,
    },
    {
      field: "jobVideoAudioTextFinishedProcessingAt",
      headerName: "Job Video, Audio, Text Finished Processing At",
      type: "string",
      width: 200,
      valueGetter: (value, row) =>
        formatDateTime(
          row?.listingExtraInfo?.jobVideoAudioTextFinishedProcessingAt
        ),
      renderCell: params =>
        formatDateTime(
          params.row?.listingExtraInfo?.jobVideoAudioTextFinishedProcessingAt
        ),
    },
    {
      field: "secondsBetweenCreationAndVideoAudioTextFinishedProcessing",
      headerName:
        "Seconds Between Job Creation and Video, Audio, Text Finished Processing",
      type: "number",
      width: 200,
      valueGetter: (value, row) =>
        row.listingExtraInfo?.secondsBetweenCreationAndVideoAudioTextProcessing,
      renderCell: params =>
        params.row.listingExtraInfo
          ?.secondsBetweenCreationAndVideoAudioTextProcessing,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <Tooltip
            key={`${id}-send-invite-to-company-tooltip`}
            title="Click to invite companies to job"
          >
            <GridActionsCellItem
              key={`${id}-send-invite-to-company`}
              disabled={
                ![JobStatus.NO_COMPANY_TO_ROUTE, JobStatus.CLOSED].includes(
                  row.status
                )
              }
              label="send-invite-to-company"
              color="inherit"
              icon={<SendIcon />}
              onClick={() => {
                setJobInvitationId(row.id);
              }}
            />
          </Tooltip>,
          <Tooltip
            key={`${id}-re-route-tooltip`}
            title="Click to re-route the job"
          >
            <GridActionsCellItem
              key={`${id}-re-route`}
              disabled={
                ![
                  JobStatus.NO_COMPANY_TO_ROUTE,
                  JobStatus.CLOSED,
                  JobStatus.HOMEOWNER_ACCEPTED,
                  JobStatus.HOMEOWNER_DELAYED,
                ].includes(row.status)
              }
              label="Re-route"
              color="inherit"
              icon={<ReplayIcon />}
              onClick={() => {
                setChosenJob(row);
              }}
            />
          </Tooltip>,
          <Tooltip key={`${id}-delete-tooltip`} title="Click to delete the job">
            <GridActionsCellItem
              key={`${id}-delete`}
              disabled={
                AppConstants.environment === "production" &&
                ![JobStatus.NO_COMPANY_TO_ROUTE, JobStatus.CLOSED].includes(
                  row.status
                )
              }
              label="Delete"
              color="inherit"
              icon={<Delete />}
              onClick={() => {
                setDeleteJobId(row.id);
              }}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  const { isLoading: isDeletingJob, mutateAsync: deleteJob } = useDeleteJob({
    onSuccess: () => {
      refetch();
      setDeleteJobId(undefined);
      enqueueSnackbar({
        message: "Job deleted successfully",
        variant: "success",
      });
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while deleting the job";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (
      params.field === "jobType" &&
      jobsSent.some(job => job.id === params.row.id)
    )
      setCurrentSelectedJob(jobsSent.find(job => job.id === params.row.id));

    if (
      params.field === "jobLandingPageInvitationLink" ||
      params.field === "publicJobLink" ||
      params.field === "publicJobId"
    ) {
      navigator.clipboard.writeText(params.value as string);
    }
    if (params.field === "listingExtraInfo-buyingCompanyName")
      setCompanyProfileId(params.row.job?.listingExtraInfo?.buyingCompanyId);
  };

  const { isLoading: _, mutateAsync: reRouteJobAPI } = useReRouteJob({
    onSuccess: () => {
      enqueueSnackbar({
        message: "Job re-routed successfully",
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar({
        message: `There was an error while re-routing the job`,
        variant: "error",
      });
    },
  });

  const reRouteJob = (companyId: number) => {
    if (chosenJob) {
      const jobId = chosenJob.id;
      reRouteJobAPI({ jobId, receivingCompanyId: companyId }).then(() => {
        refetch();
      });
    }
    setChosenJob(undefined);
  };

  const handleDownload = async (jobId: number) => {
    try {
      const response = await API.get(
        `/api/internal-admin/jobs/${jobId}/resource-url`
      ); // Replace with your API endpoint
      const videoUrl = response.data.videoUrl; // Adjust according to the API response

      // Create an anchor element and trigger the download
      const link = document.createElement("a");
      link.href = videoUrl;
      link.download = "video.mp4"; // You can dynamically set the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the video:", error);
    }
  };

  return (
    <Box display={"flex"} width={"100%"}>
      <Box
        sx={{
          height: 400,
          position: "relative",
          width: "100%",
        }}
      >
        {chosenJob ? (
          <ReRouteJobDialog
            open={chosenJob ? true : false}
            job={chosenJob}
            handleConfirm={(companyId: number) => {
              reRouteJob(companyId);
            }}
            onClose={() => {
              setChosenJob(undefined);
            }}
          />
        ) : null}
        <DataGridPremium
          checkboxSelection
          disableRowSelectionOnClick
          pagination
          columns={columns}
          sx={{
            "& .MuiDataGrid-row": {
              "--rowBorderColor": "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withRightBorder": {
              borderRightColor: "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withLeftBorder": {
              borderLeftColor: "#D9D9D9",
            },
          }}
          rows={rows ?? []}
          slots={{
            ...(expanded
              ? {
                  toolbar: () => (
                    <CustomToolbar
                      fileName="Jobs_Sent"
                      includeQuickFilter={false}
                    />
                  ),
                }
              : {}),
          }}
          editMode="row"
          initialState={{
            density: "compact",
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "jobType"],
              right: ["actions"],
            },
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          onCellClick={handleCellClick}
        />
      </Box>
      {jobInvitationId ? (
        <CompanySelectionDialog
          isOpen={!!jobInvitationId}
          jobId={jobInvitationId}
          onConfirm={() => {
            setJobInvitationId(undefined);
          }}
          onClose={() => {
            setJobInvitationId(undefined);
          }}
        />
      ) : null}
      {deleteJobId ? (
        <ConfirmationDialog
          open={!!deleteJobId}
          handleConfirm={() => {
            deleteJob(deleteJobId);
          }}
          handleClose={() => {
            setDeleteJobId(undefined);
          }}
          text={`Are you sure you want to delete the job ${deleteJobId}?`}
          title="Confirm Delete"
          confirmText="Delete"
        />
      ) : null}
      {companyProfileId ? (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!companyProfileId}
        >
          {companyProfileId ? (
            <AdminChatProvider companyId={companyProfileId}>
              <InternalAdminCompanyDrawer
                open={!!companyProfileId}
                expanded={expanded}
                companyProfileId={companyProfileId}
                connectionStatus={ConnectionStatus.CONNECTED}
                onClose={() => {
                  setCompanyProfileId(null);
                }}
              />
            </AdminChatProvider>
          ) : null}
        </Drawer>
      ) : null}
    </Box>
  );
}
