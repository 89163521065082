import { Avatar } from "@mui/material";
import { FC, useState } from "react";

import { ToolTip } from "@/common/tool-tip/ToolTip";

export interface CompanyAvatarProps {
  logo?: string;
  name: string;
  height?: string;
  width?: string;
  maxWidth?: string;
  bgColor?: string;
  border?: string;
  borderRadius?: string;
  isAdminProfile?: boolean;
}

export const CompanyAvatar: FC<CompanyAvatarProps> = ({
  logo,
  name,
  height,
  width,
  maxWidth,
  bgColor,
  borderRadius,
  border,
  isAdminProfile,
}) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false);
  if (logo && !isImageLoadError) {
    return (
      <ToolTip message={name}>
        <img
          src={logo}
          alt={"company logo"}
          style={{
            ...(height ? { height } : { height: "auto" }),
            objectFit: "contain",
            ...(width ? { width } : {}),
            ...(maxWidth ? { maxWidth } : {}),
            ...(bgColor ? { background: bgColor } : {}),
            ...(border ? { border } : {}),
            ...(borderRadius ? { borderRadius } : {}),
          }}
          onError={() => setIsImageLoadError(true)}
        />
      </ToolTip>
    );
  }
  const firstInitial = (name?.split(" ")?.[0]?.[0] ?? "").toUpperCase();
  const secondInitial = (name?.split(" ")?.[1]?.[0] ?? "").toUpperCase();

  return (
    <ToolTip message={name}>
      <Avatar
        alt={`${firstInitial}${secondInitial}`}
        sx={{
          ...(bgColor ? { bgColor } : {}),
          ...(border ? { border } : {}),
          ...(height ? { height } : {}),
          ...(width ? { width } : {}),
          ...(isAdminProfile ? { width: "32px" } : {}),
          ...(borderRadius ? { borderRadius } : {}),
        }}
      >
        {`${firstInitial}${secondInitial}` ?? ""}
      </Avatar>
    </ToolTip>
  );
};
