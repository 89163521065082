import { Search as SearchIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Theme, useMediaQuery } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { JobSectors } from "@/company/jobs/constants";
import { snakeCaseJobSectors } from "@/company/utils";
import { AutocompleteField } from "@/lib/form-fields/AutocompleteField";
import { TextInputField } from "@/lib/form-fields/TextInputField";

export const searchJobPromotionsFormSchema = yup.object().shape({
  searchWord: yup.string(),
  sectors: yup.array().of(yup.mixed<JobSectors>().required()),
});

export type SearchJobPromotionsFormFields = yup.InferType<
  typeof searchJobPromotionsFormSchema
>;

interface JobPromotionsSearchFilterFormProps {
  formMethods: ReturnType<typeof useFormik<SearchJobPromotionsFormFields>>;
  isLoading: boolean;
}

export function JobPromotionsSearchFilterForm(
  props: JobPromotionsSearchFilterFormProps
) {
  const { formMethods, isLoading } = props;

  const { session } = useAuth();
  const isUserIsAdminOrOwner = session?.groups?.some(isAdministratorOrOwner);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const jobSectorOptions = useMemo(
    () =>
      Object.values(JobSectors).map(sector => {
        return { label: snakeCaseJobSectors[sector], value: sector };
      }),
    []
  );

  return (
    <Box component="form" onSubmit={formMethods.handleSubmit}>
      {isUserIsAdminOrOwner ? (
        <Stack direction="row" gap={2}>
          <TextInputField
            fullWidth
            inputProps={{
              style: {
                paddingTop: "8px",
                paddingBottom: "8px",
              },
            }}
            placeholder="Search by company name or keyword"
            name="searchWord"
          />

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading || formMethods.isSubmitting}
            startIcon={<SearchIcon />}
            sx={{ ".MuiButton-startIcon": { marginX: "0" } }}
          >
            {isMobile ? "" : "Search"}
          </LoadingButton>
        </Stack>
      ) : (
        <AutocompleteField
          multiple
          name={"sectors"}
          options={jobSectorOptions}
          label="Select Job Service Category"
        />
      )}
    </Box>
  );
}
