import { Avatar, Box, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useQueryAuthenticateCompanies } from "@/company/api/useQueryAuthenticateCompanies";
import { CompanySelectionDialog } from "@/open/components/screens/company-identification/CompanySelectionDialog";
import { neutral } from "@/Theme/colors";
import { getInitials } from "@/utils";

export interface CompanySideNavHeaderProps {
  companyName: string;
  userRoleLabel: string;
  companyLogoUrl?: string;
}
export function CompanySideNavHeader(props: CompanySideNavHeaderProps) {
  const { isFetching: fetchingCompaniesWithGroup, data: companiesWithGroup } =
    useQueryAuthenticateCompanies();
  const navigate = useNavigate();

  const [companySelectionDialogOpen, setCompanySelectionDialogOpen] =
    useState(false);
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      gap={1}
      alignItems="flex-start"
      py={3}
      sx={{ ml: { xs: 2, md: 0 } }}
    >
      {companiesWithGroup && companiesWithGroup?.length > 1 ? (
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: "#16B364",
            },
          }}
          onClick={() => {
            setCompanySelectionDialogOpen(true);
          }}
        >
          Change Workspace
        </Typography>
      ) : null}
      <Box display="flex" alignItems="flex-start" sx={{ ml: { xs: 2, md: 0 } }}>
        <Avatar
          alt={props.companyName}
          src={props.companyLogoUrl}
          variant="rounded"
          sx={{
            width: "40px",
            height: "40px",
            "& img": {
              objectFit: "contain",
            },
          }}
        >
          {getInitials(props.companyName ?? "")}
        </Avatar>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          ml={3}
          mr={2}
          maxWidth={"272px"}
        >
          <Typography gutterBottom variant="h6" color={neutral[50]}>
            {props.companyName}
          </Typography>
          <Typography gutterBottom variant="caption" color={neutral[400]}>
            {props.userRoleLabel}
          </Typography>
        </Box>
      </Box>
      <CompanySelectionDialog
        isOpen={companySelectionDialogOpen}
        onClose={() => {
          setCompanySelectionDialogOpen(false);
        }}
      />
    </Box>
  );
}
