import * as Sentry from "@sentry/react";

import API from "@/common/api";
import { CountryCodes } from "@/common/constants/CountryCodes";

import {
  RequestLoginOtpRequest,
  Session,
  SignInRequest,
  SignInResponse,
  SignUpRequest,
} from "../types";

class AuthApi {
  async signIn(request: SignInRequest): Promise<SignInResponse> {
    const { cellPhone, email, otp } = request;

    const uri = "/auth/v2/sign-in";
    const response = await API.post(uri, {
      otp,
      email,
      cell_phone: cellPhone,
    });
    return response.data.data;
  }

  async signInToCompany(companyId: number): Promise<SignInResponse> {
    const response = await API.post(
      `auth/authenticate/user/companies/${companyId}`
    );
    return response.data.data;
  }

  async signUp(request: SignUpRequest): Promise<void> {
    const {
      user,
      brandLocationId,
      invitationCode,
      campaignCode,
      jobIdToLink,
      utmParams,
      initialJobRoutedId,
      company,
      publicJobId,
      jobInvitationId,
    } = request;

    const {
      firstName: first_name,
      lastName: last_name,
      cellPhone: cell_phone,
      email,
      jobTitle: job_title,
    } = user;
    const uri = "/auth/v2/sign-up";
    await API.post(uri, {
      invitation_code: invitationCode ?? undefined,
      campaign_code: campaignCode ?? undefined,
      initial_job_routed_id: initialJobRoutedId ?? undefined,
      job_id_to_link: jobIdToLink ?? undefined,
      joined_by_job_invitation_id: jobInvitationId ?? undefined,
      brand_location_id: brandLocationId,
      ...(publicJobId ? { public_job_id: publicJobId } : {}),
      ...(company
        ? {
            company: {
              name: company.name,
              zip_code: company.zipCode,
              sectors: company.sectors,
              company_account_type: company.accountType,
              google_places_id: company.googlePlacesId,
            },
          }
        : {}),
      user: {
        first_name,
        last_name,
        cell_phone: CountryCodes.US + cell_phone,
        email,
        job_title,
        type: user.type,
        language: user.preferredLanguage,
        signup_utm_campaign: utmParams?.campaign,
        signup_utm_source: utmParams?.source,
        signup_utm_medium: utmParams?.medium,
        acceptedTermsAndConditions: user.acceptedTermsAndConditions,
        acceptedWhatsappMessagingTerms: user.acceptedWhatsappMessagingTerms,
      },
    });
  }

  async authenticateUser(): Promise<Session> {
    const uri = "/auth/authenticate";
    const response = await API.get(uri);
    const {
      user,
      company,
      companyAddress,
      companySectors,
      groups,
      brandLocationProfile,
      brand,
      familyOfBrands,
      campaign,
      privateEquity,
      consultants,
    } = response.data.data;
    const session: Session = {
      user,
      company,
      companyAddress,
      companySectors,
      groups,
      brandLocationProfile,
      brand,
      familyOfBrands,
      campaign,
      privateEquity,
      consultants,
    };
    try {
      Sentry.setUser({
        id: user?.id, // Unique user ID
        firstName: user?.firsName, // Username
        lastName: user?.lastName, // Username
        companyId: company?.id,
      });
    } catch (error) {
      console.error("Error while setting up user on sentry.", error);
    }
    return session;
  }

  async requestLoginOTP(request: RequestLoginOtpRequest): Promise<void> {
    const { cell_phone, email } = request;
    const uri = "/auth/request-login";
    await API.post(uri, {
      cell_phone,
      email,
    });
  }
}

export const authApi = new AuthApi();
