import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import { FC } from "react";

import { useAuth } from "@/auth/context/jwt";
import { AppConstants } from "@/common/constants";

import { Job } from "../types";

export enum JOB_SHARE_SOURCE {
  HOMEOWNER = "HOMEOWNER",
  TECHNICIAN = "TECHNICIAN",
}

export const JobNetworkInvite: FC<{ job: Job; source?: JOB_SHARE_SOURCE }> = ({
  job,
  source,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const user = session?.user;
  const company = session?.company;

  const link = `${AppConstants.platformBaseUrl}/open/job/${job.publicId}`;

  let messageBodyEmail = `Hi,

I’d like to share my home services project with you. Click the link below to see the details and watch a video of the project. 

${link}?utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=brand_location_job_share&utm_medium=email
      
Want to manage your own home service projects on TradeEngage? Sign up here: ${AppConstants.platformBaseUrl}/auth/signup?utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=brand_location_job_submit_request_link_share&utm_medium=email.
      
Thank you,
${user?.firstName}`;

  if (source === JOB_SHARE_SOURCE.HOMEOWNER) {
    messageBodyEmail = `Hi,

I’d like to share my home services project with you. Click the link below to see the details and watch a video of the project. 

${link}?utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=homeowner_job_share&utm_medium=email
  
Want to manage your own home service projects on TradeEngage? Sign up here: ${AppConstants.platformBaseUrl}/auth/signup?utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=homeowner_job_submit_request_link_share&utm_medium=email.
  
Thank you,
${user?.firstName}`;
  }

  if (source === JOB_SHARE_SOURCE.TECHNICIAN) {
    messageBodyEmail = `👋 Hi. This is ${user?.firstName} at ${company?.name}. I spotted the need to ${job.jobType} at your home. Tap the link to watch a video of the project ▶️ ${link}?utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=technician_job_share&utm_medium=email`;
  }

  let messageBodySms = `Hi, Take a look at this home services project on TradeEngage. Click the link below to see the details and watch a video of the project.
${link}`;

  if (source === JOB_SHARE_SOURCE.TECHNICIAN) {
    messageBodySms = `👋 Hi. This is ${user?.firstName} at ${company?.name}. I spotted the need to ${job.jobType} at your home. Tap the link to watch a video of the project ▶️ ${link}`;
  }

  if (source === JOB_SHARE_SOURCE.HOMEOWNER) {
    messageBodySms = `👋I’d like to share my home services project with you. Tap the link to see the details and watch a video 🎥 of the project. ➡️${link}`;
  }
  const handleMailClick = () => {
    const emailSubject = encodeURIComponent(
      source === JOB_SHARE_SOURCE.TECHNICIAN
        ? "Job for you!"
        : "View my Home Services Project on TradeEngage"
    );
    const emailBody = encodeURIComponent(messageBodyEmail);
    const mailtoLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
    window.location.href = mailtoLink;
  };

  const handleSMSClick = () => {
    const body = encodeURIComponent(messageBodySms);
    if (navigator.userAgent.match(/Android/i)) {
      window.open(`sms:?&body=${body}`, "_blank");
    }
    if (navigator.userAgent.match(/iPhone/i)) {
      window.open(`sms:?&body=${body}`, "_blank"); // TODO: Add sms format for iOS
    }
    window.open(`sms:?&body=${body}`, "_blank");
  };
  return (
    <Box
      py={3}
      display={"flex"}
      flexDirection={"column"}
      gap={3}
      sx={{ height: "356.50px" }}
    >
      <Box display={"flex"} justifyContent={"center"} gap={4} py={3}>
        <Box textAlign={"center"}>
          <IconButton
            color="primary"
            sx={{ background: "#F3F4F6" }}
            onClick={handleSMSClick}
          >
            <MessageOutlinedIcon />
          </IconButton>
          <Typography color="text.secondary" variant="body2">
            Text Message
          </Typography>
        </Box>
        <Box textAlign={"center"}>
          <IconButton
            color="primary"
            sx={{ background: "#F3F4F6" }}
            onClick={handleMailClick}
          >
            <MailOutlineOutlinedIcon />
          </IconButton>
          <Typography color="text.secondary" variant="body2">
            Email
          </Typography>
        </Box>
      </Box>
      <Divider>
        <Typography display={"inline"} variant="body2" color={"text.disabled"}>
          Copy Link
        </Typography>
      </Divider>
      <Box
        display={"flex"}
        width={"100%"}
        sx={{
          gap: 2,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          disabled
          fullWidth
          hiddenLabel
          size="small"
          sx={{
            bgcolor: "neutral.100",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InsertLinkIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: false }}
          name="link"
          value={link}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ width: "fit-content" }}
          startIcon={<ContentCopyOutlinedIcon />}
          onClick={() => {
            copy(link);
            enqueueSnackbar({
              message: "The link was copied!",
              variant: "success",
            });
          }}
        >
          Copy Link
        </Button>
      </Box>
    </Box>
  );
};
