import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { JobSectors } from "../jobs/constants";
import { COMPANY_ACCOUNT_TYPES } from "../types";

export interface BasicCompanySchema {
  id: number;
  name: string;
  logoUrl?: string;
  sectors?: Array<JobSectors>;
  accountType?: COMPANY_ACCOUNT_TYPES;
}

export function useGetAllBrands(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<BasicCompanySchema>> {
  return useQuery({
    queryKey: ["/internal-admin/companies/brands"],
    queryFn: async () => {
      const response = await API.get("/internal-admin/companies/brands");
      return response.data.data;
    },
    ...options,
  });
}
