import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";
import _ from "lodash";

import API from "@/common/api";

export function useUpdateSectors(
  options: UseMutationOptions<
    void,
    AxiosError,
    Array<{ sectorId: number; buyingPrice?: number; percentagePrice?: number }>
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  Array<{ sectorId: number; buyingPrice?: number; percentagePrice?: number }>
> {
  return useMutation({
    mutationFn: async sectorsPayload => {
      const payload = changeCaseKeys(
        _.cloneDeep(sectorsPayload),
        "underscored"
      );

      await API.patch(`companies/sectors`, { sectors: payload });
    },
    ...options,
  });
}
