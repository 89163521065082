import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { FC, SyntheticEvent, useState } from "react";

import { JobSectors } from "@/company/jobs/constants";
import { JobStatus } from "@/company/jobs/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { TechnicianSentJobStatusFilterChipLabel } from "../../TechnicianJobCard/utils";

export interface TechnicianJobFilters {
  dateRange?: { startDate?: DateTime; endDate?: DateTime };
  allSectors?: Array<JobSectors>;
  status?: JobStatus;
}

interface TechnicianJobFilterDrawerProps {
  onClose?: () => void;
  applyFilters: (filters: TechnicianJobFilters) => void;
  resetFilters: () => void;
  drawerFilters: TechnicianJobFilters;
  allSectors?: Array<JobSectors>;
  open?: boolean;
}

export const TechnicianJobFilterDrawer: FC<
  TechnicianJobFilterDrawerProps
> = props => {
  const {
    onClose,
    applyFilters,
    resetFilters,
    open = false,
    drawerFilters,
    allSectors,
    ...other
  } = props;

  const [startDate, setStartDate] = useState<DateTime | null>(
    drawerFilters.dateRange?.startDate
      ? drawerFilters.dateRange.startDate
      : null
  );

  const [endDate, setEndDate] = useState<DateTime | null>(
    drawerFilters.dateRange?.endDate ? drawerFilters.dateRange.endDate : null
  );

  const [minEndDate, setMinEndDate] = useState<DateTime | null>(null);

  const handleStartDateChange = (date: DateTime | null) => {
    setStartDate(date);
    if (date) {
      if (endDate && date > endDate) {
        setEndDate(date);
      }
      setMinEndDate(date);
    }
  };
  const [jobStatus, setJobStatus] = useState<JobStatus | null>(
    drawerFilters?.status ?? null
  );

  const [sectors, setSectors] = useState<Array<JobSectors>>([
    ...(drawerFilters.sectors ? drawerFilters.sectors : []),
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setJobStatus((event.target as HTMLInputElement).value as JobStatus);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
          height: "100vh",
          maxWidth: 500,
          px: 3,
          overflow: "auto",
        },
      }}
      onClose={onClose}
      {...other}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        position={"sticky"}
        sx={{ top: 0, pt: 4, zIndex: 400, background: "white" }}
      >
        <Typography variant="h6">Filters</Typography>
        <IconButton
          aria-label="close"
          sx={{
            color: theme => theme.palette.grey[500],
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={3} flex={"1 0 0"}>
        <Autocomplete
          multiple
          id="sectors"
          options={(allSectors ?? Object.keys(JobSectors)).map(sector => {
            return snakeCaseJobSectors[sector.toString() as JobSectors];
          })}
          value={sectors ?? []}
          getOptionLabel={option => option}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              value={sectors ?? []}
              margin="normal"
              InputLabelProps={{
                style: { marginBottom: "8px" },
              }}
              label="Select Service Categories"
            />
          )}
          renderOption={(props, option, { selected }) => (
            <MenuItem {...props} key={option} value={option}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                {<Typography>{option}</Typography>}
                {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
              </Box>
            </MenuItem>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                size="small"
                label={option}
                {...getTagProps({ index })}
                variant="outlined"
              />
            ))
          }
          onChange={(_: SyntheticEvent, newValue: string[] | null) =>
            setSectors((newValue ?? []) as JobSectors[])
          }
        />
        <Box>
          <Typography variant="overline" color={"text.secondary"}>
            Date Range
          </Typography>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Box display={"flex"} gap={2}>
              <DatePicker
                label="From"
                value={startDate}
                onChange={newValue => handleStartDateChange(newValue)}
              />
              <DatePicker
                label="To"
                value={endDate}
                {...(minEndDate ? { minDate: minEndDate } : {})}
                onChange={newValue => setEndDate(newValue)}
              />
            </Box>
          </LocalizationProvider>
        </Box>
        <Box>
          <Typography variant="overline" color={"text.secondary"}>
            status
          </Typography>
          <Box>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={jobStatus}
                onChange={handleChange}
              >
                {Object.keys(TechnicianSentJobStatusFilterChipLabel).map(
                  status => {
                    return (
                      <FormControlLabel
                        key={status}
                        value={status}
                        control={<Radio />}
                        label={TechnicianSentJobStatusFilterChipLabel[status]}
                      />
                    );
                  }
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box position={"sticky"} bottom={0} sx={{ bgcolor: "white" }}>
        <Divider />
        <Stack spacing={2} py={2} direction={"row"}>
          <Button
            variant="contained"
            onClick={() => {
              applyFilters({
                ...drawerFilters,
                ...(startDate && endDate
                  ? { dateRange: { startDate, endDate } }
                  : {}),
                ...(jobStatus ? { status: jobStatus } : {}),
                ...(sectors ? { sectors } : {}),
              });
            }}
          >
            Apply Filters
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              resetFilters();
            }}
          >
            Reset Filters
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};
