import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  Card,
  CardHeader,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { JobDrawerTabsEnum } from "@/company/jobs/components/JobDrawer";
import { RoutedJobCard } from "@/company/jobs/components/RoutedJobCard";
import { RoutedJobResponse } from "@/company/jobs/types/job";
import { CompanyRoutes } from "@/company/types";

export function PendingJobs({
  routedJobs,
  setCurrentSelectedJob,
  setDefaultJobDrawerTab,
}: {
  routedJobs: Array<RoutedJobResponse>;
  setCurrentSelectedJob: (job: RoutedJobResponse) => void;
  setDefaultJobDrawerTab: (tab: JobDrawerTabsEnum) => void;
}) {
  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Grid item xs={12}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "none !important",
          overflow: "hidden",
          alignItems: "center",
          alignSelf: "stretch",
          border: "1px #E8ECEF solid",
          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
          px: 0,
          py: 1,
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            pt: 3,
            pb: 2,
            px: 3,
            alignItems: "center",
            alignSelf: "stretch",
          }}
          subheader={
            <Typography variant="body2" color="text.secondary">
              Review jobs referred to you from your trusted partners. Act FAST,
              as jobs expire after{" "}
              <Typography
                variant="body2"
                sx={{ color: "#F04438" }}
                display={"inline"}
                fontWeight={600}
              >
                24 hours
              </Typography>
            </Typography>
          }
          title={
            <Typography gutterBottom variant={"h6"}>
              Jobs Pending Review ({routedJobs.length})
            </Typography>
          }
        />
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexGrow: 1,
            alignSelf: "stretch",
            background: "#F3F4F6",
            overflow: "auto",
            px: isMobile ? 0 : 1,
            py: 1,
          }}
        >
          {routedJobs.map((job, index) => (
            <Grid key={index} item xs={12} p={isMobile ? 1 : 2}>
              <RoutedJobCard
                key={job.id}
                job={job}
                changeTab={(tab?: JobDrawerTabsEnum) => {
                  if (tab) {
                    setDefaultJobDrawerTab(tab);
                    setCurrentSelectedJob(job);
                  }
                }}
                onClick={() => {
                  setCurrentSelectedJob(job);
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Stack
          spacing={2}
          mt={1}
          mx={1}
          direction={"row"}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "start",
            alignSelf: "stretch",
          }}
        >
          <Button
            endIcon={<ArrowForwardIcon />}
            sx={{ color: "text.primary" }}
            onClick={() => {
              navigate(
                getNavigationPathFromRoutePaths([
                  CompanyRoutes.BASE,
                  CompanyRoutes.ALL_JOBS,
                ])
              );
            }}
          >
            See All
          </Button>
        </Stack>
      </Card>
    </Grid>
  );
}
