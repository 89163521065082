import constate from "constate";
import { useState } from "react";

import { LocationDetails } from "@/company/onboarding/api";

const useGeographyLocationFilter = (
  initialState: Array<LocationDetails> = []
): {
  locations: Array<LocationDetails>;
  addLocation: (location: LocationDetails) => void;
  removeLocations: () => void;
} => {
  const [locations, setLocations] =
    useState<Array<LocationDetails>>(initialState);

  const addLocation = (location: LocationDetails) => {
    const currentLocations = locations?.length ? [...locations] : [];
    setLocations([...currentLocations, location]);
  };

  const removeLocations = () => {
    setLocations([]);
  };

  return {
    locations,
    addLocation,
    removeLocations,
  };
};

export const [
  GeographyLocationFilterProvider,
  useGeographyLocationFilterContext,
] = constate(useGeographyLocationFilter);
