import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import axiosInstance from "@/common/api";

export interface JobSourceCountByCompanySchema {
  company: string;
  count: number;
}

export interface JobStatisticsSchema {
  conversionRatio: number;
  totalJobsClosed: number;
  totalJobsEarnings: number;
  costPerAcquisition: number;
  averageJobPrice: number;
  jobsNotExpired: number;
  efficiencyCostSavings: number;
  techniciansFees: number;
  jobSourceCountByCompany?: Array<JobSourceCountByCompanySchema>;
  firstJobPaidAt?: string;
}
export const GET_JOBS_STATISTICS_URL = "/job-routings/statistics";

export function useGetJobsStatistics(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<JobStatisticsSchema> {
  return useQuery({
    queryKey: [GET_JOBS_STATISTICS_URL],
    queryFn: async () => {
      const response = await axiosInstance.get(GET_JOBS_STATISTICS_URL);
      return response.data.data;
    },
    ...options,
  });
}
