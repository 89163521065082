import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Popover,
  Slider,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { GoogleAutocomplete } from "@/common/GoogleAutocomplete";
import { useQueryLocationByCoords } from "@/company/onboarding/api";
import StateLevelMap from "@/company/onboarding/components/screen/service-details/StateLevelMap";

import { useGeographyLocationFilterContext } from "./useGeographyLocationFilter";
import { useInternalCompanySearchFilterContext } from "./useInternalCompanySearchFilter";

const radiusMarks = [
  {
    value: 0,
    label: "MIN",
  },
  {
    value: 50,
  },
  {
    value: 100,
  },
  {
    value: 150,
  },
  {
    value: 200,
  },
  {
    value: 250,
  },
  {
    value: 300,
    label: "MAX",
  },
];

export default function GeographySearchComponent() {
  const { addFilter, filters } = useInternalCompanySearchFilterContext();

  const { addLocation, locations } = useGeographyLocationFilterContext();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElRadius, setAnchorElRadius] =
    useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRadiusClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElRadius(event.currentTarget);
  };

  const handleRadiusClose = () => {
    setAnchorElRadius(null);
  };

  const [address, setAddress] = useState("");
  const [coords, setCoords] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  const [coordsActive, setCoordsActive] = useState(false);

  const open = Boolean(anchorEl);
  const openRadius = Boolean(anchorElRadius);

  const id = open ? "simple-popover" : undefined;
  const idRadius = openRadius ? "radius-popover" : undefined;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { isInitialized } = useAuth();

  const { data: location } = useQueryLocationByCoords(
    coords?.latitude,
    coords?.longitude,
    !!(coords?.latitude && coords?.longitude && coordsActive)
  );

  useEffect(() => {
    if (location) addLocation(location);
  }, [location]);

  if (!isInitialized) return <>Loading...</>;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
      p={2}
      sx={{
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
        background: "#FFF",
        width: "100%",
      }}
    >
      <Box
        display={"flex"}
        gap={2}
        pb={1}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Box display={"flex"} gap={2} pb={1} alignItems={"center"}>
          <PublicOutlinedIcon />
          <Box>
            <Typography gutterBottom fontSize={"14px"} fontWeight={600}>
              Geography
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Click the map to select states or regions to search.
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} flex="end" gap={1}>
          <Button
            sx={{ px: 1, py: 0.5, borderRadius: "8px" }}
            color="primary"
            variant="outlined"
            size="small"
            startIcon={<RemoveCircleOutlineOutlinedIcon />}
            onClick={handleClick}
          >
            <Typography
              fontSize={"13px"}
              fontWeight={500}
              color="text.secondary"
            >
              Location:{" "}
              <Typography
                display={"inline"}
                fontSize={"13px"}
                fontWeight={500}
                sx={{ color: "#16B364" }}
              >
                {address ? address : "USA"}
              </Typography>
            </Typography>
          </Button>
          <Button
            sx={{ px: 1, py: 0.5, borderRadius: "8px" }}
            color="primary"
            variant="outlined"
            size="small"
            startIcon={<RemoveCircleOutlineOutlinedIcon />}
            onClick={handleRadiusClick}
          >
            <Typography
              fontSize={"13px"}
              fontWeight={500}
              color="text.secondary"
            >
              Radius:{" "}
              <Typography
                display={"inline"}
                fontSize={"13px"}
                fontWeight={500}
                sx={{ color: "#16B364" }}
              >
                {filters.milesRadius ?? 30}
              </Typography>
            </Typography>
          </Button>
        </Box>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box display={"flex"} width={"100%"} gap={2} mt={1}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "flex-start",
            columnGap: 1,
            padding: 3,
            width: "100%",
            flexGrow: { xs: 0, md: 1 },
            flex: "1 0 0",
            height: "70vh",
          }}
        >
          <CardHeader
            sx={{
              display: "flex",
              p: 0,
              pb: 2,
            }}
            subheader={
              <Typography
                color={"text.secondary"}
                variant={isMobile ? "body2" : "body1"}
              >
                Click the map below to select your service area and find the
                closest referral partners
              </Typography>
            }
          />
          <StateLevelMap
            locations={locations}
            currentLocation={location}
            selectedTerritories={filters.zipCodes}
            onTerritoryClick={newSelectedTerritories => {
              addFilter({ zipCodes: newSelectedTerritories });
            }}
          />
        </Card>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <Box display="flex" flexDirection={"column"} p={2} gap={2}>
          <Typography variant="subtitle2">Filter by Location</Typography>
          <GoogleAutocomplete
            selectedValue={address ?? "USA"}
            label="Search"
            onChange={(address: string) => {
              if (coordsActive) {
                setCoordsActive(false);
              }
              setAddress(address);
            }}
            onSelect={({ selection, zipCode, latitude, longitude }) => {
              if (coordsActive) {
                setCoordsActive(false);
              }
              if (latitude && longitude) {
                setCoords({ latitude, longitude });
              }
              setAddress(selection);
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setCoordsActive(true);
              handleClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Popover>
      <Popover
        id={idRadius}
        open={openRadius}
        anchorEl={anchorElRadius}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleRadiusClose}
      >
        <Box
          display="flex"
          width={"300px"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          p={2}
          gap={2}
        >
          <Typography variant="subtitle2">Filter by Radius (miles)</Typography>
          <Slider
            size="small"
            valueLabelDisplay="auto"
            getAriaValueText={value => `${value}`}
            max={300}
            min={0}
            marks={radiusMarks}
            step={0.1}
            sx={{ p: 0, m: 0, width: "75%", pb: 5 }}
            color="primary"
            value={filters.milesRadius ? filters.milesRadius : 30}
            onChange={(_, newValue) => {
              addFilter({ milesRadius: newValue });
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              handleRadiusClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}
