import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemText,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

interface Props {
  notes: Array<string>;
}

export const JobPricingInfoNotes: React.FC<Props> = ({ notes }: Props) => {
  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    // <Box
    //   display={"flex"}
    //   flexDirection={"column"}
    //   gap={0}
    //   sx={{
    //     // p: 2,
    //     borderRadius: 2,
    //     background: "#F8F9FA",
    //     boxShadow:
    //       "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
    //   }}
    // >
    <Accordion
      defaultExpanded
      sx={{
        px: 0.5,
        py: 0.5,
        width: "100%",
        my: "0 !important",
        mx: "0 !important",
        bgcolor: "#F8F9FA",
        "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
          height: "40px",
          minHeight: "40px",
          py: 1,
        },
        "& .MuiAccordion-root": {
          boxShadow:
            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          "& .MuiAccordionSummary-content.Mui-expanded": {
            my: 0,
            mx: 0,
          },
        }}
      >
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          Here&apos;s How It Works
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ py: 0.5, "& .MuiAccordionDetails-root": { py: 0 } }}
      >
        <Grid container p={0} display={"flex"} flexDirection={"column"}>
          <List
            component="ul"
            sx={{
              listStyleType: "disc",
              py: 0,
              pl: 2,
              "& li::marker": {
                color: "gray",
              },
            }}
          >
            {notes?.map((note, index) => (
              <ListItem key={index} sx={{ display: "list-item", pl: 0, py: 0 }}>
                <ListItemText
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      {note}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Typography variant="body2" color="text.secondary" my={0.5}>
            Questions?{" "}
            <Typography
              variant="body2"
              display={"inline"}
              fontWeight={600}
              sx={{ color: "#16B364", cursor: "pointer" }}
              onClick={() => {
                setContactSupportModalOpen(true);
              }}
            >
              Contact support
            </Typography>
          </Typography>
        </Grid>
      </AccordionDetails>
    </Accordion>
    // </Box>
  );
};
