import {
  Avatar,
  Badge,
  Box,
  Card,
  CardActions,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import numeral from "numeral";

import { useAuth } from "@/auth/context/jwt";
import { LeaderboardResponse } from "@/company/api/useGetTechnicianLeaderboard";
import { formatToSingleDecimalPlace } from "@/utils";

export function MobilePage({
  leaderboardData,
}: {
  leaderboardData: LeaderboardResponse[];
}) {
  const { session } = useAuth();
  const user = session?.user;

  const leaderboardDataWithNumerals = leaderboardData?.map(record => ({
    ...record,
    totalJobs: numeral(record.totalJobs).format("0,0"),
    earnings: formatToSingleDecimalPlace(record.earnings),
  }));

  const first = leaderboardDataWithNumerals?.[0];
  const second = leaderboardDataWithNumerals?.[1];
  const third = leaderboardDataWithNumerals?.[2];
  const restRecords = leaderboardDataWithNumerals?.slice(3);
  const ownRecord =
    user && leaderboardDataWithNumerals?.find(tech => tech.id === user.id);
  const colors = [
    "#AC6E5A",
    "#065F46",
    "#B91C1C",
    "#6B21A8",
    "#C05621",
    "#064E3B",
    "#7F1D1D",
    "#2D3748",
    "#702459",
    "#0F766E",
  ];

  return (
    <>
      <Grid item xs={12}>
        <Card
          sx={{
            p: 0,
            mx: -2,
            border: 0,
            bgcolor: "#071D2F",
            borderRadius: 0,
          }}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            p={2}
            mb={2}
          >
            {first || second || third ? (
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-around"}
                px={3}
                mb={0.5}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"start"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  textAlign={"center"}
                  gap={0.2}
                  sx={{
                    mt: "80px",
                    width: "100px",
                    overflow: "auto",
                  }}
                >
                  {second ? (
                    <>
                      <img
                        src="/rank 2.png"
                        alt="rank 2"
                        style={{
                          width: "48px",
                          height: "24px",
                        }}
                      />
                      <Avatar
                        sx={{
                          bgcolor: colors[1],
                          color: "white",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          mt: 0.5,
                          border: "2px solid #16DB65",
                        }}
                      >
                        {
                          `${second.firstName}`
                            ?.toUpperCase()
                            ?.split(" ")?.[0]?.[0]
                        }
                        {
                          `${second.lastName}`
                            ?.toUpperCase()
                            ?.split(" ")?.[0]?.[0]
                        }
                      </Avatar>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#16DB65",
                        }}
                      >
                        ${second.earnings}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "white",
                          overflow: "auto",
                          width: "90px",
                        }}
                      >
                        {second.firstName} {second.lastName}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontSize={"11px"}
                        fontWeight={500}
                        sx={{
                          color: "white",
                          opacity: 0.6,
                        }}
                      >
                        {second.totalJobs} Job{second.totalJobs < 2 ? "" : "s"}
                      </Typography>
                    </>
                  ) : null}
                </Box>
                <Box
                  display={"flex"}
                  flexWrap={"wrap"}
                  flexDirection={"column"}
                  justifyContent={"start"}
                  alignItems={"center"}
                  textAlign={"center"}
                  gap={0.2}
                  sx={{
                    mt: "12px",
                    width: "100px",
                    overflow: "auto",
                  }}
                >
                  {first ? (
                    <>
                      <img
                        src="/crown.png"
                        alt="rank 1"
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                      />
                      <Avatar
                        sx={{
                          bgcolor: colors[0],
                          color: "white",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          mt: 0.5,
                          border: "2px solid #16DB65",
                        }}
                      >
                        {
                          `${first.firstName}`
                            ?.toUpperCase()
                            ?.split(" ")?.[0]?.[0]
                        }
                        {
                          `${first.lastName}`
                            ?.toUpperCase()
                            ?.split(" ")?.[0]?.[0]
                        }
                      </Avatar>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#16DB65",
                        }}
                      >
                        ${first?.earnings}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "white",
                          overflow: "auto",
                          width: "90px",
                        }}
                      >
                        {first.firstName} {first.lastName}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontSize={"11px"}
                        fontWeight={500}
                        sx={{
                          color: "white",
                          opacity: 0.6,
                        }}
                      >
                        {first.totalJobs} Job{first.totalJobs < 2 ? "" : "s"}
                      </Typography>
                    </>
                  ) : null}
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"start"}
                  alignItems={"center"}
                  textAlign={"center"}
                  flexWrap={"wrap"}
                  gap={0.2}
                  sx={{
                    mt: "80px",
                    width: "100px",
                  }}
                >
                  {third ? (
                    <>
                      {" "}
                      <img
                        src="/rank 3.png"
                        alt="rank 3"
                        style={{
                          width: "48px",
                          height: "24px",
                        }}
                      />
                      <Avatar
                        sx={{
                          bgcolor: colors[2],
                          color: "white",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          mt: 0.5,
                          border: "2px solid #16DB65",
                        }}
                      >
                        {
                          `${third.firstName}`
                            ?.toUpperCase()
                            ?.split(" ")?.[0]?.[0]
                        }
                        {
                          `${third.lastName}`
                            ?.toUpperCase()
                            ?.split(" ")?.[0]?.[0]
                        }
                      </Avatar>
                      <Typography variant="h6" sx={{ color: "#16DB65" }}>
                        ${third.earnings}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textAlign: "center",
                          color: "white",
                          overflow: "auto",
                          width: "90px",
                        }}
                      >
                        {third.firstName} {third.lastName}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontSize={"11px"}
                        fontWeight={500}
                        sx={{ color: "white", opacity: 0.6 }}
                      >
                        {third.totalJobs} Job{third.totalJobs < 2 ? "" : "s"}
                      </Typography>
                    </>
                  ) : null}
                </Box>
              </Box>
            ) : null}
          </Box>
          {ownRecord ? (
            <CardActions sx={{ p: 0 }}>
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
                gap={1}
                p={2}
                sx={{
                  background:
                    "linear-gradient(90deg, #16DB66 -21.86%, #087443 59.38%)",
                }}
              >
                <img
                  src="/potential-income.svg"
                  height="40px"
                  width="42px"
                  alt="commission"
                />
                <Badge
                  color="primary"
                  overlap="circular"
                  badgeContent={
                    leaderboardData?.findIndex(
                      record => record.id === ownRecord.id
                    ) + 1
                  }
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#C2175B",
                      color: "white",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    {
                      `${ownRecord.firstName}`
                        ?.toUpperCase()
                        ?.split(" ")?.[0]?.[0]
                    }
                    {
                      `${ownRecord.lastName}`
                        ?.toUpperCase()
                        ?.split(" ")?.[0]?.[0]
                    }
                  </Avatar>
                </Badge>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexGrow={1}
                  ml={1}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ opacity: 0.6, color: "white" }}
                  >
                    Your Rank
                  </Typography>
                  <Typography variant="h6" sx={{ color: "white" }}>
                    {ownRecord.totalJobs} Job
                    {ownRecord.totalJobs < 2 ? "" : "s"}
                  </Typography>
                </Box>
                <Typography variant="h5" sx={{ color: "white" }}>
                  ${ownRecord.earnings}
                </Typography>
              </Box>
            </CardActions>
          ) : null}
        </Card>
      </Grid>
      {restRecords?.length ? (
        <Grid item xs={12}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{
              p: 0,
              mx: -2,
              border: 0,
              borderRadius: 0,
              bgcolor: "#071D2F",
            }}
            flexWrap={"wrap"}
          >
            {restRecords.map((record, index) => (
              <>
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  p={2}
                >
                  <Badge
                    color="primary"
                    overlap="circular"
                    badgeContent={index + 4}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: colors[index % colors.length],
                        color: "white",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        border: "2px solid #16DB65",
                      }}
                    >
                      {
                        `${record.firstName}`
                          ?.toUpperCase()
                          ?.split(" ")?.[0]?.[0]
                      }
                      {
                        `${record.lastName}`
                          ?.toUpperCase()
                          ?.split(" ")?.[0]?.[0]
                      }
                    </Avatar>
                  </Badge>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    flexGrow={1}
                    ml={1}
                  >
                    <Typography variant="subtitle1" sx={{ color: "white" }}>
                      {record.firstName} {record.lastName}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontSize={"14px"}
                      fontWeight={400}
                      sx={{ color: "text.secondary" }}
                    >
                      {record.totalJobs} Job{record.totalJobs < 2 ? "" : "s"}
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ color: "#16DB65" }}>
                    ${record.earnings}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    width: "100%",
                    borderColor: "#384250",
                    height: "1px",
                  }}
                />
              </>
            ))}
          </Box>
        </Grid>
      ) : null}
    </>
  );
}
