import { JobSectors } from "@/company/jobs/constants";
import {
  ExternalBasicCompanySchema,
  PayReferralFeeSettings,
} from "@/company/types";

export enum JobPromotionStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface JobPromotion {
  id: number;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  sector: JobSectors;
  payReferralFeeSettings?: PayReferralFeeSettings;
  leadPrice?: number | null;
  referralSalePrice?: number | null;
  referralPercentageFee?: number | null;
  payReferralFeeAfterJobIsDone: boolean;
  status: JobPromotionStatus;
  exclusionId?: number;
  createdAt: string;
  updatedAt: string;
}

export type JobPromotionWithCompany = JobPromotion & {
  company: ExternalBasicCompanySchema;
};

export interface JobPromotionExclusion {
  id: number;
  companyId: number;
  jobPromotionId: number;
}
