import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import {
  JOB_SHARE_SOURCE,
  JobNetworkInvite,
} from "@/company/jobs/components/JobNetworkInvite";
import { Job } from "@/company/jobs/types";

import { ContactInvite } from "./ContactInvite";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface JobShareDialogProps {
  isOpen: boolean;
  job: Job;
  onClose: () => void;
}

enum TabsEnum {
  CONTACT_INVITE = "MOBILE_INVITE",
  NETWORK_INVITE = "NETWORK_INVITE",
}

export const TechnicianJobShareDialog: React.FC<JobShareDialogProps> = ({
  isOpen,
  job,
  onClose,
}) => {
  const [currentTab, setCurrentTab] = React.useState<TabsEnum>(
    TabsEnum.CONTACT_INVITE
  );

  const handleTabsChange = React.useCallback(
    (_: React.ChangeEvent<unknown>, value: TabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  return (
    <BootstrapDialog
      aria-labelledby="invite-dialog-title"
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={{ p: 3 }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h5" m={0} p={0}>
            Share Job Details
          </Typography>
          <IconButton
            aria-label="close"
            sx={{
              color: theme => theme.palette.grey[500],
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography gutterBottom variant="body2" color="text.secondary">
          Share the job with homeowners for their approval.
        </Typography>
        <Tabs value={currentTab} onChange={handleTabsChange}>
          <Tab value={TabsEnum.CONTACT_INVITE} label="Scan Code" />
          <Tab value={TabsEnum.NETWORK_INVITE} label="Share" />
        </Tabs>
        <Divider />
        <Box>
          {currentTab === TabsEnum.CONTACT_INVITE ? (
            <ContactInvite jobId={job.publicId} />
          ) : null}
          {currentTab === TabsEnum.NETWORK_INVITE ? (
            <JobNetworkInvite job={job} source={JOB_SHARE_SOURCE.TECHNICIAN} />
          ) : null}
        </Box>
      </Box>
    </BootstrapDialog>
  );
};
