import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { User } from "../../auth/types";
import { Chat } from "../../company/api/useFetchChats";
import { CompanyPublicProfile } from "../../company/types";

export const USE_GET_REFERRAL_INVITATION_INFO_URI =
  "/referral-invites/public/:referralInvitationId";

export interface PublicReferralInvitation {
  lastChat: Chat;
  invitingUser: User;
  invitingCompany: CompanyPublicProfile;
}

export function useGetReferralInvitationInfo(
  referralInvitationId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<PublicReferralInvitation> {
  return useQuery({
    queryKey: [USE_GET_REFERRAL_INVITATION_INFO_URI, referralInvitationId],
    queryFn: async () => {
      const response = await API.get(
        `${USE_GET_REFERRAL_INVITATION_INFO_URI.replace(
          ":referralInvitationId",
          referralInvitationId
        )}`
      );
      return response.data.data;
    },
    ...options,
  });
}
