import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";
import { isDefined } from "@/utils/isDefined";

import { StrategicPartner } from "../types";

export function useGetStrategicPartners(
  params: {
    familyOfBrandsId: number;
    searchWord?: string;
    excludeCompanyId?: number;
  },
  options: UseQueryOptions<StrategicPartner[]> = {}
): UseQueryResult<StrategicPartner[]> {
  const { familyOfBrandsId, searchWord, excludeCompanyId } = params;
  const url = "/enterprise/strategic-partners";

  return useQuery({
    queryKey: [url, familyOfBrandsId, excludeCompanyId],
    queryFn: async (): Promise<StrategicPartner[]> => {
      let strategicPartners: StrategicPartner[] = [];

      const strategicPartnersString =
        localStorage.getItem("strategic-partners");
      if (isDefined(strategicPartnersString)) {
        strategicPartners = JSON.parse(strategicPartnersString);
      } else {
        const response = await API.post(url, {
          family_of_brands_id: familyOfBrandsId,
        });

        strategicPartners = response.data.data;
        localStorage.setItem(
          "strategic-partners",
          JSON.stringify(strategicPartners)
        );
      }

      if (isDefined(searchWord)) {
        strategicPartners = strategicPartners.filter(
          (partner: StrategicPartner) =>
            partner.company.name
              .toLowerCase()
              .includes(searchWord.toLowerCase())
        );
      }

      if (isDefined(excludeCompanyId)) {
        strategicPartners = strategicPartners.filter(
          (partner: StrategicPartner) =>
            partner.company.id !== excludeCompanyId &&
            partner.users?.length > 0 &&
            partner.users?.[0]?.firstName?.trim().length > 0
        );
      }

      const companyNameSortOrder = [
        "Northwest Exterminating of Nashville",
        "Critter Control of Nashville",
        "Orkin of Nashville - Commercial (Branch #427)",
        "Orkin of Nashville (Branch #436)",
        "Missquito of Nashville - Coming Soon!",
      ];

      const keepItLast = ["Critter Control of Columbia"];

      const companyStateSortOrder = ["TN", "GA", "FL"];

      const sortedStrategicPartners = strategicPartners.sort((a, b) => {
        const aIndex = companyNameSortOrder.indexOf(a.company.name);
        const bIndex = companyNameSortOrder.indexOf(b.company.name);

        if (keepItLast.includes(a.company.name)) return 1;
        if (keepItLast.includes(b.company.name)) return -1;

        // If both companies are in the sortOrder list, maintain their relative order
        if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex;
        }

        // If only a is in the list, it should come first
        if (aIndex !== -1) return -1;
        // If only b is in the list, it should come first
        if (bIndex !== -1) return 1;

        // If neither company is in the priority list, sort by state order
        const aStateIndex = companyStateSortOrder.indexOf(a.company.state);
        const bStateIndex = companyStateSortOrder.indexOf(b.company.state);

        // If both states are in the stateOrder list, maintain their relative order
        if (aStateIndex !== -1 && bStateIndex !== -1) {
          return aStateIndex - bStateIndex;
        }

        // If only one state is in the priority list, it should come first
        if (aStateIndex !== -1) return -1;
        if (bStateIndex !== -1) return 1;

        // If neither state is in the priority list, maintain original order
        return 0;
      });

      return sortedStrategicPartners.map((partner: StrategicPartner) => ({
        ...partner,
        googleReviewsRating: partner.googleReviewsRating ?? 0,
        googleReviewsCount: partner.googleReviewsCount ?? 0,
      }));
    },
    ...options,
  });
}
