import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { isCompanyActive, isTechnician } from "@/common/utils/accessControl";
import { useCreateCompanyConnections } from "@/company/api/useCreateCompanyConnections";
import {
  SearchedCompany,
  useQueryExploreCompanies,
} from "@/company/api/useExploreConnections";
import { useGetReferralInvitations } from "@/company/api/useGetReferralInvitations";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { RecommendedPartnerCard } from "@/company/components/company-profile-card/RecommendedPartnerCard";
import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { CompanyRoutes } from "@/company/types";

import { OnboardingContainer } from "../../container/OnboardingContainer";
import { useOnboardingReferralSkippedContext } from "../../PreviousLocationProvider";
import { OnboardingSteps } from "../../utils";

export function RecommendedPartners() {
  const MAX_RESULT_COUNT = 12;
  const navigate = useNavigate();
  const { session } = useAuth();
  const company = session?.company;
  const familyOfBrands = session?.familyOfBrands;
  const brandLocationProfile = session?.brandLocationProfile;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { outboundSkipped } = useOnboardingReferralSkippedContext();

  const [selectedCompanies, setSelectedCompanies] = useState<
    Array<SearchedCompany>
  >([]);

  const { data: recommendedOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id, {
      refetchOnWindowFocus: false,
      enabled: !!company?.id,
    });

  const relevanceOrderedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];

  const { enqueueSnackbar } = useSnackbar();

  const {
    isFetching: isFetchingReferralInvitations,
    data: referralInvitations,
  } = useGetReferralInvitations({
    enabled: !!(session && !session?.groups?.some(isTechnician)),
    refetchOnWindowFocus: false,
    onError: () => {
      enqueueSnackbar(
        "There was an error while fetching referral invitations.",
        {
          variant: "error",
        }
      );
    },
  });

  const { isFetching: isFetchingLinkedCompanies, data: linkedCompanies } =
    useQueryExploreCompanies(
      { linkedCompanies: true },
      recommendedOrderedJobSectorsList?.length
        ? recommendedOrderedJobSectorsList
        : relevanceOrderedJobSectorsList,
      {
        refetchOnWindowFocus: false,
        enabled: !!(
          relevanceOrderedJobSectorsList || recommendedOrderedJobSectorsList
        ),
        retry: false,
      }
    );

  const { isFetching: isFetchingAffiliateCompanies, data: affiliateCompanies } =
    useQueryExploreCompanies(
      {
        affiliatedCompanies: true,
        nearby: true,
        ...(linkedCompanies && linkedCompanies?.length
          ? {
              excludeCompanyIds: linkedCompanies
                .filter(comp => !!comp.companyPublicProfile?.id)
                .map(
                  comp => "excludeCompanyIds=" + comp?.companyPublicProfile?.id
                )
                ?.join("&"),
            }
          : {}),
      },
      recommendedOrderedJobSectorsList?.length
        ? recommendedOrderedJobSectorsList
        : relevanceOrderedJobSectorsList,
      {
        refetchOnWindowFocus: false,
        enabled: !!(
          (relevanceOrderedJobSectorsList || relevanceOrderedJobSectorsList) &&
          !isFetchingLinkedCompanies
        ),
        retry: false,
      }
    );

  // const {
  //   isFetching: isFetchingRecommendedCompanies,
  //   data: recommendedCompaniesData,
  // } = useQueryExploreCompanies(
  //   {
  //     nearby: true,
  //     topReferralCategoryCheck: true,
  //     limit: 12,
  //     ...(linkedCompanies && linkedCompanies?.length
  //       ? {
  //           excludeCompanyIds: linkedCompanies
  //             .filter(comp => !!comp.companyPublicProfile?.id)
  //             .map(
  //               comp => "excludeCompanyIds=" + comp?.companyPublicProfile?.id
  //             )
  //             ?.join("&"),
  //         }
  //       : {}),
  //   },
  //   relevanceOrderedJobSectorsList ?? recommendedOrderedJobSectorsList,
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled:
  //       !!relevanceOrderedJobSectorsList?.length ||
  //       !!recommendedOrderedJobSectorsList,
  //     retry: false,
  //   }
  // );

  // const {
  //   isFetching: isFetchingRecommendedGoogleCompanies,
  //   data: recommendedGoogleCompaniesData,
  // } = useQueryExploreCompanies(
  //   {
  //     topReferralCategoryCheck: true,
  //     nearby: true,
  //     isGoogleCompany: true,
  //     limit: 12,
  //   },
  //   relevanceOrderedJobSectorsList ?? recommendedOrderedJobSectorsList,
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled:
  //       !!relevanceOrderedJobSectorsList?.length ||
  //       !!recommendedOrderedJobSectorsList?.length,
  //     retry: false,
  //   }
  // );

  const {
    isLoading: creatingConnections,
    mutateAsync: createCompanyConnections,
  } = useCreateCompanyConnections({
    onSuccess: () => {
      navigate(
        OnboardingSteps[ONBOARDING_STAGES.RECOMMENDED_PARTNERS].nextStepRoute
      );
    },
    onError: () => {
      enqueueSnackbar(
        "There was an error while creating the connection requests.",
        {
          variant: "error",
        }
      );
    },
  });

  let recommendedCompanies =
    linkedCompanies?.filter(
      company =>
        !referralInvitations?.some(
          invitation =>
            company?.companyPublicProfile?.id === invitation.invitedCompanyId ||
            company?.companyPublicProfile?.id === invitation.invitingCompanyId
        )
    ) ?? [];

  // if (recommendedCompaniesData?.length) {
  //   recommendedCompanies = [
  //     ...recommendedCompanies,
  //     ...recommendedCompaniesData,
  //   ];
  // }

  recommendedCompanies = [
    ...recommendedCompanies,
    ...(affiliateCompanies?.filter(
      company =>
        !referralInvitations?.some(
          invitation =>
            company?.companyPublicProfile?.id === invitation.invitedCompanyId ||
            company?.companyPublicProfile?.id === invitation.invitingCompanyId
        )
    ) ?? []),
  ];

  const companyIdSet = new Set<number>();

  recommendedCompanies = recommendedCompanies?.filter(company => {
    const companyId = company.companyPublicProfile?.id;
    if (companyId) {
      if (companyIdSet.has(companyId)) return false;
      companyIdSet.add(companyId);
      return true;
    }
    return true;
  });

  const googlePlacesIdSet = new Set<string>();

  recommendedCompanies = recommendedCompanies?.filter(company => {
    const googleId =
      company.companyPublicProfile?.profile.googlePlacesId ??
      company.googleCompanyProfile?.googlePlacesId;
    if (googleId) {
      if (googlePlacesIdSet.has(googleId)) return false;
      googlePlacesIdSet.add(googleId);
      return true;
    }
    return true;
  });

  const onNext = async () => {
    const invitedCompanyIds = selectedCompanies
      .filter(company => company.companyPublicProfile)
      .map(
        company => company.companyPublicProfile?.id
      ) as unknown as Array<number>;

    const interestedInCompanyGoogleIds = selectedCompanies
      .filter(company => !company.companyPublicProfile)
      .map(
        company => company.googleCompanyProfile?.googlePlacesId
      ) as unknown as Array<string>;
    if (invitedCompanyIds?.length || interestedInCompanyGoogleIds?.length) {
      await createCompanyConnections({
        ...(invitedCompanyIds?.length ? { invitedCompanyIds } : {}),
        ...(interestedInCompanyGoogleIds.length
          ? { interestedInCompanyGoogleIds }
          : {}),
      });
      return;
    }
    navigate(
      OnboardingSteps[ONBOARDING_STAGES.RECOMMENDED_PARTNERS].nextStepRoute
    );
  };

  useEffect(() => {
    if (!isCompanyActive(company?.status)) {
      navigate(
        OnboardingSteps[ONBOARDING_STAGES.RECOMMENDED_PARTNERS].nextStepRoute
      );
    }
  }, [company?.status]);

  return (
    <OnboardingContainer
      heading="Start Building Your Referral Matrix"
      subheading="Click Connect to invite a company to be your referral partner. You can add and manage your partners later in your Referral Matrix.  "
    >
      <Box
        display={"flex"}
        sx={{
          overflow: "auto",
          flexGrow: 1,
          gap: 2,
          ...(isMobile ? { padding: "8px 8px" } : { padding: "8px 24px" }),
          background: "#F3F4F6",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            columnGap: "8px",
            ...(isMobile
              ? { padding: "16px", borderRadius: "8px" }
              : { padding: "24px" }),
            width: "100%",
            flexGrow: 1,
            alignSelf: "stretch",
            overflow: "auto",
          }}
        >
          <Typography
            gutterBottom
            sx={{
              color: "#111927",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "25.12px",
              wordWrap: "break-word",
            }}
          >
            Recommended Referral Partners You May Know
          </Typography>
          <Grid container gap={1}>
            {isFetchingLinkedCompanies ||
            (!linkedCompanies?.length && isFetchingAffiliateCompanies) ? (
              <Box
                py={3}
                mt={2}
                textAlign="center"
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                gap={2}
              >
                <CircularProgress />
                <Typography variant="body1">
                  Hang tight! We&apos;re building your custom list of
                  recommended referral partners.
                </Typography>
              </Box>
            ) : recommendedCompanies && recommendedCompanies.length > 0 ? (
              recommendedCompanies.map((companyData, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={5.8}
                  lg={3.9}
                  sx={{
                    ...(isMobile ? { padding: 0 } : { p: 1 }),
                  }}
                >
                  <RecommendedPartnerCard
                    {...(companyData.companyPublicProfile
                      ? {
                          companyProfile: companyData.companyPublicProfile,
                        }
                      : {})}
                    {...(companyData.googleCompanyProfile
                      ? {
                          googleCompanyProfile:
                            companyData.googleCompanyProfile,
                          hasMarkedInterest: !!companyData.hasMarkedInterest,
                        }
                      : {})}
                    showParentLogo={
                      !!companyData?.companyPublicProfile?.familyOfBrands
                        ?.name &&
                      familyOfBrands?.name ===
                        companyData?.companyPublicProfile?.familyOfBrands?.name
                    }
                    isSelected={selectedCompanies.some(selectedCompany => {
                      if (companyData.companyPublicProfile?.id) {
                        return (
                          companyData.companyPublicProfile.id ===
                          selectedCompany.companyPublicProfile?.id
                        );
                      }

                      if (companyData.googleCompanyProfile?.googlePlacesId) {
                        return (
                          companyData.googleCompanyProfile.googlePlacesId ===
                          selectedCompany.googleCompanyProfile?.googlePlacesId
                        );
                      }
                    })}
                    onClick={searchedCompany => {
                      if (
                        selectedCompanies.some(selectedCompany => {
                          if (searchedCompany.companyPublicProfile?.id) {
                            return (
                              searchedCompany.companyPublicProfile.id ===
                              selectedCompany.companyPublicProfile?.id
                            );
                          }
                          if (
                            searchedCompany.googleCompanyProfile?.googlePlacesId
                          ) {
                            return (
                              searchedCompany.googleCompanyProfile
                                .googlePlacesId ===
                              selectedCompany.googleCompanyProfile
                                ?.googlePlacesId
                            );
                          }
                        })
                      ) {
                        const filteredCompanies = selectedCompanies.filter(
                          selectedCompany => {
                            if (searchedCompany.companyPublicProfile) {
                              return !(
                                searchedCompany.companyPublicProfile.id ===
                                selectedCompany.companyPublicProfile?.id
                              );
                            }
                            if (searchedCompany.googleCompanyProfile) {
                              return !(
                                searchedCompany.googleCompanyProfile
                                  .googlePlacesId ===
                                selectedCompany.googleCompanyProfile
                                  ?.googlePlacesId
                              );
                            }
                            return true;
                          }
                        );
                        setSelectedCompanies([...filteredCompanies]);
                      } else {
                        setSelectedCompanies(selectedCompanies => [
                          ...selectedCompanies,
                          searchedCompany,
                        ]);
                      }
                    }}
                  />
                </Grid>
              ))
            ) : (
              <Box textAlign="center" width="100%" py={3}>
                <Typography variant="body1">
                  There are no companies matching your recommended sectors.
                </Typography>
              </Box>
            )}
          </Grid>
        </Card>
      </Box>
      <Stack
        spacing={2}
        mt={3}
        mb={2}
        mx={2}
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "end",
          alignSelf: "stretch",
        }}
      >
        <Button
          variant="text"
          sx={{
            px: 0,
          }}
          onClick={() => {
            trackEvent(
              AnalyticsEvents.SKIPPED_COMPANY_ONBOARDING_ADDING_SECTOR_JOB_TYPES_DATA,
              {
                companyId: session?.company?.id,
              }
            );
            navigate(
              OnboardingSteps[ONBOARDING_STAGES.RECOMMENDED_PARTNERS]
                .nextStepRoute
            );
          }}
        >
          Skip for Now
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            outboundSkipped
              ? navigate(
                  `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.OUTBOUND_PREFERENCES}`,
                  { replace: true }
                )
              : navigate(
                  `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.TECHNICIAN_SPLIT}`,
                  { replace: true }
                )
          }
        >
          Back
        </Button>
        <LoadingButton
          loading={
            // isFetchingRecommendedCompanies ||
            isFetchingReferralInvitations || creatingConnections
          }
          disabled={!selectedCompanies?.length}
          variant="contained"
          onClick={onNext}
        >
          Connect{" "}
          {selectedCompanies?.length ? `(${selectedCompanies?.length})` : ""}
        </LoadingButton>
      </Stack>
    </OnboardingContainer>
  );
}
