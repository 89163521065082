import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import {
  Box,
  Divider,
  IconButton,
  Tab,
  Tabs,
  tabsClasses,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";

import { GoogleCompanyProfileDetails } from "@/common/GoogleCompanyDrawer/GoogleCompanyProfileDetails";
import { useQueryCompanyBrandProfile } from "@/company/api/useCompanyBrandProfile";
import { useCreateChatAdmin } from "@/company/api/useCreateChatAdmin";
import { ChatThread } from "@/company/api/useFetchAdminChatsByBrandLocation";
import { USE_GET_ALL_CHATS__GROUPED_URI } from "@/company/api/useFetchCompanyMessagesList";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { useAdminChatContext } from "@/company/context/admin-chat-context";
import { CompaniesJobPromotionsList } from "@/company/job-promotions/components/CompaniesJobPromotionsList";
import {
  ConnectionStatus,
  PLATFORM_PRESENCE,
  ReferralInvitation,
} from "@/company/types";

import { CompanyMessage } from "./CompanyMessage";
import { CompanyServiceTerritory } from "./CompanyServiceTerritory";
import { JobActivityOverview } from "./JobActivityOverview";
import { PaymentsTab } from "./PaymentsTab";
import { ProfileOverview } from "./ProfileOverview";
import { ReferralNetworkOverview } from "./ReferralNetworkOverview";
import { RelationshipsOverview } from "./RelationshipsOverview";
import { UserOverview } from "./UserOverview";

interface CompanyDrawerProps {
  onClose?: () => void;
  open?: boolean;
  expanded?: boolean;
  companyProfileId: number;
  invitation?: ReferralInvitation;
  connectionStatus: ConnectionStatus;
  defaultTab?: TabsEnum;
  onExpand?: () => void;
  refetchCompanies?: () => void;
}

export enum TabsEnum {
  USERS = "users",
  REFERRAL_NETWORK = "referral-network",
  RELATIONSHIPS = "relationships",
  PROFILE = "profile",
  JOB_AND_ACTIVITY = "job-and-activity",
  MESSAGE = "message",
  PAYMENTS = "payment",
  TERRITORY = "territory",
  GOOGLE_PROFILE = "google-profile",
  JOB_PROMOTIONS = "job-promotions",
}

export const InternalAdminCompanyDrawer: FC<CompanyDrawerProps> = props => {
  const {
    onClose,
    companyProfileId,
    defaultTab,
    onExpand,
    expanded,
    refetchCompanies,
  } = props;
  const [currentTab, setCurrentTab] = useState<TabsEnum>(
    defaultTab ?? TabsEnum.PROFILE
  );
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const { chats: companyMessagesList, isLoading } = useAdminChatContext();

  const [propChatThread, setPropChatThread] = useState<ChatThread | null>(null);

  const {
    data: brandLocationProfile,
    refetch,
    isFetching: isFetchingProfile,
  } = useQueryCompanyBrandProfile(companyProfileId, {
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: createChatThread, isLoading: isCreatingChatThread } =
    useCreateChatAdmin({
      onSuccess: chatThread => {
        setPropChatThread(chatThread);
        setCurrentTab(TabsEnum.MESSAGE);
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while creating chat thread.`,
          variant: "error",
        });
      },
    });

  const { closeSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: TabsEnum): void => {
      setPropChatThread(null);
      setCurrentTab(value);
    },
    []
  );

  useEffect(() => {
    if (currentTab === TabsEnum.MESSAGE) {
      queryClient.invalidateQueries({
        queryKey: [USE_GET_ALL_CHATS__GROUPED_URI],
      });
    }
  }, [currentTab, queryClient]);

  const createNewChatThread = (userId: number) => {
    createChatThread(userId);
  };

  if (!brandLocationProfile) return null;

  const unreadMessages = companyMessagesList?.filter(
    thread => thread.unreadMessages
  )?.length;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      sx={{ ...(expanded ? { width: "100%" } : {}) }}
    >
      <Box
        position={"sticky"}
        sx={{
          top: 0,
          zIndex: 400,
          pt: 1,
          background: "white",
        }}
      >
        <Box display="flex" justifyContent="space-between" px={3} pb={2} pt={3}>
          <CompanyAvatar
            height={"48px"}
            logo={brandLocationProfile.overview.logoUrl}
            name={brandLocationProfile.overview.name ?? ""}
          />
          <Box display={"flex"} gap={2}>
            <IconButton
              aria-label="expand"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={() => {
                closeSnackbar();
                onExpand?.();
              }}
            >
              {expanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={() => {
                closeSnackbar();
                onClose?.();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Tabs
          sx={{
            px: 3,

            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
          value={currentTab}
          variant="scrollable"
          TabScrollButtonProps={{ sx: { width: "20px" } }}
          scrollButtons={true}
          allowScrollButtonsMobile={true}
          onChange={handleTabsChange}
        >
          <Tab value={TabsEnum.PROFILE} label="Profile" />
          <Tab value={TabsEnum.USERS} label="Users" />
          <Tab value={TabsEnum.REFERRAL_NETWORK} label="Referral Network" />
          <Tab value={TabsEnum.RELATIONSHIPS} label="Relationships" />
          <Tab value={TabsEnum.JOB_AND_ACTIVITY} label="Jobs & Activity" />
          <Tab
            value={TabsEnum.MESSAGE}
            label={`Messages ${unreadMessages ? `(${unreadMessages})` : ""}`}
          />
          <Tab value={TabsEnum.PAYMENTS} label={`Payments`} />
          <Tab value={TabsEnum.TERRITORY} label={`Territory`} />
          <Tab value={TabsEnum.GOOGLE_PROFILE} label={`Google Profile`} />
          <Tab value={TabsEnum.JOB_PROMOTIONS} label={`Job Promotions`} />
        </Tabs>
        <Divider />
      </Box>
      <Box p={1.5} pb={0} flex={1} overflow={"auto"}>
        {currentTab === TabsEnum.PROFILE && !propChatThread ? (
          <ProfileOverview
            companyId={companyProfileId}
            refetch={() => refetch()}
            expanded={expanded}
            overview={brandLocationProfile.overview}
            isFetchingProfile={isFetchingProfile}
            refetchCompanies={() => refetchCompanies?.()}
            campaigns={brandLocationProfile.campaigns}
          />
        ) : null}
        {currentTab === TabsEnum.USERS && !propChatThread ? (
          <UserOverview
            companyId={companyProfileId}
            expanded={expanded}
            isFetchingProfile={isFetchingProfile}
            overview={brandLocationProfile.overview}
            refetch={() => refetch()}
            usersWithGroups={brandLocationProfile.userGroups}
            createNewChatThread={createNewChatThread}
          />
        ) : null}
        {currentTab === TabsEnum.REFERRAL_NETWORK && !propChatThread ? (
          <ReferralNetworkOverview
            companyId={companyProfileId}
            refetch={() => refetch()}
            isFetchingProfile={isFetchingProfile}
            expanded={expanded}
            referralNetwork={brandLocationProfile.referralNetwork}
            overview={brandLocationProfile.overview}
            inboundInvitations={brandLocationProfile.inboundInvitations}
            outboundInvitations={brandLocationProfile.outboundInvitations}
          />
        ) : null}
        {currentTab === TabsEnum.JOB_AND_ACTIVITY && !propChatThread ? (
          <JobActivityOverview
            companyId={companyProfileId}
            refetch={() => refetch()}
            expanded={expanded}
            jobs={brandLocationProfile.jobs}
            overview={brandLocationProfile.overview}
          />
        ) : null}
        {currentTab === TabsEnum.RELATIONSHIPS && !propChatThread ? (
          <RelationshipsOverview
            companyId={companyProfileId}
            refetch={() => refetch()}
            expanded={expanded}
            referralNetwork={brandLocationProfile.referralNetwork}
            overview={brandLocationProfile.overview}
            inboundInvitations={brandLocationProfile.inboundInvitations}
            outboundInvitations={brandLocationProfile.outboundInvitations}
          />
        ) : null}
        {currentTab === TabsEnum.MESSAGE || propChatThread ? (
          <CompanyMessage
            isLoading={isLoading}
            companyMessagesList={companyMessagesList}
            propChatThread={propChatThread ?? undefined}
            onDeselectChat={() => setPropChatThread(null)}
          />
        ) : null}
        {currentTab === TabsEnum.PAYMENTS && !propChatThread ? (
          <PaymentsTab
            expanded={!!expanded}
            companyId={companyProfileId}
            balance={brandLocationProfile.overview?.balance ?? 0}
          />
        ) : null}
        {currentTab === TabsEnum.TERRITORY && !propChatThread ? (
          <CompanyServiceTerritory
            expanded={!!expanded}
            companyId={companyProfileId}
            zipCode={brandLocationProfile.overview?.zipCode}
          />
        ) : null}
        {currentTab === TabsEnum.GOOGLE_PROFILE && !propChatThread ? (
          <GoogleCompanyProfileDetails
            googlePlacesId={
              brandLocationProfile?.overview?.profile?.googlePlacesId
            }
            companyName={brandLocationProfile?.overview?.name}
            isVerified={
              brandLocationProfile?.overview?.platformPresence ===
              PLATFORM_PRESENCE.ON_PLATFORM
            }
          />
        ) : null}
        {currentTab === TabsEnum.JOB_PROMOTIONS && !propChatThread && (
          <CompaniesJobPromotionsList companyIds={[companyProfileId]} />
        )}
      </Box>
    </Box>
  );
};
