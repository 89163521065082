import { Download } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import type { FC } from "react";

import { usePopover } from "@/common/hooks/use-popover";
import { ToolTip } from "@/common/tool-tip/ToolTip";

interface JobAttachmentMoreMenuProps {
  onDelete: () => void;
  onDownload: () => void;
  disableDelete?: boolean;
  color?: string;
}

export const JobAttachmentMoreMenu: FC<JobAttachmentMoreMenuProps> = ({
  onDelete,
  onDownload,
  disableDelete,
  color,
}) => {
  const popover = usePopover<HTMLButtonElement>();

  return (
    <>
      <ToolTip message="More options">
        <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
          <SvgIcon>
            <MoreVertIcon sx={{ color: color ?? "white" }} />
          </SvgIcon>
        </IconButton>
      </ToolTip>
      <Menu
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        open={popover.open}
        PaperProps={{
          sx: {
            maxWidth: "100%",
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        onClose={popover.handleClose}
      >
        <MenuItem
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            onDownload();

            popover.handleClose();
          }}
        >
          <ListItemIcon>
            <SvgIcon>
              <Download />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Download" />
        </MenuItem>
        {/* {disableDelete ? null : (
          <MenuItem
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              onDelete();
              popover.handleClose();
            }}
          >
            <ListItemIcon>
              <SvgIcon>
                <Delete />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )} */}
      </Menu>
    </>
  );
};
