/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Button, CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { useFlags } from "launchdarkly-react-client-sdk";
import { enqueueSnackbar } from "notistack";
import { type FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { isCompanyActive } from "@/common/utils/accessControl";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { CompanyPaymentDialog } from "@/company/components/company-payments-tab/CompanyPaymentDialog";
import { useCompanyPaymentsContext } from "@/company/context/payments-context";
import { useGetRoutedJobsByCompany } from "@/company/jobs/api/useGetRoutedJobsByCompany";
import { useUpdateRoutedJobStatus } from "@/company/jobs/api/useUpdateRoutedJobStatus";
import { AccessControlJobDialog } from "@/company/jobs/components/AccessControlJobDialog";
import { DeclineJobDialog } from "@/company/jobs/components/DeclineJobDialog";
import { JobCompletionPaymentDialog } from "@/company/jobs/components/JobCompletionPaymentDialog";
import { JobDrawer } from "@/company/jobs/components/JobDrawer";
import { MarkInactiveJobDialog } from "@/company/jobs/components/MarkInactiveJobDialog";
import { RoutedJobResponse, RoutedJobStatus } from "@/company/jobs/types/job";
import { getLastActivityRoutedJob } from "@/company/jobs/utils";
import { FEATURE_FLAGS } from "@/featureFlags";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

import JobsTable from "./JobsTable";

interface ReferralStatisticsProps {
  onJobPaid: () => void;
}

export const ReferralStatistics: FC<ReferralStatisticsProps> = (
  props: ReferralStatisticsProps
) => {
  const { session } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

  const flags = useFlags();

  const [onApproveJobByInactiveCompany, setOnApproveJobByInactiveCompany] =
    useState(false);

  const {
    data: routedJobs,
    refetch: refetchRoutedJobs,
    isLoading: isLoadingRoutedJobs,
  } = useGetRoutedJobsByCompany(
    undefined,
    {
      cacheTime: 0, // Do not keep cache
      staleTime: 0,
      refetchOnWindowFocus: false, // Refetch on window focus is pretty annoying
    },
    flags[FEATURE_FLAGS.JOBS_JOB_ROUTINGS_V2_ENDPOINT]
  );

  const refetchAnalyticsData = useCallback(() => {
    refetchRoutedJobs();
    props.onJobPaid();
  }, [refetchRoutedJobs, props]);

  const [currentSelectedJob, setCurrentSelectedJob] =
    useState<RoutedJobResponse>();

  const navigate = useNavigate();
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const { refetchTransactions, refetchCard } = useCompanyPaymentsContext();
  const [declineJobDialogOpen, setDeclineJobDialogOpen] = useState(false);
  const [jobCompletionDialogOpen, setJobCompletionDialogOpen] = useState(false);
  const [markInactiveDialogOpen, setMarkInactiveDialogOpen] = useState(false);

  const { mutateAsync: updateRoutedJob, isLoading: updatingRoutedJob } =
    useUpdateRoutedJobStatus({
      onSuccess: routedJob => {
        if (
          routedJob.status === RoutedJobStatus.PAID ||
          routedJob.status === RoutedJobStatus.PENDING_PAYMENT
        ) {
          enqueueSnackbar({
            message: `${
              routedJob.status === RoutedJobStatus.PAID ? "Paid!" : "Approved!"
            }`,
            variant: "success",
          });
          refetchCard();
          refetchTransactions();
        }
        setCurrentSelectedJob(routedJob);
        refetchAnalyticsData();
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating job status.";
        if (
          error?.code === "not_enough_balance" ||
          error?.code === "not_enough_credits"
        ) {
          setPaymentDialogOpen(true);
        }
        enqueueSnackbar(
          `${
            error?.code === "not_enough_balance" ||
            error?.code === "not_enough_credits"
              ? "Insufficient funds."
              : message
          }`,
          {
            ...(error?.code === "not_enough_balance" ||
            error?.code === "not_enough_credits"
              ? {
                  subText: (
                    <>
                      Please add TradeEngage credits or update your card to
                      approve this job. Questions?{" "}
                      <a
                        role="button"
                        style={{
                          textDecorationLine: "underline",
                          color: "#7A271A",
                          cursor: "pointer",
                        }}
                        onClick={event => {
                          event.preventDefault();
                          setContactSupportModalOpen(true);
                        }}
                      >
                        Contact support
                      </a>
                      .
                    </>
                  ),
                }
              : {}),
            variant: "error",
            autoHideDuration: 10000,
          }
        );
      },
    });

  return (
    <Card
      sx={{
        position: "relative",
        boxShadow: "none",
        boxShadow: "0px 0px 0px 1px #F2F4F7",
      }}
    >
      <CardHeader sx={{ pt: 3, pb: 2, px: 3 }} title="My Job History" />
      <Box>
        {isLoadingRoutedJobs ? (
          <Box my={5} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <JobsTable
              routedJobs={routedJobs}
              expanded={true}
              onClick={job => {
                setCurrentSelectedJob(job);
              }}
            />
            {currentSelectedJob ? (
              <JobDrawer
                open={!!currentSelectedJob}
                jobId={(currentSelectedJob as RoutedJobResponse).jobId}
                inactiveReason={
                  (currentSelectedJob as RoutedJobResponse).inactiveReason ||
                  (currentSelectedJob as RoutedJobResponse).declinedReason
                }
                isRoutedJob={true}
                jobStatus={currentSelectedJob?.status}
                lastActivity={getLastActivityRoutedJob(
                  currentSelectedJob as RoutedJobResponse
                )}
                {...(currentSelectedJob?.status ===
                RoutedJobStatus.PENDING_REVIEW
                  ? {
                      actionArea: (
                        <>
                          <Button
                            size="small"
                            variant="contained"
                            disabled={updatingRoutedJob}
                            onClick={() => {
                              if (!isCompanyActive(company?.status)) {
                                setOnApproveJobByInactiveCompany(true);
                                return;
                              }
                              updateRoutedJob({
                                jobId: currentSelectedJob.id,
                                data: {
                                  status:
                                    brandLocationProfile?.payReferralFeeAfterJobIsDone
                                      ? RoutedJobStatus.PENDING_PAYMENT.toString()
                                      : RoutedJobStatus.PAID.toString(),
                                },
                              });
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            size="small"
                            color="error"
                            variant="outlined"
                            disabled={updatingRoutedJob}
                            onClick={() => {
                              if (!isCompanyActive(company?.status)) {
                                setOnApproveJobByInactiveCompany(true);
                                return;
                              }
                              setDeclineJobDialogOpen(true);
                            }}
                          >
                            Decline
                          </Button>
                        </>
                      ),
                    }
                  : {})}
                {...(currentSelectedJob?.status ===
                RoutedJobStatus.PENDING_PAYMENT
                  ? {
                      actionArea: (
                        <>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              setJobCompletionDialogOpen(true);
                            }}
                          >
                            Pay Referral Fee
                          </Button>
                          <Button
                            size="small"
                            sx={{
                              color: "#F04438 !important",
                              borderColor: "rgba(240, 68, 56, 0.50) !important",
                            }}
                            variant="outlined"
                            onClick={() => {
                              setMarkInactiveDialogOpen(true);
                            }}
                          >
                            Mark Inactive
                          </Button>
                        </>
                      ),
                    }
                  : {})}
                onClose={() => {
                  setCurrentSelectedJob(undefined);
                }}
              />
            ) : null}
            {declineJobDialogOpen && currentSelectedJob && (
              <DeclineJobDialog
                isOpen={declineJobDialogOpen}
                jobName={currentSelectedJob?.jobType ?? ""}
                onCancel={() => {
                  setDeclineJobDialogOpen(false);
                }}
                onRemove={(reason: string) => {
                  updateRoutedJob({
                    jobId: currentSelectedJob.id,
                    data: {
                      status: RoutedJobStatus.DECLINED.toString(),
                      declinedReason: reason,
                    },
                  });
                  setDeclineJobDialogOpen(false);
                  setCurrentSelectedJob(undefined);
                }}
              />
            )}
            {jobCompletionDialogOpen && currentSelectedJob && (
              <JobCompletionPaymentDialog
                isOpen={jobCompletionDialogOpen}
                job={currentSelectedJob as RoutedJobResponse}
                onClose={(refetch?: boolean) => {
                  if (refetch) {
                    refetchAnalyticsData();
                    setCurrentSelectedJob(undefined);
                  }

                  setJobCompletionDialogOpen(false);
                }}
              />
            )}
            {markInactiveDialogOpen && currentSelectedJob && (
              <MarkInactiveJobDialog
                isOpen={markInactiveDialogOpen}
                jobId={(currentSelectedJob as RoutedJobResponse).jobId}
                onCancel={() => {
                  setMarkInactiveDialogOpen(false);
                }}
                onRemove={reason => {
                  updateRoutedJob({
                    jobId: currentSelectedJob.id,
                    data: {
                      status: RoutedJobStatus.INACTIVE.toString(),
                      inactiveReason: reason,
                    },
                  });
                  setCurrentSelectedJob(undefined);
                  setMarkInactiveDialogOpen(false);
                }}
              />
            )}
          </>
        )}
      </Box>
      {onApproveJobByInactiveCompany ? (
        <AccessControlJobDialog
          isOpen={onApproveJobByInactiveCompany}
          onClose={() => {
            setOnApproveJobByInactiveCompany(false);
          }}
        />
      ) : null}
      {paymentDialogOpen ? (
        <CompanyPaymentDialog
          open={paymentDialogOpen}
          onClose={() => setPaymentDialogOpen(false)}
        />
      ) : null}
    </Card>
  );
};
