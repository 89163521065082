import {
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { Job } from "@/company/jobs/types";
import { TextEditorTextPreview } from "@/lib/text-editor/TextEditorTextPreview";
import { neutral } from "@/Theme/colors";
import { isDefined } from "@/utils/isDefined";

import { useCreateJobFromPromotion } from "../api/useCreateJobFromPromotion";
import { useGetJobPromotions } from "../api/useGetJobPromotions";
import { JobPromotionStatus, JobPromotionWithCompany } from "../types";

interface JobDetailsPromotionFormProps {
  onSubmit: (job: Job, promotionUsed?: JobPromotionWithCompany) => void;
}

export function JobDetailsPromotionForm(props: JobDetailsPromotionFormProps) {
  const { onSubmit } = props;
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [selectedJobPromotion, setSelectedJobPromotion] = useState<
    JobPromotionWithCompany | undefined
  >();
  const [expandedJobPromotionId, setExpandedJobPromotionId] = useState<
    number | undefined
  >();

  const { data: jobPromotionsData, isLoading: isLoadingJobPromotions } =
    useGetJobPromotions({ filter: { status: JobPromotionStatus.ACTIVE } });

  const { mutate: createJobFromPromotion, isLoading: isCreatingJob } =
    useCreateJobFromPromotion({
      onSuccess: data => {
        onSubmit(data, selectedJobPromotion);
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "There was an error while creating the job";
        enqueueSnackbar({
          message,
          variant: "error",
        });
      },
    });

  if (isLoadingJobPromotions) {
    return <ComponentLoader />;
  }

  if (!jobPromotionsData?.promotions.length) {
    return <Typography>No promotions found</Typography>;
  }

  return (
    <Stack gap={2}>
      {jobPromotionsData?.promotions.map(jobPromotion => (
        <Accordion
          key={jobPromotion.id}
          sx={{
            padding: 1,
            borderRadius: "8px",
            border: 1,
            borderColor: neutral[200],
            "&.Mui-expanded": { margin: 0 },
          }}
          expanded={expandedJobPromotionId === jobPromotion.id}
          onChange={() => {
            setExpandedJobPromotionId(currentExpandedJobPromotionId =>
              currentExpandedJobPromotionId === jobPromotion.id
                ? undefined
                : jobPromotion.id
            );
          }}
        >
          <AccordionSummary
            sx={{
              padding: 0,
              ".MuiAccordionSummary-content": {
                margin: 0,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              },
              "&.Mui-expanded": { minHeight: "24px" },
              minHeight: "24px",
            }}
          >
            <Stack direction="row">
              {expandedJobPromotionId === jobPromotion.id ? (
                <ExpandMoreIcon />
              ) : (
                <ChevronRightIcon />
              )}
              <Typography variant="subtitle1">{jobPromotion.title}</Typography>
            </Stack>
            <Switch
              size="small"
              checked={selectedJobPromotion?.id === jobPromotion.id}
              onChange={() => {
                setSelectedJobPromotion(currentSelectedJobPromotion => {
                  return currentSelectedJobPromotion?.id === jobPromotion.id
                    ? undefined
                    : jobPromotion;
                });
              }}
              onClick={event => {
                event.stopPropagation();
              }}
            />
          </AccordionSummary>
          <AccordionDetails sx={{ paddingX: 3, paddingY: 1 }}>
            <Stack gap={2}>
              <Stack>
                <Typography variant="overline" color="text.disabled">
                  WHAT’S INCLUDED
                </Typography>
                <Box>
                  <TextEditorTextPreview content={jobPromotion.description} />
                </Box>
              </Stack>
              <Stack alignItems={"flex-start"}>
                <Typography variant="overline" color="text.disabled">
                  Courtesy of
                </Typography>
                <Typography variant="subtitle1">
                  {jobPromotion.company.name}
                </Typography>
                <Box
                  component="img"
                  src={jobPromotion.company?.logoUrl}
                  alt="company logo"
                  sx={{
                    height: "24px",
                    width: "auto",
                    maxWidth: "50%",
                    objectFit: "contain",
                    display: "block",
                  }}
                />
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <LoadingButton
          sx={{
            mr: 1,
            borderRadius: "8px",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
          }}
          disabled={!isDefined(selectedJobPromotion)}
          loading={isCreatingJob}
          size={isMobile ? "small" : "medium"}
          color="primary"
          variant="contained"
          onClick={() => {
            createJobFromPromotion({
              jobPromotionId: selectedJobPromotion?.id ?? -1,
            });
          }}
        >
          Next
        </LoadingButton>
      </Box>
    </Stack>
  );
}
