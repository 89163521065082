import { Outlet } from "react-router-dom";

import { usePageTrackingAnalytics } from "../analytics/usePageTrackingAnalytics";
import ScrollToTop from "../ScrollToTop";

export default function RootLayout() {
  usePageTrackingAnalytics();
  return (
    <div
      className="root-layout"
      style={{
        height: "100%",
      }}
    >
      <main
        style={{
          height: "100%",
        }}
      >
        <ScrollToTop />
        <Outlet />
      </main>
    </div>
  );
}
