import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { ReactNode } from "react";

import { useBackdropContext } from "./backdrop-context";

export interface DashboardTitleProps {
  title: string;
  subtitle?: string;
  endButton?: ReactNode;
  icon?: ReactNode;
}
export function DashboardTitle(props: DashboardTitleProps) {
  const { backDropOn } = useBackdropContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Grid
      container
      sx={{
        top: "56px",
        zIndex: backDropOn ? 0 : 400,
        pt: 1,
      }}
      position="sticky"
      bgcolor="white"
    >
      <Grid item xs={12} lg={10}>
        {isMobile ? null : props.icon ? (
          <Box display="flex" gap={1} alignItems="center" mb={0.5}>
            {props.icon}
            <Typography variant="h5">{props.title}</Typography>
          </Box>
        ) : (
          <Typography gutterBottom variant="h5">
            {props.title}
          </Typography>
        )}

        {props.subtitle ? (
          <Typography gutterBottom variant="body2" color="textSecondary">
            {props.subtitle}
          </Typography>
        ) : null}
      </Grid>
      {props.endButton ? (
        <Grid item xs={12} lg={2}>
          {props.endButton}
        </Grid>
      ) : null}
    </Grid>
  );
}
