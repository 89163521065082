import { Route } from "react-router-dom";

import AnalyticsPage from "@/analytics/screens/Analytics";
import AnalyticsHubPage from "@/analytics/screens/AnalyticsHub";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { CSRJobPage } from "@/technician/components/screens/CSRJobPage";
import { LeaderboardPage } from "@/technician/components/screens/leaderboard/LeaderboardPage";
import { MyWalletPage } from "@/technician/components/screens/my-wallet/MyWallet";
import { TechnicianHomePage } from "@/technician/components/screens/technician-demo/TechnicianHomePage";
import { TechnicianJobDashboardPage } from "@/technician/components/screens/TechnicianJobDashboardPage/TechnicianJobDashboardPage";
import { TechnicianPaymentsProvider } from "@/technician/context/wallet-context";

import CompanyLayout from "./CompanyLayout";
import { TeamMembers } from "./components/company-settings/TeamMembers";
import { CompanyDashboardLayout } from "./components/layout/CompanyDashboardLayout";
import { CompanyMessagesPage } from "./components/screens/company-messages-page/CompanyMessagesPage";
import { CompanySettingsPage } from "./components/screens/company-settings/CompanySettingsPage";
import { CompanyDashboardHomePage } from "./components/screens/dashboard-home/CompanyDashboardHomePage";
import { ExplorePartnersPage } from "./components/screens/explore-partners/ExplorePartnersPage";
import { JobCallModal } from "./components/screens/job-calls-page/JobCallModal";
import { JobCallsPage } from "./components/screens/job-calls-page/JobCallsPage";
import { ManagePartnersPage } from "./components/screens/manage-partners/ManagePartnersPage";
import { ProfilePage } from "./components/screens/profile/ProfilePage";
import { ReferralPartnersPage } from "./components/screens/referral-partners/ReferralPartnersPage";
import { SuggestedPartnersPage } from "./components/screens/suggested-partners/SuggestedPartnersPage";
import { SuppportMessage } from "./components/screens/support-message-page/SupportMessagePage";
import { WelcomePage } from "./components/screens/welcome/WelcomePage";
import { SupportChatProvider } from "./context/support-chat-context";
import { CompanyJobPromotionsPage } from "./job-promotions/pages/CompanyJobPromotionsPage";
import { JobPromotionsPage } from "./job-promotions/pages/JobPromotionsPage";
import { JobDashboardPage } from "./jobs/screens/JobDashboardPage";
import { OnboardingRouter } from "./onboarding";
import { TeamMemberOnboardingRouter } from "./onboarding/team-member-onboarding/router";
import { CompanyRoutes } from "./types";

export function CompanyRouter() {
  return (
    <Route path={CompanyRoutes.BASE} element={<CompanyLayout />}>
      {OnboardingRouter()}
      {TeamMemberOnboardingRouter()}
      <Route
        path={CompanyRoutes.WELCOME}
        loader={ComponentLoader}
        element={<WelcomePage />}
      />
      <Route
        path=""
        loader={ComponentLoader}
        element={
          <SupportChatProvider>
            <CompanyDashboardLayout />
          </SupportChatProvider>
        }
      >
        <Route
          path={CompanyRoutes.JOB_PROMOTIONS}
          element={<JobPromotionsPage />}
        />
        <Route
          path={CompanyRoutes.TECHNICIAN_DEMO}
          loader={ComponentLoader}
          element={<TechnicianHomePage />}
        />
        <Route
          path={CompanyRoutes.HUBS}
          loader={ComponentLoader}
          element={<AnalyticsHubPage />}
        />
        <Route
          path={CompanyRoutes.LEADERBOARD}
          loader={ComponentLoader}
          element={<LeaderboardPage />}
        />
        <Route
          path={CompanyRoutes.DASHBOARD_HOME}
          loader={ComponentLoader}
          element={<CompanyDashboardHomePage />}
        />
        <Route
          path={CompanyRoutes.SUPPORT_MESSAGES}
          loader={ComponentLoader}
          element={<SuppportMessage />}
        />
        <Route
          path={CompanyRoutes.MESSAGES}
          loader={ComponentLoader}
          element={<CompanyMessagesPage />}
        />
        <Route
          path={CompanyRoutes.JOB_CALLS}
          loader={ComponentLoader}
          element={<JobCallsPage />}
        />
        <Route
          path={`${CompanyRoutes.JOB_CALLS}/:selectedRecordingId?/:findPartner?`}
          loader={ComponentLoader}
          element={<JobCallsPage />}
        />
        <Route
          path={`${CompanyRoutes.JOB_CALLS_MODAL}/:selectedRecordingId?/:findPartner?`}
          loader={ComponentLoader}
          element={<JobCallModal />}
        />
        <Route
          path={CompanyRoutes.REFERRAL_PARTNERS}
          loader={ComponentLoader}
          element={<ReferralPartnersPage />}
        />
        <Route
          path={CompanyRoutes.MANAGE_PARTNERS}
          loader={ComponentLoader}
          element={<ManagePartnersPage />}
        />
        <Route
          path={`${CompanyRoutes.MANAGE_PARTNERS}/:invitationId?/:tab?`}
          loader={ComponentLoader}
          element={<ManagePartnersPage />}
        />
        <Route
          path={CompanyRoutes.SUGGESTED_PARTNERS}
          loader={ComponentLoader}
          element={<SuggestedPartnersPage />}
        />
        <Route
          path={CompanyRoutes.EXPLORE_PARTNERS}
          loader={ComponentLoader}
          element={<ExplorePartnersPage />}
        />
        <Route
          path={`${CompanyRoutes.EXPLORE_PARTNERS}/:companyId?/:tab?`}
          loader={ComponentLoader}
          element={<ExplorePartnersPage />}
        />
        <Route
          path={CompanyRoutes.ALL_JOBS}
          loader={ComponentLoader}
          element={<JobDashboardPage />}
        />
        <Route
          path={`${CompanyRoutes.ALL_JOBS}/:jobType?/:id?/:tab?`}
          loader={ComponentLoader}
          element={<JobDashboardPage />}
        />
        <Route
          path={CompanyRoutes.MY_JOBS}
          loader={ComponentLoader}
          element={<TechnicianJobDashboardPage />}
        />
        <Route
          path={`${CompanyRoutes.MY_JOBS}/:openJobModal?/:tab?`}
          loader={ComponentLoader}
          element={<TechnicianJobDashboardPage />}
        />
        <Route
          path={CompanyRoutes.CSR_JOBS}
          loader={ComponentLoader}
          element={<CSRJobPage />}
        />
        <Route
          path={CompanyRoutes.COMPANY_SETTINGS}
          loader={ComponentLoader}
          element={<CompanySettingsPage />}
        />
        <Route
          path={`${CompanyRoutes.COMPANY_SETTINGS}/:tab`}
          loader={ComponentLoader}
          element={<CompanySettingsPage />}
        />
        <Route
          path={CompanyRoutes.TEAM_MEMBERS}
          loader={ComponentLoader}
          element={<TeamMembers />}
        />
        <Route
          path={CompanyRoutes.PROFILE}
          loader={ComponentLoader}
          element={<ProfilePage />}
        />
        <Route
          path={CompanyRoutes.ANALYTICS}
          loader={ComponentLoader}
          element={<AnalyticsPage />}
        />
        <Route
          path={CompanyRoutes.MY_WALLET}
          loader={ComponentLoader}
          element={
            <TechnicianPaymentsProvider>
              <MyWalletPage />
            </TechnicianPaymentsProvider>
          }
        />
      </Route>
      <Route
        path={`${CompanyRoutes.JOB_PROMOTIONS}/:companyId`}
        element={<CompanyJobPromotionsPage />}
      />
    </Route>
  );
}
