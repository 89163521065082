import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";
import { keysToSnakeCase } from "@/utils";

export enum StripeLinkType {
  UPDATE = "update",
  STRIPE_DASHBOARD = "stripe_dashboard",
}

export interface GetStripeLinkPayload {
  linkType: StripeLinkType;
  returnUrl: string;
  refreshUrl: string;
}

export const GET_USER_STRIPE_LINK_URI = "/users/stripe/link";

export function useGetStripeLink(
  options: UseMutationOptions<string, AxiosError, GetStripeLinkPayload> = {}
): UseMutationResult<string, AxiosError, GetStripeLinkPayload> {
  return useMutation({
    mutationFn: async (searchPayload: GetStripeLinkPayload) => {
      const searchParams = new URLSearchParams(keysToSnakeCase(searchPayload));
      const uri = `${GET_USER_STRIPE_LINK_URI}?${searchParams}`;
      const response = await API.get(uri);
      return response.data.data;
    },
    ...options,
  });
}
