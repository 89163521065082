import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { Campaign } from "@/auth/types";
import API from "@/common/api";

export const USE_GET_CAMPAIGN_URI = "/campaigns/:campaignCode/public";

export function useGetCampaignPublic(
  campaignCode: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Campaign> {
  return useQuery({
    queryKey: [USE_GET_CAMPAIGN_URI, campaignCode],
    queryFn: async () => {
      const response = await API.get(
        `${USE_GET_CAMPAIGN_URI.replace(":campaignCode", campaignCode)}`
      );
      return response.data.data;
    },
    ...options,
  });
}
