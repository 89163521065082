import { Route } from "react-router-dom";

import { ComponentLoader } from "../../common/ComponentLoader/ComponentLoader";
import { OnboardingLayout } from "./components/layout/OnboardingLayout";
import { CompanyProfile } from "./components/screen/company-profile/CompanyProfile";
import { InboundReferralPreferences } from "./components/screen/inbound-referrals/InboundReferralPreferences";
import { JobPrice } from "./components/screen/job-price/JobPrice";
import { OperationsAndSales } from "./components/screen/operations-sales-info/OperationsAndSales";
import { OutboundReferralPreferences } from "./components/screen/outbound-referrals/OutboundReferralPreferences";
import { RecommendedPartners } from "./components/screen/recommended-partners/RecommendedPartners";
import { ServiceDetails } from "./components/screen/service-details/ServiceDetails";
import { TechnicianSplit } from "./components/screen/technician-split/TechnicianSplit";
import { ONBOARDING_ROUTES, ONBOARDING_STAGES } from "./constants";

export function OnboardingRouter() {
  return (
    <Route path={ONBOARDING_ROUTES.BASE} element={<OnboardingLayout />}>
      <Route
        path={ONBOARDING_STAGES.SERVICE_TERRITORY}
        loader={ComponentLoader}
        element={<ServiceDetails />}
      />
      <Route
        path={ONBOARDING_STAGES.SERVICE_DETAILS}
        loader={ComponentLoader}
        element={<ServiceDetails />}
      />
      <Route
        path={ONBOARDING_STAGES.COMPANY_PROFILE}
        loader={ComponentLoader}
        element={<CompanyProfile />}
      />
      <Route
        path={ONBOARDING_STAGES.INBOUND_PREFERENCES}
        loader={ComponentLoader}
        element={<InboundReferralPreferences />}
      />
      <Route
        path={ONBOARDING_STAGES.OUTBOUND_PREFERENCES}
        loader={ComponentLoader}
        element={<OutboundReferralPreferences />}
      />
      <Route
        path={ONBOARDING_STAGES.JOB_PRICE}
        loader={ComponentLoader}
        element={<JobPrice />}
      />
      <Route
        path={ONBOARDING_STAGES.TECHNICIAN_SPLIT}
        loader={ComponentLoader}
        element={<TechnicianSplit />}
      />
      <Route
        path={ONBOARDING_STAGES.RECOMMENDED_PARTNERS}
        loader={ComponentLoader}
        element={<RecommendedPartners />}
      />
      <Route
        path={ONBOARDING_STAGES.OPERATIONS_AND_SALES}
        loader={ComponentLoader}
        element={<OperationsAndSales />}
      />
    </Route>
  );
}
