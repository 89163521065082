import {
  AttachFile,
  BackupOutlined,
  ChatBubbleOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CompanyRoutes } from "@/company/types";

export function JobUploadSuccess({
  uploadedJobId,
  onClose,
  setNewUploadScreenOpen,
}: {
  onClose: () => void;
  uploadedJobId: number;
  setNewUploadScreenOpen: (value: boolean) => void;
}) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={"100%"}
      px={3}
      mt={isMobile ? -2 : 0}
      pb={4}
      gap={1}
    >
      <img
        style={{
          height: "200px",
          width: "200px",
          marginBottom: "24px",
        }}
        src="/success.gif"
        alt="success"
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        width={"100%"}
        alignItems={"start"}
      >
        <Typography variant="h4" fontSize={"22px"}>
          Job Submitted Successfully!
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
        <Typography variant="body1" color="text.secondary">
          Great! Your job will be routed and we&apos;ll let you know when your
          earnings are on their way. 💸
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Need to share more details about this job?
        </Typography>
        <Box display={"flex"} gap={2}>
          <Button
            startIcon={<ChatBubbleOutline />}
            variant="outlined"
            size="small"
            sx={{
              width: "fit-content",
              color: "#111927",
              border: "1px solid #111927",
              borderRadius: "8px",
            }}
            onClick={() => {
              navigate(
                `/${CompanyRoutes.BASE}/${CompanyRoutes.MY_JOBS}/${uploadedJobId}/message`
              );
              onClose();
            }}
          >
            Send a Message
          </Button>
          <Button
            size="small"
            startIcon={<AttachFile />}
            variant="outlined"
            sx={{
              width: "fit-content",
              color: "#111927",
              border: "1px solid #111927",
              borderRadius: "8px",
            }}
            onClick={() => {
              navigate(
                `/${CompanyRoutes.BASE}/${CompanyRoutes.MY_JOBS}/${uploadedJobId}/attachments`
              );
              onClose();
            }}
          >
            Add Attachments
          </Button>
        </Box>
      </Box>
      <Divider sx={{ width: "100%", my: 1.5 }} />
      <Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
        <Typography variant="body1" color="text.secondary">
          Do you have more jobs to refer?
        </Typography>
      </Box>
      <Box display={"flex"} width={"100%"}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<BackupOutlined />}
          onClick={() => setNewUploadScreenOpen(false)}
        >
          Submit New Job
        </Button>
      </Box>
    </Box>
  );
}
