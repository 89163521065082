import { Route } from "react-router-dom";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { InvitationSignUpPage } from "@/open/components/screens/marketing/InvitationSignUpPage";
import { JobSignUpPage } from "@/open/components/screens/marketing/JobSignUpPage";

import { AuthLayout } from "./components/layout/AuthLayout";
import { LoginPage } from "./components/screens/login/LoginPage";
import { SignUpPage } from "./components/screens/signup/SignUpPage";
import { UserTypePage } from "./components/screens/user-type/UserTypePage";
import { VerifyOtpPage } from "./components/screens/verify-otp/VerifyOtpPage";
import { AuthRoutes } from "./constants";

export function AuthRouter() {
  return (
    <Route path={AuthRoutes.BASE} element={<AuthLayout />}>
      <Route
        path={AuthRoutes.LOGIN}
        loader={ComponentLoader}
        element={<LoginPage />}
      />
      <Route
        path={AuthRoutes.VERIFY_OTP}
        loader={ComponentLoader}
        element={<VerifyOtpPage />}
      />
      <Route
        path={AuthRoutes.SIGN_UP}
        loader={ComponentLoader}
        element={<SignUpPage />}
      />
      <Route
        path={AuthRoutes.JOB_SIGN_UP}
        loader={ComponentLoader}
        element={<JobSignUpPage />}
      />
      <Route
        path={AuthRoutes.INVITATION_SIGN_UP}
        loader={ComponentLoader}
        element={<InvitationSignUpPage />}
      />
      <Route
        path={AuthRoutes.USER_TYPE}
        loader={ComponentLoader}
        element={<UserTypePage />}
      />
    </Route>
  );
}
