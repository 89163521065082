import { Box, TextField, Typography } from "@mui/material";

interface JobDetailsTextFormProps {
  isCreatingJob: boolean;
  setTranscription: (text: string) => void;
  transcription: string | undefined;
}

export function JobDetailsTextForm(props: JobDetailsTextFormProps) {
  const { isCreatingJob, transcription, setTranscription } = props;

  if (isCreatingJob) {
    return null;
  }

  return (
    <Box display={"flex"} gap={1} flexDirection={"column"}>
      <Typography variant="body2" display={"inline"} color={"text.secondary"}>
        Describe the service and level of urgency
      </Typography>
      <TextField
        multiline
        minRows={3}
        maxRows={4}
        id="transcription"
        name="transcription"
        label="Job Summary"
        value={transcription ?? ""}
        type="string"
        InputProps={{
          inputProps: {
            maxLength: 500,
          },
        }}
        onChange={event => {
          setTranscription(event.target.value);
        }}
      />
      <Box display={"flex"} width={"100%"} justifyContent={"end"} mt={0.2}>
        <Typography
          fontSize={"12px"}
          color="text.secondary"
          fontWeight={400}
          mr={1}
        >
          {transcription?.length ?? 0}/500 Characters
        </Typography>
      </Box>
    </Box>
  );
}
