import constate from "constate";
import { useSnackbar } from "notistack";

import { useAuth } from "@/auth/context/jwt";
import { isTechnician } from "@/common/utils/accessControl";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useConnectionLimitReachedModalContext } from "@/open/components/modals/LimitReachedContext";

import { useCreateReferralInvitation } from "../api/useCreateReferralInvitation";
import { useGetReferralInvitations } from "../api/useGetReferralInvitations";
import { useUpdateReferralInvitation } from "../api/useUpdateReferralInvitation";
import { ReferralInvitationActions } from "../types";

function useReferralInvitations() {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const { setShowLimitReachedDialog } = useConnectionLimitReachedModalContext();

  const {
    isFetching: isFetchingReferralInvitations,
    data: referralInvitations,
    refetch: refetchReferralInvitations,
  } = useGetReferralInvitations({
    enabled: !!(session && !session?.groups?.some(isTechnician)),
    refetchOnWindowFocus: false,
    onError: () => {
      enqueueSnackbar(
        "There was an error while fetching referral invitations.",
        {
          variant: "error",
        }
      );
    },
  });

  const { mutateAsync: updateReferralInvitationRequest } =
    useUpdateReferralInvitation({
      onError: () => {
        enqueueSnackbar("Unable to update referral invitation at the moment.", {
          variant: "error",
        });
      },
    });

  const { mutateAsync: createReferralInvitationRequest } =
    useCreateReferralInvitation({
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error?.message
          : "There was an error while trying to send the referral invitation.";
        if (error?.status_code === 409) {
          setShowLimitReachedDialog(message);
          return;
        }
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
    });

  const updateReferralInvitation = async ({
    invitationId,
    action,
    shouldRefresh = true,
  }: {
    invitationId: number;
    action: ReferralInvitationActions;
    shouldRefresh?: boolean;
  }) => {
    await updateReferralInvitationRequest({
      invitationId,
      action,
    });
    if (shouldRefresh) await refetchReferralInvitations();
  };

  const createReferralInvitation = async ({
    invitedCompanyId,
    initialMessage,
  }: {
    invitedCompanyId: number;
    initialMessage?: ReferralInvitationActions;
  }) => {
    await createReferralInvitationRequest({
      invitedCompanyId,
      initialMessage,
    });
    await refetchReferralInvitations();
  };

  return {
    isFetchingReferralInvitations,
    referralInvitations,
    refetchReferralInvitations,
    updateReferralInvitation,
    createReferralInvitation,
  };
}

export const [ReferralInvitationProvider, useReferralInvitationContext] =
  constate(useReferralInvitations);
