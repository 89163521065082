import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import changeCaseKeys from "change-case-keys";
import qs from "qs";

import API from "@/common/api";
import { JobSectors } from "@/company/jobs/constants";

import { JobPromotionStatus, JobPromotionWithCompany } from "../types";

interface GetJobPromotionsResponse {
  promotions: JobPromotionWithCompany[];
  total: number;
  size: number;
  offset: number;
}

interface GetJobPromotionsFilter {
  keyword?: string;
  sectors?: JobSectors[];
  companyIds?: number[];
  status?: JobPromotionStatus;
}

export function useGetJobPromotions(
  params: {
    filter: GetJobPromotionsFilter;
    offset?: number;
    size?: number;
  },
  options: UseQueryOptions<GetJobPromotionsResponse> = {}
): UseQueryResult<GetJobPromotionsResponse> {
  const url = "/job-promotions";

  return useQuery({
    queryKey: [url, params],
    queryFn: async (): Promise<GetJobPromotionsResponse> => {
      const response = await API.get(url, {
        params: changeCaseKeys(params, "underscored"),
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: false,
          });
        },
      });
      return response.data.data;
    },
    ...options,
  });
}
