import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { DateTime } from "luxon";

import API from "@/common/api";

import { JobSectors } from "../jobs/constants";
import { Company, CompanyAddress } from "../types";

export enum RecommendedPartnerSource {
  BNI = "BNI",
  MANUAL = "MANUAL",
}

export interface RecommendedPartnersInternalAdmin {
  id: number;
  createdAt: DateTime;
  company: Company;
  companySectors: Array<JobSectors>;
  companyTotalRecommendedPartners: number;
  recommendedCompany: Company;
  recommendedCompanySectors: Array<JobSectors>;
  recommendedCompanyTotalTimesAppearInRecommendedPartners: number;
  companyBrand?: Company;
  companyFamilyOfBrands?: Company;
  companyPrivateEquity?: Company;
  companyGooglePlacesId?: string;
  companyAddress?: CompanyAddress;
  companyBusinessEmail?: string;
  companyBusinessPhone?: string;
  companyBuisnessPhoneCarrierType?: string;
  companyOwnerName?: string;
  companyOwnerEmail?: string;
  companyOwnerCellPhone?: string;
  companyOwnerCellPhoneCarrierType?: string;
  recommendedCompanyBrand?: Company;
  recommendedCompanyFamilyOfBrands?: Company;
  recommendedCompanyPrivateEquity?: Company;
  recommendedCompanyGooglePlacesId?: string;
  recommendedCompanyAddress: CompanyAddress;
  recommendedCompanyBusinessEmail?: string;
  recommendedCompanyBusinessPhone?: string;
  recommendedCompanyBuisnessPhoneCarrier_type?: string;
  recommendedCompanyOwnerName?: string;
  recommendedCompanyOwnerEmail?: string;
  recommendedCompanyOwnerCellPhone?: string;
  recommendedCompanyOwnerCellPhoneCarrierType?: string;
  source: RecommendedPartnerSource;
  does_referral_between_companies_exist?: boolean;
  does_referral_invitation_between_companies_exist: boolean;
}

export function useGetRecommendedPartnersInternalAdmin(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<RecommendedPartnersInternalAdmin>> {
  const uri = "/internal-admin/recommended-partners";

  return useQuery({
    queryKey: [uri.toString()],
    queryFn: async () => {
      const response = await API.get(uri);
      const camelCaseResponse = response.data.data;
      return camelCaseResponse;
    },
    ...options,
  });
}
