import {
  Button,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { useFlags } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import type { ChangeEvent, FC, KeyboardEvent } from "react";
import { useCallback, useEffect, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { CompanyPublicProfile, ConnectionStatus } from "@/company/types";
import { FEATURE_FLAGS } from "@/featureFlags";

import { isCompanyActive } from "../utils/accessControl";
import { MessageOptions } from "./MessageOptions";

export enum ChatMessageAddSource {
  COMPANY_CHAT = "COMPANY_CHAT",
  JOB_CHAT = "JOB_CHAT",
  SUPPORT_CHAT = "SUPPORT_CHAT",
}
interface ChatMessageAddProps {
  isCampaignTour?: boolean;
  disabled?: boolean;
  defaultText: string;
  showMessageOptions?: boolean;
  companyProfile?: CompanyPublicProfile;
  source?: ChatMessageAddSource;
  onSend?: (value: string) => void;
  connectionStatus?: ConnectionStatus;
  setCampaignOff?: () => void;
}

export const ChatMessageAdd: FC<ChatMessageAddProps> = props => {
  const {
    disabled = false,
    onSend,
    showMessageOptions,
    companyProfile,
    source,
    connectionStatus,
    defaultText,
    isCampaignTour,
    ...other
  } = props;

  const [body, setBody] = useState<string>();
  const { session } = useAuth();
  const campaign = session?.campaign;
  const company = session?.company;
  const user = session?.user;

  const flags = useFlags();
  const isMessagingEnabled =
    flags[FEATURE_FLAGS.MESSAGING_ENABLED_FOR_NON_ACTIVE_COMPANIES];

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { data: campaignCompanyProfile } = useFetchCompanyProfile(
    campaign?.companyId,
    {
      enabled: !!campaign?.companyId && !!isCampaignTour,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setBody(defaultText);
  }, [defaultText]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setBody(event.target.value);
    },
    []
  );

  const handleSend = useCallback(
    (isKeyEnter?: boolean): void => {
      if (!body?.replace(/\n/g, "")) {
        return;
      }
      const text = isKeyEnter ? body.replace(/\n$/, "") : body;

      if (text) {
        onSend?.(text);
        setBody("");
      }
    },
    [body, onSend]
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent<HTMLInputElement>): void => {
      if (event.code === "Enter") {
        // Handle Ctrl+Enter (or opt+Enter on macOS) for adding a newline
        if (event.ctrlKey || event.altKey || event.shiftKey) {
          event.preventDefault();
          setBody(prevBody => prevBody + "\n");
        }
        // Handle Enter without any modifiers for sending the message
        if (
          !event.shiftKey &&
          !event.altKey &&
          !event.ctrlKey &&
          !event.metaKey
        ) {
          handleSend(true);
        }
      }
    },
    [handleSend]
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isCampaignTour) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isCampaignTour]);

  if (
    user?.type === USER_TYPE.COMPANY_TEAM_MEMBER &&
    !isCampaignTour &&
    !isCompanyActive(company?.status) &&
    source !== ChatMessageAddSource.SUPPORT_CHAT &&
    !isMessagingEnabled
  ) {
    return null;
  }

  return (
    <Stack
      alignItems="left"
      direction="column"
      position={"sticky"}
      bottom={0}
      right={0}
      spacing={2}
      sx={{
        background: "white",
      }}
      {...other}
    >
      {/* <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          multiline
          fullWidth
          minRows={defaultText ? 5 : 1}
          maxRows={20}
          disabled={disabled}
          placeholder="Type your reply"
          size="small"
          value={body}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
        />
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box></Box>
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Box>
      </Paper> */}
      <OutlinedInput
        multiline
        fullWidth
        minRows={defaultText ? 5 : 1}
        maxRows={20}
        disabled={disabled}
        placeholder="Type your reply"
        size="small"
        value={body}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          m: -2,
          ml: 2,
        }}
      >
        <Tooltip
          disableFocusListener
          placement={isMobile ? "top-start" : "left"}
          open={open}
          componentsProps={{
            tooltip: {
              sx: {
                color: "white",
                backgroundColor: "#16B364",
              },
            },

            arrow: {
              sx: {
                color: "#16B364",
                ...(isMobile
                  ? {
                      transform: "translate3d(32px, 0px, 0px) !important",
                    }
                  : {}),
              },
            },
          }}
          arrow={true}
          title={
            <Box
              display={"flex"}
              p={2}
              gap={1}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"start"}
              sx={{
                borderRadius: "6px",

                background: "#16B364",
              }}
            >
              <Typography fontSize={"15px"} fontWeight={600} color="white">
                Break The Ice!
              </Typography>
              <Typography variant="subtitle2" color="white">
                Introduce yourself by sending a message
                {campaignCompanyProfile?.brand?.name
                  ? ` to the ${campaignCompanyProfile?.brand?.name} team`
                  : ""}
                .
              </Typography>
              <Button
                size="small"
                sx={{
                  color: "#FFF",
                  bgcolor: "#16B364",
                  border: "1px solid #FFF",
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Got it
              </Button>
            </Box>
          }
        >
          <Box
            width={"100%"}
            sx={{
              mt: 1,
              mb: connectionStatus === ConnectionStatus.NOT_CONNECTED ? 1 : 0,
            }}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Button
              disabled={!body?.replace(/\n/g, "") || disabled}
              variant="contained"
              onClick={() => {
                handleSend();
              }}
            >
              {connectionStatus === ConnectionStatus.NOT_CONNECTED
                ? "Connect"
                : "Send"}
            </Button>
            {showMessageOptions && companyProfile ? (
              <MessageOptions companyProfile={companyProfile} />
            ) : null}
          </Box>
        </Tooltip>
      </Box>
    </Stack>
  );
};

ChatMessageAdd.propTypes = {
  disabled: PropTypes.bool,
  onSend: PropTypes.func,
};
