import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PercentIcon from "@mui/icons-material/Percent";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Switch,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { enqueueSnackbar } from "notistack";
import * as React from "react";

import { useAuth } from "@/auth/context/jwt";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useUpdateSectors } from "@/company/api/useUpdateSectors";
import { useUpdateBrandLocationProfile } from "@/company/onboarding/api";
import {
  HOMEOWNER_APPROVAL_SETTING,
  PayReferralFeeSettings,
} from "@/company/types";

import { JobPriceSettings } from "./components/JobPriceSettings";

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 10,
    label: "10%",
  },
  {
    value: 20,
    label: "20%",
  },
  {
    value: 30,
    label: "30%",
  },
  {
    value: 40,
    label: "40%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 60,
    label: "60%",
  },
  {
    value: 70,
    label: "70%",
  },
  {
    value: 80,
    label: "80%",
  },
  {
    value: 90,
    label: "90%",
  },
  {
    value: 100,
    label: "100%",
  },
];

function valueLabelFormat(value: number) {
  return `${value}%`;
}

export type UpdateSectorType = Record<
  number,
  {
    sectorId: number;
    buyingPrice?: number;
    leadPrice?: number;
    percentagePrice?: number;
  }
>;

export const ReferralMatrix: React.FC = () => {
  const [technicianSplit, setTechnicianSplit] = React.useState(0);
  const [updatedSectors, setUpdatedSectors] =
    React.useState<UpdateSectorType | null>();

  const [openNetworkEnabled, setOpenNetworkEnabled] = React.useState(false);
  const [isUpsellingEnabled, setIsUpsellingEnabled] = React.useState(false);

  const [invitationMessage, setInvitationMessage] = React.useState("");

  const [
    payReferralFeeAfterJobCompletion,
    setPayReferralFeeAfterJobCompletion,
  ] = React.useState(false);
  const [payPerReferral, setPayPerReferral] = React.useState(false);

  const handleOpenNetworkChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOpenNetworkEnabled(event.target.checked);
  };

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const { session, isInitialized, refreshSession } = useAuth();
  const user = session?.user;
  const groups = session?.groups;
  const company = session?.company;

  const brandLocationProfile = session?.brandLocationProfile;

  const companySectors = session?.companySectors;

  const extractLeadPayload = (
    data: UpdateSectorType
  ): Record<number, { leadPrice?: number }> => {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        { leadPrice: value.leadPrice, sectorId: value.sectorId },
      ])
    );
  };

  const extractPayAfterJobCompletionPayload = (
    data: UpdateSectorType
  ): Record<number, { leadPrice?: number }> => {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        {
          sectorId: value.sectorId,
          buyingPrice: value.buyingPrice,
          percentagePrice: value.percentagePrice,
        },
      ])
    );
  };

  const [homeownerApproval, setHomeownerApproval] = React.useState(
    HOMEOWNER_APPROVAL_SETTING.ROUTE_ALL_JOBS_TO_HOME_OWNER
  );
  const [paymentMode, setPaymentMode] = React.useState(
    PayReferralFeeSettings.FIXED_AMOUNT
  );

  const handleHomeownerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHomeownerApproval(
      (event.target as HTMLInputElement).value as HOMEOWNER_APPROVAL_SETTING
    );
  };

  const handlePaymentModehange = (value: PayReferralFeeSettings) => {
    setPaymentMode(value);
  };

  const { isLoading: updatingCompany, mutateAsync: updateCompany } =
    useUpdateBrandLocationProfile(session?.brandLocationProfile?.id, {
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating company details.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        enqueueSnackbar({
          message: "Your changes have been saved.",
          variant: "success",
        });
      },
    });

  React.useEffect(() => {
    if (brandLocationProfile?.defaultTechnicianSplit) {
      setTechnicianSplit(brandLocationProfile?.defaultTechnicianSplit);
    }
    if (brandLocationProfile?.invitationMessage) {
      setInvitationMessage(brandLocationProfile?.invitationMessage);
    }
    setOpenNetworkEnabled(!!brandLocationProfile?.openNetworkEnabled);
    setPayReferralFeeAfterJobCompletion(
      !!brandLocationProfile?.payReferralFeeAfterJobIsDone
    );
    if (brandLocationProfile?.payReferralFeeSettings) {
      setPaymentMode(brandLocationProfile?.payReferralFeeSettings);
    }
    if (brandLocationProfile?.payPerReferral) {
      setPayPerReferral(brandLocationProfile?.payPerReferral);
    }
    setHomeownerApproval(
      brandLocationProfile?.homeownerJobApprovalSettings ??
        HOMEOWNER_APPROVAL_SETTING.ROUTE_ALL_JOBS_TO_HOME_OWNER
    );
    setIsUpsellingEnabled(!!brandLocationProfile?.upsellingEnabled);
  }, [brandLocationProfile]);

  React.useEffect(() => {
    if (companySectors) {
      const newSectors = {};
      for (const sector of companySectors) {
        newSectors[sector.id] = {
          sectorId: sector.id,
          buyingPrice: sector.buyingPrice,
          percentagePrice: sector.percentagePrice,
          leadPrice: sector.leadPrice,
        };
      }
      setUpdatedSectors(newSectors);
    }
  }, [companySectors]);

  const { mutateAsync: updateSectors, isLoading: isUpdatingSectors } =
    useUpdateSectors({
      onSuccess: () => {
        setUpdatedSectors(null);
        refreshSession();
      },
      onError: () => {
        enqueueSnackbar({
          message: "Unable to save sector changes.",
          variant: "error",
        });
      },
    });

  if (!isInitialized) return <>Loading...</>;

  return (
    <Grid container>
      <JobPriceSettings
        isLoading={updatingCompany || isUpdatingSectors}
        updatedSectors={updatedSectors}
        payPerReferral={payPerReferral}
        setPaymentMode={handlePaymentModehange}
        paymentMode={paymentMode}
        setPayPerReferral={(value: boolean) => setPayPerReferral(value)}
        payReferralFeeAfterJobCompletion={payReferralFeeAfterJobCompletion}
        setPayReferralFeeAfterJobCompletion={(value: boolean) =>
          setPayReferralFeeAfterJobCompletion(value)
        }
        setUpdatedSectors={setUpdatedSectors}
      />
      <Divider style={{ width: "100%" }} />
      <Grid container p={3} alignItems={"start"}>
        <Grid item xs={12} lg={4} pr={4}>
          <Box display={"flex"} alignItems={"center"} pb={1}>
            <Typography variant="h6">Homeowner Approval</Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            Do you want a homeowner to review and approve a job before it is
            routed to your referral partner?
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          {updatingCompany || isUpdatingSectors ? (
            <Box py={3} textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box
              display={"flex"}
              gap={4}
              sx={{
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="homeowner-approval-row-radio-buttons-group-label"
                  name="homeowner-approval-row-radio-buttons-group"
                  value={homeownerApproval}
                  onChange={handleHomeownerChange}
                >
                  <FormControlLabel
                    value={
                      HOMEOWNER_APPROVAL_SETTING.ROUTE_ALL_JOBS_TO_HOME_OWNER
                    }
                    disabled={!session?.groups?.some(isAdministratorOrOwner)}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">Always Required</Typography>
                    }
                  />
                  <FormControlLabel
                    value={
                      HOMEOWNER_APPROVAL_SETTING.DONT_ROUTE_ANY_JOB_TO_HOME_OWNER
                    }
                    disabled={!session?.groups?.some(isAdministratorOrOwner)}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">Not Required</Typography>
                    }
                  />
                  <FormControlLabel
                    value={
                      HOMEOWNER_APPROVAL_SETTING.TECHNICIANS_DECISION_TO_ROUTE
                    }
                    disabled={!session?.groups?.some(isAdministratorOrOwner)}
                    control={<Radio />}
                    label={
                      <Box display={"flex"} gap={1} alignItems={"center"}>
                        <Typography variant="body2">
                          Depends on the job
                        </Typography>
                        <ToolTip message="Let my Field Team decide if the homeowner needs to approve the job or if the job can be handled directly with our referral partner. This is often the case in sub-contractor relationships or if the homeowner wants you to take care of the job.">
                          <HelpOutlineIcon
                            sx={{
                              color: "#6C737F",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </ToolTip>
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} />
      <Grid container p={3} alignItems={"center"}>
        <Grid item xs={12} lg={4} pr={4}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pb={1}
          >
            <Typography variant="h6">Technician Split</Typography>
            <ToolTip message="For example, if a service provider pays $50 for a job referred by one of your technicians and the split is 50%, your company will receive $25 and your technician will receive $25.">
              <HelpOutlineIcon
                sx={{ color: "#6C737F", width: "20px", height: "20px" }}
              />
            </ToolTip>
          </Box>
          <Typography variant="body2" color="text.secondary">
            The Technician Split determines the portion of the referral fee
            earned that goes directly to the technician. We recommend a 50%
            split to align everyone&apos;s incentives.
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          {updatingCompany || isUpdatingSectors ? (
            <Box py={3} textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box
              display={"flex"}
              gap={4}
              sx={{
                flexDirection: { xs: "column", lg: "row" },
                mt: { xs: 1, md: 0 },
              }}
            >
              <TextField
                hiddenLabel
                disabled={!session?.groups?.some(isAdministratorOrOwner)}
                sx={{
                  width: "120px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: false }}
                name="link"
                type="number"
                value={technicianSplit}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 1,
                }}
                onChange={event =>
                  setTechnicianSplit(parseInt(event.target.value))
                }
              />
              <Slider
                sx={{ maxWidth: "400px" }}
                aria-label="technician split slider"
                defaultValue={technicianSplit}
                disabled={!session?.groups?.some(isAdministratorOrOwner)}
                valueLabelDisplay="auto"
                step={10}
                value={technicianSplit}
                {...(isDesktop ? { marks } : {})}
                min={0}
                max={100}
                valueLabelFormat={valueLabelFormat}
                onChange={(event: Event, newValue: number | number[]) =>
                  setTechnicianSplit(newValue as number)
                }
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} />
      <Grid container p={3} alignItems={"center"}>
        <Grid item xs={12} lg={4} pr={4}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pb={1}
          >
            <Typography variant="h6">Invitation Message</Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            This message will be sent when you invite a service provider to be
            your referral partner. You can send invitations by clicking Connect
            from Explore Partners.
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          {updatingCompany || isUpdatingSectors ? (
            <Box py={3} textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box
              display={"flex"}
              gap={4}
              sx={{
                flexDirection: { xs: "column", lg: "row" },
                mt: { xs: 1, md: 0 },
              }}
            >
              <TextField
                fullWidth
                multiline
                minRows={4}
                maxRows={20}
                id="invitationMessage"
                name="invitationMessage"
                label="Default Invitation Message"
                disabled={
                  !session?.groups?.some(isAdministratorOrOwner) ||
                  updatingCompany
                }
                InputLabelProps={{ shrink: true }}
                placeholder={
                  invitationMessage
                    ? invitationMessage
                    : `Hi, I'm ${user?.firstName} at ${company?.name}. Our team comes across referral opportunities all the time and I'd like to collaborate. We use this company to pay other teams for referrals, instead of paying Google and PPC for leads. Our team has a high-level of customer service and quality of work. When is a good time to connect for 15 minutes and introduce ourselves?`
                }
                value={invitationMessage}
                type="string"
                onChange={event => {
                  setInvitationMessage(event.target.value);
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} />
      <Grid container p={3}>
        <Grid item xs={12} lg={4} pr={4}>
          <Typography variant="h6">Additional Settings</Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          {updatingCompany || isUpdatingSectors ? (
            <Box py={3} textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Stack gap={2}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{
                  flexDirection: { xs: "column", lg: "row" },
                  mt: { xs: 1, md: 0 },
                }}
              >
                <Box>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography gutterBottom variant="subtitle1">
                      Enable open network
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    In the open network, TradeEngage will route jobs submitted
                    by your technicians to other partners on the TradeEngage
                    platform.
                  </Typography>
                </Box>
                <Switch
                  checked={openNetworkEnabled}
                  disabled={!session?.groups?.some(isAdministratorOrOwner)}
                  onChange={handleOpenNetworkChange}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{
                  flexDirection: { xs: "column", lg: "row" },
                  mt: { xs: 1, md: 0 },
                }}
              >
                <Box>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography gutterBottom variant="subtitle1">
                      Enable Upsell
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    If you enable upsell, your technicians and CSRs will be able
                    to refer your own services back to you on TradeEngage.
                  </Typography>
                </Box>
                <Switch
                  checked={isUpsellingEnabled}
                  disabled={!session?.groups?.some(isAdministratorOrOwner)}
                  onChange={event =>
                    setIsUpsellingEnabled(event.target.checked)
                  }
                />
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>

      {session?.groups?.some(isAdministratorOrOwner) ? (
        <Grid container p={3}>
          <Grid item xs={12}>
            <Stack
              spacing={2}
              m={2}
              direction={{ xs: "column", md: "row" }}
              sx={{
                display: "flex",
                alignItems: { xs: "flex-center", lg: "flex-end" },
                justifyContent: { xs: "center", lg: "flex-end" },
                alignSelf: "stretch",
              }}
            >
              <LoadingButton
                loading={updatingCompany || isUpdatingSectors}
                variant="contained"
                disabled={
                  !!(
                    updatedSectors &&
                    Object.values(updatedSectors)?.some(
                      sector => !sector.buyingPrice
                    )
                  )
                }
                onClick={async () => {
                  if (
                    technicianSplit !==
                      brandLocationProfile?.defaultTechnicianSplit ||
                    openNetworkEnabled !==
                      brandLocationProfile?.openNetworkEnabled ||
                    payReferralFeeAfterJobCompletion !==
                      brandLocationProfile?.payReferralFeeAfterJobIsDone ||
                    payPerReferral !== brandLocationProfile?.payPerReferral ||
                    invitationMessage !==
                      brandLocationProfile?.invitationMessage ||
                    homeownerApproval !==
                      brandLocationProfile?.homeownerJobApprovalSettings ||
                    paymentMode !==
                      brandLocationProfile.payReferralFeeSettings ||
                    isUpsellingEnabled !==
                      brandLocationProfile?.upsellingEnabled
                  ) {
                    await updateCompany({
                      defaultTechnicianSplit: technicianSplit,
                      openNetworkEnabled,
                      upsellingEnabled: isUpsellingEnabled,
                      invitationMessage,
                      payReferralFeeAfterJobIsDone:
                        payReferralFeeAfterJobCompletion,
                      payPerReferral,
                      homeownerJobApprovalSettings: homeownerApproval,
                      payReferralFeeSettings: paymentMode,
                    });
                  }
                  if (updatedSectors) {
                    let newSectorsPayload = {};
                    if (payPerReferral && payReferralFeeAfterJobCompletion) {
                      newSectorsPayload = updatedSectors;
                    } else if (payReferralFeeAfterJobCompletion) {
                      newSectorsPayload =
                        extractPayAfterJobCompletionPayload(updatedSectors);
                    } else {
                      newSectorsPayload = extractLeadPayload(updatedSectors);
                    }

                    await updateSectors(Object.values(newSectorsPayload));
                  }

                  refreshSession();
                }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
