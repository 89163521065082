import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useGetJobById } from "../api";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface MarkInactiveJobDialogProps {
  isOpen: boolean;
  jobId: number;
  onCancel: () => void;
  onRemove: (reason: string) => void;
}

export const MarkInactiveJobDialog: React.FC<MarkInactiveJobDialogProps> = ({
  isOpen,
  jobId,
  onCancel,
  onRemove,
}) => {
  const [reason, setReason] = React.useState("");
  const [otherReason, setOtherReason] = React.useState<string>("");

  const { data: job } = useGetJobById(jobId);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason((event.target as HTMLInputElement).value);
  };

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="partner-removal-dialog-title"
        open={isOpen}
        maxWidth={"650px"}
        onClose={onCancel}
      >
        <Box sx={{ p: 3 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ p: 0, pb: 1.5 }}
          >
            <DialogTitle sx={{ m: 0, p: 0 }} id="partner-removal-dialog-title">
              <Typography variant="h5" color="error">
                Mark Job as Inactive?
              </Typography>
            </DialogTitle>
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={onCancel}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            gutterBottom
            variant="body2"
            color={"text.secondary"}
            maxWidth={"500px"}
          >
            Jobs marked inactive aren’t eligible for referral commission. Are
            you sure you want to mark{" "}
            <Typography
              display={"inline"}
              variant="body2"
              color="text.primary"
              fontWeight={600}
            >
              {job?.jobType}
            </Typography>{" "}
            inactive?
          </Typography>
          <br />
          <Typography
            sx={{ pb: 1.5 }}
            variant="body2"
            maxWidth={"500px"}
            color={"text.secondary"}
          >
            Before you go, please select the reason why this job didn’t move
            forward.
          </Typography>
          <FormControl sx={{ pb: 1.5 }}>
            <RadioGroup
              aria-labelledby="controlled-group-decline-job"
              name="controlled-group-decline-jobr"
              value={reason}
              sx={{ justifyContent: "flex-start" }}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Our company doesn’t offer these services, or it exceeds our scope of work"
                control={<Radio />}
                label={
                  <Typography variant="body2">
                    Our company doesn’t offer these services, or it exceeds our
                    scope of work
                  </Typography>
                }
              />
              <FormControlLabel
                value="Our schedule couldn’t accommodate this job"
                control={<Radio />}
                label={
                  <Typography variant="body2">
                    Our schedule couldn’t accommodate this job
                  </Typography>
                }
              />
              <FormControlLabel
                value="Homeowner wasn't ready to move forward"
                control={<Radio />}
                label={
                  <Typography variant="body2">
                    Homeowner wasn&apos;t ready to move forward
                  </Typography>
                }
              />
              <FormControlLabel
                value="Homeowner didn't respond after multiple attempts"
                control={<Radio />}
                label={
                  <Typography variant="body2">
                    Homeowner didn&apos;t respond after multiple attempts
                  </Typography>
                }
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label={<Typography variant="body2">Other</Typography>}
              />
            </RadioGroup>
          </FormControl>
          {reason === "Other"}
          <TextField
            multiline
            fullWidth
            id="otherReason"
            label="Tell us more"
            rows={4}
            maxRows={10}
            variant="filled"
            value={otherReason}
            onChange={event => {
              setOtherReason(event.target.value);
            }}
          />
          <DialogActions>
            <Button
              size="medium"
              variant="contained"
              disabled={!reason}
              color="error"
              onClick={() => {
                onRemove(reason !== "Other" ? reason : otherReason || "Other");
              }}
            >
              Yes, Update Job Status
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};
