import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useCreateInvitation(
  options: UseMutationOptions<
    void,
    AxiosError,
    { googlePlacesId?: string; emails: Array<string> }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { googlePlacesId?: string; emails: Array<string> }
> {
  return useMutation({
    mutationFn: async ({ googlePlacesId, emails }) => {
      await API.post(`companies/invitations`, {
        google_places_id: googlePlacesId,
        invited_person_emails: emails,
      });
    },
    ...options,
  });
}
