import {
  Box,
  Tooltip as MuiTooltip,
  TooltipProps,
  Typography,
} from "@mui/material";

export interface ToolTipProps {
  title?: string;
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
  placement?: TooltipProps["placement"];
}
export function ToolTip(props: ToolTipProps) {
  const { title, message, children, placement = "top" } = props;
  return (
    <MuiTooltip
      disableFocusListener
      title={
        <Box>
          {props.title ? (
            <Typography variant="subtitle2">{title}</Typography>
          ) : null}
          <Typography variant="caption">{message}</Typography>
        </Box>
      }
      placement={placement}
    >
      {children}
    </MuiTooltip>
  );
}
