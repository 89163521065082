import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, InputBaseComponentProps, TextField } from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { ElementType } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { DashboardTitle } from "@/common/DashboardTitle";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { Seo } from "@/common/Seo/Seo";
import { useUpdateOwnUser } from "@/user/api/useUpdateOwnUser";
import { getUSPhoneValidationSchema, NAME_REGEX } from "@/utils";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .matches(NAME_REGEX, "Enter a valid name")
    .required("First Name is required"),
  lastName: yup
    .string()
    .matches(NAME_REGEX, "Enter a valid name")
    .required("Last Name is required"),
  email: yup.string().email("Enter a valid email address").optional(),
  cellPhone: getUSPhoneValidationSchema(),
});

export function HomeownerPersonalSettingsPage() {
  const { session, refreshSession } = useAuth();
  const { isLoading: isUpdatingUser, mutateAsync: updateOwnUser } =
    useUpdateOwnUser();

  const formik = useFormik({
    initialValues: {
      firstName: session?.user?.firstName ?? "",
      lastName: session?.user?.lastName ?? "",
      cellPhone: session?.user?.cellPhone?.replace("+1", "") ?? "",
      email: session?.user?.email ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const { firstName, lastName, cellPhone, email } = values;
      try {
        updateOwnUser({
          firstName,
          lastName,
          email,
        });
        refreshSession();
        enqueueSnackbar({
          message: "Details updated!",
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar("There was an error while updating your details", {
          variant: "error",
        });
      }
    },
  });

  const valuesChanged =
    session?.user?.firstName !== formik.values.firstName ||
    session?.user?.email !== formik.values.email ||
    session?.user?.cellPhone !== formik.values.cellPhone ||
    session?.user?.lastName !== formik.values.lastName;

  return (
    <>
      <Seo title="Settings" />
      <Box>
        <DashboardTitle
          title="Profile"
          subtitle="Edit your personal information from here."
        />
        <Grid container sx={{ mt: { xs: 0, md: 3 } }} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              sx={{ my: 0 }}
              value={formik.values.firstName}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              sx={{ my: 0 }}
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled
              required
              fullWidth
              id="cellPhone"
              name="cellPhone"
              label="Mobile Number"
              value={formik.values.cellPhone}
              error={
                formik.touched.cellPhone && Boolean(formik.errors.cellPhone)
              }
              helperText={"Contact us to change your mobile number."}
              InputProps={{
                inputComponent: MaskedPhoneInput as unknown as
                  | ElementType<InputBaseComponentProps>
                  | undefined,
              }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            disabled={
              !formik.isValid ||
              (!formik.dirty && !Object.keys(formik.touched).length) ||
              !valuesChanged
            }
            loading={isUpdatingUser}
            variant="contained"
            sx={{ mt: 3, px: 5 }}
            startIcon={<SaveOutlinedIcon />}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}
