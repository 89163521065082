import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import numeral from "numeral";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CreateTechnicianJobDialog } from "@/common/CreateJobDialog/CreateTechnicianJobDialog";
import { DashboardTitle } from "@/common/DashboardTitle";
import { Seo } from "@/common/Seo/Seo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { JobDrawer } from "@/company/jobs/components/JobDrawer";
import { Job } from "@/company/jobs/types";
import { Source } from "@/company/jobs/types/job";
import { getLastActivityJob } from "@/company/jobs/utils";
import { CompanyRoutes } from "@/company/types";
import { useTechniciansPaymentsContext } from "@/technician/context/wallet-context";
import { useGetStripeAccountDetails } from "@/user/api/useGetStripeAccountDetails";
import { StripeAccountStatus } from "@/user/types";

import { TechnicianBalanceWithdrawDialog } from "../../TechnicianBalanceWithdrawDialog/TechnicianBalanceWithdrawDialog";
import { TechnicianTransactionCard } from "../../TechnicianTransactionCard/TechnicianTransactionCard";
import { AddBankAccountCard } from "./AddBankAccountCard";
import { BankAccountCard } from "./BankAccountCard";
import { StripeStepsCard } from "./StripeStepsCard";
import { StripeInfoCard } from "./StripInfoCard";

export function MyWalletPage() {
  const { enqueueSnackbar } = useSnackbar();

  const {
    balance,
    transactions,
    stripeStatus,
    isFetchingStatus,
    isFetchingTransactions,
    isWithdrawing,
    withdrawBalance,
  } = useTechniciansPaymentsContext();

  const { t } = useTranslation();

  const handleIconClick = () => {
    const url = "https://stripe.com/";
    window.open(url, "_blank");
  };
  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);

  const { isFetching: fetchingAccountDetails, data: accountDetails } =
    useGetStripeAccountDetails({
      refetchOnWindowFocus: false,
    });

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState<Job | null>();

  return (
    <>
      <Seo title={t("my_wallet")} />
      <Box>
        <DashboardTitle
          title={t("my_wallet")}
          subtitle={t("you_can_view_previous_transactions")}
          endButton={<Button />}
        />
        {isFetchingStatus ? (
          <Box mt={10} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container gap={4} mt={1}>
            <Grid item xs={12} lg={7.5}>
              <Grid container>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      p: 3,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      bgcolor: "rgba(22, 219, 101, 0.10)",
                      boxShadow: "0px 0px 0px 1px #F2F4F7",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Typography variant="overline" color="text.secondary">
                        {t("my_earnings")}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      gap={2}
                      alignItems={isDesktop ? "center" : "start"}
                      flexDirection={isDesktop ? "row" : "column"}
                      justifyContent={"space-between"}
                    >
                      <Typography variant="h4">
                        {balance
                          ? numeral(balance).format("$0,0.00")
                          : numeral(0).format("$0,0.00")}
                      </Typography>
                      {stripeStatus === StripeAccountStatus.OK ? (
                        <LoadingButton
                          loading={isWithdrawing}
                          disabled={
                            stripeStatus === StripeAccountStatus.OK &&
                            balance <= 0
                          }
                          startIcon={<MonetizationOnOutlinedIcon />}
                          variant="contained"
                          onClick={async () => {
                            if (stripeStatus === StripeAccountStatus.OK) {
                              setIsWithdrawDialogOpen(true);
                            }
                          }}
                        >
                          {t("withdraw_with_stripe")}
                        </LoadingButton>
                      ) : null}
                    </Box>
                  </Card>
                </Grid>
                {isDesktop ? (
                  <Grid container item xs={12} sx={{ my: 5 }}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        {t("transaction_history")}
                      </Typography>
                    </Grid>
                    {isFetchingTransactions ? (
                      <Box my={5} textAlign="center" width="100%">
                        <CircularProgress />
                      </Box>
                    ) : transactions && transactions.length > 0 ? (
                      transactions.map(transaction => (
                        <Grid
                          key={transaction.id}
                          item
                          xs={12}
                          {...(transaction.job
                            ? {
                                onClick: () => {
                                  setSelectedJob(transaction.job);
                                },
                              }
                            : {})}
                          sx={{
                            my: 1,
                            ...(transaction.job ? { cursor: "pointer" } : {}),
                          }}
                        >
                          <Divider sx={{ width: "100%", my: 1 }} />
                          <TechnicianTransactionCard
                            transaction={transaction}
                          />
                        </Grid>
                      ))
                    ) : (
                      <Box my={5} textAlign="center" width="100%">
                        <Box textAlign="center">
                          <img
                            src="/empty-list.svg"
                            height="100%"
                            style={{ margin: "16px" }}
                            alt="No Transactions Yet"
                          />
                          <Typography gutterBottom variant="h6">
                            No Transactions to Show
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Refer a job to earn referral commission!
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ my: 2 }}
                            onClick={() => {
                              setIsJobDialogOpen(true);
                            }}
                          >
                            Refer Job
                          </Button>
                        </Box>
                      </Box>
                    )}
                    <Grid item xs={12}>
                      <Divider sx={{ width: "100%", my: 1 }} />
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container gap={2}>
                {stripeStatus === StripeAccountStatus.OK ? (
                  !fetchingAccountDetails &&
                  !accountDetails ? null : fetchingAccountDetails ? (
                    <Box
                      justifyContent={"center"}
                      display="flex"
                      sx={{ width: "100%" }}
                      textAlign="center"
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Grid item xs={12}>
                      <BankAccountCard
                        accountDetails={accountDetails}
                        fetchingAccountDetails={fetchingAccountDetails}
                      />
                    </Grid>
                  )
                ) : (
                  <Grid item xs={12}>
                    <AddBankAccountCard
                      stripeStatus={stripeStatus}
                      isFetchingStatus={isFetchingStatus}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  {stripeStatus === StripeAccountStatus.OK ? (
                    <StripeInfoCard />
                  ) : (
                    <StripeStepsCard />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {isDesktop ? null : (
              <Grid item xs={12} lg={4}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {t("transaction_history")}
                    </Typography>
                  </Grid>
                  {isFetchingTransactions ? (
                    <Box my={5} textAlign="center" width="100%">
                      <CircularProgress />
                    </Box>
                  ) : transactions && transactions.length > 0 ? (
                    transactions.map(transaction => (
                      <Grid
                        key={transaction.id}
                        item
                        xs={12}
                        {...(transaction.job
                          ? {
                              onClick: () => {
                                setSelectedJob(transaction.job);
                              },
                            }
                          : {})}
                        sx={{
                          my: 1,
                          ...(transaction.job ? { cursor: "pointer" } : {}),
                        }}
                      >
                        <Divider sx={{ width: "100%", my: 1 }} />
                        <TechnicianTransactionCard transaction={transaction} />
                      </Grid>
                    ))
                  ) : (
                    <Box my={5} textAlign="center" width="100%">
                      <Box textAlign="center">
                        <img
                          src="/empty-list.svg"
                          height="100%"
                          style={{ margin: "16px" }}
                          alt="No Transactions Yet"
                        />
                        <Typography gutterBottom variant="h6">
                          No Transactions to Show
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Refer a job to earn referral commission!
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ my: 2 }}
                          onClick={() => {
                            setIsJobDialogOpen(true);
                          }}
                        >
                          Refer Job
                        </Button>
                      </Box>
                    </Box>
                  )}
                  <Grid item xs={12}>
                    <Divider sx={{ width: "100%", my: 1 }} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      {selectedJob ? (
        <JobDrawer
          source={Source.CALL_CENTER}
          open={!!selectedJob}
          jobId={selectedJob.id}
          jobStatus={selectedJob.status}
          lastActivity={getLastActivityJob(selectedJob)}
          onClose={() => {
            setSelectedJob(null);
          }}
        />
      ) : null}
      <TechnicianBalanceWithdrawDialog
        isOpen={isWithdrawDialogOpen}
        currentBalance={balance}
        isWithdrawing={isWithdrawing}
        withdrawBalance={withdrawBalance}
        onClose={() => setIsWithdrawDialogOpen(false)}
      />
      {isJobDialogOpen ? (
        <CreateTechnicianJobDialog
          isOpen={isJobDialogOpen}
          jobsListUrl={getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.MY_JOBS,
          ])}
          onClose={() => setIsJobDialogOpen(false)}
        />
      ) : null}
    </>
  );
}
