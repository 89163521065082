import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { useFetchDefaultSectorPreferences } from "@/company/api/useFetchDefaultPreferences";
import { JobSectors } from "@/company/jobs/constants";
import { useUpdateBrandLocationProfile } from "@/company/onboarding/api";
import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { CompanyRoutes } from "@/company/types";
import {
  snakeCaseJobSectors,
  snakeCaseJobSectorsEmojis,
} from "@/company/utils";

import { OnboardingContainer } from "../../container/OnboardingContainer";
import { useOnboardingReferralSkippedContext } from "../../PreviousLocationProvider";
import { OnboardingSteps } from "../../utils";

export function InboundReferralPreferences() {
  const navigate = useNavigate();
  const { session, refreshSession } = useAuth();
  const brandLocation = session?.brandLocationProfile;
  const { setInboundSkipped } = useOnboardingReferralSkippedContext();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const {
    data: defaultPreferenceSectors,
    isLoading: fetchingDefaultPreferenceSectors,
  } = useFetchDefaultSectorPreferences({
    enabled: !brandLocation?.inboundSectors?.length,
  });

  const [selectedSectors, setSelectedSectors] = useState<Array<JobSectors>>([]);

  const { isLoading: updatingCompany, mutateAsync: updateCompany } =
    useUpdateBrandLocationProfile(session?.brandLocationProfile?.id, {
      onError() {
        enqueueSnackbar("Error while updating company details.", {
          variant: "error",
        });
      },
      async onSuccess() {
        trackEvent(
          AnalyticsEvents.COMPANY_ONBOARDING_INBOUND_PREFERENCES_ADDED,
          {
            inboundPreferences: selectedSectors,
            companyId: session?.company?.id,
            source: "NEXT_BUTTON",
          }
        );
        await refreshSession();
        navigate(
          OnboardingSteps[ONBOARDING_STAGES.INBOUND_PREFERENCES].nextStepRoute
        );
      },
    });

  const handleSelectSector = (sector: JobSectors) => {
    setSelectedSectors(sectors => [...sectors, sector]);
  };

  const handleUnSelectSector = (sector: JobSectors) => {
    const newList = [...selectedSectors].filter(
      selectedSector => sector !== selectedSector
    );
    setSelectedSectors(newList);
  };

  const onNext = async () => {
    setInboundSkipped(false);
    await updateCompany({ inboundSectors: selectedSectors });
  };

  useEffect(() => {
    if (brandLocation?.inboundSectors?.length) {
      setSelectedSectors([...brandLocation.inboundSectors]);
    }
  }, [brandLocation?.inboundSectors]);

  useEffect(() => {
    if (
      !brandLocation?.inboundSectors?.length &&
      defaultPreferenceSectors?.length
    )
      setSelectedSectors([...defaultPreferenceSectors]);
  }, [defaultPreferenceSectors, brandLocation?.inboundSectors]);

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const mobileScreen = (
    <Box
      display="flex"
      sx={{
        flexGrow: 1,
        width: "100%",
      }}
      flexDirection={"column"}
      gap={1}
    >
      <Accordion
        expanded={expanded === "panel1"}
        sx={{
          borderRadius: "8px !important",
          width: "100%",
          p: "8px 24px",
          my: "0 !important",
          mx: "0 !important",
          overflow: "auto !important",

          // bgcolor: tradeEngageColors.darkBlue,
          "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
            height: "40px",
            minHeight: "40px",
          },
          "& .MuiAccordionSummary-root": {
            px: 0,
          },
          "& .MuiAccordionDetails-root": {
            overflow: "auto",
            p: "8px 24px",
            flexGrow: 1,
            pt: 0,
            pb: 2,
            px: 0,
          },
          "& .MuiAccordionDetails-root.Mui-expanded": {
            overflow: "auto",
          },
          "&:before": {
            content: "none",
          },
        }}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          {...(expanded === "panel1"
            ? {}
            : {
                expandIcon: (
                  <ExpandMoreIcon
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                ),
              })}
          sx={{
            "& .MuiAccordionSummary-content.Mui-expanded": {
              my: 1,
              mx: 0,
            },
          }}
        >
          {expanded === "panel1" ? (
            <Typography variant="overline" color="text.secondary">
              Top Service Categories
            </Typography>
          ) : (
            <Typography
              variant="h6"
              sx={{
                color: "#111927",
                fontSize: "15px",
                lineHeight: "26px",
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Select Others
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .MuiAccordionDetails-root": {
              p: 0,
            },
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              rowGap: "16px",
              columnGap: "16px",
              flexWrap: "wrap",
              alignItems: "center",
              overflow: "auto",
              pb: 2,
            }}
          >
            {Object.entries(snakeCaseJobSectors)
              ?.filter(
                ([snakeCaseSector, sector]: [string, string]) =>
                  !selectedSectors.includes(snakeCaseSector as JobSectors)
              )
              .map(([snakeCaseSector, sector]: [string, string], index) => {
                return (
                  <Chip
                    key={index}
                    icon={<AddOutlinedIcon />}
                    label={`${
                      snakeCaseJobSectorsEmojis[snakeCaseSector as JobSectors]
                    } ${sector}`}
                    sx={{
                      borderRadius: "12px",
                      background: "#E5E7EB",
                    }}
                    onClick={() => {
                      handleSelectSector(snakeCaseSector as JobSectors);
                    }}
                  />
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        sx={{
          borderRadius: "8px !important",
          width: "100%",
          p: "8px 24px",
          my: "0 !important",
          mx: "0 !important",
          // bgcolor: tradeEngageColors.darkBlue,
          "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
            height: "40px",
            minHeight: "40px",
          },
          "& .MuiAccordionSummary-root": {
            px: 0,
          },
          "& .MuiAccordionDetails-root": {
            pt: 0,
            pb: 2,
            px: 0,
          },
          "&:before": {
            content: "none",
          },
        }}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          {...(expanded === "panel2"
            ? {}
            : {
                expandIcon: (
                  <ExpandMoreIcon
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                ),
              })}
          sx={{
            "& .MuiAccordionSummary-content.Mui-expanded": {
              my: 1,
              mx: 0,
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#111927",
              fontSize: "15px",
              lineHeight: "26px",
              fontWeight: "600",
              wordWrap: "break-word",
            }}
          >
            {`Your Selection ${
              selectedSectors?.length ? `(${selectedSectors?.length})` : ""
            }`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .MuiAccordionDetails-root": {
              p: 0,
            },
          }}
        >
          <Typography gutterBottom variant="body2" color="text.secondary">
            These are your best referral sources.
          </Typography>
          <Box
            sx={{
              display: "flex",
              rowGap: "16px",
              columnGap: "4px",
              flexWrap: "wrap",
              alignItems: "center",
              overflow: "auto",
              bgcolor: "white",
            }}
          >
            {selectedSectors.map(sector => (
              <Card
                key={sector}
                sx={{
                  boxShadow: "none !important",
                  borderRadius: "12px",
                  overflow: "hidden",
                  border: "1px #E8ECEF solid",
                  p: 2,
                  "&:active": {
                    border: "1px solid #071D2F",
                  },
                  "&:focus": {
                    border: "1px solid #071D2F",
                  },
                  "&:hover": {
                    boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
                  },
                  width: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ px: 0, py: 0 }}
                >
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Avatar
                      variant="rounded"
                      sx={{
                        width: "40px",
                        height: "40px",
                        bgcolor: "#F8F9FA",
                      }}
                    >
                      <Typography variant="h5">
                        {snakeCaseJobSectorsEmojis[sector]}
                      </Typography>
                    </Avatar>
                    <Typography
                      sx={{
                        color: "#1A1D1F",
                        fontSize: 15,
                        fontWeight: "600",
                        lineHeight: "26px",
                        wordWrap: "break-word",
                      }}
                    >
                      {snakeCaseJobSectors[sector]}
                    </Typography>
                  </Box>
                  <CloseIcon
                    sx={{
                      cursor: "pointer",
                      color: "#6C737F",
                    }}
                    onClick={() => {
                      handleUnSelectSector(sector);
                    }}
                  />
                </Box>
                {/* <Card
                  sx={{
                    boxShadow: "0px 0px 0px 1px #F2F4F7;",
                    bgcolor: tradeEngageColors.darkBlue,
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    borderRadius: "8px",
                  }}
                >
                  <Accordion
                    sx={{
                      bgcolor: tradeEngageColors.darkBlue,
                      "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded":
                        {
                          height: "40px",
                          minHeight: "40px",
                        },
                      "& .MuiAccordionSummary-root": {
                        px: 1,
                      },
                      "& .MuiAccordionDetails-root": {
                        pt: 0,
                        pb: 2,
                        px: 4,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{ color: "#16DB65", cursor: "pointer" }}
                        />
                      }
                      sx={{
                        height: "20px",
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                          {
                            transform: "rotate(0deg)",
                          },
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          transform: "rotate(-90deg)",
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                          m: 0,
                        },
                        "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded":
                          {
                            height: "20px",
                            minHeight: "20px !important",
                          },
                        "& .MuiAccordionSummary-root.Mui-expanded": {
                          height: "20px",
                          minHeight: "20px !important",
                        },
                      }}
                    >
                      <Typography variant="overline" sx={{ color: "#16DB65" }}>
                        Example
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        "& .MuiAccordionDetails-root": {
                          p: 0,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 12,
                          fontWeight: "500",
                          lineHeight: "20px",
                          letterSpacing: 0.14,
                          wordWrap: "break-word",
                        }}
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Card> */}
              </Card>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  const desktopScreen = (
    <Card
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: "flex-start",
        columnGap: "8px",
        padding: "24px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: isMobile ? "100%" : "60%",
          flexGrow: 1,
          alignSelf: "stretch",
          overflow: "auto",
        }}
      >
        <Box width={"100%"} pb={2}>
          <Typography variant="overline" color="text.secondary">
            Top Service Categories
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            rowGap: "16px",
            columnGap: "16px",
            flexWrap: "wrap",
            alignItems: "center",
            overflow: "auto",
            pb: 2,
          }}
        >
          {Object.entries(snakeCaseJobSectors)
            ?.filter(
              ([snakeCaseSector, sector]: [string, string]) =>
                !selectedSectors.includes(snakeCaseSector as JobSectors)
            )
            .map(([snakeCaseSector, sector]: [string, string], index) => {
              return (
                <Chip
                  key={index}
                  icon={<AddOutlinedIcon />}
                  label={`${
                    snakeCaseJobSectorsEmojis[snakeCaseSector as JobSectors]
                  } ${sector}`}
                  sx={{
                    borderRadius: "12px",
                    background: "#E5E7EB",
                  }}
                  onClick={() => {
                    handleSelectSector(snakeCaseSector as JobSectors);
                  }}
                />
              );
            })}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          borderLeft: "1px #F3F4F6 solid",
          pl: 2,
          flexDirection: "column",
          alignItems: "flex-start",
          width: isMobile ? "100%" : "40%",
          flexGrow: 1,
          alignSelf: "stretch",
        }}
      >
        <Box width={"100%"} pb={1}>
          <Typography
            variant="h6"
            sx={{
              color: "#111927",
              fontSize: "15px",
              lineHeight: "26px",
              fontWeight: "600",
              wordWrap: "break-word",
            }}
          >
            {`Your Selection (${selectedSectors?.length})`}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            These are your best referral sources.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            rowGap: "16px",
            columnGap: "4px",
            flexWrap: "wrap",
            alignItems: "center",
            overflow: "auto",
            bgcolor: "white",
          }}
        >
          {selectedSectors.map(sector => (
            <Card
              key={sector}
              sx={{
                boxShadow: "none !important",
                borderRadius: "12px",
                overflow: "hidden",
                border: "1px #E8ECEF solid",
                p: 2,
                "&:active": {
                  border: "1px solid #071D2F",
                },
                "&:focus": {
                  border: "1px solid #071D2F",
                },
                "&:hover": {
                  boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
                },
                width: "100%",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ px: 1, py: 0 }}
              >
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: "40px",
                      height: "40px",
                      bgcolor: "#F8F9FA",
                    }}
                  >
                    <Typography variant="h5">
                      {snakeCaseJobSectorsEmojis[sector]}
                    </Typography>
                  </Avatar>
                  <Typography
                    sx={{
                      color: "#1A1D1F",
                      fontSize: 15,
                      fontWeight: "600",
                      lineHeight: "26px",
                      wordWrap: "break-word",
                    }}
                  >
                    {snakeCaseJobSectors[sector]}
                  </Typography>
                </Box>
                <CloseIcon
                  sx={{
                    cursor: "pointer",
                    color: "#6C737F",
                  }}
                  onClick={() => {
                    handleUnSelectSector(sector);
                  }}
                />
              </Box>
              {/* <Card
                sx={{
                  boxShadow: "0px 0px 0px 1px #F2F4F7;",
                  bgcolor: tradeEngageColors.darkBlue,
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  borderRadius: "8px",
                }}
              >
                <Accordion
                  sx={{
                    bgcolor: tradeEngageColors.darkBlue,
                    "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded":
                      {
                        height: "40px",
                        minHeight: "40px",
                      },
                    "& .MuiAccordionSummary-root": {
                      px: 1,
                    },
                    "& .MuiAccordionDetails-root": {
                      pt: 0,
                      pb: 2,
                      px: 4,
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ color: "#16DB65", cursor: "pointer" }}
                      />
                    }
                    sx={{
                      height: "20px",
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(0deg)",
                      },
                      "& .MuiAccordionSummary-expandIconWrapper": {
                        transform: "rotate(-90deg)",
                      },
                      "& .MuiAccordionSummary-content.Mui-expanded": {
                        m: 0,
                      },
                      "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded":
                        {
                          height: "20px",
                          minHeight: "20px !important",
                        },
                      "& .MuiAccordionSummary-root.Mui-expanded": {
                        height: "20px",
                        minHeight: "20px !important",
                      },
                    }}
                  >
                    <Typography variant="overline" sx={{ color: "#16DB65" }}>
                      Example
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      "& .MuiAccordionDetails-root": {
                        p: 0,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: 12,
                        fontWeight: "500",
                        lineHeight: "20px",
                        letterSpacing: 0.14,
                        wordWrap: "break-word",
                      }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Card> */}
            </Card>
          ))}
        </Box>
      </Box>
    </Card>
  );

  return (
    <OnboardingContainer
      heading="Share Your Referral Sources (What Types of Service Providers Give You Jobs?)"
      subheading="Select these service categories below. We’ve listed a few recommendations and examples of service providers who could be sending you jobs."
    >
      <Box
        display={"flex"}
        sx={{
          overflow: "auto",
          flexGrow: 1,
          ...(isMobile ? { padding: "8px 8px" } : { padding: "8px 24px" }),
          background: "#F3F4F6",
        }}
      >
        {isMobile ? mobileScreen : desktopScreen}
      </Box>
      <Stack
        spacing={2}
        mt={3}
        mb={2}
        mx={2}
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "end",
          alignSelf: "stretch",
        }}
      >
        <Button
          variant="text"
          sx={{
            px: 0,
          }}
          onClick={() => {
            setInboundSkipped(true);
            trackEvent(
              AnalyticsEvents.SKIPPED_COMPANY_ONBOARDING_ADDING_INBOUND_PREFERENCES_DATA,
              {
                selectedSectors,
                companyId: session?.company?.id,
                source: "SKIP_BUTTON",
              }
            );
            navigate(
              OnboardingSteps[ONBOARDING_STAGES.JOB_PRICE].nextStepRoute
            );
          }}
        >
          Skip for Now
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            navigate(
              `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.SERVICE_DETAILS}`,
              { replace: true }
            )
          }
        >
          Back
        </Button>
        <LoadingButton
          loading={updatingCompany}
          variant="contained"
          onClick={onNext}
        >
          Continue
        </LoadingButton>
      </Stack>
    </OnboardingContainer>
  );
}
