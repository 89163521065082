/* eslint-disable jsx-a11y/media-has-caption */
import { Close } from "@mui/icons-material";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";

import { CallRecordingNotification } from "@/company/api/useFetchCallNotificationsUnread";
import { useFetchCallRecordingById } from "@/company/api/useFetchCallRecordingById";
import { CallRecording, CallType } from "@/company/api/useFetchCallRecordings";
import {
  JobDrawer,
  JobDrawerTabsEnum,
} from "@/company/jobs/components/JobDrawer";
import { JobStatus, Source } from "@/company/jobs/types/job";
import { getLastActivityJob } from "@/company/jobs/utils";
import { PayReferralFeeSettings } from "@/company/types";
import { formatUSPhoneNumber } from "@/utils";

import CustomerRepresentative from "./components/CustomerRepresentative";
import HomeownerDetails from "./components/HomeownerDetails";
import JobDetails from "./components/JobDetails";

const ScheduledNotification = ({
  selectedRecordingNotification,
  selectedRecordingId,
  recording,
  hideCloseIcon,
  isMobile = false,
  onClose,
}: {
  selectedRecordingNotification: CallRecordingNotification;
  recording?: CallRecording;
  onClose: () => void;
  hideCloseIcon?: boolean;
  isMobile?: boolean;
  selectedRecordingId?: number;
}) => {
  const [defaultJobDrawerTab, setDefaultJobDrawerTab] =
    useState<JobDrawerTabsEnum>(JobDrawerTabsEnum.PROJECT);
  const [jobDrawerOpen, setJobDrawerOpen] = useState(false);
  const handleJobClick = async (showMessage?: boolean) => {
    if (showMessage) setDefaultJobDrawerTab(JobDrawerTabsEnum.MESSAGE);
    setJobDrawerOpen(true);
  };

  const { isLoading, data } = useFetchCallRecordingById(selectedRecordingId, {
    enabled: !!selectedRecordingId,
  });

  const selectedRecording = (recording ?? data) as CallRecording;

  const createdAt = DateTime.fromFormat(
    (selectedRecording?.createdAt ?? DateTime.now)
      .toString()
      .split(".")[0]
      .split("T")
      .join(" "),
    "yyyy-MM-dd HH:mm:ss",
    { zone: "utc" }
  );

  const todayStart = DateTime.local().startOf("day");

  const formattedDate =
    createdAt.toLocal() < todayStart
      ? createdAt.toLocal().toFormat("MM/dd/yy")
      : createdAt.toLocal().toFormat("h:mm a");
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        p={2}
        id="custom-scrollbar"
        sx={{ overflow: "auto", width: "100%" }}
      >
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
            gap={1}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              {selectedRecording?.type === CallType.INBOUND ? (
                <SouthEastOutlinedIcon
                  fontSize="large"
                  color="info"
                  sx={{
                    bgcolor: theme => theme.palette.info.light,
                    color: theme => theme.palette.info.main,
                    borderRadius: 30,
                    p: 1,
                    mr: 2,
                  }}
                />
              ) : (
                <NorthEastOutlinedIcon
                  fontSize="large"
                  sx={{
                    bgcolor: theme => theme.palette.primary.light,
                    color: theme => theme.palette.primary.main,
                    borderRadius: 30,
                    p: 1,
                    mr: 2,
                  }}
                />
              )}
              <Typography
                variant="overline"
                sx={{
                  color:
                    selectedRecording?.type === CallType.INBOUND
                      ? "#2970FF"
                      : "#16B364",
                }}
              >
                {selectedRecording?.type === CallType.INBOUND
                  ? `Inbound Call from ${formatUSPhoneNumber(
                      selectedRecording?.fromPhone ?? ""
                    )}`
                  : `Outbound Call to ${formatUSPhoneNumber(
                      selectedRecording?.toPhone ?? ""
                    )}`}
              </Typography>
            </Box>
            {hideCloseIcon ? null : (
              <IconButton onClick={() => onClose()}>
                <Close />
              </IconButton>
            )}
          </Box>
          <Typography variant="h6">Job Follow Up Identified!</Typography>{" "}
          <Typography variant="body1" color={"text.secondary"}>
            You just had a follow up with the homeowner for this job!
            {selectedRecording?.job?.homeId &&
            (selectedRecording?.job?.createdByCompanyId ===
              selectedRecording?.company?.id ||
              selectedRecording?.job?.status ===
                JobStatus.PENDING_REFERRAL_PAYMENT ||
              selectedRecording?.job?.status === JobStatus.PAID)
              ? "Message Partner now to update them."
              : ""}
          </Typography>
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={() => {
              handleJobClick();
            }}
          >
            Job Details
          </Button>
          {selectedRecording?.job?.homeId &&
          (selectedRecording?.job?.createdByCompanyId ===
            selectedRecording?.company?.id ||
            selectedRecording?.job?.status ===
              JobStatus.PENDING_REFERRAL_PAYMENT ||
            selectedRecording?.job?.status === JobStatus.PAID) ? (
            <Button
              variant={"outlined"}
              sx={{ width: "100%" }}
              onClick={() => {
                handleJobClick(true);
              }}
            >
              Message Partner
            </Button>
          ) : null}
        </Box>
        <Divider sx={{ borderColor: "#DCDFE4" }} />
        {selectedRecording?.company?.id ===
          selectedRecording?.job?.createdByCompanyId &&
        selectedRecording?.potentialReferralPartner &&
        selectedRecording.potentialReferralPartnerPayReferralSettings ===
          PayReferralFeeSettings.FIXED_AMOUNT ? (
          <>
            <Box
              sx={{
                borderRadius: "8px",
                p: 2,
                gap: "4px",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #16DB65",
                background: "rgba(185, 244, 209, 0.30)",
              }}
            >
              <Typography fontSize={"13px"} fontWeight={500} color={"#16B364"}>
                Your Instant Potential Commission
              </Typography>
              <Typography variant="h5">
                $
                {selectedRecording.potentialReferralPartnerSectors
                  ?.find(
                    sector => sector.sector === selectedRecording.job?.sector
                  )
                  ?.buyingPrice?.toFixed(2) ?? "25.00"}
              </Typography>
              <Typography variant="body2" color={"text.secondary"}>
                Refer this job now to earn instantly on TradeEngage!{" "}
              </Typography>
            </Box>
            <Divider sx={{ borderColor: "#DCDFE4" }} />
          </>
        ) : null}
        {selectedRecording?.job ? (
          <>
            <JobDetails selectedJob={selectedRecording?.job} />
            <Divider sx={{ borderColor: "#DCDFE4" }} />
          </>
        ) : null}
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography variant="overline" color={"text.secondary"}>
            User Details
          </Typography>
          {selectedRecording?.homeDetails ? (
            <HomeownerDetails homeDetails={selectedRecording?.homeDetails} />
          ) : null}
          {selectedRecording?.customerServiceRepresentative ??
          selectedRecordingNotification?.csrName ? (
            <CustomerRepresentative
              name={
                selectedRecording?.customerServiceRepresentative
                  ? `${selectedRecording?.customerServiceRepresentative?.firstName} ${selectedRecording?.customerServiceRepresentative?.lastName}`
                  : selectedRecordingNotification?.csrName ?? ""
              }
            />
          ) : null}
          <Divider sx={{ borderColor: "#DCDFE4" }} />
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography variant="overline" color={"text.secondary"}>
            Call Details
          </Typography>
          {selectedRecording?.job?.videoUrl ??
          selectedRecording?.audioPublicUrl ? (
            <>
              <Typography variant="subtitle2">Call Recording</Typography>
              <Typography variant="caption" color="text.secondary">
                Created At: {formattedDate}
              </Typography>
              <audio
                controls
                playsInline
                controlsList="nodownload"
                style={{
                  width: "100%",
                  borderRadius: 3,
                  colorScheme: "light",
                  height: "40px",
                  background: "white",
                }}
              >
                <source
                  src={
                    selectedRecording?.job?.videoUrl ??
                    selectedRecording?.audioPublicUrl
                  }
                  type="audio/mpeg"
                />
              </audio>
            </>
          ) : null}
          {selectedRecording?.transcription ? (
            <>
              <Typography variant="subtitle2">Transcript</Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign={"start"}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedRecording?.transcription,
                  }}
                ></span>
              </Typography>
            </>
          ) : null}
        </Box>
      </Box>
      {jobDrawerOpen && selectedRecording?.job ? (
        <JobDrawer
          source={Source.CALL_CENTER}
          defaultTab={defaultJobDrawerTab}
          open={!!selectedRecording.job}
          jobId={selectedRecording.job.id}
          jobStatus={selectedRecording.job?.status}
          lastActivity={getLastActivityJob(selectedRecording.job)}
          onClose={() => {
            setDefaultJobDrawerTab(JobDrawerTabsEnum.PROJECT);
            setJobDrawerOpen(false);
          }}
        />
      ) : null}
    </>
  );
};

export default ScheduledNotification;
