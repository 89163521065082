import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";
import { keysToSnakeCase } from "@/utils";

export function useDeleteCompanyLogo(
  companyId: number | undefined,
  options: UseMutationOptions<
    void,
    AxiosError,
    { isLogoDeleted?: boolean }
  > = {}
): UseMutationResult<void, AxiosError, { isLogoDeleted?: boolean }> {
  return useMutation({
    mutationFn: async ({ isLogoDeleted }) => {
      let companyPayload = {};

      if (isLogoDeleted) {
        companyPayload.fssLogoBucket = "";
        companyPayload.fssLogoKey = "";
      }
      companyPayload = keysToSnakeCase(companyPayload);
      const updateCompanyUri = `/internal-admin/companies/brand-location/${companyId}`;
      return API.patch(updateCompanyUri, { company: companyPayload });
    },
    ...options,
  });
}
