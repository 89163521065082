import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";

import { USER_TYPE } from "@/auth/types";
import { GoogleCompanyDrawer } from "@/common/GoogleCompanyDrawer/GoogleCompanyDrawer";
import { GoogleCompanyProfileDetails } from "@/common/GoogleCompanyDrawer/GoogleCompanyProfileDetails";
import { JobChatProvider } from "@/company/context/job-chat-context";
import { useGetJobById } from "@/company/jobs/api";
import { useUpdateJobView } from "@/company/jobs/api/useUpdateJobView";
import { JobMessage } from "@/company/jobs/components/JobMessageTab";
import { JobStatus } from "@/company/jobs/types";
import { neutral } from "@/Theme/colors";

import { HomeownerJobActionDialog } from "../HomeownerJobActionDialog/HomeownerJobActionDialog";
import { JobProjectTab } from "./JobProjectTab";

interface HomeownerJobDrawerProps {
  open: boolean;
  fullscreen?: boolean;
  onClose: () => void;
  jobId: number | null;
}

export enum TabsEnum {
  PROJECT = "Project",
  SERVICE_PROVIDER = "Service provider",
  MESSAGES = "Messages",
}

export const HomeownerJobDrawer: FC<HomeownerJobDrawerProps> = props => {
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const { jobId, onClose, open, fullscreen } = props;
  const [currentTab, setCurrentTab] = useState<TabsEnum>(TabsEnum.PROJECT);
  const [homeownerAction, setHomeownerAction] = useState<
    "DELAY" | "DECLINE" | "APPROVE" | null
  >(null);
  const [isCompanyDrawerOpen, setIsCompanyDrawerOpen] = useState(false);

  const {
    data: job,
    isFetching,
    isError: isErrorFetching,
  } = useGetJobById(jobId, { refetchOnWindowFocus: false });

  const handleTabsChange = useCallback(
    (_: ChangeEvent<unknown>, value: TabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  if (isErrorFetching) {
    enqueueSnackbar({
      message: "Unable to fetch job details.",
      variant: "error",
    });
  }

  const { mutateAsync: updateJobView } = useUpdateJobView({
    onError: error => {
      console.error("Error while adding job view by homeowner.", { error });
    },
  });

  useEffect(() => {
    if (job && jobId && !job.listingExtraInfo?.homeownerViewedAt) {
      updateJobView({ jobId });
    }
  }, [job, jobId]);

  const handleSchedulingUrlClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        PaperProps={{
          sx: { width: fullscreen ? "100%" : isDesktop ? 500 : "100%" },
        }}
        sx={{ p: 0 }}
        onClose={() => {
          setCurrentTab(TabsEnum.PROJECT);
          onClose();
        }}
      >
        <Paper
          sx={{
            height: "100vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            position={"sticky"}
            sx={{ top: 0, zIndex: 400, pt: 1, background: "white" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              px={3}
              pb={2}
              pt={3}
            >
              <Typography variant="h6">{job?.jobType}</Typography>
              <IconButton
                aria-label="close"
                sx={{
                  color: theme => theme.palette.grey[500],
                  padding: 0,
                }}
                onClick={() => {
                  setCurrentTab(TabsEnum.PROJECT);
                  onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Tabs sx={{ px: 3 }} value={currentTab} onChange={handleTabsChange}>
              <Tab value={TabsEnum.PROJECT} label="Project" />
              {job &&
              (job.listingExtraInfo?.potentialReferralForCompanyId ||
                job.listingExtraInfo?.routedToCompanyId ||
                job.listingExtraInfo?.buyingCompanyId) &&
              (job.listingExtraInfo?.buyingCompanyGooglePlacesId ||
                job.listingExtraInfo?.routedToCompanyGooglePlacesId ||
                job.listingExtraInfo
                  ?.potentialReferralForCompanyGooglePlacesId) ? (
                <Tab
                  value={TabsEnum.SERVICE_PROVIDER}
                  label="Service Provider"
                />
              ) : null}
              {(job?.createdByUserType === USER_TYPE.HOMEOWNER &&
                job?.buyingCompanyId) ||
              job?.createdByCompanyId ? (
                <Tab value={TabsEnum.MESSAGES} label="Messages" />
              ) : null}
            </Tabs>
            <Divider />
          </Box>
          {isFetching ? (
            <Box my={5} textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box pt={2} px={3} height={"100%"} overflow={"auto"}>
              {job ? (
                currentTab === TabsEnum.PROJECT ? (
                  <JobProjectTab
                    job={job}
                    openCompanyDrawer={() => setIsCompanyDrawerOpen(true)}
                    openServiceProviderTab={() =>
                      setCurrentTab(TabsEnum.SERVICE_PROVIDER)
                    }
                  />
                ) : currentTab === TabsEnum.MESSAGES ? (
                  <JobChatProvider jobId={job?.id}>
                    <JobMessage />
                  </JobChatProvider>
                ) : currentTab === TabsEnum.SERVICE_PROVIDER &&
                  (job.listingExtraInfo?.buyingCompanyGooglePlacesId ||
                    job.listingExtraInfo?.routedToCompanyGooglePlacesId ||
                    job.listingExtraInfo
                      ?.potentialReferralForCompanyGooglePlacesId) ? (
                  <GoogleCompanyProfileDetails
                    googlePlacesId={
                      job.listingExtraInfo?.buyingCompanyGooglePlacesId ||
                      job.listingExtraInfo?.routedToCompanyGooglePlacesId ||
                      job.listingExtraInfo
                        ?.potentialReferralForCompanyGooglePlacesId
                    }
                    companyName={
                      job.listingExtraInfo?.buyingCompanyName ||
                      job.listingExtraInfo?.routedToCompanyName ||
                      job.listingExtraInfo?.potentialReferralForCompanyName
                    }
                    isVerified={true}
                  />
                ) : null
              ) : null}
            </Box>
          )}
        </Paper>
        {job?.status &&
          [
            JobStatus.PENDING_HOMEOWNER_ACTION,
            JobStatus.HOMEOWNER_DELAYED,
          ].includes(job.status) &&
          currentTab === TabsEnum.PROJECT && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              position={"sticky"}
              bottom={0}
              bgcolor={"white"}
              p={3}
              sx={{ borderTop: `1px solid ${neutral[200]}` }}
            >
              <Stack direction="row" gap={2} justifyContent={"flex-start"}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setHomeownerAction("APPROVE")}
                >
                  Submit Request
                </Button>
                {JobStatus.PENDING_HOMEOWNER_ACTION === job?.status ? (
                  <Button
                    size="small"
                    color="inherit"
                    variant="outlined"
                    onClick={() => setHomeownerAction("DELAY")}
                  >
                    Delay
                  </Button>
                ) : null}
              </Stack>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => setHomeownerAction("DECLINE")}
              >
                Decline
              </Button>
            </Box>
          )}
        {job?.routeToHomeowner &&
        job?.listingExtraInfo?.buyingCompanySchedulingUrl &&
        currentTab !== TabsEnum.MESSAGES ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position={"sticky"}
            bottom={0}
            bgcolor={"white"}
            p={3}
            sx={{ borderTop: `1px solid ${neutral[200]}` }}
          >
            <Stack direction="row" gap={2} justifyContent={"flex-start"}>
              <Button
                sx={{
                  mr: 1,
                }}
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  handleSchedulingUrlClick(
                    job.listingExtraInfo?.buyingCompanySchedulingUrl ?? ""
                  );
                }}
              >
                Schedule Now
              </Button>
            </Stack>
          </Box>
        ) : null}
      </Drawer>
      <HomeownerJobActionDialog
        open={Boolean(homeownerAction)}
        job={job}
        action={homeownerAction}
        onClose={() => setHomeownerAction(null)}
      />
      <GoogleCompanyDrawer
        isOpen={isCompanyDrawerOpen}
        googlePlacesId={job?.listingExtraInfo?.createdByCompanyGooglePlacesId}
        onClose={() => setIsCompanyDrawerOpen(false)}
      />
    </>
  );
};
