import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useQueryCompanySearch } from "@/company/api/useQueryCompanySearch";

import GeographySearchComponent from "./GeographySearchComponent";
import HomeBusinessSearchList from "./HomeBusinessSearchList";
import OtherCriteriaSearchList from "./OtherCriteriaSearchList";
import OwnershipSearchList from "./OwnershipSearchList";
import ReferralNetworkSearchList from "./ReferralNetworkSearchList";
import ReviewAndQualitySearchList from "./ReviewAndQuality";
import { useInternalCompanySearchFilterContext } from "./useInternalCompanySearchFilter";

export enum TabsEnum {
  COMPANY_TYPE = "COMPANY_TYPE",
  OWNERSHIP = "OWNERSHIP",
  REFERRAL_NETWORK = "REFERRAL_NETWORK",
  REVIEW_AND_QUALITY_SIGNALS = "REVIEW_AND_QUALITY_SIGNALS",
  OTHER_CRITERIA = "OTHER_CRITERIA",
  SAVED_LIST = "SAVED_LIST",
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SearchCriteria() {
  const navigate = useNavigate();
  const { filters } = useInternalCompanySearchFilterContext();

  const [value, setValue] = useState(0);

  const { data: companies } = useQueryCompanySearch(
    0,
    25,
    { ...filters, countOnly: true },
    undefined,
    undefined,
    { refetchOnWindowFocus: false }
  );

  return (
    <Box
      p={3}
      bgcolor={"#F8F9FA"}
      sx={{ height: "100%" }}
      display={"flex"}
      flexDirection={"column"}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pb={2}
        width={"100%"}
      >
        <Typography variant="h5">
          Local Businesses & Franchises{" "}
          {companies?.total ? (
            <Typography
              variant="h5"
              display={"inline"}
              sx={{ color: "#16B364" }}
            >
              {companies?.total} Results
            </Typography>
          ) : null}
        </Typography>
        <Box display={"flex"} gap={2}>
          <Button variant="outlined" startIcon={<BookmarkBorderOutlinedIcon />}>
            Save Search
          </Button>
          <Button
            variant="contained"
            startIcon={<SearchOutlinedIcon />}
            onClick={() => {
              navigate(`/admin/company`);
            }}
          >
            Search Results
          </Button>
        </Box>
      </Box>

      <Box display={"flex"} flex={1} width={"100%"}>
        <Box
          width={"25%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          bgcolor={"#F8F9FA"}
          sx={{ borderTop: "1px solid #E5E7EB" }}
        >
          <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Typography variant="h6" py={2} px={1}>
              All Search Criteria
            </Typography>
            <Typography
              variant="subtitle1"
              py={2}
              px={1}
              sx={{
                cursor: "pointer",
                "&:hover": { bgcolor: "white", color: "#16B364" },
                ...(value === 0 ? { bgcolor: "white", color: "#16B364" } : {}),
              }}
              onClick={() => {
                setValue(0);
              }}
            >
              Company Type
            </Typography>
            <Typography
              variant="subtitle1"
              py={2}
              px={1}
              sx={{
                cursor: "pointer",
                "&:hover": { bgcolor: "white", color: "#16B364" },
                ...(value === 1 ? { bgcolor: "white", color: "#16B364" } : {}),
              }}
              onClick={() => {
                setValue(1);
              }}
            >
              Geography
            </Typography>
            <Typography
              sx={{
                cursor: "pointer",
                "&:hover": { bgcolor: "white", color: "#16B364" },
                ...(value === 2 ? { bgcolor: "white", color: "#16B364" } : {}),
              }}
              variant="subtitle1"
              py={2}
              px={1}
              onClick={() => {
                setValue(2);
              }}
            >
              Ownership
            </Typography>
            <Typography
              sx={{
                cursor: "pointer",
                "&:hover": { bgcolor: "white", color: "#16B364" },
                ...(value === 3 ? { bgcolor: "white", color: "#16B364" } : {}),
              }}
              variant="subtitle1"
              py={2}
              px={1}
              onClick={() => {
                setValue(3);
              }}
            >
              Referral Network
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                cursor: "pointer",
                "&:hover": { bgcolor: "white", color: "#16B364" },
                ...(value === 4 ? { bgcolor: "white", color: "#16B364" } : {}),
              }}
              py={2}
              px={1}
              onClick={() => {
                setValue(4);
              }}
            >
              Reviews & Quality Signals
            </Typography>
            <Typography
              variant="subtitle1"
              py={2}
              px={1}
              sx={{
                cursor: "pointer",
                "&:hover": { bgcolor: "white", color: "#16B364" },
                ...(value === 5 ? { bgcolor: "white", color: "#16B364" } : {}),
              }}
              onClick={() => {
                setValue(5);
              }}
            >
              Other Criteria
            </Typography>
            <Typography
              variant="subtitle1"
              py={2}
              px={1}
              sx={{
                cursor: "pointer",
                "&:hover": { bgcolor: "white", color: "#16B364" },
                ...(value === 6 ? { bgcolor: "white", color: "#16B364" } : {}),
              }}
              onClick={() => {
                setValue(6);
              }}
            >
              Saved List
            </Typography>
          </Box>
        </Box>
        <Box sx={{ bgcolor: "white" }} width={"75%"}>
          <TabPanel value={value} index={0}>
            <HomeBusinessSearchList />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GeographySearchComponent />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <OwnershipSearchList />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ReferralNetworkSearchList />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ReviewAndQualitySearchList />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <OtherCriteriaSearchList />
          </TabPanel>
          <TabPanel value={value} index={6}>
            Item Six
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}
