import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import {
  Box,
  Divider,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { AppConstants } from "@/common/constants";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import { formatUSPhoneNumber } from "@/utils";

export function Footer() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box zIndex={1000} sx={{ bgcolor: "#071D2F" }} width={"100%"}>
      <Box
        display={"flex"}
        py={3}
        rowGap={2}
        px={isMobile ? 3 : 16}
        alignItems={isMobile ? "start" : "center"}
        width={"100%"}
        justifyContent={"space-between"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Box>
          <TradeEngageLogo
            height="24px"
            logotype={TradeEngageLogoTypes.LOGO_LIGHT}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={isMobile ? "column" : "row"}
          gap={2}
        >
          <Box
            display={"flex"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              const mailtoLink = `mailto:info@tradeengage.com`;
              window.location.href = mailtoLink;
            }}
          >
            <MailOutlineOutlinedIcon
              fontSize="small"
              sx={{
                width: "20px",
                height: "20px",
                mr: 1,
                color: "#16DB65",
              }}
            />
            <Typography sx={{ color: "#D9DBE1" }} variant="body2">
              info@tradeengage.com
            </Typography>
          </Box>
          <Box
            display={"flex"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              window.open(`tel:${AppConstants.supportContactNumber}`, "_blank");
            }}
          >
            <PhoneIphoneOutlinedIcon
              fontSize="small"
              sx={{
                width: "20px",
                height: "20px",
                mr: 0.6,
                color: "#16DB65",
              }}
            />
            <Typography sx={{ color: "#D9DBE1" }} variant="body2">
              {formatUSPhoneNumber(AppConstants.supportContactNumber)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          height: "0.5px",
          borderColor: "#6C737F",
        }}
      />
      <Box
        display={"flex"}
        py={3}
        rowGap={2}
        px={isMobile ? 3 : 16}
        flexDirection={isMobile ? "column" : "row"}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography sx={{ color: "#D9DBE1" }} variant="body2">
            © 2024 TradeEngage LLC. All rights reserved
          </Typography>
        </Box>
        <Box display={"flex"}>
          <Typography px={1} display={"inline"} color="white">
            <Link href={"/open/terms-of-service"} rel="noreferrer">
              <Typography
                display="inline"
                sx={{ color: "#D9DBE1" }}
                variant="body2"
              >
                Terms and Conditions
              </Typography>
            </Link>{" "}
            <Typography px={1} display={"inline"} color="white">
              |
            </Typography>{" "}
            <Link href={"/open/privacy-policy"} rel="noreferrer">
              <Typography
                display="inline"
                sx={{ color: "#D9DBE1" }}
                variant="body2"
              >
                Privacy Policy
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
