import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { ElementType } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { Languages } from "@/common/constants/languages";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { Seo } from "@/common/Seo/Seo";
import {
  canUpdatePersonalInfo,
  isAdministratorOrOwner,
  isTechnician,
} from "@/common/utils/accessControl";
import { useUpdateOwnUser } from "@/user/api/useUpdateOwnUser";
import { NAME_REGEX } from "@/utils";

export function PersonalInformation() {
  const { session, refreshSession } = useAuth();

  const { t } = useTranslation();
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .matches(NAME_REGEX, "Enter a valid name")
      .required("First Name is required"),
    lastName: yup
      .string()
      .matches(NAME_REGEX, "Enter a valid name")
      .required("Last Name is required"),
    jobTitle: yup.string().required("Job Title is required"),
    email: yup.string().email("Please enter a valid email").optional(),
    preferredLanguage: yup
      .mixed<Languages>()
      .oneOf(Object.values(Languages))
      .required(),
  });
  const { isLoading: isUpdatingUser, mutateAsync: updateOwnUser } =
    useUpdateOwnUser({
      onError() {
        enqueueSnackbar("Unable to save changes.", {
          variant: "error",
        });
      },
      onSuccess() {
        enqueueSnackbar({
          message: "Updated profile successfully.",
          variant: "success",
        });
        refreshSession();
      },
    });

  const formik = useFormik({
    initialValues: {
      firstName: session?.user?.firstName ?? "",
      lastName: session?.user?.lastName ?? "",
      jobTitle: session?.user?.jobTitle ?? "",
      preferredLanguage: session?.user?.preferredLanguage ?? Languages.ENGLISH,
      email: session?.user?.email ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const { firstName, lastName, jobTitle, preferredLanguage, email } =
        values;
      try {
        updateOwnUser({
          firstName,
          lastName,
          jobTitle,
          email,
          preferredLanguage,
        }).then();
        refreshSession();
      } catch (error) {
        enqueueSnackbar("Unable to save changes.", {
          variant: "error",
        });
      }
    },
  });

  const valuesChanged =
    session?.user?.firstName !== formik.values.firstName ||
    session?.user?.lastName !== formik.values.lastName ||
    session?.user?.jobTitle !== formik.values.jobTitle ||
    session?.user?.preferredLanguage !== formik.values.preferredLanguage ||
    session?.user?.email !== formik.values.email;

  return (
    <>
      <Seo title={t("personal_information")} />
      <Grid container py={3}>
        <Grid item xs={12} lg={4} pr={4}>
          <Typography gutterBottom variant="h6">
            {t("personal_information")}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                id="firstName"
                name="firstName"
                label={t("first_name")}
                sx={{ my: 0 }}
                value={formik.values.firstName}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={
                  session?.groups?.some(canUpdatePersonalInfo)
                    ? formik.touched.firstName && formik.errors.firstName
                    : t("contact_your_admin_to_change_this")
                }
                disabled={!session?.groups?.some(canUpdatePersonalInfo)}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                name="lastName"
                label={t("last_name")}
                sx={{ my: 0 }}
                value={formik.values.lastName}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={
                  session?.groups?.some(canUpdatePersonalInfo)
                    ? formik.touched.lastName && formik.errors.lastName
                    : t("contact_your_admin_to_change_this")
                }
                disabled={!session?.groups?.some(canUpdatePersonalInfo)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="jobTitle"
                name="jobTitle"
                label={t("job_title")}
                value={formik.values.jobTitle}
                error={
                  formik.touched.jobTitle && Boolean(formik.errors.jobTitle)
                }
                helperText={
                  session?.groups?.some(canUpdatePersonalInfo)
                    ? formik.touched.jobTitle && formik.errors.jobTitle
                    : t("contact_your_admin_to_change_this")
                }
                disabled={!session?.groups?.some(canUpdatePersonalInfo)}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            {session?.groups?.some(isAdministratorOrOwner) ||
            session?.groups?.some(isTechnician) ? (
              <Grid item xs={12}>
                <FormControl sx={{ my: 2, width: "100%" }}>
                  <InputLabel id="preferred-language">
                    {t("preferred_language")}
                  </InputLabel>
                  <Select
                    fullWidth
                    id="preferredLanguage"
                    label={t("preferred_language")}
                    name="preferredLanguage"
                    sx={{ my: 1 }}
                    value={formik.values.preferredLanguage}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={Languages.ENGLISH}>{t("en")}</MenuItem>
                    <MenuItem value={Languages.SPANISH}>{t("es")}</MenuItem>
                  </Select>
                  <FormHelperText>
                    {t("this_will_be_used_for_translation_only")}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} />
      <Grid container py={3}>
        <Grid item xs={12} lg={4} pr={4}>
          <Typography gutterBottom variant="h6">
            {t("contact_information")}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                disabled
                fullWidth
                id="cellPhone"
                name="cellPhone"
                label={t("mobile_number")}
                value={session?.user?.cellPhone ?? ""}
                helperText={t("contact_us_to_change_mobile")}
                InputProps={{
                  inputComponent: MaskedPhoneInput as unknown as
                    | ElementType<InputBaseComponentProps>
                    | undefined,
                }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!session?.groups?.some(canUpdatePersonalInfo)}
                id="email"
                name="email"
                label={t("work_email_address")}
                value={formik.values.email}
                helperText={
                  session?.groups?.some(canUpdatePersonalInfo)
                    ? formik.touched.email && formik.errors.email
                    : t("contact_your_admin_to_change_this")
                }
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }} px={3}>
        <LoadingButton
          disabled={
            !formik.isValid ||
            (!formik.dirty && !Object.keys(formik.touched).length) ||
            !valuesChanged
          }
          loading={isUpdatingUser}
          variant="contained"
          sx={{ mt: 3 }}
          onClick={() => formik.handleSubmit()}
        >
          {t("save")}
        </LoadingButton>
      </Box>
    </>
  );
}
