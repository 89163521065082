import { Navigate, Outlet, useLocation } from "react-router-dom";

import { AuthRoutes } from "@/auth/constants";
import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { getDefaultLoginRedirectTo } from "@/auth/utils";
import { PublicRoutes } from "@/open/constants";

import { ComponentLoader } from "./ComponentLoader/ComponentLoader";

const ProtectedRoute = ({ userRole }: { userRole?: USER_TYPE }) => {
  const { isInitialized, isAuthenticated, session } = useAuth();
  const location = useLocation();

  if (!isInitialized) return <ComponentLoader />;

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        to={`/${AuthRoutes.BASE}/${AuthRoutes.LOGIN}${
          location.search ? `${location.search}&` : "?"
        }redirectTo=${encodeURIComponent(location.pathname)}`}
      />
    );
  }

  if (userRole && session?.user?.type !== userRole) {
    return <Navigate replace to={getDefaultLoginRedirectTo(session)} />;
  }

  if (
    session?.user?.type &&
    session?.user?.type !== USER_TYPE.HOMEOWNER &&
    !session?.company
  ) {
    return (
      <Navigate
        replace
        to={`/${PublicRoutes.BASE}/${PublicRoutes.COMPANY_SELECTION}`}
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
