/* eslint-disable jsx-a11y/media-has-caption */
import {
  Close as CloseIcon,
  FileUploadOutlined as FileUploadOutlinedIcon,
  VideoCallOutlined as VideoCallOutlinedIcon,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { isIOS } from "@/utils";
import { isDefined } from "@/utils/isDefined";

interface JobDetailsVideoFormProps {
  videoFile: File | null;
  setVideoFile: (file: File | null) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  isCreatingJob: boolean;
}

export function JobDetailsVideoForm(props: JobDetailsVideoFormProps) {
  const { videoFile, setVideoFile, fileInputRef, isCreatingJob } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [startBackdrop, setStartBackdrop] = useState(false);
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    if (startBackdrop && countdown > 0) {
      const timer = setInterval(() => {
        setTimeout(() => {
          setCountdown(prev => prev - 1);
        }, 150);
      }, 500);

      return () => clearInterval(timer);
    }
    if (countdown === 0) {
      if (startBackdrop && !isIOS()) {
        setStartBackdrop(false);
        fileInputRef.current?.click();
      }
    }
  }, [countdown, startBackdrop]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const onDrop = (files: File[]) => {
    const newFile = files && files.length > 0 ? files[0] : null;
    setVideoFile(newFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "video/mp4": [".mp4"],
      "video/quicktime": [".mov"],
      "video/x-msvideo": [".avi"],
    },
    onError: error => {
      enqueueSnackbar({
        message: error?.message,
        variant: "error",
      });
      console.error(error);
    },
    onDrop: onDrop,
    multiple: false,
  });

  return (
    <>
      <Backdrop
        sx={_theme => ({ color: "#fff", zIndex: 9999999 })}
        open={startBackdrop}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
            zIndex: 2000,
          }}
        >
          <Box
            sx={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              backgroundColor: "#071D2F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1,
              zIndex: 2000,
              boxShadow: `
              0 2px 4px rgba(0, 0, 0, 0.05),
              0 4px 8px rgba(0, 0, 0, 0.08),
              0 6px 12px rgba(0, 0, 0, 0.1)
            `,
            }}
          >
            <Typography variant="h1" color="white">
              {countdown}
            </Typography>
            <Typography
              variant="h6"
              fontSize={"15px"}
              fontWeight={600}
              width={"120px"}
              sx={{ cursor: "pointer", color: "#16DB65", textAlign: "center" }}
            >
              Describe what you see
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: "#071D2F",
              borderColor: "#071D2F",
              p: 2,
              borderRadius: "8px",
              textAlign: "center",
              boxShadow: `
                0 2px 4px rgba(0, 0, 0, 0.05),
                0 4px 8px rgba(0, 0, 0, 0.08),
                0 6px 12px rgba(0, 0, 0, 0.1)
              `,
            }}
          >
            <Typography gutterBottom variant="h6" sx={{ color: "#16DB65" }}>
              Ready to upload a job?
            </Typography>
            <Typography variant="h6" sx={{ color: "#16DB65" }}>
              Speak clearly for best results!
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Box display={"flex"} gap={1} flexDirection={"column"}>
        <Typography gutterBottom variant="body2" color="text.secondary">
          Record a brief video.{" "}
          <Typography
            variant="body2"
            display={"inline"}
            fontWeight={700}
            color="primary"
          >
            Speak clearly for the best results!
          </Typography>
        </Typography>
        {videoFile ? null : isMobile ? (
          <Button
            sx={{
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
              borderRadius: "8px",
              mt: 3,
            }}
            size="large"
            variant="contained"
            startIcon={<VideoCallOutlinedIcon />}
            onClick={async event => {
              event.preventDefault();
              event.stopPropagation();
              if (isIOS()) {
                fileInputRef.current?.click();
                return;
              }
              event.stopPropagation();
              setCountdown(3);
              setStartBackdrop(true);
            }}
          >
            Start Recording
          </Button>
        ) : (
          <Box
            sx={{
              p: 3,
              borderRadius: "20px",
              bgcolor: "#F8F9FA",
              border: "1px dashed var(--divider, #B5BCC4)",
              mb: 2,
              cursor: "pointer",
            }}
            width={"fit-content"}
            {...getRootProps()}
          >
            <Box
              gap={2}
              display={"flex"}
              alignItems={"center"}
              width={"fit-content"}
            >
              <IconButton
                size="small"
                sx={{
                  p: 0,
                  borderRadius: "100px",
                  width: "64px",
                  height: "64px",
                  bgcolor: "white",
                }}
              >
                <FileUploadOutlinedIcon sx={{ color: "#16B364" }} />
              </IconButton>
              <Box gap={0.5} display={"flex"} flexDirection={"column"}>
                <Typography variant="subtitle2" sx={{ color: "#111927" }}>
                  Drag and drop or{" "}
                  <Typography
                    variant="subtitle2"
                    display={"inline"}
                    sx={{ color: "#16B364" }}
                  >
                    browse
                  </Typography>
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontSize={"12px"}
                  fontWeight={400}
                  color="text.secondary"
                >
                  Supported Format MP4
                </Typography>
              </Box>
            </Box>
            <input {...getInputProps()} />
          </Box>
        )}
      </Box>
      {isDefined(videoFile) && !isCreatingJob && (
        <Box
          sx={{
            alignItems: "center",
            position: "relative",
            border: 1,
            borderRadius: 1,
            borderStyle: "dashed",
            borderColor: "6C737F",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            outline: "none",
            p: { md: 1, xs: 1 },
            bgcolor: "#F8F9FA",
          }}
        >
          <button
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              border: "none",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              cursor: "pointer",
              background: "transparent",
              zIndex: 1, // Ensure the button is above the video
            }}
            onClick={() => {
              setVideoFile(null);
            }}
          >
            <CloseIcon />
          </button>
          <video
            controls
            controlsList="nodownload"
            src={`${window.URL.createObjectURL(videoFile)}`}
            width="100%"
            height="200px"
            style={{ border: "1px solid black" }}
          />
        </Box>
      )}
    </>
  );
}
