import { JobSectors } from "@/company/jobs/constants";
import { Referral, ReferralInvitation } from "@/company/types";

function sortObjectKeysByArrayLength(
  obj: Record<JobSectors, Array<Referral | ReferralInvitation>>
) {
  const sortedEntries = Object.entries(obj).sort(
    ([, valuesA], [, valuesB]) => valuesB.length - valuesA.length
  );

  const sortedObject = Object.fromEntries(sortedEntries);
  return sortedObject;
}

export function getInitialData(): Record<
  JobSectors,
  Array<Referral | ReferralInvitation>
> {
  const result = {};
  Object.values(JobSectors).forEach(sector => {
    result[sector] = [];
  });
  return result;
}

export function getSortedSectorsData(
  data: Record<JobSectors, Array<Referral | ReferralInvitation>>,
  relevantSectorsList: Array<JobSectors>
): Record<JobSectors, Array<Referral | ReferralInvitation>> {
  const sortedCombinedData = sortObjectKeysByArrayLength(data) as Record<
    JobSectors,
    Array<Referral | ReferralInvitation>
  >;

  const sortedCombinedDataByRelevantJobSectors = {} as Record<
    JobSectors,
    Array<Referral | ReferralInvitation>
  >;

  relevantSectorsList?.forEach(sector => {
    if (sortedCombinedData[sector as JobSectors]?.length) {
      sortedCombinedDataByRelevantJobSectors[sector as JobSectors] =
        sortedCombinedData[sector as JobSectors];
    }
  });

  Object.keys(sortedCombinedData).forEach(sector => {
    if (
      !relevantSectorsList?.includes(sector as JobSectors) &&
      sortedCombinedData[sector as JobSectors]?.length
    ) {
      sortedCombinedDataByRelevantJobSectors[sector as JobSectors] =
        sortedCombinedData[sector as JobSectors];
    }
  });
  return sortedCombinedDataByRelevantJobSectors;
}
