import { Paper } from "@mui/material";

import { isDefined } from "@/utils/isDefined";

import { referralCampaignConfigurationSteps } from "../../constants";
import {
  ReferralCampaign,
  ReferralCampaignConfigurationStep,
} from "../../types";
import { CampaignBasicInfo } from "./CampaignBasicInfo";
import { CampaignCompensation } from "./CampaignCompensation";
import { CampaignGoal } from "./CampaignGoal";
import { CampaignSuccessCriteria } from "./CampaignSuccessCriteria";

interface ReferralCampaignDetailsProps {
  referralCampaign: Omit<ReferralCampaign, "id" | "createdAt" | "updatedAt">;
  activeStep?: ReferralCampaignConfigurationStep;
  onClose?: () => void;
}

export function ReferralCampaignDetails(props: ReferralCampaignDetailsProps) {
  const { referralCampaign, onClose, activeStep } = props;

  const indexOfActiveStep = isDefined(activeStep)
    ? referralCampaignConfigurationSteps.indexOf(activeStep)
    : undefined;

  return (
    <Paper
      sx={{
        padding: 3,
        height: "100%",
        gap: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CampaignBasicInfo
        referralCampaign={referralCampaign}
        indexOfActiveStep={indexOfActiveStep}
        onClose={onClose}
      />
      <CampaignGoal
        referralCampaign={referralCampaign}
        indexOfActiveStep={indexOfActiveStep}
      />
      <CampaignSuccessCriteria
        referralCampaign={referralCampaign}
        indexOfActiveStep={indexOfActiveStep}
      />
      <CampaignCompensation
        referralCampaign={referralCampaign}
        indexOfActiveStep={indexOfActiveStep}
      />
    </Paper>
  );
}
