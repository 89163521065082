import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import {
  isAdministratorOrOwner,
  isTechnician,
} from "@/common/utils/accessControl";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { LeaderboardResponse } from "@/company/api/useGetTechnicianLeaderboard";
import { CompanyRoutes } from "@/company/types";

export function EmptyScreen({
  leaderboardData,
}: {
  leaderboardData?: LeaderboardResponse[];
}) {
  const { session } = useAuth();
  const user = session?.user;
  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const ownRecord = user && leaderboardData?.find(tech => tech.id === user.id);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      gap={3}
      my={2}
      flex={1}
    >
      <img
        src="/empty-leaderboard.png"
        style={{ width: "132px", height: "128px" }}
        alt="no leaderboard thumbnail"
      />
      <Box textAlign={"center"}>
        <Typography
          gutterBottom
          variant="h6"
          sx={{ ...(isMobile ? { color: "white" } : {}) }}
        >
          No Leaderboard to Show
        </Typography>
        {ownRecord?.id === leaderboardData?.[0]?.id &&
        !session?.groups?.some(isAdministratorOrOwner) &&
        session?.groups?.some(isTechnician) ? null : (
          <Typography variant="body1" color={"text.secondary"}>
            Onboard your field team to start earning through job referrals and
            track their progress on the weekly leaderboard.
          </Typography>
        )}
      </Box>
      {ownRecord?.id === leaderboardData?.[0]?.id &&
      !session?.groups?.some(isAdministratorOrOwner) &&
      session?.groups?.some(isTechnician) ? null : (
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            navigate(
              getNavigationPathFromRoutePaths([
                CompanyRoutes.BASE,
                CompanyRoutes.TEAM_MEMBERS,
              ])
            );
          }}
        >
          Invite Team
        </Button>
      )}
    </Box>
  );
}
