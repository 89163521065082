import {
  getGridDateOperators,
  getGridNumericOperators,
  GridFilterOperator,
} from "@mui/x-data-grid-premium";
import _ from "lodash";
import numeral from "numeral";
import * as Yup from "yup";

import { US_MOBILE_REGULAR_EXPRESSION } from "../common/constants/UsPhoneRegExp";
import { getNavigationPathFromRoutePaths } from "../common/utils/getNavigationPathFromRoutePaths";
import { SearchedCompany } from "../company/api/useExploreConnections";
import { ChatMessage } from "../company/api/useFetchAdminChatsByBrandLocation";
import { Chat } from "../company/api/useFetchChats";
import { JobChat } from "../company/jobs/api/useFetchJobChats";
import {
  canReceiveSms,
  CompanyPublicProfile,
  CompanyRoutes,
} from "../company/types";
import { HomeownerRoutes } from "../homeowner/constants";

interface S3UrlInfo {
  bucket: string;
  objectKey: string;
}

export const URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

export const NAME_REGEX = /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-/.]+$/;

// Function to convert object keys to snake_case
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function keysToSnakeCase(obj: any): any {
  if (_.isArray(obj)) {
    return obj.map(item => keysToSnakeCase(item));
  } else if (_.isObject(obj) && !_.isFunction(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newObject: Record<string, any> = {};
    _.forEach(obj, (value, key) => {
      newObject[_.snakeCase(key)] = _.isObject(value)
        ? keysToSnakeCase(value)
        : value;
    });
    return newObject;
  }
  return obj;
}

export function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export const appendSearchParamsToRedirectTo = () => {
  const searchParams = new URLSearchParams(location.search);
  const excludeParam = "redirectTo";

  // Create a new URLSearchParams object to hold the filtered parameters
  const filteredParams = new URLSearchParams();

  // Iterate over each parameter and add it to the filteredParams if it's not the one to exclude
  searchParams.forEach((value, key) => {
    if (key !== excludeParam) {
      filteredParams.append(key, value);
    }
  });

  return filteredParams.toString() ? `?${filteredParams.toString()}` : "";
};

export function snakeCaseToTitleCase(snakeCaseString: string) {
  return snakeCaseString
    .toLowerCase()
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());
}

export function getUSPhoneValidationSchema() {
  return Yup.string()
    .required("Mobile Number is required")
    .transform(value => {
      // Transform the formatted phone number to a normalized version without special characters
      return value ? value.replace(/[\s()-]/g, "") : value;
    })
    .matches(US_MOBILE_REGULAR_EXPRESSION, "Must be a valid US mobile number")
    .max(10);
}

export function getInitials(title: string) {
  try {
    return (
      title.split(" ")[0][0].toUpperCase() +
      " " +
      (title.split(" ")[1]
        ? title.split(" ")[1][0].toUpperCase()
        : title.split(" ")[0][1].toUpperCase())
    );
  } catch {
    return "";
  }
}

export function formatToSingleDecimalPlace(num: number) {
  if (Math.floor(num) != num) {
    return numeral(num).format("0,0.00");
  }
  return numeral(num).format("0,0");
}

export const getPhonesFromCompanyProfile = (
  companyProfile: CompanyPublicProfile
) => {
  const phones = companyProfile?.owners
    ? companyProfile?.owners
        ?.filter(
          owner =>
            owner.phone &&
            owner.phoneCarrierType &&
            canReceiveSms(owner.phoneCarrierType)
        )
        ?.map(owner => owner.phone)
    : [];

  if (
    companyProfile.profile?.businessPhone &&
    companyProfile.profile?.businessPhoneCarrierType &&
    canReceiveSms(companyProfile.profile?.businessPhoneCarrierType)
  )
    phones.push(companyProfile.profile?.businessPhone);
  return phones;
};

export const getEmailsFromCompanyProfile = (
  companyProfile: CompanyPublicProfile
) => {
  const emails = companyProfile?.owners
    ? companyProfile?.owners
        ?.filter(owner => !!owner.email)
        ?.map(owner => owner.email)
    : [];

  if (companyProfile.profile?.businessEmail)
    emails.push(companyProfile.profile?.businessEmail);
  return emails;
};

export function getHeaderFromPath(path: string) {
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.ALL_JOBS,
      ])
    )
  ) {
    return "Jobs Dashboard";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.ANALYTICS,
      ])
    )
  ) {
    return "Analytics";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.LEADERBOARD,
      ])
    )
  ) {
    return "Leaderboard";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.MANAGE_PARTNERS,
      ])
    )
  ) {
    return "Manage Partners";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.EXPLORE_PARTNERS,
      ])
    )
  ) {
    return "Explore Partners";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.MY_JOBS,
      ])
    )
  ) {
    return "My Jobs";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.PROFILE,
      ])
    )
  ) {
    return "Profile";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.MY_WALLET,
      ])
    )
  ) {
    return "My Wallet";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.TEAM_MEMBERS,
      ])
    )
  ) {
    return "Team Members";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.COMPANY_SETTINGS,
      ])
    )
  ) {
    return "Settings";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.REFERRAL_PARTNERS,
      ])
    )
  ) {
    return "Referral Partners";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.JOB_CALLS,
      ])
    )
  ) {
    return "Call Analytics";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.MESSAGES,
      ])
    )
  ) {
    return "Messages";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.SUPPORT_MESSAGES,
      ])
    )
  ) {
    return "TradeEngage Messages";
  }

  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        HomeownerRoutes.BASE,
        HomeownerRoutes.JOBS_LIST,
      ])
    )
  ) {
    return "HomeHub Concierge";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        HomeownerRoutes.BASE,
        HomeownerRoutes.SERVICE_PROVIDERS,
      ])
    )
  ) {
    return "My Service Providers";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        HomeownerRoutes.BASE,
        HomeownerRoutes.SERVICE_PROVIDERS,
      ])
    )
  ) {
    return "My Service Providers";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        HomeownerRoutes.BASE,
        HomeownerRoutes.PERSONAL_SETTINGS,
      ])
    )
  ) {
    return "Profile";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.DASHBOARD_HOME,
      ])
    )
  ) {
    return "Hi, Welcome";
  }
  if (
    path.startsWith(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.JOB_PROMOTIONS,
      ])
    )
  ) {
    return "Refer These Promotions";
  }
  return "";
}

/* eslint-disable no-restricted-properties */
export const bytesToSize = (bytes: number, decimals = 2): string => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export function getS3KeysFromPresignedUrl(s3PresignedUrl: string): S3UrlInfo {
  const parts = s3PresignedUrl
    .replace("https://", "")
    .split(".s3-accelerate.amazonaws.com/");
  const bucket = parts[0];
  const objectKey = parts[1].split("?")[0];

  return {
    bucket,
    objectKey,
  };
}

export const timeout = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const formatUSPhoneNumber = (phoneNumber: string): string | null => {
  if (!phoneNumber) {
    return null;
  }
  // Remove any non-numeric characters from the input
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Use regular expressions to format the phone number
  const formattedPhoneNumber = cleanedPhoneNumber.replace(
    /^1?(\d{3})(\d{3})(\d{4})$/,
    "+1 ($1) $2-$3"
  );

  return formattedPhoneNumber;
};

export type ChatDate = { date: string; isDate: boolean; isToday?: boolean };

export const isTypeDate = (
  obj:
    | Chat
    | JobChat
    | ChatMessage
    | { date: string; isDate: boolean; isToday?: boolean }
): obj is { date: string; isDate: boolean; isToday?: boolean } => {
  return !!Object.hasOwn(obj, "isDate");
};

export const customCompanyProfileSort = (
  companyA: SearchedCompany,
  companyB: SearchedCompany
) => {
  // Compare precedence of a, b, and c
  const precedenceA = companyA.companyPublicProfile ? 0 : 1;
  const precedenceB = companyB.companyPublicProfile ? 0 : 1;
  return precedenceA - precedenceB;
};

export const getFileExtension = (filename?: string) => {
  if (typeof filename !== "string") {
    return null;
  }

  const parts = filename.split(".");
  if (!parts || parts.length === 1) {
    return null; // No extension found
  }

  return parts?.pop()?.toLowerCase();
};

export function camelToUnderscorePreserveHyphen(str: string): string {
  // Replace camelCase with underscores
  return str.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
}

type Sorting = {
  field: string;
  sort: string;
};

export function transformSortingFields(objects: Sorting[]): Sorting[] {
  return objects.map(obj => ({
    ...obj,
    field: obj.field.split("-").map(camelToUnderscorePreserveHyphen).join("-"),
  }));
}

export const getGridDateOperatorsDataGrid: GridFilterOperator<
  any,
  Date,
  any
>[] = getGridDateOperators().filter(
  operator => operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
);

export const getGridNumericOperatorsDataGrid = getGridNumericOperators().filter(
  operator => operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
);

export const mimeToExtension = (mimeType: string) => {
  const mimeToExtMap = {
    "application/pdf": "pdf",
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/gif": "gif",
    "image/bmp": "bmp",
    "image/tiff": "tiff",
    "image/svg+xml": "svg",
    "text/plain": "txt",
    "text/html": "html",
    "text/csv": "csv",
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "docx",
    "application/vnd.ms-excel": "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/vnd.ms-powerpoint": "ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "pptx",
    "application/zip": "zip",
    "application/x-rar-compressed": "rar",
    "application/postscript": "eps",
    "application/json": "json",
    "application/octet-stream": "bin",
    "audio/mpeg": "mp3",
    "audio/wav": "wav",
    "video/mp4": "mp4",
    "video/quicktime": "mov",
    "video/x-msvideo": "avi",
    "video/webm": "webm",
    "video/ogg": "ogv",
  };
  return mimeToExtMap?.[mimeType] || "unknown"; // Default to 'unknown' if MIME type is not in the map
};

const mimeTypes = {
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  gif: "image/gif",
  bmp: "image/bmp",
  tiff: "image/tiff",
  svg: "image/svg+xml",
  txt: "text/plain",
  csv: "text/csv",
  zip: "application/zip",
  rar: "application/x-rar-compressed",
  eps: "application/postscript",
  mp3: "audio/mpeg",
  wav: "audio/wav",
  "audio/mp4": "audio/mp4",
  mp4: "video/mp4",
  mov: "video/quicktime",
  quicktime: "video/quicktime",
  avi: "video/x-msvideo",
  json: "application/json", // Keep this if needed, otherwise you can remove it.
};

export const getMimeTypeFromExtension = (extension: string) => {
  const ext = extension.toLowerCase();
  return mimeTypes[ext] || "application/octet-stream"; // Fallback for unknown extensions
};
