import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useDeleteRecommendedPartners(
  options: UseMutationOptions<
    void,
    AxiosError,
    { companyId: number; recommendedPartnerId: number }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { companyId: number; recommendedPartnerId: number }
> {
  return useMutation({
    mutationFn: async ({ companyId, recommendedPartnerId }) => {
      const uri = `/internal-admin/companies/brand-location/${companyId}/recommended-partners`;
      await API.delete(uri, {
        data: { recommended_company_id: recommendedPartnerId },
      });
    },
    ...options,
  });
}
