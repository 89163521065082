import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { StripeAccountStatus } from "../types";

export const GET_USER_STRIPE_STATUS_URI = "/users/stripe/status";

export function useGetUserStripeStatus(
  options?: UseQueryOptions<any>
): UseQueryResult<StripeAccountStatus> {
  return useQuery({
    queryKey: [GET_USER_STRIPE_STATUS_URI],
    queryFn: async () => {
      const response = await API.get(GET_USER_STRIPE_STATUS_URI);
      return response.data.data;
    },
    ...options,
  });
}
