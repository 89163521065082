import "./styles.css"; // Import your CSS file containing the styles

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Card,
  Container,
  LinearProgress,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import {
  AuthRoutes,
  MIN_LOGIN_OTP_REQUEST_INTERVAL_IN_SECONDS,
} from "@/auth/constants";
import { FIRST_CAMPAIGN_LOGIN_KEY, useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { getDefaultLoginRedirectTo } from "@/auth/utils";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import { Seo } from "@/common/Seo/Seo";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";
import { PublicRoutes } from "@/open/constants";
import { formatUSPhoneNumber } from "@/utils";

const validationSchema = yup.object({
  otp: yup.string().min(6).max(6).required("Otp is required"),
});

export function VerifyOtpPage() {
  const navigate = useNavigate();
  const { signIn, loginRequestData, requestLoginOtp } = useAuth();
  const [searchParams] = useSearchParams();
  const phone = searchParams.get("phone");

  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [alert, setAlert] = useState<string | null>(null);

  const [isRequestingOTP, setIsRequestingOTP] = useState(false);

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ otp }) => {
      if (loginRequestData) {
        try {
          setLoading(true);
          const session = await signIn({
            ...(loginRequestData.cellPhone || phone
              ? { cellPhone: loginRequestData.cellPhone ?? phone }
              : {}),
            ...(loginRequestData.email
              ? { email: loginRequestData.email }
              : {}),
            otp,
          });
          if (
            session?.company?.accountType === COMPANY_ACCOUNT_TYPES.SUPERADMIN
          ) {
            navigate(getNavigationPathFromRoutePaths(["admin", "company"]), {
              replace: true,
            });
            return;
          }
          if (
            session?.company?.accountType ===
            COMPANY_ACCOUNT_TYPES.FAMILY_BRANDS
          ) {
            navigate(
              getNavigationPathFromRoutePaths(["admin", "bi", "brand-overlap"]),
              {
                replace: true,
              }
            );
            return;
          }
          if (
            session?.company?.accountType === COMPANY_ACCOUNT_TYPES.CONSULTANT
          ) {
            navigate(getNavigationPathFromRoutePaths(["consultant", "home"]), {
              replace: true,
            });
            return;
          }
          if (
            session?.user?.type !== USER_TYPE.HOMEOWNER &&
            !session?.company
          ) {
            return navigate(
              `${getNavigationPathFromRoutePaths([
                PublicRoutes.BASE,
                PublicRoutes.COMPANY_SELECTION,
              ])}`,
              { replace: true }
            );
          }
          if (
            loginRequestData.redirectTo === "null" ||
            loginRequestData.redirectTo?.startsWith("null")
          ) {
            return navigate(getDefaultLoginRedirectTo(session), {
              replace: true,
            });
          }

          navigate(
            loginRequestData.redirectTo ?? getDefaultLoginRedirectTo(session),
            { replace: true }
          );
        } catch (error: unknown) {
          const message = doesErrorHaveMessage(error)
            ? error.message
            : "There was an error while validating your OTP";
          setAlert(message);
        }
        setLoading(false);
      } else if (phone) {
        try {
          setLoading(true);
          const session = await signIn({
            ...(phone ? { cellPhone: phone } : {}),
            otp,
          });
          localStorage.setItem(FIRST_CAMPAIGN_LOGIN_KEY, "true");

          navigate(getDefaultLoginRedirectTo(session), { replace: true });
        } catch (error: unknown) {
          localStorage.removeItem(FIRST_CAMPAIGN_LOGIN_KEY);

          const message = doesErrorHaveMessage(error)
            ? error.message
            : "There was an error while validating your OTP";
          setAlert(message);
        }
        setLoading(false);
      } else {
        setAlert("Please try to login again");
      }
    },
  });

  const resendOTP = async () => {
    if (loginRequestData) {
      // Perform your OTP resend API call here
      try {
        setIsRequestingOTP(true);
        formik.setFieldValue("otp", "");
        await requestLoginOtp({
          ...(loginRequestData.cellPhone || phone
            ? { cellPhone: loginRequestData.cellPhone ?? phone }
            : {}),
          ...(loginRequestData.email ? { email: loginRequestData.email } : {}),
          redirectTo: loginRequestData?.redirectTo,
        });
        startTimer();
      } catch (error: unknown) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "There was an error while requesting your OTP";
        setAlert(message);
      }
      setIsRequestingOTP(false);
    } else if (phone) {
      // Perform your OTP resend API call here
      try {
        setIsRequestingOTP(true);
        formik.setFieldValue("otp", "");
        await requestLoginOtp({
          ...(phone ? { cellPhone: phone } : {}),
        });
        startTimer();
      } catch (error: unknown) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "There was an error while requesting your OTP";
        setAlert(message);
      }
      setIsRequestingOTP(false);
    } else {
      setAlert("Please try to login again");
    }
  };

  const startTimer = () => {
    setTimer(MIN_LOGIN_OTP_REQUEST_INTERVAL_IN_SECONDS); // Reset the timer to its initial value
    const interval = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(interval); // Stop the timer when it reaches 0
          return 0;
        }
      });
    }, 1000); // Update the timer every 1 second
  };

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <>
      <Seo title="Verify OTP" />
      <Container maxWidth="xs">
        {phone ? null : (
          <Box
            display={"flex"}
            position={"absolute"}
            top={"80px"}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon sx={{ mr: 1 }} />
            <Typography variant="subtitle2">Back</Typography>
          </Box>
        )}
        <Box
          height={"100vh"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box pb={2} py={4}>
            <TradeEngageLogo logotype={TradeEngageLogoTypes.LOGO_DARK} />
          </Box>
          <Box pb={4}>
            <Card sx={{ pt: 4, px: 3, pb: 3, width: "100%" }}>
              <Typography variant="h5" align="left">
                Verify Your{" "}
                {`${
                  loginRequestData?.cellPhone || phone
                    ? "Mobile Number"
                    : "Email Address"
                }`}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                align="left"
                py={1}
              >
                {`We've sent a 6-digit code to ${
                  loginRequestData?.cellPhone || phone
                    ? `${formatUSPhoneNumber(
                        loginRequestData?.cellPhone ?? phone
                      )?.replace("+1", "")}`
                    : `${loginRequestData?.email}`
                }, please enter the confirmation code in the below box to verify your ${
                  loginRequestData?.cellPhone || phone
                    ? "mobile number"
                    : "email address"
                }`}
              </Typography>
              <Box
                component={"form"}
                sx={{
                  display: "flex",
                  width: "100%",
                  pt: 3,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <OtpInput
                  inputType="number"
                  shouldAutoFocus={true}
                  value={formik.values.otp}
                  numInputs={6}
                  renderSeparator={<span style={{ width: "20px" }}></span>}
                  skipDefaultStyles={true}
                  containerStyle={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  renderInput={props => (
                    <input
                      {...props}
                      className={`noSpinner ${alert ? " error" : ""}`}
                      style={{
                        padding: "8px",
                        border: "1px solid rgb(229, 231, 235)",
                        width: "15%",
                        maxWidth: "40px",
                        height: "40px",
                        textAlign: "center",
                        WebkitAppearance: "none",
                        margin: 0,
                        borderRadius: "8px",
                        fontSize: "16px",
                      }}
                      onKeyUp={key =>
                        key.code === "Enter" && formik.handleSubmit()
                      }
                    />
                  )}
                  onChange={value => formik.setFieldValue("otp", value)}
                />
              </Box>
              {timer > 0 ? (
                <Typography color="text.secondary" variant="body2" my={2}>
                  Send code again in {formatTime(timer)}
                </Typography>
              ) : isRequestingOTP ? (
                <LinearProgress sx={{ height: "5px", my: 4 }} />
              ) : (
                <Typography color="text.secondary" variant="body2" my={2}>
                  Didn&apos;t get the code?{" "}
                  <Link
                    underline="hover"
                    variant="subtitle2"
                    sx={{ cursor: "pointer" }}
                    onClick={resendOTP}
                  >
                    Resend Now
                  </Link>
                </Typography>
              )}
              <LoadingButton
                fullWidth
                variant="contained"
                loading={loading}
                disabled={
                  isRequestingOTP ||
                  !formik.isValid ||
                  (!formik.dirty && !Object.keys(formik.touched).length)
                }
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Verify
              </LoadingButton>
              {alert ? (
                <Alert
                  severity="error"
                  color="error"
                  variant="standard"
                  sx={{ mt: 2 }}
                  onClose={() => setAlert(null)}
                >
                  {alert}
                </Alert>
              ) : null}
            </Card>
          </Box>
          <Box display="flex" pb={3}>
            <Typography variant="body2" color="text.secondary">
              Already have an account?{" "}
              <Link
                href={`/${AuthRoutes.BASE}/${AuthRoutes.LOGIN}`}
                color="#16B364"
                variant="subtitle2"
                rel="noreferrer"
                underline="none"
              >
                Sign In
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
}
