import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { USER_TYPE } from "@/auth/types";
import API from "@/common/api";
import { Languages } from "@/common/constants/languages";
import { keysToSnakeCase } from "@/utils";

export interface UpdateOwnUserPayload {
  preferredLanguage?: Languages;
  acceptedWhatsappMessagingTerms?: boolean;
  acceptedTermsAndConditions?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  jobTitle?: string;
  cellPhone?: string;
  type?: USER_TYPE;
}

export const UPDATE_OWN_USER_URI = "/users/me";

export function useUpdateOwnUser(
  options: UseMutationOptions<void, AxiosError, UpdateOwnUserPayload> = {}
): UseMutationResult<void, AxiosError, UpdateOwnUserPayload> {
  return useMutation({
    mutationFn: async (userDetails: UpdateOwnUserPayload) => {
      const userPayload = keysToSnakeCase(userDetails);
      await API.patch(UPDATE_OWN_USER_URI, userPayload);
    },
    ...options,
  });
}
