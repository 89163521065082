import {
  ReferralCampaignConfigurationStep,
  ReferralCampaignNotificationChannel,
  ReferralCampaignPrizeType,
  ReferralCampaignSendUpdatesFrequency,
  ReferralCampaignTargetParticipants,
} from "./types";

export const referralCampaignConfigurationSteps = [
  ReferralCampaignConfigurationStep.CAMPAIGN_BRANDING,
  ReferralCampaignConfigurationStep.CAMPAIGN_GEOGRAPHY,
  ReferralCampaignConfigurationStep.CAMPAIGN_BASIC_INFO,
  ReferralCampaignConfigurationStep.CAMPAIGN_GOAL,
  ReferralCampaignConfigurationStep.CAMPAIGN_SUCCESS_CRITERIA,
  ReferralCampaignConfigurationStep.CAMPAIGN_REWARDS,
  ReferralCampaignConfigurationStep.CAMPAIGN_ADDITIONAL_SETTINGS,
];

export const referralCampaignConfigurationStepLabels: Record<
  ReferralCampaignConfigurationStep,
  string
> = {
  [ReferralCampaignConfigurationStep.CAMPAIGN_BRANDING]: "Branding",
  [ReferralCampaignConfigurationStep.CAMPAIGN_GEOGRAPHY]: "Geography",
  [ReferralCampaignConfigurationStep.CAMPAIGN_BASIC_INFO]: "Basic Info",
  [ReferralCampaignConfigurationStep.CAMPAIGN_GOAL]: "Goal",
  [ReferralCampaignConfigurationStep.CAMPAIGN_SUCCESS_CRITERIA]:
    "Success Criteria",
  [ReferralCampaignConfigurationStep.CAMPAIGN_REWARDS]: "Compensation & Prizes",
  [ReferralCampaignConfigurationStep.CAMPAIGN_ADDITIONAL_SETTINGS]:
    "Additional Settings",
};

export const referralCampaignPrizeTypeLabels: Record<
  ReferralCampaignPrizeType,
  string
> = {
  [ReferralCampaignPrizeType.CASH]: "Cash",
  [ReferralCampaignPrizeType.GIFTCARD]: "Giftcard",
  [ReferralCampaignPrizeType.TICKETS]: "Tickets",
  [ReferralCampaignPrizeType.OTHERS]: "Others",
};

export const referralCampaignNotificationChannelLabels: Record<
  ReferralCampaignNotificationChannel,
  string
> = {
  [ReferralCampaignNotificationChannel.EMAIL]: "Email",
  [ReferralCampaignNotificationChannel.TEXT]: "Text",
  [ReferralCampaignNotificationChannel.IN_APP]: "In-App",
};

export const referralCampaignSendUpdatesFrequencyLabels: Record<
  ReferralCampaignSendUpdatesFrequency,
  string
> = {
  [ReferralCampaignSendUpdatesFrequency.DAILY]: "Daily",
  [ReferralCampaignSendUpdatesFrequency.WEEKLY]: "Weekly",
  [ReferralCampaignSendUpdatesFrequency.MONTHLY]: "Monthly",
};

export const referralCampaignTargetParticipantsLabels: Record<
  ReferralCampaignTargetParticipants,
  string
> = {
  [ReferralCampaignTargetParticipants.ADMIN_AND_OWNERS]: "Admin & Owners",
  [ReferralCampaignTargetParticipants.OPERATIONS]: "Operations",
  [ReferralCampaignTargetParticipants.FIELD_TEAM]: "Field Team",
};

export const referralCampaignGeographyOptions = [
  "Southeast",
  "Northeast",
  "Midwest",
  "South",
  "West",
];
