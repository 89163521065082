import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { User } from "@/auth/types";
import API from "@/common/api";

import { CompanyGroup } from "../types";

export interface UserWithGroups {
  user: User;
  groups: CompanyGroup[];
}
export function useFetchUsersWithGroups(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<UserWithGroups>> {
  const uri = `/groups/users`;

  return useQuery({
    queryKey: [uri],
    queryFn: async () => {
      const response = await API.get(uri);
      return response.data.data;
    },
    ...options,
  });
}
