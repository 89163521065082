import { Route } from "react-router-dom";

import AnalyticsHubPage from "@/analytics/screens/AnalyticsHub";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";

import { ConsultantDashboardLayout } from "./DashboardLayout";

export function ConsultantRouter() {
  return (
    <Route path={"consultant"} element={<ConsultantDashboardLayout />}>
      <Route
        path={"home"}
        loader={ComponentLoader}
        element={<AnalyticsHubPage />}
      />
    </Route>
  );
}
