import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { User } from "@/auth/types";
import API from "@/common/api";

import { JobSectors } from "../jobs/constants";
import { Job } from "../jobs/types";
import { CallCategory, Home, RoutedJobResponse } from "../jobs/types/job";
import { Company, CompanySector, PayReferralFeeSettings } from "../types";

export const GET_CALL_RECORDINGS = "/call-recordings";

export enum CallRecordingStatus {
  NEW = "NEW",
  NOTIFIED = "NOTIFIED",
}

export enum CallType {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
}

export interface HomeDetails {
  homeowner: Partial<User>;
  home: Partial<Home>;
}

export interface CallRecording {
  id: number;
  userId: string;
  toPhone: string;
  fromPhone: string;
  transcription: string;
  transcriptionFormatted?: string;
  createdAt: string;
  company?: Partial<Company>;
  potentialReferralPartner?: Partial<Company>;
  job?: Job;
  homeDetails?: HomeDetails;
  status: CallRecordingStatus;
  type?: CallType;
  jobRouting?: RoutedJobResponse;
  homeownerZipCode?: string;
  homeownerFullAddress?: string;
  homeownerPhone?: string;
  homeownerName?: string;
  referringBusinessName?: string;
  referringBusiness?: Partial<Company>;
  referredBusinessName?: string;
  referredBusiness?: Partial<Company>;
  jobSector?: JobSectors;
  jobSummary?: string;
  jobType?: string;
  callSummary?: string;
  customerServiceRepresentative?: User;
  label?: CallCategory;
  isThereAReferral: boolean;
  audioPublicUrl?: string;
  potentialReferralPartnerPayReferralSettings?: PayReferralFeeSettings;
  potentialReferralPartnerSectors?: Array<CompanySector>;
  followUpMessage?: string;
}

export function useFetchCompanyCallRecordingList(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<CallRecording>> {
  return useQuery({
    queryKey: [GET_CALL_RECORDINGS],
    queryFn: async () => {
      const response = await API.get(`${GET_CALL_RECORDINGS}`);
      return response.data.data;
    },
    ...options,
  });
}
