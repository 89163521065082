export enum PublicRoutes {
  BASE = "open",
  COMPANY_LANDING = "company-landing",
  JOB = "job/:id",
  SERVICE_PROVIDER = "service-provider/:id",
  CONTACT_SUPPORT = "contact-support",
  FDD_COMPANY_IDENTIFIED = "fdd-company-identified",
  IDENTIFY_EXTERNAL_COMPANY = "identify-external-company",
  IDENTIFY_EXTERNAL_COMPANY_LOGGED_IN = "identify-external-company-logged-in",
  EXTERNAL_COMPANY_IDENTIFIED = "external-company-identified",
  COMPANY_SELECTION = "company-selection",
  TERMS_OF_SERVICE = "terms-of-service",
  PRIVACY_POLICY = "privacy-policy",
  CLAIM_JOB = "claim-job/:id",
  JOB_REFERRAL = "job-referral",
}
