import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import VideoChatOutlinedIcon from "@mui/icons-material/VideoChatOutlined";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";

import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { CompanyPublicProfile } from "@/company/types";
import {
  getEmailsFromCompanyProfile,
  getPhonesFromCompanyProfile,
} from "@/utils";

import { AppConstants } from "../constants";

export const MessageOptions = ({
  companyProfile,
}: {
  companyProfile: CompanyPublicProfile;
}) => {
  const { session } = useAuth();
  const company = session?.company;
  const user = session?.user;
  const link = `${AppConstants.platformBaseUrl}`;
  const emails = getEmailsFromCompanyProfile(companyProfile);
  const phones = getPhonesFromCompanyProfile(companyProfile);

  const messageBodyEmail = `Hi,

I’m using a new referral platform that will help both of us exchange referrals easier. It also enables us to tip our field teams, directly from the platform.

Here is a link to sign-up. You can connect with us and see other local referral partners as well.

Link -> ${link}?invitationCode=${company?.invitationCode}&utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=brand_location_invitation_reminder&utm_medium=email

Give me a call or reach out with any questions, as I see a lot of potential for us and our teams.

Best,
${user?.firstName}`;

  const messageBodySms = `Hi,

I’m using a new referral platform that will help both of us exchange referrals easier. It also enables us to tip our field teams, directly from the platform.

Here is a link to sign-up. You can connect with us and see other local referral partners as well.

Give me a call or reach out with any questions, as I see a lot of potential for us and our teams.

Link -> ${link}?invitationCode=${company?.invitationCode}&utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=brand_location_invitation_reminder&utm_medium=sms`;

  const handleMailClick = () => {
    const emailSubject = encodeURIComponent(
      "Connect on TradeEngage to Receive Job Referrals"
    );
    const emailBody = encodeURIComponent(messageBodyEmail);
    const mailtoLink = `mailto:${emails?.join(
      ","
    )}?cc=support@tradeengage.com&subject=${emailSubject}&body=${emailBody}`;
    window.location.href = mailtoLink;
  };

  const handleSMSClick = () => {
    const body = encodeURIComponent(messageBodySms);
    if (navigator.userAgent.match(/Android/i)) {
      window.open(`sms:${phones?.join(",")}?&body=${body}`, "_blank");
    }
    if (navigator.userAgent.match(/iPhone/i)) {
      window.open(`sms:${phones?.join(",")}?&body=${body}`, "_blank"); // TODO: Add sms format for iOS
    }
    window.open(`sms:${phones?.join(",")}?&body=${body}`, "_blank");
  };

  return (
    <Box display={"flex"} gap={0.5} alignItems={"start"}>
      <ToolTip
        placement="bottom"
        message={"You will be redirected to your Email to send"}
        title={"Email"}
      >
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            trackEvent(AnalyticsEvents.CLICKED_CAMPAIGN_GET_STARTED, {
              loggedCompany: company?.name,
              loggedUserName: `${user?.firstName} ${user?.lastName}`,
              loggedCompanyId: company?.id,
              cardCompanyId: (companyProfile as CompanyPublicProfile).id,
              cardCompanyName: companyProfile.name,
              cardOwners: (companyProfile as CompanyPublicProfile)?.owners?.map(
                owner => ({
                  cardOwnerName: `${owner?.firstName} ${owner?.lastName}`,
                  cardOwnerPhone: owner.phone,
                  cardOwnerEmail: owner.email,
                  cardOwnerPhoneCarrierType: owner.phoneCarrierType,
                })
              ),
              cardCompanyBusinessEmail: (companyProfile as CompanyPublicProfile)
                .profile?.businessEmail,
              cardCompanyBusinessPhone: (companyProfile as CompanyPublicProfile)
                .profile?.businessEmail,
              cardCompanyBusinesPhoneCarrierType: (
                companyProfile as CompanyPublicProfile
              ).profile?.businessPhoneCarrierType,
              type: "EMAIL",
              source: "MESSAGE_SCREEN",
            });
            handleMailClick();
          }}
        >
          <MarkunreadOutlinedIcon />
        </IconButton>
      </ToolTip>
      <ToolTip
        placement="bottom"
        message={"You will be redirected to your Messages to send"}
        title={"Text Message"}
      >
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            trackEvent(AnalyticsEvents.CLICKED_CAMPAIGN_GET_STARTED, {
              loggedCompany: company?.name,
              loggedUserName: `${user?.firstName} ${user?.lastName}`,
              loggedCompanyId: company?.id,
              cardCompanyId: (companyProfile as CompanyPublicProfile).id,
              cardCompanyName: companyProfile.name,
              cardOwners: (companyProfile as CompanyPublicProfile)?.owners?.map(
                owner => ({
                  cardOwnerName: `${owner?.firstName} ${owner?.lastName}`,
                  cardOwnerPhone: owner.phone,
                  cardOwnerEmail: owner.email,
                  cardOwnerPhoneCarrierType: owner.phoneCarrierType,
                })
              ),
              cardCompanyBusinessEmail: (companyProfile as CompanyPublicProfile)
                .profile?.businessEmail,
              cardCompanyBusinessPhone: (companyProfile as CompanyPublicProfile)
                .profile?.businessEmail,
              cardCompanyBusinesPhoneCarrierType: (
                companyProfile as CompanyPublicProfile
              ).profile?.businessPhoneCarrierType,
              type: "SMS",
              source: "MESSAGE_SCREEN",
            });
            handleSMSClick();
          }}
        >
          <SmsOutlinedIcon />
        </IconButton>
      </ToolTip>
      <ToolTip
        placement="bottom"
        message={"Coming Soon!"}
        title={"Video Message"}
      >
        <IconButton
          type="button"
          sx={{ p: "10px", color: "#11192761" }}
          aria-label="search"
        >
          <VideoChatOutlinedIcon />
        </IconButton>
      </ToolTip>
    </Box>
  );
};
