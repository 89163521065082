import { Box, Theme, Typography, useMediaQuery } from "@mui/material";

const TermsOfService = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      textAlign={"start"}
      p={3}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="start"
        height="100%"
        maxWidth={"700px"}
        textAlign={"start"}
        gap={2}
      >
        <Typography
          gutterBottom
          variant={isMobile ? "h4" : "h1"}
          fontWeight={"400"}
        >
          Terms of Service
        </Typography>
        <Typography variant="body1" fontStyle={"italic"}>
          Updated May 11, 2024.
        </Typography>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Introduction</Typography>
          <Typography variant="body1">
            TradeEngage LLC, (“we,” “us,” or “TradeEngage”) a Delaware
            corporation, welcomes you to our website (the “
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Site
            </Typography>
            ”) and the App and services available from us, through the Site, on
            your mobile device, or other platforms (collectively with the Site,
            the “
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Services
            </Typography>
            ”). Your use of the Services are governed by these Terms of Service
            (these “
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Terms
            </Typography>
            ”). Any time you use the Services in any way, you agree to be bound
            by these Terms. If you don’t agree to these Terms, do not use the
            Services.
          </Typography>
          <Typography variant="body1">
            Your use of the Services is also subject to our Privacy Policy,
            which you can access through the Services or on our website, as well
            as any policies and procedures we publish from time to time
            (collectively, the “
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Policies
            </Typography>
            ”). We reserve the right to modify these Terms at any time, with
            such changes becoming effective when we post the modified Terms. We
            also reserve the right to make any changes to the Services in any
            manner and to deny or terminate your access to the Services, even if
            you have an Account, in our sole discretion.
          </Typography>
          <Typography variant="body1">
            Each time you use the Services, the then-current version of the
            Terms will apply. If you use the Services after a modification of
            these Terms, you agree to be bound by the Terms as modified.
          </Typography>
          <Typography variant="body1">
            These Terms contain important information regarding your rights with
            respect to the Services, including your relationship with us, and
            include an arbitration provision that may limit your ability to
            pursue claims against us in court. Please read them carefully and
            review them regularly.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Eligibility</Typography>
          <Typography variant="body1">
            When you use the Services, you represent that you are (i) at least
            the age of majority in the jurisdiction where you reside or (ii) if
            you have not reached the age of majority in the jurisdiction where
            you reside, that you have received permission to use the Services
            from your parent or legal guardian.
          </Typography>
          <Typography variant="body1">
            You represent that any information you submit to us when using the
            Services is accurate, truthful, and current. You also represent that
            your use of the Services does not violate any applicable law or
            regulation.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Registration</Typography>
          <Typography variant="body1">
            In order to use the Services, you will be required to register for
            an account (“
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Account
            </Typography>
            ”). As the person who registered for the Account (the “
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Account Owner
            </Typography>
            ”), you are entitled to certain administrative permissions as set
            out in the Services. As part of the Account creation process, you
            will be asked to provide a username and password unique to the
            Account (“
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Login Information
            </Typography>
            ”). You are responsible for the confidentiality and use of your
            Login Information and agree not to transfer or disclose your Login
            Information to any third party other than an individual with express
            authority to act on your behalf. You are solely responsible for any
            activities occurring under your Account. If you suspect any
            unauthorized use of your Account, you agree to notify us
            immediately. We reserve the right to discontinue offering the
            Services, including by terminating your Account, at any time
            pursuant to these Terms. You have no ownership right to your
            Account, and our liability if you are unable to access the Services,
            if any, is limited by these Terms.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Accounts</Typography>
          <Typography variant="body1">
            Accounts come in two primary groups: 1) Organizational Accounts, and
            2) Individual Accounts. Organizational Accounts can be linked to a
            certain number of Individual Accounts as set out in your access
            plan.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Organizational Accounts</Typography>
          <Typography variant="body1">
            As the Account Owner of an Account opened on behalf of an
            organization (an “
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Organizational Account
            </Typography>
            ”), you may grant access to the Services to certain individuals (“
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Authorized Users
            </Typography>
            ”), subject to the limits of any plan for which you enroll. When
            registering for an Organizational Account, administering such
            Account, and when accessing or otherwise using the Services, you
            represent or warrant that the information you enter for your
            organization is correct. You further acknowledge and agree that the
            Organizational Account Owner is responsible for all activity
            conducted by all Authorized Users. Each Authorized User must have
            their own Login Information and use of a single individual Account
            by several end users shall be considered a material breach of this
            Agreement, and TradeEngage reserves the right to terminate the
            Organizational Account for cause due to such breach.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Individual Accounts</Typography>
          <Typography variant="body1">
            As the Account Owner of an individual Account, you are responsible
            for your own actions. If you choose to add any users, you will
            become an Organizational Account for purposes of these Terms. If you
            have accepted an invitation to join the Services by your
            organization, and your Account becomes linked to such Organizational
            Account, you acknowledge that the administrators of the
            Organizational Account to which you are linked may have access to
            all activity/ data logged or generated in your Account. You further
            acknowledge that you have no individual rights in your Account if
            you are linked below an Organizational Account, and that the
            Organizational Account’s Account Owner may revoke your permission to
            access your Account or any Organizational Content as such Account
            Owner sees fit. Finally, you acknowledge that any and all Content
            you upload or otherwise supply to the Services shall become the
            property of your Organizational Account Owner immediately upon its
            acceptance by the Services, and you hereby assign all right, title,
            or interest in such Content to such Account Owner.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Payment Terms</Typography>
          <Typography variant="body1">
            In the event you have a paid account, fees are non-refundable except
            as required by law or in our sole discretion. If we terminate your
            Account without cause, we may refund you the fees for the unused
            portion of your subscription. If you sign up for our annual or
            monthly prepaid plans, and cancel those plans later, you are not
            entitled to a refund for the unused portion of your subscription
            period. You agree to pay all applicable fees when due and, if such
            fees are being paid via credit card or other electronic means, you
            authorize us to charge such fees using your selected payment method.{" "}
            <Typography variant="body1" fontWeight={700} display={"inline"}>
              By default, customer accounts are set to auto-renew and we may
              automatically charge you for such renewal on or after the renewal
              date associated with your account unless you have cancelled the
              Service prior to its renewal date.
            </Typography>{" "}
            We may revise fee rates for the Service from time to time and will
            provide you with email notice of any changes in fees at least thirty
            (30) days prior to your Service renewal date. You are responsible
            for providing complete and accurate billing information to us. We
            may suspend or terminate your use of the Service if fees become past
            due. You are responsible for all taxes (excluding taxes on our net
            income), and we will charge tax if required to do so by law.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Restrictions on Use</Typography>
          <Typography variant="body1">
            Any violation of the foregoing restrictions may result in immediate
            termination of your access to the software solution and potential
            legal action. You agree not to: Copy, distribute, reproduce,
            publish, license, lease, sell, resell, transfer, assign, or exploit
            any portion of the software solution or any access to it, except as
            expressly permitted by these Terms and Conditions; Modify, adapt,
            alter, or translate the software, or create derivative works based
            upon the software solution in whole or in part; Reverse engineer,
            decompile, disassemble, or attempt to discover the source code,
            algorithms, trade secrets, or other proprietary aspects of the
            software solution; Use the software solution to develop a competing
            product or service or assist any third party in such activities;
            Attempt to bypass or interfere with any security features, usage
            restrictions, or licensing mechanisms within the software; Create or
            register an account, or otherwise grant access to the software
            solution, for any user who is employed by, or acting on behalf of
            any unintended or unassociated entity to the business owner name
            without prior written consent from the Company.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Location-based Services</Typography>
          <Typography variant="body1">
            Some of the Services may require that location functionality be
            enabled on the relevant device in order to work properly. You
            acknowledge and agree that if location permissions and
            functionalities are not enabled on the device with which you access
            the Services, the Services may not work as intended. We will use any
            location information we receive from you in accordance with our
            Privacy Policy.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Third Party Sites</Typography>
          <Typography variant="body1">
            The Site may contain links to third party applications or websites
            we do not operate, control, or maintain (“
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Third Party Sites
            </Typography>
            ”). We do not endorse any Third Party Sites, and we make no
            representation or warranty in any respect regarding the Third Party
            Websites. Any links to Third Party Sites on the Services are
            provided solely for your convenience. If you do access any Third
            Party Sites, you do so at your own risk and waive any and all claims
            against us regarding the Third Party Sites or our links thereto.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Data</Typography>
          <Typography variant="body1">
            You agree that we have the right to collect and analyze data and
            other information relating to the provision, use and performance of
            various aspects of the Services, and related systems (for example,
            anonymous and aggregated information concerning user behavior and
            use of the Services), and we will be free (during and after the term
            hereof) to (i) use such information and data to improve and enhance
            the Site Services and for other development, diagnostic and
            corrective purposes in connection with the Site and Services and
            other of our offerings, and (ii) disclose such data solely in
            aggregate or other de-identified form in connection with its
            business.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Ownership</Typography>
          <Typography variant="body1">
            The TradeEngage Services belong in their entirety to us. We grant
            only that limited license herein to you. User Content (as defined
            below) belongs to you, and we take no ownership interest in it. The
            TradeEngage name and logo are our trademarks, and may not be copied,
            imitated or used, in whole or in part, without our prior written
            permission. In addition, all page headers, custom graphics, button
            icons, and scripts are our service marks, trademarks, and/ or trade
            dress, and may not be copied, imitated or used, in whole or in part,
            without prior written permission from us.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">User Conduct</Typography>
          <Typography variant="body1">
            You agree not to use any part of the Services to take any action or
            actions that (including with respect to any User Content): (i) are
            patently offensive in any manner (as determined in our sole
            discretion), (ii) involve commercial activities without our prior
            written consent, such as contests or sweepstakes, (iii) are contrary
            to our public image, goodwill, or reputation, (iv) infringe on our
            or any third party’s intellectual property rights, (v) violate any
            law or any third party’s legal rights, or (vi) &quot;frame&quot; or
            &quot;mirror&quot; any part of the Site without our prior written
            consent.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">User Content Generally</Typography>
          <Typography variant="body1">
            When you post content and information to the Site or in connection
            with the Services (“
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Content
            </Typography>
            ”), including photos, videos or other information about the jobs you
            are undertaking, you represent and warrant to us that (i) you own or
            have the necessary rights to use and share the Content, (ii) the
            posting of the Content does not violate any rights of any person or
            entity, and (iii) you have no agreement with or obligations to any
            third party that would prohibit your use of the Site or Services in
            the manner so used. You agree to pay all royalties, fees, and any
            other monies owing to any person or entity by reason of any Content
            posted by you to the Site or through the Services. You acknowledge
            and agree that we may, in our sole discretion, remove Content at any
            time and for any reason, or for no reason at all. If you are an
            Authorized User of an Organizational Account, you also acknowledge
            and agree that any Content uploaded to the Services shall
            immediately become the property of such Organizational Account, and
            you assign all right, title, and interest in such Content to the
            Organizational Account Owner upon posting the Content to the
            Services.
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            You agree not to include any personally-identifiable information
            about yourself or any other person in any User Content except as
            requested or required by TradeEngage.
          </Typography>
          <Typography variant="body1">
            By posting or storing any Content in the Services, you give us and
            our affiliates a perpetual, nonexclusive, irrevocable, royalty-free,
            sublicensable and transferable worldwide license to all intellectual
            property rights you own or control to use, transmit, reproduce,
            commercialize, distribute, modify, create derivative works from, and
            otherwise exploit such Content for any and all purposes and without
            further notice to you, attribution, and without the requirement of
            any permission or payment to you or any other person or entity,
            except as otherwise expressly provided herein. You also authorize
            and appoint us as your attorney in fact and agent with full power to
            enter into and execute any document or undertake any action we may
            consider appropriate to use or enforce the grant of rights and
            waivers set forth in these Terms.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Feedback</Typography>
          <Typography variant="body1">
            From time to time, you may be asked to provide feedback on the
            Services or the Platform, whether by a survey or by giving a written
            testimonial (“
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Feedback
            </Typography>
            ”). Feedback shall include any communications directed to us related
            to the Services, including without limitation suggestions for new
            features or functionality or comments, questions, or other
            suggestions. If you choose to give such Feedback, you agree that all
            such Feedback shall belong entirely to us, including any ideas,
            know-how, concepts, techniques, or other intellectual property
            rights contained in such Feedback, and you hereby assign all right,
            title, and interest in such Feedback to us. We shall be free to use
            any Feedback, with or without attribution (subject to our
            obligations in our Privacy Policy) or compensation to the provider.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Content-Sharing Platform</Typography>
          <Typography variant="body1">
            The Services include a content-sharing platform (the “
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Platform
            </Typography>
            ”) whereby content-creating users (“
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Creators
            </Typography>
            ”) can create and share certain Content with other users subject to
            the sharing permission set by the Creator in the Platform. By
            sharing Content, you represent and warrant that you have all
            necessary permission to share the Content in its form. If the
            Content is a photograph or video, you represent that all personally
            identifiable information has been removed from the photograph or
            video. At times, entering project information into the Services and
            Platform may require sharing the personal information of third
            parties, such as clients. You agree that only such personal
            information as is necessary has been uploaded to the Services and
            Platform, and that you have permission from the subject to share
            their information with whoever has permission to see such personal
            information inside the Services.
          </Typography>
          <Typography variant="body1">
            Except as otherwise described in the Service’s Privacy Policy, as
            between you and us, any Content will be non-confidential and
            nonproprietary and we will not be liable for any use or disclosure
            of User Content.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Communication Platform</Typography>
          <Typography variant="body1">
            The Services encompass a communication feature (the “Feature”) that
            allows users to engage in in-app messaging and receive notifications
            via SMS and email. When utilizing this Feature, you, as the user,
            are responsible for the content of your messages and the information
            you choose to share, ensuring it aligns with the permissions you set
            within the Feature. By using this Feature, you represent and warrant
            that you possess all necessary permissions to share any content or
            information in the manner you choose. This includes ensuring that
            any personal information shared through the Feature, whether it be
            yours or that of third parties, is done with the necessary consent
            and only to the extent necessary for the intended communication.
          </Typography>
          <Typography variant="body1">
            You acknowledge that, in the process of using this Feature, you may
            share personal information of third parties, such as contacts in
            your messages or notifications. You agree to share only such
            personal information as is necessary and that you have obtained
            permission from the individuals involved to share their information.
          </Typography>
          <Typography variant="body1">
            As outlined in our Privacy Policy, except where stated otherwise,
            any content or information you share through the Feature will be
            considered non-confidential and nonproprietary. We are not liable
            for the use or disclosure of any content or information you share
            through this Feature.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Losses of Stored Content</Typography>
          <Typography variant="body1">
            Although it is our intention for the Service to be available as much
            as possible, there will be occasions when the Service may be
            interrupted, including, without limitation, for scheduled
            maintenance or upgrades, for emergency repairs, for unscheduled
            downtime, for system and server failures, or due to failure of
            telecommunications links and/ or equipment. Consequently, we
            encourage you to maintain your own backup of your Content. In other
            words, we are not a backup service and you agree that you will not
            rely on the Service for the purposes of Content backup or storage.
            We will not be liable to you for any modification, suspension, or
            discontinuation of the Services, or the loss of any Content. You
            also acknowledge that the Internet may be subject to breaches of
            security and that the submission of Content or other information may
            not be secure.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Copyright Infringement</Typography>
          <Typography variant="body1">
            We respect the intellectual property rights of others. The Digital
            Millennium Copyright Act of 1998 (the “
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              DMCA
            </Typography>
            ”) provides a complaint procedure for copyright owners who believe
            that any material posted online or in an app infringes their rights
            under U.S. copyright law. If you believe that your work has been
            improperly copied and posted, please provide us with the following
            information: (i) name, address, telephone number, email address and
            an electronic or physical signature of the copyright owner or of the
            person authorized to act on his/ her behalf; (ii) a description of
            the copyrighted work that you claim has been infringed; (iii) a
            description of where on the Site the material that you claim is
            infringing is located; (iv) a written statement that you have a good
            faith belief that the disputed use is not authorized by the
            copyright owner, its agent, or the law; and (v) a statement by you,
            made under penalty of perjury, that the above information in your
            notice is accurate and that you are the copyright owner or
            authorized to act on the copyright owner’s behalf. These
            requirements must be followed to give us legally sufficient notice
            of infringement. Send copyright infringement complaints to the
            following email address: support@ tradeengage. com. We suggest that
            you consult your legal advisor before filing a DMCA notice with our
            copyright agent. There can be penalties for false claims under the
            DMCA.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Warranty Disclaimer</Typography>
          <Typography variant="body1">
            You agree that the Services are available on an “as is” basis,
            without any warranty, and that you use the Services at your own
            risk. We disclaim, to the maximum extent permitted by law, any and
            all warranties, whether express or implied, including, without
            limitation, (a) warranties of merchantability or fitness for a
            particular purpose, (b) warranties against infringement of any third
            party intellectual property or proprietary rights, © warranties
            relating to delays, interruptions, errors, or omissions in the
            Services or on the Site, (d) warranties relating to the accuracy or
            correctness of data on the Services, and (e) any other warranties
            otherwise relating to our performance, nonperformance, or other acts
            or omissions.
          </Typography>
          <Typography variant="body1">
            We do not warrant that the Site or the Services will operate
            error-free or that they are free of computer viruses and/ or other
            harmful materials. If your use of the Site or the Services results
            in the need for servicing or replacing equipment or data, we are not
            responsible for any such costs.
          </Typography>
          <Typography variant="body1">
            Some jurisdictions do not allow the exclusion or limitation of
            certain categories of damages or implied warranties; therefore, the
            above limitations may not apply to you. In such jurisdictions, our
            liability is limited to the greatest extent permitted by law.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Limitation of Liability</Typography>
          <Typography variant="body1">
            Any liability we have to you in connection with these Terms, under
            any cause of action or theory, is strictly limited to, in aggregate
            for all violations, $100. Without limiting the previous sentence, in
            no event shall we or any of our affiliates be liable to you for any
            indirect, special, incidental, consequential, punitive, or exemplary
            damages arising out of or in connection with, these Terms. The
            foregoing limitations apply whether the alleged liability is based
            on contract, tort, negligence, strict liability, or any other basis,
            even if we or our affiliates have been advised of the possibility of
            such damages.
          </Typography>
          <Typography variant="body1">
            You agree to indemnify and hold us harmless for any breach of
            security or any compromise of your Account.
          </Typography>
          <Typography variant="body1">
            Some jurisdictions do not allow the exclusion or limitation of
            incidental or consequential; therefore, the above limitations may
            not apply to you. In such jurisdictions, our liability is limited to
            the greatest extent permitted by law.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Indemnification</Typography>
          <Typography variant="body1">
            You agree to indemnify and hold harmless us, our affiliates and our
            and their officers, directors, partners, agents, and employees from
            and against any loss, liability, claim, or demand, including
            reasonable attorneys’ fees (collectively, “
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Claims
            </Typography>
            ”), made by any third party due to or arising out of your use of the
            Services in violation of these Terms, any breach of the
            representations and warranties you make in these Terms, or your User
            Content. You agree to be solely responsible for defending any Claims
            against or suffered by us with counsel subject to our reasonable
            approval and further subject to our right to participate with
            counsel of our own choosing.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">
            Electronic Signatures and Notices.
          </Typography>
          <Typography variant="body1">
            Certain activities on the Services may require you to make an
            electronic signature. You understand and accept that an electronic
            signature has same legal rights and obligations as a physical
            signature.
          </Typography>
          <Typography variant="body1">
            If you have an Account, you agree that we may provide you any and
            all required notices electronically through your Account or other
            electronic means. You agree that we are not responsible for any
            delivery fees charged to you as a result of your receipt of our
            electronic notices.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Miscellaneous</Typography>
          <Typography variant="body1">
            These Terms are governed by Florida law, without giving effect to
            conflicts of law principles. You agree that, to the extent
            applicable and expressly subject to the dispute resolution
            provisions below, to submit to the exclusive jurisdiction of the
            state and federal courts located in Miami-Dade County, Florida in
            circumstances where these Terms permit litigation in court. We may
            assign, transfer, delegate, or otherwise hypothecate our rights
            under these Terms in our sole discretion. If we fail to enforce a
            provision of these Terms, you agree that such a failure does not
            constitute a waiver to enforce the provision (or any other provision
            hereunder). If any provision of these Terms is held or made invalid,
            the invalidity does not affect the remainder of these Terms. We
            reserve all rights not expressly granted in these Terms and disclaim
            all implied licenses.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Dispute Resolution</Typography>
          <Typography variant="body1">
            Please read this section carefully. It contains procedures for
            mandatory binding arbitration and a class action waiver.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Arbitration</Typography>
          <Typography variant="body1">
            Any controversy or claim arising out of or relating to this
            contract, or the breach thereof, shall be settled by arbitration
            administered by the American Arbitration Association in accordance
            with its Commercial Arbitration Rules, and judgment on the award
            rendered by the arbitrator(s) may be entered in any court having
            jurisdiction thereof. Any such arbitration shall take place in
            Miami-Dade County, Florida. All aspects of the arbitration
            proceeding, including but not limited to the award of the arbitrator
            and compliance therewith, shall be strictly confidential. You agree
            to maintain confidentiality unless otherwise required by law. This
            paragraph shall not prevent a party from submitting to a court of
            law any information necessary to enforce this Section, to enforce an
            arbitration award, or to seek injunctive or equitable relief.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">
            Waiver of Jury Trial & Class Actions
          </Typography>
          <Typography variant="body1">
            THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS
            TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY,
            instead electing that all claims and disputes shall be resolved by
            arbitration under these terms. Arbitration procedures are typically
            more limited, more efficient and less costly than rules applicable
            in a court and are subject to very limited review by a court. In the
            event any litigation should arise between you and us in any state or
            federal court in a suit to vacate or enforce an arbitration award or
            otherwise, YOU WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing
            that the dispute be resolved by a judge. ALL CLAIMS AND DISPUTES
            WITHIN THE SCOPE OF THIS SECTION MUST BE ARBITRATED OR LITIGATED ON
            AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE
            THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR
            CONSOLIDATED WITH THOSE OF ANY OTHER USER.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Severance</Typography>
          <Typography variant="body1">
            If any part or parts of these Terms are found under the law to be
            invalid or unenforceable by a court of competent jurisdiction, then
            such specific part or parts shall be of no force and effect and
            shall be severed and the remainder shall continue in full force and
            effect.
          </Typography>
          <Typography variant="body1">
            <Typography variant="body1" fontWeight={700} display={"inline"}>
              Notice for California Users.
            </Typography>{" "}
            Under California Civil Code Section 1789.3, California Website users
            are entitled to the following specific consumer rights notice: The
            Complaint Assistance Unit of the Division of Consumer Services of
            the California Department of Consumer Affairs may be contacted in
            writing at 1625 N. Market Blvd., Suite S‑202, Sacramento, California
            95834, or by telephone at (800) 952‑5210.
          </Typography>
          <Typography variant="body1">
            Please visit our{" "}
            <a href="/open/privacy-policy" style={{ color: "black" }}>
              Privacy Policy
            </a>{" "}
            for more information. If you have any questions about these Terms of
            Service or if you wish to make any complaint or claim with respect
            to the Website, App or Service, please contact us at:{" "}
            <a href="mailto:info@tradeengage.com" style={{ color: "black" }}>
              info@tradeengage.com
            </a>
            .
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsOfService;
