import { DateTime } from "luxon";
import numeral from "numeral";

export const formatNumber = (number: number, convert?: boolean) => {
  if (convert) {
    return numeral(number).format("0,0.0");
  }
  return numeral(number).format("0,0");
};

export const calculateBusinessDays = (createdAt: string) => {
  // Parse the createdAt date
  const start = DateTime.fromISO(createdAt);
  const end = DateTime.now();

  // Initialize business days counter
  let businessDaysCount = 0;

  // Iterate through the days
  for (let dt = start; dt < end; dt = dt.plus({ days: 1 })) {
    // Check if the current day is a weekday (Monday to Friday)
    if (dt.weekday >= 1 && dt.weekday <= 5) {
      businessDaysCount++;
    }
  }

  return businessDaysCount;
};
