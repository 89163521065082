/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
import { Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { JobPromotionWithCompany } from "@/company/job-promotions/types";
import { useGetJobById } from "@/company/jobs/api";
import { useCloseJob } from "@/company/jobs/api/useCloseJob";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { useDeleteJob } from "@/company/jobs/api/useDeleteJob";
import { Job, JobStatus } from "@/company/jobs/types";
import { neutral, tradeEngageColors } from "@/Theme/colors";
import { isDefined } from "@/utils/isDefined";

import { doesErrorHaveMessage } from "../utils/doesErrorHaveMessage";
import { CreatedJobSummary } from "./components/CreatedJobSummary";
import { HomeownerDetailsUpload } from "./HomeownerDetailsUpload";
import { JobMediaUploadWeb } from "./JobMediaUploadWeb";
import { JobUploadSuccess } from "./JobUploadSuccess";

export interface CreateJobDialogProps {
  isOpen: boolean;
  createdForCompanyId?: number;
  homeId?: number;
  defaultHomeownerDetails?: {
    firstName: string;
    lastName?: string;
    address?: string;
    cellPhone?: string;
    zipCode?: string;
  };
  defaultSummary?: string;
  onClose: () => void;
  jobsListUrl: string;
  createdJob?: Job;
  jobPromotion?: JobPromotionWithCompany;
  refreshJobs?: () => Promise<unknown>;
}

function CreateTechnicianJobDialogFunc(props: CreateJobDialogProps) {
  const { session } = useAuth();

  const [transcription, setTranscription] = useState<string | undefined>();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { enqueueSnackbar } = useSnackbar();

  const [newJob, setNewJob] = useState<Job | undefined>(props.createdJob);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [step, setStep] = useState(isDefined(props.createdJob) ? 1 : 0);
  const [polling, setPolling] = useState(false);
  const [newUploadScreenOpen, setNewUploadScreenOpen] = useState(false);
  const [uploadedJobId, setUploadedJobId] = useState<number | undefined>();
  const [promotionUsedForJobCreation, setPromotionUsedForJobCreation] =
    useState<JobPromotionWithCompany | undefined>(props.jobPromotion);

  const [mediaType, setMediaType] = useState<JobUploadTypes>(
    isMobile ? JobUploadTypes.VIDEO : JobUploadTypes.TEXT
  );

  const { isLoading: isDeletingJob, mutateAsync: deleteJob } = useDeleteJob({
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while cancelling the job";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const { mutateAsync: closeJob } = useCloseJob();

  const { data: job } = useGetJobById(newJob?.id ?? null, {
    enabled: !!newJob?.id && step === 2,
    refetchInterval: () => {
      if (
        newJob?.status === JobStatus.OPPORTUNITY ||
        newJob?.status == JobStatus.VIDEO_PROCESSING_FAILED
      ) {
        if (newJob?.status === JobStatus.OPPORTUNITY && step === 2) setStep(3);
        setPolling(false);
        return false;
      }
      return 2000;
    },
  });

  useEffect(() => {
    if (job) {
      if (job?.status === JobStatus.VIDEO_PROCESSING_FAILED) {
        enqueueSnackbar({
          message: `There was an error while processing job video. Please try again.`,
          variant: "error",
        });
        reset();
      }
      setNewJob(job);
    }
  }, [job]);

  const reset = () => {
    setVideoFile(null);
    setNewJob(undefined);
    setStep(0);
    setMediaType(JobUploadTypes.VIDEO);
    setTranscription("");
    setPolling(false);
    return true;
  };

  const revertStep1 = async () => {
    if (newJob) {
      closeJob(newJob?.id, {
        onError: error => {
          console.error(error);
        },
      });
    }
    setPolling(false);
    setStep(0);
  };
  const revertStep3 = () => {
    setStep(1);
  };

  return (
    <Dialog
      fullWidth
      {...(isMobile ? { fullScreen: true, m: 0 } : { m: 3 })}
      open={props.isOpen}
      maxWidth="md"
      onClose={() => reset() && props.onClose()}
      onClick={event => event.stopPropagation()}
    >
      <DialogTitle component="div" width="95%">
        {newUploadScreenOpen ? null : (
          <>
            {" "}
            <Typography gutterBottom variant="h5">
              {session?.user?.type === USER_TYPE.HOMEOWNER
                ? "Add Home Project"
                : "Submit New Job"}
            </Typography>
            {session?.user?.type === USER_TYPE.HOMEOWNER ? (
              <Typography gutterBottom variant="body2" color="textSecondary">
                Create a new project and it will be routed to a trusted service
                provider.
              </Typography>
            ) : (
              <Typography gutterBottom variant="body2" color="textSecondary">
                Upload a job to refer to your network. It&apos;s easy!
              </Typography>
            )}
          </>
        )}
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => reset() && props.onClose()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          px: { xs: 0 },
          pt: 0,
          overflow: "auto",
          position: "relative",
        }}
        onClick={event => event.stopPropagation()}
      >
        {newUploadScreenOpen && isDefined(uploadedJobId) ? (
          <JobUploadSuccess
            setNewUploadScreenOpen={setNewUploadScreenOpen}
            uploadedJobId={uploadedJobId}
            onClose={props.onClose}
          />
        ) : (
          <Box
            display={"flex"}
            sx={{ ...(!isMobile ? { minHeight: "580px" } : {}) }}
          >
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },

                mt: 0,
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <Avatar
                    variant="square"
                    sx={{
                      mb: 1,
                      color: neutral[50],
                      borderRadius: 1,
                      bgcolor: tradeEngageColors.green,
                    }}
                  >
                    {step > 0 ? <CheckIcon color="inherit" /> : "1"}
                  </Avatar>
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 5, pt: 0.5 }}>
                  <Typography
                    gutterBottom
                    variant="overline"
                    {...(step > 0 ? { color: "#071D2F" } : {})}
                  >
                    Project Details
                  </Typography>
                  {step > 0 ? null : (
                    <JobMediaUploadWeb
                      mediaType={mediaType}
                      setMediaType={setMediaType}
                      videoFile={videoFile}
                      setTranscription={setTranscription}
                      transcription={transcription}
                      setVideoFile={file => setVideoFile(file)}
                      homeId={props.homeId}
                      createdForCompanyId={props.createdForCompanyId}
                      onSubmit={(
                        job: Job,
                        promotionUsed?: JobPromotionWithCompany
                      ) => {
                        setNewJob(job);
                        setStep(1);
                        setPromotionUsedForJobCreation(promotionUsed);
                      }}
                    />
                  )}
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <Avatar
                    variant="square"
                    color="primary"
                    sx={{
                      my: 1,
                      borderRadius: 1,
                      color: step >= 1 ? neutral[50] : "textPrimary",
                      bgcolor:
                        step >= 1 ? tradeEngageColors.green : neutral[200],
                    }}
                  >
                    {step > 1 ? <CheckIcon color="inherit" /> : "2"}
                  </Avatar>
                  {step >= 1 ? (
                    <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                  ) : null}
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                  <Typography
                    variant="overline"
                    {...(step > 1 ? { color: "#6C737F" } : {})}
                  >
                    Add Homeowner Info
                  </Typography>
                  {step >= 1 && isDefined(newJob) ? (
                    <HomeownerDetailsUpload
                      defaultHomeownerDetails={props.defaultHomeownerDetails}
                      step={step}
                      jobId={newJob?.id}
                      revertStep1={
                        isDefined(promotionUsedForJobCreation)
                          ? undefined
                          : revertStep1
                      }
                      onJobHomeUpload={() => {
                        setStep(2);
                        setPolling(true);
                      }}
                    />
                  ) : null}
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <Avatar
                    variant="square"
                    color="primary"
                    sx={{
                      my: 1,
                      borderRadius: 1,
                      color: step >= 2 ? neutral[50] : "textPrimary",
                      bgcolor:
                        step >= 2 ? tradeEngageColors.green : neutral[200],
                    }}
                  >
                    {step > 3 ? <CheckIcon color="inherit" /> : "3"}
                  </Avatar>
                  {step >= 2 ? (
                    <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                  ) : null}
                </TimelineSeparator>
                <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                  <Typography variant="overline">Review Job</Typography>
                  {step >= 2 ? (
                    polling ? (
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={0}
                        mt={3}
                      >
                        <Typography variant="subtitle1">
                          Fetching Job Summary
                        </Typography>
                        <Typography variant="caption" fontSize={"0.625rem"}>
                          Please do not close this window.
                        </Typography>
                        <LinearProgress sx={{ width: "100%", mt: 1 }} />
                      </Box>
                    ) : (
                      <Box width="100%" pt={3}>
                        {isDefined(newJob) && (
                          <CreatedJobSummary
                            newJob={newJob}
                            setNewJob={(job: Job) => {
                              setNewJob(job);
                            }}
                            revertStep={revertStep3}
                            jobPromotion={promotionUsedForJobCreation}
                            onSubmit={() => {
                              setUploadedJobId(newJob?.id);
                              reset();
                              props.refreshJobs?.();
                              enqueueSnackbar({
                                message: "Job submitted successfully.",
                                variant: "success",
                              });
                              setNewUploadScreenOpen(true);
                            }}
                          />
                        )}
                      </Box>
                    )
                  ) : null}
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Box>
        )}
      </DialogContent>

      {step !== 0 && newJob ? (
        <Box position={"sticky"} bottom={0} mb={2}>
          <Divider />
          <Box display={"flex"} gap={1} alignItems={"baseline"} mt={2} px={2}>
            <Typography variant="subtitle2" sx={{ color: "#111927" }}>
              Want to restart?
            </Typography>
            <Button
              variant="text"
              sx={{ color: "#F04438", p: 0 }}
              disabled={isDeletingJob}
              size="small"
              onClick={() => {
                deleteJob(newJob.id);
                reset();
              }}
            >
              Cancel Job
            </Button>
          </Box>
        </Box>
      ) : null}
    </Dialog>
  );
}

export const CreateTechnicianJobDialog = React.memo(
  CreateTechnicianJobDialogFunc,
  (prevProps, nextProps) => {
    // Compare props deeply if needed
    return prevProps.isOpen === nextProps.isOpen;
  }
);
