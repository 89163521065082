import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

const TRIGGER_REFERRAL_INVITATION_NOTIFICATION_BY_ID_URL =
  "/internal-admin/referral-invitations/:invitationId/send-notifications";

export function useTriggerReferralInvitationNotification(
  options: UseMutationOptions<void, AxiosError, number> = {}
): UseMutationResult<void, AxiosError, number> {
  return useMutation({
    mutationFn: async (invitationId: number) => {
      const url = TRIGGER_REFERRAL_INVITATION_NOTIFICATION_BY_ID_URL.replace(
        ":invitationId",
        invitationId.toString()
      );
      return await API.post(url);
    },
    ...options,
  });
}
