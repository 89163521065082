import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";
import { Company } from "@/company/types";

export function useFetchJobRecommendedPartner(
  jobId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Company> {
  return useQuery({
    queryKey: [`jobs/${jobId}/recommended-partner`],
    queryFn: async () => {
      const response = await API.get(
        `jobs/${jobId}/recommended-partner?include_partners_outside_territory=true`
      );
      // const response = {
      //   data: {
      //     data: {
      //       id: 1,
      //       name: "test company",
      //       logoUrl: "test",
      //     },
      //   },
      // };
      return response.data.data;
    },
    ...options,
  });
}
