import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import MobileFriendlyOutlinedIcon from "@mui/icons-material/MobileFriendlyOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { Box, Chip, Divider, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FC } from "react";

import { useAuth } from "@/auth/context/jwt";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { AffiliateCompanies } from "@/company/components/company-drawer/AffiliateCompanies";
import { JobSectors } from "@/company/jobs/constants";
import { snakeCaseJobSectors } from "@/company/utils";

interface JobOverviewProps {
  companyId: number;
}

export const JobOverview: FC<JobOverviewProps> = ({ companyId }) => {
  const { data: companyProfile } = useFetchCompanyProfile(companyId);
  const { session } = useAuth();
  const brandLocationProfile = session?.brandLocationProfile;

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(companyId, {
      refetchOnWindowFocus: false,
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];

  return (
    <>
      <Box display={"flex"} py={1} flexDirection={"column"} gap={1}>
        <CompanyAvatar
          width={"48px"}
          logo={companyProfile?.logoUrl}
          name={companyProfile?.name ?? ""}
        />
        <Typography variant="h5">{companyProfile?.name}</Typography>
      </Box>
      <Box>
        <Typography variant="overline" color="rgba(17, 25, 39, 0.38)" py={1}>
          SERVICES
        </Typography>
        {[JobSectors.ACCESSIBILITY_SOLUTIONS, JobSectors.BLINDS].length ? (
          <Box>
            {companyProfile?.sectors.map(sector => (
              <Chip
                key={sector.sector}
                label={
                  snakeCaseJobSectors[sector.sector] ??
                  snakeCaseJobSectors[JobSectors.OTHER]
                }
                color="default"
                sx={{ mr: 1 }}
              />
            ))}
          </Box>
        ) : null}
      </Box>
      <Divider sx={{ my: 1.5 }} />
      <Box>
        <Typography
          variant="overline"
          color="rgba(17, 25, 39, 0.38)
"
        >
          OVERVIEW
        </Typography>
        <Box>
          <Chip label={"Franchise"} color="primary" size="small" />
        </Box>
        <Box display={"flex"} flexDirection={"column"} py={1} gap={1}>
          {companyProfile?.sectors?.some(
            sector =>
              recommendedJobSectorsList?.includes(sector.sector) ||
              relevanceOrderedJobSectorsList?.includes(sector.sector)
          ) ? (
            <Box display={"flex"}>
              <img
                src="/top-referral.svg"
                alt="top referral category"
                style={{ marginRight: "8px" }}
              />
              <Typography variant="body2" color="primary">
                Top Referral Category
              </Typography>
            </Box>
          ) : null}
          {companyProfile ? (
            <>
              {companyProfile?.yearEstablished ? (
                <Box display={"flex"}>
                  <VerifiedUserOutlinedIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#B5BCC4" }}
                  />
                  <Typography variant="body2">
                    {DateTime.now().year -
                    (companyProfile?.yearEstablished ?? DateTime.now().year)
                      ? DateTime.now().year -
                        (companyProfile?.yearEstablished ?? DateTime.now().year)
                      : "< 1"}{" "}
                    Years in Business
                  </Typography>
                </Box>
              ) : null}
              {companyProfile.numEmployees ? (
                <Box display={"flex"}>
                  <EngineeringOutlinedIcon
                    fontSize="small"
                    sx={{ mr: 1, color: "#B5BCC4" }}
                  />
                  <Typography variant="body2">
                    {companyProfile.numEmployees ?? 0} Employees
                  </Typography>
                </Box>
              ) : null}
            </>
          ) : null}
        </Box>
      </Box>
      <Divider sx={{ my: 1.5 }} />
      <Box>
        <Typography
          variant="overline"
          color="rgba(17, 25, 39, 0.38)
"
        >
          OWNER
        </Typography>
        <Box display={"flex"} flexDirection={"column"} py={1} gap={1}>
          {companyProfile?.owners?.[0]?.firstName ? (
            <Box display={"flex"}>
              <PersonOutlineIcon
                fontSize="small"
                sx={{ mr: 1, color: "#B5BCC4" }}
              />
              <Typography variant="body2">
                {`${companyProfile?.owners?.[0]?.firstName ?? ""} ${
                  companyProfile?.owners?.[0]?.lastName ?? ""
                }`}{" "}
                (Owner)
              </Typography>
            </Box>
          ) : null}
          {companyProfile?.owners?.[0]?.hasPhone ? (
            <Box display={"flex"}>
              <MobileFriendlyOutlinedIcon
                fontSize="small"
                sx={{ mr: 1, color: "#B5BCC4" }}
              />
              <Typography variant="body2">
                Owner Mobile Phone Verified
              </Typography>
            </Box>
          ) : null}
          {companyProfile?.owners?.[0]?.hasEmail ? (
            <Box display={"flex"}>
              <MarkEmailReadOutlinedIcon
                fontSize="small"
                sx={{ mr: 1, color: "#B5BCC4" }}
              />
              <Typography variant="body2">Owner Email Verified</Typography>
            </Box>
          ) : null}
          {companyProfile?.familyOfBrands ? (
            <Box display={"flex"}>
              <BusinessOutlinedIcon
                fontSize="small"
                sx={{ mr: 1, color: "#B5BCC4" }}
              />
              <Typography variant="body2">
                {companyProfile?.familyOfBrands.name}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      {companyProfile?.familyOfBrands ? (
        <>
          <Divider sx={{ my: 1.5 }} />
          <Box>
            <Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  gutterBottom
                  variant="overline"
                  color="rgba(17, 25, 39, 0.38)"
                >
                  BRAND AFFILIATES
                </Typography>
                <ToolTip message="This company is part of a family of brands and these are the company's affiliates.">
                  <HelpOutlineIcon
                    sx={{
                      background: "none",
                      color: "#6C737F",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </ToolTip>
              </Box>
              <Box
                display={"flex"}
                sx={{ flexWrap: "wrap", alignItems: "center" }}
                py={1}
              >
                <AffiliateCompanies
                  familyOfBrandsId={companyProfile?.familyOfBrands.id}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};
