import { Box, Chip, Theme, Typography, useMediaQuery } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridEventListener,
  GridRowModesModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { DateTime } from "luxon";
import numeral from "numeral";
import { useEffect, useState } from "react";

import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { RoutedJobResponse } from "@/company/jobs/types/job";
import { ProjectJobStatusChipProps } from "@/company/jobs/utils";
import { getGridStringOperatorsDataGrid } from "@/internal-admin/utils";
import { getGridNumericOperatorsDataGrid } from "@/utils";

const formatNumber = (number: number) => {
  return numeral(number).format("0,0.00");
};

export const formatDate = value => {
  if (!value) return ""; // Return empty if value is undefined or null
  const dt = DateTime.fromFormat(
    value.toString().split(".")[0].split("T").join(" "),
    "yyyy-MM-dd HH:mm:ss",
    { zone: "utc" }
  );
  return dt.toFormat("MM/dd/yy"); // Format as MM/DD/YY
};

export const formatDateTime = value => {
  if (!value) return ""; // Return empty if value is undefined or null
  const dt = DateTime.fromFormat(
    value.toString().split(".")[0].split("T").join(" "),
    "yyyy-MM-dd HH:mm:ss",
    { zone: "utc" }
  ).toLocal();
  return dt.toFormat("MM/dd/yy hh:mm a");
};

export const formatDateTimeV2 = value => {
  if (!value) return ""; // Return empty if value is undefined or null
  const dt = DateTime.fromFormat(
    value.toString().split(".")[0].split("T").join(" "),
    "yyyy-MM-dd HH:mm:ss",
    { zone: "utc" }
  ).toLocal();
  return dt.toFormat("MM/dd/yy hh:mm a");
};

export const formatDateV2 = value => {
  if (!value) return ""; // Return empty if value is undefined or null
  const dt = DateTime.fromFormat(value.toString(), "yyyy-MM-dd", {
    zone: "utc",
  }).toLocal();
  return dt.toFormat("MM/dd/yy");
};

export const formatToDateTime = value => {
  if (!value) return ""; // Return empty if value is undefined or null
  const dt = DateTime.fromFormat(
    value.toString().split(".")[0].split("T").join(" "),
    "yyyy-MM-dd HH:mm:ss",
    { zone: "utc" }
  );
  return dt.toJSDate();
};

export const formatToDate = value => {
  if (!value) return ""; // Return empty if value is undefined or null
  const dt = DateTime.fromFormat(value.toString(), "yyyy-MM-dd", {
    zone: "utc",
  });
  return dt.toJSDate();
};

const CustomToolbar = () => {
  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  return (
    <Box
      width={"100%"}
      sx={{
        ...(isDesktop
          ? { position: "relative" }
          : {
              position: "absolute",
              top: "-48px",
              right: "16px",
            }),
      }}
    >
      <GridToolbarContainer
        sx={{ justifyContent: { lg: "space-between", xs: "start" } }}
      >
        <Box sx={{ width: { md: "0", lg: "50%" } }}></Box>
        <Box display={"flex"} gap={1} flexWrap={"wrap"}>
          <GridToolbarColumnsButton
            slotProps={{
              button: { sx: { color: "#384250" }, variant: "outlined" },
            }}
          />
          <GridToolbarDensitySelector
            slotProps={{
              button: { sx: { color: "#384250" }, variant: "outlined" },
            }}
          />
          <GridToolbarExport
            printOptions={{ disableToolbarButton: true }}
            csvOptions={{
              fileName: `Referral_Statistics_${DateTime.now().toFormat(
                "M-dd-yyyy-hh-mm-a"
              )}`,
            }}
            excelOptions={{
              fileName: `Referral_Statistics_${DateTime.now().toFormat(
                "M-dd-yyyy-hh-mm-a"
              )}`,
            }}
            slotProps={{
              button: { sx: { color: "#384250" }, variant: "outlined" },
            }}
          />
        </Box>
      </GridToolbarContainer>
    </Box>
  );
};

export default function JobsTable({
  routedJobs,
  expanded,
  onClick,
}: {
  routedJobs: Array<RoutedJobResponse>;
  expanded?: boolean;
  onClick: (job: RoutedJobResponse) => void;
}) {
  const [rows, setRows] = useState([]);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    setRows(routedJobs);
  }, [routedJobs]);

  let columns: GridColDef[] = [
    {
      field: "jobId",
      headerName: "Job ID",
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="body2">{params.row.jobId}</Typography>
          </Box>
        );
      },
      width: 65,
    },

    {
      field: "jobType",
      headerName: "Job Type",
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            sx={{ cursor: "pointer" }}
            gap={1}
          >
            <Typography variant="body2">{params.row.jobType}</Typography>
          </Box>
        );
      },
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (value, row) => row.status.replaceAll("_", " "),
      renderCell: params => {
        return (
          <Chip
            label={ProjectJobStatusChipProps[params.row.status].label}
            sx={{
              bgcolor: ProjectJobStatusChipProps[params.row.status].bgcolor,
              color: ProjectJobStatusChipProps[params.row.status].color,
              fontSize: "12px",
              fontWeight: 600,
              height: "24px",
            }}
          />
        );
      },
      width: 180,
    },
    {
      field: "Referral Fee",
      headerName: "Referral Fee",
      width: 120,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueFormatter: value => value && `$${value?.toString()}`,
      valueGetter: (value, row) => row.job?.jobBuyingCost,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1}
          >
            <Typography variant="body2">
              {params.row.job?.jobBuyingCost
                ? `$${formatNumber(params.row.job?.jobBuyingCost ?? 0)}`
                : ""}
            </Typography>
          </Box>
        );
      },
      sortable: true,
    },
    {
      field: "Revenue from Referrals",
      headerName: "Revenue from Referrals",
      width: 200,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueGetter: (value, row) => row.job?.salePrice,
      valueFormatter: value => value && `$${value?.toString()}`,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1}
          >
            <Typography variant="body2">
              {params.row.job?.salePrice
                ? `$${formatNumber(params.row.job?.salePrice ?? 0)}`
                : ""}
            </Typography>
          </Box>
        );
      },
      sortable: true,
    },
    {
      field: "Job Selling Company",
      headerName: "Referral Partner",
      width: 200,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1}
          >
            <CompanyAvatar
              logo={
                params.row.job?.listingExtraInfo?.createdByCompanyLogoUrl ||
                undefined
              }
              name={params.row.courtesyOf?.companyName}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
            <Typography variant="body2">
              {params.row.courtesyOf?.companyName}
            </Typography>
          </Box>
        );
      },
      valueGetter: (value, row) => row.courtesyOf?.companyName,
      sortable: true,
    },

    {
      field: "recommendedBy",
      headerName: "Technician Name",
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1}
          >
            <Typography variant="body2">
              {params.row.job?.listingExtraInfo?.technicianName}
            </Typography>
          </Box>
        );
      },
      valueGetter: (value, row) => row.job?.listingExtraInfo?.technicianName,
      width: 160,
    },
    {
      field: "createdAt",
      headerName: "Created At",

      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
            gap={1}
          >
            <Typography variant="body2">
              {formatDateTime(params.row.job?.createdAt)}
            </Typography>
          </Box>
        );
      },
      valueFormatter: formatDateTime,
      valueGetter: (value, row) =>
        DateTime.fromFormat(
          row.job?.createdAt.toString().split(".")[0].split("T").join(" "),
          "yyyy-MM-dd HH:mm:ss",
          { zone: "utc" }
        )
          .toLocal()
          .toFormat("MM/dd/yy hh:mm a"),
      width: 200,
    },
  ];

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (params.field === "jobId" || params.field === "jobType")
      onClick(params.row);
  };

  return (
    <Box display={"flex"} width={"100%"} sx={{ p: 0 }}>
      <Box
        sx={{
          height: 400,
          //   position: "relative",
          width: "100%",
        }}
      >
        <DataGridPremium
          checkboxSelection
          disableRowSelectionOnClick
          cellSelection
          pagination
          columns={columns}
          sx={{
            "& .MuiDataGrid-row": {
              "--rowBorderColor": "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withRightBorder": {
              borderRightColor: "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withLeftBorder": {
              borderLeftColor: "#D9D9D9",
            },
          }}
          rows={rows ?? []}
          slots={{
            ...(expanded
              ? { toolbar: () => <CustomToolbar fileName="Jobs" /> }
              : {}),
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          initialState={{
            density: "compact",
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          slotProps={{
            toolbar: { setRowModesModel },
          }}
          onCellClick={handleCellClick}
          onRowModesModelChange={handleRowModesModelChange}
        />
      </Box>
    </Box>
  );
}
