import { Avatar } from "@mui/material";
import { FC, useState } from "react";

import { ToolTip } from "@/common/tool-tip/ToolTip";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";

interface MutualPartnerProps {
  companyId: number;
}

export const MutualPartner: FC<MutualPartnerProps> = ({ companyId }) => {
  const { data: companyProfile } = useFetchCompanyProfile(companyId, {
    refetchOnWindowFocus: false,
  });

  const [isImageLoadError, setIsImageLoadError] = useState(false);

  if (!companyProfile) return null;
  if (companyProfile?.logoUrl && !isImageLoadError) {
    return (
      <ToolTip message={companyProfile?.name}>
        <img
          key={companyId}
          style={{
            objectFit: "contain",
            width: "80px",
            height: "60px",
            marginRight: "16px",
          }}
          src={companyProfile?.logoUrl}
          alt={companyId.toString()}
          onError={() => setIsImageLoadError(true)}
        />
      </ToolTip>
    );
  }
  const firstInitial = companyProfile?.name?.split(" ")?.[0]?.[0] ?? "";
  const secondInitial = companyProfile?.name?.split(" ")?.[1]?.[0] ?? "";
  return (
    <ToolTip
      key={`${firstInitial}${secondInitial}`}
      message={companyProfile?.name}
    >
      <Avatar
        sx={{
          maxWidth: "80px",
          maxHeight: "60px",
          mr: 1,
          "& img": {
            objectFit: "contain",
          },
        }}
      >{`${firstInitial}${secondInitial}`}</Avatar>
    </ToolTip>
  );
};
