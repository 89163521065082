import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { TextInputField } from "@/lib/form-fields/TextInputField";

export const searchReferralCampaignsFormSchema = yup.object().shape({
  searchWord: yup.string(),
});

export type SearchReferralCampaignsFormFields = yup.InferType<
  typeof searchReferralCampaignsFormSchema
>;

interface ReferralCampaignsSearchFilterFormProps {
  formMethods: ReturnType<typeof useFormik<SearchReferralCampaignsFormFields>>;
  isLoading: boolean;
}

export function ReferralCampaignsSearchFilterForm(
  props: ReferralCampaignsSearchFilterFormProps
) {
  const { formMethods, isLoading } = props;

  return (
    <Box component="form" onSubmit={formMethods.handleSubmit}>
      <Grid
        container
        sx={{
          top: "56px",
          pt: 1,
        }}
        position="sticky"
        bgcolor="white"
      >
        <Grid item xs={12} lg={10} sx={{ paddingY: 1 }}>
          <TextInputField
            fullWidth
            inputProps={{
              style: {
                paddingTop: "8px",
                paddingBottom: "8px",
              },
            }}
            placeholder="Search by company name or keyword"
            name="searchWord"
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <LoadingButton
            type="submit"
            size="large"
            loading={isLoading || formMethods.isSubmitting}
            startIcon={<FilterAltOutlinedIcon />}
            sx={{ ".MuiButton-startIcon": { marginX: "0" } }}
          >
            Add Filters
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
