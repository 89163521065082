import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

import { Company } from "../types";

export function useCompanyBuyCredits(
  options: UseMutationOptions<Company, AxiosError, number> = {}
): UseMutationResult<Company, AxiosError, number> {
  return useMutation({
    mutationFn: async (credits: number): Promise<Company> => {
      const response = await API.post(`company-payments/credits`, {
        credits,
      });
      return response.data.data;
    },
    ...options,
  });
}
