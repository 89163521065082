import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

export interface Territory {
  territoryGeoIds: Array<string>;
  territory: unknown;
}

export function useFetchCompanyTerritory(
  companyId?: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Territory> {
  const uri = `/companies/${companyId}/territory`;
  return useQuery({
    queryKey: [uri],

    queryFn: async () => {
      const resp = await API.get(uri);
      return resp.data.data;
    },
    ...options,
  });
}
