import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { useTranslation } from "react-i18next";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import HomeSmile from "@/common/icons/untitled-ui/duocolor/home-smile";
import { DashboardLayout, NavLink } from "@/common/layouts/DashboardLayout";
import ProtectedRoute from "@/common/ProtectedRoutes";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyRoutes } from "@/company/types";
import { getUserRoleLabel } from "@/company/utils";

import SideNavHeader from "./SideNavHeader";

export function ConsultantDashboardLayout() {
  const { session } = useAuth();
  const { t } = useTranslation();

  const companyPrimaryNavLinks: NavLink[] = [
    {
      label: t("home"),
      link: getNavigationPathFromRoutePaths(["consultant", "home"]),
      icon: <HomeSmile />,
    },
  ];

  const CompanyTeamMemberAccountButtons = [
    {
      label: t("profile"),
      link: getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.PROFILE,
      ]),
      icon: <ManageAccountsOutlinedIcon />,
    },
  ];

  return (
    <>
      <DashboardLayout
        primaryNavLinks={companyPrimaryNavLinks}
        sectionedNavLinks={[]}
        sideNavHeader={
          <SideNavHeader
            companyName={session?.company?.name || ""}
            userRoleLabel={getUserRoleLabel(session)}
            companyLogoUrl={session?.company?.logoUrl}
          />
        }
        accountButtons={CompanyTeamMemberAccountButtons}
        bottomNavBarProps={{
          navLinks: [],
          onClickAction: () => {
            console.log("consultant");
          },
          actionIcon: (
            <img src="/whatsapp-redirect.svg" alt="submit jobs on whatsapp" />
          ),
          enabled: false,
        }}
      >
        <ProtectedRoute userRole={USER_TYPE.COMPANY_TEAM_MEMBER} />
      </DashboardLayout>
    </>
  );
}
