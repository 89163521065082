import "./charts-toolbar.css"; // Import your custom CSS file

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { useTheme } from "@mui/material/styles";
import type { ApexOptions } from "apexcharts";
import PropTypes from "prop-types";
import type { FC } from "react";

import { Chart } from "./chart";

type ChartSeries = {
  name: string;
  data: (number | null)[];
}[];

const useChartOptions = (
  categories: Array<string>,
  yLabel: string,
  xLabel: string
): ApexOptions => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        className: "custom-toolbar",
        show: true, // Enable the toolbar
        tools: {
          download: false, // Allow download as image
          selection: true, // Enable selection
          zoom: true, // Enable zooming
          zoomin: true, // Enable zoom-in button
          zoomout: true, // Enable zoom-out button
          pan: false, // Enable panning
          reset: true, // Enable resetting zoom
        },
        autoSelected: "zoom", // Set the default selected tool
      },
    },
    colors: [theme.palette.primary.main],
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      radius: 2,
      shape: "circle",
      size: 4,
      strokeWidth: 0,
    },
    stroke: {
      curve: "smooth",
      lineCap: "butt",
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      tickAmount: 10,
      title: {
        text: xLabel ?? "Date", // X-axis label
      },
      categories: categories,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: [
      {
        axisBorder: {
          show: true,
        },

        forceNiceScale: true,
        axisTicks: {
          show: true,
        },
        labels: {
          formatter: function (value) {
            return Math.round(value); // Ensures only integers are displayed
          },
          show: true,
        },
        title: {
          text: yLabel ?? "Jobs",
        },
      },
    ],
  };
};

interface AnalyticsSourcesProps {
  chartSeries: ChartSeries;
  categories: Array<string>;
  title: string;
  tooltip: string;
  yLabel: string;
  xLabel: string;
}

export const AnalyticsSources: FC<AnalyticsSourcesProps> = props => {
  const chartOptions = useChartOptions(
    props.categories,
    props.yLabel,
    props.xLabel
  );

  return (
    <Card
      sx={{
        boxShadow: "0px 0px 0px 1px #F2F4F7",
      }}
    >
      <CardHeader
        sx={{ mb: 0, py: 3, pb: "0 !important" }}
        title={
          <Box display={"flex"} flexDirection={"column"} gap={0.5}>
            <Typography variant="h6">{props.title}</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              Drag to select and zoom in on specific job intervals and dates,
              enabling focused analysis of job trends over time.
            </Typography>
          </Box>
        }
      />
      <CardContent sx={{ p: 2, py: 1, pb: "0 !important" }}>
        <Chart
          options={chartOptions ?? []}
          series={props.chartSeries ?? []}
          type="line"
          height="400"
          onMouseHover
        />
        <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"} gap={2}>
          {props.chartSeries?.map((item, index) => (
            <Box
              key={index}
              display={"flex"}
              flexWrap={"wrap"}
              alignItems={"center"}
              gap={2}
            ></Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

AnalyticsSources.propTypes = {
  chartSeries: PropTypes.any.isRequired,
  labels: PropTypes.array.isRequired,
};
