import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import { FC } from "react";

import { ToolTip } from "@/common/tool-tip/ToolTip";
import {
  CompanyPublicProfile,
  PayReferralFeeSettings,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { MutualPartner } from "./MutualPartner";

interface ActivityProps {
  otherCompanyProfile: CompanyPublicProfile;
}

export const Activity: FC<ActivityProps> = ({ otherCompanyProfile }) => {
  return (
    <>
      <Box display={"flex"} py={1} alignItems={"center"}>
        <Typography variant="h5">
          {otherCompanyProfile.name}
          {otherCompanyProfile.platformPresence ===
          PLATFORM_PRESENCE.ON_PLATFORM ? (
            <ToolTip
              placement="bottom"
              title="TradeEngage Participant"
              message="This is a verified active business on TradeEngage."
            >
              <VerifiedOutlinedIcon
                sx={{ verticalAlign: "center", color: "#16DB65", ml: 1 }}
              />
            </ToolTip>
          ) : null}
        </Typography>
      </Box>
      <Box>
        {otherCompanyProfile?.profile?.payPerReferral ? (
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
              gap={0.5}
              py={1}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  gutterBottom
                  variant="overline"
                  color="rgba(17, 25, 39, 0.38)"
                >
                  Pay Per Referral
                </Typography>
                <ToolTip
                  placement="bottom"
                  title="Pay Per Referral"
                  message={
                    otherCompanyProfile?.profile?.payReferralFeeAfterJobIsDone
                      ? "This company pays for every referral, then gives a bonus if the job closes!"
                      : "This company pays for every referral they receive."
                  }
                >
                  <HelpOutlineIcon
                    sx={{
                      background: "none",
                      color: "#6C737F",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </ToolTip>
              </Box>
              {otherCompanyProfile.sectors.map(({ sector, leadPrice }) => (
                <Box key={sector} display={"flex"}>
                  <PaidOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#B5BCC4" }}
                  />
                  <Typography variant="body2" ml={1}>
                    {`Pays $${leadPrice ?? 25} / ${
                      snakeCaseJobSectors[sector]
                    }`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </>
        ) : null}
        {otherCompanyProfile?.profile?.payReferralFeeAfterJobIsDone ? (
          <>
            {" "}
            <Divider />{" "}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              py={1}
              gap={0.5}
              flexDirection={"column"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  gutterBottom
                  variant="overline"
                  color="rgba(17, 25, 39, 0.38)"
                >
                  Pay Per Job Completion
                </Typography>
                <ToolTip
                  placement="bottom"
                  title="Pay Per Job Completion"
                  message={
                    otherCompanyProfile?.profile?.payPerReferral
                      ? "This company pays for every referral, then gives a bonus if the job closes!"
                      : "This company pays for referrals when the job closes."
                  }
                >
                  <HelpOutlineIcon
                    sx={{
                      background: "none",
                      color: "#6C737F",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </ToolTip>
              </Box>
              {otherCompanyProfile.sectors.map(
                ({ sector, buyingPrice, percentagePrice }) => (
                  <Box key={sector} display={"flex"}>
                    <PaidOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#B5BCC4" }}
                    />
                    <Typography variant="body2" ml={1}>
                      {otherCompanyProfile.profile
                        ?.payReferralFeeAfterJobIsDone &&
                      otherCompanyProfile.profile?.payReferralFeeSettings ===
                        PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE
                        ? `Pays ${percentagePrice}% / ${snakeCaseJobSectors[sector]}`
                        : otherCompanyProfile.profile
                            ?.payReferralFeeSettings ===
                          PayReferralFeeSettings.FIXED_AMOUNT
                        ? `Pays $${buyingPrice ?? 25} / ${
                            snakeCaseJobSectors[sector]
                          }`
                        : `Varies Depending on Job / ${snakeCaseJobSectors[sector]}`}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          </>
        ) : null}
      </Box>
      <Box display={"flex"} flexDirection={"column"} py={1} gap={2}>
        <Divider />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            gutterBottom
            variant="overline"
            color="rgba(17, 25, 39, 0.38)"
          >
            PARTNERS OVERVIEW
          </Typography>
        </Box>
        {otherCompanyProfile.totalReferralPartners ? (
          <Box display={"flex"}>
            <GroupsOutlinedIcon fontSize="small" sx={{ color: "#B5BCC4" }} />
            <Typography variant="body2" ml={1}>
              {otherCompanyProfile.totalReferralPartners} Total Partners
            </Typography>
          </Box>
        ) : null}
        {otherCompanyProfile.mutualReferralPartners?.length ? (
          <Box display={"flex"}>
            <SupervisorAccountOutlinedIcon
              fontSize="small"
              sx={{ color: "#B5BCC4" }}
            />
            <Typography variant="body2" ml={1}>
              {otherCompanyProfile.mutualReferralPartners?.length} Mutual
              Partners
            </Typography>
          </Box>
        ) : null}
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            gutterBottom
            variant="overline"
            color="rgba(17, 25, 39, 0.38)"
          >
            REFERRAL BALANCE
          </Typography>
          <ToolTip
            placement="bottom"
            title="Referral Balance"
            message="This shows whether a company approves more jobs (longer green bar to the right) or refers more jobs (green bar to the left)."
          >
            <HelpOutlineIcon
              sx={{
                background: "none",
                color: "#6C737F",
                width: "20px",
                height: "20px",
              }}
            />
          </ToolTip>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} py={1}>
          <Typography variant="subtitle2">Jobs Approved</Typography>
          <Typography variant="subtitle2">Jobs Referred</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            py: 1,
          }}
        >
          <LinearProgress
            color="primary"
            variant="determinate"
            value={otherCompanyProfile.receivingJobsPercentage}
            sx={{ height: "8px" }}
          />
        </Box>
      </Box>
      {otherCompanyProfile?.mutualReferralPartners?.length ? (
        <>
          <Divider sx={{ my: 1 }} />
          <Box>
            <Box>
              <Typography
                gutterBottom
                variant="overline"
                color="rgba(17, 25, 39, 0.38)"
              >
                MUTUAL PARTNERS
              </Typography>
              <Box
                display={"flex"}
                gap={2}
                sx={{
                  alignItems: "center",

                  flexWrap: "wrap",
                }}
              >
                {otherCompanyProfile.mutualReferralPartners.map(companyId => (
                  <MutualPartner key={companyId} companyId={companyId} />
                ))}
              </Box>
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};
