import constate from "constate";
import { useState } from "react";

function useBackDrop() {
  const [backDropOn, setBackDropOn] = useState(false);

  return { backDropOn, setBackDropOn };
}

export const [BackDropProvider, useBackdropContext] = constate(useBackDrop);
