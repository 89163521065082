import { Close as CloseIcon } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  tabsClasses,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from "react";

import { useAuth } from "@/auth/context/jwt";
import { CompanyExploreFilters } from "@/company/api/useExploreConnections";
import { useQueryExplorePartners } from "@/company/api/useExplorePartners";
import { RecommendedPartnerCard } from "@/company/components/company-profile-card/RecommendedPartnerCard";
import ModifySectorDialog from "@/company/components/screens/job-calls-page/ModifySectorDialog";
import { useReferralContext } from "@/company/context/referral-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import { useUpdateJobSector } from "@/company/jobs/api/useUpdateJobSector";
import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import { CompanyPublicProfile, ExternalCompanyProfile } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { customCompanyProfileSort } from "@/utils";

enum TabsEnumPartners {
  MY_PARTNERS = "MY_PARTNERS",
  POTENTIAL_PARTNERS = "POTENTIAL_PARTNERS",
}

export function ExplorePartnerJobCard({
  job,
  isOpen,
  onClose,
  onConfirm,
  companyId,
  onAddNew,
  refetchCalls,
  sector,
  setSector,
  isSellingCompany,
}: {
  job: Job;
  isOpen: boolean;
  isSellingCompany: boolean;
  companyId: number;
  onAddNew: () => void;
  refetchCalls?: () => void;
  sector: JobSectors;
  setSector: (sector: JobSectors) => void;
  onConfirm: (
    companyProfile: CompanyPublicProfile | ExternalCompanyProfile
  ) => void;
  onClose: () => void;
}) {
  const { session } = useAuth();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const [modifyJob, setModifyJob] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState("");

  const [companyLimit, setCompanyLimit] = useState<number | null>(9);

  const [filters, setFilters] = useState<CompanyExploreFilters>({});

  const { isLoading: updatingJobSector, mutateAsync: updateJobSector } =
    useUpdateJobSector({
      onSuccess: (job: Job) => {
        setSector(job.sector);
        setModifyJob(false);
        refetchCalls?.();
        enqueueSnackbar({
          message: "Job service category updated successfully.",
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while updating job service category details.`,
          variant: "error",
        });
      },
    });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [currentTab, setCurrentTab] = useState<TabsEnumPartners>(
    TabsEnumPartners.MY_PARTNERS
  );

  const { isFetching: isFetchingCompanies, data } = useQueryExplorePartners(
    {
      ...filters,
      sectors: isSellingCompany
        ? [sector ?? job?.sector ?? JobSectors.OTHER]
        : Object.keys(JobSectors),
      nearby: true,
      ...(companyLimit ? { limit: 9 } : {}),
    },

    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { isFetching: isFetchingGoogleCompanies, data: googleCompaniesData } =
    useQueryExplorePartners(
      {
        ...filters,
        sectors: isSellingCompany
          ? [sector ?? job?.sector ?? JobSectors.OTHER]
          : Object.keys(JobSectors),
        nearby: true,
        isGoogleCompany: true,
        limit: 20,
      },
      {
        refetchOnWindowFocus: false,
        retry: false,
      }
    );

  const [isSticky, setIsSticky] = useState(false);

  const [partnerCompanyProfile, setPartnerCompanyProfile] = useState<
    CompanyPublicProfile | ExternalCompanyProfile | undefined
  >();

  const { referralInvitations } = useReferralInvitationContext();

  const jobSectorOptions = !isSellingCompany
    ? session?.companySectors?.map(sector => sector.sector) ?? []
    : Object.keys(JobSectors);
  const { referralPartners, isFetchingReferralPartners } = useReferralContext();

  const memoizedOtherCompanies = useMemo(() => {
    let otherCompanies = data ?? [];

    if (googleCompaniesData?.length) {
      otherCompanies = [...otherCompanies, ...googleCompaniesData];
      otherCompanies.sort(customCompanyProfileSort);
    }
    const googlePlacesIdSet = new Set<string>();

    otherCompanies = otherCompanies.filter(company => {
      const googleId =
        company.companyPublicProfile?.profile.googlePlacesId ??
        company.googleCompanyProfile?.googlePlacesId;
      if (googleId) {
        if (googlePlacesIdSet.has(googleId)) return false;
        googlePlacesIdSet.add(googleId);
        return true;
      }
      return true;
    });
    return otherCompanies;
  }, [data, googleCompaniesData]);

  const memoizedReferralPartners = useMemo(() => {
    const IdSet = new Set<number>();

    const uniqReferralPartners = (
      [...(referralPartners ?? []), ...(referralInvitations ?? [])] ?? []
    ).filter(company => {
      const companyId = company.otherCompanyProfile?.id;
      if (companyId) {
        if (IdSet.has(companyId)) return false;
        IdSet.add(companyId);
        return true;
      }
      return true;
    });
    return uniqReferralPartners.filter(referral =>
      !isSellingCompany
        ? true
        : referral.otherCompanyProfile?.sectors?.some(
            currentSector => currentSector.sector === sector
          )
    );
  }, [referralPartners, referralInvitations, sector]);

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: TabsEnumPartners): void => {
      setCurrentTab(value);
    },
    []
  );

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === "Enter") {
      if (searchKeyword) {
        setFilters(filter => ({
          ...filter,
          keyword: searchKeyword,
        }));

        return;
      }
      setFilters(filter => {
        const newFilter = { ...filter };
        delete newFilter.keyword;

        return newFilter;
      });
      setCompanyLimit(null);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        sx={{ m: { xs: -3, md: 0 } }}
        open={isOpen}
        maxWidth="lg"
        onClose={() => onClose()}
      >
        <DialogTitle component="div" width="95%">
          <Typography variant="h5">Link the Referral Partner</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
            {!isSellingCompany
              ? "Select the referral partner that referred this job to you"
              : "Select the referral partner to route this job to"}
          </Typography>
          <Box display={"flex"} gap={2} alignItems={"center"} sx={{ mt: 1 }}>
            <Typography variant="body2" fontWeight={600}>
              Service Category:-
            </Typography>
            <Chip
              key={sector}
              sx={{
                color: "text.secondary",
                bgcolor: "#F8F9FA",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: 0.14,
                wordWrap: "break-word",
              }}
              label={
                snakeCaseJobSectors[sector as JobSectors] ??
                snakeCaseJobSectors[JobSectors.OTHER]
              }
              size="small"
            />
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setModifyJob(true);
              }}
            >
              Edit Service Category
            </Button>
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ px: { xs: 0, md: 3 }, pt: 0, height: "80vh" }}>
          <Grid container>
            <Grid
              item
              sx={{ zIndex: 400, position: "sticky", top: 0 }}
              bgcolor={"white"}
              xs={12}
              lg={isDesktop ? false : 12}
            >
              <Grid
                container
                sx={{
                  py: isMobile ? 1 : 2,
                  zIndex: 400,

                  bgcolor: "white",
                  position: "sticky",
                  top: 0,
                }}
                gap={{ xs: 0, lg: 0 }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  sx={{
                    background: "white",
                    zIndex: 1000,
                    position: "sticky",
                    width: "100%",
                    top: 0,
                  }}
                >
                  <Tabs
                    value={currentTab}
                    variant="scrollable"
                    scrollButtons={isDesktop ? false : true}
                    allowScrollButtonsMobile={isDesktop ? false : true}
                    sx={{
                      ...(isDesktop
                        ? {}
                        : {
                            [`& .${tabsClasses.scrollButtons}`]: {
                              "&.Mui-disabled": { opacity: 0.3 },
                            },
                          }),
                    }}
                    onChange={handleTabsChange}
                  >
                    <Tab
                      value={TabsEnumPartners.MY_PARTNERS}
                      label="My Partners"
                    />
                    <Tab
                      value={TabsEnumPartners.POTENTIAL_PARTNERS}
                      label="Potential Partners"
                    />
                  </Tabs>
                  <Divider />
                </Box>
                {currentTab === TabsEnumPartners.POTENTIAL_PARTNERS &&
                companyLimit ? (
                  <>
                    <Grid item xs={9} lg={10.5} px={isMobile ? 2 : 0} mt={1}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size={isMobile ? "small" : "medium"}
                        name="filterKeyword"
                        placeholder="Search by company name or keyword"
                        value={searchKeyword ?? ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {searchKeyword ? (
                                // eslint-disable-next-line react/jsx-sort-props
                                <CloseIcon
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSearchKeyword("");
                                    setFilters(filter => {
                                      const newFilter = { ...filter };
                                      delete newFilter.keyword;
                                      return newFilter;
                                    });
                                    setCompanyLimit(9);
                                  }}
                                />
                              ) : null}
                            </InputAdornment>
                          ),
                        }}
                        onKeyUp={handleKeyUp}
                        onChange={event => setSearchKeyword(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={2} lg={1.5} px={{ xs: 0, lg: 2 }} mt={1}>
                      {isMobile ? (
                        <IconButton
                          size="small"
                          sx={{
                            p: 0.5,
                            bgcolor: "#16B364",
                            borderRadius: "8px",
                            mx: 1,
                            "&:hover": { bgcolor: "#16B364" },
                          }}
                          onClick={() => {
                            if (searchKeyword) {
                              setFilters(filter => ({
                                ...filter,
                                keyword: searchKeyword,
                              }));
                              setCompanyLimit(null);
                              return;
                            }
                            setFilters(filter => {
                              const newFilter = { ...filter };
                              delete newFilter.keyword;
                              return newFilter;
                            });
                            setCompanyLimit(9);
                          }}
                        >
                          <SearchOutlinedIcon sx={{ color: "white" }} />
                        </IconButton>
                      ) : (
                        <Button
                          fullWidth
                          color="primary"
                          variant="contained"
                          startIcon={<SearchOutlinedIcon />}
                          onClick={() => {
                            if (searchKeyword) {
                              setFilters(filter => ({
                                ...filter,
                                keyword: searchKeyword,
                              }));
                              setCompanyLimit(null);

                              return;
                            }
                            setFilters(filter => {
                              const newFilter = { ...filter };
                              delete newFilter.keyword;
                              return newFilter;
                            });
                            setCompanyLimit(9);
                          }}
                        >
                          Search
                        </Button>
                      )}
                    </Grid>
                  </>
                ) : null}
                {currentTab === TabsEnumPartners.POTENTIAL_PARTNERS ? (
                  <Alert
                    variant="standard"
                    severity="info"
                    sx={{
                      my: 2,
                      width: "100%",
                      backgroundColor: (theme: Theme) =>
                        theme.palette.info.light,
                      color: (theme: Theme) => theme.palette.info.main,
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "#00359E" }}>
                      You’re not connected with these partners. Selecting one
                      will send an invitation and route the job upon acceptance.
                      This may take some time.
                    </Typography>
                  </Alert>
                ) : null}
                {!companyLimit ? (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    flexDirection={isMobile ? "column" : "row"}
                    width={"100%"}
                    py={1}
                    pr={3}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                      sx={{
                        ...(isMobile ? { width: "100%" } : { minWidth: "85%" }),
                        flexWrap: isMobile ? "none" : "wrap",
                        overflow: isMobile ? "auto" : "none",
                      }}
                    >
                      <Chip
                        key={searchKeyword ?? companyLimit}
                        label={
                          searchKeyword
                            ? searchKeyword
                            : !companyLimit
                            ? "Other nearby referral partners for you"
                            : ""
                        }
                        color="primary"
                        variant="outlined"
                        sx={{ mr: 1 }}
                        size="small"
                        onDelete={() => {
                          setSearchKeyword("");
                          setCompanyLimit(9);
                        }}
                      />
                    </Box>
                    <Button
                      color="error"
                      variant="text"
                      sx={{
                        flex: "auto",
                        minWidth: isMobile ? "none" : "200px",
                      }}
                      startIcon={<CloseOutlinedIcon />}
                      onClick={() => {
                        setSearchKeyword("");
                        setCompanyLimit(9);
                      }}
                    >
                      Clear Filter
                    </Button>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              lg={isDesktop ? false : 12}
              sx={{ ...(isSticky && isMobile ? { mt: "56px" } : {}) }}
            >
              <Grid container>
                <Grid item xs={12}>
                  {currentTab === TabsEnumPartners.MY_PARTNERS ? (
                    <Grid
                      container
                      py={isDesktop ? 3 : 1}
                      sx={{
                        ...(isSticky && !isDesktop ? { mt: "112px" } : {}),
                        overflow: "auto",
                      }}
                    >
                      {isFetchingReferralPartners ? (
                        <Box
                          py={3}
                          mt={6}
                          px={1}
                          textAlign="center"
                          width={"100%"}
                          display={"flex"}
                          alignItems={"center"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <CircularProgress />
                          <Typography variant="body1">
                            Hang tight! We&apos;re building your list of
                            referral partners.
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          {memoizedReferralPartners &&
                          memoizedReferralPartners.length > 0
                            ? memoizedReferralPartners.map(
                                (referralPartner, index) => (
                                  <Grid
                                    key={referralPartner.id}
                                    item
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    p={2}
                                  >
                                    <RecommendedPartnerCard
                                      companyProfile={
                                        referralPartner.otherCompanyProfile
                                      }
                                      isSelected={
                                        partnerCompanyProfile?.id ===
                                        referralPartner.otherCompanyProfile.id
                                      }
                                      onClick={() => {
                                        if (
                                          partnerCompanyProfile?.id ===
                                          referralPartner.otherCompanyProfile.id
                                        ) {
                                          setPartnerCompanyProfile(undefined);
                                        } else
                                          setPartnerCompanyProfile(
                                            referralPartner.otherCompanyProfile
                                          );
                                      }}
                                    />
                                  </Grid>
                                )
                              )
                            : null}
                          {referralPartners?.length ? null : (
                            <Box textAlign="center" width="100%" py={3} px={1}>
                              <Typography variant="body1">
                                You don&apos;t have any referral partners.
                                Connect with referral partners by exploring
                                affiliate companies or other recommendations!
                              </Typography>
                            </Box>
                          )}
                        </>
                      )}
                    </Grid>
                  ) : null}
                  {currentTab === TabsEnumPartners.POTENTIAL_PARTNERS ? (
                    <Grid
                      container
                      py={3}
                      sx={{
                        ...(isSticky && !isDesktop ? { mt: "112px" } : {}),
                        overflow: "auto",
                      }}
                    >
                      {isFetchingCompanies ? (
                        <Box
                          py={3}
                          px={1}
                          mt={6}
                          textAlign="center"
                          width={"100%"}
                          display={"flex"}
                          alignItems={"center"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <CircularProgress />
                          <Typography variant="body1">
                            Hang tight! We&apos;re building your custom list of
                            potential partners.
                          </Typography>
                        </Box>
                      ) : memoizedOtherCompanies &&
                        memoizedOtherCompanies?.length > 0 ? (
                        !companyLimit ? (
                          <>
                            {memoizedOtherCompanies?.map(
                              (companyData, index) => (
                                <Grid
                                  key={index}
                                  item
                                  xs={12}
                                  sm={6}
                                  lg={4}
                                  p={2}
                                >
                                  <RecommendedPartnerCard
                                    {...(companyData.companyPublicProfile
                                      ? {
                                          companyProfile:
                                            companyData.companyPublicProfile,
                                        }
                                      : {})}
                                    {...(companyData.googleCompanyProfile
                                      ? {
                                          googleCompanyProfile:
                                            companyData.googleCompanyProfile,
                                        }
                                      : {})}
                                    isSelected={
                                      companyData.companyPublicProfile
                                        ? partnerCompanyProfile?.id ===
                                          companyData.companyPublicProfile.id
                                        : partnerCompanyProfile?.googlePlacesId ===
                                          companyData.googleCompanyProfile
                                            .googlePlacesId
                                    }
                                    onClick={() => {
                                      if (
                                        companyData.companyPublicProfile
                                          ? partnerCompanyProfile?.id ===
                                            companyData.companyPublicProfile.id
                                          : partnerCompanyProfile?.googlePlacesId ===
                                            companyData.googleCompanyProfile
                                              .googlePlacesId
                                      ) {
                                        setPartnerCompanyProfile(undefined);
                                      } else
                                        setPartnerCompanyProfile(
                                          companyData.companyPublicProfile
                                            ? companyData.companyPublicProfile
                                            : companyData.googleCompanyProfile
                                        );
                                    }}
                                  />
                                </Grid>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {memoizedOtherCompanies &&
                            memoizedOtherCompanies?.length > 0 ? (
                              <Accordion defaultExpanded sx={{ width: "100%" }}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <Box
                                    width={"100%"}
                                    display={"flex"}
                                    mr={isMobile ? 2 : 0}
                                    justifyContent={"space-between"}
                                    alignItems={isMobile ? "start" : "center"}
                                    flexDirection={isMobile ? "column" : "row"}
                                  >
                                    <Typography variant="h6">
                                      Other nearby referral partners for you
                                    </Typography>
                                    <Button
                                      endIcon={<ArrowForwardIcon />}
                                      sx={{
                                        color: "primary",
                                        ...(isMobile ? { px: 0, pb: 0 } : {}),
                                      }}
                                      onClick={() => {
                                        setCompanyLimit(null);
                                      }}
                                    >
                                      See all
                                    </Button>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container>
                                    {memoizedOtherCompanies
                                      .slice(0, 9)
                                      ?.map((companyData, index) => (
                                        <Grid
                                          key={index}
                                          item
                                          xs={12}
                                          sm={6}
                                          lg={4}
                                          p={2}
                                        >
                                          <RecommendedPartnerCard
                                            {...(companyData.companyPublicProfile
                                              ? {
                                                  companyProfile:
                                                    companyData.companyPublicProfile,
                                                }
                                              : {})}
                                            {...(companyData.googleCompanyProfile
                                              ? {
                                                  googleCompanyProfile:
                                                    companyData.googleCompanyProfile,
                                                }
                                              : {})}
                                            isSelected={
                                              companyData.companyPublicProfile
                                                ? partnerCompanyProfile?.id ===
                                                  companyData
                                                    .companyPublicProfile.id
                                                : partnerCompanyProfile?.googlePlacesId ===
                                                  companyData
                                                    .googleCompanyProfile
                                                    .googlePlacesId
                                            }
                                            onClick={() => {
                                              if (
                                                companyData.companyPublicProfile
                                                  ? partnerCompanyProfile?.id ===
                                                    companyData
                                                      .companyPublicProfile.id
                                                  : partnerCompanyProfile?.googlePlacesId ===
                                                    companyData
                                                      .googleCompanyProfile
                                                      .googlePlacesId
                                              ) {
                                                setPartnerCompanyProfile(
                                                  undefined
                                                );
                                              } else
                                                setPartnerCompanyProfile(
                                                  companyData.companyPublicProfile
                                                    ? companyData.companyPublicProfile
                                                    : companyData.googleCompanyProfile
                                                );
                                            }}
                                          />
                                        </Grid>
                                      ))}
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            ) : null}
                          </>
                        )
                      ) : (
                        <Box
                          textAlign="center"
                          width="100%"
                          py={3}
                          px={1}
                          sx={{
                            ...(isSticky && !isDesktop ? { mt: "112px" } : {}),
                          }}
                        >
                          <Typography variant="body1">
                            You don&apos;t have any nearby potential partners.
                            Connect with referral partners by exploring other
                            recommendations!
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "end",
            py: 3,
            px: 3,
            gap: 1,
          }}
        >
          <Button
            variant="outlined"
            disabled={!!partnerCompanyProfile}
            onClick={() => {
              onAddNew();
            }}
          >
            Add Company Details Manually
          </Button>
          <Button
            variant="contained"
            disabled={!partnerCompanyProfile}
            onClick={() => {
              if (partnerCompanyProfile) onConfirm(partnerCompanyProfile);
            }}
          >
            Confirm
          </Button>
        </Box>
      </Dialog>
      {modifyJob && job ? (
        <ModifySectorDialog
          open={modifyJob}
          modifyingSector={updatingJobSector}
          sectorOptions={jobSectorOptions}
          sector={sector}
          job={job}
          handleConfirm={newSector => {
            updateJobSector({ jobId: job?.id, sector: newSector });
          }}
          onClose={() => setModifyJob(false)}
        />
      ) : null}
    </>
  );
}
