import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";
import { Languages } from "@/common/constants/languages";
import { DefaultCompanyGroups, PLATFORM_PRESENCE } from "@/company/types";

interface UserPayload {
  firstName: string;
  lastName: string;
  language: Languages;
  platformPresence: PLATFORM_PRESENCE;
  email?: string;
  cellPhone?: string;
  jobTitle?: string;
}

interface CreateUserInternalAdminRequestPayload {
  user: UserPayload;
  group: DefaultCompanyGroups;
  companyId: number;
}

export function useCreateUser(
  options: UseMutationOptions<
    void,
    AxiosError,
    CreateUserInternalAdminRequestPayload
  > = {}
): UseMutationResult<void, AxiosError, CreateUserInternalAdminRequestPayload> {
  return useMutation({
    mutationFn: async payload => {
      const updateCompanyUri = `/internal-admin/users`;
      await API.post(updateCompanyUri, changeCaseKeys(payload, "underscored"));
    },
    ...options,
  });
}
