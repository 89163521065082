import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";
import { getS3KeysFromPresignedUrl } from "@/utils";

export type CreateJobAttachmentData = {
  jobId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  videoFile: any;
  fileName: string;
};

export function useCreateJobAttachment(
  options: UseMutationOptions<void, AxiosError, CreateJobAttachmentData> = {}
): UseMutationResult<void, AxiosError, CreateJobAttachmentData> {
  return useMutation({
    mutationFn: async (data): Promise<void> => {
      let dataToSend = {};

      // upload video
      let uploadUrlApi = `/jobs/:jobId/attachment-urls`;
      uploadUrlApi = uploadUrlApi.replace(":jobId", data.jobId.toString());
      const UploadUrlResponse = await API.post(uploadUrlApi, [data.fileName]);
      const uploadUrl = UploadUrlResponse.data.data;
      console.log({ uploadUrl });
      const url = uploadUrl[0].presignedUrl;
      await axios.put(url, data.videoFile);
      const s3Info = getS3KeysFromPresignedUrl(url);
      dataToSend = {
        name: data.fileName,
        bucket: s3Info?.bucket,
        key: s3Info?.objectKey,
      };

      // submit new job

      await API.post(
        `/jobs/${data.jobId}/attachments`,
        changeCaseKeys([dataToSend], "underscored")
      );
    },
    ...options,
  });
}
