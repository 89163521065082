import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { Box, Button, Typography } from "@mui/material";

import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

const ErrorBoundary = () => {
  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const handleReload = () => {
    window.location.href = "/"; // Navigate to home page and reload
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      gap={1}
      width="100%"
      textAlign={"center"}
      p={3}
    >
      <img
        src="/maintenance.png"
        style={{ width: "132px", height: "128px" }}
        alt="maintenance thumbnail"
      />
      <Typography gutterBottom variant="h5">
        Oops, something went wrong
      </Typography>
      <Typography gutterBottom variant="body1" color="text.secondary">
        Looks like there was a problem. Please refresh the page or return home.
      </Typography>
      <Button
        variant="contained"
        endIcon={<ArrowForwardOutlinedIcon />}
        onClick={() => {
          handleReload();
        }}
      >
        Return to Home
      </Button>
      <Typography variant="body1" color="text.secondary" mt={3}>
        If this issue continues, please{" "}
        <Typography
          variant="body1"
          color={"#16B364"}
          fontWeight={600}
          display={"inline"}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => setContactSupportModalOpen(true)}
        >
          contact support.
        </Typography>
      </Typography>
    </Box>
  );
};

export default ErrorBoundary;
