import { Box, Divider } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect, useRef } from "react";

import {
  ChatMessageAdd,
  ChatMessageAddSource,
} from "@/common/chat/ChatMessageAdd";
import {
  ChatThread,
  ChatType,
} from "@/company/api/useFetchAdminChatsByBrandLocation";
import { useMarkChatsWithAdminAsRead } from "@/company/api/useMarkChatsWithAdminAsRead";
import { useUpdateChatAdmin } from "@/company/api/useUpdateChatAdmin";
import { useAdminChatContext } from "@/company/context/admin-chat-context";

import { AdminChatMessages } from "./AdminChatMessages";

interface MessageProps {
  chatThread: ChatThread;
  onChatMessageAdd?: () => void;
}

export const MessageBox: FC<MessageProps> = ({ chatThread }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { refetch } = useAdminChatContext();

  const { mutateAsync: markChatsWithCompanyAsReadMutateFn } =
    useMarkChatsWithAdminAsRead();

  const { mutateAsync: updateChat } = useUpdateChatAdmin({
    onSuccess: () => {
      refetch();
    },
    onError: error => {
      enqueueSnackbar({
        message: `There was an error while posting the message.`,
        variant: "error",
      });
    },
  });

  const messagesRef = useRef();

  useEffect(() => {
    // Scroll to the bottom when messages change
    if (messagesRef.current)
      messagesRef.current.scrollTop = messagesRef?.current?.scrollHeight;
  }, [chatThread?.messages]);

  useEffect(() => {
    if (chatThread?.id && chatThread.unreadMessages)
      markChatsWithCompanyAsReadMutateFn(chatThread?.id).then(() => {
        refetch();
      });
  }, [chatThread?.messages]);

  // const { messagesRef } = useMessagesScroll(thread);
  return (
    <>
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        overflow={"auto"}
        pb={2}
      >
        <Box
          ref={messagesRef}
          sx={{
            flexGrow: "1",
            overflowY: "auto",
            bgcolor: "#F8F9FA",
          }}
        >
          <AdminChatMessages
            isAdmin={true}
            participants={chatThread.participants || []}
            chats={chatThread.messages || []}
          />
        </Box>
        {chatThread.type === ChatType.COMPANY_TO_USER ? (
          <Box bgcolor="white" px={1} pb={2}>
            <Divider sx={{ mb: 2 }} />
            <ChatMessageAdd
              source={ChatMessageAddSource.SUPPORT_CHAT}
              defaultText=""
              onSend={async (value: string) => {
                try {
                  updateChat({ message: value, chatId: chatThread.id });
                } catch {
                  enqueueSnackbar({
                    message:
                      "An error occurred while trying to send your message",
                  });
                }
              }}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
};
