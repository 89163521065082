import { DoneAllOutlined as DoneAllOutlinedIcon } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import * as yup from "yup";

import { NumberInputField } from "@/lib/form-fields/NumberInputField";

import { AddReferralCampaignFormInputField } from "./AddReferralCampaignFormInputField";

export const referralCampaignSuccessCriteriaFormSchema = yup.object().shape({
  successCriteria: yup
    .array()
    .of(
      yup.object().shape({
        criteria: yup.string().required("Success Criteria is required"),
        numberOfReferrals: yup
          .number()
          .required("Number of referrals is required")
          .min(1, "Minimum no. of referrals should be 1"),
        numberOfDays: yup
          .number()
          .required("Number of days is required")
          .min(1, "Minimum no. of days should be 1"),
      })
    )
    .required("Campaign Success Criteria is required")
    .min(1, "Campaign Success Criteria is required"),
});

export type ReferralCampaignSuccessCriteriaFormFields = yup.InferType<
  typeof referralCampaignSuccessCriteriaFormSchema
>;

export const referralCampaignSuccessCriteriaOptions = [
  "First to send # of referrals within # timeframe",
  "First to send # of jobs to within # timeframe",
  "Connect and receive # of referrals within # timeframe",
  "Connect with # of referral partners within # timeframe",
  "Sign up on TradeEngage",
];

export function ReferralCampaignSuccessCriteriaForm() {
  const [successCriteriaField, successCriteriaMeta, successCriteriaHelpers] =
    useField<ReferralCampaignSuccessCriteriaFormFields["successCriteria"]>(
      "successCriteria"
    );

  return (
    <Stack spacing={4}>
      <AddReferralCampaignFormInputField
        title="Success Criteria"
        icon={<DoneAllOutlinedIcon />}
        includeConnector={false}
      >
        <Box sx={{ marginTop: 2 }}>
          <FormControl>
            <FormLabel sx={{ mb: 1 }}>SELECT AT LEAST ONE</FormLabel>
            <FormGroup>
              {referralCampaignSuccessCriteriaOptions.map(option => {
                const successCriteriaIndex =
                  successCriteriaField.value.findIndex(
                    successCriteria => successCriteria.criteria === option
                  );
                return (
                  <>
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={successCriteriaIndex !== -1}
                          size="medium"
                          onChange={(_event, checked) => {
                            if (checked) {
                              successCriteriaHelpers.setValue([
                                ...successCriteriaField.value,
                                {
                                  criteria: option,
                                  numberOfReferrals: 1,
                                  numberOfDays: 1,
                                },
                              ]);
                            } else {
                              successCriteriaHelpers.setValue(
                                successCriteriaField.value.filter(
                                  (successCriteria: { criteria: string }) =>
                                    successCriteria.criteria !== option
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={<Typography variant="body2">{option}</Typography>}
                    />
                    {successCriteriaIndex !== -1 &&
                      option !== referralCampaignSuccessCriteriaOptions[4] && (
                        <Stack
                          key={option}
                          gap={1}
                          direction="row"
                          alignItems="center"
                          sx={{ paddingLeft: 4 }}
                        >
                          <NumberInputField
                            name={`successCriteria.${successCriteriaIndex}.numberOfReferrals`}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography variant="body1">
                                    Referrals
                                  </Typography>
                                </InputAdornment>
                              ),
                              sx: {
                                ".MuiFilledInput-input": {
                                  padding: 1.5,
                                },
                              },
                            }}
                            inputProps={{
                              min: 0,
                              step: 1,
                            }}
                          />
                          <Typography variant="body2">within</Typography>
                          <NumberInputField
                            name={`successCriteria.${successCriteriaIndex}.numberOfDays`}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography variant="body1">Days</Typography>
                                </InputAdornment>
                              ),
                              sx: {
                                ".MuiFilledInput-input": {
                                  padding: 1.5,
                                },
                              },
                            }}
                            inputProps={{
                              min: 0,
                              step: 1,
                            }}
                          />
                        </Stack>
                      )}
                  </>
                );
              })}
            </FormGroup>
            {successCriteriaMeta.error &&
              typeof successCriteriaMeta.error === "string" && (
                <FormHelperText error>
                  {successCriteriaMeta.error}
                </FormHelperText>
              )}
          </FormControl>
        </Box>
      </AddReferralCampaignFormInputField>
    </Stack>
  );
}
