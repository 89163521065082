import {
  Box,
  Card,
  CardHeader,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";

import { getCurrentOnboardingProgress } from "../utils";

export function OnboardingContainer({
  heading,
  subheading,
  children,
}: {
  heading: string;
  subheading?: string;
  children: ReactNode;
}) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { pathname } = useLocation();
  const progressValue = getCurrentOnboardingProgress(pathname);

  return (
    <Box
      sx={{
        ...(isMobile
          ? { px: 2, py: 1 }
          : {
              px: 4,
              py: 1,
            }),

        maxHeight: "100vh",
        height: "100%",
        overflow: "hidden",
        bgcolor: "white",
      }}
    >
      {isMobile ? (
        <Box
          display={"flex"}
          pb={2}
          pt={2}
          width="100%"
          justifyContent={"start"}
        >
          <TradeEngageLogo logotype={TradeEngageLogoTypes.LOGO_DARK} />
        </Box>
      ) : null}{" "}
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: isMobile ? "calc(100dvh - 72px)" : "100%",
          boxShadow: "none !important",
          overflow: "hidden",
          ...(isMobile
            ? { px: 0, py: 1 }
            : {
                border: "1px #E8ECEF solid",
                "&:hover": {
                  boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
                },
                px: 0,
                py: 1,
              }),
          alignItems: "center",
          alignSelf: "stretch",
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            ...(isMobile
              ? { p: 0, py: 1 }
              : {
                  pt: 3,
                  pb: 2,
                  px: 3,
                }),

            alignItems: "center",
            alignSelf: "stretch",
          }}
          subheader={
            <Typography color={"text.secondary"} variant="body2">
              {subheading}
            </Typography>
          }
          title={
            <Typography gutterBottom variant={isMobile ? "h6" : "h5"}>
              {heading}
            </Typography>
          }
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <LinearProgress
            variant="determinate"
            value={progressValue}
            sx={{
              flex: "none",
              height: "8px",
              [`&.${linearProgressClasses.colorPrimary}`]: {
                bgcolor: "#A2F1C1",
                color: "#15DB65",
              },
              [`& .${linearProgressClasses.bar}`]: {
                bgcolor: "#15DB65",
              },
            }}
          />
          {children}
        </Box>
      </Card>
    </Box>
  );
}
