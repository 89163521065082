import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

import { CallRecordingStatus } from "./useFetchCallRecordings";

export function useUpdateCallRecordingStatus(
  options: UseMutationOptions<
    void,
    AxiosError,
    { notificationId: number; status: CallRecordingStatus }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { notificationId: number; status: CallRecordingStatus }
> {
  return useMutation({
    mutationFn: async ({ status, notificationId }): Promise<void> => {
      await API.patch(`/call-center-notifications/${notificationId}`, {
        status,
      });
      return;
    },
    ...options,
  });
}
