import { Box, Grid } from "@mui/material";
import type { Theme } from "@mui/material/styles/createTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { type ReactNode, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { CompanyRoutes } from "@/company/types";

import BottomAppBar from "../bottom-nav/bottom-nav";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "../side-nav";
import { TopNav } from "../top-nav";
import { getNavigationPathFromRoutePaths } from "../utils/getNavigationPathFromRoutePaths";
import { useCallRecordsPolling } from "./useCallRecordsPolling";

export interface NavLink {
  label: string;
  link: string;
  icon: ReactNode;
  endIcon?: ReactNode;
}

export interface SectionedNavLinks {
  sectionName: string;
  navLinks: NavLink[];
}

export interface DashboardLayoutProps {
  primaryNavLinks: NavLink[];
  sectionedNavLinks: SectionedNavLinks[];
  children: ReactNode;
  sideNavHeader?: ReactNode;
  accountButtons: Array<{ label: string; link: string; icon: ReactNode }>;
  bottomNavBarProps?: {
    navLinks: [NavLink, NavLink, NavLink, NavLink];
    onClickAction: () => void;
    actionIcon?: ReactNode;
    enabled?: boolean;
  };
}

export function DashboardLayout(props: DashboardLayoutProps) {
  const {
    children,
    primaryNavLinks,
    sectionedNavLinks,
    sideNavHeader,
    accountButtons,
    bottomNavBarProps,
  } = props;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const isMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const location = useLocation();

  const isHubPage =
    location.pathname.startsWith("/consultant/home") ||
    location.pathname.startsWith("/company/hubs");

  const [searchParams] = useSearchParams();
  const extension = searchParams.get("chrome-extension");

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  useCallRecordsPolling();

  return (
    <Grid
      container
      display={"flex"}
      sx={{
        ...(isMedium &&
        location.pathname.startsWith(
          getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.LEADERBOARD,
          ])
        )
          ? { bgcolor: "#071D2F" }
          : {}),
      }}
    >
      {extension == "true" ? null : !isMobile && !isHubPage ? (
        <Grid item position={"relative"} sx={{ width: "320px" }}>
          <SideNav
            primaryNavLinks={primaryNavLinks}
            sectionedNavLinks={sectionedNavLinks}
            header={sideNavHeader}
          />
        </Grid>
      ) : (
        <MobileNav
          isOpen={isMobileNavOpen}
          primaryNavLinks={primaryNavLinks}
          sectionedNavLinks={sectionedNavLinks}
          header={sideNavHeader}
          closeMobileNav={() => setIsMobileNavOpen(false)}
        />
      )}
      <Grid
        item
        {...(isMobile || extension == "true" || isHubPage ? { xs: 12 } : {})}
        sx={{
          position: { sm: "relative" },

          ...(isMobile || extension == "true"
            ? {}
            : {
                flex: 1,
                maxWidth: `calc(100% - 320px)`,
              }),
        }}
      >
        <TopNav
          accountButtons={accountButtons}
          onMobileNavOpen={() => setIsMobileNavOpen(true)}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 2,
            pb: {
              xs: bottomNavBarProps && bottomNavBarProps.enabled ? 20 : 0,
              md: 0,
              minHeight: "calc(100vh - 60px)",
            },
          }}
        >
          {children}
        </Box>
        {bottomNavBarProps && bottomNavBarProps.enabled && isMobile ? (
          <BottomAppBar
            navLinks={bottomNavBarProps.navLinks}
            actionIcon={bottomNavBarProps.actionIcon}
            onClickAction={bottomNavBarProps.onClickAction}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
