/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { closeSnackbar, SnackbarKey } from "notistack";
import { FC, useState } from "react";

import { GoogleCompanyProfileDetails } from "@/common/GoogleCompanyDrawer/GoogleCompanyProfileDetails";
import { useMarkedInterestSetContext } from "@/company/context/marked-interest-context";
import { ExternalCompanyProfile } from "@/company/types";

import { CompanyAvatar } from "../company-avatar/CompanyAvatar";
import { ExternalInviteDialog } from "../external-invitation-dialog/ExternalInvitationDialog";

interface ExternalCompanyDrawerProps {
  onClose?: (hasMarkedInterest?: boolean) => void;
  open?: boolean;
  companyGoogleProfile?: ExternalCompanyProfile;
  hasMarkedInterest: boolean;
}

export enum TabsEnum {
  OVERVIEW = "overview",
  ACTIVITY = "activity",
  MESSAGE = "message",
  GOOGLE_PROFILE = "google-profile",
}

const action = (snackbarId: SnackbarKey, onClick: () => void) => (
  <>
    <Typography
      sx={{
        color: "#16DB65",
        fontSize: "14px",
        fontWeight: 600,
        pr: 2,
        cursor: "pointer",
      }}
      onClick={() => {
        onClick();
        closeSnackbar(snackbarId);
      }}
    >
      Contact Us
    </Typography>
    <CloseIcon
      sx={{ color: "white", cursor: "pointer", mr: 1 }}
      fontSize="small"
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    />
  </>
);

export const ExternalCompanyDrawer: FC<ExternalCompanyDrawerProps> = props => {
  const {
    onClose,
    open = false,
    companyGoogleProfile,
    hasMarkedInterest,
    ...other
  } = props;
  const { markedInterestSet } = useMarkedInterestSetContext();

  const [externalInviteDialogOpen, setExternalInviteDialogOpen] =
    useState(false);

  const [markedInterest, setMarkedInterest] = useState(false);

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 500,
          px: 1.5,
          pb: 0,
          pt: 0,
          height: "100%",
        },
      }}
      onClose={() => onClose?.(markedInterest)}
      {...other}
    >
      <Box display={"flex"} flexDirection={"column"}>
        <Box
          display="flex"
          justifyContent="space-between"
          px={3}
          pb={2}
          position={"sticky"}
          sx={{
            pt: 3,
            top: 0,
            zIndex: 400,
            background: "white",
          }}
        >
          <CompanyAvatar
            height={"48px"}
            logo={companyGoogleProfile?.logoUrl}
            name={companyGoogleProfile?.name || ""}
          />
          <IconButton
            aria-label="close"
            sx={{
              color: theme => theme.palette.grey[500],
            }}
            onClick={() => onClose?.(markedInterest)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={1.5}>
          <GoogleCompanyProfileDetails
            googlePlacesId={companyGoogleProfile?.googlePlacesId}
          />
        </Box>
      </Box>
      {hasMarkedInterest ||
      markedInterest ||
      markedInterestSet.has(
        companyGoogleProfile?.googlePlacesId ?? ""
      ) ? null : (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="start"
          spacing={2}
          px={3}
          pb={3}
          position={"sticky"}
          bottom={0}
          bgcolor={"white"}
        >
          <Divider sx={{ width: "100%" }} />
          <Button
            size="small"
            variant="contained"
            onClick={async () => {
              setExternalInviteDialogOpen(true);
            }}
          >
            Connect
          </Button>
        </Stack>
      )}
      {companyGoogleProfile ? (
        <ExternalInviteDialog
          isOpen={externalInviteDialogOpen}
          companyPublicProfile={{
            logoUrl: companyGoogleProfile?.logoUrl,
            name: companyGoogleProfile?.name || "",
            googlePlacesId: companyGoogleProfile?.googlePlacesId || "",
          }}
          onClose={invitationSent => {
            if (invitationSent) setMarkedInterest(true);
            setExternalInviteDialogOpen(false);
          }}
        />
      ) : null}
    </Drawer>
  );
};
