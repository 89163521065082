import { Close as CloseIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { SyntheticEvent } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { useFetchRelatedJobSectors } from "@/company/jobs/api/useFetchRelatedJobSectors";
import { useUpdateJobSector } from "@/company/jobs/api/useUpdateJobSector";
import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";

export interface UpdateJobSectorDialogProps {
  isOpen: boolean;
  job: Job;
  onClose: (sector?: JobSectors) => void;
}
const validationSchema = yup
  .object({
    sector: yup.string().optional(),
  })
  .optional();

export function ModifyJobDialog(props: UpdateJobSectorDialogProps) {
  const { session } = useAuth();

  const { job } = props;

  const formik = useFormik({
    initialValues: {
      sector: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      console.log("submit");
    },
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: updatingJobSector, mutateAsync: updateJobSector } =
    useUpdateJobSector({
      onSuccess: (job: Job) => {
        props.onClose(job.sector as JobSectors);
        formik.resetForm();
        enqueueSnackbar({
          message: "Update job sector successfully.",
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while updating job sector details.`,
          variant: "error",
        });
      },
    });

  const {
    isFetching: isFetchingRelatedJobSectors,
    data: fetchedRelatedJobSectors,
  } = useFetchRelatedJobSectors(job?.sector as JobSectors, {
    enabled: !!job?.sector,
  });

  return (
    <Dialog
      fullWidth
      sx={{ m: { xs: -3, md: 0 } }}
      open={props.isOpen}
      maxWidth="md"
      onClose={() => props.onClose()}
    >
      <DialogTitle component="div" width="95%">
        <Typography gutterBottom variant="h5">
          Update Job Sector
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => props.onClose()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: { xs: 0 }, pt: 0 }}>
        <Box width="100%" pt={0}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems={"center"}
            width={"100%"}
            flexDirection={"column"}
            gap={2}
            px={2}
          >
            <Box width="100%">
              <Autocomplete
                options={(
                  fetchedRelatedJobSectors ?? Object.keys(JobSectors)
                ).map(sector => {
                  if (sector === JobSectors.HVAC) {
                    return sector.toString();
                  }
                  return snakeCaseJobSectors[sector.toString() as JobSectors];
                })}
                value={formik.values.sector}
                getOptionLabel={option => option}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    error={
                      formik.touched.sector && Boolean(formik.errors.sector)
                    }
                    helperText={formik.touched.sector && formik.errors.sector}
                    value={formik.values.sector ?? []}
                    margin="normal"
                    InputLabelProps={{
                      style: { marginBottom: "8px" },
                    }}
                    label="Select Service Categories"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      {<Typography>{option}</Typography>}
                      {selected ? (
                        <CheckCircleOutlineIcon color="primary" />
                      ) : null}
                    </Box>
                  </MenuItem>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                      size="small"
                      label={option}
                      {...getTagProps({ index })}
                      variant="outlined"
                    />
                  ))
                }
                onChange={(_: SyntheticEvent, newValue: string | null) =>
                  formik.setFieldValue("sector", newValue as JobSectors)
                }
                onBlur={formik.handleBlur}
              />
            </Box>
            <Box
              display="flex"
              width={"100%"}
              justifyContent="flex-start"
              alignItems="start"
            >
              <Button
                sx={{
                  mr: 1,
                }}
                size={isMobile ? "small" : "medium"}
                color="primary"
                variant="contained"
                disabled={
                  (formik.touched.sector && Boolean(formik.errors.sector)) ||
                  !formik.values.sector
                }
                onClick={() => {
                  updateJobSector({
                    jobId: job?.id,
                    sector: reverseSnakeCaseJobSectors[formik.values.sector],
                  });
                }}
              >
                Update Job
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
