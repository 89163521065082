import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  debounce,
  Dialog,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColumnVisibilityModel,
  GridComparatorFn,
  GridFilterModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useQueryCompanySearch } from "@/company/api/useQueryCompanySearch";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { AdminChatProvider } from "@/company/context/admin-chat-context";
import { JobSectors } from "@/company/jobs/constants";
import {
  CarrierType,
  COMPANY_ACCOUNT_TYPES,
  ConnectionStatus,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import {
  getGridDateOperatorsDataGrid,
  getGridNumericOperatorsDataGrid,
} from "@/utils";

import { useSendJobInvitations } from "../api/useSendJobInvitations";
import { EditNotesArea } from "../common/EditNotesArea";
import { SEARCH_MINIMUM_CHAR_COUNT } from "../constants";
import { InternalAdminCompanyDrawer } from "../InternalAdminCompanyDrawer";
import { useInternalCompanySearchFilterContext } from "../useInternalCompanySearchFilter";
import {
  CustomToolbar,
  drawerWidth,
  formatDate,
  getGridStringOperatorsDataGrid,
  renderJobSectorsArea,
} from "../utils";

const sectorComparator: GridComparatorFn<Array<string>> = (v1, v2) => {
  const minLength = Math.min(v1.length, v2.length);

  for (let i = 0; i < minLength; i++) {
    if (v1[i] < v2[i]) return -1;
    if (v1[i] > v2[i]) return 1;
  }

  // If all elements are equal so far, the shorter array comes first
  return v1.length - v2.length;
};

const formatYear = value => {
  if (!value) return ""; // If value is undefined or null
  const dt = DateTime.fromISO(value); // Convert to DateTime
  return dt.year; // Return only the year
};
export default function CompanySelectionDialog({
  jobId,
  isOpen,
  onClose,
  onConfirm,
}: {
  jobId: number;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const [companyProfileId, setCompanyProfileId] = useState<number | null>();

  const [keyword, setKeyword] = useState("");
  const { session } = useAuth();
  const user = session?.user;

  const [rows, setRows] = React.useState([]);

  const { removeFilter, filters, addFilter } =
    useInternalCompanySearchFilterContext();

  const debouncedSetter = useMemo(
    () =>
      debounce((keyword: string) => {
        addFilter({ keyword });
      }, 500),
    []
  );

  const renderEditNotesArea = params => (
    <EditNotesArea {...params} user={user} />
  );
  const [expanded, setExpanded] = useState(false);

  const [serverSorting, setServerSorting] = React.useState<
    undefined | GridSortModel
  >();

  const [serverFiltering, setServerFiltering] = React.useState<
    undefined | GridFilterModel
  >();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (params.field === "name") setCompanyProfileId(params.row.id);
  };

  const [selectedRows, setSelectedRows] = React.useState([]);

  const { mutateAsync: sendInvitations, isLoading: sendingInvitations } =
    useSendJobInvitations({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while sending invitations.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        discardChanges();
        enqueueSnackbar({
          message: "Invitations sent successfully.",
          variant: "success",
        });
      },
    });

  const handleSelectionChange = newSelection => {
    setSelectedRows(newSelection);
  };

  const discardChanges = () => {
    setSelectedRows([]);
    apiRef.current.setRowSelectionModel([]);
  };

  const saveChanges = async () => {
    sendInvitations({ jobId, companyIds: selectedRows });
  };

  const {
    data: companies,
    isFetching,
    isLoading,
    refetch,
  } = useQueryCompanySearch(
    paginationModel.page,
    paginationModel.pageSize,
    filters,
    serverSorting,
    serverFiltering,
    { refetchOnWindowFocus: false } // Two minutes
  );

  useEffect(() => {
    const seenIds = new Set();
    const fetchedRows = companies?.companies
      ?.filter(item => {
        if (seenIds.has(item.id)) {
          return false; // If ID is already in the set, exclude this item
        } else {
          seenIds.add(item.id); // Otherwise, add ID to set and include the item
          return true;
        }
      })
      .map(company => ({
        ...company,
        sectors: company?.sectors?.map(
          sector => snakeCaseJobSectors[sector.sector]
        ),
      }));
    setRows(fetchedRows);
  }, [companies]);

  let columns: GridColDef[] = [
    {
      field: GRID_CHECKBOX_SELECTION_COL_DEF.field,
      headerName: "Checkbox Selection",
      width: 50,
      hideable: false,
      filterable: false,
      sortable: false,
    }, // Checkbox selection column

    {
      field: "logo",
      headerName: "Logo",
      sortable: false,
      filterable: false,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <CompanyAvatar
              logo={params.row.logoUrl || undefined}
              name={params.row.name}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
          </Box>
        );
      },
    },
    {
      field: "id",
      headerName: "Id",
      renderCell: params => params.row?.id.toString(),

      width: 200,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
    },
    { field: "name", headerName: "Company Name", width: 200 },
    {
      field: "sectors",
      headerName: "Service Categories",
      type: "singleSelect",
      valueOptions: Object.values(JobSectors),
      width: 200,
      sortComparator: sectorComparator,
      renderEditCell: renderJobSectorsArea,
      valueFormatter: value => `${value}`,
      renderCell: params => {
        const sectorChips = params.row?.sectors?.map(sector => (
          <Chip
            key={sector}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={sector}
            size="small"
          />
        ));
        return sectorChips;
      },
    },
    {
      field: "accountType",
      headerName: "Company Type",
      editable: false,
      width: 200,
      type: "singleSelect",
      renderCell: params => {
        return params.row.accountType?.replaceAll("_", " ");
      },
      valueOptions: [COMPANY_ACCOUNT_TYPES.BRAND_LOCATION],
    },
    {
      width: 200,
      field: "platformPresence",
      headerName: "Platform Presence",
      type: "singleSelect",
      valueGetter: (value, row) => row.platformPresence.replaceAll("_", " "),
      valueSetter: (value, row) => {
        return {
          ...row,

          platformPresence: value?.replaceAll(" ", "_"),
        };
      },
      valueOptions: [
        PLATFORM_PRESENCE.ON_PLATFORM,
        PLATFORM_PRESENCE.OFF_PLATFORM,
      ],
    },
    {
      field: "yearEstablished",
      headerName: "Year Established",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueFormatter: params => formatYear(params),
      headerAlign: "left",
      width: 200,
    },
    {
      field: "numberEmployees",
      headerName: "No. of Employees",
      width: 200,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      headerAlign: "left",
    },
    {
      field: "websiteUrl",
      headerName: "Website Url",
      width: 200,
    },
    {
      field: "invitationCode",
      headerName: "Invitation Code",
      width: 200,
    },
    {
      field: "balance",
      headerName: "Balance",
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      width: 200,
      headerAlign: "left",
    },
    { field: "street", headerName: "Street", width: 200 },
    { field: "city", headerName: "City", width: 200 },
    { field: "state", headerName: "State", width: 200 },
    { field: "zipCode", headerName: "Zip Code", width: 200 },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "brandId",
      headerName: "Brand Id",
      renderCell: params => params.row.brand?.id.toString(),

      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueGetter: (value, row) => row.brand?.id,
    },
    {
      field: "brand",
      headerName: "Brand",
      type: "string",
      valueGetter: (value, row) => row.brand?.name,
      valueSetter: (value, row) => {
        return {
          ...row,
          brand: value,
        };
      },
      renderCell: params => {
        return params.row?.brand?.name;
      },
      width: 200,
    },
    {
      field: "familyOfBrandsId",
      headerName: "Family of Brands Id",
      editable: false,
      type: "number",
      renderCell: params => params.row.familyOfBrands?.id.toString(),

      filterOperators: getGridNumericOperatorsDataGrid,
      valueGetter: (value, row) => row.familyOfBrands?.id,
    },
    {
      field: "familyOfBrands",
      valueGetter: (value, row) => row.familyOfBrands?.name,
      valueSetter: (value, row) => {
        return {
          ...row,
          familyOfBrands: {
            ...row.profile,
            name: value,
          },
        };
      },
      headerName: "Family of Brands",
      width: 200,
    },
    {
      field: "privateEquityId",
      headerName: "Private Equity Id",
      renderCell: params => params.row.privateEquity?.id.toString(),

      editable: false,
      type: "number",
      filterOperators: getGridNumericOperatorsDataGrid,
      valueGetter: (value, row) => row.privateEquity?.id,
    },
    {
      field: "privateEquity",
      headerName: "Private Equity",
      valueGetter: (value, row) => row.privateEquity?.name,
      valueSetter: (value, row) => {
        return {
          ...row,
          privateEquity: {
            ...row.profile,
            name: value,
          },
        };
      },
      width: 200,
    },
    {
      field: "deletedAt",
      headerName: "Deleted At",
      type: "date",
      editable: false,
      valueFormatter: formatDate,
      width: 200,
      filterOperators: getGridDateOperatorsDataGrid,
    },
    {
      field: "ownersInformationName",
      headerName: "Owner's Name",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[0],
      renderCell: params => params.row?.ownersInformation?.[0]?.[0],
    },
    {
      field: "ownersInformationEmail",
      headerName: "Owner's Email",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[3],
      renderCell: params => params.row?.ownersInformation?.[0]?.[3],
    },
    {
      field: "ownersInformationPhone",
      headerName: "Owner's Phone",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[1],
      renderCell: params => params.row?.ownersInformation?.[0]?.[1],
    },
    {
      field: "ownersInformationPhoneCarrierType",
      headerName: "Owner's Phone Carrier Type",
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(CarrierType)],
      headerAlign: "left",
      valueGetter: (value, row) => row?.ownersInformation?.[0]?.[2],
      renderCell: params => params.row?.ownersInformation?.[0]?.[2],
    },
    {
      field: "notes",
      headerName: "Company Notes",
      width: 200,
      type: "string",
      headerAlign: "left",
      renderEditCell: renderEditNotesArea,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      valueFormatter: formatDate,
      width: 200,
      filterOperators: getGridDateOperatorsDataGrid,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
  ];

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  const apiRef = useGridApiRef();

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const handleSortModelChange = (model: GridSortModel) => {
    setServerSorting(model);
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    setServerFiltering(model);
  };

  useEffect(() => {
    refetch();
  }, [serverSorting, refetch, serverFiltering]);

  useEffect(() => {
    if (keyword.length >= SEARCH_MINIMUM_CHAR_COUNT || keyword === "") {
      debouncedSetter(keyword);
    }
  }, [debouncedSetter, keyword]);

  return (
    <Dialog fullWidth open={isOpen} maxWidth="lg" onClose={onClose}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          height: "100%",
          width: expanded ? "0%" : `100%`,
          display: expanded ? "none" : "visible",
        }}
      >
        <Box
          position={"sticky"}
          sx={{ top: 0, bgcolor: "white", zIndex: 1000 }}
          display={"flex"}
          gap={1}
          px={2}
          flexDirection={"column"}
        >
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems={"start"}
            width={"100%"}
            pt={2}
          >
            <Typography variant="h5">Local Companies and Franchises</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              gap={3}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography variant="subtitle1" fontSize={"16px"}>
                {companies?.total
                  ? `Showing ${Intl.NumberFormat("en-US").format(
                      companies?.total ?? 0
                    )} companies`
                  : "Fetching results"}
              </Typography>
            </Box>
          </Box>
          <TextField
            sx={{
              width: "50%",
              position: {
                xs: "relative",
              },
              zIndex: 100,
            }}
            variant="outlined"
            size={"small"}
            name="filterKeyword"
            placeholder="Search by company name or keyword"
            value={keyword}
            //   onKeyUp={handleKeyUp}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {keyword ? (
                    // eslint-disable-next-line react/jsx-sort-props
                    <CloseIcon
                      fontSize="small"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setKeyword("");
                        removeFilter("keyword");
                      }}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
            onChange={event => {
              setKeyword(event.target.value);
            }}
          />
          <Box display={"flex"} gap={2} mb={1} position={"relative"}>
            <LoadingButton
              size="small"
              disabled={!selectedRows?.length || sendingInvitations}
              loading={sendingInvitations}
              variant="contained"
              loadingPosition="start"
              onClick={saveChanges}
            >
              Send
            </LoadingButton>
            <LoadingButton
              size="small"
              disabled={!selectedRows?.length || sendingInvitations}
              variant={
                !selectedRows?.length || sendingInvitations
                  ? "contained"
                  : "outlined"
              }
              onClick={discardChanges}
            >
              Discard Changes
            </LoadingButton>
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            px: 3,
          }}
        >
          <Box
            sx={{
              height: "calc(100vh - 260px)",
              overflow: "auto",
              position: "relative",
            }}
          >
            <DataGridPremium
              checkboxSelection
              disableRowSelectionOnClick
              cellSelection
              keepNonExistentRowsSelected
              pagination
              disableAggregation
              disableRowGrouping
              filterMode={"server"}
              filterModel={serverFiltering}
              sortingMode={"server"}
              columns={columns}
              loading={isFetching || isLoading}
              rows={rows ?? []}
              slots={{
                toolbar: () => <CustomToolbar includeQuickFilter={false} />,
              }}
              sx={{
                "& .MuiDataGrid-row": {
                  "--rowBorderColor": "#D9D9D9",
                },
                "& .MuiDataGrid-cell--withRightBorder": {
                  borderRightColor: "#D9D9D9",
                },
                "& .MuiDataGrid-cell--withLeftBorder": {
                  borderLeftColor: "#D9D9D9",
                },
                "& .MuiDataGrid-topContainer": {
                  background: "#F8F9FA",
                },
              }}
              sortModel={serverSorting}
              apiRef={apiRef}
              columnVisibilityMenuProps={{
                disableColumnReorder: true, // Disable reordering in the Hide Columns menu
              }}
              columnVisibilityModel={columnVisibilityModel}
              initialState={{
                pinnedColumns: {
                  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "logo", "name"],
                  right: ["actions"],
                },
                density: "compact",
              }}
              paginationModel={paginationModel}
              paginationMode="server"
              rowCount={companies?.total}
              pageSizeOptions={[5, 10, 25, 50, 100, 250, 500, 5000]}
              disableMultipleColumnsSorting={false}
              onCellClick={handleCellClick}
              onRowSelectionModelChange={newSelection =>
                handleSelectionChange(newSelection)
              }
              onColumnVisibilityModelChange={newModel =>
                setColumnVisibilityModel(newModel)
              }
              onFilterModelChange={handleFilterModelChange}
              onSortModelChange={handleSortModelChange}
              onPaginationModelChange={setPaginationModel}
            />
          </Box>
        </Box>
      </Box>
      {expanded && companyProfileId ? (
        <AdminChatProvider companyId={companyProfileId}>
          <Dialog
            fullScreen
            open={companyProfileId}
            maxWidth="lg"
            onClose={() => {
              setExpanded(false);
            }}
          >
            <InternalAdminCompanyDrawer
              open={!!companyProfileId}
              companyProfileId={companyProfileId}
              connectionStatus={ConnectionStatus.CONNECTED}
              expanded={expanded}
              refetchCompanies={() => refetch()}
              onClose={() => {
                setCompanyProfileId(null);
                setExpanded(false);
              }}
              onExpand={() => setExpanded(prev => !prev)}
            />
          </Dialog>
        </AdminChatProvider>
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!companyProfileId}
        >
          {companyProfileId ? (
            <AdminChatProvider companyId={companyProfileId}>
              <InternalAdminCompanyDrawer
                open={!!companyProfileId}
                expanded={expanded}
                companyProfileId={companyProfileId}
                connectionStatus={ConnectionStatus.CONNECTED}
                refetchCompanies={() => refetch()}
                onClose={() => {
                  setCompanyProfileId(null);
                  setExpanded(false);
                }}
                onExpand={() => setExpanded(prev => !prev)}
              />
            </AdminChatProvider>
          ) : null}
        </Drawer>
      )}
    </Dialog>
  );
}
