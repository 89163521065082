import "../global.css";

import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import { LeadProvider } from "@/lead/context/lead-context";

import App from "./App";
import { AuthProvider } from "./auth/context/jwt";
import { BrandOverlapSearchFilterProvider } from "./brand-overlap/contexts/SearchFilterContext";
import { setupAppAnalytics } from "./common/analytics";
import { BackDropProvider } from "./common/backdrop-context";
import { AppConstants } from "./common/constants";
import { JobCampaignTourProvider } from "./common/job-campaign-context";
import { JobUploadProvider } from "./common/job-upload-context";
import { setupSentry } from "./common/sentry";
import { ShowToolTipProvider } from "./common/show-tool-tip";
import { CustomBannerSnackBar } from "./common/snackbar/banner";
import { ErrorSnackBar } from "./common/snackbar/error";
import { InfoSnackBar } from "./common/snackbar/info";
import { SuccessSnackBar } from "./common/snackbar/success";
import { WarnSnackBar } from "./common/snackbar/warn";
import { GeographyLocationFilterProvider } from "./internal-admin/useGeographyLocationFilter";
import { InternalCompanySearchFilterProvider } from "./internal-admin/useInternalCompanySearchFilter";
import { ContactSupportModalProvider } from "./open/components/modals/ContactSupportContext";
import { ConnectionLimitReachedModalProvider } from "./open/components/modals/LimitReachedContext";
import { SettingsConsumer, SettingsProvider } from "./settings";
import { createTheme } from "./Theme";

LicenseInfo.setLicenseKey(
  "f1fbf10d9d40de3f88139fff2e20b697Tz04OTczNCxFPTE3NDY0NjM2OTcwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
);

const container = document.getElementById("root");
const root = createRoot(container as HTMLDivElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0, // Do not keep cache
      staleTime: 0, // Data is always considered stale
      refetchOnMount: true, // Refetch data when the component mounts
    },
  },
});

setupSentry();
setupAppAnalytics();

const stripePromise = loadStripe(AppConstants.stripePublishableKey);
const LDProvider = withLDProvider({
  clientSideID: AppConstants.launchDarklyClientSideID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App);

root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ContactSupportModalProvider>
          <ConnectionLimitReachedModalProvider>
            <LeadProvider>
              <BackDropProvider>
                <JobCampaignTourProvider>
                  <JobUploadProvider>
                    <ShowToolTipProvider>
                      <GeographyLocationFilterProvider>
                        <InternalCompanySearchFilterProvider>
                          <BrandOverlapSearchFilterProvider>
                            <SettingsProvider>
                              <SettingsConsumer>
                                {settings => {
                                  // Prevent theme flicker when restoring custom settings from browser storage
                                  if (!settings.isInitialized) {
                                    // return null;
                                  }

                                  const theme = createTheme({
                                    colorPreset: settings.colorPreset,
                                    contrast: settings.contrast,
                                    direction: settings.direction,
                                    paletteMode: settings.paletteMode,
                                    responsiveFontSizes:
                                      settings.responsiveFontSizes,
                                  });
                                  return (
                                    <ThemeProvider theme={theme}>
                                      <SnackbarProvider
                                        classes={{ containerRoot: "z-alert" }}
                                        autoHideDuration={4000}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        Components={{
                                          error: ErrorSnackBar,
                                          info: InfoSnackBar,
                                          warning: WarnSnackBar,
                                          success: SuccessSnackBar,
                                          banner: CustomBannerSnackBar,
                                        }}
                                      >
                                        <Elements stripe={stripePromise}>
                                          <LDProvider />
                                        </Elements>
                                      </SnackbarProvider>
                                    </ThemeProvider>
                                  );
                                }}
                              </SettingsConsumer>
                            </SettingsProvider>
                          </BrandOverlapSearchFilterProvider>
                        </InternalCompanySearchFilterProvider>
                      </GeographyLocationFilterProvider>
                    </ShowToolTipProvider>
                  </JobUploadProvider>
                </JobCampaignTourProvider>
              </BackDropProvider>
            </LeadProvider>
          </ConnectionLimitReachedModalProvider>
        </ContactSupportModalProvider>
      </AuthProvider>
    </QueryClientProvider>
  </HelmetProvider>
);
