import { Box, Card, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC, ReactNode } from "react";

interface JobsCardProps {
  jobId: number;
  title: string;
  subTitle?: ReactNode | string;
  statusArea?: ReactNode;
  onClick?: (jobId: number) => void;
  border?: string;
}

export const JobCardContainer: FC<JobsCardProps> = ({
  title,
  subTitle,
  statusArea,
  onClick,
  jobId,
  border,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <Card
      sx={{
        display: "flex",
        cursor: "pointer",
        flexDirection: { xs: "column", lg: "row" },
        boxShadow: "0px 0px 0px 1px #F2F4F7",
        background: "#FFF",
        borderRadius: 1,
        ...(border ? { border } : {}),
        "&:active": {
          border: "1px solid #071D2F",
        },
        "&:hover": {
          border: "1px solid #071D2F",
        },
        "&:focus": {
          border: "1px solid #071D2F",
        },
      }}
      onClick={() => {
        onClick?.(jobId);
      }}
    >
      <Box
        display={"flex"}
        sx={{
          p: 2,
          flex: "1 0 0",
        }}
        alignItems={"center"}
        gap={2}
      >
        {isDesktop ? (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img
            src="/video-thumbnail.svg"
            style={{ width: "40px" }}
            alt="video thumbnail"
          />
        ) : null}
        <Box
          display={"flex"}
          sx={{
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {subTitle}
          </Typography>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{ p: 2, alignItems: { lg: "end", xs: "start" } }}
        justifyContent={"center"}
      >
        {statusArea}
      </Box>
    </Card>
  );
};
