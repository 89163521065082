/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";

import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { isIOS } from "@/utils";

export interface JobDetailsUploadProps {
  setVideoFile: (file: File | null) => void;
  setTranscription: (text: string) => void;
  transcription: string | undefined;
  defaultSummary?: string;
  reset: () => boolean;
  videoFile: File | null;
  setMediaType: (type: JobUploadTypes) => void;
  mediaType: JobUploadTypes;
  onClickGenerateJob: () => Promise<void>;
}

export function JobDetailsUpload(props: JobDetailsUploadProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [startBackdrop, setStartBackdrop] = useState(false);
  const [countdown, setCountdown] = useState(2);

  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const fileAudioInputRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleButtonClick = event => {
    event.stopPropagation();
    setCountdown(2);
    setStartBackdrop(true);
  };

  useEffect(() => {
    if (startBackdrop && countdown > 0) {
      const timer = setInterval(() => {
        setTimeout(() => {
          setCountdown(prev => prev - 1);
        }, 375);
      }, 750);

      return () => clearInterval(timer);
    }
    if (countdown === 0) {
      if (startBackdrop) {
        setStartBackdrop(false);
        if (!isIOS()) {
          fileInputRef.current?.click();
        }
      }
    }
  }, [countdown, startBackdrop]);

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.includes("video/")) {
        props.setVideoFile(file);
        props.setMediaType(JobUploadTypes.VIDEO);
      } else {
        enqueueSnackbar({
          message: "Invalid file type. Please upload a video file.",
          variant: "error",
          subText: (
            <>
              <a
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "#7A271A",
                  cursor: "pointer",
                }}
                onClick={event => {
                  event.preventDefault();
                  setContactSupportModalOpen(true);
                }}
              >
                Contact support
              </a>
              .
            </>
          ),
        });
        event.target.value = null;
      }
    }
  };

  const handleAudioButtonClick = () => {
    fileAudioInputRef.current?.click();
  };

  const handleAudioFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.includes("audio/")) {
        props.setVideoFile(file);
        props.setMediaType(JobUploadTypes.AUDIO);
      } else {
        enqueueSnackbar({
          message: "Invalid file type. Please upload an audio file.",
          variant: "error",
          subText: (
            <>
              <a
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "#7A271A",
                  cursor: "pointer",
                }}
                onClick={event => {
                  event.preventDefault();
                  setContactSupportModalOpen(true);
                }}
              >
                Contact support
              </a>
              .
            </>
          ),
        });
        event.target.value = null;
      }
    }
  };

  const [textType, setTextType] = useState(false);

  useEffect(() => {
    if (props.defaultSummary) {
      setTextType(true);
      props.setTranscription(props.defaultSummary);
      props.setMediaType(JobUploadTypes.TEXT);
    }
  }, [props.defaultSummary]);

  return (
    <Box width="100%" pt={3}>
      <Backdrop
        sx={theme => ({ color: "#fff", zIndex: 2000 })}
        open={startBackdrop}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
            zIndex: 2000,
          }}
        >
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            accept="video/*"
            capture={"environment"}
            onChange={handleFileChange}
          />
          <Box
            sx={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              backgroundColor: "#071D2F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1,
              zIndex: 2000,
              boxShadow: `
            0 2px 4px rgba(0, 0, 0, 0.05),
            0 4px 8px rgba(0, 0, 0, 0.08),
            0 6px 12px rgba(0, 0, 0, 0.1)
          `,
            }}
          >
            <Typography variant="h1" color="white">
              {countdown}
            </Typography>
            <Typography
              variant="h6"
              fontSize={"15px"}
              fontWeight={600}
              width={"120px"}
              sx={{ cursor: "pointer", color: "#16DB65", textAlign: "center" }}
              onClick={() => {
                setStartBackdrop(false);
              }}
            >
              Describe what you see
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: "#16B364",
              borderColor: "#16B364",
              p: 2,
              borderRadius: "8px",
              textAlign: "center",
              boxShadow: `
              0 2px 4px rgba(0, 0, 0, 0.05),
              0 4px 8px rgba(0, 0, 0, 0.08),
              0 6px 12px rgba(0, 0, 0, 0.1)
            `,
            }}
          >
            <Typography gutterBottom variant="h6" color="white">
              Ready to upload a job?
            </Typography>
            <Typography variant="h6" color="white">
              Speak clearly for best results!
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      {textType ? (
        <Typography gutterBottom variant="subtitle1">
          Briefly describe the job
        </Typography>
      ) : (
        <Typography gutterBottom variant="subtitle1">
          Record a brief video and remember to{" "}
          <Typography
            variant="subtitle1"
            display={"inline"}
            color="primary"
            fontWeight={600}
          >
            describe the job. Speak clearly for the best results.
          </Typography>
        </Typography>
      )}
      <Box
        display={"flex"}
        gap={isMobile ? 1 : 2}
        alignItems={"baseline"}
        flexWrap={"wrap"}
        flexDirection={isMobile ? "column" : "row"}
      >
        {textType ? (
          <>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Upload a video or voice memo instead?
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setTextType(false)}
            >
              Upload
            </Button>
          </>
        ) : (
          <>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Type your notes instead?
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setTextType(true)}
            >
              Switch to Text
            </Button>
          </>
        )}
      </Box>
      <Box>
        {textType ? (
          <Box display={"flex"} py={2} gap={2} flexDirection={"column"}>
            <Box display={"flex"} width={"100%"} flexDirection={"column"}>
              <Typography
                variant="body2"
                mb={0.5}
                ml={0.3}
                fontSize={"12px"}
                color="text.secondary"
                fontWeight={400}
              >
                Write a brief job description for your referral partner,
                including the service category, type of work, and level of
                urgency.
              </Typography>
              <TextField
                multiline
                minRows={isMobile ? 4 : 2}
                maxRows={4}
                id="transcription"
                name="transcription"
                label="Job Summary"
                value={props.transcription ?? ""}
                type="string"
                InputProps={{
                  inputProps: {
                    maxLength: 500,
                  },
                }}
                onChange={event => {
                  props.setTranscription(event.target.value);
                  props.setMediaType(JobUploadTypes.TEXT);
                }}
              />
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"end"}
                mt={0.5}
              >
                {" "}
                <Typography
                  fontSize={"12px"}
                  color="text.secondary"
                  fontWeight={400}
                  mr={1}
                >
                  {props.transcription?.length ?? 0}/500 Characters
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Button
                sx={{
                  mr: 1,
                }}
                disabled={!props.transcription}
                size={isMobile ? "small" : "medium"}
                color="primary"
                variant="contained"
                onClick={() => {
                  setCountdown(2);
                  setStartBackdrop(false);
                  props.onClickGenerateJob();
                }}
              >
                Next
              </Button>
            </Box>{" "}
          </Box>
        ) : props.videoFile ? (
          <Box>
            {props.mediaType === JobUploadTypes.VIDEO ? (
              <Box
                sx={{
                  alignItems: "center",
                  position: "relative",
                  border: 1,
                  borderRadius: 1,
                  borderStyle: "dashed",
                  borderColor: "6C737F",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  outline: "none",
                  mt: 3,
                  p: { md: 1, xs: 1 },
                  bgcolor: "#F8F9FA",
                }}
              >
                <button
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    border: "none",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    background: "transparent",
                    zIndex: 1, // Ensure the button is above the video
                  }}
                  onClick={props.reset}
                >
                  <CloseIcon />
                </button>
                <video
                  controls
                  controlsList="nodownload"
                  src={`${window.URL.createObjectURL(props.videoFile)}`}
                  width="100%"
                  height="200px"
                  style={{ border: "1px solid black" }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  mt: 3,
                  p: { md: 1, xs: 1 },
                  width: "100%",
                  gap: 1,
                }}
              >
                <audio
                  controls
                  controlsList="nodownload"
                  src={`${window.URL.createObjectURL(props.videoFile)}`}
                  style={{ width: isMobile ? "90%" : "300px" }}
                  height="200px"
                />
                <CloseIcon
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                  onClick={props.reset}
                />
              </Box>
            )}
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              mt={2}
            >
              <Button
                sx={{
                  mr: 1,
                }}
                size={isMobile ? "small" : "medium"}
                color="primary"
                variant="contained"
                onClick={() => {
                  setCountdown(2);
                  setStartBackdrop(false);
                  props.onClickGenerateJob();
                }}
              >
                Next
              </Button>
            </Box>
          </Box>
        ) : (
          <Box display={"flex"} flexWrap={"wrap"} py={2} gap={2} mt={2}>
            <Box
              px={2}
              py={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "16px",
                gap: 2,
                background: "#FFF",
                boxShadow: "0px 0px 0px 1px #F2F4F7",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#F8F9FA",
                },
              }}
              onClick={async event => {
                event.preventDefault();
                event.stopPropagation();
                if (isMobile) {
                  handleButtonClick(event);
                  if (isIOS()) {
                    fileInputRef.current?.click();
                  }
                  return;
                }
                fileInputRef.current?.click();
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "50px",
                  height: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "100px",
                  background: "#e8fbf0",
                }}
              >
                <img
                  src="/video.gif"
                  alt="video"
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </Box>
              <Typography fontSize={"13px"} fontWeight={600}>
                Upload Video
              </Typography>
            </Box>
            <Box
              px={2}
              py={1}
              sx={{
                borderRadius: "16px",
                display: "flex",
                background: "#FFF",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 0px 0px 1px #F2F4F7",
                "&:hover": {
                  backgroundColor: "#F8F9FA",
                },
                cursor: "pointer",
              }}
              onClick={() => handleAudioButtonClick()}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "50px",
                  height: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "100px",
                  background: "#e8fbf0",
                }}
              >
                <img
                  src="/audio.gif"
                  alt="audio"
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </Box>
              <input
                ref={fileAudioInputRef}
                type="file"
                style={{ display: "none" }}
                accept=".wav,.aif,.aiff,.flac,.alac,.aac,.ogg,.mp3"
                onChange={handleAudioFileChange}
              />
              <Typography fontSize={"13px"} fontWeight={600}>
                Upload Voice
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
