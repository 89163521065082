import { Avatar } from "@mui/material";
import { FC, useState } from "react";

import { ToolTip } from "@/common/tool-tip/ToolTip";

interface AffiliateCompanyAvatarProps {
  logo?: string;
  name: string;
}

export const AffiliateCompanyAvatar: FC<AffiliateCompanyAvatarProps> = ({
  logo,
  name,
}) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false);

  if (logo && !isImageLoadError) {
    return (
      <ToolTip key={name} message={name}>
        <img
          style={{
            width: "80px",
            height: "60px",
            marginRight: "16px",
            objectFit: "contain",
            backgroundColor: "white",
          }}
          alt={name}
          src={logo}
          onError={() => setIsImageLoadError(true)}
        />
      </ToolTip>
    );
  }
  const firstInitial = name?.split(" ")?.[0]?.[0] ?? "";
  const secondInitial = name?.split(" ")?.[1]?.[0] ?? "";
  return (
    <ToolTip key={`${firstInitial}${secondInitial}`} message={name}>
      <Avatar
        sx={{
          maxWidth: "80px",
          maxHeight: "60px",
          mr: 1,
          "& img": {
            objectFit: "contain",
          },
        }}
      >{`${firstInitial}${secondInitial}`}</Avatar>
    </ToolTip>
  );
};
