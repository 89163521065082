import CloseIcon from "@mui/icons-material/Close";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { GoogleCompanyProfileDetails } from "@/common/GoogleCompanyDrawer/GoogleCompanyProfileDetails";
import { canSeeExplorePartners } from "@/common/utils/accessControl";
import { USE_GET_ALL_CHATS__GROUPED_URI } from "@/company/api/useFetchCompanyMessagesList";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import { ChatProvider } from "@/company/context/chat-context";
import { useReferralContext } from "@/company/context/referral-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import {
  CompanyPublicProfile,
  ConnectionStatus,
  PLATFORM_PRESENCE,
  ReferralInvitation,
  ReferralInvitationActions,
} from "@/company/types";

import { CompanyAvatar } from "../company-avatar/CompanyAvatar";
import { ServiceProviderShareDialog } from "../service-provider-dialog/ServiceProviderShareDialog";
import { Activity } from "./Activity";
import { Message } from "./Message";
import { Overview } from "./Overview";

interface CompanyDrawerProps {
  onClose?: () => void;
  open?: boolean;
  companyProfile: CompanyPublicProfile;
  invitation?: ReferralInvitation;
  connectionStatus: ConnectionStatus;
  defaultTab?: TabsEnum;
  isCampaignTour?: boolean;
  isViewOnly?: boolean;
}

export enum TabsEnum {
  OVERVIEW = "overview",
  ACTIVITY = "activity",
  MESSAGE = "message",
  GOOGLE_PROFILE = "google-profile",
}

export const CompanyDrawer: FC<CompanyDrawerProps> = props => {
  const {
    onClose,
    open = false,
    defaultTab,
    invitation,
    connectionStatus,
    isCampaignTour,
    isViewOnly,
    ...other
  } = props;

  let { companyProfile } = props;
  const [currentTab, setCurrentTab] = useState<TabsEnum>(
    defaultTab ?? TabsEnum.OVERVIEW
  );

  const [profileShareOpen, setProfileShareOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { session } = useAuth();

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: TabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );
  const [referralInviteSentLoading, setReferralInviteSentLoading] =
    useState(false);

  const { data: companyProfileV2, isLoading } = useFetchCompanyProfile(
    companyProfile.id,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { refetchReferralPartners } = useReferralContext();

  const { createReferralInvitation, updateReferralInvitation } =
    useReferralInvitationContext();
  useEffect(() => {
    if (currentTab === TabsEnum.MESSAGE) {
      queryClient.invalidateQueries({
        queryKey: [USE_GET_ALL_CHATS__GROUPED_URI],
      });
    }
  }, [currentTab, queryClient]);
  const otherCompanyId = companyProfile.id;
  if (!otherCompanyId) return null;
  if (!isLoading) {
    companyProfile = companyProfileV2!;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 500,
          background: "white",
          px: 1.5,
        },
      }}
      onClose={() => {
        closeSnackbar();
        onClose?.();
      }}
      {...other}
    >
      <Box display={"flex"} flexDirection={"column"} height={"100%"}>
        <Box
          position={"sticky"}
          sx={{
            top: 0,
            zIndex: 400,
            pt: 1,
            background: "white",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            px={3}
            pb={2}
            pt={3}
          >
            <CompanyAvatar
              height={"48px"}
              maxWidth="250px"
              logo={companyProfile.logoUrl}
              name={companyProfile.name ?? ""}
            />
            <Box display={"flex"} gap={2}>
              <IconButton
                aria-label="close"
                sx={{
                  color: theme => theme.palette.grey[500],
                }}
                onClick={() => {
                  setProfileShareOpen(true);
                }}
              >
                <ShareOutlinedIcon />
              </IconButton>
              <IconButton
                aria-label="close"
                sx={{
                  color: theme => theme.palette.grey[500],
                }}
                onClick={() => {
                  closeSnackbar();
                  onClose?.();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Tabs sx={{ px: 3 }} value={currentTab} onChange={handleTabsChange}>
            {companyProfile.platformPresence ===
            PLATFORM_PRESENCE.ON_PLATFORM ? (
              <Tab value={TabsEnum.ACTIVITY} label="Activity" />
            ) : null}
            <Tab value={TabsEnum.OVERVIEW} label="Overview" />
            {companyProfile.profile?.googlePlacesId ? (
              <Tab value={TabsEnum.GOOGLE_PROFILE} label="Google Reviews" />
            ) : null}
            {isViewOnly ||
            !session?.groups?.some(canSeeExplorePartners) ? null : (
              <Tab value={TabsEnum.MESSAGE} label="Message" />
            )}
          </Tabs>
          <Divider />
        </Box>
        <Box p={1.5} pb={0} flex={1} overflow={"auto"}>
          {currentTab === TabsEnum.OVERVIEW ? (
            <Overview companyProfile={companyProfile} />
          ) : null}
          {currentTab === TabsEnum.ACTIVITY ? (
            <Activity otherCompanyProfile={companyProfile} />
          ) : null}
          {currentTab === TabsEnum.MESSAGE ? (
            <ChatProvider companyId={otherCompanyId}>
              <Message
                isCampaignTour={isCampaignTour}
                otherCompanyProfile={companyProfile}
                connectionStatus={connectionStatus}
              />
            </ChatProvider>
          ) : null}
          {currentTab === TabsEnum.GOOGLE_PROFILE ? (
            <GoogleCompanyProfileDetails
              googlePlacesId={companyProfile.profile.googlePlacesId}
              companyName={companyProfile?.name}
              isVerified={
                companyProfile.platformPresence ===
                PLATFORM_PRESENCE.ON_PLATFORM
              }
            />
          ) : null}
        </Box>
      </Box>
      {connectionStatus === ConnectionStatus.NOT_CONNECTED &&
      currentTab !== TabsEnum.MESSAGE &&
      !isViewOnly ? (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          p={3}
          position="sticky"
          bottom={"0"}
          bgcolor={"white"}
          zIndex={1000}
        >
          <Button
            size="small"
            variant="contained"
            disabled={referralInviteSentLoading}
            onClick={async () => {
              try {
                setReferralInviteSentLoading(true);

                await createReferralInvitation({
                  invitedCompanyId: companyProfile.id,
                });
                enqueueSnackbar({
                  message: (
                    <Typography>
                      Connection request sent with your default message. Follow
                      up with a personalized message or update your default
                      message from Settings.
                    </Typography>
                  ),
                  variant: "success",
                  subText: (
                    <Typography
                      sx={{
                        color: "#16DB65",
                        fontSize: "14px",
                        fontWeight: 600,
                        pr: 2,
                        cursor: "pointer",
                      }}
                      onClick={event => {
                        event.stopPropagation();
                        setCurrentTab(TabsEnum.MESSAGE);
                      }}
                    >
                      Send a Message Now
                    </Typography>
                  ),
                });
              } catch (error) {
                console.error(error);
              }
              setReferralInviteSentLoading(false);
            }}
          >
            Connect
          </Button>
        </Stack>
      ) : null}
      {connectionStatus === ConnectionStatus.INVITED &&
      invitation &&
      currentTab !== TabsEnum.GOOGLE_PROFILE &&
      currentTab !== TabsEnum.MESSAGE &&
      !isViewOnly ? (
        <>
          <Box position="sticky" bottom={"0"} bgcolor={"white"} zIndex={1000}>
            <Divider />
            <Stack
              bgcolor={"white"}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              p={3}
            >
              <Button
                size="small"
                variant="contained"
                onClick={async () => {
                  await updateReferralInvitation({
                    invitationId: invitation.id,
                    action: ReferralInvitationActions.ACCEPT,
                  });
                  enqueueSnackbar({
                    message: `${invitation.otherCompanyProfile.name} is now a Referral
                    Partner.`,
                    variant: "success",
                  });
                  refetchReferralPartners();
                  setCurrentTab(TabsEnum.MESSAGE);
                }}
              >
                Accept
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={async () => {
                  await updateReferralInvitation({
                    invitationId: invitation.id,
                    action: ReferralInvitationActions.IGNORE,
                  });
                  enqueueSnackbar({
                    message: `You have ignored the invitation from 
                    ${invitation.otherCompanyProfile.name}.`,
                    variant: "success",
                  });
                  onClose?.();
                }}
              >
                Ignore
              </Button>
            </Stack>
          </Box>
        </>
      ) : null}
      <ServiceProviderShareDialog
        isOpen={profileShareOpen}
        companyProfile={companyProfile}
        onClose={() => {
          setProfileShareOpen(false);
        }}
      />
    </Drawer>
  );
};
