/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
import {
  Check as CheckIcon,
  Close as CloseIcon,
  HelpOutline,
} from "@mui/icons-material";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useEffect, useState } from "react";
import * as yup from "yup";

import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { useCreateJob, useGetJobById } from "@/company/jobs/api";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { useDeleteJob } from "@/company/jobs/api/useDeleteJob";
import { useFetchRelatedJobSectors } from "@/company/jobs/api/useFetchRelatedJobSectors";
import { GET_JOB_BY_ID_URL } from "@/company/jobs/api/useGetJobById";
import { useUpdateJobSector } from "@/company/jobs/api/useUpdateJobSector";
import { JobSectors } from "@/company/jobs/constants";
import { Job, JobStatus } from "@/company/jobs/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";
import { USE_GET_MY_JOBS_URI } from "@/homeowner/api/useGetMyJobs";
import { useHomeownerActOnJob } from "@/homeowner/api/useHomeOwnerActOnJob";
import { useHomesContext } from "@/homeowner/contexts/home-context";
import { Home } from "@/homeowner/types";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { neutral, tradeEngageColors } from "@/Theme/colors";

import { Languages } from "../constants/languages";
import { GoogleAutocomplete } from "../GoogleAutocomplete";
import { ToolTip } from "../tool-tip/ToolTip";
import { doesErrorHaveMessage } from "../utils/doesErrorHaveMessage";
import { JobDetailsUpload } from "./JobDetailsUpload";

export interface CreateJobDialogProps {
  isOpen: boolean;
  createdForCompanyId?: number;
  homeId?: number;
  defaultHomeownerDetails?: {
    firstName: string;
    lastName?: string;
    address?: string;
    cellPhone?: string;
    zipCode?: string;
  };
  defaultSummary?: string;
  onClose: () => void;
  jobsListUrl: string;
  refreshJobs?: () => Promise<unknown>;
}

export function CreateHomeownerJobDialog(props: CreateJobDialogProps) {
  const { session, refreshSession } = useAuth();
  const brandLocationProfile = session?.brandLocationProfile;

  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const [zipCode, setZipCode] = useState<string | undefined>("");
  const [latitude, setLatitude] = useState<number | undefined>();
  const [longitude, setLongitude] = useState<number | undefined>();

  const [transcription, setTranscription] = useState<string | undefined>();
  const [homeownerDetailsUpload, setHomeownerDetailsUpload] = useState<
    File | undefined
  >();
  const [jobType, setJobType] = useState("");
  const [jobSummary, setJobSummary] = useState("");

  const [mediaType, setMediaType] = useState<JobUploadTypes>(
    JobUploadTypes.VIDEO
  );

  const { isLoading: isCreatingJob, mutateAsync: createNewJob } =
    useCreateJob();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { enqueueSnackbar } = useSnackbar();

  const [newJob, setNewJob] = useState<Job | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [videoFile, setVideoFile] = useState<any>(undefined);
  const [step, setStep] = useState(0);
  const [polling, setPolling] = useState(false);
  const [submitingJob, setSubmitingJob] = useState(false);

  const [newUploadScreenOpen, setNewUploadScreenOpen] = useState(false);

  const { homes, createHome, linkHomeToJob, refreshHomes } = useHomesContext();

  const queryClient = useQueryClient();

  const validationSchema = yup.object().shape({
    address: yup.string().required("Home Address is required"),
    sector: yup.string().optional(),
  });

  const { isLoading: isUpdating, mutateAsync: actOnJob } = useHomeownerActOnJob(
    newJob?.id ?? null,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [USE_GET_MY_JOBS_URI] });
        queryClient.invalidateQueries({
          queryKey: [
            GET_JOB_BY_ID_URL.replace(":id", job?.id?.toString() ?? ""),
          ],
        });
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Unable to update job.";
        enqueueSnackbar({
          message,
          variant: "error",
        });
      },
    }
  );

  const { isLoading: isDeletingJob, mutateAsync: deleteJob } = useDeleteJob({
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while cancelling the job";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      address: homes?.at(-1)?.streetAddress
        ? homes?.at(-1)?.streetAddress === "street_address"
          ? ""
          : homes?.at(-1)?.streetAddress
        : "",
      allowAutoRouting: true,
      sector: "",
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const { address, allowAutoRouting } = values;
      setSubmitingJob(true);
      try {
        let newHome: Home | null = null;
        if (
          !(homes?.map(home => home.streetAddress) ?? []).includes(
            address as string
          )
        ) {
          // TODO: get data from google api
          newHome = await createHome({
            label: `Home ${(homes?.length ?? 0) + 1}`,
            streetAddress: address as string,
            homeownerId: session?.user?.id ?? 0,
            country: "US",
            ...(zipCode ? { zipCode } : {}),
          });
          refreshHomes();
        }
        const homeId = newHome
          ? newHome.id
          : homes?.filter(home => home.streetAddress === address)[0].id;
        await linkHomeToJob({ jobId: newJob?.id, homeId: homeId ?? 0 });
        try {
          await actOnJob({
            action: "APPROVE",
            allowAutoRouting,
            summary: jobSummary,
            jobType,
          });
        } catch (error) {
          setSubmitingJob(false);
          return;
        }
        setNewUploadScreenOpen(true);
        reset();
      } catch (error) {
        if (error?.code == "unable_to_retrieve_coordinates_from_address") {
          enqueueSnackbar("Address Not Found", {
            subText: (
              <>
                Please enter a valid address. Questions?{" "}
                <a
                  role="button"
                  style={{
                    textDecorationLine: "underline",
                    color: "#7A271A",
                    cursor: "pointer",
                  }}
                  onClick={event => {
                    event.preventDefault();
                    setContactSupportModalOpen(true);
                  }}
                >
                  Contact support
                </a>
                .
              </>
            ),
            variant: "error",
            autoHideDuration: 10000,
          });
        } else {
          const message = doesErrorHaveMessage(error)
            ? error.message
            : "Unable to update job.";
          enqueueSnackbar({
            message,
            variant: "error",
          });
        }
      }
      setSubmitingJob(false);
    },
  });

  const { isLoading: updatingJobSector, mutateAsync: updateJobSector } =
    useUpdateJobSector({
      onSuccess: (job: Job) => {
        setModifyJob(false);
        setNewJob(job);
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while updating job sector details.`,
          variant: "error",
        });
      },
    });

  const {
    isFetching: isFetchingRelatedJobSectors,
    data: fetchedRelatedJobSectors,
  } = useFetchRelatedJobSectors(newJob?.sector as JobSectors, {
    enabled: !!newJob?.sector,
  });

  const {
    isFetching: fetchingJobStatus,
    isRefetching,
    error,
    data: job,
  } = useGetJobById(newJob?.id, {
    enabled: !!newJob?.id && step === 1,
    refetchInterval: () => {
      if (
        newJob?.status === JobStatus.PENDING_HOMEOWNER_ACTION ||
        newJob?.status == JobStatus.VIDEO_PROCESSING_FAILED
      ) {
        if (newJob?.status === JobStatus.PENDING_HOMEOWNER_ACTION && step === 1)
          setStep(2);
        setPolling(false);
        return false;
      }
      return 2000;
    },
  });

  useEffect(() => {
    if (job) {
      if (job?.status === JobStatus.VIDEO_PROCESSING_FAILED) {
        enqueueSnackbar({
          message: `There was an error while processing job video. Please try again.`,
          variant: "error",
        });
        reset();
      }
      setNewJob(job);
      if (job?.summary) {
        setJobSummary(job.summary);
      }
      if (job?.jobType) {
        setJobType(job.jobType);
      }
    }
  }, [job]);
  const handleVideoButtonClick = () => {
    const videoUrl =
      "https://tradeengage-app.s3.amazonaws.com/videos/Outdoor+electrical+box+shorting.mp4";
    window.open(videoUrl, "_blank");
  };

  const [modifyJob, setModifyJob] = useState(false);

  const reset = () => {
    setVideoFile(undefined);
    setNewJob(undefined);
    setStep(0);
    setZipCode(undefined);
    setLatitude(undefined);
    setLongitude(undefined);
    setMediaType(JobUploadTypes.VIDEO);
    setTranscription("");
    setPolling(false);
    setHomeownerDetailsUpload(undefined);
    setModifyJob(false);
    formik.resetForm();
    return true;
  };

  const revertStep1 = () => {
    setHomeownerDetailsUpload(undefined);
    setPolling(false);
    formik.resetForm();
    setZipCode(undefined);
    setLatitude(undefined);
    setLongitude(undefined);
    setHomeownerDetailsUpload(undefined);
    setStep(0);
  };
  const revertStep3 = () => {
    setStep(1);
  };

  const onClickGenerateJob = async () => {
    setPolling(true);
    createNewJob(
      {
        videoFile,
        videoLanguage: Languages.ENGLISH,
        homeId: props.homeId,
        createdForCompanyId: props.createdForCompanyId,
        type: mediaType,
        ...(mediaType === JobUploadTypes.TEXT && transcription
          ? { summary: transcription }
          : {}),
      },
      {
        onSuccess: (data: Job) => {
          setNewJob(data);
          setStep(1);
        },
        onError: () => {
          setStep(0);
          setPolling(false);
          enqueueSnackbar({
            message: `There was an error while creating ${
              session?.user?.type === USER_TYPE.HOMEOWNER
                ? "your project"
                : "the job"
            }.`,
            variant: "error",
          });
        },
      }
    );
  };

  let disableHomeOwnerReviewRequiredNextButton = false;

  if (!formik.values.address) {
    disableHomeOwnerReviewRequiredNextButton = true;
  }

  return (
    <Dialog
      fullWidth
      {...(isMobile ? { fullScreen: true, m: 0 } : { m: 3 })}
      open={props.isOpen}
      maxWidth="md"
      onClose={() => reset() && props.onClose()}
    >
      <DialogTitle component="div" width="95%">
        {newUploadScreenOpen ? null : (
          <>
            {" "}
            <Typography gutterBottom variant="h5">
              {session?.user?.type === USER_TYPE.HOMEOWNER
                ? "Add Home Project"
                : "Submit New Job"}
            </Typography>
            {session?.user?.type === USER_TYPE.HOMEOWNER ? (
              <Typography gutterBottom variant="body2" color="textSecondary">
                Create a new project and it will be routed to a trusted service
                provider.
              </Typography>
            ) : (
              <Typography gutterBottom variant="body2" color="textSecondary">
                Upload a job to refer to your network. It&apos;s easy!
              </Typography>
            )}
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <Typography variant="subtitle2">Need an example?</Typography>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  px: "10px",
                  py: "4px",
                  width: "fit-content",
                  borderRadius: "8px",
                }}
                endIcon={<PlayCircleFilledWhiteOutlinedIcon />}
                onClick={handleVideoButtonClick}
              >
                Watch Video
              </Button>
            </Box>
          </>
        )}
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => reset() && props.onClose()}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent
        sx={{ px: { xs: 0 }, pt: 0, overflow: "auto", position: "relative" }}
      >
        {newUploadScreenOpen ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            px={3}
            py={4}
            gap={1}
          >
            <img
              style={{
                height: "250px",
                width: "250px",
              }}
              src="/success.gif"
              alt="success"
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
              alignItems={"start"}
            >
              <Typography variant="h4" fontSize={"22px"}>
                Job Submitted Successfully!
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              <Typography variant="body2" color="text.secondary">
                Great! Your job will be routed and we&apos;ll let you know when
                your earnings are on their way. 💸
              </Typography>
            </Box>

            <Divider sx={{ width: "100%", my: 1.5 }} />
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              <Typography variant="body2" color="text.secondary">
                Do you have more jobs to refer?
              </Typography>
            </Box>
            <Box display={"flex"} width={"100%"}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<BackupOutlinedIcon />}
                onClick={() => setNewUploadScreenOpen(false)}
              >
                Upload New Job
              </Button>
            </Box>
          </Box>
        ) : (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              mt: 0,
            }}
          >
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  sx={{
                    mb: 1,
                    color: neutral[50],
                    borderRadius: 1,
                    bgcolor: tradeEngageColors.green,
                  }}
                >
                  {step > 0 ? <CheckIcon color="inherit" /> : "1"}
                </Avatar>
                <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
              </TimelineSeparator>
              {isCreatingJob ? (
                <TimelineContent sx={{ pb: 5, pt: 0.5 }}>
                  <Typography
                    gutterBottom
                    variant="overline"
                    {...(step > 0 ? { color: "#6C737F" } : {})}
                  >
                    Upload Job Video
                  </Typography>
                  <Box
                    width="100%"
                    pt={3}
                    display="flex"
                    flexDirection={"column"}
                  >
                    {videoFile ? (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      mediaType === JobUploadTypes.VIDEO ? (
                        <video
                          controls
                          controlsList="nodownload"
                          src={`${window.URL.createObjectURL(videoFile)}`}
                          width="72px"
                          height="72px"
                          style={{
                            marginBottom: "16px",
                            border: "1px solid black",
                          }}
                        />
                      ) : (
                        <audio
                          controls
                          controlsList="nodownload"
                          src={`${window.URL.createObjectURL(videoFile)}`}
                          width="72px"
                          height="72px"
                          style={{
                            marginBottom: "16px",
                          }}
                        />
                      )
                    ) : null}
                    <Typography gutterBottom variant="body2">
                      We&apos;re uploading your{" "}
                      {videoFile ? mediaType.toLowerCase() : "transcription"},
                      please do not close this window.
                    </Typography>
                    <LinearProgress sx={{ width: "100%" }} />
                  </Box>
                </TimelineContent>
              ) : (
                <TimelineContent sx={{ pb: 5, pt: 0.5 }}>
                  <Typography
                    gutterBottom
                    variant="overline"
                    {...(step > 0 ? { color: "#6C737F" } : {})}
                  >
                    Project Details
                  </Typography>
                  {step > 0 ? null : (
                    <JobDetailsUpload
                      reset={reset}
                      mediaType={mediaType}
                      setMediaType={setMediaType}
                      videoFile={videoFile}
                      transcription={transcription}
                      setTranscription={(text: string) =>
                        setTranscription(text)
                      }
                      defaultSummary={props.defaultSummary}
                      setVideoFile={file => setVideoFile(file)}
                      onClickGenerateJob={onClickGenerateJob}
                    />
                  )}
                </TimelineContent>
              )}
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  color="primary"
                  sx={{
                    my: 1,
                    borderRadius: 1,
                    color: step >= 1 ? neutral[50] : "textPrimary",
                    bgcolor: step >= 1 ? tradeEngageColors.green : neutral[200],
                  }}
                >
                  {step > 1 ? <CheckIcon color="inherit" /> : "2"}
                </Avatar>
                {step >= 1 ? (
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                ) : null}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                <Typography
                  variant="overline"
                  {...(step > 1 ? { color: "#6C737F" } : {})}
                >
                  Generate Project
                </Typography>
                {step === 1 ? (
                  polling ? (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={0}
                      mt={3}
                    >
                      <Typography variant="subtitle1">
                        Fetching Job Summary
                      </Typography>
                      <Typography variant="caption" fontSize={"0.625rem"}>
                        Please do not close this window.
                      </Typography>
                      <LinearProgress sx={{ width: "100%", mt: 1 }} />
                    </Box>
                  ) : null
                ) : null}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  color="primary"
                  sx={{
                    my: 1,
                    borderRadius: 1,
                    color: step >= 2 ? neutral[50] : "textPrimary",
                    bgcolor: step >= 2 ? tradeEngageColors.green : neutral[200],
                  }}
                >
                  {step > 2 ? <CheckIcon color="inherit" /> : "3"}
                </Avatar>
                {step >= 2 ? (
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                ) : null}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                <Typography variant="overline">Modify Job</Typography>
                {step === 2 ? (
                  <Box width="100%" pt={3}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems={"baseline"}
                      width={"100%"}
                      flexDirection={"column"}
                      gap={1.5}
                    >
                      <Box
                        display="flex"
                        width={"100%"}
                        flexDirection={"column"}
                        gap={1.5}
                      >
                        <Typography gutterBottom variant="subtitle1">
                          Here is the Job Summary
                        </Typography>
                        {modifyJob ? (
                          <Autocomplete
                            options={(
                              fetchedRelatedJobSectors ??
                              Object.keys(JobSectors)
                            ).map(sector => {
                              if (sector === JobSectors.HVAC) {
                                return sector.toString();
                              }
                              return snakeCaseJobSectors[
                                sector.toString() as JobSectors
                              ];
                            })}
                            defaultValue={
                              snakeCaseJobSectors[
                                newJob?.sector as JobSectors
                              ] ?? snakeCaseJobSectors[JobSectors.OTHER]
                            }
                            value={formik.values.sector}
                            getOptionLabel={option => option}
                            renderInput={params => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                error={
                                  formik.touched.sector &&
                                  Boolean(formik.errors.sector)
                                }
                                helperText={
                                  formik.touched.sector && formik.errors.sector
                                }
                                value={formik.values.sector ?? []}
                                margin="normal"
                                InputLabelProps={{
                                  style: { marginBottom: "8px" },
                                }}
                                label="Select Service Categories"
                              />
                            )}
                            renderOption={(props, option, { selected }) => (
                              <MenuItem {...props} key={option} value={option}>
                                <Box
                                  display={"flex"}
                                  flexDirection={"row"}
                                  justifyContent={"space-between"}
                                  width={"100%"}
                                >
                                  {<Typography>{option}</Typography>}
                                  {selected ? (
                                    <CheckCircleOutlineIcon color="primary" />
                                  ) : null}
                                </Box>
                              </MenuItem>
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                // eslint-disable-next-line react/jsx-key
                                <Chip
                                  size="small"
                                  label={option}
                                  {...getTagProps({ index })}
                                  variant="outlined"
                                />
                              ))
                            }
                            onChange={(
                              _: SyntheticEvent,
                              newValue: string | null
                            ) => {
                              formik.setFieldValue(
                                "sector",
                                newValue as JobSectors
                              );
                              updateJobSector({
                                jobId: newJob?.id,
                                sector:
                                  reverseSnakeCaseJobSectors[
                                    newValue as JobSectors
                                  ],
                              });
                            }}
                            onBlur={formik.handleBlur}
                          />
                        ) : (
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={isMobile ? "start" : "center"}
                            flexDirection={isMobile ? "column" : "row"}
                            gap={1}
                            mb={isMobile ? 1 : 0}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"start"}
                              alignItems={isMobile ? "start" : "center"}
                              flexDirection={isMobile ? "column" : "row"}
                              gap={1}
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{ color: "#111927" }}
                              >
                                Service Category
                              </Typography>
                              <Chip
                                key={newJob?.sector}
                                sx={{
                                  color: "text.secondary",
                                  bgcolor: "#F8F9FA",
                                }}
                                label={
                                  snakeCaseJobSectors[
                                    newJob?.sector as JobSectors
                                  ] ?? snakeCaseJobSectors[JobSectors.OTHER]
                                }
                                size="small"
                              />
                            </Box>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                setModifyJob(true);
                              }}
                            >
                              Edit Service Category
                            </Button>
                          </Box>
                        )}
                        <Box display={"flex"} gap={0.5} alignItems={"center"}>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "#111927" }}
                          >
                            Job Type
                          </Typography>
                          <ToolTip
                            placement="bottom"
                            message={`A brief title based on the job you uploaded.
                           After analyzing the content, we summarize it
                           into a one-liner. Example: "Faucet
                           Repair" for a plumbing video.`}
                          >
                            <HelpOutline
                              sx={{
                                background: "none",
                                color: "#6C737F",
                                width: "20px",
                                height: "17px",
                              }}
                            />
                          </ToolTip>
                        </Box>
                        <TextField
                          required
                          fullWidth
                          multiline
                          hiddenLabel
                          maxRows={8}
                          id="jobType"
                          name="jobType"
                          margin="normal"
                          InputLabelProps={{ shrink: false }}
                          inputProps={{ minLength: 3, maxLength: 50 }}
                          helperText={
                            jobType.length < 3
                              ? `Minimum length is 3 characters`
                              : jobType.length > 50
                              ? `Maximum 50 characters`
                              : ""
                          }
                          error={jobType?.length < 3 || jobType?.length > 50}
                          sx={{ my: 0, mx: 0, mt: -1 }}
                          value={jobType}
                          onChange={e => setJobType(e.target.value)}
                        />
                        <Box
                          display={"flex"}
                          width={"100%"}
                          justifyContent={"end"}
                          mt={-1}
                        >
                          <Typography
                            fontSize={"12px"}
                            color="text.secondary"
                            fontWeight={400}
                            mr={1}
                          >
                            {jobType?.length ?? 0}/500 Characters
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            gutterBottom
                            variant="subtitle2"
                            sx={{ color: "#111927" }}
                          >
                            Summary
                          </Typography>
                          <TextField
                            required
                            fullWidth
                            multiline
                            hiddenLabel
                            id="summary"
                            name="summary"
                            margin="normal"
                            InputLabelProps={{ shrink: false }}
                            maxRows={8}
                            inputProps={{ minLength: 3, maxLendth: 500 }}
                            helperText={
                              jobSummary.length < 3
                                ? `Minimum length is 3 characters`
                                : jobSummary.length > 500
                                ? `Maximum 500 characters`
                                : ""
                            }
                            error={
                              jobSummary?.length < 3 || jobSummary?.length > 500
                            }
                            sx={{ my: 0, mx: 0, p: 0 }}
                            value={jobSummary}
                            onChange={e => setJobSummary(e.target.value)}
                          />
                          <Box
                            display={"flex"}
                            width={"100%"}
                            justifyContent={"end"}
                            mt={0.5}
                          >
                            <Typography
                              fontSize={"12px"}
                              color="text.secondary"
                              fontWeight={400}
                              mr={1}
                            >
                              {jobSummary?.length ?? 0}/500 Characters
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        width={"100%"}
                        justifyContent="start"
                        alignItems="start"
                        mt={1}
                      >
                        <Button
                          sx={{
                            mr: 1,
                          }}
                          color="primary"
                          variant="outlined"
                          size={isMobile ? "small" : "medium"}
                          onClick={() => {
                            revertStep3();
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          sx={{
                            ml: 1,
                          }}
                          color="primary"
                          variant="contained"
                          size={isMobile ? "small" : "medium"}
                          onClick={() => {
                            setStep(3);
                          }}
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <Avatar
                  variant="square"
                  color="primary"
                  sx={{
                    my: 1,
                    borderRadius: 1,
                    color: step >= 3 ? neutral[50] : "textPrimary",
                    bgcolor: step >= 3 ? tradeEngageColors.green : neutral[200],
                  }}
                >
                  {step > 3 ? <CheckIcon color="inherit" /> : "4"}
                </Avatar>
                {step >= 3 ? (
                  <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
                ) : null}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: 5, pt: 1.6 }}>
                <Typography variant="overline">Add Home Address</Typography>
                {step === 3 ? (
                  <Box width="100%" pt={3}>
                    <Box
                      display="flex"
                      justifyContent="start"
                      alignItems={"start"}
                      width={"100%"}
                      flexDirection={"column"}
                      gap={1.5}
                    >
                      <Box
                        display="flex"
                        flexDirection={"column"}
                        gap={1.5}
                        width="100%"
                      >
                        <Box
                          display={"flex"}
                          width="100%"
                          gap={2}
                          flexDirection={isMobile ? "column" : "row"}
                        >
                          <Box sx={{ width: "100%" }}>
                            <GoogleAutocomplete
                              defaultOptions={[
                                ...(homes?.map(home => home.streetAddress) ??
                                  []),
                              ]}
                              selectedValue={formik.values.address ?? ""}
                              error={
                                formik.touched.address &&
                                Boolean(formik.errors.address)
                              }
                              helperText={
                                formik.touched.address && formik.errors.address
                              }
                              label="Home Address*"
                              onChange={(address: string) => {
                                formik.setFieldValue("address", address);
                              }}
                              onSelect={({ selection, zipCode }) => {
                                if (zipCode) {
                                  setZipCode(zipCode);
                                }
                                formik.setFieldValue("address", selection);
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        width={"100%"}
                        justifyContent="start"
                        alignItems="start"
                        mt={1}
                      >
                        <Button
                          sx={{
                            mr: 1,
                          }}
                          variant="outlined"
                          size={isMobile ? "small" : "medium"}
                          onClick={() => {
                            setZipCode("");
                            setLatitude(undefined);
                            setLongitude(undefined);
                            formik.setFieldValue("address", "");

                            setStep(2);
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          disabled={
                            disableHomeOwnerReviewRequiredNextButton ||
                            submitingJob
                          }
                          color="primary"
                          variant="contained"
                          size={isMobile ? "small" : "medium"}
                          onClick={() => formik.handleSubmit()}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        )}
      </DialogContent>

      {step !== 0 && newJob ? (
        <Box position={"sticky"} bottom={0} mb={2}>
          {" "}
          <Divider />
          <Box display={"flex"} gap={2} alignItems={"center"} mt={2} px={2}>
            <Typography variant="subtitle2" fontWeight={600}>
              Want to restart?
            </Typography>
            <Button
              startIcon={<CloseIcon />}
              variant="outlined"
              disabled={isDeletingJob}
              size="small"
              onClick={() => {
                deleteJob(newJob.id);
                reset();
              }}
            >
              Cancel Job
            </Button>
          </Box>
        </Box>
      ) : null}
    </Dialog>
  );
}
