import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";
import { Job } from "@/company/jobs/types";
import { Company, CompanyPublicProfile } from "@/company/types";

export const USE_GET_MY_SERVICE_PROVIDERS_URI = "/homeowner/service-providers";

export function useGetMyServiceProviders(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<
  Array<{
    companyProfile: CompanyPublicProfile;
    lastJob: Job;
    referredByCompany: Company;
  }>
> {
  return useQuery({
    queryKey: [USE_GET_MY_SERVICE_PROVIDERS_URI],
    queryFn: async () => {
      const response = await API.get(USE_GET_MY_SERVICE_PROVIDERS_URI);
      return response.data.data;
    },
    ...options,
  });
}
