import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Typography,
} from "@mui/material";
import { useField } from "formik";

import { isDefined } from "@/utils/isDefined";

interface RadioGroupFieldProps extends Omit<RadioGroupProps, "name"> {
  label?: string;
  name: string;
  radioSize?: RadioProps["size"];
  options: {
    label: string;
    value: string | number;
  }[];
}

export function RadioGroupField(props: RadioGroupFieldProps) {
  const { label, name, radioSize = "medium", options, ...otherProps } = props;

  const [field] = useField(name);
  return (
    <FormControl>
      {isDefined(label) && <FormLabel sx={{ mb: 1 }}>{label}</FormLabel>}
      <RadioGroup {...field} {...otherProps} sx={{ gap: 1 }}>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio size={radioSize} />}
            label={<Typography variant="body2">{option.label}</Typography>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
