import { GridColumnOrderChangeParams } from "@mui/x-data-grid-premium";

export const handleColumnOrderChange = (
  params: GridColumnOrderChangeParams,
  columns: any,
  setColumnOrder: any
) => {
  const { column, targetIndex } = params;

  // Reorder the columns based on the drag and drop
  const newColumnOrder = [...columns];
  const columnIndex = newColumnOrder.findIndex(
    col => col.field === column.field
  );

  // Remove the moved column and reinsert it at the new index
  const [movedColumn] = newColumnOrder.splice(columnIndex, 1);
  newColumnOrder.splice(targetIndex, 0, movedColumn);

  // Extract the new field order
  const newFieldOrder = newColumnOrder.map(col => col.field);

  setColumnOrder(newFieldOrder);
};
