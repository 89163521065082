import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useCreateReferralInvitations(
  options: UseMutationOptions<
    void,
    AxiosError,
    { referrerId: number; referredId: number }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { referrerId: number; referredId: number }
> {
  return useMutation({
    mutationFn: async ({ referrerId, referredId }) => {
      const updateCompanyUri = `/internal-admin/companies/${referrerId}/referral-invitations`;
      await API.post(updateCompanyUri, { company_id: referredId });
    },
    ...options,
  });
}
