import { Box, Typography } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { AppConstants } from "@/common/constants";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { LocationDetails } from "@/company/onboarding/api";

import { findBoundaryRestrictions, findStatesInRadius } from "./utils";

const mapOptions = {
  mapId: AppConstants.googleMapId,
};

const mapContainerStyle = {
  height: "100%",
};

const FEATURE_FILL_COLOR = "#67A680";
const FEATURE_STROKE_COLOR = "#087443";
const FEATURE_IDENTIFIER = "GEOID10";

export default function TerritoryMap({
  location,
  selectedTerritories,
  currentGeoIds,
  onTerritoryClick,
  companyId,
}: {
  location: LocationDetails | undefined;
  selectedTerritories: Array<google.maps.Data.Feature>;
  currentGeoIds?: Array<string>;
  onTerritoryClick: (features: Array<google.maps.Data.Feature>) => void;
  companyId?: number;
}) {
  const { session } = useAuth();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const dataLayer = useRef<google.maps.Data | null>();
  const eventListeners = useRef<google.maps.MapsEventListener>();

  const onMapLoad = useCallback((map: google.maps.Map) => {
    dataLayer.current = map.data;
    setMap(map);
  }, []);

  /**
   * OnClick handler to select and deselect territories on user click.
   */
  const onClick = useCallback(
    async (event: { feature: google.maps.Data.Feature }) => {
      if (!dataLayer.current) {
        return;
      }
      const feature = event.feature;
      const featureId = feature.getProperty(FEATURE_IDENTIFIER);
      // To change color to transparent when territory is deselected
      if (
        selectedTerritories.some(
          territory => territory.getProperty(FEATURE_IDENTIFIER) === featureId
        )
      ) {
        dataLayer.current.overrideStyle(feature, {
          strokeColor: FEATURE_STROKE_COLOR,
          strokeWeight: 2,
          fillColor: "transparent",
        });
        const newSelectedTerritories = selectedTerritories.filter(
          feature => feature.getProperty(FEATURE_IDENTIFIER) !== featureId
        );
        onTerritoryClick([...newSelectedTerritories]);
        return;
      }

      // To change color to green when territory is selected
      dataLayer.current.overrideStyle(feature, {
        fillColor: FEATURE_FILL_COLOR,
      });
      const newSelectedTerritories = [...selectedTerritories];
      newSelectedTerritories.push(feature);
      onTerritoryClick(newSelectedTerritories);
    },
    [onTerritoryClick, selectedTerritories, dataLayer]
  );

  /**
   * OnClick handler to select and deselect territories on user click.
   */
  const loadGeoJson = useCallback(async () => {
    if (dataLayer.current && location?.stateCode) {
      setCenter({ lat: location.lat, lng: location.lng });
      const bounds = findBoundaryRestrictions(
        location,
        // TODO: remove hardcoding after enterprise account implementation
        companyId === 4345 || session?.company?.id === 4345 ? 200 : undefined
      );
      map?.setOptions({
        restriction: {
          latLngBounds: bounds,
          strictBounds: true,
        },
      });
      const newDataLayer = new google.maps.Data();
      dataLayer.current.setMap(null);
      newDataLayer.setMap(map);
      dataLayer.current = newDataLayer;
      dataLayer.current.setStyle({
        strokeColor: FEATURE_STROKE_COLOR,
        strokeWeight: 2,
        fillColor: "transparent",
      });
      const response = await axios.get(
        `${AppConstants.s3BucketPathStatesGeoJson}/${location?.stateCode}.geojson.gz`
      );
      if (session?.groups?.some(isAdministratorOrOwner)) {
        const event = dataLayer.current.addListener("click", onClick);
        eventListeners.current = event;
      }
      newDataLayer.addGeoJson(response.data, {
        idPropertyName: FEATURE_IDENTIFIER,
      });
      if (currentGeoIds) {
        const newSelectedTerritories = [...selectedTerritories];
        currentGeoIds.forEach(geoId => {
          const feature = dataLayer.current?.getFeatureById(geoId);
          if (feature) {
            dataLayer.current?.overrideStyle(feature, {
              fillColor: FEATURE_FILL_COLOR,
            });
            newSelectedTerritories.push(feature);
          }
        });
        onTerritoryClick(newSelectedTerritories);
      }

      const states = findStatesInRadius(
        location.zipCode,
        // TODO: remove hardcoding after enterprise account implementation
        companyId === 4345 || session?.company?.id === 4345 ? 200 : undefined
      );
      states.forEach(state => {
        if (state.toLowerCase() !== location.stateCode) {
          newDataLayer.loadGeoJson(
            `${
              AppConstants.s3BucketPathStatesGeoJson
            }/${state.toLowerCase()}.geojson.gz`,
            {
              idPropertyName: FEATURE_IDENTIFIER,
            },
            /**
             * Callback to mark the existing territories as selected using the unique GEOID10 field
             * of a unique territory in geojson.
             */
            _ => {
              if (currentGeoIds) {
                const newSelectedTerritories = [...selectedTerritories];
                currentGeoIds.forEach(geoId => {
                  const feature = dataLayer.current?.getFeatureById(geoId);
                  if (feature) {
                    dataLayer.current?.overrideStyle(feature, {
                      fillColor: FEATURE_FILL_COLOR,
                    });
                    newSelectedTerritories.push(feature);
                  }
                });
                onTerritoryClick(newSelectedTerritories);
              }
            }
          );
        }
      });
    }
  }, [location, map, currentGeoIds]);

  /**
   * Useeffect needed to update data layer whenever the location center is changed.
   * Unfortunately we can not replace the data and hence need to completely remove
   * the current layer with new one.
   */
  useEffect(() => {
    if (dataLayer.current && location?.stateCode) {
      loadGeoJson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, map, currentGeoIds]);

  /**
   * Useeffect needed to remove outdated onclick handler.
   * As we change onclick handler when selected territories change.
   */
  useEffect(() => {
    if (!dataLayer.current) return;
    eventListeners?.current?.remove();
    if (session?.groups?.some(isAdministratorOrOwner)) {
      eventListeners.current = dataLayer.current.addListener("click", onClick);
    }
  }, [onClick]);

  // TODO: Placeholder till we get design
  if (!location) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          gap={3}
          flex={1}
        >
          <img
            src="/empty-icon.png"
            style={{ width: "132px", height: "128px" }}
            alt="video thumbnail"
          />
          <Box textAlign={"center"}>
            <Typography gutterBottom variant="h6">
              No Zip Code Added
            </Typography>
            <Typography variant="body1" color={"text.secondary"}>
              Enter a valid U.S. zip code to display the map here.
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%", width: "100%", borderRadius: "10px" }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={11.5}
        center={center}
        options={mapOptions}
        onLoad={onMapLoad}
      ></GoogleMap>
    </Box>
  );
}
