import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { Home } from "../types";

export type CreateHomeOptions = Omit<Home, "id" | "createdAt">;

export function useCreateHome(
  options: UseMutationOptions<Home, AxiosError, CreateHomeOptions> = {}
): UseMutationResult<Home, AxiosError, CreateHomeOptions> {
  return useMutation({
    mutationFn: async (data): Promise<Home> => {
      const response = await API.post(
        `/homeowner/homes`,
        changeCaseKeys(data, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
