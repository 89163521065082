import { MapOutlined as MapOutlinedIcon } from "@mui/icons-material";
import * as yup from "yup";

import { AutocompleteField } from "@/lib/form-fields/AutocompleteField";

import { referralCampaignGeographyOptions } from "../../constants";
import { AddReferralCampaignFormInputField } from "./AddReferralCampaignFormInputField";

export const referralCampaignBrandOptions = ["All", "Specific Brands"];

export const referralCampaignGeographyFormSchema = yup.object().shape({
  geographies: yup
    .array()
    .of(yup.string().required())
    .required("Campaign Regions are required")
    .min(1, "Campaign Regions are required"),
});

export type ReferralCampaignGeographyFormFields = yup.InferType<
  typeof referralCampaignGeographyFormSchema
>;

export function ReferralCampaignGeographyForm() {
  return (
    <AddReferralCampaignFormInputField
      title="Geography(s)"
      icon={<MapOutlinedIcon />}
      includeConnector={false}
    >
      <AutocompleteField
        fullWidth
        multiple
        name="geographies"
        label="Regions"
        options={referralCampaignGeographyOptions?.map(geography => ({
          label: geography,
          value: geography,
        }))}
      />
    </AddReferralCampaignFormInputField>
  );
}
