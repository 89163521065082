import { Add as AddIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import type { DropResult } from "react-beautiful-dnd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";

import { MANAGE_PARTNERS_INFO_TOAST_KEY, useAuth } from "@/auth/context/jwt";
import { Seo } from "@/common/Seo/Seo";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useGetReferralInvitations } from "@/company/api/useGetReferralInvitations";
import { useGetReferralInvitationById } from "@/company/api/useGetReferralInviteById";
import { useGetReferralPartners } from "@/company/api/useGetReferralPartners";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { useReferralContext } from "@/company/context/referral-context";
import { JobSectors } from "@/company/jobs/constants";
import {
  CompanyPublicProfile,
  CompanyRoutes,
  ConnectionStatus,
  Referral,
  ReferralInvitation,
} from "@/company/types";
import { isTypeReferral, snakeCaseJobSectors } from "@/company/utils";

import { CompanyDrawer, TabsEnum } from "../../company-drawer/CompanyDrawer";
import { CompanyPartnerCard } from "../../company-profile-card/CompanyPartnerCard";
import { InvitationDialog } from "../../invitation-dialog/InvitationDialog";
import { getInitialData, getSortedSectorsData } from "./utils";

export function ManagePartnersPage() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TabsEnum | null>(null);
  const [filteredSectors, setFilteredSectors] = useState<Array<string>>([]);
  const [showProTip, setShowProTip] = useState(false);
  const { invitationId, tab } = useParams();

  const navigate = useNavigate();

  const { updateReferralOrder } = useReferralContext();

  const { isFetching: isFetchingReferralPartners, data: referralPartners } =
    useGetReferralPartners({
      refetchOnWindowFocus: false,
    });

  const { session } = useAuth();
  const company = session?.company;

  useEffect(() => {
    const tipCount = window.localStorage.getItem(
      MANAGE_PARTNERS_INFO_TOAST_KEY
    );
    localStorage.setItem(
      MANAGE_PARTNERS_INFO_TOAST_KEY,
      tipCount ? (parseInt(tipCount) + 1).toString() : "1"
    );
    if (!tipCount || parseInt(tipCount) < 3) {
      setShowProTip(true);
    }
  }, []);

  const {
    data: relevanceOrderedJobSectorsList,
    isFetching: isFetchingRelevanceOrderedJobSectorsList,
  } = useGetRelevanceOrderedJobSectorsList(company?.id, {
    refetchOnWindowFocus: false,
  });

  const {
    isFetching: isFetchingReferralInvitations,
    data: referralInvitations,
    refetch: refetchReferralInvitations,
  } = useGetReferralInvitations({
    refetchOnWindowFocus: false,
  });

  const combinedData = getInitialData();
  referralPartners?.forEach(partner => {
    if (Object.keys(combinedData).includes(partner.sector as JobSectors)) {
      combinedData[partner.sector].push(partner);
    }
  });
  referralInvitations?.forEach(invitation => {
    if (!invitation.declinedAt && !invitation.acceptedAt) {
      invitation.otherCompanyProfile?.sectors.forEach(sectors => {
        if (Object.keys(combinedData).includes(sectors.sector as JobSectors)) {
          combinedData[sectors.sector].push(invitation);
        }
      });
    }
  });

  const sortedCombinedDataByRelevantJobSectors = getSortedSectorsData(
    combinedData,
    relevanceOrderedJobSectorsList ?? []
  );

  const getSortedFilteredDataKeys = (
    filteredData: Record<JobSectors, (Referral | ReferralInvitation)[]>
  ) => {
    return Object.keys(filteredData).sort();
  };

  useEffect(() => {
    setFilteredData(sortedCombinedDataByRelevantJobSectors);
  }, [referralPartners, referralInvitations]);

  const handleDragEnd = useCallback(
    async ({ source, destination, draggableId }: DropResult): Promise<void> => {
      try {
        // Dropped outside the column
        if (!destination) {
          return;
        }

        // Task has not been moved
        if (
          source.droppableId === destination.droppableId &&
          source.index === destination.index
        ) {
          return;
        }

        // Moved to the same column on different position
        if (source.droppableId === destination.droppableId) {
          const referralId = parseInt(draggableId.split("-")[1]);
          await updateReferralOrder({
            referralId,
            newOrderId: destination.index,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [referralPartners, referralInvitations]
  );

  const [filteredData, setFilteredData] = useState(combinedData);
  const [currentCompany, setCurrentCompany] = useState<
    Referral | ReferralInvitation | null
  >(null);

  useEffect(() => {
    if (!filteredSectors.length) {
      // If the search term is empty, show the whole list
      setFilteredData(sortedCombinedDataByRelevantJobSectors);
      return;
    }
    const newData = {} as Record<
      JobSectors,
      Array<Referral | ReferralInvitation>
    >;
    Object.keys(sortedCombinedDataByRelevantJobSectors).forEach(sector => {
      if (filteredSectors.includes(snakeCaseJobSectors[sector as JobSectors])) {
        newData[sector as JobSectors] =
          sortedCombinedDataByRelevantJobSectors[sector as JobSectors];
      }
    });
    setFilteredData(newData);
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        left: 0,
      });
    }
  }, [filteredSectors]);

  const { data: invitationByParam } = useGetReferralInvitationById(
    invitationId,
    {
      refetchOnWindowFocus: false,
      enabled: !!invitationId,
    }
  );

  const getConnectionStatus = useCallback(
    (currentCompany: CompanyPublicProfile): ConnectionStatus => {
      if (
        referralPartners?.some(
          referral =>
            referral.referredCompanyId === currentCompany?.id ||
            referral.referringCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.CONNECTED;
      if (
        referralInvitations?.some(
          invitation => invitation.invitedCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.PENDING;

      if (
        referralInvitations?.some(
          invitation => invitation.invitingCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.INVITED;

      return ConnectionStatus.NOT_CONNECTED;
    },
    [referralInvitations, referralPartners]
  );

  const containerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft <
          container.scrollWidth - container.clientWidth - 24
      );
    }
  };

  const handleScrollLeft = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        left:
          container.scrollLeft - (isMobile ? window.innerWidth : 600) + 96 - 24,
        behavior: "smooth", // Smooth scrolling behavior
      });
    }
  };

  const handleScrollRight = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        left:
          container.scrollLeft + (isMobile ? window.innerWidth : 600) + 24 - 96,
        behavior: "smooth", // Smooth scrolling behavior
      });
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft <
          container.scrollWidth - container.clientWidth - 24
      );
    }
  });

  useEffect(() => {
    if (invitationByParam) {
      setSelectedTab(tab as TabsEnum);
      setCurrentCompany(invitationByParam);
    }
  }, [invitationByParam, tab]);

  return (
    <>
      <Seo title="Manage Partners" />
      <Container disableGutters maxWidth="100%">
        <Grid
          container
          flexDirection={"column"}
          sx={{
            ...(referralInvitations?.length || referralInvitations?.length
              ? { top: "56px", zIndex: 400, pt: 1, position: "sticky" }
              : { position: "relative", pt: 1 }),
          }}
          bgcolor={"white"}
        >
          <Box maxWidth="100%" height={"fit-content "}>
            {isMobile ? null : (
              <Box display={"flex"} alignItems={"center"}>
                <Typography variant="h5">Manage Partners</Typography>
                <ToolTip
                  message="You can have as many partners as you'd like but partners listed first will be the first to receive any jobs you refer in that service category. Partners have 24 hours to respond before the job is offered to your second referral partner and so forth."
                  placement="top"
                >
                  <HelpOutlineIcon
                    sx={{
                      background: "none",
                      color: "#6C737F",
                      width: "20px",
                      height: "20px",
                      ml: "2px",
                    }}
                    fontSize="small"
                  />
                </ToolTip>
              </Box>
            )}
            {isDesktop && (
              <Typography variant="body2" color="text.secondary" sx={{ pt: 1 }}>
                Manage referral partners across all service categories by
                clicking the left and right arrows. Re-order referral partners
                by dragging companies up-and-down.
              </Typography>
            )}
          </Box>
          {referralInvitations?.length ||
          referralPartners?.length ||
          isFetchingRelevanceOrderedJobSectorsList ||
          isFetchingReferralPartners ||
          isFetchingReferralInvitations ? (
            <Grid
              container
              pb={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} lg={12}>
                <Autocomplete
                  multiple
                  id="sectors"
                  sx={{ m: 0 }}
                  options={Object.keys(sortedCombinedDataByRelevantJobSectors)
                    .map(sector => {
                      if (sector === JobSectors.HVAC) {
                        return sector.toString();
                      }
                      return snakeCaseJobSectors[
                        sector.toString() as JobSectors
                      ];
                    })
                    .sort()}
                  getOptionLabel={option => option}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        style: { marginBottom: "8px" },
                      }}
                      label="Select Service Categories"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props} key={option} value={option}>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        {<Typography>{option}</Typography>}
                        {selected ? (
                          <CheckCircleOutlineIcon color="primary" />
                        ) : null}
                      </Box>
                    </MenuItem>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      // eslint-disable-next-line react/jsx-key
                      <Chip
                        size="small"
                        label={option}
                        {...getTagProps({ index })}
                        variant="outlined"
                      />
                    ))
                  }
                  onChange={(_: SyntheticEvent, newValue: string[] | null) =>
                    setFilteredSectors(newValue ?? [])
                  }
                />
              </Grid>
              {isDesktop && showProTip ? (
                <Grid item xs={12} lg={12}>
                  <Box
                    px={3}
                    py={2}
                    display={"flex"}
                    gap={2}
                    alignItems={"center"}
                    bgcolor={"#071D2F"}
                    mx={-2}
                  >
                    <IconButton
                      sx={{
                        bgcolor: "#16DB65",
                        color: "white",
                        borderRadius: "20px",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <EmojiObjectsOutlinedIcon />
                    </IconButton>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={"700"}
                      color={"white"}
                    >
                      PRO TIP:{" "}
                      <Typography display={"inline"} variant="body2">
                        You can have as many referral partners as you&apos;d
                        like but partners listed first will be the first to
                        receive any jobs you refer in that service category.
                        Partners have 24 hours to respond before the job is
                        offered to your second referral partner and so forth.
                      </Typography>
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setShowProTip(false);
                      }}
                    >
                      <CloseIcon sx={{ color: "gray" }} />
                    </IconButton>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          ) : (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              pt={"200px"}
              gap={3}
              flex={1}
            >
              <img
                src="/empty-icon.png"
                style={{ width: "132px", height: "128px" }}
                alt="no result"
              />
              <Box textAlign={"center"}>
                <Typography gutterBottom variant="h5">
                  No Connections Made
                </Typography>
                <Typography variant="body1" color={"text.secondary"}>
                  You are one step closer to your first referral. Explore and
                  connect with partners now to get started.
                </Typography>
              </Box>
              <Button
                variant="contained"
                size="large"
                startIcon={<TravelExploreIcon />}
                onClick={() => {
                  navigate(
                    getNavigationPathFromRoutePaths([
                      CompanyRoutes.BASE,
                      CompanyRoutes.EXPLORE_PARTNERS,
                    ])
                  );
                }}
              >
                Explore
              </Button>
            </Box>
          )}
        </Grid>
        {isFetchingRelevanceOrderedJobSectorsList ||
        isFetchingReferralPartners ||
        isFetchingReferralInvitations ? (
          <Box py={5} textAlign={"center"}>
            <CircularProgress />
          </Box>
        ) : referralInvitations?.length || referralPartners?.length ? (
          <Box {...(isDesktop && showProTip ? { mt: 3 } : {})}>
            <>
              {/* <Box
                sx={{
                  // display: "flex",
                  // alignItems: "flex-start",
                  overflow: "scroll",
                  transform: "rotateX(180deg)",
                }}
              > */}
              <Box display={"flex"} alignItems={"flex-start"}>
                {showLeftArrow || showRightArrow || isMobile ? (
                  <IconButton
                    size="small"
                    disabled={!showLeftArrow}
                    sx={{ px: 2, pt: 1.5, width: "12px" }}
                    onClick={event => {
                      event.preventDefault();
                      handleScrollLeft();
                    }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                ) : null}
                <Box
                  ref={containerRef}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    // transform: "rotateX(180deg)",
                  }}
                  onScroll={handleScroll}
                >
                  <Box display={"flex"} sx={{ position: "sticky", top: "8px" }}>
                    {getSortedFilteredDataKeys(filteredData)?.map(
                      (jobSector, index) => {
                        return (
                          <Box
                            key={jobSector}
                            sx={{
                              mr:
                                index === Object.keys(filteredData)?.length - 1
                                  ? 0
                                  : 3,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mb: 2,
                                width: isMobile
                                  ? "calc(100vw - 96px)"
                                  : "300px",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {snakeCaseJobSectors[jobSector as JobSectors]}
                              </Typography>
                              <Chip
                                label={
                                  filteredData[jobSector as JobSectors].length
                                }
                                color="default"
                                sx={{ ml: 1 }}
                              />
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                  <Box
                    display={"flex"}
                    overflow={"auto"}
                    sx={{
                      mt: "8px",
                      maxHeight: "calc(100vh - 270px)",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {getSortedFilteredDataKeys(filteredData)?.map(jobSector => {
                      return (
                        <Box key={jobSector} sx={{ mr: 3 }}>
                          <Box
                            sx={{
                              borderRadius: "25px",
                              bgcolor: "#F8F9FA",
                              width: isMobile ? "calc(100vw - 96px)" : "300px",
                              py: 2,
                              mb: 2,
                            }}
                          >
                            {filteredData[jobSector as JobSectors].filter(
                              data => !!isTypeReferral(data)
                            ).length ? (
                              <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable
                                  droppableId={jobSector}
                                  type={jobSector}
                                >
                                  {(droppableProvider): JSX.Element => (
                                    <Box
                                      ref={droppableProvider.innerRef}
                                      sx={{
                                        flexGrow: 1,
                                        minHeight: 80,
                                        overflowY: "auto",
                                        px: 3,
                                        py: 0,
                                      }}
                                    >
                                      {filteredData[jobSector as JobSectors]
                                        .filter(data => !!isTypeReferral(data))
                                        .map((data, index) => {
                                          if (!isTypeReferral(data))
                                            return null;
                                          return (
                                            <Draggable
                                              key={`${jobSector}-${data?.id?.toString()}`}
                                              draggableId={`${jobSector}-${data?.id?.toString()}`}
                                              isDragDisabled={
                                                !session?.groups?.some(
                                                  isAdministratorOrOwner
                                                )
                                              }
                                              index={data?.order}
                                            >
                                              {(
                                                draggableProvided,
                                                snapshot
                                              ): JSX.Element => (
                                                <Box
                                                  ref={
                                                    draggableProvided.innerRef
                                                  }
                                                  style={{
                                                    ...draggableProvided
                                                      .draggableProps.style,
                                                  }}
                                                  sx={{
                                                    outline: "none",
                                                    py: 1.5,
                                                  }}
                                                  {...draggableProvided.draggableProps}
                                                  {...draggableProvided.dragHandleProps}
                                                >
                                                  <CompanyPartnerCard
                                                    key={data?.id}
                                                    jobSector={
                                                      jobSector as JobSectors
                                                    }
                                                    companyDetails={data}
                                                    dragging={
                                                      snapshot.isDragging
                                                    }
                                                    orderId={data?.order?.toString()}
                                                    onOpen={(tab: TabsEnum) => {
                                                      setSelectedTab(tab);
                                                      setCurrentCompany(data);
                                                    }}
                                                  />
                                                </Box>
                                              )}
                                            </Draggable>
                                          );
                                        })}
                                      {droppableProvider.placeholder}
                                    </Box>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            ) : null}
                            {filteredData[jobSector as JobSectors]
                              .filter(data => !isTypeReferral(data))
                              .map(data => (
                                <Box
                                  key={data.id}
                                  sx={{
                                    outline: "none",
                                    px: 3,
                                    py: 1.5,
                                  }}
                                >
                                  <CompanyPartnerCard
                                    key={data.id}
                                    jobSector={jobSector as JobSectors}
                                    companyDetails={data}
                                    onOpen={(tab: TabsEnum) => {
                                      setSelectedTab(tab);
                                      setCurrentCompany(data);
                                    }}
                                  />
                                </Box>
                              ))}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                {showLeftArrow || showRightArrow || isMobile ? (
                  <IconButton
                    size="small"
                    disabled={!showRightArrow}
                    sx={{ px: 2, pt: 1.5, width: "12px" }}
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      handleScrollRight();
                    }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                ) : null}
              </Box>
              {/* </Box> */}
            </>
          </Box>
        ) : null}
      </Container>
      <InvitationDialog
        isOpen={isInviteDialogOpen}
        onClose={() => setIsInviteDialogOpen(false)}
      />
      {currentCompany && selectedTab ? (
        <CompanyDrawer
          open={!!currentCompany}
          companyProfile={currentCompany.otherCompanyProfile}
          {...(isTypeReferral(currentCompany)
            ? {}
            : { invitation: currentCompany })}
          connectionStatus={
            isTypeReferral(currentCompany)
              ? ConnectionStatus.CONNECTED
              : getConnectionStatus(currentCompany.otherCompanyProfile)
          }
          isViewOnly={!session?.groups?.some(isAdministratorOrOwner)}
          defaultTab={selectedTab}
          onClose={() => {
            setCurrentCompany(null);
          }}
        />
      ) : null}
      {isMobile || !session?.groups?.some(isAdministratorOrOwner) ? null : (
        <Box position="fixed" bottom={"40px"} right={"48px"}>
          <Fab
            color="primary"
            aria-label="add-invite"
            onClick={() => setIsInviteDialogOpen(true)}
          >
            <AddIcon />
          </Fab>
        </Box>
      )}
    </>
  );
}
