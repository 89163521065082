import CloseIcon from "@mui/icons-material/Close";
import TimelineIcon from "@mui/icons-material/Timeline";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { Box, Chip, Divider, Tab, Tabs } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import * as React from "react";

import { useAuth } from "@/auth/context/jwt";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { JobSectors } from "@/company/jobs/constants";
import {
  BRAND_CATEGORY,
  CompanyPublicProfile,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { CompanyAvatar } from "../company-avatar/CompanyAvatar";
import { ProfileContactInvite } from "./ProfileContactInvite";
import { ProfileNetworkInvite } from "./ProfileNetworkInvite";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export enum ProfileShareSource {
  FIELD_TEAM = "FIELD_TEAM",
  OPERATIONS = "OPERATIONS",
}

interface ServiceProviderShareDialogProps {
  isOpen: boolean;
  companyProfile: CompanyPublicProfile;
  onClose: () => void;
  source?: ProfileShareSource;
}

enum TabsEnum {
  CONTACT_INVITE = "EMAIL_INVITE",
  NETWORK_INVITE = "NETWORK_INVITE",
}

export const ServiceProviderShareDialog: React.FC<
  ServiceProviderShareDialogProps
> = ({
  isOpen,
  companyProfile,
  onClose,
  source = ProfileShareSource.OPERATIONS,
}) => {
  const { session } = useAuth();

  const user = session?.user;
  const [currentTab, setCurrentTab] = React.useState<TabsEnum>(
    TabsEnum.CONTACT_INVITE
  );

  const handleTabsChange = React.useCallback(
    (event: React.ChangeEvent<any>, value: TabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth
        aria-labelledby="invite-dialog-title"
        open={isOpen}
        sx={{ m: { xs: -3, md: 0 } }}
        onClose={onClose}
      >
        <Box sx={{ p: 3 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h5" m={0} p={0}>
              Share Profile
            </Typography>
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography gutterBottom variant="body2" color="text.secondary">
            {source === ProfileShareSource.FIELD_TEAM
              ? "Recommend your partner to a homeowner."
              : "Recommend your referral partner to a homeowner by sharing their profile."}
          </Typography>
          {source === ProfileShareSource.FIELD_TEAM ? null : (
            <Box
              sx={{
                mt: 2,
                borderRadius: "16px",
                background: "#FFF",
                p: 2,
                gap: 1,
                /* elevation/outlined */
                boxShadow: "0px 0px 0px 1px #F2F4F7",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box my={1}>
                <CompanyAvatar
                  height={"40px"}
                  maxWidth="250px"
                  logo={companyProfile.logoUrl}
                  name={companyProfile.name ?? ""}
                />
              </Box>
              {companyProfile.sectors.length ? (
                <Box display={"flex"} gap={1} flexWrap={"wrap"}>
                  {companyProfile.sectors.map(sector => (
                    <Chip
                      key={sector.sector}
                      label={
                        snakeCaseJobSectors[sector.sector] ??
                        snakeCaseJobSectors[JobSectors.OTHER]
                      }
                      sx={{ mr: 1, bgcolor: "#1119270A", color: "#6C737F" }}
                    />
                  ))}
                </Box>
              ) : null}
              <Box
                display={"flex"}
                gap={0.5}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography variant="h6">{companyProfile.name}</Typography>
                {companyProfile.platformPresence ===
                PLATFORM_PRESENCE.ON_PLATFORM ? (
                  <ToolTip
                    placement="bottom"
                    title="TradeEngage Participant"
                    message="This is a verified active business on TradeEngage."
                  >
                    <VerifiedOutlinedIcon
                      sx={{
                        verticalAlign: "center",
                        color: "#16DB65",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </ToolTip>
                ) : null}
              </Box>
              <Box display={"flex"} gap={2} alignItems={"center"}>
                {companyProfile.profile.brandCategory ===
                BRAND_CATEGORY.FRANCHISE ? (
                  <Box>
                    <Chip
                      label={"Franchise"}
                      sx={{ bgcolor: "#16DB65", color: "white" }}
                      size="small"
                    />
                  </Box>
                ) : null}
                {companyProfile?.lastActivity ? (
                  <Box display={"flex"}>
                    <TimelineIcon
                      fontSize="small"
                      sx={{ mr: 0.3, color: "#6C737F" }}
                    />
                    <Typography variant="caption" color="text.secondary">
                      Last Activity:{" "}
                      {DateTime.fromISO(companyProfile.lastActivity, {
                        zone: "utc",
                      })
                        .toLocal()
                        .toFormat("MM/dd/yy")}
                    </Typography>
                  </Box>
                ) : null}
                {companyProfile?.yearEstablished ? (
                  <Box display={"flex"}>
                    <VerifiedUserOutlinedIcon
                      fontSize="small"
                      sx={{ mr: 0.3, color: "#6C737F" }}
                    />
                    <Typography variant="caption" color="text.secondary">
                      {DateTime.now().year -
                      (companyProfile?.yearEstablished ?? DateTime.now().year)
                        ? DateTime.now().year -
                          (companyProfile?.yearEstablished ??
                            DateTime.now().year)
                        : "< 1"}{" "}
                      Years in Business
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Box>
          )}
          <Tabs value={currentTab} onChange={handleTabsChange}>
            <Tab value={TabsEnum.CONTACT_INVITE} label="Email Invite" />
            <Tab value={TabsEnum.NETWORK_INVITE} label="Share Link" />
          </Tabs>
          <Divider />
          <Box>
            {currentTab === TabsEnum.CONTACT_INVITE ? (
              <ProfileContactInvite companyId={companyProfile.id} />
            ) : null}
            {currentTab === TabsEnum.NETWORK_INVITE ? (
              <ProfileNetworkInvite companyProfile={companyProfile} />
            ) : null}
          </Box>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};
