import constate from "constate";
import { useCallback, useEffect, useState } from "react";

import { useMutationCreateLead } from "@/company/onboarding/api";
import { Lead } from "@/lead/types";

export const LEAD_DATA_STORAGE_KEY = "leads";

function useLeadsDetails() {
  const [leadDetails, setLeadDetails] = useState<Lead>({});

  const { mutateAsync: createLead } = useMutationCreateLead();

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const lead = window.localStorage.getItem(LEAD_DATA_STORAGE_KEY);
      if (lead) {
        setLeadDetails(JSON.parse(lead));
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const updateLeadDetails = async (
    updatedLeadDetails: Partial<Lead>,
    disableApiCall?: boolean
  ) => {
    const newLeadDetails = {
      ...leadDetails,
      ...updatedLeadDetails,
    };
    if (!disableApiCall) await createLead(newLeadDetails);
    window.localStorage.setItem(
      LEAD_DATA_STORAGE_KEY,
      JSON.stringify(newLeadDetails)
    );
    setLeadDetails(newLeadDetails);
  };
  return { leadDetails, updateLeadDetails };
}

export const [LeadProvider, useLeadContext] = constate(useLeadsDetails);
