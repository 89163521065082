import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FactsCarousel.css"; // Create a CSS file for custom styling

import { Box, Card, Divider, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";

export const FactsCarousel = () => {
  const items = [
    {
      id: 1,
      heading: "90%",
      italicContent:
        "of consumers have been convinced to make a purchase after watching a video",
      baseContent:
        "TradeEngage empowers home service pros to create job videos.",
    },
    {
      id: 2,
      heading: "78%",
      italicContent:
        "of consumers' local searches on mobile devices lead to a purchase within 24 hours",
      baseContent:
        "TradeEngage enables homeowners to instantly review service requests.",
    },
    {
      id: 3,
      heading: "76%",
      italicContent:
        "of consumers “regularly” read online reviews when browsing for local businesses",
      baseContent: "TradeEngage builds trust in your network.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: "button__bar",
    arrows: false,
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 0px 0px 0px #F2F4F7;",
        pt: 1,
        px: 1,
        bgcolor: "#F8F9FA",
        display: "flex",
        flexDirection: "column",
        border: "none",
        gap: 2,
        position: "relative",
      }}
    >
      <Slider {...settings}>
        {items.map(item => (
          <Box key={item.id}>
            <Box
              display="flex"
              gap={2}
              flexDirection={"column"}
              pt={2}
              mt={"16px"}
              mb={"0"}
            >
              <Typography variant="h2" color={"#16db65"}>
                {item.heading}
              </Typography>
              <Typography
                variant="body2"
                fontStyle={"italic"}
                sx={{
                  opacity: 0.9,
                  color: "#6C737F",
                }}
              >
                {item.italicContent}
              </Typography>
              <Divider
                sx={{
                  height: "1px",
                  width: "70px",
                  borderColor: "#16DB65",
                }}
              />
              <Typography
                fontSize={"16px"}
                fontWeight={"500"}
                color={"#111927"}
              >
                {item.baseContent}
              </Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </Card>
  );
};
