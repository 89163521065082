import { Box, Chip, debounce, styled, TextField } from "@mui/material";
import {
  getGridDateOperators,
  getGridStringOperators,
  GridColDef,
  GridFilterOperator,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import _ from "lodash";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";

import {
  BRAND_CATEGORY,
  HOMEOWNER_APPROVAL_SETTING,
  PayReferralFeeSettings,
} from "@/company/types";
import {
  getGridDateOperatorsDataGrid,
  getGridNumericOperatorsDataGrid,
} from "@/utils";

import { EditJobSectors, EditTextarea } from "./common/EditTextArea";
import { EditTimePicker, EditTimePickerFilter } from "./common/EditTimePicker";

export const formatDate = value => {
  if (!value) return ""; // Return empty if value is undefined or null
  const dt = DateTime.fromFormat(
    value.toString().split(".")[0].split("T").join(" "),
    "yyyy-MM-dd HH:mm:ss",
    { zone: "utc" }
  ).toLocal();
  return dt.toFormat("MM/dd/yy"); // Format as MM/DD/YY
};

export const formatDateTime = value => {
  if (!value) return ""; // Return empty if value is undefined or null
  const dt = DateTime.fromFormat(
    value.toString().split(".")[0].split("T").join(" "),
    "yyyy-MM-dd HH:mm:ss",
    { zone: "utc" }
  ).toLocal();
  return dt.toFormat("MM/dd/yy hh:mm a");
};

export function getDifference(newObj, oldObj) {
  const diff = {};

  function findDifference(newObj, oldObj, diff) {
    for (const key in newObj) {
      if (Object.prototype.hasOwnProperty.call(newObj, key)) {
        if (_.isObject(newObj[key]) && !_.isArray(newObj[key])) {
          if (!_.isEqual(newObj[key], oldObj[key])) {
            diff[key] = {};
            findDifference(newObj[key], oldObj[key], diff[key]);
          }
        } else if (!_.isEqual(newObj[key], oldObj[key])) {
          diff[key] = newObj[key];
        }
      }
    }
  }

  findDifference(newObj, oldObj, diff);
  return diff;
}

export const renderEditTextarea = params => <EditTextarea {...params} />;
export const renderJobSectorsArea = params => <EditJobSectors {...params} />;

export const alignableSourceFields: GridColDef[] = [
  {
    field: "alignable-id",
    type: "number",
    filterOperators: getGridNumericOperatorsDataGrid,
    headerName: "Alignable Source Alignable Source ID",
    width: 100,
    renderCell: params => params.row.alignableSource?.id?.toString(),
    valueFormatter: value => value && `${value?.toString()}`,
    valueGetter: (value, row) => row.alignableSource?.id,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          id: value,
        },
      };
    },
  },
  {
    field: "alignable-name",
    editable: true,
    headerName: "Alignable Source Name",
    width: 150,
    valueGetter: (value, row) => row.alignableSource?.name,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          name: value,
        },
      };
    },
  },
  {
    field: "alignable-description",
    editable: true,
    headerName: "Alignable Source Description",
    width: 200,
    valueGetter: (value, row) => row.alignableSource?.description,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          description: value,
        },
      };
    },
  },
  {
    field: "alignable-recommendationStatus",
    editable: true,
    headerName: "Alignable Source Recommendation Status",
    width: 200,
    valueGetter: (value, row) => row.alignableSource?.recommendationStatus,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          recommendationStatus: value,
        },
      };
    },
  },
  {
    field: "alignable-street",
    headerName: "Alignable Source Street",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.alignableSource?.street,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          street: value,
        },
      };
    },
  },
  {
    field: "alignable-city",
    headerName: "Alignable Source City",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.alignableSource?.city,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          city: value,
        },
      };
    },
  },
  {
    field: "alignable-state",
    headerName: "Alignable Source State",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.alignableSource?.state,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          state: value,
        },
      };
    },
  },
  {
    field: "alignable-zipCode",
    headerName: "Alignable Source Zip Code",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.alignableSource?.zipCode,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          zipCode: value,
        },
      };
    },
  },
  {
    field: "alignable-country",
    headerName: "Alignable Source Country",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.alignableSource?.country,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          country: value,
        },
      };
    },
  },
  {
    field: "alignable-sectors",
    headerName: "Alignable Source Sectors",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.alignableSource?.nsectorsame,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          sectors: value,
        },
      };
    },
  },
  {
    field: "alignable-representativeName",
    headerName: "Alignable Source Representative Name",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.alignableSource?.representativeName,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          representativeName: value,
        },
      };
    },
  },
  {
    field: "alignable-representativeRole",
    headerName: "Alignable Source Representative Role",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.alignableSource?.representativeRole,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          representativeRole: value,
        },
      };
    },
  },
  {
    field: "alignable-recommendedPartnersSectors",
    headerName: "Alignable Source Recommended Partners Sectors",
    editable: true,
    width: 250,
    valueGetter: (value, row) =>
      row.alignableSource?.recommendedPartnersSectors?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          recommendedPartnersSectors: value?.split(", "),
        },
      };
    },
  },
  {
    field: "alignable-productsAndServicesOffered",
    headerName: "Alignable Source Products and Services Offered",
    editable: true,
    width: 250,
    valueGetter: (value, row) =>
      row.alignableSource?.productsAndServicesOffered?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          productsAndServicesOffered: value?.split(", "),
        },
      };
    },
  },
  {
    field: "alignable-spotlightRecommendation",
    headerName: "Alignable Source Spotlight Recommendation",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.alignableSource?.spotlightRecommendation,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          spotlightRecommendation: value,
        },
      };
    },
  },
  {
    field: "alignable-spotlightRecommendationGivenByUserName",
    headerName: "Alignable Source Spotlight Recommendation Given by User",
    editable: true,
    width: 250,
    valueGetter: (value, row) =>
      row.alignableSource?.spotlightRecommendationGivenByUserName,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          spotlightRecommendationGivenByUserName: value,
        },
      };
    },
  },
  {
    field: "alignable-spotlightRecommendationGivenByCompany",
    headerName: "Alignable Source Spotlight Recommendation Given by Company",
    editable: true,
    width: 250,
    valueGetter: (value, row) =>
      row.alignableSource?.spotlightRecommendationGivenByCompany,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          spotlightRecommendationGivenByCompany: value,
        },
      };
    },
  },
  {
    field: "alignable-idealCustomerDescription",
    headerName: "Alignable Source Ideal Customer Description",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.alignableSource?.idealCustomerDescription,
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          idealCustomerDescription: value,
        },
      };
    },
  },
  {
    field: "alignable-recommendationsGivenTo",
    headerName: "Alignable Source Recommendations Given To",
    editable: true,
    width: 250,
    valueGetter: (value, row) =>
      row.alignableSource?.recommendationsGivenTo?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          recommendationsGivenTo: value?.split(", "),
        },
      };
    },
  },
  {
    field: "alignable-recommendationsReceived",
    headerName: "Alignable Source Recommendations Received",
    editable: true,
    width: 250,
    valueGetter: (value, row) =>
      row.alignableSource?.recommendationsReceived?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        alignableSource: {
          ...row.alignableSource,
          recommendationsReceived: value?.split(", "),
        },
      };
    },
  },
  {
    field: "alignable-outboundSectors",
    headerName: "Alignable Source Outbound Sectors",
    editable: true,
    width: 200,
    renderCell: params =>
      params.row.alignableSource?.outboundSectors?.join(", "),
  },
  {
    field: "alignable-inboundSectors",
    headerName: "Alignable Source Inbound Sectors",
    editable: true,
    width: 200,
    renderCell: params =>
      params.row.alignableSource?.inboundSectors?.join(", "),
  },
  {
    field: "alignable-createdAt",
    headerName: "Alignable Source Created At",
    type: "date",
    width: 200,
    renderCell: params => formatDate(params.row.alignableSource?.createdAt),
  },
];

export const websiteSourceFields: GridColDef[] = [
  {
    field: "websiteSource-id",
    headerName: "Website Source Website Source ID",
    type: "number",
    filterOperators: getGridNumericOperatorsDataGrid,
    valueFormatter: value => value && `${value?.toString()}`,
    width: 100,
    renderCell: params => params.row.websiteSource?.id?.toString(),
  },
  {
    field: "websiteSource-description",
    headerName: "Website Source Website Source Description",
    editable: true,
    width: 200,
    renderCell: params => params.row.websiteSource?.description,
    valueGetter: (value, row) => row.websiteSource?.description,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          description: value,
        },
      };
    },
  },
  {
    field: "websiteSource-phone",
    headerName: "Website Source Phone",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.websiteSource?.phone,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          phone: value,
        },
      };
    },
  },
  {
    field: "websiteSource-email",
    headerName: "Website Source Email",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.email,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          email: value,
        },
      };
    },
  },
  {
    field: "websiteSource-territoryZipCodes",
    headerName: "Website Source Territory Zip Codes",
    editable: true,
    width: 250,
    valueGetter: (value, row) =>
      row.websiteSource?.territoryZipCodes?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          territoryZipCodes: value?.split(", "),
        },
      };
    },
  },
  {
    field: "websiteSource-websiteUrl",
    headerName: "Website Source Website URL",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.websiteSource?.websiteUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          websiteUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-logoUrl",
    headerName: "Website Source Logo URL",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.logoUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          logoUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-street",
    headerName: "Website Source Street",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.websiteSource?.description,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.alignableSource,
          description: value,
        },
      };
    },
  },
  {
    field: "websiteSource-city",
    headerName: "Website Source City",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.websiteSource?.city,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          city: value,
        },
      };
    },
  },
  {
    field: "websiteSource-state",
    headerName: "Website Source State",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.websiteSource?.state,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          state: value,
        },
      };
    },
  },
  {
    field: "websiteSource-zipCode",
    headerName: "Website Source Zip Code",
    editable: true,
    width: 150,
    renderCell: params => params.row.websiteSource?.zipCode,
    valueGetter: (value, row) => row.websiteSource?.zipCode,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          zipCode: value,
        },
      };
    },
  },
  {
    field: "websiteSource-country",
    headerName: "Website Source Country",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.websiteSource?.country,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          country: value,
        },
      };
    },
  },
  {
    field: "websiteSource-linkedinUrl",
    headerName: "Website Source LinkedIn URL",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.linkedinUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          linkedinUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-houzzUrl",
    headerName: "Website Source Houzz URL",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.houzzUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          houzzUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-pinterestUrl",
    headerName: "Website Source Pinterest URL",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.pinterestUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          pinterestUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-tiktokUrl",
    headerName: "Website Source TikTok URL",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.tiktokUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          tiktokUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-facebookUrl",
    headerName: "Website Source Facebook URL",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.facebookUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          facebookUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-twitterUrl",
    headerName: "Website Source Twitter URL",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.twitterUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          twitterUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-youtubeUrl",
    headerName: "Website Source YouTube URL",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.youtubeUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          youtubeUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-license",
    headerName: "Website Source License",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.websiteSource?.license,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          license: value,
        },
      };
    },
  },
  {
    field: "websiteSource-requestServiceUrl",
    headerName: "Website Source Request Service URL",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.websiteSource?.requestServiceUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          requestServiceUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-awardsLogosUrls",
    headerName: "Website Source Awards Logos URLs",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.websiteSource?.awardsLogosUrls?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          awardsLogosUrls: value?.split(", "),
        },
      };
    },
  },
  {
    field: "websiteSource-teamWebsiteUrl",
    headerName: "Website Source Team Website URL",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.websiteSource?.teamWebsiteUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          teamWebsiteUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-services",
    headerName: "Website Source Services",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.services?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          services: value?.split(", "),
        },
      };
    },
  },
  {
    field: "websiteSource-areas",
    headerName: "Website Source Areas",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.areas?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          areas: value?.split(", "),
        },
      };
    },
  },
  {
    field: "websiteSource-majorBrands",
    headerName: "Website Source Major Brands",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.websiteSource?.majorBrands?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          majorBrands: value?.split(", "),
        },
      };
    },
  },
  {
    field: "websiteSource-partnerLogosUrls",
    headerName: "Website Source Partner Logos URLs",
    editable: true,
    width: 250,
    valueGetter: (value, row) =>
      row.websiteSource?.partnerLogosUrls?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          partnerLogosUrls: value?.split(", "),
        },
      };
    },
  },
  {
    field: "websiteSource-sisterBrandLogosUrls",
    headerName: "Website Source Sister Brand Logos URLs",
    editable: true,
    width: 250,
    valueGetter: (value, row) =>
      row.websiteSource?.sisterBrandLogosUrls?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          sisterBrandLogosUrls: value?.split(", "),
        },
      };
    },
  },
  {
    field: "websiteSource-googlePlacesUrl",
    headerName: "Website Source Google Places URL",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.websiteSource?.descrigooglePlacesUrlption,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          googlePlacesUrl: value,
        },
      };
    },
  },
  {
    field: "websiteSource-googlePlacesId",
    headerName: "Website Source Google Places ID",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.websiteSource?.googlePlacesId,
    valueSetter: (value, row) => {
      return {
        ...row,
        websiteSource: {
          ...row.websiteSource,
          googlePlacesId: value,
        },
      };
    },
  },
  {
    field: "websiteSource-createdAt",
    headerName: "Website Source Created At",
    type: "date",

    width: 200,
    renderCell: params => formatDate(params.row.websiteSource?.createdAt),
  },
  {
    field: "websiteSource-dateScraped",
    headerName: "Website Source Date Scraped",
    editable: true,
    width: 200,
    renderCell: params => params.row.websiteSource?.dateScraped,
  },
];

export const bniSourceFields: GridColDef[] = [
  {
    field: "bni-id",
    headerName: "BNI Source ID",
    type: "number",
    filterOperators: getGridNumericOperatorsDataGrid,
    renderCell: params => params.row.bniSource?.id.toString(),
    valueFormatter: value => value && `${value?.toString()}`,
    width: 100,
  },
  {
    field: "bni-description",
    headerName: "BNI Source Description",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.bniSource?.description,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          description: value,
        },
      };
    },
  },
  {
    field: "bni-representativeName",
    headerName: "BNI Source Representative Name",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.bniSource?.representativeName,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          representativeName: value,
        },
      };
    },
  },
  {
    field: "bni-phone",
    headerName: "BNI Source Phone",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.bniSource?.phone,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          phone: value,
        },
      };
    },
  },
  {
    field: "bni-street",
    headerName: "BNI Source Street",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.bniSource?.street,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          street: value,
        },
      };
    },
  },
  {
    field: "bni-city",
    headerName: "BNI Source City",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.bniSource?.city,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          city: value,
        },
      };
    },
  },
  {
    field: "bni-state",
    headerName: "BNI Source State",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.bniSource?.state,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          state: value,
        },
      };
    },
  },
  {
    field: "bni-zipCode",
    headerName: "BNI Source Zip Code",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.bniSource?.zipCode,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          zipCode: value,
        },
      };
    },
  },
  {
    field: "bni-country",
    headerName: "BNI Source Country",
    editable: true,
    width: 150,
    valueGetter: (value, row) => row.bniSource?.country,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          country: value,
        },
      };
    },
  },
  {
    field: "bni-chapter",
    headerName: "BNI Source Chapter",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.bniSource?.chapter,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          chapter: value,
        },
      };
    },
  },
  {
    field: "bni-chapterLocation",
    headerName: "BNI Source Chapter Location",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.bniSource?.chapterLocation,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          chapterLocation: value,
        },
      };
    },
  },
  {
    field: "bni-industryPrimary",
    headerName: "BNI Source Industry Primary",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.bniSource?.industryPrimary,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          industryPrimary: value,
        },
      };
    },
  },
  {
    field: "bni-industrySecondary",
    headerName: "BNI Source Industry Secondary",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.bniSource?.industrySecondary,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          industrySecondary: value,
        },
      };
    },
  },
  {
    field: "bni-industryTe",
    headerName: "BNI Source Industry TE",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.bniSource?.industryTe,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          industryTe: value,
        },
      };
    },
  },
  {
    field: "bni-myBusinessDescription",
    headerName: "BNI Source My Business Description",
    editable: true,
    width: 300,
    valueGetter: (value, row) => row.bniSource?.myBusinessDescription,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          myBusinessDescription: value,
        },
      };
    },
  },
  {
    field: "bni-idealReferral",
    headerName: "BNI Source Ideal Referral",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.bniSource?.idealReferral,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          idealReferral: value,
        },
      };
    },
  },
  {
    field: "bni-idealReferralPartner",
    headerName: "BNI Source Ideal Referral Partner",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.bniSource?.idealReferralPartner,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          idealReferralPartner: value,
        },
      };
    },
  },
  {
    field: "bni-topProblemSolved",
    headerName: "BNI Source Top Problem Solved",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.bniSource?.topProblemSolved,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          topProblemSolved: value,
        },
      };
    },
  },
  {
    field: "bni-representativeProfileUrl",
    headerName: "BNI Source Representative Profile URL",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.bniSource?.representativeProfileUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          representativeProfileUrl: value,
        },
      };
    },
  },
  {
    field: "bni-website",
    headerName: "BNI Source Website",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.bniSource?.website,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          website: value,
        },
      };
    },
  },
  {
    field: "bni-topProduct",
    headerName: "BNI Source Top Product",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.bniSource?.topProduct,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          topProduct: value,
        },
      };
    },
  },
  {
    field: "bni-logoUrl",
    headerName: "BNI Source Logo URL",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.bniSource?.logoUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          logoUrl: value,
        },
      };
    },
  },
  {
    field: "bni-dateScraped",
    headerName: "BNI Source Date Scraped",
    editable: true,
    width: 200,
    renderCell: params => params.row.bniSource?.dateScraped,
  },
  {
    field: "bni-googlePlacesId",
    headerName: "BNI Source Google Places ID",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.bniSource?.googlePlacesId,
    valueSetter: (value, row) => {
      return {
        ...row,
        bniSource: {
          ...row.bniSource,
          googlePlacesId: value,
        },
      };
    },
  },
  {
    field: "bni-createdAt",
    headerName: "BNI Source Created At",
    type: "date",
    width: 250,
    filterOperators: getGridDateOperatorsDataGrid,
    renderCell: params => formatDate(params.row.bniSource?.createdAt),
  },
];

export const serpSourceFields: GridColDef[] = [
  {
    field: "serp-id",
    headerName: "Serp Source Id",
    type: "number",

    filterOperators: getGridNumericOperatorsDataGrid,
    width: 200,
    renderCell: params => params.row.serpSource?.serpId,
  },
  {
    field: "serp-googlePlacesId",
    headerName: "Serp Source Google Places Id",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.serpSource?.googlePlacesId,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          googlePlacesId: value,
        },
      };
    },
  },
  {
    field: "serp-gpsLatitude",
    headerName: "Serp Source GPS Latitude",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.serpSource?.gpsLatitude,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          gpsLatitude: value,
        },
      };
    },
  },
  {
    field: "serp-gpsLongitude",
    headerName: "Serp Source GPS Longitude",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.serpSource?.gpsLongitude,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          gpsLongitude: value,
        },
      };
    },
  },
  {
    field: "serp-googleMapsUrl",
    headerName: "Serp Source Google Maps Url",
    editable: true,
    width: 300,
    valueGetter: (value, row) => row.serpSource?.googleMapsUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          googleMapsUrl: value,
        },
      };
    },
  },
  {
    field: "serp-name",
    headerName: "Serp Source Name",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.serpSource?.name,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          name: value,
        },
      };
    },
  },
  {
    field: "serp-dataId",
    headerName: "Serp Source Data Id",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.serpSource?.dataId,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          dataId: value,
        },
      };
    },
  },
  {
    field: "serp-dataCid",
    headerName: "Serp Source Data Cid",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.serpSource?.dataCid,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          dataCid: value,
        },
      };
    },
  },
  {
    field: "serp-googleReviewsUrl",
    headerName: "Serp Source Google Reviews Url",
    editable: true,
    width: 300,
    valueGetter: (value, row) => row.serpSource?.googleReviewsUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          googleReviewsUrl: value,
        },
      };
    },
  },
  {
    field: "serp-googlePhotosUrl",
    headerName: "Serp Source Google Photos Url",
    editable: true,
    width: 300,
    valueGetter: (value, row) => row.serpSource?.googlePhotosUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          googlePhotosUrl: value,
        },
      };
    },
  },
  {
    field: "serp-googleReviewsRating",
    headerName: "Google Reviews Rating",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.serpSource?.googleReviewsRating,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          googleReviewsRating: value,
        },
      };
    },
  },
  {
    field: "serp-googleReviewsCount",
    headerName: "Serp Source Google Reviews Count",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.serpSource?.googleReviewsCount,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          googleReviewsCount: value,
        },
      };
    },
  },
  {
    field: "serp-website",
    headerName: "Serp Source Website",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.serpSource?.website,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          website: value,
        },
      };
    },
  },
  {
    field: "serp-thumbnail",
    headerName: "Serp Source Thumbnail",
    editable: true,
    width: 250,
    valueGetter: (value, row) => row.serpSource?.thumbnail,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          thumbnail: value,
        },
      };
    },
  },
  {
    field: "serp-phone",
    headerName: "Serp Source Phone",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.serpSource?.phone,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          phone: value,
        },
      };
    },
  },
  {
    field: "serp-address",
    headerName: "Serp Source Address",
    editable: true,
    width: 300,
    valueGetter: (value, row) => row.serpSource?.address,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          address: value,
        },
      };
    },
  },
  {
    field: "serp-types",
    headerName: "Serp Source Types",
    editable: true,
    width: 200,
    renderCell: params => params.row.serpSource?.types?.join(", "),
    valueGetter: (value, row) => row.serpSource?.types?.join(", "),
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          types: value?.split(", "),
        },
      };
    },
  },
  {
    field: "serp-yearsInBusiness",
    headerName: "Serp Source Years in Business",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.serpSource?.yearsInBusiness,
    valueSetter: (value, row) => {
      return {
        ...row,
        serpSource: {
          ...row.serpSource,
          yearsInBusiness: value,
        },
      };
    },
  },
  {
    field: "serp-createdAt",
    headerName: "Serp Source Created At",
    type: "date",
    width: 250,
    filterOperators: getGridDateOperatorsDataGrid,
    renderCell: params => formatDate(params.row.serpSource?.createdAt),
  },
  {
    field: "serp-dateScraped",
    headerName: "Serp Source Date Scraped",
    type: "date",
    width: 250,
    filterOperators: getGridDateOperatorsDataGrid,
    renderCell: params => formatDate(params.row.serpSource?.createdAt),
  },
];

export const fddSourceFields: GridColDef[] = [
  {
    field: "fdd-city",
    headerName: "FDD Source City",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.fddSource?.city,
    valueSetter: (value, row) => {
      return {
        ...row,
        fddSource: {
          ...row.fddSource,
          city: value,
        },
      };
    },
  },
  {
    field: "fdd-country",
    headerName: "FDD Source Country",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.fddSource?.country,
    valueSetter: (value, row) => {
      return {
        ...row,
        fddSource: {
          ...row.fddSource,
          country: value,
        },
      };
    },
  },
  {
    field: "fdd-createdAt",
    headerName: "FDD Created At",
    type: "date",
    filterOperators: getGridDateOperatorsDataGrid,
    renderCell: params => {
      return formatDate(params.row.fddSource?.createdAt);
    },
    width: 200,
  },
  {
    field: "fdd-dateFdd",
    headerName: "FDD Date Scraped",
    width: 200,
    type: "date",
    filterOperators: getGridDateOperatorsDataGrid,
    renderCell: params => {
      return formatDate(params.row.fddSource?.dateFdd);
    },
  },
  {
    field: "fdd-email",
    headerName: "FDD Source Email",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.fddSource?.email,
    valueSetter: (value, row) => {
      return {
        ...row,
        fddSource: {
          ...row.fddSource,
          email: value,
        },
      };
    },
  },
  {
    field: "fdd-name",
    headerName: "FDD Source Company Name",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.fddSource?.name,
    valueSetter: (value, row) => {
      return {
        ...row,
        fddSource: {
          ...row.fddSource,
          name: value,
        },
      };
    },
  },
  {
    field: "fdd-phone",
    headerName: "FDD Source Phone",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.fddSource?.phone,
    valueSetter: (value, row) => {
      return {
        ...row,
        fddSource: {
          ...row.fddSource,
          phone: value,
        },
      };
    },
  },
  {
    field: "fdd-sourceFile",
    headerName: "FDD Source File",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.fddSource?.sourceFile,
    valueSetter: (value, row) => {
      return {
        ...row,
        fddSource: {
          ...row.fddSource,
          sourceFile: value,
        },
      };
    },
  },
  {
    field: "fdd-state",
    headerName: "FDD Source State",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.fddSource?.state,
    valueSetter: (value, row) => {
      return {
        ...row,
        fddSource: {
          ...row.fddSource,
          state: value,
        },
      };
    },
  },
  {
    field: "fdd-street",
    headerName: "FDD Source Street",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.fddSource?.street,
    valueSetter: (value, row) => {
      return {
        ...row,
        fddSource: {
          ...row.fddSource,
          street: value,
        },
      };
    },
  },
  {
    field: "fdd-territoryName",
    headerName: "FDD Source Territory",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.fddSource?.territoryName,
    valueSetter: (value, row) => {
      return {
        ...row,
        fddSource: {
          ...row.fddSource,
          territoryName: value,
        },
      };
    },
  },

  {
    field: "fdd-zipCode",
    headerName: "FDD Source Zip Code",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.fddSource?.zipCode,
    valueSetter: (value, row) => {
      return {
        ...row,
        fddSource: {
          ...row.fddSource,
          zipCode: value,
        },
      };
    },
  },
];

export const profileFields: GridColDef[] = [
  {
    field: "profile-id",
    headerName: "Profile Id",
    width: 200,
    type: "number",
    filterOperators: getGridNumericOperatorsDataGrid,
    renderCell: params => params.row.profile?.id.toString(),
    valueFormatter: value => value && `${value?.toString()}`,
    headerAlign: "left",
    valueGetter: (value, row) => row.profile?.id,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          id: value,
        },
      };
    },
  },
  {
    field: "profile-googlePlacesId",
    headerName: "Google Places Id",
    width: 200,
    editable: true,
    valueGetter: (value, row) => row.profile?.googlePlacesId,
    valueFormatter: value => value && `${value?.toString()}`,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          googlePlacesId: value,
        },
      };
    },
  },
  {
    field: "profile-createdAt",
    headerName: "Date Profile Created",
    type: "date",
    filterOperators: getGridDateOperatorsDataGrid,
    renderCell: params => {
      return formatDate(params.row.profile?.createdAt);
    },
  },
  {
    field: "profile-deletedAt",
    headerName: "Profile Deleted At",
    type: "date",
    width: 200,
    filterOperators: getGridDateOperatorsDataGrid,
    renderCell: params => {
      return formatDate(params.row.profile?.deletedAt);
    },
  },
  {
    field: "profile-businessEmail",
    headerName: "Business Email",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.profile?.businessEmail,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          businessEmail: value,
        },
      };
    },
  },
  {
    field: "profile-businessPhone",
    headerName: "Business Phone",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.profile?.businessPhone,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          businessPhone: value,
        },
      };
    },
  },
  {
    field: "profile-inboundSectors",
    headerName: "Inbound Sectors",
    editable: true,
    type: "string",
    width: 200,
    renderEditCell: renderJobSectorsArea,
    valueFormatter: value => `${value}`,
    //valueOptions: Object.keys(reverseSnakeCaseJobSectors),
    renderCell: params => {
      const sectorChips = params.row?.["profile-inboundSectors"]?.map(
        sector => (
          <Chip
            key={sector}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={sector}
            size="small"
          />
        )
      );
      return sectorChips;
    },
  },
  {
    field: "profile-outboundSectors",
    headerName: "Outbound Sectors",
    editable: true,
    type: "string",
    width: 200,
    renderEditCell: renderJobSectorsArea,
    valueFormatter: value => `${value}`,
    //valueOptions: Object.keys(reverseSnakeCaseJobSectors),
    renderCell: params => {
      const sectorChips = params.row?.["profile-outboundSectors"]?.map(
        sector => (
          <Chip
            key={sector}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={sector}
            size="small"
          />
        )
      );
      return sectorChips;
    },
  },
  {
    field: "profile-brandCategory",
    headerName: "Brand Category",
    type: "singleSelect",
    editable: true,
    width: 200,
    valueGetter: (value, row) => row.profile?.brandCategory,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          brandCategory: value,
        },
      };
    },
    valueOptions: [BRAND_CATEGORY.FRANCHISE, BRAND_CATEGORY.INDEPENDENT],
  },
  {
    field: "profile-defaultTechnicianSplit",
    headerName: "Technician Split",
    editable: true,
    width: 200,
    type: "number",
    filterOperators: getGridNumericOperatorsDataGrid,
    headerAlign: "left",
    valueGetter: (value, row) => row.profile?.defaultTechnicianSplit,
    valueFormatter: value => `${value}%`,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          defaultTechnicianSplit: value,
        },
      };
    },
  },
  {
    field: "profile-openNetworkEnabled",
    editable: true,
    width: 200,
    type: "boolean",
    headerAlign: "left",
    headerName: "Open Network Enabled",
    valueGetter: (value, row) => row.profile?.openNetworkEnabled,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          openNetworkEnabled: value,
        },
      };
    },
  },
  {
    field: "profile-alignableUrl",
    headerName: "Alignable Url",
    valueGetter: (value, row) => row.profile?.alignableUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          alignableUrl: value,
        },
      };
    },
    editable: true,
    width: 200,
  },
  {
    field: "profile-bniUrl",
    headerName: "BNI Url",
    valueGetter: (value, row) => row.profile?.bniUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          bniUrl: value,
        },
      };
    },
    editable: true,
    width: 200,
  },
  {
    field: "profile-businessPhoneCarrierType",
    headerName: "Business Phone Carrier Type",
    valueGetter: (value, row) => row.profile?.businessPhoneCarrierType,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          businessPhoneCarrierType: value,
        },
      };
    },
    editable: true,
    width: 200,
  },
  {
    field: "profile-hasTerritory",
    headerName: "Has Territory",
    type: "boolean",
    valueGetter: (value, row) => row.profile?.hasTerritory,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          hasTerritory: value,
        },
      };
    },
  },
  {
    field: "profile-googleReviewLink",
    headerName: "Google Review Link",
    editable: true,
    valueGetter: (value, row) => row.profile?.googleReviewLink,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          googleReviewLink: value,
        },
      };
    },
  },
  {
    field: "profile-legalEntityName",
    valueGetter: (value, row) => row.profile?.legalEntityName,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          legalEntityName: value,
        },
      };
    },
    headerName: "Legal Entity Name",
    editable: true,
    width: 200,
  },
  {
    field: "profile-facebookUrl",
    valueGetter: (value, row) => row.profile?.facebookUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          facebookUrl: value,
        },
      };
    },
    headerName: "Facebook Url",
    editable: true,
    width: 200,
  },
  {
    field: "profile-instagramUrl",
    headerName: "Instagram Url",
    valueGetter: (value, row) => row.profile?.instagramUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          instagramUrl: value,
        },
      };
    },
    editable: true,
    width: 200,
  },
  {
    field: "profile-operatingSystem",
    headerName: "Operating System",
    valueGetter: (value, row) => row.profile?.operatingSystem,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          operatingSystem: value,
        },
      };
    },
    editable: true,
    width: 200,
  },
  {
    field: "profile-linkedinUrl",
    headerName: "LinkedIn Url",
    valueGetter: (value, row) => row.profile?.linkedinUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          linkedinUrl: value,
        },
      };
    },
    editable: true,
    width: 200,
  },
  {
    field: "profile-payReferralFeeAfterJobIsDone",
    valueGetter: (value, row) => row.profile?.payReferralFeeAfterJobIsDone,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          payReferralFeeAfterJobIsDone: value,
        },
      };
    },
    headerName: "Pay Referral Fee After Job is Done",
    editable: true,
    width: 200,
    type: "boolean",
  },
  {
    field: "profile-payPerReferral",
    valueGetter: (value, row) => row.profile?.payPerReferral,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          payPerReferral: value,
        },
      };
    },
    headerName: "Pay Per Referral",
    editable: true,
    width: 200,
    type: "boolean",
  },
  {
    field: "profile-payReferralFeeSettings",
    valueGetter: (value, row) => row.profile?.payReferralFeeSettings,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          payReferralFeeSettings: value,
        },
      };
    },
    headerName: "Pay Referral Fee Settings",
    editable: true,
    width: 200,
    type: "singleSelect",
    valueOptions: [...Object.keys(PayReferralFeeSettings)],
  },
  {
    field: "profile-showExplorePartners",
    valueGetter: (value, row) => row.profile?.showExplorePartners,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          showExplorePartners: value,
        },
      };
    },
    headerName: "Show Explore Partners",
    editable: true,
    width: 200,
    type: "boolean",
  },
  {
    field: "profile-invitationMessage",
    valueGetter: (value, row) => row.profile?.invitationMessage,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          invitationMessage: value,
        },
      };
    },
    headerName: "Invitation Message",
    editable: true,
    width: 200,
  },
  {
    field: "profile-homeownerJobApprovalSettings",
    valueGetter: (value, row) => row.profile?.homeownerJobApprovalSettings,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          homeownerJobApprovalSettings: value,
        },
      };
    },
    headerName: "Homeowner Job Approval Settings",
    editable: true,
    width: 200,
    type: "singleSelect",
    valueOptions: [...Object.keys(HOMEOWNER_APPROVAL_SETTING)],
  },
  {
    field: "profile-callAnalyticsEnabled",
    valueGetter: (value, row) => row.profile?.callAnalyticsEnabled,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          callAnalyticsEnabled: value,
        },
      };
    },
    headerName: "Call Analytics Enabled",
    editable: true,
    width: 200,
    type: "boolean",
  },
  {
    field: "profile-showJobAnalytics",
    valueGetter: (value, row) => row.profile?.showJobAnalytics,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          showJobAnalytics: value,
        },
      };
    },
    headerName: "Show Job Analytics",
    editable: true,
    width: 200,
    type: "boolean",
  },
  {
    field: "profile-startedOnboardingAt",
    renderCell: params => {
      return formatDate(params.row.profile?.startedOnboardingAt);
    },
    headerName: "Date Onboarding Started",
    width: 200,
    valueFormatter: formatDate,
    filterOperators: getGridDateOperatorsDataGrid,
    type: "date",
    valueGetter: (value, row) => row.profile?.startedOnboardingAt,
    description:
      "The date the onboarding process was started, after clicking next on the first onboarding screen..",
  },
  {
    field: "profile-onboardingCompletedAt",
    renderCell: params => {
      return formatDate(params.row.profile?.onboardingCompletedAt);
    },
    headerName: "Date Onboarding Completed",
    type: "date",
    valueFormatter: formatDate,
    filterOperators: getGridDateOperatorsDataGrid,
    width: 200,
    valueGetter: (value, row) => row.profile?.onboardingCompletedAt,
    description:
      "The date the onboarding process was completed, the actual onboarding screens.",
  },
  {
    field: "profile-schedulingUrl",
    valueGetter: (value, row) => row.profile?.schedulingUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          schedulingUrl: value,
        },
      };
    },
    headerName: "Scheduling URL",
    editable: true,
    width: 200,
  },
  {
    field: "profile-signupUtmCampaign",
    headerName: "Signup Utm Campaign",
    valueGetter: (value, row) => row.profile?.signupUtmCampaign,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          signupUtmCampaign: value,
        },
      };
    },
    editable: true,
    width: 200,
  },
  {
    field: "profile-signupUtmMedium",
    headerName: "Signup Utm Medium",
    valueGetter: (value, row) => row.profile?.signupUtmMedium,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          signupUtmMedium: value,
        },
      };
    },
    editable: true,
    width: 200,
  },
  {
    field: "profile-signupUtmSource",
    headerName: "Signup Utm Source",
    valueGetter: (value, row) => row.profile?.signupUtmSource,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          signupUtmSource: value,
        },
      };
    },
    editable: true,
    width: 200,
  },
  {
    field: "profile-twitterUrl",
    headerName: "Twitter Url",
    valueGetter: (value, row) => row.profile?.twitterUrl,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          twitterUrl: value,
        },
      };
    },
    editable: true,
    width: 200,
  },
  {
    field: "profile-updatedAt",
    headerName: "Date Profile Updated",
    type: "date",
    filterOperators: getGridDateOperatorsDataGrid,
    renderCell: params => {
      return formatDate(params.row.profile?.updatedAt);
    },
    width: 200,
  },
  {
    field: "profile-fieldServiceApp",
    headerName: "Field Service App",
    width: 200,
    editable: true,
    type: "string",
    valueGetter: (value, row) => row.profile?.fieldServiceApp,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          fieldServiceApp: value,
        },
      };
    },
  },
  {
    field: "profile-weeklyMeetingDay",
    headerName: "Weekly Meeting Day",
    width: 200,
    editable: true,
    type: "singleSelect",
    valueOptions: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    valueGetter: (value, row) => row.profile?.weeklyMeetingDay,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          weeklyMeetingDay: value,
        },
      };
    },
  },
  {
    field: "profile-weeklyMeetingTime",
    headerName: "Weekly Meeting Time",
    width: 200,
    editable: true,
    type: "dateTime",
    filterOperators: getGridDateOperators(true).map(item => ({
      ...item,
      InputComponent: EditTimePickerFilter,
    })),
    renderEditCell: params => <EditTimePicker {...params} />,
    valueFormatter: value => {
      if (!value) return "";
      return DateTime.fromFormat(value, "HH:mm:ss").toFormat("hh:mm a");
    },
    valueGetter: (value, row) => row.profile?.weeklyMeetingTime,
    valueSetter: (value, row) => {
      return {
        ...row,
        profile: {
          ...row.profile,
          weeklyMeetingTime: value,
        },
      };
    },
  },
];

function DebouncedInputComponent({ item, applyValue }) {
  const [value, setValue] = useState(item.value || "");

  const debouncedApplyValue = useMemo(
    () => debounce(value => applyValue({ ...item, value }), 500),
    [applyValue, item]
  );

  const handleChange = event => {
    const newValue = event.target.value;
    setValue(newValue);
    debouncedApplyValue(newValue);
  };

  return (
    <TextField
      variant="standard"
      value={value}
      placeholder="Filter value"
      label="Value"
      InputLabelProps={{
        shrink: true, // Always shrink the label
      }}
      InputProps={{
        style: { padding: "0", fontSize: "0.875rem" },
      }}
      onChange={handleChange}
    />
  );
}

export const drawerWidth = 600;

export const customIsNotOperator = {
  label: "is not",
  value: "isNot",
  getApplyFilterFn: filterItem => {
    if (!filterItem.value) {
      return null;
    }
    return (value: string) =>
      value?.toString().trim().toLowerCase() !=
      filterItem.value.trim().toLowerCase();
  },
  InputComponent: DebouncedInputComponent,
};

export const getGridStringOperatorsDataGrid: GridFilterOperator<
  any,
  string,
  any
>[] = [...getGridStringOperators(), customIsNotOperator];

export const Main = styled("main", {
  shouldForwardProp: prop => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: "relative",
}));

export const CustomToolbar = ({
  fileName,
  includeQuickFilter = true,
}: {
  fileName?: string;
  includeQuickFilter?: boolean;
}) => {
  return (
    <Box width={"100%"}>
      <GridToolbarContainer
        sx={{
          ...(includeQuickFilter
            ? { justifyContent: { lg: "space-between", xs: "start" } }
            : { justifyContent: "end" }),
        }}
      >
        {includeQuickFilter ? (
          <Box sx={{ width: { xs: "0", lg: "50%" } }}>
            <GridToolbarQuickFilter />
          </Box>
        ) : null}
        <Box display={"flex"} gap={1}>
          <GridToolbarColumnsButton
            slotProps={{
              button: { sx: { color: "#384250" }, variant: "outlined" },
            }}
          />
          <GridToolbarDensitySelector
            slotProps={{
              button: { sx: { color: "#384250" }, variant: "outlined" },
            }}
          />
          <GridToolbarExport
            printOptions={{ disableToolbarButton: true }}
            excelOptions={{ disableToolbarButton: true }}
            csvOptions={{
              fileName: `TradeEngage_${
                fileName ?? ""
              }_${DateTime.now().toFormat("M-dd-yyyy-hh-mm-a")}`,
            }}
            slotProps={{
              button: { sx: { color: "#384250" }, variant: "outlined" },
            }}
          />
        </Box>
      </GridToolbarContainer>
    </Box>
  );
};
