import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { Job } from "@/company/jobs/types";
import { HomeownerAction } from "@/homeowner/api/useHomeOwnerActOnJob";

export interface HomeownerJobDeclineDialogProps {
  job: Job;
  open: boolean;
  isUpdating?: boolean;
  onClose: () => void;
  actOnJob: (data: HomeownerAction) => void;
  isMobile?: boolean;
}

export function HomeownerJobDeclineDialog(
  props: HomeownerJobDeclineDialogProps
) {
  const { open, onClose, actOnJob, job, isUpdating } = props;
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");

  const reset = () => {
    setReason("");
    setOtherReason("");
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <DialogTitle component="div">
        <Typography gutterBottom variant="h5" color="error">
          Decline Project?
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => {
          reset();
          onClose();
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pt: 0, px: 3 }}>
        <Typography gutterBottom variant="body2">
          Are you sure you want to decline the <strong>{job.jobType}</strong>{" "}
          project? You can always delay this project and reference it later from
          your HomeHub.
        </Typography>
        <Typography variant="body2" sx={{ my: 2 }}>
          Select the reason why you declined this project.
        </Typography>
        <FormControl disabled={isUpdating}>
          <RadioGroup
            value={reason}
            onChange={e => {
              setOtherReason("");
              setReason(e.target.value);
            }}
          >
            <FormControlLabel
              value="This is a low priority project or I am not interested in completing it right now."
              control={<Radio />}
              label={
                <Typography variant={props.isMobile ? "caption" : "body2"}>
                  This is a low priority project or I am not interested in
                  completing it right now.
                </Typography>
              }
            />
            <FormControlLabel
              value="I don't have the budget for this project right now."
              control={<Radio />}
              label={
                <Typography variant={props.isMobile ? "caption" : "body2"}>
                  I don&apos;t have the budget for this project right now.
                </Typography>
              }
            />
            <FormControlLabel
              value="I don't need a referral and I have a service provider that I prefer."
              control={<Radio />}
              label={
                <Typography variant={props.isMobile ? "caption" : "body2"}>
                  I don&apos;t need a referral and I have a service provider
                  that I prefer.
                </Typography>
              }
            />
            <FormControlLabel
              value="Other"
              control={<Radio />}
              label={
                <Typography variant={props.isMobile ? "caption" : "body2"}>
                  Other
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
        {reason === "Other" ? (
          <TextareaAutosize
            style={{
              marginTop: "16px",
              marginBottom: "16px",
              width: "100%",
              padding: "8px",
              borderRadius: "8px",
              borderColor: "#E5E7EB",
              fontSize: "0.875rem",
              fontWeight: 400,
              lineHeight: 1.57,
              fontFamily:
                '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
              color: " #6C737F",
            }}
            value={otherReason}
            minRows={5}
            placeholder="🗒️ Please explain"
            onChange={e => setOtherReason(e.target.value)}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        {!isUpdating && (
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            No, Cancel
          </Button>
        )}
        <LoadingButton
          loading={isUpdating}
          size="small"
          variant="contained"
          color="error"
          disabled={
            (reason && reason === "Other" && otherReason === "") || !reason
          }
          onClick={() =>
            actOnJob({
              action: "DECLINE",
              declineReason:
                reason && reason === "Other" ? otherReason : reason,
            })
          }
        >
          Yes, Decline
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
