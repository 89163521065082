import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { DateTime } from "luxon";

import axiosInstance from "@/common/api";

const GET_JOB_ATTACHMENTS = "/jobs/:id/attachments";

export interface JobAttachment {
  id: number;
  name: string;
  presignedUrl: string;
  createdAt: DateTime;
  jobId: number;
  userId: number;
  userName: string;
  companyId: number;
  companyName: string;
}

export function useGetJobAttachments(
  jobId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<JobAttachment>> {
  const url = GET_JOB_ATTACHMENTS.replace(":id", jobId.toString());

  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      const response = await axiosInstance.get(url);
      return response.data.data;
    },
    ...options,
  });
}
