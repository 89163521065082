import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  debounce,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import zipcodes from "zipcodes";

import { AuthRoutes } from "@/auth/constants";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { JobSectors } from "@/company/jobs/constants";
import {
  useQueryBrandLocationById,
  useQuerySearchCompanies,
} from "@/company/onboarding/api";
import { COMPANY_ACCOUNT_TYPES, SearchCompanyProfile } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { useLeadContext } from "@/lead/context/lead-context";
import { PublicRoutes } from "@/open/constants";

const validationSchema = yup.object({
  name: yup.string().required("Company Name is required"),
  zipCode: yup
    .string()
    .required("Zip Code is required")
    .oneOf(Object.keys(zipcodes.codes), "Invalid U.S. zip code")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits"),
  sectors: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least one Service Category")
    .required("Sectors are required"),
});

interface FormValues {
  name: string;
  zipCode: string;
  sectors: Array<JobSectors>;
}

const initialFormValues: FormValues = {
  name: "",
  zipCode: "",
  sectors: [],
};

export function CompanyInfo({
  isReferralInvitation,
}: {
  isReferralInvitation: boolean;
}) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const invitationCode = searchParams.get("invitationCode");
  const campaignCode = searchParams.get("campaignCode");

  const invitedCompanyId = searchParams.get("invitedCompanyId");

  const utmCampaign = searchParams.get("utm_campaign") ?? undefined;
  const utmSource = searchParams.get("utm_source") ?? undefined;
  const utmMedium = searchParams.get("utm_medium") ?? undefined;

  const [filterKeyword, setFilterKeyword] = useState<string>("");

  const debouncedSetter = useMemo(
    () => debounce((keyword: string) => setFilterKeyword(keyword), 500),
    []
  );

  const { data, isFetching: loading } = useQuerySearchCompanies(
    filterKeyword,
    !!filterKeyword
  );

  const { updateLeadDetails } = useLeadContext();

  const handleDemoClick = async () => {
    const selectedCompany = data?.find(
      (record: SearchCompanyProfile) => record.name === formik.values.name
    );

    const url =
      "https://calendly.com/tradeengage-chelsea/book-tradeengage-demo";
    window.open(url, "_blank");
  };

  const { data: invitedCompanyProfile, isFetching } = useQueryBrandLocationById(
    { id: invitedCompanyId },
    { enabled: !!invitedCompanyId }
  );

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: async values => {
      const selectedCompany = data?.find(
        (record: SearchCompanyProfile) => record.name === values.name
      );
      await updateLeadDetails({
        id: selectedCompany?.id,
        zipCode: values.zipCode,
        name: values.name,
        sectors: values.sectors as Array<JobSectors>,
        utmCampaign,
        utmMedium,
        utmSource,
        ...(selectedCompany && selectedCompany.name
          ? { accountType: COMPANY_ACCOUNT_TYPES.BRAND_LOCATION }
          : {}),
      });
      if (selectedCompany) {
        navigate(
          `${getNavigationPathFromRoutePaths([
            PublicRoutes.BASE,
            PublicRoutes.FDD_COMPANY_IDENTIFIED,
          ])}${invitationCode || campaignCode ? "?" : ""}${
            invitationCode ? `&invitationCode=${invitationCode}` : ""
          }${campaignCode ? `&campaignCode=${campaignCode}` : ""}`
        );
        return;
      }
      navigate(
        `${getNavigationPathFromRoutePaths([
          PublicRoutes.BASE,
          PublicRoutes.IDENTIFY_EXTERNAL_COMPANY,
        ])}?redirectTo=/${AuthRoutes.BASE}/${AuthRoutes.USER_TYPE}${
          invitationCode ? `&invitationCode=${invitationCode}` : ""
        }${campaignCode ? `&campaignCode=${campaignCode}` : ""}`
      );
    },
  });

  const setInitialCompanyValues = async (
    invitedCompanyProfile: SearchCompanyProfile
  ) => {
    if (invitedCompanyProfile) {
      await formik.setValues(
        {
          ["name"]: invitedCompanyProfile.name,
          ["sectors"]: invitedCompanyProfile?.sectors?.length
            ? (invitedCompanyProfile.sectors.map(
                sector => snakeCaseJobSectors[sector]
              ) as Array<JobSectors>)
            : [],
          zipCode: invitedCompanyProfile.zipCode,
        },
        true
      );
    }
  };

  useEffect(() => {
    if (invitedCompanyProfile) {
      setInitialCompanyValues(invitedCompanyProfile);
    }
  }, [invitedCompanyProfile]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        component={"form"}
        sx={{
          margin: isMobile ? "12px" : "20px",
          gap: 2,
          px: isMobile ? 2 : 4,
          pb: 4,
          maxWidth: "440px",
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            pb: 2,
            px: 0,
            alignItems: "center",
            alignSelf: "stretch",
          }}
          subheader={
            <Typography color={"text.secondary"} variant="body2" sx={{ pt: 1 }}>
              {isReferralInvitation
                ? "Enter your details to receive jobs from partners in your area."
                : "The TradeEngage community is by invitation only. Please join using your invitation link or schedule a demo here."}
            </Typography>
          }
          title={"Discover Home Service Referral Partners Waiting for You!"}
        />
        <Autocomplete
          freeSolo
          sx={{ width: "100%" }}
          value={formik.values.name}
          loading={loading}
          options={
            data?.map((option: SearchCompanyProfile) => option.name) ?? []
          }
          renderInput={params => (
            <TextField
              {...params}
              required
              id="name"
              name="name"
              label="Company Name"
              inputProps={{ "data-hj-allow": true, ...params?.inputProps }}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
            />
          )}
          onChange={async (
            _: SyntheticEvent,
            newValue: string | null | undefined
          ) => {
            const selectedCompany = data?.find(
              (record: SearchCompanyProfile) => record.name === newValue
            );
            if (selectedCompany?.zipCode) {
              await formik.setFieldValue(
                "zipCode",
                selectedCompany.zipCode,
                true
              );
            }
            if (selectedCompany?.sectors) {
              const newSectors = selectedCompany?.sectors
                ?.filter(sector =>
                  Object.keys(snakeCaseJobSectors).includes(sector)
                )
                ?.map(sector => snakeCaseJobSectors[sector] as JobSectors);

              await formik.setFieldValue("sectors", newSectors, true);
            }
            await formik.setFieldValue("name", newValue, true);
          }}
          onInputChange={(_: unknown, newInputValue: string) => {
            if (newInputValue.length >= 3) {
              debouncedSetter(newInputValue);
            }
          }}
          onBlur={formik.handleBlur}
        />
        <TextField
          required
          fullWidth
          type="number"
          id="zipCode"
          name="zipCode"
          margin="normal"
          label="Zip Code"
          inputMode="numeric"
          sx={{ my: 2, mx: 0 }}
          inputProps={{ pattern: "[0-9]*", "data-hj-allow": true }}
          value={formik.values.zipCode}
          error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
          helperText={formik.touched.zipCode && formik.errors.zipCode}
          onChange={event => {
            if (event.target.value?.length <= 5) {
              formik.setFieldValue("zipCode", event.target.value, true);
            }
          }}
          onBlur={formik.handleBlur}
        />
        <Autocomplete
          multiple
          id="sectors"
          sx={{ width: "100%" }}
          options={Object.keys(JobSectors).map(sector => {
            if (sector === JobSectors.HVAC) {
              return sector.toString();
            }
            return snakeCaseJobSectors[sector.toString() as JobSectors];
          })}
          getOptionLabel={option => option}
          value={formik.values.sectors}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{ "data-hj-allow": true, ...params?.inputProps }}
              helperText={formik.touched.sectors && formik.errors.sectors}
              error={formik.touched.sectors && Boolean(formik.errors.sectors)}
              label="Relevant Service Category"
            />
          )}
          renderOption={(props, option, { selected }) => (
            <MenuItem {...props} key={option} value={option}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                {<Typography>{option}</Typography>}
                {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
              </Box>
            </MenuItem>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                size="small"
                label={option}
                {...getTagProps({ index })}
                variant="outlined"
              />
            ))
          }
          onChange={(_: SyntheticEvent, newValue: string[] | null) =>
            formik.setFieldValue("sectors", newValue)
          }
          onBlur={formik.handleBlur}
        />
        {isReferralInvitation ? (
          <Button
            fullWidth
            disabled={
              !formik.isValid ||
              (!formik.dirty && !Object.keys(formik.touched).length)
            }
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            {invitedCompanyId ? "Get Started For Free" : "Get Started"}
          </Button>
        ) : (
          <Button
            fullWidth
            disabled={
              !formik.isValid ||
              (!formik.dirty && !Object.keys(formik.touched).length)
            }
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => handleDemoClick()}
          >
            Book Demo
          </Button>
        )}
      </Card>
    </Box>
  );
}
