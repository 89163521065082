import constate from "constate";
import { useState } from "react";

function useJobUpload() {
  const [jobUploadOpen, setJobUploadOpen] = useState(false);

  return { jobUploadOpen, setJobUploadOpen };
}

export const [JobUploadProvider, useJobUploadContext] = constate(useJobUpload);
