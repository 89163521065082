import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { isDefined } from "@/utils/isDefined";

import { useDeleteJobPromotionExclusion } from "../api/useDeleteJobPromotionExclusion";
import { useUpsertJobPromotionExclusion } from "../api/useUpsertJobPromotionExclusion";
import { JobPromotion } from "../types";

interface JobPromotionExclusionFieldProps {
  jobPromotion: JobPromotion;
  onUpdate: () => void;
}

export function JobPromotionExclusionField(
  props: JobPromotionExclusionFieldProps
) {
  const { jobPromotion, onUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const isUserIsAdminOrOwner = session?.groups?.some(isAdministratorOrOwner);

  const {
    mutateAsync: upsertJobPromotionExclusionAsync,
    isLoading: isUpsertingJobPromotionExclusion,
  } = useUpsertJobPromotionExclusion({
    onSuccess: () => {
      onUpdate();
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while creating the promotion exclusion";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const {
    mutateAsync: deleteJobPromotionExclusionAsync,
    isLoading: isDeletingJobPromotionExclusion,
  } = useDeleteJobPromotionExclusion({
    onSuccess: () => {
      onUpdate();
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while deleting the promotion exclusion";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  if (!isUserIsAdminOrOwner) {
    // returning empty box to maintain layout
    return <Box />;
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={!isDefined(jobPromotion.exclusionId)}
          disabled={
            isDeletingJobPromotionExclusion || isUpsertingJobPromotionExclusion
          }
          onChange={async event => {
            if (event.target.checked) {
              await deleteJobPromotionExclusionAsync({
                jobPromotionExclusionId: jobPromotion.exclusionId ?? -1,
                jobPromotionId: jobPromotion.id,
              });
              return;
            }

            await upsertJobPromotionExclusionAsync({
              jobPromotionId: jobPromotion.id,
            });
          }}
        />
      }
      label={
        !isDefined(jobPromotion.exclusionId) ? (
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant="subtitle2" fontWeight={600}>
              Active
            </Typography>
            <Tooltip
              placement="top"
              title={
                "If you disable this promo, your team won't be able to refer it to your customers."
              }
            >
              <HelpOutlineIcon
                sx={{
                  color: "#6C737F",
                  height: "20px",
                  width: "20px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Stack>
        ) : (
          <Typography variant="subtitle2" fontWeight={600}>
            Paused
          </Typography>
        )
      }
      onClick={event => {
        event.stopPropagation();
      }}
    />
  );
}
