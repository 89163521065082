import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { JobUploadProvider } from "@/common/job-upload-context";

import { CompanyPaymentsProvider } from "./context/payments-context";
import { OnboardingReferralSkippedProvider } from "./onboarding/components/PreviousLocationProvider";

export default function CompanyLayout() {
  const [mutationComplete, setMutationComplete] = useState(false);
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("companyId");

  const { session, signInToCompany, refreshSession } = useAuth();

  useEffect(() => {
    if (companyId && session?.company?.id != Number(companyId)) {
      signInToCompany({ companyId: Number(companyId) })
        .then(() => {
          refreshSession();
          setMutationComplete(true);
        })
        .catch(error => {
          setMutationComplete(true);
        });
    }
  }, [companyId, session]);

  if (
    !mutationComplete &&
    companyId &&
    session?.company?.id != Number(companyId)
  ) {
    return (
      <Box my={5} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <OnboardingReferralSkippedProvider>
      <CompanyPaymentsProvider>
        <JobUploadProvider>
          <Outlet />
        </JobUploadProvider>
      </CompanyPaymentsProvider>
    </OnboardingReferralSkippedProvider>
  );
}
