import { Typography } from "@mui/material";
import { FC } from "react";

import { ReceivedReferralsChips, SentReferralsChips } from "../types/job";

interface SubtitleTextReferralsProps {
  totalEarning?: number;
  jobChipType: SentReferralsChips | ReceivedReferralsChips;
}

export const SubtitleTextReferrals: FC<SubtitleTextReferralsProps> = ({
  totalEarning,
  jobChipType,
}) => {
  if (jobChipType === SentReferralsChips.EARNED) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
      >{`These are jobs submitted by your technicians and paid to you from your partners! ${
        totalEarning
          ? `You’ve earned $${
              totalEarning ?? 0
            } to lower your job acquisition costs of buying referrals!`
          : ""
      }`}</Typography>
    );
  }

  if (jobChipType === SentReferralsChips.PENDING_HOMEOWNER) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
      >{`These are jobs submitted by your technicians that homeowners are evaluating. Feel free to reach out to give them a nudge!`}</Typography>
    );
  }

  if (jobChipType === SentReferralsChips.PENDING_PARTNER) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
      >{`These are jobs pending action from your referral partner. Feel free to reach out to give them a nudge!`}</Typography>
    );
  }

  if (jobChipType === SentReferralsChips.ALL_HISTORY) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
      >{`These are all of the jobs submitted by your technicians, also known as outbound referrals!`}</Typography>
    );
  }

  if (jobChipType === ReceivedReferralsChips.ALL_HISTORY) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
      >{`These are all of the jobs referred to you from your trusted partners, also known as inbound referrals!`}</Typography>
    );
  }

  if (jobChipType === ReceivedReferralsChips.PENDING_PAYMENT) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
      >{`These are all of the jobs pending payment by you from your trusted partners, also known as inbound referrals!`}</Typography>
    );
  }

  if (jobChipType === ReceivedReferralsChips.PENDING_REVIEWS) {
    return (
      <Typography variant="body2" color="text.secondary">
        These are new jobs referred to you from your trusted partners. Hurry!{" "}
        <Typography
          display="inline"
          fontWeight={600}
          variant="body2"
          color="text.secondary"
        >
          You only have 24 hours to review and approve each job!
        </Typography>
      </Typography>
    );
  }
  return null;
};
