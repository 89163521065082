import { Theme, useMediaQuery } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { GET_JOB_BY_ID_URL } from "@/company/jobs/api/useGetJobById";
import { Job } from "@/company/jobs/types";
import { USE_GET_MY_JOBS_URI } from "@/homeowner/api/useGetMyJobs";
import { useHomeownerActOnJob } from "@/homeowner/api/useHomeOwnerActOnJob";

import { HomeownerJobApproveDialog } from "./HomeownerJobApproveDialog";
import { HomeownerJobDeclineDialog } from "./HomeownerJobDeclineDialog";
import { HomeownerJobDelayDialog } from "./HomeownerJobDelayDialog";

export interface HomeownerJobActionDialogProps {
  job?: Job;
  open: boolean;
  action: "DELAY" | "DECLINE" | "APPROVE" | null;
  onClose: () => void;
}

export const HomeownerDelayReasons = [];
export const HomeownerDeclineReasons = [];

export function HomeownerJobActionDialog(props: HomeownerJobActionDialogProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { open, onClose, action, job } = props;

  const { isLoading: isUpdating, mutateAsync: actOnJob } = useHomeownerActOnJob(
    job?.id ?? null,
    {
      onSuccess: () => {
        let message = "requested";
        if (action === "DECLINE") {
          message = "declined";
        } else if (action === "DELAY") {
          message = "delayed";
        }
        enqueueSnackbar({
          message: `You have ${message} this project.`,
          variant: "success",
        });
        queryClient.invalidateQueries({ queryKey: [USE_GET_MY_JOBS_URI] });
        queryClient.invalidateQueries({
          queryKey: [
            GET_JOB_BY_ID_URL.replace(":id", job?.id?.toString() ?? ""),
          ],
        });
        onClose();
      },
      onError: error => {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Unable to update job.";
        enqueueSnackbar({
          message,
          variant: "error",
        });
      },
    }
  );

  if (!job || !action) return null;

  switch (action) {
    case "APPROVE": {
      return (
        <HomeownerJobApproveDialog
          job={job}
          open={open}
          isUpdatingJob={isUpdating}
          actOnJob={actOnJob}
          onClose={onClose}
        />
      );
    }
    case "DELAY": {
      return (
        <HomeownerJobDelayDialog
          job={job}
          open={open}
          isUpdating={isUpdating}
          isMobile={isMobile}
          actOnJob={actOnJob}
          onClose={onClose}
        />
      );
    }
    case "DECLINE": {
      return (
        <HomeownerJobDeclineDialog
          job={job}
          open={open}
          isUpdating={isUpdating}
          isMobile={isMobile}
          actOnJob={actOnJob}
          onClose={onClose}
        />
      );
    }
  }
}
