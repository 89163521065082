/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Add as AddIcon } from "@mui/icons-material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { useBackdropContext } from "@/common/backdrop-context";
import { AppConstants } from "@/common/constants";
import CreateTechnicianJobDialogWrapper from "@/common/CreateJobDialog/CreateTechnicianJobDialogWrapper";
import { Seo } from "@/common/Seo/Seo";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { JobSectors } from "@/company/jobs/constants";
import {
  useQueryBrandLocationById,
  useQueryBrandsUnderFamilyBrand,
} from "@/company/onboarding/api";
import { CompanyRoutes } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

import { FactsCarousel } from "./FactsCarousel";
import { TechnicianDemoPage } from "./TechnicianDemoPage";

export const speedDialActions = [
  {
    icon: (
      <BackupOutlinedIcon
        sx={{
          bgcolor: "#16B364",
          color: "white",
          width: "32px",
          height: "32px",
          padding: "6px",
          borderRadius: "50%",
        }}
        color="primary"
      />
    ),
    name: "Upload Job Video",
  },
];

export function TechnicianHomePage() {
  const [isImageLoadError, setIsImgaeLoadError] = useState(false);
  const { backDropOn, setBackDropOn } = useBackdropContext();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);
  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const handleVideoButtonClick = () => {
    const videoUrl =
      "https://tradeengage-app.s3.amazonaws.com/videos/You're+Invited+to+Join+TradeEngage.mp4";
    window.open(videoUrl, "_blank");
  };

  const videoRef = useRef(null);

  const handleVideoEnd = () => {
    trackEvent(AnalyticsEvents.WATCHED_TECHNICIAN_DEMO, {});
  };

  const [playClicked, setPlayClicked] = useState(false);

  useEffect(() => {
    if (playClicked) {
      const videoElement = videoRef.current;
      if (videoElement && videoElement.paused) {
        videoElement.play();
      }
    }
  }, [playClicked]);

  const { session } = useAuth();

  const company = session?.company;
  const familyOfBrands = session?.familyOfBrands;

  const companySectors = session?.companySectors;

  const { data: searchCompanyRecord } = useQueryBrandLocationById({
    id: company?.id,
  });

  const { data: { count: totalParentBrandsCount } = {} } =
    useQueryBrandsUnderFamilyBrand(
      {
        familyBrandId: searchCompanyRecord?.familyOfBrands?.id,
      },
      !!searchCompanyRecord?.familyOfBrands
    );

  const [open, setOpen] = useState(false);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  // TODO: will be replaced with actual count once the search algo for referrals is finalised
  const randomRecommendedReferralPartnerCount = Math.floor(
    Math.random() * (40 - 25 + 1) + 25
  );

  return isMobile ? (
    <TechnicianDemoPage />
  ) : (
    <>
      <Seo title="Company Home" />
      <Container disableGutters maxWidth="100%" sx={{ bgcolor: "white" }}>
        <Grid container spacing={2} bgcolor={"white"}>
          {isMobile ? null : (
            <Grid
              item
              xs={12}
              sx={{
                top: "56px",
                zIndex: backDropOn ? 0 : 400,
                pt: 1,
                pb: 2,
              }}
              position={"sticky"}
              bgcolor={"white"}
            >
              <Typography gutterBottom variant="h5">
                Boost Your Earnings Today!
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary">
                Watch our video for tips to submit job videos.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} lg={8} pt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    boxShadow: "0px 0px 0px 1px #F2F4F7;",
                    display: "flex",
                    position: "relative",
                    flexDirection: "column",
                    backgroundSize: "108% 120%",
                    backgroundPosition: "center",
                    backgroundImage: 'url("/card.png")',
                    px: 1,
                    pb: 3,
                    pt: 2,
                    gap: 3,
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    width={"100%"}
                    alignItems={"start"}
                    px={1}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"start"}
                      alignItems={"center"}
                      px={1}
                      gap={1}
                      width="100%"
                    >
                      <img
                        src="/video.gif"
                        alt="dollar"
                        style={{
                          width: "48px",
                          height: "48px",
                        }}
                      />

                      <Typography
                        variant="h6"
                        sx={{
                          color: "#16DB65",
                        }}
                      >
                        Give to Get! Submit your AI-driven referral today
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                      px={1}
                      gap={2}
                      width="100%"
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#D2D6DB",
                        }}
                      >
                        {`Record a video of a job opportunity. We’ll summarize it and route it to your referral partner. If you’ve set up a commission with your referral partner, your team will earn instantly.`}
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                      px={1}
                      pt={1.5}
                      gap={3}
                      width="100%"
                    >
                      {" "}
                      <Typography
                        width={"100%"}
                        variant="subtitle1"
                        sx={{
                          color: "white",
                        }}
                      >
                        {isMobile ? (
                          "Tap the + sign to get started!"
                        ) : (
                          <>
                            Click{" "}
                            <Typography
                              variant="subtitle1"
                              display={"inline"}
                              sx={{
                                cursor: "pointer",
                                color: "#16DB65",
                              }}
                              onClick={() => {
                                setIsJobDialogOpen(true);
                              }}
                            >
                              here{" "}
                            </Typography>
                            to get started!
                          </>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              my={3}
              sx={{
                justifyContent: { xs: "space-around" },
                alignItems: { xs: "start", md: "start" },
                gap: 1,
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                textAlign={"center"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"start"}
                sx={{ height: "410px" }}
              >
                {playClicked ? (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video
                    ref={videoRef}
                    autoPlay
                    controls
                    controlsList="nodownload"
                    style={{
                      borderRadius: "20px",
                      border: "1px solid black",
                    }}
                    width={"100%"}
                    height="100%"
                    onEnded={handleVideoEnd}
                  >
                    <source
                      src={AppConstants.technicianDemoVideoUrl}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <Box
                    style={{
                      borderRadius: "20px",
                      height: "100%",
                    }}
                    width={"100%"}
                    height="100%"
                    bgcolor="primary.light"
                    display={playClicked ? "none" : "flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    onClick={() => setPlayClicked(true)}
                  >
                    <img
                      src="/technician-demo-video.svg"
                      alt="technician-demo-poster"
                      width="100%"
                      height="320px"
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={5.8}>
                <FactsCarousel width="100%" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} mb={1} {...(isDesktop ? { px: 2 } : {})}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    boxShadow: "0px 0px 0px 1px #F2F4F7;",
                    py: 4,
                    px: 3,
                    bgcolor: "#F8F9FA",
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                  }}
                >
                  <>
                    <Box display="flex" gap={2} height={"100%"}>
                      <IconButton
                        aria-label="close"
                        sx={{
                          bgcolor: "white",
                          borderRadius: "20px",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <TravelExploreOutlinedIcon
                          sx={{
                            color: "black",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </IconButton>
                      <Box display="flex" flexDirection={"column"}>
                        {familyOfBrands ? (
                          <>
                            {" "}
                            <Typography variant="subtitle1" fontWeight={400}>
                              You&apos;re affiliated with{" "}
                              <Typography
                                variant="subtitle1"
                                display={"inline"}
                                fontWeight={700}
                              >
                                {familyOfBrands.name}!
                              </Typography>
                            </Typography>
                            {familyOfBrands.logoUrl && !isImageLoadError ? (
                              <img
                                src={familyOfBrands.logoUrl}
                                alt="parent logo"
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "112px",
                                  padding: "16px",
                                }}
                                onError={() => setIsImgaeLoadError(true)}
                              />
                            ) : null}
                            {totalParentBrandsCount ? (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {familyOfBrands.name} has over{" "}
                                {totalParentBrandsCount} brands and growing! Add
                                them as referral partners today.
                              </Typography>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <Typography variant="subtitle1" fontWeight={700}>
                              Start building referral partnerships today!
                            </Typography>
                            {randomRecommendedReferralPartnerCount &&
                            companySectors?.length ? (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                <Typography
                                  variant="subtitle1"
                                  display={"inline"}
                                  fontWeight={500}
                                >
                                  {randomRecommendedReferralPartnerCount}{" "}
                                </Typography>
                                referral partners offer services complementary
                                to{" "}
                                <Typography
                                  variant="body2"
                                  display={"inline"}
                                  fontWeight={700}
                                  sx={{ color: "#16B364" }}
                                >
                                  {
                                    snakeCaseJobSectors[
                                      companySectors?.[0].sector as JobSectors
                                    ]
                                  }
                                </Typography>{" "}
                                near you. Connect with them today!
                              </Typography>
                            ) : null}
                          </>
                        )}
                      </Box>
                    </Box>
                    <Divider sx={{ border: "0.2px #E5E7EB solid" }} />
                  </>
                  <Box display="flex" gap={2} height={"100%"}>
                    <IconButton
                      aria-label="close"
                      sx={{
                        bgcolor: "white",
                        borderRadius: "20px",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <VideocamOutlinedIcon
                        sx={{
                          color: "black",
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </IconButton>
                    <Box display="flex" flexDirection={"column"}>
                      <Typography variant="subtitle1">
                        Get to know the platform
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Watch our quick start video for tips
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "fit-content",
                          mt: 2,
                        }}
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={handleVideoButtonClick}
                      >
                        Watch Video
                      </Button>
                    </Box>
                  </Box>
                  <Divider sx={{ border: "0.2px #E5E7EB solid" }} />
                  <Box display="flex" gap={2} height={"100%"}>
                    <IconButton
                      aria-label="close"
                      sx={{
                        bgcolor: "white",
                        borderRadius: "20px",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <SupportAgentOutlinedIcon
                        sx={{
                          color: "black",
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </IconButton>
                    <Box display="flex" flexDirection={"column"}>
                      <Typography variant="subtitle1">Need Help?</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Message our Support Team
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          width: "fit-content",
                          mt: 2,
                        }}
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={() => setContactSupportModalOpen(true)}
                      >
                        Contact Support
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {isMobile ? null : (
        <Box
          position="fixed"
          // bottom={"12px"}
          bottom={"40px"}
          right={"48px"}
          //  right={"100px"}
          sx={{
            ...(open
              ? { zIndex: theme => theme.zIndex.drawer + 1, color: "#fff" }
              : {}),
          }}
        >
          <ToolTip placement="bottom" message="Upload a Job">
            <Fab
              color="primary"
              aria-label="add-invite"
              onClick={() => {
                setIsJobDialogOpen(true);
                setOpen(false);
              }}
            >
              <AddIcon />
            </Fab>
          </ToolTip>
        </Box>
      )}
      {isJobDialogOpen ? (
        <CreateTechnicianJobDialogWrapper
          isOpen={isJobDialogOpen}
          jobsListUrl={getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.MY_JOBS,
          ])}
          onClose={() => {
            setIsJobDialogOpen(false);
          }}
        />
      ) : null}
    </>
  );
}
