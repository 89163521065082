import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useResendUserInvite } from "@/company/api/useResendUserInvite";
import { DefaultCompanyGroups, PLATFORM_PRESENCE } from "@/company/types";
import ConfirmationDialog from "@/internal-admin/ConfirmationDialog";
import { UserOnboardingStatus } from "@/user/types";
import { formatUSPhoneNumber, snakeCaseToTitleCase } from "@/utils";

import { STAFF_TYPES, StaffData } from "./types";

interface Column {
  id: "name" | "cellPhone" | "role" | "onboardingStatus" | "email";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "name", label: "Name", minWidth: 60 },
  { id: "cellPhone", label: "Mobile Number", minWidth: 100 },
  { id: "email", label: "Email Address", minWidth: 100 },
  { id: "role", label: "Role", minWidth: 100 },
  { id: "onboardingStatus", label: "Status", minWidth: 100 },
];

export const ChipObj = {
  [DefaultCompanyGroups.ADMINISTRATOR]: {
    color: "#16B364",
    bgColor: "#EDFCF2",
  },
  [DefaultCompanyGroups.JOB_APPROVER]: {
    color: "#B54708",
    bgColor: "#F790091F",
  },
  [DefaultCompanyGroups.OWNER]: { color: "#16B364", bgColor: "#EDFCF2" },
  [DefaultCompanyGroups.TEAM_MEMBER]: {
    color: "#0E7090",
    bgColor: "#06AED41F",
  },
  [DefaultCompanyGroups.FIELD_TEAM]: {
    color: "#0E7090",
    bgColor: "#06AED41F",
  },
};

export const UserOnboardingStatusChipObj = {
  [UserOnboardingStatus.INVITED]: {
    color: "#16B364",
    bgColor: "#EDFCF2",
  },
  [UserOnboardingStatus.CREATED]: {
    color: "#B54708",
    bgColor: "#F790091F",
  },
  [UserOnboardingStatus.ONBOARDED]: {
    color: "#0E7090",
    bgColor: "#06AED41F",
  },
};

export function StaffTable({
  staffType,
  onRemoveMember,
  onEditMember,
  activateUser,
  refetchUsers,
  isActivatingUser = false,
  staff = [],
}: {
  staffType: STAFF_TYPES;
  onRemoveMember: (staffType: STAFF_TYPES, index: number) => void;
  onEditMember: (index: number) => void;
  activateUser: (staffType: STAFF_TYPES, index: number) => void;
  refetchUsers?: () => void;
  isActivatingUser: boolean;
  staff: Array<StaffData & { name: string }>;
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { session } = useAuth();
  const companyId = session?.company?.id;
  const [resendRowId, setResendRowId] = useState<number | undefined>();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleDelete = (index: number) => {
    onRemoveMember(staffType, index);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { mutateAsync: resendUserInvite, isLoading: isResendingUserInvite } =
    useResendUserInvite({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while resending user invite.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        enqueueSnackbar({
          message: "User invite resent successfully.",
          variant: "success",
        });
        refetchUsers?.();
      },
    });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        overflow: "auto",
      }}
    >
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 0,
          width: "100%",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "56vh", overflow: "auto" }}
        >
          <Table stickyHeader size="small" aria-label="staff table">
            <TableHead>
              <TableRow sx={{ height: "2px" }}>
                {columns
                  .filter(column => {
                    if (
                      (column.id === "role" &&
                        staffType === STAFF_TYPES.FIELD_TEAM) ||
                      (!isDesktop &&
                        (column.id === "cellPhone" ||
                          column.id === "email" ||
                          column.id === "role"))
                    )
                      return false;
                    if (!refetchUsers && column.id === "onboardingStatus") {
                      return false;
                    }
                    return true;
                  })
                  .map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        paddingLeft: "8px",
                        paddingRight: "8px",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                <TableCell
                  key={"action"}
                  align={"right"}
                  style={{
                    minWidth: 120,
                    paddingRight: "8px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staff
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                      {columns
                        .filter(column => {
                          if (
                            (column.id === "role" &&
                              staffType === STAFF_TYPES.FIELD_TEAM) ||
                            (!isDesktop &&
                              (column.id === "cellPhone" ||
                                column.id === "email" ||
                                column.id === "role"))
                          )
                            return false;
                          if (
                            !refetchUsers &&
                            column.id === "onboardingStatus"
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                paddingLeft: "8px",
                                paddingRight: "8px",
                              }}
                            >
                              {column.id === "role" ? (
                                <Chip
                                  sx={{
                                    color:
                                      row.platformPresence ===
                                      PLATFORM_PRESENCE.OFF_PLATFORM
                                        ? "none"
                                        : ChipObj[value as DefaultCompanyGroups]
                                            .color,
                                    bgcolor:
                                      row.platformPresence ===
                                      PLATFORM_PRESENCE.OFF_PLATFORM
                                        ? "none"
                                        : ChipObj[value as DefaultCompanyGroups]
                                            .bgColor,
                                  }}
                                  label={
                                    <Typography variant="overline">
                                      {snakeCaseToTitleCase(
                                        value?.toString() ?? ""
                                      )}
                                    </Typography>
                                  }
                                  size="small"
                                />
                              ) : column.id === "name" ? (
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  gap={1}
                                >
                                  <Box>
                                    <Box
                                      display={"flex"}
                                      sx={{
                                        maxWidth: "100px",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <Typography variant="subtitle2">
                                        {value}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              ) : column.id === "cellPhone" ? (
                                <Tooltip
                                  key={`${row.id}-mobile`}
                                  title={formatUSPhoneNumber(value)}
                                >
                                  <Typography
                                    noWrap
                                    variant="subtitle2"
                                    sx={{
                                      maxWidth: {
                                        xl: "400px",
                                        xs: "120px",
                                        lg: "120px",
                                      },
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {formatUSPhoneNumber(value)}
                                  </Typography>
                                </Tooltip>
                              ) : column.id === "onboardingStatus" ? (
                                <Chip
                                  sx={{
                                    color:
                                      UserOnboardingStatusChipObj[
                                        value as UserOnboardingStatus
                                      ].color,
                                    bgcolor:
                                      UserOnboardingStatusChipObj[
                                        value as UserOnboardingStatus
                                      ].bgColor,
                                  }}
                                  label={
                                    <Typography variant="overline">
                                      {snakeCaseToTitleCase(
                                        value?.toString() ?? ""
                                      )}
                                    </Typography>
                                  }
                                  size="small"
                                />
                              ) : (
                                <Tooltip
                                  key={`${row.id}-generic-field`}
                                  title={value}
                                >
                                  <Typography
                                    noWrap
                                    variant="subtitle2"
                                    sx={{
                                      maxWidth: {
                                        xl: "400px",
                                        xs: "120px",
                                        lg: "120px",
                                      },
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {value}
                                  </Typography>
                                </Tooltip>
                              )}
                            </TableCell>
                          );
                        })}
                      <TableCell
                        align="right"
                        style={{
                          paddingLeft: "0px",
                          paddingRight: "8px",
                        }}
                      >
                        {row.platformPresence ===
                        PLATFORM_PRESENCE.OFF_PLATFORM ? (
                          <>
                            <IconButton
                              size="small"
                              aria-label="edit"
                              sx={{ mr: 1 }}
                              onClick={() => onEditMember(row.id)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                            <Button
                              disabled={isActivatingUser}
                              variant="contained"
                              size={"small"}
                              sx={{ py: 0.5 }}
                              onClick={() => activateUser(staffType, row.id)}
                            >
                              Invite
                            </Button>
                          </>
                        ) : (
                          <>
                            {row.onboardingStatus ===
                            UserOnboardingStatus.INVITED ? (
                              <Tooltip
                                key={`${row.id}-resend-invite-user-tooltip`}
                                title="Resend Onboarding Invite"
                              >
                                <IconButton
                                  size="small"
                                  aria-label="edit"
                                  onClick={() => setResendRowId(row.id)}
                                >
                                  <NotificationsActiveIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            <IconButton
                              size="small"
                              aria-label="edit"
                              onClick={() => onEditMember(row.id)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              aria-label="delete"
                              onClick={() => handleDelete(row.id)}
                            >
                              <DeleteForeverRoundedIcon />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={staff.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        open={!!resendRowId}
        handleClose={() => setResendRowId(undefined)}
        handleConfirm={() => {
          resendUserInvite(resendRowId!);
          setResendRowId(undefined);
        }}
        text={`Are you sure you want to re-send the onboarding invites?`}
        title="Send Onboarding Invitations"
        confirmText="Send"
      />
    </Box>
  );
}
