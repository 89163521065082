import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";

import { useAuth } from "@/auth/context/jwt";
import API from "@/common/api";
import { keysToSnakeCase } from "@/utils";

import { JobSectors } from "../jobs/constants";
import {
  COMPANY_ACCOUNT_TYPES,
  CompanyPublicProfile,
  ExternalCompanyProfile,
} from "../types";

export interface CompanyExploreFilters {
  keyword?: string;
  sectors?: JobSectors[];
  linkedCompanies?: boolean;
  excludeRecordIds?: string;
  excludeCompanyIds?: string;
  nearby?: boolean;
  accountTypes?: COMPANY_ACCOUNT_TYPES[];
  parentCompanyId?: number | null;
  searchRadius?: number;
  zipCode?: string;
  googleRating?: Array<1 | 2 | 3 | 4>;
  ownerContactInformationAvailable?: boolean;
  affiliatedCompanies?: boolean;
  hasMutualReferrals?: boolean;
  activeOnTradeEngage?: boolean;
  topReferralCategories?: JobSectors[];
  topReferralCategoryCheck?: boolean;
  otherNearbyCheck?: boolean;
  franchiseCheck?: boolean;
  excludeCurrentCompany?: boolean;
  fddDisregardNearbyLocations?: boolean;
  limit?: number;
  isGoogleCompany?: boolean;
  privateEquityNetwork?: boolean;
  consultantNetworkId?: number;
  hubId?: number;
  familyOfBrandsNonAffiliatePartners?: boolean;
}

export interface SearchedCompany {
  googleCompanyProfile?: ExternalCompanyProfile;
  companyPublicProfile?: CompanyPublicProfile;
  hasMarkedInterest?: boolean;
}

export function useQueryExploreCompanies(
  filters?: CompanyExploreFilters,
  relevanceOrderedJobSectorsList?: Array<JobSectors>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<SearchedCompany>> {
  const uri = "/company-search/v2";
  const newFilters = { ...filters };

  const { session } = useAuth();

  const companySectors = session?.companySectors;
  const campaign = session?.campaign;

  if (
    (newFilters.topReferralCategoryCheck || newFilters.hasMutualReferrals) &&
    relevanceOrderedJobSectorsList
  ) {
    newFilters.sectors = [
      ...new Set([
        ...(newFilters.sectors ? newFilters.sectors : []),
        ...relevanceOrderedJobSectorsList,
      ]),
    ];
  }

  if (
    (newFilters.otherNearbyCheck || newFilters.hasMutualReferrals) &&
    relevanceOrderedJobSectorsList
  ) {
    newFilters.sectors = [
      ...new Set([
        ...(newFilters.sectors ? newFilters.sectors : []),
        ...Object.values(JobSectors).filter(
          sector => !relevanceOrderedJobSectorsList?.includes(sector)
        ),
      ]),
    ];
  }

  if (newFilters.sectors?.length) {
    newFilters.sectors = newFilters.sectors?.map(sector =>
      sector.toString().replaceAll(" ", "_").toUpperCase()
    );
  }
  const batchSize = 4;
  const batchSectors = [];
  const sectorsWithoutOwnSectors = newFilters?.sectors
    ?.filter(
      (sector: JobSectors) =>
        !companySectors
          ?.map(companySector => companySector.sector)
          .includes(sector)
    )
    ?.filter(
      (sector: JobSectors) =>
        !campaign?.companyCampaignSectors?.includes(sector)
    );

  if (sectorsWithoutOwnSectors?.length) {
    for (let i = 0; i < sectorsWithoutOwnSectors?.length; i += batchSize) {
      const batch = sectorsWithoutOwnSectors?.slice(i, i + batchSize);
      batchSectors.push(batch?.map(sector => "sectors=" + sector).join("&"));
    }
  }

  const sectors = sectorsWithoutOwnSectors
    ?.map(sector => "sectors=" + sector)
    .join("&");

  delete newFilters.sectors;

  const excludeCompanyIds = newFilters.excludeCompanyIds;
  const excludeRecordIds = newFilters.excludeRecordIds;
  delete newFilters.excludeCompanyIds;
  delete newFilters.excludeRecordIds;

  if (filters?.franchiseCheck) {
    newFilters.accountTypes = [COMPANY_ACCOUNT_TYPES.BRAND_LOCATION];
  }
  const searchQuery = new URLSearchParams(
    keysToSnakeCase({
      ...newFilters,
      nearby:
        newFilters?.privateEquityNetwork ||
        newFilters.consultantNetworkId ||
        newFilters.hubId
          ? false
          : true,
    })
  );

  const queries = batchSectors.map(sectors => {
    const uriWithSearchQuery = `${uri}?${searchQuery.toString()}&${
      sectors ?? ""
    }&${excludeRecordIds ?? ""}&${excludeCompanyIds ?? ""}`;

    return uriWithSearchQuery;
  });
  const uriWithSearchQuery = `${uri}?${searchQuery.toString()}&${
    sectors ?? ""
  }&${excludeRecordIds ?? ""}&${excludeCompanyIds ?? ""}`;

  return useQuery({
    queryKey: [uriWithSearchQuery.toString()],
    queryFn: async () => {
      if (queries?.length && filters?.isGoogleCompany) {
        const responses = await axios.all(
          queries?.map(query => API.get(query))
        );

        const jsonData = responses.map(response => {
          const camelCaseResponse = response.data.data;
          const result = camelCaseResponse.map(data => ({
            googleCompanyProfile: data.googleCompany,
            companyPublicProfile: data.tradeEngageCompany,
            hasMarkedInterest: data.hasMarkedInterest,
          }));
          return result;
        });

        const results = [].concat(...jsonData) as Array<SearchedCompany>;

        const uniqueGoogleIds = new Set();
        const uniqueCompanyIds = new Set();
        const companyResults = [] as Array<SearchedCompany>;
        results.forEach(company => {
          const googlePlacesId =
            company.companyPublicProfile?.profile.googlePlacesId ??
            company.googleCompanyProfile?.googlePlacesId;
          const companyId = company.companyPublicProfile?.profile.id;
          if (googlePlacesId) {
            if (!uniqueGoogleIds.has(googlePlacesId)) {
              uniqueGoogleIds.add(googlePlacesId);
              companyResults.push(company);
            }
          } else if (companyId) {
            if (!uniqueCompanyIds.has(companyId)) {
              uniqueCompanyIds.add(companyId);
              companyResults.push(company);
            }
          } else {
            companyResults.push(company);
          }
        });
        return companyResults;
      }
      const response = await API.get(uriWithSearchQuery);
      const camelCaseResponse = response.data.data;
      const result = camelCaseResponse.map(data => ({
        googleCompanyProfile: data.googleCompany,
        companyPublicProfile: data.tradeEngageCompany,
        hasMarkedInterest: data.hasMarkedInterest,
      }));
      return result;
    },
    ...options,
  });
}
