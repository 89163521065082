/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  debounce,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputBaseComponentProps,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import {
  ElementType,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import zipcodes from "zipcodes";

import { AuthRoutes } from "@/auth/constants";
import { FIRST_CAMPAIGN_LOGIN_KEY, useAuth } from "@/auth/context/jwt";
import { useGetCampaignPublic } from "@/campaign/api/useGetCampaignPublic";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { AppConstants } from "@/common/constants";
import { Languages } from "@/common/constants/languages";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { Seo } from "@/common/Seo/Seo";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useFetchCompanyProfilePublic } from "@/company/api/useFetchCompanyProfilePublic";
import { JobSectors } from "@/company/jobs/constants";
import { useQuerySearchCompanies } from "@/company/onboarding/api";
import {
  COMPANY_ACCOUNT_TYPES,
  CompanyRoutes,
  SearchCompanyProfile,
} from "@/company/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { PublicRoutes } from "@/open/constants";
import { getUSPhoneValidationSchema, NAME_REGEX } from "@/utils";

export function CampaignMarketingSignUpPage() {
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("company");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const inputRef = useRef(null);

  const [searchParams] = useSearchParams();
  const { location } = useParams();

  const campaignCode = location;
  const utmCampaign = searchParams.get("utm_campaign");
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");

  const { signUp } = useAuth();
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .matches(NAME_REGEX, "Enter a valid name")
      .required("First Name is required"),
    lastName: yup
      .string()
      .matches(NAME_REGEX, "Enter a valid name")
      .required("Last Name is required"),
    companyName:
      value === "technician"
        ? yup.string()
        : yup.string().required("Company Name is required"),
    email: yup
      .string()
      .email("Work Email Address must be a valid email address")
      .optional(),
    cellPhone: getUSPhoneValidationSchema(),
    acceptedTermsAndConditions: yup
      .boolean()
      .oneOf([true], "You must accept the Terms and Conditions"),
    acceptedWhatsappMessagingTerms: yup
      .boolean()
      .oneOf(
        [true],
        "You must agree to receive messages on WhatsApp to submit job videos"
      ),
    preferredLanguage: yup
      .string()
      .oneOf(
        [Languages.ENGLISH, Languages.SPANISH],
        "It should either english or spanish"
      )
      .required("Preferred language is required"),
    zipCode: yup
      .string()
      .required("Zip Code is required")
      .oneOf(Object.keys(zipcodes.codes), "Invalid U.S. zip code")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      cellPhone: "",
      companyName: "",
      email: "",
      zipCode: "",
      sectors: [],
      acceptedTermsAndConditions: false,
      preferredLanguage: Languages.ENGLISH,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const {
        firstName,
        lastName,
        cellPhone,
        companyName,
        zipCode,
        sectors,
        email,
        acceptedTermsAndConditions,
        preferredLanguage,
      } = values;
      try {
        setLoading(true);
        const selectedCompany = data?.find(
          (record: SearchCompanyProfile) => record.name === companyName
        );
        await signUp({
          utmParams: {
            campaign: utmCampaign ?? undefined,
            medium: utmMedium ?? undefined,
            source: utmSource ?? undefined,
          },
          user: {
            firstName,
            lastName,
            cellPhone: cellPhone.replace(/[\s()-]/g, ""),
            email: email ?? "",
            jobTitle: value === "technician" ? "Technician" : "Administrator",
            acceptedTermsAndConditions,
            preferredLanguage,
          },
          ...(selectedCompany && value !== "technician"
            ? {
                brandLocationId: selectedCompany?.id,
                company: {
                  name: selectedCompany?.name,
                  zipCode: selectedCompany?.zipCode,
                  sectors: selectedCompany?.sectors,
                  accountType: COMPANY_ACCOUNT_TYPES.BRAND_LOCATION,
                },
              }
            : {
                company: {
                  name:
                    companyName && value !== "technician"
                      ? companyName
                      : `${firstName} ${lastName}`,
                  zipCode,
                  sectors: sectors?.map(
                    sector => reverseSnakeCaseJobSectors[sector]
                  ) as Array<JobSectors>,
                  accountType:
                    value === "technician"
                      ? COMPANY_ACCOUNT_TYPES.INDEPENDENT_CONTRACTOR
                      : COMPANY_ACCOUNT_TYPES.BRAND_LOCATION,
                },
              }),

          campaignCode: campaignCode ?? undefined,
          redirectTo:
            selectedCompany || value === "technician"
              ? `/${CompanyRoutes.BASE}/${CompanyRoutes.DASHBOARD_HOME}`
              : `${getNavigationPathFromRoutePaths([
                  PublicRoutes.BASE,
                  PublicRoutes.IDENTIFY_EXTERNAL_COMPANY_LOGGED_IN,
                ])}`,
        });
        localStorage.setItem(FIRST_CAMPAIGN_LOGIN_KEY, "true");
        if (AppConstants.CAMPAIGN_SITE) {
          parent.window.open(
            `${AppConstants.platformBaseUrl}/${AuthRoutes.BASE}/${
              AuthRoutes.VERIFY_OTP
            }?phone=${cellPhone?.replace(/[\s()-]/g, "")}`,
            "_blank"
          );
          return;
        }
        navigate(
          getNavigationPathFromRoutePaths([
            AuthRoutes.BASE,
            AuthRoutes.VERIFY_OTP,
          ])
        );
      } catch (error) {
        let message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while signing up user.";
        if (
          message.includes("An account with that mobile number already exists")
        ) {
          message = "An account with that mobile number already exists.";
        }
        enqueueSnackbar(message, {
          variant: "error",
          subText: message.includes(
            "An account with that mobile number already exists"
          ) ? (
            <>
              Questions?{" "}
              <a
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "#7A271A",
                  cursor: "pointer",
                }}
                onClick={event => {
                  event.preventDefault();
                  setContactSupportModalOpen(true);
                }}
              >
                Contact Support
              </a>
              .
            </>
          ) : (
            <>
              Please{" "}
              <a
                href={`/${AuthRoutes.BASE}/${AuthRoutes.LOGIN}`}
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "#7A271A",
                }}
              >
                sign in
              </a>{" "}
              or{" "}
              <a
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "#7A271A",
                  cursor: "pointer",
                }}
                onClick={event => {
                  event.preventDefault();
                  setContactSupportModalOpen(true);
                }}
              >
                contact support
              </a>
              .
            </>
          ),
        });
      }
      setLoading(false);
    },
  });

  const [filterKeyword, setFilterKeyword] = useState<string>("");

  const debouncedSetter = useMemo(
    () => debounce((keyword: string) => setFilterKeyword(keyword), 500),
    []
  );

  const { data, isFetching: isFetchingSearchedCompanies } =
    useQuerySearchCompanies(filterKeyword, !!filterKeyword);

  const {
    data: campaign,
    error,
    isLoading,
    isFetching,
  } = useGetCampaignPublic(campaignCode, {
    enabled: !!campaignCode,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: companyProfile, isFetching: isFetchingProfile } =
    useFetchCompanyProfilePublic(campaign?.companyId, {
      enabled: !!campaign?.companyId,
      refetchOnWindowFocus: false,
    });

  const navigate = useNavigate();

  useEffect(() => {
    if (error?.notFound) {
      navigate("/");
    }
  }, [error]);

  if (isLoading || isFetching || isFetchingProfile) {
    return <ComponentLoader />;
  }

  return (
    <>
      <Seo title="Sign Up" />
      <Box
        sx={{
          p: "0 !important",
          width: "100%",
          overflow: "auto",
          bgcolor: "white",
        }}
        className="custom-scrollbar"
      >
        <Box
          sx={{
            pt: { xs: 2, md: 1.5 },
            pb: { xs: 2, md: 1.5 },
            px: { xs: 2, md: 4 },
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: { xs: "flex-start", md: "center" },
            alignItems: "center",
            gap: "16px",
          }}
        >
          {/* <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: { xs: "center", md: "start" },
                      }}
                    >
                      <img
                        style={{
                          height: "40px",
                          maxWidth: "250px",
                          objectFit: "contain",
                        }}
                        src={
                          companyProfile ? companyProfile?.logoUrl : "/logo.svg"
                        }
                        alt="success"
                      />
                    </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "8px",
              width: "100%",
              py: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <Typography variant="h5">Sign Up to Partner with Us</Typography>
            </Box>
            <Box
              component={"form"}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  columnGap: "8px",
                  width: "100%",
                }}
              >
                <FormControl>
                  <FormLabel id="row-radio-buttons-group-label">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      textAlign={"start"}
                    >
                      Which best describes you?
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="company"
                      color="#FB6125"
                      control={<Radio />}
                      label={<Typography variant="body2">Company</Typography>}
                    />
                    <FormControlLabel
                      value="technician"
                      color="#FB6125"
                      control={<Radio />}
                      label={
                        <Typography variant="body2">Technician</Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {value !== "technician" ? (
                <>
                  <Autocomplete
                    freeSolo
                    sx={{ width: "100%" }}
                    value={formik.values.companyName}
                    loading={loading}
                    options={
                      data?.map(
                        (option: SearchCompanyProfile) => option.name
                      ) ?? []
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        id="companyName"
                        name="companyName"
                        label="Company Name"
                        inputProps={{
                          "data-hj-allow": true,
                          ...params?.inputProps,
                        }}
                        value={formik.values.companyName}
                        error={
                          formik.touched.companyName &&
                          Boolean(formik.errors.companyName)
                        }
                        FormHelperTextProps={{
                          sx: {
                            margin: 0,
                            padding: 0,
                            pt: 0.2,
                            pl: 0.2,
                          },
                        }}
                        helperText={
                          formik.touched.companyName &&
                          formik.errors.companyName
                            ? formik.errors.companyName
                            : "Can't find your company? No problem. Type in your Company Name"
                        }
                        onChange={formik.handleChange}
                      />
                    )}
                    onChange={async (
                      _: SyntheticEvent,
                      newValue: string | null | undefined
                    ) => {
                      const selectedCompany = data?.find(
                        (record: SearchCompanyProfile) =>
                          record.name === newValue
                      );
                      if (selectedCompany?.zipCode) {
                        await formik.setFieldValue(
                          "zipCode",
                          selectedCompany.zipCode,
                          true
                        );
                      }
                      if (selectedCompany?.sectors) {
                        const newSectors = selectedCompany?.sectors
                          ?.filter(sector =>
                            Object.keys(snakeCaseJobSectors).includes(sector)
                          )
                          ?.map(
                            sector => snakeCaseJobSectors[sector] as JobSectors
                          );

                        await formik.setFieldValue("sectors", newSectors, true);
                      }
                      await formik.setFieldValue("companyName", newValue, true);
                    }}
                    onInputChange={(_: unknown, newInputValue: string) => {
                      if (newInputValue.length >= 3) {
                        debouncedSetter(newInputValue);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <Autocomplete
                    multiple
                    id="sectors"
                    sx={{ width: "100%" }}
                    options={Object.keys(JobSectors).map(sector => {
                      if (sector === JobSectors.HVAC) {
                        return sector.toString();
                      }
                      return snakeCaseJobSectors[
                        sector.toString() as JobSectors
                      ];
                    })}
                    getOptionLabel={option => option}
                    value={formik.values.sectors}
                    renderInput={params => (
                      <TextField
                        {...params}
                        inputProps={{
                          "data-hj-allow": true,
                          ...params?.inputProps,
                        }}
                        helperText={
                          formik.touched.sectors && formik.errors.sectors
                        }
                        error={
                          formik.touched.sectors &&
                          Boolean(formik.errors.sectors)
                        }
                        label="Relevant Service Category"
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <MenuItem {...props} key={option} value={option}>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          width={"100%"}
                        >
                          {<Typography>{option}</Typography>}
                          {selected ? (
                            <CheckCircleOutlineIcon color="primary" />
                          ) : null}
                        </Box>
                      </MenuItem>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip
                          size="small"
                          label={option}
                          {...getTagProps({ index })}
                          variant="outlined"
                        />
                      ))
                    }
                    onChange={(_: SyntheticEvent, newValue: string[] | null) =>
                      formik.setFieldValue("sectors", newValue)
                    }
                    onBlur={formik.handleBlur}
                  />
                </>
              ) : null}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  columnGap: "8px",
                  width: "100%",
                }}
              >
                <TextField
                  required
                  sx={{ width: { xs: 1 / 2 } }}
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={formik.values.firstName}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <TextField
                  required
                  sx={{ width: { xs: 1 / 2 } }}
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  value={formik.values.lastName}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Box>
              <TextField
                required
                fullWidth
                inputRef={inputRef}
                id="cellPhone"
                name="cellPhone"
                label="Mobile Number"
                value={formik.values.cellPhone}
                error={
                  formik.touched.cellPhone && Boolean(formik.errors.cellPhone)
                }
                helperText={formik.touched.cellPhone && formik.errors.cellPhone}
                InputProps={{
                  inputComponent: MaskedPhoneInput as unknown as
                    | ElementType<InputBaseComponentProps>
                    | undefined,
                }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email Address"
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <TextField
                required
                fullWidth
                type="number"
                id="zipCode"
                name="zipCode"
                label="Zip Code"
                inputMode="numeric"
                sx={{ mx: 0 }}
                inputProps={{
                  pattern: "[0-9]*",
                  "data-hj-allow": true,
                }}
                value={formik.values.zipCode}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                onChange={event => {
                  if (event.target.value?.length <= 5) {
                    formik.setFieldValue("zipCode", event.target.value, true);
                  }
                }}
                onBlur={formik.handleBlur}
              />
              {value == "technician" ? (
                <FormControl sx={{ width: "100%", textAlign: "start" }}>
                  <InputLabel id="preferred-language" textAlign={"start"}>
                    Preferred Language
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="preferred-language"
                    id="preferred-language"
                    label="Preferred Language"
                    name="preferredLanguage"
                    value={formik.values.preferredLanguage}
                    error={
                      formik.touched.preferredLanguage &&
                      Boolean(formik.errors.preferredLanguage)
                    }
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={Languages.ENGLISH}>English</MenuItem>
                    <MenuItem value={Languages.SPANISH}>Spanish</MenuItem>
                  </Select>
                  <FormHelperText>
                    {
                      "This will be used only for translating and analyzing videos"
                    }
                  </FormHelperText>
                </FormControl>
              ) : null}
              <Box
                display={"flex"}
                flexDirection={"column"}
                width={"100%"}
                px={2}
              >
                <FormControl sx={{ width: "100%" }}>
                  <FormControlLabel
                    sx={{
                      "& .MuiTypography-root": {
                        lineHeight: "normal",
                      },
                    }}
                    value={formik.values.acceptedTermsAndConditions}
                    control={
                      <Checkbox
                        sx={{
                          mx: 0.5,
                          p: 0,
                          // mt: 0.1,
                          "& svg": { width: "16px" },
                        }}
                        size="small"
                        name="acceptedTermsAndConditions"
                        checked={Boolean(
                          formik.values.acceptedTermsAndConditions
                        )}
                        onChange={formik.handleChange}
                      />
                    }
                    label={
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography
                          variant="caption"
                          fontWeight={400}
                          fontSize={"0.8rem"}
                          color="text.secondary"
                        >
                          I have read and accepted the{" "}
                          <Link
                            color={"#16B364"}
                            target="_blank"
                            href={`${AppConstants.platformBaseUrl}/open/terms-of-service`}
                            rel="noreferrer"
                          >
                            <Typography
                              // py={4}
                              display="inline"
                              variant="caption"
                              fontWeight={600}
                              fontSize={"0.8rem"}
                              color={"#16B364"}
                            >
                              Terms and Conditions
                            </Typography>
                          </Link>
                          .
                        </Typography>
                      </Box>
                    }
                  />
                  {formik.touched.acceptedTermsAndConditions &&
                    formik.errors.acceptedTermsAndConditions && (
                      <FormHelperText error>
                        {formik.errors.acceptedTermsAndConditions}
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
              <LoadingButton
                fullWidth
                disabled={
                  !formik.isValid ||
                  (!formik.dirty && !Object.keys(formik.touched).length)
                }
                loading={loading}
                variant="contained"
                onClick={() => formik.handleSubmit()}
              >
                Connect with Us
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
