import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useUpdateSectors } from "@/company/api/useUpdateSectors";
import { PayAfterJobCompletion } from "@/company/components/company-settings/components/PayAfterJobCompletion";
import { PayPerReferral } from "@/company/components/company-settings/components/PayPerReferral";
import { UpdateSectorType } from "@/company/components/company-settings/ReferralMatrix";
import { useUpdateBrandLocationProfile } from "@/company/onboarding/api";
import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { CompanyRoutes, PayReferralFeeSettings } from "@/company/types";
import { tradeEngageColors } from "@/Theme/colors";

import { OnboardingContainer } from "../../container/OnboardingContainer";
import { OnboardingSteps } from "../../utils";

export function JobPrice() {
  const navigate = useNavigate();
  const { session, refreshSession } = useAuth();
  const companySectors = session?.companySectors;
  const [payPerReferral, setPayPerReferral] = useState(false);
  const brandLocationProfile = session?.brandLocationProfile;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { isLoading: updatingCompany, mutateAsync: updateCompany } =
    useUpdateBrandLocationProfile(session?.brandLocationProfile?.id, {
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating job pay type.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        refreshSession();
      },
    });
  const [updatedSectors, setUpdatedSectors] =
    useState<UpdateSectorType | null>();

  const [paymentMode, setPaymentMode] = useState(
    PayReferralFeeSettings.DEPENDING_ON_JOB_AND_REFERRAL_PARTNER
  );

  const [
    payReferralFeeAfterJobCompletion,
    setPayReferralFeeAfterJobCompletion,
  ] = useState(false);

  const { mutateAsync: updateSectors, isLoading: isUpdatingSectors } =
    useUpdateSectors({
      onSuccess: () => {
        trackEvent(AnalyticsEvents.COMPANY_ONBOARDING_SECTOR_JOB_PRICE_ADDED, {
          updatedSectorJobPrices: updatedSectors
            ? Object.values(updatedSectors)
            : {},
          companyId: session?.company?.id,
          source: "NEXT_BUTTON",
        });
        refreshSession();
        navigate(OnboardingSteps[ONBOARDING_STAGES.JOB_PRICE].nextStepRoute);
      },
      onError: () => {
        enqueueSnackbar({
          message: "Unable to save sector changes.",
          variant: "error",
        });
      },
    });

  const onNext = async () => {
    await updateCompany({
      payReferralFeeSettings: paymentMode,
      payReferralFeeAfterJobIsDone: payReferralFeeAfterJobCompletion,
      payPerReferral,
    });
    if (updatedSectors) {
      await updateSectors(Object.values(updatedSectors));
      return;
    }

    navigate(OnboardingSteps[ONBOARDING_STAGES.JOB_PRICE].nextStepRoute);
  };

  const handlePaymentModehange = (value: PayReferralFeeSettings) => {
    setPaymentMode(value);
  };

  useEffect(() => {
    if (brandLocationProfile?.payReferralFeeSettings) {
      setPaymentMode(brandLocationProfile?.payReferralFeeSettings);
    }
    if (brandLocationProfile?.payPerReferral) {
      setPayPerReferral(brandLocationProfile?.payPerReferral);
    }
    setPayReferralFeeAfterJobCompletion(
      !!brandLocationProfile?.payReferralFeeAfterJobIsDone
    );
  }, [brandLocationProfile]);

  console.log({ updatedSectors });

  useEffect(() => {
    if (companySectors) {
      const newSectors = {};

      for (const sector of companySectors) {
        newSectors[sector.id] = {
          sectorId: sector.id,
          buyingPrice: sector.buyingPrice,
          percentagePrice: sector.percentagePrice,
          leadPrice: sector.leadPrice,
        };
      }
      setUpdatedSectors(newSectors);
    }
  }, [companySectors]);

  const isPayPerReferralDisabled =
    payPerReferral && !payReferralFeeAfterJobCompletion;
  const isPayReferralFeeAfterJobCompletionDisabled =
    payReferralFeeAfterJobCompletion && !payPerReferral;

  const isContinueDisabled = () => {
    if (payReferralFeeAfterJobCompletion) {
      if (
        paymentMode === PayReferralFeeSettings.FIXED_AMOUNT &&
        !!(
          updatedSectors &&
          Object.values(updatedSectors).some(sector => !sector.buyingPrice)
        )
      ) {
        return true;
      }
      if (
        paymentMode === PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE &&
        !!(
          updatedSectors &&
          Object.values(updatedSectors).some(sector => !sector.percentagePrice)
        )
      ) {
        return true;
      }
    }
    if (
      payPerReferral &&
      !!(
        updatedSectors &&
        Object.values(updatedSectors).some(sector => !sector.leadPrice)
      )
    ) {
      return true;
    }
    return false;
  };

  const sectorPriceForm = (
    <>
      <Box width={"100%"} px={isMobile ? 0 : 1}>
        <Typography gutterBottom variant="h6">
          Type of Payment
        </Typography>
        {updatingCompany || isUpdatingSectors ? (
          <Box py={3} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box display={"flex"} flexDirection={"column"} gap={2} mt={2}>
            <PayPerReferral
              disabled={isPayPerReferralDisabled}
              updatedSectors={updatedSectors}
              setUpdatedSectors={setUpdatedSectors}
              payPerReferral={payPerReferral}
              setPayPerReferral={setPayPerReferral}
            />
            <PayAfterJobCompletion
              disabled={isPayReferralFeeAfterJobCompletionDisabled}
              paymentMode={paymentMode}
              setPaymentMode={handlePaymentModehange}
              updatedSectors={updatedSectors}
              setUpdatedSectors={setUpdatedSectors}
              payReferralFeeAfterJobCompletion={
                payReferralFeeAfterJobCompletion
              }
              setPayReferralFeeAfterJobCompletion={
                setPayReferralFeeAfterJobCompletion
              }
            />
          </Box>
        )}
      </Box>
      {isMobile ? null : (
        <Card
          sx={{
            display: "flex",
            px: 3,
            mt: 1,
            py: 2,
            bgcolor: tradeEngageColors.darkBlue,
            color: "white",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            alignSelf: "stretch",
            overflow: "visible",
            gap: 2,
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "21.98px",
              wordWrap: "break-word",
            }}
          >
            You and your technicians offset the cost of buying jobs on
            TradeEngage by making referrals.
          </Typography>
          <Typography
            sx={{
              color: "#16DB65",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "21.6px",
              wordWrap: "break-word",
            }}
          >
            Let&apos;s set up your outbound referrals next.
          </Typography>
        </Card>
      )}
    </>
  );

  const priceAccordion = (
    <>
      {" "}
      <IconButton
        sx={{
          bgcolor: "#16DB65",
          color: "white",
          borderRadius: "20px",
          width: "40px",
          height: "40px",
          my: 1,
        }}
      >
        <EmojiObjectsOutlinedIcon />
      </IconButton>
      <Accordion
        defaultExpanded
        sx={{
          width: "100%",
          my: "0 !important",
          mx: "0 !important",
          bgcolor: tradeEngageColors.darkBlue,
          "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
            height: "40px",
            minHeight: "40px",
          },
          "& .MuiAccordionSummary-root": {
            px: 0,
          },
          "& .MuiAccordionDetails-root": {
            pt: 0,
            pb: 2,
            px: 0,
          },
          "&:before": {
            position: "absolute",
            left: 0,
            top: "-1px",
            right: 0,
            height: "0.2px",
            content: '""',
            opacity: 1,
            bgcolor: "gray",
            "-webkit-transition":
              "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            transition:
              "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                cursor: "pointer",
              }}
            />
          }
          sx={{
            "& .MuiAccordionSummary-content.Mui-expanded": {
              my: 1,
              mx: 0,
            },
          }}
        >
          <Typography
            sx={{
              color: "white",
              "&:hover": { color: "#16DB65" },
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "21.98px",
              wordWrap: "break-word",
            }}
          >
            What is a Referral Fee?
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .MuiAccordionDetails-root": {
              p: 0,
            },
          }}
        >
          <Typography
            sx={{
              opacity: 0.7,
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: 0.15,
              wordWrap: "break-word",
            }}
          >
            This is the referral fee you pay service providers and their
            technicians who source and send you jobs. We recommend 5% of the
            job’s total estimate, or at least $50, for most service categories.
            This motivates your referral partners and their teams. You only pay
            the Referral Fee once you approve the job.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded
        sx={{
          width: "100%",
          my: "0 !important",
          mx: "0 !important",
          bgcolor: tradeEngageColors.darkBlue,
          "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
            height: "40px",
            minHeight: "40px",
          },
          "& .MuiAccordionSummary-root": {
            px: 0,
          },
          "& .MuiAccordionDetails-root": {
            pt: 0,
            pb: 2,
            px: 0,
          },
          "&:before": {
            position: "absolute",
            left: 0,
            top: "-1px",
            right: 0,
            height: "0.2px",
            content: '""',
            opacity: 1,
            bgcolor: "gray",
            "-webkit-transition":
              "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            transition:
              "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                cursor: "pointer",
              }}
            />
          }
          sx={{
            "& .MuiAccordionSummary-content.Mui-expanded": {
              my: 1,
              mx: 0,
            },
          }}
        >
          <Typography
            sx={{
              color: "white",
              "&:hover": { color: "#16DB65" },
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "21.98px",
              wordWrap: "break-word",
            }}
          >
            What is a Job Price?
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .MuiAccordionDetails-root": {
              p: 0,
            },
          }}
        >
          <Typography
            sx={{
              opacity: 0.7,
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: 0.15,
              wordWrap: "break-word",
            }}
          >
            This is the fee you pay service providers and their technicians who
            source and send you jobs based on the conversion and sale price of
            job. We recommend 5% of the job’s total estimate, or at least $50,
            for most service categories. This motivates your referral partners
            and their teams. You only pay the Job Price once the job is
            complete.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded
        sx={{
          width: "100%",
          my: "0 !important",
          mx: "0 !important",
          bgcolor: tradeEngageColors.darkBlue,
          "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
            height: "40px",
            minHeight: "40px",
          },
          "& .MuiAccordionSummary-root": {
            px: 0,
          },
          "& .MuiAccordionDetails-root": {
            pt: 0,
            pb: 2,
            px: 0,
            width: "100% ",
          },
          "&:before": {
            position: "absolute",
            left: 0,
            top: "-1px",
            right: 0,
            height: "0.2px",
            content: '""',
            opacity: 1,
            bgcolor: "gray",
            "-webkit-transition":
              "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            transition:
              "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                cursor: "pointer",
              }}
            />
          }
          sx={{
            "& .MuiAccordionSummary-content.Mui-expanded": {
              my: 1,
              mx: 0,
            },
          }}
        >
          <Typography
            sx={{
              color: "white",
              "&:hover": { color: "#16DB65" },
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "21.98px",
              wordWrap: "break-word",
            }}
          >
            Exclusivity Period
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .MuiAccordionDetails-root": {
              p: 0,
            },
          }}
        >
          <Typography
            sx={{
              opacity: 0.7,
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: 0.15,
              wordWrap: "break-word",
            }}
          >
            Jobs referred to you are offered exclusively to you for a limited
            time. If you buy the job, it’s exclusively yours.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded
        sx={{
          my: "0 !important",
          mx: "0 !important",
          width: "100%",
          bgcolor: tradeEngageColors.darkBlue,
          "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
            height: "40px",
            minHeight: "40px",
          },
          "& .MuiAccordionSummary-root": {
            px: 0,
          },
          "& .MuiAccordionDetails-root": {
            pt: 0,
            pb: 2,
            px: 0,
          },
          "&:before": {
            position: "absolute",
            left: 0,
            top: "-1px",
            right: 0,
            height: "0.2px",
            content: '""',
            opacity: 1,
            bgcolor: "gray",
            "-webkit-transition":
              "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            transition:
              "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                cursor: "pointer",
              }}
            />
          }
          sx={{
            "& .MuiAccordionSummary-content.Mui-expanded": {
              my: 1,
              mx: 0,
            },
          }}
        >
          <Typography
            sx={{
              color: "white",
              "&:hover": { color: "#16DB65" },
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "21.98px",
              wordWrap: "break-word",
            }}
          >
            Benefits of Referrals on TradeEngage
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .MuiAccordionDetails-root": {
              p: 0,
            },
          }}
        >
          <Typography
            sx={{
              opacity: 0.7,
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: 0.15,
              wordWrap: "break-word",
            }}
          >
            1. Enhanced trust and accountability so that you’re not the only one
            making referrals in the relationship
          </Typography>
          <Typography
            sx={{
              opacity: 0.7,
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: 0.15,
              wordWrap: "break-word",
            }}
          >
            2. Validation as each job is approved by the Homeowner before you
            buy it
          </Typography>
          <Typography
            sx={{
              opacity: 0.7,
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: 0.15,
              wordWrap: "break-word",
            }}
          >
            3. No more check cutting or administrative burdens! Instantly
            pay/receive referral commission on TradeEngage
          </Typography>
          <Typography
            sx={{
              opacity: 0.7,
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: 0.15,
              wordWrap: "break-word",
            }}
          >
            4. Increased transparency so you can be sure technicians get paid
            for the jobs they refer to you
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );

  return (
    <OnboardingContainer
      heading="Set Your Job Price"
      subheading="You won’t pay until you review and approve a job referred to you. Job Prices can be changed later from your Settings."
    >
      <Box
        display={"flex"}
        sx={{
          overflow: "auto",
          flexGrow: 1,
          gap: 2,
          ...(isMobile ? { padding: "8px 8px" } : { padding: "8px 24px" }),
          background: "#F3F4F6",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {isMobile ? (
          <Card
            sx={{
              display: "flex",
              px: 2,
              py: 3,
              borderRadius: "8px",
              flexDirection: "column",
              alignItems: "flex-start",
              width: isMobile ? "100%" : "50%",
              alignSelf: "stretch",
              overflow: "auto",
            }}
          >
            {sectorPriceForm}
            <Card
              sx={{
                display: "flex",
                px: 3,
                py: 2,
                bgcolor: tradeEngageColors.darkBlue,
                color: "white",
                flexDirection: "column",
                alignItems: "flex-start",
                width: isMobile ? "100%" : "50%",
                flexGrow: 1,
                alignSelf: "stretch",
                overflow: "visible",
              }}
            >
              {priceAccordion}
            </Card>
          </Card>
        ) : (
          <>
            {" "}
            <Card
              sx={{
                display: "flex",
                px: 2,
                py: 3,
                flexDirection: "column",
                alignItems: "flex-start",
                width: isMobile ? "100%" : "50%",
                alignSelf: "stretch",
                overflow: "auto",
              }}
            >
              {sectorPriceForm}
            </Card>
            <Card
              sx={{
                display: "flex",
                px: 3,
                py: 2,
                bgcolor: tradeEngageColors.darkBlue,
                color: "white",
                flexDirection: "column",
                alignItems: "flex-start",
                width: isMobile ? "100%" : "50%",
                flexGrow: 1,
                alignSelf: "stretch",
                overflow: "auto",
              }}
            >
              {priceAccordion}
            </Card>
          </>
        )}
      </Box>
      <Stack
        spacing={2}
        mt={3}
        mb={2}
        mx={2}
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "end",
          alignSelf: "stretch",
        }}
      >
        <Button
          variant="text"
          sx={{
            px: 0,
          }}
          onClick={() => {
            trackEvent(
              AnalyticsEvents.SKIPPED_COMPANY_ONBOARDING_ADDING_SECTOR_JOB_PRICE_DATA,
              {
                companyId: session?.company?.id,
                source: "SKIP_BUTTON",
              }
            );
            navigate(
              OnboardingSteps[ONBOARDING_STAGES.JOB_PRICE].nextStepRoute
            );
          }}
        >
          Skip for Now
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            navigate(
              `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.INBOUND_PREFERENCES}`,
              { replace: true }
            )
          }
        >
          Back
        </Button>
        <LoadingButton
          loading={isUpdatingSectors}
          disabled={isContinueDisabled()}
          variant="contained"
          onClick={onNext}
        >
          Continue
        </LoadingButton>
      </Stack>
    </OnboardingContainer>
  );
}
