import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { UserTransaction } from "../types";

export const USE_GET_USER_TRANSACTIONS_URI = "/users/transactions";

export function useGetUserTransactions(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<UserTransaction>> {
  return useQuery({
    queryKey: [USE_GET_USER_TRANSACTIONS_URI],
    queryFn: async () => {
      const response = await API.get(USE_GET_USER_TRANSACTIONS_URI);
      return response.data.data;
    },
    ...options,
  });
}
