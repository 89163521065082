import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import VideoChatOutlinedIcon from "@mui/icons-material/VideoChatOutlined";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import type { FC } from "react";

import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { AppConstants } from "@/common/constants";
import { usePopover } from "@/common/hooks/use-popover";
import { CompanyPublicProfile, ExternalCompanyProfile } from "@/company/types";
import {
  getEmailsFromCompanyProfile,
  getPhonesFromCompanyProfile,
} from "@/utils";

interface MoreMenuProps {
  onMessageClick: () => void;
  onClick?: () => void;
  showToolTip?: boolean;
  companyProfile: CompanyPublicProfile | ExternalCompanyProfile;
  onToolTipClose?: () => void;
  showTradeEngageMessageOption?: boolean;
}

export const MoreMenuMessaging: FC<MoreMenuProps> = ({
  onMessageClick,
  companyProfile,
  onClick,
  showToolTip = false,
  onToolTipClose,
  showTradeEngageMessageOption = true,
}) => {
  const { session } = useAuth();
  const user = session?.user;
  const company = session?.company;
  const popover = usePopover<HTMLButtonElement>();

  const link = `${AppConstants.platformBaseUrl}`;
  const emails = getEmailsFromCompanyProfile(
    companyProfile as CompanyPublicProfile
  );
  const phones = getPhonesFromCompanyProfile(
    companyProfile as CompanyPublicProfile
  );

  const messageBodyEmail = `Hi,

I’m using a new referral platform that will help both of us exchange referrals easier. It also enables us to tip our field teams, directly from the platform.

Here is a link to sign-up. You can connect with us and see other local referral partners as well.

Link -> ${link}?invitationCode=${company?.invitationCode}&utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=brand_location_invitation_reminder&utm_medium=email

Give me a call or reach out with any questions, as I see a lot of potential for us and our teams.

Best,
${user?.firstName}`;

  const messageBodySms = `Hi,

I’m using a new referral platform that will help both of us exchange referrals easier. It also enables us to tip our field teams, directly from the platform.

Here is a link to sign-up. You can connect with us and see other local referral partners as well.

Give me a call or reach out with any questions, as I see a lot of potential for us and our teams.

Link -> ${link}?invitationCode=${company?.invitationCode}&utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=brand_location_invitation_reminder&utm_medium=sms`;

  const handleMailClick = () => {
    const emailSubject = encodeURIComponent(
      "Connect on TradeEngage to Receive Job Referrals"
    );
    const emailBody = encodeURIComponent(messageBodyEmail);
    const mailtoLink = `mailto:${emails?.join(
      ","
    )}?cc=support@tradeengage.com&subject=${emailSubject}&body=${emailBody}`;
    window.location.href = mailtoLink;
  };

  const handleSMSClick = () => {
    const body = encodeURIComponent(messageBodySms);
    if (navigator.userAgent.match(/Android/i)) {
      window.open(`sms:${phones?.join(",")}?&body=${body}`, "_blank");
    }
    if (navigator.userAgent.match(/iPhone/i)) {
      window.open(`sms:${phones?.join(",")}?&body=${body}`, "_blank"); // TODO: Add sms format for iOS
    }
    window.open(`sms:${phones?.join(",")}?&body=${body}`, "_blank");
  };

  return (
    <Tooltip
      arrow
      open={showToolTip}
      PopperProps={{
        modifiers: [
          {
            name: "zIndex",
            enabled: true,
            phase: "beforeWrite",
            fn: ({ state }) => {
              state.styles.popper.zIndex = 100; // Adjust the zIndex as needed
            },
          },
        ],
      }}
      title={
        <Box display={"flex"} maxWidth={"140px"}>
          <Typography variant="caption" lineHeight={1.5}>
            Follow up by sending an email or text message here!
          </Typography>
          <CloseIcon
            fontSize="small"
            sx={{
              p: 0,
              width: "16px",
              height: "16px",
              cursor: "pointer",
            }}
            onClick={event => {
              event.stopPropagation();
              onToolTipClose?.();
            }}
          />
        </Box>
      }
      componentsProps={{
        tooltip: {
          className: "bounce-tooltip", // Apply the animation class here
        },
        arrow: {
          style: { color: "#16b364" }, // Change the arrow color to match the tooltip background
        },
      }}
      placement="top"
    >
      <Button
        ref={popover.anchorRef}
        size="small"
        variant="outlined"
        startIcon={<ChatBubbleOutlineOutlinedIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          onClick?.();
          popover.handleOpen();
        }}
      >
        Send a Message
      </Button>
      <Menu
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        open={popover.open}
        PaperProps={{
          sx: {
            maxWidth: "100%",
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        onClose={event => {
          event.stopPropagation();
          popover.handleClose();
        }}
      >
        {showTradeEngageMessageOption ? (
          <MenuItem
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              trackEvent(AnalyticsEvents.CLICKED_CAMPAIGN_GET_STARTED, {
                loggedCompany: company?.name,
                loggedUserName: `${user?.firstName} ${user?.lastName}`,
                loggedCompanyId: company?.id,
                cardCompanyId: (companyProfile as CompanyPublicProfile).id,
                cardCompanyName: companyProfile.name,
                cardOwners: (
                  companyProfile as CompanyPublicProfile
                )?.owners?.map(owner => ({
                  cardOwnerName: `${owner?.firstName} ${owner?.lastName}`,
                  cardOwnerPhone: owner.phone,
                  cardOwnerEmail: owner.email,
                  cardOwnerPhoneCarrierType: owner.phoneCarrierType,
                })),
                cardCompanyBusinessEmail: (
                  companyProfile as CompanyPublicProfile
                ).profile?.businessEmail,
                cardCompanyBusinessPhone: (
                  companyProfile as CompanyPublicProfile
                ).profile?.businessEmail,
                cardCompanyBusinesPhoneCarrierType: (
                  companyProfile as CompanyPublicProfile
                ).profile?.businessPhoneCarrierType,
                type: "CHAT",
                source: "COMPANY_CARD",
              });
              onMessageClick();
              popover.handleClose();
            }}
          >
            <ListItemIcon sx={{ m: 0 }}>
              <SvgIcon>
                <QuickreplyOutlinedIcon sx={{ color: "#16B364" }} />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" fontSize={"13px"}>
                  Message on TradeEngage
                </Typography>
              }
            />
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            trackEvent(AnalyticsEvents.CLICKED_CAMPAIGN_GET_STARTED, {
              loggedCompany: company?.name,
              loggedUserName: `${user?.firstName} ${user?.lastName}`,
              loggedCompanyId: company?.id,
              cardCompanyId: (companyProfile as CompanyPublicProfile).id,
              cardCompanyName: companyProfile.name,
              cardOwners: (companyProfile as CompanyPublicProfile)?.owners?.map(
                owner => ({
                  cardOwnerName: `${owner?.firstName} ${owner?.lastName}`,
                  cardOwnerPhone: owner.phone,
                  cardOwnerEmail: owner.email,
                  cardOwnerPhoneCarrierType: owner.phoneCarrierType,
                })
              ),
              cardCompanyBusinessEmail: (companyProfile as CompanyPublicProfile)
                .profile?.businessEmail,
              cardCompanyBusinessPhone: (companyProfile as CompanyPublicProfile)
                .profile?.businessEmail,
              cardCompanyBusinesPhoneCarrierType: (
                companyProfile as CompanyPublicProfile
              ).profile?.businessPhoneCarrierType,
              type: "EMAIL",
              source: "COMPANY_CARD",
            });
            handleMailClick();
            popover.handleClose();
          }}
        >
          <ListItemIcon sx={{ m: 0 }}>
            <SvgIcon>
              <MarkunreadOutlinedIcon sx={{ color: "#16B364" }} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" fontSize={"13px"}>
                Send Email
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            trackEvent(AnalyticsEvents.CLICKED_CAMPAIGN_GET_STARTED, {
              loggedCompany: company?.name,
              loggedUserName: `${user?.firstName} ${user?.lastName}`,
              loggedCompanyId: company?.id,
              cardCompanyId: (companyProfile as CompanyPublicProfile).id,
              cardCompanyName: companyProfile.name,
              cardOwners: (companyProfile as CompanyPublicProfile)?.owners?.map(
                owner => ({
                  cardOwnerName: `${owner?.firstName} ${owner?.lastName}`,
                  cardOwnerPhone: owner.phone,
                  cardOwnerEmail: owner.email,
                  cardOwnerPhoneCarrierType: owner.phoneCarrierType,
                })
              ),
              cardCompanyBusinessEmail: (companyProfile as CompanyPublicProfile)
                .profile?.businessEmail,
              cardCompanyBusinessPhone: (companyProfile as CompanyPublicProfile)
                .profile?.businessEmail,
              cardCompanyBusinesPhoneCarrierType: (
                companyProfile as CompanyPublicProfile
              ).profile?.businessPhoneCarrierType,
              type: "SMS",
              source: "COMPANY_CARD",
            });
            handleSMSClick();
            popover.handleClose();
          }}
        >
          <ListItemIcon sx={{ m: 0 }}>
            <SvgIcon>
              <SmsOutlinedIcon sx={{ color: "#16B364" }} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" fontSize={"13px"}>
                Send Text Message
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem disabled={true}>
          <ListItemIcon sx={{ m: 0 }}>
            <SvgIcon>
              <VideoChatOutlinedIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" fontSize={"13px"}>
                Video message coming soon!
              </Typography>
            }
          />
        </MenuItem>
      </Menu>
    </Tooltip>
  );
};
