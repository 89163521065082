import { Close as CloseIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  debounce,
  Dialog,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useMemo, useState } from "react";
import * as yup from "yup";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useGetAllBrandLocations } from "@/company/api/useGetAllBrandLocations";
import { Company } from "@/company/types";

import { useCreateReferral } from "./api/useCreateReferral";
import { SEARCH_MINIMUM_CHAR_COUNT } from "./constants";

export interface CreateReferralDialogProps {
  open: boolean;
  onClickConfirm: () => void;
  onClickCancel: () => void;
  onClickClose: () => void;
  refetch: () => void;
}

const companySchema = yup.object().shape({
  id: yup.string().required("Company is required"),
});

const validationSchema = yup.object({
  referredCompany: companySchema,
  referringCompany: companySchema,
});
export function CreateReferralDialog(props: CreateReferralDialogProps) {
  const { open, onClickCancel, onClickClose, refetch } = props;

  const { mutateAsync: createReferral, isLoading } = useCreateReferral({
    onSuccess: () => {
      enqueueSnackbar({
        message: "Created referral successfully.",
        variant: "success",
      });
      onClickClose();
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while creating referral.";
      enqueueSnackbar({
        message,
        variant: "error",
        includeContactSupportText: false,
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      sector: "",
      referredCompany: null,
      referringCompany: null,
      order: undefined,
      user: null,
    },
    validationSchema: validationSchema,
    onSubmit: async ({ sector, referredCompany, referringCompany, order }) => {
      createReferral({
        referringCompanyId: referringCompany?.id,
        referredCompanyId: referredCompany?.id,
      });
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const [keyword, setKeyword] = useState("");
  const debouncedSetter = useMemo(
    () => debounce((keyword: string) => setKeyword(keyword), 500),
    []
  );

  const [referredKeyword, setReferredKeyword] = useState("");

  const debouncedReferredSetter = useMemo(
    () =>
      debounce(
        (referredKeyword: string) => setReferredKeyword(referredKeyword),
        500
      ),
    []
  );

  const { data: allbrandLocations, isLoading: isLoadingBrandLocations } =
    useGetAllBrandLocations(keyword, { enabled: !!keyword });

  const {
    data: allReferredbrandLocations,
    isLoading: isLoadingReferredBrandLocations,
  } = useGetAllBrandLocations(referredKeyword, { enabled: !!referredKeyword });

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      sx={{ p: 2 }}
      onClose={() => onClickClose()}
    >
      <Box width="100%" p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Box>
            <Typography gutterBottom variant="h5">
              Create Referral
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary">
              Select the referrer and referred companies from dropdown.
            </Typography>
          </Box>
          <IconButton onClick={() => onClickClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Autocomplete
          sx={{ width: "100%" }}
          id="referrer"
          options={allbrandLocations ?? []}
          value={formik.values.referringCompany}
          getOptionLabel={option => `id=${option.id}, name=${option.name}`}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              error={
                formik.touched.referringCompany &&
                Boolean(formik.errors.referringCompany)
              }
              helperText={
                formik.touched.referringCompany &&
                formik.errors.referringCompany
              }
              value={formik.values.referringCompany}
              margin="normal"
              InputLabelProps={{
                style: { marginBottom: "8px" },
              }}
              label="Select Referrer Company"
            />
          )}
          renderOption={(props, option, { selected }) => (
            <MenuItem {...props} key={option} value={option}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                {
                  <Typography>{`id=${option.id}, name=${option.name}`}</Typography>
                }
                {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
              </Box>
            </MenuItem>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                size="small"
                label={`id=${option.id}, name=${option.name}`}
                {...getTagProps({ index })}
                variant="outlined"
              />
            ))
          }
          onInputChange={(_: unknown, newInputValue: string) => {
            if (newInputValue.length >= SEARCH_MINIMUM_CHAR_COUNT) {
              debouncedSetter(newInputValue);
            }
          }}
          onChange={(_: SyntheticEvent, newValue) =>
            formik.setFieldValue("referringCompany", newValue as Company)
          }
        />
        <Autocomplete
          sx={{ width: "100%" }}
          id="referred"
          options={allReferredbrandLocations ?? []}
          value={formik.values.referredCompany}
          getOptionLabel={option => `id=${option.id}, name=${option.name}`}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              error={
                formik.touched.referredCompany &&
                Boolean(formik.errors.referredCompany)
              }
              helperText={
                formik.touched.referredCompany && formik.errors.referredCompany
              }
              value={formik.values.referredCompany}
              margin="normal"
              InputLabelProps={{
                style: { marginBottom: "8px" },
              }}
              label="Select Referred Company"
            />
          )}
          renderOption={(props, option, { selected }) => (
            <MenuItem {...props} key={option} value={option}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                {
                  <Typography>{`id=${option.id}, name=${option.name}`}</Typography>
                }
                {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
              </Box>
            </MenuItem>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                size="small"
                label={`id=${option.id}, name=${option.name}`}
                {...getTagProps({ index })}
                variant="outlined"
              />
            ))
          }
          onInputChange={(_: unknown, newInputValue: string) => {
            if (newInputValue.length >= SEARCH_MINIMUM_CHAR_COUNT) {
              debouncedReferredSetter(newInputValue);
            }
          }}
          onChange={(_: SyntheticEvent, newValue: string | null) =>
            formik.setFieldValue("referredCompany", newValue as Company)
          }
        />
        <Box
          width="100%"
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={3}
          mt={2}
        >
          <Button color="inherit" variant="outlined" onClick={onClickCancel}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={
              !formik.isValid ||
              (!formik.dirty && !Object.keys(formik.touched).length)
            }
            onClick={() => formik.handleSubmit()}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
}
