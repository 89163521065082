import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import API from "@/common/api";

const createPatchRequests = ({
  companyId,
  recommendedPartnerIds,
}: {
  companyId: number;
  recommendedPartnerIds: Array<number>;
}) => {
  return recommendedPartnerIds.map(id => {
    const companyPayload = { recommended_company_id: id }; // Assuming you want to change key names to underscored
    const updateCompanyUri = `/internal-admin/companies/brand-location/${companyId}/recommended-partners`;
    return API.post(updateCompanyUri, companyPayload);
  });
};

export function useCreateRecommendedPartners(
  options: UseMutationOptions<
    void,
    AxiosError,
    { companyId: number; recommendedPartnerIds: Array<number> }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { companyId: number; recommendedPartnerIds: Array<number> }
> {
  return useMutation({
    mutationFn: async ({ companyId, recommendedPartnerIds }) => {
      const patchRequests = createPatchRequests({
        companyId,
        recommendedPartnerIds,
      });
      await axios.all(patchRequests);
    },
    ...options,
  });
}
