import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { JobSectors } from "@/company/jobs/constants";
import { DefaultCompanyGroups } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

export const EditTextarea = (props: Record<string, any>) => {
  const { colDef } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();

  const handleRef = (el: any) => {
    setAnchorEl(el);
  };

  return (
    <Box>
      <Box
        ref={handleRef}
        style={{
          maxHeight: "80px",
          width: colDef.computedWidth,
          display: "block",
          marginTop: "48px",
          top: 48,
          overflow: "auto",
          zIndex: 100,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="top-start">
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <Autocomplete
              multiple
              sx={{ maxWidth: "200px" }}
              value={props.value}
              options={Object.keys(DefaultCompanyGroups)}
              // value={formik.values.sectors}
              getOptionLabel={option => option}
              renderInput={params => (
                <TextField {...params} variant="standard" margin="normal" />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} key={option} value={option}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    {<Typography>{option.replace("_", " ")}</Typography>}
                    {selected ? (
                      <CheckCircleOutlineIcon color="primary" />
                    ) : null}
                  </Box>
                </MenuItem>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                    variant="outlined"
                  />
                ))
              }
              onChange={(e, nv) => {
                props.api.setEditCellValue({ ...props, value: nv });
              }}
            />
          </Paper>
        </Popper>
      )}
    </Box>
  );
};

export const EditJobSectors = props => {
  const { id, field, value, colDef, api } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();

  const handleRef = el => {
    setAnchorEl(el);
  };

  return (
    <div>
      <div
        ref={handleRef}
        style={{
          maxHeight: "80px",
          width: colDef.computedWidth,
          display: "block",
          // position: "absolute",
          marginTop: "48px",
          top: 48,
          overflow: "auto",
          zIndex: 100,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="top-start">
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <Autocomplete
              multiple
              sx={{ maxWidth: "200px" }}
              value={props.value}
              options={Object.keys(JobSectors).map(sector => {
                if (sector === JobSectors.HVAC) {
                  return sector.toString();
                }
                return snakeCaseJobSectors[sector.toString() as JobSectors];
              })}
              // value={formik.values.sectors}
              getOptionLabel={option => option}
              renderInput={params => (
                <TextField {...params} variant="standard" margin="normal" />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} key={option} value={option}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    {<Typography>{option}</Typography>}
                    {selected ? (
                      <CheckCircleOutlineIcon color="primary" />
                    ) : null}
                  </Box>
                </MenuItem>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                    variant="outlined"
                  />
                ))
              }
              onChange={(e, nv) => {
                props.api.setEditCellValue({ ...props, value: nv });
              }}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
};
