import { Delete } from "@mui/icons-material";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  Box,
  Breadcrumbs,
  Button,
  Drawer,
  Link,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridFilterModel,
  GridRowId,
  GridRowModesModel,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  formatDateTime,
  formatToDateTime,
} from "@/analytics/components/JobsTable";
import { useAuth } from "@/auth/context/jwt";
import { HtmlModal } from "@/common/HtmlModal";
import { SMSModal } from "@/common/SMSModal";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useCreateTableView } from "@/company/api/useCreateTableView";
import {
  ReferralInvitationInternalAdmin,
  useQueryReferralInvitationsInternalAdmin,
} from "@/company/api/useQueryReferralInvitationsInternalAdmin";
import { useTriggerReferralInvitationNotification } from "@/company/api/useTriggerReferralInvitationNotification";
import { AdminChatProvider } from "@/company/context/admin-chat-context";
import {
  CarrierType,
  CHANNEL_ONBOARDED,
  CompanyStatus,
  ConnectionStatus,
  PLATFORM_PRESENCE,
  SMS_STATUS,
} from "@/company/types";
import { getGridDateOperatorsDataGrid } from "@/utils";

import { CompanyAvatar } from "../company/components/company-avatar/CompanyAvatar";
import { useDeleteReferralInvitations } from "./api/useDeleteReferralInvitations";
import { EditNotesArea } from "./common/EditNotesArea";
import ConfirmationDialog from "./ConfirmationDialog";
import { InternalAdminCompanyDrawer } from "./InternalAdminCompanyDrawer";
import { CreateTableViewDialog } from "./saved-searches/CreateTableViewDialog";
import { TableType } from "./saved-searches/SavedSearchTable";
import { handleColumnOrderChange } from "./util";
import {
  CustomToolbar,
  drawerWidth,
  formatDate,
  getGridStringOperatorsDataGrid,
  Main,
} from "./utils";

export default function ReferralInvitationsInternalAdmin({
  name,
  preFilledColumns,
  payload,
}: {
  name?: string;
  preFilledColumns?: Array<any>;
  payload?: Record<string, any>;
}) {
  const [rows, setRows] = React.useState<
    Array<ReferralInvitationInternalAdmin>
  >([]);

  const { session } = useAuth();
  const user = session?.user;
  const [serverSorting, setServerSorting] = useState<
    undefined | GridSortModel
  >();
  const [serverFiltering, setServerFiltering] = useState<
    undefined | GridFilterModel
  >();

  const [companyProfileId, setCompanyProfileId] = useState<number | null>();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const handleSortModelChange = (model: GridSortModel) => {
    setServerSorting(model);
  };

  const [createTableViewDialogOpen, setCreateTableViewDialogOpen] =
    useState(false);

  const [triggerNotificationRowId, setTriggerNotificationRowId] = useState<
    GridRowId | null | undefined | number
  >(null);

  const [deleteInviteId, setDeleteInviteId] = useState<
    GridRowId | null | undefined | number
  >(null);

  const [emailHtml, setEmailHtml] = useState(null);
  const [smsMessage, setSMSMessage] = useState(null);

  const handleFilterModelChange = (model: GridFilterModel) => {
    setServerFiltering(model);
  };

  const navigate = useNavigate();

  const { mutateAsync: triggerNotification } =
    useTriggerReferralInvitationNotification({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while triggering notification.";
        enqueueSnackbar(message, {
          variant: "error",
        });
        setTriggerNotificationRowId(null);
      },
      onSuccess() {
        enqueueSnackbar({
          message: "Notifications sent successfully.",
          variant: "success",
        });
        setTriggerNotificationRowId(null);
      },
    });

  const { mutateAsync: createTableView, isLoading: creatingTableView } =
    useCreateTableView({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while saving search.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess(tableView) {
        enqueueSnackbar({
          message: "Search saved successfully.",
          variant: "success",
        });
        navigate(`/admin/saved-searches/${tableView.id}`, {
          state: { tableView },
        });
      },
    });

  const renderEditNotesArea = params => (
    <EditNotesArea {...params} user={user} />
  );

  const [expanded, setExpanded] = React.useState(false);

  const processRowUpdate = (newRow, oldRow) => {
    const rowId = newRow.id;

    unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }
    return newRow;
  };

  const {
    data: referralInvitations,
    isFetching,
    isLoading,
    refetch,
  } = useQueryReferralInvitationsInternalAdmin({
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: deleteReferralInvitations, isLoading: isDeleting } =
    useDeleteReferralInvitations({
      onSuccess: () => {
        refetch();
        enqueueSnackbar({
          message: "Invitations deleted successfully.",
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while deleting invitations.`,
          variant: "error",
        });
      },
    });

  useEffect(() => {
    setRows(referralInvitations ?? []);
  }, [referralInvitations]);

  let columns: GridColDef[] = [
    {
      field: GRID_CHECKBOX_SELECTION_COL_DEF.field,
      headerName: "Checkbox Selection",
      width: 50,
      hideable: false,
    }, // Checkbox selection column

    {
      field: "id",
      headerName: "Referral Invitation Id",
      width: 200,
      renderCell: params => params.row.id.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      type: "number",
    },
    {
      field: "manual",
      headerName: "Manual Invitation?",
      width: 200,
      type: "boolean",
      description:
        "Whether this invitation was manually sent to an email, and not to a company on the database. X means it's not manual, ✔️ means it is manual. A manual invitation is sent when the user inputs the receiving email manually instead of inviting a company from the platform.",
    },
    {
      field: "manualEmailInvited",
      headerName: "Manual Email",
      width: 200,
      type: "string",
      description: "The email the invitation was manually sent to",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      valueFormatter: formatDate,
      width: 200,
    },
    {
      field: "acceptedAt",
      headerName: "Accepted At",
      type: "date",
      valueFormatter: formatDate,
      width: 200,
    },

    {
      field: "invitedCompanyId",
      headerName: "Invited Company Id",
      renderCell: params => params.row.invitedCompanyId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      width: 200,
      type: "number",
    },
    {
      field: "invitedCompany-logo",
      headerName: "Invited Company Logo",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <CompanyAvatar
              logo={params.row.invitedCompany?.logoUrl || undefined}
              name={params.row.invitedCompany?.name}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
          </Box>
        );
      },
      valueGetter: (value, row) => row.invitedCompany?.logoUrl,
      valueSetter: (value, row) => {
        return {
          ...row,
          invitedCompany: {
            ...row.invitedCompany,
            logoUrl: value,
          },
        };
      },
    },
    {
      field: "invitedCompany-name",
      headerName: "Invited Company Name",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row.invitedCompany?.name,
      valueSetter: (value, row) => {
        return {
          ...row,
          invitedCompany: {
            ...row.invitedCompany,
            name: value,
          },
        };
      },
    },
    {
      field: "invitedCompanyBusinessEmail",
      headerName: "Invited Company Business Email",
      width: 200,
      type: "string",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "invitedCompanyBusinessPhone",
      headerName: "Invited Company Business Phone",
      width: 200,
      type: "string",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "invitedCompanyBusinessPhoneCarrierType",
      headerName: "Invited Company Business Phone Carrier Type",
      width: 200,
      type: "singleSelect",
      headerAlign: "left",
      editable: false,
      valueOptions: [...Object.keys(CarrierType)],
    },

    {
      field: "invitedCompanyBrandId",
      headerName: "Invited Company Brand Id",
      width: 150,
      type: "number",
      renderCell: params => params.row.invitedCompanyBrand?.id.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      valueGetter: (value, row) => row.invitedCompanyBrand?.id,
    },
    {
      field: "invitedCompanyBrandName",
      headerName: "Invited Company Brand Name",
      width: 150,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row.invitedCompanyBrand?.name,
    },
    {
      field: "invitedCompanyFamilyOfBrandsId",
      headerName: "Invited Company Family Of Brands Id",
      width: 150,
      type: "number",
      renderCell: params =>
        params.row.invitedCompanyFamilyOfBrands?.id.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      valueGetter: (value, row) => row.invitedCompanyFamilyOfBrands?.id,
    },
    {
      field: "invitedCompanyFamilyOfBrandsName",
      headerName: "Invited Company Family Of Brands Name",
      width: 150,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row.invitedCompanyFamilyOfBrands?.name,
    },
    {
      field: "invitedCompanyPrivateEquityId",
      headerName: "Invited Company Private Equity Id",
      width: 150,
      type: "number",
      renderCell: params =>
        params.row.invitedCompanyPrivateEquity?.id.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      valueGetter: (value, row) => row.invitedCompanyPrivateEquity?.id,
    },
    {
      field: "invitedCompanyPrivateEquityName",
      headerName: "Invited Company Private Equity Name",
      width: 150,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row.invitedCompanyPrivateEquity?.name,
    },
    {
      field: "invitedCompany-platformPresence",
      headerName: "Invited Company Platform Presence",
      width: 200,
      type: "singleSelect",
      headerAlign: "left",
      valueGetter: (value, row) => row.invitedCompany?.platformPresence,
      valueSetter: (value, row) => {
        return {
          ...row,
          invitedCompany: {
            ...row.invitedCompany,
            platformPresence: value.replaceAll(" ", "_"),
          },
        };
      },
      valueOptions: [...Object.keys(PLATFORM_PRESENCE)],
    },
    {
      field: "invitedCompanyDateMarkedOnPlatform",
      headerName: "Date Invited Company Was Marked On PLatform",
      type: "date",
      description:
        "Date the company's platform presence was marked as ON_PLATFORM on the platform",
      width: 200,
      valueGetter: (value, row) =>
        formatToDateTime(row?.invitedCompanyDateMarkedOnPlatform),
      renderCell: params =>
        formatDateTime(params.row?.invitedCompanyDateMarkedOnPlatform),
    },
    {
      field: "invitedCompanyDateOnboardingCompleted",
      headerName: "Date Invited Company Onboarded",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.invitedCompanyDateOnboardingCompleted),
      renderCell: params =>
        formatDateTime(params.row?.invitedCompanyDateOnboardingCompleted),
    },
    {
      field: "wasInvitedCompanyOffPlatformAtTheTimeOfTheInvitation",
      headerName: "Invited Company was Off Platform At Time of Invitation",
      width: 200,
      type: "boolean",
      description:
        "Whether the invited company was off platform at the time of the invitation",
    },
    {
      field: "invitedCompanyStreet",
      headerName: "Invited Company Street",
      width: 200,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "invitedCompanyZipCode",
      headerName: "Invited Company Zip Code",
      width: 200,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "invitedCompanyCity",
      headerName: "Invited Company City",
      width: 200,
      type: "string",
      headerAlign: "left",
    },

    {
      field: "invitedCompanyState",
      headerName: "Invited Company State",
      width: 200,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "invitedCompanyStatus",
      headerName: "Invited Company Status",
      width: 200,
      type: "singleSelect",
      headerAlign: "left",
      valueOptions: [...Object.keys(CompanyStatus)],
      valueGetter: (value, row) => row.invitedCompany?.status,
    },
    {
      field: "activatedAt",
      headerName: "Invited Company Date Activated",
      type: "date",
      valueFormatter: formatDate,
      width: 200,
      description:
        "Date the company's status was marked as ACTIVE on the platform",
      valueGetter: (value, row) => row.invitedCompany?.activatedAt,
    },
    {
      field: "invitedCompanyChannelOnboarded",
      headerName: "Invited Company Channel Onboarded",
      width: 200,
      type: "singleSelect",
      headerAlign: "left",
      valueOptions: [...Object.keys(CHANNEL_ONBOARDED)],
    },

    {
      field: "invitedOwnersInformationName",
      headerName: "Invited Company Owners Name",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.invitedOwnersInformation?.[0]?.[0],
      renderCell: params => params.row?.invitedOwnersInformation?.[0]?.[0],
    },
    {
      field: "invitedOwnersInformationPhone",
      headerName: "Invited Company Owners Phone",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.invitedOwnersInformation?.[0]?.[1],
      renderCell: params => params.row?.invitedOwnersInformation?.[0]?.[1],
    },
    {
      field: "invitedOwnersInformationPhoneCarrierType",
      headerName: "Invited Company Owners Phone Carrier Type",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.invitedOwnersInformation?.[0]?.[2],
      renderCell: params => params.row?.invitedOwnersInformation?.[0]?.[2],
    },
    {
      field: "invitedOwnersInformationEmail",
      headerName: "Invited Company Owners Email",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row?.invitedOwnersInformation?.[0]?.[3],
      renderCell: params => params.row?.invitedOwnersInformation?.[0]?.[3],
    },
    {
      field: "numberOfInvitationsReceived",
      headerName: "Invited Company No. of Invitations Received",
      width: 200,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "numberOfPendingInvitationsReceived",
      headerName: "Invited Company No. of Pending Invitations Received",
      width: 200,
      type: "number",
      headerAlign: "left",
    },

    {
      field: "numberOfDeclinedInvitationsReceived",
      headerName: "Invited Company No. of Declined Invitations Received",
      width: 200,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "numberOfAcceptedInvitationsReceived",
      headerName: "Invited Company No. of Accepted Invitations Received",
      width: 200,
      type: "number",
      headerAlign: "left",
    },

    {
      field: "numberOfReferralInvitationsSent",
      headerName: "Invited Company No. of Referral Invitations Sent",
      width: 200,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "numberOfPendingInvitationsSent",
      headerName: "Invited Company No. of Pending Invitations Sent",
      width: 200,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "numberOfDeclinedInvitationsSent",
      headerName: "Invited Company No. of Declined Invitations Sent",
      width: 200,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "numberOfAcceptedInvitationsSent",
      headerName: "Invited Company No. of Accepted Invitations Sent",
      width: 200,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "invitedCompany-notes",
      headerName: "Invited Company Notes",
      width: 200,
      type: "string",
      headerAlign: "left",
      editable: true,
      renderEditCell: renderEditNotesArea,
      valueGetter: (value, row) => row.invitedCompany?.notes,
      valueSetter: (value, row) => {
        return {
          ...row,
          invitedCompany: {
            ...row.invitedCompany,
            notes: value,
          },
        };
      },
    },
    {
      field:
        "wasInvitedCompanyRecommendedPartnerOfInvitingCompanyAtTheTimeOfTheInvitation",
      headerName: "Invited Company was Recommended Partner",
      width: 200,
      type: "boolean",
      editable: false,
      description:
        "Whether the invited company was recommended partner of the inviting company at the time of the invitation",
    },
    {
      field: "firstInvitingCompanyId",
      headerName: "First Inviting Company Id of Invited Company",
      width: 200,
      description:
        "First company that sent the first referral invitation to the invited company of this referral invitation",
      type: "number",
      renderCell: params => params.row.firstInvitingCompany?.id.toString(),
      valueGetter: (value, row) => row.firstInvitingCompany?.id,
      valueFormatter: value => value && `${value?.toString()}`,
    },
    {
      field: "firstInvitingCompanyName",
      headerName: "First Inviting Company Name of Invited Company",
      width: 200,
      description:
        "First company that sent the first referral invitation to the invited company of this referral invitation",
      type: "string",
      valueGetter: (value, row) => row.firstInvitingCompany?.name,
    },
    {
      field: "firstInvitingCompanyDate",
      headerName: "Date First Inviting Company sent Referral Invitation",
      width: 200,
      description:
        "Date of the first referral invitation the invited company received",
      type: "date",
      valueGetter: (value, row) => row.firstInvitingCompanyDate,
      valueFormatter: formatDate,
    },
    {
      field: "wasThisFirstReferralInvitationOfInvitedCompany",
      headerName:
        "Is this the first referral invitation of the invited company?",
      width: 200,
      type: "boolean",
      description:
        "Whether this referral invitation is the first referral invitation of the invited company",
    },
    {
      field: "invitingCompanyId",
      headerName: "Inviting Company Id",
      width: 200,
      renderCell: params => params.row.invitingCompanyId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      type: "number",
    },
    {
      field: "invitingCompany-logo",
      headerName: "Inviting Company Logo",
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <CompanyAvatar
              logo={params.row.invitingCompany?.logoUrl || undefined}
              name={params.row.invitingCompany?.name}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
          </Box>
        );
      },
      valueGetter: (value, row) => row.invitingCompany?.logoUrl,
      valueSetter: (value, row) => {
        return {
          ...row,
          invitingCompany: {
            ...row.invitingCompany,
            logoUrl: value,
          },
        };
      },
    },
    {
      field: "invitingCompany-name",
      headerName: "Inviting Company Name",
      width: 200,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row.invitingCompany?.name,
      valueSetter: (value, row) => {
        return {
          ...row,
          invitingCompany: {
            ...row.invitingCompany,
            name: value,
          },
        };
      },
    },
    {
      field: "invitingCompanyDateMarkedOnPlatform",
      headerName: "Date Inviting Company Was Marked On Platform",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.invitingCompanyDateMarkedOnPlatform),
      renderCell: params =>
        formatDateTime(params.row?.invitingCompanyDateMarkedOnPlatform),
    },
    {
      field: "invitingCompanyDateOnboardingCompleted",
      headerName: "Date Inviting Company Onboarded",
      width: 200,
      type: "date",
      filterOperators: getGridDateOperatorsDataGrid,
      valueGetter: (value, row) =>
        formatToDateTime(row?.invitingCompanyDateOnboardingCompleted),
      renderCell: params =>
        formatDateTime(params.row?.invitingCompanyDateOnboardingCompleted),
    },
    {
      field: "invitingCompanyBrandId",
      headerName: "Inviting Company Brand Id",
      width: 150,
      renderCell: params => params.row.invitingCompanyBrandId?.toString(),
      type: "number",
      valueFormatter: value => value && `${value?.toString()}`,
      valueGetter: (value, row) => row.invitingCompanyBrand?.id,
    },
    {
      field: "invitingCompanyBrandName",
      headerName: "Inviting Company Brand Name",
      width: 150,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row.invitingCompanyBrand?.name,
    },
    {
      field: "invitingCompanyFamilyOfBrandsId",
      headerName: "Inviting Company Family Of Brands Id",
      width: 150,
      type: "number",
      renderCell: params =>
        params.row.invitingCompanyFamilyOfBrands?.id.toString(),
      valueGetter: (value, row) => row.invitingCompanyFamilyOfBrands?.id,
      valueFormatter: value => value && `${value?.toString()}`,
    },
    {
      field: "invitingCompanyFamilyOfBrandsName",
      headerName: "Inviting Company Family Of Brands Name",
      width: 150,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row.invitingCompanyFamilyOfBrands?.name,
    },
    {
      field: "invitingCompanyPrivateEquityId",
      headerName: "Inviting Company Private Equity Id",
      width: 150,
      type: "number",
      renderCell: params =>
        params.row.invitingCompanyPrivateEquity?.id.toString(),
      valueGetter: (value, row) => row.invitingCompanyPrivateEquity?.id,
      valueFormatter: value => value && `${value?.toString()}`,
    },
    {
      field: "invitingCompanyPrivateEquityName",
      headerName: "Inviting Company Private Equity Name",
      width: 150,
      type: "string",
      headerAlign: "left",
      valueGetter: (value, row) => row.invitingCompanyPrivateEquity?.name,
    },

    {
      field: "invitingCompanyStreet",
      headerName: "Inviting Company Street",
      width: 200,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "invitingCompanyZipCode",
      headerName: "Inviting Company Zip Code",
      width: 200,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "invitingCompanyCity",
      headerName: "Inviting Company City",
      width: 200,
      type: "string",
      headerAlign: "left",
    },

    {
      field: "invitingCompanyState",
      headerName: "Inviting Company State",
      width: 200,
      type: "string",
      headerAlign: "left",
    },

    {
      field: "declinedAt",
      headerName: "Declined At",
      type: "date",
      valueFormatter: formatDate,
      width: 200,
    },
    {
      field: "totalEmailsSent",
      headerName: "Total Emails Sent",
      width: 200,
      type: "number",
      editable: false,
    },
    {
      field: "latestEmailSentAt",
      headerName: "Latest Email Sent At",
      width: 200,
      type: "date",
      editable: false,
      valueFormatter: formatDate,
    },
    {
      field: "latestEmailReceivedEmail",
      headerName: "Latest Email Received Email",
      width: 200,
      type: "string",
      editable: false,
    },
    {
      field: "latestEmailReceivedHtmlBody",
      headerName: "Latest Email Received Html Body",
      width: 200,
      type: "string",
      editable: false,
    },
    {
      field: "latestEmailReceivedSubject",
      headerName: "Latest Email Received Subject",
      width: 200,
      type: "string",
      editable: false,
    },
    {
      field: "latestEmailReceivedTemplate",
      headerName: "Latest Email Received Template",
      width: 200,
      type: "string",
      editable: false,
    },
    {
      field: "latestEmailReceivedUserId",
      headerName: "Latest Email Received User Id",
      width: 200,
      type: "number",
      renderCell: params => params.row.latestEmailReceivedUserId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      editable: false,
    },
    {
      field: "latestEmailReceivedUserName",
      headerName: "Latest Email Received User Name",
      width: 200,
      type: "string",
      editable: false,
    },
    {
      field: "latestEmailReceivedLatestEvent",
      headerName: "Latest Email Received Status",
      width: 200,
      type: "string",
      editable: false,
      description:
        "Posible values are: EMAIL_ACCEPTED,EMAIL_CLICKED,EMAIL_SPAM_COMPLAINT, EMAIL_DELIVERED,EMAIL_OPENED,,EMAIL_PERMANENT_FAILURE EMAIL_TEMPORARY_FAILURE,EMAIL_UNSUBSCRIBED",
    },
    {
      field: "latestEmailReceivedLatestEventAt",
      headerName: "Latest Email Received Latest Event At",
      width: 200,
      type: "date",
      editable: false,
      valueFormatter: formatDate,
    },
    {
      field: "totalSmsMessagesSent",
      headerName: "Total Sms Messages Sent",
      width: 200,
      type: "number",
      editable: false,
    },
    {
      field: "latestSmsSentAt",
      headerName: "Latest Sms Sent At",
      width: 200,
      type: "date",
      editable: false,
      valueFormatter: formatDate,
    },
    {
      field: "latestSmsReceivedCompanyId",
      headerName: "Latest Sms Received Company Id",
      width: 200,
      type: "number",
      renderCell: params => params.row.latestSmsReceivedCompanyId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      editable: false,
    },
    {
      field: "latestSmsReceivedPhone",
      headerName: "Latest Sms Received Phone",
      width: 200,
      type: "string",
      editable: false,
    },
    {
      field: "latestSmsReceivedCarrierType",
      headerName: "Latest Sms Received Carrier Type",
      width: 200,
      type: "singleSelect",
      editable: false,
      valueOptions: [...Object.keys(CarrierType)],
    },
    {
      field: "latestSmsReceivedMessage",
      headerName: "Latest Sms Received Message",
      width: 200,
      type: "string",
      editable: false,
    },
    {
      field: "latestSmsReceivedUserId",
      headerName: "Latest SMS Received User Id",
      width: 200,
      type: "number",
      renderCell: params => params.row.latestSmsReceivedUserId?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      editable: false,
    },
    {
      field: "latestSmsReceivedUserName",
      headerName: "Latest SMS Received User Name",
      width: 200,
      type: "string",
    },
    {
      field: "latestSmsStatus",
      headerName: "Latest SMS Received Status",
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(SMS_STATUS)],
    },

    {
      field: "latestSmsFailureReason",
      headerName: "Latest SMS Failure Reason",
      width: 200,
      type: "string",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      align: "right",
      headerAlign: "right",
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          ...(row.acceptedAt
            ? []
            : [
                <GridActionsCellItem
                  key={`${id}-delete`}
                  icon={<Delete />}
                  label="Delete"
                  color="inherit"
                  disabled={isDeleting}
                  onClick={() => {
                    setDeleteInviteId(row.id);
                  }}
                />,
              ]),
          <GridActionsCellItem
            key={`${id}-trigger-invite`}
            icon={<NotificationsActiveIcon />}
            label="Notify"
            color="inherit"
            disabled={row.id === triggerNotificationRowId}
            onClick={() => {
              setTriggerNotificationRowId(row.id);
            }}
          />,
        ];
      },
    },
  ];

  const [columnOrder, setColumnOrder] = useState(() => {
    const savedColumnOrder = localStorage.getItem(
      "referral-invitations-datagridColumnOrder"
    );
    return savedColumnOrder
      ? JSON.parse(savedColumnOrder)
      : columns.map(column => column.field);
  });

  const [columnWidths, setColumnWidths] = useState(() => {
    const savedColumnWidths = localStorage.getItem(
      "referral-invitations-datagridColumnWidths"
    );
    return savedColumnWidths
      ? JSON.parse(savedColumnWidths)
      : columns.reduce((acc, col) => {
          acc[col.field] = col.width;
          return acc;
        }, {});
  });

  const handleColumnResize = (newColumn: any) => {
    setColumnWidths({
      ...columnWidths,
      [newColumn.colDef.field]: newColumn.width,
    });
  };

  columns = columns.map(column => ({
    ...column,
    width: columnWidths?.[column.field] ?? column.width,
  }));
  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  const oldColumns = [...columns];

  columns = [
    ...columnOrder.map(field => oldColumns.find(col => col.field === field)),
    ...oldColumns.filter(col => !columnOrder.includes(col.field)),
  ];

  const apiRef = useGridApiRef();

  useEffect(() => {
    localStorage.setItem(
      "referral-invitations-datagridColumnWidths",
      JSON.stringify(columnWidths)
    );
  }, [columnWidths]);

  useEffect(() => {
    localStorage.setItem(
      "referral-invitations-datagridColumnOrder",
      JSON.stringify(columnOrder)
    );
  }, [columnOrder]);

  useEffect(() => {
    if (payload) {
      setServerFiltering(payload.serverFiltering);
      setServerSorting(payload.serverSorting);
      setPaginationModel({ pageSize: payload.size, page: payload.page });
      if (payload.columnOrder) setColumnOrder(payload.columnOrder);
    }
  }, [payload]);

  useEffect(() => {
    if (preFilledColumns) {
      const newColumnWidths = preFilledColumns.reduce((acc, col) => {
        acc[col.field] = col.width;
        return acc;
      }, {});
      setColumnWidths(newColumnWidths);
      setColumnVisibilityModel(
        columns.reduce((acc, col) => {
          acc[col.field] = preFilledColumns.some(
            preFilledColumn => preFilledColumn.field === col.field
          );
          return acc;
        }, {})
      );
      for (const column of preFilledColumns) {
        apiRef.current.setColumnIndex(column.field, column.position);
        apiRef.current.pinColumn(column.field, column.pinned);
      }
    }
  }, [preFilledColumns, apiRef]);

  const unsavedChangesRef = React.useRef<{
    unsavedRows: Record<GridRowId, GridValidRowModel>;
    rowsBeforeChange: Record<GridRowId, GridValidRowModel>;
  }>({
    unsavedRows: {},
    rowsBeforeChange: {},
  });

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (
      params.field === "invitedCompany-name" ||
      params.field === "invitedCompanyId"
    )
      setCompanyProfileId(params.row.invitedCompanyId);
    if (
      params.field === "invitingCompany-name" ||
      params.field === "invitingCompanyId"
    )
      setCompanyProfileId(params.row.invitingCompanyId);

    if (params.field === "latestEmailReceivedHtmlBody") {
      setEmailHtml(params.row.latestEmailReceivedHtmlBody);
    }
    if (params.field === "latestSmsReceivedMessage") {
      setSMSMessage(params.row.latestSmsReceivedMessage);
    }
    if (
      params.field === "firstInvitingCompanyId" ||
      params.field === "firstInvitingCompanyName"
    ) {
      setCompanyProfileId(params.row.firstInvitingCompany?.id);
    }
  };

  return (
    <Box display={"flex"} sx={{ height: "100%" }} overflow={"auto"}>
      {emailHtml ? (
        <HtmlModal
          open={emailHtml}
          htmlContent={emailHtml}
          onClose={() => setEmailHtml(null)}
        />
      ) : null}
      {smsMessage ? (
        <SMSModal
          open={smsMessage}
          smsContent={smsMessage}
          onClose={() => setSMSMessage(null)}
        />
      ) : null}
      <Main
        open={!!companyProfileId}
        sx={{
          width: expanded ? "0%" : `calc(100% - ${drawerWidth}px)`,
          display: expanded ? "none" : "visible",
        }}
      >
        <ConfirmationDialog
          open={!!triggerNotificationRowId}
          handleClose={() => setTriggerNotificationRowId(null)}
          handleConfirm={() => {
            triggerNotification(triggerNotificationRowId!);
            setTriggerNotificationRowId(null);
          }}
          text={`Are you sure you want to re-trigger the notification?`}
          title="Send Referral Invitation Notifications"
          confirmText="Send"
        />

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
          p={3}
        >
          {name ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                href="/admin/saved-searches"
              >
                Saved Searches
              </Link>
              <Link underline="hover" color="inherit">
                {name}
              </Link>
            </Breadcrumbs>
          ) : null}
          <Typography variant="h5">Referral Invitations</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={3}
          py={1}
        >
          <Box
            display={"flex"}
            gap={3}
            justifyContent={"space-between"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography variant="subtitle1" fontSize={"16px"}>
              {referralInvitations?.length
                ? `Showing ${Intl.NumberFormat("en-US").format(
                    referralInvitations?.length ?? 0
                  )} referral invitations`
                : "Fetching results"}
            </Typography>
            <Box
              display={"flex"}
              gap={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Button
                startIcon={<BookmarkAddOutlinedIcon />}
                variant="outlined"
                onClick={() => {
                  setCreateTableViewDialogOpen(true);
                }}
              >
                Save Search
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 260px)",
            px: 3,
          }}
        >
          <DataGridPremium
            checkboxSelection
            disableRowSelectionOnClick
            cellSelection
            keepNonExistentRowsSelected
            disableAggregation
            disableRowGrouping
            pagination
            columns={columns}
            sortModel={serverSorting}
            filterModel={serverFiltering}
            loading={isFetching || isLoading}
            rows={rows ?? []}
            slots={{
              toolbar: () => <CustomToolbar fileName="Referral_Invitations" />,
            }}
            sx={{
              "& .MuiDataGrid-row": {
                "--rowBorderColor": "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withRightBorder": {
                borderRightColor: "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withLeftBorder": {
                borderLeftColor: "#D9D9D9",
              },
              "& .MuiDataGrid-topContainer": {
                background: "#F8F9FA",
              },
            }}
            apiRef={apiRef}
            columnVisibilityModel={columnVisibilityModel}
            processRowUpdate={processRowUpdate}
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                right: ["actions"],
              },
              density: "compact",
            }}
            slotProps={{
              toolbar: { setRowModesModel },
            }}
            paginationModel={paginationModel}
            pageSizeOptions={[5, 10, 25, 50, 100, 250, 500, 5000]}
            columnOrder={columnOrder}
            onColumnWidthChange={handleColumnResize}
            onColumnVisibilityModelChange={newModel =>
              setColumnVisibilityModel(newModel)
            }
            onColumnOrderChange={params =>
              handleColumnOrderChange(params, columns, setColumnOrder)
            }
            onCellClick={handleCellClick}
            onPaginationModelChange={setPaginationModel}
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={handleSortModelChange}
          />
        </Box>
      </Main>
      {expanded && companyProfileId ? (
        <AdminChatProvider companyId={companyProfileId}>
          <InternalAdminCompanyDrawer
            open={!!companyProfileId}
            companyProfileId={companyProfileId}
            connectionStatus={ConnectionStatus.CONNECTED}
            expanded={expanded}
            onClose={() => {
              setCompanyProfileId(null);
              setExpanded(false);
            }}
            onExpand={() => setExpanded(prev => !prev)}
          />
        </AdminChatProvider>
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!companyProfileId}
        >
          {companyProfileId ? (
            <AdminChatProvider companyId={companyProfileId}>
              <InternalAdminCompanyDrawer
                open={!!companyProfileId}
                expanded={expanded}
                companyProfileId={companyProfileId}
                connectionStatus={ConnectionStatus.CONNECTED}
                onClose={() => {
                  setCompanyProfileId(null);
                  setExpanded(false);
                }}
                onExpand={() => setExpanded(prev => !prev)}
              />
            </AdminChatProvider>
          ) : null}
        </Drawer>
      )}
      {createTableViewDialogOpen ? (
        <CreateTableViewDialog
          isOpen={createTableViewDialogOpen}
          creatingTableView={creatingTableView}
          onCreate={({
            name,
            description,
          }: {
            name: string;
            description?: string;
          }) => {
            const payload = {
              page: paginationModel.page,
              size: paginationModel.pageSize,
              serverSorting,
              serverFiltering,
              columnOrder,
            };
            const columns = apiRef.current.getVisibleColumns();
            const columnsPayload = columns.map(column => ({
              field: column.field,
              width: column.width,
              position: apiRef.current.getColumnPosition(column.field),
              pinned: apiRef.current.isColumnPinned(column.field),
            }));
            createTableView({
              name,
              tableName: TableType.REFERRAL_INVITATION,
              description,
              body: payload,
              columns: columnsPayload,
            });
            setCreateTableViewDialogOpen(false);
          }}
          onClose={() => setCreateTableViewDialogOpen(false)}
        />
      ) : null}
      <ConfirmationDialog
        open={!!deleteInviteId}
        handleClose={() => setDeleteInviteId(undefined)}
        handleConfirm={() => {
          deleteReferralInvitations({
            referralInvitationIds: [deleteInviteId as unknown as number],
          });
          setDeleteInviteId(undefined);
        }}
        text={`Are you sure you want to delete the invite?`}
        title="Confirm Invite Deletion"
        confirmText="Delete"
      />
    </Box>
  );
}
