import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { enqueueSnackbar } from "notistack";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { PayReferralFeeSettings } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { UpdateSectorType } from "../ReferralMatrix";
import { JobPriceSettingsProps } from "./JobPriceSettings";
import { JobPricingInfoNotes } from "./JobPricingInfoNotes";

type PayAfterJobCompletionPriceSettingsProps = Pick<
  JobPriceSettingsProps,
  | "setPayReferralFeeAfterJobCompletion"
  | "payReferralFeeAfterJobCompletion"
  | "updatedSectors"
  | "setUpdatedSectors"
  | "setPaymentMode"
  | "paymentMode"
> & {
  disabled?: boolean;
};

export const PayAfterJobCompletion: FC<
  PayAfterJobCompletionPriceSettingsProps
> = (props: PayAfterJobCompletionPriceSettingsProps) => {
  const {
    updatedSectors,
    setUpdatedSectors,
    setPayReferralFeeAfterJobCompletion,
    payReferralFeeAfterJobCompletion,
    setPaymentMode,
    paymentMode,
    disabled,
  } = props;

  const containerRef = useRef(null);
  const [isNarrow, setIsNarrow] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  // Handler for changing expanded accordion
  const handleChange = () => {
    setExpanded(expanded => !expanded);
  };

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect.width < 600) {
        setIsNarrow(true);
      } else {
        setIsNarrow(false);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const { session, isInitialized } = useAuth();

  const companySectors = session?.companySectors;

  const handlePaymentModeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const payReferalSettings = (event.target as HTMLInputElement)
      .value as PayReferralFeeSettings;
    setPaymentMode(payReferalSettings);
    if (payReferalSettings === PayReferralFeeSettings.FIXED_AMOUNT) {
      if (companySectors) {
        const newSectors = updatedSectors ? { ...updatedSectors } : {};

        for (const sector of companySectors) {
          newSectors[sector.id] = {
            ...(newSectors[sector.id] ? newSectors[sector.id] : {}),
            percentagePrice: sector.percentagePrice,
          };
        }
        setUpdatedSectors(newSectors);
      }
    }
    if (
      payReferalSettings === PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE
    ) {
      if (companySectors) {
        const newSectors = updatedSectors ? { ...updatedSectors } : {};

        for (const sector of companySectors) {
          newSectors[sector.id] = {
            ...(newSectors[sector.id] ? newSectors[sector.id] : {}),
            buyingPrice: sector.buyingPrice,
          };
        }
        setUpdatedSectors(newSectors);
      }
    }
    if (
      payReferalSettings ===
      PayReferralFeeSettings.DEPENDING_ON_JOB_AND_REFERRAL_PARTNER
    ) {
      if (companySectors) {
        const newSectors = updatedSectors ? { ...updatedSectors } : {};

        for (const sector of companySectors) {
          newSectors[sector.id] = {
            ...(newSectors[sector.id] ? newSectors[sector.id] : {}),
            percentagePrice: sector.percentagePrice,
            buyingPrice: sector.buyingPrice,
          };
        }
        setUpdatedSectors(newSectors);
      }
    }
  };

  const handlePayReferralFeeAfterJobCompletionChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (disabled) {
      enqueueSnackbar({
        message:
          "At least one job pricing option must remain active. Enable either 'Pay for Every Referral' to reward partners as soon as a referral is received, or 'Pay When Job is Completed' to incentivize job completion.",
        variant: "error",
        includeContactSupportText: false,
      });
      return;
    }
    setPayReferralFeeAfterJobCompletion(event.target.checked);
    if (!event.target.checked) {
      if (companySectors) {
        const newSectors = updatedSectors ? { ...updatedSectors } : {};

        for (const sector of companySectors) {
          newSectors[sector.id] = {
            ...(newSectors[sector.id] ? newSectors[sector.id] : {}),
            percentagePrice: sector.percentagePrice,
            buyingPrice: sector.buyingPrice,
          };
        }
        setUpdatedSectors(newSectors);
      }
    }
  };

  const informationNotes = [
    "You’ll pay this amount when a job closes",
    "If you also enabled Pay for Every Referral, you'll pay that amount first, then you'll pay this amount when the job closes. This gives technicians and CSRs a bonus for closed jobs!",
    "After a job is completed, go to the Jobs Dashboard, click on a job and select Pay Referral Fee",
  ];

  if (!isInitialized) return <>Loading...</>;

  return (
    <Grid ref={containerRef} container p={0}>
      <Grid item xs={12} p={0}>
        <Accordion
          defaultExpanded
          expanded={expanded}
          sx={{
            px: 0.5,
            py: 0.5,
            width: "100%",
            my: "0 !important",
            mx: "0 !important",
            bgcolor: "#F8F9FA",
            borderRadius: 2,
            border: "1px solid #E5E7EB",
            background: "#FFF",
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore onClick={() => handleChange()} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              "& .MuiAccordionSummary-content.Mui-expanded": {
                my: 0,
                mx: 0,
              },
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
              p={0}
            >
              <Typography variant="subtitle1">
                Pay When Job is Completed
              </Typography>
              <Switch
                checked={payReferralFeeAfterJobCompletion}
                onChange={handlePayReferralFeeAfterJobCompletionChange}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{ py: 0.5, "& .MuiAccordionDetails-root": { py: 0 } }}
          >
            <Grid container px={2} py={0} pb={2}>
              <Grid item xs={12} lg={isNarrow ? 12 : 7} pr={2} pb={2}>
                <Box
                  display={"flex"}
                  gap={4}
                  sx={{
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="payment-mode-row-radio-buttons-group-label"
                      name="payment-mode-row-radio-buttons-group"
                      value={paymentMode}
                      onChange={handlePaymentModeChange}
                    >
                      <FormControlLabel
                        value={PayReferralFeeSettings.FIXED_AMOUNT}
                        control={<Radio />}
                        disabled={
                          !session?.groups?.some(isAdministratorOrOwner)
                        }
                        label={
                          <Typography variant="body2">
                            Fixed Amount ($)
                          </Typography>
                        }
                      />
                      {paymentMode === PayReferralFeeSettings.FIXED_AMOUNT ? (
                        <>
                          {companySectors?.map((sector, index) => {
                            return (
                              <>
                                <Box
                                  key={sector.sector}
                                  display="flex"
                                  flexWrap={"wrap"}
                                  py={2}
                                  gap={2}
                                >
                                  <TextField
                                    label={"Enter Price"}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                      inputProps: { min: 0, max: 100, step: 1 },
                                    }}
                                    value={
                                      updatedSectors?.[sector.id]?.buyingPrice
                                    }
                                    disabled={
                                      !session?.groups?.some(
                                        isAdministratorOrOwner
                                      ) || !payReferralFeeAfterJobCompletion
                                    }
                                    error={
                                      !updatedSectors?.[sector.id]?.buyingPrice
                                    }
                                    helperText={
                                      !updatedSectors?.[sector.id]?.buyingPrice
                                        ? "The Job Price is required"
                                        : ""
                                    }
                                    sx={{ width: "120px" }}
                                    name="new price"
                                    type="number"
                                    defaultValue={sector.buyingPrice}
                                    onChange={event => {
                                      setUpdatedSectors({
                                        ...(updatedSectors
                                          ? updatedSectors
                                          : {}),
                                        [sector.id]: {
                                          sectorId: sector.id,
                                          ...(updatedSectors?.[sector.id]
                                            ? updatedSectors?.[sector.id]
                                            : {}),
                                          buyingPrice: event.target.value,
                                        },
                                      } as unknown as UpdateSectorType);
                                    }}
                                  />
                                  <Typography
                                    fontSize={"14px"}
                                    fontWeight={"600"}
                                    flex={1}
                                    pt={"17px"}
                                  >
                                    {snakeCaseJobSectors[sector.sector]}
                                  </Typography>
                                </Box>
                                {index !== companySectors?.length - 1 ? (
                                  <Divider />
                                ) : null}
                              </>
                            );
                          })}
                        </>
                      ) : null}
                      <Divider sx={{ my: 2 }} />
                      <FormControlLabel
                        value={
                          PayReferralFeeSettings.DEPENDING_ON_JOB_AND_REFERRAL_PARTNER
                        }
                        control={<Radio />}
                        disabled={
                          !session?.groups?.some(isAdministratorOrOwner)
                        }
                        label={
                          <Box display={"flex"} gap={0.5} alignItems={"normal"}>
                            <Typography variant="body2">
                              Varies Depending on the Job and Referral Partner
                              ($)
                            </Typography>
                          </Box>
                        }
                      />
                      <Divider sx={{ my: 2 }} />
                      <FormControlLabel
                        value={PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE}
                        control={<Radio />}
                        disabled={
                          !session?.groups?.some(isAdministratorOrOwner)
                        }
                        label={
                          <Box display={"flex"} gap={0.5} alignItems={"normal"}>
                            <Typography variant="body2">
                              Percentage of the Final Sale Price (%)
                            </Typography>
                          </Box>
                        }
                      />
                      {paymentMode ===
                      PayReferralFeeSettings.PERCENTAGE_OF_SALE_PRICE ? (
                        <>
                          {companySectors?.map((sector, index) => {
                            return (
                              <>
                                <Box
                                  key={`${sector.sector}-percent`}
                                  display="flex"
                                  flexWrap={"wrap"}
                                  py={2}
                                  gap={2}
                                >
                                  <TextField
                                    label={"Percentage"}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          %
                                        </InputAdornment>
                                      ),
                                      inputProps: { min: 0, max: 100, step: 1 },
                                    }}
                                    value={
                                      updatedSectors?.[sector.id]
                                        ?.percentagePrice
                                    }
                                    disabled={
                                      !session?.groups?.some(
                                        isAdministratorOrOwner
                                      )
                                    }
                                    error={
                                      !updatedSectors?.[sector.id]
                                        ?.percentagePrice
                                    }
                                    helperText={
                                      !updatedSectors?.[sector.id]
                                        ?.percentagePrice
                                        ? "Job Percentage is required"
                                        : ""
                                    }
                                    sx={{ width: "120px" }}
                                    name="new price"
                                    type="number"
                                    defaultValue={sector.percentagePrice}
                                    onChange={event => {
                                      setUpdatedSectors({
                                        ...(updatedSectors
                                          ? updatedSectors
                                          : {}),
                                        [sector.id]: {
                                          sectorId: sector.id,
                                          ...(updatedSectors?.[sector.id]
                                            ? updatedSectors?.[sector.id]
                                            : {}),
                                          percentagePrice: event.target.value,
                                        },
                                      } as unknown as UpdateSectorType);
                                    }}
                                  />
                                  <Typography
                                    fontSize={"14px"}
                                    fontWeight={"600"}
                                    flex={1}
                                    pt={"17px"}
                                  >
                                    {snakeCaseJobSectors[sector.sector]}
                                  </Typography>
                                </Box>
                                {index !== companySectors?.length - 1 ? (
                                  <Divider />
                                ) : null}
                              </>
                            );
                          })}
                        </>
                      ) : null}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} lg={isNarrow ? 12 : 5}>
                <JobPricingInfoNotes notes={informationNotes} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
