import CloseIcon from "@mui/icons-material/Close";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { Box, Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { AppConstants } from "@/common/constants";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface ContactSupportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ContactSupportModal: React.FC<ContactSupportModalProps> = ({
  isOpen,
  onClose,
}) => {
  const handleMailClick = () => {
    const mailtoLink = `mailto:support@tradeengage.com`;
    window.location.href = mailtoLink;
  };

  const { t } = useTranslation();

  const handleSMSClick = () => {
    window.open(`tel:${AppConstants.supportContactNumber}`, "_blank");
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="contact-support-modal-title"
        open={isOpen}
        maxWidth="sm"
        sx={{ m: { xs: -3, md: 0 } }}
        onClose={onClose}
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h5" m={0} p={0}>
              {t("contact_support")}
            </Typography>
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
                p: 0,
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {t("questions_or_feedback_message")}
          </Typography>
          <Box
            display={"flex"}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            gap={4}
            width={"100%"}
            height={"100%"}
          >
            <Card
              sx={{
                width: { xs: "100%", md: "50%" },
                p: 3,
                cursor: "pointer",
                boxShadow: "0px 0px 0px 1px #F2F4F7",
              }}
            >
              <Box display={"flex"} flexDirection={"column"} gap={1.5}>
                <IconButton
                  aria-label="mail"
                  sx={{
                    width: "40px",
                    height: "40px",
                    bgcolor: "#16DB651A",
                  }}
                >
                  <MailOutlineOutlinedIcon
                    sx={{ color: "#16DB65", width: "24px", height: "24px" }}
                  />
                </IconButton>
                <Box>
                  <Typography variant="subtitle1">{t("email")}</Typography>
                  <Typography
                    color="primary"
                    fontSize="15px"
                    fontWeight={600}
                    sx={{ cursor: "pointer" }}
                    fontStyle={"normal"}
                    onClick={() => {
                      handleMailClick();
                    }}
                  >
                    support@tradeengage.com
                  </Typography>
                </Box>
              </Box>
            </Card>
            <Card
              sx={{
                width: { xs: "100%", md: "50%" },
                p: 3,
                cursor: "pointer",
                boxShadow: "0px 0px 0px 1px #F2F4F7",
              }}
            >
              {" "}
              <Box display={"flex"} flexDirection={"column"} gap={1.5}>
                <IconButton
                  aria-label="mail"
                  sx={{
                    width: "40px",
                    height: "40px",
                    bgcolor: "#16DB651A",
                  }}
                >
                  <LocalPhoneOutlinedIcon
                    sx={{ color: "#16DB65", width: "24px", height: "24px" }}
                  />
                </IconButton>
                <Box>
                  <Typography variant="subtitle1">
                    {t("phone_number")}
                  </Typography>
                  <Typography
                    color="primary"
                    fontSize="15px"
                    fontWeight={600}
                    sx={{ cursor: "pointer" }}
                    fontStyle={"normal"}
                    onClick={() => {
                      handleSMSClick();
                    }}
                  >
                    +1 (786) 673-1551
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};
