import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useProfileShare(
  options: UseMutationOptions<
    void,
    AxiosError,
    {
      companyId: number;
      contacts?: Array<string>;
    }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { companyId: number; contacts?: Array<string>; mobile?: string }
> {
  return useMutation({
    mutationFn: async ({ companyId, contacts }) => {
      await API.post(`companies/${companyId}/share`, { emails: contacts });
    },
    ...options,
  });
}
