import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { SyntheticEvent, useState } from "react";

import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import {
  reverseSnakeCaseJobSectors,
  snakeCaseJobSectors,
} from "@/company/utils";

const ModifySectorDialog = ({
  open,
  onClose,
  handleConfirm,
  job,
  modifyingSector,
  sectorOptions,
  sector,
}: {
  open: boolean;
  job: Job;
  sectorOptions?: Array<JobSectors>;
  modifyingSector: boolean;
  onClose: () => void;
  handleConfirm: (sector: JobSectors) => void;
  sector?: string;
}) => {
  const [newSector, setNewSector] = useState("");
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Modify Service Category</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={(sectorOptions ?? Object.keys(JobSectors)).map(sector => {
            if (sector === JobSectors.HVAC) {
              return sector.toString();
            }
            return snakeCaseJobSectors[sector.toString() as JobSectors];
          })}
          defaultValue={
            snakeCaseJobSectors[job?.sector as JobSectors] ??
            snakeCaseJobSectors[JobSectors.OTHER]
          }
          value={
            newSector ? newSector : snakeCaseJobSectors[sector as JobSectors]
          }
          getOptionLabel={option => option}
          renderInput={params => (
            <TextField
              {...params}
              required
              fullWidth
              value={
                newSector
                  ? newSector
                  : snakeCaseJobSectors[sector as JobSectors]
              }
              margin="normal"
              InputLabelProps={{
                style: { marginBottom: "8px" },
              }}
              label="Select Service Category"
            />
          )}
          renderOption={(props, option, { selected }) => (
            <MenuItem {...props} key={option} value={option}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                {<Typography>{option}</Typography>}
                {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
              </Box>
            </MenuItem>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                size="small"
                label={option}
                {...getTagProps({ index })}
                variant="outlined"
              />
            ))
          }
          onChange={(_: SyntheticEvent, newValue: string | null) => {
            setNewSector(newValue);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={modifyingSector} onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={modifyingSector}
          variant="contained"
          onClick={() => {
            handleConfirm(reverseSnakeCaseJobSectors[newSector]);
          }}
        >
          Modify
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModifySectorDialog;
