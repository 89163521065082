import { useFlags } from "launchdarkly-react-client-sdk";

import { FEATURE_FLAGS } from "@/featureFlags";

import {
  CreateJobDialogProps,
  CreateTechnicianJobDialog,
} from "./CreateTechnicianJobDialog";
import { CreateTechnicianJobDialogV1 } from "./CreateTechnicianJobDialogV1";

const CreateTechnicianJobDialogWrapper = (props: CreateJobDialogProps) => {
  const flags = useFlags();
  const newJobUploadFLowEnabled =
    flags[FEATURE_FLAGS.NEW_JOB_UPLOAD_FLOW_ENABLED];

  return (
    <>
      {newJobUploadFLowEnabled ? (
        <CreateTechnicianJobDialog {...props} />
      ) : (
        <CreateTechnicianJobDialogV1 {...props} />
      )}
    </>
  );
};

export default CreateTechnicianJobDialogWrapper;
