/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputBaseComponentProps,
  Link,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { ElementType, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import { AuthRoutes } from "@/auth/constants";
import { useAuth } from "@/auth/context/jwt";
import { USER_TYPE } from "@/auth/types";
import { useGetReferralInvitationInfo } from "@/campaign/api/useGetReferralInvitationInfo";
import { Languages } from "@/common/constants/languages";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import { MaskedPhoneInput } from "@/common/MaskedPhoneInput";
import { Seo } from "@/common/Seo/Seo";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { COMPANY_ACCOUNT_TYPES, CompanyRoutes } from "@/company/types";
import { useLeadContext } from "@/lead/context/lead-context";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import {
  appendSearchParamsToRedirectTo,
  getUSPhoneValidationSchema,
  NAME_REGEX,
} from "@/utils";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .matches(NAME_REGEX, "Enter a valid name")
    .required("First Name is required"),
  lastName: yup
    .string()
    .matches(NAME_REGEX, "Enter a valid name")
    .required("Last Name is required"),
  email: yup
    .string()
    .email("Work Email Address must be a valid email address")
    .optional(),
  cellPhone: getUSPhoneValidationSchema(),
  acceptedTermsAndConditions: yup
    .boolean()
    .oneOf([true], "You must accept the Terms and Conditions"),
  // acceptedWhatsappMessagingTerms: yup
  //   .boolean()
  //   .oneOf(
  //     [true],
  //     "You must agree to receive messages on WhatsApp to submit job videos"
  //   ),
});

export function SignUpPage() {
  const { leadDetails, updateLeadDetails } = useLeadContext();
  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");
  const campaignCode = searchParams.get("campaignCode");
  const invitedCompanyId = searchParams.get("invitedCompanyId");
  const referralInvitationId = searchParams.get("referralInvitationId");
  const utmCampaign = searchParams.get("utm_campaign") ?? undefined;
  const utmSource = searchParams.get("utm_source") ?? undefined;
  const utmMedium = searchParams.get("utm_medium") ?? undefined;

  const googlePlacesId = searchParams.get("googlePlacesId");
  const brandLocationId = searchParams.get(" brandLocationId,");

  const redirectTo = searchParams.get("redirectTo");
  const jobIdToLink = searchParams.get("job_id_to_link");
  const initialJobRoutedId = searchParams.get("initial_job_routed_id");

  const { signUp } = useAuth();
  const appendSearchParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.toString() ? `?${searchParams.toString()}` : "";
  };

  const {
    data: referralInvitationInfo,
    isFetching: loadingReferralInvitationInfo,
  } = useGetReferralInvitationInfo(referralInvitationId, {
    enabled: !!referralInvitationId,
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      cellPhone: "",
      email: "",
      // acceptedWhatsappMessagingTerms: false,
      acceptedTermsAndConditions: false,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const {
        firstName,
        lastName,
        cellPhone,
        email,
        // acceptedWhatsappMessagingTerms,
        acceptedTermsAndConditions,
      } = values;
      try {
        setLoading(true);
        await signUp({
          utmParams: {
            campaign: utmCampaign ?? leadDetails?.utmCampaign ?? undefined,
            medium: utmMedium ?? leadDetails?.utmMedium ?? undefined,
            source: utmSource ?? leadDetails?.utmSource ?? undefined,
          },
          user: {
            firstName,
            lastName,
            ...(redirectTo?.includes("referringCompanyId")
              ? { type: USER_TYPE.HOMEOWNER }
              : {}),
            cellPhone: cellPhone.replace(/[\s()-]/g, ""),
            email: email ?? "",
            jobTitle: leadDetails || invitationCode ? "Administrator" : "",
            // acceptedWhatsappMessagingTerms,
            acceptedTermsAndConditions,
            preferredLanguage: Languages.ENGLISH,
          },
          ...(leadDetails && Object.keys(leadDetails).length > 0
            ? {
                brandLocationId: leadDetails?.id ?? brandLocationId,
                company: {
                  name: leadDetails?.name,
                  zipCode: leadDetails?.zipCode,
                  sectors: leadDetails?.sectors,
                  ...(googlePlacesId ? { googlePlacesId } : {}),
                  accountType:
                    leadDetails?.accountType ??
                    COMPANY_ACCOUNT_TYPES.BRAND_LOCATION,
                },
              }
            : {}),
          invitationCode: invitationCode ?? undefined,
          campaignCode: campaignCode ?? undefined,
          redirectTo: campaignCode
            ? `/${CompanyRoutes.BASE}/${CompanyRoutes.DASHBOARD_HOME}`
            : redirectTo
            ? `${redirectTo}${
                appendSearchParamsToRedirectTo()
                  ? appendSearchParamsToRedirectTo()
                  : ""
              }`
            : leadDetails?.redirectTo
            ? leadDetails?.redirectTo
            : invitationCode
            ? `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.SERVICE_DETAILS}`
            : undefined,
          jobIdToLink:
            jobIdToLink && parseInt(jobIdToLink)
              ? parseInt(jobIdToLink)
              : undefined,
          initialJobRoutedId:
            initialJobRoutedId && parseInt(initialJobRoutedId)
              ? parseInt(initialJobRoutedId)
              : leadDetails?.initialJobRoutedId ?? undefined,
        });
        navigate(
          getNavigationPathFromRoutePaths([
            AuthRoutes.BASE,
            AuthRoutes.VERIFY_OTP,
          ])
        );
      } catch (error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while signing up user.";
        enqueueSnackbar(message, {
          variant: "error",
          subText: (
            <>
              Please{" "}
              <a
                href={`/${AuthRoutes.BASE}/${AuthRoutes.LOGIN}${
                  redirectTo ? `?redirectTo=${redirectTo}` : ""
                }`}
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "#7A271A",
                }}
              >
                sign in
              </a>{" "}
              or{" "}
              <a
                role="button"
                style={{
                  textDecorationLine: "underline",
                  color: "#7A271A",
                  cursor: "pointer",
                }}
                onClick={event => {
                  event.preventDefault();
                  setContactSupportModalOpen(true);
                }}
              >
                contact support
              </a>
              .
            </>
          ),
        });
      }
      setLoading(false);
    },
  });
  const navigate = useNavigate();

  const handleDemoClick = async () => {
    updateLeadDetails({
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      email: formik.values.email,
      phone: formik.values.cellPhone.replace(/[\s()-]/g, ""),
      utmCampaign,
      utmMedium,
      utmSource,
    });
    const url =
      "https://calendly.com/tradeengage-chelsea/book-tradeengage-demo";
    window.open(url, "_blank");
  };

  return (
    <>
      <Seo title="Sign Up" />
      <Box
        sx={{
          padding: { xs: 2, md: "32px 300px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: { xs: "flex-start", md: "center" },
          alignItems: "center",
          gap: "16px",
          height: "100%",
          width: "100%",
        }}
      >
        {" "}
        {loadingReferralInvitationInfo ? (
          <Box py={3} textAlign="center" width={"100%"}>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "16px",
              width: "100%",
              maxWidth: "540px",
            }}
          >
            <Box
              display={"flex"}
              pb={2}
              py={4}
              width="100%"
              justifyContent={isMobile ? "center" : "start"}
            >
              <TradeEngageLogo logotype={TradeEngageLogoTypes.LOGO_DARK} />
            </Box>
            <Typography variant="h5">
              Sign Up
              {referralInvitationInfo?.lastChat?.message &&
              referralInvitationInfo?.invitingCompany
                ? " to Send Reply"
                : ""}
            </Typography>
            {referralInvitationInfo?.lastChat?.message &&
            referralInvitationInfo?.invitingCompany ? (
              referralInvitationInfo?.invitingUser?.firstName ? (
                <Typography variant="body2" color="text.secondary">
                  Sign up to send your reply to{" "}
                  <Typography
                    variant="body2"
                    fontWeight={700}
                    display={"inline"}
                    color="text.secondary"
                  >
                    {referralInvitationInfo?.invitingUser?.firstName}
                    {referralInvitationInfo?.invitingUser?.lastName
                      ? ` ${referralInvitationInfo?.invitingUser?.lastName}`
                      : ""}
                  </Typography>{" "}
                  at{" "}
                  <Typography
                    variant="body2"
                    fontWeight={700}
                    display={"inline"}
                    color="text.secondary"
                  >
                    {referralInvitationInfo?.invitingCompany?.name}
                  </Typography>
                  ! Complete the form below, and we&apos;ll make sure your
                  message reaches them.
                </Typography>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Sign up to send your reply to{" "}
                  {referralInvitationInfo?.invitingCompany?.name}! Complete the
                  form below, and we&apos;ll make sure your message reaches
                  them.
                </Typography>
              )
            ) : (
              <Typography variant="body2" color="textSecondary">
                Sign up for free to connect with home service providers in your
                area exclusively on TradeEngage.
              </Typography>
            )}
            <Box
              component={"form"}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "8px",
                  width: "100%",
                }}
              >
                <TextField
                  required
                  sx={{ width: { xs: 1, md: 1 / 2 } }}
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  margin="normal"
                  value={formik.values.firstName}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <TextField
                  required
                  sx={{ width: { xs: 1, md: 1 / 2 } }}
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  margin="normal"
                  value={formik.values.lastName}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Box>
              <TextField
                required
                fullWidth
                inputRef={inputRef}
                id="cellPhone"
                name="cellPhone"
                margin="normal"
                label="Mobile Number"
                value={formik.values.cellPhone}
                error={
                  formik.touched.cellPhone && Boolean(formik.errors.cellPhone)
                }
                helperText={formik.touched.cellPhone && formik.errors.cellPhone}
                InputProps={{
                  inputComponent: MaskedPhoneInput as unknown as
                    | ElementType<InputBaseComponentProps>
                    | undefined,
                }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                margin="normal"
                label="Email Address"
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <FormControl sx={{ width: "100%" }}>
                <FormControlLabel
                  value={formik.values.acceptedTermsAndConditions}
                  control={
                    <Checkbox
                      name="acceptedTermsAndConditions"
                      checked={Boolean(
                        formik.values.acceptedTermsAndConditions
                      )}
                      onChange={formik.handleChange}
                    />
                  }
                  label={
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        I have read and accepted the{" "}
                        <Link
                          color="#16B364"
                          href={"/open/terms-of-service"}
                          rel="noreferrer"
                        >
                          <Typography
                            py={4}
                            display="inline"
                            variant="subtitle2"
                            color="#16B364"
                          >
                            Terms and Conditions
                          </Typography>
                        </Link>
                        .
                      </Typography>
                    </Box>
                  }
                />
                {formik.touched.acceptedTermsAndConditions &&
                  formik.errors.acceptedTermsAndConditions && (
                    <FormHelperText error>
                      {formik.errors.acceptedTermsAndConditions}
                    </FormHelperText>
                  )}
              </FormControl>
              {/* <FormControl sx={{ width: "100%", marginBottom: "8px" }}>
              <FormControlLabel
                value={formik.values.acceptedWhatsappMessagingTerms}
                control={
                  <Checkbox
                    name="acceptedWhatsappMessagingTerms"
                    checked={Boolean(
                      formik.values.acceptedWhatsappMessagingTerms
                    )}
                    onChange={formik.handleChange}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      {"I agree to receive communication by SMS and WhatsApp."}
                    </Typography>
                  </Box>
                }
              />
              {formik.touched.acceptedWhatsappMessagingTerms &&
                formik.errors.acceptedWhatsappMessagingTerms && (
                  <FormHelperText error>
                    {formik.errors.acceptedWhatsappMessagingTerms}
                  </FormHelperText>
                )}
            </FormControl> */}
              <LoadingButton
                fullWidth
                disabled={
                  !formik.isValid ||
                  (!formik.dirty && !Object.keys(formik.touched).length)
                }
                loading={loading}
                variant="contained"
                onClick={() => {
                  if (
                    !referralInvitationId &&
                    !invitationCode &&
                    !invitedCompanyId &&
                    !leadDetails.name
                  ) {
                    handleDemoClick();
                    return;
                  }
                  formik.handleSubmit();
                }}
              >
                Sign Up
              </LoadingButton>
              <Typography py={4} variant="body2" color="text.secondary">
                Already have an account?{" "}
                <Link
                  color="#16B364"
                  rel="noreferrer"
                  underline="none"
                  sx={{
                    cursor: "pointer",
                  }}
                  variant="subtitle2"
                  onClick={() => {
                    navigate(
                      `/${AuthRoutes.BASE}/${
                        AuthRoutes.LOGIN
                      }${appendSearchParams()}`
                    );
                  }}
                >
                  <Typography
                    py={4}
                    display="inline"
                    variant="subtitle2"
                    color="#16B364"
                  >
                    Sign In
                  </Typography>
                </Link>
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
