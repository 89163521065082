import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { JobAssociation } from "../jobs/api/useAssociateCompanyToJob";
import { CallRecording } from "./useFetchCallRecordings";

export function useCreateJobFromRecording(
  options: UseMutationOptions<
    CallRecording,
    AxiosError,
    {
      recordingId: number;
      jobAssociation: JobAssociation;
      createdByUserId: number;
    }
  > = {}
): UseMutationResult<
  CallRecording,
  AxiosError,
  {
    recordingId: number;
    jobAssociation: JobAssociation;
    createdByUserId: number;
  }
> {
  return useMutation({
    mutationFn: async ({
      recordingId,
      jobAssociation,
      createdByUserId,
    }): Promise<CallRecording> => {
      const payload = changeCaseKeys(
        { jobAssociation, createdByUserId },
        "underscored"
      );

      const response = await API.post(
        `/call-recordings/${recordingId}/jobs`,
        payload
      );
      return response?.data?.data;
    },
    ...options,
  });
}
