import { BoltOutlined as BoltOutlinedIcon } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { useField } from "formik";
import * as yup from "yup";

import { RadioGroupField } from "@/lib/form-fields/RadioGroupField";
import { TextInputField } from "@/lib/form-fields/TextInputField";

import { AddReferralCampaignFormInputField } from "./AddReferralCampaignFormInputField";

export const referralCampaignGoalFormSchema = yup.object().shape({
  goal: yup.string().required("Campaign Goal is required"),
  otherGoal: yup.string().when("goal", {
    is: (goal: string) => goal === "Other",
    then: s => s.required("Campaign Goal is required"),
    otherwise: s => s.nullable(),
  }),
});

export type ReferralCampaignGoalFormFields = yup.InferType<
  typeof referralCampaignGoalFormSchema
>;

export const referralCampaignGoalOptions = [
  "I want to establish a referral network for a new franchisee to accelerate sales",
  "My franchisee is a seasonal business and I want to re-engage referral partners from prior years",
  "I want to accelerate sales growth of a new or suffering franchisee with more sales volume from existing referral relationships",
  "I want to drive adoption and participation of our network on TradeEngage",
  "I want to drive more cross-sell across my family of brands",
  "I want to drive more cross-sell and collaboration across my family of brands and affiliate brand partners",
  "I want to encourage more outbound sales initiatives with local service providers",
  "I want to provide more earnings opportunities for technicians",
  "I want to test the potential of a new service offering I could expand into",
  "Other",
];

export function ReferralCampaignGoalForm() {
  const [field] = useField("goal");

  return (
    <Stack spacing={4}>
      <AddReferralCampaignFormInputField
        includeConnector={false}
        title="Campaign Goal"
        icon={<BoltOutlinedIcon />}
      >
        <Box sx={{ marginTop: 2 }}>
          <RadioGroupField
            label="SELECT ONE"
            name="goal"
            options={referralCampaignGoalOptions.map(option => ({
              label: option,
              value: option,
            }))}
          />
          {field.value === "Other" && (
            <Box sx={{ paddingX: 4 }}>
              <TextInputField
                fullWidth
                name="otherGoal"
                label="Other Goal"
                placeholder="Enter Your Goal"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          )}
        </Box>
      </AddReferralCampaignFormInputField>
    </Stack>
  );
}
