import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

export interface LeaderboardResponse {
  id: number;
  firstName: string;
  lastName?: string;
  earnings: number;
  totalJobs: number;
}

export function useGetTechnicianLeaderboard(
  { fromDate, toDate }: { fromDate: string; toDate: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<LeaderboardResponse>> {
  const uri = `/dashboard/technicians-leaderboard?from_date=${fromDate}&to_date=${toDate}`;
  return useQuery({
    queryKey: [uri],
    queryFn: async () => {
      const response = await API.get(uri);
      return response.data.data;
    },
    ...options,
  });
}
