import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import { Avatar, Box, Card, Typography } from "@mui/material";

import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";
import { neutral, tradeEngageColors } from "@/Theme/colors";

export function StripeStepsCard() {
  const { setContactSupportModalOpen } = useContactSupportModalContext();
  const handleIconClick = () => {
    const url = "https://stripe.com/";
    window.open(url, "_blank");
  };

  return (
    <Card
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        bgcolor: "white",
        boxShadow: "0px 0px 0px 1px #F2F4F7",
        border: "0px solid #F3F4F6",
        background: "#F8F9FA",
        width: "100%",
      }}
    >
      <Box>
        <Typography variant="h6">How Will Payouts be Processed?</Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={400}
          variant="body2"
          color="text.secondary"
        >
          We partner with Stripe for secure financial services.
        </Typography>
      </Box>
      <Card
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          bgcolor: "rgba(22, 219, 101, 0.10)",
          boxShadow: "0px 0px 0px 1px #F2F4F7",
          borderRadius: "8px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          gap={2}
          sx={{ width: "100%" }}
        >
          <img src="/icon.svg" alt="te-icon" width="32px" height="32px" />
          <SwapHorizOutlinedIcon
            sx={{
              color: "#11192761",
              width: "20px",
              height: "20px",
            }}
          />
          <img
            src="/stripe-icon.svg"
            alt="stripe"
            width="32px"
            height="32px"
            style={{ marginLeft: "4px" }}
          />
        </Box>
      </Card>
      <Typography variant="h6">What to Expect?</Typography>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          mt: 0,
          px: 0,
          mb: 0,
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <Avatar
              variant="square"
              sx={{
                width: "32px",
                height: "32px",
                color: neutral[50],
                borderRadius: 1,
                bgcolor: tradeEngageColors.green,
              }}
            >
              {"1"}
            </Avatar>
            <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
          </TimelineSeparator>
          <TimelineContent sx={{ p: 0, pb: 1, pl: 2 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize={"13px"}
              fontWeight={400}
            >
              Enter your mobile number and{" "}
              <Typography
                variant="body2"
                color="text.secondary"
                fontSize={"13px"}
                fontWeight={700}
                display={"inline"}
              >
                personal email address
              </Typography>{" "}
              to connect an existing Stripe account or create a new account.
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <Avatar
              variant="square"
              sx={{
                width: "32px",
                height: "32px",
                color: neutral[50],
                borderRadius: 1,
                bgcolor: tradeEngageColors.green,
              }}
            >
              {"2"}
            </Avatar>
            <TimelineConnector sx={{ border: "1px #D9D9D9 solid" }} />
          </TimelineSeparator>
          <TimelineContent sx={{ p: 0, pb: 1, pl: 2 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize={"13px"}
              fontWeight={400}
            >
              You’ll be asked to provide this info:
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize={"13px"}
              fontWeight={400}
            >
              Full Name
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize={"13px"}
              fontWeight={400}
            >
              D.O.B.
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize={"13px"}
              fontWeight={400}
            >
              Last Four of your SSN
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize={"13px"}
              fontWeight={400}
            >
              Enter{" "}
              <Typography
                variant="body2"
                color="text.secondary"
                fontSize={"13px"}
                fontWeight={700}
                display={"inline"}
              >
                TradeEngage.com
              </Typography>{" "}
              as the company website.
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <Avatar
              variant="square"
              sx={{
                width: "32px",
                height: "32px",
                color: neutral[50],
                borderRadius: 1,
                bgcolor: tradeEngageColors.green,
              }}
            >
              {"3"}
            </Avatar>
          </TimelineSeparator>
          <TimelineContent sx={{ p: 0, pl: 2 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize={"13px"}
              fontWeight={400}
            >
              Select your financial institution so your funds can be deposited
              seamlessly
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <Typography
        fontSize={"14px"}
        fontWeight={400}
        variant="body2"
        color="text.secondary"
        mt={-2}
      >
        Deposits take 1-2 days depending on your bank! Questions?{" "}
        <Typography
          fontSize={"14px"}
          fontWeight={700}
          variant="body2"
          color="#16B364"
          sx={{ cursor: "pointer" }}
          display={"inline"}
          onClick={() => {
            setContactSupportModalOpen(true);
          }}
        >
          Contact support
        </Typography>{" "}
        or visit{" "}
        <Typography
          fontSize={"14px"}
          fontWeight={700}
          variant="body2"
          color="#16B364"
          sx={{ cursor: "pointer" }}
          display={"inline"}
          onClick={() => {
            handleIconClick();
          }}
        >
          Stripe.com
        </Typography>
        .
      </Typography>
    </Card>
  );
}
