import { GridFilterModel } from "@mui/x-data-grid-premium";
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";
import { InternalAdminFilters } from "@/internal-admin/useInternalCompanySearchFilter";
import {
  camelToUnderscorePreserveHyphen,
  transformSortingFields,
} from "@/utils";

import { JobSectors } from "../jobs/constants";
import {
  COMPANY_ACCOUNT_TYPES,
  CompanyPublicProfile,
  ExternalCompanyProfile,
} from "../types";
import { reverseSnakeCaseJobSectors } from "../utils";

export interface CompanyExploreFilters {
  keyword?: string;
  sectors?: JobSectors[];
  linkedCompanies?: boolean;
  excludeRecordIds?: string;
  excludeCompanyIds?: string;
  nearby?: boolean;
  accountTypes?: COMPANY_ACCOUNT_TYPES[];
  parentCompanyId?: number | null;
  searchRadius?: number;
  zipCode?: string;
  googleRating?: Array<0 | 1 | 2 | 3 | 4 | 5>;
  ownerContactInformationAvailable?: boolean;
  affiliatedCompanies?: boolean;
  hasMutualReferrals?: boolean;
  activeOnTradeEngage?: boolean;
  topReferralCategories?: JobSectors[];
  topReferralCategoryCheck?: boolean;
  otherNearbyCheck?: boolean;
  franchiseCheck?: boolean;
  excludeCurrentCompany?: boolean;
  fddDisregardNearbyLocations?: boolean;
  limit?: number;
  isGoogleCompany?: boolean;
}

export interface SearchedCompany {
  googleCompanyProfile?: ExternalCompanyProfile;
  companyPublicProfile?: CompanyPublicProfile;
  hasMarkedInterest?: boolean;
}

export interface CompanyTypeFilters {
  createdAt?: string;
  sectors: Array<JobSectors>;
  relevantSectors?: boolean;
  singleService?: boolean;
  hasFamilyOfBrands?: boolean;
  nonCompetingCompanies?: boolean;
  businessAgeLowerLimit?: number;
  businessAgeUpperLimit?: number;
  existingReferral: boolean;
  keyword?: string;
}

export interface GeographyFilters {
  zipCodes?: Array<string>;
  mileRadius?: number;
}

export interface OwnershipFilters {
  privateEquityBacked?: boolean;
  multipleOwners?: boolean;
  multipleBrandLocationOwner?: boolean;
  multipleBrandOwnerSameFamilyOfBrands?: boolean;
  multipleBrandOwnerDifferentFamilyOfBrands?: boolean;
}

export interface ReferralMatrixFilters {
  tradeengageUser?: boolean;
  paysReferralFee?: boolean;
  nationalReferralNetworks?: boolean;
}

export interface ReviewsAndQualitySignalsFilters {
  googleRatingLowerLimit?: number;
  googleRatingUpperLimit?: number;
  googleNumberReviewsLowerLimit?: number;
  googleNumberReviewsUpperLimit?: number;
}

export interface OtherCriteriaFilters {
  ownerPhone?: boolean;
  ownerEmail?: boolean;
}

export interface Sorting {
  field: string;
  order: string;
}

export const getCompanySearchPayload = (
  pageNumber: number,
  pageSize: number,
  filters?: InternalAdminFilters,
  sorting?: Sorting[],
  tableFilters?: GridFilterModel
) => {
  // Get keys from object2
  const keysToExtractCompanyTypeFilters = Object.keys({} as CompanyTypeFilters);

  // Create a new object with matching keys from object1
  let companyTypeFilters = filters
    ? Object.fromEntries(
        Object.entries(filters).filter(([key]) =>
          keysToExtractCompanyTypeFilters.includes(key)
        )
      )
    : null;

  if (filters?.createdAt) {
    companyTypeFilters = {
      ...companyTypeFilters,
      createdAt: filters.createdAt.toFormat("dd/MM/yyyy"),
    };
  }

  if (filters?.sectors) {
    companyTypeFilters = {
      ...companyTypeFilters,
      sectors: filters.sectors.map(
        sector => reverseSnakeCaseJobSectors[sector]
      ),
    };
  }
  if (filters?.archived) {
    companyTypeFilters = {
      ...companyTypeFilters,
      archived: filters.archived,
    };
  }

  if (filters?.relevantSectors) {
    companyTypeFilters = {
      ...companyTypeFilters,
      relevantSectors: filters.relevantSectors,
    };
  }
  if (filters?.existingReferral) {
    companyTypeFilters = {
      ...companyTypeFilters,
      existingReferral: filters.existingReferral,
    };
  }

  if (filters?.nonCompetingCompanies) {
    companyTypeFilters = {
      ...companyTypeFilters,
      nonCompetingCompanies: filters.nonCompetingCompanies,
    };
  }

  if (filters?.franchise?.includes("Single-service")) {
    companyTypeFilters = { ...companyTypeFilters, singleService: true };
  }
  if (filters?.franchise?.includes("Family of brands")) {
    companyTypeFilters = { ...companyTypeFilters, hasFamilyOfBrands: true };
  }

  if (filters?.businessAge?.includes("New <2 years")) {
    companyTypeFilters = {
      ...companyTypeFilters,
      businessAgeUpperLimit: 2,
      businessAgeLowerLimit: 0,
    };
  }
  if (filters?.businessAge?.includes("Scaling 2-5 years")) {
    companyTypeFilters = {
      ...companyTypeFilters,
      businessAgeUpperLimit: 5,
      businessAgeLowerLimit: 2,
    };
  }
  if (filters?.keyword) {
    companyTypeFilters = {
      ...companyTypeFilters,
      keyword: filters?.keyword,
    };
  }
  if (filters?.businessAge?.includes("Established 5-10 years")) {
    companyTypeFilters = {
      ...companyTypeFilters,
      businessAgeUpperLimit: 10,
      businessAgeLowerLimit: 5,
    };
  }
  if (filters?.businessAge?.includes("Long Standing Partner>10 years")) {
    companyTypeFilters = {
      ...companyTypeFilters,
      businessAgeLowerLimit: 10,
    };
  }
  if (filters?.businessAge?.includes("Unknown")) {
    companyTypeFilters = {
      ...companyTypeFilters,
      businessAgeLowerLimit: 0,
    };
  }

  // Get keys from object2
  const keysToExtractGeographyFilters = Object.keys({} as GeographyFilters);

  // Create a new object with matching keys from object1
  let geographyFilters = null;

  // Get keys from object2
  const keysToExtractOwnershipFilters = Object.keys({} as OwnershipFilters);

  // Create a new object with matching keys from object1
  let ownershipFilters = filters
    ? Object.fromEntries(
        Object.entries(filters).filter(([key]) =>
          keysToExtractOwnershipFilters.includes(key)
        )
      )
    : null;

  if (filters?.companyOwnership?.includes("Private Equity Backed")) {
    ownershipFilters = { ...ownershipFilters, privateEquityBacked: true };
  }
  if (filters?.companyOwnership?.includes("Multi franchise brand")) {
    ownershipFilters = {
      ...ownershipFilters,
      multipleBrandLocationOwner: true,
    };
  }
  if (filters?.companyOwnership?.includes("Multiple owners")) {
    ownershipFilters = { ...ownershipFilters, multipleOwners: true };
  }
  if (filters?.companyOwnership?.includes("Single service vs. multi-service")) {
    ownershipFilters = {
      ...ownershipFilters,
      multipleBrandOwnerSameFamilyOfBrands: true,
    };
  }
  if (
    filters?.companyOwnership?.includes("Non-competing affiliate companies")
  ) {
    ownershipFilters = {
      ...ownershipFilters,
      multipleBrandOwnerDifferentFamilyOfBrands: true,
    };
  }

  // Get keys from object2
  const keysToExtractReferralMatrixFilters = Object.keys(
    {} as ReferralMatrixFilters
  );

  // Create a new object with matching keys from object1
  let referralMatrixFilters = filters
    ? Object.fromEntries(
        Object.entries(filters).filter(([key]) =>
          keysToExtractReferralMatrixFilters.includes(key)
        )
      )
    : null;

  if (filters?.localReferralNetwork) {
    referralMatrixFilters = {
      ...referralMatrixFilters,
      nationalReferralNetworks: true,
    };
  }
  if (filters?.tradeengageActivity?.includes("Pays Referral Fee")) {
    referralMatrixFilters = {
      ...referralMatrixFilters,
      paysReferralFee: true,
    };
  }
  if (filters?.tradeengageActivity?.includes("TradeEngage User")) {
    referralMatrixFilters = {
      ...referralMatrixFilters,
      tradeengageUser: true,
    };
  }

  // Get keys from object2
  const keysToExtractReviewsAndQualitySignalsFilters = Object.keys(
    {} as ReviewsAndQualitySignalsFilters
  );

  // Create a new object with matching keys from object1
  let reviewsAndQualitySignalsFilters = filters
    ? Object.fromEntries(
        Object.entries(filters).filter(([key]) =>
          keysToExtractReviewsAndQualitySignalsFilters.includes(key)
        )
      )
    : null;

  if (filters?.googleRating) {
    reviewsAndQualitySignalsFilters = {
      ...reviewsAndQualitySignalsFilters,
      googleRatingUpperLimit: filters.googleRating[1],
      googleRatingLowerLimit: filters.googleRating[0],
    };
  }

  if (filters?.numberOfGoogleReviews) {
    reviewsAndQualitySignalsFilters = {
      ...reviewsAndQualitySignalsFilters,
      googleNumberReviewsUpperLimit: filters.numberOfGoogleReviews[1],
      googleNumberReviewsLowerLimit: filters.numberOfGoogleReviews[0],
    };
  }

  // Get keys from object2
  const keysToExtractOtherCriteriaFilters = Object.keys(
    {} as OtherCriteriaFilters
  );

  // Create a new object with matching keys from object1
  let otherCriteriaFilters = filters
    ? Object.fromEntries(
        Object.entries(filters).filter(([key]) =>
          keysToExtractOtherCriteriaFilters.includes(key)
        )
      )
    : null;

  if (filters?.zipCodes?.length) {
    geographyFilters = {
      zipCodes: filters?.zipCodes,
      milesRadius: filters?.milesRadius ?? 30,
    };
  }
  if (filters?.states?.length) {
    geographyFilters = {
      states: filters?.states,
    };
  }

  if (filters?.contactInformation?.includes("Owner Mobile Number Verified")) {
    otherCriteriaFilters = { ...otherCriteriaFilters, ownerPhone: true };
  }
  if (filters?.contactInformation?.includes("Owner Email Address Verified")) {
    otherCriteriaFilters = {
      ...otherCriteriaFilters,
      ownerEmail: true,
    };
  }
  const offset = pageNumber === 0 ? pageNumber : pageSize * pageNumber + 1;

  const newSorting = sorting ? transformSortingFields(sorting) : undefined;

  let filtersArray: Array<any> = [];
  type LogicOperator = "and" | "or";
  let logicOperator: LogicOperator | undefined;
  const payload = {
    ...filters,
    offset,
    size: pageSize,
    sorting,
    filterTables: {},
  };

  if (tableFilters) {
    logicOperator = tableFilters.logicOperator as LogicOperator;
    filtersArray = tableFilters.items
      .filter(
        (item: {
          value: null | undefined | unknown;
          operator: string;
          field: string;
        }) =>
          item.value !== null && item.value !== "" && item.field !== "__check__"
      )
      .filter(
        (item: { value: string; operator: string }) =>
          item.value !== undefined ||
          (item.value === undefined &&
            ["isEmpty", "isNotEmpty"].includes(item.operator))
      )
      .map(
        (item: {
          value: null | undefined | unknown;
          operator: string;
          field: string;
        }) => ({
          value: item.value,
          field: camelToUnderscorePreserveHyphen(item.field),
          operator: item.operator,
        })
      );
    payload.filterTables = {
      logicOperator,
      filters: filtersArray,
    };
  }
  const underScordedPayload = changeCaseKeys(
    {
      offset,
      size: pageSize,
      otherCriteriaFilters,
      reviewsAndQualitySignalsFilters,
      referralMatrixFilters,
      ownershipFilters,
      companyTypeFilters,
      geographyFilters,
      sorting: newSorting,
      ...(filters?.countOnly ? { countOnly: true } : {}),
      ...(filtersArray.length > 0
        ? { filterTables: payload.filterTables }
        : {}),
    },
    "underscored"
  );
  return underScordedPayload;
};

export function useQueryCompanySearch(
  pageNumber: number,
  pageSize: number,
  filters?: InternalAdminFilters,
  sorting?: Sorting[],
  tableFilters?: GridFilterModel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<any>> {
  // const uri = "/internal-admin/companies/search";
  const uri = "/internal-admin/companies/search/async";
  const payload = getCompanySearchPayload(
    pageNumber,
    pageSize,
    filters,
    sorting,
    tableFilters
  );

  return useQuery({
    queryKey: [uri.toString(), JSON.stringify(payload)],
    queryFn: async () => {
      const response = await API.post(uri, payload);
      const camelCaseResponse = response.data.data;
      return camelCaseResponse;
    },
    ...options,
  });
}
