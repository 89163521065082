import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Tooltip as MuiTooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

type ChartSeries = {
  name: string;
  data: (number | null)[];
}[];

interface BasicCompanySchema {
  name: string;
  brandName?: string;
  logoUrl?: string;
}

interface AnalyticsBarChartsProps {
  chartSeries: ChartSeries;
  categories: Array<BasicCompanySchema>;
  title: string;
  subtitle?: string;
  tooltip: string;
  yLabel: string;
  xLabel: string;
}

const CustomLegend = ({ data }) => {
  return (
    <Box display="flex" justifyContent="center" mt={2}>
      {data.map((entry, index) => (
        <Box key={`legend-${index}`} display="flex" alignItems="center" mx={1}>
          <Box
            sx={{
              backgroundColor: entry.color,
              width: 12,
              height: 12,
              borderRadius: "50%",
              marginRight: 1,
            }}
          />
          <Typography
            variant="body2"
            style={{ fontSize: 12, fontFamily: "Arial" }}
          >
            {entry.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const AnalyticsBarChart: React.FC<AnalyticsBarChartsProps> = ({
  chartSeries,
  categories,
  title,
  subtitle,
  yLabel,
  xLabel,
}) => {
  const theme = useTheme();

  const data = categories.map((category, index) => ({
    name: category.name,
    brandName: category.brandName,
    logo: category.logoUrl,
    value1: chartSeries[0].data[index],
    value2: chartSeries[1].data[index],
  }));

  // Custom X-axis tick with logo and tooltip
  const CustomXAxisTick = ({ x, y, payload }: any) => {
    const company = data.find(item => item.name === payload.value);

    return (
      <foreignObject x={x - 50} y={y} width={100} height={150}>
        <MuiTooltip title={company?.name ?? ""}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              transform: "rotate(-45deg)", // Rotate the entire label
              transformOrigin: "center", // Anchor to bottom left
              marginTop: "30px", // Adjust margin to avoid clipping
            }}
          >
            {/* {company?.logo && (
              <img
                src={company.logo}
                alt={company.name}
                style={{
                  width: 30,
                  height: 30,
                  marginBottom: 5,
                }}
              />
            )} */}
            <Typography
              variant="caption"
              style={{ fontSize: "0.7rem", textAlign: "center" }}
            >
              {company?.name}
            </Typography>
          </Box>
        </MuiTooltip>
      </foreignObject>
    );
  };

  return (
    <Card sx={{ boxShadow: "0px 0px 0px 1px #F2F4F7" }}>
      <CardHeader
        sx={{ pb: 0, mb: 0 }}
        title={
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="h6">{title}</Typography>
            {subtitle && (
              <Typography variant="subtitle2" color="text.secondary">
                {subtitle}
              </Typography>
            )}
          </Box>
        }
      />
      <CardContent sx={{ p: 2, py: 1 }}>
        <ResponsiveContainer width="100%" height={550}>
          <BarChart
            data={data}
            margin={{ top: 30, right: 30, left: 20, bottom: 100 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="name"
              angle={-45}
              tick={<CustomXAxisTick />}
              axisLine={{ stroke: "rgb(224, 224, 224)", strokeWidth: 1 }} // Color and width for X-axis line
              tickLine={{ stroke: "rgb(224, 224, 224)" }} // Color for X-axis ticks
            />

            <YAxis
              tick={{
                fontSize: 11,
                fontWeight: 400,
                fontFamily: "Helvetica, Arial, sans-serif",
                fill: "#333",
              }} // Adjust font for Y-axis labels
              axisLine={{ stroke: "rgb(224, 224, 224)", strokeWidth: 1 }} // Color and width for Y-axis line
              tickLine={{ stroke: "rgb(224, 224, 224)" }} // Color for Y-axis ticks
              label={{
                value: yLabel,
                angle: -90,
                position: "insideLeft",
                fill: "#333",
                fontSize: 11,
                fontWeight: 900,
                dy: 20,
                fontFamily: "Helvetica, Arial, sans-serif",
              }}
            />
            <Tooltip
              content={({ payload }) => {
                if (payload && payload.length) {
                  return (
                    <Box
                      p={1}
                      bgcolor="rgba(0, 0, 0, 0.75)"
                      color="white"
                      display="flex"
                      flexDirection={"column"}
                      gap={0.5}
                    >
                      <Typography variant="body2">
                        {payload[0].payload.name}
                      </Typography>
                      {chartSeries.map((series, index) => (
                        <Typography key={index} variant="caption">
                          {series.name}: {payload[index].value}
                        </Typography>
                      ))}
                    </Box>
                  );
                }
                return null;
              }}
            />
            <Bar
              dataKey="value1"
              fill={theme.palette.primary.main}
              name={chartSeries[0].name}
            />
            <Bar dataKey="value2" fill="#071D2F" name={chartSeries[1].name} />
          </BarChart>
        </ResponsiveContainer>
        <CustomLegend
          data={[
            { name: chartSeries[0].name, color: theme.palette.primary.main },
            { name: chartSeries[1].name, color: "#071D2F" },
          ]}
        />
      </CardContent>
    </Card>
  );
};

export default AnalyticsBarChart;
