import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import { useCallback, useState } from "react";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useUpdateCompaniesById } from "@/company/onboarding/api";

export const EditNotesArea = (props: Record<string, any>) => {
  const { isLoading: updatingCompany, mutateAsync: updateCompanyNotes } =
    useUpdateCompaniesById({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while updating company notes.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        enqueueSnackbar({
          message: "Your notes have been saved.",
          variant: "success",
        });
      },
    });
  const { id, field, value, colDef, api, user } = props;
  const [valueState, setValueState] = useState("");

  const handleSaveNote = useCallback(async () => {
    const newNote = `${valueState}\nAdded By:- ${user?.email}\n${DateTime.utc()
      .toLocal()
      .toFormat("MM/dd/yy")}`;
    const updatedNotes = value ? value + "\n\n\n" + newNote : newNote;
    await updateCompanyNotes([
      {
        id: props.row.id,
        companyDetails: { company: { notes: updatedNotes } },
      },
    ]);
    api.setEditCellValue({ ...props, value: updatedNotes });
    api.stopCellEditMode({ id, field });
  }, [api, field, id, valueState, value, updateCompanyNotes]);

  const handleClose = useCallback(() => {
    api.stopCellEditMode({ id, field });
  }, [api, field, id]);

  return (
    <Dialog
      fullWidth
      open={true}
      maxWidth="md"
      sx={{ p: 2 }}
      onClose={() => handleClose()}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        m={2}
        p={2}
      >
        <Box>
          <Typography variant="h5">
            Add Notes to Follow Up with Company
          </Typography>
        </Box>
        <IconButton size="small" sx={{ pr: 0 }} onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Paper elevation={1} sx={{ p: 2, minWidth: colDef.computedWidth }}>
        <Box display={"flex"} flexDirection={"column"} gap={1} width={"100%"}>
          <Box
            sx={{
              height: "400px",
              overflow: "auto",
              whiteSpace: "pre-line",
            }}
          >
            <Typography gutterBottom variant="h6">
              Notes
            </Typography>
            {props.value ? <Divider sx={{ mb: 2 }} /> : null}
            <Typography variant="body2">{props.value}</Typography>
          </Box>
          <TextField
            multiline
            id="note"
            name="note"
            label="Add New Note"
            margin="normal"
            value={valueState}
            sx={{
              overflow: "auto",
            }}
            inputProps={{
              onKeyDown: e => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                }
              },
            }}
            onChange={event => {
              setValueState(event.target.value);
            }}
          />
          <Button
            disabled={
              updatingCompany || !valueState?.replace(/\s+/g, "")?.length
            }
            variant="contained"
            onClick={handleSaveNote}
          >
            Add Note
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};
