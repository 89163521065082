import constate from "constate";
import { useState } from "react";

function useConnectionLimitReachedModal() {
  const [showLimitReachedDialog, setShowLimitReachedDialog] = useState("");

  return { showLimitReachedDialog, setShowLimitReachedDialog };
}

export const [
  ConnectionLimitReachedModalProvider,
  useConnectionLimitReachedModalContext,
] = constate(useConnectionLimitReachedModal);
