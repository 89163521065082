import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback } from "react";

import { useAuth } from "@/auth/context/jwt";
import {
  CompanyExploreFilters,
  SearchedCompany,
  useQueryExploreCompanies,
} from "@/company/api/useExploreConnections";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { JobSectors } from "@/company/jobs/constants";
import {
  CompanyPublicProfile,
  ConnectionStatus,
  Referral,
  ReferralInvitation,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { customCompanyProfileSort } from "@/utils";

import { TabsEnum } from "../../company-drawer/CompanyDrawer";
import { CompanyProfileCard } from "../../company-profile-card/CompanyProfileCard";

export const ExplorePartnerTabs = [
  { label: "Pending Invitations", filter: {} },
  { label: "Affiliate Companies", filter: {} },
  { label: "Companies You May Know", filter: {} },
  { label: "Explore", filters: {} },
];
export interface CompanyFiltersModal {
  initialValues: CompanyExploreFilters;
  applyFilters: (filters: CompanyExploreFilters) => void;
  variant: "permanent" | "temporary";
  closeFilters: () => void;
}

export const filterValidationSchema = {};

export function OtherRecommendations({
  setFiltersThroughRecommendationTab,
  setSelectedCompany,
  setCurrentCompanyProfile,
  setSelectedTab,
  referralPartners,
  referralInvitations,
  isTabPanelSticky = false,
  showRecommendedPartnersOnly = false,
}: {
  setFiltersThroughRecommendationTab?: (
    drawerFilters: Partial<CompanyExploreFilters>
  ) => void;
  setSelectedCompany: (value: SearchedCompany | null) => void;
  setCurrentCompanyProfile: (value: CompanyPublicProfile | null) => void;
  setSelectedTab: (value: TabsEnum | null) => void;
  referralPartners?: Array<Referral>;
  referralInvitations?: Array<ReferralInvitation>;
  isTabPanelSticky?: boolean;
  showRecommendedPartnersOnly?: boolean;
}) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const MAX_RESULT_COUNT = 20;
  const { session } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id, {
      refetchOnWindowFocus: false,
      enabled: !!company,
      retry: false,
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];
  const { isFetching: isFetchingLinkedCompanies, data: linkedCompanies } =
    useQueryExploreCompanies(
      { linkedCompanies: true, limit: 9 },
      recommendedJobSectorsList?.length
        ? recommendedJobSectorsList
        : relevanceOrderedJobSectorsList,
      {
        refetchOnWindowFocus: false,
        enabled: !!(
          relevanceOrderedJobSectorsList || recommendedJobSectorsList
        ),
        retry: false,
      }
    );

  const {
    isFetching: isFetchingRecommendedCompanies,
    data: recommendedCompaniesData,
  } = useQueryExploreCompanies(
    {
      topReferralCategoryCheck: true,
      nearby: true,
      limit: MAX_RESULT_COUNT,
      ...(linkedCompanies && linkedCompanies?.length
        ? {
            excludeCompanyIds: linkedCompanies
              .filter(comp => !!comp.companyPublicProfile?.id)
              .map(
                comp => "excludeCompanyIds=" + comp?.companyPublicProfile?.id
              )
              ?.join("&"),
          }
        : {}),
    },
    recommendedJobSectorsList?.length
      ? recommendedJobSectorsList
      : relevanceOrderedJobSectorsList,
    {
      refetchOnWindowFocus: false,
      enabled: !!(
        (!!relevanceOrderedJobSectorsList || relevanceOrderedJobSectorsList) &&
        !isFetchingLinkedCompanies &&
        !showRecommendedPartnersOnly
      ),
      retry: false,
    }
  );

  const {
    isFetching: isFetchingOtherRecommendations,
    data: otherRecommendedCompaniesData,
  } = useQueryExploreCompanies(
    {
      nearby: true,
      sectors: Object.values(JobSectors).filter(sector => {
        if (recommendedJobSectorsList?.length) {
          return !relevanceOrderedJobSectorsList?.includes(sector);
        }
        return !relevanceOrderedJobSectorsList?.includes(sector);
      }),
      limit: MAX_RESULT_COUNT,
      ...(linkedCompanies && linkedCompanies?.length
        ? {
            excludeCompanyIds: linkedCompanies
              .filter(comp => !!comp.companyPublicProfile?.id)
              .map(
                comp => "exclude_company_ids=" + comp?.companyPublicProfile?.id
              )
              ?.join("&"),
          }
        : {}),
    },
    recommendedJobSectorsList?.length
      ? recommendedJobSectorsList
      : relevanceOrderedJobSectorsList,
    {
      refetchOnWindowFocus: false,
      enabled: !!(
        (relevanceOrderedJobSectorsList || relevanceOrderedJobSectorsList) &&
        !isFetchingLinkedCompanies &&
        !showRecommendedPartnersOnly
      ),
      retry: false,
    }
  );

  const {
    isFetching: isFetchingRecommendedGoogleCompanies,
    data: recommendedGoogleCompaniesData,
  } = useQueryExploreCompanies(
    {
      topReferralCategoryCheck: true,
      nearby: true,
      limit: MAX_RESULT_COUNT,
      isGoogleCompany: true,
    },
    recommendedJobSectorsList?.length
      ? recommendedJobSectorsList
      : relevanceOrderedJobSectorsList,
    {
      refetchOnWindowFocus: false,
      enabled: !!(
        (relevanceOrderedJobSectorsList || relevanceOrderedJobSectorsList) &&
        !isFetchingLinkedCompanies &&
        !showRecommendedPartnersOnly
      ),
      retry: false,
    }
  );

  const {
    isFetching: isFetchingOtherGoogleRecommendations,
    data: otherRecommendedGoogleCompaniesData,
  } = useQueryExploreCompanies(
    {
      nearby: true,
      sectors: Object.values(JobSectors).filter(
        sector => !relevanceOrderedJobSectorsList?.includes(sector)
      ),
      limit: MAX_RESULT_COUNT,
      isGoogleCompany: true,
    },
    recommendedJobSectorsList?.length
      ? recommendedJobSectorsList
      : relevanceOrderedJobSectorsList,
    {
      refetchOnWindowFocus: false,
      enabled: !!(
        (relevanceOrderedJobSectorsList || relevanceOrderedJobSectorsList) &&
        !isFetchingLinkedCompanies &&
        !showRecommendedPartnersOnly
      ),
      retry: false,
    }
  );

  const interactedCompanies = linkedCompanies?.filter(
    company =>
      !referralPartners?.some(
        partner =>
          partner.otherCompanyProfile.id === company?.companyPublicProfile?.id
      )
  );

  let recommendedCompanies = recommendedCompaniesData ?? [];
  if (recommendedGoogleCompaniesData?.length) {
    recommendedCompanies = [
      ...recommendedCompanies,
      ...recommendedGoogleCompaniesData,
    ];
    recommendedCompanies.sort(customCompanyProfileSort);
  }

  recommendedCompanies = recommendedCompanies?.filter(
    company =>
      !referralPartners?.some(
        partner =>
          partner.otherCompanyProfile.id === company?.companyPublicProfile?.id
      )
  );

  let otherRecommendedCompanies = otherRecommendedCompaniesData ?? [];

  if (otherRecommendedGoogleCompaniesData?.length) {
    otherRecommendedCompanies = [
      ...otherRecommendedCompanies,
      ...otherRecommendedGoogleCompaniesData,
    ];
    otherRecommendedCompanies.sort(customCompanyProfileSort);
  }

  otherRecommendedCompanies = otherRecommendedCompanies?.filter(
    company =>
      !referralPartners?.some(
        partner =>
          partner.otherCompanyProfile.id === company?.companyPublicProfile?.id
      )
  );

  const getConnectionStatus = useCallback(
    (currentCompany: CompanyPublicProfile): ConnectionStatus => {
      if (
        referralPartners?.some(
          referral =>
            referral.referredCompanyId === currentCompany?.id ||
            referral.referringCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.CONNECTED;

      if (
        referralInvitations?.some(
          invitation => invitation.invitedCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.PENDING;

      if (
        referralInvitations?.some(
          invitation => invitation.invitingCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.INVITED;

      return ConnectionStatus.NOT_CONNECTED;
    },
    [referralInvitations, referralPartners]
  );

  const googlePlacesIdSet = new Set<string>();

  recommendedCompanies = recommendedCompanies?.filter(company => {
    const googleId =
      company.companyPublicProfile?.profile.googlePlacesId ??
      company.googleCompanyProfile?.googlePlacesId;
    if (googleId) {
      if (googlePlacesIdSet.has(googleId)) return false;
      googlePlacesIdSet.add(googleId);
      return true;
    }
    return true;
  });

  recommendedCompanies = recommendedCompanies.slice(0, 9);

  otherRecommendedCompanies = otherRecommendedCompanies?.filter(company => {
    const googleId =
      company.companyPublicProfile?.profile.googlePlacesId ??
      company.googleCompanyProfile?.googlePlacesId;
    if (googleId) {
      if (googlePlacesIdSet.has(googleId)) return false;
      googlePlacesIdSet.add(googleId);
      return true;
    }
    return true;
  });
  otherRecommendedCompanies = otherRecommendedCompanies.slice(0, 9);

  return (
    <Grid
      container
      py={3}
      sx={{ ...(isTabPanelSticky && isMobile ? { mt: "112px" } : {}) }}
    >
      {isFetchingLinkedCompanies ? (
        <Box
          py={3}
          mt={6}
          textAlign="center"
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={2}
        >
          <CircularProgress />
          <Typography variant="body1">
            Hang tight! We&apos;re building your custom list of referral
            partners.
          </Typography>
        </Box>
      ) : showRecommendedPartnersOnly ? (
        <>
          {interactedCompanies?.length ? (
            <Accordion defaultExpanded sx={{ width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  mr={isMobile ? 2 : 0}
                  justifyContent={"space-between"}
                  alignItems={isMobile ? "start" : "center"}
                  flexDirection={isMobile ? "column" : "row"}
                >
                  <Typography variant="h6" sx={{ ml: isMobile ? 2 : 0 }}>
                    Partners you may know based on your recent activity
                  </Typography>
                  <Button
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      color: "primary",
                      ...(isMobile ? { px: 0, pb: 0 } : {}),
                    }}
                    onClick={() => {
                      setFiltersThroughRecommendationTab?.({
                        linkedCompanies: true,
                      });
                    }}
                  >
                    See all
                  </Button>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {interactedCompanies && interactedCompanies.length > 0 ? (
                    interactedCompanies.map((companyData, index) => (
                      <Grid key={index} item xs={12} sm={6} lg={4} p={2}>
                        <CompanyProfileCard
                          {...(companyData.companyPublicProfile
                            ? {
                                companyProfile:
                                  companyData.companyPublicProfile,
                                connectionStatus: getConnectionStatus(
                                  companyData.companyPublicProfile
                                ),
                              }
                            : {})}
                          {...(companyData.googleCompanyProfile
                            ? {
                                googleCompanyProfile:
                                  companyData.googleCompanyProfile,
                                hasMarkedInterest:
                                  !!companyData.hasMarkedInterest,
                              }
                            : {})}
                          showParentLogo={false}
                          onOpen={({ tab, markInterest }) => {
                            if (tab && companyData.companyPublicProfile) {
                              setSelectedTab(tab);
                              setCurrentCompanyProfile(
                                companyData.companyPublicProfile ?? null
                              );
                            } else {
                              setSelectedCompany({
                                ...companyData,
                                hasMarkedInterest: markInterest,
                              });
                            }
                          }}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Box textAlign="center" width="100%" py={3}>
                      <Typography variant="body1">
                        There are no companies matching your filters, please
                        click on the filters button above and update them.
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </>
      ) : (
        <>
          {interactedCompanies?.length ? (
            <Accordion defaultExpanded sx={{ width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  mr={isMobile ? 2 : 0}
                  justifyContent={"space-between"}
                  alignItems={isMobile ? "start" : "center"}
                  flexDirection={isMobile ? "column" : "row"}
                >
                  <Typography variant="h6">
                    Partners you may know based on your recent activity
                  </Typography>
                  <Button
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      color: "primary",
                      ...(isMobile ? { px: 0, pb: 0 } : {}),
                    }}
                    onClick={() => {
                      setFiltersThroughRecommendationTab?.({
                        linkedCompanies: true,
                      });
                    }}
                  >
                    See all
                  </Button>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {interactedCompanies && interactedCompanies.length > 0 ? (
                    interactedCompanies.map((companyData, index) => (
                      <Grid key={index} item xs={12} sm={6} lg={4} p={2}>
                        <CompanyProfileCard
                          {...(companyData.companyPublicProfile
                            ? {
                                companyProfile:
                                  companyData.companyPublicProfile,
                                connectionStatus: getConnectionStatus(
                                  companyData.companyPublicProfile
                                ),
                              }
                            : {})}
                          {...(companyData.googleCompanyProfile
                            ? {
                                googleCompanyProfile:
                                  companyData.googleCompanyProfile,
                                hasMarkedInterest:
                                  !!companyData.hasMarkedInterest,
                              }
                            : {})}
                          showParentLogo={false}
                          onOpen={({ tab, markInterest }) => {
                            if (tab && companyData.companyPublicProfile) {
                              setSelectedTab(tab);
                              setCurrentCompanyProfile(
                                companyData.companyPublicProfile ?? null
                              );
                            } else {
                              setSelectedCompany({
                                ...companyData,
                                hasMarkedInterest: markInterest,
                              });
                            }
                          }}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Box textAlign="center" width="100%" py={3}>
                      <Typography variant="body1">
                        There are no companies matching your filters, please
                        click on the filters button above and update them.
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : null}
          {recommendedCompanies?.length ? (
            <Accordion defaultExpanded sx={{ width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  mr={isMobile ? 2 : 0}
                  justifyContent={"space-between"}
                  alignItems={isMobile ? "start" : "center"}
                  flexDirection={isMobile ? "column" : "row"}
                >
                  <Typography variant="h6">
                    {(recommendedJobSectorsList?.length
                      ? recommendedJobSectorsList
                      : relevanceOrderedJobSectorsList
                    )
                      ?.slice(0, 2)
                      ?.map(sector => snakeCaseJobSectors[sector])
                      ?.join(", ")}{" "}
                    & other sectors you may be interested in
                  </Typography>
                  <Button
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      color: "primary",
                      ...(isMobile ? { px: 0, pb: 0 } : {}),
                    }}
                    onClick={() => {
                      setFiltersThroughRecommendationTab?.({
                        topReferralCategoryCheck: true,
                      });
                    }}
                  >
                    See all
                  </Button>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {recommendedCompanies && recommendedCompanies.length > 0 ? (
                    recommendedCompanies.map((companyData, index) => (
                      <Grid key={index} item xs={12} sm={6} lg={4} p={2}>
                        <CompanyProfileCard
                          {...(companyData.companyPublicProfile
                            ? {
                                companyProfile:
                                  companyData.companyPublicProfile,
                                connectionStatus: getConnectionStatus(
                                  companyData.companyPublicProfile
                                ),
                              }
                            : {})}
                          {...(companyData.googleCompanyProfile
                            ? {
                                googleCompanyProfile:
                                  companyData.googleCompanyProfile,
                                hasMarkedInterest:
                                  !!companyData.hasMarkedInterest,
                              }
                            : {})}
                          showParentLogo={false}
                          onOpen={({ tab, markInterest }) => {
                            if (tab && companyData.companyPublicProfile) {
                              setSelectedTab(tab);
                              setCurrentCompanyProfile(
                                companyData.companyPublicProfile ?? null
                              );
                            } else {
                              setSelectedCompany({
                                ...companyData,
                                hasMarkedInterest: markInterest,
                              });
                            }
                          }}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Box textAlign="center" width="100%" py={3}>
                      <Typography variant="body1">
                        There are no companies matching your filters, please
                        click on the filters button above and update them.
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : null}
          {otherRecommendedCompanies?.length ? (
            <Accordion defaultExpanded sx={{ width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  mr={isMobile ? 2 : 0}
                  justifyContent={"space-between"}
                  alignItems={isMobile ? "start" : "center"}
                  flexDirection={isMobile ? "column" : "row"}
                >
                  <Typography variant="h6">
                    Other nearby referral partners for you
                  </Typography>
                  <Button
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      color: "primary",
                      ...(isMobile ? { px: 0, pb: 0 } : {}),
                    }}
                    onClick={() => {
                      setFiltersThroughRecommendationTab?.({
                        otherNearbyCheck: true,
                      });
                    }}
                  >
                    See all
                  </Button>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {otherRecommendedCompanies &&
                  otherRecommendedCompanies.length > 0 ? (
                    otherRecommendedCompanies.map((companyData, index) => (
                      <Grid key={index} item xs={12} sm={6} lg={4} p={2}>
                        <CompanyProfileCard
                          {...(companyData.companyPublicProfile
                            ? {
                                companyProfile:
                                  companyData.companyPublicProfile,
                                connectionStatus: getConnectionStatus(
                                  companyData.companyPublicProfile
                                ),
                              }
                            : {})}
                          {...(companyData.googleCompanyProfile
                            ? {
                                googleCompanyProfile:
                                  companyData.googleCompanyProfile,
                                hasMarkedInterest:
                                  !!companyData.hasMarkedInterest,
                              }
                            : {})}
                          showParentLogo={false}
                          onOpen={({ tab, markInterest }) => {
                            if (tab && companyData.companyPublicProfile) {
                              setSelectedTab(tab);
                              setCurrentCompanyProfile(
                                companyData.companyPublicProfile ?? null
                              );
                            } else {
                              setSelectedCompany({
                                ...companyData,
                                hasMarkedInterest: markInterest,
                              });
                            }
                          }}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Box textAlign="center" width="100%" py={3}>
                      <Typography variant="body1">
                        There are no companies matching your filters, please
                        click on the filters button above and update them.
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </>
      )}
    </Grid>
  );
}
