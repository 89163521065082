import AddIcon from "@mui/icons-material/Add";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { Box, Button, Divider, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FC, useState } from "react";

import { useCompanyChildrenRelationships } from "@/company/api/useCompanyChildrenRelationships";
import { useCompanyParentRelationships } from "@/company/api/useCompanyParentRelationships";
import { BasicCompanySchema } from "@/company/api/useGetAllBrands";
import { JobSectors } from "@/company/jobs/constants";
import { COMPANY_ACCOUNT_TYPES, PLATFORM_PRESENCE } from "@/company/types";

import ChildrenRelationshipTable from "./ChildrenRelationshipTable";
import { CreateRelationshipByParentDialog } from "./CreateRelationshipByParentDialog";
import ParentRelationshipTable from "./ParentRelationshipTable";

export interface BrandLocationReferralSchema {
  sector: JobSectors;
  order: number;
  createdAt: DateTime;
  buyingPrice: number;
}

export interface BrandLocationReferralInvitationSchema {
  sectors: Array<JobSectors>;
  createdAt: DateTime;
  acceptedAt?: string;
  declinedAt?: string;
}

export interface BrandLocationReferralNetworkSchema {
  referrals: Array<BrandLocationReferralSchema>;
  referralInvitationsSent: Array<BrandLocationReferralInvitationSchema>;
  referralInvitationsReceived: Array<BrandLocationReferralInvitationSchema>;
  affiliatedCompanies: Array<BasicCompanySchema>;
  recommendedPartners: Array<BasicCompanySchema>;
}

export interface RelationshipsOverviewProps {
  referralNetwork: BrandLocationReferralNetworkSchema;
  overview: any;
  outboundInvitations?: number;
  inboundInvitations?: number;
  companyId: number;
  expanded?: boolean;
  refetch: () => void;
}

export enum RelationshipType {
  PARENT_RELATIONSHIPS = "PARENT_RELATIONSHIPS",
  CHILDREN_RELATIONSHIPS = "CHILDREN_RELATIONSHIPS",
}

export const RelationshipsOverview: FC<RelationshipsOverviewProps> = ({
  overview,
  companyId,
  expanded,
  refetch,
}) => {
  const { data: parentRelationships, refetch: refetchParents } =
    useCompanyParentRelationships(companyId);
  const { data: childrenRelationships, refetch: refetchChildren } =
    useCompanyChildrenRelationships(companyId);

  const [createRelationshipsOpen, setCreateRelationshipsOpen] = useState(false);

  const [selectedRelationshipType, setSelectedRelationshipType] =
    useState<RelationshipType>(RelationshipType.PARENT_RELATIONSHIPS);
  return (
    <>
      <Box
        display={"flex"}
        pb={2}
        gap={1}
        flexDirection={"column"}
        width={"50%"}
        pl={2}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="h6">{overview.name}</Typography>
          {overview.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM ? (
            <VerifiedOutlinedIcon
              sx={{
                verticalAlign: "center",
                color: "#16DB65",
                ml: 0.5,
                height: "20px",
              }}
            />
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: "8px",
          /* elevation/outlined */
          boxShadow: "0px 0px 0px 1px #F2F4F7",
        }}
        pl={2}
        py={1}
      >
        <Typography variant="overline" color="rgba(17, 25, 39, 0.38)" py={2}>
          All Relationships
        </Typography>
        {overview.accountType !== COMPANY_ACCOUNT_TYPES.BRAND_LOCATION ? (
          <Box
            width="100%"
            display={"flex"}
            alignItems={"center"}
            gap={1}
            py={2}
          >
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setCreateRelationshipsOpen(true)}
            >
              Add Children Companies
            </Button>
          </Box>
        ) : null}
        <Divider />
        <Box display={"flex"} gap={1} py={1} flexWrap={"wrap"}>
          <Button
            size="small"
            disabled={!parentRelationships?.length}
            sx={{
              bgcolor:
                selectedRelationshipType ===
                RelationshipType.PARENT_RELATIONSHIPS
                  ? "#16B364"
                  : "rgba(17, 25, 39, 0.08)",
              color:
                selectedRelationshipType ===
                RelationshipType.PARENT_RELATIONSHIPS
                  ? "white"
                  : "#111927",
              "&:hover": {
                color: "#111927",
              },
              "&:focus": {
                bgcolor: "#16B364",
              },
            }}
            onClick={() =>
              setSelectedRelationshipType(RelationshipType.PARENT_RELATIONSHIPS)
            }
          >
            Parent Relationships ({parentRelationships?.length})
          </Button>
          <Button
            size="small"
            disabled={!childrenRelationships?.length}
            sx={{
              bgcolor:
                selectedRelationshipType ===
                RelationshipType.CHILDREN_RELATIONSHIPS
                  ? "#16B364"
                  : "rgba(17, 25, 39, 0.08)",
              color:
                selectedRelationshipType ===
                RelationshipType.CHILDREN_RELATIONSHIPS
                  ? "white"
                  : "#111927",
              "&:hover": {
                color: "#111927",
              },
              "&:focus": {
                bgcolor: "#16B364",
              },
            }}
            onClick={() =>
              setSelectedRelationshipType(
                RelationshipType.CHILDREN_RELATIONSHIPS
              )
            }
          >
            Children Relationships ({childrenRelationships?.length})
          </Button>
        </Box>
        {selectedRelationshipType === RelationshipType.PARENT_RELATIONSHIPS &&
        parentRelationships?.length ? (
          <ParentRelationshipTable
            companyId={companyId}
            refetch={refetchParents}
            expanded={expanded}
            relationshipData={parentRelationships}
          />
        ) : selectedRelationshipType ===
            RelationshipType.CHILDREN_RELATIONSHIPS &&
          childrenRelationships?.length ? (
          <ChildrenRelationshipTable
            companyId={companyId}
            refetch={refetchChildren}
            expanded={expanded}
            relationshipData={childrenRelationships}
          />
        ) : null}
      </Box>
      {createRelationshipsOpen ? (
        <CreateRelationshipByParentDialog
          open={createRelationshipsOpen}
          parentId={companyId}
          refetch={() => refetchChildren()}
          onClickConfirm={() => setCreateRelationshipsOpen(false)}
          onClickCancel={() => setCreateRelationshipsOpen(false)}
          onClickClose={() => setCreateRelationshipsOpen(false)}
        />
      ) : null}
    </>
  );
};
