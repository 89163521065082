import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { isCompanyActive } from "@/common/utils/accessControl";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import {
  SearchedCompany,
  useQueryExploreCompanies,
} from "@/company/api/useExploreConnections";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { useMarkedInterestSetContext } from "@/company/context/marked-interest-context";
import { useReferralContext } from "@/company/context/referral-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import {
  CompanyPublicProfile,
  CompanyRoutes,
  ConnectionStatus,
} from "@/company/types";
import { customCompanyProfileSort } from "@/utils";

import { CompanyDrawer } from "../../company-drawer/CompanyDrawer";
import { ExternalCompanyDrawer } from "../../company-drawer/ExternalCompanyDrawer";
import { CompanyProfileCard } from "../../company-profile-card/CompanyProfileCard";

export function ExplorePartnersComponent() {
  const MAX_RESULT_COUNT = 4;
  const { session } = useAuth();
  const navigate = useNavigate();

  const [currentCompanyProfile, setCurrentCompanyProfile] =
    useState<CompanyPublicProfile | null>(null);

  const [selectedCompany, setSelectedCompany] =
    useState<SearchedCompany | null>(null);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const company = session?.company;
  const familyOfBrands = session?.familyOfBrands;
  const brandLocationProfile = session?.brandLocationProfile;

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id, {
      refetchOnWindowFocus: false,
      enabled: !!company,
      retry: false,
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];

  const { referralPartners, isFetchingReferralPartners } = useReferralContext();
  const { referralInvitations, isFetchingReferralInvitations } =
    useReferralInvitationContext();
  const { updateMarkedInterestSet } = useMarkedInterestSetContext();

  const { isFetching: isFetchingLinkedCompanies, data: linkedCompanies } =
    useQueryExploreCompanies(
      { linkedCompanies: true },
      recommendedJobSectorsList?.length
        ? recommendedJobSectorsList
        : relevanceOrderedJobSectorsList,
      {
        refetchOnWindowFocus: false,
        enabled: !!(
          relevanceOrderedJobSectorsList || recommendedJobSectorsList
        ),
        retry: false,
      }
    );

  const {
    isFetching: isFetchingRecommendedCompanies,
    data: recommendedCompaniesData,
  } = useQueryExploreCompanies(
    {
      nearby: true,
      limit: MAX_RESULT_COUNT,
      sectors: recommendedJobSectorsList?.length
        ? recommendedJobSectorsList
        : relevanceOrderedJobSectorsList,
      ...(linkedCompanies && linkedCompanies?.length
        ? {
            excludeCompanyIds: linkedCompanies
              .filter(comp => !!comp.companyPublicProfile?.id)
              .map(
                comp => "exclude_company_ids=" + comp?.companyPublicProfile?.id
              )
              ?.join("&"),
          }
        : {}),
    },
    recommendedJobSectorsList?.length
      ? recommendedJobSectorsList
      : relevanceOrderedJobSectorsList,
    {
      refetchOnWindowFocus: false,
      enabled:
        !!isCompanyActive(company?.status) &&
        !!(
          relevanceOrderedJobSectorsList?.length ||
          recommendedJobSectorsList?.length
        ),
      retry: false,
    }
  );

  const {
    isFetching: isFetchingRecommendedGoogleCompanies,
    data: recommendedGoogleCompaniesData,
  } = useQueryExploreCompanies(
    {
      nearby: true,
      limit: MAX_RESULT_COUNT,
      isGoogleCompany: true,
      sectors: recommendedJobSectorsList?.length
        ? recommendedJobSectorsList
        : relevanceOrderedJobSectorsList,
    },
    recommendedJobSectorsList?.length
      ? recommendedJobSectorsList
      : relevanceOrderedJobSectorsList,
    {
      refetchOnWindowFocus: false,
      enabled:
        !!isCompanyActive(company?.status) &&
        !!(
          relevanceOrderedJobSectorsList?.length ||
          recommendedJobSectorsList?.length
        ),
      retry: false,
    }
  );

  let recommendedCompanies =
    linkedCompanies?.filter(
      company =>
        !referralInvitations?.some(
          invitation =>
            company?.companyPublicProfile?.id === invitation.invitedCompanyId ||
            company?.companyPublicProfile?.id === invitation.invitingCompanyId
        )
    ) ?? [];

  recommendedCompanies = [
    ...recommendedCompanies,
    ...(recommendedCompaniesData?.filter(
      company =>
        !referralInvitations?.some(
          invitation =>
            company?.companyPublicProfile?.id === invitation.invitedCompanyId ||
            company?.companyPublicProfile?.id === invitation.invitingCompanyId
        )
    ) ?? []),
  ];

  recommendedCompanies = recommendedCompanies?.filter(
    company =>
      !referralPartners?.some(
        partner =>
          partner.otherCompanyProfile.id === company?.companyPublicProfile?.id
      )
  );

  if (recommendedGoogleCompaniesData?.length) {
    recommendedCompanies = [
      ...recommendedCompanies,
      ...recommendedGoogleCompaniesData,
    ];
    recommendedCompanies.sort(customCompanyProfileSort);
    recommendedCompanies = recommendedCompanies.filter(
      company => !company.hasMarkedInterest
    );
    recommendedCompanies = recommendedCompanies.slice(0, MAX_RESULT_COUNT);
  }

  const getConnectionStatus = useCallback(
    (currentCompany: CompanyPublicProfile): ConnectionStatus => {
      if (
        referralPartners?.some(
          referral =>
            referral.referredCompanyId === currentCompany?.id ||
            referral.referringCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.CONNECTED;

      if (
        referralInvitations?.some(
          invitation => invitation.invitedCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.PENDING;

      if (
        referralInvitations?.some(
          invitation => invitation.invitingCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.INVITED;

      return ConnectionStatus.NOT_CONNECTED;
    },
    [referralInvitations, referralPartners]
  );

  return (
    <Grid item xs={12}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "none !important",
          overflow: "hidden",
          alignItems: "center",
          alignSelf: "stretch",
          border: "1px #E8ECEF solid",
          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
          px: 0,
          py: 1,
        }}
      >
        <CardHeader
          sx={{
            display: "flex",
            pt: 3,
            pb: 2,
            px: 3,
            alignItems: "center",
            alignSelf: "stretch",
          }}
          subheader={
            <Typography color={"text.secondary"} variant="body2">
              Build trusted partnerships and add referral partners to your
              Referral Matrix.
            </Typography>
          }
          title={
            <Typography gutterBottom variant={"h6"}>
              Explore Partners
            </Typography>
          }
        />
        {isFetchingRecommendedCompanies ||
        isFetchingRecommendedGoogleCompanies ||
        isFetchingReferralInvitations ||
        isFetchingReferralPartners ? (
          <Box py={5}>
            <ComponentLoader />
          </Box>
        ) : (
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexGrow: 1,
              alignSelf: "stretch",
              background: "#F3F4F6",
              overflow: "auto",
              px: isMobile ? 0 : 2,
              py: 1,
            }}
          >
            {recommendedCompanies && recommendedCompanies.length > 0 ? (
              recommendedCompanies.map((companyData, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  lg={6}
                  p={isMobile ? 1 : 2}
                >
                  <CompanyProfileCard
                    {...(companyData.companyPublicProfile
                      ? {
                          companyProfile: companyData.companyPublicProfile,
                          connectionStatus: getConnectionStatus(
                            companyData.companyPublicProfile
                          ),
                        }
                      : {})}
                    {...(companyData.googleCompanyProfile
                      ? {
                          googleCompanyProfile:
                            companyData.googleCompanyProfile,
                          hasMarkedInterest: !!companyData.hasMarkedInterest,
                        }
                      : {})}
                    showParentLogo={
                      !!companyData?.companyPublicProfile?.familyOfBrands
                        ?.name &&
                      familyOfBrands?.name ===
                        companyData?.companyPublicProfile?.familyOfBrands?.name
                    }
                    onOpen={() => {
                      if (companyData.companyPublicProfile) {
                        setCurrentCompanyProfile(
                          companyData.companyPublicProfile ?? null
                        );
                      } else {
                        setSelectedCompany(companyData ?? null);
                      }
                    }}
                  />
                </Grid>
              ))
            ) : (
              <Box textAlign="center" width="100%" py={3}>
                <Typography variant="body1">
                  There are no companies matching your filters, please click on
                  the filters button above and update them.
                </Typography>
              </Box>
            )}
          </Grid>
        )}
        <Stack
          spacing={2}
          mt={1}
          mx={1}
          direction={"row"}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "start",
            alignSelf: "stretch",
          }}
        >
          <Button
            endIcon={<ArrowForwardIcon />}
            sx={{ color: "text.primary" }}
            onClick={() => {
              navigate(
                getNavigationPathFromRoutePaths([
                  CompanyRoutes.BASE,
                  CompanyRoutes.EXPLORE_PARTNERS,
                ])
              );
            }}
          >
            See All
          </Button>
        </Stack>
      </Card>
      {selectedCompany ? (
        <ExternalCompanyDrawer
          open={!!selectedCompany}
          companyGoogleProfile={selectedCompany.googleCompanyProfile}
          hasMarkedInterest={!!selectedCompany?.hasMarkedInterest}
          onClose={markedInterest => {
            if (
              markedInterest &&
              selectedCompany.googleCompanyProfile?.googlePlacesId
            ) {
              updateMarkedInterestSet(
                selectedCompany.googleCompanyProfile?.googlePlacesId ?? ""
              );
            }
            setSelectedCompany(null);
          }}
        />
      ) : null}
      {currentCompanyProfile ? (
        <CompanyDrawer
          open={!!currentCompanyProfile}
          companyProfile={currentCompanyProfile}
          connectionStatus={getConnectionStatus(currentCompanyProfile)}
          onClose={() => {
            setCurrentCompanyProfile(null);
          }}
        />
      ) : null}
    </Grid>
  );
}
