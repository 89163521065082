import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import axiosInstance from "@/common/api";

import { RoutedJobResponse } from "../types/job";

export function useGetJobRoutingById(
  jobId: number,
  options?: UseQueryOptions<any>
): UseQueryResult<RoutedJobResponse> {
  const url = `/job-routings/${jobId}`;
  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      const response = await axiosInstance.get(url);
      return response.data.data;
    },
    ...options,
  });
}
