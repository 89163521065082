import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";
import { Job } from "@/company/jobs/types";
import { Company, CompanyPublicProfile } from "@/company/types";

import { Home } from "../types";

export type CreateHomeOptions = Omit<Home, "id" | "createdAt">;

export function useCreateServiceProvider(
  options: UseMutationOptions<
    {
      companyProfile: CompanyPublicProfile;
      lastJob: Job;
      referredByCompany: Company;
    },
    AxiosError,
    {
      referringCompanyId: number;
      sharingUserId: number;
      referredCompanyId: number;
    }
  > = {}
): UseMutationResult<
  {
    companyProfile: CompanyPublicProfile;
    lastJob: Job;
    referredByCompany: Company;
  },
  AxiosError,
  {
    referringCompanyId: number;
    sharingUserId: number;
    referredCompanyId: number;
  }
> {
  return useMutation({
    mutationFn: async (
      data
    ): Promise<{
      companyProfile: CompanyPublicProfile;
      lastJob: Job;
      referredByCompany: Company;
    }> => {
      const response = await API.post(
        `/homeowner/referrals`,
        changeCaseKeys(data, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
