import { User, USER_TYPE } from "@/auth/types";
import { JobUploadTypes } from "@/company/jobs/api/useCreateJob";
import { JobSectors } from "@/company/jobs/constants";
import { JobStatus } from "@/company/jobs/types";
import { JobSource } from "@/company/jobs/types/job";
import { CarrierType, PayReferralFeeSettings } from "@/company/types";

export interface JobResponse {
  id: number;
  publicId?: string | null;
  source: JobSource;
  sector?: JobSectors | null;
  jobType?: string | null;
  summary?: string | null;
  videoTranscription?: string | null;
  videoLanguage?: string;
  videoMimeType?: string | null;
  jobBuyingCost?: number | null;
  technicianFee?: number | null;
  completionDate?: Date | null;
  createdAt?: Date | null;
  routeToHomeowner?: boolean | null;
  salePrice?: number | null;
  percentageFee?: number | null;
  type?: JobUploadTypes | null;
  homeownerAllowedAutoRoutingAt?: Date | null;
  payReferralFeeSettings?: PayReferralFeeSettings | null;
  createdByCompanyName?: string | null;
  createdByCompanyLogoUrl?: string | null;
  createdByCompanyId?: number | null;
  createdByUserName?: string | null;
  createdByCompanyInvitationCode?: string | null;
  createdByUserId?: number | null;
  createdByUserType?: USER_TYPE | null;
  createdByUserEmail?: string | null;
  createdByUserCellPhone?: string | null;
  createdByUserCellPhoneCarrierType?: CarrierType | null;
  homeId?: number | null;
  homeStreetAddress?: string | null;
  homeLatitude?: number | null;
  homeLongitude?: number | null;
  homeZipCode?: string | null;
  homeCity?: string | null;
  homeState?: string | null;
  homeownerName?: string | null;
  homeownerId?: number | null;
  homeownerCellPhone?: string | null;
  homeownerViewedAt?: Date | null;
  buyingCompanyName?: string | null;
  buyingCompanyId?: number | null;
  createdForCompanyId?: number | null;
  createdForCompanyName?: string | null;
  currentRoutedCompanyId?: number | null;
  currentRoutedCompanyName?: string | null;
  companyFee?: number | null;
  secondsBetweenSubmissionAndCreation?: number | null;
  submissionTime?: Date | null;
  secondsBetweenCreationAndOpportunity?: number | null;
  opportunityTime?: Date | null;
  paidAt?: Date | null;
  claimedAt?: Date | null;
  userWhoApprovedTheJobName?: string | null;
  totalHomeownerViews?: number | null;
  totalCompanyViews?: number | null;
}

export interface Job {
  id: number;
  status: JobStatus;
  publicId?: string;
  title?: string;
  sector?: string;
  summary?: string;
  summarySpanish?: string;
  createdByCompanyId?: number;
  createdForCompanyId?: number;
  createdByUserId: number;
  createdByUserType: USER_TYPE;
  buyingCompanyId?: number;
  jobType?: string;
  jobTypeSpanish?: string;
  videoUrl?: string;
  videoTranscription?: string;
  videoLanguage?: string;
  videoMimeType?: string;
  costRangeEstimate?: string;
  homeId?: number;
  createdAt: Date;
  updatedAt?: Date;
  completionDate?: Date;
  actualCost?: number;
  jobBuyingCost?: number;
  lastActivity?: Date;
  technicianFee?: number;
  salePrice?: number;
  source?: JobSource;
  type?: JobUploadTypes;
  routeToHomeowner?: boolean;
  percentageFee?: string;
  payReferralFeeSettings?: PayReferralFeeSettings;
}

export interface JobListingExtraInformation {
  createdByCompanyId?: number;
  createdByCompanyName?: string;
  createdByCompanyInvitationCode?: string;
  createdByCompanyLogoUrl?: string;
  createdByUserId?: number;
  createdByUserType?: USER_TYPE;
  createdByUserEmail?: string;
  createdByUserCellPhone?: string;
  createdByUserCellPhoneCarrierType?: string;
  homeId?: number;
  homeStreetAddress?: string;
  homeLatitude?: number;
  homeLongitude?: number;
  homeZipCode?: string;
  homeCity?: string;
  homeState?: string;
  buyingCompanyName?: string;
  buyingCompanyId?: number;
  buyingCompanyLogoUrl?: string;
  createdForCompanyName?: string;
  createdForCompanyId?: number;
  createdForCompanyLogoUrl?: string;
  currentRoutedCompanyName?: string;
  currentRoutedCompanyId?: number;
  currentRoutedCompanyLogoUrl?: string;
  boughtAt?: Date;
  claimedAt?: Date;
  homeownerReceivedAt?: Date;
  homeownerDelayedAt?: Date;
  homeownerDeclinedAt?: Date;
  routedToCompanyId?: number;
  routedToCompanyGooglePlacesId?: string;
  routedToCompanyName?: string;
  routedToCompanyLogoUrl?: string;
  routedToCompanyAt?: Date;
  technicianName: string;
  homeownerViewedAt?: Date;
  routedPartnerViewedAt?: Date;
  homeownerFirstName?: string;
  homeownerLastName?: string;
  homeownerEmail?: string;
  homeownerCellPhone?: string;
  homeownerId?: number;
  companyFee?: number;
  secondsBetweenSubmissionAndCreation?: number;
  secondsBetweenCreationAndOpportunity?: number;
  submissionTime?: number;
  opportunityTime?: number;
}

export interface InternalAdminJobResponse {
  job: Job;
  jobExtraInfo: JobListingExtraInformation;
}

export enum JobTransactionType {
  REFERRAL_FEE_EARNED = "REFERRAL_FEE_EARNED",
}

export interface JobTransaction {
  id: number;
  jobId: number;
  amount: number;
  newBalance: number;
  concept: JobTransactionType;
  createdAt: string;
  createdByUser?: Pick<User, "firstName" | "lastName" | "email">;
  user?: Pick<User, "firstName" | "lastName" | "email">;
}
