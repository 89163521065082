/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Close, Info } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ImageListItemBar,
  SvgIcon,
  Tab,
  Tabs,
  tabsClasses,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { formatDateTimeV2 } from "@/analytics/components/JobsTable";
import { useAuth } from "@/auth/context/jwt";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";
import { getMimeTypeFromExtension, mimeToExtension } from "@/utils";

import { useCreateJobAttachment } from "../api/useCreateJobAttachment";
import { useGetJobAttachments } from "../api/useGetJobAttachments";
import { JobStatus } from "../types";
import { RoutedJobStatus } from "../types/job";
import { CompanyProfileDrawer } from "./CompanyProfileDrawer";
import FilePreview from "./FilePreview";
import FullscreenImagePreview from "./FullScreenPreview";
import { JobAttachmentMoreMenu } from "./JobAttachmentMoreMenu";

export enum JobAttachmentsTabsEnum {
  MEDIA = "media",
  DOCUMENTS = "documents",
}

export default function JobAttachments({
  jobId,
  isRoutedJob,
  status,
}: {
  jobId: number;
  isRoutedJob?: boolean;
  status?: JobStatus | RoutedJobStatus;
}) {
  const [currentTab, setCurrentTab] = useState<JobAttachmentsTabsEnum>(
    JobAttachmentsTabsEnum.MEDIA
  );
  const { session } = useAuth();
  const supportedFormat =
    "Supported file types include: .jpg, .jpeg, .png, .gif, .bmp, .tiff, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv, .zip, .rar, .eps, .mp4, .mov, .avi, .mp3, and .wav.";
  const [videoFile, setVideoFile] = useState<any>(undefined);
  const onDrop = (files: File[]) => {
    const newFile = files && files.length > 0 ? files[0] : null;
    setVideoFile(newFile);
  };
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const {
    isLoading: isCreatingJobAttachment,
    mutateAsync: createNewJobAttachment,
  } = useCreateJobAttachment({
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while uploading attachment ";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
    onSuccess: () => {
      setVideoFile(undefined);
      refetch();
      enqueueSnackbar({
        message: "Attachment uploaded successfully.",
        variant: "success",
      });
    },
  });

  const {
    data: jobAttachments,
    isFetching,
    isLoading,
    refetch,
  } = useGetJobAttachments(jobId, { refetchOnWindowFocus: false });
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: JobAttachmentsTabsEnum): void => {
      setCurrentTab(value);
    },
    []
  );

  const handleDownload = async (
    url: string,
    type: string,
    filename: string
  ) => {
    try {
      const media = await axios.get(url, {
        responseType: "blob",
      });
      const blob = new Blob([media.data]);
      const urlObject = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute(
        "download",
        filename
          ? filename
          : `te-job-${jobId}-attachment.${mimeToExtension(type)}`
      );

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Cleanup
      link.remove();
    } catch (error) {
      console.error("Error downloading the video:", error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "image/gif": [".gif"],
      "image/bmp": [".bmp"],
      "image/tiff": [".tiff"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "text/plain": [".txt"],
      "text/csv": [".csv"],
      "application/zip": [".zip"],
      "application/x-rar-compressed": [".rar"],
      "application/postscript": [".eps"],
      "image/svg+xml": [".svg"],
      "video/mp4": [".mp4"],
      "video/quicktime": [".mov"],
      "video/x-msvideo": [".avi"],
      "audio/mpeg": [".mp3"],
      "audio/wav": [".wav"],
    },
    onError: error => {
      enqueueSnackbar({
        message: error?.message ?? supportedFormat,
        variant: "error",
      });
      console.error(error);
    },
    onDrop: onDrop,
    multiple: false,
  });

  const mediaList = jobAttachments
    ?.map(item => {
      const extension = item.name?.split(".")?.pop() ?? "";
      const mimeType = getMimeTypeFromExtension(extension);
      return { ...item, fileType: mimeType };
    })
    ?.filter(item => {
      return (
        item.fileType.startsWith("video") ||
        item.fileType.startsWith("image") ||
        item.fileType.startsWith("audio")
      );
    });
  const documentList = jobAttachments
    ?.map(item => {
      const extension = item.name?.split(".")?.pop() ?? "";
      const mimeType = getMimeTypeFromExtension(extension);
      return { ...item, fileType: mimeType };
    })
    ?.filter(item => {
      return (
        !item.fileType.startsWith("video") &&
        !item.fileType.startsWith("image") &&
        !item.fileType.startsWith("audio")
      );
    });

  return (
    <Grid
      container
      spacing={{
        xs: 3,
        lg: 4,
      }}
      sx={{
        m: "0 !important",
        width: "100% !important",
      }}
      gap={0.5}
    >
      <Grid
        xs={12}
        sx={{
          position: "sticky",
          top: "-18px",
          zIndex: 100,
          bgcolor: "white",
        }}
      >
        {isCreatingJobAttachment ? (
          <Box my={5} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (isRoutedJob &&
            status !== RoutedJobStatus.PENDING_PAYMENT &&
            status !== RoutedJobStatus.PAID) ||
          session?.company?.accountType !==
            COMPANY_ACCOUNT_TYPES.BRAND_LOCATION ? null : videoFile ? (
          <Box
            sx={{
              alignItems: "center",
              position: "relative",
              border: 1,
              borderRadius: 1,
              borderStyle: "dashed",
              borderColor: "6C737F",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              outline: "none",
              mt: 3,
              p: { md: 1, xs: 1 },
              bgcolor: "#F8F9FA",
            }}
          >
            <button
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                border: "none",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                cursor: "pointer",
                background: "transparent",
                zIndex: 1, // Ensure the button is above the video
              }}
              onClick={() => {
                setVideoFile(undefined);
              }}
            >
              <Close />
            </button>
            {videoFile.type.startsWith("video/") ||
            videoFile.type.startsWith("audio/") ? (
              <video
                controls
                controlsList="nodownload"
                src={`${window.URL.createObjectURL(videoFile)}`}
                style={{
                  border: "1px solid black",
                  width: "100%",
                  height: "200px",
                }}
              />
            ) : videoFile.type.startsWith("image/") ? (
              <img
                src={`${window.URL.createObjectURL(videoFile)}`}
                alt="Preview"
                style={{
                  border: "1px solid black",
                  width: "100%",
                  height: "200px",
                }}
              />
            ) : (
              <ImageListItem
                key={videoFile.presignedUrl}
                sx={{
                  height: "200px !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <DescriptionIcon
                  sx={{ color: "#16B364", height: "80px", width: "80px" }}
                />
                <ImageListItemBar
                  title={videoFile.name}
                  sx={{ borderRadius: "10px" }}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label={`info about ${videoFile.name}`}
                    >
                      <Info />
                    </IconButton>
                  }
                />
              </ImageListItem>
            )}
            <Box
              width={"100%"}
              display={"flex"}
              gap={1}
              p={1}
              pt={2}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Button
                variant="outlined"
                onClick={() => setVideoFile(undefined)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  createNewJobAttachment({
                    jobId,
                    videoFile,
                    fileName: videoFile.name,
                  });
                }}
              >
                Upload
              </Button>
            </Box>
          </Box>
        ) : isMobile ? (
          <Box
            sx={{
              p: 3,
              borderRadius: "20px",
              border: "1px dashed var(--divider, #D9D9D9)",
              mb: 2,
              cursor: "pointer",
            }}
            {...getRootProps()}
          >
            <Box
              gap={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {" "}
              <IconButton
                size="small"
                sx={{
                  p: 0,
                  borderRadius: "100px",
                  width: "64px",
                  height: "64px",
                  bgcolor: "#EDFCF2",
                }}
              >
                <FileUploadOutlinedIcon sx={{ color: "#16B364" }} />
              </IconButton>
              <Box gap={0.5} display={"flex"}>
                <Typography
                  variant="subtitle1"
                  display={"inline"}
                  sx={{ color: "#16B364" }}
                >
                  Add Attachment
                </Typography>
                <Tooltip title={supportedFormat}>
                  <SvgIcon color="action">
                    <HelpOutlineIcon />
                  </SvgIcon>
                </Tooltip>
              </Box>
            </Box>
            <input {...getInputProps()} />
          </Box>
        ) : (
          <Box
            sx={{
              p: 3,
              borderRadius: "20px",
              border: "1px dashed var(--divider, #D9D9D9)",
              mb: 2,
              cursor: "pointer",
            }}
            {...getRootProps()}
          >
            <Box gap={2} display={"flex"} alignItems={"center"}>
              {" "}
              <IconButton
                size="small"
                sx={{
                  p: 0,
                  borderRadius: "100px",
                  width: "64px",
                  height: "64px",
                  bgcolor: "#EDFCF2",
                }}
              >
                <FileUploadOutlinedIcon sx={{ color: "#16B364" }} />
              </IconButton>
              <Box gap={0.5} display={"flex"}>
                <Typography variant="subtitle1" color="text.secondary">
                  Drag and drop or click to{" "}
                  <Typography
                    variant="subtitle1"
                    display={"inline"}
                    sx={{ color: "#16B364" }}
                  >
                    Browse
                  </Typography>
                </Typography>
                <Tooltip title={supportedFormat}>
                  <SvgIcon color="action">
                    <HelpOutlineIcon />
                  </SvgIcon>
                </Tooltip>
              </Box>
            </Box>
            <input {...getInputProps()} />
          </Box>
        )}
        {isLoading || !jobAttachments?.length ? null : (
          <Tabs
            centered
            value={currentTab}
            variant="scrollable"
            scrollButtons={isMobile ? true : false}
            allowScrollButtonsMobile={isMobile ? true : false}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              px: isMobile ? 0 : 3,
              ...(!isMobile
                ? {}
                : {
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { display: "none" },
                    },
                  }),
            }}
            onChange={handleTabsChange}
          >
            <Tab
              value={JobAttachmentsTabsEnum.MEDIA}
              label={`Media${
                mediaList?.length ? ` (${mediaList?.length})` : ""
              }`}
              sx={{ width: "50%", ml: "0 !important" }}
            />
            <Tab
              value={JobAttachmentsTabsEnum.DOCUMENTS}
              label={`Documents${
                documentList?.length ? ` (${documentList?.length})` : ""
              }`}
              sx={{ width: "50%", ml: "0 !important" }}
            />
          </Tabs>
        )}
      </Grid>
      {isFetching ? (
        <Box my={5} textAlign="center" width={"100%"}>
          <CircularProgress />
        </Box>
      ) : !jobAttachments?.length ? null : currentTab ===
        JobAttachmentsTabsEnum.MEDIA ? (
        mediaList?.map(item => (
          <Grid key={item.id} xs={5.9} pt={2}>
            <ImageListItem
              key={item.presignedUrl}
              sx={{ height: "200px !important" }}
            >
              <ImageListItemBar
                position={"top"}
                sx={{
                  borderRadius: "10px",
                  zIndex: 90,
                  width: "fit-content",
                  left: "auto",
                  ".MuiImageListItemBar-titleWrap": { p: 0 },
                }}
                actionIcon={
                  <JobAttachmentMoreMenu
                    disableDelete={isRoutedJob}
                    onDelete={() => {
                      console.log("delete");
                    }}
                    onDownload={() => {
                      handleDownload(
                        item.presignedUrl,
                        item.fileType,
                        item.name
                      );
                    }}
                  />
                }
              />
              {item.fileType.includes("image") ? (
                <img
                  src={item.presignedUrl}
                  alt="test"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "inherit",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPreviewUrl(item.presignedUrl);
                  }}
                />
              ) : item.fileType.startsWith("video") ||
                item.fileType.startsWith("audio") ? (
                <video
                  controls
                  playsInline
                  id="video"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "inherit",
                    borderRadius: "10px",
                  }}
                >
                  <source
                    id="mp4"
                    src={`${item.presignedUrl}#t=0.1`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <embed
                  src={`${item.presignedUrl}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "inherit",
                    borderRadius: "10px",
                  }}
                />
              )}
            </ImageListItem>
            <Box display={"flex"} flexDirection={"column"} gap={0.1}>
              <Typography variant="subtitle1" sx={{ wordBreak: "break-word" }}>
                {item.name}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ wordBreak: "break-word" }}
              >
                {formatDateTimeV2(item.createdAt)}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ wordBreak: "break-word" }}
              >
                By: {item.userName}
              </Typography>
              {item.companyName ? (
                <Typography
                  color="primary"
                  variant="subtitle2"
                  fontWeight={700}
                  sx={{ cursor: "pointer", wordBreak: "break-word" }}
                  onClick={event => {
                    event.stopPropagation();
                    setSelectedCompanyId(item.companyId);
                  }}
                >
                  {item.companyName}
                </Typography>
              ) : null}
            </Box>
          </Grid>
        ))
      ) : currentTab === JobAttachmentsTabsEnum.DOCUMENTS ? (
        documentList?.map(item => (
          <Grid key={item.id} xs={12} pt={2}>
            <Card
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                p: 2,
                boxShadow: "0px 0px 0px 1px #F2F4F7",
                "&:active": {
                  border: "1px solid #071D2F",
                },
                "&:focus": {
                  border: "1px solid #071D2F",
                },
                "&:hover": {
                  boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
                },
              }}
            >
              <Box
                display={"flex"}
                gap={2}
                width={"100%"}
                alignItems={"start"}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleDownload(item.presignedUrl, item.fileType, item.name);
                }}
              >
                <DescriptionIcon
                  sx={{ color: "#16B364", height: "50px", width: "40px" }}
                />
                <Box display={"flex"} flexDirection={"column"} gap={0.1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textOverflow: "ellipsis",
                      width: "220px",

                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatDateTimeV2(item.createdAt)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    By: {item.userName}
                  </Typography>
                  {item.companyName ? (
                    <Typography
                      color="primary"
                      variant="body2"
                      fontWeight={700}
                      sx={{ cursor: "pointer" }}
                      onClick={event => {
                        event.stopPropagation();
                        setSelectedCompanyId(item.companyId);
                      }}
                    >
                      {item.companyName}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <JobAttachmentMoreMenu
                color={"black"}
                disableDelete={isRoutedJob}
                onDelete={() => {
                  console.log("delete");
                }}
                onDownload={() => {
                  handleDownload(item.presignedUrl, item.fileType, item.name);
                }}
              />
            </Card>
            <Divider sx={{ width: "100%", height: "2px" }} />
          </Grid>
        ))
      ) : null}

      {previewUrl ? (
        <FullscreenImagePreview
          open={!!previewUrl}
          url={previewUrl}
          setOpen={() => {
            setPreviewUrl(null);
          }}
        />
      ) : null}
      {fileUrl ? (
        <FilePreview
          open={!!fileUrl}
          url={fileUrl}
          setOpen={() => {
            setFileUrl(null);
          }}
        />
      ) : null}
      {selectedCompanyId ? (
        <CompanyProfileDrawer
          open={!!selectedCompanyId}
          companyId={selectedCompanyId}
          onClose={() => {
            setSelectedCompanyId(undefined);
          }}
        />
      ) : null}
    </Grid>
  );
}
