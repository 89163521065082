import { DoneAllOutlined as DoneAllOutlinedIcon } from "@mui/icons-material";
import {
  Avatar,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { isDefined } from "@/utils/isDefined";

import { referralCampaignConfigurationSteps } from "../../constants";
import {
  ReferralCampaign,
  ReferralCampaignConfigurationStep,
} from "../../types";

interface CampaignSuccessCriteriaProps {
  referralCampaign: Omit<ReferralCampaign, "id" | "createdAt" | "updatedAt">;
  indexOfActiveStep?: number;
}

export function CampaignSuccessCriteria(props: CampaignSuccessCriteriaProps) {
  const { indexOfActiveStep, referralCampaign } = props;

  const campaignSuccessCriteriaStepIndex =
    referralCampaignConfigurationSteps.indexOf(
      ReferralCampaignConfigurationStep.CAMPAIGN_SUCCESS_CRITERIA
    );

  const isLoading =
    isDefined(indexOfActiveStep) &&
    indexOfActiveStep === campaignSuccessCriteriaStepIndex;

  if (
    isDefined(indexOfActiveStep) &&
    indexOfActiveStep < campaignSuccessCriteriaStepIndex
  ) {
    return null;
  }

  return (
    <Stack gap={1}>
      <Typography variant="h6" fontWeight={700}>
        Success Criteria
      </Typography>
      <Stepper
        activeStep={3}
        orientation="vertical"
        sx={{
          ".MuiStepConnector-root": { marginLeft: "18px" },
          ".MuiStepConnector-line": {
            ...(!isLoading && { borderColor: "#16DB65" }),
            borderWidth: "2px",
          },
        }}
      >
        <Step>
          <StepLabel
            StepIconComponent={() =>
              isLoading ? (
                <Skeleton variant="circular" width={40} height={40} />
              ) : (
                <Avatar sx={{ backgroundColor: "#EDFCF2" }}>
                  <DoneAllOutlinedIcon sx={{ color: "#16DB65" }} />
                </Avatar>
              )
            }
            sx={{
              paddingY: 0,
            }}
          >
            {isLoading ? (
              <Stack gap={1}>
                <Skeleton variant="rounded" width="100%" height="15px" />
                <Skeleton variant="rounded" width="70%" height="15px" />
              </Stack>
            ) : (
              <Typography
                variant="subtitle2"
                fontWeight={500}
                color="text.secondary"
              >
                Submit{" "}
                {referralCampaign.successCriteria[0]?.numberOfReferrals ?? 15}+
                referrals
              </Typography>
            )}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            StepIconComponent={() =>
              isLoading ? (
                <Skeleton variant="circular" width={40} height={40} />
              ) : (
                <Avatar sx={{ backgroundColor: "#EDFCF2" }}>
                  <DoneAllOutlinedIcon sx={{ color: "#16DB65" }} />
                </Avatar>
              )
            }
            sx={{
              paddingY: 0,
            }}
          >
            {isLoading ? (
              <Stack gap={1}>
                <Skeleton variant="rounded" width="100%" height="15px" />
                <Skeleton variant="rounded" width="70%" height="15px" />
              </Stack>
            ) : (
              <Typography
                variant="subtitle2"
                fontWeight={500}
                color="text.secondary"
              >
                Earn referral fees for each job
              </Typography>
            )}
          </StepLabel>
        </Step>
      </Stepper>
    </Stack>
  );
}
