import { Box } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import axios from "axios";
import { capitalCase } from "change-case";
import { useCallback, useEffect, useRef, useState } from "react";
import zipcodes from "zipcodes";

import { useAuth } from "@/auth/context/jwt";
import { AppConstants } from "@/common/constants";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";

const mapOptions = {
  mapId: AppConstants.googleMapId,
};

const mapContainerStyle = {
  height: "100%",
};

const usBounds = {
  north: 49.3457868,
  south: 24.396308,
  west: -125.0,
  east: -66.93457,
};

const usCenter = { lat: 37.0902, lng: -95.7129 };

const FEATURE_FILL_COLOR = "#67A680";
const FEATURE_STROKE_COLOR = "#087443";
const FEATURE_IDENTIFIER = "name";

export default function StateSelectionMap({
  selectedTerritories,
  onTerritoryClick,
}: {
  selectedTerritories: Array<string>;
  onTerritoryClick: (features: Array<string>) => void;
}) {
  const { session } = useAuth();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const dataLayer = useRef<google.maps.Data | null>();
  const eventListeners = useRef<google.maps.MapsEventListener>();

  const onMapLoad = useCallback((map: google.maps.Map) => {
    dataLayer.current = map.data;
    setMap(map);
  }, []);

  /**
   * OnClick handler to select and deselect territories on user click.
   */
  const onClick = useCallback(
    async (event: { feature: google.maps.Data.Feature }) => {
      if (!dataLayer.current) {
        return;
      }
      const feature = event.feature;
      const featureId = feature.getProperty(FEATURE_IDENTIFIER);
      // To change color to transparent when territory is deselected
      if (
        [...(selectedTerritories ?? [])].some(
          territory => territory === featureId
        )
      ) {
        dataLayer.current.overrideStyle(feature, {
          strokeColor: FEATURE_STROKE_COLOR,
          strokeWeight: 2,
          fillColor: "transparent",
        });
        const newSelectedTerritories = selectedTerritories.filter(
          feature => feature !== featureId
        );
        onTerritoryClick([...newSelectedTerritories]);
        return;
      }

      // To change color to green when territory is selected
      dataLayer.current.overrideStyle(feature, {
        fillColor: FEATURE_FILL_COLOR,
      });
      const newSelectedTerritories = [...(selectedTerritories ?? [])];
      newSelectedTerritories.push(featureId);
      onTerritoryClick(newSelectedTerritories);
    },
    [onTerritoryClick, selectedTerritories, dataLayer]
  );

  /**
   * OnClick handler to select and deselect territories on user click.
   */
  const loadInitialGeoJson = useCallback(async () => {
    if (dataLayer.current) {
      dataLayer.current.setStyle({
        strokeColor: "transparent",
        strokeWeight: 2,
        fillColor: "transparent",
      });

      const response = await axios.get(
        `https://tradeengage-geojson-production.s3.us-east-1.amazonaws.com/states.geojson`
      );
      if (session?.groups?.some(isAdministratorOrOwner)) {
        const event = dataLayer.current.addListener("click", onClick);
        eventListeners.current = event;
      }
      dataLayer.current.addGeoJson(response.data, {
        idPropertyName: FEATURE_IDENTIFIER,
      });
      const states = Object.keys(zipcodes.states.full).map(state =>
        capitalCase(state.toLowerCase())
      );
      if (states?.length) {
        const newSelectedTerritories = [...(selectedTerritories ?? [])];
        states.forEach(geoId => {
          const feature = dataLayer.current?.getFeatureById(geoId.toString());
          const featureId = feature?.getProperty(FEATURE_IDENTIFIER);
          if (feature) {
            dataLayer.current?.overrideStyle(feature, {
              fillColor: FEATURE_FILL_COLOR,
              strokeColor: FEATURE_STROKE_COLOR,
              strokeWeight: 2,

              //fillColor: FEATURE_FILL_COLOR,
            });
            newSelectedTerritories.push(featureId);
          }
        });
        onTerritoryClick(newSelectedTerritories);
      }
    }
  }, [map]);

  /**
   * Useeffect needed to update data layer whenever the location center is changed.
   * Unfortunately we can not replace the data and hence need to completely remove
   * the current layer with new one.
   */
  useEffect(() => {
    if (dataLayer.current) {
      loadInitialGeoJson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  //   /**
  //    * Useeffect needed to update data layer whenever the location center is changed.
  //    * Unfortunately we can not replace the data and hence need to completely remove
  //    * the current layer with new one.
  //    */
  //   useEffect(() => {
  //     if (dataLayer.current) {
  //       loadGeoJson();
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [map]);

  /**
   * Useeffect needed to remove outdated onclick handler.
   * As we change onclick handler when selected territories change.
   */
  useEffect(() => {
    if (!dataLayer.current) return;
    eventListeners?.current?.remove();
    if (session?.groups?.some(isAdministratorOrOwner)) {
      eventListeners.current = dataLayer.current.addListener("click", onClick);
    }
  }, [onClick]);

  useEffect(() => {
    if (map) {
      setCenter(usCenter);
      map?.setOptions({
        restriction: {
          latLngBounds: usBounds,
          strictBounds: false,
        },
        zoom: 4,
      });
    }
  }, [map]);

  return (
    <Box sx={{ height: "100%", width: "100%", borderRadius: "10px" }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={4}
        center={center}
        options={mapOptions}
        onLoad={onMapLoad}
      ></GoogleMap>
    </Box>
  );
}
