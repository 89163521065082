import { DeleteForeverOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import * as yup from "yup";

import { NotifyAction } from "@/company/components/NotifyAction";
import { useUpdateCompany } from "@/company/onboarding/api";
import { Company } from "@/company/types";
import { getInitials } from "@/utils";

export interface ProfileOverviewProps {
  company: Company;
}
const validationSchema = yup
  .object({
    name: yup.string().required("Company Name is required"),
    yearEstablished: yup.date().nullable().optional(),
    numberEmployees: yup
      .number()
      .nullable()
      .min(0, "Can not be a negative number")
      .optional(),
    websiteUrl: yup
      .string()
      // .matches(URL_REGEX, "Website URL must be a valid URL")
      .optional(),
    schedulingUrl: yup
      .string()
      // .matches(URL_REGEX, "Website URL must be a valid URL")
      .optional(),
    // businessPhone: getUSPhoneValidationSchema().optional(),
    email: yup.string().email("Must be a valid email address").optional(),

    description: yup
      .string()
      // .matches(URL_REGEX, "Google local services URL must be a valid URL")
      .optional(),
  })
  .optional();

export const ProfileOverview: FC<ProfileOverviewProps> = ({ company }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [logoFile, setLogoFile] = useState<any>(undefined);
  const [currentLogoUrl, setCurrentLogoUrl] = useState<any>(undefined);

  const [imageDeleted, setImageDeleted] = useState(false);

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFile = event.target.files[0];
      const img = new Image();
      const objectUrl = window.URL.createObjectURL(newFile);
      img.onload = function () {
        window.URL.revokeObjectURL(objectUrl);
        if (this.height < 40 || this.width > 160) {
          enqueueSnackbar(
            <Typography variant="body2">
              For best results, ensure your logo is a minimum of 40px in height
              and 160px in width.
            </Typography>,
            {
              action: snackbarId => {
                return NotifyAction(snackbarId);
              },
              variant: "info",
            }
          );
        }
        setLogoFile(newFile);
        setImageDeleted(false);
      };
      img.src = objectUrl;
    } else {
      setLogoFile(undefined);
    }
  };

  const { isLoading: updatingCompany, mutateAsync: updateCompany } =
    useUpdateCompany(company?.id, {
      onError() {
        enqueueSnackbar("Error while updating company details.", {
          variant: "error",
        });
      },
      async onSuccess() {
        setLogoFile(undefined);
        enqueueSnackbar({
          message: "Updated company details successfully.",
          variant: "success",
        });
      },
    });

  const minDate = DateTime.fromObject({ year: 1944, month: 1, day: 1 });

  const formik = useFormik({
    initialValues: {
      name: "",
      yearEstablished: null,
      numberEmployees: null,
      websiteUrl: "",
      zipCode: "",
      sectors: [],
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({
      yearEstablished,
      numberEmployees,
      websiteUrl,
      zipCode,
      sectors,
      name,
      description,
    }) => {
      const companyPayload = {
        name,
        yearEstablished: yearEstablished ? yearEstablished?.year : "null",
        numberEmployees: numberEmployees ? numberEmployees : "null",
        websiteUrl,
        description,
        zipCode,
      };
      await updateCompany({
        companyPayload,
        companySectors: sectors,
        logoFile,
        isLogoDeleted: imageDeleted,
      });
    },
  });

  useEffect(() => {
    if (company) {
      if (company?.yearEstablished) {
        formik.setFieldValue(
          "yearEstablished",
          DateTime.utc(company?.yearEstablished)
        );
      }
      if (company.logoUrl) {
        setCurrentLogoUrl(company.logoUrl);
      }
      if (company?.name) {
        formik.setFieldValue("name", company.name);
      }
      if (company?.numberEmployees)
        formik.setFieldValue("numberEmployees", company.numberEmployees);
      if (company?.websiteUrl)
        formik.setFieldValue("websiteUrl", company.websiteUrl);
      if (company?.description)
        formik.setFieldValue("description", company?.description);
    }
  }, [company]);

  const [isImageLoadError, setIsImageLoadError] = useState(false);

  const handleDelete = () => {
    setLogoFile(undefined);
    setCurrentLogoUrl(undefined);
    setImageDeleted(true);
  };
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <>
      <Box
        display={"flex"}
        gap={1}
        flexDirection={"column"}
        width={"100%"}
        pl={2}
      >
        <Grid container>
          <>
            <Grid container py={1}>
              {isMobile ? null : (
                <Grid item xs={12} pr={4}>
                  <Typography gutterBottom variant="body1" fontWeight={600}>
                    Company Information
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="4px"
                      mb={2}
                      sx={{ mt: { xs: 2, lg: 0 } }}
                    >
                      {logoFile || (currentLogoUrl && !isImageLoadError) ? (
                        <img
                          src={
                            logoFile
                              ? window.URL.createObjectURL(logoFile)
                              : currentLogoUrl
                          }
                          alt={company?.name}
                          width="40px"
                          height="40px"
                          onError={error => {
                            console.error(error);
                            setIsImageLoadError(true);
                          }}
                        />
                      ) : (
                        <Avatar
                          variant="square"
                          sx={{ height: "40px", width: "40px" }}
                        >
                          {getInitials(company?.name ?? "")}
                        </Avatar>
                      )}
                      <Box ml={1}>
                        <Box display={"flex"}>
                          <Button component="label" sx={{ p: 0 }}>
                            Change Logo
                            <input
                              type="file"
                              style={{
                                clip: "rect(0 0 0 0)",
                                clipPath: "inset(50%)",
                                height: 1,
                                overflow: "hidden",
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                whiteSpace: "nowrap",
                                width: 1,
                              }}
                              accept="image/*"
                              onChange={onSelectFile}
                            />
                          </Button>
                          {!imageDeleted && (logoFile || company?.logoUrl) ? (
                            <IconButton
                              size="small"
                              aria-label="delete"
                              onClick={() => handleDelete()}
                            >
                              <DeleteForeverOutlined />
                            </IconButton>
                          ) : null}
                        </Box>
                        <Typography variant="body2" color={"text.secondary"}>
                          Logo should be at least 40px in height and have a
                          maximum width of 160px
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      required
                      disabled
                      aria-disabled
                      fullWidth
                      id="name"
                      name="name"
                      label="Company Name"
                      sx={{ my: 0 }}
                      value={formik.values.name}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ py: "0 !important" }}>
                    <Typography variant="caption" color="text.secondary">
                      Contact us to change your Company Name.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container py={1}>
              <Grid item xs={12} pr={4}>
                <Typography gutterBottom variant="body1" fontWeight={600}>
                  Additional Company Information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      fullWidth
                      id="description"
                      name="description"
                      label="Describe Your Company and Your Ideal Referrals"
                      rows={2}
                      maxRows={4}
                      value={formik.values.description}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                          ? formik.errors.description
                          : "This will be shown on your company profile for other service providers to get to know you."
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <DatePicker
                        disableFuture
                        sx={{ width: { xs: 1 } }}
                        disabled={updatingCompany}
                        minDate={minDate}
                        maxDate={DateTime.now()}
                        label={"Year Established"}
                        views={["year"]}
                        value={formik.values.yearEstablished}
                        onChange={newValue =>
                          formik.setFieldValue("yearEstablished", newValue)
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="numberEmployees"
                      name="numberEmployees"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      label="Number of Employees"
                      sx={{ my: 0 }}
                      disabled={updatingCompany}
                      value={formik.values.numberEmployees}
                      error={
                        formik.touched.numberEmployees &&
                        Boolean(formik.errors.numberEmployees)
                      }
                      helperText={
                        formik.touched.numberEmployees &&
                        formik.errors.numberEmployees
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="websiteUrl"
                      name="websiteUrl"
                      label="Website"
                      sx={{ my: 0 }}
                      disabled={updatingCompany}
                      value={formik.values.websiteUrl}
                      error={
                        formik.touched.websiteUrl &&
                        Boolean(formik.errors.websiteUrl)
                      }
                      helperText={
                        formik.touched.websiteUrl && formik.errors.websiteUrl
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="schedulingUrl"
                      name="schedulingUrl"
                      label="Scheduling URL"
                      sx={{ my: 0 }}
                      disabled={updatingCompany}
                      value={formik.values.schedulingUrl}
                      error={
                        formik.touched.schedulingUrl &&
                        Boolean(formik.errors.schedulingUrl)
                      }
                      helperText={
                        formik.touched.schedulingUrl &&
                        formik.errors.schedulingUrl
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Stack
              direction="row"
              gap={2}
              sx={{
                display: "flex",
                alignItems: { xs: "flex-center", lg: "flex-end" },
                justifyContent: "end",
                alignSelf: "stretch",
                width: "100%",
                mr: 3,
              }}
            >
              <LoadingButton
                loading={updatingCompany}
                variant="contained"
                color="primary"
                disabled={!formik.dirty && !Object.keys(formik.touched).length}
                onClick={() => formik.handleSubmit()}
              >
                Save
              </LoadingButton>
            </Stack>
          </>
        </Grid>
      </Box>
    </>
  );
};
