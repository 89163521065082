/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  CardGiftcard as CardGiftcardIcon,
  Share as ShareIcon,
  Timeline as TimelineIcon,
  VerifiedOutlined as VerifiedOutlinedIcon,
  VerifiedUserOutlined as VerifiedUserOutlinedIcon,
} from "@mui/icons-material";
import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToolTip } from "@/common/tool-tip/ToolTip";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { JobSectors } from "@/company/jobs/constants";
import {
  BRAND_CATEGORY,
  CompanyPublicProfile,
  CompanyRoutes,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { isDefined } from "@/utils/isDefined";

import { CompanyAvatar } from "../../company-avatar/CompanyAvatar";
import {
  ProfileShareSource,
  ServiceProviderShareDialog,
} from "../../service-provider-dialog/ServiceProviderShareDialog";

export interface ReferralProfileCardProps {
  companyProfile: CompanyPublicProfile;
}

export function ReferralProfileCard(props: ReferralProfileCardProps) {
  const { companyProfile } = props;
  const navigate = useNavigate();

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          mt: 2,
          borderRadius: "16px",
          background: "#FFF",
          p: 2,
          gap: 1,
          /* elevation/outlined */
          boxShadow: "0px 0px 0px 1px #F2F4F7",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box my={1}>
          <CompanyAvatar
            height={"40px"}
            logo={companyProfile.logoUrl}
            name={companyProfile.name ?? ""}
          />
        </Box>
        {companyProfile.sectors.length ? (
          <Box display={"flex"} gap={1} flexWrap={"wrap"}>
            {companyProfile.sectors.slice(0, 3).map(sector => (
              <Chip
                key={sector.sector}
                label={
                  snakeCaseJobSectors[sector.sector] ??
                  snakeCaseJobSectors[JobSectors.OTHER]
                }
                sx={{ mr: 1, bgcolor: "#1119270A", color: "#6C737F" }}
              />
            ))}
            {companyProfile.sectors.length > 3 ? (
              <ToolTip
                placement="bottom"
                message={companyProfile.sectors
                  ?.slice(3)
                  .map(
                    ({ sector }) => snakeCaseJobSectors[sector as JobSectors]
                  )
                  .join(", ")}
              >
                <Chip
                  key={companyProfile.sectors
                    ?.slice(3)
                    .map(
                      ({ sector }) => snakeCaseJobSectors[sector as JobSectors]
                    )
                    .join(", ")}
                  sx={{ mr: 1, bgcolor: "#1119270A", color: "#6C737F" }}
                  label={`+${companyProfile.sectors.length - 3}`}
                />
              </ToolTip>
            ) : null}
          </Box>
        ) : null}
        <Box
          display={"flex"}
          gap={0.5}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography variant="h6">{companyProfile.name}</Typography>
          {companyProfile.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM ? (
            <ToolTip
              placement="bottom"
              title="TradeEngage Participant"
              message="This is a verified active business on TradeEngage."
            >
              <VerifiedOutlinedIcon
                sx={{
                  verticalAlign: "center",
                  color: "#16DB65",
                  height: "20px",
                  width: "20px",
                }}
              />
            </ToolTip>
          ) : null}
        </Box>
        <Stack gap={2} alignItems={"flex-start"}>
          {companyProfile.profile.brandCategory === BRAND_CATEGORY.FRANCHISE ? (
            <Box>
              <Chip
                label={"Franchise"}
                sx={{ bgcolor: "#16DB65", color: "white", height: "20px" }}
                size="small"
              />
            </Box>
          ) : null}
          {companyProfile?.lastActivity ? (
            <Box display={"flex"}>
              <TimelineIcon
                fontSize="small"
                sx={{ mr: 0.3, color: "#6C737F" }}
              />
              <Typography variant="caption" color="text.secondary">
                Last Activity:{" "}
                {DateTime.fromISO(companyProfile.lastActivity, {
                  zone: "utc",
                })
                  .toLocal()
                  .toFormat("MM/dd/yy")}
              </Typography>
            </Box>
          ) : null}
          {companyProfile?.yearEstablished ? (
            <Box display={"flex"}>
              <VerifiedUserOutlinedIcon
                fontSize="small"
                sx={{ mr: 0.3, color: "#6C737F" }}
              />
              <Typography variant="caption" color="text.secondary">
                {DateTime.now().year -
                (companyProfile?.yearEstablished ?? DateTime.now().year)
                  ? DateTime.now().year -
                    (companyProfile?.yearEstablished ?? DateTime.now().year)
                  : "< 1"}{" "}
                Years in Business
              </Typography>
            </Box>
          ) : null}
          {isDefined(companyProfile.activePromotionsCount) &&
            companyProfile.activePromotionsCount > 0 && (
              <Button
                size="small"
                variant="text"
                startIcon={<CardGiftcardIcon />}
                sx={{
                  paddingX: 0.5,
                  paddingY: 0,
                  ".MuiButton-startIcon": { marginRight: 0.5 },
                }}
                onClick={() => {
                  navigate(
                    getNavigationPathFromRoutePaths([
                      CompanyRoutes.BASE,
                      CompanyRoutes.JOB_PROMOTIONS,
                      String(companyProfile.id),
                    ])
                  );
                }}
              >
                <Typography variant="caption" color="primary">
                  {companyProfile.activePromotionsCount} Ongoing Promotions
                </Typography>
              </Button>
            )}
        </Stack>
        <Divider sx={{ width: "100%" }} />
        <Box py={1} display={"flex"} justifyContent={"start"}>
          <Button
            startIcon={<ShareIcon />}
            variant="contained"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setShareDialogOpen(true);
            }}
          >
            Share
          </Button>
        </Box>
      </Box>
      <ServiceProviderShareDialog
        isOpen={shareDialogOpen}
        source={ProfileShareSource.FIELD_TEAM}
        companyProfile={companyProfile}
        onClose={() => setShareDialogOpen(false)}
      />
    </>
  );
}
