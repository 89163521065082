import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useUpdateServiceTerritory(
  companyId: number,
  options: UseMutationOptions<void, AxiosError, Array<unknown>> = {}
): UseMutationResult<void, AxiosError, Array<unknown>> {
  return useMutation({
    mutationFn: async territories => {
      await API.patch(`/companies/${companyId}/territory`, { territories });
    },
    ...options,
  });
}
