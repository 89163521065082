import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export interface ShareJobPayload {
  email?: string;
  mobile?: string;
}

export function useJobShare(
  options: UseMutationOptions<
    void,
    AxiosError,
    { jobId: number; contacts?: Array<ShareJobPayload>; mobile?: string }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { jobId: number; contacts?: Array<ShareJobPayload>; mobile?: string }
> {
  return useMutation({
    mutationFn: async ({ jobId, contacts, mobile }) => {
      await API.post(`jobs/${jobId}/share`, { contacts, mobile });
    },
    ...options,
  });
}
