/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Close } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";

const FullscreenImagePreview = ({
  open,
  url,
  setOpen,
}: {
  url: string;
  setOpen: () => void;
  open: boolean;
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      onClose={() => {
        setOpen();
      }}
    >
      <IconButton
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Black background with transparency
          color: "white",
          padding: "5px", // Optional padding for better click area
          borderRadius: "50%",
          zIndex: 2,
        }}
        onClick={() => {
          setOpen();
        }}
      >
        <Close />
      </IconButton>
      {url && (
        <img
          src={url}
          alt="Fullscreen Preview"
          style={{
            width: "100%",
            height: "auto",
          }}
          onClick={() => {
            setOpen();
          }}
        />
      )}
    </Dialog>
  );
};

export default FullscreenImagePreview;
