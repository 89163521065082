import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

export function useGetUsersInternalAdmin(
  options?: UseQueryOptions<any>
): UseQueryResult<Array<any>> {
  const uri = `/internal-admin/users/search`;
  return useQuery({
    queryKey: [uri],
    queryFn: async () => {
      const response = await API.post(uri, {
        size: 10000,
      });
      return response.data.data;
    },
    ...options,
  });
}
