/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import PhonelinkLockOutlinedIcon from "@mui/icons-material/PhonelinkLockOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { ToolTip } from "@/common/tool-tip/ToolTip";
import { SearchedCompany } from "@/company/api/useExploreConnections";
import {
  CompanyPublicProfile,
  ExternalCompanyProfile,
  PLATFORM_PRESENCE,
} from "@/company/types";

import { CompanyAvatar } from "../company-avatar/CompanyAvatar";
import { getCompanySectorsChip } from "./utils";

export interface CompanyProfileCardProps {
  googleCompanyProfile?: ExternalCompanyProfile;
  companyProfile?: CompanyPublicProfile;
  showParentLogo?: boolean;
  isSelected?: boolean;
  onClick?: ({
    googleCompanyProfile,
    companyPublicProfile,
  }: SearchedCompany) => void;
}

export function RecommendedPartnerCard(props: CompanyProfileCardProps) {
  const {
    companyProfile,
    googleCompanyProfile,
    showParentLogo,
    isSelected,
    onClick,
  } = props;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const parentLogoUrl = companyProfile?.familyOfBrands?.logoUrl;
  const parentCompanyName = companyProfile?.familyOfBrands?.name;

  const cardProfile = companyProfile ?? googleCompanyProfile;

  return (
    <>
      <Card
        sx={{
          p: 2,
          boxShadow: "0px 0px 0px 1px #F2F4F7",
          "&:active": {
            border: "1px solid #071D2F",
          },
          "&:focus": {
            border: "1px solid #071D2F",
          },
          cursor: "pointer",
          "&:hover": {
            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
          },
          ...(isSelected ? { border: "1px solid #071D2F" } : {}),
        }}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          onClick?.({
            companyPublicProfile: companyProfile,
            googleCompanyProfile,
          });
        }}
      >
        <Box
          sx={{ display: "flex" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={1}
        >
          <CompanyAvatar
            height={"40px"}
            maxWidth={"160px"}
            logo={cardProfile?.logoUrl}
            name={cardProfile?.name || ""}
          />
          {parentLogoUrl && showParentLogo ? (
            <CompanyAvatar
              height={"32px"}
              maxWidth={"80px"}
              logo={parentLogoUrl}
              name={parentCompanyName || ""}
            />
          ) : null}
        </Box>
        <CardContent sx={{ p: "0 !important" }}>
          <Box display={"flex"} gap={1} mt={1} flexWrap={"wrap"}>
            {getCompanySectorsChip(
              companyProfile?.sectors,
              googleCompanyProfile?.sectors
            )}
          </Box>
          <Grid container pt={2}>
            <Grid item xs={12} display={"flex"} alignItems={"center"}>
              <span>
                <Typography display="inline" variant="h6">
                  {" "}
                  {cardProfile?.name}{" "}
                </Typography>
                {!!(
                  companyProfile?.platformPresence ===
                    PLATFORM_PRESENCE.ON_PLATFORM ||
                  googleCompanyProfile?.tradeEngageCompanyId
                ) && (
                  <ToolTip
                    placement="bottom"
                    title="TradeEngage Participant"
                    message="This is a verified active business on TradeEngage."
                  >
                    <VerifiedOutlinedIcon
                      color="primary"
                      fontSize="small"
                      sx={{ verticalAlign: "center", color: "#16DB65" }}
                    />
                  </ToolTip>
                )}
              </span>
            </Grid>
          </Grid>
          <Box display={"flex"} flexDirection={"column"} pt={2}>
            <Box display={"flex"} gap={1}>
              <Box display={"flex"} alignItems={"center"}>
                {companyProfile?.owners?.[0]?.firstName ? (
                  <>
                    <Box display={"flex"} alignItems={"flex-start"}>
                      {" "}
                      <AccountCircleOutlinedIcon
                        color="primary"
                        sx={{ width: "16px", mr: 0.5, mt: -0.3 }}
                      />{" "}
                      <Typography
                        variant="caption"
                        color="primary"
                        alignItems={"start"}
                      >
                        {companyProfile?.owners?.[0]?.firstName
                          ? `${companyProfile?.owners?.[0]?.firstName}`
                          : ""}
                        {companyProfile?.owners?.[0]?.firstName &&
                        companyProfile?.owners?.[0]?.lastName
                          ? " "
                          : ""}
                        {companyProfile?.owners?.[0]?.lastName
                          ? `${companyProfile?.owners?.[0]?.lastName}`
                          : ""}
                      </Typography>
                    </Box>
                  </>
                ) : null}
                {companyProfile?.owners?.[0]?.firstName &&
                companyProfile?.owners?.[0]?.hasPhone ? (
                  <ToolTip placement="bottom" message="Mobile Number Verified">
                    <PhonelinkLockOutlinedIcon
                      color="primary"
                      fontSize="small"
                      sx={{ width: "16px", mt: -0.3 }}
                    />
                  </ToolTip>
                ) : null}
                {companyProfile?.owners?.[0]?.firstName &&
                companyProfile?.owners?.[0]?.hasEmail ? (
                  <ToolTip placement="bottom" message="Email Address Verified">
                    <MarkEmailReadOutlinedIcon
                      color="primary"
                      fontSize="small"
                      sx={{ width: "16px", mt: -0.3 }}
                    />
                  </ToolTip>
                ) : null}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
