/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import CloseIcon from "@mui/icons-material/Close";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { Box, Theme, Typography } from "@mui/material";
import { closeSnackbar, CustomContentProps, SnackbarContent } from "notistack";
import { forwardRef } from "react";

interface WarnSnackBarProps extends CustomContentProps {
  onClose?: () => void;
}

// eslint-disable-next-line react/display-name
export const WarnSnackBar = forwardRef<HTMLDivElement, WarnSnackBarProps>(
  (props, ref) => {
    const {
      // You have access to notistack props and options 👇🏼
      id,
      message,
      onClose,

      // as well as your own custom props 👇🏼
      ...other
    } = props;

    return (
      <SnackbarContent ref={ref} role="alert" {...other}>
        <Box
          display={"flex"}
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.warning.main,
            padding: "6px 16px",
            borderRadius: "12px",
            boxShadow: "0px 6px 30px 0px rgba(0, 0, 0, 0.08)",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ pt: "10px", mr: "12px", width: "22px", height: "22px" }}>
            <WarningOutlinedIcon
              fontSize="small"
              sx={{
                color: "white",
              }}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"} py={1}>
            {message ? (
              <Typography
                sx={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  color: "white",
                }}
              >
                {message}
              </Typography>
            ) : null}
          </Box>
          {onClose ? (
            <CloseIcon
              fontSize="small"
              sx={{
                color: "#7A271A",
                cursor: "pointer",
                ml: 2,
                mt: "10px",
              }}
              onClick={() => {
                onClose();
                closeSnackbar(id);
              }}
            />
          ) : null}
        </Box>
      </SnackbarContent>
    );
  }
);
