import { Search } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NorthEastOutlinedIcon from "@mui/icons-material/NorthEastOutlined";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { DashboardTitle } from "@/common/DashboardTitle";
import { Seo } from "@/common/Seo/Seo";
import { canSeeCompanyMessages } from "@/common/utils/accessControl";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import {
  CallRecordingNotification,
  GET_CALL_RECORDING_NOTIFICATONS,
  useFetchCompanyCallRecordingNotificationList,
} from "@/company/api/useFetchCallNotificationsUnread";
import { useFetchCallRecordingById } from "@/company/api/useFetchCallRecordingById";
import {
  CallRecording,
  CallType,
  GET_CALL_RECORDINGS,
  useFetchCompanyCallRecordingList,
} from "@/company/api/useFetchCallRecordings";
import { AccessControlDialog } from "@/company/jobs/components/AccessControlDialog";
import { JobStatus } from "@/company/jobs/types";
import { CallCategory } from "@/company/jobs/types/job";
import { CompanyRoutes } from "@/company/types";

import { CompanyAvatar } from "../../company-avatar/CompanyAvatar";
import EmptyNotification from "./EmptyNotification";
import ReferralNotification from "./ReferralNotification";
import ScheduledNotification from "./ScheduledNotification";

export enum TabsEnum {
  DRAFT = "DRAFT",
  ALL_HISTORY = "ALL_HISTORY",
}

export function JobCallsPage() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { session } = useAuth();
  const company = session?.company;
  const navigate = useNavigate();
  const brandLocationProfile = session?.brandLocationProfile;
  const queryClient = useQueryClient();
  const {
    isLoading,
    isFetching,
    data: companyRecordingList,
  } = useFetchCompanyCallRecordingList({
    refetchOnWindowFocus: false,
  });

  const [companyList, setCompanyList] = useState<Array<CallRecording>>([]);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const [currentTab, setCurrentTab] = useState<TabsEnum>(TabsEnum.DRAFT);
  const [currentFindPartner, setFindPartner] = useState(false);

  const handleTabsChange = useCallback((value: TabsEnum): void => {
    setCurrentTab(value);
  }, []);

  const { selectedRecordingId, findPartner } = useParams();

  const { data: recordingById } = useFetchCallRecordingById(
    selectedRecordingId,
    {
      enabled: !!selectedRecordingId,
    }
  );

  useEffect(() => {
    if (findPartner === "find-partner") {
      setFindPartner(true);
    } else {
      setFindPartner(false);
    }
  }, [findPartner]);

  const [selectedCallRecordingId, setSelectedCallRcordingId] = useState<
    null | number
  >(null);
  const [searchText, setSearchText] = useState("");
  const { data: companyRecordingNotificationList } =
    useFetchCompanyCallRecordingNotificationList({
      refetchOnWindowFocus: false,
      enabled: !!session?.groups?.some(canSeeCompanyMessages),
    });

  let filteredCompanyRecordingList = searchText
    ? companyList?.filter(recording =>
        `${recording.homeDetails?.homeowner.firstName} ${recording.homeDetails?.homeowner.lastName}`
          ?.toLowerCase()
          ?.startsWith(searchText.toLowerCase())
      )
    : companyList;

  useEffect(() => {
    if (companyRecordingList?.length) {
      setCompanyList(companyRecordingList);
    }
  }, [companyRecordingList]);

  const refetchCalls = async (selectedRecording: CallRecording) => {
    try {
      setCompanyList(prevList =>
        prevList.map(recording =>
          recording.id === selectedRecording.id ? selectedRecording : recording
        )
      );
    } catch (error) {
      console.error("Error fetching the updated recording:", error);
    }
  };

  useEffect(() => {
    if (recordingById) {
      if (recordingById.job?.status !== JobStatus.DRAFT && recordingById.job) {
        setCurrentTab(TabsEnum.ALL_HISTORY);
      }
      setSelectedCallRcordingId(recordingById.id);
    }
  }, [recordingById]);

  filteredCompanyRecordingList =
    currentTab === TabsEnum.DRAFT
      ? filteredCompanyRecordingList?.filter(
          recording =>
            recording.job?.status === JobStatus.DRAFT || !recording?.job
        )
      : filteredCompanyRecordingList;

  const draftList = filteredCompanyRecordingList?.filter(
    recording => recording.job?.status === JobStatus.DRAFT || !recording?.job
  );

  const currentRecording = selectedCallRecordingId
    ? companyList?.find(item => item.id === selectedCallRecordingId)
    : null;

  return (
    <>
      <Seo title="Call Analytics Jobs Inbox" />
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"calc(100vh - 60px)"}
        pt={1}
      >
        <DashboardTitle
          title="Call Analytics"
          subtitle="Generate referrals right from your call analytics."
        />
        {isFetching ? (
          <Box textAlign={"center"} width="100%" mt={5}>
            <ComponentLoader />
          </Box>
        ) : companyList && companyList.length > 0 ? (
          <Container
            maxWidth={false}
            sx={{
              mx: 0,
              mt: 2,
              flexGrow: 1,
              display: "flex",
              overflow: "auto",
            }}
            disableGutters={true}
          >
            <Grid container flexGrow={1} display={"flex"} overflow={"auto"}>
              <Grid
                item
                container
                xs={12}
                md={isMobile ? 12 : 4}
                sx={{
                  overflow: "auto",
                  display: "flex",
                  height: "fit-content",
                  maxHeight: "100%",
                  pr: 2,
                  gap: 2,
                }}
              >
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    placeholder="Search by name..."
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                  />
                  <Box
                    sx={{
                      background: "white",
                      zIndex: 1000,
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <Box display="flex" pb={1} gap={1} mt={2}>
                      <Chip
                        label={`Draft ${draftList?.length}`}
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(currentTab === TabsEnum.DRAFT
                          ? { color: "primary", variant: "filled" }
                          : { variant: "outlined" })}
                        onClick={() => {
                          handleTabsChange(TabsEnum.DRAFT);
                        }}
                      />
                      <Chip
                        label={`All History ${companyList?.length}`}
                        sx={{
                          cursor: "pointer",
                          "&:hover": isMobile
                            ? { bgcolor: "#16B364" }
                            : { bgcolor: "#087443" },
                        }}
                        {...(currentTab === TabsEnum.ALL_HISTORY
                          ? { color: "primary", variant: "filled" }
                          : { variant: "outlined" })}
                        onClick={() => {
                          handleTabsChange(TabsEnum.ALL_HISTORY);
                        }}
                      />
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {currentTab === TabsEnum.ALL_HISTORY
                        ? `Here you can view all the jobs generated and routed from your call analytics`
                        : "We need more information for these jobs to be routed properly"}
                    </Typography>
                    <Divider sx={{ mt: 1 }} />
                  </Box>
                </Grid>
                {filteredCompanyRecordingList?.map(item => {
                  const createdAt = DateTime.fromFormat(
                    item.createdAt
                      .toString()
                      .split(".")[0]
                      .split("T")
                      .join(" "),
                    "yyyy-MM-dd HH:mm:ss",
                    { zone: "utc" }
                  );
                  const ago = createdAt.toLocal().toRelative({
                    base: DateTime.now().toLocal(),
                  });
                  const todayStart = DateTime.local().startOf("day");

                  const formattedDate =
                    createdAt.toLocal() < todayStart
                      ? createdAt.toLocal().toFormat("MM/dd/yy")
                      : createdAt.toLocal().toFormat("h:mm a");

                  const isUnread = companyRecordingNotificationList?.some(
                    notification => notification.callRecordingId === item.id
                  );

                  return (
                    <>
                      <Grid
                        key={item.id}
                        container
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          borderRadius: "24px",
                          px: { xs: 0, md: 3 },
                          py: { xs: 2, md: 2 },
                          cursor: "pointer",
                          // borderRadius: "16px",
                          border: "1px solid #F3F4F6",
                          background: "#FFF",
                          backgroundColor: (theme: Theme) =>
                            selectedCallRecordingId === item.id
                              ? theme.palette.primary.light
                              : theme.palette.background.paper,
                        }}
                        onClick={() => {
                          setSelectedCallRcordingId(item.id);
                          if (currentFindPartner) {
                            setFindPartner(false);
                          }
                          queryClient.invalidateQueries({
                            queryKey: [
                              GET_CALL_RECORDINGS,
                              GET_CALL_RECORDING_NOTIFICATONS,
                            ],
                          });
                        }}
                      >
                        <Grid
                          item
                          xs={10}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            borderRadius: "24px",
                          }}
                        >
                          {item.job?.createdByCompanyId === company?.id ? (
                            <NorthEastOutlinedIcon
                              fontSize="large"
                              sx={{
                                bgcolor: theme => theme.palette.primary.light,
                                color: theme => theme.palette.primary.main,
                                borderRadius: 30,
                                p: 1,
                                mr: 2,
                              }}
                            />
                          ) : item.job?.createdForCompanyId === company?.id ? (
                            <SouthEastOutlinedIcon
                              fontSize="large"
                              color="info"
                              sx={{
                                bgcolor: theme => theme.palette.info.light,
                                color: theme => theme.palette.info.main,
                                borderRadius: 30,
                                p: 1,
                                mr: 2,
                              }}
                            />
                          ) : (
                            <CancelOutlinedIcon
                              fontSize="large"
                              color="info"
                              sx={{
                                bgcolor: "#3842500A",
                                color: "#6C737F",
                                borderRadius: 30,
                                p: 1,
                                mr: 2,
                              }}
                            />
                          )}
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            flexDirection={"column"}
                            mx={1}
                          >
                            <Typography variant="h6">
                              {`${
                                item.type === CallType.INBOUND
                                  ? item.fromPhone
                                  : item.toPhone
                              }`}
                            </Typography>
                            <Box display={"flex"} gap={1} alignItems={"center"}>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                              >
                                {item.job?.jobType}
                              </Typography>
                            </Box>
                            {item.potentialReferralPartner ? (
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"flex-start"}
                                mt={1}
                                p={1}
                                sx={{
                                  borderRadius: "20px",
                                  bgcolor: "#F8F9FA",
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  gap={0.5}
                                  px={0.5}
                                >
                                  <CompanyAvatar
                                    height={"24px"}
                                    maxWidth="24px"
                                    bgColor="white"
                                    borderRadius="50%"
                                    logo={
                                      item.potentialReferralPartner?.logoUrl
                                    }
                                    name={
                                      item.potentialReferralPartner?.name ?? ""
                                    }
                                  />

                                  <Typography variant="caption">
                                    {item.potentialReferralPartner?.name}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-end"
                          flexDirection={"column"}
                        >
                          <Typography
                            gutterBottom
                            variant="caption"
                            color={isUnread ? "text.primary" : "text.secondary"}
                          >
                            {ago?.startsWith("in 0 seconds") ||
                            ago?.startsWith("0 seconds ago")
                              ? "Just now"
                              : `${formattedDate}`}
                          </Typography>
                          {isUnread ? (
                            <FiberManualRecordIcon
                              color="primary"
                              fontSize="small"
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                      {isMobile ? (
                        <Divider sx={{ width: "100%", my: 1 }} />
                      ) : null}
                    </>
                  );
                })}
              </Grid>
              {isMobile ? null : (
                <Grid
                  item
                  container
                  xs={false}
                  md={!isMobile ? 8 : false}
                  sx={{
                    px: 3,
                    borderLeft: isMobile ? null : "2px #F8F9FA solid",
                    overflow: "auto",
                    height: "100%",
                  }}
                >
                  {isFetching ? (
                    <Box textAlign={"center"} width="100%" mt={5}>
                      <ComponentLoader />
                    </Box>
                  ) : selectedCallRecordingId ? (
                    currentRecording?.job ? (
                      currentRecording?.isThereAReferral ? (
                        <ReferralNotification
                          setTab={(tab: TabsEnum) => {
                            setCurrentTab(tab);
                          }}
                          selectedRecordingId={selectedCallRecordingId}
                          findPartner={currentFindPartner}
                          notification={
                            companyRecordingNotificationList?.find(
                              notification =>
                                notification.callRecordingId ===
                                selectedCallRecordingId
                            ) as CallRecordingNotification
                          }
                          setFindPartner={() => {
                            setFindPartner(false);
                          }}
                          refetchCalls={(recording: CallRecording) => {
                            refetchCalls(recording);
                          }}
                          onClose={() => {
                            setSelectedCallRcordingId(null);
                          }}
                        />
                      ) : currentRecording?.label ===
                        CallCategory.HOMEOWNER_SCHEDULING_A_JOB ? (
                        <ScheduledNotification
                          selectedRecordingId={selectedCallRecordingId}
                          selectedRecordingNotification={
                            companyRecordingNotificationList?.find(
                              notification =>
                                notification.callRecordingId ===
                                selectedCallRecordingId
                            ) as CallRecordingNotification
                          }
                          onClose={() => {
                            setSelectedCallRcordingId(null);
                          }}
                        />
                      ) : null
                    ) : (
                      <EmptyNotification
                        selectedRecordingNotification={
                          companyRecordingNotificationList?.find(
                            notification =>
                              notification.callRecordingId ===
                              selectedCallRecordingId
                          ) as CallRecordingNotification
                        }
                        refetchCalls={(recording: CallRecording) => {
                          refetchCalls(recording);
                        }}
                        selectedRecording={
                          companyList?.find(
                            item => item.id === selectedCallRecordingId
                          ) as CallRecording
                        }
                        onClose={() => {
                          setSelectedCallRcordingId(null);
                        }}
                      />
                    )
                  ) : isMobile ? null : (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={"100%"}
                      height={"100%"}
                      gap={3}
                      flex={1}
                    >
                      <img
                        src="/empty-icon.png"
                        style={{ width: "132px", height: "128px" }}
                        alt="no pending jobs thumbnail"
                      />
                      <Box textAlign={"center"}>
                        <Typography gutterBottom variant="h6">
                          No Call Summary Selected
                        </Typography>
                        <Typography variant="body2" color={"text.secondary"}>
                          Select a call summary from your inbox to refer the job
                          to local partners.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </Container>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={5}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                No Calls Found
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                Click below to view your partners and start chatting with them.
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                navigate(
                  getNavigationPathFromRoutePaths([
                    CompanyRoutes.BASE,
                    CompanyRoutes.MANAGE_PARTNERS,
                  ])
                );
              }}
            >
              View Partners
            </Button>
          </Box>
        )}
      </Box>
      <Dialog
        fullScreen
        open={Boolean(selectedCallRecordingId && isMobile)}
        onClose={() => setSelectedCallRcordingId(null)}
      >
        {isFetching ? (
          <Box textAlign={"center"} width="100%" mt={5}>
            <ComponentLoader />
          </Box>
        ) : selectedCallRecordingId ? (
          currentRecording?.job ? (
            currentRecording?.isThereAReferral ? (
              <ReferralNotification
                setTab={(tab: TabsEnum) => {
                  setCurrentTab(tab);
                }}
                selectedRecordingId={selectedCallRecordingId}
                findPartner={currentFindPartner}
                notification={
                  companyRecordingNotificationList?.find(
                    notification =>
                      notification.callRecordingId === selectedCallRecordingId
                  ) as CallRecordingNotification
                }
                setFindPartner={() => {
                  setFindPartner(false);
                }}
                refetchCalls={(recording: CallRecording) => {
                  refetchCalls(recording);
                }}
                onClose={() => {
                  setSelectedCallRcordingId(null);
                }}
              />
            ) : currentRecording?.label ===
              CallCategory.HOMEOWNER_SCHEDULING_A_JOB ? (
              <ScheduledNotification
                selectedRecordingId={selectedCallRecordingId}
                selectedRecordingNotification={
                  companyRecordingNotificationList?.find(
                    notification =>
                      notification.callRecordingId === selectedCallRecordingId
                  ) as CallRecordingNotification
                }
                onClose={() => {
                  setSelectedCallRcordingId(null);
                }}
              />
            ) : null
          ) : (
            <EmptyNotification
              selectedRecordingNotification={
                companyRecordingNotificationList?.find(
                  notification =>
                    notification.callRecordingId === selectedCallRecordingId
                ) as CallRecordingNotification
              }
              refetchCalls={(recording: CallRecording) => {
                refetchCalls(recording);
              }}
              selectedRecording={
                companyList?.find(
                  item => item.id === selectedCallRecordingId
                ) as CallRecording
              }
              onClose={() => {
                setSelectedCallRcordingId(null);
              }}
            />
          )
        ) : null}
      </Dialog>
      {!brandLocationProfile?.callAnalyticsEnabled ? (
        <AccessControlDialog
          message="to connect TradeEngage to your call analytics and generate referrals directly from your phone calls."
          isOpen={!brandLocationProfile?.callAnalyticsEnabled}
          onClose={() => {
            navigate(-1);
          }}
        />
      ) : null}
    </>
  );
}
