import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { DateTime } from "luxon";

import API from "@/common/api";

import { JobSectors } from "../jobs/constants";

export interface InternalAdminJobFilters {
  sectors?: Array<JobSectors>;
  createdAt?: DateTime;
  nonCompetingCompanies?: boolean;
  franchise?: Array<string>;
  businessAge?: Array<string>;
  relevantSectors?: boolean;
  confirmedInsured?: boolean;
  existingCustomerReferralProgram?: boolean;
  existingReferral?: boolean;
  companyOwnership?: Array<string>;
  ownersNearRetirement?: boolean;
  familyOperatedBusiness?: boolean;
  mutualConnections?: boolean;
  localReferralNetwork?: Array<string>;
  collaborateWithOtherPartners?: boolean;
  tradeengageActivity?: Array<string>;
  betterBusinessBureau?: Array<string>;
  googleRating?: Array<number>;
  numberOfGoogleReviews?: Array<number>;
  historicalLawsuits?: boolean;
  numberOfWebsiteVisitors?: Array<number>;
  numberOfEmployees?: number;
  brickAndMortarLocation?: boolean;
  contactInformation?: Array<string>;
  detailsTechnologyStack?: boolean;
  onlineScheduling?: boolean;
  website?: boolean;
  marketPresence?: Array<string>;
  countOnly?: boolean;
  zipCodes?: Array<string>;
  milesRadius?: number;
  keyword?: string;
  archived?: boolean;
}

export interface Sorting {
  field: string;
  order: string;
}

export function useQueryJobSearch(
  //   pageNumber: number,
  //   pageSize: number,
  //   filters?: InternalAdminJobFilters,
  //   sorting?: Sorting[],
  //   tableFilters?: GridFilterModel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<any>> {
  const uri = "/internal-admin/jobs/search";

  //   const offset = pageNumber === 0 ? pageNumber : pageSize * pageNumber + 1;

  //   const newSorting = sorting ? transformSortingFields(sorting) : undefined;

  //   let filtersArray: Array<any> = [];
  //   type LogicOperator = "and" | "or";
  //   let logicOperator: LogicOperator | undefined;
  //   const payload = {
  //     offset,
  //     size: pageSize,
  //     sorting,
  //     filterTables: {},
  //   };

  //   if (tableFilters) {
  //     logicOperator = tableFilters.logicOperator as LogicOperator;
  //     filtersArray = tableFilters.items
  //       .filter(
  //         (item: {
  //           value: null | undefined | unknown;
  //           operator: string;
  //           field: string;
  //         }) =>
  //           item.value !== null && item.value !== "" && item.field !== "__check__"
  //       )
  //       .filter(
  //         (item: { value: string; operator: string }) =>
  //           item.value !== undefined ||
  //           (item.value === undefined &&
  //             ["isEmpty", "isNotEmpty"].includes(item.operator))
  //       )
  //       .map(
  //         (item: {
  //           value: null | undefined | unknown;
  //           operator: string;
  //           field: string;
  //         }) => ({
  //           value: item.value,
  //           field: camelToUnderscorePreserveHyphen(item.field),
  //           operator: item.operator,
  //         })
  //       );
  //     payload.filterTables = {
  //       logicOperator,
  //       filters: filtersArray,
  //     };
  //   }
  //   payload.filters = filters;

  return useQuery({
    queryKey: [uri.toString()],
    queryFn: async () => {
      //   const underScordedPayload = changeCaseKeys(
      //     {
      //       offset,
      //       size: pageSize,
      //       keyword: payload.filters?.keyword ?? "",
      //       sorting: newSorting,
      //       ...(filtersArray.length > 0
      //         ? { filterTables: payload.filterTables }
      //         : {}),
      //     },
      //     "underscored"
      //   );
      //   const response = await API.post(uri, underScordedPayload);
      const response = await API.post(uri, { size: 10000, offset: 0 });
      return response.data.data;
    },
    ...options,
  });
}
