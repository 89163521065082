import { Box, Typography } from "@mui/material";

import { HomeDetails } from "@/company/api/useFetchCallRecordings";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { formatUSPhoneNumber } from "@/utils";

const HomeownerDetails = ({ homeDetails }: { homeDetails: HomeDetails }) => {
  const homeownerName =
    homeDetails?.homeowner?.name ?? homeDetails.homeowner.firstName
      ? `${homeDetails.homeowner.firstName} ${homeDetails.homeowner.lastName}`
      : null;
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1} mb={2}>
      <Typography variant="subtitle2">Homeowner</Typography>
      <Box display={"flex"} gap={2} alignItems={"baseline"}>
        {homeownerName ? (
          <CompanyAvatar
            name={`${
              homeDetails?.homeowner?.name ??
              `${homeDetails.homeowner.firstName} ${homeDetails.homeowner.lastName}`
            }`}
            width="32px"
            height="32px"
          />
        ) : null}
        {homeDetails.home.zipCode || homeDetails.homeowner.cellPhone ? (
          <Box display={"flex"} gap={0.5} flexDirection={"column"}>
            {homeownerName ? (
              <Typography variant="subtitle1">{`${
                homeDetails?.homeowner?.name ??
                `${homeDetails.homeowner.firstName} ${homeDetails.homeowner.lastName}`
              }`}</Typography>
            ) : null}
            {homeDetails.home.zipCode ? (
              <Typography variant="body2" color="text.secondary">
                {`Zip Code: ${homeDetails.home.zipCode ?? ""}`}
              </Typography>
            ) : null}
            {homeDetails.homeowner.cellPhone ? (
              <Typography variant="body2" color="text.secondary">
                {`${formatUSPhoneNumber(homeDetails.homeowner.cellPhone)}`}
              </Typography>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default HomeownerDetails;
