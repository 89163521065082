import { Box, Button, Popover, Slider, Stack, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";

import { isDefined } from "@/utils/isDefined";

interface GoogleRatingsFilterFormProps {
  anchorEl: HTMLElement | null;
  minValue: number;
  onSubmit: (value: number) => void;
  onClose: () => void;
}

export function GoogleRatingsFilterForm(props: GoogleRatingsFilterFormProps) {
  const { anchorEl, minValue, onSubmit, onClose } = props;

  const googleRatingFilterFormMethods = useFormik<{ minValue: number }>({
    initialValues: { minValue },
    onSubmit: values => {
      onSubmit(values.minValue);
    },
  });

  return (
    <Popover
      anchorEl={anchorEl}
      open={isDefined(anchorEl)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={onClose}
    >
      <Box sx={{ padding: 2, width: 272 }}>
        <Typography variant="subtitle1" fontWeight={500}>
          Google Rating
        </Typography>
        <Typography variant="body2" fontWeight={400} color="text.secondary">
          Set a minimum Google rating to limit participation.
        </Typography>
        <FormikProvider value={googleRatingFilterFormMethods}>
          <Box component="form">
            <Stack direction="row" justifyContent="space-between" marginTop={1}>
              <Typography variant="subtitle2" fontWeight={600}>
                1.0
              </Typography>
              <Typography variant="subtitle2" fontWeight={600}>
                5.0
              </Typography>
            </Stack>
            <Slider
              marks
              aria-label="Google Rating"
              valueLabelDisplay="auto"
              shiftStep={0.5}
              size="small"
              step={0.5}
              min={0.5}
              max={5}
              sx={{ paddingY: 0.5 }}
              value={googleRatingFilterFormMethods.values.minValue}
              onChange={(_event, value) => {
                googleRatingFilterFormMethods.setFieldValue("minValue", value);
              }}
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography
                variant="overline"
                color="text.disabled"
                fontWeight={600}
              >
                MIN
              </Typography>
              <Typography
                variant="overline"
                color="text.disabled"
                fontWeight={600}
              >
                MAX
              </Typography>
            </Stack>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => googleRatingFilterFormMethods.submitForm()}
            >
              Apply
            </Button>
          </Box>
        </FormikProvider>
      </Box>
    </Popover>
  );
}
