import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import { AppConstants } from "@/common/constants";
import { Seo } from "@/common/Seo/Seo";

import { FactsCarousel } from "./FactsCarousel";

export function TechnicianDemoPage() {
  const videoRef = useRef(null);

  const handleVideoEnd = () => {
    trackEvent(AnalyticsEvents.WATCHED_TECHNICIAN_DEMO, {});
  };

  const [playClicked, setPlayClicked] = useState(false);

  useEffect(() => {
    if (playClicked) {
      const videoElement = videoRef.current;
      if (videoElement && videoElement.paused) {
        videoElement.play();
      }
    }
  }, [playClicked]);

  return (
    <>
      <Seo title="Demo" />
      <Box>
        <Grid
          container
          sx={{
            zIndex: 400,
            pt: 1,
          }}
          bgcolor="white"
        >
          <Grid item xs={12} lg={10}>
            <Typography gutterBottom variant="h5">
              Boost Your Earnings Today!
            </Typography>
            <Typography gutterBottom variant="body2" color="textSecondary">
              Watch our video for tips to submit job videos.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          my={3}
          sx={{
            justifyContent: { xs: "space-around", md: "center" },
            alignItems: { xs: "center", md: "space-around" },
            gap: 3,
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            textAlign={"center"}
            display={"flex"}
            justifyContent={"center"}
          >
            {playClicked ? (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video
                ref={videoRef}
                autoPlay
                controls
                controlsList="nodownload"
                style={{
                  width: "368px",
                  height: "600px",
                  borderRadius: "20px",
                  objectFit: "contain",
                }}
                onEnded={handleVideoEnd}
              >
                <source
                  src={AppConstants.technicianDemoVideoUrl}
                  type="video/mp4"
                />
              </video>
            ) : (
              <Box
                style={{
                  width: "368px",
                  height: "600px",
                  objectFit: "contain",
                  borderRadius: "20px",
                }}
                bgcolor="primary.light"
                display={playClicked ? "none" : "flex"}
                justifyContent={"center"}
                alignItems={"center"}
                onClick={() => setPlayClicked(true)}
              >
                <img
                  src="/technician-demo-video.svg"
                  alt="technician-demo-poster"
                  width="100%"
                  height="320px"
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FactsCarousel />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
