import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CircularProgress,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";

import { useAuth } from "@/auth/context/jwt";
import { AppConstants } from "@/common/constants";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyRoutes } from "@/company/types";
import { StripeAccountDetails } from "@/user/api/useGetStripeAccountDetails";
import {
  GetStripeLinkPayload,
  StripeLinkType,
  useGetStripeLink,
} from "@/user/api/useGetStripeAccountLink";

export function BankAccountCard({
  fetchingAccountDetails,
  accountDetails,
}: {
  fetchingAccountDetails: boolean;
  accountDetails?: StripeAccountDetails;
}) {
  const { refreshSession } = useAuth();

  const getStripeDashboardLinkPayload: GetStripeLinkPayload = {
    linkType: StripeLinkType.STRIPE_DASHBOARD,
    returnUrl:
      AppConstants.platformBaseUrl +
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.MY_WALLET,
      ]),
    refreshUrl: window.location.href,
  };

  const { isLoading: isFetchingLink, mutateAsync: getStripeLink } =
    useGetStripeLink({
      onError: () => {
        enqueueSnackbar(
          "There was an error while fetching your stripe account link.",
          { variant: "error" }
        );
      },
      onSuccess: () => {
        refreshSession();
      },
    });

  if (!fetchingAccountDetails && !accountDetails) {
    return null;
  }
  return (
    <Card
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        bgcolor: "white",
        boxShadow: "0px 0px 0px 1px #F2F4F7",
        width: "100%",
      }}
    >
      {fetchingAccountDetails ? (
        <Box mt={10} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center" gap={1}>
            <AccountBalanceOutlinedIcon
              sx={{ color: "#6C737F", width: "20px", height: "20px" }}
            />
            <Typography variant="overline" color="text.secondary">
              ********{accountDetails?.bankLast4Digits}
            </Typography>
          </Box>
          <Typography variant="h6">{accountDetails?.bankName}</Typography>
          {accountDetails?.lastWithdrawDate ? (
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="text.secondary">
                Last TradeEngage withdrawal on{" "}
                {DateTime.fromFormat(
                  accountDetails?.lastWithdrawDate,
                  "MM/dd/yy",
                  {
                    zone: "utc",
                  }
                )
                  .toLocal()
                  .toFormat("MM/dd/yy")}
              </Typography>
            </Box>
          ) : null}
        </>
      )}
      <LoadingButton
        variant="contained"
        disabled={isFetchingLink}
        loading={isFetchingLink}
        onClick={async () => {
          const link = await getStripeLink(getStripeDashboardLinkPayload);
          if (link) {
            window.open(link, "_blank");
          }
        }}
      >
        Change Bank
      </LoadingButton>
      {accountDetails?.actionRequired ? (
        <Alert
          severity="error"
          variant="standard"
          sx={{ mt: 2, color: "#6d331b", bgcolor: "#fae5e2" }}
        >
          <AlertTitle>Action Required</AlertTitle>
          Please fulfill the necessary requirements in your Stripe Dashboard to
          withdraw your funds.
        </Alert>
      ) : null}
    </Card>
  );
}
