import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { JobSectors } from "../jobs/constants";

export function useFetchRecommendedSectors(
  sectors: JobSectors[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<JobSectors>> {
  const uri = "/companies/recommended-referral-job-sectors";

  const sectorParams = sectors?.map(sector => "sectors=" + sector).join("&");

  const uriWithSearchQuery = `${uri}?${sectorParams ?? ""}`;

  return useQuery({
    queryKey: [uriWithSearchQuery.toString()],
    queryFn: async () => {
      const response = await API.get(uriWithSearchQuery);
      return response.data.data ?? [];
    },
    ...options,
  });
}
