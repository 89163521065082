import {
  EmailOutlined,
  HomeOutlined,
  KeyOutlined,
  PaidOutlined,
  PhoneOutlined,
  WatchLaterOutlined,
} from "@mui/icons-material";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import Link from "@mui/icons-material/Link";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { enqueueSnackbar } from "notistack";
import { FC, useState } from "react";

import { Campaign } from "@/auth/types";
import { useDeleteCompanyLogo } from "@/company/api/useDeleteCompanyLogo";
import { BasicCompanySchema } from "@/company/api/useGetAllBrands";
import { useUpdateCompanyLogo } from "@/company/api/useUpdateCompanyLogo";
import { JobSectors } from "@/company/jobs/constants";
import { CompanySector, PLATFORM_PRESENCE } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { getInitials, snakeCaseToTitleCase } from "@/utils";

export interface BrandLocationReferralSchema {
  sector: JobSectors;
  order: number;
  createdAt: DateTime;
  buyingPrice: number;
}

export interface BrandLocationReferralInvitationSchema {
  sectors: Array<JobSectors>;
  createdAt: DateTime;
  acceptedAt?: string;
  declinedAt?: string;
}

export interface BrandLocationReferralNetworkSchema {
  referrals: Array<BrandLocationReferralSchema>;
  referralInvitationsSent: Array<BrandLocationReferralInvitationSchema>;
  referralInvitationsReceived: Array<BrandLocationReferralInvitationSchema>;
  affiliatedCompanies: Array<BasicCompanySchema>;
  recommendedPartners: Array<BasicCompanySchema>;
}

export interface ProfileOverviewProps {
  overview: any;
  companyId: number;
  expanded?: boolean;
  isFetchingProfile?: boolean;
  refetch: () => void;
  refetchCompanies: () => void;
  campaigns?: Array<Campaign>;
}

export const ProfileOverview: FC<ProfileOverviewProps> = ({
  overview,
  companyId,
  expanded,
  isFetchingProfile,
  refetch,
  refetchCompanies,
  campaigns = [],
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [logoFile, setLogoFile] = useState<any>(undefined);
  const [currentLogoUrl, setCurrentLogoUrl] = useState<any>(overview?.logoUrl);

  const [imageDeleted, setImageDeleted] = useState(false);

  const { mutateAsync: updateCompanyLogo, isLoading: isUpdatingLogo } =
    useUpdateCompanyLogo(companyId, {
      onSuccess: () => {
        enqueueSnackbar({
          message: "Updated company logo successfully.",
          variant: "success",
        });
        refetch();
        refetchCompanies();
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while updating company logo.`,
          variant: "error",
        });
      },
    });

  const { mutateAsync: deleteCompanyLogo, isLoading: isDeletingLogo } =
    useDeleteCompanyLogo(companyId, {
      onSuccess: () => {
        setLogoFile(undefined);
        setCurrentLogoUrl(undefined);
        setImageDeleted(true);
        enqueueSnackbar({
          message: "Updated company logo successfully.",
          variant: "success",
        });
        refetch();
        refetchCompanies();
      },
      onError: () => {
        enqueueSnackbar({
          message: `There was an error while updating company logo.`,
          variant: "error",
        });
      },
    });

  const onSelectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFile = event.target.files[0];
      const img = new Image();
      const objectUrl = window.URL.createObjectURL(newFile);
      img.onload = function () {
        window.URL.revokeObjectURL(objectUrl);
        setLogoFile(newFile);
        setImageDeleted(false);
        updateCompanyLogo({ logoFile: newFile });
      };
      img.src = objectUrl;
    } else {
      setLogoFile(undefined);
    }
  };

  const [isImageLoadError, setIsImageLoadError] = useState(false);

  const handleDelete = () => {
    deleteCompanyLogo({ isLogoDeleted: true });
  };

  return (
    <>
      <Box
        display={"flex"}
        pb={2}
        gap={1}
        flexDirection={"column"}
        width={"100%"}
        pl={2}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="h6">{overview.name}</Typography>
          {overview.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM ? (
            <VerifiedOutlinedIcon
              sx={{
                verticalAlign: "center",
                color: "#16DB65",
                ml: 0.5,
                height: "20px",
              }}
            />
          ) : null}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap="4px"
          mb={1}
          sx={{ mt: { xs: 2, lg: 1 } }}
        >
          {logoFile || (currentLogoUrl && !isImageLoadError) ? (
            <img
              src={
                logoFile ? window.URL.createObjectURL(logoFile) : currentLogoUrl
              }
              alt={overview?.name}
              width="40px"
              height="40px"
              onError={error => {
                console.error(error);
                setIsImageLoadError(true);
              }}
            />
          ) : (
            <Avatar variant="square" sx={{ height: "40px", width: "40px" }}>
              {getInitials(overview?.name ?? "")}
            </Avatar>
          )}
          <Box ml={1}>
            <Box display={"flex"}>
              <Button
                component="label"
                sx={{ p: 0 }}
                disabled={isUpdatingLogo || isDeletingLogo || isFetchingProfile}
              >
                Change Logo
                <input
                  type="file"
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  accept="image/*"
                  onChange={onSelectFile}
                />
              </Button>
              {!imageDeleted && (logoFile || overview?.logoUrl) ? (
                <IconButton
                  size="small"
                  aria-label="delete"
                  disabled={
                    isUpdatingLogo || isDeletingLogo || isFetchingProfile
                  }
                  onClick={() => handleDelete()}
                >
                  <DeleteForeverRoundedIcon />
                </IconButton>
              ) : null}
            </Box>
            <Typography
              variant="body2"
              color={
                isUpdatingLogo || isDeletingLogo
                  ? "text.disabled"
                  : "text.secondary"
              }
            >
              Logo should be at least 40px in height and have a maximum width of
              160px
            </Typography>
          </Box>
        </Box>
        <Divider />
        {overview.sectors?.length ? (
          <Box>
            <Typography variant="overline" color="text.secondary">
              Sectors
            </Typography>
            {overview.sectors?.length ? (
              <Box py={1} display={"flex"} gap={1}>
                {overview.sectors.map((sector: CompanySector) => (
                  <Chip
                    key={sector.id}
                    sx={{
                      color: "text.secondary",
                      bgcolor: "#F8F9FA",
                    }}
                    label={snakeCaseJobSectors[sector.sector]}
                    size="small"
                  />
                ))}
              </Box>
            ) : null}
            <Divider sx={{ mt: 1 }} />
          </Box>
        ) : null}
        {overview.description ? (
          <Box mb={1}>
            {" "}
            <Typography variant="overline" color="text.secondary" mb={1}>
              Description
            </Typography>
            <Typography variant="body2" color={"text.secondary"} mb={1}>
              {overview.description}
            </Typography>
            <Divider />
          </Box>
        ) : null}
        <Typography variant="overline" color="text.secondary" mb={1}>
          Overview
        </Typography>

        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          width="100%"
        >
          <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
            <KeyOutlined sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Company ID
            </Typography>
          </Box>
          <Typography
            fontSize={"12px"}
            fontWeight={500}
            width="50%"
            sx={{ overflowWrap: "break-word" }}
          >
            {overview.id}
          </Typography>
        </Box>

        {overview.profile?.brandCategory ? (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            width="100%"
          >
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <GroupWorkOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Brand Category
              </Typography>
            </Box>
            <Chip
              sx={{
                color: "white",
                bgcolor: "#16B364",
              }}
              label={overview.profile?.brandCategory}
              size="small"
            />
          </Box>
        ) : null}
        {overview.profile?.googlePlacesId ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <VerifiedOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Google Places ID
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              width="50%"
              sx={{ overflowWrap: "break-word" }}
            >
              {overview.profile.googlePlacesId}
            </Typography>
          </Box>
        ) : null}

        {overview.profile?.googleReviewLink ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <VerifiedOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Google Review Link
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              width="50%"
              sx={{ overflowWrap: "break-word" }}
            >
              {overview.profile?.googleReviewLink}
            </Typography>
          </Box>
        ) : null}

        {overview.websiteUrl ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <LanguageOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Website
              </Typography>
            </Box>
            <Typography
              width="50%"
              fontSize={"12px"}
              fontWeight={500}
              flexWrap={"wrap"}
              sx={{ overflowWrap: "break-word" }}
            >
              {overview.websiteUrl}
            </Typography>
          </Box>
        ) : null}
        {overview.yearEstablished ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <VerifiedUserOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Years In Business
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              width="50%"
              justifyContent={"start"}
              sx={{ overflowWrap: "break-word" }}
            >
              {DateTime.now().year -
              (overview?.yearEstablished ?? DateTime.now().year)
                ? DateTime.now().year -
                  (overview?.yearEstablished ?? DateTime.now().year)
                : "< 1"}
            </Typography>
          </Box>
        ) : null}
        {overview.numberEmployees ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <EngineeringOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Employees
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              width="50%"
              sx={{ overflowWrap: "break-word" }}
              fontWeight={500}
            >
              {overview.numberEmployees}
            </Typography>
          </Box>
        ) : null}
        {overview.createdAt ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <VerifiedOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Member since
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              width="50%"
              sx={{ overflowWrap: "break-word" }}
            >
              {DateTime.fromISO(overview.createdAt, {
                zone: "utc",
              })
                .toLocal()
                .toFormat("MM/dd/yy")}
            </Typography>
          </Box>
        ) : null}
        {overview.platformPresence ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <img
                alt="Trade Engage logo"
                src={"/icon.svg"}
                style={{ height: "20px" }}
              />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Platform Presence
              </Typography>
            </Box>
            <Chip
              sx={{
                color:
                  overview.platformPresence === PLATFORM_PRESENCE.OFF_PLATFORM
                    ? "none"
                    : "#16B364",
                bgcolor:
                  overview.platformPresence === PLATFORM_PRESENCE.OFF_PLATFORM
                    ? "none"
                    : "#EDFCF2",
              }}
              label={
                <Typography variant="overline">
                  {snakeCaseToTitleCase(overview.platformPresence ?? "")}
                </Typography>
              }
              size="small"
            />
          </Box>
        ) : null}
        {overview.invitedByCompany ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="70%" display={"flex"} alignItems={"center"} gap={1}>
              <MarkEmailReadOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Invited By
              </Typography>
            </Box>
            <Typography fontSize={"12px"} fontWeight={500}>
              {overview.invitedByCompany?.name}
            </Typography>
          </Box>
        ) : null}
        <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
          <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
            <Link sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Invitation Code
            </Typography>
          </Box>
          <Typography fontSize={"12px"} fontWeight={500}>
            {overview.invitationCode}
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
          <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
            <Link sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Invitation Link
            </Typography>
          </Box>
          <Typography fontSize={"12px"} fontWeight={500}>
            {`${window.location.origin}?invitationCode=${overview.invitationCode}`}
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
          <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
            <Link sx={{ color: "text.secondary" }} />
            <Typography
              fontSize={"12px"}
              fontWeight={400}
              color="text.secondary"
            >
              Onboarding Link
            </Typography>
          </Box>
          <Typography fontSize={"12px"} fontWeight={500}>
            {overview.platformPresence === PLATFORM_PRESENCE.OFF_PLATFORM ? (
              `${window.location.origin}?invitedCompanyId=${overview.id}`
            ) : (
              <b>Company is already Onboarded</b>
            )}
          </Typography>
        </Box>
        {overview.onboardingStartedAt ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <WatchLaterOutlined sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Onboarding Started At
              </Typography>
            </Box>
            <Typography fontSize={"12px"} fontWeight={500}>
              {DateTime.fromISO(overview.onboardingStartedAt, {
                zone: "utc",
              })
                .toLocal()
                .toFormat("MM/dd/yy")}
            </Typography>
          </Box>
        ) : null}

        {campaigns
          ? campaigns.map(campaign => (
              <Box
                key={`campaign-${campaign.id}`}
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
                  <Link sx={{ color: "text.secondary" }} />
                  <Typography
                    fontSize={"12px"}
                    fontWeight={400}
                    color="text.secondary"
                  >
                    Campaign {snakeCaseJobSectors[campaign.sector]} Link
                  </Typography>
                </Box>
                <Typography fontSize={"12px"} fontWeight={500}>
                  {`${window.location.origin}/ads/signup/${campaign.code}`}
                </Typography>
              </Box>
            ))
          : null}
        {overview.showExplorePartners !== undefined ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <VerifiedOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Show Explore Partners
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              width="50%"
              sx={{ overflowWrap: "break-word" }}
            >
              {overview.showExplorePartners ? "Yes" : "No"}
            </Typography>
          </Box>
        ) : null}
        {overview.showJobAnalytics !== undefined ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <VerifiedOutlinedIcon sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Show Job Analytics
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              width="50%"
              sx={{ overflowWrap: "break-word" }}
            >
              {overview.showJobAnalytics ? "Yes" : "No"}
            </Typography>
          </Box>
        ) : null}
        {overview.callAnalyticsEnabled !== undefined ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <PhoneOutlined sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Call Analytics Enabled
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              width="50%"
              sx={{ overflowWrap: "break-word" }}
            >
              {overview.callAnalyticsEnabled ? "Yes" : "No"}
            </Typography>
          </Box>
        ) : null}
        {overview.homeownerJobApprovalSettings !== undefined ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <HomeOutlined sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Homeowner Job Approval Settings
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              width="50%"
              sx={{ overflowWrap: "break-word" }}
            >
              {snakeCaseToTitleCase(overview.homeownerJobApprovalSettings)}
            </Typography>
          </Box>
        ) : null}
        {overview.payReferralFeeSettings !== undefined ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <PaidOutlined sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Pay Referral Fee Settings
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              width="50%"
              sx={{ overflowWrap: "break-word" }}
            >
              {snakeCaseToTitleCase(overview.payReferralFeeSettings)}
            </Typography>
          </Box>
        ) : null}
        {overview.invitationMessage ? (
          <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
            <Box width="50%" display={"flex"} alignItems={"center"} gap={1}>
              <EmailOutlined sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={"12px"}
                fontWeight={400}
                color="text.secondary"
              >
                Invitation Message
              </Typography>
            </Box>
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              width="50%"
              sx={{ overflowWrap: "break-word" }}
            >
              {overview.invitationMessage}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </>
  );
};
