import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { CallRecording } from "./useFetchCallRecordings";

export const GET_CALL_RECORDING_NOTIFICATONS =
  "/call-center-notifications/unread";

export enum CallRecordingStatus {
  NEW = "NEW",
  NOTIFIED = "NOTIFIED",
}

export enum CallType {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
}

export interface CallRecordingNotification {
  id: number;
  userId: number;
  callRecordingId: number;
  createdAt: string;
  callRecording?: CallRecording;
  status: CallRecordingStatus;
  csrName?: string;
}

export function useFetchCompanyCallRecordingNotificationList(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<CallRecordingNotification>> {
  return useQuery({
    queryKey: [GET_CALL_RECORDING_NOTIFICATONS],
    queryFn: async () => {
      const response = await API.get(`${GET_CALL_RECORDING_NOTIFICATONS}`);
      return response.data.data;
    },
    ...options,
  });
}
