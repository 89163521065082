import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { useAuth } from "@/auth/context/jwt";

import CheckboxList from "./CheckboxList";
import ServiceCategoryCheckboxList from "./ServiceCategoryCheckboxList";
import { useInternalCompanySearchFilterContext } from "./useInternalCompanySearchFilter";

export default function OtherCriteriaSearchList() {
  const { session } = useAuth();
  const company = session?.company;

  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const { addFilter, removeFilter, filters } =
    useInternalCompanySearchFilterContext();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
      p={2}
      sx={{
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
        background: "#FFF",
      }}
    >
      <Box display={"flex"} gap={2} alignItems={"center"} pb={1}>
        <FilterAltOutlinedIcon />
        <Typography fontSize={"14px"} fontWeight={600}>
          Other Criteria
        </Typography>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box display={"flex"} width={"100%"} gap={2} mt={1}>
        <Box display={"flex"} width={"50%"} flexDirection={"column"}>
          <Box>
            <Typography variant="overline" color="text.secondary">
              Software and Operating System
            </Typography>
          </Box>
          <Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              {open ? (
                <ExpandLess onClick={() => setOpen(false)} />
              ) : (
                <ExpandMore onClick={() => setOpen(true)} />
              )}
              <FormControlLabel
                label={
                  <Typography variant="subtitle2" color="text.secondary">
                    All
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    sx={{ width: "20px", height: "20px", p: 0, m: 1 }}
                    checked={checked}
                    onChange={() =>
                      setChecked(checked => {
                        return !checked;
                      })
                    }
                  />
                }
              />
            </Box>
            <Collapse unmountOnExit in={open} timeout="auto">
              <Box pl={6}>
                <ServiceCategoryCheckboxList isAllChecked={checked} />
              </Box>
            </Collapse>
          </Box>
        </Box>
        <Box display={"flex"} width={"50%"} flexDirection={"column"}>
          <Box>
            <Typography variant="overline" color="text.secondary">
              More Filters
            </Typography>
          </Box>
          <CheckboxList
            parent={"Brick & Mortar Location"}
            parentChecked={!!filters.brickAndMortarLocation}
            toggleParentChecked={() => {
              if (filters.brickAndMortarLocation) {
                removeFilter("brickAndMortarLocation");
              } else {
                addFilter({
                  brickAndMortarLocation: true,
                });
              }
            }}
          />
          <CheckboxList
            parent={"Contact Information"}
            checklist={[
              "Owner Mobile Number Verified",
              "Owner Email Address Verified",
            ]}
            parentChecked={!!filters.contactInformation}
            toggleParentChecked={() => {
              if (filters.contactInformation) {
                removeFilter("contactInformation");
              } else {
                addFilter({
                  contactInformation: [
                    "Owner Mobile Number Verified",
                    "Owner Email Address Verified",
                  ],
                });
              }
            }}
            setSelectedList={list => {
              addFilter({
                contactInformation: list,
              });
            }}
            selectedList={filters.contactInformation}
          />
          <CheckboxList
            parent={"Details Technology Stack"}
            parentChecked={!!filters.detailsTechnologyStack}
            toggleParentChecked={() => {
              if (filters.detailsTechnologyStack) {
                removeFilter("detailsTechnologyStack");
              } else {
                addFilter({
                  detailsTechnologyStack: true,
                });
              }
            }}
          />
          <CheckboxList
            parent={"Marketplace Presence"}
            checklist={[
              "Angi",
              "Google Business Profile",
              "Local Search & PPC",
              "Thumbtack",
              "Unknown",
            ]}
            parentChecked={!!filters.marketPresence}
            toggleParentChecked={() => {
              if (filters.marketPresence) {
                removeFilter("marketPresence");
              } else {
                addFilter({
                  marketPresence: [
                    "Angi",
                    "Google Business Profile",
                    "Local Search & PPC",
                    "Thumbtack",
                    "Unknown",
                  ],
                });
              }
            }}
            setSelectedList={list => {
              addFilter({
                marketPresence: list,
              });
            }}
            selectedList={filters.marketPresence}
          />
          <CheckboxList
            parent={"Online Scheduling"}
            parentChecked={!!filters.onlineScheduling}
            toggleParentChecked={() => {
              if (filters.onlineScheduling) {
                removeFilter("onlineScheduling");
              } else {
                addFilter({
                  onlineScheduling: true,
                });
              }
            }}
          />
          <CheckboxList
            parent={"Website"}
            parentChecked={!!filters.website}
            toggleParentChecked={() => {
              if (filters.website) {
                removeFilter("website");
              } else {
                addFilter({
                  website: true,
                });
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
