import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { SyntheticEvent } from "react";

import { JobSectors } from "@/company/jobs/constants";
import { snakeCaseJobSectors } from "@/company/utils";

export interface SectorsMultiSelectDropDownProps {
  setSectors: (newSectors: JobSectors[]) => void;
  disabled?: boolean;
  required?: boolean;
  options?: Array<JobSectors>;
}
export function SectorsMultiSelectDropDown(
  props: SectorsMultiSelectDropDownProps
) {
  return (
    <Autocomplete
      multiple
      id="sectors"
      disabled={props.disabled}
      sx={{ m: 0 }}
      options={(props.options as Array<string>) ?? Object.keys(JobSectors)}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          required={props.required}
          margin="normal"
          InputLabelProps={{
            style: { marginBottom: "8px" },
          }}
          label="Select Service Categories"
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} key={option} value={option}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            {
              <Typography>
                {snakeCaseJobSectors[option.toString() as JobSectors]}
              </Typography>
            }
            {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
          </Box>
        </MenuItem>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            size="small"
            label={snakeCaseJobSectors[option.toString() as JobSectors]}
            {...getTagProps({ index })}
            variant="outlined"
          />
        ))
      }
      onChange={(_: SyntheticEvent, newValue: string[] | null) =>
        props.setSectors(newValue ? (newValue as JobSectors[]) : [])
      }
    />
  );
}
