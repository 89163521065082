import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";
import { CompanyTransaction } from "@/company/types";

export const USE_GET_COMPANY_TRANSACTIONS_URI =
  "/internal-admin/companies/:companyId/transactions";

export function useGetCompanyTransactionsById(
  companyId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<CompanyTransaction>> {
  return useQuery({
    queryKey: [
      USE_GET_COMPANY_TRANSACTIONS_URI.replace(
        ":companyId",
        companyId.toString()
      ),
    ],
    queryFn: async () => {
      const response = await API.get(
        USE_GET_COMPANY_TRANSACTIONS_URI.replace(
          ":companyId",
          companyId.toString()
        )
      );
      return response.data.data;
    },
    ...options,
  });
}
