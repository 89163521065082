import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

const URL =
  "/internal-admin/:companyId/users/:userId/retrigger-onboarding-invitation";

export function useResendUserInviteAdmin(
  options: UseMutationOptions<
    void,
    AxiosError,
    {
      companyId: number;
      userId: number;
    }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  {
    companyId: number;
    userId: number;
  }
> {
  return useMutation({
    mutationFn: async ({
      companyId,
      userId,
    }: {
      companyId: number;
      userId: number;
    }) => {
      const url = URL.replace(":userId", userId.toString()).replace(
        ":companyId",
        companyId.toString()
      );
      return await API.post(url);
    },
    ...options,
  });
}
