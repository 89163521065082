import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

import { Home } from "../types";

export const USE_GET_MY_HOMES_URI = "/homeowner/homes";

export function useGetMyHomes(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<Home>> {
  return useQuery({
    queryKey: [USE_GET_MY_HOMES_URI],
    queryFn: async () => {
      const response = await API.get(USE_GET_MY_HOMES_URI);
      return response.data.data;
    },
    ...options,
  });
}
