import { Box, Card, Typography } from "@mui/material";
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";

import { CompanyProfileDrawer } from "@/company/jobs/components/CompanyProfileDrawer";
import {
  CustomToolbar,
  getGridStringOperatorsDataGrid,
} from "@/internal-admin/utils";

import { HubCompanySummarySchema } from "../api/useGetHubCompanySummary";

export default function HubCompanySummaryTable({
  companySummaries,
  columns,
  title,
}: {
  companySummaries: Array<HubCompanySummarySchema>;
  columns: GridColDef[];
  title: string;
}) {
  const [rows, setRows] = useState([]);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [companyId, setCompanyId] = useState();

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleCellClick: GridEventListener<"cellClick"> = (
    params: GridCellParams<any>
  ) => {
    if (params.field == "company") {
      setCompanyId(params.row.id);
      return;
    }
  };
  useEffect(() => {
    setRows(companySummaries);
  }, [companySummaries]);

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  return (
    <Card
      sx={{
        position: "relative",
        p: 2,
        boxShadow: "0px 0px 0px 1px #F2F4F7",
      }}
    >
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"column"}
        sx={{ p: 0 }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
          p={1}
          mb={2}
        >
          <Typography variant="h6">{title}</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Jobs listed in this table include all jobs that are sent and
            received by participants in this group.
          </Typography>
        </Box>
        <Box
          sx={{
            //   position: "relative",
            height: rows?.length < 5 ? "300px" : "450px",
            width: "100%",
            overflow: "auto",
          }}
        >
          <DataGridPremium
            checkboxSelection
            disableRowSelectionOnClick
            cellSelection
            pagination
            columns={columns}
            sx={{
              "& .MuiDataGrid-row": {
                "--rowBorderColor": "#D9D9D9",
              },
              "& .MuiDataGrid-main": {
                minHeight: "100px !important",
              },
              "& .MuiDataGrid-cell--withRightBorder": {
                borderRightColor: "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withLeftBorder": {
                borderLeftColor: "#D9D9D9",
              },
            }}
            rows={rows ?? []}
            slots={{
              toolbar: () => <CustomToolbar fileName="Jobs" />,
            }}
            editMode="row"
            rowModesModel={rowModesModel}
            initialState={{
              density: "compact",
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            slotProps={{
              toolbar: { setRowModesModel },
            }}
            onCellClick={handleCellClick}
            onRowModesModelChange={handleRowModesModelChange}
          />
        </Box>
      </Box>
      {companyId ? (
        <CompanyProfileDrawer
          open={!!companyId}
          companyId={companyId}
          onClose={() => setCompanyId(undefined)}
        />
      ) : null}
    </Card>
  );
}
