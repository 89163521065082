import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { trackEvent } from "@/common/analytics";
import { AnalyticsEvents } from "@/common/analytics/enums";
import CreateTechnicianJobDialogWrapper from "@/common/CreateJobDialog/CreateTechnicianJobDialogWrapper";
import { useJobUploadContext } from "@/common/job-upload-context";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useCompanyPaymentsContext } from "@/company/context/payments-context";
import { CompanyRoutes } from "@/company/types";

import { BalanceCard } from "./BalanceCard";
import { BuyCreditsComponent } from "./BuyCreditsComponent";
import { CompanyCardDetailComponent } from "./CompanyCardDetailComponent";
import { CompanyTransactionCard } from "./CompanyTransactionCard";
import { ConfirmPurchaseDialog } from "./ConfirmPurchaseDialog";

export function CompanyPaymentsTab() {
  const { enqueueSnackbar } = useSnackbar();
  const { session, updateCompanyDetails } = useAuth();

  const [searchParams] = useSearchParams();
  const showCardParam = searchParams.get("showCard");
  const showCreditModalParam = searchParams.get("showCreditModal");

  const {
    transactions,
    isFetchingTransactions,
    buyCredits,
    isBuyingCredits,
    refetchTransactions,
    isFetchingCard,
    card,
  } = useCompanyPaymentsContext();

  const navigate = useNavigate();
  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);
  const { setJobUploadOpen } = useJobUploadContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [creditsToBuy, setCreditsToBuy] = useState(300);
  const [buyCreditsModalOpen, setBuyCreditsModalOpen] = useState(false);
  const [showCardDialog, setShowCardDialog] = useState(false);

  const onClickBuyCredits = () => {
    if (!card) {
      enqueueSnackbar({
        message: "Please add a card in order to buy credits!",
        variant: "info",
      });
      return;
    }
    setBuyCreditsModalOpen(true);
    trackEvent(AnalyticsEvents.CLICKED_ON_BUY_CREDITS, { creditsToBuy });
  };

  // To open add card modal from search param
  useEffect(() => {
    if (showCardParam) {
      setShowCardDialog(true);
    }
  }, [showCardParam]);

  // To open credit card modal from search param
  useEffect(() => {
    if (showCreditModalParam && !isFetchingCard && !card) {
      setShowCardDialog(true);
      return;
    }
    if (showCreditModalParam && !isFetchingCard && card) {
      setBuyCreditsModalOpen(true);
      return;
    }
  }, [showCreditModalParam, card, isFetchingCard]);

  return (
    <>
      <Grid container spacing={[0, 0, 0, 5]} justifyContent="space-between">
        <Grid container item xs={12} lg={6.5} spacing={2}>
          <Grid item xs={12} lg={5} sx={{ my: 2 }}>
            <BalanceCard balance={session?.company?.balance ?? "0.00"} />
          </Grid>
          <Grid item xs={12} lg={7} sx={{ my: 2 }}>
            <Card
              sx={{
                width: "100%",
                borderRadius: "20px",
                border: "1px solid #F3F4F6",
              }}
              elevation={0}
            >
              <BuyCreditsComponent
                creditsToBuy={creditsToBuy}
                setCreditsToBuy={setCreditsToBuy}
                isBuyingCredits={isBuyingCredits}
                isFetchingCard={isFetchingCard}
                onClickBuyCredits={onClickBuyCredits}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12} sx={{ mb: 2 }}>
            <Card
              sx={{ p: 2, borderRadius: "20px", border: "1px solid #F3F4F6" }}
              elevation={0}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Transaction History</Typography>
                <ToolTip
                  message="View your previous transactions on the platform here. If you've just made or received a payment, it may take a few hours to appear."
                  placement="top"
                >
                  <HelpOutlineIcon
                    sx={{
                      background: "none",
                      color: "#6C737F",
                      width: "20px",
                      height: "20px",
                    }}
                    fontSize="small"
                  />
                </ToolTip>
              </Box>
              <Divider sx={{ width: "100%", my: 2 }} />
              {isFetchingTransactions ? (
                <Box my={3} textAlign="center">
                  <CircularProgress />
                </Box>
              ) : transactions && transactions.length > 0 ? (
                transactions.map(transaction => (
                  <>
                    <CompanyTransactionCard
                      key={transaction.id}
                      transaction={transaction}
                    />
                    <Divider sx={{ width: "100%", my: 2 }} />
                  </>
                ))
              ) : (
                <Box textAlign="center">
                  <img
                    src="/empty-list.svg"
                    height="100%"
                    style={{ margin: "16px" }}
                    alt="No Transactions Yet"
                  />
                  <Typography gutterBottom variant="h6">
                    No Transactions to Show
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Refer a job to earn referral commission!
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ my: 2 }}
                    disabled={!session?.groups?.some(isAdministratorOrOwner)}
                    onClick={() => {
                      if (isMobile) {
                        setIsJobDialogOpen(true);
                        return;
                      }
                      setIsJobDialogOpen(true);
                    }}
                  >
                    Refer Job
                  </Button>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5.5} sx={{ my: 2 }}>
          <CompanyCardDetailComponent
            setShowCardDialog={setShowCardDialog}
            showCardDialog={showCardDialog}
          />
        </Grid>
      </Grid>
      <ConfirmPurchaseDialog
        creditsToBuy={creditsToBuy}
        open={buyCreditsModalOpen}
        isBuyingCredits={isBuyingCredits}
        card={card}
        onClickClose={() => setBuyCreditsModalOpen(false)}
        onClickCancel={() => {
          trackEvent(AnalyticsEvents.CANCELLED_PURCHASE_ON_REVIEW, {
            creditsToBuy,
          });
          setBuyCreditsModalOpen(false);
        }}
        onClickChangeCard={() => {
          trackEvent(AnalyticsEvents.CHANGED_CARD_ON_PURCHASE_REVIEW, {
            creditsToBuy,
          });
          setBuyCreditsModalOpen(false);
          setShowCardDialog(true);
        }}
        onClickConfirm={() => {
          buyCredits(creditsToBuy, {
            onSuccess: newCompany => {
              updateCompanyDetails(newCompany);
              enqueueSnackbar({
                message: `$${creditsToBuy} has been added to your account!`,
                variant: "success",
              });
              setBuyCreditsModalOpen(false);
              refetchTransactions();
            },
            onError: () => {
              enqueueSnackbar({
                variant: "error",
                message: "Unable to buy credits at the moment.",
              });
            },
          });
        }}
      />
      {isJobDialogOpen ? (
        <CreateTechnicianJobDialogWrapper
          isOpen={isJobDialogOpen}
          jobsListUrl={getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.MY_JOBS,
          ])}
          onClose={() => setIsJobDialogOpen(false)}
        />
      ) : null}
    </>
  );
}
