import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

const URL = "/internal-admin/jobs/:jobId/invitations";

export function useSendJobInvitations(
  options: UseMutationOptions<
    void,
    AxiosError,
    { jobId: number; companyIds: Array<number> }
  > = {}
): UseMutationResult<
  void,
  AxiosError,
  { jobId: number; companyIds: Array<number> }
> {
  return useMutation({
    mutationFn: async ({ jobId, companyIds }) => {
      const url = URL.replace(":jobId", jobId.toString());
      return await API.post(url, { company_ids: companyIds });
    },
    ...options,
  });
}
