import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import type { FC } from "react";

import { useAuth } from "@/auth/context/jwt";
import { usePopover } from "@/common/hooks/use-popover";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";

interface MoreMenuProps {
  onViewProfile: () => void;
  onAcceptInvitation?: () => void;
}

export const MoreMenuInvitation: FC<MoreMenuProps> = ({
  onViewProfile,
  onAcceptInvitation,
}) => {
  const popover = usePopover<HTMLButtonElement>();
  const { session } = useAuth();

  return (
    <>
      <ToolTip message="More options">
        <IconButton
          ref={popover.anchorRef}
          sx={{ py: 1, px: 0.5 }}
          onClick={popover.handleOpen}
        >
          <SvgIcon>
            <MoreVertIcon />
          </SvgIcon>
        </IconButton>
      </ToolTip>
      <Menu
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        open={popover.open}
        PaperProps={{
          sx: {
            maxWidth: "100%",
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        onClose={popover.handleClose}
      >
        <MenuItem
          onClick={() => {
            onViewProfile();
            popover.handleClose();
          }}
        >
          <ListItemIcon>
            <SvgIcon>
              <HomeWorkOutlinedIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="View Profile" />
        </MenuItem>
        {onAcceptInvitation && session?.groups?.some(isAdministratorOrOwner) ? (
          <MenuItem
            onClick={() => {
              onAcceptInvitation();
              popover.handleClose();
            }}
          >
            <ListItemIcon>
              <SvgIcon>
                <PersonAddAltOutlinedIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Accept Invitation" />
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};
