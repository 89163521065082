import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";

import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { NumberInputField } from "@/lib/form-fields/NumberInputField";
import { UseModalState } from "@/lib/modals/useModalState";

import { useCreateJobTransaction } from "../api/useCreateJobTransaction";
import { JobTransactionType } from "../types";

interface AddJobTransactionFormProps {
  jobId: number;
  modalState: UseModalState;
  onSuccess: () => void;
}

const addJobTransactionFormSchema = yup.object().shape({
  amount: yup.number().min(1).max(200).required("Amount is required"),
});

type AddJobTransactionFormFields = yup.InferType<
  typeof addJobTransactionFormSchema
>;

export function AddJobTransactionForm(props: AddJobTransactionFormProps) {
  const { jobId, modalState, onSuccess } = props;
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutateAsync: createJobTransactionAsync,
    isLoading: isCreatingJobTransaction,
  } = useCreateJobTransaction({
    onSuccess: () => {
      onSuccess();
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while creating the job transaction";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const addJobTransactionFormMethods = useFormik<AddJobTransactionFormFields>({
    initialValues: {
      amount: 0,
    },
    validationSchema: addJobTransactionFormSchema,
    onSubmit: async data => {
      await createJobTransactionAsync({
        jobId,
        transactionType: JobTransactionType.REFERRAL_FEE_EARNED,
        amount: data.amount,
      });
      addJobTransactionFormMethods.resetForm();
    },
  });

  return (
    <Dialog
      fullWidth
      open={modalState.modalIsOpen}
      maxWidth="sm"
      onClose={modalState.closeModal}
    >
      <DialogTitle component="div" sx={{ px: 2 }}>
        <Typography gutterBottom variant="h5">
          Add Job Transaction
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={modalState.closeModal}
      >
        <CloseIcon />
      </IconButton>
      <FormikProvider value={addJobTransactionFormMethods}>
        <DialogContent dividers>
          <Box component={"form"}>
            <Stack spacing={1}>
              <NumberInputField
                fullWidth
                required
                name="amount"
                label="Amount"
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isCreatingJobTransaction}
            variant="contained"
            color="primary"
            disabled={
              !addJobTransactionFormMethods.isValid || isCreatingJobTransaction
            }
            onClick={() => addJobTransactionFormMethods.handleSubmit()}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </FormikProvider>
    </Dialog>
  );
}
