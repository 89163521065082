import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import { FC } from "react";

import { useAuth } from "@/auth/context/jwt";
import { AppConstants } from "@/common/constants";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import { snakeCaseJobSectors } from "@/company/utils";

export const NetworkInvite: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;
  const user = session?.user;

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id, {
      refetchOnWindowFocus: false,
      enabled: !!company,
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
    ...(relevanceOrderedJobSectorsList ?? []),
  ];

  const invitationLink = company?.invitationLink
    ? `${company.invitationLink}?`
    : `${AppConstants.platformBaseUrl}?invitationCode=${company?.invitationCode}&`;
  const link = `${invitationLink}utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=referral_invite_share`;
  const linkEmail = `${link}&utm_medium=email`;
  const linkSms = `${link}&utm_medium=sms`;

  const handlMailClick = () => {
    const emailSubject = encodeURIComponent(
      "Join Our Referral Network on TradeEngage!"
    );
    const emailBody = encodeURIComponent(
      `Hi\nThis is ${user?.firstName} from ${
        company?.name
      }. I would like to add you as a referral partner on TradeEngage, the referral management platform exclusively for the home services industry.\n My team will refer you  ${relevanceOrderedJobSectorsList?.reduce(
        (acc, sector, index) => {
          if (index === 0) {
            return snakeCaseJobSectors[sector];
          } else if (index === recommendedJobSectorsList.length - 1) {
            return acc + " and " + snakeCaseJobSectors[sector];
          } else {
            return acc + ", " + snakeCaseJobSectors[sector];
          }
        },
        ""
      )} jobs. We'll also pay you for any jobs you send to us.\nTradeEngage ensures you get more validated jobs and handles the referral fees, reducing administrative burdens.\nClick ${linkEmail} to sign up, view our company profile and ACCEPT our referral partnership. Act fast, the link expires in 24 hours!\nLooking forward to our partnership.\nBest,`
    );
    const mailtoLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
    window.location.href = mailtoLink;
  };

  const handlSMSClick = () => {
    const smsBody = encodeURIComponent(
      `Hi\nThis is ${user?.firstName} from ${
        company?.name
      }. I would like to add you as a referral partner on TradeEngage, the referral management platform exclusively for the home services industry.\n ${
        recommendedJobSectorsList?.length
          ? `My team will refer you  ${recommendedJobSectorsList?.reduce(
              (acc, sector, index) => {
                if (index === 0) {
                  return snakeCaseJobSectors[sector];
                } else if (index === recommendedJobSectorsList.length - 1) {
                  return acc + " and " + snakeCaseJobSectors[sector];
                } else {
                  return acc + ", " + snakeCaseJobSectors[sector];
                }
              },
              ""
            )} jobs.`
          : ""
      } We'll also pay you for any jobs you send to us.\nTradeEngage ensures you get more validated jobs and handles the referral fees, reducing administrative burdens.\nClick ${linkSms} to sign up, view our company profile and ACCEPT our referral partnership. Act fast, the link expires in 24 hours!\nLooking forward to our partnership.\nBest,`
    );
    if (navigator.userAgent.match(/Android/i)) {
      window.open(`sms:?&body=${smsBody}`, "_blank");
    }
    if (navigator.userAgent.match(/iPhone/i)) {
      window.open(`sms:?&body=${smsBody}`, "_blank");
    }
  };
  return (
    <Box
      py={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{ height: "397.33px" }}
    >
      <Box
        display={"flex"}
        width={"100%"}
        height={"100%"}
        sx={{
          gap: 2,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          disabled
          fullWidth
          hiddenLabel
          size="small"
          sx={{
            bgcolor: "neutral.100",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InsertLinkIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: false }}
          name="link"
          value={link}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ width: "fit-content" }}
          startIcon={<ContentCopyOutlinedIcon />}
          onClick={() => {
            copy(link);
            enqueueSnackbar({
              message: "Link copied!",
              variant: "success",
            });
          }}
        >
          Copy Link
        </Button>
      </Box>
    </Box>
  );
};
