import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback } from "react";

import { useAuth } from "@/auth/context/jwt";
import {
  CompanyExploreFilters,
  SearchedCompany,
  useQueryExploreCompanies,
} from "@/company/api/useExploreConnections";
import { useGetRelevanceOrderedJobSectorsList } from "@/company/api/useGetRelevanceOrderedJobSectorsList";
import {
  Company,
  CompanyPublicProfile,
  ConnectionStatus,
  Referral,
  ReferralInvitation,
} from "@/company/types";

import { TabsEnum } from "../../company-drawer/CompanyDrawer";
import { CompanyProfileCard } from "../../company-profile-card/CompanyProfileCard";

export const ExplorePartnerTabs = [
  { label: "Pending Invitations", filter: {} },
  { label: "Affiliate Companies", filter: {} },
  { label: "Companies You May Know", filter: {} },
  { label: "Explore", filters: {} },
];
export interface CompanyFiltersModal {
  initialValues: CompanyExploreFilters;
  applyFilters: (filters: CompanyExploreFilters) => void;
  variant: "permanent" | "temporary";
  closeFilters: () => void;
}

export const filterValidationSchema = {};

export function PrivateEquityNetwork({
  privateEquity,
  setSelectedCompany,
  setCurrentCompanyProfile,
  setSelectedTab,
  referralPartners,
  referralInvitations,
  isTabPanelSticky = false,
}: {
  privateEquity: Company;
  setSelectedCompany: (value: SearchedCompany | null) => void;
  setCurrentCompanyProfile: (value: CompanyPublicProfile | null) => void;
  setSelectedTab: (value: TabsEnum | null) => void;
  referralPartners?: Array<Referral>;
  referralInvitations?: Array<ReferralInvitation>;
  isTabPanelSticky?: boolean;
  showRecommendedPartnersOnly?: boolean;
}) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { session } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id, {
      refetchOnWindowFocus: false,
      enabled: !!company,
      retry: false,
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];
  const { isFetching: isFetchingLinkedCompanies, data: linkedCompanies } =
    useQueryExploreCompanies(
      { privateEquityNetwork: true },
      recommendedJobSectorsList?.length
        ? recommendedJobSectorsList
        : relevanceOrderedJobSectorsList,
      {
        refetchOnWindowFocus: false,
        enabled: !!(
          relevanceOrderedJobSectorsList || recommendedJobSectorsList
        ),
        retry: false,
      }
    );

  const interactedCompanies = linkedCompanies?.filter(
    company =>
      !referralPartners?.some(
        partner =>
          partner.otherCompanyProfile.id === company?.companyPublicProfile?.id
      )
  );

  const getConnectionStatus = useCallback(
    (currentCompany: CompanyPublicProfile): ConnectionStatus => {
      if (
        referralPartners?.some(
          referral =>
            referral.referredCompanyId === currentCompany?.id ||
            referral.referringCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.CONNECTED;

      if (
        referralInvitations?.some(
          invitation => invitation.invitedCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.PENDING;

      if (
        referralInvitations?.some(
          invitation => invitation.invitingCompanyId === currentCompany?.id
        )
      )
        return ConnectionStatus.INVITED;

      return ConnectionStatus.NOT_CONNECTED;
    },
    [referralInvitations, referralPartners]
  );

  return (
    <Grid
      container
      py={3}
      sx={{ ...(isTabPanelSticky && isMobile ? { mt: "112px" } : {}) }}
    >
      {isFetchingLinkedCompanies ? (
        <Box py={3} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {interactedCompanies?.length ? (
            <Grid container>
              {interactedCompanies && interactedCompanies.length > 0 ? (
                interactedCompanies.map((companyData, index) => (
                  <Grid key={index} item xs={12} sm={6} lg={4} p={2}>
                    <CompanyProfileCard
                      {...(companyData.companyPublicProfile
                        ? {
                            companyProfile: companyData.companyPublicProfile,
                            connectionStatus: getConnectionStatus(
                              companyData.companyPublicProfile
                            ),
                          }
                        : {})}
                      {...(companyData.googleCompanyProfile
                        ? {
                            googleCompanyProfile:
                              companyData.googleCompanyProfile,
                            hasMarkedInterest: !!companyData.hasMarkedInterest,
                          }
                        : {})}
                      showParentLogo={false}
                      parentNetworkCompany={privateEquity}
                      onOpen={({ tab, markInterest }) => {
                        if (tab && companyData.companyPublicProfile) {
                          setSelectedTab(tab);
                          setCurrentCompanyProfile(
                            companyData.companyPublicProfile ?? null
                          );
                        } else {
                          setSelectedCompany({
                            ...companyData,
                            hasMarkedInterest: markInterest,
                          });
                        }
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <Box textAlign="center" width="100%" py={3}>
                  <Typography variant="body1">
                    There are no companies matching your filters, please click
                    on the filters button above and update them.
                  </Typography>
                </Box>
              )}
            </Grid>
          ) : (
            <Box textAlign="center" width="100%" py={3}>
              <Typography variant="body1">
                There are no companies matching your filters, please click on
                the filters button above and update them.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Grid>
  );
}
