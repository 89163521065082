import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

const RE_ROUTE_JOB_URL = "/internal-admin/jobs/:id/re-route";

export function useReRouteJob(
  options: UseMutationOptions<
    null,
    AxiosError,
    { jobId: number; receivingCompanyId: number }
  > = {}
): UseMutationResult<
  null,
  AxiosError,
  { jobId: number; receivingCompanyId: number }
> {
  return useMutation({
    mutationFn: async ({ jobId, receivingCompanyId }) => {
      const url = RE_ROUTE_JOB_URL.replace(":id", jobId.toString());
      const data = { receivingCompanyId };
      await API.post(url, changeCaseKeys(data, "underscored"));
      return null;
    },
    ...options,
  });
}
