import { Box, Modal, Typography } from "@mui/material";

// Define additional styles for the SMS text

export const SMSModal = ({
  open,
  onClose,
  smsContent,
}: {
  open: boolean;
  onClose: () => void;
  smsContent: string;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 3,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f4f4f9",
            borderRadius: "8px",
            padding: "16px",
            marginBottom: "12px",
            fontFamily: '"Roboto", sans-serif',
            fontSize: "1rem",
            wordWrap: "break-word",
            textAlign: "left",
          }}
        >
          <Typography variant="body2">{smsContent}</Typography>
        </Box>
      </Box>
    </Modal>
  );
};
