import { GridFilterModel } from "@mui/x-data-grid-premium";
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import changeCaseKeys from "change-case-keys";
import zipcodes from "zipcodes";

import API from "@/common/api";
import { Sorting } from "@/company/api/useQueryCompanySearch";
import { reverseSnakeCaseJobSectors } from "@/company/utils";

import { BrandOverlapSearchFilters } from "../contexts/SearchFilterContext";

export function useQueryBrandLocationSearch(
  pageNumber: number,
  pageSize: number,
  filters?: BrandOverlapSearchFilters,
  sorting?: Sorting[],
  tableFilters?: GridFilterModel,
  familyOfBrandId?: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<any>> {
  // const uri = "/internal-admin/companies/search";
  const uri = "/enterprise/brands/overlap/brand-locations";
  const dataPayload = {};
  if (filters?.zipCodes) {
    dataPayload.zipCodes = filters?.zipCodes;
  }
  if (filters?.states) {
    dataPayload.states = filters?.states.map(
      state => zipcodes.states.full[state.toUpperCase()]
    );
  }
  if (filters?.brands) {
    dataPayload.myBrands = filters?.brands;
  }
  if (filters?.otherBrands) {
    dataPayload.otherBrands = filters?.otherBrands;
  }
  if (filters?.keyword) {
    dataPayload.keyword = filters?.keyword;
  }
  if (filters?.sectors) {
    dataPayload.sectors = filters?.sectors.map(
      sector => reverseSnakeCaseJobSectors[sector]
    );
  }
  if (filters?.overlappingDistance) {
    dataPayload.overlappingDistance = filters?.overlappingDistance;
  }
  dataPayload.familyOfBrandsId = familyOfBrandId;

  if (filters?.googleRating) {
    dataPayload.googleRating = filters?.googleRating;
  }
  if (filters?.numberOfGoogleReviews) {
    dataPayload.numberOfGoogleReviews = filters?.numberOfGoogleReviews;
  }

  return useQuery({
    queryKey: [uri.toString(), JSON.stringify(dataPayload)],
    queryFn: async () => {
      const response = await API.post(
        uri,
        changeCaseKeys(dataPayload, "underscored")
      );
      const camelCaseResponse = response.data.data;
      return camelCaseResponse;
    },
    ...options,
  });
}
