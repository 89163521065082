import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import type { ApexOptions } from "apexcharts";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";

import { ToolTip } from "@/common/tool-tip/ToolTip";

const useChartOptions = (): ApexOptions => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [theme.palette.primary.main],
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100],
      },
      type: "gradient",
    },
    grid: {
      show: false,
      padding: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
  };
};

interface AnalyticsStatsProps {
  action: ReactNode;
  title: string;
  value: string;
  toolTip?: string;
  disabled?: boolean;
}

export const AnalyticsStats: FC<AnalyticsStatsProps> = props => {
  const { value, title, toolTip, disabled } = props;

  return (
    <Card sx={{ background: "#F8F9FA", boxShadow: "none" }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{
          px: 3,
          py: 1.5,
        }}
      >
        <Box>
          <Box display={"flex"} gap={0.5} alignItems={"center"}>
            <Typography color="text.secondary" variant="body2">
              {title}
            </Typography>
            {toolTip ? (
              <ToolTip placement="bottom" message={toolTip}>
                <HelpOutlineIcon
                  sx={{
                    background: "none",
                    color: "#6C737F",
                    width: "20px",
                    height: "17px",
                  }}
                />
              </ToolTip>
            ) : null}
          </Box>
          <Typography
            sx={{ mt: 0.5, ...(disabled ? { color: "text.secondary" } : {}) }}
            variant={disabled ? "h6" : "h5"}
            fontWeight={disabled ? 500 : 700}
          >
            {value}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      {/* <CardActions sx={{ px: 3 }}>{action}</CardActions> */}
    </Card>
  );
};

AnalyticsStats.propTypes = {
  action: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
