import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { DashboardTitle } from "@/common/DashboardTitle";
import { GoogleCompanyDrawer } from "@/common/GoogleCompanyDrawer/GoogleCompanyDrawer";
import { SectorsMultiSelectDropDown } from "@/common/SectorsMultiSelectDropDown/SectorsMultiSelectDropDown";
import { Seo } from "@/common/Seo/Seo";
import { JobSectors } from "@/company/jobs/constants";
import { Job } from "@/company/jobs/types";
import { Company, CompanyPublicProfile } from "@/company/types";
import { useCreateServiceProvider } from "@/homeowner/api/useCreateServiceProvider";
import { useGetMyServiceProviders } from "@/homeowner/api/useGetMyServiceProviders";

import { ServiceProviderCard } from "../../ServiceProviderCard/ServiceProviderCard";

export function HomeownerServiceProvidersPage() {
  const {
    data,
    isFetching,
    error: isError,
  } = useGetMyServiceProviders({
    refetchOnWindowFocus: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [sectors, setSectors] = useState<Array<JobSectors>>([]);
  const [selectedGooglePlacesId, setSelectedGooglePlacesId] = useState<
    string | undefined
  >(undefined);

  const { companyId: referredCompanyId } = useParams();

  const [searchParams] = useSearchParams();
  const referringCompanyId = searchParams.get("referringCompanyId");
  const sharingUserId = searchParams.get("sharingUserId");

  const [selectedCompany, setSelectedCompany] = useState<{
    companyProfile: CompanyPublicProfile;
    lastJob: Job;
    referredByCompany: Company;
  } | null>(null);
  const { mutateAsync: createServiceProvider } = useCreateServiceProvider({
    onSuccess: response => {
      setSelectedCompany(response);
    },
  });

  const allSectors =
    data?.flatMap(company =>
      company.companyProfile.sectors.map(({ sector }) => sector)
    ) ?? [];
  const uniqueSectors = [...new Set(allSectors)].sort();

  if (isError) {
    enqueueSnackbar({
      message: "There was an error while fetching your service providers.",
      variant: "error",
    });
  }

  const filteredData =
    data && data.length > 0
      ? data.filter(item => {
          if (sectors.length > 0) {
            return sectors.some(sector =>
              item.companyProfile.sectors
                .map(({ sector }) => sector)
                .includes(sector)
            );
          }
          return true;
        })
      : [];

  useEffect(() => {
    //TODO:  Add endpoint to add as sp
    if (referredCompanyId && referringCompanyId && sharingUserId) {
      createServiceProvider({
        referredCompanyId,
        referringCompanyId,
        sharingUserId,
      });
    }
  }, [referredCompanyId, referringCompanyId, sharingUserId]);

  return (
    <>
      <Seo title="My Service Providers" />
      <Box>
        <DashboardTitle
          title="My Service Providers"
          subtitle="Easily find contacts of your preferred service providers and refer them to family and friends."
        />
        <Box>
          <SectorsMultiSelectDropDown
            disabled={isFetching}
            required={false}
            options={uniqueSectors}
            setSectors={setSectors}
          />
        </Box>
        <Box
          sx={{
            mt: { xs: 0, md: 2 },
            p: 2,
            pt: { xs: 1, md: 2 },
            pb: 4,
            px: { xs: 0, md: 2 },
            // backgroundColor: neutral[50],
            flexGrow: 1,
          }}
        >
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
          >
            {isFetching ? (
              <Box textAlign="center" mt={4} width="100%">
                <CircularProgress />
              </Box>
            ) : selectedCompany ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                sx={{ mt: 2, width: "100%", md: "350px" }}
              >
                <ServiceProviderCard
                  companyProfile={selectedCompany.companyProfile}
                  lastJob={selectedCompany.lastJob}
                  referredByCompany={selectedCompany.referredByCompany}
                  onClick={() =>
                    setSelectedGooglePlacesId(
                      selectedCompany.companyProfile?.profile.googlePlacesId
                    )
                  }
                />
              </Grid>
            ) : filteredData.length > 0 ? (
              filteredData.map(item => (
                <Grid
                  key={item.companyProfile.id}
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  sx={{ mt: 2, width: "100%", md: "350px" }}
                >
                  <ServiceProviderCard
                    companyProfile={item.companyProfile}
                    lastJob={item.lastJob}
                    referredByCompany={item.referredByCompany}
                    onClick={() =>
                      setSelectedGooglePlacesId(
                        item.companyProfile?.profile.googlePlacesId
                      )
                    }
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sx={{ mt: 4, textAlign: "center" }}>
                <img
                  src="/empty-list.svg"
                  style={{ margin: "24px" }}
                  alt="no service providers"
                />
                <Typography variant="h6">
                  No service providers found!
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <GoogleCompanyDrawer
        isOpen={Boolean(selectedGooglePlacesId)}
        googlePlacesId={selectedGooglePlacesId}
        onClose={() => setSelectedGooglePlacesId(undefined)}
      />
    </>
  );
}
