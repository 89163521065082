import {
  Box,
  Button,
  CircularProgress,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import Lottie from "react-lottie";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AuthRoutes } from "@/auth/constants";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useFetchRecommendedSectors } from "@/company/api/useFetchRecommendedSectors";
import {
  useQueryBrandLocationById,
  useQueryBrandsUnderFamilyBrand,
} from "@/company/onboarding/api";
import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { CompanyRoutes } from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";
import { useLeadContext } from "@/lead/context/lead-context";

import animationData from "./confetti-lottie.json";

export function CompanyIdentification() {
  const defaultOptions = {
    loop: 1,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { leadDetails: companyDetails } = useLeadContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");
  const campaignCode = searchParams.get("campaignCode");
  const referralInvitationId = searchParams.get("referralInvitationId");

  const [
    isFamilyBrandsCompanyLogoUrlImageLoadError,
    setIsFamilyBrandsCompanyLogoUrlImageLoadError,
  ] = useState(false);
  const [isCompanyLogoUrlImageLoadError, setIsCompanyLogoUrlImageLoadError] =
    useState(false);

  const [
    isBrandCompanyLogoUrlImageLoadError,
    setIsBrandCompanyLogoUrlImageLoadError,
  ] = useState(false);

  const randomRecommendedReferralPartnerCount =
    Math.floor(Math.random() * (30 - 15 + 1)) + 15;

  const { isLoading: isFetchingFddRecord, data: searchCompanyRecord } =
    useQueryBrandLocationById(
      {
        id: companyDetails?.id ?? "",
      },
      { enabled: !!companyDetails?.id }
    );

  const sectors = [...(companyDetails.sectors ?? [])];
  const [animationComplete, setAnimationComplete] = useState(false);

  const lastSector = sectors?.length > 1 ? sectors.pop() : null;

  const {
    isLoading: isFetchingBrandsUnderFamilyBrand,
    data: { count: totalParentBrandsCount } = {},
  } = useQueryBrandsUnderFamilyBrand(
    {
      familyBrandId: searchCompanyRecord?.familyOfBrands?.id,
      brandId: searchCompanyRecord?.brand?.id,
      zipCode: companyDetails?.zipCode,
    },
    !!searchCompanyRecord?.familyOfBrands?.name
  );

  const { isLoading: isFetchingRecommendedSectors, data: recommendedSectors } =
    useFetchRecommendedSectors(
      companyDetails.sectors ?? [],

      {
        refetchOnWindowFocus: false,
        enabled: !!companyDetails.sectors,
        retry: false,
      }
    );

  const isLoading =
    (isFetchingRecommendedSectors && companyDetails.sectors) ||
    (isFetchingFddRecord &&
      !!companyDetails?.zipCode &&
      !!companyDetails?.name) ||
    (searchCompanyRecord?.familyOfBrands?.name &&
      isFetchingBrandsUnderFamilyBrand);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={isMobile ? "start" : "center"}
      alignItems={"center"}
      p={2}
      minHeight={"calc(100vh - 205px)"}
      overflow={"auto"}
    >
      <Box position={"fixed"} px={3}>
        {isLoading || animationComplete ? null : (
          <Lottie
            eventListeners={[
              {
                eventName: "complete",
                callback: () => {
                  setAnimationComplete(true);
                },
              },
            ]}
            options={defaultOptions}
            isClickToPauseDisabled={false}
            height={400}
            width={400}
          />
        )}
      </Box>
      {isMobile ? (
        <Box
          display={"flex"}
          pb={4}
          pt={3}
          width="100%"
          justifyContent={"start"}
        >
          <TradeEngageLogo logotype={TradeEngageLogoTypes.LOGO_DARK} />
        </Box>
      ) : null}
      <Box
        maxWidth={"460px"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"start"}
        gap={3}
        px={isMobile ? 0 : 3}
      >
        {" "}
        {isLoading ? (
          <Box py={3} textAlign="center" width={"100%"}>
            <CircularProgress />
          </Box>
        ) : searchCompanyRecord?.familyOfBrands?.name ? (
          <>
            <Box>
              <Typography variant="subtitle1" fontWeight={400}>
                You&apos;re affiliated with{" "}
                <Typography
                  display="inline"
                  fontWeight="500"
                  variant="subtitle1"
                  sx={{ color: "#16B364" }}
                >
                  {searchCompanyRecord?.familyOfBrands?.name}.{" "}
                </Typography>
                Unlock the power of their network today!
              </Typography>
            </Box>
            <Box display={"flex"} width={"100%"} justifyContent={"center"}>
              {searchCompanyRecord?.familyOfBrands?.logoUrl &&
              !isFamilyBrandsCompanyLogoUrlImageLoadError ? (
                <img
                  src={searchCompanyRecord?.familyOfBrands?.logoUrl}
                  alt="brand logo"
                  style={{
                    maxWidth: isMobile ? "300px" : "460px",
                    maxHeight: "120px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  onError={() =>
                    setIsFamilyBrandsCompanyLogoUrlImageLoadError(true)
                  }
                />
              ) : null}
            </Box>
            {totalParentBrandsCount ? (
              <Typography variant="subtitle1" fontWeight="400">
                <Typography
                  display="inline"
                  variant="subtitle1"
                  sx={{ color: "#16B364" }}
                  fontWeight="500"
                >
                  {" "}
                  {searchCompanyRecord?.familyOfBrands?.name}{" "}
                </Typography>
                has{" "}
                <Typography
                  display="inline"
                  variant="subtitle1"
                  sx={{ color: "#16B364" }}
                  fontWeight="500"
                >
                  {totalParentBrandsCount} locations{" "}
                </Typography>
                in your area and they can be your referral partners today.
              </Typography>
            ) : null}
          </>
        ) : searchCompanyRecord?.brand?.name ? (
          <>
            <Box display={"flex"} width={"100%"} justifyContent={"center"}>
              {searchCompanyRecord?.brand?.logoUrl &&
              !isBrandCompanyLogoUrlImageLoadError ? (
                <img
                  src={searchCompanyRecord?.brand?.logoUrl}
                  alt="brand logo"
                  style={{
                    maxWidth: "460px",
                    maxHeight: "120px",
                    objectFit: "contain",
                  }}
                  onError={() => setIsBrandCompanyLogoUrlImageLoadError(true)}
                />
              ) : null}
            </Box>
            <Typography variant="subtitle1" fontWeight="400">
              It looks like you&apos;re part of the{" "}
              <Typography
                display="inline"
                sx={{ color: "#16B364" }}
                fontWeight="500"
                variant="subtitle1"
              >
                {searchCompanyRecord?.brand?.name}{" "}
              </Typography>
              brand. Unlock the power of their brand today!
            </Typography>
          </>
        ) : (
          <>
            <Box display={"flex"} width={"100%"} justifyContent={"center"}>
              {searchCompanyRecord?.logoUrl &&
              !isCompanyLogoUrlImageLoadError ? (
                <img
                  src={searchCompanyRecord?.logoUrl}
                  alt="brand logo"
                  style={{
                    maxWidth: "460px",
                    maxHeight: "120px",
                    objectFit: "contain",
                  }}
                  onError={() => setIsCompanyLogoUrlImageLoadError(true)}
                />
              ) : null}
            </Box>
            <Typography variant="h4">We found your company!</Typography>
          </>
        )}
        {!isLoading ? (
          <Typography variant="subtitle1" fontWeight="400">
            Sign up now to connect with over{" "}
            <Typography
              display="inline"
              sx={{ color: "#16B364" }}
              fontWeight="500"
              variant="subtitle1"
            >
              {randomRecommendedReferralPartnerCount}{" "}
              {recommendedSectors?.[0]
                ? snakeCaseJobSectors[recommendedSectors?.[0]]
                : ""}{" "}
            </Typography>
            providers and other relevant home services businesses that give{" "}
            <Typography
              display="inline"
              variant="subtitle1"
              sx={{ color: "#16B364" }}
              fontWeight="500"
            >
              {sectors?.map(sector => snakeCaseJobSectors[sector]).join(", ")}{" "}
            </Typography>
            {lastSector ? (
              <>
                {" "}
                and{" "}
                <Typography
                  display="inline"
                  sx={{ color: "#16B364" }}
                  fontWeight="500"
                  variant="subtitle1"
                >
                  {snakeCaseJobSectors[lastSector]}{" "}
                </Typography>
              </>
            ) : null}
            referrals.
          </Typography>
        ) : null}
        <Button
          color="primary"
          sx={{ width: "100%" }}
          variant="contained"
          disabled={!!isLoading}
          onClick={() => {
            if (
              searchCompanyRecord?.familyOfBrands?.name ||
              searchCompanyRecord?.brand?.name
            ) {
              navigate(
                `${getNavigationPathFromRoutePaths([
                  AuthRoutes.BASE,
                  AuthRoutes.SIGN_UP,
                ])}?redirectTo=/${CompanyRoutes.BASE}/${
                  ONBOARDING_ROUTES.BASE
                }/${ONBOARDING_STAGES.SERVICE_DETAILS}${
                  invitationCode ? `&invitationCode=${invitationCode}` : ""
                }${campaignCode ? `&campaignCode=${campaignCode}` : ""}${
                  referralInvitationId
                    ? `&referralInvitationId=${referralInvitationId}`
                    : ""
                }`
              );
              return;
            }
            navigate(
              `${getNavigationPathFromRoutePaths([
                AuthRoutes.BASE,
                AuthRoutes.SIGN_UP,
              ])}?redirectTo=/${AuthRoutes.BASE}/${AuthRoutes.USER_TYPE}${
                invitationCode ? `&invitationCode=${invitationCode}` : ""
              }${campaignCode ? `&campaignCode=${campaignCode}` : ""}${
                referralInvitationId
                  ? `&referralInvitationId=${referralInvitationId}`
                  : ""
              }`
            );
          }}
        >
          Get Started For Free
        </Button>
      </Box>
      <Typography mt={3} variant="body2" color="text.secondary">
        Not a Company?{" "}
        <Link
          color="#16B364"
          rel="noreferrer"
          underline="none"
          sx={{
            cursor: "pointer",
            zIndex: 1000,
          }}
          variant="subtitle2"
          onClick={() => {
            navigate(
              `${getNavigationPathFromRoutePaths([
                AuthRoutes.BASE,
                AuthRoutes.SIGN_UP,
              ])}?redirectTo=/${AuthRoutes.BASE}/${AuthRoutes.USER_TYPE}${
                invitationCode ? `&invitationCode=${invitationCode}` : ""
              }${campaignCode ? `&campaignCode=${campaignCode}` : ""}`
            );
          }}
        >
          <Typography
            mt={4}
            display="inline"
            variant="subtitle2"
            color="#16B364"
          >
            Sign Up as a Homeowner
          </Typography>
        </Link>
      </Typography>
    </Box>
  );
}
