import { Box } from "@mui/material";
import { FC } from "react";
import QRCode from "react-qr-code";

import { useAuth } from "@/auth/context/jwt";
import { AppConstants } from "@/common/constants";

export const QRInvite: FC = () => {
  const { session } = useAuth();
  const company = session?.company;

  const invitationLink = company?.invitationLink
    ? `${company.invitationLink}?`
    : `${AppConstants.platformBaseUrl}?invitationCode=${company?.invitationCode}&`;
  const link = `${invitationLink}utm_source=${AppConstants.tradeEngageAppUtmId}&utm_campaign=referral_invite_share`;

  return (
    <Box
      py={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{ height: "397.33px" }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        p={4}
        gap={2}
        sx={{ height: "356.50px" }}
      >
        <QRCode
          style={{ height: "200px", maxWidth: "100%", width: "100%" }}
          value={link}
        />
      </Box>
    </Box>
  );
};
