import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import axiosInstance from "@/common/api";

import { RoutedJobResponse } from "../types/job";

export type UpdateJobRequest = {
  status: string;
  salePrice?: number;
  declinedReason?: string;
  inactiveReason?: string;
  completionDate?: string;
  referralFeePrice?: number;
};

export function useUpdateRoutedJobStatus(
  options: UseMutationOptions<
    RoutedJobResponse,
    AxiosError,
    { jobId: number; data: UpdateJobRequest }
  > = {}
): UseMutationResult<
  RoutedJobResponse,
  AxiosError,
  { jobId: number; data: UpdateJobRequest }
> {
  return useMutation({
    mutationFn: async ({ jobId, data }) => {
      const url = `job-routings/${jobId}`;
      const response = await axiosInstance.patch(
        url,
        changeCaseKeys(data, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
