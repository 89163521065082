import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { ToolTip } from "@/common/tool-tip/ToolTip";
import { BalanceCard } from "@/company/components/company-payments-tab/BalanceCard";
import { CompanyTransactionCard } from "@/company/components/company-payments-tab/CompanyTransactionCard";

import { useGetCompanyTransactionsById } from "./api/useGetCompanyTransactionsById";
import { CompanyCardDetails } from "./CompanyCardDetails";

export function PaymentsTab({
  expanded,
  companyId,
  balance,
}: {
  expanded: boolean;
  companyId: number;
  balance: string;
}) {
  const { isFetching: isFetchingTransactions, data: transactions } =
    useGetCompanyTransactionsById(companyId, {
      refetchOnWindowFocus: false,
    });

  const [showCardDialog, setShowCardDialog] = useState(false);

  return (
    <>
      <Grid container spacing={[0, 0, 0, 5]} justifyContent="space-between">
        <Grid container item xs={12} lg={12} spacing={2}>
          <Grid item xs={12} lg={expanded ? 5 : 12} sx={{ my: 2 }}>
            <BalanceCard balance={balance ?? "0.00"} />
          </Grid>
          <Grid item xs={12} lg={expanded ? 7 : 12} sx={{ my: 2 }}>
            <CompanyCardDetails
              setShowCardDialog={setShowCardDialog}
              showCardDialog={showCardDialog}
              disableAddCard={true}
              companyId={companyId}
            />
          </Grid>
          <Grid item xs={12} lg={12} sx={{ mb: 2 }}>
            <Card
              sx={{ p: 2, borderRadius: "20px", border: "1px solid #F3F4F6" }}
              elevation={0}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Transaction History</Typography>
                <ToolTip
                  message="You can view all your previous transactions on the platform here. If you've just made a payment, it may take a few hours for it to appear in the table below."
                  placement="top"
                >
                  <HelpOutlineIcon
                    sx={{
                      background: "none",
                      color: "#6C737F",
                      width: "20px",
                      height: "20px",
                    }}
                    fontSize="small"
                  />
                </ToolTip>
              </Box>
              <Divider sx={{ width: "100%", my: 2 }} />
              {isFetchingTransactions ? (
                <Box my={3} textAlign="center">
                  <CircularProgress />
                </Box>
              ) : transactions && transactions.length > 0 ? (
                transactions.map(transaction => (
                  <>
                    <CompanyTransactionCard
                      key={transaction.id}
                      transaction={transaction}
                    />
                    <Divider sx={{ width: "100%", my: 2 }} />
                  </>
                ))
              ) : (
                <Box textAlign="center">
                  <img
                    src="/empty-list.svg"
                    height="100%"
                    style={{ margin: "16px" }}
                    alt="No Transactions Yet"
                  />
                  <Typography gutterBottom variant="h6">
                    No Transactions Yet
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    You are one step closer to your first referral.
                  </Typography>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
