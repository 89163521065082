import {
  AutoGraphOutlined,
  BusinessOutlined,
  ExpandLess,
  ExpandMore,
  HandshakeOutlined,
  RocketLaunchOutlined,
  RsvpOutlined,
  VpnLockOutlined,
  WorkOutline,
} from "@mui/icons-material";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { Sidebar } from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import ProtectedRoute from "@/common/ProtectedRoutes";
import { Seo } from "@/common/Seo/Seo";
import { TopNav } from "@/common/top-nav";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { ONBOARDING_STAGES } from "@/company/onboarding/constants";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";
import { tradeEngageColors } from "@/Theme/colors";

export function AdminLayout() {
  const { pathname } = useLocation();

  const seoName = pathname.split("/").slice(-1).pop() as ONBOARDING_STAGES;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [collapsed, setCollapsed] = useState(false);
  const { session } = useAuth();
  const company = session?.company;

  const isFamilyOfBrands =
    company?.accountType === COMPANY_ACCOUNT_TYPES.FAMILY_BRANDS;

  const [collapsedList, setCollapsedList] = useState(true);

  const navigate = useNavigate();
  return (
    <>
      <Seo title={_.startCase(seoName.replaceAll("-", " "))} />
      <Grid container columns={16} height={isMobile ? "100%" : "100vh"}>
        <Box sx={{ display: "flex" }}>
          <Sidebar
            collapsed={collapsed}
            backgroundColor={tradeEngageColors.darkBlue}
            collapsedWidth="60px"
            width="300px"
          >
            <Box
              px={2}
              mt={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={collapsed ? "center" : "space-between"}
              gap={1}
            >
              {!collapsed ? (
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #B5BCC4",
                      background: "#FFF",
                      height: "40px",
                      width: "40px",
                    }}
                  >
                    {isFamilyOfBrands ? (
                      <CompanyAvatar
                        logo={company?.logoUrl}
                        name={company?.name ?? ""}
                        height={"40px"}
                        width={"40px"}
                        bgColor="white"
                        border="1px solid gray !important"
                        isAdminProfile={true}
                      />
                    ) : (
                      <TradeEngageLogo
                        height="40px"
                        width="40px"
                        logotype={TradeEngageLogoTypes.ICON_LIGHT}
                      />
                    )}
                  </Box>
                  <Typography variant="h6" color="white">
                    {isFamilyOfBrands ? company.name : "TradeEngage Admin"}
                  </Typography>
                </Box>
              ) : null}
              {collapsed ? (
                <LastPageOutlinedIcon
                  sx={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    setCollapsed(prev => !prev);
                  }}
                />
              ) : (
                <FirstPageOutlinedIcon
                  sx={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    setCollapsed(prev => !prev);
                  }}
                />
              )}
            </Box>
            {isFamilyOfBrands ? (
              <>
                {" "}
                {!collapsed ? (
                  <Box px={2} mt={2}>
                    <Typography variant="caption" color="#8A94A6">
                      BUSINESS INTELLIGENCE
                    </Typography>
                  </Box>
                ) : null}
                <List
                  sx={{ width: "100%", maxWidth: 360 }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton
                    onClick={() => {
                      navigate(`/admin/bi/analytics`);
                    }}
                  >
                    <ListItemIcon>
                      <AutoGraphOutlined
                        sx={{ color: "white" }}
                        fontSize="small"
                      />
                    </ListItemIcon>
                    {!collapsed ? (
                      <ListItemText>
                        <Typography variant="subtitle2" color="white">
                          Dashboards
                        </Typography>
                      </ListItemText>
                    ) : null}
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/admin/bi/brand-overlap`);
                    }}
                  >
                    <ListItemIcon>
                      <VpnLockOutlined
                        sx={{ color: "white" }}
                        fontSize="small"
                      />
                    </ListItemIcon>
                    {!collapsed ? (
                      <ListItemText>
                        <Typography variant="subtitle2" color="white">
                          Brand Overlap
                        </Typography>
                      </ListItemText>
                    ) : null}
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/admin/bi/strategic-partners`);
                    }}
                  >
                    <ListItemIcon>
                      <HandshakeOutlined
                        sx={{ color: "white" }}
                        fontSize="small"
                      />
                    </ListItemIcon>
                    {!collapsed ? (
                      <ListItemText>
                        <Typography variant="subtitle2" color="white">
                          {session?.company?.name} Permissions
                        </Typography>
                      </ListItemText>
                    ) : null}
                  </ListItemButton>
                </List>
                {!collapsed ? (
                  <Box px={2} mt={2}>
                    <Typography variant="caption" color="#8A94A6">
                      MARKETING
                    </Typography>
                  </Box>
                ) : null}
                <List
                  sx={{ width: "100%", maxWidth: 360 }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton
                    onClick={() => {
                      navigate(`/admin/marketing/referral-campaigns`);
                    }}
                  >
                    <ListItemIcon>
                      <RocketLaunchOutlined
                        sx={{ color: "white" }}
                        fontSize="small"
                      />
                    </ListItemIcon>
                    {!collapsed ? (
                      <ListItemText>
                        <Typography variant="subtitle2" color="white">
                          Referral Campaigns
                        </Typography>
                      </ListItemText>
                    ) : null}
                  </ListItemButton>
                </List>
              </>
            ) : (
              <>
                {" "}
                {!collapsed ? (
                  <Box px={2} mt={4}>
                    <Typography variant="caption" color="#8A94A6">
                      DATABASE
                    </Typography>
                  </Box>
                ) : null}
                <List
                  sx={{ width: "100%", maxWidth: 360 }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton
                    onClick={() => {
                      navigate(`/admin/users`);
                    }}
                  >
                    <ListItemIcon>
                      <GroupsOutlinedIcon
                        sx={{ color: "white" }}
                        fontSize="small"
                      />
                    </ListItemIcon>
                    {!collapsed ? (
                      <ListItemText>
                        <Typography variant="subtitle2" color="white">
                          Users
                        </Typography>
                      </ListItemText>
                    ) : null}
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/admin/referral-invitations`);
                    }}
                  >
                    <ListItemIcon>
                      <RsvpOutlined sx={{ color: "white" }} fontSize="small" />
                    </ListItemIcon>
                    {!collapsed ? (
                      <ListItemText>
                        <Typography variant="subtitle2" color="white">
                          Referral Invitations
                        </Typography>
                      </ListItemText>
                    ) : null}
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/admin/recommended-partners`);
                    }}
                  >
                    <ListItemIcon>
                      <HandshakeOutlined
                        sx={{ color: "white" }}
                        fontSize="small"
                      />
                    </ListItemIcon>
                    {!collapsed ? (
                      <ListItemText>
                        <Typography variant="subtitle2" color="white">
                          Recommended Partners
                        </Typography>
                      </ListItemText>
                    ) : null}
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/admin/jobs`);
                    }}
                  >
                    <ListItemIcon>
                      <WorkOutline sx={{ color: "white" }} fontSize="small" />
                    </ListItemIcon>
                    {!collapsed ? (
                      <ListItemText>
                        <Typography variant="subtitle2" color="white">
                          Jobs
                        </Typography>
                      </ListItemText>
                    ) : null}
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      setCollapsedList(prev => !prev);
                    }}
                  >
                    <ListItemIcon>
                      <BusinessOutlined
                        sx={{ color: "white" }}
                        fontSize="small"
                      />
                    </ListItemIcon>
                    {!collapsed ? (
                      <ListItemText>
                        <Typography variant="subtitle2" color="white">
                          Market Screeners
                        </Typography>
                      </ListItemText>
                    ) : null}
                    {collapsed ? null : collapsedList ? (
                      <ExpandLess sx={{ color: "white" }} />
                    ) : (
                      <ExpandMore sx={{ color: "white" }} />
                    )}
                  </ListItemButton>
                  <Collapse unmountOnExit in={collapsedList} timeout="auto">
                    <List disablePadding component="div">
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => {
                          navigate(`/admin/company`);
                        }}
                      >
                        {!collapsed ? (
                          <ListItemText>
                            <Typography variant="caption" color="white">
                              Local Companies and Franchise
                            </Typography>
                          </ListItemText>
                        ) : null}
                      </ListItemButton>
                    </List>
                    <List disablePadding component="div">
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => {
                          window.open(`/consultant/home`, "_blank");
                        }}
                      >
                        {!collapsed ? (
                          <ListItemText>
                            <Typography variant="caption" color="white">
                              Hub Dashboard
                            </Typography>
                          </ListItemText>
                        ) : null}
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </>
            )}
            {!collapsed ? (
              <Box px={2} mt={2}>
                <Typography variant="caption" color="#8A94A6">
                  ACCOUNT
                </Typography>
              </Box>
            ) : null}
            <List
              sx={{ width: "100%", maxWidth: 360 }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton
                onClick={() => {
                  navigate(`/admin/saved-searches`);
                }}
              >
                <ListItemIcon>
                  <BookmarkAddOutlinedIcon
                    sx={{ color: "white" }}
                    fontSize="small"
                  />
                </ListItemIcon>
                {!collapsed ? (
                  <ListItemText>
                    <Typography variant="subtitle2" color="white">
                      Saved Search List
                    </Typography>
                  </ListItemText>
                ) : null}
              </ListItemButton>
            </List>
          </Sidebar>
          <Box
            width={`calc(100vw - ${collapsed ? "60px" : "300px"})`}
            height={`calc(100vh - 60px)`}
            sx={{ transition: "width, left, right, 300ms;" }}
          >
            <TopNav accountButtons={[]} />

            <ProtectedRoute />
          </Box>
        </Box>
      </Grid>
    </>
  );
}
