import constate from "constate";
import { useState } from "react";

function useMarkedInterestsSet() {
  const [markedInterestSet, setMarkedInterestSet] = useState<Set<string>>(
    new Set()
  );

  const updateMarkedInterestSet = (googlePlacesId: string) => {
    if (markedInterestSet.has(googlePlacesId)) {
      return;
    }
    const newSet = new Set(markedInterestSet);
    newSet.add(googlePlacesId);
    setMarkedInterestSet(newSet);
  };

  return {
    markedInterestSet,
    updateMarkedInterestSet,
  };
}

export const [MarkedInterestSetProvider, useMarkedInterestSetContext] =
  constate(useMarkedInterestsSet);
