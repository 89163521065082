import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

export function useMarkChatsWithCompanyAsRead(
  options: UseMutationOptions<undefined, AxiosError, number> = {}
): UseMutationResult<undefined, AxiosError, number> {
  return useMutation({
    mutationFn: async (withCompanyId: number) => {
      const response = await API.patch(`chats/${withCompanyId}`);
      return response.data.data;
    },
    ...options,
  });
}
