import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import CheckIcon from "@mui/icons-material/Check";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { isCompanyActive } from "@/common/utils/accessControl";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import {
  ONBOARDING_ROUTES,
  ONBOARDING_STAGES,
} from "@/company/onboarding/constants";
import { CompanyProfileCompleteness, CompanyRoutes } from "@/company/types";

interface Props {
  profileCompleteness?: CompanyProfileCompleteness;
}

export const GettingStartedCheckList: FC<Props> = ({ profileCompleteness }) => {
  const navigate = useNavigate();
  const { session } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;

  const calculateStepsCompleted = () => {
    let stepsCompleted = 0;
    if (profileCompleteness?.companyProfileCompleted) stepsCompleted += 1;
    if (profileCompleteness?.referralPartnersCompleted) stepsCompleted += 1;
    if (profileCompleteness?.mobileVerificationCompleted) stepsCompleted += 1;
    if (profileCompleteness?.inviteTeamMembersCompleted) stepsCompleted += 1;
    if (profileCompleteness?.serviceDetailsCompleted) stepsCompleted += 1;

    return stepsCompleted;
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const isProfileComplete = profileCompleteness?.percentage === 100;

  return (
    <Card
      sx={{
        boxShadow: "0px 0px 0px 1px #F2F4F7;",
        p: 3,
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {!brandLocationProfile?.startedOnboardingAt ? (
        <Box
          display="flex"
          alignItems={"center"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Box
            display="flex"
            alignItems={"start"}
            flexDirection={"column"}
            gap={0.5}
          >
            <Typography variant="h6">Getting Started</Typography>
            <Typography variant="body2" color="text.secondary">
              {calculateStepsCompleted()}/5 completed
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => {
              navigate(
                `/${CompanyRoutes.BASE}/${ONBOARDING_ROUTES.BASE}/${ONBOARDING_STAGES.SERVICE_DETAILS}`
              );
            }}
          >
            Complete Onboarding
          </Button>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems={"center"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Getting Started</Typography>
          <Typography variant="body2" color="text.secondary">
            {calculateStepsCompleted()}/5 completed
          </Typography>
        </Box>
      )}
      <LinearProgress
        color="primary"
        variant="determinate"
        value={calculateStepsCompleted() * 20}
        sx={{ height: "8px" }}
      />
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 10 : 2}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={isMobile ? "start" : "center"}
            alignItems={"center"}
            sx={{
              opacity: profileCompleteness?.mobileVerificationCompleted
                ? 0.5
                : 1,
            }}
          >
            <IconButton
              aria-label="close"
              sx={{
                bgcolor: "#F8F9FA",
                borderRadius: "12px",
              }}
            >
              <PhonelinkLockIcon sx={{ color: "#6C737F" }} />
            </IconButton>
          </Box>
        </Grid>
        {isMobile ? (
          <Grid item xs={isMobile ? 2 : 5}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={isMobile ? "start" : "end"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Box
                sx={{
                  bgcolor: "#E5E7EB",
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#6C737F",
                }}
              >
                <CheckIcon />
              </Box>
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={isMobile ? 12 : 5}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"start"}
            flexDirection={"column"}
            sx={{
              opacity: profileCompleteness?.mobileVerificationCompleted
                ? 0.5
                : 1,
            }}
          >
            <Typography variant="subtitle2">Verify Mobile Number</Typography>
            <Typography variant="body2" color="text.secondary">
              Verification completed
            </Typography>
          </Box>
        </Grid>
        {isMobile ? null : (
          <Grid item xs={isMobile ? 2 : 5}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={isMobile ? "start" : "end"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Box
                sx={{
                  bgcolor: "#E5E7EB",
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#6C737F",
                }}
              >
                <CheckIcon />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      {isMobile ? <Divider /> : null}
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 10 : 2}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={isMobile ? "start" : "center"}
            alignItems={"center"}
            sx={{
              opacity: profileCompleteness?.serviceDetailsCompleted ? 0.5 : 1,
            }}
          >
            <IconButton
              aria-label="close"
              sx={{
                bgcolor: "#F8F9FA",
                borderRadius: "12px",
              }}
            >
              <MapOutlinedIcon sx={{ color: "#6C737F" }} />
            </IconButton>
          </Box>
        </Grid>
        {isMobile && profileCompleteness?.serviceDetailsCompleted ? (
          <Grid item xs={isMobile ? 2 : 5}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={isMobile ? "start" : "end"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Box
                sx={{
                  bgcolor: "#E5E7EB",
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#6C737F",
                }}
              >
                <CheckIcon />
              </Box>
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={isMobile ? 12 : 5}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            sx={{
              opacity: profileCompleteness?.serviceDetailsCompleted ? 0.5 : 1,
            }}
          >
            <Typography variant="subtitle2">Service Details</Typography>
            <Typography variant="body2" color="text.secondary">
              Select your service offerings and territory
            </Typography>
          </Box>
        </Grid>
        {!isMobile ||
        (!profileCompleteness?.serviceDetailsCompleted && isMobile) ? (
          <Grid item xs={isMobile ? 12 : 5}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={isMobile ? "start" : "end"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              {profileCompleteness?.serviceDetailsCompleted ? (
                <Box
                  sx={{
                    bgcolor: "#E5E7EB",
                    width: "40px",
                    height: "40px",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#6C737F",
                  }}
                >
                  <CheckIcon />
                </Box>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(
                      getNavigationPathFromRoutePaths([
                        CompanyRoutes.BASE,
                        CompanyRoutes.COMPANY_SETTINGS,
                      ])
                    );
                  }}
                >
                  Update Service Details
                </Button>
              )}
            </Box>
          </Grid>
        ) : null}
      </Grid>
      {isMobile ? <Divider /> : null}
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 10 : 2}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={isMobile ? "start" : "center"}
            alignItems={"center"}
            sx={{
              opacity: profileCompleteness?.companyProfileCompleted ? 0.5 : 1,
            }}
          >
            <IconButton
              aria-label="close"
              sx={{
                bgcolor: "#F8F9FA",
                borderRadius: "12px",
              }}
            >
              <StoreMallDirectoryOutlinedIcon sx={{ color: "#6C737F" }} />
            </IconButton>
          </Box>
        </Grid>
        {isMobile && profileCompleteness?.companyProfileCompleted ? (
          <Grid item xs={isMobile ? 2 : 5}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={isMobile ? "start" : "end"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Box
                sx={{
                  bgcolor: "#E5E7EB",
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#6C737F",
                }}
              >
                <CheckIcon />
              </Box>
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={isMobile ? 12 : 6}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            sx={{
              opacity: profileCompleteness?.companyProfileCompleted ? 0.5 : 1,
            }}
          >
            <Typography variant="subtitle2">Company Profile</Typography>
            <Typography variant="body2" color="text.secondary">
              Share more about your company to build trust in your network
            </Typography>
          </Box>
        </Grid>
        {!isMobile ||
        (!profileCompleteness?.companyProfileCompleted && isMobile) ? (
          <Grid item xs={isMobile ? 12 : 4}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={isMobile ? "start" : "end"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              {profileCompleteness?.companyProfileCompleted ? (
                <Box
                  sx={{
                    bgcolor: "#E5E7EB",
                    width: "40px",
                    height: "40px",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#6C737F",
                  }}
                >
                  <CheckIcon />
                </Box>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(
                      getNavigationPathFromRoutePaths([
                        CompanyRoutes.BASE,
                        CompanyRoutes.COMPANY_SETTINGS,
                      ])
                    );
                  }}
                >
                  Update Profile
                </Button>
              )}
            </Box>
          </Grid>
        ) : null}
      </Grid>
      {isMobile ? <Divider /> : null}
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 10 : 2}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={isMobile ? "start" : "center"}
            alignItems={"center"}
            sx={{
              opacity:
                profileCompleteness?.referralPartnersCompleted ||
                !isCompanyActive(company?.status)
                  ? 0.5
                  : 1,
            }}
          >
            <IconButton
              aria-label="close"
              sx={{
                bgcolor: "#F8F9FA",
                borderRadius: "12px",
              }}
            >
              <AccountTreeOutlinedIcon sx={{ color: "#6C737F" }} />
            </IconButton>
          </Box>
        </Grid>
        {isMobile && profileCompleteness?.referralPartnersCompleted ? (
          <Grid item xs={isMobile ? 2 : 5}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={isMobile ? "start" : "end"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Box
                sx={{
                  bgcolor: "#E5E7EB",
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#6C737F",
                }}
              >
                <CheckIcon />
              </Box>
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={isMobile ? 12 : 5}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            sx={{
              opacity:
                profileCompleteness?.referralPartnersCompleted ||
                !isCompanyActive(company?.status)
                  ? 0.5
                  : 1,
            }}
          >
            <Typography variant="subtitle2">
              Connect with Referral Partners
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Build exclusive partnerships to refer and receive jobs
            </Typography>
          </Box>
        </Grid>
        {!isMobile ||
        (!profileCompleteness?.referralPartnersCompleted && isMobile) ? (
          <Grid item xs={isMobile ? 12 : 5}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={isMobile ? "start" : "end"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              {profileCompleteness?.referralPartnersCompleted ? (
                <Box
                  sx={{
                    bgcolor: "#E5E7EB",
                    width: "40px",
                    height: "40px",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#6C737F",
                  }}
                >
                  <CheckIcon />
                </Box>
              ) : (
                <Button
                  variant="contained"
                  disabled={!isCompanyActive(company?.status)}
                  onClick={() => {
                    navigate(
                      getNavigationPathFromRoutePaths([
                        CompanyRoutes.BASE,
                        CompanyRoutes.EXPLORE_PARTNERS,
                      ])
                    );
                  }}
                >
                  Explore Partners
                </Button>
              )}
            </Box>
          </Grid>
        ) : null}
      </Grid>
      {isMobile ? <Divider /> : null}
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 10 : 2}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={isMobile ? "start" : "center"}
            alignItems={"center"}
            sx={{
              opacity: profileCompleteness?.inviteTeamMembersCompleted
                ? 0.5
                : 1,
            }}
          >
            <IconButton
              aria-label="close"
              sx={{
                bgcolor: "#F8F9FA",
                borderRadius: "12px",
              }}
            >
              <PersonAddAltIcon sx={{ color: "#6C737F" }} />
            </IconButton>
          </Box>
        </Grid>
        {isMobile && profileCompleteness?.inviteTeamMembersCompleted ? (
          <Grid item xs={isMobile ? 2 : 5}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={isMobile ? "start" : "end"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Box
                sx={{
                  bgcolor: "#E5E7EB",
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#6C737F",
                }}
              >
                <CheckIcon />
              </Box>
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={isMobile ? 12 : 5}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            sx={{
              opacity: profileCompleteness?.inviteTeamMembersCompleted
                ? 0.5
                : 1,
            }}
          >
            <Typography variant="subtitle2">Invite Team Members</Typography>
            <Typography variant="body2" color="text.secondary">
              Onboard your field team so they can earn through job referrals
            </Typography>
          </Box>
        </Grid>
        {!isMobile ||
        (!profileCompleteness?.inviteTeamMembersCompleted && isMobile) ? (
          <Grid item xs={isMobile ? 12 : 5}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={isMobile ? "start" : "end"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              {profileCompleteness?.inviteTeamMembersCompleted ? (
                <Box
                  sx={{
                    bgcolor: "#E5E7EB",
                    width: "40px",
                    height: "40px",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#6C737F",
                  }}
                >
                  <CheckIcon />
                </Box>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(
                      getNavigationPathFromRoutePaths([
                        CompanyRoutes.BASE,
                        CompanyRoutes.TEAM_MEMBERS,
                      ])
                    );
                  }}
                >
                  Invite Team
                </Button>
              )}
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Card>
  );
};
