import CancelIcon from "@mui/icons-material/Cancel";
import ChatIcon from "@mui/icons-material/Chat";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid-premium";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import {
  appendQueryParams,
  convertPhoneToNationalFormat,
  removeNullAndUndefinedKeys,
} from "@/common/utils";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useArchiveUser } from "@/company/api/useArchiveUser";
import { BrandLocationUserGroup } from "@/company/api/useGetUsersByCompanyId";
import { useUpdateCompanyUser } from "@/company/api/useUpdateCompanyUser";
import {
  ChipObj,
  UserOnboardingStatusChipObj,
} from "@/company/onboarding/components/screen/operations-sales-info/StaffTable";
import { DefaultCompanyGroups, PLATFORM_PRESENCE } from "@/company/types";
import { UserOnboardingStatus } from "@/user/types";
import { snakeCaseToTitleCase } from "@/utils";

import { useResendUserInviteAdmin } from "./api/useResendUserInviteAdmin";
import ConfirmationDialog from "./ConfirmationDialog";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import {
  CustomToolbar,
  formatDate,
  formatDateTime,
  getGridStringOperatorsDataGrid,
  renderEditTextarea,
} from "./utils";

export default function UserTable({
  users,
  expanded,
  createNewChatThread,
  companyId,
  refetch,
  isFetchingProfile,
}: {
  users: Array<BrandLocationUserGroup>;
  createNewChatThread: (userId: number) => void;
  companyId: number;
  expanded?: boolean;
  refetch: () => void;
  isFetchingProfile?: boolean;
}) {
  const [rows, setRows] = useState([]);

  const { isLoading: isDeleting, mutateAsync: archiveUser } = useArchiveUser({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while archiving user.";
      enqueueSnackbar(message, {
        variant: "error",
      });
    },
    onSuccess() {
      enqueueSnackbar({
        message: "User archived successfully.",
        variant: "success",
      });
    },
  });

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const { mutateAsync: updateUser, isLoading } = useUpdateCompanyUser({
    onError(error) {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "Error while updating user details.";
      enqueueSnackbar(message, {
        variant: "error",
      });
    },
    onSuccess() {
      refetch();
      enqueueSnackbar({
        message: "Your changes have been saved.",
        variant: "success",
      });
    },
  });

  const { mutateAsync: resendUserInvite, isLoading: isResendingUserInvite } =
    useResendUserInviteAdmin({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while resending user invite.";
        enqueueSnackbar(message, {
          variant: "error",
        });
        refetch();
      },
      onSuccess() {
        enqueueSnackbar({
          message: "User invite resent successfully.",
          variant: "success",
        });
        refetch();
      },
    });

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const [deleteRowName, setDeleteRowName] = useState<string>("");
  const [resendRowId, setResendRowId] = useState<number | undefined>();

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleMessageClick = row => () => {
    createNewChatThread?.(row.id);
  };

  const handleDeleteClick = (id: GridRowId) => {
    archiveUser({ companyId, userId: id });
    setRows(rows.filter(row => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find(row => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter(row => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    await updateUser({
      companyId,
      userId: updatedRow.id,
      updatedUser: {
        ...newRow,
        firstName: newRow?.name?.split(" ")?.[0],
        lastName: newRow?.name?.split(" ")?.[1],
        oldGroups: oldRow.types,
        newGroups: newRow.types,
      },
    });

    setRows(
      rows.map(row =>
        row.id === newRow.id
          ? {
              ...updatedRow,
              firstName: newRow?.name?.split(" ")?.[0],
              lastName: newRow?.name?.split(" ")?.[1],
            }
          : row
      )
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const [deleteRowId, setDeleteRowId] = useState<GridRowId | null | undefined>(
    null
  );

  useEffect(() => {
    const fetchedRows = users.map(userWithGroup => {
      let onboardingStatus = UserOnboardingStatus.CREATED;
      if (userWithGroup.user.lastLoginAt) {
        onboardingStatus = UserOnboardingStatus.ONBOARDED;
      } else if (
        userWithGroup.user.platformPresence == PLATFORM_PRESENCE.ON_PLATFORM
      ) {
        onboardingStatus = UserOnboardingStatus.INVITED;
      }
      return {
        ...userWithGroup.user,
        name: `${userWithGroup.user?.firstName} ${userWithGroup.user?.lastName}`,
        types: userWithGroup.groups.map(group => group.name),
        onboardingStatus,
      };
    });
    setRows(fetchedRows);
  }, [users]);
  const [selectedPlatformPresence, setSelectedPlatformPresence] = useState<
    PLATFORM_PRESENCE | "All"
  >(PLATFORM_PRESENCE.ON_PLATFORM);

  let columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      renderCell: params => params.row.id.toString(),

      width: 200,
      editable: false,
    },
    {
      field: "name",
      headerName: "NAME",
      width: 200,
      editable: true,
      // renderCell: params => `${params.row.name} ${params.row.lastName}`,
    },
    {
      field: "types",
      width: 200,
      headerName: "USER TYPE",
      editable: true,
      type: "string",
      renderEditCell: renderEditTextarea,
      renderCell: params => {
        const sectorChips = params.row?.types?.map(type => (
          <Chip
            key={type}
            sx={{
              color:
                params.row.platformPresence === PLATFORM_PRESENCE.OFF_PLATFORM
                  ? "none"
                  : ChipObj[type as DefaultCompanyGroups].color,
              bgcolor:
                params.row.platformPresence === PLATFORM_PRESENCE.OFF_PLATFORM
                  ? "none"
                  : ChipObj[type as DefaultCompanyGroups].bgColor,
            }}
            label={
              <Typography variant="overline">
                {snakeCaseToTitleCase(type?.toString() ?? "")}
              </Typography>
            }
            size="small"
          />
        ));
        return sectorChips;
      },
    },
    ...(expanded
      ? [
          {
            field: "cellPhone",
            editable: true,
            headerName: "MOBILE PHONE",
            width: 200,
          },
          {
            field: "email",
            editable: true,
            headerName: "EMAIL",
            width: 200,
          },
          {
            field: "balance",
            headerName: "BALANCE",
            renderCell: params => `$${params.row.balance?.toString()}`,
            width: 200,
          },
          {
            field: "lifetimeEarnings",
            headerName: "Lifetime Earnings",
            renderCell: params => `$${params.row.lifetimeEarnings?.toString()}`,
            width: 200,
          },
          {
            field: "createdAt",
            valueFormatter: formatDate,
            headerName: "Created At".toUpperCase(),
            width: 200,
          },
          {
            field: "onboardingStatus",
            width: 200,
            headerName: "ONBOARDING STATUS",
            type: "string",
            renderCell: params => {
              return (
                <Chip
                  key={params.row.onboardingStatus}
                  sx={{
                    color:
                      UserOnboardingStatusChipObj[
                        params.row.onboardingStatus as UserOnboardingStatus
                      ].color,
                    bgcolor:
                      UserOnboardingStatusChipObj[
                        params.row.onboardingStatus as UserOnboardingStatus
                      ].bgColor,
                  }}
                  label={
                    <Typography variant="overline">
                      {snakeCaseToTitleCase(
                        params.row.onboardingStatus?.toString() ?? ""
                      )}
                    </Typography>
                  }
                  size="small"
                />
              );
            },
          },
          {
            field: "lastInvitedAt",
            valueFormatter: formatDate,
            headerName: "Last Invited At".toUpperCase(),
            width: 200,
          },
          {
            field: _.camelCase("terms_and_conditions_accepted_at"),
            valueFormatter: formatDate,
            headerName: "Onboarded At".toUpperCase(),
            width: 200,
          },
          {
            field: "preferredLanguage",
            editable: true,
            headerName: "Preferred Language".toUpperCase(),
            width: 200,
          },
          {
            field: "stripeAccountId",
            editable: true,
            headerName: "Stripe Account Id".toUpperCase(),
            width: 200,
          },
          {
            field: "acceptedWhatsappMessagingTerms",
            editable: true,
            type: "boolean",
            headerName: "Accepted Whatsapp Messaging Terms".toUpperCase(),
            width: 200,
          },
          {
            field: "loginLink",
            editable: false,
            headerName: "Login Link".toUpperCase(),
            width: 200,
            valueGetter: (_value, row) => {
              const loginDetails = removeNullAndUndefinedKeys({
                phone: convertPhoneToNationalFormat(row.cellPhone),
                email: row.email,
              });
              return appendQueryParams(
                `${window.location.origin}/auth/login`,
                loginDetails
              );
            },
          },
          {
            editable: false,
            field: "lastTradeEngageMessageSentAt",
            valueFormatter: formatDateTime,
            headerName: "Last TradeEngage Message Date",
            width: 200,
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 160,
            headerAlign: "right",
            align: "right",
            cellClassName: "actions",
            getActions: ({ id, row }) => {
              const isInEditMode =
                rowModesModel[id]?.mode === GridRowModes.Edit;
              if (isInEditMode) {
                return [
                  <GridActionsCellItem
                    key={`${id}-save`}
                    icon={<SaveIcon />}
                    label="Save"
                    sx={{
                      color: "primary.main",
                    }}
                    onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                    key={`${id}-cancel`}
                    icon={<CancelIcon />}
                    label="Cancel"
                    className="textPrimary"
                    color="inherit"
                    onClick={handleCancelClick(id)}
                  />,
                ];
              }

              return [
                ...(!row.deletedAt && !row.lastLoginAt
                  ? [
                      <Tooltip
                        key={`${id}-resend-invite-user-tooltip`}
                        title="Resend Onboarding Invite"
                      >
                        <GridActionsCellItem
                          key={`${id}-resend-invite`}
                          icon={<NotificationsActiveIcon />}
                          label="Resend Invite"
                          color="inherit"
                          disabled={isResendingUserInvite}
                          onClick={() => {
                            setResendRowId(row.id);
                          }}
                        />
                      </Tooltip>,
                    ]
                  : []),
                <GridActionsCellItem
                  key={`${id}-message`}
                  icon={<ChatIcon />}
                  label="Message"
                  color="inherit"
                  onClick={handleMessageClick(row)}
                />,
                <GridActionsCellItem
                  key={`${id}-edit`}
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  color="inherit"
                  onClick={handleEditClick(id)}
                />,
                <GridActionsCellItem
                  key={`${id}-delete`}
                  icon={<DeleteIcon />}
                  label="Delete"
                  color="inherit"
                  onClick={() => {
                    setDeleteRowId(id);
                    setDeleteRowName(row.name);
                  }}
                />,
              ];
            },
          },
        ]
      : []),
  ];

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  const filteredRows =
    selectedPlatformPresence === "All"
      ? rows
      : rows.filter(row => row.platformPresence === selectedPlatformPresence);

  return (
    <Box display={"flex"} width={"100%"}>
      <Box
        sx={{
          height: 400,
          position: "relative",
          width: "100%",
        }}
      >
        <Box display={"flex"} gap={1} py={1}>
          <Button
            size="small"
            sx={{
              bgcolor:
                selectedPlatformPresence === PLATFORM_PRESENCE.ON_PLATFORM
                  ? "#16B364"
                  : "rgba(17, 25, 39, 0.08)",
              color:
                selectedPlatformPresence === PLATFORM_PRESENCE.ON_PLATFORM
                  ? "white"
                  : "#111927",
              "&:hover": {
                color: "#111927",
              },
              "&:focus": {
                bgcolor: "#16B364",
              },
            }}
            onClick={() =>
              setSelectedPlatformPresence(PLATFORM_PRESENCE.ON_PLATFORM)
            }
          >
            On Platform (
            {
              rows?.filter(
                row => row.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM
              ).length
            }
            )
          </Button>
          <Button
            size="small"
            sx={{
              bgcolor:
                selectedPlatformPresence === PLATFORM_PRESENCE.OFF_PLATFORM
                  ? "#16B364"
                  : "rgba(17, 25, 39, 0.08)",
              color:
                selectedPlatformPresence === PLATFORM_PRESENCE.OFF_PLATFORM
                  ? "white"
                  : "#111927",
              "&:hover": {
                color: "#111927",
              },
              "&:focus": {
                bgcolor: "#16B364",
              },
            }}
            onClick={() =>
              setSelectedPlatformPresence(PLATFORM_PRESENCE.OFF_PLATFORM)
            }
          >
            Off Platform (
            {
              rows?.filter(
                row => row.platformPresence === PLATFORM_PRESENCE.OFF_PLATFORM
              ).length
            }
            )
          </Button>
          <Button
            size="small"
            sx={{
              bgcolor:
                selectedPlatformPresence === "All"
                  ? "#16B364"
                  : "rgba(17, 25, 39, 0.08)",
              color: selectedPlatformPresence === "All" ? "white" : "#111927",
              "&:hover": {
                color: "#111927",
              },
              "&:focus": {
                bgcolor: "#16B364",
              },
            }}
            onClick={() => setSelectedPlatformPresence("All")}
          >
            All ({rows?.length})
          </Button>
        </Box>
        <DataGridPremium
          checkboxSelection
          disableRowSelectionOnClick
          pagination
          columns={columns}
          loading={isFetchingProfile}
          sx={{
            "& .MuiDataGrid-row": {
              "--rowBorderColor": "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withRightBorder": {
              borderRightColor: "#D9D9D9",
            },
            "& .MuiDataGrid-cell--withLeftBorder": {
              borderLeftColor: "#D9D9D9",
            },
          }}
          rows={filteredRows ?? []}
          slots={{
            ...(expanded
              ? {
                  toolbar: () => (
                    <CustomToolbar
                      fileName="Company_Users"
                      includeQuickFilter={false}
                    />
                  ),
                }
              : {}),
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
          initialState={{
            density: "compact",
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "name"],
              right: ["actions"],
            },
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          slotProps={{
            toolbar: { setRowModesModel },
          }}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
        />
      </Box>
      <DeleteConfirmationDialog
        open={!!deleteRowId}
        text={deleteRowName}
        handleClose={() => setDeleteRowId(null)}
        handleConfirm={() => {
          handleDeleteClick(deleteRowId);
          setDeleteRowName("");
          setDeleteRowId(null);
        }}
      />
      <ConfirmationDialog
        open={!!resendRowId}
        handleClose={() => setResendRowId(undefined)}
        handleConfirm={() => {
          resendUserInvite({
            userId: resendRowId!,
            companyId,
          });
          setResendRowId(undefined);
        }}
        text={`Are you sure you want to re-send the onboarding invites?`}
        title="Send Onboarding Invitations"
        confirmText="Send"
      />
    </Box>
  );
}
