import { Alert, Box, CircularProgress, Divider } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect, useRef } from "react";

import { useAuth } from "@/auth/context/jwt";
import { ChatMessageAdd } from "@/common/chat/ChatMessageAdd";
import { ChatMessages } from "@/common/chat/ChatMessages";
import { useChatContext } from "@/company/context/chat-context";
import { useReferralInvitationContext } from "@/company/context/referral-invitations-context";
import {
  CompanyPublicProfile,
  ConnectionStatus,
  PLATFORM_PRESENCE,
} from "@/company/types";

interface MessageProps {
  otherCompanyProfile: CompanyPublicProfile;
  connectionStatus: ConnectionStatus;
  removeBaseStyles?: boolean;
  onChatMessageAdd?: () => void;
  isCampaignTour?: boolean;
}

export const Message: FC<MessageProps> = ({
  otherCompanyProfile,
  connectionStatus,
  removeBaseStyles,
  onChatMessageAdd,
  isCampaignTour,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const { chats, isLoading, updateChat } = useChatContext();
  const { createReferralInvitation } = useReferralInvitationContext();

  const company = session?.company;

  const user = session?.user;
  const brandLocationProfile = session?.brandLocationProfile;

  const { id: otherCompanyId, name: otherCompanyName } = otherCompanyProfile;

  const participantIds = [otherCompanyId];
  const participants = [
    {
      id: otherCompanyId,
      avatar: otherCompanyProfile.logoUrl,
      name: otherCompanyName ?? "",
    },
  ];

  const defaultInvitationMessage = brandLocationProfile?.invitationMessage
    ? brandLocationProfile.invitationMessage
    : `Hi, I'm ${user?.firstName} at ${company?.name}. Our team comes across referral opportunities all the time and I'd like to collaborate. We use this company to pay other teams for referrals, instead of paying Google and PPC for leads. Our team has a high-level of customer service and quality of work. When is a good time to connect for 15 minutes and introduce ourselves?`;

  const defaultText =
    !chats?.length && !isLoading ? defaultInvitationMessage : "";

  const thread = {
    id: "1",
    messages: chats ?? [],
    participantIds,
    participants,
    type: "ONE_TO_ONE",
  };

  const messagesRef = useRef();

  useEffect(() => {
    // Scroll to the bottom when messages change
    if (messagesRef.current)
      messagesRef.current.scrollTop = messagesRef?.current?.scrollHeight;
  }, [chats]);

  // const { messagesRef } = useMessagesScroll(thread);
  return (
    <>
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        overflow={"auto"}
      >
        <Box
          ref={messagesRef}
          sx={{
            flexGrow: "1",
            overflowY: "auto",
            bgcolor: "#F8F9FA",
          }}
        >
          {isLoading ? (
            <Box py={3} textAlign="center">
              <CircularProgress />
            </Box>
          ) : company ? (
            <ChatMessages
              initialChatAlert={
                connectionStatus === ConnectionStatus.PENDING ||
                connectionStatus === ConnectionStatus.INVITED ? (
                  <Alert
                    severity="info"
                    variant="standard"
                    sx={{ mt: 2, color: "#00359E", bgcolor: "#EBEFFF" }}
                  >
                    This partnership request is pending.
                  </Alert>
                ) : connectionStatus === ConnectionStatus.CONNECTED ? (
                  <Alert
                    severity="success"
                    variant="standard"
                    color="success"
                    sx={{ mt: 2 }}
                  >
                    {participants[0].name} is a connection! This is the
                    beginning of your conversation
                  </Alert>
                ) : (
                  <Alert
                    severity="success"
                    variant="standard"
                    color="success"
                    sx={{ mt: 2 }}
                  >
                    This is the beginning of your conversation.
                  </Alert>
                )
              }
              chats={thread?.messages || []}
              participants={thread?.participants || []}
            />
          ) : null}
        </Box>
        <Box
          bgcolor="white"
          px={1}
          pb={2}
          sx={{
            color: "#fff",
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
        >
          <Divider sx={{ mb: 2 }} />
          <ChatMessageAdd
            showMessageOptions={
              otherCompanyProfile.platformPresence ===
              PLATFORM_PRESENCE.OFF_PLATFORM
            }
            companyProfile={otherCompanyProfile}
            isCampaignTour={isCampaignTour}
            defaultText={defaultText}
            connectionStatus={connectionStatus}
            onSend={async (value: string) => {
              try {
                await updateChat(value);
                onChatMessageAdd?.();
                if (connectionStatus === ConnectionStatus.NOT_CONNECTED) {
                  await createReferralInvitation({
                    invitedCompanyId: otherCompanyId,
                  });
                }
              } catch {
                enqueueSnackbar({
                  message:
                    "An error occurred while trying to send your message",
                });
              }
            }}
          />
        </Box>
      </Box>
    </>
  );
};
