import { Close as CloseIcon } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { CompanySelectionPage } from "./CompanySelectionPage";

export interface CompanySelectionDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CompanySelectionDialog(props: CompanySelectionDialogProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Dialog
      fullWidth
      sx={{
        m: { xs: -3, md: 0 },
        ...(isMobile
          ? { px: 2, py: 4 }
          : {
              "& .MuiDialog-paper": {
                maxWidth: "1100px !important",
                width: "fit-content",
                minWidth: "800px",
              },
            }),
      }}
      open={props.isOpen}
      fullScreen={isMobile}
      onClose={() => props.onClose()}
    >
      {isMobile ? (
        <DialogTitle
          component="div"
          width="95%"
          sx={{ ...(isMobile ? { p: 0, px: 4, pt: 2 } : {}) }}
        >
          <Typography gutterBottom variant="h5" pr={1}>
            Which workspace do you want to access?
          </Typography>
        </DialogTitle>
      ) : null}
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 16,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
        onClick={() => props.onClose()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: { xs: 0, md: 2 }, pt: 0, pb: 0 }}>
        <CompanySelectionPage
          changeWorkspaceInApp={true}
          onChange={props.onClose}
        />
      </DialogContent>
    </Dialog>
  );
}
