import constate from "constate";
import { useState } from "react";

import { useAuth } from "@/auth/context/jwt";

import { useQueryExploreCompanies } from "../api/useExploreConnections";
import { useGetRelevanceOrderedJobSectorsList } from "../api/useGetRelevanceOrderedJobSectorsList";

function useCompanySearch() {
  const [filters, setFilters] = useState({});
  const [enabled, setEnabled] = useState(false);

  const { session } = useAuth();
  const company = session?.company;
  const brandLocationProfile = session?.brandLocationProfile;

  const { data: relevanceOrderedJobSectorsList } =
    useGetRelevanceOrderedJobSectorsList(company?.id, {
      refetchOnWindowFocus: false,
      enabled: enabled && !!company?.id,
      retry: false,
      refetchOnReconnect: false,
    });

  const recommendedJobSectorsList = [
    ...(brandLocationProfile?.inboundSectors ?? []),
    ...(brandLocationProfile?.outboundSectors ?? []),
  ];
  const {
    isFetching,
    data,
    refetch: refetchCompanies,
  } = useQueryExploreCompanies(
    { ...filters },
    recommendedJobSectorsList?.length
      ? recommendedJobSectorsList
      : relevanceOrderedJobSectorsList,
    {
      refetchOnWindowFocus: false,
      enabled: enabled && !!Object.keys(filters)?.length,
      retry: false,
      refetchOnReconnect: false,
    }
  );

  const searchCompanies = () => {
    refetchCompanies();
  };

  const updateFilter = async filter => {
    setFilters(filter);
  };

  return {
    isFetching,
    updateFilter,
    data,
    searchCompanies,
    filters,
    setEnabled,
  };
}

export const [CompanySearchProvider, useCompanySearchContext] =
  constate(useCompanySearch);
