import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import axiosInstance from "@/common/api";

import { RoutedJobResponse } from "../types/job";

export type UpdateJobRequest = {
  status: string;
  declinedReason?: string;
};

export function useUpdateJobView(
  options: UseMutationOptions<
    RoutedJobResponse,
    AxiosError,
    { jobId: number }
  > = {}
): UseMutationResult<RoutedJobResponse, AxiosError, { jobId: number }> {
  return useMutation({
    mutationFn: async ({ jobId }) => {
      const url = `jobs/${jobId}/views`;
      const response = await axiosInstance.post(url);
      return response.data.data;
    },
    ...options,
  });
}
