import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import axiosInstance from "@/common/api";

import { CompanyProfileCompleteness } from "../types";

export const GET_COMPANY_PROFILE_COMPLETENESS_INFO_URL =
  "/companies/profile-completeness";

export function useGetProfileCompletenessInfo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<CompanyProfileCompleteness> {
  return useQuery({
    queryKey: [GET_COMPANY_PROFILE_COMPLETENESS_INFO_URL],
    queryFn: async () => {
      const response = await axiosInstance.get(
        GET_COMPANY_PROFILE_COMPLETENESS_INFO_URL
      );
      return response.data.data;
    },
    ...options,
  });
}
