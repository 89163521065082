import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface ReferralRemoveProps {
  isOpen: boolean;
  companyName: string;
  onCancel: () => void;
  onRemove: (value: string) => void;
}

export const ReferralRemoveDialog: React.FC<ReferralRemoveProps> = ({
  isOpen,
  companyName,
  onCancel,
  onRemove,
}) => {
  const [reason, setReason] = React.useState("");
  const [otherReason, setOtherReason] = React.useState("");

  React.useEffect(() => {
    if (reason !== "Other") {
      setOtherReason("");
    }
  }, [reason]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason((event.target as HTMLInputElement).value);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="partner-removal-dialog-title"
        open={isOpen}
        onClose={onCancel}
      >
        <Box sx={{ p: 3 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <DialogTitle sx={{ m: 0, p: 0 }} id="partner-removal-dialog-title">
              <Typography variant="h5" color="error">
                Remove Partnership?
              </Typography>
            </DialogTitle>
            <IconButton
              aria-label="close"
              sx={{
                color: theme => theme.palette.grey[500],
              }}
              onClick={onCancel}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            gutterBottom
            variant="body2"
            color={"text.secondary"}
            maxWidth={"500px"}
          >
            By removing this partnership, you will no longer be able to SEND
            jobs to{" "}
            <Typography
              display={"inline"}
              variant="body2"
              color="text.primary"
              fontWeight={"600"}
            >
              {companyName}
            </Typography>{" "}
            but you will still be able to receive jobs from{" "}
            <Typography
              display={"inline"}
              variant="body2"
              color="text.primary"
              fontWeight={"600"}
            >
              {companyName}
            </Typography>
            .
          </Typography>
          <FormControl>
            <Typography
              variant="overline"
              sx={{ py: 1.5, color: "text.secondary" }}
            >
              REASON FOR REMOVING THIS PARTNERSHIP
            </Typography>
            <RadioGroup
              aria-labelledby="controlled-group-remove-partner"
              name="controlled-group-remove-partner"
              value={reason}
              sx={{ justifyContent: "flex-start" }}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Did not receive enough job referrals or the job referrals I received were not relevant"
                control={<Radio />}
                label="Did not receive enough job referrals or the job referrals I received were not relevant"
              />
              <FormControlLabel
                value="Problem with the referral partner"
                control={<Radio />}
                label="Problem with the referral partner"
              />
              <FormControlLabel
                value="Added a new referral partner"
                control={<Radio />}
                label="Added a new referral partner"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            multiline
            fullWidth
            id="otherReason"
            label="Tell us more"
            rows={4}
            maxRows={10}
            variant="filled"
            value={otherReason}
            onChange={event => {
              setOtherReason(event.target.value);
            }}
          />
          <Typography variant="body2">
            Are you sure you want to continue?
          </Typography>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
              No, Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!reason}
              color="error"
              onClick={() => {
                onRemove(`${reason}, ${otherReason}`);
              }}
            >
              Yes, Remove
            </Button>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};
