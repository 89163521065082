import { Drawer } from "@mui/material";

import { GoogleCompanyProfile } from "@/company/types";

import { GoogleCompanyProfileDetails } from "./GoogleCompanyProfileDetails";

export interface GoogleCompanyDrawerProps {
  googlePlacesId?: string;
  isOpen: boolean;
  onClose: () => void;
  googleProfile?: GoogleCompanyProfile;
}

export function GoogleCompanyDrawer(props: GoogleCompanyDrawerProps) {
  return (
    <Drawer
      open={props.isOpen}
      anchor="right"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 500,
          background: "white",
          p: 2,
        },
      }}
      onClose={props.onClose}
    >
      <GoogleCompanyProfileDetails
        googleProfile={props.googleProfile}
        googlePlacesId={props.googlePlacesId}
        onClose={props.onClose}
      />
    </Drawer>
  );
}
