import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { CreateTechnicianJobDialog } from "@/common/CreateJobDialog/CreateTechnicianJobDialog";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { Job } from "@/company/jobs/types";
import { COMPANY_ACCOUNT_TYPES, CompanyRoutes } from "@/company/types";
import { useModalState } from "@/lib/modals/useModalState";
import { isDefined } from "@/utils/isDefined";

import { useCreateJobFromPromotion } from "../api/useCreateJobFromPromotion";
import { JobPromotionWithCompany } from "../types";

interface JobPromotionReferButtonProps {
  jobPromotion: JobPromotionWithCompany;
}

export function JobPromotionReferButton(props: JobPromotionReferButtonProps) {
  const { jobPromotion } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();

  const addJobModalState = useModalState();
  const [createdJob, setCreatedJob] = useState<Job | null>(null);

  const {
    mutate: createJobFromPromotion,
    isLoading: isCreatingJobFromPromotion,
  } = useCreateJobFromPromotion({
    onSuccess: data => {
      setCreatedJob(data);
      addJobModalState.openModal();
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while creating the job";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  if (session?.company?.accountType === COMPANY_ACCOUNT_TYPES.CONSULTANT) {
    return <Box />;
  }

  return (
    <>
      <LoadingButton
        variant="contained"
        loading={isCreatingJobFromPromotion}
        onClick={event => {
          event.stopPropagation();
          createJobFromPromotion({ jobPromotionId: jobPromotion.id });
        }}
      >
        Refer Promotion
      </LoadingButton>
      {isDefined(createdJob) && addJobModalState.modalIsOpen && (
        <CreateTechnicianJobDialog
          isOpen={addJobModalState.modalIsOpen}
          jobsListUrl={getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.ALL_JOBS,
          ])}
          createdJob={createdJob}
          jobPromotion={jobPromotion}
          onClose={() => {
            setCreatedJob(null);
            addJobModalState.closeModal();
          }}
        />
      )}
    </>
  );
}
