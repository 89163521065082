import {
  Close as CloseIcon,
  Share as ShareIcon,
  VerifiedOutlined as VerifiedOutlinedIcon,
} from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { useFetchCompanyProfile } from "@/company/api/useFetchExternalProfile";
import {
  ProfileShareSource,
  ServiceProviderShareDialog,
} from "@/company/components/service-provider-dialog/ServiceProviderShareDialog";
import { PLATFORM_PRESENCE } from "@/company/types";
import { useModalState } from "@/lib/modals/useModalState";
import { isDefined } from "@/utils/isDefined";

import { CompaniesJobPromotionsList } from "../components/CompaniesJobPromotionsList";

export function CompanyJobPromotionsPage() {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { session } = useAuth();

  const isUserIsAdminOrOwner = session?.groups?.some(isAdministratorOrOwner);

  const shareProfileDialogState = useModalState();

  const {
    data: companyProfile,
    isLoading: isLoadingCompanyProfile,
    isFetching: isFetchingCompanyProfile,
  } = useFetchCompanyProfile(isDefined(companyId) ? parseInt(companyId) : -1, {
    enabled: isDefined(companyId),
  });

  if (isUserIsAdminOrOwner) {
    return null;
  }

  if (isLoadingCompanyProfile || isFetchingCompanyProfile) {
    return <ComponentLoader />;
  }

  if (!isDefined(companyProfile)) {
    return (
      <Box sx={{ height: "100%", alignContent: "center" }}>
        <Stack gap={2} alignItems="center">
          <Box
            component="img"
            src="/empty-icon.png"
            style={{ width: "132px", height: "128px" }}
            alt="no job promotions"
          />
          <Typography variant="h6" color="text.secondary">
            Company not found
          </Typography>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 60px)",
        padding: 3,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box
          component="img"
          src={companyProfile.logoUrl}
          alt="company logo"
          sx={{
            height: "40px",
            width: "auto",
            maxWidth: "50%",
            objectFit: "contain",
            display: "block",
          }}
        />
        <Stack direction="row" gap={1}>
          <IconButton
            aria-label="share"
            sx={{
              color: theme => theme.palette.grey[500],
            }}
            onClick={() => {
              shareProfileDialogState.openModal();
            }}
          >
            <ShareIcon />
          </IconButton>
          <IconButton
            aria-label="close"
            sx={{
              color: theme => theme.palette.grey[500],
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography variant="h5">{companyProfile?.name}</Typography>
        {companyProfile.platformPresence === PLATFORM_PRESENCE.ON_PLATFORM && (
          <ToolTip
            placement="bottom"
            title="TradeEngage Participant"
            message="This is a verified active business on TradeEngage."
          >
            <VerifiedOutlinedIcon
              sx={{
                verticalAlign: "center",
                color: "#16DB65",
                height: "20px",
                width: "20px",
              }}
            />
          </ToolTip>
        )}
      </Stack>
      <CompaniesJobPromotionsList companyIds={[companyProfile.id]} />
      {shareProfileDialogState.modalIsOpen && (
        <ServiceProviderShareDialog
          isOpen={shareProfileDialogState.modalIsOpen}
          source={ProfileShareSource.FIELD_TEAM}
          companyProfile={companyProfile}
          onClose={shareProfileDialogState.closeModal}
        />
      )}
    </Box>
  );
}
