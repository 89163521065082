import {
  AvatarGroup,
  Box,
  Button,
  Container,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { DashboardTitle } from "@/common/DashboardTitle";
import { Seo } from "@/common/Seo/Seo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { ChatThread } from "@/company/api/useFetchAdminChatsByBrandLocation";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { useSupportChatContext } from "@/company/context/support-chat-context";
import { CompanyRoutes } from "@/company/types";

import { SupportMessageBox } from "./SupportMessageBox";

export function SuppportMessage() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { chats: companyMessagesList, isLoading } = useSupportChatContext();
  const navigate = useNavigate();

  const filteredCompanyMessagesList = companyMessagesList.filter(
    chat => chat.messages.length
  );

  const getTitle = (chatThread: ChatThread) => {
    const nameSet = new Set();
    let name = "";
    chatThread.participants.forEach((participant, index) => {
      if (!nameSet.has(participant.name)) {
        name = name + `${index === 0 ? "" : " + "}${participant.name}`;

        nameSet.add(participant.name);
      }
    });
    return name;
  };
  const selectedChat = filteredCompanyMessagesList?.length
    ? companyMessagesList[0]
    : null;

  return (
    <>
      <Seo title="Company Messages" />
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"calc(100vh - 60px)"}
        pt={1}
      >
        <DashboardTitle
          title="TradeEngage Team Messages"
          subtitle="Messages from the TradeEngage team appear here. If you need to get in touch urgently, visit Contact Support to reach us by phone or email."
        />
        {isLoading ? (
          <Box textAlign={"center"} width="100%" mt={5}>
            <ComponentLoader />
          </Box>
        ) : selectedChat ? (
          <Container
            maxWidth={false}
            sx={{
              mx: 0,
              flexGrow: 1,
              display: "flex",
              overflow: "auto",
            }}
            disableGutters={true}
          >
            <Grid container flexGrow={1} display={"flex"} overflow={"auto"}>
              <Grid
                item
                container
                sx={{
                  px: 3,
                  borderLeft: isMobile ? null : "2px #F8F9FA solid",
                  overflow: "auto",
                  height: "100%",
                }}
              >
                {selectedChat ? (
                  <Box
                    width="100%"
                    height={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Box
                      mb={2}
                      display="flex"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box display="flex" alignItems={"center"}>
                        <AvatarGroup max={1}>
                          {selectedChat.participants.map(participant => (
                            <CompanyAvatar
                              key={participant.id}
                              logo={participant.logoUrl || undefined}
                              name={participant.name}
                              height={"48px"}
                              width={"48px"}
                              bgColor="white"
                              border="1px solid gray !important"
                            />
                          ))}
                        </AvatarGroup>
                        <Typography variant="h6" ml={1}>
                          {getTitle(selectedChat)}
                        </Typography>
                      </Box>
                    </Box>
                    <SupportMessageBox
                      chatThread={selectedChat}
                      onChatMessageAdd={() => {
                        console.log("chat added");
                      }}
                    />
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={5}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                No Messages Found
              </Typography>
              <Typography variant="body1" color={"text.secondary"}>
                Click below to view your jobs.
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                navigate(
                  getNavigationPathFromRoutePaths([
                    CompanyRoutes.BASE,
                    CompanyRoutes.MY_JOBS,
                  ])
                );
              }}
            >
              View Jobs
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}
