import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";
import { Job } from "@/company/jobs/types";

export const USE_GET_MY_JOBS_URI = "/homeowner/jobs";

export function useGetMyJobs(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<Job>> {
  return useQuery({
    queryKey: [USE_GET_MY_JOBS_URI],
    queryFn: async () => {
      const response = await API.get(USE_GET_MY_JOBS_URI);
      return response.data.data;
    },
    ...options,
  });
}
