import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Fab,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { DashboardTitle } from "@/common/DashboardTitle";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { ConfirmationDialog } from "@/lib/dialogs/ConfirmationDialog";
import { ModalStatus, useModalState } from "@/lib/modals/useModalState";
import { isDefined } from "@/utils/isDefined";

import { useDeleteJobPromotion } from "../api/useDeleteJobPromotion";
import { useGetJobPromotions } from "../api/useGetJobPromotions";
import { AddJobPromotionDialog } from "../components/AddJobPromotionDialog";
import { JobPromotionCard } from "../components/JobPromotionCard";
import { JobPromotionDetailsDrawer } from "../components/JobPromotionDetailsDrawer";
import {
  JobPromotionsSearchFilterForm,
  SearchJobPromotionsFormFields,
  searchJobPromotionsFormSchema,
} from "../components/JobPromotionsSearchFilterForm";
import { JobPromotionStatus, JobPromotionWithCompany } from "../types";

enum JobPromotionTabs {
  ALL_OFFERS = "ALL_OFFERS",
  MY_OFFERS = "MY_OFFERS",
}

export function JobPromotionsPage() {
  const [searchParams] = useSearchParams();
  const openJobPromotionParam = searchParams.get("openJobPromotion");
  const addJobPromotionModalState = useModalState(
    openJobPromotionParam === "true" ? ModalStatus.OPEN : ModalStatus.CLOSED
  );
  const jobPromotionDetailsModalState = useModalState();
  const deleteJobPromotionModalState = useModalState();
  const { session } = useAuth();
  const isUserIsAdminOrOwner = session?.groups?.some(isAdministratorOrOwner);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTab, setSelectedTab] = useState<JobPromotionTabs>(
    JobPromotionTabs.ALL_OFFERS
  );
  const [searchJobPromotionsFormFields, setsearchJobPromotionsFormFields] =
    useState<SearchJobPromotionsFormFields | null>(null);
  const [selectedJobPromotion, setSelectedJobPromotion] = useState<
    JobPromotionWithCompany | undefined
  >();
  const [jobPromotionMoreOptionsAnchorEl, setJobPromotionMoreOptionsAnchorEl] =
    useState<HTMLElement | null>(null);

  const searchJobPromotionsFormMethods =
    useFormik<SearchJobPromotionsFormFields>({
      initialValues: {
        searchWord: "",
        sectors: [],
      },
      validationSchema: searchJobPromotionsFormSchema,
      onSubmit: async data => {
        setsearchJobPromotionsFormFields(data);
      },
    });

  const {
    data: jobPromotionsData,
    isLoading: isLoadingJobPromotions,
    refetch: refetchJobPromotions,
  } = useGetJobPromotions(
    {
      filter: {
        keyword: searchJobPromotionsFormFields?.searchWord ?? "",
        sectors: searchJobPromotionsFormMethods.values?.sectors,
        ...(!isUserIsAdminOrOwner ? { status: JobPromotionStatus.ACTIVE } : {}),
      },
    },
    {
      onError: () => {
        enqueueSnackbar({
          message: "Error while getting the promotions",
          variant: "error",
        });
      },
    }
  );

  const { mutate: deleteJobPromotion } = useDeleteJobPromotion({
    onSuccess: () => {
      setSelectedJobPromotion(undefined);
      deleteJobPromotionModalState.closeModal();
      refetchJobPromotions();
    },
    onError: error => {
      const message = doesErrorHaveMessage(error)
        ? error.message
        : "There was an error while deleting the promotion";
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
  });

  const renderJobPromotions = () => {
    if (isLoadingJobPromotions) {
      return <ComponentLoader />;
    }

    if (!jobPromotionsData?.promotions.length) {
      return (
        <Box sx={{ flexGrow: 1, alignContent: "center" }}>
          <Stack gap={2} alignItems="center">
            <Box
              component="img"
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no job promotions"
            />
            <Typography variant="h6" color="text.secondary">
              No job promotions found
            </Typography>
          </Stack>
        </Box>
      );
    }

    const filteredJobPromotions = jobPromotionsData?.promotions.filter(
      jobPromotion => {
        if (selectedTab === JobPromotionTabs.ALL_OFFERS) {
          return true;
        }
        return jobPromotion.company.id === session?.company?.id;
      }
    );

    return (
      <Grid
        container
        gap={2}
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, 360px)",
          justifyContent: "space-around",
        }}
      >
        {filteredJobPromotions?.map(jobPromotion => (
          <Grid key={jobPromotion.id} item>
            <JobPromotionCard
              jobPromotion={jobPromotion}
              onClick={() => {
                setSelectedJobPromotion(jobPromotion);
                jobPromotionDetailsModalState.openModal();
              }}
              onMoreOptionsClick={event => {
                event.stopPropagation();
                setSelectedJobPromotion(jobPromotion);
                setJobPromotionMoreOptionsAnchorEl(event.currentTarget);
              }}
              onUpdate={() => {
                refetchJobPromotions();
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 60px)",
          paddingTop: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            top: "56px",
            paddingY: 1,
          }}
          position={"sticky"}
          display={"flex"}
          bgcolor={"white"}
          zIndex={1200}
          flexDirection={"column"}
          width={"100%"}
          gap={1}
        >
          <DashboardTitle
            title={"Promotions"}
            subtitle={
              isUserIsAdminOrOwner
                ? "Create and offer promotions for your services! Your referral partners will share your promos with their customers. Check out the promos your partners are offering and refer them right from here"
                : "Refer one of the promotions below to your homeowner and earn 💸"
            }
          />
          <FormikProvider value={searchJobPromotionsFormMethods}>
            <JobPromotionsSearchFilterForm
              formMethods={searchJobPromotionsFormMethods}
              isLoading={isLoadingJobPromotions}
            />
          </FormikProvider>
          {isUserIsAdminOrOwner && (
            <Tabs
              value={selectedTab}
              onChange={(_, newValue) => setSelectedTab(newValue)}
            >
              <Tab
                label={"All Promotions"}
                value={JobPromotionTabs.ALL_OFFERS}
              />
              <Tab label={"My Promotions"} value={JobPromotionTabs.MY_OFFERS} />
            </Tabs>
          )}
          <Divider sx={{ width: "100%" }} />
        </Box>
        {renderJobPromotions()}
        {isUserIsAdminOrOwner && (
          <Fab
            color="primary"
            aria-label="add-job-promotion"
            sx={{
              position: "fixed",
              bottom: theme => theme.spacing(3),
              right: theme => theme.spacing(3),
            }}
            onClick={() => {
              setSelectedJobPromotion(undefined);
              addJobPromotionModalState.openModal();
            }}
          >
            <AddIcon />
          </Fab>
        )}
      </Box>
      {isUserIsAdminOrOwner && addJobPromotionModalState.modalIsOpen && (
        <AddJobPromotionDialog
          modalState={addJobPromotionModalState}
          jobPromotion={selectedJobPromotion}
          onSuccess={() => {
            refetchJobPromotions();
          }}
        />
      )}
      {isDefined(selectedJobPromotion) && (
        <JobPromotionDetailsDrawer
          modalState={jobPromotionDetailsModalState}
          jobPromotion={selectedJobPromotion}
        />
      )}
      {isDefined(selectedJobPromotion) && (
        <ConfirmationDialog
          open={deleteJobPromotionModalState.modalIsOpen}
          handleClose={deleteJobPromotionModalState.closeModal}
          handleConfirm={() => {
            deleteJobPromotion({
              jobPromotionId: selectedJobPromotion?.id,
            });
          }}
          text={`Are you sure you want to delete the promotion?`}
          title="Confirm Promotion Deletion"
          confirmText="Delete"
        />
      )}
      {isDefined(selectedJobPromotion) && (
        <Menu
          anchorEl={jobPromotionMoreOptionsAnchorEl}
          open={isDefined(jobPromotionMoreOptionsAnchorEl)}
          sx={{
            borderRadius: "8px",
          }}
          onClose={() => setJobPromotionMoreOptionsAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setJobPromotionMoreOptionsAnchorEl(null);
              addJobPromotionModalState.openModal();
            }}
          >
            <EditIcon sx={{ marginRight: 1, color: "text.secondary" }} />
            <Typography variant="body1" color="text.secondary">
              Edit
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setJobPromotionMoreOptionsAnchorEl(null);
              deleteJobPromotionModalState.openModal();
            }}
          >
            <DeleteIcon sx={{ marginRight: 1, color: "error.main" }} />
            <Typography variant="body1" color="error">
              Delete
            </Typography>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
