import { Route } from "react-router-dom";

import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import PrivacyPolicy from "@/common/PrivacyPolicy";
import TermsOfService from "@/common/TermsOfService";

import { CompanyIdentificationOpenLayout } from "./components/layout/CompanyIdentificationOpenLayout";
import { OpenLayout } from "./components/layout/OpenLayout";
import { ClaimJobLandingPage } from "./components/screens/claim-job/ClaimJobLandingPage";
import { CompanyIdentificationExternalLoggedIn } from "./components/screens/company-identification/CompanyIdentificationExternalLoggedIn";
import { CompanyIdentificationExternalSuccess } from "./components/screens/company-identification/CompanyIdentificationExternalSuccess";
import { CompanyIdentification } from "./components/screens/company-identification/CompanyIdentificationPage";
import { CompanyIdentificationExternal } from "./components/screens/company-identification/CompanyIdentificationPageExternal";
import { CompanySelectionPage } from "./components/screens/company-identification/CompanySelectionPage";
import { ContactSupportPage } from "./components/screens/contact-support/ContactSupportPage";
import { JobPublicPage } from "./components/screens/job/JobPublicPage";
import { JobReferralPage } from "./components/screens/job-referral/JobReferralPage";
import { MarketingPage } from "./components/screens/marketing/MarketingPage";
import { ServiceProviderPage } from "./components/screens/service-provider/ServiceProviderPage";
import { PublicRoutes } from "./constants";

export function OpenRouter() {
  return (
    <Route path={PublicRoutes.BASE}>
      <Route
        path={PublicRoutes.CONTACT_SUPPORT}
        loader={ComponentLoader}
        element={
          <OpenLayout>
            <ContactSupportPage />
          </OpenLayout>
        }
      />
      <Route
        path={PublicRoutes.JOB}
        loader={ComponentLoader}
        element={
          <OpenLayout>
            <JobPublicPage />
          </OpenLayout>
        }
      />
      <Route
        path={PublicRoutes.SERVICE_PROVIDER}
        loader={ComponentLoader}
        element={
          <OpenLayout>
            <ServiceProviderPage />
          </OpenLayout>
        }
      />

      {/* Landing Page routers go here */}
      <Route
        path={PublicRoutes.COMPANY_LANDING}
        loader={ComponentLoader}
        element={
          <OpenLayout>
            <MarketingPage />
          </OpenLayout>
        }
      />
      <Route
        path={PublicRoutes.JOB_REFERRAL}
        loader={ComponentLoader}
        element={<JobReferralPage />}
      />
      <Route
        path={PublicRoutes.TERMS_OF_SERVICE}
        loader={ComponentLoader}
        element={
          <OpenLayout>
            <TermsOfService />
          </OpenLayout>
        }
      />
      <Route
        path={PublicRoutes.PRIVACY_POLICY}
        loader={ComponentLoader}
        element={
          <OpenLayout>
            <PrivacyPolicy />
          </OpenLayout>
        }
      />
      <Route
        path={PublicRoutes.CLAIM_JOB}
        loader={ComponentLoader}
        element={
          <OpenLayout>
            <ClaimJobLandingPage />
          </OpenLayout>
        }
      />
      <Route
        path={PublicRoutes.IDENTIFY_EXTERNAL_COMPANY}
        loader={ComponentLoader}
        element={
          <CompanyIdentificationOpenLayout>
            <CompanyIdentificationExternal />
          </CompanyIdentificationOpenLayout>
        }
      />
      <Route
        path={PublicRoutes.COMPANY_SELECTION}
        loader={ComponentLoader}
        element={
          <CompanyIdentificationOpenLayout>
            <CompanySelectionPage />
          </CompanyIdentificationOpenLayout>
        }
      />
      <Route
        path={`${PublicRoutes.COMPANY_SELECTION}/:changeWorkspace?`}
        loader={ComponentLoader}
        element={
          <CompanyIdentificationOpenLayout>
            <CompanySelectionPage />
          </CompanyIdentificationOpenLayout>
        }
      />
      <Route
        path={PublicRoutes.IDENTIFY_EXTERNAL_COMPANY_LOGGED_IN}
        loader={ComponentLoader}
        element={
          <CompanyIdentificationOpenLayout>
            <CompanyIdentificationExternalLoggedIn />
          </CompanyIdentificationOpenLayout>
        }
      />
      <Route
        path={PublicRoutes.EXTERNAL_COMPANY_IDENTIFIED}
        loader={ComponentLoader}
        element={
          <CompanyIdentificationOpenLayout>
            <CompanyIdentificationExternalSuccess />
          </CompanyIdentificationOpenLayout>
        }
      />
      <Route
        path={PublicRoutes.FDD_COMPANY_IDENTIFIED}
        element={
          <CompanyIdentificationOpenLayout>
            <CompanyIdentification />
          </CompanyIdentificationOpenLayout>
        }
      />
      <Route
        path={PublicRoutes.TERMS_OF_SERVICE}
        loader={ComponentLoader}
        element={
          <OpenLayout>
            <TermsOfService />
          </OpenLayout>
        }
      />
      <Route
        path={PublicRoutes.PRIVACY_POLICY}
        loader={ComponentLoader}
        element={
          <OpenLayout>
            <PrivacyPolicy />
          </OpenLayout>
        }
      />
    </Route>
  );
}
