import {
  type UseMutationResult,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { Job, JobStatus } from "../types";

const UPDATE_JOB_BY_ID_URL = "/jobs/:id";

export type UpdateJobRequest = {
  jobId: string;
  status: JobStatus;
  summary: string;
  routeToHomeowner?: boolean;
};

export type UpdateJobResponse = { job: Job };

export function useUpdateJobById(
  options: UseMutationOptions<void, AxiosError, UpdateJobRequest> = {}
): UseMutationResult<void, AxiosError, UpdateJobRequest> {
  return useMutation({
    mutationFn: async (data: UpdateJobRequest) => {
      const url = UPDATE_JOB_BY_ID_URL.replace(":id", data.jobId.toString());
      return await API.patch(url, changeCaseKeys(data, "underscored"));
    },
    ...options,
  });
}
