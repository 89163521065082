import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { CompanyRoutes } from "@/company/types";

export function WelcomePage() {
  const navigate = useNavigate();
  const onPrevious = () => {
    navigate(-1);
  };
  const onNext = () => {
    navigate(
      getNavigationPathFromRoutePaths([
        CompanyRoutes.BASE,
        CompanyRoutes.DASHBOARD_HOME,
      ])
    );
  };
  const { session } = useAuth();
  const [currentTab, setCurrentTab] = useState<TabsEnumPartners>(
    TabsEnumPartners.MY_PARTNERS
  );

  const user = session?.user;

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          bgcolor: "secondary.main",
          display: "flex",
          height: "160px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TradeEngageLogo logotype={TradeEngageLogoTypes.LOGO_LIGHT} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: "20px",
          p: "200px",
          flex: 1,
        }}
      >
        <Typography variant="h5" component="h3" lineHeight={1} fontWeight={600}>
          Welcome to TradeEngage!
          {user?.firstName ? `, ${_.capitalize(user.firstName)}` : ""}
        </Typography>
        <Typography lineHeight={1} fontWeight={400}>
          Our records indicate that FDD John is the owner of the business. We
          are authenticating your business profile and reviewing your
          application. To ensure the quality of our platform, we due diligence
          bonding, licensing, business and customer reviews to ensure it meets
          our minimum requirements. As we review your profile, please tour the
          TradeEngage platform to unlock your referral network.
        </Typography>
        <Stack spacing={2} direction="row">
          <Button
            variant="outlined"
            sx={{ width: "100px" }}
            onClick={onPrevious}
          >
            Previous
          </Button>
          <Button variant="contained" onClick={onNext}>
            Product Tour
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
