import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import API from "@/common/api";

interface DeleteJobPromotionExclusionRequest {
  jobPromotionId: number;
  jobPromotionExclusionId: number;
}

export function useDeleteJobPromotionExclusion(
  options: UseMutationOptions<
    void,
    AxiosError,
    DeleteJobPromotionExclusionRequest
  > = {}
): UseMutationResult<void, AxiosError, DeleteJobPromotionExclusionRequest> {
  return useMutation({
    mutationFn: async ({
      jobPromotionId,
      jobPromotionExclusionId,
    }): Promise<void> => {
      await API.delete(
        `/job-promotions/${jobPromotionId}/exclusions/${jobPromotionExclusionId}`
      );
    },
    ...options,
  });
}
