import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { JobPromotion } from "../types";

type CreateJobPromotionRequest = Omit<
  JobPromotion,
  "id" | "createdAt" | "updatedAt" | "status"
>;

export function useCreateJobPromotion(
  options: UseMutationOptions<
    JobPromotion,
    AxiosError,
    CreateJobPromotionRequest
  > = {}
): UseMutationResult<JobPromotion, AxiosError, CreateJobPromotionRequest> {
  return useMutation({
    mutationFn: async (data): Promise<JobPromotion> => {
      const response = await API.post(
        "/job-promotions",
        changeCaseKeys(data, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
