import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { enqueueSnackbar } from "notistack";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { snakeCaseJobSectors } from "@/company/utils";

import { UpdateSectorType } from "../ReferralMatrix";
import { JobPriceSettingsProps } from "./JobPriceSettings";
import { JobPricingInfoNotes } from "./JobPricingInfoNotes";

type PayPerReferralPriceSettingsProps = Pick<
  JobPriceSettingsProps,
  | "setPayPerReferral"
  | "payPerReferral"
  | "updatedSectors"
  | "setUpdatedSectors"
> & {
  disabled?: boolean;
};
export const PayPerReferral: FC<PayPerReferralPriceSettingsProps> = (
  props: PayPerReferralPriceSettingsProps
) => {
  const {
    updatedSectors,
    setUpdatedSectors,
    setPayPerReferral,
    payPerReferral,
    disabled,
  } = props;

  const informationNotes = [
    "You’ll pay this amount for every referral you receive",
    "Funds are paid directly to the technician or CSR who referred the job to you",
    "Payments are tracked in the Jobs Dashboard",
  ];

  const { session, isInitialized } = useAuth();

  const [expanded, setExpanded] = useState<boolean>(false);

  // Handler for changing expanded accordion
  const handleChange = () => {
    setExpanded(expanded => !expanded);
  };

  const companySectors = session?.companySectors;

  const handlePayPerReferralChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (disabled) {
      enqueueSnackbar({
        message:
          "At least one job pricing option must remain active. Enable either 'Pay for Every Referral' to reward partners as soon as a referral is received, or 'Pay When Job is Completed' to incentivize job completion.",
        variant: "error",
        includeContactSupportText: false,
      });
      return;
    }
    setPayPerReferral(event.target.checked);
    if (!event.target.checked) {
      if (companySectors) {
        const newSectors = updatedSectors ? { ...updatedSectors } : {};
        for (const sector of companySectors) {
          newSectors[sector.id] = {
            ...(newSectors[sector.id] ? newSectors[sector.id] : {}),
            leadPrice: sector.leadPrice,
          };
        }
        setUpdatedSectors(newSectors);
      }
    }
  };
  const containerRef = useRef(null);
  const [isNarrow, setIsNarrow] = useState(false);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect.width < 600) {
        setIsNarrow(true);
      } else {
        setIsNarrow(false);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  if (!isInitialized) return <>Loading...</>;

  return (
    <Grid ref={containerRef} container p={0}>
      <Grid item xs={12} p={0}>
        <Accordion
          defaultExpanded
          expanded={expanded}
          sx={{
            px: 0.5,
            py: 0.5,
            width: "100%",
            my: "0 !important",
            mx: "0 !important",
            bgcolor: "#F8F9FA",
            borderRadius: 2,
            border: "1px solid #E5E7EB",
            background: "#FFF",
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore onClick={() => handleChange()} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              "& .MuiAccordionSummary-content.Mui-expanded": {
                my: 0,
                mx: 0,
              },
            }}
          >
            {" "}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
              p={0}
            >
              <Typography variant="subtitle1">
                Pay for Every Referral
              </Typography>
              <Switch
                checked={payPerReferral}
                onChange={handlePayPerReferralChange}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ "& .MuiAccordionDetails-root": { py: 0.5 } }}>
            <Grid container px={2} py={0} pb={2}>
              <Grid item xs={12} lg={isNarrow ? 12 : 7} pr={2}>
                <Box
                  display={"flex"}
                  gap={1}
                  pb={1}
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  {companySectors?.map((sector, index) => {
                    return (
                      <>
                        <Box
                          key={sector.sector}
                          display="flex"
                          flexWrap={"wrap"}
                          py={1}
                          gap={2}
                        >
                          <TextField
                            label={"Enter Price"}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              inputProps: { min: 0, max: 100, step: 1 },
                            }}
                            value={updatedSectors?.[sector.id]?.leadPrice}
                            disabled={
                              !session?.groups?.some(isAdministratorOrOwner) ||
                              !payPerReferral
                            }
                            error={!updatedSectors?.[sector.id]?.leadPrice}
                            helperText={
                              !updatedSectors?.[sector.id]?.leadPrice
                                ? "The Referral Fee is required"
                                : ""
                            }
                            sx={{ width: "120px" }}
                            name="new price"
                            type="number"
                            defaultValue={sector.leadPrice}
                            onChange={event => {
                              setUpdatedSectors({
                                ...(updatedSectors ? updatedSectors : {}),
                                [sector.id]: {
                                  sectorId: sector.id,
                                  ...(updatedSectors?.[sector.id]
                                    ? updatedSectors?.[sector.id]
                                    : {}),
                                  leadPrice: event.target.value,
                                },
                              } as unknown as UpdateSectorType);
                            }}
                          />
                          <Typography
                            fontSize={"14px"}
                            fontWeight={"600"}
                            flex={1}
                            pt={"17px"}
                          >
                            {snakeCaseJobSectors[sector.sector]}
                          </Typography>
                        </Box>
                        {index !== companySectors?.length - 1 ? (
                          <Divider />
                        ) : null}
                      </>
                    );
                  })}
                </Box>
                <Divider />
              </Grid>
              <Grid item xs={12} lg={isNarrow ? 12 : 5}>
                <JobPricingInfoNotes notes={informationNotes} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
