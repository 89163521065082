import { Box, Theme, Typography, useMediaQuery } from "@mui/material";

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      textAlign={"start"}
      p={3}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="start"
        height="100%"
        maxWidth={"700px"}
        textAlign={"start"}
        gap={2}
      >
        <Typography
          gutterBottom
          variant={isMobile ? "h4" : "h1"}
          fontWeight={"400"}
        >
          Privacy Policy
        </Typography>
        <Typography variant="body1" fontStyle={"italic"}>
          Updated March 27, 2024
        </Typography>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Introduction</Typography>
          <Typography variant="body1">
            We at TradeEngage LLC (“we,” ​“us,” or ​“TradeEngage”) respect your
            privacy, and we don’t ask for information unless we truly need it.
            We do not collect Personal Information from children under the age
            of 16 without parental consent. As a data processor, we have limited
            control over the information provided to us by our users. When you
            provide data in that sense, you are subject to the Terms of Service.
            In some limited cases, we may collect information ourselves, but
            such collection is limited to the information we need to provide you
            the services.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">The Right to Know</Typography>
          <Typography variant="body1">
            We believe in your right to know what companies are collecting about
            you and why. This Privacy Policy lays out the details about what we
            have and what we use it for. Please review it carefully, and if you
            don’t agree with what we lay out here, please stop using the app and
            contact us with any questions we can answer.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">
            Collection of Personal Information
          </Typography>
          <Typography variant="body1">
            “Personal Information” is information that can be used to identify
            you as an individual, either alone or taken with other information
            we collect. Over the course of providing you the services, we may
            ask for:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" mb={1.8}>
                Contact information such as your name, mobile phone and email
                address;
              </Typography>
            </li>
            <li>
              <Typography variant="body1" mb={1.8}>
                Employment information such as your company name, your title,
                and other company details including address and mobile phone;
              </Typography>
            </li>
            <li>
              <Typography variant="body1" mb={1.8}>
                Financial information such as your billing information; and
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Location information including your address, IP address, and
                location data
              </Typography>
            </li>
          </ul>
          <Typography variant="body1">
            We collect this information in order to provide our services to you,
            generally directly from you. We may request the information
            specifically, or we may collect it through your engagement with
            certain aspects of our services such as message boards or forums. We
            do not collect the content of such messages, but we may collect
            information about the message senders and recipients in order to
            improve our services. We do not sell or otherwise dispose of your
            data, though we may transfer it to certain third parties as outlined
            below.
          </Typography>
          <Typography variant="body1">
            The lawful basis for our collection of your information is listed
            below. Where we collect information with your consent (evidenced by
            your direct provision of the information to us), you can revoke that
            consent at any time.
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" mb={1.8}>
                <Typography variant="body1" display={"inline"} fontWeight={700}>
                  Contact Information
                </Typography>{" "}
                Necessary in order to perform our contract with you
              </Typography>
            </li>
            <li>
              <Typography variant="body1" mb={1.8}>
                <Typography variant="body1" display={"inline"} fontWeight={700}>
                  Employment Information
                </Typography>{" "}
                It is in our legitimate business interest to collect information
                about the types of company using our services. We also use this
                information to connect individual accounts to their organization
                and we only collect this information with your consent
              </Typography>
            </li>
            <li>
              <Typography variant="body1" mb={1.8}>
                <Typography variant="body1" display={"inline"} fontWeight={700}>
                  Financial Information
                </Typography>{" "}
                Necessary in order to perform our contract with you (for billing
                activities)
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <Typography variant="body1" display={"inline"} fontWeight={700}>
                  Location Information
                </Typography>{" "}
                This is automatically collected and used for analytical
                purposes. Your address is collected in order to perform our
                contract with you (for billing purposes), and your IP address
                and location data is anonymized/​not used to personally identify
                you.
              </Typography>
            </li>
          </ul>
          <Typography variant="body1">
            If you interact with a third party service when using our services
            or if you share content from our services through a third party
            service through an integration, we may receive additional Personal
            Information about you. We only request information as set out above,
            and we use this information for our legitimate business interest of
            personalizing the services to improve your experience.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Data Subjects</Typography>
          <Typography variant="body1">
            When our customers use the Services, they often input information
            about their clients (the person whose property they are working on,
            their ​“
            <Typography
              variant="body1"
              fontWeight={700}
              display={"inline"}
              fontStyle={"italic"}
            >
              Clients
            </Typography>
            ”). We treat this information as highly confidential and it is
            entitled to our highest available levels of protection including
            encryption while on our systems. This information often includes
            information that would identify the Client individually, such as:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" mb={1.8}>
                The Client’s address
              </Typography>
            </li>
            <li>
              <Typography variant="body1" mb={1.8}>
                The Client’s mobile number, email address, or other ways to
                contact them.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" mb={1.8}>
                The Client’s name
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Photos or videos of the Client’s property
              </Typography>
            </li>
          </ul>
          <Typography variant="body1">
            We collect this information in order to provide our services to you,
            generally directly from you. We may request the information
            specifically, or we may collect it through your engagement with
            certain aspects of our services such as message boards or forums. We
            do not collect the content of such messages, but we may collect
            information about the message senders and recipients in order to
            improve our services. We do not sell or otherwise dispose of your
            data, though we may transfer it to certain third parties as outlined
            below.
          </Typography>
          <Typography variant="body1">
            If you are a Client of one of our customers, you have certain rights
            related to this data that we have about you. We require that our
            customers notify you that they will be using TradeEngage, and that
            they get your consent before sharing your personal information with
            us. If you feel that your information or photos or videos of your
            property were uploaded to our app without your consent, please
            contact us and we will remove them as quickly as possible. Our
            customers promise that they have your permission to input this
            information/​upload these photos and videos and that the photos and
            videos won’t include any personal information about you. We also
            require that our customers do not input any personal information
            belonging to you where it does not belong (outside of a field
            specifically meant for that piece of information), but we cannot
            guarantee their compliance with this requirement. If there is a
            problem, please see the ​“Your Rights to Your Personal Information”
            section below for more details about your rights in information that
            is about you.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">
            Collection from Third Party Sources
          </Typography>
          <Typography variant="body1">
            We receive information about you from other users, from third party
            services, and from our business and channel partners.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Other Users of the Services</Typography>
          <Typography variant="body1">
            Other users of our services may provide information about you when
            they submit content through the services. For example, you may be
            mentioned by someone else on a job, or a team member may upload
            content about you to TradeEngage. We also receive your email address
            and mobile number from other users when they provide it in order to
            invite you to the services. Similarly, an administrator may provide
            your contact information when they designate you as another
            administrator for a task, team or an organizational account.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">
            Other Services You Link to Your Account
          </Typography>
          <Typography variant="body1">
            We receive information about you when you or your administrator
            enable third party apps or integrate or link a third party service
            with our services. For example, you or your administrator may also
            integrate our services with other services you use, such as to allow
            you to access, store, share and edit certain content from a third
            party through our services. For example, you may authorize our
            services to access and display files from a third party
            document-sharing service within the services interface. The
            information we receive when you link or integrate our services with
            a third party service depends on the settings, permissions and
            privacy policy controlled by that third party service. You should
            always check the privacy settings and notices in these third party
            services to understand what data may be disclosed to us or shared
            with our services.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Other Partners</Typography>
          <Typography variant="body1">
            We receive information about you and your activities on and off the
            services from third party partners, such as advertising and market
            research partners who provide us with information about your
            interest in, and engagement with, our services and online
            advertisements.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Analytics</Typography>
          <Typography variant="body1">
            Like nearly every website owner out there, we use a variety of
            analytical tools to make sure that your experience on our website
            and in our products is as excellent as it can be. Our analytics
            tools (listed below) generally use cookies to perform their
            services. You are able to opt-out of cookies at any time, but please
            be aware that disabling some cookies may cause the services to stop
            working as intended. To opt-out, you can use the native tools in
            your browser.
          </Typography>
          <Typography variant="body1">
            You are encouraged to use the links below to check out the privacy
            policies of our third party vendors. We do not transfer any
            information to these vendors, it is collected by their tools when
            you visit our website and use our services.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">
            Collection of Nonpersonal Information
          </Typography>
          <Typography variant="body1">
            Nonpersonal information is information that cannot be used to
            identify you as an individual, either because it is insufficient to
            identify you without being combined with other collected data, or
            because it has been aggregated and anonymized so that it cannot be
            used to identify you. We collect certain nonpersonal information
            automatically through the use of cookies and other web beacons to
            gather information that we can use to improve the services. This may
            include your browser type, your click information, and other
            information that we can use for analytics. You can disable cookies
            and other web services in order to block this automatic collection,
            but please be aware that disabling some cookies may cause the
            services to top working as intended.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Use of Personal Information</Typography>
          <Typography variant="body1">
            We do not sell your personal information. We only use it for our own
            legitimate business purposes, including certain marketing
            activities. You are free to object to any such processing at any
            time by contacting us at the address set out below, but if you
            choose to do so some of the functions of the services may no longer
            be available to you. Our uses include:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" mb={1.8}>
                Providing you with products and services
              </Typography>
            </li>
            <li>
              <Typography variant="body1" mb={1.8}>
                Providing you with information about our products and services
              </Typography>
            </li>
            <li>
              <Typography variant="body1" mb={1.8}>
                Managing and improving our products, services, quality control,
                administration, communication and internal processes
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Personalizing and customizing your experience with us
              </Typography>
            </li>
            <li>
              <Typography variant="body1">Verifying your identity</Typography>
            </li>
            <li>
              <Typography variant="body1">
                Granting you access to your account with us
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Investigating any enquiries or complaints submitted by or about
                you; and/​or
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Complying with regulatory or legal obligations
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                To protect us, our business, or our users (e.g. to enforce our
                terms of service and/​or use, prevent spam, or investigate or
                protect against fraud)
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                To maintain the security of our products
              </Typography>
            </li>
          </ul>
          <Typography variant="body1">
            We do not share your personal information with third-parties, except
            where required by law, to protect our own rights, or to provide a
            service to you (see below).
          </Typography>
          <Typography variant="body1">
            We will only retain personal information for as long as necessary to
            provide you with a service.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Sharing Personal Information</Typography>
          <Typography variant="body1">
            We will not share your Personal Information with third-parties,
            except where required by law, to protect our own rights, or to
            provide a service to you. We may, however, disclose anonymized and
            aggregated versions of this information for business, marketing or
            public relations purposes. If we share any information, we seek
            assurances that these contractors agree to (1) protect the privacy
            of your personally identifiable information consistent with this
            Privacy Policy and (2) not use or disclose your personally
            identifiable information for any purpose other than providing us
            with the products or services for which we contracted or as required
            by law.
          </Typography>
          <Typography variant="body1">
            We may also share your Personal Information with law enforcement if
            we believe we are required to do so in order to comply with
            applicable law, or other lawful order by a court or law enforcement.
            We may disclose Personal Information in special circumstances when
            we have reason to believe that disclosing this information is
            necessary to identify, contact, or bring legal action against
            someone who may be violating our Terms of Service, to detect fraud,
            or to protect the safety and/​or security of our users, the
            TradeEngage services or the general public.
          </Typography>
          <Typography variant="body1">
            We also may be required to disclose an individual’s personal
            information in response to a lawful request by public authorities,
            including to meet national security or law enforcement requirements.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Feedback</Typography>
          <Typography variant="body1">
            From time to time, you may be asked to provide Feedback (as such
            term is defined in the Terms of Service) related to the Services.
            Provision of Feedback is optional. If you choose to provide
            Feedback, we may use your Feedback in the form of a written
            testimonial posted online. Absent your permission, we will not
            attribute such Feedback directly to you, but we may attribute it
            generally to you. We will not use any personally-identifiable
            information in such feedback without your permission, which we may
            request via email or other communication with you.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Cookies</Typography>
          <Typography variant="body1">
            We use ​“cookies” to collect information about you and your activity
            across our site. A cookie is a small piece of data that our website
            stores on your computer, and accesses each time you visit so we can
            understand how you use our site and serve you content based on
            preferences you have specified.
          </Typography>
          <Typography variant="body1">
            If you do not wish to accept cookies from us, you should instruct
            your browser to refuse cookies from our website, with the
            understanding that we may be unable to provide you with some of your
            desired service without them. This policy covers only the use of
            cookies between your computer and our website; it does not cover the
            use of cookies by any advertisers.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Essential Cookies</Typography>
          <Typography variant="body1">
            Essential cookies are crucial to your experience of a website,
            enabling core features like user logins, account management,
            shopping carts and payment processing. We use essential cookies to
            enable certain functions on our website and App.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Performance Cookies</Typography>
          <Typography variant="body1">
            Performance cookies are used in the tracking of how you use a
            website during your visit, without collecting personal information
            about you. Typically, this information is anonymous and aggregated
            with information tracked across all site users, to help companies
            understand visitor usage patterns, identify and diagnose problems or
            errors their users may encounter, and make better strategic
            decisions in improving their audience’s overall website experience.
            These cookies may be set by the website you’re visiting (first
            party) or by third party services. We use performance cookies on our
            website and App.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Functionality Cookies</Typography>
          <Typography variant="body1">
            Functionality cookies are used in collecting information about your
            device and any settings you may configure on the website you’re
            visiting (like language and time zone settings). With this
            information, websites can provide you with customized, enhanced or
            optimized content and services. These cookies may be set by the
            website you’re visiting (first-party) or by third party service. We
            use functionality cookies for selected features on our website and
            App.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Targeting/​Advertising Cookies</Typography>
          <Typography variant="body1">
            Targeting/​advertising cookies are used in determining what
            promotional content is more relevant and appropriate to you and your
            interests. Websites may use them to deliver targeted advertising or
            to limit the number of times you see an advertisement. This helps
            companies improve the effectiveness of their campaigns and the
            quality of content presented to you. These cookies may be set by the
            website you’re visiting (first-party) or by third party services.
            Targeting/​advertising cookies set by third-parties may be used to
            track you on other websites that use the same third party service.
            We use targeting/​advertising cookies on our website and App.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Third party cookies on our site</Typography>
          <Typography variant="body1">
            We may employ third party companies and individuals on our
            websites — for example, analytics providers and content partners. We
            grant these third parties access to selected information to perform
            specific tasks on our behalf. They may also set third party cookies
            in order to deliver the services they are providing. Third party
            cookies can be used to track you on other websites that use the same
            third party service. As we have no control over third party cookies,
            they are not covered by our cookie policy.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">
            Your Rights to Your Personal Information
          </Typography>
          <Typography variant="body1">
            We believe in your personal rights to your Personal Information.
            Therefore, we honor and recognize the following rights that you have
            over such information:
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">The right to know –</Typography>
          <Typography variant="body1">
            This Privacy Policy is here to disclose to you what we have and what
            we use it for. If you have any questions about the data we have, you
            can always ask.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">
            The right to opt-out of the sale of your data –
          </Typography>
          <Typography variant="body1">
            We do not sell your data at any time, so there’s no need for you to
            opt out.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">The right to be forgotten –</Typography>
          <Typography variant="body1">
            You can request that we delete all or some of your data from our
            systems.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">The right to withdraw consent –</Typography>
          <Typography variant="body1">
            If we are processing your Personal Information based on your consent
            (as indicated at the time of collection of such data), you have the
            right to withdraw your consent at any time. Please note, however,
            that if you exercise this right, you may have to then provide
            express consent on a case-by-case basis for the use or disclosure of
            certain of your Personal Information, if such use or disclosure is
            necessary to enable you to utilize some or all of our services.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">The right to object –</Typography>
          <Typography variant="body1">
            You can contact us to let us know that you object to the further use
            or disclosure of your Personal Information for certain purposes,
            such as for direct marketing purposes.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">
            The right to restrict processing –
          </Typography>
          <Typography variant="body1">
            You can ask us to restrict further processing of your Personal
            Information
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">The right to correct –</Typography>
          <Typography variant="body1">
            You can request that we correct any Personal Information we hold
            about you that may be incorrect or incomplete
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">The right to access –</Typography>
          <Typography variant="body1">
            You can request more information about the information we have about
            you and can request a copy of that data by contacting us at the
            contact information set out below.
          </Typography>
          <Typography variant="body1">
            If you live in the EU, you also have the right to file a complaint
            about our data practices with the supervisory authority in your
            country or EU member state.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">California Privacy Rights</Typography>
          <Typography variant="body1">
            TradeEngage complies with the California Consumer Privacy Act
            (referred to in this Privacy Policy as ​“CCPA”) as set forth by the
            State of California. If the CCPA applies to your personal
            information this Privacy Policy provides you with information
            regarding how to receive information about our privacy practices, as
            well as request access to, and deletion of your personal
            information.
          </Typography>
          <Typography variant="body1">
            California consumers may make a request pursuant to their rights
            under the CCPA by contacting us using the contact information below.
            Upon receiving a request, we will verify your request using the
            information associated with the services, including your email
            address. Government identification may be required. Consumer can
            also designate an authorized agent to exercise these rights on the
            consumer’s behalf.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Business Transfers</Typography>
          <Typography variant="body1">
            If we or our assets are acquired, or in the unlikely event that we
            go out of business or enter bankruptcy, we would include user
            information among our assets transferred to or acquired by a third
            party. You acknowledge that such transfers may occur, and that any
            parties who acquire us may continue to use your personal information
            according to this policy.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Security</Typography>
          <Typography variant="body1">
            We take security seriously and do what we can within commercially
            acceptable means to protect your personal information from loss or
            theft, as well as unauthorized access, disclosure, copying, use or
            modification. That said, we advise that no method of electronic
            transmission or storage is 100% secure and cannot guarantee the
            absolute security of your data.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Links to Other Sites</Typography>
          <Typography variant="body1">
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            practices of these sites and cannot assume responsibility for their
            treatment of your personal information. This Privacy Policy only
            covers our website and App and privacy practices.
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1.8}>
          <Typography variant="h6">Changes to Our Privacy Policy</Typography>
          <Typography variant="body1">
            At our discretion, we may change this Privacy Policy from time to
            time. Any changes will be reflected here, so we encourage you to
            visit this page regularly. We will also notify our registered users
            of updates to our policy. Your continued use of this site after any
            changes to this policy will be regarded as acceptance of our
            practices around privacy and personal information.
          </Typography>
          <Typography variant="body1">
            If you have any questions about this Privacy Policy or if you wish
            to make any complaint or claim with respect to the Website, App or
            Service, please contact us at:{" "}
            <a href="mailto:info@tradeengage.com" style={{ color: "black" }}>
              info@tradeengage.com
            </a>
            .
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
