import { Add as AddIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { Seo } from "@/common/Seo/Seo";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useFetchUsersWithGroups } from "@/company/api/useFetchUsersWithGroups";
import {
  useMutationActivateUser,
  useMutationDeleteGroupUser,
  useQueryFetchCompanyGroups,
} from "@/company/onboarding/api";
import { AddStaffForm } from "@/company/onboarding/components/screen/operations-sales-info/AddStaffForm";
import { AddStaffModal } from "@/company/onboarding/components/screen/operations-sales-info/AddStaffModal";
import { EditStaffModal } from "@/company/onboarding/components/screen/operations-sales-info/EditStaffModal";
import { StaffTable } from "@/company/onboarding/components/screen/operations-sales-info/StaffTable";
import {
  STAFF_TYPES,
  StaffData,
} from "@/company/onboarding/components/screen/operations-sales-info/types";
import { getCompanyGroupIdFromName } from "@/company/onboarding/components/screen/operations-sales-info/utils";
import { DefaultCompanyGroups, PLATFORM_PRESENCE } from "@/company/types";
import { UserOnboardingStatus } from "@/user/types";

export function TeamMembers() {
  const tabs = Object.values(STAFF_TYPES).map(staffType => ({
    label: staffType,
    value: staffType,
  }));

  const [currentTab, setCurrentTab] = useState<string>(STAFF_TYPES.OPERATIONS);

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: string): void => {
      setCurrentTab(value);
    },
    []
  );

  const initialStaffDataObj: Record<STAFF_TYPES, Array<StaffData>> = {
    [STAFF_TYPES.OPERATIONS]: [],
    [STAFF_TYPES.FIELD_TEAM]: [],
  };
  const [staffDataObj, setStaffDataObj] = useState(initialStaffDataObj);

  const [selectedStaffIndex, setSelectedStaffIndex] = useState<
    number | undefined
  >();
  const { session } = useAuth();

  const { data: companyGroups, isLoading: fetchingCompanyGroups } =
    useQueryFetchCompanyGroups(session?.company?.id);

  const {
    data: userWithGroups,
    refetch: refetchUsers,
    isLoading: fetchingUsersWithGroups,
  } = useFetchUsersWithGroups();

  const { mutateAsync: deleteUser, isLoading: deletingUser } =
    useMutationDeleteGroupUser({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while deleting team member.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        refetchUsers();
        enqueueSnackbar({
          message: "Deleted user successfully.",
          variant: "success",
        });
      },
    });

  const { mutateAsync: activateUser, isLoading: activatingUser } =
    useMutationActivateUser({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while inviting team member.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess() {
        refetchUsers();
        enqueueSnackbar({
          message: "Invited user successfully.",
          variant: "success",
        });
      },
    });
  /**
   * Use effect to populate default staff data from already existing users
   */
  useEffect(() => {
    const defaultStaffObject: Record<STAFF_TYPES, Array<StaffData>> = {
      [STAFF_TYPES.OPERATIONS]: [],
      [STAFF_TYPES.FIELD_TEAM]: [],
    };

    userWithGroups?.forEach(userWithGroup => {
      const { groups, user } = userWithGroup;
      /**
       * We need to show administrator as both a field team and operations member
       */
      if (groups[0].name === DefaultCompanyGroups.ADMINISTRATOR) {
        defaultStaffObject[STAFF_TYPES.FIELD_TEAM].push({
          ...user,
          onboardingStatus: user.lastLoginAt
            ? UserOnboardingStatus.ONBOARDED
            : user.platformPresence == PLATFORM_PRESENCE.ON_PLATFORM
            ? UserOnboardingStatus.INVITED
            : UserOnboardingStatus.CREATED,
          role: groups[0].name as DefaultCompanyGroups,
        } as StaffData);
        defaultStaffObject[STAFF_TYPES.OPERATIONS].push({
          ...user,
          onboardingStatus: user.lastLoginAt
            ? UserOnboardingStatus.ONBOARDED
            : user.platformPresence == PLATFORM_PRESENCE.ON_PLATFORM
            ? UserOnboardingStatus.INVITED
            : UserOnboardingStatus.CREATED,
          role: groups[0].name as DefaultCompanyGroups,
        } as StaffData);
      } else {
        /**
         * Logic to show member belonging to field team show them in a separate tab
         */
        const staffType =
          groups[0].name === DefaultCompanyGroups.FIELD_TEAM
            ? STAFF_TYPES.FIELD_TEAM
            : STAFF_TYPES.OPERATIONS;
        defaultStaffObject[staffType].push({
          ...user,
          onboardingStatus: user.lastLoginAt
            ? UserOnboardingStatus.ONBOARDED
            : user.platformPresence == PLATFORM_PRESENCE.ON_PLATFORM
            ? UserOnboardingStatus.INVITED
            : UserOnboardingStatus.CREATED,
          role: groups[0].name as DefaultCompanyGroups,
        } as StaffData);
      }
    });
    setStaffDataObj(defaultStaffObject);
  }, [userWithGroups]);

  const onModalClose = () => {
    refetchUsers();
  };

  const onEditClose = () => {
    if (selectedStaffIndex !== undefined && selectedStaffIndex >= 0) {
      refetchUsers();
    }
  };

  const onEditMember = (index: number) => {
    setSelectedStaffIndex(index);
  };

  const onRemoveMember = (staffType: STAFF_TYPES, index: number) => {
    const newStaffData = [...staffDataObj[staffType]];
    const deletedUser = newStaffData.find(member => member.id === index);
    if (deletedUser)
      deleteUser({
        userId: deletedUser.id,
        groupId: getCompanyGroupIdFromName(
          companyGroups ?? [],
          deletedUser.role ?? ""
        ),
      });
  };
  const onActivateMember = (staffType: STAFF_TYPES, index: number) => {
    const newStaffData = [...staffDataObj[staffType]];
    const activatingUser = newStaffData.find(member => member.id === index);
    if (activatingUser)
      activateUser({
        userId: activatingUser.id,
        groupId: getCompanyGroupIdFromName(
          companyGroups ?? [],
          activatingUser.role ?? ""
        ),
      });
  };

  const [addModalOpen, setAddModalOpen] = useState(false);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <>
      <Seo title="Team Members" />
      <Grid container>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          position={"sticky"}
          sx={{ top: "56px", zIndex: 400 }}
          bgcolor={"white"}
          pb={2}
          pt={1}
        >
          <Box>
            {isMobile ? null : (
              <Typography gutterBottom variant="h5">
                Team Members
              </Typography>
            )}
            <Typography variant="body2" color="text.secondary">
              Invite your team to approve jobs and your Field Team to submit
              jobs and earn from their own referrals!
            </Typography>
          </Box>
        </Grid>
        {fetchingUsersWithGroups || fetchingCompanyGroups || deletingUser ? (
          <Box py={3} textAlign="center" width="100%">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container py={3}>
            <Grid item xs={12} lg={4} pr={isMobile ? 0 : 3}>
              {isMobile ? (
                <Box
                  display={"flex"}
                  pt={2}
                  width={"100%"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      lineHeight: "24px",
                      letterSpacing: 0.5,
                      wordWrap: "break-word",
                    }}
                  >
                    All Team Members
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => setAddModalOpen(true)}
                  >
                    New Team Member
                  </Button>
                </Box>
              ) : (
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    columnGap: "8px",
                    padding: "24px",
                    boxShadow: "0px 0px 0px 1px #F2F4F7",
                    "&:active": {
                      border: "1px solid #071D2F",
                    },
                    "&:hover": {
                      boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.08)",
                    },
                    "&:focus": {
                      border: "1px solid #071D2F",
                    },
                    minWidth: "320px",
                  }}
                >
                  <AddStaffForm
                    staffType={currentTab as STAFF_TYPES}
                    onClose={onModalClose}
                  />
                </Card>
              )}
            </Grid>
            <Grid item xs={12} lg={8}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  columnGap: "8px",
                  ...(isMobile
                    ? {
                        p: 0,
                        border: "none",
                        boxShadow: "none",
                        borderRadius: 0,
                      }
                    : { padding: "8px" }),
                  width: "100%",
                  height: "100%",
                }}
              >
                {isMobile ? null : (
                  <Typography gutterBottom variant="overline" lineHeight={1}>
                    All Team Members
                  </Typography>
                )}
                <Tabs
                  indicatorColor="primary"
                  scrollButtons={false}
                  textColor="primary"
                  value={currentTab}
                  variant="scrollable"
                  sx={{
                    paddingBottom: "8px",
                  }}
                  onChange={handleTabsChange}
                >
                  {tabs.map(tab => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </Tabs>
                {currentTab === STAFF_TYPES.OPERATIONS && (
                  <>
                    <StaffTable
                      key={currentTab}
                      staffType={currentTab}
                      staff={staffDataObj[currentTab].map(member => ({
                        ...member,
                        name: member.firstName + " " + member.lastName,
                      }))}
                      refetchUsers={refetchUsers}
                      isActivatingUser={activatingUser}
                      activateUser={onActivateMember}
                      onEditMember={onEditMember}
                      onRemoveMember={onRemoveMember}
                    />
                  </>
                )}
                {currentTab === STAFF_TYPES.FIELD_TEAM && (
                  <>
                    <StaffTable
                      key={currentTab}
                      staffType={currentTab}
                      staff={staffDataObj[currentTab].map(member => ({
                        ...member,
                        name: member.firstName + " " + member.lastName,
                        onboardingStatus: member.onboardingStatus,
                      }))}
                      refetchUsers={refetchUsers}
                      isActivatingUser={activatingUser}
                      activateUser={onActivateMember}
                      onEditMember={onEditMember}
                      onRemoveMember={onRemoveMember}
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
        {selectedStaffIndex !== undefined && selectedStaffIndex >= 0 ? (
          <EditStaffModal
            isOpen={selectedStaffIndex !== undefined && selectedStaffIndex >= 0}
            staffData={{
              ...staffDataObj[currentTab as STAFF_TYPES]?.find(
                (member, index) => member.id === selectedStaffIndex
              ),
              staffType: currentTab as STAFF_TYPES,
            }}
            staffType={currentTab as STAFF_TYPES}
            onClose={() => {
              setSelectedStaffIndex(undefined);
            }}
            onSave={() => {
              onEditClose();
              setSelectedStaffIndex(undefined);
            }}
          />
        ) : null}
      </Grid>
      {addModalOpen ? (
        <AddStaffModal
          staffType={currentTab as STAFF_TYPES}
          isOpen={addModalOpen}
          onClose={() => {
            setAddModalOpen(false);
            refetchUsers();
          }}
        />
      ) : null}
    </>
  );
}
