import { Box, Typography } from "@mui/material";

import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";

const CustomerRepresentative = ({ name }: { name: string }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Typography variant="subtitle2">
        Customer Service Representative
      </Typography>
      <Box display={"flex"} gap={2} alignItems={"baseline"}>
        <CompanyAvatar name={name} width="32px" height="32px" />
        <Box display={"flex"} gap={1} flexDirection={"column"}>
          <Typography variant="body1" color={"text.secondary"}>
            {name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerRepresentative;
