import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";

import { BasicCompanySchema } from "@/company/api/useGellAllCompanies";

import { useBrandOverlapSearchFilterContext } from "../contexts/SearchFilterContext";

export default function BrandInterestsCheckboxList({
  isAllChecked,
  allBrands,
}: {
  isAllChecked: boolean;
  allBrands: Array<BasicCompanySchema>;
}) {
  const { addFilter, filters } = useBrandOverlapSearchFilterContext();

  return (
    <Box>
      <Autocomplete
        multiple
        limitTags={2}
        id="brands"
        sx={{
          width: "100%",
        }}
        value={filters.otherBrands ?? []}
        getOptionLabel={(option: BasicCompanySchema) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id} // Ensures correct comparison for selection
        options={allBrands ?? []} // Data is already an array of `CompanyPublicProfile`
        renderInput={params => (
          <TextField
            {...params}
            required
            id="name"
            name="name"
            label="Company Name"
            inputProps={{
              ...params.inputProps,
              "data-hj-allow": true,
              style: {
                whiteSpace: "nowrap",
                overflowX: "hidden",
                textOverflow: "ellipsis",
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box
                  sx={{
                    maxHeight: "100px", // Define the max height for tags container
                    overflowY: "auto", // Enable vertical scroll
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {params.InputProps.startAdornment}
                </Box>
              ),
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props} key={option.id} value={option}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {<Typography>{option.name}</Typography>}
              {selected ? <CheckCircleOutlineIcon color="primary" /> : null}
            </Box>
          </MenuItem>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              size="small"
              label={option.name}
              {...getTagProps({ index })}
              variant="outlined"
            />
          ))
        }
        onBlur={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
        onChange={(
          _: React.SyntheticEvent,
          newValue: BasicCompanySchema[] | null
        ) => {
          if (newValue?.some(company => company.id === "all")) {
            addFilter({ otherBrands: allBrands ? allBrands : [] });
          } else {
            addFilter({ otherBrands: newValue ?? [] });
          }
        }}
      />
    </Box>
  );
}
