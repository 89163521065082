import { Box, Chip, Drawer, LinearProgress, Typography } from "@mui/material";
import {
  DataGridPremium,
  GridComparatorFn,
  GridEventListener,
} from "@mui/x-data-grid-premium";
import { DataGridProProps, GridColDef } from "@mui/x-data-grid-pro";
import * as React from "react";

import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { JobSectors } from "@/company/jobs/constants";
import { snakeCaseJobSectors } from "@/company/utils";
import { drawerWidth, renderJobSectorsArea } from "@/internal-admin/utils";
import { isDefined } from "@/utils/isDefined";

import { BrandDrawer } from "./BrandDrawer";

const sectorComparator: GridComparatorFn<Array<string>> = (v1, v2) => {
  const minLength = Math.min(v1.length, v2.length);

  for (let i = 0; i < minLength; i++) {
    if (v1[i] < v2[i]) return -1;
    if (v1[i] > v2[i]) return 1;
  }

  // If all elements are equal so far, the shorter array comes first
  return v1.length - v2.length;
};

const rows = [
  {
    hierarchy: ["1-800 Junk"],
    companyName: "1-800 Junk",
    logoUrl:
      "https://app-tradeengage.s3.amazonaws.com/Brand/Mobiledumps_Logo.png",
    overlap: 12,
    id: 0,
  },
  {
    hierarchy: ["1-800 Junk", "1-800-GOT-JUNK? San Diego North"],
    companyName: "1-800-GOT-JUNK? San Diego North",
    overlap: 12,
    logoUrl:
      "https://app-tradeengage.s3.amazonaws.com/Brand/Mobiledumps_Logo.png",
    id: 2,
  },
  {
    hierarchy: ["1-800 Junk", "1-800-Got-Junk? Of San Diego South"],
    companyName: "1-800-Got-Junk? Of San Diego South",
    overlap: 12,
    logoUrl:
      "https://app-tradeengage.s3.amazonaws.com/Brand/Mobiledumps_Logo.png",
    id: 3,
  },
];

const columns: GridColDef<typeof rows[number]>[] = [
  {
    field: "sectors",
    headerName: "Service Categories",
    editable: true,
    type: "singleSelect",
    valueOptions: Object.values(JobSectors),
    width: 200,
    sortComparator: sectorComparator,
    renderEditCell: renderJobSectorsArea,
    valueFormatter: value => `${snakeCaseJobSectors[value]}`,
    renderCell: params => {
      const sectorChips = params.row?.sectors?.map(sector => (
        <Chip
          key={sector}
          sx={{
            color: "text.secondary",
            bgcolor: "#F8F9FA",
          }}
          label={snakeCaseJobSectors[sector]}
          size="small"
        />
      ));
      return sectorChips;
    },
  },
  { field: "brandId", headerName: "Brand Id", width: 100 },
  {
    field: "logo",
    headerName: "Logo",
    sortable: false,
    filterable: false,
    renderCell: params => {
      return (
        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          height={"100%"}
        >
          <CompanyAvatar
            logo={params.row.logoUrl || undefined}
            name={params.row.companyName}
            height={"32px"}
            width={"48px"}
            bgColor="white"
            border="1px solid gray !important"
            isAdminProfile={true}
          />
        </Box>
      );
    },
  },
  {
    field: "overlap",
    headerName: "Brand Overlap",
    width: 420,
    renderCell: params => (
      <Box
        width="100%"
        display={"flex"}
        justifyContent={"start"}
        alignItems={"start"}
        flexDirection={"column"}
        height={"100%"}
        gap={0.2}
        my={0.3}
      >
        {" "}
        <Box
          width="100%"
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          height={"100%"}
          gap={1}
        >
          <LinearProgress
            variant="determinate"
            value={params.row.overlap}
            style={{ flexGrow: 1, height: 8, borderRadius: 4 }}
          />
          <Typography variant="caption" color="primary.main">
            {params.row.overlap}%
          </Typography>
        </Box>{" "}
        {params.row.totalBrandLocationsOfOtherBrandThatOverlap &&
        params.row.totalBrandLocationsOfMYBrandThatOverlap ? (
          <Box
            sx={{
              maxWidth: "400px",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                maxWidth: "400px",
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              <Typography
                variant="subtitle2"
                display="inline"
                sx={{
                  maxWidth: "400px",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  color: "#16B364",
                }}
              >
                {params.row.totalBrandLocationsOfMYBrandThatOverlap}
              </Typography>{" "}
              <Typography
                variant="subtitle2"
                fontWeight={600}
                color="text.secondary"
                display="inline"
                sx={{
                  maxWidth: "400px",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {params.row.hierarchy[0]}
              </Typography>{" "}
              Location
              {params.row.totalBrandLocationsOfMYBrandThatOverlap > 1
                ? "s"
                : ""}{" "}
              can collaborate with{" "}
              <Typography
                variant="subtitle2"
                display="inline"
                sx={{
                  color: "#16B364",
                  maxWidth: "400px",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {params.row.totalBrandLocationsOfOtherBrandThatOverlap}
              </Typography>{" "}
              <Typography
                variant="subtitle2"
                fontWeight={600}
                color="text.secondary"
                display="inline"
                sx={{
                  maxWidth: "400px",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {params.row.hierarchy[1]}
              </Typography>{" "}
              Location
              {params.row.totalBrandLocationsOfOtherBrandThatOverlap > 1
                ? "s"
                : ""}
            </Typography>
          </Box>
        ) : null}
      </Box>
    ),
  },
];

const getTreeDataPath: DataGridProProps["getTreeDataPath"] = row =>
  row.hierarchy;

export default function BrandOverlapTable({
  rows,
  expanded,
  setExpanded,
  brandId,
  isLoading,
  setBrandId,
}: {
  rows: Array<any>;
  expanded: boolean;
  isLoading: boolean;
  setExpanded: (value: boolean) => void;
  brandId: boolean;
  setBrandId: (value: number | null) => void;
}) {
  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (params.row.hierarchy?.length === 1) setBrandId(params.row.brandId);
  };

  const brands = rows.map((row, index) => ({ ...row, id: index }));

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
    >
      <DataGridPremium
        treeData
        loading={isLoading}
        rows={brands}
        columns={columns}
        rowHeight={80}
        groupingColDef={{ headerName: "Name", width: "350" }}
        getTreeDataPath={getTreeDataPath} // Rename grouping column header
        onCellClick={handleCellClick}
      />
      {expanded && isDefined(brandId) ? (
        <BrandDrawer
          companyId={brandId}
          expanded={expanded}
          onClose={() => {
            setBrandId(null);
            setExpanded(false);
          }}
          onExpand={() => setExpanded(false)}
        />
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!isDefined(brandId)}
        >
          {isDefined(brandId) ? (
            <BrandDrawer
              open={!!isDefined(brandId)}
              expanded={expanded}
              companyId={brandId}
              onClose={() => {
                setBrandId(null);
                setExpanded(false);
              }}
              onCellClick={handleCellClick}
              onExpand={() => setExpanded(true)}
            />
          ) : null}
        </Drawer>
      )}
    </Box>
  );
}
