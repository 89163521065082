import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import changeCaseKeys from "change-case-keys";

import API from "@/common/api";

import { Chat } from "./useFetchChats";

export function useUpdateChat(
  options: UseMutationOptions<
    Chat,
    AxiosError,
    Pick<Chat, "toCompanyId" | "message">
  > = {}
): UseMutationResult<Chat, AxiosError, Pick<Chat, "toCompanyId" | "message">> {
  return useMutation({
    mutationFn: async (
      chat: Pick<Chat, "toCompanyId" | "message">
    ): Promise<Chat> => {
      const response = await API.post(
        "chats",
        changeCaseKeys(chat, "underscored")
      );
      return response.data.data;
    },
    ...options,
  });
}
