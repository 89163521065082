import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { CryptoCard } from "@/company/components/company-payments-tab/crypto-card";
import { neutral } from "@/Theme/colors";

import { useGetCompanyCardById } from "./api/useGetPaymentCardById";

export function CompanyCardDetails(props: {
  showCardDialog: boolean;
  setShowCardDialog: (value: boolean) => void;
  disableAddCard?: boolean;
  companyId: number;
}) {
  const { showCardDialog, setShowCardDialog, companyId } = props;
  const { session } = useAuth();

  const { isFetching: isFetchingCard, data: card } = useGetCompanyCardById(
    companyId,
    {
      refetchOnWindowFocus: false,
      onError: () => {
        enqueueSnackbar("There was an error while fetching your card.", {
          variant: "error",
        });
      },
    }
  );

  const stripeElements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();

  const showErrorNotification = (errorMessage?: string) => {
    enqueueSnackbar({
      variant: "error",
      message: errorMessage ?? `There was an error while connecting to stripe.`,
    });
  };

  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [holderName, setHolderName] = useState("");
  const [validationError, setValidationError] = useState("");

  const onSubmitCardDetails = async () => {
    if (!stripe || !stripeElements) {
      showErrorNotification();
      return;
    }
    const cardCvcElement = stripeElements.getElement(CardCvcElement);
    const cardNumberElement = stripeElements.getElement(CardNumberElement);
    const cardExpiryElement = stripeElements.getElement(CardExpiryElement);
    if (!cardCvcElement || !cardNumberElement || !cardExpiryElement) {
      showErrorNotification();
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    if (result.error || !result.paymentMethod.card) {
      showErrorNotification(
        result.error?.message ??
          "There was an error connecting to stripe, please try again after sometime."
      );
      setIsStripeLoading(false);
      return;
    }

    if (!holderName) {
      setValidationError("The cardholder's name is required.");
    }

    const { token, error } = await stripe.createToken(cardNumberElement, {
      name: holderName,
    });
    if (error || !token) {
      showErrorNotification(error?.message);
      setIsStripeLoading(false);
      return;
    }
    setIsStripeLoading(false);
  };

  return (
    <>
      <Card
        sx={{
          p: 2,
          borderRadius: "20px",
          border: "1px solid #F3F4F6",
          position: "relative",
          width: "400px",
        }}
        elevation={0}
      >
        <Typography variant="h6">Card Information</Typography>
        <Divider sx={{ width: "100%", my: 2 }} />
        {isFetchingCard || !stripe || !stripeElements ? (
          <Box my={3} textAlign="center">
            <CircularProgress />
          </Box>
        ) : card ? (
          <>
            <CryptoCard card={card} />
            <Box
              sx={{
                width: "100%",
                my: 3,
              }}
            >
              <Button
                fullWidth
                disabled={
                  !session?.groups?.some(isAdministratorOrOwner) ||
                  props.disableAddCard
                }
                variant="contained"
                color="primary"
                onClick={() => setShowCardDialog(true)}
              >
                Update Card
              </Button>
            </Box>
          </>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignContent="center"
          >
            <Box textAlign="center">
              <img
                src="/no-card.svg"
                height="100%"
                style={{ margin: "16px" }}
                alt="No Card Added"
              />
              <Typography gutterBottom variant="h6">
                No Card Added
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary">
                Add your card here to replenish your credits whenever you run
                out
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                my: 2,
              }}
            >
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={
                  !session?.groups?.some(isAdministratorOrOwner) ||
                  props.disableAddCard
                }
                onClick={() => setShowCardDialog(true)}
              >
                Add Card
              </Button>
            </Box>
          </Box>
        )}
      </Card>
      <Dialog
        open={showCardDialog}
        onClose={() => !isStripeLoading && setShowCardDialog(false)}
      >
        <DialogTitle component="div">
          <Typography gutterBottom variant="h5">
            Add Credit Card
          </Typography>
        </DialogTitle>
        <IconButton
          disabled={isStripeLoading}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
          onClick={() => setShowCardDialog(false)}
        >
          <CloseIcon />
        </IconButton>
        <Box px={3} pb={3}>
          <Divider sx={{ width: "100%", mb: 2 }} />
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" textAlign={"right"}>
                Card Number
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <CardNumberElement
                options={{
                  iconStyle: "default",
                  style: {
                    base: {
                      fontSize: "0.875rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      fontFamily:
                        '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                      "::placeholder": {
                        fontWeight: 300,
                        color: neutral[400],
                      },
                    },
                  },
                }}
                onChange={e => {
                  e.error && setValidationError(e.error.message);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" textAlign={"right"}>
                Expiration Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      fontSize: "0.875rem",
                      fontWeight: 500,

                      fontFamily:
                        '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                      "::placeholder": {
                        fontWeight: 300,
                        color: neutral[400],
                      },
                    },
                  },
                }}
                onChange={e => {
                  e.error && setValidationError(e.error.message);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" textAlign={"right"}>
                CVC
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      fontSize: "0.875rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      fontFamily:
                        '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                      "::placeholder": {
                        fontWeight: 300,
                        color: neutral[400],
                      },
                    },
                  },
                }}
                onChange={e => {
                  e.error && setValidationError(e.error.message);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" textAlign={"right"}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                value={holderName}
                placeholder="Cardholder Name"
                InputProps={{ disableUnderline: true }}
                sx={{
                  "& input::placeholder": {
                    fontWeight: 300,
                    fontSize: "0.875rem",
                    color: neutral[400],
                  },
                }}
                onChange={e => setHolderName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            color="primary"
            variant="contained"
            sx={{ my: 2 }}
            loading={isStripeLoading}
            disabled={true}
            onClick={onSubmitCardDetails}
          >
            Add Card
          </LoadingButton>
          <Typography
            variant="body2"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            Powered by
            <img
              src="/stripe-logo.svg"
              alt="stripe"
              width="34px"
              height="14px"
              style={{ marginLeft: "4px" }}
            />
          </Typography>
          {/* <Alert
            variant="outlined"
            icon={<Warning color="error" />}
            sx={{
              visibility: validationError ? "visible" : "hidden",
              my: 2,
              backgroundColor: "error.light",
              color: "error.main",
              borderColor: "error.main",
            }}
          >
            {validationError}
          </Alert> */}
        </Box>
      </Dialog>
    </>
  );
}
