import {
  Button,
  Card,
  CardHeader,
  Grid,
  Stack,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { enqueueSnackbar } from "notistack";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { useFetchCompanyTerritory } from "@/company/api/useFetchCompanyTerritory";
import { useUpdateServiceTerritory } from "@/company/api/useUpdateServiceTerritory";
import { useQueryLocation } from "@/company/onboarding/api";
import TerritoryMap from "@/company/onboarding/components/screen/service-details/TerritoryMap";
import { promisifiedFunction } from "@/company/onboarding/components/screen/service-details/utils";

export const ServiceTerritory: React.FC = () => {
  const [selectedTerritories, setSelectedTerritories] = React.useState<
    Array<google.maps.Data.Feature>
  >([]);

  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { session, isInitialized } = useAuth();
  const company = session?.company;
  const companyAddress = session?.companyAddress;

  const { data: location } = useQueryLocation(
    companyAddress?.zipCode ?? "",
    !!companyAddress?.zipCode
  );

  const { data: companyTerritory } = useFetchCompanyTerritory(company?.id);
  const currentGeoIds = companyTerritory?.territoryGeoIds;

  const {
    isLoading: updatingServiceTerritory,
    mutateAsync: updateServiceTerritory,
  } = useUpdateServiceTerritory(company?.id, {
    onError() {
      enqueueSnackbar({
        message: "Error while updating company territory.",
        variant: "error",
      });
    },
    onSuccess() {
      enqueueSnackbar({
        message: "Your changes have been saved.",
        variant: "success",
      });
    },
  });

  if (!isInitialized || !location) return <>Loading...</>;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "flex-start",
            columnGap: 1,
            padding: 3,
            width: "100%",
            flexGrow: { xs: 0, md: 1 },
            flex: "1 0 0",
            height: "70vh",
          }}
        >
          <CardHeader
            sx={{
              display: "flex",
              p: 0,
              pb: 2,
            }}
            subheader={
              <Typography
                color={"text.secondary"}
                variant={isMobile ? "body2" : "body1"}
              >
                Click the map below to select your service area and find the
                closest referral partners
              </Typography>
            }
          />
          <TerritoryMap
            location={location}
            currentGeoIds={currentGeoIds}
            selectedTerritories={selectedTerritories}
            onTerritoryClick={newSelectedTerritories =>
              setSelectedTerritories(newSelectedTerritories)
            }
          />
          {session?.groups?.some(isAdministratorOrOwner) ? (
            <Stack
              spacing={2}
              width={"100%"}
              direction="row"
              textAlign={"center"}
              sx={{ justifyContent: { xs: "center", lg: "flex-end" } }}
              pt={3}
            >
              <Button
                variant="contained"
                onClick={async () => {
                  const territoriesGeoJson = [];
                  for (const feature of selectedTerritories) {
                    const geoJson = await promisifiedFunction(
                      feature.toGeoJson.bind(feature)
                    );
                    territoriesGeoJson.push(geoJson);
                  }
                  await updateServiceTerritory(territoriesGeoJson);
                }}
              >
                Save
              </Button>
            </Stack>
          ) : null}
        </Card>
      </Grid>
    </Grid>
  );
};
