import CurrencyTextField from "@lupus-ai/mui-currency-textfield";
import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  FormControl,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";

export interface TechnicianBalanceWithdrawDialogProps {
  currentBalance: number;
  isOpen: boolean;
  isWithdrawing: boolean;
  withdrawBalance: (amount: number) => void;
  onClose: () => void;
}

export function TechnicianBalanceWithdrawDialog(
  props: TechnicianBalanceWithdrawDialogProps
) {
  const { enqueueSnackbar } = useSnackbar();

  const { currentBalance, isOpen, isWithdrawing, withdrawBalance, onClose } =
    props;

  const [amount, setAmount] = useState(
    Math.abs(parseFloat(currentBalance)).toFixed(2)
  );

  return (
    <Dialog fullWidth open={isOpen} maxWidth="md" onClose={onClose}>
      <Box p={2} width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography gutterBottom variant="h6">
              Withdraw Balance
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box px={2} my={2} textAlign="center">
          <FormControl fullWidth>
            <CurrencyTextField
              label="Amount"
              variant="outlined"
              value={amount}
              currencySymbol="$"
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value) => setAmount(value)}
            />
          </FormControl>
        </Box>
        <LoadingButton
          fullWidth
          color="primary"
          variant="contained"
          loading={isWithdrawing}
          onClick={() => {
            if (amount > currentBalance) {
              enqueueSnackbar({
                variant: "error",
                message: `You can withdraw only a maximum of $${currentBalance}.`,
              });
            } else {
              withdrawBalance(amount);
            }
          }}
        >
          Withdraw
        </LoadingButton>
      </Box>
    </Dialog>
  );
}
