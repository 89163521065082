import { Container } from "@mui/material";
import { useParams } from "react-router-dom";

import { GoogleCompanyProfileDetails } from "@/common/GoogleCompanyDrawer/GoogleCompanyProfileDetails";

export function ServiceProviderPage() {
  const { id } = useParams();
  if (!id) return null;

  return (
    <Container
      maxWidth={"lg"}
      sx={{ minHeight: "calc(100vh - 205px)", overflow: "auto" }}
    >
      <GoogleCompanyProfileDetails googlePlacesId={id} />
    </Container>
  );
}
