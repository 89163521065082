import { Close } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Avatar,
  AvatarGroup,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import { usePopover } from "@/common/hooks/use-popover";
import { Seo } from "@/common/Seo/Seo";
import {
  ChatParticipant,
  ChatThread,
  ChatType,
  ChatTypeParticipant,
} from "@/company/api/useFetchAdminChatsByBrandLocation";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";

import { AdminParticipantsPopover } from "./AdminParticipantPopover";
import { MessageBox } from "./MessageBox";

export function CompanyMessage({
  isLoading,
  propChatThread,
  onDeselectChat,
  companyMessagesList,
}: {
  isLoading: boolean;
  propChatThread?: ChatThread;
  onDeselectChat?: () => void;
  companyMessagesList: Array<ChatThread>;
}) {
  const { session } = useAuth();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const user = session?.user;
  const navigate = useNavigate();

  const [selectedChat, setSelectedChat] = useState<ChatThread | null>(null);
  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);

  const filteredCompanyMessagesList = companyMessagesList.filter(
    chat => chat.messages.length
  );

  const popover = usePopover<HTMLButtonElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  filteredCompanyMessagesList.sort((a, b) => {
    if (
      a.type === ChatType.COMPANY_TO_USER &&
      b.type !== ChatType.COMPANY_TO_USER
    ) {
      return -1; // a comes before b
    }
    if (
      b.type === ChatType.COMPANY_TO_USER &&
      a.type !== ChatType.COMPANY_TO_USER
    ) {
      return 1; // b comes before a
    }
    const aCount = a.unreadMessages === null ? -Infinity : a.unreadMessages; // Treat null as -Infinity for sorting
    const bCount = b.unreadMessages === null ? -Infinity : b.unreadMessages;
    return bCount - aCount; // Descending order
  });

  const getTitle = (chatThread: ChatThread) => {
    const nameSet = new Set();
    if (chatThread.type === ChatType.COMPANY_TO_COMPANY) {
      let name = "";
      chatThread.participants.forEach((participant, index) => {
        if (!nameSet.has(participant.name)) {
          name = name + `${index === 0 ? "" : " + "}${participant.name}`;
          nameSet.add(participant.name);
        }
      });
      return name;
    }
    if (chatThread.type === ChatType.COMPANY_TO_USER) {
      let name = "";

      chatThread.participants.forEach((participant, index) => {
        if (
          !(
            participant.id === user?.id &&
            participant.type === ChatTypeParticipant.TEAM_MEMBER
          )
        ) {
          if (!nameSet.has(participant.name)) {
            name = name + `${index === 0 ? "" : " and "}${participant.name}`;

            nameSet.add(participant.name);
          }
        }
      });
      return name;
    }
    if (chatThread.type === ChatType.JOB) {
      return chatThread.jobType;
    }
  };

  const getParticipants = (chatThread: ChatThread) => {
    const participants: Array<ChatParticipant> = [];
    let includedTradeEngageTeam = false;
    chatThread.participants.forEach((participant, index) => {
      if (
        participant?.email?.includes("tradeengage.com") &&
        session?.company?.accountType === COMPANY_ACCOUNT_TYPES.SUPERADMIN
      ) {
        if (!includedTradeEngageTeam) {
          participants.push({ ...participant, name: "TradeEngage Team" });
          includedTradeEngageTeam = true;
        }
      } else if (
        !(
          participant.id === user?.id &&
          participant.type === ChatTypeParticipant.TEAM_MEMBER &&
          chatThread.type === ChatType.COMPANY_TO_USER
        )
      ) {
        participants.push(participant);
      }
    });

    return participants;
  };

  useEffect(() => {
    if (propChatThread) {
      const threadFound = filteredCompanyMessagesList.find(
        filteredThread => filteredThread.id === propChatThread.id
      );
      setSelectedChat(threadFound ?? propChatThread);
    }
  }, [propChatThread, filteredCompanyMessagesList]);

  useEffect(() => {
    if (selectedChatId) {
      const threadFound = filteredCompanyMessagesList.find(
        filteredThread => filteredThread.id === selectedChatId
      );
      if (threadFound) setSelectedChat(threadFound);
    }
  }, [selectedChatId, filteredCompanyMessagesList]);

  return (
    <>
      <Seo title="Super Admin Messages" />
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"calc(100vh - 160px)"}
        pt={1}
      >
        {isLoading ? (
          <Box textAlign={"center"} width="100%" mt={5}>
            <ComponentLoader />
          </Box>
        ) : selectedChat ? (
          <Container
            maxWidth={false}
            sx={{
              mx: 0,
              flexGrow: 1,
              display: "flex",
              overflow: "auto",
            }}
            disableGutters={true}
          >
            <Grid container flexGrow={1} display={"flex"} overflow={"auto"}>
              <Grid
                item
                container
                sx={{
                  px: 3,
                  borderLeft: isMobile ? null : "2px #F8F9FA solid",
                  overflow: "auto",
                  height: "100%",
                }}
              >
                {selectedChat ? (
                  <Box
                    width="100%"
                    height={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Box
                      mb={2}
                      display="flex"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box
                        ref={popover.anchorRef}
                        display="flex"
                        alignItems={"center"}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >
                        <AvatarGroup max={4} spacing={"small"}>
                          {getParticipants(selectedChat).map(participant => (
                            <CompanyAvatar
                              key={participant.id}
                              logo={participant.logoUrl || undefined}
                              name={participant.name}
                              height={"48px"}
                              width={"48px"}
                              bgColor="white"
                              border="1px solid gray !important"
                            />
                          ))}
                        </AvatarGroup>
                        <Box ml={1}>
                          <Typography variant="h6">
                            {getTitle(selectedChat)}
                          </Typography>
                          <Typography variant="body2" color={"text.secondary"}>
                            {getParticipants(selectedChat)?.length} participants
                            in this chat
                          </Typography>
                        </Box>
                      </Box>

                      <IconButton
                        onClick={() => {
                          setSelectedChat(null);
                          setSelectedChatId(null);
                          onDeselectChat?.();
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                    <MessageBox
                      chatThread={selectedChat}
                      onChatMessageAdd={() => {
                        //refetchChat();
                      }}
                    />
                  </Box>
                ) : isMobile ? null : (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"100%"}
                    height={"100%"}
                    gap={3}
                    flex={1}
                  >
                    <img
                      src="/empty-icon.png"
                      style={{ width: "132px", height: "128px" }}
                      alt="no pending jobs thumbnail"
                    />
                    <Box textAlign={"center"}>
                      <Typography gutterBottom variant="h6">
                        No Conversation Selected
                      </Typography>
                      <Typography variant="body2" color={"text.secondary"}>
                        Select a conversation from your inbox to message with
                        local partners.
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>
        ) : filteredCompanyMessagesList &&
          filteredCompanyMessagesList.length > 0 ? (
          <Container
            maxWidth={false}
            sx={{
              mx: 0,
              flexGrow: 1,
              display: "flex",
              overflow: "auto",
            }}
            disableGutters={true}
          >
            <Grid container flexGrow={1} display={"flex"} overflow={"auto"}>
              <Grid
                item
                container
                xs={12}
                md={12}
                sx={{
                  overflow: "auto",
                  display: "flex",
                  height: "fit-content",
                  maxHeight: "100%",
                  pr: 2,
                }}
              >
                {filteredCompanyMessagesList?.map((companyChat, index) => {
                  const lastMessage =
                    companyChat.messages[companyChat.messages.length - 1];
                  const createdAt = DateTime.fromFormat(
                    lastMessage.createdAt
                      .toString()
                      .split(".")[0]
                      .split("T")
                      .join(" "),
                    "yyyy-MM-dd HH:mm:ss",
                    { zone: "utc" }
                  );
                  const ago = createdAt.toLocal().toRelative({
                    base: DateTime.now().toLocal(),
                  });
                  const todayStart = DateTime.local().startOf("day");

                  const formattedDate =
                    createdAt.toLocal() < todayStart
                      ? createdAt.toLocal().toFormat("MM/dd/yy")
                      : createdAt.toLocal().toFormat("h:mm a");

                  return (
                    <>
                      <Grid
                        key={index}
                        container
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          borderRadius: "24px",
                          px: { xs: 0 },
                          py: { xs: 2 },
                          cursor: "pointer",
                          //   backgroundColor: (theme: Theme) =>
                          //     selectedChat?.companyProfile.id ===
                          //     item.companyProfile.id
                          //       ? theme.palette.primary.light
                          //       : theme.palette.background.paper,
                        }}
                        onClick={() => {
                          if (companyChat.id) {
                            setSelectedChatId(companyChat.id);
                            return;
                          }
                          setSelectedChat(companyChat);
                        }}
                      >
                        <Grid
                          item
                          xs={10}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            borderRadius: "24px",
                          }}
                        >
                          <Box position={"relative"} display={"flex"} mr={3}>
                            <CompanyAvatar
                              key={getParticipants(companyChat)[0].id}
                              logo={
                                getParticipants(companyChat)[0].logoUrl ||
                                undefined
                              }
                              name={getParticipants(companyChat)[0].name}
                              height={"30px"}
                              width={"30px"}
                              bgColor="white"
                              border={
                                getParticipants(companyChat)[0].logoUrl
                                  ? "2px solid black !important"
                                  : "1px solid gray !important"
                              }
                            />
                            <Avatar
                              sx={{
                                position: "absolute",
                                left: "16px",
                                top: "-12px",
                                height: "30px",
                                width: "30px",
                                bgColor: "white",
                                border: "1px solid gray !important",
                              }}
                            >{`+${(
                              getParticipants(companyChat).length - 1
                            ).toString()}`}</Avatar>
                          </Box>
                          {/* <AvatarGroup max={1}>
                            {companyChat.participants.map(participant => (
                              <CompanyAvatar
                                key={participant.id}
                                logo={participant.logoUrl || undefined}
                                name={participant.name}
                                height={"48px"}
                                width={"48px"}
                                bgColor="white"
                                border="1px solid gray !important"
                              />
                            ))}
                          </AvatarGroup> */}

                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            flexDirection={"column"}
                            mx={1}
                          >
                            <Typography gutterBottom variant="h6">
                              {getTitle(companyChat)}
                            </Typography>
                            <Typography
                              variant="body2"
                              color={"text.secondary"}
                            >
                              {_.truncate(lastMessage.message, {
                                length: selectedChat || isMobile ? 35 : 50,
                              })}
                              {lastMessage.message.length >
                              (selectedChat || isMobile ? 35 : 50)
                                ? "..."
                                : null}
                            </Typography>
                          </Box>
                          {companyChat.unreadMessages ? (
                            <FiberManualRecordIcon
                              color="primary"
                              fontSize="small"
                            />
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-end"
                          flexDirection={"column"}
                        >
                          <Typography
                            gutterBottom
                            variant="caption"
                            color={"text.secondary"}
                          >
                            {ago?.startsWith("in 0 seconds") ||
                            ago?.startsWith("0 seconds ago")
                              ? "Just now"
                              : `${formattedDate}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      {isMobile ? (
                        <Divider sx={{ width: "100%", my: 1 }} />
                      ) : null}
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            gap={3}
            my={5}
            flex={1}
          >
            <img
              src="/empty-icon.png"
              style={{ width: "132px", height: "128px" }}
              alt="no pending jobs thumbnail"
            />
            <Box textAlign={"center"}>
              <Typography gutterBottom variant="h5">
                No Messages Found
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <AdminParticipantsPopover
        anchorEl={anchorEl}
        open={open}
        participants={selectedChat ? getParticipants(selectedChat) : []}
        onClose={handlePopoverClose}
      />
    </>
  );
}
