import { Box, Container, Link, Typography } from "@mui/material";

import { USER_TYPE } from "@/auth/types";
import { AppConstants } from "@/common/constants";
import { TradeEngageLogo, TradeEngageLogoTypes } from "@/common/Logo";
import ProtectedRoute from "@/common/ProtectedRoutes";

export function TeamMemberOnboardingLayout() {
  return (
    <Container maxWidth="xs">
      <Box pt={3}>
        <TradeEngageLogo logotype={TradeEngageLogoTypes.LOGO_DARK} />
      </Box>
      <Box pt={5} pb={2}>
        <ProtectedRoute userRole={USER_TYPE.COMPANY_TEAM_MEMBER} />
      </Box>
      <Box display="flex" pb={3}>
        <Typography variant="body2" mr={1} color="text.secondary">
          Need help?{" "}
        </Typography>
        <Link
          href={`tel:${AppConstants.supportContactNumber}`}
          variant="body2"
          color="#16B364"
        >
          Contact Support
        </Link>
      </Box>
    </Container>
  );
}
