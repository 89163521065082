import { Divider, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";

import { useAuth } from "@/auth/context/jwt";
import { User } from "@/auth/types";
import { ChatMessage as ChatMessageComponent } from "@/common/chat/ChatMessage";
import {
  ChatMessage,
  ChatParticipant,
  ChatTypeParticipant,
} from "@/company/api/useFetchAdminChatsByBrandLocation";
import { Company, COMPANY_ACCOUNT_TYPES } from "@/company/types";
import { ChatDate, isTypeDate } from "@/utils";

const insertAdminDates = (
  chats: Array<ChatMessage>
): Array<ChatMessage | ChatDate> => {
  let currentDate: string | null = null;
  const formattedChats: Array<ChatMessage | ChatDate> = [];

  chats.forEach(chat => {
    const chatDate = DateTime.fromFormat(
      chat.createdAt.toString().split(".")[0].split("T").join(" "),
      "yyyy-MM-dd HH:mm:ss",
      { zone: "utc" }
    )
      .toLocal()
      .toFormat("LLL dd");

    if (chatDate !== currentDate) {
      const today = DateTime.now().toLocal().toFormat("LLL dd yyyy");
      const date = DateTime.fromFormat(
        chat.createdAt.toString().split(".")[0].split("T").join(" "),
        "yyyy-MM-dd HH:mm:ss",
        { zone: "utc" }
      )
        .toLocal()
        .toFormat("LLL dd yyyy");
      formattedChats.push({
        date: chatDate,
        isDate: true,
        ...(date === today ? { isToday: true } : {}),
      });
      currentDate = chatDate;
    }

    formattedChats.push(chat);
  });

  return formattedChats;
};

const getAuthor = (
  message: ChatMessage,
  participants: ChatParticipant[],
  company?: Company,
  user?: User
) => {
  let author = participants.find(
    participant => participant.id === message.fromUserId
  );
  if (!author) {
    author = participants.find(
      participant => participant.id === message.fromCompanyId
    );
  }
  let isUser = false;
  if (
    author?.type === ChatTypeParticipant.COMPANY &&
    company?.id === author.id
  ) {
    isUser = true;
  } else if (user?.id === author?.id) {
    isUser = true;
  } else if (
    author?.email?.includes("tradeengage.com") &&
    company?.accountType === COMPANY_ACCOUNT_TYPES.SUPERADMIN
  ) {
    isUser = true;
  }

  return {
    name: author?.name ?? "Me",
    avatar: author?.logoUrl,
    isUser,
  };
};

interface ChatMessagesProps {
  initialChatAlert?: ReactNode;
  chats?: ChatMessage[];
  participants?: ChatParticipant[];
  isAdmin?: boolean;
}

export const AdminChatMessages: FC<ChatMessagesProps> = props => {
  const { chats = [], participants = [], initialChatAlert, ...other } = props;
  const { session } = useAuth();
  const user = session?.user;
  const company = session?.company;

  const chatsWithDates = insertAdminDates(chats);

  return (
    <Stack spacing={2} sx={{ p: 3 }} {...other}>
      {initialChatAlert}
      {chatsWithDates.map((chat, index) => {
        if (isTypeDate(chat)) {
          if (chat.isToday)
            return (
              <Divider sx={{ width: "100%" }}>
                <Typography variant="overline" color="text.secondary">
                  Today
                </Typography>
              </Divider>
            );
          return (
            <Divider sx={{ width: "100%" }}>
              <Typography variant="overline" color="text.secondary">
                {chat.date}
              </Typography>
            </Divider>
          );
        }

        const author = getAuthor(chat, participants, company, user);

        return (
          <ChatMessageComponent
            key={index}
            authorAvatar={author.avatar}
            authorName={author.name}
            message={chat.message}
            createdAt={DateTime.fromFormat(
              chat.createdAt.toString().split(".")[0].split("T").join(" "),
              "yyyy-MM-dd HH:mm:ss",
              { zone: "utc" }
            )}
            position={author.isUser ? "right" : "left"}
          />
        );
      })}
    </Stack>
  );
};

AdminChatMessages.propTypes = {
  chats: PropTypes.array,
  participants: PropTypes.array,
};
