import {
  AccessTime as AccessTimeIcon,
  AttachMoney as AttachMoneyIcon,
  CalendarToday as CalendarTodayIcon,
  MoreHoriz as MoreHorizIcon,
  PriceCheck as PriceCheckIcon,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";

import { useAuth } from "@/auth/context/jwt";
import { isAdministratorOrOwner } from "@/common/utils/accessControl";
import { COMPANY_ACCOUNT_TYPES } from "@/company/types";
import { TextEditorTextPreview } from "@/lib/text-editor/TextEditorTextPreview";
import { neutral } from "@/Theme/colors";
import { isDefined } from "@/utils/isDefined";

import { JobPromotionStatus, JobPromotionWithCompany } from "../types";
import { getReferralFeeText } from "../utils";
import { JobPromotionExclusionField } from "./JobPromotionExclusionField";
import { JobPromotionReferButton } from "./JobPromotionReferButton";
import { JobPromotionStatusField } from "./JobPromotionStatusField";

interface JobPromotionCardProps {
  jobPromotion: JobPromotionWithCompany;
  onClick?: () => void;
  onMoreOptionsClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onUpdate?: () => void;
}

export function JobPromotionCard(props: JobPromotionCardProps) {
  const { jobPromotion, onClick, onMoreOptionsClick, onUpdate } = props;
  const { session } = useAuth();

  const isMyOffer = jobPromotion.company.id === session?.company?.id;
  const isUserIsAdminOrOwner = session?.groups?.some(isAdministratorOrOwner);
  const isConsultant =
    session?.company?.accountType === COMPANY_ACCOUNT_TYPES.CONSULTANT;

  const jobPromotionEndsInDays = Math.ceil(
    DateTime.fromISO(jobPromotion.endDate.toString(), {
      zone: "utc",
    }).diff(DateTime.utc(), "days").days
  );
  const jobPromotionExpiryLabel = () => {
    if (jobPromotionEndsInDays < 0) {
      return "Ended";
    }

    if (jobPromotionEndsInDays <= 1) {
      return "Ends Today";
    }

    return `Ends in ${jobPromotionEndsInDays} days`;
  };

  const isJobPromotionEndingSoon = jobPromotionEndsInDays <= 1;

  const renderUpdateStatusButton = () => {
    if (!isDefined(onUpdate)) {
      return <Box />;
    }

    if (isMyOffer || isConsultant) {
      return (
        <JobPromotionStatusField
          jobPromotion={jobPromotion}
          onUpdate={onUpdate}
        />
      );
    }

    return (
      <JobPromotionExclusionField
        jobPromotion={jobPromotion}
        onUpdate={onUpdate}
      />
    );
  };

  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        width: "360px",
        height: "320px",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onClick}
    >
      <CardContent
        sx={{
          paddingX: 2,
          paddingY: 1,
          overflow: "hidden",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Stack gap={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box
              component="img"
              src={jobPromotion.company?.logoUrl}
              alt="company logo"
              sx={{
                height: "40px",
                width: "auto",
                maxWidth: "50%",
                objectFit: "contain",
                display: "block",
              }}
            />
            {isMyOffer && jobPromotion.status !== JobPromotionStatus.ACTIVE ? (
              <Typography variant="subtitle2" color="text.secondary">
                Last Edited{" "}
                {DateTime.fromISO(jobPromotion.updatedAt.toString(), {
                  zone: "utc",
                })
                  .toLocal()
                  .toFormat("MM/dd/yy")}
              </Typography>
            ) : (
              <Chip
                size="small"
                icon={
                  isJobPromotionEndingSoon ? (
                    <AccessTimeIcon fontSize="small" />
                  ) : (
                    <CalendarTodayIcon fontSize="small" />
                  )
                }
                label={jobPromotionExpiryLabel()}
                sx={{
                  backgroundColor: isJobPromotionEndingSoon
                    ? "#FEE4E2"
                    : "#EDFCF2",
                  color: isJobPromotionEndingSoon ? "#F04438" : "#16B364",
                  borderRadius: 1,
                  ".MuiChip-icon": {
                    color: isJobPromotionEndingSoon ? "#F04438" : "#16B364",
                  },
                }}
              />
            )}
          </Stack>
          <Typography variant="h6">{jobPromotion.title}</Typography>
        </Stack>
        <Box sx={{ paddingY: 0.5, overflow: "hidden", flexGrow: 1 }}>
          <TextEditorTextPreview
            content={jobPromotion.description}
            lineClamp={3}
            fontSize="14px"
          />
        </Box>
        <Stack height="48px">
          {isDefined(jobPromotion.leadPrice) && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Stack direction="row" alignItems={"center"}>
                <AttachMoneyIcon color="primary" sx={{ fontSize: "16px" }} />
                <Typography variant="subtitle2" color="primary">
                  {jobPromotion.leadPrice}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems={"center"}>
                <PriceCheckIcon color="primary" sx={{ fontSize: "16px" }} />
                <Typography variant="subtitle2" color="primary">
                  Pays for every referral
                </Typography>
              </Stack>
            </Stack>
          )}
          {jobPromotion.payReferralFeeAfterJobIsDone && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Stack direction="row" alignItems={"center"}>
                <AttachMoneyIcon color="primary" sx={{ fontSize: "16px" }} />
                <Typography variant="subtitle2" color="primary">
                  {getReferralFeeText(jobPromotion)}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems={"center"}>
                <PriceCheckIcon color="primary" sx={{ fontSize: "16px" }} />
                <Typography variant="subtitle2" color="primary">
                  Pays when job closes
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </CardContent>
      <CardActions
        sx={{
          padding: 2,
          display: "flex",
          borderTop: `1px solid ${neutral[100]}`,
          justifyContent: "space-between",
        }}
      >
        {renderUpdateStatusButton()}
        {isMyOffer && isUserIsAdminOrOwner ? (
          <IconButton onClick={onMoreOptionsClick}>
            <MoreHorizIcon />
          </IconButton>
        ) : (
          <JobPromotionReferButton jobPromotion={jobPromotion} />
        )}
      </CardActions>
    </Card>
  );
}
