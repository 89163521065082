import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Drawer,
  Link,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridComparatorFn,
  GridEventListener,
  GridFilterModel,
  GridRowModesModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { HtmlModal } from "@/common/HtmlModal";
import { SMSModal } from "@/common/SMSModal";
import { doesErrorHaveMessage } from "@/common/utils/doesErrorHaveMessage";
import { useCreateTableView } from "@/company/api/useCreateTableView";
import {
  RecommendedPartnersInternalAdmin,
  RecommendedPartnerSource,
  useGetRecommendedPartnersInternalAdmin,
} from "@/company/api/useGetRecommendedPartnersInternalAdmin";
import { AdminChatProvider } from "@/company/context/admin-chat-context";
import { JobSectors } from "@/company/jobs/constants";
import {
  CarrierType,
  CompanyStatus,
  ConnectionStatus,
  PLATFORM_PRESENCE,
} from "@/company/types";
import { snakeCaseJobSectors } from "@/company/utils";

import { CompanyAvatar } from "../company/components/company-avatar/CompanyAvatar";
import { InternalAdminCompanyDrawer } from "./InternalAdminCompanyDrawer";
import { CreateTableViewDialog } from "./saved-searches/CreateTableViewDialog";
import { TableType } from "./saved-searches/SavedSearchTable";
import {
  CustomToolbar,
  drawerWidth,
  formatDate,
  getGridStringOperatorsDataGrid,
  Main,
  renderJobSectorsArea,
} from "./utils";

const sectorComparator: GridComparatorFn<Array<string>> = (v1, v2) => {
  const minLength = Math.min(v1.length, v2.length);

  for (let i = 0; i < minLength; i++) {
    if (v1[i] < v2[i]) return -1;
    if (v1[i] > v2[i]) return 1;
  }

  // If all elements are equal so far, the shorter array comes first
  return v1.length - v2.length;
};

export default function InternalAdminRecommendedPartners({
  name,
  preFilledColumns,
  payload,
}: {
  name?: string;
  preFilledColumns?: Array<any>;
  payload?: Record<string, any>;
}) {
  const [rows, setRows] = React.useState<
    Array<RecommendedPartnersInternalAdmin>
  >([]);

  const { session } = useAuth();
  const user = session?.user;
  const [serverSorting, setServerSorting] = useState<
    undefined | GridSortModel
  >();
  const [serverFiltering, setServerFiltering] = useState<
    undefined | GridFilterModel
  >();

  const [companyProfileId, setCompanyProfileId] = useState<number | null>();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const handleSortModelChange = (model: GridSortModel) => {
    setServerSorting(model);
  };

  const [createTableViewDialogOpen, setCreateTableViewDialogOpen] =
    useState(false);

  const [emailHtml, setEmailHtml] = useState(null);
  const [smsMessage, setSMSMessage] = useState(null);

  const handleFilterModelChange = (model: GridFilterModel) => {
    setServerFiltering(model);
  };

  const navigate = useNavigate();

  const { mutateAsync: createTableView, isLoading: creatingTableView } =
    useCreateTableView({
      onError(error) {
        const message = doesErrorHaveMessage(error)
          ? error.message
          : "Error while saving search.";
        enqueueSnackbar(message, {
          variant: "error",
        });
      },
      onSuccess(tableView) {
        enqueueSnackbar({
          message: "Search saved successfully.",
          variant: "success",
        });
        navigate(`/admin/saved-searches/${tableView.id}`, {
          state: { tableView },
        });
      },
    });

  const [expanded, setExpanded] = React.useState(false);

  const {
    data: recommendedPartners,
    isFetching,
    isLoading,
  } = useGetRecommendedPartnersInternalAdmin({
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setRows(recommendedPartners ?? []);
  }, [recommendedPartners]);

  let columns: GridColDef[] = [
    {
      field: GRID_CHECKBOX_SELECTION_COL_DEF.field,
      headerName: "Checkbox Selection",
      width: 50,
      hideable: false,
    },
    {
      field: "id",
      headerName: "ID",
      renderCell: params => params.row.id.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      width: 200,
      type: "number",
    },
    {
      field: "companyId",
      headerName: "Company ID",
      renderCell: params => params.row.companyId?.id?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      editable: false,
      type: "number",
      valueGetter: (value, row) => row.company?.id,
    },
    {
      field: "company-logo",
      headerName: "Company Logo",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <CompanyAvatar
              logo={params.row.company?.logoUrl || undefined}
              name={params.row.company?.name}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
          </Box>
        );
      },
      valueGetter: (value, row) => row.company?.logoUrl,
    },
    {
      field: "companyName",
      type: "string",
      width: 200,
      valueGetter: (value, row) => row.company?.name,
      headerAlign: "left",
      headerName: "Company Name",
    },
    {
      field: "companySectors",
      headerName: "Company Sectors",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: Object.values(JobSectors),
      sortComparator: sectorComparator,
      renderEditCell: renderJobSectorsArea,
      valueFormatter: value => `${value}`,
      renderCell: params => {
        const sectorChips = params.row?.companySectors?.map(sector => (
          <Chip
            key={snakeCaseJobSectors[sector]}
            sx={{
              color: "text.secondary",
              bgcolor: "#F8F9FA",
            }}
            label={snakeCaseJobSectors[sector]}
            size="small"
          />
        ));
        return sectorChips;
      },
    },
    {
      field: "companyStatus",
      headerName: "Company Status",
      editable: false,
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(CompanyStatus)],
      valueGetter: (value, row) => row.company?.status,
    },

    {
      field: "companyPlatformPresence",
      headerName: "Company Platform Presence",
      editable: false,
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(PLATFORM_PRESENCE)],
      valueGetter: (value, row) => row.company?.platformPresence,
    },
    {
      field: "companyActivatedAt",
      headerName: "Date Company Activated",
      editable: false,
      width: 200,
      type: "date",
      valueFormatter: formatDate,
      valueGetter: (value, row) => row.company?.activatedAt,
    },
    {
      field: "companyMarkedOnPlatformAt",
      headerName: "Date Marked On PLatform",
      editable: false,
      width: 200,
      type: "date",
      valueFormatter: formatDate,
      valueGetter: (value, row) => row.company?.markedOnPlatformAt,
    },
    {
      field: "companyBrandId",
      headerName: "Company Brand Id",
      renderCell: params => params.row.companyBrand?.id?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      editable: false,
      type: "number",
      width: 200,
      valueGetter: (value, row) => row.companyBrand?.id,
    },
    {
      field: "brand",
      headerName: "Company Brand",
      editable: true,
      type: "string",
      valueGetter: (value, row) => row.companyBrand?.name,
      renderCell: params => {
        return params.row?.companyBrand?.name;
      },
      width: 200,
    },
    {
      field: "companyFamilyOfBrandsId",
      headerName: "Company Family of Brands Id",

      renderCell: params => params.row.companyFamilyOfBrands?.id?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      editable: false,
      type: "number",
      width: 200,
      valueGetter: (value, row) => row.companyFamilyOfBrands?.id,
    },
    {
      field: "companyFamilyOfBrands",
      valueGetter: (value, row) => row.companyFamilyOfBrands?.name,
      headerName: "Company Family of Brands",
      width: 200,
    },
    {
      field: "companyPrivateEquityId",
      headerName: "Company Private Equity Id",
      editable: false,
      renderCell: params => params.row.companyPrivateEquity?.id?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      type: "number",
      width: 200,
      valueGetter: (value, row) => row.companyPrivateEquity?.id,
    },
    {
      field: "companyPrivateEquityName",
      valueGetter: (value, row) => row.companyPrivateEquity?.name,
      headerName: "Company Private Equity",
      width: 200,
    },
    {
      field: "recommendedCompanyId",
      headerName: "Recommended Company ID",
      editable: false,
      type: "number",
      renderCell: params => params.row.recommendedCompany?.id?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      width: 200,
      valueGetter: (value, row) => row.recommendedCompany?.id,
    },
    {
      field: "recommendedCompany-logo",
      headerName: "Recommended Company Logo",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: params => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <CompanyAvatar
              logo={params.row.recommendedCompany?.logoUrl || undefined}
              name={params.row.recommendedCompany?.name}
              height={"32px"}
              width={"48px"}
              bgColor="white"
              border="1px solid gray !important"
              isAdminProfile={true}
            />
          </Box>
        );
      },
      valueGetter: (value, row) => row.recommendedCompany?.logoUrl,
    },
    {
      field: "recommendedCompanyName",
      type: "string",
      width: 200,
      valueGetter: (value, row) => row.recommendedCompany?.name,
      headerAlign: "left",
      headerName: "Recommended Company Name",
    },
    {
      field: "recommendedCompanySectors",
      headerName: "Recommended Company Sectors",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: Object.values(JobSectors),
      sortComparator: sectorComparator,
      renderEditCell: renderJobSectorsArea,
      valueFormatter: value => `${value}`,
      renderCell: params => {
        const sectorChips = params.row?.recommendedCompanySectors?.map(
          sector => (
            <Chip
              key={snakeCaseJobSectors[sector]}
              sx={{
                color: "text.secondary",
                bgcolor: "#F8F9FA",
              }}
              label={snakeCaseJobSectors[sector]}
              size="small"
            />
          )
        );
        return sectorChips;
      },
    },
    {
      field: "recommendedCompanyStatus",
      headerName: "RecommendedCompany Status",
      editable: false,
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(CompanyStatus)],
      valueGetter: (value, row) => row.recommendedCompany?.status,
    },

    {
      field: "recommendedCompanyPlatformPresence",
      headerName: "Recommended Company Platform Presence",
      editable: false,
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(PLATFORM_PRESENCE)],
      valueGetter: (value, row) => row.recommendedCompany?.platformPresence,
    },
    {
      field: "recommendedCompanyActivatedAt",
      headerName: "Date Recommended Company Activated",
      editable: false,
      width: 200,
      type: "date",
      valueFormatter: formatDate,
      valueGetter: (value, row) => row.company?.activatedAt,
    },
    {
      field: "recommendedCompanyMarkedOnPlatformAt",
      headerName: "Date Recommended Company Marked On PLatform",
      editable: false,
      width: 200,
      type: "date",
      valueFormatter: formatDate,
      valueGetter: (value, row) => row.recommendedCompany?.markedOnPlatformAt,
    },
    {
      field: "recommendedCompanyTotalTimesAppearInRecommendedPartners",
      headerName:
        "Number of Times the Recommended Company Appears in the Recommended Partners",
      width: 200,
      editable: false,
      type: "number",
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      type: "date",
      valueFormatter: formatDate,
      width: 200,
    },
    {
      field: "source",
      headerName: "Source",
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(RecommendedPartnerSource)],
      headerAlign: "left",
    },
    {
      field: "companyGooglePlacesId",
      headerName: "Company Google Places Id",
      type: "string",
      headerAlign: "left",
      width: 150,
    },
    {
      field: "companyStreetAddress",
      headerName: "Company Street Address",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.companyAddress?.streetAddress,
    },
    {
      field: "companyCity",
      headerName: "Company City",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.companyAddress?.city,
    },
    {
      field: "companyState",
      headerName: "Company State",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.companyAddress?.state,
    },
    {
      field: "companyZipCode",
      headerName: "Company Zip Code",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.companyAddress?.zipCode,
    },
    {
      field: "companyBusinessEmail",
      headerName: "Company Business Email",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.companyBusinessEmail,
    },
    {
      field: "companyBusinessPhone",
      headerName: "Company Business Phone",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.companyBusinessPhone,
    },
    {
      field: "companyBusinessPhoneCarrierType",
      headerName: "Company Business Phone Carrier Type",
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(CarrierType)],
    },
    {
      field: "companyOwnerName",
      headerName: "Company Owner Name",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.companyOwnerName,
    },
    {
      field: "companyOwnerEmail",
      headerName: "Company Owner Email",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.companyOwnerEmail,
    },
    {
      field: "companyOwnerCellPhone",
      headerName: "Company Owner Cell Phone",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.companyOwnerCellPhone,
    },
    {
      field: "companyOwnerCellPhoneCarrierType",
      headerName: "Company Owner Cell Phone Carrier Type",
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(CarrierType)],
    },
    {
      field: "recommendedCompanyBrandId",
      headerName: "Recommended Company Brand Id",
      editable: false,
      renderCell: params => params.row.recommendedCompanyBrand?.id?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      type: "number",
      width: 200,
      valueGetter: (value, row) => row.recommendedCompanyBrand?.id,
    },
    {
      field: "recommendedBrand",
      headerName: "Recommended Company Brand",
      editable: true,
      type: "string",
      valueGetter: (value, row) => row.recommendedCompanyBrand?.name,
      renderCell: params => {
        return params.row?.recommendedCompanyBrand?.name;
      },
      width: 200,
    },
    {
      field: "recommendedCompanyFamilyOfBrandsId",
      headerName: "Recommended Company Family of Brands Id",
      renderCell: params =>
        params.row.recommendedCompanyFamilyOfBrands?.id?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      editable: false,
      type: "number",
      width: 200,
      valueGetter: (value, row) => row.recommendedCompanyFamilyOfBrands?.id,
    },
    {
      field: "recommendedCompanyFamilyOfBrands",
      valueGetter: (value, row) => row.recommendedCompanyFamilyOfBrands?.name,
      headerName: "Recommended Company Family of Brands",
      width: 200,
    },
    {
      field: "recommendedCompanyPrivateEquityId",
      headerName: "Recommended Company Private Equity Id",
      renderCell: params =>
        params.row.recommendedCompanyPrivateEquity?.id?.toString(),
      valueFormatter: value => value && `${value?.toString()}`,
      editable: false,
      type: "number",
      width: 200,
      valueGetter: (value, row) => row.recommendedCompanyPrivateEquity?.id,
    },
    {
      field: "recommendedCompanyPrivateEquityName",
      valueGetter: (value, row) => row.recommendedCompanyPrivateEquity?.name,
      headerName: "Recommended Company Private Equity",
      width: 200,
    },
    {
      field: "recommendedCompanyGooglePlacesId",
      headerName: "Recommended Company Google Places Id",
      type: "string",
      headerAlign: "left",
      width: 150,
    },
    {
      field: "recommendedCompanyStreetAddress",
      headerName: "Recommended Company Street Address",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.recommendedCompanyAddress?.streetAddress,
    },
    {
      field: "recommendedCompanyCity",
      headerName: "Recommended Company City",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.recommendedCompanyAddress?.city,
    },
    {
      field: "recommendedCompanyState",
      headerName: "Recommended Company State",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.recommendedCompanyAddress?.state,
    },
    {
      field: "recommendedCompanyZipCode",
      headerName: "RecommendedCompany Zip Code",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.recommendedCompanyAddress?.zipCode,
    },
    {
      field: "recommendedCompanyBusinessEmail",
      headerName: "Recommended Company Business Email",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.recommendedCompanyBusinessEmail,
    },
    {
      field: "recommendedCompanyBusinessPhone",
      headerName: "Recommended Company Business Phone",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.recommendedCompanyBusinessPhone,
    },
    {
      field: "recommendedCompanyBusinessPhoneCarrierType",
      headerName: "Recommended Company Business Phone Carrier Type",
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(CarrierType)],
    },
    {
      field: "recommendedCompanyOwnerName",
      headerName: "Recommended Company Owner Name",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.recommendedCompanyOwnerName,
    },
    {
      field: "recommendedCompanyOwnerEmail",
      headerName: "Recommended Company Owner Email",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.recommendedCompanyOwnerEmail,
    },
    {
      field: "recommendedCompanyOwnerCellPhone",
      headerName: "Recommended Company Owner Cell Phone",
      width: 200,
      type: "string",
      valueGetter: (value, row) => row.recommendedCompanyOwnerCellPhone,
    },
    {
      field: "recommendedCompanyOwnerCellPhoneCarrierType",
      headerName: "Company Owner Cell Phone Carrier Type",
      width: 200,
      type: "singleSelect",
      valueOptions: [...Object.keys(CarrierType)],
    },
    {
      field: "doesReferralBetweenCompaniesExist",
      headerName: "Does Active Referral Between Companies Exist",
      width: 200,
      type: "boolean",
      description:
        "Whether there is an active referral between the company and the recommended company or vice versa that is active",
    },
    {
      field: "doesReferralInvitationBetweenCompaniesExist",
      headerName: "Does Active Referral Invitation Between Companies Exist",
      width: 200,
      type: "boolean",
      description:
        "Whethere there is a referral invitation between the company and the recommended company or vice versa that is active",
    },
  ];

  columns = columns.map(column => {
    if (!column.type || column.type === "string") {
      return {
        ...column,
        filterOperators: getGridStringOperatorsDataGrid,
      };
    }
    return column;
  });

  const [columnWidths, setColumnWidths] = useState(() => {
    const savedColumnWidths = localStorage.getItem(
      "recommended-partners-datagridColumnWidths"
    );
    return savedColumnWidths
      ? JSON.parse(savedColumnWidths)
      : columns.reduce((acc, col) => {
          acc[col.field] = col.width;
          return acc;
        }, {});
  });

  const [columnOrder, setColumnOrder] = useState(() => {
    const savedColumnOrder = localStorage.getItem(
      "recommended-partners-datagridColumnOrder"
    );
    return savedColumnOrder
      ? JSON.parse(savedColumnOrder)
      : columns.map(column => column.field);
  });

  const handleColumnResize = (newColumn: any) => {
    setColumnWidths({
      ...columnWidths,
      [newColumn.colDef.field]: newColumn.width,
    });
  };

  columns = columns.map(column => ({
    ...column,
    width: columnWidths?.[column.field] ?? column.width,
  }));

  const oldColumns = [...columns];

  columns = [
    ...columnOrder.map(field => oldColumns.find(col => col.field === field)),
    ...oldColumns.filter(col => !columnOrder.includes(col.field)),
  ];

  const apiRef = useGridApiRef();

  useEffect(() => {
    localStorage.setItem(
      "recommended-partners-datagridColumnWidths",
      JSON.stringify(columnWidths)
    );
  }, [columnWidths]);

  useEffect(() => {
    localStorage.setItem(
      "recommended-partners-datagridColumnOrder",
      JSON.stringify(columnOrder)
    );
  }, [columnOrder]);

  useEffect(() => {
    if (payload) {
      setServerFiltering(payload.serverFiltering);
      setServerSorting(payload.serverSorting);
      setPaginationModel({ pageSize: payload.size, page: payload.page });
      if (payload.columnOrder) setColumnOrder(payload.columnOrder);
    }
  }, [payload]);

  useEffect(() => {
    if (preFilledColumns) {
      const newColumnWidths = preFilledColumns.reduce((acc, col) => {
        acc[col.field] = col.width;
        return acc;
      }, {});
      setColumnWidths(newColumnWidths);
      setColumnVisibilityModel(
        columns.reduce((acc, col) => {
          acc[col.field] = preFilledColumns.some(
            preFilledColumn => preFilledColumn.field === col.field
          );
          return acc;
        }, {})
      );
      for (const column of preFilledColumns) {
        apiRef.current.setColumnIndex(column.field, column.position);
        apiRef.current.pinColumn(column.field, column.pinned);
      }
    }
  }, [preFilledColumns, apiRef]);

  const handleCellClick: GridEventListener<"cellClick"> = (
    params // GridCellParams<any>
  ) => {
    if (
      params.field === "invitedCompany-name" ||
      params.field === "invitedCompanyId"
    )
      setCompanyProfileId(params.row.invitedCompanyId);
    if (
      params.field === "invitingCompany-name" ||
      params.field === "invitingCompanyId"
    )
      setCompanyProfileId(params.row.invitingCompanyId);

    if (params.field === "latestEmailReceivedHtmlBody") {
      setEmailHtml(params.row.latestEmailReceivedHtmlBody);
    }
    if (params.field === "latestSmsReceivedMessage") {
      setSMSMessage(params.row.latestSmsReceivedMessage);
    }
    if (
      params.field === "firstInvitingCompanyId" ||
      params.field === "firstInvitingCompanyName"
    ) {
      setCompanyProfileId(params.row.firstInvitingCompany?.id);
    }
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const { column, targetIndex } = params;

    // Reorder the columns based on the drag and drop
    const newColumnOrder = [...columns];
    const columnIndex = newColumnOrder.findIndex(
      col => col.field === column.field
    );

    // Remove the moved column and reinsert it at the new index
    const [movedColumn] = newColumnOrder.splice(columnIndex, 1);
    newColumnOrder.splice(targetIndex, 0, movedColumn);

    // Extract the new field order
    const newFieldOrder = newColumnOrder.map(col => col.field);

    setColumnOrder(newFieldOrder);
  };

  return (
    <Box display={"flex"} sx={{ height: "100%" }} overflow={"auto"}>
      {emailHtml ? (
        <HtmlModal
          open={emailHtml}
          htmlContent={emailHtml}
          onClose={() => setEmailHtml(null)}
        />
      ) : null}
      {smsMessage ? (
        <SMSModal
          open={smsMessage}
          smsContent={smsMessage}
          onClose={() => setSMSMessage(null)}
        />
      ) : null}
      <Main
        open={!!companyProfileId}
        sx={{
          width: expanded ? "0%" : `calc(100% - ${drawerWidth}px)`,
          display: expanded ? "none" : "visible",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
          p={3}
        >
          {name ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                href="/admin/saved-searches"
              >
                Saved Searches
              </Link>
              <Link underline="hover" color="inherit">
                {name}
              </Link>
            </Breadcrumbs>
          ) : null}
          <Typography variant="h5">Recommended Partners</Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={3}
          py={1}
        >
          <Box
            display={"flex"}
            gap={3}
            justifyContent={"space-between"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography variant="subtitle1" fontSize={"16px"}>
              {recommendedPartners?.length
                ? `Showing ${Intl.NumberFormat("en-US").format(
                    recommendedPartners?.length ?? 0
                  )} recommended partners`
                : "Fetching results"}
            </Typography>
            <Box
              display={"flex"}
              gap={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Button
                startIcon={<BookmarkAddOutlinedIcon />}
                variant="outlined"
                onClick={() => {
                  setCreateTableViewDialogOpen(true);
                }}
              >
                Save Search
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 260px)",
            px: 3,
          }}
        >
          <DataGridPremium
            checkboxSelection
            disableRowSelectionOnClick
            cellSelection
            keepNonExistentRowsSelected
            disableAggregation
            disableRowGrouping
            pagination
            columns={columns}
            columnOrder={columnOrder}
            sortModel={serverSorting}
            filterModel={serverFiltering}
            loading={isFetching || isLoading}
            rows={rows ?? []}
            slots={{
              toolbar: () => <CustomToolbar fileName="Recommended_Partners" />,
            }}
            sx={{
              "& .MuiDataGrid-row": {
                "--rowBorderColor": "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withRightBorder": {
                borderRightColor: "#D9D9D9",
              },
              "& .MuiDataGrid-cell--withLeftBorder": {
                borderLeftColor: "#D9D9D9",
              },
              "& .MuiDataGrid-topContainer": {
                background: "#F8F9FA",
              },
            }}
            apiRef={apiRef}
            columnVisibilityModel={columnVisibilityModel}
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                right: ["actions"],
              },
              density: "compact",
            }}
            slotProps={{
              toolbar: { setRowModesModel },
            }}
            paginationModel={paginationModel}
            pageSizeOptions={[5, 10, 25, 500, 1000, 5000]}
            onColumnWidthChange={handleColumnResize}
            onColumnVisibilityModelChange={newModel =>
              setColumnVisibilityModel(newModel)
            }
            onColumnOrderChange={params =>
              handleColumnOrderChange(params, columns, setColumnOrder)
            }
            onCellClick={handleCellClick}
            onPaginationModelChange={setPaginationModel}
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={handleSortModelChange}
          />
        </Box>
      </Main>
      {expanded && companyProfileId ? (
        <AdminChatProvider companyId={companyProfileId}>
          <InternalAdminCompanyDrawer
            open={!!companyProfileId}
            companyProfileId={companyProfileId}
            connectionStatus={ConnectionStatus.CONNECTED}
            expanded={expanded}
            onClose={() => {
              setCompanyProfileId(null);
              setExpanded(false);
            }}
            onExpand={() => setExpanded(prev => !prev)}
          />
        </AdminChatProvider>
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={!!companyProfileId}
        >
          {companyProfileId ? (
            <AdminChatProvider companyId={companyProfileId}>
              <InternalAdminCompanyDrawer
                open={!!companyProfileId}
                expanded={expanded}
                companyProfileId={companyProfileId}
                connectionStatus={ConnectionStatus.CONNECTED}
                onClose={() => {
                  setCompanyProfileId(null);
                  setExpanded(false);
                }}
                onExpand={() => setExpanded(prev => !prev)}
              />
            </AdminChatProvider>
          ) : null}
        </Drawer>
      )}
      {createTableViewDialogOpen ? (
        <CreateTableViewDialog
          isOpen={createTableViewDialogOpen}
          creatingTableView={creatingTableView}
          onCreate={({
            name,
            description,
          }: {
            name: string;
            description?: string;
          }) => {
            const payload = {
              page: paginationModel.page,
              size: paginationModel.pageSize,
              serverSorting,
              serverFiltering,
              columnOrder,
            };
            const columns = apiRef.current.getVisibleColumns();
            const columnsPayload = columns.map(column => ({
              field: column.field,
              width: column.width,
              position: apiRef.current.getColumnPosition(column.field),
              pinned: apiRef.current.isColumnPinned(column.field),
            }));
            createTableView({
              name,
              tableName: TableType.RECOMMENDED_PARTNERS,
              description,
              body: payload,
              columns: columnsPayload,
            });
            setCreateTableViewDialogOpen(false);
          }}
          onClose={() => setCreateTableViewDialogOpen(false)}
        />
      ) : null}
    </Box>
  );
}
