import { Add as AddIcon } from "@mui/icons-material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "lodash";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import { useBackdropContext } from "@/common/backdrop-context";
import { ComponentLoader } from "@/common/ComponentLoader/ComponentLoader";
import CreateTechnicianJobDialogWrapper from "@/common/CreateJobDialog/CreateTechnicianJobDialogWrapper";
import { useJobUploadContext } from "@/common/job-upload-context";
import { ToolTip } from "@/common/tool-tip/ToolTip";
import { isCompanyActive } from "@/common/utils/accessControl";
import { getNavigationPathFromRoutePaths } from "@/common/utils/getNavigationPathFromRoutePaths";
import { useGetProfileCompletenessInfo } from "@/company/api/useGetProfileCompletenessInfo";
import {
  useQueryBrandLocationById,
  useQueryBrandsUnderFamilyBrand,
} from "@/company/onboarding/api";
import { CompanyPublicProfile, CompanyRoutes } from "@/company/types";
import { useContactSupportModalContext } from "@/open/components/modals/ContactSupportContext";

import { ExplorePartnersComponent } from "./ExplorePartnersComponent";
import { FactsCarousel } from "./FactsCarousel";
import { GettingStartedCheckList } from "./GettingStartedCheckList";
import { SemicircularProgressBar } from "./SemiCircularProgressBar";

interface Props {
  campaignCompanyProfile?: CompanyPublicProfile;
}

export const CompanyDashboardHomePageInProgress: FC<Props> = ({
  campaignCompanyProfile,
}) => {
  const navigate = useNavigate();
  const { session } = useAuth();
  const company = session?.company;
  const campaign = session?.campaign;

  const { data: searchCompanyRecord } = useQueryBrandLocationById({
    id: company?.id,
  });

  const { backDropOn, setBackDropOn } = useBackdropContext();
  const [isImageLoadError, setIsImgaeLoadError] = useState(false);

  const { jobUploadOpen, setJobUploadOpen } = useJobUploadContext();
  const handleOpen = () => {
    setJobUploadOpen(true);
    setBackDropOn(true);
  };
  const handleClose = () => {
    setJobUploadOpen(false);
    setBackDropOn(false);
  };
  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);

  const user = session?.user;
  const familyOfBrands = session?.familyOfBrands;

  const { data: { count: totalParentBrandsCount } = {} } =
    useQueryBrandsUnderFamilyBrand(
      {
        familyBrandId: searchCompanyRecord?.familyOfBrands?.id,
      },
      !!searchCompanyRecord?.familyOfBrands
    );

  // const { data: userWithGroups } = useFetchUsersWithGroups();

  // const bonusEligibleUsers = userWithGroups?.filter(
  //   userWithGroup =>
  //     userWithGroup.groups.some(
  //       group =>
  //         group.name === DefaultCompanyGroups.FIELD_TEAM ||
  //         group.name === DefaultCompanyGroups.TEAM_MEMBER
  //     ) &&
  //     !userWithGroup.groups.some(
  //       group =>
  //         group.name === DefaultCompanyGroups.ADMINISTRATOR ||
  //         group.name === DefaultCompanyGroups.OWNER
  //     )
  // );

  const { data: profileCompleteness, isLoading: isLoadingProfile } =
    useGetProfileCompletenessInfo();

  const { setContactSupportModalOpen } = useContactSupportModalContext();

  const calculateStepsCompleted = () => {
    let stepsCompleted = 0;
    if (profileCompleteness?.companyProfileCompleted) stepsCompleted += 1;
    if (profileCompleteness?.referralPartnersCompleted) stepsCompleted += 1;
    if (profileCompleteness?.mobileVerificationCompleted) stepsCompleted += 1;
    if (profileCompleteness?.inviteTeamMembersCompleted) stepsCompleted += 1;
    if (profileCompleteness?.serviceDetailsCompleted) stepsCompleted += 1;

    return stepsCompleted;
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleVideoButtonClick = (videoUrl?: string) => {
    if (videoUrl) {
      window.open(videoUrl, "_blank");
      return;
    }
    const genericVideoUrl =
      "https://tradeengage-app.s3.amazonaws.com/videos/You're+Invited+to+Join+TradeEngage.mp4";
    window.open(genericVideoUrl, "_blank");
  };

  const isProfileComplete = profileCompleteness?.percentage === 100;

  return (
    <>
      <Container disableGutters maxWidth="100%">
        <Grid container spacing={2} display={"relative"}>
          {isMobile ? null : (
            <Grid
              item
              xs={12}
              sx={{
                top: "48px",
                zIndex: backDropOn ? 0 : 100,
                py: 1,
              }}
              position={"sticky"}
              bgcolor={"white"}
            >
              <Typography variant="h5">
                Welcome to TradeEngage
                {user?.firstName ? `, ${_.capitalize(user.firstName)}` : ""}
              </Typography>
            </Grid>
          )}
          {isLoadingProfile ? (
            <Box py={5}>
              <ComponentLoader />
            </Box>
          ) : (
            <>
              <Grid item xs={12} lg={8} my={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        boxShadow: "0px 0px 0px 1px #F2F4F7;",
                        display: "flex",
                        position: "relative",
                        flexDirection: "column",
                        backgroundSize: "108% 120%",
                        backgroundPosition: "center",
                        backgroundImage: 'url("/card.png")',
                        px: 1,
                        pb: 3,
                        pt: 2,
                        gap: 3,
                      }}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        width={"100%"}
                        alignItems={"start"}
                        px={1}
                      >
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"start"}
                          alignItems={"center"}
                          px={1}
                          gap={1}
                          width="100%"
                        >
                          <img
                            src="/video.gif"
                            alt="dollar"
                            style={{
                              width: "48px",
                              height: "48px",
                            }}
                          />

                          <Typography
                            variant="h6"
                            sx={{
                              color: "#16DB65",
                            }}
                          >
                            {isCompanyActive(company?.status) || !campaign
                              ? "Give to Get! Submit your AI-driven referral today"
                              : `You’re Ready to Refer Jobs${
                                  campaignCompanyProfile?.brand?.name
                                    ? ` to ${campaignCompanyProfile?.brand?.name}`
                                    : ""
                                }!`}
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"start"}
                          alignItems={"start"}
                          px={1}
                          gap={2}
                          width="100%"
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#D2D6DB",
                            }}
                          >
                            {isCompanyActive(company?.status) && campaign
                              ? `Record a video of a job opportunity. We’ll summarize it and route it to your referral partner. If you’ve set up a commission with your referral partner, your team will earn instantly.`
                              : "Record a video of a job opportunity. We’ll use AI to summarize it and send it on so you earn instantly! "}
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"start"}
                          alignItems={"start"}
                          px={1}
                          pt={1.5}
                          gap={3}
                          width="100%"
                        >
                          {" "}
                          <Typography
                            width={"100%"}
                            variant="subtitle1"
                            sx={{
                              color: "white",
                            }}
                          >
                            {isMobile ? (
                              "Tap the + sign to get started!"
                            ) : (
                              <>
                                Click{" "}
                                <Typography
                                  variant="subtitle1"
                                  display={"inline"}
                                  sx={{
                                    cursor: "pointer",
                                    color: "#16DB65",
                                  }}
                                  onClick={() => {
                                    setIsJobDialogOpen(true);
                                  }}
                                >
                                  here{" "}
                                </Typography>
                                to get started!
                                {!isCompanyActive(company?.status) &&
                                campaign?.videoLink
                                  ? " Click "
                                  : ""}
                                {!isCompanyActive(company?.status) &&
                                campaign?.videoLink ? (
                                  <Typography
                                    variant="subtitle1"
                                    display={"inline"}
                                    sx={{
                                      cursor: "pointer",
                                      color: "#16DB65",
                                    }}
                                    onClick={() =>
                                      handleVideoButtonClick(
                                        campaign.videoLink ?? ""
                                      )
                                    }
                                  >
                                    here{" "}
                                  </Typography>
                                ) : null}
                                {!isCompanyActive(company?.status) &&
                                campaign?.videoLink
                                  ? "to watch an example video."
                                  : null}
                              </>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  {!isProfileComplete ? (
                    isCompanyActive(company?.status) ? (
                      <Grid item xs={12} md={12}>
                        <Card
                          sx={{
                            boxShadow: "0px 0px 0px 1px #F2F4F7;",
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 3,
                          }}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={isMobile ? "column" : "row"}
                            width={"100%"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            p={1}
                            gap={3}
                          >
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              p={1}
                            >
                              <SemicircularProgressBar
                                value={profileCompleteness?.percentage ?? 0}
                              />
                            </Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "start",
                              }}
                            >
                              <Typography gutterBottom variant="h6">
                                Company Profile Completeness
                              </Typography>
                              <Typography
                                variant="caption"
                                color={"text.secondary"}
                              >
                                {
                                  "Follow the checklist to boost your profile and receive more job referrals. We verify all company profiles to maintain our platform's integrity and quality."
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={12}>
                        <Card
                          sx={{
                            boxShadow: "0px 0px 0px 1px #F2F4F7;",
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 3,
                          }}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={isMobile ? "column" : "row"}
                            width={"100%"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            p={1}
                            gap={3}
                          >
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              p={1}
                            >
                              <SemicircularProgressBar
                                value={profileCompleteness?.percentage ?? 0}
                              />
                            </Box>
                            <Box
                              sx={{
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "start",
                              }}
                            >
                              <Typography gutterBottom variant="h6">
                                Company Profile Completeness
                              </Typography>
                              <Chip
                                size="small"
                                sx={{
                                  color: "#F3F4F6",
                                  // borderRadius: "12px",
                                  my: 1,
                                }}
                                label={
                                  <Typography
                                    variant="overline"
                                    sx={{
                                      color: "text.secondary",
                                    }}
                                  >
                                    VERIFICATION IN PROCESS
                                  </Typography>
                                }
                              />
                              <Typography
                                variant="caption"
                                color={"text.secondary"}
                              >
                                {
                                  "We verify all company profiles to maintain our platform's integrity and quality. We’ll reach out if we have any questions. In the meantime, get started by referring a job!"
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    )
                  ) : null}
                  {isProfileComplete ? (
                    isCompanyActive(company?.status) ? (
                      <ExplorePartnersComponent />
                    ) : null
                  ) : (
                    <Grid item xs={12}>
                      <GettingStartedCheckList
                        profileCompleteness={profileCompleteness}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4} my={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        boxShadow: "0px 0px 0px 1px #F2F4F7;",
                        py: 4,
                        px: 3,
                        bgcolor: "#F8F9FA",
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                      }}
                    >
                      {familyOfBrands ? (
                        <>
                          {" "}
                          <Typography variant="subtitle1" fontWeight={400}>
                            You&apos;re affiliated with{" "}
                            <Typography
                              variant="subtitle1"
                              display={"inline"}
                              fontWeight={700}
                            >
                              {familyOfBrands.name}!
                            </Typography>
                          </Typography>
                          {familyOfBrands.logoUrl && !isImageLoadError ? (
                            <img
                              src={familyOfBrands.logoUrl}
                              alt="parent logo"
                              style={{
                                maxWidth: "200px",
                                maxHeight: "112px",
                                padding: "4px 0",
                                margin: "0 16px",
                                objectFit: "contain",
                              }}
                              onError={() => setIsImgaeLoadError(true)}
                            />
                          ) : null}
                          {totalParentBrandsCount ? (
                            <Typography variant="body2" color="text.secondary">
                              {familyOfBrands.name} has over{" "}
                              {totalParentBrandsCount} brands and growing! Add
                              them as referral partners today.
                            </Typography>
                          ) : null}
                          <Divider />
                        </>
                      ) : null}
                      <FactsCarousel />
                      <Divider />
                      <Box display="flex" gap={2} height={"100%"}>
                        <IconButton
                          aria-label="close"
                          sx={{
                            bgcolor: "white",
                            borderRadius: "20px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          <VideocamOutlinedIcon
                            sx={{
                              color: "black",
                              width: "22px",
                              height: "22px",
                            }}
                          />
                        </IconButton>
                        <Box display="flex" flexDirection={"column"}>
                          <Typography variant="subtitle1">
                            Get to know the platform
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Watch our quick start video for tips
                          </Typography>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              width: "fit-content",
                              mt: 2,
                            }}
                            endIcon={<ArrowForwardOutlinedIcon />}
                            onClick={() => {
                              handleVideoButtonClick();
                            }}
                          >
                            Watch Video
                          </Button>
                        </Box>
                      </Box>
                      <Divider />
                      <Box display="flex" gap={2} height={"100%"}>
                        <IconButton
                          aria-label="close"
                          sx={{
                            bgcolor: "white",
                            borderRadius: "20px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          <SupportAgentOutlinedIcon
                            sx={{
                              color: "black",
                              width: "22px",
                              height: "22px",
                            }}
                          />
                        </IconButton>
                        <Box display="flex" flexDirection={"column"}>
                          <Typography variant="subtitle1">
                            Need Help?
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Message our Support Team
                          </Typography>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              width: "fit-content",
                              mt: 2,
                            }}
                            endIcon={<ArrowForwardOutlinedIcon />}
                            onClick={() => setContactSupportModalOpen(true)}
                          >
                            Contact Support
                          </Button>
                        </Box>
                      </Box>
                    </Card>
                    {/* {bonusEligibleUsers?.some(
                      userWithGroup =>
                        !!userWithGroup.user.acceptedTermsAndConditions
                    ) ? null : (
                      <BonusCard />
                    )} */}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      {isMobile ? null : (
        <Box
          position="fixed"
          // bottom={"12px"}
          // right={"100px"}
          bottom={"40px"}
          right={"48px"}
        >
          {/* <Backdrop open={jobUploadOpen} />
          <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%, -32%)",
            }}
            icon={<SpeedDialIcon />}
            open={jobUploadOpen}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            {speedDialActions.map((action, index) => (
              <SpeedDialAction
                key={action.name}
                tooltipOpen
                icon={action.icon}
                tooltipTitle={action.name} // Apply custom tooltip class
                classes={{ staticTooltipLabel: "custom-tooltip" }}
                tooltipPlacement="left"
                onClick={
                  index === 1
                    ? () => (window.location.href = InitialWhatsappMessage)
                    : () => {
                        handleClose();
                        setIsJobDialogOpen(true);
                      }
                }
              />
            ))}
          </SpeedDial> */}
          <ToolTip placement="bottom" message="Upload a Job">
            <Fab
              color="primary"
              aria-label="add-invite"
              onClick={() => setIsJobDialogOpen(true)}
            >
              <AddIcon />
            </Fab>
          </ToolTip>
        </Box>
      )}
      {isJobDialogOpen ? (
        <CreateTechnicianJobDialogWrapper
          isOpen={isJobDialogOpen}
          jobsListUrl={getNavigationPathFromRoutePaths([
            CompanyRoutes.BASE,
            CompanyRoutes.MY_JOBS,
          ])}
          onClose={() => {
            setIsJobDialogOpen(false);
          }}
        />
      ) : null}
    </>
  );
};
