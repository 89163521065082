import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import API from "@/common/api";

export const USE_GET_CHATS_URI = "/chats";

export interface Chat {
  id: number;
  fromCompanyId: number;
  toCompanyId: number;
  message: string;
  createdAt: string;
  toCompanyReadAt?: string;
}

export function useFetchChats(
  companyId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>
): UseQueryResult<Array<Chat>> {
  return useQuery({
    queryKey: [USE_GET_CHATS_URI, companyId],
    queryFn: async () => {
      const response = await API.get(`${USE_GET_CHATS_URI}/${companyId}`);
      return response.data.data;
    },
    ...options,
  });
}
