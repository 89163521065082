import Hotjar from "@hotjar/browser";

import { AppConstants } from "../constants";

const hotjarVersion = 6;

export const setupHotJar = () => {
  if (AppConstants.environment !== "local") {
    Hotjar.init(AppConstants.HOTJAR_SITE_ID, hotjarVersion);
  }
};

export const identifyUserOnHotJar = (
  userId: number,
  userPropertiesForAnalytics?: Record<string, string | number | boolean | Date>
) => {
  if (AppConstants.environment !== "local") {
    Hotjar.identify(String(userId), userPropertiesForAnalytics ?? {});
  } else {
    console.info(`skipping identify for ${userId} on hotjar as env is local`);
  }
};
