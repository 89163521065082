// PollingComponent.js
import CloseIcon from "@mui/icons-material/Close";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth/context/jwt";
import {
  CallRecordingNotification,
  GET_CALL_RECORDING_NOTIFICATONS,
} from "@/company/api/useFetchCallNotificationsUnread";
import {
  CallRecordingStatus,
  CallType,
  GET_CALL_RECORDINGS,
} from "@/company/api/useFetchCallRecordings";
import { useFetchLatestCallRecording } from "@/company/api/useFetchLatestCallRecording";
import { useUpdateCallRecordingStatus } from "@/company/api/useUpdateCallRecording";
import { CompanyAvatar } from "@/company/components/company-avatar/CompanyAvatar";
import { CompanyRoutes } from "@/company/types";

export function useCallRecordsPolling() {
  const [showSnackBanner, setShowSnackBanner] = useState(false);
  const queryClient = useQueryClient();

  const { session } = useAuth();
  const { mutateAsync: updateCallRecordingStatus } =
    useUpdateCallRecordingStatus({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [GET_CALL_RECORDINGS],
        });
        queryClient.invalidateQueries({
          queryKey: [GET_CALL_RECORDING_NOTIFICATONS],
        });
        navigate(
          `/${CompanyRoutes.BASE}/${CompanyRoutes.JOB_CALLS}/${selectedRecordingNotification?.callRecording?.id}`
        );
        closeSnackbar();
      },
    });

  const [selectedRecordingNotification, etSelectedRecordingNotification] =
    useState<null | CallRecordingNotification>(null);
  const {
    data: recording,
    error,
    isLoading,
  } = useFetchLatestCallRecording({
    // enabled: !!(
    //   (AppConstants.environment !== "demo" ||
    //     AppConstants.environment !== "staging" ||
    //     AppConstants.environment !== "local") &&
    //   session?.groups?.some(canSeeCompanyMessages)
    // ),
    enabled: false,
    refetchInterval: 1000,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (showSnackBanner && selectedRecordingNotification) {
      const selectedRecording = selectedRecordingNotification.callRecording;
      enqueueSnackbar("Referral opportunity identified", {
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        variant: "banner",
        action: snackbarId => {
          return (
            <CloseIcon
              sx={{
                color: "white",
                cursor: "pointer",
                mr: 1,
                width: "20px",
                height: "20px",
              }}
              fontSize="small"
              onClick={() => {
                updateCallRecordingStatus({
                  notificationId: selectedRecordingNotification?.id,
                  status: CallRecordingStatus.NOTIFIED,
                });
              }}
            />
          );
        },
        subText: (
          <Box display={"flex"} flexDirection={"column"} py={1} gap={0.5}>
            <Box display={"flex"} flexDirection={"column"} pt={1} gap={0.5}>
              <Typography variant="body1" fontWeight={600}>
                Call With
              </Typography>
              <Box display={"flex"} pt={1} gap={2} alignItems={"center"}>
                {/* <CompanyAvatar
                  name={`${
                    selectedRecording.type === CallType.INBOUND
                      ? selectedRecording?.fromPhone
                      : selectedRecording?.fromPhone
                  }`}
                  height={"48px"}
                  width={"48px"}
                  bgColor="white"
                  border="1px solid gray !important"
                /> */}
                <Box>
                  <Typography variant="subtitle2">{`${
                    selectedRecording?.type === CallType.INBOUND
                      ? selectedRecording?.fromPhone
                      : selectedRecording?.toPhone
                  }`}</Typography>
                  {/* <Typography
                    variant="subtitle2"
                    color="text.secondary"
                  >{`${selectedRecording?.fromPhone}`}</Typography> */}
                </Box>
              </Box>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box display={"flex"} flexDirection={"column"} gap={0.5}>
              <Typography variant="body1" fontWeight={600}>
                Referral Partner
              </Typography>
              {selectedRecording?.potentialReferralPartner ? (
                <Box display={"flex"} pt={1} gap={2} alignItems={"center"}>
                  <CompanyAvatar
                    name={`${selectedRecording?.potentialReferralPartner?.name}`}
                    logo={selectedRecording?.potentialReferralPartner?.logoUrl}
                    height={"48px"}
                    width={"48px"}
                    bgColor="white"
                    border="1px solid gray !important"
                  />
                  <Box>
                    <Typography variant="subtitle2">
                      {selectedRecording?.potentialReferralPartner?.name}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  fontStyle={"italic"}
                >
                  Not Identified
                </Typography>
              )}
            </Box>
            <Divider sx={{ my: 1 }} />
            <Button
              startIcon={<QuestionAnswerOutlinedIcon />}
              variant="contained"
              onClick={() => {
                updateCallRecordingStatus({
                  notificationId: selectedRecordingNotification?.id,
                  status: CallRecordingStatus.NOTIFIED,
                });
              }}
            >
              {selectedRecording?.potentialReferralPartner
                ? "View Transcript"
                : "Connect"}
            </Button>
          </Box>
        ),
      });
    }
  }, [showSnackBanner, selectedRecordingNotification]);

  useEffect(() => {
    if (
      recording?.callRecording &&
      recording?.status === CallRecordingStatus.NEW
    ) {
      etSelectedRecordingNotification(recording);
      setShowSnackBanner(true);
    }
  }, [recording?.id]);
  return;
}
